import * as Tooltip from "@radix-ui/react-tooltip";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { default as classNames, default as cx } from "classnames";
import { CalendarDeadline } from "components/Deadline/Deadline";
import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Cross } from "components/icons/Cross";
import { Info } from "components/icons/Info";
import Modal from "components/Modal/Modal";
import { TOGGLE_INTERACTION } from "constants/analyticsEvents";
import { CALENDAR, FISCAL } from "constants/calendarTypes";
import { CALENDAR_SPOTLIGHT_CLOSED } from "constants/session";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useAnalytics } from "hooks/useAnalytics";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useWindowSize } from "hooks/useWindowSize";
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowCounterClockwise from "static/images/ArrowCounterClockwise.svg";
import ArrowDownLong from "static/images/ArrowDownLong.svg";
import {
  useApplyCouponMutation,
  useGetCalenderQuery,
  useLazyGetCalenderQuery,
  useUpdateQuestionMutation,
} from "store/apis/calendar";
import { setCalenderType } from "store/slices/calender";
import { RootState } from "store/store";
import { CALENDAR_TYPES } from "types/contants/calendarTypes";
import { Deadline } from "types/Models/calendar";
import { BottomBar } from "./BottomBar";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { DashboardLayout } from "components/DashboardLayout";

const SwitchField = ({
  label,
  name,
  description,
  onChange,
  disabled = false,
}: {
  label: string;
  name: string;
  description: string;
  onChange?: (value: boolean) => any;
  disabled?: boolean;
}) => {
  const { setFieldValue, values } = useFormikContext();

  const onCheckedChange = (value: boolean) => {
    setFieldValue(name, value);
    onChange?.(value);
  };

  return (
    <div className="t-flex t-items-center t-justify-between">
      <ToolTip text={description}>
        <label htmlFor={name} className="t-cursor-pointer">
          <span
            className={classNames("t-mr-2 t-inline-block t-text-body", {
              "t-opacity-50": disabled === true,
            })}
          >
            {label}
          </span>
        </label>
      </ToolTip>
      <div className="t-shrink-0">
        <Switch
          disabled={disabled}
          checked={(values as any)[name]}
          name={name}
          onCheckedChange={onCheckedChange}
        />
      </div>
    </div>
  );
};

export const Calendar = ({
  publicCalendar = false,
  extraContentHeight = "0px",
  groupId,
  bottomBar,
  header,
}: {
  publicCalendar?: boolean;
  extraContentHeight?: string;
  groupId?: string;
  bottomBar?: ReactNode;
  header?: ReactNode;
}) => {
  const [isSpotLightOpen, setSpotLightOpen] = useState(false);
  const [showNudge, setShowNudge] = useState(false);
  const [retake, setRetake] = useState(false);
  const [updateCalender] = useUpdateQuestionMutation();
  const [deadlineModal, setDeadlineModal] = useState<
    Deadline | null | undefined
  >(null);
  const calendarInnerRef = useRef<HTMLDivElement | null>(null);
  const calendarDeadlinesRef = useRef<HTMLDivElement | null>(null);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { isPublicUser } = useRoleBasedView();
  const entityId = useCurrentEntityId();

  const calendarType = (useSelector(
    (state: RootState) => state.calendar.calendarType
  ) || CALENDAR) as CALENDAR_TYPES;

  const calendarYear = useSelector((state: RootState) => state.calendar.year);

  const {
    data: calendar,
    isLoading,
    refetch,
    isFetching,
    isSuccess,
  } = useGetCalenderQuery(
    {
      taxPeriod: calendarType as CALENDAR_TYPES,
      groupId,
      calendarYear,
      entityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [
    applyCoupon,
    {
      isLoading: applyingCoupon,
      isError: applyCouponFailed,
      error: applyCouponError,
    },
  ] = useApplyCouponMutation();

  const [getCalender] = useLazyGetCalenderQuery();

  const initialValues = calendar?.question_answers.reduce(
    (acc, c) => ({
      ...acc,
      [c.question.uuid]: c.answers[0].is_selected ? true : false,
    }),
    {}
  );

  const onChange = (name: string) => async (value: boolean) => {
    const question = calendar?.question_answers.find(
      (q) => q.question.uuid === name
    );

    const answer = value ? question?.answers[0] : question?.answers[1];

    if (answer && question) {
      trackEvent(TOGGLE_INTERACTION, {
        interacted_with: question.question.title,
        current_value: value,
        id: question.question.uuid,
      });

      updateCalender({
        questionId: name,
        answerId: answer.uuid,
        groupId,
        calendarYear,
        entityId,
      });
      if (
        calendarInnerRef.current?.getBoundingClientRect().top !== 0 &&
        width &&
        width > 768
      ) {
        calendarInnerRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onCalendarTypeChange = (value: boolean) => {
    dispatch(setCalenderType(value ? CALENDAR : FISCAL));
  };

  const onReset = async () => {
    setRetake(true);
    await getCalender({
      taxPeriod: calendarType,
      retake: true,
      groupId,
      calendarYear,
      entityId,
    }).unwrap();
    await refetch().unwrap();
    dispatch(setCalenderType(CALENDAR));
    setRetake(false);
  };

  const scrollToDeadlines = () => {
    calendarDeadlinesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useLayoutEffect(() => {
    const spotLightClosed =
      localStorage.getItem(CALENDAR_SPOTLIGHT_CLOSED) === "true";

    if (width && !spotLightClosed) {
      const isMobile = width > 768;
      setSpotLightOpen(isMobile);
    }
  }, [Boolean(width)]);

  useEffect(() => {
    const onClick = () => {
      setSpotLightOpen(false);
      localStorage.setItem(CALENDAR_SPOTLIGHT_CLOSED, "true");
    };

    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setShowNudge(true);
      setTimeout(() => setShowNudge(false), 3000);
    }
  }, [isFetching]);

  if (!calendar || isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  const onCouponSubmit = ({ coupon }: { coupon: string }) =>
    applyCoupon({ coupon, calendarYear, entityId });

  const discountAppliedOnTotal =
    calendar?.coupon &&
    calendar.total_discounted_amount !== calendar.total_amount;

  return (
    <DashboardLayout
      className="t-h-full t-w-full t-overflow-hidden"
      header={header}
    >
      {isSpotLightOpen && (
        <div className="t-fixed t-inset-0 t-z-[90] t-bg-neutral-50 t-opacity-60"></div>
      )}
      <div
        className={classNames(
          "t-h-full t-gap-5 t-overflow-y-auto t-overflow-x-hidden md:t-flex md:t-overflow-y-auto md:t-pb-0"
        )}
      >
        <div
          className={cx("t-top-0 md:t-order-3", {
            "md:t-sticky": !isSpotLightOpen,
          })}
        >
          {isPublicUser && (
            <div className="t-mb-4 t-items-center t-gap-3 t-rounded-lg t-border t-border-solid t-border-neutral-0 t-bg-white t-px-5 t-py-4">
              <p className="t-m-0 t-mb-2 t-text-subtitle-sm">Add Coupon</p>
              <Formik
                enableReinitialize
                initialValues={{
                  coupon: "",
                }}
                onSubmit={onCouponSubmit}
              >
                {({
                  setFieldValue,
                  values,
                }: FormikProps<{ coupon: string }>) => {
                  const onClearCoupon = () => {
                    setFieldValue("coupon", "");
                    applyCoupon({ coupon: "", calendarYear });
                  };

                  return (
                    <Form className="t-m-0 t-flex t-w-full t-gap-3">
                      {!calendar.coupon && (
                        <>
                          <div>
                            <TextInput
                              name="coupon"
                              customSize="small"
                              placeholder="Apply Coupon"
                            />
                            {applyCouponFailed && applyCouponError && (
                              <div className="t-mt-1.5 t-text-caption t-capitalize t-leading-none t-text-red">
                                {
                                  (
                                    (applyCouponError as FetchBaseQueryError)
                                      .data as { error: { message: string } }
                                  ).error.message
                                }
                              </div>
                            )}
                          </div>
                          <Button isLoading={applyingCoupon} size="small">
                            Apply
                          </Button>
                        </>
                      )}
                      {Boolean(calendar.coupon) && (
                        <div className="t-flex t-items-center t-gap-2 t-rounded t-bg-green-10 t-px-4 t-py-2 t-text-body-sm t-font-medium">
                          <span>"{calendar.coupon}" coupon applied</span>
                          <button
                            className="all:unset t-flex"
                            onClick={() => onClearCoupon()}
                          >
                            <Cross />
                          </button>
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}

          <div
            className={cx(
              "t-grow-1 t-h-min t-shrink-0 t-p-5 md:t-basis-[440px] md:t-rounded-xl md:t-border md:t-border-solid md:t-border-neutral-0 md:t-pb-7  md:t-shadow-calendar-questions"
            )}
          >
            <div className="t-mb-4 t-flex t-items-start t-justify-between">
              <div className="t-flex t-items-center t-gap-2">
                <p className="t-m-0 t-text-subtitle t-font-bold">Settings</p>
                <ToolTip
                  text={`Only for Delaware C-Corps incorporated before Jan 1, ${
                    Number(calendarYear) + 1
                  }.`}
                >
                  <span className="t-text-neutral">
                    <Info />
                  </span>
                </ToolTip>
              </div>

              <ToolTip text="Reset to default deadlines">
                <span>
                  <Button
                    customType="ghost_icon"
                    size="small"
                    onClick={onReset}
                  >
                    <img
                      src={ArrowCounterClockwise}
                      alt="reset-toggles"
                      className={cx({
                        "t-animate-spin-forward": retake,
                      })}
                    />
                  </Button>
                </span>
              </ToolTip>
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                ...initialValues,
                is_annual_calendar: calendarType === CALENDAR,
              }}
              onSubmit={(v) => {}}
            >
              <Form className="t-m-0 t-flex t-w-full t-flex-col t-gap-3">
                <Tooltip.Provider>
                  <Tooltip.Root open={isSpotLightOpen}>
                    <Tooltip.Trigger asChild>
                      <div
                        className={cx("t-relative", {
                          "t-z-[99999] t-rounded t-bg-white t-p-2":
                            isSpotLightOpen,
                        })}
                      >
                        <SwitchField
                          onChange={onCalendarTypeChange}
                          label="Elected Calendar Year as Federal tax year"
                          name="is_annual_calendar"
                          description={`Have elected Calendar Year (Jan to Dec) as your US entity's ${calendarYear} Federal tax year?`}
                        />
                      </div>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="t-z-[999] t-rounded t-bg-white t-p-2"
                        side="right"
                      >
                        Start here
                        <Tooltip.Arrow className="t-fill-white t-text-white" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>

                {calendar.question_answers.map(
                  ({ question: { title, description, uuid } }) => (
                    <>
                      <Divider />
                      <SwitchField
                        onChange={onChange(uuid)}
                        label={title}
                        name={uuid}
                        description={description}
                      />
                    </>
                  )
                )}
              </Form>
            </Formik>
          </div>
        </div>
        <div className="md:t-hidden">
          <Divider />
        </div>
        <div
          ref={calendarDeadlinesRef}
          className="t-grow md:t-order-1 md:t-mb-0 md:t-mt-0 md:t-px-0"
        >
          <div className="t-h-full t-w-full t-pb-20 md:t-pb-0 md:t-pr-0">
            <div className="t-flex t-grow t-flex-col t-gap-4 t-p-5 md:t-p-0 !t-pb-32">
              <LayoutGroup>
                <AnimatePresence>
                  {calendar?.deadlines?.map(
                    ({
                      date,
                      uuid,
                      title,
                      description,
                      extension,
                      price,
                      discounted_price,
                      task_data,
                      task_template,
                    }) => (
                      <motion.div
                        layout
                        key={uuid}
                        transition={{ duration: 0.45, delay: -0.1 }}
                        initial={{ x: 100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -100, opacity: 0 }}
                      >
                        <CalendarDeadline
                          date={date}
                          title={title}
                          description={description}
                          extension={extension}
                          isFree={discounted_price === 0}
                          discountedPrice={`$${discounted_price}`}
                          price={price && `$${price}`}
                          taskData={task_data}
                          taskTemplate={task_template}
                        />
                      </motion.div>
                    )
                  )}
                </AnimatePresence>
              </LayoutGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="t-bottom-0 t-z-20 md:t-sticky">
        {bottomBar || (
          <BottomBar
            total={calendar.total_discounted_amount}
            makeSpaceForChatInMobile={!publicCalendar}
          />
        )}
      </div>

      <AnimatePresence>
        {showNudge && (
          <motion.div
            className="md:t-hidden"
            transition={{
              duration: 0.2,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <motion.div
              style={{
                x: "-50%",
                opacity: 1,
              }}
              initial={{
                y: "-20%",
              }}
              animate={{
                y: "0%",
              }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                restSpeed: 0.2,
                duration: 0.5,
                ease: "easeOut",
              }}
              className="t-fixed t-bottom-24 t-left-1/2 t-z-20 t-transform t-rounded-full t-bg-white t-px-4 t-py-2 t-text-body-sm t-shadow-[0px_4px_20px_rgba(97,96,96,0.2)]"
              onClick={scrollToDeadlines}
            >
              <Button className="all:unset">
                <div className="t-flex t-items-center t-justify-center t-gap-1">
                  View deadline <img src={ArrowDownLong} alt="Arrow down" />
                </div>
              </Button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
};
