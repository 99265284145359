import DashboardContainer from "components/dashboard/DashboardContainer";
import Breadcrumb from "components/DesignSystem/Breadcrumb/Breadcrumb";
import Tab from "components/DesignSystem/Tab/Tab";
import ServiceTeam from "components/serviceTeam/serviceTeam";
import { ForeignCATask } from "components/tasks/taskForForeignCA/ForeignCATask";
import { TeamManagement } from "components/TeamManagement/TeamManagement";
import { usePageTitle } from "hooks/usePageTitle";
import {
  matchPath,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import "static/styles/components/groupData.css";
import { useGetPracticeDetailQuery } from "store/apis/teamSettingCPA";
import { PracticeCrmGroupChat } from "./PracticeCrmGroupChat";

export const GroupPracticeCrm = () => {
  const { pathname } = useLocation();
  const { practicecrmId } = useParams<{ practicecrmId: string }>();
  const { path, url } = useRouteMatch();
  const FILING = "filings";
  const CRM = "clients";
  const TEAM_MANAGEMENT = "teammanagement";

  let { data } = useGetPracticeDetailQuery(
    { serviceTeamId: practicecrmId },
    { skip: !practicecrmId }
  );
  usePageTitle(data?.service_team_name || "group");
  const groupDomain = data?.admin_details?.email?.split("@")?.[1];

  const matchPathHelper = (subPath: string) =>
    matchPath(pathname, {
      path: `${path}/${subPath}`,
      strict: false,
      exact: false,
    });

  if (!data) {
    return null;
  }

  const isHome = [FILING, CRM, TEAM_MANAGEMENT].every(
    (v) => !pathname.includes(v)
  );
  const isTeamManagement = matchPathHelper(TEAM_MANAGEMENT);
  const isFiling = matchPathHelper(FILING);
  const isCrm = matchPathHelper(CRM);
  const basePath = path.replace("/:practicecrmId", "");

  return (
    <DashboardContainer className="t-h-full">
      <DashboardContainer.Header>
        <div className="t-px-6">
          <Breadcrumb.Root>
            <Breadcrumb.Item link={basePath}>Practice CRM</Breadcrumb.Item>
            <Breadcrumb.Item>{data?.service_team_name}</Breadcrumb.Item>
          </Breadcrumb.Root>
        </div>
        <div className="t-px-6 t-text-h5 t-text-text-100 t-mb-1">
          {data?.service_team_name}
        </div>
        <Tab.Root value="MATCH">
          <div className="t-flex t-items-center t-justify-between t-gap-8 t-p-0 t-ml-6 t-border-x-0 t-border-b t-border-t-0 t-border-solid t-border-neutral-0">
            <Tab.List>
              <Tab.NavTrigger to={url} value={isHome ? "MATCH" : "UNMATCH"}>
                Chat
              </Tab.NavTrigger>

              <Tab.NavTrigger
                to={`${url}/${FILING}`}
                value={isFiling ? "MATCH" : "UNMATCH"}
              >
                Task Tracker
              </Tab.NavTrigger>
              <Tab.NavTrigger
                to={`${url}/${CRM}`}
                value={isCrm ? "MATCH" : "UNMATCH"}
              >
                Clients
              </Tab.NavTrigger>
              <Tab.NavTrigger
                to={`${url}/${TEAM_MANAGEMENT}`}
                value={isTeamManagement ? "MATCH" : "UNMATCH"}
              >
                Team Management
              </Tab.NavTrigger>
            </Tab.List>
          </div>
        </Tab.Root>
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-mt-5">
        <Switch>
          <Route exact path={path}>
            <PracticeCrmGroupChat />
          </Route>
          <Route exact path={`${path}/${FILING}`}>
            <ForeignCATask hideTabBar />
          </Route>
          <Route exact path={`${path}/${TEAM_MANAGEMENT}`}>
            <TeamManagement groupDomain={groupDomain} />
          </Route>
          <Route path={`${path}/${CRM}`}>
            <ServiceTeam hideTabBar />
          </Route>
        </Switch>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
