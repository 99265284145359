import { SearchBox } from "components/design/searchBox";
import { DownloadIcon } from "components/icons/Download";
import { debounce } from "utils/debouncing";
import { Button } from "../DesignSystem/Button/Button";
export function Header({
  setShowModal,
  searchedTerm,
  taskStates,
  selectedTaskState,
  update,
  exportCsv,
  isDownloadingCsv,
}) {
  const searchTerm = debounce((e) => {
    const { value } = e.target;
    if (value) {
      update({ query: "search_term", value: value });
    } else {
      update({ query: "search_term", value: null });
    }
  });

  const onTaskFilter = (e) => {
    const { value } = e.target;
    if (value) {
      update({ query: "task_state_filter_uuids", value: value });
    } else {
      update({ query: "task_state_filter_uuids", value: null });
    }
  };

  return (
    <div className="t-w-full">
      <div className="t-flex t-border t-border-b t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-neutral-10 t-py-3 t-pl-0 t-pr-5">
        <span className="searchTable ">
          <SearchBox
            onChange={searchTerm}
            placeholder="  Search"
            value={searchedTerm}
          />
        </span>

        <div>
          <select className="selectFilterDrop" onChange={onTaskFilter}>
            <option value=""> Select Task status </option>
            {taskStates.map((taskState) => {
              return (
                <option
                  key={taskState.uuid}
                  value={taskState.uuid}
                  selected={selectedTaskState === taskState.uuid}
                >
                  {taskState.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="t-ml-auto t-flex t-gap-4">
          <Button
            onClick={exportCsv}
            customType="icon"
            size="small"
            isLoading={isDownloadingCsv}
          >
            <span className="t-text-text-30">
              <DownloadIcon color="currentColor" size={20} />
            </span>
          </Button>
          <Button
            size="small"
            customType="primary"
            onClick={() => setShowModal(true)}
          >
            + New group
          </Button>
        </div>
      </div>
    </div>
  );
}
