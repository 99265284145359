import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useLazyGetCreditsQuery } from "store/apis/refrral";
import { RootState } from "store/store";

type IAddCreditsProps = {
  showPaymentModal: boolean;
  closePaymentModal: () => void;
  refetch?: () => void;
};

export const AddCredits: FC<IAddCreditsProps> = ({
  showPaymentModal,
  closePaymentModal,
  refetch,
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const creditAmountToAdd = useSelector(
    (state: RootState) => state.credits.creditAmountToAdd
  );
  const entityId = useCurrentEntityId();
  const [getCredits] = useLazyGetCreditsQuery();

  const handlePaymentSuccess = async () => {
    await getCredits({ groupId }).unwrap();
    refetch?.();
    closePaymentModal();
  };

  return (
    <CheckoutModal
      open={showPaymentModal}
      entityId={entityId}
      type="credits"
      creditsToBeAdded={Number(creditAmountToAdd)}
      onCreditsAdded={handlePaymentSuccess}
      onClose={closePaymentModal}
    />
  );
};
