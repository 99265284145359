import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import React from "react";
import { useModal } from "hooks/useModal";
import ThreeDots from "static/images/ThreeDots.svg";
import ShareHolderDelete from "./ShareHolderDelete";
import { AddShareholder } from "./AddShareholder";
import { Shareholder } from "types/Models/Shareholders";

const ShareholderAction = ({ shareholder }: { shareholder: Shareholder }) => {
  const deleteModal = useModal();
  const editModal = useModal();

  return (
    <div>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <img src={ThreeDots} alt="options" />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={6} align="end">
            <Dropdown.Item onSelect={editModal.open} key="Edit">
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={deleteModal.open}
              key="delete"
              type="danger"
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <ShareHolderDelete shareholderId={shareholder.uuid} {...deleteModal} />
      <AddShareholder {...editModal} shareholder={shareholder} />
    </div>
  );
};

export default ShareholderAction;
