import { ChannelInfoSlider } from "components/ChannelinfoSlider/ChannelinfoSlider";
import { ChannelPendingActions } from "components/ChannelinfoSlider/ChannelPendingActions";
import { ChannelPinnedItem } from "components/ChannelinfoSlider/ChannelPinnedItem";
import { ChannelMediaSlider } from "components/ChannelMediaSlider/ChannelMediaSlider";
import { ChannelMessageSearch } from "components/ChannelMessageSearch/ChannelMessageSearch";
import Loader from "components/design/loader";
import { BIG } from "constants/chatBubbleWidths";
import { ChannelFilters } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { Channel as ChatChannel } from "./Channel";
import { ChannelList } from "./ChannelList";
import { ChannelForwardChannelSelector } from "components/ChannelForwardChannelSelector/ChannelForwardChannelSelector";
import { MessageInfoSlider } from "components/WhatsappSharedSlider/MessageInfoSlider";
import { WhatsappForwardedSlider } from "components/WhatsappSharedSlider/WhatsappForwardedSlider";

export const Chat = ({
  filters,
  messageMaxWidth = BIG,
  unreplied,
  broadcast,
  channelId,
  onChannelSelect,
  messageId,
  hideTabBar,
}: {
  filters?: ChannelFilters<DefaultStreamChatGenerics>;
  messageMaxWidth?: string;
  unreplied?: boolean;
  broadcast?: boolean;
  channelId?: string;
  onChannelSelect?: (channelId: string, messageId?: string) => void;
  messageId?: string;
  hideTabBar?: boolean;
}) => {
  const { client } = useChatContext();

  if (!client) {
    return <Loader />;
  }

  if (!client.user) {
    return <Loader />;
  }

  return (
    <div
      className="t-flex-1 t-basis-auto t-overflow-y-auto t-h-full"
      // @ts-ignore
      style={{ "--message-max-width": messageMaxWidth }}
    >
      <div className="t-block t-h-full md:t-flex">
        <div className="t-w-full md:t-w-4/12 t-h-full t-border-solid t-border-r t-border-0 t-border-neutral-10">
          <ChannelList
            channelId={channelId}
            onSelect={onChannelSelect}
            filters={filters}
            unreplied={unreplied}
            broadcast={broadcast}
            hideTabBar={hideTabBar}
          />
        </div>

        <div className="t-w-full md:t-w-8/12 t-shrink">
          <ChatChannel
            messageId={messageId}
            channelId={channelId}
            messageMaxWidth={messageMaxWidth}
          >
            <ChannelMessageSearch onSelect={onChannelSelect} />
            <ChannelInfoSlider />
            <ChannelPendingActions />
            <ChannelPinnedItem />
            <ChannelMediaSlider />
            <WhatsappForwardedSlider />
            <MessageInfoSlider />
            <ChannelForwardChannelSelector />
          </ChatChannel>
        </div>
      </div>
    </div>
  );
};
