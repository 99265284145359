import { ConditionalLink } from "components/conditionalLink";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import { useQuery } from "hooks/useQuery";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FloatingChatIcon from "static/images/FloatingChat.svg";
import FloatingChatOpenedIcon from "static/images/FloatingChatOpened.svg";
import { useGetTask7004Query } from "store/apis/task7004";
import { PublicFormEngine } from "./PublicFormEngine";
import Task7004Bar from "./Task7004Bar";
import Task7004Chat from "./Task7004Chat";
import Task7004Header from "./Task7004Header";

const Task7004Container = ({
  floating,
  setFloating,
}: {
  floating: boolean;
  setFloating: (v: boolean) => void;
}) => {
  const { authtoken } = useContext(authContext);
  const isLoggedInUser = Boolean(authtoken);
  const { data, isLoading, isSuccess } = useGetTask7004Query(null, {
    skip: isLoggedInUser,
  });
  const history = useHistory();
  const query = useQuery();
  const refCode = query.get("ref");

  const signUpLink = refCode ? `/signup?ref=${refCode}` : "/signup";

  useEffect(() => {
    if (isLoggedInUser) {
      history.push("/filings");
    }
  }, [isLoggedInUser]);

  const handleFloating = () => {
    setFloating(true);
  };

  return (
    <div className="t-overflow-scroll">
      <Task7004Header />
      <button
        className="floatingBtn t-fixed t-bottom-7 t-right-4 t-z-[99] t-hidden t-h-12 t-w-12 t-rounded-[50%] t-border-none t-bg-purple t-outline-none t-drop-shadow-bubble hover:t-bg-purple-80 sm:t-block"
        onClick={handleFloating}
      >
        <img
          src={floating ? FloatingChatOpenedIcon : FloatingChatIcon}
          alt="floatingChatIcon"
        />
        <span className="t-absolute t-bottom-full t-left-full t-flex t-h-6 t-w-6 -t-translate-x-[80%] t-translate-y-[70%] t-items-center t-justify-center t-rounded-full t-bg-red-50 t-text-caption t-text-neutral-0">
          <span className="">1</span>
        </span>
      </button>

      {!isSuccess || isLoading ? (
        <Loader />
      ) : (
        <div className="t-flex t-w-full sm:t-block">
          <div className="t-w-[66%] t-overflow-scroll sm:t-w-full">
            <Task7004Bar />
            <PublicFormEngine
              email={data.public_user_email}
              mobile={data.public_user_mobile}
              form_data={data?.form_data}
            />
          </div>
          <div className="t-group t-relative t-block t-h-full t-w-[34%] t-flex-col t-gap-1 t-border-0 t-border-r t-border-solid t-border-neutral-10 t-bg-neutral-0 sm:t-hidden sm:t-w-full">
            <div className="t-absolute t-inset-0 t-z-10 t-bg-white t-opacity-60" />
            <Task7004Chat />
            <div className="t-invisible t-absolute t-bottom-1/2 t-left-1/2 t-z-[999] t-flex t-w-10/12 -t-translate-x-1/2 t-transform t-flex-col t-justify-center t-gap-3 t-rounded-lg t-bg-white t-p-4 t-opacity-0 t-shadow-[0px_0px_12px_rgba(152,152,152,0.12)] t-transition-opacity group-hover:t-visible group-hover:t-opacity-100">
              <span className="t-text-center t-text-body t-font-bold">
                Sign up to access
              </span>
              <ConditionalLink to={signUpLink} target="_blank">
                <Button block size="small" customType="primary">
                  Sign up
                </Button>
              </ConditionalLink>
            </div>
          </div>
        </div>
      )}
      {floating && (
        <Modal.Root open={floating} onOpenChange={() => setFloating(false)}>
          <Modal.Portal>
            <Modal.Overlay />
            <Modal.Content className="!t-w-[90vw] t-min-w-[90vw] t-pt-14">
              <Modal.Close />
              <div className="t-group t-relative t-block  t-w-full t-flex-col t-gap-1 t-border-0 t-border-r t-border-solid t-border-neutral-10 t-bg-neutral-0">
                <div className="t-absolute t-inset-0 t-z-10 t-bg-white t-opacity-60" />
                <Task7004Chat />
              </div>
              <div className="t-mt-4 t-flex t-w-full t-justify-between">
                <span className="t-text-center t-text-body t-font-bold">
                  Sign up to access
                </span>
                <ConditionalLink to={signUpLink} target="_blank">
                  <Button block size="small" customType="primary">
                    Sign up
                  </Button>
                </ConditionalLink>
              </div>
            </Modal.Content>
          </Modal.Portal>
        </Modal.Root>
      )}
    </div>
  );
};

export default Task7004Container;
