export const LargeWhiteTick = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1249_3824)">
      <path
        d="M3.64783 11.25L8.02283 15.625L18.0228 5.625"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1249_3824">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.522827)"
        />
      </clipPath>
    </defs>
  </svg>
);
