import React from "react";
import cx from "classnames";
import "static/styles/components/design/unorderedList.css";

export const UnorderedList = ({ children, className, ...props }) => (
  <ul
    className={cx("unordered-list", {
      [className]: className,
    })}
    {...props}
  >
    {children}
  </ul>
);
