import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PERKS_FILTER_CATEGORIES } from "types/contants/perks";
import { PerkFilter } from "types/Models/perks";

type PerkState = {
  currentFilter: string;
  perkCategoryFilter: string[];
  perkTags: PerkFilter[];
};

const initialState: PerkState = {
  currentFilter: PERKS_FILTER_CATEGORIES,
  perkCategoryFilter: [],
  perkTags: [],
};

export const perks = createSlice({
  name: "perks",
  initialState,
  reducers: {
    setPerksCurrentFilter: (state, action: PayloadAction<string>) => {
      state.currentFilter = action.payload;
    },
    setPerksCategoryFilter: (state, action: PayloadAction<string>) => {
      state.perkCategoryFilter = [...state.perkCategoryFilter, action.payload];
    },
    removePerksCategoryFilter: (state, action: PayloadAction<string>) => {
      state.perkCategoryFilter = state.perkCategoryFilter.filter(
        (filter) => filter !== action.payload
      );
    },
    setDefaultPerksCategoryFilter: (state, action: PayloadAction<string[]>) => {
      state.perkCategoryFilter = action.payload;
    },
    clearPerksCategoryFilter: (state) => {
      state.perkCategoryFilter = [];
    },
    setPerkTags: (state, action: PayloadAction<PerkFilter[]>) => {
      state.perkTags = action.payload;
    },
  },
});

export const {
  setPerksCurrentFilter,
  setPerksCategoryFilter,
  removePerksCategoryFilter,
  setDefaultPerksCategoryFilter,
  clearPerksCategoryFilter,
  setPerkTags,
} = perks.actions;

export default perks.reducer;
