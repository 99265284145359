import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { Formik, Form } from "formik";
import { invoiceSettingValidation } from "formValidations/invoiceSettingValidation";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext, useState } from "react";
import {
  useCreateOrUpdateInvoiceSettingsMutation,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import * as FILE_TYPE from "constants/fileTypes";

export const InvoiceSettingModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { authtoken } = useContext(authContext);
  const [logo, setLogoFile] = useState<File | null>();
  const [createInvoiceSetting, { isLoading: creatingInvoiceSetting }] =
    useCreateOrUpdateInvoiceSettingsMutation();
  const group = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();

  const { data: invoiceSetting } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    {
      skip: !group?.uuid || !entityId,
    }
  );

  const onDrop = async (e: FileType[]) => {
    const droppedInvoice = e[0] as File;
    if (droppedInvoice) {
      setLogoFile(droppedInvoice);
    }
  };

  const droppedFile = logo && {
    name: logo?.name,
    file_type: logo?.type as keyof typeof FILE_TYPE,
    is_previewable: logo?.type.includes("image"),
    uuid: "",
  };

  const clearFile = () => setLogoFile(null);

  const onSubmit = async (values: {
    invoice_prefix: string;
    logo_url?: string;
    entity_id: string;
    address?: {
      uuid: string;
      street_address: string;
      city: string;
      state: string;
      zipcode: string;
      country: string;
    };
    email: string;
  }) => {
    if (group?.uuid) {
      try {
        await createInvoiceSetting({
          groupId: group.uuid,
          entityId: entityId,
          payload: {
            ...values,
            autofill_address_id: values?.address?.uuid,
            // @ts-ignore
            address: undefined,
          },
        }).unwrap();
        successToast({ message: "Invoice settings updated successfully" });
        close();
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  const selectedEntity = group.entities?.find(
    (e) => e.uuid === invoiceSetting?.entity_id
  );

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      {isOpen && (
        <Formik
          validationSchema={invoiceSettingValidation}
          validateOnBlur
          validateOnChange={false}
          onSubmit={onSubmit}
          enableReinitialize
          initialValues={
            invoiceSetting || {
              invoice_prefix: "",
              phone: "",
              entity_id: "",
              email: authtoken.email,
              address: {
                uuid: "",
                street_address: "",
                city: "",
                state: "",
                zipcode: "",
                country: "",
              },
            }
          }
        >
          {({ submitForm, values, setFieldValue }) => {
            return (
              <Form>
                <Modal.Content useCustomOverlay>
                  <Modal.Header>
                    <Modal.Title>Invoice Settings</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body>
                    <div className="t-flex t-flex-col t-gap-5">
                      <div>
                        <Combobox
                          isDisabled={Boolean(invoiceSetting?.entity_id)}
                          options={group.entities?.map((entity) => ({
                            label: entity.name,
                            value: entity.uuid,
                          }))}
                          label="Entity"
                          withForm
                          defaultValue={
                            selectedEntity && {
                              label: selectedEntity.name,
                              value: selectedEntity.uuid,
                            }
                          }
                          size="large"
                          name="entity_id"
                          placeholder="Select your entity"
                          actions={
                            <ConditionalLink target="_blank" to="/entities">
                              <Button customType="secondary">Add Entity</Button>
                            </ConditionalLink>
                          }
                        />
                      </div>
                      <TextInput
                        name="email"
                        label="Email"
                        note="This will be set as CC & reply-to email"
                      />
                      <TextInput
                        name="invoice_prefix"
                        label="Invoice Prefix"
                        note={`Invoice number preview: ${values.invoice_prefix}-000001`}
                      />
                      <div className="t-flex t-gap-2 t-items-end">
                        <FileInput
                          withForm
                          name="logo"
                          onDelete={clearFile}
                          onDrop={onDrop}
                          file={droppedFile}
                          label="Logo"
                        />

                        {invoiceSetting?.logo_url && (
                          <img
                            src={invoiceSetting?.logo_url}
                            width={54}
                            height={54}
                            className="t-object-contain"
                            alt="logo"
                          />
                        )}
                      </div>

                      <SelectAutofillCombobox
                        selected={values.address?.uuid}
                        label="My Company Address"
                        name="address.uuid"
                        placeholder=""
                        type="addresses"
                        entityId={values.entity_id}
                        withForm
                        onSelect={(autofill) =>
                          setFieldValue("address.uuid", autofill.uuid)
                        }
                      />
                    </div>
                  </Modal.Body>

                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button>Cancel</Button>
                    </Modal.RawClose>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={creatingInvoiceSetting}
                      disabled={creatingInvoiceSetting}
                    >
                      Update
                    </Button>
                  </Modal.FooterButtonGroup>
                </Modal.Content>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal.Root>
  );
};
