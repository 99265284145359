import SignUp from "components/magicLink/signup/signUp";
import { usePageTitle } from "hooks/usePageTitle";
import React from "react";

// components
import OnboardingOptionLists from "../onboardinOptionLists";

const OnBoardingOption = ({ updatePage, authLoading, showSignup }) => {
  usePageTitle("Sign up");

  return showSignup ? (
    <SignUp updatePage={updatePage} authLoading={authLoading} />
  ) : (
    <div className="onboardingOptionContainer">
      <OnboardingOptionLists />
    </div>
  );
};

export default OnBoardingOption;
