import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import { useDeleteSplitTransactionsMutation } from "store/apis/transactions";
import { openEditSplitTransactionModal } from "store/slices/splitTransaction";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";

export const RemoveSplitActionModal = ({
  isOpen,
  close,
  transaction,
  onConfirm,
  merchantIdToAssignTransaction,
  onMerchantConfirm,
}: {
  isOpen: boolean;
  close: () => void;
  transaction: Transactions;
  onConfirm?: () => void;
  merchantIdToAssignTransaction?: string;
  onMerchantConfirm?: ({
    merchantId,
  }: {
    merchantId?: string;
  }) => Promise<void>;
}) => {
  const [deleteSplitTransaction, { isLoading: isDeleting }] =
    useDeleteSplitTransactionsMutation();
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const transactionId = transaction.transaction.uuid;

  const assignTransactionVendor = async () => {
    try {
      await deleteSplitTransaction({
        groupId,
        entityId,
        transactionId,
      }).unwrap();
      onMerchantConfirm?.({ merchantId: merchantIdToAssignTransaction });
      close();
      successToast({ message: "Vendor assigned" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const assignTransactionCategory = async () => {
    try {
      await deleteSplitTransaction({
        groupId,
        entityId,
        transactionId,
      }).unwrap();
      onConfirm?.();
      close();
      successToast({ message: "Category assigned" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const openEditModal = () => {
    close();
    dispatch(openEditSplitTransactionModal(transaction));
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content className="!t-z-datePicker">
        <Modal.Header>
          <Modal.Title>Remove Split Transaction</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-60">
            This transaction has been split into multiple transactions. Are you
            sure you want to remove the split and make changes?
          </div>
        </Modal.Body>

        <Modal.FooterButtonGroup>
          <Button type="reset" onClick={openEditModal}>
            Edit split transaction
          </Button>
          <Button
            customType="primary"
            type="submit"
            disabled={isDeleting}
            isLoading={isDeleting}
            onClick={
              merchantIdToAssignTransaction
                ? assignTransactionVendor
                : assignTransactionCategory
            }
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
