import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useDocPreview = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const openPreview = useCallback(
    (uuid: string) => {
      history.push(`${pathname}/preview/${uuid}${search}`);
    },
    [pathname]
  );

  return openPreview;
};
