export const Logout = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H6.5C6.91421 1.75 7.25 2.08579 7.25 2.5C7.25 2.91421 6.91421 3.25 6.5 3.25H3.25V12.75H6.5C6.91421 12.75 7.25 13.0858 7.25 13.5C7.25 13.9142 6.91421 14.25 6.5 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612Z"
        fill="#CC4859"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.75 8C5.75 7.58579 6.08579 7.25 6.5 7.25H13.5C13.9142 7.25 14.25 7.58579 14.25 8C14.25 8.41421 13.9142 8.75 13.5 8.75H6.5C6.08579 8.75 5.75 8.41421 5.75 8Z"
        fill="#CC4859"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4697 4.96967C10.7626 4.67678 11.2374 4.67678 11.5303 4.96967L14.0303 7.46967C14.3232 7.76256 14.3232 8.23744 14.0303 8.53033L11.5303 11.0303C11.2374 11.3232 10.7626 11.3232 10.4697 11.0303C10.1768 10.7374 10.1768 10.2626 10.4697 9.96967L12.4393 8L10.4697 6.03033C10.1768 5.73744 10.1768 5.26256 10.4697 4.96967Z"
        fill="#CC4859"
      />
    </svg>
  );
};
