import React, { FC } from "react";
import { Task, TaskFileType } from "types/Models/task";
import ReportCard from "./ReportCard";
import { formatDate } from "utils/formatDate";
import SuccessBox from "./step5";

type AmendFilingProps = {
  task: Task;
  parentRef: any;
  isWSeries: boolean;
};

export const AmendFiling: FC<AmendFilingProps> = ({
  task,
  parentRef,
  isWSeries,
}) => {
  const taskAmendmentFee = task?.amendment?.amendment_fee;
  const amendmentFeeWithDollar = isNaN(Number(taskAmendmentFee))
    ? taskAmendmentFee
    : `$${taskAmendmentFee}`;
  const amendmentFee = taskAmendmentFee ? amendmentFeeWithDollar : "-";

  const amendmentCompletedDate = task?.amendment?.amendment_completed_date
    ? formatDate(task?.amendment?.amendment_completed_date)
    : "-";

  return (
    <div className="t-w-full t-overflow-auto">
      <div className="t-w-full t-mx-auto t-py-6 t-px-20">
        <div className="t-w-full t-pt-8">
          <div className="t-text-text-100 t-text-subtitle">
            Amendment is successfully completed as on {amendmentCompletedDate}
          </div>
        </div>
        <div className="t-ml-0 t-flex t-w-full t-flex-col t-items-start t-justify-start t-pb-10">
          <div className="t-w-full t-border t-border-solid  t-border-surface-transparent t-border-b-neutral-10 t-py-6">
            <div
              className="t-flex t-justify-between t-text-subtitle-sm t-font-medium t-text-text-30
t-text-subtitle-sm t-font-medium t-text-text-30"
            >
              <div>Filing Name</div>
              <div>Amendment Completion Date</div>
            </div>
            <div className="t-mt-2 t-flex t-justify-between t-text-subtitle-sm t-font-normal t-text-text-100">
              <div>{task?.title}</div>
              <div>{amendmentCompletedDate}</div>
            </div>
            <div className="t-text-text-100 t-text-subtext t-mt-2">
              The filing has been approved by IRS
            </div>
          </div>
          <div className="t-w-full t-border t-border-solid  t-border-[transparent] t-border-b-neutral-10 t-py-6">
            <div className="t-text-subtitle-sm t-font-medium t-text-text-30">
              Inkle Pricing
            </div>
            <div
              className="t-mt-2 t-flex t-justify-between t-text-subtitle-sm t-font-normal t-text-text-100
t-text-subtitle-sm t-font-normal t-text-text-100"
            >
              <div>Amendment fee</div>
              <div>{amendmentFee}</div>
            </div>
            <div className="t-mt-2 t-flex t-justify-between t-text-subtitle-sm t-text-text-100">
              <div>Total</div>
              <div>{amendmentFee}</div>
            </div>
          </div>
          {task?.amendment?.task_amendment_documents?.length > 0 && (
            <div className="t-w-full t-border t-border-solid  t-border-[transparent] t-border-b-neutral-10 t-py-6">
              <div className="t-mb-2 t-text-subtitle-sm t-font-medium t-text-text-30">
                Filing Documents After Amendment
              </div>
              {task?.amendment?.task_amendment_documents?.map(
                (amendmentDoc: TaskFileType) => (
                  <div className="t-mb-2" key={amendmentDoc.uuid}>
                    <ReportCard fileData={amendmentDoc} />
                  </div>
                )
              )}
            </div>
          )}
          {Boolean(task?.amendment?.invoices?.length > 0) && (
            <div className="t-w-full t-border t-border-solid  t-border-[transparent] t-border-b-neutral-10 t-py-6 t-pb-10">
              <div className="t-mb-2 t-text-subtitle-sm t-font-medium t-text-text-30">
                Invoices
              </div>
              {task?.amendment?.invoices?.map((invoice: TaskFileType) => (
                <div className="t-mb-2" key={invoice.uuid}>
                  <ReportCard fileData={invoice} />
                </div>
              ))}
            </div>
          )}
          {Boolean(task?.amendment?.receipts?.length > 0) && (
            <div className="t-mb-8 t-w-full t-pb-10 t-pt-6">
              <div className="t-mb-2 t-text-subtitle-sm t-font-medium t-text-text-30">
                Payment Receipt
              </div>
              {task?.amendment?.receipts?.map((receipt: TaskFileType) => (
                <div className="t-mb-2" key={receipt.uuid}>
                  <ReportCard fileData={receipt} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <SuccessBox
        task={task}
        parentRef={parentRef}
        isAmendCompleted
        isWSeries={isWSeries}
      />
    </div>
  );
};
