import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { COMPANY_GROUP_TAG, PROFILE_TAG } from "constants/tagCategory";
import {
  CHURNED,
  DISSOLVED,
  MOVE_TO_OPS,
  MOVE_TO_SALES,
  REMOVED,
} from "constants/TagType";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import {
  useUpdateGroupDataMutation,
  useUpdateUserDataMutation,
} from "store/apis/salesCrm";
import { ConfirmationModal } from "./ConfirmationModal";
import { MoveToOpsModal } from "./MoveToOpsModal";
import { MoveToSalesModal } from "./MoveToSalesModal";

type CrmTableDropdownProps = {
  actionOn: "group" | "profile";
  showMoveToSalesOption?: boolean;
  showMoveToOpsOption?: boolean;
  uuid: string;
};

export const CrmTableDropdown = ({
  actionOn,
  showMoveToSalesOption = false,
  showMoveToOpsOption = false,
  uuid,
}: CrmTableDropdownProps) => {
  const isForProfile = actionOn === "profile";
  const { alertToast, successToast } = useToast();
  const { data: tagsData = [] } = useGetTagsForCategoryQuery({
    tagType: isForProfile ? PROFILE_TAG : COMPANY_GROUP_TAG,
  });
  const [updateGroup, { isLoading: isUpdatingGroup }] =
    useUpdateGroupDataMutation();
  const [updateUser, { isLoading: isUpdatingUser }] =
    useUpdateUserDataMutation();
  const removeModal = useModal();
  const churnModal = useModal();
  const dissolveModal = useModal();
  const salesModal = useModal();
  const opsModal = useModal();

  const getGroupActionPayload = (tagTitle: string) => {
    const tag_id = tagsData.find(({ title }) => title === tagTitle)?.uuid;
    switch (tagTitle) {
      case REMOVED: {
        return { tag_id };
      }
      case CHURNED: {
        return { tag_id };
      }
      case DISSOLVED: {
        return { tag_id };
      }
      case MOVE_TO_SALES: {
        return {
          crm_type: "SALES_CRM",
        };
      }
      case MOVE_TO_OPS: {
        return {
          crm_type: "OPS_CRM",
        };
      }
      default: {
        return {};
      }
    }
  };

  const getProfileActionPayload = (tagTitle: string) => {
    const tag_id = tagsData.find(({ title }) => title === tagTitle)?.uuid || "";
    switch (tagTitle) {
      case REMOVED: {
        return { tag_id };
      }
      case CHURNED: {
        return { tag_id };
      }
      case DISSOLVED: {
        return { tag_id };
      }
      default: {
        return { tag_id };
      }
    }
  };

  const updateGroupData = async (
    tagTitle:
      | typeof CHURNED
      | typeof DISSOLVED
      | typeof MOVE_TO_SALES
      | typeof REMOVED
      | typeof MOVE_TO_OPS,
    closeModal: () => void
  ) => {
    try {
      if (isForProfile) {
        const payload = getProfileActionPayload(tagTitle);
        await updateUser({
          payload: payload,
          profileId: uuid,
        }).unwrap();
        successToast({ message: "User updated successfully!" });
        closeModal();
        return;
      }

      const payload = getGroupActionPayload(tagTitle);
      await updateGroup({
        payLoad: payload,
        groupId: uuid,
      }).unwrap();
      successToast({ message: "Group updated successfully!" });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()} data-testid="crm-table-dropdown">
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <img src={ThreeDots} alt="Show more options" />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={6} align="end">
            {showMoveToSalesOption && (
              <Dropdown.Item onSelect={salesModal.open} key="moveToSales">
                Move to sales
              </Dropdown.Item>
            )}
            {showMoveToOpsOption && (
              <Dropdown.Item onSelect={opsModal.open} key="moveToOps">
                Move to ops
              </Dropdown.Item>
            )}
            <Dropdown.Item onSelect={removeModal.open} key="remove">
              Remove
            </Dropdown.Item>
            <Dropdown.Item onSelect={churnModal.open} key="churned">
              Churned
            </Dropdown.Item>
            <Dropdown.Item onSelect={dissolveModal.open} key="dissolved">
              Dissolved
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      {/* remove modal */}
      <ConfirmationModal
        show={removeModal.isOpen}
        close={removeModal.close}
        action={"Remove"}
        actionOn={actionOn}
        handleClick={() => updateGroupData(REMOVED, removeModal.close)}
        isLoading={isUpdatingGroup || isUpdatingUser}
      />
      {/* churn modal */}
      <ConfirmationModal
        show={churnModal.isOpen}
        close={churnModal.close}
        action={"Churn"}
        actionOn={actionOn}
        handleClick={() => updateGroupData(CHURNED, churnModal.close)}
        isLoading={isUpdatingGroup || isUpdatingUser}
      />
      {/* dissolve modal */}
      <ConfirmationModal
        show={dissolveModal.isOpen}
        close={dissolveModal.close}
        action={"Dissolve"}
        actionOn={actionOn}
        handleClick={() => updateGroupData(DISSOLVED, dissolveModal.close)}
        isLoading={isUpdatingGroup || isUpdatingUser}
      />
      {/* move to sales */}
      <MoveToSalesModal
        show={salesModal.isOpen}
        close={salesModal.close}
        handleMoveToSales={() =>
          updateGroupData(MOVE_TO_SALES, salesModal.close)
        }
        isLoading={isUpdatingGroup}
      />
      <MoveToOpsModal
        show={opsModal.isOpen}
        close={opsModal.close}
        handleMoveToSales={() => updateGroupData(MOVE_TO_OPS, opsModal.close)}
        isLoading={isUpdatingGroup}
      />
    </div>
  );
};
