import React, { ReactNode } from "react";
import cx from "classnames";
import classNames from "classnames";

type NotificationDotProps = {
  color?: "yellow" | "purple" | "red" | "grey";
  children?: ReactNode;
};

export const NotificationDot = ({
  children,
  color = "red",
}: NotificationDotProps) => {
  return (
    <span
      className={cx("t-rounded-sm", {
        "t-bg-purple-20 t-text-purple t-text-body-sm t-p-1": color === "purple",
        "t-bg-yellow t-text-text-100 t-px-1 t-py-0.5 t-text-overline":
          color === "yellow",
        "t-bg-pink-40 t-text-text-100 t-px-1 t-py-0.5 t-text-overline":
          color === "red",
        "t-bg-neutral-10 t-text-text-100 t-px-1 t-py-0.5 t-text-overline":
          color === "grey",
      })}
    >
      {children}
    </span>
  );
};

export const CollapsedNotification = ({
  color,
}: {
  color?: "yellow" | "purple" | "red" | "grey";
}) => {
  return (
    <div
      className={classNames(
        "t-w-2.5 t-h-2.5 t-bg-purple t-rounded-full t-top-1.5 t-absolute t-right-1.5",
        {
          "t-bg-red": color === "red",
          "t-bg-yellow": color === "yellow",
          "t-bg-neutral-10": color === "grey",
        }
      )}
    ></div>
  );
};
