import React, { ReactNode } from "react";
import classNames from "classnames";

type StepProps = {
  step?: number;
  isActive?: boolean;
  notFirstStep?: boolean;
  children: string;
  sectionKey: string;
  setActiveSectionKey: (sectionKey: string) => void;
};

const Step = ({
  children,
  step,
  isActive,
  notFirstStep,
  sectionKey,
  setActiveSectionKey,
}: StepProps) => {
  return (
    <>
      {notFirstStep && (
        <div className="t-w-0.5 t-ml-[3px] t-h-6 t-bg-neutral-10"></div>
      )}

      <button
        className="all:unset t-flex t-items-center t-h-2 t-min-w-2 t-gap-4 t-max-w-full"
        onClick={() => setActiveSectionKey(sectionKey)}
        type="button"
      >
        <div
          className={classNames(
            "t-flex t-items-center t-justify-center t-rounded-full t-h-2 t-min-w-2 t-text-body-sm",
            {
              "t-bg-purple-40": isActive,
              "t-bg-purple-20": !isActive,
            }
          )}
        ></div>
        <div
          className={classNames(
            "t-text-subtext-sm t-max-w-full t-line-clamp-2",
            {
              "t-text-purple-70": isActive,
              "t-text-neutral-30": !isActive,
            }
          )}
        >
          {children}
        </div>
      </button>
    </>
  );
};

export const FillFormStepper = ({ children }: { children: ReactNode }) => {
  return <div className="t-flex t-flex-col t-w-full">{children}</div>;
};

FillFormStepper.Step = Step;
