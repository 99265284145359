import { object, string } from "yup";

export const parentAccount = object({
  name: string().required("Please enter name"),
  typeId: string().required("Please select type"),
  account_id: string()
    .required("Please enter Account ID")
    .matches(/^[^_]+$/, "Account ID must be exactly 5 digits"),
});

export const parentSelectorValidator = object({
  parent_account: string().required("Please select a parent"),
});

export const newAccount = object({
  account_name: string().required("Please enter name"),
  account_id: string()
    .required("Please enter name")
    .length(5, "Account ID must be exactly 5 characters")
    .matches(/^[^_]+$/, "Account ID must be exactly 5 digits"),
  type: string().required("Please select a Type"),
  start_date: string().when("isBalanceSheetAssociated", {
    is: true,
    then: string().required("Please select starting date"),
    otherwise: string().notRequired(),
  }),
  opening_balance: string().when("isBalanceSheetAssociated", {
    is: true,
    then: string().required("Please enter account balance"),
    otherwise: string().notRequired(),
  }),
});
