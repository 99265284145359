import React from "react";
import { Input } from "components/design/input";
import {
  INTRO_EMAIL,
  PROMO_CODE,
  SPECIAL_EMAIL,
  SPECIAL_URL,
} from "constants/perks";

const PerkTypeRedemption = ({ perkType }) => {
  switch (perkType) {
    case PROMO_CODE: {
      return (
        <div>
          <label className="inputLabel">Coupon Code</label>
          <Input
            type="text"
            className="newPerkInputs"
            placeholder="Enter the discount coupon code"
            name="couponCode"
            required
          />
          <label className="inputLabel">Redemption URL</label>
          <Input
            type="url"
            className="newPerkInputs"
            placeholder="The URL to redeem the code"
            name="redemptionUrl"
            required
          />
        </div>
      );
    }
    case SPECIAL_URL: {
      return (
        <div>
          <label className="inputLabel">UTM Link</label>
          <Input
            type="text"
            className="newPerkInputs"
            placeholder="Please enter the UTM link to redirect the customer to"
            name="utmLink"
            required
          />
        </div>
      );
    }
    case SPECIAL_EMAIL: {
      return (
        <div>
          <label className="inputLabel">Referral Email </label>
          <Input
            type="text"
            className="newPerkInputs"
            placeholder="Referral email ID for customer to reach you out ine"
            name="referralEmail"
            required
          />
        </div>
      );
    }
    case INTRO_EMAIL: {
      return (
        <div>
          <label className="inputLabel">Inkle Introduction </label>
          <Input
            type="text"
            className="newPerkInputs"
            placeholder="Email ID for Inkle to introduce a customer in"
            name="introEmail"
            required
          />
        </div>
      );
    }
    default:
      return;
  }
};

export default PerkTypeRedemption;
