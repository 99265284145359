import {
  LeftBarProfileDropdown,
  LeftBarProfileDropdownItem,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { Settings } from "components/icons/Navbar/Settings";
import { Billing } from "components/icons/Navbar/Billing";
import { Team } from "components/icons/Navbar/Team";
import { Perks } from "components/icons/Navbar/Perks";
import { Resources } from "components/icons/Navbar/Resources";
import { Logout } from "components/icons/Navbar/Logout";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { useAuth } from "hooks/useAuth";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useContext } from "react";
import authContext from "jwt_context&axios/authContext";
import { USER_PROFILE } from "constants/userTypes";
import { SmallBellIcon } from "components/icons/LeftNav/SmallBellIcon";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";

export const ProfileDropdown = ({
  useInternalLink,
}: {
  useInternalLink?: boolean;
}) => {
  const auth = useAuth();
  const group = useCurrentGroupContext();
  const { isCustomer, isAdmin, isCpa } = useRoleBasedView();

  const { logoutUser } = useContext(authContext);
  const { link } = useConstructInternalLink();

  if (!auth) {
    return <Loader />;
  }

  const { name, email, profile_url } = auth;
  const { name: groupName } = group;

  const mayBeUseLink = useInternalLink ? link : (path: string) => path;

  return (
    <LeftBarProfileDropdown
      email={email}
      name={name}
      imageUrl={profile_url || USER_PROFILE}
      org={isCustomer ? groupName : null}
    >
      {isCustomer && (
        <LeftBarProfileDropdownItem
          useParentPath={false}
          icon={Billing}
          to={mayBeUseLink("/settings/billing")}
        >
          Billing
        </LeftBarProfileDropdownItem>
      )}
      <LeftBarProfileDropdownItem
        useParentPath={false}
        icon={Settings}
        to={mayBeUseLink("/settings/profile")}
      >
        Profile Settings
      </LeftBarProfileDropdownItem>
      {(isAdmin || isCpa) && (
        <LeftBarProfileDropdownItem
          useParentPath={false}
          icon={SmallBellIcon}
          to={mayBeUseLink("/settings/notifications")}
        >
          Notifications
        </LeftBarProfileDropdownItem>
      )}
      {isCustomer && (
        <LeftBarProfileDropdownItem
          useParentPath={false}
          icon={Team}
          to={mayBeUseLink("/settings/team")}
        >
          Team Settings
        </LeftBarProfileDropdownItem>
      )}
      {isCustomer && (
        <LeftBarProfileDropdownItem
          useParentPath={false}
          icon={Perks}
          to={mayBeUseLink("/community/perks")}
        >
          Perks
        </LeftBarProfileDropdownItem>
      )}
      {isCustomer && (
        <LeftBarProfileDropdownItem
          useParentPath={false}
          icon={Perks}
          to={mayBeUseLink("/settings/billing/credits")}
        >
          Referrals
        </LeftBarProfileDropdownItem>
      )}
      {isCustomer && (
        <LeftBarProfileDropdownItem
          useParentPath={false}
          icon={Billing}
          to={mayBeUseLink("/settings/auditlog")}
        >
          Audit logs
        </LeftBarProfileDropdownItem>
      )}
      <LeftBarSeparator />
      <LeftBarProfileDropdownItem
        onClick={logoutUser}
        useParentPath={false}
        type="danger"
        icon={Logout}
      >
        Logout
      </LeftBarProfileDropdownItem>
    </LeftBarProfileDropdown>
  );
};
