import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useState } from "react";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { AccountCard } from "./ConnectBank";
import GreenTickWithCircle from "static/images/GreenTickWithCircle.svg";
import { pluralize } from "utils/pluralize";

export const BooksBankAccounts = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [silderAccordionValue, setSliderAccordionValue] = useState("");

  const { data: ledger } = useGetEntityBanksQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const { accounts = [] } = ledger || {};

  if (accounts.length === 0) {
    return null;
  }

  return (
    <div className="t-my-3">
      <SliderAccordion.Root
        type="single"
        collapsible
        value={silderAccordionValue}
        onValueChange={setSliderAccordionValue}
        className="t-flex t-gap-4 t-flex-col"
      >
        <SliderAccordion.Item value="BANK_ACCOUNTS">
          <SliderAccordion.Trigger>
            <div className="t-flex t-items-center t-gap-2">
              {pluralize(accounts.length, "account", "accounts")} connected
              <img
                src={GreenTickWithCircle}
                alt="GreenTickWithCircle"
                className="-t-mt-1"
              />
            </div>
          </SliderAccordion.Trigger>
          <SliderAccordion.Content>
            <span>
              <KeyDownWrapper>
                <div className="t-mt-4 t-mb-3 t-flex t-flex-col t-gap-3">
                  {accounts.map(({ bank_brand, account }) => (
                    <AccountCard
                      key={account.uuid}
                      mask={account.mask}
                      bankName={bank_brand.name}
                      accountName={account.nickname}
                      logo={bank_brand.logo}
                    />
                  ))}
                </div>
              </KeyDownWrapper>
            </span>
          </SliderAccordion.Content>
        </SliderAccordion.Item>
      </SliderAccordion.Root>
    </div>
  );
};
