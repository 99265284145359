import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import React from "react";
import { useHistory } from "react-router-dom";
import { ModalProps } from "types/utils/modal";

export const EstimatedDelivery = ({
  isOpen,
  close,
  pendingFilingCount,
}: { pendingFilingCount: number } & ModalProps) => {
  const history = useHistory();

  const redirectToMyFiling = () => {
    history.push("/admin/myfilings?etd_filter=UN_SET&status=ONGOING");
    close();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="small">
        <Modal.Header>
          <Modal.Title>Set Estimated Time of Delivery</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          You have {pendingFilingCount} filings that needs an ETD. Please set
          the ETD to ensure timely processing.
        </Modal.Body>

        <Modal.FooterButtonGroup>
          <Button onClick={close}>Cancel</Button>
          <Button customType="primary" onClick={redirectToMyFiling}>
            Set now
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default EstimatedDelivery;
