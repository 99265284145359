import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import {
  Connection,
  useDisconnectConnectionMutation,
} from "store/apis/booksConnections";
import {
  useDisconnectBankMutation,
  useRemovePlaidBankMutation,
} from "store/apis/bankConnections";
import { BackendError } from "types/utils/error";
import { object, string } from "yup";

export const DisconnectBankModal = ({
  show,
  onClose,
  connection,
}: {
  show: boolean;
  onClose: () => void;
  connection: Connection;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  let entityId = useCurrentEntityId();
  const [disconnectBank, { isLoading }] = useDisconnectBankMutation();
  const { successToast, alertToast } = useToast();

  const onDisconnect = async () => {
    if (entityId) {
      try {
        await disconnectBank({
          groupId: groupId,
          entityId: entityId,
          bankId: connection.uuid,
        }).unwrap();

        successToast({
          message: `${connection.name} disconnected successfully!`,
        });
        onClose();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Disconnect bank</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-flex t-gap-4 t-flex-col">
          <div className="t-text-text-black t-text-body">
            Are you sure you want to disconnect
            <b> {connection.name}?</b>
          </div>
          <div className="t-text-text-black t-text-body">
            This will disconnect bank accounts from inkle and new transactions
            won't be pulled.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-gap-3 t-justify-end t-w-full">
            <Button onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              customType="danger"
              onClick={onDisconnect}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Disconnect
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
