import { Button } from "components/DesignSystem/Button/Button";
import CloseArrow from "static/images/CloseArrow.svg";
import { Badge } from "components/Task/Badge";
import React from "react";
import { EDIT } from "constants/autofill";
import Slider from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { formatDate } from "utils/formatDate";

export const DirectorsOfficersSlider = ({
  title,
  setTypeAddEdit,
  selectedRowData,
  setShowAddModal,
}: {
  title: string;
  setTypeAddEdit: (newState: React.SetStateAction<string>) => void;
  selectedRowData?: any;
  setShowAddModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  if (!selectedRowData) return null;

  const {
    ap_status,
    appointed_on,
    resigned_on,
    persona_autofill: { first_name, last_name },
  } = selectedRowData;

  const onEditClick = () => {
    setShowAddModal(true);
    setTypeAddEdit(EDIT);
  };

  return (
    <div>
      <Slider.Header>
        <Slider.Title>{title} details</Slider.Title>
        <Slider.Close />
      </Slider.Header>
      <Slider.Body>
        <SliderAccordion.Root type="single" defaultValue="ABOUT">
          <SliderAccordion.Item value="ABOUT">
            <SliderAccordion.Trigger disabled>About</SliderAccordion.Trigger>
            <SliderAccordion.Content forceMount>
              <SliderAccordion.ItemGrid>
                <InfoItem label="Name">{first_name + " " + last_name}</InfoItem>

                {selectedRowData?.persona_role?.name && (
                  <InfoItem label="Role">
                    {selectedRowData?.persona_role?.name}
                  </InfoItem>
                )}

                {appointed_on && (
                  <InfoItem label="Appointed on">
                    {formatDate(appointed_on)}
                  </InfoItem>
                )}

                {resigned_on && (
                  <InfoItem label="Date resigned">
                    {formatDate(resigned_on)}
                  </InfoItem>
                )}

                <InfoItem label="Status">
                  <Badge
                    type={ap_status}
                    children={
                      ap_status.charAt(0).toUpperCase() +
                      ap_status.slice(1).toLowerCase()
                    }
                  />
                </InfoItem>
              </SliderAccordion.ItemGrid>
            </SliderAccordion.Content>
          </SliderAccordion.Item>
        </SliderAccordion.Root>
      </Slider.Body>
      <Slider.Footer>
        <Button block size="small" customType="primary" onClick={onEditClick}>
          Edit
        </Button>
      </Slider.Footer>
    </div>
  );
};
