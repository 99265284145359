import { Button } from "components/DesignSystem/Button/Button";
import { EmptyInvoiceSetting } from "components/Illustrations/EmptyInvoiceSetting";
import { InvoiceSettingModal } from "components/InvoiceSettingModal/InvoiceSettingModal";
import { useModal } from "hooks/useModal";

export const SetupInoviceSetting = () => {
  const { isOpen, close, open } = useModal();

  return (
    <>
      <div className="t-flex t-justify-center t-h-full t-w-full t-items-center">
        <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center t-w-[400px]">
          <EmptyInvoiceSetting />

          <p className="t-m-0 t-text-subtitle-sm">Setup your company details</p>
          <p className="t-m-0 t-text-body-sm t-text-center">
            This information will appear on all future invoices. You can update
            your company details in the Invoicing/Settings section.
          </p>

          <Button onClick={open} customType="primary">
            Start setup
          </Button>
        </div>
      </div>
      <InvoiceSettingModal isOpen={isOpen} close={close} />
    </>
  );
};
