import { EmptyChannelList } from "components/EmptyChannelList";
import { SearchIllustration } from "components/Illustrations/SearchIllustration";
import { MessageSearchList } from "components/MessageSearchList/MessageSearchList";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Cross } from "components/icons/Cross";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { expandDashboardSidebar } from "store/slices/dashboard";
import {
  setMessageSearchValue,
  closeMessageSearch,
} from "store/slices/messageSearch";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import { RootState } from "store/store";
import cx from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { Search } from "components/DesignSystem/Search/Search";
import { debounce } from "utils/debouncing";
import Loader from "components/design/loader";
import { EmptySearchMessage } from "components/Illustrations/EmptySearchMessage";

const List = ({
  onSelect,
}: {
  onSelect?: (channelId: string, messageId?: string) => void;
}) => {
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();
  const messageSearchValue = useSelector(
    (state: RootState) => state.messageSearch.value
  );

  const { loading, messages, next, loadNextPage } = usePaginatedMessages(
    client,
    { id: channel?.id },
    { created_at: -1 },
    { limit: 15 },
    messageSearchValue
  );

  if (loading) {
    <div>
      <Loader />
    </div>;
  }

  if (!messageSearchValue) {
    return (
      <div className="t-h-full t-flex t-justify-center t-items-center t-flex-col">
        <div>
          <SearchIllustration />
        </div>
        <span className="t-text-body t-text-center t-w-11/12 t-text-neutral-40">
          Search for messages within {channel?.data?.name}
        </span>
      </div>
    );
  }

  if (messages.length === 0) {
    return (
      <div className="t-h-full t-flex t-justify-center t-items-center t-flex-col">
        <div>
          <EmptySearchMessage />
        </div>
        <span>No result found</span>
      </div>
    );
  }

  return (
    <MessageSearchList
      onSelect={onSelect}
      windowElementId="messageSearchContent"
      searchTerm={messageSearchValue || ""}
      messages={messages}
      loadNextPage={loadNextPage}
      hasNextPage={Boolean(next)}
    />
  );
};

export const ChannelMessageSearch = ({
  onSelect,
}: {
  onSelect?: (channelId: string, messageId?: string) => void;
}) => {
  const isMessageSearchActive = useSelector(
    (state: RootState) => state.messageSearch.isActive
  );
  const expandSidebarOnMessageSearchClose = useSelector(
    (state: RootState) => state.messageSearch.expandSidebarOnClose
  );

  const dispatch = useDispatch();

  const onMessageSearchChange = debounce(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setMessageSearchValue(event.target.value));
    }
  );

  const onMessageSearchClose = () => {
    dispatch(closeMessageSearch());
    if (expandSidebarOnMessageSearchClose) {
      dispatch(expandDashboardSidebar());
    }
  };

  return (
    <DashboardContainer
      className={cx(
        "t-transition-all t-grow t-shrink-0 t-bg-surface  t-overflow-x-hidden t-z-10",
        {
          "!t-w-[360px] t-border-0 t-border-l t-border-solid t-border-neutral-10":
            isMessageSearchActive,
          "!t-w-0": !isMessageSearchActive,
        }
      )}
    >
      {isMessageSearchActive && (
        <>
          <DashboardContainer.Header>
            <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center">
              <p className="t-text-subtitle t-m-0">Search messages</p>
              <Button
                customType="ghost_icon"
                size="small"
                onClick={onMessageSearchClose}
              >
                <Cross />
              </Button>
            </div>

            <div className="t-px-5 t-pt-2 t-pb-3">
              <Search
                autoFocus
                block
                onChange={onMessageSearchChange}
                placeholder="Search here"
                customSize="regular"
              />
            </div>
          </DashboardContainer.Header>
          <DashboardContainer.Content id="messageSearchContent">
            <List onSelect={onSelect} />
          </DashboardContainer.Content>
        </>
      )}
    </DashboardContainer>
  );
};
