export const Perks = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.70005 5.7V7.3H13.3V5.7H2.70005ZM1.30005 5.5C1.30005 4.83726 1.83731 4.3 2.50005 4.3H13.5C14.1628 4.3 14.7 4.83726 14.7 5.5V7.5C14.7 8.16274 14.1628 8.7 13.5 8.7H2.50005C1.83731 8.7 1.30005 8.16274 1.30005 7.5V5.5Z"
        fill="#706A85"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.00005 7.3C3.38665 7.3 3.70005 7.6134 3.70005 8V12.3H12.3V8C12.3 7.6134 12.6134 7.3 13 7.3C13.3866 7.3 13.7 7.6134 13.7 8V12.5C13.7 12.8183 13.5736 13.1235 13.3486 13.3485C13.1235 13.5736 12.8183 13.7 12.5 13.7H3.50005C3.18179 13.7 2.87657 13.5736 2.65152 13.3485C2.42648 13.1235 2.30005 12.8183 2.30005 12.5V8C2.30005 7.6134 2.61345 7.3 3.00005 7.3Z"
        fill="#706A85"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00005 4.3C8.38665 4.3 8.70005 4.6134 8.70005 5V13C8.70005 13.3866 8.38665 13.7 8.00005 13.7C7.61345 13.7 7.30005 13.3866 7.30005 13V5C7.30005 4.6134 7.61345 4.3 8.00005 4.3Z"
        fill="#706A85"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5544 2.4456C10.2147 2.10584 9.70348 2.14068 9.46234 2.41387L9.46121 2.41514C9.12094 2.79875 8.91562 3.40707 8.80544 3.99961C8.79045 4.08025 8.77757 4.1586 8.76651 4.23367C8.84162 4.22263 8.92001 4.20977 9.00069 4.1948C9.59332 4.08483 10.2017 3.87989 10.5853 3.54037M8.00005 5C7.30005 5 7.30005 4.99983 7.30005 4.99966L7.30005 4.99835L7.30006 4.99595L7.3001 4.98899L7.30036 4.96658C7.30065 4.94797 7.3012 4.92209 7.30225 4.88967C7.30434 4.82489 7.30845 4.73365 7.31651 4.62186C7.33256 4.39912 7.36459 4.09027 7.42903 3.74368C7.55319 3.07595 7.8165 2.15995 8.41333 1.48673C9.26352 0.524368 10.7168 0.628101 11.5444 1.45565C12.3727 2.28395 12.4749 3.7369 11.5136 4.58838C10.8403 5.18441 9.9239 5.44738 9.25613 5.5713C8.90959 5.63561 8.60079 5.66757 8.37809 5.68358C8.26632 5.69162 8.1751 5.69572 8.11034 5.69781C8.07793 5.69886 8.05205 5.69941 8.03345 5.69969L8.01105 5.69995L8.00409 5.69999L8.0017 5.7L8.00039 5.7C8.00022 5.7 8.00005 5.7 8.00005 5ZM8.00005 5L8.00039 5.7C7.81474 5.7 7.63635 5.62625 7.50507 5.49498C7.3738 5.3637 7.30005 5.18531 7.30005 4.99966L8.00005 5ZM10.5544 2.4456C10.8936 2.78475 10.8601 3.2968 10.5853 3.54037L10.5544 2.4456Z"
        fill="#706A85"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.45576 1.45565C5.28331 0.628096 6.73665 0.524366 7.58684 1.48675C8.18366 2.15996 8.44697 3.07596 8.57112 3.74368C8.63557 4.09027 8.6676 4.39912 8.68365 4.62186C8.6917 4.73365 8.69581 4.82489 8.69791 4.88967C8.69896 4.92209 8.69951 4.94797 8.69979 4.96658L8.70006 4.98899L8.7001 4.99595L8.7001 4.99835L8.70011 4.99927V4.99966C8.70011 4.99983 8.70011 5 8.00011 5C8.00011 5.7 7.99994 5.7 7.99976 5.7H7.99937L7.99845 5.7L7.99606 5.69999L7.9891 5.69995L7.96671 5.69969C7.9481 5.69941 7.92223 5.69886 7.88982 5.69781C7.82505 5.69572 7.73384 5.69162 7.62206 5.68358C7.39936 5.66757 7.09056 5.63561 6.74403 5.5713C6.07626 5.44738 5.16001 5.18454 4.48674 4.5885L4.4866 4.58838C3.52528 3.7369 3.62746 2.28395 4.45576 1.45565ZM8.00011 5V5.7C8.18576 5.7 8.3638 5.62625 8.49508 5.49498C8.62636 5.3637 8.70011 5.18565 8.70011 5H8.00011ZM7.23365 4.23367C7.22259 4.1586 7.20971 4.08025 7.19471 3.99961C7.08454 3.40707 6.87922 2.79875 6.53894 2.41514L6.53781 2.41387L6.53781 2.41387C6.29667 2.14068 5.78547 2.10584 5.44571 2.4456C5.10656 2.78475 5.13992 3.29668 5.41473 3.54025M7.23365 4.23367C7.15854 4.22263 7.08015 4.20977 6.99947 4.1948C6.40684 4.08483 5.79847 3.87989 5.41486 3.54037"
        fill="#706A85"
      />
    </svg>
  );
};
