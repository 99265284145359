export const Smiley = ({ width = "21", height = "20" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Smiley">
      <path
        id="Vector"
        d="M10.3397 17.5C14.5078 17.5 17.8868 14.1421 17.8868 10C17.8868 5.85786 14.5078 2.5 10.3397 2.5C6.17146 2.5 2.79248 5.85786 2.79248 10C2.79248 14.1421 6.17146 17.5 10.3397 17.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M7.50956 9.375C8.03058 9.375 8.45295 8.95527 8.45295 8.4375C8.45295 7.91973 8.03058 7.5 7.50956 7.5C6.98853 7.5 6.56616 7.91973 6.56616 8.4375C6.56616 8.95527 6.98853 9.375 7.50956 9.375Z"
        fill="currentColor"
      />
      <path
        id="Vector_3"
        d="M13.17 9.375C13.691 9.375 14.1134 8.95527 14.1134 8.4375C14.1134 7.91973 13.691 7.5 13.17 7.5C12.6489 7.5 12.2266 7.91973 12.2266 8.4375C12.2266 8.95527 12.6489 9.375 13.17 9.375Z"
        fill="currentColor"
      />
      <path
        id="Vector_4"
        d="M13.6102 11.875C13.2777 12.4442 12.8007 12.9167 12.2267 13.2451C11.6528 13.5735 11.0021 13.7464 10.3398 13.7464C9.67748 13.7464 9.0268 13.5735 8.45285 13.2451C7.8789 12.9167 7.40181 12.4442 7.06934 11.875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
