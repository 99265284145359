import { ADDING_PERK } from "constants/perks";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { addPerk } from "../../../apis/perksApis";
import FilledCaretLeft from "../../../static/images/FilledLeftCaret.svg";
import { Button } from "../../design/button";
import PerkTypeRedemption from "./perkTypeRedemption";

const RedemptionInformation = ({ goBack, setCurrentState, perkData }) => {
  const { perkType } = perkData;
  const { alertToast, successToast } = useToast();

  const showData = async (values) => {
    try {
      let data = { ...perkData, ...values };
      successToast({ message: ADDING_PERK });
      const res = await addPerk({ data });
      if (res.status === 200) setCurrentState(3);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  return (
    <>
      <span className="perkRightContainerHeader">Redemption Information</span>
      <Formik
        initialValues={{
          couponCode: "",
          redemptionUrl: "",
          utmLink: "",
          referralEmail: "",
          introEmail: "",
        }}
        onSubmit={showData}
      >
        <Form className="w-100 newPerkForm">
          <PerkTypeRedemption perkType={perkType} />
          <div className="d-flex justify-content-between w-100 perkBtns">
            <Button size="medium" color="cancel" onClick={goBack} type="button">
              Cancel
            </Button>
            <span className="redemptionSubmit">
              <Button size="medium" color="transparent">
                <img
                  src={FilledCaretLeft}
                  alt="FilledCaretLeft"
                  onClick={() => setCurrentState(1)}
                />
              </Button>
              &nbsp;
              <Button type="submit" color="purple" size="medium">
                Submit
              </Button>
            </span>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default RedemptionInformation;
