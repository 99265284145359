/* eslint-disable no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import {
  ACCEPT,
  FOREIGN_ENTITY_ACCOUNTANT,
  ARCHIVED,
  INVITED,
  JOINED,
  REJECT,
  REQUESTED,
  ROLE_NOT_DECIDED,
} from "constants/groupRequest";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useMemo, useState } from "react";
import "react-table";
import GreenTick from "static/images/SquareGreenTick.svg";
import Cross from "static/images/SquareX.svg";
import {
  useGetGroupRequestQuery,
  useGetTeamMembersQuery,
  useGetTeamSettingTagsQuery,
  useGroupJoinActionMutation,
  useInviteMutation,
} from "store/apis/teamSetting";
import { getGroupRequests } from "../../apis/domainRecognization";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/addTeamMember.css";
import { AddOrEditTeamMemberModal } from "./AddEditTeamMemberModal";
import ArchiveMember, { TeamMemberOptions } from "./TeamMemberOptions";
import ServiceProvider from "./serviceProvider";
import Table from "components/DesignSystem/Table/V2/Table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { pluralize } from "utils/pluralize";
import SeatPlanManage from "components/billing/SeatPlanManage";
import { useModal } from "hooks/useModal";
import { Header } from "components/DesignSystem/Header/Header";
import AddUserIcon from "../../static/images/UserPlus.svg";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { InviteUserManagePlan } from "./InviteUserManagePlan";
import { TeamMemberSeatBanner } from "./TeamMemberSeatBanner";
import { RejectRequest } from "./RejectRequest";
import Tags from "components/crm/teamMemberTags";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { timezones } from "data/timezones";
import PhoneIcon from "../../static/images/PhoneIcon.svg";
import Whatsapp from "../../static/images/WhatsappIcon.svg";
import { BackendError } from "types/utils/error";
import { DashboardLayout } from "components/DashboardLayout";
import { useMakePhoneCallFromCRMMutation } from "store/apis/chat";
import {
  TEAM_INVITE_STATUS,
  TEAM_SETTINGS_INVITE_STATUS,
  TEAM_SETTINGS_INVITE_STATUS_COLOR,
} from "dictionaries";

dayjs.extend(timezone);
dayjs.extend(utc);

const CallActions = ({ mobile, voipCallAllow, phoneCall, groupUserId }) => {
  return (
    <div className="t-flex t-gap-2 t-items-center">
      <a target="_blank" rel="noreferrer" href={`https://wa.me/${mobile}`}>
        <img
          src={Whatsapp}
          alt="icon"
          height="16px"
          width="16px"
          className="-t-mt-1"
        />
      </a>
      {voipCallAllow && (
        <button
          onClick={() => phoneCall(mobile, groupUserId)}
          className="button-reset"
        >
          <img
            src={PhoneIcon}
            alt="PhoneIcon"
            className="t-w-[18px] t-h-[18px] -t-mt-1"
          />
        </button>
      )}
    </div>
  );
};

const ContactRow = ({ phone, label }) => (
  <div>
    {label && phone && (
      <span className="d-block team-contact-number-label t-text-body-sm t-text-text-30">
        {label}
      </span>
    )}
    <div className="t-flex t-gap-2 t-items-center">
      <span className="team-contact-number-actual-number">{phone}</span>
    </div>
  </div>
);

const Time = ({ timezone }) => {
  const tz = timezones.find((t) => t.value === timezone);
  const tzName = tz?.utc?.[0];
  let time;
  if (!tzName) {
    time = dayjs().format("hh:mm A");
  }

  time = dayjs().tz(tzName).format("hh:mm A");
  return (
    <span>
      {time} ({tz?.abbr})
    </span>
  );
};

const AddTeamMember = () => {
  usePageTitle("Team");
  const { alertToast, successToast } = useToast();
  const { groupEntities, authtoken } = useContext(authContext);
  const { roles } = useCurrentGroupContext();
  const { isGroupAdmin, isCustomer, isCpa } = useRoleBasedView();
  const [btnClicked, setBtnClicked] = useState("");
  const [groupName, setGroupName] = useState("");
  const [tasks, setTasks] = useState([]);
  const [voipCallAllow, setVoipCallAllow] = useState(false);
  const groupRoles = isCustomer ? roles?.[0] : roles;
  const [makeCall] = useMakePhoneCallFromCRMMutation();

  const { uuid: groupId } = useCurrentGroupContext();

  const {
    data: teamMembers = {
      active_group_users_count: 0,
      available_seats: 0,
      extra_seat_amount: 0,
      free_seats: 0,
    },
    isLoading: teamMemberLoading,
  } = useGetTeamMembersQuery({ groupId }, { skip: !groupId });
  const { data: requestedTeamMembers = [], isLoading: groupRequestLoading } =
    useGetGroupRequestQuery({ groupId }, { skip: !groupId });
  const [handleGroupJoinRequest, { isLoading: groupRequestActionLoading }] =
    useGroupJoinActionMutation();

  const { data: tags = [], isLoading, refetch } = useGetTeamSettingTagsQuery();

  const [inviteUser, { isLoading: isInviting }] = useInviteMutation();

  const multipleOwnerOfGroupExist =
    teamMembers?.group_users?.filter(
      ({ is_super_admin }) => is_super_admin === true
    ).length > 1;
  const {
    open: openSeatManageModal,
    close: closeSeatManageModal,
    isOpen: showSeatManageModal,
  } = useModal();
  const {
    open: openViewRequestModal,
    close: closeViewRequestModal,
    isOpen: showViewRequestModal,
  } = useModal();
  const {
    open: openAddMemberModal,
    close: closeAddMemberModal,
    isOpen: showAddMemberModal,
  } = useModal();
  const {
    open: openInviteUserSeatManageModal,
    close: closeInviteUserSeatManageModal,
    isOpen: showInviteUserSeatManageModal,
  } = useModal();
  const {
    open: openRejectRequestModal,
    close: closeRejectRequestModal,
    isOpen: showRejectRequestModal,
  } = useModal();
  const [editUser, setEditUser] = useState();
  const [requestedMemberRole, setRequestedMemberRole] = useState();
  const [requestRejectId, setRequestRejectId] = useState("");

  useEffect(() => {
    const teamMembersData = teamMembers?.group_users || [];
    const updatedTeamMembers =
      requestedTeamMembers.map((data) => ({
        ...data,
        email: data.profile,
        status: REQUESTED,
        role_name: ROLE_NOT_DECIDED,
      })) || [];
    setTasks([...updatedTeamMembers, ...teamMembersData]);
  }, [teamMembers, requestedTeamMembers]);

  useEffect(() => {
    setVoipCallAllow(authtoken?.is_voip_allowed);
  }, [authtoken]);

  const {
    active_group_users_count: activeGroupUsersCount = 0,
    available_seats: availableSeats = 0,
  } = teamMembers || {};

  useEffect(() => {
    if (groupEntities.data?.groups?.length > 0) {
      setGroupName(groupEntities.data.groups[0].name);
      getAllGroupRequest(groupEntities.data.groups[0].uuid);
    }
  }, [groupEntities]);

  const openViewRequest = (user) => {
    setEditUser(user);
    openViewRequestModal();
  };

  const closeViewRequest = (user) => {
    setEditUser(user);
    closeViewRequestModal();
  };

  const getAllGroupRequest = async (id) => {
    try {
      await getGroupRequests({ id: id });
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const phoneCall = async (mobile, groupUserId) => {
    try {
      await makeCall({ mobile, groupUserId });
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const openRejectRequest = (requestId) => {
    setRequestRejectId(requestId);
    openRejectRequestModal();
  };

  const closeRejectRequest = () => {
    setRequestRejectId();
    closeRejectRequestModal();
  };

  const updateArchiveToast = (toastMessage) => {
    successToast({ message: toastMessage });
  };

  const closeSeatManage = () => {
    closeSeatManageModal();
    closeInviteUserSeatManageModal();
    if (editUser?.email) {
      openViewRequestModal();
    } else {
      openAddMemberModal();
    }
  };

  const inviteOrResend = async (profileId) => {
    try {
      await inviteUser({ groupId, profileId }).unwrap();
    } catch (error) {
      alertToast({
        message: error?.data?.error?.message,
      });
    }
  };

  const columnHelper = createColumnHelper();

  const TagsColumn = isCpa
    ? [
        columnHelper.display({
          id: "tags",
          header: "Tags",
          cell: ({
            row: {
              original: {
                is_archived_user,
                crm_invite_status,
                uuid,
                tags: currentTags,
                role_name,
              },
            },
          }) => {
            const hideTags =
              role_name === "Foreign Entity Accountant" ||
              Boolean(is_archived_user) ||
              crm_invite_status === "Not Joined" ||
              crm_invite_status === INVITED;

            if (hideTags) {
              return null;
            }

            return (
              <Tags
                uuid={uuid}
                currentTags={currentTags}
                tags={tags}
                refetch={refetch}
              />
            );
          },
        }),
      ]
    : [];

  const TimeZoneColumn = isCpa
    ? [
        columnHelper.accessor("current_timezone", {
          id: "time",
          header: "Current time",
          cell: ({ row }) => {
            const { current_timezone } = row.original;
            return <Time timezone={current_timezone} />;
          },
        }),
      ]
    : [];

  const ActionsColumn = isCpa
    ? [
        columnHelper.display({
          id: "contact",
          header: "Actions",
          size: 2,
          cell: ({
            row: {
              original: {
                mobile,
                secondary_mobile,
                status,
                is_archived_user,
                profile_id,
                crm_invite_status,
                uuid,
              },
            },
          }) => {
            if (is_archived_user) {
              return null;
            }

            switch (crm_invite_status) {
              case "Not Joined":
                return (
                  <Button
                    size="small"
                    onClick={() => inviteOrResend(profile_id)}
                    isLoading={isInviting}
                    disabled={isInviting}
                  >
                    Invite
                  </Button>
                );
              case INVITED:
                return (
                  <Button
                    size="small"
                    onClick={() => inviteOrResend(profile_id)}
                  >
                    Resend
                  </Button>
                );

              default:
                return (
                  <div className="t-flex t-flex-col t-gap-4">
                    {mobile && (
                      <CallActions
                        mobile={mobile}
                        voipCallAllow={voipCallAllow}
                        phoneCall={phoneCall}
                        groupUserId={uuid}
                      />
                    )}
                    {secondary_mobile && (
                      <CallActions
                        mobile={secondary_mobile}
                        voipCallAllow={voipCallAllow}
                        phoneCall={phoneCall}
                        groupUserId={uuid}
                      />
                    )}
                  </div>
                );
            }
          },
        }),
      ]
    : [];

  const MobileColumn = isCpa
    ? [
        columnHelper.display({
          id: "mobile",
          header: "Mobile",
          cell: ({
            row: {
              original: { mobile, secondary_mobile },
            },
          }) => {
            return (
              <>
                {mobile || secondary_mobile ? (
                  <>
                    {
                      <ContactRow
                        phone={mobile}
                        label={secondary_mobile ? "Primary" : ""}
                      />
                    }
                    {secondary_mobile && (
                      <ContactRow phone={secondary_mobile} label="Secondary" />
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            );
          },
        }),
      ]
    : [];

  const teamMemberOption =
    isGroupAdmin || isCpa
      ? [
          columnHelper.display({
            id: "action",
            header: "",
            cell: (info) => {
              const {
                profile_id,
                status,
                email,
                is_archived_user,
                is_super_admin,
              } = info.row.original;

              return status === REQUESTED ? (
                <></>
              ) : (
                <TeamMemberOptions
                  profileId={profile_id}
                  isArchived={is_archived_user}
                  updateArchiveToast={updateArchiveToast}
                  isSuperAdmin={is_super_admin}
                  ownAccount={email === authtoken.email}
                  multipleOwnerOfGroupExist={multipleOwnerOfGroupExist}
                  user={info.row.original}
                  options={groupRoles}
                  groupName={groupName}
                  groupId={groupId}
                  availableSeats={availableSeats}
                  openInviteUserSeatManageModal={openInviteUserSeatManageModal}
                  seatData={teamMembers}
                />
              );
            },
            size: 2,
          }),
        ]
      : [];

  const columns = [
    columnHelper.accessor("email", {
      cell: (info) => {
        const { email, name, profile_url } = info.row.original;
        const userInitialLetter =
          name?.[0]?.toUpperCase() || email?.[0]?.toUpperCase();
        return (
          <div className="t-flex t-items-center t-gap-2">
            <div className="t-h-8 t-w-8 t-flex t-items-center t-justify-center t-rounded-full t-bg-purple-40">
              {profile_url ? (
                <img
                  src={profile_url}
                  className="t-h-8 t-w-8 t-rounded-full"
                  alt=""
                />
              ) : (
                <div className="t-text-subtitle-sm t-text-surface">
                  {userInitialLetter}
                </div>
              )}
            </div>
            <div>
              <div className="t-text-body t-text-text-100">{name || "-"}</div>
              <div className="t-text-body-sm t-text-text-30">{email}</div>
            </div>
          </div>
        );
      },
      header: "user",
      size: 28,
    }),
    columnHelper.accessor("role_name", {
      header: "Role",
      size: isCpa ? 10 : 20,
      cell: ({
        row: {
          original: { role_name, uuid },
        },
      }) => {
        return role_name === ROLE_NOT_DECIDED ? (
          "-"
        ) : (
          <div>
            <div className="t-text-body t-text-text-100">{role_name}</div>
            {role_name === FOREIGN_ENTITY_ACCOUNTANT && (
              <div className="t-text-body-sm t-text-text-30 ">Free seat</div>
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor("status", {
      header: "Status",
      size: isCpa ? 10 : 25,
      cell: ({
        row: {
          original: { crm_invite_status },
        },
      }) => {
        return (
          <Tag
            tagType={TEAM_SETTINGS_INVITE_STATUS_COLOR[crm_invite_status]}
            icon={false}
          >
            {TEAM_SETTINGS_INVITE_STATUS[crm_invite_status]}
          </Tag>
        );
      },
    }),
    columnHelper.accessor("uuid", {
      header: "Access",
      size: 25,
      cell: ({ row }) => {
        const {
          task_access_count,
          status,
          is_archived_user,
          uuid,
          company_group_id,
          profile,
        } = row.original;

        if (status === REQUESTED) {
          return (
            <div className="t-flex t-gap-2">
              <Button
                customType="danger-outlined"
                disabled={groupRequestActionLoading}
                size="small"
                isLoading={
                  groupRequestActionLoading && btnClicked === `${uuid}reject`
                }
                onClick={() => openRejectRequest(uuid)}
              >
                Reject
              </Button>
              <Button
                customType="success"
                size="small"
                onClick={() => openViewRequest(row.original)}
              >
                View request
              </Button>
            </div>
          );
        }

        let accessText = "Full access";
        if (task_access_count) {
          accessText = pluralize(task_access_count, "filing", "filings");
        }
        if (is_archived_user) {
          accessText = "No access";
        }
        if (isCpa) {
          accessText = accessText.replace("access", "");
        }

        return (
          <div
            className={classNames("t-text-body t-text-text-100", {
              "t-opacity-50": is_archived_user,
            })}
          >
            {accessText}
          </div>
        );
      },
    }),
    ...TagsColumn,
    ...TimeZoneColumn,
    ...MobileColumn,
    ...ActionsColumn,
    ...teamMemberOption,
  ];
  const data = useMemo(() => tasks, [tasks]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  if (teamMemberLoading || groupRequestLoading || !groupId) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Team Settings"
          right={
            <div className="t-flex t-items-center t-gap-2">
              <Button
                customType="primary"
                size="small"
                onClick={openAddMemberModal}
              >
                <img src={AddUserIcon} alt="AddUserIcon" /> &nbsp; Invite
              </Button>
            </div>
          }
        />
      }
    >
      <div className="form t-pb-16">
        <TeamMemberSeatBanner
          availableSeats={availableSeats}
          seatData={teamMembers}
        />

        <div>
          <Table.Container size="regular">
            <Table.Content>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        style={{ width: `${header.getSize()}%` }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row
                    key={row.id}
                    style={{
                      opacity: row.original.is_archived_user ? "0.5" : "1",
                    }}
                    className={classNames({
                      "t-bg-i-surface-grey": row.original.is_archived_user,
                    })}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Table.Cell
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </div>
      </div>
      {showAddMemberModal && (
        <AddOrEditTeamMemberModal
          show={showAddMemberModal}
          closeModal={closeAddMemberModal}
          options={groupRoles}
          groupName={groupName}
          groupId={groupId}
          availableSeats={availableSeats}
          openInviteUserSeatManageModal={openInviteUserSeatManageModal}
          userInviteFlow
          openSeatManageModal={openSeatManageModal}
        />
      )}
      {showViewRequestModal && (
        <AddOrEditTeamMemberModal
          show={showViewRequestModal}
          closeModal={closeViewRequest}
          options={groupRoles}
          groupName={groupName}
          groupId={groupId}
          availableSeats={availableSeats}
          user={editUser}
          isRequestAcceptFlow
          openInviteUserSeatManageModal={openInviteUserSeatManageModal}
          openSeatManageModal={openSeatManageModal}
          setRequestedMemberRole={setRequestedMemberRole}
          requestedMemberRole={requestedMemberRole}
        />
      )}
      <SeatPlanManage
        show={showSeatManageModal}
        closeModal={closeSeatManageModal}
        seatData={teamMembers}
        handlePlanManageSuccess={closeSeatManage}
      />
      <InviteUserManagePlan
        show={showInviteUserSeatManageModal}
        closeModal={closeInviteUserSeatManageModal}
        openManageSeatModal={openSeatManageModal}
        activeGroupUsersCount={activeGroupUsersCount}
        availableSeats={availableSeats}
      />
      <RejectRequest
        show={showRejectRequestModal}
        closeModal={closeRejectRequest}
        groupId={groupId}
        requestId={requestRejectId}
      />
    </DashboardLayout>
  );
};

export default AddTeamMember;
