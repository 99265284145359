import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import { DeleteIcon } from "components/icons/delete";
import { investorSchema } from "formValidations/investorCRM";
import {
  FieldArray,
  Form,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import {
  useEditInvestorDetailMutation,
  useInvestorDetailQuery,
} from "store/apis/raiseProfiles";
import {
  VentureCapitalist,
  useCreateVCMutation,
  useEditVCMutation,
  useGetVCDataQuery,
} from "store/apis/vcCrm";
import { setInvestorEditable } from "store/slices/investor";
import { setEditable } from "store/slices/raiseCrm";
import { BackendError } from "types/utils/error";

const AddContact = ({
  remove,
  index,
  name,
  email,
  linkedIn_url,
}: {
  remove: any;
  index: number;
  name: string;
  email: string;
  linkedIn_url: string;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-flex t-justify-between t-items-center">
        <div className="t-text-subtext-sm">
          Contact {index > 0 && <>{index + 1}</>}
        </div>
        {index > 0 && (
          <Button customType="ghost_icon" onClick={() => remove(index)}>
            <DeleteIcon color="black" />
          </Button>
        )}
      </div>
      <div className="t-flex t-flex-col t-gap-5">
        <TextInput label="Contact name" name={name} />
        <TextInput label="Contact email" name={email} />
        <TextInput label="Contact LinkedIn" name={linkedIn_url} />
      </div>
    </div>
  );
};

export const InvestorEdit = ({
  investorId,
  children,
}: {
  investorId: string;
  children?: (investorData?: VentureCapitalist) => ReactNode;
}) => {
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();
  const { isCustomer, isInvestor } = useRoleBasedView();
  let investorData: VentureCapitalist | undefined;

  const { data: vcData, isLoading } = useGetVCDataQuery(
    { vcId: investorId },
    { skip: !investorId }
  );

  const { data: vcProfile } = useInvestorDetailQuery({}, { skip: !isInvestor });

  const [updateVCProfile, { isLoading: isVcProfileUpdating }] =
    useEditInvestorDetailMutation();

  investorData = isInvestor ? vcProfile : vcData;

  const [createVC, { isLoading: isCreatingVC }] = useCreateVCMutation();
  const [editVC, { isLoading: isEditing }] = useEditVCMutation();

  const onCreate = async (values: VentureCapitalist) => {
    const payload = { ...values };
    try {
      await createVC({ payload }).unwrap();
      successToast({ message: "Investor created" });
      dispatch(setEditable(false));
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onSave = async (values: VentureCapitalist) => {
    try {
      if (isInvestor) {
        const payload = { ...values, vc_id: values?.uuid };
        await updateVCProfile({ payload }).unwrap();
        dispatch(setInvestorEditable(false));
      } else {
        const payload = { ...values };
        const vcId = values.uuid;
        await editVC({ vcId, payload }).unwrap();
        dispatch(setEditable(false));
      }

      successToast({ message: "Investor updated" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const initialValues = {
    uuid: investorData?.uuid || "",
    name: investorData?.name || "",
    website: investorData?.website || "",
    notes: investorData?.notes || "",
    vc_type: investorData?.vc_type || "",
    min_cheque: investorData?.min_cheque || "",
    location: investorData?.location || "",
    contacts:
      investorData?.contacts && investorData?.contacts?.length > 0
        ? investorData?.contacts
        : [
            {
              name: "",
              email: "",
              linkedIn_url: "",
            },
          ],
  };

  const contactData = { name: "", email: "", linkedin: "" };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {children ? children(investorData) : <></>}
      <Formik
        onSubmit={(values) =>
          values?.uuid ? onSave(values) : onCreate(values)
        }
        enableReinitialize={true}
        validationSchema={investorSchema}
        initialValues={initialValues}
        validateOnMount={false}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ isSubmitting, values, submitForm }) => (
          <div className="t-flex t-flex-col t-justify-between t-h-full">
            <div className="t-border t-border-solid t-border-neutral-10 t-flex t-flex-col t-gap-4 t-rounded-lg t-my-5 t-mx-4 t-p-4 ">
              <div className="t-text-subtitle">Fund details</div>
              <Divider />
              <div className="t-flex t-flex-col t-gap-6">
                <TextInput name="name" label="Name of fund" />
                <FieldArray name="contacts">
                  {({ push, remove }) => {
                    return (
                      <div className="t-flex t-flex-col t-gap-5">
                        {values?.contacts?.map((_, index) => (
                          <>
                            <AddContact
                              remove={remove}
                              index={index}
                              name={`contacts.${index}.name`}
                              email={`contacts.${index}.email`}
                              linkedIn_url={`contacts.${index}.linkedIn_url`}
                            />
                          </>
                        ))}
                        <div className="t-mr-auto">
                          <Button
                            size="small"
                            customType="secondary"
                            type="button"
                            onClick={() => {
                              push(contactData);
                            }}
                          >
                            Another another contact
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>
                <TextInput name="vc_type" label="Type" />
                <TextInput name="website" label="Website" />
                <TextInput name="notes" label="Thesis" />
                <TextInput name="location" label="Location" />
                <TextInput name="min_cheque" label="Min cheque size" />
              </div>
            </div>
            <div className="t-px-5 t-py-3 t-flex t-gap-2 t-sticky t-bottom-0 t-bg-white">
              <Button
                customType="primary"
                block
                isLoading={
                  isCreatingVC ||
                  isEditing ||
                  isSubmitting ||
                  isVcProfileUpdating
                }
                disabled={
                  isSubmitting ||
                  isCreatingVC ||
                  isEditing ||
                  isVcProfileUpdating
                }
                onClick={submitForm}
              >
                {values?.uuid ? "Save" : "Create"}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
