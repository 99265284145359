import { useEffect, useState } from "react";

export const useSliderNavigation = <T>({ data }: { data: T[] }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const currentItem = data?.[currentItemIndex];

  const goToNextItem = () => {
    if (currentItemIndex < data?.length - 1) {
      setCurrentItemIndex((prevIndex) => prevIndex + 1);
    }
  };

  const goToPreviousItem = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          goToPreviousItem();
          return;

        case "ArrowRight":
          goToNextItem();
          return;

        default:
          return;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentItemIndex]);

  return {
    currentItem,
    currentItemIndex,
    setCurrentItemIndex,
    goToNextItem,
    goToPreviousItem,
  };
};
