import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import CirclePlus from "components/icons/circlePlus";
import Pencil from "components/icons/pencil";
import { Tag } from "types/Models/FCA";

type TagDropdownProps = {
  currentTags: Tag[];
  tags: Tag[];
  isLoading: boolean;
  currentId: string;
  onAdd: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

export const TagDropdown = ({
  currentTags,
  tags,
  isLoading,
  currentId,
  onAdd,
  onDelete,
}: TagDropdownProps) => {
  const onTagAdd = (e: Event, uuid: string) => {
    e.preventDefault();
    onAdd(uuid);
  };
  const onTagDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    uuid: string
  ) => {
    e.stopPropagation();
    onDelete(uuid);
  };
  return (
    <Dropdown.Root modal={false}>
      <Dropdown.Trigger asChild>
        <div className="t-w-min">
          {currentTags?.length > 0 ? (
            <div className="t-flex t-flex-wrap t-gap-1">
              {currentTags?.map(({ color_hex, bg_color_hex, title, uuid }) => {
                return (
                  <span
                    style={{
                      color: color_hex,
                      backgroundColor: bg_color_hex,
                    }}
                    key={uuid}
                    className="t-rounded-lg t-py-0.5 t-px-1"
                  >
                    #{title}
                  </span>
                );
              })}
              <Pencil />
            </div>
          ) : (
            <CirclePlus />
          )}
        </div>
      </Dropdown.Trigger>

      <Dropdown.Portal>
        <Dropdown.Content sideOffset={4}>
          {tags?.map(({ uuid, title }) => {
            const isSelectedTag = Boolean(
              currentTags?.find(({ uuid: selectedId }) => selectedId === uuid)
            );

            return (
              <Dropdown.Item
                key={uuid}
                disabled={isSelectedTag}
                onSelect={(e) => {
                  onTagAdd(e, uuid);
                }}
                className="t-flex t-items-center t-justify-between t-px-2"
              >
                #{title}
                {isLoading && uuid === currentId && (
                  <i className="fas fa-spinner fa-spin task-assign-icon" />
                )}
                {isSelectedTag && !isLoading && (
                  <Button
                    onClick={(e) => {
                      onTagDelete(e, uuid);
                    }}
                    customType="ghost_icon"
                    size="small"
                    disabled={isLoading}
                  >
                    <i className="far fa-times-circle task-assign-icon" />
                  </Button>
                )}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
