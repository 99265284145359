import React from "react";
import classNames from "classnames";

//css
import "../../static/styles/components/design/toggle.css";

const Toggle = ({
  setToggleValue,
  checked,
  isDisable = false,
  handleClick = (e) => {},
}) => {
  const handleChange = (e) => {
    setToggleValue(e.target.checked);
  };

  return (
    <label className="toggleSwitch" onClick={handleClick}>
      <input
        type="checkbox"
        checked={checked}
        disabled={isDisable}
        onChange={handleChange}
        className="toggleInput"
      />
      <span
        className={classNames("toggleSlider toggleRound", {
          activeSlider: !isDisable,
        })}
      ></span>
    </label>
  );
};

export default Toggle;
