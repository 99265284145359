import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import dayjs from "dayjs";
import { Form, Formik, FormikValues } from "formik";
import { createRNDServiceSchema } from "formValidations/createServiceSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCreateTaskMutation } from "store/apis/getTemplates";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { CreateRandDServiceForm } from "./CreateRandDServiceForm";

export const RandDServiceActionModal = ({
  isOpen,
  close,
  templateData,
}: {
  isOpen: boolean;
  close: () => void;
  templateData: any;
}) => {
  const { successToast, alertToast } = useToast();
  const rndServiceActionType = useSelector(
    (state: RootState) => state.serviceList.rndActionType
  );
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const [createTask, { isLoading: isTaskCreating }] = useCreateTaskMutation();
  const history = useHistory();
  const { isCustomer } = useRoleBasedView();

  const serviceListData = {
    RND: {
      title: "Audit - R&D",
      amount: "TBD",
      baseTaskTemplateId: process.env.REACT_APP_BASE_TASK_TEMPLATE_ID_RND,
    },
  };
  const { title, amount, baseTaskTemplateId } =
    serviceListData[rndServiceActionType];

  const handleSubmit = async (values: FormikValues) => {
    try {
      const { startDate, endDate, entityId } = values || {};
      const task = await createTask({
        entity: entityId,
        metaData: {
          base_task_template_id: baseTaskTemplateId as string,
          from_date: dayjs(startDate).toISOString(),
          to_date: dayjs(endDate).toISOString(),
        },
      }).unwrap();
      successToast({ message: "Service created!" });
      close();
      const redirectUrl = isCustomer
        ? `/credits/services/${task.uuid}?entity=${entityId}`
        : `/tax/filings/${task.uuid}`;

      history.replace(redirectUrl);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            entityId: "",
            season: "",
          }}
          validationSchema={createRNDServiceSchema}
        >
          <Form className="all:unset">
            <Modal.Body className="t-flex t-gap-4 t-flex-col t-w-full">
              <CreateRandDServiceForm
                amount={amount}
                templateData={templateData}
              />
            </Modal.Body>
            <Modal.Footer className="t-justify-end t-flex">
              <Button
                customType="primary"
                isLoading={isTaskCreating}
                disabled={isTaskCreating}
                type="submit"
              >
                Start now
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
