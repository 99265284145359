import { TaskReviewBalancePaymentStepper } from "components/chat/ReviewAndBalancePayment/TaskReviewBalancePaymentStepper";
import { Button } from "components/DesignSystem/Button/Button";
import { DropFile } from "components/DesignSystem/BaseFileInput/BaseFileInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

type UploadFileProps = {
  showModal: boolean;
  closeModal: () => void;
  onFileSelect: (file: FileType[]) => void;
  prevSelectedFiles: FileType[];
  showStepper?: boolean;
  title?: string;
  isLoading?: boolean;
};

export const UploadFile = ({
  showModal,
  closeModal,
  onFileSelect,
  prevSelectedFiles,
  showStepper,
  title,
  isLoading,
}: UploadFileProps) => {
  const [selectedFiles, setSelectedFiles] =
    useState<FileType[]>(prevSelectedFiles);
  const activeChannelGroupId = useSelector(
    (state: RootState) => state.reviewAndBalancePayment.activeChannelGroupId
  );
  const { uuid: groupUuid } = useCurrentGroupContext();
  const groupId = groupUuid || activeChannelGroupId;

  const removeDuplicateFile = (arr: FileType[]) => {
    const seen = new Set();
    return arr.filter((item: FileType) => {
      let identifier;
      if ("uuid" in item) {
        identifier = item.uuid;
      }
      if ("path" in item) {
        identifier = item.path;
      }

      if (!seen.has(identifier)) {
        seen.add(identifier);
        return true;
      }

      return false;
    });
  };

  const onDrop = (newFiles: FileType[]) => {
    setSelectedFiles((prev: FileType[]) =>
      removeDuplicateFile([...prev, ...newFiles])
    );
  };

  const onDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.stopPropagation();
    setSelectedFiles((prevSelectedFiles: FileType[]) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const closeUploadFileModal = () => {
    setSelectedFiles([]);
    closeModal();
  };

  const saveFileForUpload = () => {
    onFileSelect(selectedFiles);
    setSelectedFiles([]);
  };

  return (
    <>
      <Modal.Root open={showModal} onOpenChange={closeModal}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{title || "Update Filing"}</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {showStepper && (
              <div className="t-pb-8">
                <TaskReviewBalancePaymentStepper
                  disable2ndStep={selectedFiles?.length < 1}
                />
              </div>
            )}
            <FileInput
              groupId={groupId}
              multiple
              withInkleDocs
              files={selectedFiles as DropFile[]}
              onDelete={onDelete}
              onDrop={onDrop}
              accept={{
                "image/*": [".png", ".jpeg", ".jpg", ".webp", ".avif"],
                "application/pdf": [".pdf"],
                "application/vnd.ms-excel": [".xls"],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
                "text/csv": [".csv"],
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button type="button" onClick={closeUploadFileModal}>
                Cancel
              </Button>
              <Button
                customType="primary"
                onClick={saveFileForUpload}
                disabled={selectedFiles.length < 1}
                isLoading={isLoading}
              >
                Next
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
