import { number, object, string } from "yup";

export const fixedAssetSchema = object({
  asset_name: string().required("Please add asset name"),
  purchase_price: number().required("Please add purchase price"),
  depreciation_start_date: string().required(
    "Please select depreciation start date"
  ),
  salvage_amount: number().required("Please add salvage value"),
  method: string().required("Please add depreciation method"),
  useful_life: number().required("Please add useful life"),
  frequency: string().required("Please select frequency of depreciation"),
  asset_account_id: string().required("Please select asset account"),
  depreciation_account_id: string().required(
    "Please select depreciation expense account"
  ),
  accumulated_depreciation_account_id: string().required(
    "Please select accumulated depreciation account"
  ),
});
