import * as RadioGroup from "@radix-ui/react-radio-group";
import classNames from "classnames";
import {
  ContentProps,
  RadioGroupItem,
} from "components/DesignSystem/RadioGroup/RadioGroup";

export const OnboardingRadioItem = ({
  children,
  className = "",
  value = "",
  ...props
}: RadioGroup.RadioGroupItemProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <label
      className={classNames(
        "t-flex t-flex-col-reverse t-gap-3 t-items-center t-w-full",
        className
      )}
      htmlFor={value}
    >
      <RadioGroupItem value={value} />
      <div className="t-w-full t-h-full t-text-text-100 t-cursor-pointer t-justify-center t-flex">
        {children}
      </div>
    </label>
  );
};
