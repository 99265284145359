import Async from "components/DesignSystem/AsyncComponents/Async";
import { Search } from "components/DesignSystem/Search/Search";
import GeneralLedgerAccordion from "components/GeneralLedgerAccordion/GeneralLedgerAccordion";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetGeneralLedgerQuery,
  useGetMoneyInTransitQuery,
} from "store/apis/generalLedger";
import { setLedgerFilter } from "store/slices/generalLedger";
import { RootState } from "store/store";
import { debounce } from "utils/debouncing";
import { ExportLedger } from "./ExportLedger";
import { StartBookkeepingDateEmptyScreen } from "./GeneralLedgerEmptyScreen";
import { GeneralLedgerFilters } from "./GeneralLedgerFilters";
import { MoneyInTransitBanner } from "components/MoneyTransitBanner/MoneyInTransitBanner";

export const GeneralLedger = () => {
  usePageTitle("General Ledger");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const query = useQuery();
  const searchTerm = query.get("search_term");

  const {
    filters: {
      cashFlow,
      minAmount,
      maxAmount,
      accountingMethod,
      startDate,
      endDate,
      hideZero,
      categoryIds,
    },
  } = useSelector((state: RootState) => state.ledgerFilter);

  const filterValues = {
    cashFlow,
    minAmount: ((minAmount.value || "") as string)
      ?.replaceAll("$", "")
      .replaceAll(",", ""),
    maxAmount: ((maxAmount.value || "") as string)
      ?.replaceAll("$", "")
      .replaceAll(",", ""),
    accountingMethod,
    startDate,
    endDate,
    hideZero,
    categoryIds,
  };

  const { data: moneyInTransit = [] } = useGetMoneyInTransitQuery(
    {
      groupId,
      entityId,
      startDate: startDate.value,
      endDate: endDate.value,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    data: generalLedgerData,
    isLoading,
    isSuccess,
  } = useGetGeneralLedgerQuery(
    {
      groupId,
      entityId,
      ...filterValues,
      searchTerm,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search_term", value: value || null });
    dispatch(setLedgerFilter({ search_term: value }));
  });

  const isEmpty = !generalLedgerData || generalLedgerData?.length === 0;

  return (
    <div className="t-relative t-gap-5 t-flex t-flex-col t-pb-16">
      <div className="t-sticky t-top-0 t-z-header t-flex t-flex-col t-gap-4">
        {moneyInTransit.length > 0 && (
          <MoneyInTransitBanner transactions={moneyInTransit} />
        )}
        <div className="t-bg-surface t-flex t-flex-col t-gap-4">
          <div className="t-w-1/2">
            <Search block onChange={handleChange} placeholder="Search" />
          </div>
          <div className="t-flex t-justify-between">
            <GeneralLedgerFilters />
            <ExportLedger />
          </div>
        </div>
      </div>
      <div>
        <Async.Root {...{ isLoading: isLoading, isEmpty, isSuccess }}>
          <Async.Empty>
            <StartBookkeepingDateEmptyScreen />
          </Async.Empty>
          <Async.Success>
            <GeneralLedgerAccordion.Root>
              <GeneralLedgerAccordion.Trigger data={generalLedgerData} />
            </GeneralLedgerAccordion.Root>
            <TransactionSlider />
          </Async.Success>
        </Async.Root>
      </div>
    </div>
  );
};
