import { useEffect } from "react";
import { useMessageCount } from "./useMessageCount";

export const usePageTitle = (title: string) => {
  const { messages = 0 } = useMessageCount() || {};
  const formattedCount = messages > 99 ? "99+" : messages;
  const formattedTitle =
    messages > 0 ? `(${formattedCount}) ${title} | Inkle` : `${title} | Inkle`;

  useEffect(() => {
    document.title = formattedTitle;
  }, [formattedTitle]);

  return formattedTitle;
};
