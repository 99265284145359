import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUnreadMessages } from "store/slices/chatUnreadCount";
import { RootState } from "store/store";
import { useChatContext } from "stream-chat-react";

export const useMessageCount = () => {
  const { client } = useChatContext();
  const dispatch = useDispatch();
  const unreadMessageCount = useSelector(
    (state: RootState) => state.chatUnreadCount.unreadMessages
  );

  useEffect(() => {
    if (client) {
      const listener = client.on((e) => {
        if (e.total_unread_count !== undefined) {
          dispatch(setUnreadMessages(e.total_unread_count));
        }
      });

      return () => listener.unsubscribe();
    }
  }, [client]);

  if (!client) {
    return null;
  }

  if (!client.user) {
    return null;
  }

  return {
    messages: unreadMessageCount,
  };
};
