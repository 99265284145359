import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetTransactionDataQuery } from "store/apis/transactions";

export const useGetTransaction = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  return useGetTransactionDataQuery(
    {
      groupId,
      entityId,
      transactionId,
    },
    {
      skip: !groupId || !entityId || !transactionId,
    }
  );
};
