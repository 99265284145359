import { Button } from "components/DesignSystem/Button/Button";
import { EditBillingAddress } from "components/PracticeBilling/MyBilling/EditBillingAddress";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { useServiceTeamId } from "hooks/useServiceTeamId";
import { useHistory } from "react-router-dom";
import MapPinLine from "static/images/MapPinLine.svg";
import WarningCircle from "static/images/WarningCircle.svg";
import { useGetPracticeBillingInfoQuery } from "store/apis/practiceBilling";
import { AddressAutofill } from "types/Models/addressAutofill";
import { PracticeBillingType } from "types/Models/billing";

const PracticePaymentBillingAddress = ({
  defaultAddress,
}: {
  defaultAddress: AddressAutofill;
}) => {
  const serviceTeamId = useServiceTeamId();
  const editBillingAddress = useModal();
  const { service_team } = useAuth();
  const BillingType: PracticeBillingType = service_team?.billing_type;
  const isBillingNotSelected = BillingType === "NOT_SELECTED";
  const history = useHistory();

  const { data: billingInfo } = useGetPracticeBillingInfoQuery(
    {
      serviceTeamId,
    },
    { skip: !serviceTeamId }
  );

  const { invoice_emails = [] } = billingInfo || {};

  const goToBilling = () => {
    history.push("/practice/billing?select_billing_method=PRACTICE_BILLING");
  };

  return (
    <>
      {Boolean(defaultAddress?.autofill_string) ? (
        <div className="t-flex t-justify-between t-border-solid t-border-surface-grey t-bg-surface-lighter-grey t-rounded t-pl-3 t-py-2 t-pr-5 t-border">
          <div className="t-flex t-gap-4 t-items-center t-w-3/5">
            <img src={MapPinLine} alt="MapPinLine" />
            <div className="t-w-full">
              <div className="t-text-subtext t-text-text-100 t-truncate">
                {defaultAddress?.autofill_string}
              </div>
              <div className="t-text-body t-text-neutral t-truncate">
                {defaultAddress?.country}
              </div>
            </div>
          </div>
          <Button onClick={editBillingAddress.open}>Change</Button>
        </div>
      ) : (
        <div className="t-py-2 t-flex t-justify-between t-items-center t-bg-red-10 t-border t-border-solid t-border-red-10 t-px-3 t-rounded">
          <div className="t-flex t-gap-2 t-items-center">
            <img src={WarningCircle} alt="info" />
            <div className="t-text-body t-text-text-100">
              Billing address missing
            </div>
          </div>
          <Button
            size="small"
            onClick={
              isBillingNotSelected ? goToBilling : editBillingAddress.open
            }
          >
            Add
          </Button>
        </div>
      )}
      {editBillingAddress.isOpen && defaultAddress && (
        <EditBillingAddress
          close={editBillingAddress.close}
          isOpen={editBillingAddress.isOpen}
          invoice_emails={invoice_emails}
          autoFillId={defaultAddress.uuid}
          autofillAddress={{
            country: defaultAddress.country,
            street_address: defaultAddress.street_address,
            state: defaultAddress.state,
            city: defaultAddress.city,
            zipcode: defaultAddress.zipcode,
          }}
        />
      )}
    </>
  );
};

export default PracticePaymentBillingAddress;
