export const Address = ({
  address,
}: {
  address: {
    street_address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    uuid: string;
  };
}) => {
  return (
    <>
      <span> {address?.street_address && `${address?.street_address},`}</span>
      <span> {address?.city && `${address?.city},`}</span>
      <span> {address?.state}</span>
      <br />
      <span> {address?.country && `${address?.country},`}</span>
      <span> {address?.zipcode && address?.zipcode}</span>
    </>
  );
};
