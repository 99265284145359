import { number, object, string } from "yup";

export const refundSchema = ({ amount }: { amount: string }) => {
  const amountNumber = Number(amount);
  const maxAmount = amountNumber + 0.01;
  const amountErrorMessage = `Enter refund amount less than or equal to $${amount}`;

  return object({
    refundReason: string().min(1).required("Reason required"),
    refundTo: string().min(1).required("Source required"),
    price: number()
      .moreThan(0, amountErrorMessage)
      .lessThan(maxAmount, amountErrorMessage)
      .required("Invalid price"),
  });
};
