import cx from "classnames";

import {
  COMPLETED,
  IN_PROGRESS,
  PENDING_REVIEW,
} from "constants/bookkeepingMonthlySummaryStatus";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode } from "react";
import CompletedIcon from "static/images/Completed.svg";
import InProgressIcon from "static/images/InProgress.svg";
import InReviewIcon from "static/images/InReview.svg";

const statusColorImage = {
  [IN_PROGRESS]: InProgressIcon,
  [PENDING_REVIEW]: InReviewIcon,
  [COMPLETED]: CompletedIcon,
};

const statusColorMap = {
  /* @tw */
  [IN_PROGRESS]: "t-border-yellow-50 t-bg-yellow-10",
  /* @tw */
  [PENDING_REVIEW]: "t-border-orange-30 t-bg-orange-10",
  /* @tw */
  [COMPLETED]: "t-bg-green-10 t-border-green-50",
};

export const BookkeepingCardContainer = ({
  status,
  children,
}: {
  status: "In Progress" | "Pending Review" | "Completed";
  children: ReactNode;
}) => {
  const { isCustomer } = useRoleBasedView();

  if (isCustomer && status === IN_PROGRESS) {
    return null;
  }

  return (
    <div
      className={cx(
        "t-relative t-h-max t-rounded-lg t-border t-border-solid t-p-6",
        statusColorMap[status]
      )}
    >
      <img
        src={statusColorImage[status]}
        alt={status}
        className="t-absolute t-bottom-0 t-right-0"
      />
      {children}
    </div>
  );
};
