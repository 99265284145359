import classNames from "classnames";
import {
  CANNOT_REQUEST_TO_JOIN_GROUP,
  REQUESTED_TO_JOIN_GROUP,
  REQUESTED_TO_JOIN_GROUP_FAILED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useHistory } from "react-router-dom";
import { useJoinServiceTeamMutation } from "store/apis/serviceTeam";
import { groupJoinRequest } from "../apis/domainRecognization";
import {
  ADMIN_APPROVED,
  INITIATED,
  REJECTED_REQUEST,
} from "../constants/groupRequest";
import { GROUP_REQUEST_STATE_TEXT } from "../dictionaries";
import "../static/styles/components/domainRecognize.css";

const DomainRecognize = ({
  groups,
  setCreateGroup,
  setjoinRequest,
  joinRequest,
}) => {
  const { trackEvent } = useAnalytics();
  const { alertToast, successToast } = useToast();
  const [joinServiceTeamGroup] = useJoinServiceTeamMutation();
  const { isUserTypeForeignCA } = useRoleBasedView();
  const history = useHistory();

  const joinServiceTeamRequest = async (groupId, name) => {
    try {
      await joinServiceTeamGroup({ groupId }).unwrap();
      successToast({ message: `You requested to join group ${name}` });
      history.push("/verify");
    } catch (error) {
      alertToast({ message: error?.data?.error.message });
    }
  };

  const postGroupJoinRequest = (uuid, name) => {
    groupJoinRequest({ body: { group_id: uuid } })
      .then((res) => {
        if (res.status === 200) {
          trackEvent(REQUESTED_TO_JOIN_GROUP, {
            group_name: name,
          });
          successToast({ message: `You requested to join group ${name}` });
          history.push("/verify");
        } else {
          trackEvent(REQUESTED_TO_JOIN_GROUP_FAILED, {
            group_name: name,
          });
          alertToast({
            message: `Something went wrong when joining group ${name}`,
          });
        }
      })
      .catch(() => {
        trackEvent(CANNOT_REQUEST_TO_JOIN_GROUP, {
          group_name: name,
        });
        alertToast({ message: "Something went wrong. Please try again" });
      });
  };

  const handleGroupJoinRequest = (uuid, name) => {
    if (isUserTypeForeignCA) {
      joinServiceTeamRequest(uuid, name);
    } else {
      postGroupJoinRequest(uuid, name);
    }
  };

  const disabledButtonCheck = (status) => {
    return (
      status === REJECTED_REQUEST ||
      status === INITIATED ||
      status === ADMIN_APPROVED
    );
  };

  const classNamesCheck = (status) => {
    return {
      domainRequestedBtn: status === INITIATED || status === ADMIN_APPROVED,
      rejectRequested: status === REJECTED_REQUEST,
    };
  };

  return (
    <div className="domainRecognization -t-ml-4">
      <div className="domainRecognizationContainer">
        <div
          className={classNames("domainCardContainer ", {
            "d-flex justify-content-center": groups?.length === 1,
          })}
        >
          {groups?.map(({ name, uuid, status }) => {
            return (
              <div className="domainGroupCard" key={uuid}>
                <span className="domainGroupName !t-text-subtitle">{name}</span>

                <button
                  disabled={disabledButtonCheck(status)}
                  className={classNames(
                    "all:unset t-box-border t-flex t-cursor-pointer  t-items-center t-justify-center t-rounded t-border t-border-solid t-border-neutral-10 t-bg-white !t-px-8 !t-py-2 t-text-subtitle-sm !t-font-medium t-text-neutral t-drop-shadow-button t-transition-all hover:t-bg-surface-lighter-grey focus:t-bg-neutral-0 disabled:t-bg-white disabled:t-text-neutral-20",
                    classNamesCheck(status)
                  )}
                  onClick={() => handleGroupJoinRequest(uuid, name)}
                >
                  <span>{GROUP_REQUEST_STATE_TEXT[status]}</span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DomainRecognize;
