export const Deadline = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.20005 3.19999V12.8H12.8V3.19999H3.20005ZM1.80005 2.99999C1.80005 2.33725 2.33731 1.79999 3.00005 1.79999H13C13.6628 1.79999 14.2 2.33725 14.2 2.99999V13C14.2 13.6627 13.6628 14.2 13 14.2H3.00005C2.33731 14.2 1.80005 13.6627 1.80005 13V2.99999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 0.799988C11.3866 0.799988 11.7 1.11339 11.7 1.49999V3.49999C11.7 3.88659 11.3866 4.19999 11 4.19999C10.6134 4.19999 10.3 3.88659 10.3 3.49999V1.49999C10.3 1.11339 10.6134 0.799988 11 0.799988Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00005 0.799988C5.38665 0.799988 5.70005 1.11339 5.70005 1.49999V3.49999C5.70005 3.88659 5.38665 4.19999 5.00005 4.19999C4.61345 4.19999 4.30005 3.88659 4.30005 3.49999V1.49999C4.30005 1.11339 4.61345 0.799988 5.00005 0.799988Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.80005 5.49999C1.80005 5.11339 2.11345 4.79999 2.50005 4.79999H13.5C13.8866 4.79999 14.2 5.11339 14.2 5.49999C14.2 5.88659 13.8866 6.19999 13.5 6.19999H2.50005C2.11345 6.19999 1.80005 5.88659 1.80005 5.49999Z"
      fill="currentColor"
    />
  </svg>
);
