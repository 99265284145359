import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { CreateEditMode } from "./CreateEditMode";
import { UploadMode } from "./UploadMode";

export const RequestDocumentModal = () => {
  const isOpen = useSelector(
    (state: RootState) => state.requestDocumentsInChat.isModalOpen
  );

  const requestId = useSelector(
    (state: RootState) => state.requestDocumentsInChat.requestId
  );

  const editing = useSelector(
    (state: RootState) => state.requestDocumentsInChat.editing
  );

  if (!isOpen) {
    return null;
  }

  if (requestId) {
    if (editing) {
      return <CreateEditMode requestId={requestId} />;
    }
    return <UploadMode />;
  }

  return <CreateEditMode />;
};
