export const SmallWhiteTick = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1249_3818)">
      <path
        d="M1.875 6.75L4.5 9.375L10.5 3.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1249_3818">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
