import { TableUI } from "components/design/TableUI";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Table from "components/DesignSystem/Table/V2/Table";
import { RenameIcon } from "components/icons/rename";
import { ICON_DEFAULT_COLOR } from "constants/documents";
import { useDocPreview } from "hooks/useDocPreview";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import React, { useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import ThreeDots from "static/images/ThreeDots.svg";
import { MailroomDetails } from "store/apis/mailroom";
import { FileIcon } from "utils/fileTypeIcon";
import { MailroomSlider, TypeOfMailroom } from "./mailDetails/MailroomSlider";

export const MailRoomData = ({
  mailData,
  openEditMail,
}: {
  mailData: MailroomDetails[];
  openEditMail: (val: MailroomDetails) => void;
}) => {
  const mailRoomData = React.useMemo(() => mailData, [mailData]);
  const { isAdmin } = useRoleBasedView();
  const columnHelper = createColumnHelper<MailroomDetails>();
  const openPreview = useDocPreview();
  const [showMailroomSlider, setShowMailroomSlider] = useState(false);
  const [mailroomId, setMailroomId] = useState("");
  const [mailroomGroupId, setMailroomGroupId] = useState("");

  const openFilePreview = (
    e: React.MouseEvent<HTMLButtonElement>,
    documentId: string
  ) => {
    e.stopPropagation();
    openPreview(documentId);
  };

  const editMail = isAdmin
    ? [
        columnHelper.display({
          size: 5,
          header: " ",
          cell: (info) => {
            const rowData = info.row.original;
            return (
              <span onClick={(e) => e.stopPropagation()}>
                <Dropdown.Root>
                  <Dropdown.Trigger asChild>
                    <div>
                      <Button
                        type="button"
                        customType="ghost_icon"
                        size="small"
                      >
                        <img src={ThreeDots} alt="Three dots" />
                      </Button>
                    </div>
                  </Dropdown.Trigger>
                  <Dropdown.Portal>
                    <Dropdown.Content align="end">
                      <Dropdown.Item onSelect={() => openEditMail(rowData)}>
                        <RenameIcon color={ICON_DEFAULT_COLOR} /> &nbsp; Edit
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown.Portal>
                </Dropdown.Root>
              </span>
            );
          },
        }),
      ]
    : [];

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("received_from", {
        cell: (info) => {
          return (
            <ToolTip text={info.getValue()} side="top" sideOffset={0}>
              <div className="t-truncate t-text-body t-text-text-60 t-max-w-fit">
                {info.getValue()}
              </div>
            </ToolTip>
          );
        },
        header: "Sender",
        size: 30,
      }),
      columnHelper.accessor("type", {
        cell: (info) => (
          <div className="t-w-fit">
            <TypeOfMailroom type={info.getValue()} />
          </div>
        ),
        header: "Type",
        size: isAdmin ? 20 : 25,
      }),
      columnHelper.accessor("date_received", {
        header: "Date Received",
        size: 25,
        cell: (info) => (
          <div className="t-text-body t-text-text-60">{info.getValue()}</div>
        ),
      }),
      columnHelper.accessor("document", {
        cell: (info) => {
          const document = info.getValue();
          return (
            <span>
              {Boolean(document) ? (
                <Button
                  customType="transparent"
                  onClick={(e) => openFilePreview(e, document.uuid)}
                >
                  <FileIcon
                    fileType={document.file_type}
                    width="24px"
                    height="24px"
                  />
                </Button>
              ) : (
                "-"
              )}
            </span>
          );
        },
        header: "ATTACHMENT",
        size: 15,
      }),

      ...editMail,
    ],
    [mailData]
  );

  const openMailData = ({ uuid }: { uuid: string }) => {
    const mailroomGroupId =
      mailData?.find((mail) => uuid === mail.uuid)?.group_id || "";
    setMailroomGroupId(mailroomGroupId);
    setMailroomId(uuid);
    setShowMailroomSlider(true);
  };

  const closeMailroomSlider = () => {
    setMailroomId("");
    setMailroomGroupId("");
    setShowMailroomSlider(false);
  };

  const table = useReactTable({
    data: mailRoomData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <>
      <TableUI table={table}>
        {({ row }) => (
          <Table.Row
            key={row.id}
            onClick={() => openMailData({ uuid: row.original.uuid })}
            className="hover:t-bg-surface-lighter-grey t-cursor-pointer"
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <Table.Cell
                  key={cell.id}
                  style={{
                    width: `${cell.column.getSize()}%`,
                    padding: "12px 8px",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              );
            })}
          </Table.Row>
        )}
      </TableUI>
      {showMailroomSlider && (
        <MailroomSlider
          showMailroomSlider={showMailroomSlider}
          mailroomId={mailroomId}
          groupId={mailroomGroupId}
          closeMailroomSlider={closeMailroomSlider}
        />
      )}
    </>
  );
};
