import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import {
  TASK_INPROGRESS_SHARE_MODAL_OPEN,
  TASK_INPROGRESS_SHARE_ON_FACEBOOK,
  TASK_INPROGRESS_SHARE_ON_LINKEDIN,
  TASK_INPROGRESS_SHARE_ON_TWITTER,
  TASK_INPROGRESS_SHARE_ON_WHATSAPP,
} from "constants/analyticsEvents";
import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import { useAnalytics } from "hooks/useAnalytics";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useState } from "react";
import CopyIconBlack from "static/images/CopyIconBlack.svg";
import SocialShareFacebook from "static/images/SocialShareFacebook.svg";
import SocialShareLinkedIn from "static/images/SocialShareLinkedIn.svg";
import SocialShareTwitter from "static/images/SocialShareTwitter.svg";
import SocialShareWhatsapp from "static/images/SocialShareWhatsApp.svg";
import TaskInProgressSocialShareIcon from "static/images/TaskInProgressSocialShare.svg";

const TaskInProgressSocialShare = ({
  show,
  closeModal,
  task,
}: {
  show: boolean;
  closeModal: () => void;
  task: any;
}) => {
  const { alertToast, successToast } = useToast();
  const [copied, setCopied] = useState(false);
  const {
    authtoken: { referral_link, email },
  } = useContext(authContext);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(TASK_INPROGRESS_SHARE_MODAL_OPEN, {
      task_id: task?.uuid,
      group_id: task?.group_uuid,
      task_name: task?.title,
      email: email,
    });
  }, []);

  const copyToClipBoard = async () => {
    try {
      if (!copied) {
        await navigator.clipboard.writeText(referral_link);
        setCopied(true);
        successToast({ title: COPIED_TEXT });
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
  };

  const getLinkWithDesc = (link: string, desc: string) => {
    const urlWithReferralLink = desc + " " + referral_link;
    const encodedDesc = encodeURIComponent(urlWithReferralLink);
    let linkWithDesc = link.replace("{desc}", encodedDesc);
    return linkWithDesc;
  };

  const trackShareEvent = (event: any) => {
    trackEvent(event, {
      task_id: task?.uuid,
      group_id: task?.group_uuid,
      email: email,
      task_name: task?.title,
    });
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-max-w-[360px] md:!t-max-w-[440px]">
          <Modal.Close />
          <div className="t-mt-10">
            <div className="t-text-center t-text-body t-font-semibold t-text-text-60">
              Congratulations! Your work here is done. Share this with your
              friends to save $100 on your next filing.
            </div>
            <img
              className="t-mx-auto t-mt-4 t-flex"
              src={TaskInProgressSocialShareIcon}
              alt="TaskInProgressSocialShareIcon"
            />
            <div className="t-mt-4 t-flex t-flex-col t-items-center">
              <div className="t-text-caption t-text-text-30">
                Share it with your friends
              </div>
              <div className="t-mb-4 t-mt-3 t-flex t-gap-4">
                {Boolean(task?.share_data?.linkedin_link) && (
                  <ConditionalLink
                    onClick={() =>
                      trackShareEvent(TASK_INPROGRESS_SHARE_ON_LINKEDIN)
                    }
                    to={getLinkWithDesc(
                      task.share_data.linkedin_link,
                      task.share_data.desc
                    )}
                  >
                    <img src={SocialShareLinkedIn} alt="SocialShareLinkedIn" />
                  </ConditionalLink>
                )}
                {Boolean(task?.share_data?.wa_link) && (
                  <ConditionalLink
                    to={getLinkWithDesc(
                      task.share_data.wa_link,
                      task.share_data.desc
                    )}
                    onClick={() =>
                      trackShareEvent(TASK_INPROGRESS_SHARE_ON_WHATSAPP)
                    }
                  >
                    <img src={SocialShareWhatsapp} alt="SocialShareWhatsapp" />
                  </ConditionalLink>
                )}
                {Boolean(task?.share_data?.twitter_link) && (
                  <ConditionalLink
                    onClick={() =>
                      trackShareEvent(TASK_INPROGRESS_SHARE_ON_TWITTER)
                    }
                    to={getLinkWithDesc(
                      task.share_data.twitter_link,
                      task.share_data.desc
                    )}
                  >
                    <img src={SocialShareTwitter} alt="SocialShareTwitter" />
                  </ConditionalLink>
                )}
                {Boolean(task?.share_data?.facebook_link) && (
                  <ConditionalLink
                    onClick={() =>
                      trackShareEvent(TASK_INPROGRESS_SHARE_ON_FACEBOOK)
                    }
                    to={getLinkWithDesc(
                      task?.share_data?.facebook_link,
                      task.share_data.desc
                    )}
                  >
                    <img src={SocialShareFacebook} alt="SocialShareFacebook" />
                  </ConditionalLink>
                )}
              </div>
              <div className="t-flex t-items-center">
                <Button onClick={copyToClipBoard}>
                  {copied ? (
                    <span className="t-ml-1 t-text-body t-font-medium t-text-text-60">
                      Link copied
                    </span>
                  ) : (
                    <>
                      <img src={CopyIconBlack} alt="SocialShareCopy" />
                      <span className="t-ml-1 t-text-body t-font-medium t-text-text-60">
                        Copy referral link
                      </span>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default TaskInProgressSocialShare;
