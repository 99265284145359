import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as ALL_CALENDAR_TYPES from "constants/calendarTypes";
import { CALENDAR_TYPES } from "types/contants/calendarTypes";

type Calendar = { calendarType: CALENDAR_TYPES; year: string };

const initialState: Calendar = {
  calendarType:
    (localStorage.getItem("CALENDAR_TYPE") as CALENDAR_TYPES) ||
    ALL_CALENDAR_TYPES.CALENDAR,
  year: "2023",
};

export const calendar = createSlice({
  name: "clendar",
  initialState,
  reducers: {
    setCalenderType: (state, action: PayloadAction<CALENDAR_TYPES>) => {
      localStorage.setItem("CALENDAR_TYPE", action.payload);
      state.calendarType = action.payload;
    },

    setCalendarYear: (state, action: PayloadAction<string>) => {
      state.year = action.payload;
    },
  },
});

export const { setCalenderType, setCalendarYear } = calendar.actions;

export default calendar.reducer;
