import { emptyApi } from "./emptyApi";
import * as CHAT_SETTING_BANNERS from "constants/chatSettingBannerTypes";

export type CHAT_SETTING_BANNER_TYPES =
  (typeof CHAT_SETTING_BANNERS)[keyof typeof CHAT_SETTING_BANNERS];

export const chatSettingsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getChatSettings: build.query<
      {
        banners: [
          {
            uuid: string;
            banner_text: string;
            banner_type: CHAT_SETTING_BANNER_TYPES;
          }
        ];
        holidays: [
          {
            uuid: string;
            date: string;
            timezone: string;
          }
        ];
      },
      void
    >({
      query: () => "/api/inkle/messaging/chat-settings/",
      providesTags: ["CHAT_SETTINGS"],
    }),

    editBanner: build.mutation<
      {
        uuid: string;
        banner_text: string;
        banner_type: string;
      },
      {
        uuid: string;
        text: string;
      }
    >({
      query: ({ uuid, text }) => {
        return {
          url: `/api/inkle/messaging/chat-settings/banner/${uuid}/`,
          body: {
            text,
          },
          method: "PUT",
        };
      },
      invalidatesTags: ["CHAT_SETTINGS"],
    }),

    addHoliday: build.mutation<
      {
        uuid: string;
        date: string;
        timezone: string;
      },
      {
        date: string;
        timezone: string;
      }
    >({
      query: ({ date, timezone }) => ({
        url: "/api/inkle/messaging/chat-settings/holiday/",
        body: {
          date,
          timezone,
        },
        method: "POST",
      }),
      invalidatesTags: ["CHAT_SETTINGS"],
    }),

    getCurrentBanner: build.query<
      {
        uuid: string;
        banner_text: string;
        banner_type: string;
      },
      { groupId: string }
    >({
      query: ({ groupId }) =>
        `/api/inkle/messaging/group/${groupId}/sla-response/`,
      providesTags: ["CHAT_SETTINGS"],
    }),
  }),
});

export const {
  useGetChatSettingsQuery,
  useEditBannerMutation,
  useAddHolidayMutation,
  useGetCurrentBannerQuery,
} = chatSettingsApi;
