import { updateMerchantForm, updateTaskMerchant } from "apis/merchants";
import { useToast } from "hooks/useToast";
import { FC } from "react";
import { Merchant } from "types/Models/merchant";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";
import { WSeriesEmailForm } from "./WSeriesEmailForm";
type IWSeriesRequestFormProps = {
  show: boolean;
  closeModal: () => void;
  taskId: string;
  groupId: string;
  merchant: Merchant;
  updateMerchant: (merchant: Merchant) => void;
  season: string;
};

export const WSeriesRequestForm: FC<IWSeriesRequestFormProps> = ({
  show,
  closeModal,
  taskId,
  groupId,
  merchant,
  updateMerchant,
  season,
}) => {
  const { form_status, email, uuid, name } = merchant || {};
  const { alertToast, successToast } = useToast();

  const onEmailSubmit = async ({ email }: { email: string }) => {
    try {
      if (taskId) {
        const { data } = await updateTaskMerchant({
          taskId,
          groupId,
          merchantId: uuid,
          email,
          season,
        });
        updateMerchant(data);
      } else {
        const { data } = await updateMerchantForm({
          groupId,
          merchantId: uuid,
          email,
          season,
        });
        updateMerchant(data);
      }
      closeModal();
      successToast({ message: "Email has been sent" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-p-0 md:!t-w-[440px]">
          <Modal.Header>
            <Modal.Title>Request {name}</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <div className="t-border t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-p-6">
            <WSeriesEmailForm
              formStatus={form_status}
              onSubmit={onEmailSubmit}
              email={email}
              label="Request contractor for W-form"
            />
          </div>
          {/* <Modal.Close /> */}
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
