import {
  INPUT_CLASSNAMES,
  Label,
  TextInput,
} from "components/DesignSystem/TextInput/TextInput";
import { PlusIcon } from "components/icons/PlusIcon";
import dayjs from "dayjs";
import { FieldProps, useFormikContext } from "formik";
import { ComponentProps, forwardRef, HTMLAttributes, Ref } from "react";
import ReactDatePicker from "react-datepicker";
import { createPortal } from "react-dom";

const CustomInput = forwardRef(
  (
    {
      onClick,
      value,
      ...props
    }: {
      onClick: () => void;
      value: string;
    } & HTMLAttributes<HTMLButtonElement>,
    ref: Ref<HTMLButtonElement>
  ) => (
    <button {...props} className="all:unset" onClick={onClick} ref={ref}>
      {value || <PlusIcon />}
    </button>
  )
);

const CustomInputText = forwardRef(
  (props: ComponentProps<"input">, ref: Ref<HTMLInputElement>) => (
    <input
      ref={ref}
      {...props}
      id={props.name}
      className={INPUT_CLASSNAMES("regular", undefined, undefined)}
    />
  )
);

export const BareTimePicker = ({
  name,
  label,
  value,
  onDateChange,
  ...props
}: Omit<ComponentProps<typeof ReactDatePicker>, "onChange"> & {
  onDateChange: (value: string | null) => void;
  label?: string;
}) => {
  return (
    <>
      {createPortal(
        <div
          className="t-pointer-events-auto"
          id={`Time-picker-portal-${name}`}
        />,
        document.body
      )}
      <ReactDatePicker
        {...props}
        id={name}
        // @ts-ignore
        customInput={!label ? <CustomInput /> : <CustomInputText />}
        showTimeSelect
        wrapperClassName="t-block"
        popperClassName="t-z-dropdown"
        portalId={`Time-picker-portal-${name}`}
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        placeholderText="HH:MM"
        {...(value && typeof value === "string"
          ? {
              selected: dayjs(value).toDate(),
            }
          : {})}
        showTimeSelectOnly
        value={undefined}
        onChange={(date: Date) => {
          const savingDate = date ? dayjs(date).toISOString() : null;
          onDateChange(savingDate);
        }}
      />
    </>
  );
};

export const TimePicker = ({
  name,
  label,
  ...props
}: FieldProps["field"] & { label?: string; disabled?: boolean }) => {
  const { values, setFieldValue } = useFormikContext<Record<string, string>>();

  return (
    <div>
      {createPortal(
        <div
          className="t-pointer-events-auto"
          id={`Time-picker-portal-${name}`}
        />,
        document.body
      )}
      {label && <Label htmlFor={name}>{label}</Label>}
      <ReactDatePicker
        {...props}
        // @ts-ignore
        customInput={!label ? <CustomInput /> : <CustomInputText />}
        showTimeSelect
        wrapperClassName="t-block"
        popperClassName="t-z-dropdown"
        portalId={`Time-picker-portal-${name}`}
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        placeholderText="HH:MM"
        {...(values[name]
          ? {
              selected: dayjs(values[name]).toDate(),
            }
          : {})}
        showTimeSelectOnly
        value={undefined}
        onChange={(date: Date) => {
          const savingDate = date ? dayjs(date).toISOString() : null;
          setFieldValue(name, savingDate);
        }}
      />
    </div>
  );
};
