import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import RightSlider from "components/design/RightSlider";
import Help from "components/help/help";
import ProfileHelpIcon from "components/icons/ProfileHelpIcon";
import Logo from "components/inkleLogo";
import { PHONE } from "constants/help";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BlackArrowLeft from "static/images/BlackArrowLeft.svg";
import WhatsappIcon from "static/images/whatsappIcon.png";
import { setOnboardingStep } from "store/slices/onboarding";
import { sendMagicLink } from "../../apis/magicLinkApis";
import {
  GMAIL_LINK,
  INCORRECT_OTP,
  LINK_EXPIRED,
  OTP_SUCCESS,
  OUTLOOK_LINK,
  PAYLOAD,
  TOKEN,
} from "../../constants/magicLink";
import { DURATION } from "../../constants/resendOtp";
import authContext from "../../jwt_context&axios/authContext";
import GmailLogo from "../../static/images/GmailLogo.svg";
import OutlookLogo from "../../static/images/OutlookLogo.svg";
import InCorrectCrossIcon from "../../static/images/PinkCross.svg";
import "../../static/styles/components/magicLinkSignin.css";
import { openLink } from "../../utils/openLink";
import OtpBox from "../design/otpBox";
import Timer from "../design/Timer";
import Left from "../landingContent";
import DashboardContainer from "components/dashboard/DashboardContainer";

const VerifyOtpPage = () => {
  usePageTitle("Email verify");
  const { alertToast, successToast } = useToast();
  const { hash } = useLocation();
  const history = useHistory();
  const { loginMagicUser } = useContext(authContext);
  const [inCorrectOtp, setInCorrectOtp] = useState(false);
  const [payload, setPayload] = useState();
  const [showTimer, setShowTimer] = useState(false);
  const [key, setKey] = useState(0);
  const storedOtp = useRef("");
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();
  const origin = query.get("origin");
  const [otp, setOtp] = useState("");

  const loginUser = async (updatedHash) => {
    try {
      let message = await loginMagicUser(PAYLOAD, updatedHash);
      updateData(message);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  useEffect(() => {
    if (hash) {
      let updatedHash = hash.substring(1, hash.length);
      loginUser(updatedHash);
    } else {
      const magicLinkData = localStorage.getItem("magicLinkData");
      const storedPayload =
        magicLinkData !== "undefined" ? JSON.parse(magicLinkData) : {};
      setPayload(storedPayload);
    }
  }, []);

  const updateData = (message) => {
    switch (message) {
      case INCORRECT_OTP: {
        setInCorrectOtp(true);
        break;
      }
      case LINK_EXPIRED: {
        history.push("/signin");
        break;
      }
      case true: {
        dispatch(setOnboardingStep(2));
        history.push("/phoneverify");
        break;
      }
      default:
        return;
    }
  };

  const verifyOtp = async (otp) => {
    try {
      let message;
      if (otp) {
        storedOtp.current = otp;
        message = await loginMagicUser(TOKEN, otp, payload);
      } else message = await loginMagicUser(TOKEN, storedOtp.current, payload);
      updateData(message);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const redirectToMail = (path) => openLink(path);

  const resendOtp = () => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_KEY, {
          action: "submit",
        })
        .then(async function (token) {
          try {
            await sendMagicLink({
              email: payload?.email,
              captcha_value: token,
              accept_tos: true,
            });
            setOtp("");
            setShowTimer(true);
            setKey((prev) => prev + 1);
            successToast({ message: OTP_SUCCESS });
          } catch (e) {
            alertToast({ message: e?.response?.data?.error?.message });
          }
        });
    });
  };

  const openModal = () => setShowHelp(true);
  const closeModal = () => setShowHelp(false);
  const originFromSignin = origin === "signin";
  const goBack = () => {
    if (originFromSignin) {
      history.push("/signin");
    } else {
      history.push("/signup?signupform=true");
    }
  };

  return (
    <DashboardContainer className="t-h-screen">
      <DashboardContainer.Header>
        <div className="t-flex t-h-20 t-items-center t-justify-between">
          <Logo className="t-mb-0 t-ml-16" />
          <span
            className="t-z-50 t-mr-8 t-flex t-items-center t-text-text-100 hover:t-text-purple"
            onClick={openModal}
          >
            <ProfileHelpIcon color="currentColor" />
            <span className="helpText t-ml-1 !t-text-body !t-font-medium">
              Help
            </span>
          </span>
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        <div
          className={classNames(
            "t-flex sm:t-min-h-screen sm:t-overflow-scroll sm:t-flex-col-reverse t-pt-[4%] sm:t-justify-end",
            {
              "t-justify-center": originFromSignin,
              "t-h-full": !originFromSignin,
            }
          )}
        >
          <ConditionalLink to={`https://wa.me/${PHONE}`}>
            <button className="t-absolute t-bottom-8 t-right-8 t-z-50 t-flex t-h-10 t-w-10 t-items-center t-justify-center t-rounded-full t-border-0 t-bg-[transparent] t-bg-dark_green-30 t-shadow-hover-card-shadow t-drop-shadow-bubble">
              <img className="t-h-full" src={WhatsappIcon} alt="WhatsappIcon" />
            </button>
          </ConditionalLink>
          {!originFromSignin && <Left className="sm:t-hidden" />}
          <div
            className={classNames(
              "rightBox t-gap-4 landingForm otpVerifyContainer -t-mt-1.5 t-w-3/5 !t-items-start t-pt-0 sm:t-w-full",
              {
                "t-border-0 t-border-l t-border-neutral-10 t-border-solid":
                  !originFromSignin,
              }
            )}
          >
            <div
              className={classNames(
                "accountHead !t-w-full !t-text-left !t-text-h4",
                {
                  "!t-text-center": originFromSignin,
                }
              )}
            >
              <div
                className="backBtn t-absolute -t-ml-16 -t-mt-1 sm:-t-ml-8"
                onClick={goBack}
                aria-hidden="true"
              >
                <img src={BlackArrowLeft} alt="BlackArrowLeft" />
              </div>
              Please check your email
            </div>
            <span
              className={classNames("accountSubhead !t-w-full !t-text-left", {
                "!t-text-center": originFromSignin,
              })}
            >
              We’ve sent a verification link to
              <span className="userEmail "> {payload?.email} </span>, please
              click on the link to login into your account. The link expires in
              20 minutes!
            </span>
            <div className="btnOrOption">
              <div className="btnOrRow" />
              <span>or</span>
              <div className="btnOrRow" />
            </div>
            <span
              className={classNames("enterCode !t-w-full !t-text-left", {
                "!t-text-center": originFromSignin,
              })}
            >
              Please enter 6-character code sent to {payload?.email}
            </span>
            <OtpBox
              otp={otp}
              setOtp={setOtp}
              getOtp={verifyOtp}
              className={
                originFromSignin ? "t-justify-center" : "t-ml-0 t-justify-start"
              }
              inCorrectOtp={inCorrectOtp}
            />
            {inCorrectOtp && (
              <div className="otpStatus">
                <img
                  className="statusIcon"
                  src={InCorrectCrossIcon}
                  alt="InCorrectCrossIcon"
                />
                <span className="statusText">Incorrect code! Try again</span>
              </div>
            )}
            <div
              className={classNames("mailContainer", {
                "!t-m-auto": originFromSignin,
              })}
            >
              <span
                onClick={() => redirectToMail(GMAIL_LINK)}
                className="mailContainerCard"
              >
                <img src={GmailLogo} alt="gmail Logo" /> &nbsp;&nbsp;
                <span className="gmailText">Open Gmail</span>
              </span>
              <span
                onClick={() => redirectToMail(OUTLOOK_LINK)}
                className="mailContainerCard"
              >
                <img src={OutlookLogo} alt="outlook Logo" /> &nbsp;&nbsp;
                <span className="gmailText">Open Outlook</span>
              </span>
            </div>
            <span
              className={classNames("checkSpam !t-w-full !t-text-left", {
                "!t-text-center": originFromSignin,
              })}
            >
              Can’t find your link? Check your spam folder!
            </span>
            <div
              className={classNames("t-w-full t-text-left", {
                "!t-text-center": originFromSignin,
              })}
            >
              {showTimer ? (
                <Timer
                  setShowTimer={setShowTimer}
                  duration={DURATION}
                  key={key}
                  originFromSignup={!originFromSignin}
                />
              ) : (
                <span className="resendLink" onClick={resendOtp}>
                  Resend link
                </span>
              )}
            </div>
          </div>
          {showHelp && (
            <RightSlider width="auto" show={showHelp} closeModal={closeModal}>
              <Help loggedOut closeModal={closeModal} />
            </RightSlider>
          )}
        </div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};

export default VerifyOtpPage;
