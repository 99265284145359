import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import Modal from "components/Modal/Modal";
import { Form, Formik } from "formik";
import { couponSchema } from "formValidations/couponCodeSchema";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useEffect } from "react";
import SuccessfullPartyEmoji from "static/images/SuccessfullPartyEmoji.svg";
import { useApplyGroupCouponMutation } from "store/apis/billing";

type ApplyCouponModalProps = {
  show: boolean;
  closeModal: () => void;
};

const ApplyCouponModal = ({ show, closeModal }: ApplyCouponModalProps) => {
  const [applyGroupCoupon, { data, isError, error, isSuccess, reset }] =
    useApplyGroupCouponMutation();
  const { uuid: groupId } = useCurrentGroupContext();

  const applyCoupon = async ({ couponCode }: { couponCode: string }) => {
    try {
      await applyGroupCoupon({
        groupId,
        couponCode,
      }).unwrap();

      closeModal();
    } catch (e: any) {}
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        reset();
      }, 5000);
    }
  }, [isSuccess]);

  return (
    <>
      <Modal.Root open={show} onOpenChange={closeModal}>
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="t-w-[500px]">
            <Modal.Close />
            <Modal.Title>
              <span className="t-text-h5 t-font-bold t-text-text-100">
                Apply coupon
              </span>
            </Modal.Title>
            <Formik
              onSubmit={applyCoupon}
              initialValues={{ couponCode: "" }}
              validationSchema={couponSchema}
            >
              {({ isSubmitting, isValid, values }) => (
                <Form className="t-m-0 t-mt-4 t-w-full">
                  <TextInput
                    block
                    name="couponCode"
                    type="text"
                    placeholder="Enter Coupon"
                  />
                  {values.couponCode.length !== 0 && isError && (
                    <div className="t-mt-1 t-text-caption t-text-red">
                      {/* @ts-ignore */}
                      {error?.data?.error?.message}
                    </div>
                  )}
                  <div className="d-flex justify-content-end gap-3 my-3">
                    <Button
                      isLoading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                    >
                      Apply
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
      <Modal.Root open={isSuccess}>
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="t-w-[500px]">
            <div className="t-flex t-flex-col t-items-center">
              <img src={SuccessfullPartyEmoji} alt="SuccessfullPartyEmoji" />
              <div className="t-mt-6 t-text-h5 t-font-bold t-text-text-100">
                Congrats!
              </div>
              <div className="t-mt-1">
                {/* @ts-ignore */}
                Coupon code <b>{data?.coupon_code}</b> applied successfully.
              </div>
            </div>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
    </>
  );
};

export default ApplyCouponModal;
