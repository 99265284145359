import React from "react";

export const Chat = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.80196 12.1821L2.83139 13.8352C2.76158 13.893 2.67683 13.9298 2.58695 13.9412C2.49708 13.9528 2.40578 13.9386 2.32363 13.9004C2.24149 13.8622 2.17188 13.8014 2.12287 13.7252C2.07387 13.649 2.04747 13.5604 2.04675 13.4698V3.95835C2.04675 3.83127 2.09724 3.70939 2.1871 3.61953C2.27696 3.52967 2.39884 3.47919 2.52592 3.47919H13.0676C13.1947 3.47919 13.3165 3.52967 13.4064 3.61953C13.4963 3.70939 13.5467 3.83127 13.5467 3.95835V11.625C13.5467 11.7521 13.4963 11.874 13.4064 11.9638C13.3165 12.0537 13.1947 12.1042 13.0676 12.1042H5.02357L4.80196 12.1821Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.88013 6.83331H9.71345"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.88013 8.75H9.71345"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
