import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import AddEntity from "components/Entity/addMoreEntity";
import { Redirect } from "components/icons/Redirect";
import { Star } from "components/icons/Star";
import {
  CLICKED_ON_INTER_COMPANY_TRANSACTIONS_GET_STARTED_CTA,
  CLICKED_ON_SCHEDULE_A_DEMO_CALL_INSIDE_RESOURCES,
  CLICKED_ON_VIEW_BREAKUP_CTA_IN_CALCULATOR,
  COMPLETE_TP_KYC,
  ENTERED_AMOUNT_IN_SEND_IN_FX_CALCULATOR,
} from "constants/analyticsEvents";
import {
  ARJAV_CALENDLY_LINK,
  SQUAREFEET_BLOG_LINK,
  SWIFT_BLOG_LINK,
  TP_COMPLIANCE_CALENDLY_LINK,
} from "constants/tppayments";
import { Form, Formik, FormikValues } from "formik";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import BookIconTP from "static/images/BookIconTP.svg";
import RightArrowMiddle from "static/images/RightArrowMiddle.svg";
import VideoCameraIcon from "static/images/VideoCameraIcon.svg";
import {
  useCreateEntityMutation,
  useLazyGetEntitiesQuery,
} from "store/apis/group";
import { useCreateOnboardingTaskMutation } from "store/apis/task";
import { useLazyGetGroupDataQuery } from "store/apis/teamSetting";
import {
  useGetTPAccountInfoQuery,
  useLazyGetQuoteQuery,
} from "store/apis/transferPricing";
import { setDefaultChannel } from "store/slices/chat";
import { setQuoteResponse } from "store/slices/transferpricing";
import { RootState } from "store/store";
import { TPOnboardingCard } from "TPOnboardingCard";
import { currency } from "utils/Currency";
import { debounce } from "utils/debouncing";
import { AmountInput } from "../TransferModal/AmountInput";
import { AmountStep } from "../TransferModal/AmountStep";
import { TPInfoModal } from "./TPInfoModal";

export const Illustration = ({
  isOnboardingPending,
}: {
  isOnboardingPending: boolean;
}) => {
  const { alertToast } = useToast();
  const { quote } = useSelector((state: RootState) => state.tpPaymentsSlice);
  const [showTPInfo, setShowTPInfo] = useState<boolean>(false);
  const [showAddEntity, setShowAddEntity] = useState(false);
  const [showAddEntityConfirm, setShowAddEntityConfirm] = useState(false);
  const [openTOSModal, setOpenTOSModal] = useState(false);
  const [isDispatched, setIsDispatched] = useState(false);
  const [showDetailedView, setShowDetailedView] = useState(false);
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const { isAdmin } = useRoleBasedView();
  const [getConvertedAmount] = useLazyGetQuoteQuery();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const {
    secondary_channel_url,
    uuid: groupId,
    entities,
    name: companyName,
    dispatch: groupDispatch,
  } = useCurrentGroupContext();

  const { data: accountInfo } = useGetTPAccountInfoQuery(
    { groupId },
    { skip: !groupId }
  );

  const [createEntity] = useCreateEntityMutation();

  const [createTask, { isLoading: isTaskCreating }] =
    useCreateOnboardingTaskMutation();

  const [getGroupData] = useLazyGetEntitiesQuery();

  const [getGroupDataCRM] = useLazyGetGroupDataQuery();

  const receiverAmount = quote?.receiver_amount
    ? currency({
        amount: Number(quote?.receiver_amount),
        currencyCode: "INR",
      }).replace("₹", "")
    : "";

  const onCompleteKyc = async () => {
    if (accountInfo?.tpx_task_uuid) {
      history.push(`/filings/${accountInfo?.tpx_task_uuid}`);
      setIsDispatched(false);
      return;
    }

    const indianEntities = entities.filter((e) => e.country_code === "IN");

    if (indianEntities.length > 0) {
      const payload = {
        task_template_id: accountInfo?.tpx_task_template_uuid,
        title: "Transfer Pricing Payments Onboarding",
        payment_category: 0,
        payment_amount: "",
        company_entity_ids: [indianEntities[0].uuid],
        adhoc_scope_of_work: "",
      };
      const res = await createTask({ payload }).unwrap();
      trackEvent(COMPLETE_TP_KYC, {
        company_name: companyName,
      });
      history.push(`/filings/${res.uuid}`);
      dispatch(setDefaultChannel(secondary_channel_url));
      setIsDispatched(false);
    } else {
      dispatch(setDefaultChannel(secondary_channel_url));
      setShowAddEntityConfirm(true);
      setIsDispatched(false);
    }
  };

  const setGroupData = async () => {
    if (isAdmin) {
      try {
        const response = await getGroupDataCRM({ groupId }).unwrap();
        groupDispatch({ type: "SET_GROUP", payload: response });
        setShowAddEntity(false);
        setIsDispatched(true);
      } catch (e: any) {
        alertToast({ message: e?.data?.error?.message });
        setShowAddEntity(false);
      }
    } else {
      try {
        const response = await getGroupData({}).unwrap();
        dispatch({ type: "SET_GROUP", payload: response });
        setShowAddEntity(false);
        setIsDispatched(true);
      } catch (e: any) {
        alertToast({ message: e?.data?.error?.message });
        setShowAddEntity(false);
      }
    }
  };

  const addEntityData = async (values: FormikValues) => {
    try {
      let entityData = {
        company_name: values.company_name,
        formation_type_id: values.formation_type_id,
        country_id: values.country,
        state_id: values.state_id,
      };
      await createEntity({ groupId, payload: entityData }).unwrap();
      setGroupData();
      setShowAddEntity(false);
    } catch (e: any) {
      setShowAddEntity(false);
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const handleOnChange = debounce(async (e: any) => {
    const amount = e.target.value?.replaceAll(",", "");
    trackEvent(ENTERED_AMOUNT_IN_SEND_IN_FX_CALCULATOR);
    try {
      if (amount && !isNaN(+amount)) {
        const resp = await getConvertedAmount({ amount }).unwrap();
        dispatch(setQuoteResponse(resp));
      }
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  });

  const isOnboardingPendingHandler = () => {
    trackEvent(CLICKED_ON_INTER_COMPANY_TRANSACTIONS_GET_STARTED_CTA);
    if (accountInfo?.tos_accepted) {
      onCompleteKyc();
    } else {
      setOpenTOSModal(true);
    }
  };

  const handleViewBreakupClick = () => {
    setShowDetailedView(true);
    trackEvent(CLICKED_ON_VIEW_BREAKUP_CTA_IN_CALCULATOR);
  };

  useEffect(() => {
    if (isDispatched) onCompleteKyc();
  }, [isDispatched]);

  return (
    <>
      <div className="t-flex t-flex-col t-gap-10 t-w-full t-bg-[url('static/images/TPPreOnboardingBg.svg')] t-bg-no-repeat t-bg-cover t-p-6 t-rounded-xl t-border t-border-solid t-border-b-purple-10 t-border-l-0 t-border-r-0 t-border-t-0">
        <div className="t-flex t-justify-between">
          <div className="t-text-h5">
            Get started with seamless inter-company transactions
          </div>
          <Button
            customType="primary"
            isLoading={isTaskCreating}
            disabled={isTaskCreating}
            onClick={
              isOnboardingPending ? isOnboardingPendingHandler : () => {}
            }
          >
            Get started
          </Button>
        </div>
        <div className="t-grid t-grid-cols-3 t-items-center">
          <div className="t-flex t-items-center">
            <div className=" t-rounded t-border t-border-solid t-border-purple-20 t-px-4 t-py-5 t-text-body t-bg-white">
              Do a 5-min KYC to activate a US Virtual Account
            </div>
            <img src={RightArrowMiddle} alt="RightArrow" className="t-w-full" />
          </div>
          <div className="t-flex t-items-center">
            <div className="t-rounded t-border t-border-solid t-border-purple-20 t-px-4 t-py-5 t-text-body t-bg-white">
              Make a US domestic wire to the Virtual Account
            </div>
            <img src={RightArrowMiddle} alt="RightArrow" className="t-w-full" />
          </div>
          <div className=" t-rounded t-border t-border-solid t-border-purple-20 t-px-4 t-py-5 t-text-body t-bg-white">
            Money is credited to Indian Bank Account in T+1 days
          </div>
        </div>
        <div className="t-flex t-gap-3 t-text-body">
          <div className="t-px-2 t-py-1 t-rounded t-border t-border-solid t-border-yellow-20 t-bg-white t-flex t-items-center t-gap-2">
            <Star />
            <div>
              <b>0.25%</b> FX markup over mid-market
            </div>
          </div>
          <div className="t-px-2 t-py-1 t-rounded t-border t-border-solid t-border-yellow-20 t-bg-white t-flex t-items-center t-gap-2">
            <Star />
            <div>Money moved via top 4 global banks</div>
          </div>
          <div className="t-px-2 t-py-1 t-rounded t-border t-border-solid t-border-yellow-20 t-bg-white t-flex t-items-center t-gap-2">
            <Star />
            <div>Free FIRA on dashboard</div>
          </div>
        </div>
      </div>
      <div className="t-mt-6 t-flex t-gap-4">
        <div className="t-rounded-lg t-flex t-flex-col t-border t-border-solid t-border-neutral-10 t-w-1/3 t-pt-6 t-pb-5 t-px-4 t-gap-5">
          <div className="t-text-subtitle">FX Calculator</div>
          <div className="t-flex t-flex-col t-gap-3">
            <div className="t-flex t-gap-6">
              <Formik
                initialValues={{
                  amount: quote?.sender_amount,
                  inramount: "",
                }}
                onSubmit={() => {}}
              >
                <Form
                  onChange={handleOnChange}
                  className="all:unset t-flex t-flex-col t-gap-6 t-w-full"
                >
                  <AmountInput isInitiateTransferModal={false} />
                  <TextInput
                    label="Get (indicative amount)"
                    name="inramount"
                    disabled
                    value={receiverAmount}
                    icon={
                      <div className="t-flex t-items-center t-gap-2">
                        <ReactCountryFlag
                          countryCode="IN"
                          svg
                          style={{ width: "20px", height: "24px" }}
                        />
                        <div>INR</div>
                      </div>
                    }
                  />
                </Form>
              </Formik>
            </div>
            <div className="t-text-body-sm t-text-text-30 t-ml-0.5">
              Disclaimer: The conversion rate may vary depending on the exact
              time of transaction.
            </div>
            <Button onClick={handleViewBreakupClick}>View breakup</Button>
          </div>
        </div>
        <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-w-2/3 t-p-6 t-flex t-flex-col t-gap-7">
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-text-subtitle">Resources</div>
            <div className="t-text-body t-text-text-30">
              Inkle resources to help you get started
            </div>
          </div>
          <div className="t-flex t-flex-col t-gap-6 t-text-body">
            <ConditionalLink
              to={ARJAV_CALENDLY_LINK}
              className="all:unset t-cursor-pointer hover:!t-underline hover:t-text-purple"
            >
              <span
                className="t-flex t-gap-2 t-items-center"
                onClick={() =>
                  trackEvent(CLICKED_ON_SCHEDULE_A_DEMO_CALL_INSIDE_RESOURCES)
                }
              >
                <img
                  src={VideoCameraIcon}
                  alt="CameraIcon"
                  width="20px"
                  height="20px"
                />
                <span>Schedule a demo call with us</span>
                <Redirect color="currentColor" size={18} />
              </span>
            </ConditionalLink>
            <ConditionalLink
              to={TP_COMPLIANCE_CALENDLY_LINK}
              className="all:unset t-cursor-pointer hover:!t-underline hover:t-text-purple"
            >
              <span className="t-flex t-gap-2 t-items-center">
                <img
                  src={VideoCameraIcon}
                  alt="CameraIcon"
                  width="20px"
                  height="20px"
                />
                <span>Schedule a Transfer Pricing Compliance Health Check</span>
                <Redirect color="currentColor" size={18} />
              </span>
            </ConditionalLink>
            <ConditionalLink
              to={SWIFT_BLOG_LINK}
              className="all:unset t-cursor-pointer hover:!t-underline hover:t-text-purple"
            >
              <span className="t-flex t-gap-2 t-items-center">
                <img src={BookIconTP} alt="Book" width="20px" height="20px" />
                <span>How is this different from SWIFT to Indian bank?</span>
                <Redirect color="currentColor" size={18} />
              </span>
            </ConditionalLink>
            <ConditionalLink
              to={SQUAREFEET_BLOG_LINK}
              className="all:unset t-cursor-pointer hover:!t-underline hover:t-text-purple"
            >
              <span className="t-flex t-gap-2 t-items-center">
                <img src={BookIconTP} alt="Book" width="20px" height="20px" />
                <span>
                  Read a case study on how 91SquareFeet scaled TP transactions
                  with Inkle
                </span>
                <Redirect color="currentColor" size={18} />
              </span>
            </ConditionalLink>
            <ConditionalLink
              to="https://www.inkle.io/blog/unlocking-global-trade-the-role-of-purpose-codes-in-cross-border-payments"
              className="all:unset t-cursor-pointer hover:!t-underline hover:t-text-purple"
            >
              <span className="t-flex t-gap-2 t-items-center">
                <img src={BookIconTP} alt="Book" width="20px" height="20px" />
                <span>
                  Unlocking Global Trade: The Role of Purpose Codes in
                  Cross-Border Payments
                </span>
                <Redirect color="currentColor" size={18} />
              </span>
            </ConditionalLink>
          </div>
        </div>
      </div>
      <TPInfoModal showTPInfo={showTPInfo} setShowTPInfo={setShowTPInfo} />
      <TPOnboardingCard
        onCompleteKyc={onCompleteKyc}
        setShowAddEntity={setShowAddEntity}
        showAddEntityConfirm={showAddEntityConfirm}
        setShowAddEntityConfirm={setShowAddEntityConfirm}
        setOpenTOSModal={setOpenTOSModal}
        openTOSModal={openTOSModal}
      />
      <AddEntity
        addEntityData={addEntityData}
        showAddEntity={showAddEntity}
        setShowAddEntity={setShowAddEntity}
      />
      <Modal.Root open={showDetailedView} onOpenChange={setShowDetailedView}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Transfer money</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-border t-border-solid t-border-neutral-0 t-rounded">
              <AmountStep backPath={url} isInitiateTransferModal={false} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              customType="primary"
              block
              isLoading={isTaskCreating}
              disabled={isTaskCreating}
              onClick={
                isOnboardingPending ? isOnboardingPendingHandler : () => {}
              }
            >
              Get started
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
