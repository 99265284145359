import React from "react";
import classNames from "classnames";
import ClockUpdate from "static/images/ClockUpdate.svg";
import { formatDate, formatTime } from "utils/formatDate";

type StepProps = {
  isLastStep?: boolean;
  description: string | null;
  profileName: string;
  event: string;
  eventDate: string;
};

export const EtdUpdateHistoryCard = ({
  description,
  eventDate,
  profileName,
  event,
  isLastStep,
}: StepProps) => {
  return (
    <div
      className={classNames("t-h-max t-pl-[10px] t-relative", {
        "t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-r-0 t-border-b-0":
          !isLastStep,
      })}
    >
      <img
        src={ClockUpdate}
        alt="ClockUpdate"
        className="t-absolute -t-ml-5 t-bg-surface t-h-5 t-w-5"
      />
      <div className="t-ml-3 t-space-y-1">
        <div className="-t-mt-0.5 t-text-subtext-2 t-text-text-60">
          {profileName} {event}
        </div>
        {description && (
          <div className="t-text-body-sm t-text-text-60">{description}</div>
        )}
        <div
          className={classNames("t-text-body-sm t-text-text-30", {
            "!t-mb-5": !isLastStep,
          })}
        >
          {formatDate(eventDate)} at {formatTime(eventDate)}
        </div>
      </div>
    </div>
  );
};
