import classNames from "classnames";
import React, { ReactNode, useEffect, useRef } from "react";

const TaskButtonBar = ({
  parentRef,
  children,
  justifyContent,
  addMarginLeft = true,
}: {
  parentRef: any;
  children: ReactNode;
  justifyContent: "between" | "end" | "start";
  addMarginLeft?: boolean;
}) => {
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver?.observe?.(parentRef.current);

    return () => {
      if (resizeObserver && parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, []);

  const handleResize = (entries: any) => {
    const parentWidth = entries[0].contentRect.width;
    if (childRef.current) {
      childRef.current.style.width = `${parentWidth}px`;
    }
  };

  return (
    <div
      ref={childRef}
      className={classNames(
        "t-fixed t-bottom-0 t-z-10 t-flex t-border-solid t-border t-border-surface-transparent t-border-t-neutral-10 t-bg-surface t-py-3 t-pl-4 t-pr-6 t-shadow-top-shadow",
        {
          "t-justify-end": justifyContent === "end",
          "t-justify-between": justifyContent === "between",
          "t-justify-start": justifyContent === "start",
          "-t-ml-4": addMarginLeft,
        }
      )}
    >
      {children}
    </div>
  );
};

export default TaskButtonBar;
