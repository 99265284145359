export const MAIL_SAVED = "New mail has been saved";
export const MAIL_EDIT = "Changes have been made";
export const MAIL_DELETE = "Mail has been deleted";

export const EDIT_MAIL_TITLE = "Edit mail";
export const ADD_MAIL_TITLE = "New mail";

export const NEWEST = "NEWEST";
export const OLDEST = "OLDEST";

export const ACTION_REQUIRED = "ACTION_REQUIRED";
export const FORWARD_REQUESTED = "FORWARD_REQUESTED";
export const SHREDDED = "SHREDDED";
export const FORWARDED = "FORWARDED";
export const SHRED_REQUESTED = "SHRED_REQUESTED";

export const REGISTERED_AGENT = "REGISTERED_AGENT";
export const MAILROOM = "MAILROOM";
export const MAILROOM_ADDRESS = "MAILROOM_ADDRESS";
