import { FSP_CLIENT, FSP_GROUP_RES, Group } from "types/Models/group";
import { emptyApi } from "./emptyApi";
import qs from "qs";

export type InviteFSPUserPayload = {
  first_name: string;
  last_name: string;
  email: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllFSPGroups: build.query<
      FSP_GROUP_RES,
      {
        page_num: number;
        search_term: string | null;
        paid: boolean | null;
        unpaid: boolean | null;
        country_codes: string | null;
      }
    >({
      query: ({ page_num, paid, unpaid, country_codes, search_term }) => {
        let queryUrl = qs.stringify(
          {
            page_num,
            paid,
            unpaid,
            country_codes,
            search_term,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return `/api/inkle/serviceteams/foreign_service-providers/${queryUrl}`;
      },
      providesTags: ["FSPGroups"],
    }),
    inviteFspUser: build.mutation<any, { users: InviteFSPUserPayload[] }>({
      query: ({ users }) => {
        return {
          url: "/api/inkle/serviceteams/foreign_service-providers/invite/",
          method: "post",
          body: { invite_users: users },
        };
      },
    }),
    getAllFspClients: build.query<
      FSP_CLIENT[],
      {
        practicecrmId: string;
      }
    >({
      query: ({ practicecrmId }) => {
        return `/api/inkle/serviceteams/${practicecrmId}/clients/`;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllFSPGroupsQuery,
  useInviteFspUserMutation,
  useGetAllFspClientsQuery,
} = extendedApi;
