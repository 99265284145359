import DateSelectBox from "components/design/dateSelectBox";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { putDeadline } from "../../apis/editDeadline";

export default function Deadline({ deadline, modalShow, setModalShow }) {
  const [deadlineValue, setDeadlineValue] = useState(deadline);
  const { alertToast, successToast } = useToast();

  const handleChange = (e) => {
    const { value } = e.target;
    setDeadlineValue(value);
  };

  const editChange = () => {
    const path = window.location.href;
    const url = path.split("/");
    const task_id = url[url.length - 1];

    const body = {
      operation: "DEADLINE_UPDATE",
      deadline: deadlineValue,
    };
    putDeadline({ task_id, body })
      .then(() => {
        setModalShow(false);
        successToast({ message: "Updated successfully!" });
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response) {
          setModalShow(false);
          alertToast({ message: error?.response?.data?.error?.message });
        } else {
          console.log(error.request);
        }
      });
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="addEntityModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Deadline
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateSelectBox
            type="date"
            value={deadlineValue}
            name="transaction_date"
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="bueBtn signinPad" onClick={() => editChange()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
