export const Ticket = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6240_1555)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.633423 3.99999C0.633423 3.61339 0.946824 3.29999 1.33342 3.29999H9.66676C9.77771 3.29999 9.88708 3.32636 9.98583 3.37694L11.3137 4.05699L12.4655 3.39345C12.5717 3.33222 12.6922 3.29999 12.8149 3.29999H14.6668C15.0534 3.29999 15.3668 3.61339 15.3668 3.99999V12.6667C15.3668 13.0533 15.0534 13.3667 14.6668 13.3667H12.8149C12.6922 13.3667 12.5717 13.3344 12.4655 13.2732L11.3137 12.6096L9.98583 13.2897C9.88707 13.3403 9.77771 13.3667 9.66676 13.3667H1.33342C0.946824 13.3667 0.633423 13.0533 0.633423 12.6667V3.99999ZM2.03342 4.69999V11.9667H9.49794L11.0143 11.1901C11.2256 11.0819 11.4772 11.0881 11.6829 11.2066L13.0021 11.9667H13.9668V4.69999H13.0021L11.6829 5.46006C11.4772 5.57853 11.2256 5.58475 11.0143 5.47657L9.49794 4.69999H2.03342Z"
        fill="currentColor"
      />
      <path
        d="M4.8268 11V6.90541H3.33337V6H7.33337V6.90541H5.83995V11H4.8268Z"
        fill="currentColor"
      />
      <path
        d="M10.6667 6.66669H12.0001V7.33335H10.6667V6.66669Z"
        fill="currentColor"
      />
      <path d="M10.6667 8H12.0001V8.66667H10.6667V8Z" fill="currentColor" />
      <path
        d="M10.6667 9.33331H12.0001V9.99998H10.6667V9.33331Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6240_1555">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
