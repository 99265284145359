import { makeFormData } from "utils/makeFormData";
import {
  Merchant,
  MERCHANT_TASK_STATUS_TYPES,
  W_FORM_TYPES,
} from "types/Models/merchant";
import { axiosInstance } from "./apiInstance";

export const updateTaskMerchant = async ({
  taskId,
  groupId,
  merchantId,
  email,
  file,
  form_type,
  season,
}: {
  taskId: string;
  groupId: string;
  merchantId: string;
  email?: string;
  file?: File;
  form_type?: W_FORM_TYPES;
  season: string;
}) => {
  const params = {
    merchant_id: merchantId,
    email,
    file,
    form_type,
    season,
  };

  const formData = new FormData();
  Object.entries(params).forEach(
    ([key, value]) => value && formData.append(key, value)
  );

  const { data } = await axiosInstance.put<{ data: Merchant }>(
    `/api/inkle/tasks/${taskId}/group/${groupId}/merchants/`,
    formData
  );

  return data;
};

export const updateMerchantForm = async ({
  groupId,
  merchantId,
  email,
  file,
  form_type,
  season,
}: {
  groupId: string;
  merchantId: string;
  email?: string;
  file?: File;
  form_type?: W_FORM_TYPES;
  season: string;
}) => {
  const params = { merchant_id: merchantId, email, file, form_type, season };

  const formData = new FormData();
  Object.entries(params).forEach(
    ([key, value]) => value && formData.append(key, value)
  );

  const { data } = await axiosInstance.put<{ data: Merchant }>(
    `/api/inkle/bookkeeping/group/${groupId}/merchant/`,
    formData
  );

  return data;
};

export const updateTaskMerchantFilingStatus = async ({
  merchantId,
  taskId,
  status,
}: {
  merchantId: string;
  taskId: string;
  status: MERCHANT_TASK_STATUS_TYPES;
}) => {
  const { data } = await axiosInstance.patch<{ data: Merchant }>(
    `/api/inkle/tasks/${taskId}/merchant/${merchantId}/update-status/`,
    {
      status,
    }
  );

  return data;
};

export const deleteMerchantFile = async ({
  groupId,
  merchantId,
  season,
}: {
  groupId: string;
  merchantId: string;
  season: string | null;
}) => {
  const seasonQuery = Boolean(season) ? `?season=${season}` : "/";
  const { data } = await axiosInstance.delete(
    `/api/inkle/bookkeeping/group/${groupId}/merchant/${merchantId}/update${seasonQuery}`
  );

  return data;
};

export const updateMerchant = async ({
  groupId,
  merchantId,
  email,
}: {
  groupId: string;
  merchantId: string;
  email: string;
}) => {
  const { data } = await axiosInstance.patch<{ data: Merchant }>(
    `/api/inkle/bookkeeping/group/${groupId}/merchant/${merchantId}/update/`,
    {
      email,
    }
  );

  return data;
};

export const generateContractorForm = async ({
  merchantId,
  formType,
}: {
  merchantId: string;
  formType: W_FORM_TYPES;
}) => {
  const { data } = await axiosInstance.post<{ data: string }>(
    `/api/inkle/bookkeeping/merchant/${merchantId}/get-form/`,
    {
      w_form_type: formType,
    }
  );

  return data;
};

export const upload1099File = async ({
  groupId,
  merchantId,
  email,
  file,
  taskId,
}: {
  merchantId: string;
  groupId: string;
  email?: string;
  file?: File;
  taskId: string;
}) => {
  const payload = makeFormData({
    file: file,
    email: email,
  });
  const { data } = await axiosInstance.put<{ data: Merchant }>(
    `/api/inkle/tasks/${taskId}/group/${groupId}/merchants/${merchantId}/update-1099form`,
    payload
  );

  return data;
};

export const send1099File = async ({
  groupId,
  email,
  merchantDocMapId,
}: {
  groupId: string;
  email: string;
  merchantDocMapId: string;
}) => {
  const { data } = await axiosInstance.post<{ data: Merchant }>(
    `/api/inkle/tasks/group/${groupId}/merchant/document/${merchantDocMapId}/send-1099-form/`,
    {
      email,
    }
  );

  return data;
};

export const delete1099File = async ({
  groupId,
  merchantId,
  taskId,
}: {
  merchantId: string;
  groupId: string;
  taskId: string;
}) => {
  const { data } = await axiosInstance.delete<{ data: Merchant }>(
    `/api/inkle/tasks/${taskId}/group/${groupId}/merchants/${merchantId}/update-1099form`
  );
  return data;
};
