import React from "react";
import { Modal } from "react-bootstrap";

//css
import "../../static/styles/components/commonComponent.css";

const CommonModal = ({
  size = "lg",
  show,
  closeModal,
  modalTitle,
  body,
  footer,
  closeButton = true,
  className,
  width,
  backdrop = true,
  padding,
  header,
}) => {
  const maxWidth = width < 800 ? 800 : width;
  return (
    <Modal
      style={{
        "--app-custom-modal-content-width": `${width || 500}px`,
        "--bs-modal-width": `${maxWidth}px`,
      }}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName="overflow-auto"
      show={show}
      onHide={closeModal}
      backdrop={backdrop}
      className={`commonModal ${className}`}
    >
      <Modal.Header closeButton={closeButton}>
        {modalTitle && !header ? (
          <Modal.Title id="example-modal-sizes-title-sm">
            {modalTitle}
          </Modal.Title>
        ) : (
          header
        )}
      </Modal.Header>

      <Modal.Body
        style={{
          "--app-custom-modal-body-padding": `${padding || 8}px`,
        }}
      >
        {body}
      </Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default CommonModal;
