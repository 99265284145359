export const InkleTaxSymbol = () => (
  <svg
    width="56"
    height="57"
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.263 24.1863L20.3192 34.9805L17.237 31.9404C15.5877 30.3136 15.5877 27.676 17.237 26.0492L25.1944 18.2006C26.8437 16.5738 29.5179 16.5738 31.1673 18.2006L31.263 18.295C32.9123 19.9218 32.9123 22.5594 31.263 24.1863Z"
      fill="url(#paint0_linear_5723_7733)"
    />
    <path
      d="M24.737 33.7747L35.6808 22.9805L38.763 26.0205C40.4123 27.6473 40.4123 30.2849 38.763 31.9118L30.8056 39.7604C29.1563 41.3872 26.4821 41.3872 24.8327 39.7603L24.737 39.6659C23.0877 38.0391 23.0877 35.4015 24.737 33.7747Z"
      fill="url(#paint1_linear_5723_7733)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7733"
        x1="16"
        y1="25.9805"
        x2="32.5"
        y2="25.9805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7733"
        x1="23.5"
        y1="31.9805"
        x2="40"
        y2="31.9805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
