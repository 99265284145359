import { useEffect } from "react";
import uniqBy from "lodash.uniqby";
import { Channel, ChannelFilters, Event } from "stream-chat";
import { useChatContext, getChannel } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { shouldChannelListUpdate } from "utils/shouldChannelListUpdate";

export const useNotificationAddedToChannelListener = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  setChannels: React.Dispatch<
    React.SetStateAction<Array<Channel<StreamChatGenerics>>>
  >,
  customHandler?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void,
  allowNewMessagesFromUnfilteredChannels = true,
  filters?: ChannelFilters<StreamChatGenerics>
) => {
  const { client } = useChatContext<StreamChatGenerics>(
    "useNotificationAddedToChannelListener"
  );

  const comparableFilter = JSON.stringify(filters);

  useEffect(() => {
    const handleEvent = async (event: Event<StreamChatGenerics>) => {
      const update = await shouldChannelListUpdate({ client, event, filters });

      if (customHandler && typeof customHandler === "function") {
        customHandler(setChannels, event);
      } else if (
        allowNewMessagesFromUnfilteredChannels &&
        event.channel?.type
      ) {
        const channel = await getChannel({
          client,
          type: event.channel.type,
          id: event.channel.id,
        });
        setChannels((channels) => {
          if (!update) {
            return channels;
          }

          return uniqBy([channel, ...channels], "cid");
        });
      }
    };

    client.on("notification.added_to_channel", handleEvent);

    return () => {
      client.off("notification.added_to_channel", handleEvent);
    };
  }, [customHandler, comparableFilter]);
};
