import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { CrossIcon } from "components/icons/CrossIcon";
import { BOOKS_PLUS_PLAN } from "constants/addons";
import { MONTHLY } from "constants/billingCycles";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";

const UpgradeBooksPlusPlan = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const history = useHistory();
  const { isCustomer, isAdmin } = useRoleBasedView();
  const [showBar, setShowBar] = useState(
    JSON.parse(localStorage.getItem("showBar") || "true")
  );

  const {
    data: bookkeepingSubscriptionPlans = [],
    isLoading,
    isSuccess,
  } = useGetSubscriptionsQuery(
    {
      subscription_types: BOOKS_PLUS_PLAN,
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const activeBooksPlusPlan = bookkeepingSubscriptionPlans?.find(
    ({ group_subscription = [] }) => group_subscription?.length > 0
  );

  const booksMonthlyPlusPlan = bookkeepingSubscriptionPlans?.find(
    (ele) => ele.billing_cycle === MONTHLY
  );

  const { upgrade } = useUpgradePlan();

  const onUpgrade = () => {
    upgrade({ addon: booksMonthlyPlusPlan! });
    if (isAdmin) {
      history.push(`/admin/crm/${groupId}/billing?entity=${entityId}`);
      return;
    }

    if (isCustomer) {
      history.push(`/settings/billing?entity=${entityId}`);
    }
  };

  const onRemove = () => {
    localStorage.setItem("showBar", "false");
    setShowBar(false);
  };

  return (
    <Async.Root
      isEmpty={Boolean(activeBooksPlusPlan) || !showBar}
      isLoading={isLoading}
      isSuccess={isSuccess}
    >
      <Async.Empty>{null}</Async.Empty>
      <Async.Success>
        <div className="t-py-3 t-px-6 t-rounded-xl t-text-subtitle t-text-text-60 t-flex t-items-center t-justify-between t-bg-upgrade-books-plus-plan">
          Upgrade to Inkle Books Plus
          <div className="t-flex t-items-center t-gap-3">
            <Button size="small" onClick={onUpgrade}>
              Upgrade now
            </Button>
            <Button size="small" customType="ghost_icon" onClick={onRemove}>
              <CrossIcon />
            </Button>
          </div>
        </div>
      </Async.Success>
    </Async.Root>
  );
};

export default UpgradeBooksPlusPlan;
