import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { invoiceInitialValues } from "components/InvoiceForm/InvoiceForm";
import { useFormikContext } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { Invoice, useUpdateInvoiceMutation } from "store/apis/invoices";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";

export const ConfirmChanges = ({
  open,
  close,
  openReviewModal,
  entityId,
}: {
  open: boolean;
  close: () => void;
  openReviewModal: () => void;
  entityId?: string;
}) => {
  const { invoiceId } = useParams<{
    invoiceId: string;
  }>();
  const { alertToast, successToast } = useToast();
  const { values } = useFormikContext<typeof invoiceInitialValues>();
  const [updateInvoice] = useUpdateInvoiceMutation();
  const group = useCurrentGroupContext();

  const updateChanges = async () => {
    try {
      if (group?.uuid && entityId) {
        updateInvoice({
          payload: values as Partial<Invoice>,
          groupId: group.uuid,
          entityId: entityId,
          invoiceId,
        });
      }
      successToast({ title: "Successfully updated" });
      close();
      openReviewModal();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={open} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Confirm changes</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-text-text-30 t-text-subtext">
          Invoice will be updated. This can’t be reversed.
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button customType="secondary" onClick={close}>
            Cancel
          </Button>
          <Button customType="primary" onClick={updateChanges}>
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
