export const Team = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6385_3125)">
        <path
          d="M5.75858 9.29842C7.2217 9.29842 8.40779 8.11233 8.40779 6.64921C8.40779 5.18609 7.2217 4 5.75858 4C4.29547 4 3.10938 5.18609 3.10938 6.64921C3.10938 8.11233 4.29547 9.29842 5.75858 9.29842Z"
          stroke="#706A85"
          stroke-width="1.1412"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 11.3363C2.40711 10.7103 2.96411 10.196 3.62043 9.83989C4.27676 9.48383 5.01162 9.29733 5.75831 9.29733C6.505 9.29733 7.23986 9.48383 7.89619 9.83989C8.55251 10.196 9.10951 10.7103 9.51662 11.3363"
          stroke="#706A85"
          stroke-width="1.1412"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2417 9.2984C10.9884 9.29796 11.7233 9.48416 12.3796 9.84006C13.036 10.196 13.593 10.7103 14 11.3363"
          stroke="#706A85"
          stroke-width="1.1412"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.2583 4.1885C9.62069 4.04396 10.0105 3.98125 10.4 4.00485C10.7894 4.02845 11.1688 4.13777 11.5111 4.325C11.8534 4.51224 12.1501 4.77276 12.38 5.08796C12.6099 5.40317 12.7674 5.76526 12.8412 6.14837C12.9149 6.53149 12.9032 6.92617 12.8067 7.3042C12.7102 7.68224 12.5315 8.03431 12.2832 8.33524C12.0349 8.63618 11.7232 8.87856 11.3703 9.04506C11.0175 9.21156 10.6322 9.29807 10.2421 9.29842"
          stroke="#706A85"
          stroke-width="1.1412"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6385_3125">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
