import { multipleFileDownloads } from "apis/documents";
import { ConfirmReview } from "components/chat/DocumentReview/ConfirmReview";
import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import {
  TASK_ADDITIONAL_PAYMENT_CARD_OPENED,
  TASK_AMENDMENT_COMPLETED_CARD_OPENED,
  TASK_REVIEW_AND_PAY_CARD_OPENED,
  TASK_REVIEW_DOCUMENT_CARD_OPENED,
} from "constants/analyticsEvents";
import {
  TASK_ADDITIONAL_PAYMENT,
  TASK_REVIEW_AND_PAY,
  TASK_REVIEW_DOCUMENT,
} from "constants/chatType";
import { TASK_AMENDMENT_DOCUMENTS } from "constants/taskStates";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetTaskFromChannelIdQuery,
  useGetTaskQuery,
  useMarkDocumentAsReviewMutation,
} from "store/apis/task";
import {
  closeReviewConfirmModal,
  openBalancingPaymentModal,
  openDocumentReviewModal,
  setAmount,
  setCustomData,
  setDocumentForReview,
  setDocumentForReviewMessageId,
  setDocumentOperationType,
  setHasReviewed,
  setIsDocReviewed,
  setIspaymentRequired,
} from "store/slices/reviewAndBalancePayment";
import { RootState } from "store/store";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import {
  CustomData,
  CustomDataFile,
} from "types/Models/reviewDocumentAndBalancingPayment";
import { openLink } from "utils/openLink";
import { ChatCardButton } from "./ChatCardButton";
import { ReviewChatCardBody } from "./ReviewChatCardBody";

type IReviewMessageChatCardProps = {
  customData: CustomData;
  messageId: string;
};

export const ReviewMessageChatCard: FC<IReviewMessageChatCardProps> = ({
  customData,
  messageId,
}) => {
  const dispatch = useDispatch();
  const { channelTaskId } = useSelector((state: RootState) => state.chat);
  const { channel } = useChannelStateContext();

  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );

  const {
    activeChannelGroupId,
    documentForReviewMessageId,
    documentForReview,
    showReviewConfirmModal,
  } = useSelector((state: RootState) => state.reviewAndBalancePayment);
  const { channel: currentChannel } = useChatContext();
  const channelId = currentChannel?.id;
  const [documentMarkAsReview, { isLoading }] =
    useMarkDocumentAsReviewMutation();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const isPaymentRequired = customData.pending_payment?.payment_required;
  const isDocReviewed = customData.is_reviewed;
  const { trackEvent } = useAnalytics();

  const setAllData = () => {
    dispatch(setCustomData(customData));
    dispatch(setDocumentForReviewMessageId(messageId));
    dispatch(setIsDocReviewed(isDocReviewed));
    dispatch(setIspaymentRequired(isPaymentRequired));
    dispatch(setAmount(customData.pending_payment?.pending_amount));
  };

  const openReviewDocumentModal = (
    documentOperation: string,
    fileData: CustomDataFile[]
  ) => {
    setAllData();
    dispatch(setDocumentOperationType(documentOperation));
    dispatch(setDocumentForReview(fileData));
    dispatch(openDocumentReviewModal());
  };

  const markDocumentAsReview = async () => {
    try {
      const documentIds = documentForReview
        .map(({ uuid }: { uuid: string }) => uuid)
        .join(",");
      await documentMarkAsReview({
        channel_id: channelId!,
        group_id: groupId || activeChannelGroupId,
        task_id: channelTaskId,
        message_id: documentForReviewMessageId,
        document_ids: documentIds,
      }).unwrap();
      successToast({ message: "Document marked as reviewed" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const downloadFiles = async () => {
    try {
      const fileIds = customData?.ctas?.[0]?.metadata?.file_data?.map(
        ({ uuid }: { uuid: string }) => uuid
      );
      const response = await multipleFileDownloads({
        groupId: groupId || activeChannelGroupId,
        fileIds,
      });
      response.data.data.forEach(({ download_url }: { download_url: string }) =>
        openLink(download_url)
      );
    } catch (e) {}
  };

  const markAsReview = async () => {
    await markDocumentAsReview();
    dispatch(closeReviewConfirmModal());
  };

  const trackCardEvents = (documentOperation: string) => {
    switch (documentOperation) {
      case TASK_REVIEW_DOCUMENT:
        return trackEvent(TASK_REVIEW_DOCUMENT_CARD_OPENED);
      case TASK_REVIEW_AND_PAY:
        return trackEvent(TASK_REVIEW_AND_PAY_CARD_OPENED);
      case TASK_AMENDMENT_DOCUMENTS:
        return trackEvent(TASK_AMENDMENT_COMPLETED_CARD_OPENED);
      case TASK_ADDITIONAL_PAYMENT:
        return trackEvent(TASK_ADDITIONAL_PAYMENT_CARD_OPENED);

      default:
        break;
    }
  };

  const handleCardAction = (
    documentOperation: string,
    fileData: CustomDataFile[]
  ) => {
    trackCardEvents(documentOperation);
    if (isDocReviewed && isPaymentRequired && channelTask) {
      dispatch(setHasReviewed(documentOperation === TASK_REVIEW_AND_PAY));

      setAllData();
      if (channelTask?.entity?.uuid) {
        dispatch(openBalancingPaymentModal(channelTask?.entity?.uuid));
      }
    } else if (
      documentOperation === TASK_REVIEW_DOCUMENT ||
      documentOperation === TASK_REVIEW_AND_PAY ||
      documentOperation === TASK_AMENDMENT_DOCUMENTS
    ) {
      openReviewDocumentModal(documentOperation, fileData);
    }
  };

  const chatCardBody = () => {
    const documentOperation = customData?.ctas?.[0]?.document_operation;
    switch (documentOperation) {
      case TASK_ADDITIONAL_PAYMENT:
        return (
          <div className="t-text-subtext-sm t-text-text-60">
            {customData.description}
          </div>
        );
      default:
        return (
          <ReviewChatCardBody
            customData={customData}
            downloadFiles={downloadFiles}
          />
        );
    }
  };

  return (
    <div className="t-mb-2">
      <ChatCard.Root>
        <ChatCard.Header>{customData.header}</ChatCard.Header>
        <ChatCard.Body>{chatCardBody()}</ChatCard.Body>
        <ChatCard.Footer>
          {customData.ctas?.map(
            ({
              document_operation,
              metadata: { file_data },
              url_text,
            }: {
              document_operation: string;
              url_text: string;
              metadata: { file_data: CustomDataFile[] };
            }) => (
              <ChatCardButton
                key={document_operation}
                urlText={url_text}
                documentOperation={document_operation}
                isPaymentRequired={isPaymentRequired}
                isDocReviewed={isDocReviewed}
                disabled={!channelTask}
                handleCardAction={() =>
                  handleCardAction(document_operation, file_data)
                }
              />
            )
          )}
        </ChatCard.Footer>
      </ChatCard.Root>
      {showReviewConfirmModal && (
        <ConfirmReview
          show={showReviewConfirmModal}
          closeModal={() => dispatch(closeReviewConfirmModal())}
          isLoading={isLoading}
          markDocumentAsReview={markAsReview}
        />
      )}
    </div>
  );
};
