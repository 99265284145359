import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Search } from "components/DesignSystem/Search/Search";
import { Filter } from "components/icons/Filter";
import { MentionIcon } from "components/icons/MentionIcon";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearch,
  setSearchActiveState,
  setUnreadFilter,
  setShowMentionedMessages,
} from "store/slices/chatSearch";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { RootState } from "store/store";
import { useChatContext } from "stream-chat-react";
import { debounce } from "utils/debouncing";

export const ChannelSearch = () => {
  const dispatch = useDispatch();

  const { setActiveChannel } = useChatContext();
  const isUnreadFilterActive = useSelector(
    (state: RootState) => state.chatSearch.unread
  );
  const showMentionedMessages = useSelector(
    (state: RootState) => state.chatSearch.showMentionedMessages
  );
  const { isCustomer } = useRoleBasedView();

  const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
    dispatch(setScrollToMessageId(undefined));
  });

  const filterByUnread = () => {
    const enablingTheFilter = !isUnreadFilterActive;
    if (enablingTheFilter) {
      setActiveChannel(undefined);
    }
    dispatch(setUnreadFilter(!isUnreadFilterActive));
  };

  const handleMentionFilter = () => {
    dispatch(setShowMentionedMessages(!showMentionedMessages));
  };

  useEffect(() => {
    dispatch(setSearch(""));
    dispatch(setScrollToMessageId(""));
    return () => {
      dispatch(setShowMentionedMessages(false));
    };
  }, []);

  return (
    <div>
      <div className="t-flex t-w-full t-gap-3">
        <Search
          customSize="small"
          onChange={onChange}
          block
          placeholder="Search channel"
          onFocus={() => dispatch(setSearchActiveState(true))}
          onBlur={() => dispatch(setSearchActiveState(false))}
        />
        {!isCustomer && (
          <div className="t-text-text-30">
            <Button
              size="small"
              customType={
                showMentionedMessages ? "icon-primary-outlined" : "icon"
              }
              onClick={handleMentionFilter}
              active={showMentionedMessages}
            >
              <MentionIcon />
            </Button>
          </div>
        )}
        <div className="t-flex t-gap-2">
          <ToolTip text="Sort by Unread">
            <span>
              <Button
                size="small"
                customType="icon"
                onClick={filterByUnread}
                active={isUnreadFilterActive}
              >
                <Filter />
              </Button>
            </span>
          </ToolTip>
        </div>
      </div>
      {isUnreadFilterActive && (
        <span className="t-text-overline t-text-neutral t-uppercase">
          Sorted by unread
        </span>
      )}
    </div>
  );
};
