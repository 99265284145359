export const INITIATED = "INITIATED";
export const REJECTED_REQUEST = "ADMIN_REJECTED";
export const NOT_INITIATED = "NOT_INITIATED";
export const ADMIN_APPROVED = "ADMIN_APPROVED";
export const REJECT = "REJECT";
export const ACCEPT = "ACCEPT";
export const REQUESTED = "Requested";
export const ROLE_NOT_DECIDED = "ROLE_NOT_DECIDED";
export const JOINED = "Joined";
export const INVITED = "Invited";
export const ARCHIVED = "Archived";
export const FOREIGN_ENTITY_ACCOUNTANT = "Foreign Entity Accountant";
export const MEMBER = "Member";
export const ADMIN = "Admin";
