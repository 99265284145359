import { AutofillHome } from "components/AutoFill/AutofillHome";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import "../../static/styles/components/deals.css";
import "../../static/styles/components/entity.css";
import EntityTab from "./EntityTab";
import WForms from "./WForms";
import { AutofillPage } from "components/AutoFill/AutofillPage";
import EntityDetail from "./EntityDetail";
import { DashboardLayout } from "components/DashboardLayout";

const EntitiesHeader = ({ url }: { url: string }) => {
  const { pathname } = useLocation();

  return (
    <Header
      v2
      title="My Entities"
      bottom={
        <Tab.Root defaultValue={pathname} value={pathname}>
          <Tab.List>
            <Tab.NavTrigger exact value={url}>
              Overview
            </Tab.NavTrigger>
            <Tab.NavTrigger exact value={`${url}/autofill`}>
              Autofill
            </Tab.NavTrigger>
            <Tab.NavTrigger exact value={`${url}/w-form`}>
              W-Forms
            </Tab.NavTrigger>
          </Tab.List>
        </Tab.Root>
      }
    />
  );
};

export const Entities = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <DashboardLayout header={<EntitiesHeader url={url} />}>
          <EntityTab />
        </DashboardLayout>
      </Route>

      <Route exact path={`${path}/autofill`}>
        <DashboardLayout header={<EntitiesHeader url={url} />}>
          <AutofillHome />
        </DashboardLayout>
      </Route>

      <Route path={`${path}/autofill/addresses`}>
        <AutofillPage type="addresses" parentUrl={`${url}/autofill`} />
      </Route>

      <Route path={`${path}/autofill/bank_accounts`}>
        <AutofillPage type="bank_accounts" parentUrl={`${url}/autofill`} />
      </Route>

      <Route
        path={`${path}/autofill/authorized_persons`}
        render={() => (
          <AutofillPage
            type="authorized_persons"
            parentUrl={`${url}/autofill`}
          />
        )}
      />

      <Route path={`${path}/entity/:entityId`} component={EntityDetail} />

      <Route exact path={`${path}/w-form`}>
        <DashboardLayout header={<EntitiesHeader url={url} />}>
          <WForms />
        </DashboardLayout>
      </Route>
    </Switch>
  );
};
