export const GROUP_BOOKS_CONSOLIDATION_ONE_TIME =
  "GROUP_BOOKS_CONSOLIDATION_ONE_TIME";
export const GAAP_FINANCIALS = "GAAP_FINANCIALS";
export const AUDIT = "AUDIT";
export const FINANCIAL_MODELLING = "FINANCIAL_MODELLING";
export const RND = "RND";
export const HISTORICAL_CLEANUP = "HISTORICAL_CLEANUP";
export const GROUP_BOOKS_CONSOLIDATION_RECURRING = "GROUP_BOOKS_CONSOLIDATION";
export const PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING =
  "PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING";
export const PRO_MONTHLY_BOOKKEEPING = "PRO_MONTHLY_BOOKKEEPING";
export const PAN_CARD_APPLICATION = "PAN_CARD_APPLICATION";
export const TP_PAYMENTS_ONBOARDING = "TP_PAYMENTS_ONBOARDING";

export type ServiceType =
  | typeof GROUP_BOOKS_CONSOLIDATION_ONE_TIME
  | typeof GAAP_FINANCIALS
  | typeof AUDIT
  | typeof FINANCIAL_MODELLING
  | typeof GROUP_BOOKS_CONSOLIDATION_RECURRING
  | typeof PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING
  | typeof PRO_MONTHLY_BOOKKEEPING
  | typeof PAN_CARD_APPLICATION
  | typeof TP_PAYMENTS_ONBOARDING;

export type RNDType = typeof RND;
