import { BillingInvoice } from "types/Models/billing";
import { Task } from "types/Models/task";

export const getCreditUsageForCart = (
  cartInvoice: BillingInvoice,
  total_credits: number
) => {
  const discount = Number(cartInvoice.discount || "0");

  const amountToConsider = Number(cartInvoice.amount) - discount;

  const usableCredits =
    total_credits < amountToConsider ? total_credits : amountToConsider;

  return usableCredits;
};

export const getCreditUsageForBulkInvoices = (
  invoices: BillingInvoice[],
  total_credits: number,
  total_due?: number
) => {
  let totalAmount =
    total_due ||
    invoices.reduce((total, invoice) => total + Number(invoice.amount), 0);

  const usableCredits =
    total_credits < totalAmount ? total_credits : totalAmount;

  return usableCredits;
};

export const getCreditUsageForTask = (task: Task, total_credits: number) => {
  const amountToConsider = Number(
    task.coupon?.discounted_price || task.payment_amount
  );

  const usableCredits =
    total_credits < amountToConsider ? total_credits : amountToConsider;

  return usableCredits;
};
