export const TagSolid = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <g clipPath="url(#clip0_1729_63729)">
        <path
          d="M13.306 8.23731L7.875 2.80629C7.79405 2.72469 7.69768 2.65999 7.5915 2.61596C7.48531 2.57193 7.37144 2.54944 7.25649 2.54981H2.1875C2.07147 2.54981 1.96019 2.5959 1.87815 2.67795C1.7961 2.76 1.75 2.87128 1.75 2.98731V8.05629C1.74964 8.17124 1.77212 8.28512 1.81615 8.3913C1.86019 8.49748 1.92488 8.59385 2.00649 8.67481L7.4375 14.1058C7.51876 14.1871 7.61523 14.2516 7.7214 14.2956C7.82757 14.3395 7.94137 14.3622 8.05629 14.3622C8.17122 14.3622 8.28502 14.3395 8.39119 14.2956C8.49736 14.2516 8.59383 14.1871 8.67508 14.1058L13.306 9.47489C13.3873 9.39363 13.4518 9.29717 13.4957 9.19099C13.5397 9.08482 13.5624 8.97102 13.5624 8.8561C13.5624 8.74118 13.5397 8.62738 13.4957 8.5212C13.4518 8.41503 13.3873 8.31856 13.306 8.23731ZM4.59375 6.04981C4.46396 6.04981 4.33708 6.01132 4.22916 5.93921C4.12124 5.8671 4.03713 5.76461 3.98746 5.6447C3.93779 5.52478 3.92479 5.39283 3.95011 5.26553C3.97544 5.13823 4.03794 5.0213 4.12972 4.92952C4.22149 4.83774 4.33843 4.77524 4.46573 4.74992C4.59303 4.7246 4.72498 4.73759 4.84489 4.78726C4.9648 4.83693 5.0673 4.92105 5.13941 5.02897C5.21152 5.13689 5.25 5.26377 5.25 5.39356C5.25 5.56761 5.18086 5.73453 5.05779 5.8576C4.93472 5.98067 4.7678 6.04981 4.59375 6.04981Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1729_63729">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.799805)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
