import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SplitTransactionActionType } from "components/Transaction/SplitTransaction/SplitTransaction";
import {
  DELETE_SPLIT_TRANSACTION,
  EDIT_SPLIT_TRANSACTION,
} from "constants/transactionStates";
import { Transactions } from "types/Models/books";

export type SplitTransactionModalState = {
  showSplitTransactionModal: boolean;
  actionOnSplitTxns: SplitTransactionActionType;
  transaction: Transactions | {};
};

const initialState: SplitTransactionModalState = {
  showSplitTransactionModal: false,
  actionOnSplitTxns: "",
  transaction: {},
};

export const splitTransactionSlice = createSlice({
  name: "splitTransaction",
  initialState,
  reducers: {
    openSplitTransactionModal: (state, action: PayloadAction<Transactions>) => {
      state.transaction = action.payload;
      state.showSplitTransactionModal = true;
    },
    closeSplitTransactionModal: (state) => {
      state.showSplitTransactionModal = false;
      state.actionOnSplitTxns = "";
      state.transaction = {};
    },
    openEditSplitTransactionModal: (
      state,
      action: PayloadAction<Transactions>
    ) => {
      state.transaction = action.payload;
      state.actionOnSplitTxns = EDIT_SPLIT_TRANSACTION;
      state.showSplitTransactionModal = true;
    },
    openDeleteSplitTransactionModal: (
      state,
      action: PayloadAction<Transactions>
    ) => {
      state.transaction = action.payload;
      state.actionOnSplitTxns = DELETE_SPLIT_TRANSACTION;
      state.showSplitTransactionModal = true;
    },
    setActionOnSplitTxns: (
      state,
      action: PayloadAction<SplitTransactionActionType>
    ) => {
      state.actionOnSplitTxns = action.payload;
    },
    setTransactionForSplitTxns: (
      state,
      action: PayloadAction<Transactions>
    ) => {
      state.transaction = action.payload;
    },
  },
});

export const {
  openSplitTransactionModal,
  closeSplitTransactionModal,
  openEditSplitTransactionModal,
  openDeleteSplitTransactionModal,
  setActionOnSplitTxns,
  setTransactionForSplitTxns,
} = splitTransactionSlice.actions;

export default splitTransactionSlice.reducer;
