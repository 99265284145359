import * as Accordion from "@radix-ui/react-accordion";
import { useChatContext, usePaginatedChannels } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ChannelFilters, ChannelSort } from "stream-chat";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { MessageSearchList } from "components/MessageSearchList/MessageSearchList";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { useEffect } from "react";
import cx from "classnames";
import { ChannelSearchList } from "components/ChannelSearchList";
import { EmptyChannelList } from "components/EmptyChannelList";
import { ArrowDown } from "components/icons/ArrowDown";
import Loader from "components/design/loader";

const SearchCollapse = ({ title }: { title: string }) => {
  return (
    <Accordion.Trigger asChild>
      <button className="t-bg-surface-lighter-grey t-px-3 t-py-2 all:unset t-w-full t-flex t-justify-between t-items-center t-group t-sticky t-top-0 t-z-[1] t-flex-[0_0_auto]">
        <span className="t-text-overline t-uppercase">{title}</span>
        <span className="group-data-[state=open]:t-rotate-180 t-transform t-transition-transform">
          <button className="all:unset">
            <ArrowDown />
          </button>
        </span>
      </button>
    </Accordion.Trigger>
  );
};

export const ChatSearch = ({
  filters: propFilter,
  sort,
  unreplied,
  onSelect,
}: {
  filters?: ChannelFilters<DefaultStreamChatGenerics>;
  sort?: ChannelSort<DefaultStreamChatGenerics>;
  unreplied: boolean;
  onSelect?: (channelId: string, messageId?: string) => void;
}) => {
  const searchValue = useSelector((state: RootState) => state.chatSearch.value);
  const { client, channelsQueryState, setActiveChannel } = useChatContext();
  let channelFilters = { ...propFilter };
  const messageChannelFilters: ChannelFilters<DefaultStreamChatGenerics> = {
    ...propFilter,
  };
  const showMentionedMessages = useSelector(
    (state: RootState) => state.chatSearch.showMentionedMessages
  );

  if (searchValue) {
    channelFilters = {
      ...channelFilters,
      $or: [
        { group_name: { $autocomplete: searchValue } },
        {
          name: { $autocomplete: searchValue },
        },
      ],
    };
  }

  const {
    channels,
    hasNextPage,
    loadNextPage: loadNextChannelList,
  } = usePaginatedChannels(
    client,
    channelFilters || {},
    sort || [],
    {},
    () => {}
  );

  const { messages, loading, next, loadNextPage } = usePaginatedMessages(
    client,
    messageChannelFilters || {},
    sort || [],
    { limit: 15 },
    searchValue
  );

  useEffect(() => {
    setActiveChannel();
  }, []);

  if (channelsQueryState.queryInProgress === "reload" || loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (
    (channels.length === 0 && messages.length === 0) ||
    (showMentionedMessages && messages.length === 0)
  ) {
    return <EmptyChannelList />;
  }

  return (
    <div className="t-h-full">
      <Accordion.Root
        type="multiple"
        defaultValue={["CHANNELS", "MESSAGES"]}
        className="t-h-full"
      >
        {channels.length > 0 && !showMentionedMessages && (
          <Accordion.Item
            value="CHANNELS"
            className={cx("t-flex t-flex-col t-mb-1", {
              "data-state-open:t-h-full": channels.length >= 30,
            })}
          >
            <SearchCollapse title="Chats" />
            <Accordion.Content
              forceMount
              id="channelsScrollParent"
              className="t-overflow-y-auto data-state-open:t-block t-hidden t-w-full"
            >
              <ChannelSearchList
                searchValue={searchValue}
                onSelect={onSelect}
                unreplied={unreplied}
                windowElementId="channelsScrollParent"
                channels={channels}
                hasNextPage={hasNextPage}
                loadNextPage={loadNextChannelList}
              />
            </Accordion.Content>
          </Accordion.Item>
        )}
        {messages.length > 0 && (
          <Accordion.Item
            value="MESSAGES"
            className="data-state-open:t-h-full t-flex t-flex-col"
          >
            <SearchCollapse title="Messages" />
            <Accordion.Content
              className="t-flex-auto t-overflow-y-auto"
              id="messagesScrollParent"
            >
              <MessageSearchList
                onSelect={onSelect}
                searchTerm={searchValue}
                windowElementId="messagesScrollParent"
                messages={messages}
                loadNextPage={loadNextPage}
                hasNextPage={Boolean(next)}
              />
            </Accordion.Content>
          </Accordion.Item>
        )}
      </Accordion.Root>
    </div>
  );
};
