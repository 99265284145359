export const Pin = ({ size = "16" }: { size?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1849 6.58093L10.6171 10.1542C10.8915 10.9299 11.0074 12.2248 9.81202 13.819C9.72732 13.9309 9.61996 14.0236 9.49698 14.0909C9.374 14.1583 9.23819 14.1989 9.09845 14.21H9.03136C8.77287 14.2089 8.52523 14.1057 8.34219 13.9229L5.40255 10.9788L3.31064 13.08C3.21738 13.1703 3.09273 13.2208 2.96301 13.2208C2.83328 13.2208 2.70863 13.1703 2.61537 13.08C2.52375 12.9873 2.47235 12.8622 2.47235 12.7318C2.47235 12.6014 2.52375 12.4763 2.61537 12.3836L4.71338 10.2886L1.76153 7.33223C1.66361 7.2356 1.58732 7.11923 1.53773 6.99083C1.48813 6.86242 1.46636 6.72493 1.47386 6.58746C1.48135 6.44999 1.51795 6.31568 1.58121 6.19346C1.64448 6.07123 1.73297 5.96387 1.84082 5.8785C3.38993 4.62634 4.87195 4.87677 5.52452 5.07834L9.10455 1.49288C9.28826 1.31158 9.53581 1.20996 9.79372 1.20996C10.0516 1.20996 10.2992 1.31158 10.4829 1.49288L14.1849 5.2005C14.276 5.29081 14.3483 5.3983 14.3977 5.51678C14.447 5.63525 14.4724 5.76235 14.4724 5.89071C14.4724 6.01908 14.447 6.14618 14.3977 6.26465C14.3483 6.38312 14.276 6.49062 14.1849 6.58093Z"
      fill="currentColor"
    />
  </svg>
);
