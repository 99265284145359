export const InkleCommunitySymbol = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 16H28C34.6274 16 40 21.3726 40 28H28C21.3726 28 16 22.6274 16 16Z"
      fill="url(#paint0_linear_5738_8679)"
    />
    <path
      d="M16 28H28C34.6274 28 40 33.3726 40 40H28C21.3726 40 16 34.6274 16 28Z"
      fill="url(#paint1_linear_5738_8679)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5738_8679"
        x1="16"
        y1="22"
        x2="40"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5738_8679"
        x1="16"
        y1="34"
        x2="40"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
