import { AddressModal } from "components/AutoFill/AddressModal";
import { FormikValues } from "formik";
import { useToast } from "hooks/useToast";
import {
  useCreateAutofillMutation,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import { AddressAutofill } from "types/Models/addressAutofill";
import { AutofillComponentProps } from "types/Models/bankAutofill";
import { BackendError } from "types/utils/error";

export const AddEditAddress = ({
  isOpenAdd,
  isOpenEdit,
  autofillKey,
  initialValue: detailsForEdit,
  groupId,
  onSuccess,
  onClose,
  isEditFlow,
  entityId,
}: AutofillComponentProps & {
  initialValue?: AddressAutofill;
  onSuccess: (value: AddressAutofill) => void;
  entityId: string;
}) => {
  const [createAutofill, { isLoading: isAdding }] = useCreateAutofillMutation();
  const [editAutofill, { isLoading: isUpdating }] = useUpdateAutofillMutation();
  const { alertToast, successToast } = useToast();

  const addAddress = async (values: FormikValues) => {
    try {
      const address = (await createAutofill({
        groupId,
        autofillKey,
        fields: values,
        entityId,
      }).unwrap()) as AddressAutofill;
      successToast({ message: "Address added successfully!" });
      onSuccess(address);
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      onClose();
    }
  };

  const editAddress = async (values: FormikValues) => {
    try {
      const address = (await editAutofill({
        groupId,
        autofillKey,
        fields: values,
        uuid: detailsForEdit?.uuid!,
        entityId,
      }).unwrap()) as AddressAutofill;
      successToast({ message: "Address updated successfully!" });
      onSuccess(address);
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      onClose();
    }
  };

  return (
    <AddressModal
      isLoading={isUpdating || isAdding}
      initialValues={
        Boolean(detailsForEdit?.uuid) && isEditFlow
          ? { ...detailsForEdit, id: detailsForEdit?.uuid }
          : {}
      }
      onSubmit={Boolean(detailsForEdit?.uuid) ? editAddress : addAddress}
      onClose={onClose}
      open={isOpenAdd || Boolean(isOpenEdit)}
    />
  );
};
