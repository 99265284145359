import { LeftBarItem, LeftBarTag } from "components/Sidebar/LeftBar";
import { useMessageCount } from "hooks/useMessageCount";
import { Chat } from "components/icons/LeftNav/Chat";
import { ComponentProps } from "react";

export const ChatLeftNav = (
  props: Partial<ComponentProps<typeof LeftBarItem>>
) => {
  const messageCount = useMessageCount();
  return (
    <LeftBarItem
      suffix={
        Boolean(messageCount?.messages) &&
        messageCount?.messages &&
        messageCount?.messages > 0 && (
          <LeftBarTag type="blue">
            {messageCount.messages > 99 ? "+99" : messageCount.messages}
          </LeftBarTag>
        )
      }
      to="/chat"
      icon={Chat}
      {...props}
    >
      Chat
    </LeftBarItem>
  );
};
