export const NEW_ADHOC_IDENTIFIER = "NEW_ADHOC";
export const ADHOC_IDENTIFIER = "ADHOC";
export const TASK_LOG = "TaskLog";
export const TASK_EDIT = "TaskEdit";
export const SHADE7GREEN = "#24843f";
export const ADHOC_UPDATE = "ADHOC_UPDATE";
export const ACTIVE = "ACTIVE";
export const PLEASE_FILL_ALL_FIELDS = "Please fill all fields";
export const OTHERS = "Others";
export const TASK_SUCCESSFULLY_CREATED = "Filing successfully created!";
export const CREAETE_YOUR_GROUP_FIRST = "Create your group first!";
