import { ReactNode } from "react";
import { ComplianceFilingStatus } from "types/Models/calendar";

type BadgeProps = {
  type: ComplianceFilingStatus;
  children: ReactNode;
  className?: string;
};

const BADGE_TYPE_CLASSES = {
  NOT_STARTED: "t-bg-red-10 t-text-red",
  ONGOING: "t-bg-yellow-10 t-text-yellow-90",
  COMPLETED: "t-bg-dark_green-10 t-text-dark_green-90",
  ARCHIVED: "t-bg-neutral-0 t-text-neutral-70",
  OUTSIDE_INKLE: "t-bg-neutral-0 t-text-neutral-70",
  NOT_APPLICABLE: "t-bg-neutral-0 t-text-neutral-70",
};

export const DeadlineBadge = ({
  children,
  type,
  className = "",
}: BadgeProps) => {
  return (
    <div
      className={`${BADGE_TYPE_CLASSES[type]} t-w-max t-max-w-36 t-break-words t-rounded t-px-2 t-py-1 t-text-body-sm t-font-normal 
        t-line-clamp-1 ${className}`}
    >
      {children}
    </div>
  );
};
