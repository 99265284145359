import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { ProductCard } from "components/ProductCard/ProductCard";
import { BOOKS } from "constants/taskCategoryType";
import { useMemo } from "react";
import { useGetBaseTaskTemplatesQuery } from "store/apis/getTemplates";
import {
  GROUP_BOOKS_CONSOLIDATION_ONE_TIME,
  ServiceType,
  GROUP_BOOKS_CONSOLIDATION_RECURRING,
  PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
} from "types/Models/services";
import { BaseTaskTemplate } from "types/Models/taskTemplate";

type ServiceListCardsProps = {
  onHistoricalCleanup: () => void;
  onGroupMIS: () => void;
  openServiceAction: (serviceType: ServiceType) => void;
};

const DecideAction = ({
  base_task_key,
  onGroupMIS,
  openServiceAction,
}: {
  base_task_key: string;
  onGroupMIS: () => void;
  openServiceAction: (serviceType: ServiceType) => void;
}) => {
  if ((base_task_key as ServiceType) === GROUP_BOOKS_CONSOLIDATION_RECURRING) {
    return (
      <ConditionalLink to="/settings/billing" className="all:unset">
        <Button size="small" onClick={onGroupMIS}>
          Subscribe
        </Button>
      </ConditionalLink>
    );
  } else {
    return (
      <Button
        size="small"
        onClick={() => openServiceAction(base_task_key as ServiceType)}
      >
        Start now
      </Button>
    );
  }
};
const PriceFormatter = ({
  base_task_key,
  approx_price,
}: {
  base_task_key: string;
  approx_price: string;
}) => {
  if (base_task_key == GROUP_BOOKS_CONSOLIDATION_ONE_TIME) {
    return (
      <div className="t-gap-2 t-flex t-text-subtext">
        <AmountSuperScript amount={Number(approx_price)} />
        <span>/ consolidation</span>
      </div>
    );
  }

  if (approx_price === "TBD") {
    return <>${approx_price}</>;
  }

  return (
    <>
      <AmountSuperScript amount={Number(approx_price)} />
    </>
  );
};

export const ServiceListCards = ({
  onHistoricalCleanup,
  onGroupMIS,
  openServiceAction,
}: ServiceListCardsProps) => {
  const {
    data = {},
    isSuccess,
    isLoading,
  } = useGetBaseTaskTemplatesQuery({
    task_category_type: BOOKS,
  });

  const bookServicesList: BaseTaskTemplate[] = useMemo(() => {
    return Object.entries(data).flatMap(([categoryName, services]) => services);
  }, [data]);

  return (
    <div className="t-grid md:t-grid-cols-2 lg:t-grid-cols-3 t-gap-5">
      <Async.Root {...{ isEmpty: false, isLoading, isSuccess }}>
        <Async.Empty>-</Async.Empty>
        <Async.Success>
          {bookServicesList.map(
            ({ title, description, approx_price, base_task_key }) => {
              return (
                <ProductCard
                  key={base_task_key}
                  title={title}
                  description={description}
                  price={
                    <PriceFormatter
                      base_task_key={base_task_key}
                      approx_price={approx_price}
                    />
                  }
                  action={
                    <DecideAction
                      base_task_key={base_task_key}
                      onGroupMIS={onGroupMIS}
                      openServiceAction={openServiceAction}
                    />
                  }
                />
              );
            }
          )}
        </Async.Success>
      </Async.Root>
    </div>
  );
};
