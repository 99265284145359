export const MagnifyingGlass = ({ size = "16" }: { size?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 149 148"
      fill="none"
    >
      <path
        d="M68.3333 117.167C95.5794 117.167 117.667 95.0794 117.667 67.8333C117.667 40.5873 95.5794 18.5 68.3333 18.5C41.0873 18.5 19 40.5873 19 67.8333C19 95.0794 41.0873 117.167 68.3333 117.167Z"
        stroke="currentColor"
        strokeWidth="11.2727"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130 129.5L103.175 102.675"
        stroke="currentColor"
        strokeWidth="11.2727"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="46.3485"
        y="55.9394"
        width="42.8485"
        height="24.9091"
        rx="1"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle cx="57.6818" cy="68.3939" r="6.72727" fill="currentColor" />
      <rect
        x="67.7727"
        y="63.9091"
        width="16.8182"
        height="3.36364"
        fill="currentColor"
      />
      <rect
        x="67.7727"
        y="69.5152"
        width="11.2121"
        height="3.36364"
        fill="currentColor"
      />
    </svg>
  );
};
