import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Price } from "components/Price";
import { MAILROOM, REGISTERED_AGENT } from "constants/addons";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoGray from "static/images/InfoGray.svg";
import { useAddProductToCartMutation } from "store/apis/products";
import {
  useGetSubscriptionsQuery,
  useLazyGetSubscriptionsQuery,
} from "store/apis/subscriptions";
import {
  setAddonData,
  setAddonPayload,
  setAddonType,
  setOpenCheckoutModal,
} from "store/slices/addons";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { RootState } from "store/store";

export const MailRoom = () => {
  const { alertToast } = useToast();
  const { addonType, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonData?.subscription_type === MAILROOM
  );
  const { subscription_name, billing_cycle, amount, stripe_coupon } =
    addonData || {};

  const updatedAmount = stripe_coupon?.discounted_amount.toString() || amount;

  const dispatch = useDispatch();
  const [getRegisteredAgentAddon] = useLazyGetSubscriptionsQuery();

  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const [addToCart, { isLoading: addingToCart }] =
    useAddProductToCartMutation();

  const handleNotRegisteredAgent = async () => {
    try {
      const resp = await getRegisteredAgentAddon({
        subscription_types: REGISTERED_AGENT,
        groupId,
      }).unwrap();
      const registeredAgentUnselectedStates = resp
        ?.filter((ele) => !ele.group_subscription)
        ?.map((ele) => {
          return {
            amount: ele?.amount,
            type: ele?.metadata?.ra_state,
            uuid: ele?.uuid,
          };
        });

      dispatch(
        setAddonData({
          ...resp[0],
          unselectedStates: registeredAgentUnselectedStates,
        })
      );
      dispatch(setAddonType(REGISTERED_AGENT));
    } catch (error: any) {
      alertToast({
        message: error?.message || SOMETHING_WENT_WRONG,
      });
    }
  };

  const handleAddonNext = async () => {
    const subscription = subscriptions?.find((s) => s.uuid === addonData?.uuid);

    if (
      isCartSubscriptionActive &&
      groupId &&
      addonData?.uuid &&
      updatedAmount &&
      subscription
    ) {
      await addToCart({
        groupId: groupId,
        entityId,
        payload: {
          product_id: addonData.uuid,
          product_content_type_id: subscription.product_content_type_id,
        },
      });

      dispatch(setAddonType(""));
      setShowAddonModal(false);
      return;
    }

    dispatch(
      setAddonPayload({
        title: addonData?.subscription_name,
        amount: updatedAmount,
        uuid: addonData?.uuid,
        type: addonData?.subscription_type,
      })
    );
    dispatch(setUsableCredit(false));
    dispatch(setPaymentTitle(addonData?.subscription_name!));
    dispatch(setAddonType(""));
    dispatch(setOpenCheckoutModal());
    setShowAddonModal(false);
  };

  return (
    <Modal.Root
      open={showAddonModal}
      onOpenChange={() => {
        setShowAddonModal(false);
        dispatch(setAddonType(""));
      }}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{subscription_name}</Modal.Title>
          <Modal.Close />
        </Modal.Header>

        <Modal.Body>
          <div className="t-flex t-flex-col t-gap-4">
            <div className="t-text-body">
              Inkle provides a&nbsp;
              <ConditionalLink
                to="https://www.inkle.io/products/mailroom"
                className="all:unset t-text-blue-70 !t-underline t-cursor-pointer"
              >
                Virtual Mailroom
              </ConditionalLink>
              &nbsp;address as a monthly subscription. Currently we only provide
              this service in the state of Delaware.
            </div>

            {!addonData?.is_ra_subscribed && (
              <div className="t-border-solid t-border t-border-neutral-10 t-bg-surface-grey t-rounded t-flex t-gap-2 t-text-text-30 t-p-3 t-text-subtext-sm">
                <div>
                  <img src={InfoGray} alt="Info" />
                </div>
                <div>
                  Note: In order to subscribe to Mailroom, an
                  <button
                    className="all:unset t-text-purple"
                    onClick={handleNotRegisteredAgent}
                  >
                    &nbsp;Registered Agent&nbsp;
                  </button>
                  (Delaware) subscription is required.
                </div>
              </div>
            )}

            <Divider />
            <div className="t-flex t-justify-between">
              <div className="t-text-body-sm t-text-text-30">Total</div>
              <div className="t-text-body">
                <Price
                  size="medium"
                  cycle={billing_cycle!}
                  amount={updatedAmount}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowAddonModal(false);
              dispatch(setAddonType(""));
            }}
          >
            Cancel
          </Button>
          <Button
            customType="primary"
            onClick={handleAddonNext}
            disabled={!addonData?.is_ra_subscribed}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
