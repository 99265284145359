export const monthFullName = (month) => {
  switch (month) {
    case "JAN":
      return "January";
    case "FEB":
      return "February";
    case "MAR":
      return "March";
    case "APR":
      return "April";
    case "MAY":
      return "May";
    case "JUN":
      return "June";
    case "JUL":
      return "July";
    case "AUG":
      return "August";
    case "SEP":
      return "September";
    case "OCT":
      return "October";
    case "NOV":
      return "November";
    case "DEC":
      return "December";
    default:
      return;
  }
};

export const mapdataMonth = (booksAllMonthData, booksData, isFiscalYear) => {
  let newArrays = [];
  const localDate = new Date();
  let currentMonth = localDate.getMonth();
  let j = 0;
  let i = 0;
  while (i < currentMonth + 1) {
    if (booksAllMonthData[i]?.month === booksData[j]?.month) {
      newArrays.push(booksData[j]);
      j++;
    } else {
      newArrays.push(booksAllMonthData[i]);
    }
    i++;
  }

  if (isFiscalYear) {
    newArrays.splice(0, 3);
  }

  const QuaterSize = 3;
  const groups = newArrays
    .map((e, i) => {
      return i % QuaterSize === 0 ? newArrays.slice(i, i + QuaterSize) : null;
    })
    .filter((e) => {
      return e;
    });
  return groups;
};
