import { Coupon } from "types/Models/subscription";
import { pluralize } from "utils/pluralize";
import { Badge } from "./design/badge";
import * as STRIPE_COUPON_TYPES from "constants/stripeCouponTypes";
import * as STRIPE_COUPON_PERIODS from "constants/stripeCouponPeriods";

export const Discount = ({
  coupon,
  amount,
}: {
  coupon?: Coupon;
  amount?: number | null;
}) => {
  if (!coupon || !amount) {
    return null;
  }

  const {
    discount_rate,
    discount_type,
    coupon_code,
    duration_type,
    duration_period,
  } = coupon || {};

  const discount =
    discount_type === STRIPE_COUPON_TYPES.PERCENT
      ? `${discount_rate}%`
      : `$${discount_rate}`;

  return (
    <div className="t-flex t-flex-col t-gap-2">
      <div className="t-flex t-items-center t-gap-1.5 t-text-body">
        <Badge color="light-green">{discount} off</Badge>
        <span className="t-text-neutral-40 t-line-through">
          ${amount}/month
        </span>
      </div>
      <span className="t-text-body-sm">
        {duration_type === STRIPE_COUPON_PERIODS.MONTHLY ? (
          <div className="t-text-neutral">
            <span className="t-font-semibold">{coupon_code} </span>
            expires in{" "}
            <span className="t-font-semibold">
              {pluralize(duration_period || 0, "month", "months")}
            </span>
          </div>
        ) : (
          `${coupon_code} never expires`
        )}
      </span>
    </div>
  );
};
