import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import QueryString, { ParsedQs } from "qs";

export const vendorsSeasonOptions = ["2024", "2023", "2022"];
export const vendorsTypeOptions = [
  { label: "All vendors", value: "allVendors" },
  { label: "W form", value: "wForm" },
];

export const vendorsInitialState = {
  filters: {
    season: {
      name: "season",
      value: "2023",
      type: "season",
    },
    vendorsType: {
      name: "vendorsType",
      value: vendorsTypeOptions[0].value,
      type: "vendorsType",
    },
  },
};

export type VendorsFilterName = "season" | "vendorsType";

export const vendorsFilter = createSlice({
  name: "vendorsFilter",
  initialState: vendorsInitialState,
  reducers: {
    setVendorsFilter: (state, action: PayloadAction<QueryString.ParsedQs>) => {
      const payload = action.payload;

      const updatedValues = Object.keys(payload).reduce((acc, el) => {
        //@ts-ignore
        const value = payload[el];
        const newState = {
          ...vendorsInitialState.filters?.[el as VendorsFilterName],
          value: value as null | string | ParsedQs | string[] | ParsedQs[],
        };

        return { ...acc, [el]: { ...newState } };
      }, {});

      state.filters = {
        ...state.filters,
        ...updatedValues,
      };
    },
  },
});

export const { setVendorsFilter } = vendorsFilter.actions;

export default vendorsFilter.reducer;
