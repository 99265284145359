import { PaymentMethodCard } from "components/PaymentMethodCard/PaymentMethodCard";
import { SavePaymentResponse } from "types/Models/SavedCards";
import AddedCardOptions from "./AddedCardOptions";

const AddedCard = ({
  cardDetail,
  entityId,
}: {
  cardDetail: SavePaymentResponse;
  entityId: string;
}) => {
  const isDefaultCard = cardDetail.is_default_card;
  const expiryMonth = cardDetail.card.exp_month?.toString()?.padStart(2, "0");
  const expiryYear = cardDetail.card.exp_year
    ?.toString()
    .substring(cardDetail.card.exp_year?.toString().length - 2);

  return (
    <PaymentMethodCard
      brand={cardDetail?.card?.brand}
      mask={cardDetail.card.last_four}
      option={
        <AddedCardOptions
          entityId={entityId}
          isDefaultCard={isDefaultCard}
          paymentMethodId={cardDetail?.payment_method_id}
        />
      }
      expiryMonth={expiryMonth}
      expiryYear={expiryYear}
      isDefaultCard={isDefaultCard}
    />
  );
};

export default AddedCard;
