import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { AUTOFILL_ADDRESS_TYPE_TAG } from "constants/billing";
import { MAILROOM_ADDRESS } from "constants/mailRoom";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { useRemoveMailroomDefaultAddressMutation } from "store/apis/mailroom";

export const RemoveForwardMessageModal = ({
  isOpen,
  close,
  addressIdToRemove,
}: {
  isOpen: boolean;
  close: () => void;
  addressIdToRemove: string;
}) => {
  const { data: tagsData = [] } = useGetTagsForCategoryQuery({
    tagType: AUTOFILL_ADDRESS_TYPE_TAG,
  });

  const mailroomAddressTagId = tagsData.find(
    ({ title }) => title === MAILROOM_ADDRESS
  )?.uuid;
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [removeMailroomDefaultAddress, { isLoading: isDeleting }] =
    useRemoveMailroomDefaultAddressMutation();

  const handleRemoveAddress = async () => {
    try {
      await removeMailroomDefaultAddress({
        addressId: addressIdToRemove,
        tagId: mailroomAddressTagId!,
        groupId,
        tagCategory: "AUTOFILL_ADDRESS_TYPE_TAG",
      }).unwrap();
      successToast({ message: "Address deleted" });
      close();
    } catch (error: any) {
      alertToast({
        message: error?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Remove this address?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Are you sure you want to remove this address?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-3">
            <Button onClick={close}>Cancel</Button>
            <Button
              customType="danger"
              onClick={handleRemoveAddress}
              isLoading={isDeleting}
              disabled={isDeleting}
            >
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
