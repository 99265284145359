import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { LogoutIcon } from "components/icons/LogoutIcon";
import Logo from "components/inkleLogo";
import { PHONE } from "constants/help";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import WhatsappIcon from "static/images/whatsappIcon.png";
import { setOnboardingStep } from "store/slices/onboarding";
import Loader from "../components/design/loader";
import RightSlider from "../components/design/RightSlider";
import Help from "../components/help/help";
import ProfileHelpIcon from "../components/icons/ProfileHelpIcon";
import OnBoardingAction from "../components/onboarding/onboardingAction/onBoardingAction";
import authContext from "../jwt_context&axios/authContext";
import "../static/styles/components/onboarding.css";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { profileApi, useLazyGetUserProfileQuery } from "store/apis/profile";
import { useRedirectOnAuth } from "hooks/useRedirectOnAuth";
import { BackendError } from "types/utils/error";

const Profile = () => {
  usePageTitle("Group creation");
  const { alertToast } = useToast();
  const { updateUser, logoutUser } = useContext(authContext);
  const [showHelp, setShowHelp] = useState(false);
  const [isBlink, setBlink] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useRedirectOnAuth();

  const [getProfile] = useLazyGetUserProfileQuery();

  useEffect(() => {
    dispatch(setOnboardingStep(3));
    setTimeout(() => {
      setBlink(true);
    }, 5000);
  }, []);

  let updateUserData = async () => {
    try {
      const res = await getProfile().unwrap();
      updateUser({ data: { data: res } });
      profileApi.util.invalidateTags(["PROFILE"]);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      alertToast({ message: (e as BackendError).data?.error?.message });
    }
  };

  useEffect(() => {
    updateUserData();
  }, []);

  const openModal = () => setShowHelp(true);
  const closeModal = () => setShowHelp(false);

  return isLoading ? (
    <Loader />
  ) : (
    <DashboardContainer className="t-h-screen">
      <ConditionalLink to={`https://wa.me/${PHONE}`}>
        <button className="t-fixed t-bottom-3 t-right-8 t-z-50 t-flex t-h-10 t-w-10 t-items-center t-justify-center t-rounded-full t-border-0 t-bg-[transparent] t-bg-dark_green-30 t-shadow-hover-card-shadow t-drop-shadow-bubble">
          <img className="s t-h-full" src={WhatsappIcon} alt="WhatsappIcon" />
        </button>
      </ConditionalLink>
      <DashboardContainer.Header>
        <div className="t-sticky t-top-0 t-z-10 t-flex t-h-20 t-w-full t-items-center t-justify-between t-bg-surface">
          <Logo className="t-mb-0 t-ml-16" />
          <div className="t-mr-8 t-flex t-items-center t-gap-5">
            <div
              className="helpSection !t-relative !t-top-0 t-right-0 "
              onClick={openModal}
            >
              <span className="t-z-50  t-flex t-items-center t-text-text-100 hover:t-text-purple">
                <ProfileHelpIcon color="currentColor" />
                <span className="helpText t-ml-1 !t-text-body !t-font-medium">
                  Help
                </span>{" "}
                {isBlink && (
                  <div className="t-h-3 t-w-3 t-bg-red t-ml-2 t-rounded-full t-animate-blink" />
                )}
              </span>
            </div>
            <span className="t-text-text-100 hover:t-text-purple">
              <Button customType="transparent" onClick={logoutUser}>
                <LogoutIcon color="currentColor" />
                <span className="t-ml-1 t-text-body t-font-medium">Logout</span>
              </Button>
            </span>
          </div>
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-h-full">
        <OnBoardingAction />
        {showHelp && (
          <RightSlider show={showHelp} closeModal={closeModal}>
            <Help closeModal={closeModal} loggedOut />
          </RightSlider>
        )}
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};

export default Profile;
