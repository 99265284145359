import {
  deleteMerchantFile,
  updateMerchantForm,
  updateTaskMerchant,
} from "apis/merchants";
import { NOT_SPECIFIED } from "constants/wSeriesFormTypes";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import { FC, useState } from "react";
import { Merchant, W_FORM_TYPES } from "types/Models/merchant";
import Modal from "./DesignSystem/Modal/Modal";
import { WSeriesUploadForm } from "./WSeriesUploadForm";

type IWSeriesFormUploadProps = {
  show: boolean;
  closeModal: () => void;
  taskId?: string;
  groupId: string;
  merchant: Merchant;
  editable: boolean;
  updateMerchant: (merchant: Merchant) => void;
  openModal: () => void;
  season: string;
};

export const WSeriesFormUpload: FC<IWSeriesFormUploadProps> = ({
  taskId,
  groupId,
  show,
  closeModal,
  merchant,
  editable = true,
  updateMerchant,
  openModal,
  season,
}) => {
  const { w_form_document, form_status, w_form_choice, uuid, name } =
    merchant || {};
  const { alertToast, successToast } = useToast();
  const openPreview = useDocPreview();
  const [isDeleting, setDeleting] = useState(false);

  const onFormTypeSubmit = async ({
    form_type,
    file,
  }: {
    form_type: W_FORM_TYPES;
    file?: File;
  }) => {
    try {
      if (taskId) {
        const { data } = await updateTaskMerchant({
          taskId,
          groupId,
          merchantId: uuid,
          form_type,
          file,
          season,
        });
        updateMerchant(data);
      } else {
        const { data } = await updateMerchantForm({
          // do we need it here
          groupId,
          merchantId: uuid,
          form_type,
          file,
          season,
        });
        updateMerchant(data);
      }
      closeModal();
      successToast({ message: "Your form has been saved" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const onDelete = async () => {
    try {
      setDeleting(true);
      const { data } = await deleteMerchantFile({
        groupId,
        merchantId: uuid,
        season: merchant.w_form_season,
      });
      updateMerchant(data);
      setDeleting(false);
      successToast({ message: "Form has been removed" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
      setDeleting(false);
    }
  };

  const fileToShow = w_form_document;
  const nonFormText = form_status === "SENT" ? "W-Series" : "Add form";
  const buttonText =
    w_form_choice === NOT_SPECIFIED ? nonFormText : w_form_choice;

  const onClick = () => {
    if (!editable && w_form_document) {
      openPreview(w_form_document.uuid);

      return;
    }
    openModal();
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-p-0 md:!t-w-[440px]">
          <Modal.Header>
            <Modal.Title>Upload W-form for {name}</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <div className="t-border t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-p-6">
            <WSeriesUploadForm
              isDeleting={isDeleting}
              onDelete={onDelete}
              file={fileToShow}
              onSubmit={onFormTypeSubmit}
              openPreview={() => openPreview(w_form_document?.uuid!)}
              form_document={w_form_document}
              w_form_choice={w_form_choice}
            />
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
