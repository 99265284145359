export const Cart = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1337_7316)">
        <path
          d="M11.25 11.5H5.19812C4.96394 11.5 4.73719 11.4177 4.55741 11.2677C4.37764 11.1176 4.25624 10.9092 4.21438 10.6788L2.62 1.91062C2.59907 1.79542 2.53837 1.69121 2.44848 1.61617C2.35859 1.54113 2.24522 1.50002 2.12812 1.5H1"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 14C5.94036 14 6.5 13.4404 6.5 12.75C6.5 12.0596 5.94036 11.5 5.25 11.5C4.55964 11.5 4 12.0596 4 12.75C4 13.4404 4.55964 14 5.25 14Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 14C11.9404 14 12.5 13.4404 12.5 12.75C12.5 12.0596 11.9404 11.5 11.25 11.5C10.5596 11.5 10 12.0596 10 12.75C10 13.4404 10.5596 14 11.25 14Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.90937 9H11.7563C11.9904 8.99997 12.2172 8.91774 12.397 8.76766C12.5767 8.61758 12.6981 8.40917 12.74 8.17875L13.5 4H3"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1337_7316">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
