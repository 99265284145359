import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CustomData,
  CustomDataFile,
  LocalFileOrInkleDocs,
} from "types/Models/reviewDocumentAndBalancingPayment";
import * as FILE_TYPE from "constants/fileTypes";

export type previewData = {
  file_type: keyof typeof FILE_TYPE;
  is_previewable: boolean;
  name: string;
  task_document_map_id: string[];
  task_document_type: string;
  uuid: string;
};

export type docReviewPayload = {
  amount?: string;
  description?: string;
  document_operation: string;
};

export const initialDocumentReviewPayload = {
  amount: "",
  description: "",
  document_operation: "",
};

export type ReviewBalancePaymentState = {
  customAttachment: LocalFileOrInkleDocs[];
  taskReviewBalancePaymentStep: number;
  documentForReview: CustomDataFile[];
  activeChannelGroupId: string;
  documentForReviewMessageId: string;
  documentOperationType: string;
  docReviewPayload: docReviewPayload;
  showDocumentReviewModal: boolean;
  showReviewConfirmModal: boolean;
  amount: string;
  showBalancingPaymentModal: boolean;
  isPaymentRequired: boolean;
  isDocReviewed: boolean;
  customData: CustomData | null;
  hasReviewed: boolean;
  entityId: string | null;
};

const initialState: ReviewBalancePaymentState = {
  customAttachment: [] as LocalFileOrInkleDocs[],
  taskReviewBalancePaymentStep: 1,
  documentForReview: [],
  activeChannelGroupId: "",
  documentForReviewMessageId: "",
  documentOperationType: "",
  docReviewPayload: initialDocumentReviewPayload,
  showDocumentReviewModal: false,
  showReviewConfirmModal: false,
  amount: "",
  showBalancingPaymentModal: false,
  isPaymentRequired: false,
  isDocReviewed: false,
  customData: null,
  hasReviewed: true,
  entityId: null,
};

export const reviewAndBalancePaymentSlice = createSlice({
  name: "reviewAndBalancePayment",
  initialState,
  reducers: {
    setCustomAttachments: (state, action: PayloadAction<any[]>) => {
      const allAttachments = removeDuplicatesAttachmentFile([
        ...state.customAttachment,
        ...action.payload,
      ]);
      state.customAttachment = allAttachments;
    },
    clearCustomAttachment: (state) => {
      state.customAttachment = [];
    },
    deleteCustomAttachment: (state, action: PayloadAction<string>) => {
      state.customAttachment = state.customAttachment.filter((file) => {
        if ("path" in file) {
          return file.path !== action.payload;
        }
        if ("uuid" in file) {
          return file.uuid !== action.payload;
        }
        return true;
      });
    },
    setTaskReviewBalancePaymentStep: (state, action: PayloadAction<number>) => {
      state.taskReviewBalancePaymentStep = action.payload;
    },
    setDocumentForReview: (state, action: PayloadAction<CustomDataFile[]>) => {
      state.documentForReview = action.payload;
    },
    setActiveChannelGroupId: (state, action: PayloadAction<string>) => {
      state.activeChannelGroupId = action.payload;
    },
    setDocumentForReviewMessageId: (state, action: PayloadAction<string>) => {
      state.documentForReviewMessageId = action.payload;
    },
    setDocumentOperationType: (state, action: PayloadAction<string>) => {
      state.documentOperationType = action.payload;
    },
    setDocumentReviewPayload: (
      state,
      action: PayloadAction<docReviewPayload>
    ) => {
      state.docReviewPayload = action.payload;
    },
    openDocumentReviewModal: (state) => {
      state.showDocumentReviewModal = true;
    },
    closeDocumentReviewModal: (state) => {
      state.showDocumentReviewModal = false;
    },
    openReviewConfirmModal: (state) => {
      state.showReviewConfirmModal = true;
    },
    closeReviewConfirmModal: (state) => {
      state.showReviewConfirmModal = false;
      state.entityId = null;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    openBalancingPaymentModal: (state, action: PayloadAction<string>) => {
      state.showBalancingPaymentModal = true;
      state.entityId = action.payload;
    },
    closeBalancingPaymentModal: (state) => {
      state.showBalancingPaymentModal = false;
    },
    setIspaymentRequired: (state, action: PayloadAction<boolean>) => {
      state.isPaymentRequired = action.payload;
    },
    setIsDocReviewed: (state, action: PayloadAction<boolean>) => {
      state.isDocReviewed = action.payload;
    },
    resetReviewBalancePaymentState: (state) => {
      return initialState;
    },
    setCustomData: (state, action: PayloadAction<CustomData>) => {
      state.customData = action.payload;
    },
    setHasReviewed: (state, action: PayloadAction<boolean>) => {
      state.hasReviewed = action.payload;
    },
  },
});

export const {
  setCustomAttachments,
  clearCustomAttachment,
  deleteCustomAttachment,
  setTaskReviewBalancePaymentStep,
  setDocumentForReview,
  setActiveChannelGroupId,
  setDocumentForReviewMessageId,
  setDocumentOperationType,
  setDocumentReviewPayload,
  resetReviewBalancePaymentState,
  openBalancingPaymentModal,
  closeBalancingPaymentModal,
  setIsDocReviewed,
  setIspaymentRequired,
  setAmount,
  openReviewConfirmModal,
  openDocumentReviewModal,
  closeReviewConfirmModal,
  closeDocumentReviewModal,
  setCustomData,
  setHasReviewed,
} = reviewAndBalancePaymentSlice.actions;

export default reviewAndBalancePaymentSlice.reducer;

export const removeDuplicatesAttachmentFile = (arr: LocalFileOrInkleDocs[]) => {
  const seen = new Set();

  return arr.filter((item: LocalFileOrInkleDocs) => {
    let identifier;
    if ("uuid" in item) {
      identifier = item.uuid;
    }
    if ("path" in item) {
      identifier = item.path;
    }

    if (!seen.has(identifier)) {
      seen.add(identifier);
      return true;
    }

    return false;
  });
};
