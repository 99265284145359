import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Table from "components/DesignSystem/Table/V2/Table";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import AddTeam from "static/images/AddTeam.svg";
import { AddInternalTeam } from "./AddInternalTeam";
import { useState } from "react";
import {
  InternalTeamResponse,
  useGetInternalTeamsQuery,
} from "store/apis/internalTeamSetting";
import { InternalTeamOptions } from "./InternalTeamOptions";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { TableUI } from "components/design/TableUI";

export const InternalTeam = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: teams = [] } = useGetInternalTeamsQuery(
    { groupId: groupId! },
    { skip: !groupId }
  );
  const {
    open: openAddTeamModal,
    close: closeAddTeamModal,
    isOpen: showAddTeamModal,
  } = useModal();
  const [teamToEdit, setTeamToEdit] = useState<InternalTeamResponse | null>();

  const closeModal = () => {
    setTeamToEdit(null);
    closeAddTeamModal();
  };

  const openTeamEditModal = (team: InternalTeamResponse) => {
    setTeamToEdit(team);
    openAddTeamModal();
  };

  const columnHelper = createColumnHelper<InternalTeamResponse>();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <div className="t-flex t-gap-2 t-items-center t-p-4">
            <Avatar src={rowData.logo || ""} alt={info.getValue()} />
            <div className="t-text-subtext t-text-text-60">
              {info.getValue()}
            </div>
          </div>
        );
      },
      header: () => <div className="t-py-2 t-px-4">TEAM</div>,
      size: 30,
    }),
    columnHelper.accessor("is_all_access_allowed", {
      cell: (info) => {
        return (
          <div className="t-text-subtext t-text-text-60">
            {info.getValue() ? "Full access" : "Limited access"}
          </div>
        );
      },
      header: "STATUS",
      size: 30,
    }),
    columnHelper.accessor("task_access_count", {
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <Button size="small" onClick={() => openTeamEditModal(rowData)}>
            {rowData.is_all_access_allowed
              ? "All filings"
              : `${rowData.task_access_count} filings`}
          </Button>
        );
      },
      header: "ACCESS",
      size: 30,
    }),
    columnHelper.display({
      id: "action",
      header: "",
      cell: (info) => {
        return <InternalTeamOptions team={info.row.original} />;
      },
      size: 2,
    }),
  ];

  const table = useReactTable({
    data: teams,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  return (
    <div className="t-mx-6">
      <Header
        title="Internal Team"
        right={
          <div className="t-flex t-items-center t-gap-2">
            <Button
              customType="primary"
              size="small"
              onClick={openAddTeamModal}
            >
              <img src={AddTeam} alt="AddUserIcon" /> &nbsp; Add Team
            </Button>
          </div>
        }
        className="!t-px-0"
      />

      <TableUI table={table} />
      <AddInternalTeam
        show={showAddTeamModal}
        closeModal={closeModal}
        team={teamToEdit}
      />
    </div>
  );
};
