export const CheckOutline = () => (
  <>
    <span className="t-sr-only">CheckOutline</span>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_15_9169)">
        <path
          d="M2.5 9L6 12.5L14 4.5"
          stroke="currentColor"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15_9169">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);
