export const InkleSalesTaxSymbol = () => (
  <svg
    width="57"
    height="56"
    viewBox="0 0 57 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.8965 33.251C37.8989 32.2875 40.6465 30.2984 40.6465 28.001C40.6465 25.7035 37.8989 23.7144 33.8965 22.751C34.3082 24.3307 34.54 26.1138 34.54 28.001C34.54 29.8881 34.3082 31.6713 33.8965 33.251Z"
      fill="url(#paint0_linear_6302_7274)"
    />
    <path
      d="M23.3965 33.251C19.3941 32.2875 16.6465 30.2984 16.6465 28.001C16.6465 25.7035 19.3941 23.7144 23.3965 22.751C22.9847 24.3307 22.753 26.1138 22.753 28.001C22.753 29.8881 22.9847 31.6713 23.3965 33.251Z"
      fill="url(#paint1_linear_6302_7274)"
    />
    <path
      d="M23.3965 33.251C24.3599 37.2534 26.349 40.001 28.6465 40.001C30.9439 40.001 32.933 37.2534 33.8965 33.251C32.3168 33.6627 30.5336 33.8945 28.6465 33.8945C26.7593 33.8945 24.9762 33.6627 23.3965 33.251Z"
      fill="url(#paint2_linear_6302_7274)"
    />
    <path
      d="M23.3965 22.751C24.3599 18.7486 26.349 16.001 28.6465 16.001C30.9439 16.001 32.933 18.7486 33.8965 22.751C32.3168 22.3392 30.5336 22.1075 28.6465 22.1075C26.7593 22.1075 24.9762 22.3392 23.3965 22.751Z"
      fill="url(#paint3_linear_6302_7274)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6302_7274"
        x1="33.8965"
        y1="28.001"
        x2="40.6465"
        y2="28.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6302_7274"
        x1="16.6465"
        y1="28.001"
        x2="23.3965"
        y2="28.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6302_7274"
        x1="23.3965"
        y1="36.626"
        x2="33.8965"
        y2="36.626"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6302_7274"
        x1="23.3965"
        y1="19.376"
        x2="33.8965"
        y2="19.376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
