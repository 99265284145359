import classNames from "classnames";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowDown } from "components/icons/ArrowDown";
import { Cross } from "components/icons/Cross";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { MultiValue } from "react-select";
import {
  useForwardMessageToWhatsappMutation,
  useGetAllChannelMemberQuery,
} from "store/apis/chat";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import {
  MessageUIComponentProps,
  useChannelStateContext,
} from "stream-chat-react";
import { BackendError } from "types/utils/error";

export const SendToWhatsapp = ({
  isOpen,
  close,
  message,
}: {
  isOpen: boolean;
  close: () => void;
  message: MessageUIComponentProps["message"] & {
    forwarded_message?: MessageUIComponentProps["message"];
  };
}) => {
  const { channel } = useChannelStateContext();
  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );
  const groupId = currentChannelData?.company_group?.uuid;
  const { data: members, isLoading } = useGetAllChannelMemberQuery(
    {
      groupId: groupId!,
      channelId: channel.id!,
    },
    {
      skip: !channel.id || !groupId,
    }
  );
  const membersWithPhone = members?.filter(({ mobile }) => Boolean(mobile));
  const [forwardMessageToWhatsapp, { isLoading: isForwarding }] =
    useForwardMessageToWhatsappMutation();
  const [selectedMembers, setSelectedMembers] = useState<
    {
      label: string;
      value: {
        email: string;
        mobile: string;
        profile_url: string | null;
        name: string;
      };
    }[]
  >([]);
  const { alertToast, successToast } = useToast();

  const onChange = (values?: MultiValue<OptionData>) => {
    const parsedValues =
      values?.map(({ label, value }) => ({
        label: label as string,
        value: JSON.parse(value),
      })) || [];
    setSelectedMembers(parsedValues);
  };

  const handleSelectAll = () => {
    const parsedValues =
      membersWithPhone?.map(({ name, email, mobile, profile_url }) => ({
        label: name,
        value: { email, mobile: mobile!, name, profile_url },
      })) || [];
    setSelectedMembers(parsedValues);
  };

  const handleResetAll = () => {
    setSelectedMembers([]);
  };

  const forwardMessage = async () => {
    try {
      await forwardMessageToWhatsapp({
        groupId: groupId!,
        channelId: channel.id!,
        messageId: message.id,
        payload: {
          text: message.text || "",
          to_users: selectedMembers.map(({ value }) => value),
        },
      }).unwrap();
      successToast({ message: "Message forwarded successfully" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      close();
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Modal.Content useCustomOverlay size="small">
        <Modal.Header>
          <Modal.Title>Forward To</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Combobox
            label="This message will be forwarded to"
            components={{
              DropdownIndicator: (props) => (
                <div
                  className={classNames("t-text-text-60 t-p-1", {
                    "t-rotate-180": props.selectProps.menuIsOpen,
                  })}
                >
                  <ArrowDown color="currentColor" />
                </div>
              ),
              ClearIndicator: () => (
                <div
                  className="t-text-text-60 t-p-1"
                  role="button"
                  onClick={handleResetAll}
                >
                  <Cross color="currentColor" />
                </div>
              ),
            }}
            menuPortalTarget={document.body}
            isMulti
            styles={{
              menu: () => ({ width: "400px" }),
            }}
            placeholder="Select"
            name="members"
            //@ts-ignore
            onChange={onChange}
            options={
              membersWithPhone?.map((member) => ({
                label: member.name,
                value: JSON.stringify({
                  email: member.email,
                  mobile: member.mobile,
                  profile_url: member.profile_url,
                  name: member.name,
                }),
              }))!
            }
            value={
              selectedMembers.length > 0
                ? selectedMembers?.map((member) => ({
                    value: JSON.stringify({
                      email: member.value.email,
                      mobile: member.value.mobile,
                      profile_url: member.value.profile_url,
                      name: member.value.name,
                    }),
                    label: member.label,
                  }))
                : null
            }
            actions={
              <div>
                <Button customType="text" size="small" onClick={handleResetAll}>
                  Reset Selection
                </Button>
                <Button
                  customType="text"
                  size="small"
                  onClick={handleSelectAll}
                >
                  Select All
                </Button>
              </div>
            }
            isLoading={isLoading}
          />
          <div className="t-mt-4 t-flex t-flex-col t-gap-4">
            {selectedMembers?.map(({ value }) => (
              <div
                className="t-flex t-w-full t-gap-2 t-items-center"
                key={value?.email}
              >
                <Avatar
                  src={value?.profile_url as string}
                  alt={value?.name || ""}
                  size="regular"
                />
                <div className="t-flex t-flex-col t-w-full">
                  <div className="t-text-subtext-sm t-text-text-60">
                    {value.name}
                  </div>
                  <div className="t-text-body-sm t-text-text-30">
                    <div>{value.mobile}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="t-justify-end t-flex t-gap-3">
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="primary"
            onClick={forwardMessage}
            disabled={selectedMembers.length === 0 || isForwarding}
            isLoading={isForwarding}
          >
            Forward
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
