import { createSelector } from "@reduxjs/toolkit";
import { FilterName } from "store/slices/transactionFilter";
import { RootState } from "store/store";

const getTxnFilterState = (state: RootState) => state.transactionFilter;

export const getFilterStatus = createSelector(
  [getTxnFilterState],
  ({ filters }) => {
    const amount = Object.values(filters).filter(
      ({ type }) => type === "amount"
    );
    const transactionDate = Object.values(filters).filter(
      ({ type }) => type === "transactionDate"
    );
    const sources = Object.values(filters).filter(
      ({ type }) => type === "sources"
    );
    const others = Object.values(filters).filter(
      ({ type }) => type === "others"
    );
    const category = Object.values(filters).filter(
      ({ type }) => type === "category"
    );
    const vendors = Object.values(filters).filter(
      ({ type }) => type === "vendors"
    );
    const fromIds = Object.values(filters).filter(
      ({ type }) => type === "from"
    );

    const appliedFilterCount = [
      ...amount,
      ...transactionDate,
      ...others,
      ...sources,
      ...category,
      ...vendors,
      ...fromIds,
    ].filter(({ value }) => value)?.length;

    const capsuleFilters = Object.values(filters)
      .filter(({ name }) => Boolean(name))
      .filter(({ value, fixed }) => value && !fixed);

    const fixedFilters = Object.values(filters).filter(({ fixed }) => fixed);

    const getFilterName = (name: string) => {
      for (let key in filters) {
        // @ts-ignore
        if (filters.hasOwnProperty(key) && filters[key].name === name) {
          return key as FilterName;
        }
      }
    };

    return {
      appliedFilterCount,
      capsuleFilters,
      getFilterName,
      filters: {
        amount,
        transactionDate,
        others,
        sources,
        category,
        vendors,
        fromIds,
      },
      fixedFilters,
    };
  }
);
