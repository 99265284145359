import React from "react";
import Modal from "components/Modal/Modal";
import TPInfo from "static/images/TPInfo.svg";

export const TPInfoModal = ({ showTPInfo, setShowTPInfo }: any) => {
  return (
    <Modal.Root open={showTPInfo} onOpenChange={setShowTPInfo}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Close />
          <div className="t-p-16">
            <img src={TPInfo} alt="TPflow" />
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
