export const InkleTax = () => (
  <svg
    width="158"
    height="56"
    viewBox="0 0 158 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7191 24.0048L19.5993 32.9999L17.0308 30.4665C15.6564 29.1109 15.6564 26.9129 17.0308 25.5572L23.6619 19.0168C25.0364 17.6611 27.2648 17.6611 28.6393 19.0168L28.7191 19.0954C30.0935 20.4511 30.0935 22.6491 28.7191 24.0048Z"
      fill="url(#paint0_linear_5723_7726)"
    />
    <path
      d="M23.2808 31.9951L32.4006 23L34.9691 25.5334C36.3435 26.889 36.3435 29.087 34.9691 30.4427L28.338 36.9831C26.9635 38.3388 24.7351 38.3388 23.3606 36.9831L23.2808 36.9045C21.9064 35.5488 21.9064 33.3508 23.2808 31.9951Z"
      fill="url(#paint1_linear_5723_7726)"
    />
    <path
      d="M46 20.378C46 19.1746 46.78 18.3945 48.0058 18.3945C49.2092 18.3945 49.9892 19.1746 49.9892 20.378C49.9892 21.5815 49.2092 22.3838 48.0058 22.3838C46.78 22.3838 46 21.5815 46 20.378ZM49.7887 37.4939H46.2229V24.1221H49.7887V37.4939Z"
      fill="url(#paint2_linear_5723_7726)"
    />
    <path
      d="M60.7288 30.7857C60.7288 28.8245 59.7037 27.5319 58.1213 27.5319C56.6282 27.5319 55.5361 28.9137 55.5361 30.808V37.4939H51.9703V24.1221H55.5361V25.6821C56.0041 24.6793 57.3859 23.9661 58.7676 23.9661C62.1774 23.9661 64.2946 26.685 64.2946 30.7857V37.4939H60.7288V30.7857Z"
      fill="url(#paint3_linear_5723_7726)"
    />
    <path
      d="M70.0118 37.4939H66.446V18.5505H70.0118V30.1171L74.5359 24.1221H78.436L73.8896 30.808L78.904 37.4939H74.3799L70.0118 31.4989V37.4939Z"
      fill="url(#paint4_linear_5723_7726)"
    />
    <path
      d="M83.8046 37.4939H80.2388V18.5505H83.8046V37.4939Z"
      fill="url(#paint5_linear_5723_7726)"
    />
    <path
      d="M94.6458 33.3932H98.4345C97.5208 35.8893 95.1361 37.6053 92.4172 37.6053C88.5171 37.6053 85.7759 34.7749 85.7759 30.7634C85.7759 26.8856 88.6063 23.9438 92.3503 23.9438C96.0944 23.9438 98.9248 26.8856 98.9248 30.7634C98.9248 31.1868 98.9025 31.6326 98.8356 32.0337H89.52C89.9211 33.3932 91.0132 34.2624 92.3949 34.2624C93.4424 34.2624 94.267 33.9058 94.6458 33.3932ZM89.52 29.4708H95.1807C94.7795 28.1559 93.7321 27.309 92.3503 27.309C90.9686 27.309 89.9211 28.1559 89.52 29.4708Z"
      fill="url(#paint6_linear_5723_7726)"
    />
    <path
      d="M111.387 37.4835H109.206V22.334H103.925V20.1523H116.668V22.334H111.387V37.4835Z"
      fill="#41395C"
    />
    <path
      d="M126.085 37.4835V35.1998C125.534 36.6271 123.781 37.6058 121.946 37.6058C118.439 37.6058 116.114 35.1183 116.114 31.3462C116.114 27.5945 118.418 25.107 121.946 25.107C123.76 25.107 125.534 26.0857 126.085 27.5334V25.2497H128.267V37.4835H126.085ZM118.316 31.3462C118.316 33.793 119.907 35.4037 122.211 35.4037C124.494 35.4037 126.085 33.793 126.085 31.3666C126.085 28.9606 124.454 27.2887 122.19 27.2887C119.886 27.2887 118.316 28.8995 118.316 31.3462Z"
      fill="#41395C"
    />
    <path
      d="M129.841 37.4835L134.164 31.3666L129.841 25.2497H132.472L135.469 29.4908L138.466 25.2497H141.117L136.794 31.3666L141.117 37.4835H138.466L135.469 33.2424L132.472 37.4835H129.841Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7726"
        x1="16"
        y1="25.5"
        x2="29.7499"
        y2="25.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7726"
        x1="22.25"
        y1="30.5"
        x2="35.9999"
        y2="30.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7726"
        x1="46"
        y1="27.9999"
        x2="98.9248"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7726"
        x1="46"
        y1="27.9999"
        x2="98.9248"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5723_7726"
        x1="46"
        y1="27.9999"
        x2="98.9248"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5723_7726"
        x1="46"
        y1="27.9999"
        x2="98.9248"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5723_7726"
        x1="46"
        y1="27.9999"
        x2="98.9248"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
