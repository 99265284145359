import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { ConditionalLink } from "components/conditionalLink";
import { Badge } from "components/design/badge";
import { ArrowRight } from "components/icons/ArrowRight";
import { Cross } from "components/icons/Cross";
import { PlusIcon } from "components/icons/PlusIcon";
import { ARCHIVED } from "constants/task";
import dayjs from "dayjs";
import { TASK_STATE_TO_COLOR } from "dictionaries";
import { Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import SmallWhatsappIcon from "static/images/SmallWhatsappIcon.svg";
import {
  useAddMemberToChannelMutation,
  useGetAllSharedMessageQuery,
  useGetMembersCanBeAddedToChannelQuery,
} from "store/apis/chat";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import {
  openShareViaWhatsappSlider,
  setChannelInfoSliderActive,
  setMediaSliderActive,
} from "store/slices/channelInfoSlider";
import { expandDashboardSidebar } from "store/slices/dashboard";
import { RootState } from "store/store";
import { ChannelMemberResponse } from "stream-chat";
import {
  DefaultStreamChatGenerics,
  useChannelPreviewInfo,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import { BackendError } from "types/utils/error";
import { pluralize } from "utils/pluralize";

const InfoAccordion = ({
  members,
  title,
}: {
  members: ChannelMemberResponse<DefaultStreamChatGenerics>[];
  title: string;
}) => {
  const { channel } = useChannelStateContext();
  const { isAdmin } = useRoleBasedView();
  const inviteModal = useModal();

  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );

  const groupId = currentChannelData?.company_group?.uuid;

  const { data: membersCanBeInvited } = useGetMembersCanBeAddedToChannelQuery(
    {
      channelId: channel.id!,
      groupId: groupId!,
    },
    {
      skip: !channel.id || !groupId || !isAdmin,
    }
  );

  const [addMmeberToChannel, { isLoading: addingMemberToChannel }] =
    useAddMemberToChannelMutation();

  const { successToast, alertToast } = useToast();

  const onAddMemberToChannel = async (values: { member: string }) => {
    try {
      await addMmeberToChannel({
        channelId: channel.id!,
        memberId: values.member,
        groupId: groupId!,
      }).unwrap();
      successToast({ message: "Member added successfully" });
      inviteModal.close();
    } catch (error: any) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };
  return (
    <div className="t-border t-border-solid t-border-neutral-0 t-rounded">
      <Accordion.Root type="single" collapsible>
        <Accordion.Item value={title || "CompanyGroup"}>
          <Accordion.Trigger asChild>
            <div className="t-group t-w-full t-h-full t-flex t-p-2 t-items-center t-justify-between">
              <div className="t-flex t-items-center t-gap-2 t-h-full">
                <div className="t-text-subtext t-h-full">
                  {title || "Company Group"} Team
                </div>
                <div className="t-flex t-text-body-sm t-text-text-30 t-h-full t-items-center">
                  ({members?.length} members)
                </div>
              </div>
              <div
                className={classNames(
                  "group-data-state-open:-t-rotate-90 group-data-state-closed:t-rotate-90"
                )}
              >
                <ArrowRight />
              </div>
            </div>
          </Accordion.Trigger>
          <Accordion.Content>
            <div className="t-flex t-flex-col t-gap-3 t-p-2">
              {Object.entries(members).map(([memberId, member]) => {
                return (
                  <div className="t-flex t-items-center t-gap-2" key={memberId}>
                    <Avatar
                      lettersCount={2}
                      size="regular"
                      src={member?.user?.image || ""}
                      alt={member?.user?.name || ""}
                      className="t-w-8 t-h-8 t-rounded-full"
                    />
                    <p className="t-m-0 t-text-body">
                      {member?.user?.name || ""}
                    </p>
                  </div>
                );
              })}
              {isAdmin && title === "Inkle" && (
                <Modal.Root
                  open={inviteModal.isOpen}
                  onOpenChange={inviteModal.toggle}
                >
                  <Modal.Trigger asChild>
                    <span>
                      <Button size="small">
                        <div className="t-flex t-items-center t-gap-1">
                          <PlusIcon />
                          <span>Invite</span>
                        </div>
                      </Button>
                    </span>
                  </Modal.Trigger>
                  <Formik
                    initialValues={{ member: "" }}
                    onSubmit={onAddMemberToChannel}
                  >
                    <Modal.Content asChild useCustomOverlay>
                      <Form className="t-m-0">
                        <Modal.Header>
                          <Modal.Title>Invite Team Member</Modal.Title>
                          <Modal.Close />
                        </Modal.Header>
                        <Modal.Body>
                          <Combobox
                            withForm
                            name="member"
                            menuPortalTarget={document.body}
                            label="Select user"
                            options={membersCanBeInvited?.map((member) => ({
                              value: member.member_uuid,
                              label: member.name,
                            }))}
                          />
                        </Modal.Body>
                        <Modal.FooterButtonGroup>
                          <Modal.RawClose asChild>
                            <Button type="button">Cancel</Button>
                          </Modal.RawClose>
                          <Button
                            type="submit"
                            customType="primary"
                            isLoading={addingMemberToChannel}
                            disabled={addingMemberToChannel}
                          >
                            Invite
                          </Button>
                        </Modal.FooterButtonGroup>
                      </Form>
                    </Modal.Content>
                  </Formik>
                </Modal.Root>
              )}
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  );
};

const InfoItem = ({ title, value }: { title: string; value: ReactNode }) => {
  return (
    <div className="t-space-y-1.5">
      <p className="t-text-overline t-text-text-30 t-m-0">{title}</p>
      {value}
    </div>
  );
};

const TaskLink = () => {
  const { channel } = useChannelStateContext();
  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );
  const { displayTitle } = useChannelPreviewInfo({
    channel,
  });

  if (
    !(
      currentChannelData &&
      currentChannelData?.tasks &&
      currentChannelData?.tasks.length > 0
    )
  ) {
    return null;
  }

  if (currentChannelData.tasks.length > 1) {
    return (
      <InfoItem
        title="FILLINGS"
        value={
          <div className="t-flex t-gap-2 t-flex-col">
            <div>
              <p className="t-m-0 t-text-body">{displayTitle}</p>
              <p className="t-m-0 t-text-body-sm t-text-text-30">
                Includes {currentChannelData.tasks.length} filings
              </p>
            </div>
            {currentChannelData.tasks.map((task) => {
              const taskStateType = task?.is_archived
                ? ARCHIVED
                : task?.current_state?.type || "Not Started";

              const taskState = task?.is_archived
                ? "Archived"
                : task?.current_state?.name || "Not Started";

              return (
                <div className="t-flex t-justify-between" key={task.uuid}>
                  <ConditionalLink
                    to={`/filings/${task?.uuid}`}
                    className="t-text-body t-text-purple hover:!t-underline t-truncate t-w-3/5 t-text-ellipsis"
                  >
                    {task.name}
                  </ConditionalLink>
                  <div className="t-flex-1 t-shrink-0 t-w-2/5 t-flex t-justify-end">
                    <Badge
                      color={TASK_STATE_TO_COLOR[taskStateType]}
                      size="small"
                    >
                      {taskState}
                    </Badge>
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
    );
  }

  const task = currentChannelData?.tasks[0];

  const taskStateType = task?.is_archived
    ? ARCHIVED
    : task?.current_state?.type || "Not Started";

  const taskState = task?.is_archived
    ? "Archived"
    : task?.current_state?.name || "Not Started";

  return (
    <>
      <InfoItem
        title="FILLING"
        value={
          <ConditionalLink
            to={`/filings/${task?.uuid}`}
            className="t-text-body t-text-purple hover:!t-underline"
          >
            {task.name}
          </ConditionalLink>
        }
      />
      {(task?.current_state || task?.is_archived) && (
        <InfoItem
          title="STEP"
          value={
            <span className="t-flex">
              <Badge color={TASK_STATE_TO_COLOR[taskStateType]} size="small">
                {taskState}
              </Badge>
            </span>
          }
        />
      )}
    </>
  );
};

const Info = () => {
  const { channel } = useChannelStateContext();
  const { isCustomer } = useRoleBasedView();
  const dispatch = useDispatch();
  const { isAdmin, isCpa } = useRoleBasedView();
  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );
  const groupId = currentChannelData?.company_group?.uuid;

  const { data: allSharedMessage } = useGetAllSharedMessageQuery(
    { groupId: groupId!, channelId: channel.id! },
    { skip: !channel.id || !groupId }
  );

  const entity = currentChannelData?.entity;
  const task = currentChannelData?.tasks[0];
  const companyGroup = currentChannelData?.company_group;
  const entityLink = isCustomer ? `/entities` : `/crm/${companyGroup?.uuid}`;

  const openAllSharedMessages = () => {
    dispatch(openShareViaWhatsappSlider());
  };

  return (
    <>
      {entity && (
        <InfoItem
          title="ENTITY"
          value={
            <ConditionalLink
              to={entityLink}
              className="t-flex t-text-body after:t-ml-2 t-text-text-100 hover:t-text-purple hover:!t-underline t-items-center t-gap-2"
            >
              <ReactCountryFlag
                style={{
                  width: "14px",
                  height: "10px",
                }}
                svg
                countryCode={entity.code_alpha_2}
              />
              <span className="t-max-w-[120px] t-truncate">{entity.name}</span>
            </ConditionalLink>
          }
        />
      )}

      <TaskLink />

      {task?.deadline && (
        <InfoItem
          title="DEADLINE"
          // @ts-ignore
          value={
            <p className="t-m-0 t-text-body">
              {dayjs(task.deadline).format("DD-MMM-YYYY")}
            </p>
          }
        />
      )}

      {task?.eligibility && (
        <InfoItem
          title="ELIGIBILITY"
          // @ts-ignore
          value={<p className="t-m-0 t-text-body">{task.eligibility}</p>}
        />
      )}
      {isAdmin &&
        Boolean(
          allSharedMessage?.forwarded_messages_count &&
            allSharedMessage?.forwarded_messages_count > 0
        ) && (
          <InfoItem
            title="SHARED VIA WHATSAPP"
            value={
              <p
                className="t-m-0 t-text-body t-flex t-justify-between t-items-center t-px-2 t-py-1 t-border t-border-solid t-border-neutral-0 t-rounded"
                onClick={openAllSharedMessages}
                role="button"
              >
                <span className="t-flex t-items-center t-gap-1">
                  <img
                    src={SmallWhatsappIcon}
                    alt="SmallWhatsappIcon"
                    height="16"
                  />
                  {pluralize(
                    allSharedMessage?.forwarded_messages_count as number,
                    "message",
                    "messages"
                  )}{" "}
                  sent
                </span>

                <Button customType="ghost_icon" size="small">
                  <ArrowRight />
                </Button>
              </p>
            }
          />
        )}
    </>
  );
};

export const Attachments = () => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const dispatch = useDispatch();

  const { messages: messagesWithImages } = usePaginatedMessages(
    client,
    {
      id: channel.id,
    },
    {},
    { limit: 5 },
    {
      "attachments.type": { $in: ["image"] },
    }
  );

  const { messages: messagesWithAttatchments } = usePaginatedMessages(
    client,
    {
      id: channel.id,
    },
    {},
    { limit: 5 },
    {
      attachments: { $exists: true },
    }
  );

  const images = messagesWithImages
    .flatMap((m) => m.message.attachments)
    .filter((m) => m?.type === "image")
    .slice(0, 5);

  const attachments = messagesWithAttatchments.flatMap(
    (m) => m.message.attachments
  );

  const openMediaSlider = () => {
    dispatch(setMediaSliderActive(true));
  };

  if (attachments.length === 0) {
    return null;
  }

  return (
    <div className="t-space-y-2 t-text-text-30">
      <div
        className="t-flex t-justify-between t-items-center t-cursor-pointer"
        role="button"
        onClick={openMediaSlider}
      >
        <p className="t-m-0 t-text-overline">MEDIA AND DOCUMENTS</p>
        <Button customType="ghost_icon" size="small">
          <ArrowRight />
        </Button>
      </div>
      <div className="t-flex t-gap-3 t-overflow-hidden">
        {images.map((image) => (
          <img
            key={image?.id}
            src={image?.image_url}
            className="t-h-16 t-w-16 t-object-cover t-rounded t-border t-border-neutral-0 t-border-solid"
            alt={image?.fallback}
          />
        ))}
      </div>
    </div>
  );
};

const Team = () => {
  const { channel } = useChannelStateContext();

  // const serviceTeams = new Set();

  // Object.entries(channel?.state?.members).forEach(([key, value]) => {
  //   const serviceTeamName = (value?.user?.service_team as { name: string })
  //     ?.name;
  //   serviceTeams.add(serviceTeamName);
  // });

  const serviceTeams = Object.entries(channel?.state?.members).map(
    ([key, value]) => {
      const serviceTeamName = (value?.user?.service_team as { name: string })
        ?.name;
      return serviceTeamName;
    }
  );

  const uniqueServiceTeams = new Set(serviceTeams);

  const membersInServiceTeams = [...uniqueServiceTeams].map((serviceTeam) => {
    const teamMembers = Object.entries(channel?.state?.members)
      .filter(([key, value]) => {
        return (
          serviceTeam === (value?.user?.service_team as { name: string })?.name
        );
      })
      ?.map(([key, value]) => value);
    return { name: serviceTeam, members: teamMembers };
  });

  return (
    <InfoItem
      title="TEAMS"
      value={
        <div className="t-flex t-flex-col t-gap-3">
          {membersInServiceTeams.map(({ name, members }) => (
            <InfoAccordion
              members={members}
              title={name as string}
              key={name}
            />
          ))}
        </div>
      }
    />
  );
};

export const ChannelInfoSlider = () => {
  const dispatch = useDispatch();
  const { isCpa } = useRoleBasedView();
  const { channel } = useChannelStateContext();
  const sliderOpen = useSelector(
    (store: RootState) => store.channelInfoSlider.isActive
  );
  const expandSidebarOnMessageSearchClose = useSelector(
    (store: RootState) => store.channelInfoSlider.setSideBarToExpandOnClose
  );

  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );
  const groupId = currentChannelData?.company_group?.uuid;

  const { data: allSharedMessage } = useGetAllSharedMessageQuery(
    { groupId: groupId!, channelId: channel.id! },
    { skip: !channel.id || !groupId }
  );

  const onChannelInfoClose = () => {
    dispatch(setChannelInfoSliderActive({ isActive: false }));
    if (expandSidebarOnMessageSearchClose) {
      dispatch(expandDashboardSidebar());
    }
  };

  return (
    <div
      className={classNames(
        "t-transition-all t-grow t-shrink-0 t-bg-surface t-overflow-x-hidden t-z-0",
        {
          "!t-w-[360px] t-border-0 t-border-l t-border-solid t-border-neutral-10":
            sliderOpen,
          "!t-w-0": !sliderOpen,
        }
      )}
    >
      {sliderOpen && (
        <>
          <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center">
            <span className="t-text-subtitle">Details</span>
            <Button
              customType="ghost_icon"
              size="small"
              onClick={onChannelInfoClose}
            >
              <Cross />
            </Button>
          </div>
          <div className="t-flex t-flex-col t-gap-4 t-px-5 t-pb-5 t-pt-2">
            {(currentChannelData?.entity ||
              currentChannelData?.tasks[0] ||
              Boolean(
                allSharedMessage?.forwarded_messages_count &&
                  allSharedMessage?.forwarded_messages_count > 0
              )) && (
              <>
                <Info />
                <hr className="t-m-0 t-text-neutral-20" />
              </>
            )}
            <Attachments />
            <Team />
          </div>
        </>
      )}
    </div>
  );
};
