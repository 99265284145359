import classNames from "classnames";
import { SUBSCRIPTION_CANCELLATION_MODAL_OPENED } from "constants/analyticsEvents";
import { YEARLY } from "constants/billingCycles";
import { MONTHLY } from "constants/stripeCouponPeriods";
import { SubscriptionsContext } from "contexts/SubscriptionsContext";
import { useAnalytics } from "hooks/useAnalytics";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetEntitiesQuery } from "store/apis/group";
import { SubscriptionInProfile } from "types/Models/subscription";
import { Badge } from "./design/badge";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";
import { Discount } from "./Discount";
import { Price } from "./Price";
import SubscriptionCancelModal from "./SubscriptionCancelModal";
import { SubscriptionModal } from "./SubscriptionModal/SubscriptionModal";
import dayjs from "dayjs";
import { formatDate } from "utils/formatDate";

export const SubscriptionUpdateModal = ({
  isOpen,
  handleClose,
  freeSeats,
  extraSeats,
  activeSeats,
  setShowSeatManage,
}: {
  isOpen: boolean;
  handleClose: () => void;
  freeSeats: number;
  extraSeats: number;
  activeSeats: number;
  setShowSeatManage: (v: boolean) => void;
}) => {
  const { subscriptions } = useContext(SubscriptionsContext);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [isConfirmationOpen, setConfirmationModal] = useState(false);
  const { groupId } = useParams<{ groupId?: string }>();
  const { authtoken } = useContext(authContext);
  const { platform_subscription: groupPlatformSubscription } =
    useCurrentGroupContext();
  const { data: { groups: [group] = [null] } = {} } = useGetEntitiesQuery();

  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const { isCpa, isGroupAdmin } = useRoleBasedView();

  const closeSubscriptionModal = () => {
    setSubscriptionModal(false);
  };
  const { trackEvent } = useAnalytics();

  let {
    platform_subscription: currentSubscription,
  }: {
    platform_subscription: SubscriptionInProfile;
  } = authtoken || {};

  if (groupId) {
    currentSubscription = groupPlatformSubscription;
  }

  // show the change plan cta only to the existing customers who have monthly recurring subscription
  const isMonthlySubscribedCustomer =
    currentSubscription.billing_cycle === "MONTHLY";

  const isCurrentYearIncorporated =
    dayjs(currentSubscription.scheduled_date)
      .subtract(1, "year")
      .year()
      .toString() === new Date().getFullYear().toString();

  const onCancelSubscription = () => {
    trackEvent(SUBSCRIPTION_CANCELLATION_MODAL_OPENED, {
      subscription_id: currentSubscription.uuid,
    });
    setConfirmationModal(true);
    handleClose();
  };

  const isAnnualSubscription = currentSubscription.billing_cycle === YEARLY;
  const subscriptionAmount =
    currentSubscription.stripe_coupon?.discounted_amount ||
    currentSubscription.amount;

  const upgradable = subscriptions.some(
    ({ upgrade_level }) =>
      upgrade_level > (currentSubscription.upgrade_level || 0)
  );

  const openSeatManage = () => {
    handleClose();
    setShowSeatManage(true);
  };

  const openSubscription = () => {
    setSubscriptionModal(true);
    handleClose();
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={handleClose}>
        <Modal.Content size="regular">
          <div className="t-mb-3 t-flex t-flex-col t-gap-6">
            <Modal.Header>
              <Modal.Title>Subscription summary</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <div className="t-mb-1 t-flex t-justify-between">
                <p className="t-mb-0 t-text-subtext t-font-medium">
                  {currentSubscription.subscription_name || "Free plan"}
                </p>
                {isCurrentYearIncorporated ? (
                  <div className="t-flex t-text-body-sm t-text-neutral-70 t-bg-neutral-0 t-rounded-3xl t-px-3 t-items-center">
                    Next payment:{" "}
                    {formatDate(currentSubscription?.scheduled_date)}
                  </div>
                ) : (
                  <Badge color="dark-purple">
                    <span className="t-text-caption"> CURRENT PLAN</span>
                  </Badge>
                )}
              </div>
              <div className="t-flex t-gap-2 t-items-center">
                <Price
                  amount={subscriptionAmount}
                  size="small"
                  cycle={isAnnualSubscription ? YEARLY : MONTHLY}
                  type={isCurrentYearIncorporated ? "STRIKE_THROUGH" : "NORMAL"}
                />
                {isCurrentYearIncorporated && (
                  <div className="t-text-subtext">Free</div>
                )}
              </div>
              {isCurrentYearIncorporated && (
                <div className="t-text-body-sm t-text-text-30 t-mt-2.5">
                  Current year is free for entities incorporated this year.
                </div>
              )}
              <div className="t-mt-2">
                <Discount
                  coupon={currentSubscription.stripe_coupon}
                  amount={currentSubscription.amount}
                />
              </div>
              <div className="t-mt-4 t-border-[0.5px] t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-pt-4">
                <div className="t-text-body t-text-text-60 t-flex t-justify-between">
                  Tier seats(included in plan):
                  <span className="t-text-subtext-sm t-text-text-100">
                    {freeSeats}
                  </span>
                </div>
                <div className="t-mt-1 t-text-body t-text-text-60 t-flex t-justify-between">
                  Seats added:{" "}
                  <span className="t-text-subtext-sm t-text-text-100">
                    {extraSeats}
                  </span>
                </div>
                <div className="t-mt-1 t-text-body t-text-text-60 t-flex t-justify-between">
                  Active users:{" "}
                  <span className="t-text-subtext-sm t-text-text-100">
                    {activeSeats}
                  </span>
                </div>
              </div>
            </Modal.Body>
            {currentSubscription.uuid && (
              <Modal.Footer>
                <div className="t-flex t-gap-2 t-justify-between">
                  <Button onClick={onCancelSubscription} customType="ghost">
                    Cancel subscription
                  </Button>
                  <div className="t-flex t-gap-2">
                    {isGroupAdmin && (
                      <Button onClick={openSeatManage}>Manage seats</Button>
                    )}
                    {isMonthlySubscribedCustomer && (
                      <Button
                        onClick={openSubscription}
                        customType="primary"
                        disabled={
                          !upgradable || isCartSubscriptionActive
                            ? false
                            : isCpa
                        }
                      >
                        Change plan
                      </Button>
                    )}
                  </div>
                </div>
              </Modal.Footer>
            )}
          </div>
        </Modal.Content>
      </Modal.Root>

      <SubscriptionCancelModal
        isConfirmationOpen={isConfirmationOpen}
        onCloseConfirmationModal={() => setConfirmationModal(false)}
      />

      <SubscriptionModal
        onPaymentDone={closeSubscriptionModal}
        show={subscriptionModal}
        onClose={closeSubscriptionModal}
      />
    </>
  );
};
