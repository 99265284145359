import { axiosInstance } from "./apiInstance";
import qs from "qs";

export const getNewFeatures = async ({ pageNum }) => {
  let url = `/api/inkle/notifications/new-features/get-all/`;

  let queryUrl = qs.stringify(
    {
      page_num: pageNum,
    },
    { skipNulls: true }
  );
  if (queryUrl) url = url.replace("get-all/", "get-all?");
  url += queryUrl;

  return await axiosInstance.get(url);
};
