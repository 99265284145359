import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";

export type AuditLog = {
  uuid: string;
  frontend_url: string | null;
  product_type:
    | "TAX"
    | "BOOKS"
    | "SALES_TAX"
    | "PLATFORM"
    | "MAILROOM"
    | "INTRAGROUP"
    | "BOARD"
    | "TAX_CREDITS"
    | "COMMUNITY";
  company_group?: {
    uuid: string;
    name: string;
  };
  profile?: {
    uuid: string;
    name: string;
    short_name: string;
  };
  company_entity?: {
    uuid: string;
    name: string;
  };
  obj?: {
    uuid: string;
    content_type:
      | "task"
      | "companygroupinvoice"
      | "entitytransaction"
      | "entityitem"
      | "companygroupsubscriptiondata";
  };
  event: {
    name: string;
    description: string;
    time: string;
  };
};

export const auditlogs = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyGroupAuditLogs: build.query<
      Pagination & { logs: AuditLog[] },
      {
        groupId?: string;
        entityId?: string;
        productTypes?: string;
        profileIdList?: string;
        startDate?: string;
        endDate?: string;
        pageNum?: number;
        searchTerm?: string;
      }
    >({
      query: ({
        groupId,
        entityId,
        productTypes,
        profileIdList,
        startDate,
        endDate,
        pageNum,
        searchTerm,
      }) => {
        return {
          url: `/api/inkle/auditlogs/company_group/${groupId}/logs/`,
          params: {
            group_id: groupId,
            entity_id: entityId,
            product_types: productTypes,
            profile_id_list: profileIdList,
            start_date: startDate,
            end_date: endDate,
            page_num: pageNum,
            search_term: searchTerm,
          },
        };
      },
    }),
    getAuditLogs: build.query<
      Pagination & { logs: AuditLog[] },
      {
        groupIds?: string;
        entityId?: string;
        productTypes?: string;
        profileIdList?: string;
        startDate?: string;
        endDate?: string;
        pageNum?: number;
        searchTerm?: string;
      }
    >({
      query: ({
        groupIds,
        entityId,
        productTypes,
        profileIdList,
        startDate,
        endDate,
        pageNum,
        searchTerm,
      }) => {
        return {
          url: `/api/inkle/auditlogs/logs/`,
          params: {
            group_ids: groupIds,
            entity_id: entityId,
            product_types: productTypes,
            profile_id_list: profileIdList,
            start_date: startDate,
            end_date: endDate,
            page_num: pageNum,
            search_term: searchTerm,
          },
        };
      },
    }),

    getByObjectIdAuditLogs: build.query<
      Pagination & { logs: AuditLog[] },
      {
        entityId: string;
        objectId: string;
        page_num?: number;
      }
    >({
      query: ({ entityId, objectId, page_num }) => {
        return {
          url: `/api/inkle/auditlogs/entity/${entityId}/object/${objectId}/logs/`,
          params: {
            entity_id: entityId,
            page_num,
          },
        };
      },
      providesTags: (result) => (result ? ["AUDIT_LOG_BY_OBJECT"] : []),
    }),
  }),
});

export const {
  useGetAuditLogsQuery,
  useGetCompanyGroupAuditLogsQuery,
  useGetByObjectIdAuditLogsQuery,
  useLazyGetByObjectIdAuditLogsQuery,
} = auditlogs;
