import randomBytes from "randombytes";

export const setSessionId = ({
  key,
  sessionId,
}: {
  key: string;
  sessionId: string;
}) => {
  localStorage.setItem(key, sessionId);
};

export const getSessionId = (key: string) => {
  return localStorage.getItem(key);
};

export const setOrGetRandomSessionId = (key: string) => {
  let sessionId: string = getSessionId(key) || "";

  if (sessionId) {
    return sessionId;
  }

  sessionId = randomBytes(16).toString("hex");
  setSessionId({ key, sessionId });

  return sessionId;
};

export const setNewRandomSessionId = (key: string) => {
  let sessionId = randomBytes(16).toString("hex");
  setSessionId({ key, sessionId });

  return sessionId;
};
