import { emailValidationRegex } from "constants/emailValidationRegex";
import { object, string } from "yup";

export const invoiceCustomerValidation = object({
  email: string()
    .required("Email is required")
    .matches(emailValidationRegex, "Please enter a valid email"),
  company_name: string().required("Company name is required"),
  billing_address: object({
    street_address: string().required("Street address is required"),
    city: string().required("City is required"),
    country: string().required("Country is required"),
    zipcode: string().required("Zipcode is required"),
    state: string().required("State is required"),
  }),
});
