import { object, string } from "yup";

export const addManualBankSchema = object({
  bank_name_id: string().required("Please select or enter name"),
  account_number: string().required("Please enter Account number"),
  account_type: string().required("Please select type"),
  opening_balance: string().required("Please enter Opening balance"),
  account_opening_date: string().required("Please select Opening balance date"),
});

export const editManualBankSchema = object({
  account_number: string().required("Please enter Account number"),
  account_type: string().required("Please select type"),
  opening_balance: string().required("Please enter Opening balance"),
  opening_balance_date: string().required("Please select Opening balance date"),
});
