import { Seperator } from "components/icons/Chat/Seperator";

export const ChatUserName = ({
  name,
  role,
}: {
  name?: string;
  role?: unknown | string;
}) => (
  <>
    {name}{" "}
    {role && typeof role === "string" && (
      <span className="t-flex t-text-body-sm t-text-neutral-50">
        <span className="t-mx-1 t--translate-y-px">
          <Seperator />
        </span>
        {role}
      </span>
    )}
  </>
);
