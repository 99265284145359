import { StateFilings } from "types/Models/StateFilings";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { Entity } from "types/Models/entity";
import { COMPLIANCE_FREQUENCIES } from "types/contants/complianceFrequencies";
import { COMPLIANCE_MONTH_STATUSES } from "types/contants/complianceMonthStatuses";

type OtherFilings = { task: string; type: string; status: string };

export type EntityCompliance = {
  uuid: string;
  entity: Pick<
    Entity,
    "country" | "uuid" | "country_id" | "country_code" | "name"
  >;
  compliance_type: string;
  frequency: COMPLIANCE_FREQUENCIES;
  due_dates: string[];
  monthly_compliance_list: {
    month: string;
    status: COMPLIANCE_MONTH_STATUSES;
  }[];
  name: string;
};

type GetFilingsResponse = {
  other_filings: OtherFilings[];
  state_filings: StateFilings[];
  active_states: number;
  home_state: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getChoices: build.query<string[], { choice: string }>({
      query: ({ choice }) =>
        `api/inkle/tax_compliance/choices/?choice_type=${choice}`,
    }),

    getFilings: build.query<
      GetFilingsResponse,
      { groupId: string; entityId: string; season: string }
    >({
      query: ({ groupId, entityId, season }) => {
        let queryUrl = qs.stringify(
          {
            season,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/${queryUrl}`,
        };
      },
      providesTags: ["GetFilings"],
    }),

    addState: build.mutation({
      query: ({ groupId, entityId, data }) => {
        return {
          url: `api/inkle/tax_compliance/admin/group/${groupId}/entity/${entityId}/`,
          method: "post",
          body: data,
        };
      },
      invalidatesTags: ["GetFilings"],
    }),

    editState: build.mutation({
      query: ({ groupId, entityId, data }) => {
        return {
          url: `api/inkle/tax_compliance/admin/group/${groupId}/entity/${entityId}/`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["GetFilings"],
    }),

    getAllEntityCompliance: build.query<
      EntityCompliance[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/global_compliance/`,
        };
      },
      providesTags: ["GLOBAL_COMPLIANCE"],
    }),

    createEntityCompliance: build.mutation<
      EntityCompliance,
      {
        groupId: string;
        entityId: string;
        payload: Omit<EntityCompliance, "entity" | "uuid">;
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/global_compliance/`,
          body: payload,
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? ["GLOBAL_COMPLIANCE"] : []),
    }),
    editEntityCompliance: build.mutation<
      EntityCompliance,
      {
        groupId: string;
        entityId: string;
        complianceId: string;
        payload: Omit<EntityCompliance, "entity" | "uuid">;
      }
    >({
      query: ({ groupId, entityId, payload, complianceId }) => {
        return {
          url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/global_compliance/${complianceId}/`,
          // @ts-ignore
          body: payload,
          method: "PUT",
        };
      },
      invalidatesTags: (result) => (result ? ["GLOBAL_COMPLIANCE"] : []),
    }),

    deleteEntityCompliance: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        complianceId: string;
      }
    >({
      query: ({ groupId, entityId, complianceId }) => {
        return {
          url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/global_compliance/${complianceId}/`,
          // @ts-ignore
          method: "DELETE",
        };
      },
      invalidatesTags: ["GLOBAL_COMPLIANCE"],
    }),

    getAllComplianceType: build.query<
      { compliance_type: string }[],
      { groupId: string }
    >({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/tax_compliance/group/${groupId}/compliance_type/`,
        };
      },
    }),
  }),
});

export const {
  useGetChoicesQuery,
  useGetFilingsQuery,
  useAddStateMutation,
  useEditStateMutation,
  useGetAllEntityComplianceQuery,
  useCreateEntityComplianceMutation,
  useEditEntityComplianceMutation,
  useGetAllComplianceTypeQuery,
  useDeleteEntityComplianceMutation,
} = extendedApi;
