import {
  ACTION_REQUIRED,
  FORWARDED,
  FORWARD_REQUESTED,
  MAILROOM,
  NEWEST,
  OLDEST,
  REGISTERED_AGENT,
  SHREDDED,
  SHRED_REQUESTED,
} from "constants/mailRoom";

export const SORT_TYPE = {
  [NEWEST]: {
    name: "Newest first",
    type: "NEWEST",
  },
  [OLDEST]: {
    name: "Oldest first",
    type: "OLDEST",
  },
};

export const MAIL_TYPE = {
  [REGISTERED_AGENT]: "Registered Agent",
  [MAILROOM]: "Mailroom",
};

export const STATUS_TYPE = {
  [ACTION_REQUIRED]: "Action Required",
  [FORWARD_REQUESTED]: "Ship Requested",
  [FORWARDED]: "Shipped",
  [SHRED_REQUESTED]: "Shred Requested",
  [SHREDDED]: "Shredded",
};

export const STATUS_TYPE_COLOURS = {
  [ACTION_REQUIRED]: "initial",
  [FORWARD_REQUESTED]: "ongoing",
  [FORWARDED]: "ongoing",
  [SHRED_REQUESTED]: "notStarted",
  [SHREDDED]: "notStarted",
};

export const TAB_TPYE = {
  0: null,
  1: "MAILROOM",
  2: "REGISTERED_AGENT",
};

export const TAB_INDEX = {
  [null]: 0,
  [MAILROOM]: 1,
  [REGISTERED_AGENT]: 2,
};
