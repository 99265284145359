import {
  CashBalance,
  PrimaryMetric,
  ShuReport,
  Stakeholder,
  StakeholderIntro,
  StakeholderPrimaryMetric,
  StakeholderUpdate,
  StakeholderUpdates,
  TextField,
} from "../../types/Models/stakeholderUpdate";
import { emptyApi } from "./emptyApi";

type StakeholderIntroPayload = {
  note: string;
  logo: File;
};

const stakeholderUpdates = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStakeholderUpdates: builder.query<
      StakeholderUpdates,
      { groupId: string; pageNum?: number }
    >({
      query: ({ groupId, pageNum = 1 }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/updates/`,
        params: {
          page_num: pageNum,
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    getStakeholderUpdate: builder.query<
      StakeholderUpdate,
      { groupId: string; updateId: string }
    >({
      query: ({ groupId, updateId }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/`,
      }),
      providesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    createStakeholderUpdate: builder.mutation<
      StakeholderUpdate,
      { groupId: string; title: string }
    >({
      query: ({ groupId, title }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/updates/`,
        method: "POST",
        body: { title },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    updateStakeholderUpdate: builder.mutation<
      StakeholderUpdate,
      {
        groupId: string;
        updateId: string;
        payload: {
          title: string;
          is_goals_available: boolean;
          is_requests_available: boolean;
          is_cash_balance_available: boolean;
          is_primary_metrics_available: boolean;
          is_reports_available: boolean;
          report_description: string;
          is_highlights_available: boolean;
          is_lowlights_available: boolean;
          is_team_updates_available: boolean;
          is_product_updates_available: boolean;
          block_json: string;
        };
      }
    >({
      query: ({ groupId, updateId, payload }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    deleteStakeholderUpdate: builder.mutation<
      void,
      { groupId: string; updateId: string }
    >({
      query: ({ groupId, updateId }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: "STAKEHOLDER_UPDATE" }],
    }),

    getStakeholders: builder.query<
      { holders: Stakeholder[] },
      { groupId: string }
    >({
      query: ({ groupId }) =>
        `/api/inkle/stakeholders/group/${groupId}/holders/`,
      providesTags: (result) => (result ? [{ type: "STAKEHOLDERS" }] : []),
    }),

    addStakeholder: builder.mutation<
      Stakeholder,
      { groupId: string; payload: { name: string; email: string } }
    >({
      query: ({ groupId, payload: { name, email } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/holders/`,
        method: "POST",
        body: { name, email },
      }),
      invalidatesTags: (result) => (result ? [{ type: "STAKEHOLDERS" }] : []),
    }),

    deleteStakeholder: builder.mutation<
      Stakeholder,
      { groupId: string; stakeholderId: string }
    >({
      query: ({ groupId, stakeholderId }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/holder/${stakeholderId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: "STAKEHOLDERS" }],
    }),

    getStakeholderUpdateIntro: builder.query<
      StakeholderIntro,
      { groupId: string; updateId: string }
    >({
      query: ({ groupId, updateId }) =>
        `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/intro/`,
      providesTags: (result) =>
        result
          ? [
              { type: "STAKEHOLDER_UPDATE" },
              { type: "STAKEHOLDER_UPDATE_INTRO" },
            ]
          : [],
    }),

    createStakeholderUpdateIntro: builder.mutation<
      StakeholderIntro,
      {
        groupId: string;
        updateId: string;
        payload: Partial<StakeholderIntroPayload>;
      }
    >({
      query: ({ groupId, updateId, payload: { note, logo } }) => {
        const form = new FormData();
        if (note) {
          form.append("note", note);
        }

        if (logo) {
          form.append("logo", logo);
        }

        return {
          url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/intro/`,
          method: "POST",
          body: form,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: "STAKEHOLDER_UPDATE" },
              { type: "STAKEHOLDER_UPDATE_INTRO" },
            ]
          : [],
    }),

    updateStakeHoldersIntro: builder.mutation<
      StakeholderIntro,
      {
        groupId: string;
        updateId: string;
        introId: string;
        payload: Partial<StakeholderIntroPayload>;
      }
    >({
      query: ({ groupId, updateId, introId, payload: { note, logo } }) => {
        const form = new FormData();
        if (note !== undefined) {
          form.append("note", note);
        }

        if (logo !== undefined) {
          form.append("logo", logo);
        }

        return {
          url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/intro/${introId}/`,
          method: "PUT",
          body: form,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: "STAKEHOLDER_UPDATE" },
              { type: "STAKEHOLDER_UPDATE_INTRO" },
            ]
          : [],
    }),

    getShuCashBalances: builder.query<
      CashBalance[],
      { groupId: string; updateId: string }
    >({
      query: ({ groupId, updateId }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/balance/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE_CB" }] : [],
    }),

    addShuCashBalance: builder.mutation<
      CashBalance,
      {
        groupId: string;
        updateId: string;
        payload: Pick<CashBalance, "balance" | "account_name" | "edited"> & {
          entity_id: string;
        };
      }
    >({
      query: ({
        groupId,
        updateId,
        payload: { entity_id, account_name, balance, edited },
      }) => {
        return {
          url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/balance/`,
          method: "POST",
          body: {
            entity_id,
            account_name,
            balance,
            edited,
          },
        };
      },
      invalidatesTags: (result) =>
        result
          ? [{ type: "STAKEHOLDER_UPDATE" }, { type: "STAKEHOLDER_UPDATE_CB" }]
          : [],
    }),

    updateShuCashBalance: builder.mutation<
      CashBalance,
      {
        groupId: string;
        updateId: string;
        cashBalanceId: string;
        payload: Pick<CashBalance, "balance" | "account_name" | "edited"> & {
          entity_id: string;
        };
      }
    >({
      query: ({
        groupId,
        updateId,
        cashBalanceId,
        payload: { entity_id, account_name, balance, edited },
      }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/balance/${cashBalanceId}/`,
        method: "PUT",
        body: {
          entity_id,
          account_name,
          balance,
          edited,
        },
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: "STAKEHOLDER_UPDATE" }, { type: "STAKEHOLDER_UPDATE_CB" }]
          : [],
    }),

    deleteShuCashBalance: builder.mutation<
      void,
      { groupId: string; updateId: string; updateCbId: string }
    >({
      query: ({ groupId, updateId, updateCbId }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/balance/${updateCbId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _error, _args, query) => {
        return [
          { type: "STAKEHOLDER_UPDATE" },
          { type: "STAKEHOLDER_UPDATE_CB" },
        ];
      },
    }),

    getShuReports: builder.query({
      query: ({ groupId, updateId }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/report/`,
        method: "GET",
      }),
    }),

    addShuReport: builder.mutation<
      ShuReport,
      {
        groupId: string;
        updateId: string;
        payload: {
          reportFile: File;
          folderId: string;
          title: string;
          description: string;
        };
      }
    >({
      query: ({
        groupId,
        updateId,
        payload: { reportFile, folderId, title, description },
      }) => {
        const form = new FormData();
        form.append("report_file", reportFile);
        form.append("folder_id", folderId);
        form.append("title", title);
        form.append("description", description);

        return {
          url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/report/`,
          method: "POST",
          body: form,
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    deleteShuReport: builder.mutation<
      void,
      { groupId: string; updateId: string; reportId: string }
    >({
      query: ({ groupId, updateId, reportId }) => ({
        url: `api/inkle/stakeholders/group/${groupId}/update/${updateId}/report/${reportId}/`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "STAKEHOLDER_UPDATE" }],
    }),

    getPrimaryMetricChoices: builder.query<PrimaryMetric[], void>({
      query: () => "/api/inkle/stakeholders/primary-metrics/",
    }),

    getPrimaryMetrics: builder.query<
      StakeholderPrimaryMetric[],
      { groupId: string; updateId: string }
    >({
      query: ({ groupId, updateId }) =>
        `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/metric/`,
    }),

    addShuPrimaryMetric: builder.mutation<
      StakeholderPrimaryMetric,
      {
        groupId: string;
        updateId: string;
        payload: { primary_metric_id: string; value: number };
      }
    >({
      query: ({
        groupId,
        updateId,
        payload: { primary_metric_id, value },
      }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/metric/`,
        method: "POST",
        body: {
          primary_metric_id,
          value,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    updateShuPrimaryMetric: builder.mutation<
      StakeholderPrimaryMetric,
      {
        groupId: string;
        updateId: string;
        primaryMetricId: string;
        payload: { primary_metric_id: string; value: number };
      }
    >({
      query: ({
        groupId,
        updateId,
        primaryMetricId,
        payload: { primary_metric_id, value },
      }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/metric/${primaryMetricId}/`,
        method: "PUT",
        body: {
          primary_metric_id,
          value,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    deleteShuPrimaryMetric: builder.mutation<
      StakeholderPrimaryMetric,
      {
        groupId: string;
        updateId: string;
        primaryMetricId: string;
      }
    >({
      query: ({ groupId, updateId, primaryMetricId }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/metric/${primaryMetricId}/`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "STAKEHOLDER_UPDATE" }],
    }),

    createShuGoal: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/goal/`,
        method: "POST",
        body: {
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    updateShuGoal: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        goalId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, goalId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/goal/${goalId}/`,
        method: "PUT",
        body: {
          sh_update_goal_id: goalId,
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    createShuHighlight: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/highlight/`,
        method: "POST",
        body: {
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    updateShuHighlight: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        highlightId: string;
        payload: { description: string };
      }
    >({
      query: ({
        groupId,
        updateId,
        highlightId,
        payload: { description },
      }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/highlight/${highlightId}/`,
        method: "PUT",
        body: {
          sh_update_goal_id: highlightId,
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    createShuLowlight: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/lowlight/`,
        method: "POST",
        body: {
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    updateShuLowlight: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        lowlightId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, lowlightId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/lowlight/${lowlightId}/`,
        method: "PUT",
        body: {
          sh_update_lowlight_id: lowlightId,
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    createShuProduct: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/product/`,
        method: "POST",
        body: {
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    updateShuProduct: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        productId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, productId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/product/${productId}/`,
        method: "PUT",
        body: {
          sh_update_product_id: productId,
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    createShuTeam: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/team/`,
        method: "POST",
        body: {
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    updateShuTeam: builder.mutation<
      TextField,
      {
        groupId: string;
        updateId: string;
        teamId: string;
        payload: { description: string };
      }
    >({
      query: ({ groupId, updateId, teamId, payload: { description } }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/team/${teamId}/`,
        method: "PUT",
        body: {
          sh_update_team_id: teamId,
          description,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    sendStakeholderUpdate: builder.mutation<
      null,
      {
        groupId: string;
        updateId: string;
        stakeholders: string[];
        ccEmails: string[];
        emailSenderInkle: boolean;
        subject?: string;
      }
    >({
      query: ({
        groupId,
        updateId,
        stakeholders,
        ccEmails,
        emailSenderInkle,
        subject,
      }) => ({
        url: `/api/inkle/stakeholders/group/${groupId}/update/${updateId}/trigger/`,
        method: "POST",
        body: {
          stake_holder_ids: stakeholders,
          cc_emails: ccEmails,
          email_sender_inkle: emailSenderInkle,
          subject,
        },
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),

    getAuthorizationURLGmail: builder.query<
      { authorization_url: string },
      { callbackUrl: string }
    >({
      query: ({ callbackUrl }) => ({
        url: `/api/inkle/users/auth/google/email/`,
        params: {
          callback_url: callbackUrl,
        },
      }),
    }),

    setCallbackGmail: builder.query<
      { callback_url: string; status: string },
      { error: string; code: string }
    >({
      query: ({ error, code }) => ({
        url: `/api/inkle/users/auth/email/callback/`,
        method: "POST",
        body: {
          code,
          error,
        },
      }),
    }),

    duplicateInvestorUpdate: builder.mutation({
      query: ({ groupId, updateId }) => {
        return {
          url: `/api/inkle/stakeholders/group/${groupId}/updates/duplicate/${updateId}/`,
          method: "POST",
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "STAKEHOLDER_UPDATE" }] : [],
    }),
  }),
});

export const {
  useGetAllStakeholderUpdatesQuery,
  useGetStakeholderUpdateQuery,
  useCreateStakeholderUpdateMutation,
  useCreateStakeholderUpdateIntroMutation,
  useUpdateStakeHoldersIntroMutation,
  useAddShuPrimaryMetricMutation,
  useDeleteShuPrimaryMetricMutation,
  useUpdateShuPrimaryMetricMutation,
  useGetPrimaryMetricChoicesQuery,
  useUpdateStakeholderUpdateMutation,
  useAddShuCashBalanceMutation,
  useDeleteShuCashBalanceMutation,
  useUpdateShuCashBalanceMutation,
  useAddShuReportMutation,
  useDeleteShuReportMutation,
  useAddStakeholderMutation,
  useDeleteStakeholderMutation,
  useSendStakeholderUpdateMutation,
  useGetStakeholdersQuery,
  useDeleteStakeholderUpdateMutation,
  useCreateShuGoalMutation,
  useUpdateShuGoalMutation,
  useCreateShuHighlightMutation,
  useUpdateShuHighlightMutation,
  useCreateShuLowlightMutation,
  useUpdateShuLowlightMutation,
  useCreateShuProductMutation,
  useUpdateShuProductMutation,
  useUpdateShuTeamMutation,
  useCreateShuTeamMutation,
  useLazyGetAuthorizationURLGmailQuery,
  useGetShuCashBalancesQuery,
  useSetCallbackGmailQuery,
  useGetPrimaryMetricsQuery,
  useLazyGetAllStakeholderUpdatesQuery,
  useDuplicateInvestorUpdateMutation,
} = stakeholderUpdates;
