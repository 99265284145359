import classNames from "classnames";
import ToolTip from "components/design/toolTip";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Warning } from "components/icons/Warning";
import { FormikValues, useFormikContext } from "formik";
import { useField } from "formik";
import React from "react";
import BlackFilledInfo from "static/images/BlackFilledInfo.svg";

export const BrandCreation = ({
  onChange,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { errors } = useFormikContext<FormikValues>();
  const isError = Boolean(errors.brandName);

  return (
    <div className="t-relative">
      <div className={classNames("t-max-w-96")}>
        <TextInput
          label={
            <div className="t-flex t-flex-col t-gap-2 t-mb-1.5">
              <div className="t-flex t-gap-1 t-items-center">
                <span className="t-text-subtitle t-text-text-100">
                  Brand name
                </span>
                <ToolTip
                  side="top"
                  text="Enter the name under which your company operates publicly. This is the name your customers will recognize and use to find your services or products. It may be different from your legal business name."
                >
                  <img src={BlackFilledInfo} alt="info" />
                </ToolTip>
              </div>
              <span className="t-text-body t-font-extralight">
                Note: This is not your legal entity name
              </span>
            </div>
          }
          customSize="large"
          name="brandName"
          type="text"
          placeholder="Eg.Acme"
          rightComponent={isError ? <Warning /> : <></>}
          customType={isError ? "warning" : "success"}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
