export const RANDDIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="#F3F3F5" />
    <rect
      x="5.69014"
      y="8"
      width="3.26667"
      height="3.26667"
      transform="rotate(-45 5.69014 8)"
      stroke="black"
      stroke-width="1.4"
    />
    <circle
      cx="7.99998"
      cy="8.00001"
      r="5.96667"
      stroke="black"
      stroke-width="1.4"
    />
  </svg>
);
