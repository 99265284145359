import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import { motion } from "framer-motion";
import cx from "classnames";

const Content = ({
  className = "",
  children,
  ...props
}: HoverCardPrimitive.HoverCardContentProps &
  React.RefAttributes<HTMLDivElement>) => {
  return (
    <HoverCardPrimitive.Content {...props} asChild>
      <motion.div
        className={cx(
          "primary-border t-z-dropdown t-rounded-md t-bg-surface t-text-body t-drop-shadow-i-dropdown t-overflow-auto",
          {
            [className]: className,
          }
        )}
        initial={{ y: -10 }}
        animate={{ y: 0 }}
        exit={{ y: -10 }}
      >
        {children}
      </motion.div>
    </HoverCardPrimitive.Content>
  );
};

const HoverCard = {
  ...HoverCardPrimitive,
  Content,
};

export default HoverCard;
