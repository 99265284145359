export const Files = ({ color = "currentColor" }: { color?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2895_16592)">
        <path
          d="M11.625 15.3003H4.27502C4.13578 15.3003 4.00225 15.245 3.90379 15.1465C3.80534 15.0481 3.75002 14.9145 3.75002 14.7753V5.32529C3.75002 5.18605 3.80534 5.05252 3.90379 4.95406C4.00225 4.85561 4.13578 4.80029 4.27502 4.80029H9.52502L12.15 7.42529V14.7753C12.15 14.9145 12.0947 15.0481 11.9963 15.1465C11.8978 15.245 11.7643 15.3003 11.625 15.3003Z"
          stroke={color}
          strokeWidth="1.05"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.85002 4.8002V3.2252C5.85002 3.08596 5.90533 2.95242 6.00379 2.85396C6.10225 2.75551 6.23578 2.7002 6.37502 2.7002H11.625L14.25 5.3252V12.6752C14.25 12.8144 14.1947 12.948 14.0963 13.0464C13.9978 13.1449 13.8643 13.2002 13.725 13.2002H12.15"
          stroke={color}
          strokeWidth="1.05"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.37502 10.5752H9.52502"
          stroke={color}
          strokeWidth="1.05"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.37502 12.6753H9.52502"
          stroke={color}
          strokeWidth="1.05"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2895_16592">
          <rect
            width="16.8"
            height="16.8"
            fill="white"
            transform="translate(0.599998 0.600098)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
