import { ReactNode } from "react";

export const ProductCard = ({
  title,
  description,
  price,
  action,
}: {
  title: ReactNode;
  description?: ReactNode;
  price: ReactNode;
  action: ReactNode;
}) => (
  <div className="t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface t-pt-4 t-pb-4 t-pl-4 t-pr-3 t-flex t-flex-col t-gap-4 t-justify-between t-text-text-60">
    <div className="t-flex t-gap-2 t-flex-col">
      <p className="t-m-0 t-text-subtext">{title}</p>
      {description && (
        <p className="t-m-0 t-text-body-sm t-text-text-30">{description}</p>
      )}
    </div>

    <div className="t-flex t-justify-between t-items-end t-gap-3">
      {price}
      <div className="t-shrink-0 t-justify-self-endt-grow">{action}</div>
    </div>
  </div>
);
