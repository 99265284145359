export const Star = ({ color = "#FFCC00" }: { color?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2621_2067)">
      <path
        d="M14.6562 7.14875L11.8375 9.60875L12.6819 13.2712C12.7266 13.4627 12.7138 13.6631 12.6452 13.8474C12.5766 14.0316 12.4552 14.1916 12.2962 14.3072C12.1372 14.4229 11.9477 14.4891 11.7513 14.4976C11.5548 14.5061 11.3603 14.4565 11.1919 14.355L7.9975 12.4175L4.81 14.355C4.6416 14.4565 4.44703 14.5061 4.2506 14.4976C4.05418 14.4891 3.86462 14.4229 3.70562 14.3072C3.54662 14.1916 3.42524 14.0316 3.35665 13.8474C3.28806 13.6631 3.27531 13.4627 3.32 13.2712L4.16312 9.6125L1.34375 7.14875C1.19463 7.02014 1.0868 6.85036 1.03378 6.66071C0.980764 6.47107 0.98492 6.26999 1.04573 6.08269C1.10654 5.89539 1.22129 5.73022 1.37559 5.60788C1.5299 5.48554 1.71689 5.41149 1.91312 5.395L5.62937 5.07312L7.08 1.61312C7.15575 1.43157 7.28353 1.27649 7.44724 1.16741C7.61095 1.05833 7.80327 1.00012 8 1.00012C8.19672 1.00012 8.38904 1.05833 8.55275 1.16741C8.71646 1.27649 8.84424 1.43157 8.92 1.61312L10.375 5.07312L14.09 5.395C14.2862 5.41149 14.4732 5.48554 14.6275 5.60788C14.7818 5.73022 14.8966 5.89539 14.9574 6.08269C15.0182 6.26999 15.0224 6.47107 14.9693 6.66071C14.9163 6.85036 14.8085 7.02014 14.6594 7.14875H14.6562Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2621_2067">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
