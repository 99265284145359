import { COORDINATES, US_COORDINATES } from "constants/regAgent";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import usMap from "../RegisteredAgent/usmap.json";

import { MapPin } from "components/icons/MapPin";

export const ForeignQualifications = ({
  activeStates,
  homeState,
}: {
  activeStates?: string[];
  homeState: keyof typeof COORDINATES;
}) => {
  let mapPinCoordinates: [number, number] = [
    COORDINATES[homeState][0] - 1,
    COORDINATES[homeState][1] + 2,
  ];

  return (
    <div className="t-flex t-gap-[74px] t-mt-8">
      <div className="t-w-[344px] t-h-[234px]">
        <ComposableMap
          projection="geoMercator"
          className="t-w-[344px] t-h-[234px]"
        >
          <ZoomableGroup
            center={US_COORDINATES}
            zoom={5.5}
            filterZoomEvent={() => false}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Geographies geography={usMap}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const {
                    properties: { name },
                  } = geo;
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      stroke={
                        activeStates?.includes(name) ? "#DDD3FF" : "#DDD3FF"
                      }
                      fill={
                        activeStates?.includes(name) ? "#DDD3FF" : "#EEE9FF"
                      }
                      strokeWidth="0.2"
                    />
                  );
                })
              }
            </Geographies>
            {homeState && (
              <Marker coordinates={mapPinCoordinates}>
                <MapPin />
              </Marker>
            )}
          </ZoomableGroup>
        </ComposableMap>
      </div>
      <div className="t-flex t-flex-col t-max-w-[422px]">
        <span className="t-text-subtext t-text-text-30">United States</span>
        <span className="t-text-subtitle t-text-text-100">
          Foreign Qualifications
        </span>
        <p className="t-text-body t-text-text-30 t-my-3">
          Here are all the states where you are conducting business in the US.
          For a holistic view of your tax & compliances, read the below
          information.
        </p>
        <span className="t-text-subtitle-sm t-text-text-100">
          Active States:{" "}
          <span className="t-text-body t-text-text-100">
            {activeStates?.length}
          </span>
        </span>
        <span className="t-text-subtitle-sm t-text-text-100">
          Home State:{" "}
          <span className="t-text-body t-text-text-100">{homeState}</span>
        </span>
      </div>
    </div>
  );
};
