import React from "react";

const AuthHeader = ({ signin }) => {
  return (
    <div className="authHeader">
      <h1 className="accountHead">{signin ? "Sign in" : "Sign up"}</h1>
    </div>
  );
};

export default AuthHeader;
