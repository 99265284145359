import React, { useState } from "react";
import { AddTransactionManuallyModal } from "./AddTransactionManuallyModal";
import { CSVColumnMapping } from "./CSVColumnMapping";
import { CSVParsedResponse, CSVResponse } from "types/Models/reconciliation";

export const AddTransactionModalBody = ({
  close,
  selectedAccountId,
  uploadedCsvData,
  currentStep,
  setCurrentStep,
}: {
  close: () => void;
  selectedAccountId: string;
  uploadedCsvData?: CSVParsedResponse;
  currentStep: number;
  setCurrentStep: (newState: React.SetStateAction<number>) => void;
}) => {
  const [csvTransactions, setCsvTransactions] = useState<
    CSVResponse[] | undefined
  >();

  switch (true) {
    case uploadedCsvData && currentStep === 2:
      return (
        <CSVColumnMapping
          close={close}
          uploadedCsvData={uploadedCsvData}
          setCurrentStep={setCurrentStep}
          setCsvTransactions={setCsvTransactions}
        />
      );

    default:
      return (
        <AddTransactionManuallyModal
          close={close}
          selectedAccountId={selectedAccountId}
          csvTransactions={csvTransactions}
        />
      );
  }
};
