import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OpenItemStatus } from "types/Models/openItem";

export type OpenItemState = {
  showUpdloadCsvModal: boolean;
  calrifyModal: {
    messageId: string;
    csvId: string;
    status: OpenItemStatus;
    show: boolean;
  };
  channelId: string;
  addRequestInfoModal: {
    csvId: string;
    groupId: string;
    channelId: string;
    messageId: string;
  };
};

const initialState: OpenItemState = {
  showUpdloadCsvModal: false,
  calrifyModal: { messageId: "", csvId: "", status: "REQUESTED", show: false },
  channelId: "",
  addRequestInfoModal: {
    csvId: "",
    groupId: "",
    messageId: "",
    channelId: "",
  },
};

export const openItem = createSlice({
  name: "openItem",
  initialState,
  reducers: {
    openUploadCSVModal: (
      state,
      action: PayloadAction<{ channelId: string }>
    ) => {
      state.showUpdloadCsvModal = true;
      state.channelId = action.payload.channelId;
    },
    closeUploadCSVModal: (state) => {
      state.showUpdloadCsvModal = false;
    },
    openCalrifyModal: (
      state,
      action: PayloadAction<{
        messageId: string;
        csvId: string;
        status: OpenItemStatus;
      }>
    ) => {
      state.calrifyModal = { ...action.payload, show: true };
    },
    closeCalrifyModal: (state) => {
      state.calrifyModal = initialState.calrifyModal;
    },
    openAddRequestInfoModal: (
      state,
      action: PayloadAction<OpenItemState["addRequestInfoModal"]>
    ) => {
      state.addRequestInfoModal = { ...action.payload };
    },
    closeAddRequestInfoModal: (state) => {
      state.addRequestInfoModal = initialState.addRequestInfoModal;
    },
  },
});

export const {
  openUploadCSVModal,
  closeUploadCSVModal,
  openCalrifyModal,
  closeCalrifyModal,
  openAddRequestInfoModal,
  closeAddRequestInfoModal,
} = openItem.actions;

export default openItem.reducer;
