import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Field, Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import {
  useCreateChatTemplateMutation,
  useUpdateChatTemplateMutation,
} from "store/apis/chatTemplate";
import { ChatTemplate } from "types/Models/ChatTemplate";
import { object, string } from "yup";

const CreateTemplate = ({
  closeAddStep,
  templateData,
}: {
  closeAddStep: () => void;
  templateData: ChatTemplate;
}) => {
  const [createChatTemplate] = useCreateChatTemplateMutation();
  const [editChatTemplate] = useUpdateChatTemplateMutation();
  const { alertToast } = useToast();

  const handleSubmit = async (values: any) => {
    try {
      if (templateData.uuid) {
        await editChatTemplate({
          templateId: templateData.uuid,
          response_body: values.messageBody,
          response_header: values.header,
        });
      } else {
        await createChatTemplate({
          response_body: values.messageBody,
          response_header: values.header,
        });
      }
      closeAddStep();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeAddStep();
    }
  };

  const templateSchema = () =>
    object({
      header: string().min(1).required("Header is required"),
      messageBody: string().min(1).required("Message is required"),
    });

  return (
    <Formik
      validateOnMount
      validationSchema={templateSchema}
      onSubmit={handleSubmit}
      initialValues={{
        header: templateData.response_header,
        messageBody: templateData.response_body,
      }}
    >
      {({ isValid }) => (
        <Form className="t-m-0 t-flex t-w-full t-flex-col t-gap-6">
          <TextInput name="header" placeholder="Header" />
          <Field
            as="textarea"
            name="messageBody"
            placeholder="Message body"
            className="t-mb-2 t-h-32 t-w-full t-resize-none t-rounded t-border t-border-surface-grey t-bg-surface-lighter-grey t-p-3 focus:t-outline-none"
          />
          <div className="t-flex t-justify-end">
            <Button disabled={!isValid}>Save</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTemplate;
