import Modal from "components/DesignSystem/Modal/Modal";
import { FormikValues } from "formik";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import {
  BrandEntityCreation,
  checkFirstEmptyEntity,
} from "signInSignUp/BrandEntityCreation";

import { useCreateCompanyAndEntityMutation } from "store/apis/salesCrm";

type UserDataPayLoad = {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  linkedin: string;
};

type AddCrmEntityTableModalProps = {
  showModal: boolean;
  setModalShow: (newState: React.SetStateAction<boolean>) => void;
  setShowGroupModal: (newState: React.SetStateAction<boolean>) => void;
  userDataPayload: UserDataPayLoad[] | null;
  groupName: string;
  websiteName?: string;
};

const AddCrmEntityTableModal = ({
  showModal,
  setModalShow,
  setShowGroupModal,
  userDataPayload,
  groupName,
  websiteName,
}: AddCrmEntityTableModalProps) => {
  const { alertToast, successToast } = useToast();
  const [createCompanyAndEntity, { isLoading: isCreating }] =
    useCreateCompanyAndEntityMutation();
  const [preIncorporated, setPreincorporated] = useState(false);
  const { isForeignCA } = useRoleBasedView();

  const handleClose = () => {
    setModalShow(false);
  };

  const moveBack = () => {
    setModalShow(false);
    setShowGroupModal(true);
  };

  const onFormSubmitHandler = async (
    entities: {
      state_id: string;
      formation_type_id: string;
      company_name: string;
    }[]
  ) => {
    try {
      if (entities.length === 0) return;

      const groupEntityPayload = {
        group_name: groupName,
        website_name: websiteName,
        user_data: userDataPayload,
        entity_data: entities,
        preincorporation_user: preIncorporated,
      };

      await createCompanyAndEntity(groupEntityPayload).unwrap();
      successToast({ title: "Group created" });
      setModalShow(false);
      setShowGroupModal(false);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      setModalShow(false);
      setShowGroupModal(false);
    }
  };

  return (
    <Modal.Root open={showModal} onOpenChange={handleClose}>
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title>
            Add {isForeignCA ? "legal entity information" : "Entity"}
          </Modal.Title>
          <Modal.Close className="t-mt-2" />
        </Modal.Header>
        <Modal.Body>
          <BrandEntityCreation
            groupName={groupName}
            isFromAdmin
            moveBack={moveBack}
            onAdminSubmit={onFormSubmitHandler}
            preIncorporated={preIncorporated}
            setPreincorporated={setPreincorporated}
            isCreating={isCreating}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default AddCrmEntityTableModal;
