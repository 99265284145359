import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import dayjs from "dayjs";
import { Form, Formik, FormikValues, useFormikContext } from "formik";
import { createServiceSchema } from "formValidations/createServiceSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useCreateTaskMutation,
  useGetBaseTaskTemplatesQuery,
} from "store/apis/getTemplates";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { CreateServiceForm } from "./CreateServiceForm";
import { ServiceType } from "types/Models/services";
import { useMemo } from "react";
import { YYYY_MM_DD } from "constants/date";
import Loader from "components/design/loader";

export const ServiceActionModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { successToast, alertToast } = useToast();
  const serviceActionType: ServiceType = useSelector(
    (state: RootState) => state.serviceList.serviceActionType
  );
  const { uuid: groupId } = useCurrentGroupContext();
  const [createTask, { isLoading: isTaskCreating }] = useCreateTaskMutation();
  const history = useHistory();
  const { isCustomer } = useRoleBasedView();
  const entityId = useCurrentEntityId();

  const { data = {} } = useGetBaseTaskTemplatesQuery({
    task_category_type: "BOOKS",
  });

  const serviceListData = useMemo(() => {
    return Object.entries(data).flatMap(([categoryName, services]) => services);
  }, [data]);

  const service = serviceListData.find(
    ({ base_task_key }) => serviceActionType === base_task_key
  );

  const {
    title,
    approx_price: amount,
    uuid: baseTaskTemplateId,
    seasons = [],
  } = service || {};

  const handleSubmit = async (values: FormikValues) => {
    if (baseTaskTemplateId) {
      try {
        const { startDate, endDate, season, entity } = values || {};

        const finalEntityId = entityId || entity;
        const task = await createTask({
          entity: finalEntityId,
          metaData: {
            base_task_template_id: baseTaskTemplateId,
            from_date: startDate ? dayjs(startDate).format(YYYY_MM_DD) : "",
            to_date: endDate ? dayjs(endDate).format(YYYY_MM_DD) : "",
            season: season,
          },
        }).unwrap();
        successToast({ message: "Service created!" });
        close();
        const redirectUrl = isCustomer
          ? `/books/services/${task.uuid}?entity=${finalEntityId}`
          : `/books/services/${task.uuid}?company=${groupId}&entity=${finalEntityId}`;

        history.replace(redirectUrl);
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Modal.Content useCustomOverlay>
        {!service ? (
          <>
            <Modal.Header>
              <Modal.Title>
                <div className="t-bg-neutral-0 t-rounded-md t-my-2 t-h-11 t-animate-pulse-fast"></div>
              </Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Loader />
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
              <Modal.Close />
            </Modal.Header>

            <Formik
              onSubmit={handleSubmit}
              initialValues={{
                startDate: "",
                endDate: "",
                serviceActionType,
              }}
              validationSchema={createServiceSchema}
            >
              {({ values, setFieldValue }) => {
                const date1 = dayjs(values.startDate, "DD-MMM-YYYY");
                const date2 = dayjs(values.endDate, "DD-MMM-YYYY");

                const isDate1Bigger = date1.isAfter(date2);
                if (isDate1Bigger) {
                  setFieldValue("endDate", "");
                }

                return (
                  <Form className="all:unset">
                    <Modal.Body
                      className="t-flex t-gap-4 t-flex-col t-w-full"
                      title={serviceActionType}
                    >
                      {amount && (
                        <CreateServiceForm amount={amount} seasons={seasons} />
                      )}
                    </Modal.Body>
                    <Modal.Footer className="t-justify-end t-flex">
                      <Button
                        customType="primary"
                        isLoading={isTaskCreating}
                        disabled={isTaskCreating}
                        type="submit"
                      >
                        Start now
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
