import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { closeBalancingPaymentModal } from "store/slices/reviewAndBalancePayment";
import { RootState } from "store/store";
import { BalancingPaymentSuccess } from "./BalancingPaymentSuccess";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { useGetBillingInvoiceQuery } from "store/apis/billingInvoice";
import { AmountSuperScript } from "components/design/AmountSuperScript";

export const BalancingPaymentReviewAndPay = () => {
  const {
    activeChannelGroupId,
    showBalancingPaymentModal,
    customData,
    documentForReviewMessageId: messageId,
    hasReviewed,
    entityId,
  } = useSelector((state: RootState) => state.reviewAndBalancePayment);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const [balancingPaymentInvoice, setBalancingPaymentInvoice] = useState<{
    invoiceId?: string;
    amount?: string;
  }>({});
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const amount = customData?.pending_payment?.pending_amount;

  const invoiceId = balancingPaymentInvoice.invoiceId;
  const { data: invoice } = useGetBillingInvoiceQuery(
    {
      groupId: activeChannelGroupId,
      invoiceId: invoiceId!,
      entityId: entityId!,
    },
    {
      skip: !entityId || !invoiceId || !activeChannelGroupId,
    }
  );

  const showCongratulateModal = () => {
    setShowPaymentModal(false);
    setShowPaymentSuccessModal(true);
  };

  const openPaymentModal = async () => {
    setBalancingPaymentInvoice({
      invoiceId: customData?.pending_payment.pending_invoice_id,
      amount: amount,
    });
    dispatch(closeBalancingPaymentModal());
    dispatch(setUsableCredit(true));
    dispatch(setPaymentTitle("Balancing payment"));
    setShowPaymentModal(true);
  };

  return (
    <>
      <Modal.Root
        open={showBalancingPaymentModal}
        onOpenChange={() => dispatch(closeBalancingPaymentModal())}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              {hasReviewed ? "Review documents and pay" : "Balance payment"}
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-overflow-auto t-gap-8 t-flex t-flex-col t-justify-between">
            <div>
              {hasReviewed && (
                <div className="t-text-body t-text-text-30 t-mb-4">
                  Are you sure you have reviewed the draft and want to proceed
                  with the filing?
                </div>
              )}
              <div className="t-flex t-justify-between t-w-full">
                <div className="t-flex t-flex-col t-gap-2">
                  <div className="t-text-subtext-sm t-text-text-30">TITLE</div>
                  <div className="t-text-caption t-text-text-100">Total</div>
                  <div className="t-text-caption t-text-text-100">Paid</div>
                  <div className="t-text-caption t-text-text-100">
                    Balance payment
                  </div>
                </div>
                <div className="t-flex t-flex-col t-gap-2 t-items-end">
                  <div className="t-text-subtext-sm t-text-text-30">PRICE</div>
                  <div className="t-text-caption t-text-text-100">
                    ${customData?.pending_payment?.total}
                  </div>
                  <div className="t-text-caption t-text-text-100">
                    ${customData?.pending_payment?.paid_amount}
                  </div>
                  <div className="t-text-caption t-text-text-100">
                    ${amount}
                  </div>
                </div>
              </div>
            </div>
            <div className="t-h-16 t-p-4 t-bg-neutral-0 t-flex t-justify-end t-items-center t-rounded t-gap-5">
              <div className="t-flex t-flex-col t-items-end">
                <div className="t-text-overline t-text-text-100">
                  Initial Price <span className="t-text-dark_green">Paid</span>
                  <img src={DoubleGreenTick} alt="DoubleGreenTick" />
                </div>
                <div className="t-text-subtitle t-text-text-100">
                  ${customData?.pending_payment?.paid_amount}
                </div>
              </div>
              <div className="t-flex t-flex-col t-items-end">
                <div className="t-text-overline t-text-text-100">
                  Balance amount
                </div>
                <div className="t-text-subtitle t-text-text-100">
                  {" "}
                  <AmountSuperScript amount={Number(amount)} />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end">
              <Button customType="primary" onClick={openPaymentModal}>
                {hasReviewed
                  ? `Confirm review and pay $${amount}`
                  : `Pay $${amount}`}
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      {showPaymentModal && invoice && entityId && (
        <CheckoutModal
          entityId={entityId}
          type="invoices"
          open={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          onInvoicePaid={showCongratulateModal}
          invoices={[invoice]}
          messageId={messageId}
        />
      )}
      <BalancingPaymentSuccess
        show={showPaymentSuccessModal}
        closeModal={() => setShowPaymentSuccessModal(false)}
      />
    </>
  );
};
