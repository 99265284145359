import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldProps } from "formik";

export const FilingEmailInput = ({ name, ...props }: { name: string }) => {
  const validateEmail = (value: string) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  return (
    <Field name={name} placeholder="Email" validate={validateEmail} {...props}>
      {({ field, form: { touched } }: FieldProps) => (
        <TextInput
          {...{ ...field, ...props }}
          type="email"
          placeholder="Email"
          customType={
            touched[field.name] || touched[field.name] === undefined
              ? "success"
              : "error"
          }
        />
      )}
    </Field>
  );
};
