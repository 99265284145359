import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Table from "components/DesignSystem/Table/V2/Table";
import { DarkFilledInfo } from "components/icons/DarkFilledInfo";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import React, { useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import ConnectBankIcon from "static/images/ConnectBank.svg";
import {
  useExportBooksQuotesMutation,
  useGetBooksQuotesQuery,
} from "store/apis/financialClosing";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import ConnectBankInQuotes from "./ConnectBankInQuotes";

type HeaderNameKeys =
  | "month"
  | "cash_expense"
  | "pro_pricing"
  | "pro_pricing_slab"
  | "transactions_count";

export const BooksQuotes = () => {
  const currentYear = dayjs().year();
  const [season, setSeason] = useState<string>((currentYear - 1).toString());
  const startYear = 2021;
  const yearOptions = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push(year.toString());
  }
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const {
    data: quoteList = [],
    isLoading,
    isSuccess,
  } = useGetBooksQuotesQuery(
    { groupId, entityId, year: season },
    { skip: !groupId || !entityId || !season, refetchOnMountOrArgChange: true }
  );
  const [exportBooksQuotes, { isLoading: isExporting }] =
    useExportBooksQuotesMutation();
  const [getPreviewUrl, { isLoading: isGettingPreviewUrl }] =
    useLazyGetPreviewUrlQuery();
  const { alertToast } = useToast();
  const {
    open: openConnectionModal,
    close: closeConnectionModal,
    isOpen: isConnectionModalOpen,
  } = useModal();
  const months = quoteList ? quoteList.map(({ month }) => month) : [];

  const exportQuotes = async () => {
    try {
      const { excel_document_id } = await exportBooksQuotes({
        groupId,
        entityId,
        year: season,
      }).unwrap();
      const { download_url } = await getPreviewUrl({
        groupId,
        fileId: excel_document_id,
      }).unwrap();
      openLink(download_url, "_blank");
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const getAllMonthdata = (name: HeaderNameKeys) => {
    return quoteList.reduce((acc, q) => ({ ...acc, [q.month]: q[name] }), {});
  };

  const newData = useMemo(
    () => [
      {
        header: (
          <div>
            Cash Expense{" "}
            <ToolTip text="This is based on bank account debits excluding transfers.">
              <span>
                <DarkFilledInfo />
              </span>
            </ToolTip>{" "}
          </div>
        ),
        ...getAllMonthdata("cash_expense"),
        type: "CURRENCY",
      },
      {
        header: (
          <div>
            Total transactions{" "}
            <ToolTip text="This is based on total transactions across all data sources.">
              <span>
                <DarkFilledInfo />
              </span>
            </ToolTip>{" "}
          </div>
        ),
        ...getAllMonthdata("transactions_count"),
        type: "STRING",
      },
      {
        header: "Pro pricing slab",
        ...getAllMonthdata("pro_pricing_slab"),
        type: "STRING",
      },
      {
        header: "Pro pricing",
        ...getAllMonthdata("pro_pricing"),
        type: "CURRENCY",
      },
    ],
    [quoteList]
  );

  const columnHelper = createColumnHelper<any>();

  const monthColumns = months.map((month) =>
    columnHelper.accessor(month, {
      cell: (info) => {
        if (info.getValue()) {
          return (
            <div className="t-text-body t-text-text-30">
              {!isNaN(Number(info.getValue())) &&
              info.row.original.type === "CURRENCY" ? (
                <AmountSuperScript amount={info.getValue()} />
              ) : (
                info.getValue()
              )}
            </div>
          );
        }

        return "-";
      },
      header: () => (
        <div className="t-text-subtext t-text-text-30">{month}</div>
      ),
    })
  );

  const columns = [
    columnHelper.accessor("header", {
      cell: (info) => <div>{info.getValue()}</div>,
      size: 180,
      header: () => "Month",
    }),
    ...monthColumns,
  ];

  const table = useReactTable({
    data: newData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const isEmpty = !quoteList || quoteList?.length === 0;
  const isExportingQuotes = isExporting || isGettingPreviewUrl;

  return (
    <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
      <Async.Empty>
        <>
          <div className="t-mx-auto t-flex t-w-3/5 t-flex-col t-items-center t-justify-center t-gap-4 t-px-3 t-py-8">
            <img src={ConnectBankIcon} alt="ConnectBankIcon" />
            <p className="t-m-0 t-text-subtext-sm t-text-text-100">
              Connect your accounts to continue
            </p>
            <Button
              customType="primary"
              size="small"
              onClick={openConnectionModal}
            >
              Connect Bank
            </Button>
          </div>
          <ConnectBankInQuotes
            isOpen={isConnectionModalOpen}
            close={closeConnectionModal}
          />
        </>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-justify-between t-items-center">
          <div className="t-text-body-lg">
            Estimate for bookkeeping services
          </div>
          <div className="t-flex t-gap-4">
            <Combobox
              menuPlacement="auto"
              menuPortalTarget={document.body}
              size="small"
              tooltip
              creatable={false}
              isClearable={false}
              options={yearOptions.map((year) => ({
                label: year,
                value: year,
              }))}
              onChange={(value: any) => {
                setSeason(value.value);
              }}
              styles={{
                menu: (base) => ({
                  ...base,
                  width: "100px",
                }),
              }}
              value={{ label: season, value: season }}
            />
            <ToolTip text="Export as CSV">
              <span>
                <Button
                  customType="primary"
                  size="small"
                  onClick={exportQuotes}
                  disabled={isExportingQuotes}
                  isLoading={isExportingQuotes}
                >
                  Export
                </Button>
              </span>
            </ToolTip>
          </div>
        </div>
        <Table.Container
          className="t-mt-5 t-overflow-scroll scrollbar-visible"
          layout="flex"
        >
          <Table.Content className="t-w-full">
            <Table.Body>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id} className="t-pl-0">
                  {headerGroup.headers.map((header, index) => (
                    <Table.Cell
                      className="books-quote-form-cell"
                      key={header.id}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
              {table.getRowModel().rows.map((row) => (
                <Table.Row key={row.id} className="t-pl-0">
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                      className="books-quote-form-cell !t-py-4"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      </Async.Success>
    </Async.Root>
  );
};
