import { Profile } from "types/Models/user";
import { axiosInstance } from "./apiInstance";

const NAME_UPDATE = "NAME_UPDATE";
const PASSWORD_UPDATE = "PASSWORD_UPDATE";
const PUBLIC_USER_UPDATE = "PUBLIC_USER_UPDATE";

export const profileData = async () => {
  return await axiosInstance.get<{ data: Profile }>(
    "/api/inkle/users/profile/"
  );
};

export const profileDataUpdate = async (payload: any) => {
  return await axiosInstance.put("/api/inkle/users/profile/", payload);
};

export const updateName = async ({
  first_name,
  last_name,
}: {
  first_name: string;
  last_name: string;
}) => {
  return await axiosInstance.put("/api/inkle/users/profile/", {
    operation: NAME_UPDATE,
    first_name,
    last_name,
  });
};

export const updateAdditionalInfo = async ({
  linkedIn_url,
  secondary_mobile,
  current_timezone,
}: {
  linkedIn_url?: string;
  secondary_mobile?: string;
  current_timezone?: string;
}) => {
  return await axiosInstance.put("/api/inkle/users/profile/", {
    operation: "ADDITIONAL_INFO_UPDATE",
    linkedIn_url,
    secondary_mobile,
    current_timezone,
  });
};

export const updatePassword = async (passwordParams: any) => {
  return await axiosInstance.put("/api/inkle/users/profile/", {
    operation: PASSWORD_UPDATE,
    ...passwordParams,
  });
};

export const updatePublicUser = async ({
  isPublicUser,
}: {
  isPublicUser: boolean;
}) => {
  return await axiosInstance.put("/api/inkle/users/profile/", {
    operation: PUBLIC_USER_UPDATE,
    is_public_user: isPublicUser,
  });
};
