import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";

dayjs.extend(utcPlugin);

export const timeToDateTime = (time: string) => {
  const [hour, minute] = time.split(":");
  return dayjs()
    .utc()
    .set("hour", +hour)
    .set("minute", +minute)
    .set("second", 0)
    .set("millisecond", 0)
    .toISOString();
};
