import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/V2/Table";
import { Preview } from "components/PreviewModal";
import { DownloadIcon } from "components/icons/Download";
import { DeleteIcon } from "components/icons/delete";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetAllCapTablesQuery } from "store/apis/capTable";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { CapTable as CapTableType } from "types/Models/capTable";
import { fileAndFolder } from "types/Models/documents";
import { BackendError } from "types/utils/error";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import { CapTableSlider } from "./CapTableSlider";
import ToolTip from "components/design/toolTip";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { debounce } from "utils/debouncing";
import { Search } from "components/DesignSystem/Search/Search";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { DeleteModal } from "./DeleteModal";
import { useParams } from "react-router-dom";

const CapTablePreview = ({ document }: { document: fileAndFolder }) => {
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    isOpen: isPreviewModalOpen,
    close: onPreviewClose,
    open: openPreview,
  } = useModal();

  const { uuid: previewId, file_type } = document;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ToolTip text="Preview" side="top">
        <span>
          <Button
            customType="ghost_icon"
            size="small"
            onClick={(e) => {
              openPreview();
            }}
          >
            <FileIcon fileType={file_type} width="24px" height="24px" />
          </Button>
        </span>
      </ToolTip>
      {isPreviewModalOpen && (
        <Preview
          showModal={isPreviewModalOpen}
          closeModal={onPreviewClose}
          groupId={groupId}
          previewId={previewId}
        />
      )}
    </div>
  );
};

const Actions = ({
  capTableId,
  fileId,
}: {
  capTableId: string;
  fileId: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast } = useToast();

  const { isOpen, open, close } = useModal();

  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const onDownload = async () => {
    try {
      const { download_url } = await getPreviewUrl({
        groupId: groupId,
        fileId: fileId,
      }).unwrap();
      openLink(download_url);
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <>
      <div
        className="t-flex t-gap-2 t-items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <ToolTip text="Download">
          <span>
            <Button customType="ghost_icon" size="small" onClick={onDownload}>
              <DownloadIcon color="currentColor" />
            </Button>
          </span>
        </ToolTip>
        <ToolTip text="Delete">
          <span>
            <Button customType="ghost_icon" size="small" onClick={open}>
              <DeleteIcon />
            </Button>
          </span>
        </ToolTip>
      </div>
      {isOpen && (
        <DeleteModal isOpen={isOpen} close={close} capTableId={capTableId} />
      )}
    </>
  );
};

export const CapTable = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { entityId } = useParams<{ entityId: string }>();
  const [capTableId, setCapTableId] = useState("");
  const { update, updateMultiple } = useUpdateQuery();
  const query = useQuery();
  const pageNum = Number(query.get("page")) || 1;
  const searchTerm = query.get("search_term") || null;

  const { data: capTable } = useGetAllCapTablesQuery({
    pageNum: 1,
    searchTerm,
    groupId,
    entityId,
  });

  const {
    cap_tables: capTableData = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = capTable || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = pageNum < total_pages ? pageNum : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNum < total_pages) {
      update({ query: "page", value: pageNum + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const handleChange = debounce((e) => {
    const { value } = e.target;
    updateMultiple([
      { query: "search_term", value: value || null },
      { query: "page", value: 1 },
    ]);
  });

  const onRowClick = (id: string) => {
    setCapTableId(id);
  };

  const createColumn = createColumnHelper<CapTableType>();

  const columns = useMemo(
    () => [
      createColumn.accessor("valid_as_on", {
        size: 12,
        header: "AS ON",
        cell: (info) => {
          const {
            row: {
              original: { valid_as_on },
            },
          } = info;

          return (
            <div className="t-text-subtext">
              {dayjs(valid_as_on).format(DD_MMM_YYYY)}
            </div>
          );
        },
      }),

      createColumn.accessor("note", {
        size: 70,
        header: "NOTE",
        cell: (info) => {
          const {
            row: {
              original: { note },
            },
          } = info;

          return (
            <>
              {note ? (
                <ToolTip text={note} side="top">
                  <span>
                    <div className="t-text-subtext t-text-text-60 t-w-[730px] t-truncate">
                      {note}
                    </div>
                  </span>
                </ToolTip>
              ) : (
                "-"
              )}
            </>
          );
        },
      }),

      createColumn.accessor("document", {
        size: 10,
        header: "FILE",
        cell: (info) => {
          const {
            row: {
              original: { document },
            },
          } = info;

          return <CapTablePreview document={document} />;
        },
      }),

      createColumn.display({
        size: 5,
        header: "ACTIONS",
        cell: (info) => {
          const {
            row: {
              original: {
                uuid,
                document: { uuid: fileId },
              },
            },
          } = info;
          return <Actions capTableId={uuid} fileId={fileId} />;
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: capTableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <div>
      <div className="t-flex t-justify-between t-items-center">
        <div className="t-w-1/2 t-flex t-items-center">
          <Search
            placeholder="Search..."
            defaultValue={searchTerm || ""}
            onChange={handleChange}
            block
          />
        </div>
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <div className="t-py-5">
        <Table.Container>
          <Table.Content>
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body>
              {table.getRowModel().rows.map((row) => (
                <Table.Row
                  key={row.id}
                  onClick={() => onRowClick(row.original.uuid)}
                  className={classNames(
                    "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                    {
                      "t-bg-surface-lighter-grey":
                        capTableId === row.original.uuid,
                    }
                  )}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Content>
        </Table.Container>
        <CapTableSlider capTableId={capTableId} setCapTableId={setCapTableId} />
      </div>
    </div>
  );
};
