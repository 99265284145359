import { array, object, string } from "yup";

export const moveCategoriesSchema = object({
  move_to_category_id: string().required("Please select a category"),
  moveCategories: array()
    .of(
      object({
        newId: string()
          .required("Please enter Account ID")
          .length(5, "Account ID must be exactly 5 characters")
          .matches(/^[^_]+$/, "Account ID must be exactly 5 digits"),
      })
    )
    .required(),
});
