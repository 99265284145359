export const RenameIcon = ({ color, size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.0123 6.24699C14.3994 5.85364 14.3944 5.21891 14.0042 4.83495L11.2064 2.03776C11.0163 1.85015 10.763 1.74635 10.4973 1.7501C10.2302 1.75197 9.97946 1.85828 9.79436 2.04589L2.28954 9.55133C2.10193 9.73956 1.99938 9.9897 2 10.2542V13.0495C2 13.6011 2.449 14.0501 3.00056 14.0501H13.5064C13.7828 14.0501 14.0067 13.8262 14.0067 13.5498C14.0067 13.2734 13.7828 13.0495 13.5064 13.0495H7.20979L14.0123 6.24699ZM10.5079 2.75316L13.2969 5.54785L12.0062 6.83857L9.21404 4.04638L10.5079 2.75316ZM2.99806 10.258L8.50551 4.75365L11.2977 7.54584L5.79525 13.0495H3.00056L2.99806 10.258Z"
        fill={color}
      />
    </svg>
  );
};
