import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { FieldArray, Form, Formik } from "formik";
import { groupDetails } from "formValidations/salesCrm";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import React from "react";
import { useCheckValidEmailMobileMutation } from "store/apis/salesCrm";
import { checkEmptyObject } from "utils/checkEmptyObject";
import Modal from "components/DesignSystem/Modal/Modal";

type UserDataPayLoad = {
  first_name: string;
  last_name: string;
  email: string;
  linkedin: string;
};

type AddTeamMemberProps = {
  memberNumber: number;
  firstName: string;
  lastName: string;
  email: string;
  linkedin: string;
  remove: (obj: any) => void;
  isAddMemberBtnDisabled: boolean;
  userData: UserDataPayLoad[];
};

type NewGroupCreationModalProps = {
  showModal: boolean | undefined;
  setShowModal: (newState: React.SetStateAction<boolean>) => void;
  setShowEntityModal: (newState: React.SetStateAction<boolean>) => void;
  userDataPayload: UserDataPayLoad | null;
  setUserDataPayLoad: (
    newState: React.SetStateAction<UserDataPayLoad[]>
  ) => void;
  setGroupName: (newState: React.SetStateAction<string>) => void;
  fromProfiles?: boolean;
};

type FormValues = {
  group_name: string;
  leadfounder_first_name: string;
  leadfounder_last_name: string;
  leadfounder_email: string;
  leadfounder_linkedin: string;
  users_data: UserDataPayLoad[];
};

const AddTeamMember = ({
  memberNumber,
  firstName,
  lastName,
  email,
  linkedin,
  remove,
  isAddMemberBtnDisabled,
  userData,
}: AddTeamMemberProps) => {
  const handleDeleteEntity = () => {
    remove(memberNumber);
    isAddMemberBtnDisabled = false;
  };

  return (
    <>
      <Divider />
      <div className="t-flex t-flex-row t-justify-between">
        <strong>Team Member</strong>
        <Button customType="ghost_icon" onClick={handleDeleteEntity}>
          <DeleteIcon color="black" />
        </Button>
      </div>
      <div className="t-flex t-w-full t-gap-3">
        <TextInput
          className="!t-w-[48%]"
          block
          name={firstName}
          label="First name"
          placeholder="Enter first name"
        />
        <TextInput
          block
          className="!t-w-[48%]"
          name={lastName}
          label="Last name"
          placeholder="Enter last name"
        />
      </div>

      <TextInput
        block
        name={email}
        type="email"
        label="Email"
        placeholder="Enter email"
      />
      <TextInput
        block
        name={linkedin}
        label="LinkedIn"
        placeholder="LinkedIn link"
      />
    </>
  );
};

const NewGroupCreationModal = ({
  showModal,
  setShowModal,
  setShowEntityModal,
  userDataPayload,
  setUserDataPayLoad,
  setGroupName,
  fromProfiles = false,
}: NewGroupCreationModalProps) => {
  const { alertToast } = useToast();
  const { isForeignCA, isUserTypeForeignCA } = useRoleBasedView();
  const isFCA = isUserTypeForeignCA || isForeignCA;

  const userData = {
    first_name: "",
    last_name: "",
    email: "",
    linkedin: "",
  };

  const [checkValidEmailMobile, { data }] = useCheckValidEmailMobileMutation();
  const checkEmailMobile = async (values: FormValues) => {
    const emails = [values.leadfounder_email];
    values.users_data.map(({ email }) => {
      emails.push(email);
    });

    const emailMobileData = { emails };

    try {
      let existingEmails = false;
      if (!fromProfiles) {
        const response = await checkValidEmailMobile(emailMobileData).unwrap();
        existingEmails = response?.emails_already_exits.length > 0;
      }

      if (existingEmails) {
        alertToast({ message: "Existing Emails found" });
        setShowModal(false);
      } else {
        let updatedUserData = values.users_data.map((data) => ({
          ...data,
        }));

        setUserDataPayLoad([
          {
            first_name: values.leadfounder_first_name,
            last_name: values.leadfounder_last_name,
            email: values.leadfounder_email,
            linkedin: values.leadfounder_linkedin,
          },
          ...updatedUserData,
        ]);
        setGroupName(values.group_name);
        setShowEntityModal(true);
      }
    } catch (e: any) {
      setShowModal(false);
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal.Root open={showModal} onOpenChange={handleClose}>
      {showModal && (
        <Formik
          initialValues={{
            group_name: "",
            leadfounder_first_name: userDataPayload?.first_name || "",
            leadfounder_last_name: userDataPayload?.last_name || "",
            leadfounder_email: userDataPayload?.email || "",
            leadfounder_linkedin: userDataPayload?.linkedin || "",
            users_data: [] as unknown as {
              first_name: string;
              last_name: string;
              email: string;
              linkedin: string;
            }[],
          }}
          validationSchema={groupDetails}
          onSubmit={checkEmailMobile}
          validateOnChange={false}
        >
          {({ values, isSubmitting, submitForm }) => {
            let isAddMemberBtnDisabled = false;
            if (values?.users_data.length > 0) {
              const index = values.users_data.length - 1;
              let lastMember = {
                first_name: values?.users_data[index]?.first_name,
                last_name: values?.users_data[index]?.last_name,
                email: values?.users_data[index]?.email,
              };
              if (!checkEmptyObject(lastMember)) {
                isAddMemberBtnDisabled = true;
              } else {
                isAddMemberBtnDisabled = false;
              }
            }

            return (
              <Modal.Content>
                <Form>
                  <Modal.Header>
                    <Modal.Title>
                      {isFCA ? "New Client" : "New Group"}
                    </Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body>
                    <div className="t-mb-3 t-flex t-max-h-[500px] t-flex-col t-gap-6 t-overflow-auto">
                      <TextInput
                        required
                        block
                        name="group_name"
                        label="Brand name"
                        placeholder="Enter the name"
                      />
                      <strong>Lead Founder</strong>
                      <div className="t-flex t-w-full t-gap-3">
                        <TextInput
                          required
                          className="!t-w-[48%]"
                          block
                          name="leadfounder_first_name"
                          label="First name"
                          placeholder="Enter first name"
                        />
                        <TextInput
                          required
                          block
                          className="!t-w-[48%]"
                          name="leadfounder_last_name"
                          label="Last name"
                          placeholder="Enter last name"
                        />
                      </div>

                      <TextInput
                        required
                        block
                        name="leadfounder_email"
                        label={
                          isFCA
                            ? "Email (For record only, Inkle will not contact the founder)"
                            : "Email"
                        }
                        placeholder="Enter email"
                        disabled={fromProfiles}
                      />
                      {!isFCA && (
                        <TextInput
                          block
                          name="leadfounder_linkedin"
                          label="Linkedin"
                          placeholder="LinkedIn"
                        />
                      )}

                      <FieldArray name="users_data">
                        {({ push, remove }) => {
                          return (
                            <>
                              {values.users_data.map((_, index) => (
                                <>
                                  <AddTeamMember
                                    memberNumber={index}
                                    firstName={`users_data.${index}.first_name`}
                                    lastName={`users_data.${index}.last_name`}
                                    email={`users_data.${index}.email`}
                                    linkedin={`users_data.${index}.linkedin`}
                                    remove={remove}
                                    isAddMemberBtnDisabled={
                                      isAddMemberBtnDisabled
                                    }
                                    userData={values.users_data}
                                  />
                                </>
                              ))}
                              <div className="t-mr-auto">
                                <Button
                                  size="small"
                                  customType="secondary"
                                  disabled={isAddMemberBtnDisabled}
                                  type="button"
                                  onClick={() => {
                                    push(userData);
                                  }}
                                >
                                  + Another Team Member
                                </Button>
                              </div>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      customType="primary"
                      size="regular"
                      type="submit"
                      onClick={submitForm}
                    >
                      Next
                    </Button>
                  </Modal.FooterButtonGroup>
                </Form>
              </Modal.Content>
            );
          }}
        </Formik>
      )}
    </Modal.Root>
  );
};

export default NewGroupCreationModal;
