export const Metrics = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_416_49808)">
      <path
        d="M3.54395 13.5V9H6.54395"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5439 13.5H2.54395"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.54395 13.5V6H10.0439"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0439 13.5V3H13.5439V13.5"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_416_49808">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.543945 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
