import { ReactNode } from "react";
import * as PopOver from "@radix-ui/react-popover";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";

type ContentProps = {
  className?: string;
};

const Root = ({
  children,
  ...props
}: React.RefAttributes<HTMLDivElement> & PopOver.PopoverProps) => {
  return <PopOver.Root {...props}>{children}</PopOver.Root>;
};

const Portal = (props: PopOver.PopoverPortalProps) => {
  return (
    <AnimatePresence>
      <PopOver.Portal {...props} />
    </AnimatePresence>
  );
};

const Content = ({
  children,
  className,
  ...props
}: React.RefAttributes<HTMLDivElement> &
  PopOver.PopoverContentProps &
  ContentProps) => {
  return (
    <PopOver.Content {...props} asChild sideOffset={10}>
      <div
        className={classNames(
          "t-bg-neutral-100 !t-z-dropdown t-p-3 t-rounded",
          {
            [className as string]: className,
          }
        )}
      >
        {children}
      </div>
    </PopOver.Content>
  );
};

const Header = ({ children }: { children: ReactNode }) => {
  return <div className="t-text-subtitle-sm t-text-white">{children}</div>;
};

const Propover = {
  ...PopOver,
  Root,
  Portal,
  Content,
  Header,
};

export default Propover;
