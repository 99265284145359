import { array, object, string } from "yup";

const emailValidationRegex =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const billingContactSchema = (groupName: string) =>
  object({
    companyName: string().min(1).required("Company name is required"),
    email: string().min(1).required("Email is required"),
  });

export const billingAddressSchema = () =>
  object({
    invoice_emails: array()
      .min(1, "Please add at least one email address.")
      .test("invalidEmails", "", function (value) {
        if (!value) {
          return this.createError({
            path: this.path,
            message: "Please add at least one email address.",
          });
        }

        const invalidEmails = value.filter(
          (email) => !emailValidationRegex.test(email!)
        );

        if (invalidEmails.length > 0) {
          return this.createError({
            path: this.path,
            message: `Invalid emails: ${invalidEmails.join(", ")}`,
          });
        }

        return true;
      }),
  });
