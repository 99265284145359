export const DOCUMENTS_ROUTES = ["/documents/:docId"];

export const DEALS_INTROS_ROUTES = ["/perks", "/createperk"];
export const TASK_ROUTES = ["/filings", "/myfilings", "/bookkeeping-tracker"];
export const CRM_ROUTES = ["/crm", "/radeadlines", "/bookscrm"];
export const NO_SIDEBAR_ROUTES = [
  "/forum",
  "/sales-tax",
  "/handbook",
  "/inklecalendar",
  "/createperk",
  "/books",
  "/intragroup",
  "/board",
  "/mailroom",
  "/community",
];
export const ENTITY_ROUTES = ["/entity", "/stock", "/esops", "/w-forms"];

export const NO_HEADER_ROUTES = ["/inklecalendar", "/books", "/intragroup"];
export const NO_GROUP_ACCESS_ROUTES = ["/forum", "/handbook", "/home"];
export const RAISE_ROUTES = ["/raise"];

export const CUSTOMIZE_SIDEBAR_ROUTES = ["/rewards", "/billing"];

export const DASHBOARD_SIDEBAR_MESSAGE_UPDATE =
  "DASHBOARD_SIDEBAR_MESSAGE_UPDATE";

export const TIME_GAP_TO_SHOW_REMINDER = 24 * 60 * 60 * 1000;
