export const InklePracticePro = () => (
  <svg
    width="248"
    height="56"
    viewBox="0 0 248 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5723_7922)">
      <mask
        id="mask0_5723_7922"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="18"
        width="20"
        height="20"
      >
        <path d="M36 18H16V38H36V18Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5723_7922)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 18L16 23L26 28L16 33L16 38L26 33V38L36 33V28V23L26 18V23L16 18ZM26 23V28V33L36 28L26 23Z"
          fill="url(#paint0_linear_5723_7922)"
        />
      </g>
    </g>
    <path
      d="M46 20.065C46 18.8121 46.78 18 48.0058 18C49.2092 18 49.9893 18.8121 49.9893 20.065C49.9893 21.3179 49.2092 22.1531 48.0058 22.1531C46.78 22.1531 46 21.3179 46 20.065ZM49.7887 37.884H46.2229V23.9629H49.7887V37.884Z"
      fill="url(#paint1_linear_5723_7922)"
    />
    <path
      d="M60.7289 30.9002C60.7289 28.8585 59.7037 27.5128 58.1214 27.5128C56.6282 27.5128 55.5362 28.9513 55.5362 30.9234V37.884H51.9704V23.9629H55.5362V25.587C56.0042 24.5429 57.386 23.8005 58.7677 23.8005C62.1775 23.8005 64.2947 26.6311 64.2947 30.9002V37.884H60.7289V30.9002Z"
      fill="url(#paint2_linear_5723_7922)"
    />
    <path
      d="M70.0119 37.884H66.4461V18.1624H70.0119V30.2042L74.536 23.9629H78.4362L73.8897 30.9234L78.9042 37.884H74.38L70.0119 31.6427V37.884Z"
      fill="url(#paint3_linear_5723_7922)"
    />
    <path
      d="M83.8049 37.884H80.2391V18.1624H83.8049V37.884Z"
      fill="url(#paint4_linear_5723_7922)"
    />
    <path
      d="M94.6461 33.6148H98.4348C97.5211 36.2135 95.1364 38 92.4175 38C88.5174 38 85.7761 35.0534 85.7761 30.877C85.7761 26.8399 88.6065 23.7773 92.3506 23.7773C96.0948 23.7773 98.9251 26.8399 98.9251 30.877C98.9251 31.3179 98.9028 31.7819 98.836 32.1995H89.5203C89.9214 33.6148 91.0135 34.5197 92.3952 34.5197C93.4427 34.5197 94.2673 34.1485 94.6461 33.6148ZM89.5203 29.5313H95.181C94.7799 28.1624 93.7324 27.2807 92.3506 27.2807C90.9689 27.2807 89.9214 28.1624 89.5203 29.5313Z"
      fill="url(#paint5_linear_5723_7922)"
    />
    <path
      d="M219.459 31.7402C219.459 28.2128 222.069 25.501 225.454 25.501C228.818 25.501 231.407 28.2128 231.407 31.7402C231.407 35.288 228.818 37.9999 225.454 37.9999C222.069 37.9999 219.459 35.288 219.459 31.7402ZM225.454 27.6827C223.313 27.6827 221.661 29.4362 221.661 31.7402C221.661 34.0646 223.313 35.7978 225.454 35.7978C227.594 35.7978 229.226 34.0646 229.226 31.7402C229.226 29.4362 227.594 27.6827 225.454 27.6827Z"
      fill="#41395C"
    />
    <path
      d="M218.497 25.501V27.6827C215.48 27.6623 213.747 29.2323 213.747 31.9849V37.8775H211.565V25.6437H213.747V28.1516C214.725 26.0719 216.805 25.501 218.497 25.501Z"
      fill="#41395C"
    />
    <path
      d="M204.331 31.5981H200.335V37.8781H198.133V20.5469H204.148C207.675 20.5469 210.061 22.6266 210.061 26.0317C210.061 29.3552 207.716 31.5981 204.331 31.5981ZM204.148 22.7286H200.335V29.396H204.331C206.37 29.396 207.859 28.0095 207.859 26.0317C207.859 24.0539 206.35 22.7286 204.148 22.7286Z"
      fill="#41395C"
    />
    <path
      d="M188.354 34.1257H190.678C189.842 36.4093 187.701 37.9794 185.254 37.9794C181.747 37.9794 179.28 35.3899 179.28 31.7197C179.28 28.1719 181.829 25.4805 185.193 25.4805C188.537 25.4805 191.127 28.1515 191.127 31.6178C191.127 31.9236 191.106 32.2295 191.045 32.5149H181.523C181.829 34.5539 183.277 35.92 185.234 35.92C186.661 35.92 187.803 35.186 188.354 34.1257ZM181.544 30.9041H188.884C188.578 28.9467 187.11 27.5602 185.193 27.5602C183.297 27.5602 181.849 28.9263 181.544 30.9041Z"
      fill="#41395C"
    />
    <path
      d="M171.811 27.6622C169.589 27.6622 168.08 29.273 168.08 31.7197C168.08 34.1665 169.589 35.7773 171.811 35.7773C173.483 35.7773 174.768 34.8597 175.318 33.2489H177.622C176.99 36.2054 174.809 37.9794 171.811 37.9794C168.284 37.9794 165.878 35.4918 165.878 31.7197C165.878 27.968 168.284 25.4805 171.811 25.4805C174.788 25.4805 176.99 27.2544 177.622 30.1905H175.318C174.747 28.5797 173.483 27.6622 171.811 27.6622Z"
      fill="#41395C"
    />
    <path
      d="M161.524 21.9739C161.524 21.1991 162.034 20.6689 162.85 20.6689C163.625 20.6689 164.134 21.1991 164.134 21.9739C164.134 22.7691 163.625 23.2992 162.85 23.2992C162.034 23.2992 161.524 22.7691 161.524 21.9739ZM163.951 37.8778H161.749V25.644H163.951V37.8778Z"
      fill="#41395C"
    />
    <path
      d="M155.409 23.0547H157.06V25.6442H159.935V27.7239H157.06V33.6369C157.06 35.0846 157.652 35.7167 159.038 35.7167H159.935V37.878H158.834C156.041 37.878 154.858 36.6954 154.858 33.7593V27.7239H152.962V25.6442H154.858L155.409 23.0547Z"
      fill="#41395C"
    />
    <path
      d="M145.503 27.6622C143.28 27.6622 141.771 29.273 141.771 31.7197C141.771 34.1665 143.28 35.7773 145.503 35.7773C147.175 35.7773 148.459 34.8597 149.01 33.2489H151.314C150.682 36.2054 148.5 37.9794 145.503 37.9794C141.975 37.9794 139.569 35.4918 139.569 31.7197C139.569 27.968 141.975 25.4805 145.503 25.4805C148.48 25.4805 150.682 27.2544 151.314 30.1905H149.01C148.439 28.5797 147.175 27.6622 145.503 27.6622Z"
      fill="#41395C"
    />
    <path
      d="M135.304 37.8775V35.5939C134.753 37.0212 133 37.9999 131.164 37.9999C127.657 37.9999 125.333 35.5123 125.333 31.7402C125.333 27.9885 127.637 25.501 131.164 25.501C132.979 25.501 134.753 26.4797 135.304 27.9274V25.6437H137.485V37.8775H135.304ZM127.535 31.7402C127.535 34.187 129.125 35.7978 131.43 35.7978C133.713 35.7978 135.304 34.187 135.304 31.7606C135.304 29.3546 133.672 27.6827 131.409 27.6827C129.105 27.6827 127.535 29.2935 127.535 31.7402Z"
      fill="#41395C"
    />
    <path
      d="M124.289 25.501V27.6827C121.271 27.6623 119.538 29.2323 119.538 31.9849V37.8775H117.356V25.6437H119.538V28.1516C120.517 26.0719 122.597 25.501 124.289 25.501Z"
      fill="#41395C"
    />
    <path
      d="M110.124 31.5981H106.127V37.8781H103.925V20.5469H109.94C113.468 20.5469 115.853 22.6266 115.853 26.0317C115.853 29.3552 113.508 31.5981 110.124 31.5981ZM109.94 22.7286H106.127V29.396H110.124C112.163 29.396 113.651 28.0095 113.651 26.0317C113.651 24.0539 112.142 22.7286 109.94 22.7286Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7922"
        x1="16"
        y1="28"
        x2="36"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7922"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7922"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7922"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5723_7922"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5723_7922"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <clipPath id="clip0_5723_7922">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(16 18)"
        />
      </clipPath>
    </defs>
  </svg>
);
