export const BooksServices = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={112}
    height={112}
    viewBox="0 0 112 112"
    fill="none"
  >
    <g clipPath="url(#clip0_1844_249894)">
      <path
        d="M87.5 77V28C87.5 25.2152 86.3938 22.5445 84.4246 20.5754C82.4555 18.6062 79.7848 17.5 77 17.5H17.5"
        stroke="currentColor"
        strokeWidth={7.84}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5 45.5H73.5"
        stroke="currentColor"
        strokeWidth={7.84}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5 59.5H73.5"
        stroke="currentColor"
        strokeWidth={7.84}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 35C10.5 35 7 32.375 7 28C7 25.2152 8.10625 22.5445 10.0754 20.5754C12.0445 18.6062 14.7152 17.5 17.5 17.5C20.2848 17.5 22.9555 18.6062 24.9246 20.5754C26.8938 22.5445 28 25.2152 28 28V84C28 86.7848 29.1062 89.4555 31.0754 91.4246C33.0445 93.3938 35.7152 94.5 38.5 94.5M38.5 94.5C41.2848 94.5 43.9555 93.3938 45.9246 91.4246C47.8938 89.4555 49 86.7848 49 84C49 79.625 45.5 77 45.5 77H94.5C94.5 77 98 79.625 98 84C98 86.7848 96.8938 89.4555 94.9246 91.4246C92.9555 93.3938 90.2848 94.5 87.5 94.5H38.5Z"
        stroke="currentColor"
        strokeWidth={7.84}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1844_249894">
        <rect width={112} height={112} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
