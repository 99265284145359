import { default as classNames, default as cx } from "classnames";
import { Field } from "formik";
import { InputHTMLAttributes, ReactNode, useRef } from "react";
import "../../static/styles/components/input.css";

const CustomInput = ({ className = "", ...props }: { className: string }) => {
  const inputRef = useRef<any>(null);

  const handleClick = () => {
    inputRef.current?.showPicker();
  };

  return (
    <span onClick={handleClick}>
      <input
        className={`custom-input ${className}`}
        {...props}
        ref={inputRef}
      />
    </span>
  );
};

type InputProps = {
  label?: string | ReactNode;
  name: string;
  block?: boolean;
  customClass?: string;
  sizeType?: "medium" | "small" | "large";
  customInputClass?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = ({
  label,
  name,
  type = "text",
  block,
  sizeType = "medium",
  customClass = "",
  customInputClass = "",
  ...restOfTheProps
}: InputProps) => {
  const isInline = type === "radio" || type === "checkbox";
  const id = type === "radio" ? `${name}-${restOfTheProps.value}` : name;

  return (
    <div
      className={cx({
        "radio-wrapper": isInline,
        "w-100": block,
      })}
    >
      {label && (
        <label
          className={classNames("custom-label", {
            [customClass]: Boolean(customClass),
            [sizeType]: sizeType,
            "radio-label": isInline,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <Field type={type} name={name} className="w-100" {...restOfTheProps}>
        {({
          field,
          form: { touched, errors },
        }: {
          field: any;
          form: { touched: any; errors: any };
        }) => (
          <>
            <CustomInput
              {...{ ...field, ...restOfTheProps }}
              id={id}
              type={type}
              className={cx({
                "w-100": block,
                [sizeType]: sizeType,
                [customInputClass]: Boolean(customInputClass),
              })}
            />

            {touched[field.name] && errors[field.name] && (
              <div className="custom-input-error">{errors[field.name]}</div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};
