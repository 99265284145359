export const VendorDefault = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.90918"
      y="0.828125"
      width="24"
      height="24"
      rx="12"
      fill="#E7E6EB"
    />
    <g clip-path="url(#clip0_408_49754)">
      <path
        d="M18.4717 10.7017H7.52881V17.8187H18.4717V10.7017Z"
        fill="#CFCDD6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6726 8.08289H16.6284C16.6701 8.55164 16.8297 9.02039 17.1243 9.48955C17.4184 9.95789 17.5784 10.4271 17.6188 10.8954H18.9459C18.8922 10.4266 18.688 9.95789 18.3093 9.48955C17.9305 9.0208 17.7263 8.55205 17.6726 8.08289Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.583 8.08289H16.6284C16.6701 8.55164 16.8297 9.02039 17.1243 9.48955C17.4184 9.95789 17.5784 10.4271 17.6188 10.8954H16.2918C16.263 10.4266 16.1488 9.95789 15.938 9.48955C15.7272 9.0208 15.613 8.55205 15.5834 8.08289H15.583Z"
        fill="#E7E6EB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.583 8.08289H14.5388C14.5555 8.55164 14.6247 9.0208 14.7513 9.48955C14.878 9.9583 14.9472 10.4271 14.9638 10.8954H16.2909C16.2622 10.4266 16.148 9.95789 15.9372 9.48955C15.7263 9.0208 15.6122 8.55205 15.5826 8.08289H15.583Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4937 8.08289H14.5391C14.5557 8.55164 14.6249 9.0208 14.7516 9.48955C14.8782 9.9583 14.9474 10.4271 14.9641 10.8954H13.637C13.6324 10.4266 13.6082 9.95789 13.5653 9.48955C13.5224 9.02122 13.4995 8.55205 13.4937 8.08289Z"
        fill="#E7E6EB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4938 8.08289H12.4496C12.4426 8.55164 12.4205 9.0208 12.3792 9.48955C12.338 9.95789 12.3171 10.4271 12.3101 10.8954H13.6371C13.6326 10.4266 13.6084 9.95789 13.5655 9.48955C13.5226 9.02122 13.4996 8.55205 13.4938 8.08289Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4046 8.08289H12.4501C12.443 8.55164 12.4209 9.0208 12.3796 9.48955C12.3384 9.95789 12.3176 10.4271 12.3105 10.8954H10.9834C11.0026 10.4266 11.0688 9.95789 11.1938 9.48955C11.3188 9.0208 11.3863 8.55205 11.4051 8.08289H11.4046Z"
        fill="#E7E6EB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4044 8.08289H10.359C10.329 8.55164 10.2153 9.0208 10.0065 9.48955C9.79819 9.95789 9.68652 10.4271 9.65527 10.8954H10.9824C11.0015 10.4266 11.0678 9.95789 11.1928 9.48955C11.3178 9.0208 11.3853 8.55205 11.404 8.08289H11.4044Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.31504 8.08289H10.3592C10.3292 8.55164 10.2155 9.0208 10.0067 9.48955C9.79837 9.95789 9.6867 10.4271 9.65545 10.8954H8.32837C8.37129 10.4266 8.52837 9.95747 8.82045 9.48955C9.11337 9.02039 9.27212 8.55205 9.31504 8.08289Z"
        fill="#E7E6EB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.315 8.08289H8.26875C8.21375 8.55164 8.01042 9.02039 7.63333 9.48955C7.25667 9.95747 7.055 10.4266 7 10.8954H8.32833C8.37125 10.4266 8.52833 9.95747 8.82042 9.48955C9.11333 9.02039 9.27208 8.55205 9.315 8.08289Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3852 17.8005H14.6925V13.5508C14.6925 13.519 14.6576 13.4928 14.6144 13.4928H11.4628C11.4201 13.4928 11.3848 13.519 11.3848 13.5508V17.8005H11.3852Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9421 10.8538H17.6155V11.3996C17.6155 11.7875 17.9138 12.105 18.2788 12.105C18.6438 12.105 18.9426 11.7875 18.9426 11.3996V10.8538H18.9421Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2883 10.8538H17.6149V11.3996C17.6149 11.7875 17.3166 12.105 16.9516 12.105C16.5866 12.105 16.2878 11.7875 16.2878 11.3996V10.8538H16.2883Z"
        fill="#F3F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6347 10.8538H14.9614V11.3996C14.9614 11.7875 14.663 12.105 14.298 12.105C13.933 12.105 13.6343 11.7875 13.6343 11.3996V10.8538H13.6347Z"
        fill="#F3F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6345 10.8538H12.3079V11.3996C12.3079 11.7875 12.6062 12.105 12.9712 12.105C13.3362 12.105 13.6349 11.7875 13.6349 11.3996V10.8538H13.6345Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9811 10.8538H12.3078V11.3996C12.3078 11.7875 12.0095 12.105 11.6445 12.105C11.2795 12.105 10.9807 11.7875 10.9807 11.3996V10.8538H10.9811Z"
        fill="#F3F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9812 10.8538H9.65454V11.3996C9.65454 11.7875 9.95287 12.105 10.3179 12.105C10.6829 12.105 10.9816 11.7875 10.9816 11.3996V10.8538H10.9812Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.32757 10.8538H9.65423V11.3996C9.65423 11.7875 9.3559 12.105 8.9909 12.105C8.6259 12.105 8.32715 11.7875 8.32715 11.3996V10.8538H8.32757Z"
        fill="#F3F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.32757 10.8538H7.00049V11.3996C7.00049 11.7875 7.29882 12.105 7.66382 12.105C8.02882 12.105 8.32757 11.7875 8.32757 11.3996V10.8538Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6923 8.23086H8.23075C8.22783 7.9107 8.41178 7.61548 8.9824 7.61548H16.9377C17.5066 7.61548 17.6872 7.91322 17.6923 8.23086Z"
        fill="#B8B5C2"
      />
      <path d="M18.9996 17.7662H7V18.4475H18.9996V17.7662Z" fill="#B8B5C2" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2883 10.8538H14.9617V11.3996C14.9617 11.7875 15.26 12.105 15.625 12.105C15.99 12.105 16.2888 11.7875 16.2888 11.3996V10.8538H16.2883Z"
        fill="#B8B5C2"
      />
    </g>
    <defs>
      <clipPath id="clip0_408_49754">
        <rect
          width="12"
          height="11.4471"
          fill="white"
          transform="translate(7 7)"
        />
      </clipPath>
    </defs>
  </svg>
);
