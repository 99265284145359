import { editMerchant } from "apis/merchant";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik, FormikValues } from "formik";
import { contractorEditSchema } from "formValidations/contractorEditSchema";
import { useToast } from "hooks/useToast";
import { FC, useState } from "react";
import { Merchant } from "types/Models/merchant";

interface IEditContractorForAdminProps {
  show: boolean;
  closeModal: () => void;
  taskId: string;
  groupId: string;
  merchant: Merchant;
  refetch: () => void;
  season: string;
}

export const EditContractor: FC<IEditContractorForAdminProps> = ({
  show,
  closeModal,
  taskId,
  groupId,
  merchant,
  refetch,
  season,
}) => {
  const { alertToast, successToast } = useToast();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (payload: FormikValues) => {
    try {
      setLoading(true);
      await editMerchant({
        groupId,
        uuid: merchant.uuid,
        payload: { ...payload, season: season },
      });
      refetch();
      successToast({ message: "Merchant details has been edited" });
      setLoading(false);
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-p-0 md:!t-w-[440px]">
          <Modal.Header>
            <Modal.Title>Edit Contractor </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <div className="t-border t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-p-6">
            <Formik
              onSubmit={onSubmit}
              initialValues={{
                name: merchant.name,
                email: merchant.email,
              }}
              validateOnChange
              validationSchema={contractorEditSchema}
            >
              {({ isSubmitting, isValid }) => (
                <Form className="t-m-0 t-w-full t-space-y-4">
                  <TextInput block name="name" label="Name" />
                  <TextInput block name="email" label="Email" />
                  <div className="!t-mt-6 t-flex t-justify-end t-gap-3 ">
                    <Button onClick={closeModal} type="button">
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting || !isValid || isLoading}
                      isLoading={isSubmitting || isLoading}
                      customType="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
