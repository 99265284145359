import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Coin from "static/images/Coin.svg";
import { useGetCreditsQuery } from "store/apis/refrral";
import { setCreditAmountToAdd, setPaymentTitle } from "store/slices/credit";
import { AddCredits } from "./billing/Credits/AddCredit";
import { Button } from "./DesignSystem/Button/Button";
import { PromoCard, PromoCardItem } from "./PromoCard/PromoCard";

export const CreditsCard = ({ refetch }: { refetch?: () => void }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { data } = useGetCreditsQuery({ groupId }, { skip: !groupId });
  const { total_credits } = data || {};
  const {
    open: openPaymentModal,
    close: closePaymentModal,
    isOpen: showPaymentModal,
  } = useModal();
  const query = useQuery();
  const creditsToPurchase = query.get("credits_to_purchase");
  const dispatch = useDispatch();

  useEffect(() => {
    if (creditsToPurchase !== null) {
      dispatch(setCreditAmountToAdd(+creditsToPurchase));
      dispatch(setPaymentTitle("Inkle Credits"));
      openPaymentModal();
    }
  }, [creditsToPurchase]);

  return (
    <>
      <PromoCard
        title="You can use these credits for:"
        image={
          <>
            <span className="t-flex t-items-center t-justify-center">
              <img src={Coin} alt="credits" />
              <span className="credit-points t-text-h3 t-font-bold">
                ${total_credits}
              </span>
            </span>
            <span className="t-text-body-sm t-text-text-30">Inkle Credits</span>
          </>
        }
        CTA={
          <Button
            customType="primary-outlined"
            block
            onClick={openPaymentModal}
          >
            Add credits
          </Button>
        }
      >
        <PromoCardItem>Tax and compliance filings</PromoCardItem>
        <PromoCardItem>Bookkeeping</PromoCardItem>
      </PromoCard>
      <AddCredits
        showPaymentModal={showPaymentModal}
        closePaymentModal={closePaymentModal}
        refetch={refetch}
      />
    </>
  );
};
