import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { AddTransactionOptions } from "components/Transaction/AddTransaction/AddTransactionOptions";
import { Formik } from "formik";
import { useModal } from "hooks/useModal";
import Lottie from "lottie-react";
import { ModalProps } from "types/utils/modal";
import successAnimation from "./SuccessAnimation.json";

export const AddBankSuccessModal = ({
  close,
  isOpen,
  accountId,
}: ModalProps & { accountId: string }) => {
  const {
    isOpen: isAddTransactionModalOpen,
    close: closeAddTransactionModal,
    open: openAddTransactionModal,
  } = useModal();

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Formik
          initialValues={{}}
          onSubmit={() => {
            openAddTransactionModal();
            close();
          }}
        >
          {({ submitForm }) => (
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Bank account added</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-flex t-gap-4 t-flex-col t-justify-center t-w-full t-items-center">
                <Lottie className="t-w-52" animationData={successAnimation} />
                <h3 className="t-text-h3 t-text-text-100">Hurray!</h3>
                <span className="t-text-body-lg t-text-text-60">
                  Your bank account has been added!
                </span>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button customType="primary" onClick={submitForm} block>
                  Add Transactions
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          )}
        </Formik>
      </Modal.Root>
      <AddTransactionOptions
        close={closeAddTransactionModal}
        isOpen={isAddTransactionModalOpen}
        openAddTransactionModal={openAddTransactionModal}
        selectedAccountId={accountId}
      />
    </>
  );
};
