import { ActionCard } from "components/ActionCard/ActionCard";
import { AppLayout } from "components/AppLayout/AppLayout";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import { ConditionalLink } from "components/conditionalLink";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { DashboardLayout } from "components/DashboardLayout";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { AvatarGroup } from "components/DesignSystem/AvatarGroup/AvatarGroup";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Entities } from "components/Entity/Entity";
import { LargeCard } from "components/home/Home";
import { Documents } from "components/icons/LeftNav/Documents";
import { Filings } from "components/icons/LeftNav/Filings";
import { Home } from "components/icons/LeftNav/Home";
import { Transfers } from "components/icons/LeftNav/Intragroup/Transfers";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { Ticket } from "components/icons/LeftNav/Ticket";
import { InkleIntragroup } from "components/icons/Logos/InkleIntragroup";
import { InkleIntragroupSymbol } from "components/icons/Logos/InkleIntragroupSymbol";
import { IntragroupServices } from "components/IntragroupServices/IntragroupServices";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarApps,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { TopBar } from "components/TopBar/TopBar";
import InitiateTransferModal from "components/TPPayments/TransferModal/InitiateTransferModal";
import {
  CLICKED_INTRAGROUP_SERVICES_ON_LEFT_NAV,
  CLICKED_TRANSFER_ON_LEFT_NAV,
} from "constants/analyticsEvents";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAnalytics } from "hooks/useAnalytics";
import { useAuth } from "hooks/useAuth";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { parse, stringify } from "qs";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { EntitySelector } from "../components/EntitySelector/EntitySelector";
import { Invoices } from "./TPPayments/Invoices";
import SquareFeet from "components/Illustrations/SquareFeet.svg";
import AlgoUniversity from "components/Illustrations/AlgoUniversity.svg";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import * as Accordion from "@radix-ui/react-accordion";
import { CaretDown } from "components/icons/CaretDown";
import { INTRAGROUP_FAQS } from "constants/intragroupServices";
import classNames from "classnames";
import { UpgradeTaxPlan } from "./Tax/UpgradeTaxPlan";

const PromotionalBanner = () => {
  return (
    <div className="t-flex t-items-center t-justify-between t-py-5 t-px-7 t-text-center t-gap-2 t-bg-[url('/src/components/Illustrations/IntraGroupBannerBg.svg')] t-bg-no-repeat t-bg-cover t-rounded-lg t-w-full t-overflow-hidden t-bg-center t-border t-border-solid t-border-green-20 t-flex-wrap sm:t-justify-center">
      <AvatarGroup
        max={2}
        size="large"
        remainingText="& more"
        variant="no-fill"
        className="!t-border-green-20 t-border-solid t-border-[3px] t-bg-white !t-text-dark_green t-min-h-12 t-min-w-12 t-max-h-12 t-max-w-12 t-px-1"
      >
        <Avatar
          alt={SquareFeet}
          src={SquareFeet}
          size="large"
          className="!t-border-green-20 t-border-solid t-border-[3px] t-bg-text-100 t-min-h-12 t-min-w-12 t-max-h-12 t-max-w-12 t-px-1"
        />
        <Avatar
          alt={SquareFeet}
          src={AlgoUniversity}
          size="large"
          className="!t-border-green-20 t-border-solid t-border-[3px] t-bg-white t-min-h-12 t-min-w-12 t-max-h-12 t-max-w-12 t-px-1"
        />
        <Avatar
          alt={"more"}
          size="large"
          className="!t-border-green-20 t-border-solid t-border-[3px] t-bg-white t-min-h-12 t-min-w-12 t-max-h-12 t-max-w-12 t-px-1"
        />
      </AvatarGroup>
      <div className="t-flex t-justify-center t-flex-col">
        <span className="t-text-subtitle t-text-text-100">
          Trusted by startups
        </span>
        <span className="t-text-body t-text-text-30">
          that moved more than $4MM since April 2023
        </span>
      </div>
      <div className="t-p-3 t-rounded t-border-green-20 t-border t-border-solid t-bg-white t-flex t-gap-2 t-items-center">
        <Tag tagType="green" icon transparent>
          <span className="t-text-subtitle-sm t-text-text-100">
            100% Safe & Secure
          </span>
        </Tag>

        <Tag tagType="green" icon transparent>
          {" "}
        </Tag>
      </div>
    </div>
  );
};

const IntragroupNav = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { type } = useAuth();
  const { trackEvent } = useAnalytics();

  const search = stringify(
    {
      entity: parsedSearch.entity,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <>
      {type === FOREIGN_CA && (
        <div className="t-m-2">
          <GroupSelect />
        </div>
      )}
      <LeftBarItem exact to="" icon={Home}>
        Home
      </LeftBarItem>

      <LeftBarItem to={`/entities${search}`} icon={MyEntities}>
        My Entities
      </LeftBarItem>
      <LeftBarItem to={`/transfers${search}`} icon={Transfers}>
        <div onClick={() => trackEvent(CLICKED_TRANSFER_ON_LEFT_NAV)}>
          Transfers
        </div>
      </LeftBarItem>

      <LeftBarItem to={`/services${search}`} icon={Filings}>
        <div
          onClick={() => trackEvent(CLICKED_INTRAGROUP_SERVICES_ON_LEFT_NAV)}
        >
          Services
        </div>
      </LeftBarItem>

      <div className="t-py-3">
        <LeftBarSeparator />
      </div>

      <ChatLeftNav to={`/chat${search}`} />
      <LeftBarItem to={`/tickets${search}`} icon={Ticket}>
        Tickets
      </LeftBarItem>
      <LeftBarItem to={`/documents${search}`} icon={Documents}>
        Documents
      </LeftBarItem>

      <div className="t-mt-auto">
        <div className="t-py-3">
          <LeftBarSeparator name="Other Apps" />
        </div>
        <LeftBarApps current={url} />
      </div>
    </>
  );
};

export const IntragroupDashboard = () => {
  const { path, url } = useRouteMatch();
  const { first_name } = useAuth();
  const { isCpa } = useRoleBasedView();

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo
              logo={InkleIntragroup}
              symbol={InkleIntragroupSymbol}
            />
          }
        >
          <IntragroupNav />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={
            <>
              <LeftBarLogo
                logo={InkleIntragroup}
                symbol={InkleIntragroupSymbol}
              />
            </>
          }
          nav={
            <LeftBar logo={<></>}>
              <IntragroupNav />
            </LeftBar>
          }
        >
          {isCpa && <EntitySelector toolTipPlacement="bottom" />}
        </TopBar>
      }
      banner={<DashboardBanner />}
    >
      <Switch>
        <RestrictedRoute exact path={`${path}/services/addtask`}>
          <TemplateMenu
            to={`/intragroup/services`}
            taskCategoryType="INTRA_GROUP"
          />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/services/:taskId`}>
          <FilingDetails isFromServices parentRoute="/intragroup/services" />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/entities`} component={Entities} />

        <RestrictedRoute path={`${path}/transfers/transfer/`}>
          <InitiateTransferModal to={`${path}/transfers/`} />
        </RestrictedRoute>

        <RestrictedRoute path={`${path}/transfers/`}>
          <Invoices />
        </RestrictedRoute>

        <RestrictedRoute path={`${path}/services/`}>
          <IntragroupServices />
        </RestrictedRoute>

        <RestrictedRoute exact path={`${path}/transfer-pricing`}>
          <Redirect to={`${path}/transfers`} />
        </RestrictedRoute>

        <RestrictedRoute exact path={path}>
          <DashboardLayout
            header={<Header v2 title={`Welcome ${first_name}`} />}
          >
            <div className="t-flex t-flex-wrap t-gap-8">
              <PromotionalBanner />
              <div className="t-flex t-gap-8 t-flex-wrap t-w-full">
                <div className="t-flex t-flex-col t-gap-6">
                  <LargeCard>
                    <ConditionalLink to={`${url}/transfers`}>
                      <ActionCard
                        className="t-bg-[url('static/images/LightMetallic.svg')] t-bg-no-repeat t-bg-right-bottom t-flex t-flex-col t-justify-between"
                        name="Transfers"
                        content={
                          <span className="t-text-body t-text-text-30 t-mb-10">
                            Seamless intra-group payments
                          </span>
                        }
                        action={
                          <div>
                            <Button size="small">Transfer Money</Button>
                          </div>
                        }
                      />
                    </ConditionalLink>
                  </LargeCard>
                  <LargeCard>
                    <ConditionalLink to="/intragroup/services/addtask">
                      <ActionCard
                        className="t-bg-[url('static/images/LightMetallic.svg')] t-bg-no-repeat t-bg-right-bottom t-flex t-flex-col t-justify-between"
                        name="Transfer pricing study & benchmarking"
                        content={
                          <span className="t-text-body t-text-text-30 t-mb-10">
                            Determine transfer pricing margins.
                          </span>
                        }
                        action={
                          <div>
                            <Button size="small">Start Now</Button>
                          </div>
                        }
                      />
                    </ConditionalLink>
                  </LargeCard>
                </div>
                <ActionCard
                  className="t-flex-1 t-bg-[url('static/images/InklePracticePromotionalCard.svg')] t-bg-no-repeat t-bg-right-bottom t-flex t-flex-col t-justify-between"
                  name="Frequently asked questions"
                  content={
                    <span className="t-mt-6">
                      <Accordion.Root
                        type="single"
                        defaultValue=""
                        className="t-space-y-4"
                        collapsible
                      >
                        {INTRAGROUP_FAQS.map(
                          ({ value, question, answer }, i) => (
                            <Accordion.Item
                              key={value}
                              value={value}
                              className={classNames({
                                "t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-l-0 t-border-r-0 t-pb-4":
                                  i !== INTRAGROUP_FAQS.length - 1,
                              })}
                            >
                              <Accordion.Trigger className="all:unset t-text-subtext-sm t-text-text-100 t-flex t-gap-2 t-group t-w-full">
                                <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                                  <CaretDown />
                                </span>
                                {question}
                              </Accordion.Trigger>
                              <Accordion.Content className="t-text-body-sm t-text-text-60 t-mt-2 t-pl-6">
                                {answer}
                              </Accordion.Content>
                            </Accordion.Item>
                          )
                        )}
                      </Accordion.Root>
                    </span>
                  }
                  action={
                    <ConditionalLink
                      to="https://www.inkle.io/products/intragroup#:~:text=Read%20case%20study-,FAQs,-How%20funds%20are"
                      target="_blank"
                    >
                      <div>
                        <Button size="small">Know more</Button>
                      </div>
                    </ConditionalLink>
                  }
                />
              </div>
            </div>
          </DashboardLayout>
        </RestrictedRoute>
        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
