import { axiosInstance } from "./apiInstance";

export const createNewFolder = async ({ groupId, payload }) => {
  return await axiosInstance.post(
    `/api/inkle/documents/group/${groupId}/folder/create/`,
    payload
  );
};

export const getListAnyFolderContent = async ({ groupId, folderId }) => {
  return await axiosInstance.get(
    `/api/inkle/documents/group/${groupId}/folder/${folderId}/list/`
  );
};

export const uploadFileToFolder = async ({ groupId, payload }) => {
  const formData = new FormData();
  formData.append("parent_uuid", payload.parent_uuid);
  formData.append("file", payload.file);
  return await axiosInstance.post(
    `/api/inkle/documents/group/${groupId}/file/upload/`,
    formData
  );
};

export const uploadMultipleFiles = async ({
  groupId,
  uploadPayload,
  parentUuid,
}) => {
  const formData = new FormData();
  formData.append("parent_uuid", parentUuid);
  for (let i = 0; i < uploadPayload.length; i++) {
    formData.append("files", uploadPayload[i]);
  }

  return await axiosInstance.post(
    `/api/inkle/documents/group/${groupId}/files/upload/`,
    formData
  );
};

export const getFileDownloadUrl = async ({ groupId, fileId }) => {
  return await axiosInstance.get(
    `/api/inkle/documents/group/${groupId}/file/${fileId}/url/`
  );
};

export const getFileDownloadUrlWithNewName = async ({
  groupId,
  fileId,
  documentFilename,
}) => {
  return await axiosInstance.get(
    `/api/inkle/documents/group/${groupId}/file/${fileId}/url/?filename=${documentFilename}`
  );
};

export const renameFileAndFolder = async ({ groupId, fileId, payload }) => {
  return await axiosInstance.put(
    `/api/inkle/documents/group/${groupId}/file/${fileId}/`,
    payload
  );
};

export const deleteFileAndFolder = async ({ groupId, folderId }) => {
  return await axiosInstance.delete(
    `/api/inkle/documents/group/${groupId}/file/${folderId}/`
  );
};

export const moveFileLocation = async ({ groupId, payload, fileId }) => {
  return await axiosInstance.post(
    `/api/inkle/documents/group/${groupId}/file/${fileId}/move/`,
    payload
  );
};

export const multipleFileDownloads = async ({ groupId, fileIds }) => {
  return await axiosInstance.post(
    `/api/inkle/documents/group/${groupId}/files/download/`,
    {
      file_uuids: fileIds,
    }
  );
};

export const multipleFileDelete = async ({ groupId, fileIds }) => {
  return await axiosInstance.post(
    `/api/inkle/documents/group/${groupId}/files/delete/`,
    {
      file_uuids: fileIds,
    }
  );
};

export const multipleFileMove = async ({
  groupId,
  fileIds,
  destinationFolderId,
}) => {
  const { destination_folder_id } = destinationFolderId;
  return await axiosInstance.post(
    `/api/inkle/documents/group/${groupId}/files/move/`,
    {
      destination_folder_id,
      file_uuids: fileIds,
    }
  );
};
