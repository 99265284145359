export const InklePracticeProSymbol = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5723_7972)">
      <mask
        id="mask0_5723_7972"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="24"
        height="24"
      >
        <path d="M40 16H16V40H40V16Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5723_7972)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 16L16 22L28 28L16 34L16 40L28 34V40L40 34V28V22L28 16V22L16 16ZM28 22V28V34L40 28L28 22Z"
          fill="url(#paint0_linear_5723_7972)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5723_7972"
        x1="16"
        y1="28"
        x2="40"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <clipPath id="clip0_5723_7972">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
);
