import {
  BOOKKEEPING_SOFTWARE,
  BOOKS_PLUS_PLAN,
  GROUP_BOOKS_CONSOLIDATION,
  REGISTERED_AGENT,
  PLATFORM,
} from "constants/addons";
import { MONTHLY } from "constants/billingCycles";
import { Subscription } from "types/Models/subscription";

export const useGetSubscriptionsToShow = ({
  allSubscriptions,
}: {
  allSubscriptions: Subscription[];
}) => {
  /*_____________________________ Books Plus Plan _____________________________ */
  const booksMonthlyPlusPlan = allSubscriptions?.find(
    (ele) =>
      ele.subscription_type === BOOKS_PLUS_PLAN && ele.billing_cycle === MONTHLY
  );

  const activeBooksPlusPlan = allSubscriptions?.find(
    ({ subscription_type, group_subscription = [] }) =>
      subscription_type === BOOKS_PLUS_PLAN && group_subscription?.length > 0
  );

  const booksPlusPlanToshow = activeBooksPlusPlan
    ? activeBooksPlusPlan
    : booksMonthlyPlusPlan;
  /*_____________________________ Books Plus Plan ______________________________ */

  let allAddOnSubscriptions = allSubscriptions?.filter(
    (ele) =>
      ele.subscription_type !== BOOKKEEPING_SOFTWARE &&
      ele.subscription_type !== PLATFORM &&
      ele.subscription_type !== REGISTERED_AGENT &&
      ele.subscription_type !== GROUP_BOOKS_CONSOLIDATION &&
      ele.subscription_type !== BOOKS_PLUS_PLAN
  );

  const groupConsolidation = allSubscriptions.filter(
    (ele) => ele.subscription_type === GROUP_BOOKS_CONSOLIDATION
  );

  const groupConsolidationMinAmount = groupConsolidation.reduce((min, obj) => {
    return Number(obj?.amount) < Number(min)
      ? Number(obj?.amount)
      : Number(min);
  }, Number(groupConsolidation?.[0]?.amount));

  const groupConsolidationMappings =
    groupConsolidation
      ?.filter(
        (obj) => obj?.group_subscription && obj?.group_subscription?.length > 0
      )
      ?.map((ele) => {
        return {
          groupSubscriptionId:
            ele?.group_subscription?.[0]?.group_subscription_id,
          subscriptionName: ele?.subscription_name,
          amount: ele?.amount,
          billingCycle: ele?.billing_cycle,
        };
      }) || [];

  if (groupConsolidation?.length > 0) {
    const groupConsolidationObj = {
      amount: groupConsolidationMinAmount,
      billing_cycle: "quarterly or monthly",
      description: "Consolidated financials of all your entities",
      dropdownContent: groupConsolidation,
      subscription_name: "Group Books Consolidation",
      subscription_type: GROUP_BOOKS_CONSOLIDATION,
      ...(groupConsolidationMappings?.length > 0
        ? { group_subscription: groupConsolidationMappings }
        : {}),
      uuid: "",
    };
    //@ts-ignore
    allAddOnSubscriptions = [groupConsolidationObj, ...allAddOnSubscriptions];
  }

  const registeredAgents = allSubscriptions.filter(
    (ele) => ele.subscription_type === REGISTERED_AGENT
  );

  const registeredAgentMinAmount = registeredAgents?.reduce((min, obj) => {
    return Number(obj?.amount) < Number(min)
      ? Number(obj?.amount)
      : Number(min);
  }, Number(registeredAgents?.[0]?.amount));

  const registeredAgentStateMappings =
    registeredAgents
      ?.filter((ele) => ele.group_subscription)
      ?.map((ele) => {
        return {
          amount: ele?.amount,
          selectedStates: ele?.group_subscription,
          type: ele?.metadata?.ra_state,
          uuid: ele?.uuid,
        };
      }) || [];

  const registeredAgentUnselectedStates = registeredAgents
    ?.filter((ele) => !ele.group_subscription)
    ?.map((ele) => {
      return {
        amount: ele?.amount,
        type: ele?.metadata?.ra_state,
        uuid: ele?.uuid,
      };
    });

  if (registeredAgents?.length > 0) {
    const registeredAgentObj = {
      amount: registeredAgentMinAmount,
      billing_cycle: "MONTHLY",
      description:
        "Registered Agent service for all states of the United States of America",
      subscription_name: "Registered Agent",
      subscription_type: REGISTERED_AGENT,
      ...(registeredAgentStateMappings?.length > 0
        ? { group_subscription: registeredAgentStateMappings }
        : {}),
      unselectedStates: registeredAgentUnselectedStates,
    };
    // @ts-ignore - TODO: This needs to be typed
    allAddOnSubscriptions.unshift(registeredAgentObj);
  }

  const bookKeepingSoftware = allSubscriptions?.filter(
    (ele) => ele.subscription_type === BOOKKEEPING_SOFTWARE
  );

  const booksMinAmount = bookKeepingSoftware.reduce((min, obj) => {
    return Number(obj?.amount) < Number(min)
      ? Number(obj?.amount)
      : Number(min);
  }, Number(bookKeepingSoftware?.[0]?.amount));

  const bookKeepingSoftwareMappings =
    bookKeepingSoftware
      ?.filter(
        (obj) => obj?.group_subscription && obj?.group_subscription?.length > 0
      )
      ?.map((ele) => {
        return {
          groupSubscriptionId:
            ele?.group_subscription?.[0]?.group_subscription_id,
          subscriptionName: ele?.subscription_name,
          amount: ele?.amount,
        };
      }) || [];

  const quickBooksDropdown =
    bookKeepingSoftware?.filter((ele) => {
      return ele?.metadata?.software === "QuickBooks";
    }) || [];

  const zohoBooksDropdown =
    bookKeepingSoftware?.filter(
      (ele) => ele?.metadata?.software === "ZohoBooks"
    ) || [];

  if (bookKeepingSoftware?.length > 0) {
    const bookkeepingSoftwareObj = {
      amount: booksMinAmount,
      billing_cycle: "MONTHLY",
      description: "Choose between QuickBooks or Zoho Books USA.",
      dropdownContent: [quickBooksDropdown, zohoBooksDropdown],
      ...(bookKeepingSoftwareMappings?.length > 0
        ? { group_subscription: bookKeepingSoftwareMappings }
        : {}),
      subscription_name: "US Accounting Software",
      subscription_type: BOOKKEEPING_SOFTWARE,
      uuid: "",
    };

    if (
      quickBooksDropdown.some((sub) => sub.group_subscription) ||
      zohoBooksDropdown.some((sub) => sub.group_subscription)
    ) {
      // @ts-ignore - TODO: This needs to be type
      allAddOnSubscriptions.unshift(bookkeepingSoftwareObj);
    }
  }

  return { allAddOnSubscriptions, booksPlusPlanToshow };
};
