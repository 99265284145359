import { PreviewModal } from "components/ChatPreviewModal/ChatPreviewModal";
import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Preview } from "components/PreviewModal";
import { PlusIcon } from "components/icons/PlusIcon";
import { Trash } from "components/icons/Trash";
import { FieldArray } from "formik";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  invoiceApis,
  useGetEntityInvoiceQuery,
  useGetInvoiceSettingsQuery,
  useLazyDownloadInvoiceQuery,
  useSendInvoiceMutation,
} from "store/apis/invoices";
import { currency } from "utils/Currency";
import { FileIcon } from "utils/fileTypeIcon";
import { useAnalytics } from "hooks/useAnalytics";
import { CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL } from "constants/analyticsEvents";
import { useQuery } from "hooks/useQuery";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";

const AddAttachment = ({ onDrop }: { onDrop: (files: File[]) => void }) => {
  const { getInputProps, open, getRootProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input hidden {...getInputProps()} />
      <Button type="button" size="small" onClick={open}>
        <span className="t-flex t-gap-1 t-items-center">
          <PlusIcon />
          <span>Add attachment</span>
        </span>
      </Button>
    </div>
  );
};

export const SendInvoiceModal = ({
  isOpen,
  close,
  invoiceFromProp,
  isReminder,
}: {
  isOpen: boolean;
  close: () => void;
  invoiceFromProp?: string;
  isReminder?: boolean;
}) => {
  const group = useCurrentGroupContext();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [attachment, setAttachment] = useState<string | null>();
  const [tempAttachment, setTempAttachment] = useState<File | null>(null);
  const { successToast, alertToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const invoiceUuid = invoiceFromProp || invoiceId;
  const query = useQuery();
  const editFlow = query.get("editflow");
  const entityId = useCurrentEntityId();

  const [sendInvoice, { isLoading: sendingInvoice }] = useSendInvoiceMutation();
  const { data: invoiceSetting } = useGetInvoiceSettingsQuery(
    {
      groupId: group.uuid,
      entityId,
    },
    {
      skip: !group.uuid || !entityId,
    }
  );

  const { data: invoice, isLoading: invoiceLoading } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId: invoiceUuid,
    },
    { skip: !group?.uuid || !entityId }
  );
  const isSentInvoiceEditFlow = invoice?.status === "SENT" && Boolean(editFlow);
  const attachmentDataUrI =
    tempAttachment && URL.createObjectURL(tempAttachment);

  const [getInvoiceDownloadUrl] = useLazyDownloadInvoiceQuery();

  useEffect(() => {
    const maybeDownloadInvoice = async () => {
      if (invoice && !invoice.document && entityId) {
        await getInvoiceDownloadUrl({
          groupId: group.uuid,
          entityId: entityId,
          invoiceId: invoiceUuid,
        }).unwrap();

        dispatch(invoiceApis.util.invalidateTags([{ type: "INVOICES" }]));
      }
    };

    maybeDownloadInvoice();
  }, []);

  const { link } = useConstructInternalLink();

  const onSendInvoice = async (values: {
    attachments: File[];
    body: string;
    subject: string;
  }) => {
    trackEvent(CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL);
    if (group?.uuid && entityId) {
      try {
        await sendInvoice({
          groupId: group.uuid,
          entityId: entityId,
          invoiceId: invoiceUuid,
          payload: {
            subject: values.subject,
            body: values.body,
            attachments: values.attachments,
          },
        }).unwrap();
        successToast({ message: "Invoice has been sent." });
        if (Boolean(invoiceFromProp)) {
          close();
          return;
        }
        history.push(link("/books/invoicing"));
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  const entity = group.entities.find((entity) => entity.uuid === entityId);

  if (!invoice || !entity || invoiceLoading) {
    return (
      <Modal.Root>
        <Modal.Content>
          <Modal.Body>
            <div className="t-flex t-w-full t-h-full t-justify-center t-items-center">
              <Loader />
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    );
  }
  const addUpdatedInEmail = isSentInvoiceEditFlow ? "updated " : "";
  let addUpdatedInSubject = isSentInvoiceEditFlow ? "Updated: " : "";
  if (isReminder) {
    addUpdatedInSubject += "Reminder: ";
  }

  const defaultBody = `Hi,

Please find ${addUpdatedInEmail}attached Invoice ${
    invoice.invoice_number
  } for the amount of ${currency({
    amount: invoice.due_balance || 0,
  })} from ${entity.name}, feel free to reach out if you have any questions.
${
  invoiceSetting?.payment_info
    ? `
Payment Instructions:
${invoiceSetting.payment_info}
`
    : ""
}
Thank you for your prompt payment.

Best Regards,
${entity.name}
${invoiceSetting?.email}`;

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Formik
          onSubmit={onSendInvoice}
          initialValues={{
            attachments: [] as File[],
            subject:
              addUpdatedInSubject +
              (invoice?.title || `You have a new invoice from ${entity.name}`),
            body: defaultBody,
          }}
        >
          {({ setFieldValue, values, submitForm }) => {
            return (
              <>
                <Modal.Header>
                  <Modal.Title>Send Invoice</Modal.Title>
                  <Modal.Close />
                </Modal.Header>

                <Modal.Body>
                  <Form className="t-m-0 t-flex t-flex-col t-gap-4">
                    <TextInput
                      value={invoice?.customer?.email}
                      disabled
                      name="recipients"
                      label="To"
                    />

                    <div className="t-flex t-gap-1 t-flex-col">
                      <Label>From</Label>
                      <p className="t-text-subtext t-m-0">
                        {invoiceSetting?.email}
                      </p>
                    </div>

                    <TextInput name="subject" label="Subject" />

                    <TextArea name="body" label="Body" />

                    <div className="t-flex t-gap-1 t-flex-col">
                      <Label>Attachments</Label>
                      {invoice?.document && (
                        <div
                          className="t-border t-border-solid t-border-neutral-10 t-rounded t-px-3 t-py-1 t-flex t-gap-2 t-items-center"
                          role="button"
                          onClick={() =>
                            setAttachment(invoice.document?.file_id)
                          }
                        >
                          <FileIcon fileType="PDF" width={28} height={28} />
                          <span className="t-text-body-sm">
                            {invoice?.document?.file_name}
                          </span>
                        </div>
                      )}
                      <FieldArray
                        name="attachments"
                        render={(arrayHelpers) => {
                          return values.attachments.map((attachment, index) => (
                            <div
                              key={attachment.name}
                              className="t-border t-border-solid t-border-neutral-10 t-rounded t-px-3 t-py-1 t-flex t-gap-2 t-items-center"
                              role="button"
                              onClick={() => setTempAttachment(attachment)}
                            >
                              <FileIcon
                                fileType={attachment.name
                                  .split(".")
                                  .pop()
                                  ?.toUpperCase()}
                                width={28}
                                height={28}
                              />
                              <span className="t-text-body-sm">
                                {attachment.name}
                              </span>
                              <div className="t-ml-auto">
                                <Button
                                  type="button"
                                  customType="ghost_icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <span className="t-text-red-50">
                                    <Trash />
                                  </span>
                                </Button>
                              </div>
                            </div>
                          ));
                        }}
                      />

                      <div>
                        <AddAttachment
                          onDrop={(files) =>
                            setFieldValue("attachments", [
                              ...values.attachments,
                              ...files,
                            ])
                          }
                        />
                      </div>
                    </div>
                    {attachment && (
                      <Preview
                        showModal={Boolean(attachment)}
                        closeModal={() => setAttachment(null)}
                        groupId={group.uuid}
                        previewId={attachment}
                      />
                    )}
                    {tempAttachment && attachmentDataUrI && (
                      <PreviewModal
                        title={tempAttachment.name}
                        open={Boolean(tempAttachment)}
                        onClose={() => setTempAttachment(null)}
                      >
                        <iframe
                          className="t-w-full t-h-[96%]"
                          src={attachmentDataUrI}
                          title={tempAttachment.name}
                        />
                      </PreviewModal>
                    )}
                  </Form>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Back</Button>
                  </Modal.RawClose>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={sendingInvoice}
                    disabled={sendingInvoice}
                    type="submit"
                  >
                    Send invoice
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
