import qs from "qs";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPopulateTransactionIDs } from "store/slices/chat";
import { pluralize } from "utils/pluralize";
import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { ReactNode } from "react";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { AvatarGroup } from "components/DesignSystem/AvatarGroup/AvatarGroup";
import { formatDate } from "utils/formatDate";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { VendorDefault } from "components/icons/VendorDefault";
import { useAppSelector } from "hooks/useAppSelector";
import { useLazyGetAllTransactionsDataQuery } from "store/apis/transactions";
import {
  setFiltersAplied,
  setTransactionHiddenByFilters,
} from "store/slices/transactionCardPreview";
import TransactionColumn from "components/Transaction/TransactionColumn";
import { resetTxnFilters } from "store/slices/transactionFilter";

type TransactionDetail = {
  amount: string;
  datetime: string | null;
  is_credit: boolean;
  merchant_data: {
    logo: string | null;
    name: string;
  };
  source: {
    source_name: string;
  };
  uuid: string;
};

export const TransactionChatCard = ({
  customData: { metadata },
  groupId,
  children,
}: {
  customData: {
    metadata?: {
      action: string;
      entity_id: string;
      transaction_count: number;
      transaction_ids: string;
      txn_details?: TransactionDetail[];
      version?: "V2";
    };
  };
  groupId: string;
  children: ReactNode;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [getTransactionPageNum, { isLoading }] =
    useLazyGetAllTransactionsDataQuery();
  const allFilters = useAppSelector(
    (state) => state.transactionCardPreview.filtersApplied
  );

  if (!metadata) {
    return null;
  }

  const multiAction = () => {
    dispatch(resetTxnFilters());
    let queryParams = qs.stringify(
      { company: groupId, entity: metadata.entity_id },
      { skipNulls: true, addQueryPrefix: true }
    );

    history.push(`/books/transactions${queryParams}`);
    const transactionIds =
      metadata.version === "V2"
        ? metadata.txn_details?.map((txn) => txn.uuid).join(",")
        : metadata?.transaction_ids;

    if (transactionIds) {
      dispatch(setPopulateTransactionIDs(transactionIds));
    }
  };

  const singleAction = async () => {
    const transactionIds =
      metadata.version === "V2"
        ? metadata.txn_details?.map((txn) => txn.uuid)
        : metadata?.transaction_ids.split(",");

    if (transactionIds?.length === 1) {
      const data = await getTransactionPageNum({
        ...allFilters,
        jump_txn_id: transactionIds[0],
        fetch_page_num: true,
      }).unwrap();

      // @ts-ignore
      if (data.page_number === 0) {
        dispatch(
          setFiltersAplied({
            ...allFilters,
            entityId: metadata.entity_id,
          })
        );
        return dispatch(
          setTransactionHiddenByFilters({
            hidden: true,
            transactionId: transactionIds[0],
            // @ts-ignore
            page: data.page_number as number,
          })
        );
      }

      let queryParams = qs.stringify(
        {
          company: groupId,
          entity: metadata.entity_id,
          // @ts-ignore
          page: data?.page_number,
          selected_transaction_id: transactionIds?.[0],
        },
        { skipNulls: true, addQueryPrefix: true }
      );
      history.push(`/books/transactions${queryParams}`);
    }
  };

  if (metadata.version !== "V2") {
    return (
      <div className="t-flex t-flex-col t-gap-4 t-min-w-64">
        <ChatCard.Content>
          <ChatCard.Header>Transaction Details</ChatCard.Header>
          <ChatCard.Body>
            <div className="t-w-full t-flex t-flex-col t-gap-1.5">
              <div className="t-flex t-justify-between t-w-full t-items-center">
                <div className="t-flex t-gap-2 t-items-center">
                  <span className="t-text-subtext">
                    {pluralize(
                      metadata.transaction_ids.split(",").length,
                      "transaction",
                      "transactions"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </ChatCard.Body>
        </ChatCard.Content>
        {children}
        <div className="t-mx-1">
          <Button block onClick={multiAction} customType="primary">
            View transaction
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="t-flex t-flex-col t-gap-4 t-min-w-64">
      <ChatCard.Content>
        <ChatCard.Header>Transaction Details</ChatCard.Header>
        <ChatCard.Body>
          {metadata.txn_details && metadata.txn_details.length > 1 ? (
            <div className="t-w-full t-flex t-flex-col t-gap-1.5">
              <div className="t-flex t-justify-between t-w-full t-items-center">
                <div className="t-flex t-gap-2 t-items-center">
                  <AvatarGroup max={4}>
                    {metadata.txn_details.map((txn) => (
                      <div
                        className="t-border-solid t-border-2 t-border-surface t-rounded-full"
                        key={txn.uuid}
                      >
                        <ConditionalToolTip condition={txn.merchant_data.name}>
                          <div>
                            {txn.merchant_data.name ? (
                              <Avatar
                                src={txn.merchant_data.logo || ""}
                                alt={txn.merchant_data.name}
                              />
                            ) : (
                              <VendorDefault />
                            )}
                          </div>
                        </ConditionalToolTip>
                      </div>
                    ))}
                  </AvatarGroup>
                  <span className="t-text-subtext">
                    {pluralize(
                      metadata.transaction_count,
                      "transaction",
                      "transactions"
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="t-w-full t-flex t-flex-col t-gap-1.5">
              <div className="t-flex t-justify-between t-w-full t-items-center">
                <div className="t-flex t-gap-2 t-items-center">
                  {metadata.txn_details?.[0].merchant_data.name ? (
                    <Avatar
                      src={
                        metadata.txn_details?.[0].merchant_data.logo ||
                        undefined
                      }
                      alt={metadata.txn_details?.[0].merchant_data.name || ""}
                    />
                  ) : (
                    <VendorDefault />
                  )}
                  <span className="t-text-subtext t-max-w-[100px] t-truncate">
                    {metadata.txn_details?.[0].merchant_data.name || (
                      <span className="t-text-text-30">--</span>
                    )}
                  </span>
                </div>
                <span className="t-text-subtext">
                  <TransactionColumn.Amount
                    amount={Number(metadata.txn_details?.[0].amount || 0)}
                    isCreditCard={metadata.txn_details?.[0].is_credit || false}
                  />
                </span>
              </div>
              <div className="t-flex t-text-body-sm t-text-text-30">
                <p className="t-max-w-[100px] t-truncate t-m-0 t-ml-8">
                  {metadata.txn_details?.[0].source.source_name}
                </p>
                <span className="t-ml-auto">
                  {formatDate(metadata.txn_details?.[0].datetime)}
                </span>
              </div>
            </div>
          )}
        </ChatCard.Body>
      </ChatCard.Content>
      {children && <div className="t-mx-1">{children}</div>}
      <div className="t-mx-1">
        {metadata.txn_details && metadata.txn_details.length > 1 ? (
          <Button block onClick={multiAction} customType="primary">
            View transactions
          </Button>
        ) : (
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            block
            onClick={singleAction}
            customType="primary"
          >
            View transaction
          </Button>
        )}
      </div>
    </div>
  );
};
