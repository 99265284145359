import { axiosInstance } from "./apiInstance";

export const getInvoiceNumber = ({
  task_id,
  group_id,
  credits_applied,
  usableCredit,
  credits_available,
}) => {
  return axiosInstance.post(
    `api/inkle/tasks/group/${group_id}/tasks/${task_id}/create_invoice/`,
    {
      credits_applied,
      credits_available,
    }
  );
};

export const updateTaskArchival = ({ taskId }) => {
  return axiosInstance.put(`/api/inkle/tasks/${taskId}/archive/toggle/`);
};
