import { Button } from "components/DesignSystem/Button/Button";
import React from "react";
import Lightning from "static/images/Lightning.svg";

export const Upgrade = ({ onUpgrade }: { onUpgrade: () => void }) => {
  return (
    <div className="t-flex t-items-center">
      <Button size="small" customType="primary" onClick={onUpgrade}>
        <img src={Lightning} alt="Lightning" /> &nbsp; Upgrade
      </Button>
    </div>
  );
};
