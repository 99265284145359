export const InkleSalesTaxSymbolSmall = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.625 8.62598C10.6262 8.14425 12 7.1497 12 6.00098C12 4.85225 10.6262 3.85771 8.625 3.37598C8.83088 4.16584 8.94674 5.0574 8.94674 6.00098C8.94674 6.94455 8.83088 7.83612 8.625 8.62598Z"
      fill="url(#paint0_linear_6302_7260)"
    />
    <path
      d="M3.375 8.62598C1.37379 8.14425 0 7.1497 0 6.00098C0 4.85225 1.37379 3.85771 3.375 3.37598C3.16912 4.16584 3.05326 5.0574 3.05326 6.00098C3.05326 6.94455 3.16912 7.83612 3.375 8.62598Z"
      fill="url(#paint1_linear_6302_7260)"
    />
    <path
      d="M3.375 8.62598C3.85673 10.6272 4.85128 12.001 6 12.001C7.14872 12.001 8.14327 10.6272 8.625 8.62598C7.83514 8.83185 6.94358 8.94772 6 8.94772C5.05642 8.94772 4.16486 8.83185 3.375 8.62598Z"
      fill="url(#paint2_linear_6302_7260)"
    />
    <path
      d="M3.375 3.37598C3.85673 1.37477 4.85128 0.00097651 6 0.000976562C7.14872 0.000976615 8.14327 1.37477 8.625 3.37598C7.83514 3.1701 6.94358 3.05423 6 3.05423C5.05642 3.05423 4.16486 3.1701 3.375 3.37598Z"
      fill="url(#paint3_linear_6302_7260)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6302_7260"
        x1="8.625"
        y1="6.00098"
        x2="12"
        y2="6.00098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6302_7260"
        x1="0"
        y1="6.00098"
        x2="3.375"
        y2="6.00098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6302_7260"
        x1="3.375"
        y1="10.3135"
        x2="8.625"
        y2="10.3135"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6302_7260"
        x1="3.375"
        y1="1.68848"
        x2="8.625"
        y2="1.68848"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
