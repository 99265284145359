import { Button as NewButton } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { MAILROOM, REGISTERED_AGENT } from "constants/mailRoom";
import dayjs from "dayjs";
import { Field, Form, Formik, useFormikContext } from "formik";
import { useToast } from "hooks/useToast";
import { date, mixed, object, string } from "yup";
import { DD_MMM_YYYY } from "constants/date";
import { useAddMailMutation, useUpdateMailMutation } from "store/apis/mailroom";
import Modal from "components/DesignSystem/Modal/Modal";
import { FileInput } from "components/FileInput/FileInput";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { US } from "constants/countryCodes";
import ReactCountryFlag from "react-country-flag";

export const AddNewmailModal = ({
  addMailModalShow,
  closeModal,
  groupId,
  title,
  clickedData,
  mailboxData,
}) => {
  const { alertToast, successToast } = useToast();
  const [updateMailroom] = useUpdateMailMutation();
  const [createMailroom, { isLoading: isCreating }] = useAddMailMutation();
  const { entities } = useCurrentGroupContext();
  const changeMailData = async (payload) => {
    try {
      await updateMailroom({
        groupId,
        payload: payload,
        mailId: clickedData?.uuid,
      }).unwrap();
      successToast({ message: "Mail has been edited" });
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };
  const Label = ({ country, country_code, name }) => (
    <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
      <ReactCountryFlag countryCode={country_code} svg title={country} />
      <span className="t-truncate t-max-w-36">{name}</span>
    </div>
  );

  const usEntities = entities.filter((e) => e.country_code === US);
  const entityOptions = usEntities.map(
    ({ name, uuid, country, country_code }) => {
      return {
        label: (
          <span>
            <Label country={country} country_code={country_code} name={name} />
          </span>
        ),
        value: uuid,
      };
    }
  );

  const handleSubmit = async (values) => {
    const selectedEntity = usEntities.find(
      ({ uuid }) => uuid == values.sent_to
    );
    let updatePayload = {
      ...values,
      sent_to: selectedEntity.name,
      date_received: dayjs(values.date_received).format("YYYY-MM-DD"),
    };

    try {
      if (!clickedData.uuid) {
        await createMailroom({
          groupId,
          payload: updatePayload,
        }).unwrap();
        successToast({ message: "New mail has been added" });
      } else {
        if (clickedData.document.name === updatePayload.file.name) {
          const putPayload = { ...updatePayload };
          delete putPayload.file;
          changeMailData(putPayload);
        } else {
          changeMailData(updatePayload);
        }
      }
      closeModal();
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const checkFormValidation = object({
    received_from: string().required("Sender is required"),
    sent_to: string().required("Receiver is required"),
    type: string()
      .required("Please select a type")
      .oneOf([REGISTERED_AGENT, MAILROOM]),
    date_received: date().required("Select Recevied date"),
    file: mixed().required("A file is required"),
  });

  return (
    <Modal.Root open={addMailModalShow} onOpenChange={closeModal}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            received_from: clickedData?.received_from || "",
            sent_to: clickedData?.sent_to || entityOptions[0]?.value,
            type: clickedData?.type || "",
            date_received: clickedData?.date_received || "",
            file: clickedData?.document || null,
          }}
          onSubmit={handleSubmit}
          validationSchema={checkFormValidation}
        >
          {({
            isSubmitting,
            setFieldValue,
            values: { file, date_received, sent_to },
          }) => {
            const selectedEntity = entityOptions.find(
              ({ value }) => value == sent_to
            );
            return (
              <Form className="t-m-0">
                <Modal.Body className="t-flex t-flex-col t-gap-3">
                  <TextInput
                    name="received_from"
                    label="Sender"
                    placeholder="Enter mail recieved from"
                    required
                  />
                  <Combobox
                    withForm
                    name="sent_to"
                    label="Receiver"
                    required
                    menuPlacement="auto"
                    onChange={() => {}}
                    placeholder="Enter whom it's addressed to"
                    menuPortalTarget={document.body}
                    creatable={false}
                    isClearable={false}
                    options={entityOptions}
                    value={selectedEntity}
                  />

                  <span className="d-flex justify-content-between t-gap-4 t-items-center">
                    <Field name="date_received">
                      {({ field }) => (
                        <DateInput
                          {...field}
                          name="date_received"
                          label="Date Recieved"
                          portalId="date_received"
                          placeholder={DD_MMM_YYYY}
                          value={date_received && new Date(date_received)}
                          required
                        />
                      )}
                    </Field>
                    <span className="t-mt-[6px]">
                      <SelectDropDown label="Type" name="type" required>
                        <option value="">Select type</option>
                        <option value="REGISTERED_AGENT">
                          Registered Agent
                        </option>
                        <option value="MAILROOM">Mailroom</option>
                      </SelectDropDown>
                    </span>
                  </span>

                  <span className="mailAddData">
                    <Label required htmlFor="file">
                      Upload
                    </Label>
                    <FileInput
                      file={file}
                      accept={{
                        "image/*": [".jpeg", ".png"],
                        "application/pdf": [".pdf"],
                      }}
                      withForm
                      name="file"
                      onDelete={() => setFieldValue("file", null)}
                      onDrop={(v) => setFieldValue("file", v[0])}
                    />
                  </span>
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-end t-mr-2">
                  <NewButton
                    type="submit"
                    customType="primary"
                    size="regular"
                    disabled={isSubmitting}
                  >
                    {isCreating ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        />
                        {clickedData.uuid ? "Updating..." : "Adding..."}
                      </>
                    ) : (
                      "Save"
                    )}
                  </NewButton>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
