import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { ArrowDown } from "components/icons/ArrowDown";
import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import {
  ControlProps,
  MultiValue,
  SingleValue,
  components,
} from "react-select";
import { useUpdateChatTicketMutation } from "store/apis/chatTicket";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { ChatTicket } from "types/Models/chatTicket";

const Control = ({ children, ...props }: ControlProps<OptionData>) => (
  <components.Control {...props}>
    {children}
    <div className="t-text-text-30 t-rounded t-py-2 t-px-3">
      <ArrowDown />
    </div>
  </components.Control>
);

export const TicketAssigneeDropdown = ({
  currentTicketAssignees,
  ticketId,
  showValuesInControlField = false,
}: {
  currentTicketAssignees: ChatTicket["assignee"];
  ticketId: string;
  showValuesInControlField?: boolean;
}) => {
  const { isAdmin, isCustomer } = useRoleBasedView();
  const { data: assignees = [] } = useGetServiceTeamQuery(
    { accessible_teams: isAdmin ? INKLE_TEAM_AND_CPA_TEAM : null },
    {
      skip: isCustomer,
    }
  );
  const [updateTicket, { isLoading: isTicketUpdating }] =
    useUpdateChatTicketMutation();

  const handleAssignees = async (
    values?: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    if (values && values instanceof Array) {
      let newUpdatedAssignees = values.map((v) => v.value);
      const res = await updateTicket({
        assignee_profile_ids: newUpdatedAssignees.join(","),
        resolved_status: null,
        ticket_id: ticketId,
      }).unwrap();
    }
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Combobox
        isMulti
        isSearchable={false}
        isClearable={false}
        menuPortalTarget={document.body}
        onChange={handleAssignees}
        components={{
          Control: Control,
          DropdownIndicator: () => null,
          ClearIndicator: () => null,
          Placeholder: () => null,
          LoadingIndicator: () => <Loader size="small" />,
        }}
        styles={{
          menu: () => ({ width: "240px" }),
        }}
        options={assignees.map(({ name, uuid }) => ({
          label: name,
          value: uuid,
        }))}
        value={currentTicketAssignees.map(
          ({ assignee_name, assignee_uuid }) => ({
            label: assignee_name,
            value: assignee_uuid,
          })
        )}
        controlShouldRenderValue={showValuesInControlField}
        backspaceRemovesValue={false}
        isLoading={isTicketUpdating}
      />
    </div>
  );
};
