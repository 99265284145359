import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import React from "react";
import { useParams } from "react-router-dom";

//api
import { stateUpdate } from "../../apis/stateUpdate";

export default function EditForm({ current, data, uuid, isFromStep0 = false }) {
  const indexing = new Map();
  const stateAccess = new Map();
  const { taskId } = useParams();
  data?.forEach((element, index) => {
    indexing.set(element.uuid, index);
    stateAccess.set(
      element.uuid,
      element.is_cpa_state && element.is_customer_state
    );
  });

  function check() {
    return (
      indexing?.get(current) < indexing?.get(uuid) && stateAccess.get(uuid)
    );
  }

  function stateChange() {
    const id = current;
    stateUpdate({ taskId, id }).then(() => {
      window.location.reload();
    });
  }

  return (
    <>
      {check() ? (
        <div className="t-absolute t-z-[999] t-flex t-justify-end t-mt-2 t-mb-4 t-right-[32px]">
          <Button
            onClick={stateChange}
            customType="primary-outlined"
            size="small"
          >
            <div className="t-mr-2">Edit</div>
            <i className="fas fa-pen stepIcon"></i>
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
