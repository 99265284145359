import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { Check } from "components/icons/Check";
import { Files } from "components/icons/Files";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { openRequestDocumentsModal } from "store/slices/requestDocumentsInChat";
import { useChannelStateContext } from "stream-chat-react";

type DocumentRequested = {
  uuid: string;
  type: string;
  is_uploaded: string;
};

type CustomData = {
  header: string;
  metadata: {
    document_request_id: string;
    requested_documents: DocumentRequested[];
  };
};

export const RequestDocumentsChatCard = ({
  children,
  channelId,
  customData,
  groupId,
}: {
  children: ReactNode;
  customData: CustomData;
  channelId: string;
  groupId: string;
}) => {
  const { requested_documents, document_request_id } = customData.metadata;

  const dispatch = useDispatch();

  const onUploadClick = () =>
    dispatch(
      openRequestDocumentsModal({
        isModalOpen: true,
        channelId: channelId,
        groupId: groupId,
        documentsRequest: requested_documents,
        requestId: document_request_id,
      })
    );

  const allDocumentsAreUploaded = requested_documents.every(
    (d) => d.is_uploaded
  );

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <ChatCard.Content>
        <ChatCard.Header
          icon={<Files />}
          status={allDocumentsAreUploaded ? "COMPLETED" : "IN_PROGRESS"}
        >
          {customData.header}
        </ChatCard.Header>
        <ChatCard.Body>
          {requested_documents.map((document) => (
            <div
              className="t-border-solid first:t-pt-0 last:t-pb-0 first:t-border-t-0 t-border-0 t-border-t t-border-neutral-10 t-flex t-justify-between t-py-2.5"
              key={document.uuid}
            >
              <div className="t-line-clamp-2">{document.type}</div>
              {document.is_uploaded && <Check />}
            </div>
          ))}
        </ChatCard.Body>
      </ChatCard.Content>
      {children}
      <div className="t-mx-1">
        <Button
          block
          onClick={onUploadClick}
          customType={allDocumentsAreUploaded ? "secondary" : "primary"}
        >
          {allDocumentsAreUploaded ? "View" : "Upload"}
        </Button>
      </div>
    </div>
  );
};
