import { mixed, object, string } from "yup";

export const capTableSchema = () =>
  object({
    group_entity_id: string().required("Please select an entity"),
    valid_as_on: string().required("Please select a date"),
    document: mixed().test("file-present", "File is required", (value) => {
      return value !== null && value !== undefined && value !== "";
    }),
  });
