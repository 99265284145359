import { WSeriesForm } from "components/WSeriesForm";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useGetTaskMerchantsQuery } from "store/apis/taskMerchants";

type IWSeriesFormTableForSuccessPageProps = {
  groupId: string;
  noContractorsFound: boolean;
  taskSeason: string;
  entityId: string;
};

export const WSeriesFormTableForSuccessPage: FC<
  IWSeriesFormTableForSuccessPageProps
> = ({ groupId, taskSeason, noContractorsFound, entityId }) => {
  const { taskId } = useParams<{ taskId: string }>();
  const { data: merchants = [], isLoading: isContractorLoading } =
    useGetTaskMerchantsQuery({
      groupId,
      taskId,
      season: taskSeason,
      entityId,
    });

  if (isContractorLoading || merchants.length === 0 || noContractorsFound) {
    return <></>;
  }

  return (
    <div className="t-my-6 t-w-full">
      <WSeriesForm
        groupId={groupId}
        noContractorsFound={false}
        taskSeason={taskSeason}
        isSuccessPage
        shouldLoadBooksOnboarding={false}
        entityId={entityId}
      />
    </div>
  );
};
