import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { TransferPricingInfo } from "components/TransferPricingInfo";
import { CLICKED_INITIATE_TRANSFER } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  useGetTPAccountInfoQuery,
  useGetTransfersQuery,
} from "store/apis/transferPricing";
import { setAccountInfo } from "store/slices/transferpricing";
import { RootState } from "store/store";
import { useDebounce } from "utils/debounce";
import { Header } from "../../components/DesignSystem/Header/Header";
import { Search } from "../../components/DesignSystem/Search/Search";
import { AcceptTOS } from "../../components/TPPayments/Header/AcceptTOS";
import { TransferMoneyCard } from "../../components/TPPayments/Header/TransferMoneyCard";
import { Illustration } from "../../components/TPPayments/Illustration/Illustration";
import { TransferTable } from "../../components/TPPayments/TransferTable/TransferTable";

export function Invoices() {
  usePageTitle("TP Payments");
  const { path } = useRouteMatch();
  const { search } = useLocation();
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const { isForeignCA } = useRoleBasedView();
  const { trackEvent } = useAnalytics();

  const debouncedSearchedValue = useDebounce(searchedValue, 100);

  const dispatch = useDispatch();
  const { isSnackbar } = useSelector((state: RootState) => state.dashboard);
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    data: accountInfo,
    isLoading: isInfoLoading,
    isSuccess: isInfoLoaded,
    isUninitialized: isInfoUninitialized,
  } = useGetTPAccountInfoQuery({ groupId }, { skip: !groupId });

  useEffect(() => {
    if (accountInfo) dispatch(setAccountInfo(accountInfo));
  }, [isInfoLoaded, isInfoLoading]);

  const status = accountInfo?.status;
  const receiverUuid = accountInfo?.receiver?.uuid;
  const senderUuid = accountInfo?.sender?.uuid;

  const { data: transfers = [], isLoading: isTransfersLoading } =
    useGetTransfersQuery(
      { groupId, searchTerm: debouncedSearchedValue, userId: senderUuid || "" },
      { skip: !senderUuid }
    );

  let toolTipContent = null;

  const handleInitiateTransfer = () => {
    trackEvent(CLICKED_INITIATE_TRANSFER);
  };

  if (isInfoLoading || isInfoUninitialized) {
    return <Loader />;
  }

  const isOnboardingPending =
    isInfoLoaded && (!senderUuid || !receiverUuid || status !== "active");

  if (isOnboardingPending) {
    toolTipContent = "Please agree to the updated Terms of Service to proceed.";
  }

  if (!accountInfo?.tos_accepted) {
    toolTipContent = "Please complete KYC to proceed.";
  }
  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Transfer Pricing Payments"
          right={isForeignCA && <EntitySelector />}
        />
      }
    >
      <div>
        {isOnboardingPending ? (
          <div className="t-mb-5 t-grid">
            <Illustration isOnboardingPending={isOnboardingPending} />
          </div>
        ) : (
          <div className="t-mb-5 t-flex">
            <TransferMoneyCard
              setInstructionsModal={setInstructionsModal}
              sender={accountInfo?.sender?.name}
              receiver={accountInfo?.receiver?.name}
              isOnboardingPending={isOnboardingPending}
              tosAccepted={accountInfo?.tos_accepted || false}
            />
          </div>
        )}

        {!Boolean(accountInfo?.tos_accepted) && !isOnboardingPending && (
          <AcceptTOS />
        )}

        {!isOnboardingPending && (
          <>
            <div className="t-text-subtitle-sm">Your transfers</div>
            <div className="t-flex t-items-center t-justify-between t-py-3">
              <Search
                placeholder="Search..."
                value={searchedValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchedValue(e.target.value)
                }
              />
              <ConditionalLink to={`${path}transfer/${search}`}>
                <Button
                  size="small"
                  customType="primary"
                  onClick={handleInitiateTransfer}
                  disabled={isOnboardingPending || !accountInfo?.tos_accepted}
                >
                  <ConditionalToolTip condition={toolTipContent}>
                    <span className="t-whitespace-nowrap">Create transfer</span>
                  </ConditionalToolTip>
                </Button>
              </ConditionalLink>
            </div>

            <TransferTable
              transferList={transfers}
              isTransferDataLoading={isTransfersLoading}
              setInstructionsModal={setInstructionsModal}
            />
          </>
        )}
      </div>

      <TransferPricingInfo
        openTransferMoney={instructionsModal}
        accountInfo={accountInfo}
        onClose={() => setInstructionsModal(false)}
      />
    </DashboardLayout>
  );
}
