import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React from "react";
import { useDeleteMonthCardMutation } from "store/apis/financialClosing";
import { closeDeleteMonthCardModal } from "store/slices/financialClosing";
import { BackendError } from "types/utils/error";

const DeleteCard = () => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useAppDispatch();
  const [deleteMonthCard, { isLoading }] = useDeleteMonthCardMutation();

  const { monthId, open } = useAppSelector(
    (state) => state.financialClosing.deleteCard
  );

  const onClose = () => {
    dispatch(closeDeleteMonthCardModal());
  };

  const onDelete = async () => {
    try {
      await deleteMonthCard({
        groupId,
        entityId,
        uuid: monthId,
      }).unwrap();
      successToast({ title: "Successfully deleted" });
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-text-text-30 t-text-subtext">
          This will remove the existing monthly closing card. <br />
          <span className="t-text-subtitle-sm">This cannot be undone.</span>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button customType="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={onDelete}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Delete
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DeleteCard;
