import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import QueryString from "qs";

export type BooksCrmFilterType = "subscription_plans" | "teams" | "assignees";

export type BooksCrmFilterName = "Plan" | "Team" | "Assignee";

type Filter = {
  name: BooksCrmFilterName;
  value: string[];
  type: BooksCrmFilterType;
};

type InitialState = {
  filters: Record<BooksCrmFilterType, Filter>;
};

export const bookkeepingTrackerInitialState: InitialState = {
  filters: {
    subscription_plans: {
      name: "Plan",
      value: [],
      type: "subscription_plans",
    },
    teams: {
      name: "Team",
      value: [],
      type: "teams",
    },
    assignees: {
      name: "Assignee",
      value: [],
      type: "assignees",
    },
  },
};

export const BookkeepingTracker = createSlice({
  name: "bookkeepingTracker",
  initialState: bookkeepingTrackerInitialState,
  reducers: {
    setBookkeepingTrackerFilter: (
      state,
      action: PayloadAction<QueryString.ParsedQs>
    ) => {
      const payload = action.payload;

      const updatedValues = Object.keys(payload).reduce((acc, el) => {
        const newState = {
          ...bookkeepingTrackerInitialState.filters?.[el as BooksCrmFilterType],
          value: payload[el] as string[],
        };

        return { ...acc, [el]: { ...newState } };
      }, {});

      state.filters = {
        ...state.filters,
        ...updatedValues,
      };
    },
  },
});

export const { setBookkeepingTrackerFilter } = BookkeepingTracker.actions;

export default BookkeepingTracker.reducer;
