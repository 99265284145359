export const VC_TAG = "VC_TAG";
export const COMPANY_GROUP_TAG = "COMPANY_GROUP_TAG";
export const TASK_TAG = "TASK_TAG";
export const PROFILE_TAG = "PROFILE_TAG";
export const CRM_TAG = "CRM_TAG";
export const INDIA_LAW_FIRM_TAG = "INDIA_LAW_FIRM_TAG";
export const US_LAW_FIRM_TAG = "US_LAW_FIRM_TAG";
export const CURRENT_CPA = "CURRENT_CPA";
export const COMPANY_GROUP_USER_MAP_TAG = "COMPANY_GROUP_USER_MAP_TAG";
export const PERK_TAG = "PERK_TAG";
export const DOCUMENT_TAG = "DOCUMENT_TAG";
export const ENTITY_TRANSACTION_TAG = "ENTITY_TRANSACTION_TAG";
export const AUTOFILL_ADDRESS_TYPE_TAG = "AUTOFILL_ADDRESS_TYPE_TAG";
export const AUTOFILL_PERSON_TYPE_TAG = "AUTOFILL_PERSON_TYPE_TAG";
export const TICKETS_TAG = "TICKETS_TAG";
export const SERVICE_TEAM_TAG = "SERVICE_TEAM_TAG";
