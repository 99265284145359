export const SOURCE_BANK = "SOURCE_BANK";
export const INKLE_CREDITS = "INKLE_CREDITS";

// Define a type for the keys of SOURCE_TITLE
export type SourceTitleKeys = typeof SOURCE_BANK | typeof INKLE_CREDITS;

export const SOURCE_TITLE: Record<SourceTitleKeys, string> = {
  [SOURCE_BANK]: "Original Payment Method",
  [INKLE_CREDITS]: "Inkle Credits",
};
