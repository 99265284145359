import { emptyApi } from "./emptyApi";

type CoFounder = {
  cofounder_name: string;
  cofounder_linkedIn_url: string;
  cofounder_email: string;
  id: string;
};

type Founder = {
  name: string;
  linkedIn_url: string;
  twitter_url: string;
  email: string;
};

type Entity = {
  name: string;
  year_of_incorporation: string;
};

type CompanyGroup = {
  name: string;
  description: string;
  company_pitch: string;
  group_website?: string;
  group_linkedIn_url?: string;
  year_founded?: string;
};

type DetailsForInvestors = {
  raised_till_date: string;
  round_type: string;
  investor_types: string[];
  indian_resident_ten_percent_founder_exists: boolean;
  markets: string[];
  annual_revenue: string;
  business_models: string[];
  industry_types: string[];
  founder_count: number;
  cofounder_names: string;
  year_founded?: string;
  cofounder_data: CoFounder[];
  updated_at: string;
};

export type VCRaiseProfile = {
  uuid: string;
  comment?: string;
  founder: Founder;
  entity: Entity;
  company_group: CompanyGroup;
  details_for_investors: DetailsForInvestors;
  vc_interest: "Accepted" | "Rejected";
};

export type RaiseProfileUpdatePayload = {
  company_pitch: string;
  round_type: string;
  investor_types: string[];
  indian_resident_ten_percent_founder_exists: boolean;
  markets: string[];
  industry_types: string[];
  business_models: string[];
  current_annual_revenue: string;
  description: string;
  cofounder_data: CoFounder[];
};

export type RaisePofile = {
  founder?: Founder;
  entity?: Entity;
  company_group?: CompanyGroup;
  details_for_investors?: DetailsForInvestors;
};

export const vcIntroApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getRaiseProfiles: build.query<VCRaiseProfile[], void>({
      query: () => ({
        url: `api/inkle/reference/raise_groups/`,
      }),
      providesTags: () => ["RAISE_PROFILES"],
    }),

    getGroupsRaiseProfile: build.query<RaisePofile, { groupId: string }>({
      query: ({ groupId }) =>
        `/api/inkle/reference/group/${groupId}/raise_group/`,
      providesTags: (res) => (res ? ["RAISE_PROFILES"] : []),
    }),

    updateGroupRaiseProfile: build.mutation<
      {
        round_types: string[];
        investor_types: string[];
        markets: string[];
        business_models: string[];
        industry_types: string[];
        annual_revenue: string[];
      },
      { groupId: string; payload: RaiseProfileUpdatePayload }
    >({
      query: ({ groupId, payload }) => ({
        url: `api/inkle/reference/group/${groupId}/raise_group/`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: (res) => (res ? ["VC_INTROS", "RAISE_PROFILES"] : []),
    }),

    getRaiseChoices: build.query<
      {
        round_types: string[];
        investor_types: string[];
        markets: string[];
        business_models: string[];
        industry_types: string[];
        annual_revenue: string[];
      },
      void
    >({
      query: () => `api/inkle/reference/raise_choices/`,
    }),

    updateRaiseProfile: build.mutation<
      VCRaiseProfile,
      {
        status: "Accepted" | "Rejected";
        profileId: string;
        comment?: string;
      }
    >({
      query: ({ status, profileId, comment }) => ({
        url: `/api/inkle/reference/vc/intro/${profileId}/`,
        method: "PUT",
        body: {
          status,
          comment,
        },
      }),
      invalidatesTags: (res) => (res ? ["RAISE_PROFILES"] : []),
    }),

    investorDetail: build.query({
      query: () => {
        return { url: `/api/inkle/reference/vc/view/` };
      },
      providesTags: () => ["VC_PROFILE"],
    }),

    editInvestorDetail: build.mutation({
      query: ({ payload }) => ({
        url: `/api/inkle/reference/vc/view/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (res) => (res ? ["VC_PROFILE"] : []),
    }),
  }),
});

export const {
  useGetRaiseProfilesQuery,
  useUpdateRaiseProfileMutation,
  useGetGroupsRaiseProfileQuery,
  useUpdateGroupRaiseProfileMutation,
  useGetRaiseChoicesQuery,
  useInvestorDetailQuery,
  useEditInvestorDetailMutation,
} = vcIntroApis;
