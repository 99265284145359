import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationState {
  showNudge: boolean;
  browserBlocked: boolean;
}

const initialState: NotificationState = {
  showNudge: false,
  browserBlocked: false,
};

export const notification = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationNudgeModal: (
      state,
      action: PayloadAction<{ show_nudge: boolean; browser_blocked: boolean }>
    ) => {
      state.showNudge =
        action.payload?.show_nudge && !action.payload?.browser_blocked;
      state.browserBlocked =
        action.payload?.show_nudge && action.payload?.browser_blocked;
    },
    closeNotificationNudgeModal: (state) => {
      state.showNudge = false;
    },
    openNotificationBlockModal: (state) => {
      state.browserBlocked = true;
    },
    closeNotificationBlockModal: (state) => {
      state.browserBlocked = false;
    },
  },
});

export const {
  setNotificationNudgeModal,
  closeNotificationNudgeModal,
  closeNotificationBlockModal,
  openNotificationBlockModal,
} = notification.actions;

export default notification.reducer;
