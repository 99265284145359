import { useEffect, useState } from "react";
import { MessageResponse } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const useGetMessage = (messageID: string) => {
  const { client } = useChatContext();
  const [message, setMessage] =
    useState<MessageResponse<DefaultStreamChatGenerics>>();

  useEffect(() => {
    const getMessage = async () => {
      try {
        const { message } = await client.getMessage(messageID);
        setMessage(message);
      } catch (error) {
        console.log(error);
      }
    };
    if (messageID) {
      getMessage();
    }
  }, [messageID]);

  return message;
};
