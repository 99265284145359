import { Button } from "components/DesignSystem/Button/Button";
import ConditionalToolTip from "components/design/conditionalToolTip";
import ToolTip from "components/design/toolTip";
import ReactCountryFlag from "react-country-flag";
import BlackArrowLeft from "static/images/BlackArrowLeft.svg";

interface HeaderI {
  sender?: string | null;
  receiver?: string | null;
  isOnboardingPending: boolean;
  tosAccepted: boolean;
  setInstructionsModal: (b: boolean) => void;
}

export function TransferMoneyCard({
  sender,
  receiver,
  isOnboardingPending,
  setInstructionsModal,
  tosAccepted,
}: HeaderI) {
  let toolTipContent = null;

  if (isOnboardingPending) {
    toolTipContent = "Please agree to the updated Terms of Service to proceed.";
  }

  if (!tosAccepted) {
    toolTipContent = "Please complete KYC to proceed.";
  }

  return (
    <>
      <div className=" t-rounded-md t-border t-border-solid t-border-neutral-10 t-px-6 t-pb-4 t-pt-5 t-w-[552px]">
        <div className="t-flex t-items-center t-justify-between t-w-full">
          <div className="t-flex t-flex-col t-gap-8 md:t-flex-row t-w-full t-justify-between">
            <div className="t-flex t-flex-col t-gap-7">
              <span className="t-text-subtitle t-font-semibold">
                Transfer Money
              </span>

              <div className="t-flex t-gap-4 t-pt-2">
                <div>
                  <div className="t-flex t-items-center t-gap-1">
                    <ReactCountryFlag
                      countryCode="US"
                      svg
                      style={{
                        fontSize: "1rem",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      title="US"
                    />
                    <div className="t-text-body t-text-text-30">Sender</div>
                  </div>
                  <div className="t-truncate t-text-body t-text-text-100">
                    <ToolTip text={sender as string}>
                      <span>{sender ? sender : "Acme Inc"}</span>
                    </ToolTip>
                  </div>
                </div>
                <div className="t-flex t-rotate-180 t-justify-center t-w-6 t-h-6">
                  <img src={BlackArrowLeft} alt="to" />
                </div>
                <div className="t-max-w-32">
                  <div className="t-flex t-items-center t-gap-1">
                    <ReactCountryFlag
                      countryCode="IN"
                      svg
                      style={{
                        fontSize: "1rem",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      title="IN"
                    />
                    <div className="t-text-body t-text-text-30">Recipient</div>
                  </div>
                  <div className="t-truncate t-text-body t-text-text-100">
                    <ToolTip text={receiver as string}>
                      <span>{receiver ? receiver : "Acme Ind Pvt Ltd"}</span>
                    </ToolTip>
                  </div>
                </div>
              </div>
            </div>

            <div className="t-flex t-gap-2 t-justify-end">
              <Button
                size="small"
                onClick={() => setInstructionsModal(true)}
                disabled={isOnboardingPending || !tosAccepted}
                customType="primary-outlined"
              >
                <ConditionalToolTip condition={toolTipContent}>
                  <span className="t-whitespace-nowrap">Account Info</span>
                </ConditionalToolTip>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
