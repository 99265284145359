import { Divider } from "@mui/material";
import {
  OptionData,
  Combobox,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { datePeriod } from "constants/bookkeeping";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs, { Dayjs } from "dayjs";
import { Formik, Form, Field, FieldProps } from "formik";
import { useToast } from "hooks/useToast";
import { MultiValue, SingleValue } from "react-select";
import { getDateRange, DateRangeValue } from "utils/getDateRange";

export const DateFilter = ({
  updateFilter,
  values,
}: {
  updateFilter: <S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
    }[S]
  ) => void;
  values: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
  };
}) => {
  const initialDates = {
    START_DATE: values?.START_DATE ? new Date(values.START_DATE as string) : "",
    END_DATE: values?.END_DATE ? new Date(values.END_DATE as string) : "",
    SELECT_PERIOD: datePeriod.find(
      ({ value }) => value === values?.SELECT_PERIOD
    ),
  };
  const { alertToast } = useToast();

  return (
    <Formik initialValues={initialDates} onSubmit={() => {}}>
      {({ setFieldValue, values: { START_DATE, END_DATE } }) => {
        const handleDateChange = ({
          date,
          name,
        }: {
          date: Date;
          name: "START_DATE" | "END_DATE";
        }) => {
          updateFilter(name, date ? dayjs(date).format(YYYY_MM_DD) : "");
        };

        const updateDates = (START_DATE?: Dayjs, END_DATE?: Dayjs) => {
          setFieldValue("START_DATE", START_DATE);
          setFieldValue("END_DATE", END_DATE);
          if (START_DATE) {
            updateFilter("START_DATE", dayjs(START_DATE).format(YYYY_MM_DD));
          }
          if (END_DATE) {
            updateFilter("END_DATE", dayjs(END_DATE).format(YYYY_MM_DD));
          }
        };

        const handleChange = (
          values: MultiValue<OptionData> | SingleValue<OptionData>
        ) => {
          setFieldValue(
            "SELECT_PERIOD",
            (values as SingleValue<OptionData>)?.value || ""
          );
          updateFilter(
            "SELECT_PERIOD",
            (values as SingleValue<OptionData>)?.value || ""
          );
          const { startDate, endDate } = getDateRange(
            (values as SingleValue<OptionData>)?.value as DateRangeValue
          );
          updateDates(startDate, endDate);
        };

        return (
          <Form className="all:unset t-flex t-flex-col t-gap-4">
            <Combobox
              withForm
              name="SELECT_PERIOD"
              label="Period"
              placeholder="Select period"
              onChange={handleChange}
              components={{ ClearIndicator: () => null }}
              options={datePeriod}
              block
              value={initialDates.SELECT_PERIOD}
            />
            <Divider />
            <Field name="START_DATE">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    maxDate={END_DATE ? new Date(END_DATE) : new Date()}
                    label="From"
                    placeholder={DD_MMM_YYYY}
                    onDateChange={(date) => {
                      if (new Date(END_DATE) < new Date(date)) {
                        alertToast({
                          message: "Start date cannot be greater than end date",
                        });
                        setFieldValue("END_DATE", "");
                        return;
                      }
                      handleDateChange({ date, name: "START_DATE" });
                    }}
                    portalId="callStart"
                  />
                );
              }}
            </Field>
            <Field name="END_DATE">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    maxDate={new Date()}
                    minDate={START_DATE ? new Date(START_DATE) : undefined}
                    label="To"
                    placeholder={DD_MMM_YYYY}
                    onDateChange={(date) => {
                      if (new Date(START_DATE) > new Date(date)) {
                        alertToast({
                          message: "End date cannot be less than start date",
                        });
                        setFieldValue("START_DATE", "");
                        return;
                      }
                      handleDateChange({ date, name: "END_DATE" });
                    }}
                    portalId="callEnd"
                  />
                );
              }}
            </Field>
          </Form>
        );
      }}
    </Formik>
  );
};
