import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik, FormikValues } from "formik";
import { bankAutofill } from "formValidations/autofillSchema";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";

type BankModalProps = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (v: FormikValues) => void;
  initialValues: any;
};

export const BankModal = ({
  isLoading,
  open,
  onClose,
  initialValues,
  onSubmit,
}: BankModalProps) => {
  const { entities } = useCurrentGroupContext();
  const { id } = initialValues;
  const localInitialValues = id
    ? initialValues
    : {
        account_number: "",
        bank_identifier: "",
        entity_id: "",
        name: "",
        routing_number: "",
        swift_code: "",
      };

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Formik
        initialValues={localInitialValues}
        onSubmit={onSubmit}
        validationSchema={bankAutofill}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{id ? "Edit" : "Add"} Bank</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Form className="all:unset t-mt-3 t-flex t-flex-col t-gap-5">
              <TextInput block label="Bank Name" name="name" />
              <TextInput block label="Account Number" name="account_number" />
              <TextInput block label="Routing Number" name="routing_number" />
              <TextInput block label="Swift Code" name="swift_code" />
              <TextInput
                block
                label="Bank Identifier"
                name="bank_identifier"
                tooltipText="IFSC for India and IBAN for International"
              />
              <SelectDropDown name="entity_id" label="Entity">
                <option value="">Select entity</option>
                {entities.map(({ uuid, name }) => (
                  <option value={uuid} key={uuid}>
                    {name}
                  </option>
                ))}
              </SelectDropDown>
            </Form>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button
              type="reset"
              customType="secondary"
              size="regular"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              customType="primary"
              size="regular"
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Formik>
    </Modal.Root>
  );
};
