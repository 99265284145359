import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import MinusCircle from "components/icons/MinusCircle";
import { TEAM_UPDATE_MESSAGE } from "constants/documents";
import { useAuth } from "hooks/useAuth";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import GrayPlusCircle from "static/images/GrayPlusCircle.svg";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useUpdateTeamSeatsMutation } from "store/apis/teamSetting";
import { SubscriptionInProfile } from "types/Models/subscription";

const SeatPlanManage = ({
  show,
  closeModal,
  seatData,
  handlePlanManageSuccess,
}: {
  show: boolean;
  closeModal: () => void;
  seatData: {
    active_group_users_count: number;
    available_seats: number;
    extra_seat_amount: number;
    free_seats: number;
  };
  handlePlanManageSuccess?: () => void;
}) => {
  const {
    active_group_users_count: activeGroupUsersCount,
    available_seats: availableSeats,
    extra_seat_amount: extraSeatAmount,
    free_seats: freeSeats,
  } = seatData;
  const totalSeats = availableSeats + activeGroupUsersCount;
  const { uuid: groupId } = useCurrentGroupContext();
  const [isSeatManageStep, setSeatManageStep] = useState(true);
  const [updatedSeats, setUpdatedSeats] = useState(totalSeats);
  const [updateTeamSeats, { isLoading }] = useUpdateTeamSeatsMutation();
  const { alertToast, successToast } = useToast();
  const userNeedsToBeArchived = activeGroupUsersCount - updatedSeats;

  const entityId = useCurrentEntityId();

  const authtoken = useAuth();
  let {
    platform_subscription: platformSubscription,
  }: {
    platform_subscription: SubscriptionInProfile;
  } = authtoken || {};

  const handleSeatManage = async () => {
    try {
      if (updatedSeats > totalSeats || activeGroupUsersCount <= updatedSeats) {
        await updateTeamSeats({
          group_id: groupId,
          seat_count: updatedSeats - freeSeats,
        }).unwrap();
        successToast({ message: TEAM_UPDATE_MESSAGE });
        handlePlanManageSuccess?.();
        closeModal();
      } else {
        setSeatManageStep(false);
      }
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-w-[440px]">
          <Modal.Header>
            <Modal.Title>Manage seats</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {isSeatManageStep ? (
              <div>
                <div className="t-text-body">
                  Every additional seat costs{" "}
                  <b>
                    ${extraSeatAmount}/
                    {platformSubscription?.billing_cycle === "MONTHLY"
                      ? "month"
                      : "year"}
                  </b>
                  . The first payment will be pro-rated and your subscription
                  plan will be updated from next month onwards.
                </div>
                <div className="t-mt-2 t-flex t-items-center t-justify-between t-text-text-100">
                  <div className="t-text-subtext">Update seats number</div>
                  <div className="t-flex t-items-center t-gap-1.5">
                    <Button
                      customType="transparent"
                      onClick={() => setUpdatedSeats((prev) => prev - 1)}
                      disabled={updatedSeats <= 3}
                    >
                      <div
                        className={classNames({
                          "t-text-text-30": updatedSeats > 3,
                          "t-text-neutral-20": updatedSeats <= 3,
                        })}
                      >
                        <MinusCircle color="currentColor" />
                      </div>
                    </Button>
                    <div className="t-text-subtitle-sm">{updatedSeats}</div>
                    <Button
                      customType="transparent"
                      onClick={() => setUpdatedSeats((prev) => prev + 1)}
                    >
                      <img src={GrayPlusCircle} alt="GrayPlusCircle" />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="t-mr-5 t-w-full t-border t-border-solid t-border-surface-transparent t-border-t-neutral-0 t-px-6 t-py-5 t-text-body-sm">
                <div className="t-text-body">
                  Please{" "}
                  <b>
                    archive any {userNeedsToBeArchived}{" "}
                    {userNeedsToBeArchived === 1 ? "member" : "members"}{" "}
                  </b>
                  from Team Settings in order to reduce the existing seat count.
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isSeatManageStep ? (
              <div className="t-flex t-justify-end t-gap-3">
                <Button onClick={closeModal}>Cancel</Button>
                <Button
                  customType="primary"
                  isLoading={isLoading}
                  onClick={handleSeatManage}
                  disabled={updatedSeats === totalSeats || isLoading}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div className="t-flex t-justify-end t-gap-4">
                <Button onClick={closeModal}>Cancel</Button>
                <ConditionalLink to="/team">
                  <Button customType="primary">Archive</Button>
                </ConditionalLink>
              </div>
            )}
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default SeatPlanManage;
