import { Upgrade } from "components/TopBar/Upgrade";
import { FREE_PLAN } from "constants/subscriptionType";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import "static/styles/containers/dashboard.css";
import {
  openFreeSubscription,
  openSubscriptionModal,
  setRedirectPath,
} from "store/slices/subscriptionModal";

export const UpgradeTaxPlan = () => {
  const dispatch = useAppDispatch();
  const { isCustomer, isInvestor } = useRoleBasedView();
  const { authtoken } = useContext(authContext);

  const currentPlan = authtoken?.platform_subscription?.subscription_name;
  const shouldUpgrade = !currentPlan || currentPlan === FREE_PLAN;
  const shouldShowUpgrade = Boolean(isCustomer && shouldUpgrade && !isInvestor);

  const showBlocker = () => {
    dispatch(setRedirectPath(""));
    if (authtoken.is_public_user) {
      dispatch(openFreeSubscription());
    } else {
      dispatch(openSubscriptionModal());
    }
  };

  if (shouldShowUpgrade) {
    return <Upgrade onUpgrade={showBlocker} />;
  }

  return null;
};
