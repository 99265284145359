import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { ONE_TIME, RECURRING } from "constants/billing";
import { Form, Formik } from "formik";
import { createInvoiceSchema } from "formValidations/createInvoiceSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useCreateBillingInvoiceMutation } from "store/apis/billingInvoice";
import { useGetTaskListForServiceTeamQuery } from "store/apis/taskList";
import { BackendError } from "types/utils/error";

export const CreateInvoice = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const [createInvoice, { isLoading }] = useCreateBillingInvoiceMutation();
  const entityId = useCurrentEntityId();
  const { successToast, alertToast } = useToast();
  const { isAdmin } = useRoleBasedView();

  const { data: { tasks } = {} } = useGetTaskListForServiceTeamQuery(
    {
      groupId,
    },
    {
      skip: !isAdmin || !groupId,
    }
  );

  const onCreateInvoice = async (values: {
    task_id: string;
    invoice_amount: string;
    task_short_name: string;
    invoice_type: typeof ONE_TIME | typeof RECURRING;
    description?: string;
    season: string;
  }) => {
    try {
      await createInvoice({ ...values, groupId, entityId }).unwrap();
      successToast({ message: "Invoice created" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const taskOptions = tasks?.map((task) => ({
    value: task.uuid,
    label: `${task.title} - ${task.season}`,
  }));

  const seasonOptions = [
    { value: "2019", label: 2019 },
    { value: "2020", label: 2020 },
    { value: "2021", label: 2021 },
    { value: "2022", label: 2022 },
    { value: "2023", label: 2023 },
    { value: "2024", label: 2024 },
  ];

  const invoiceTypeOptions = [
    {
      value: ONE_TIME,
      label: "One time",
    },
    {
      value: RECURRING,
      label: "Recurring",
    },
  ];

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        validationSchema={createInvoiceSchema}
        initialValues={{
          invoice_type: ONE_TIME,
          season: "",
          description: "",
          task_id: "",
          task_short_name: "",
          invoice_amount: "",
        }}
        onSubmit={onCreateInvoice}
      >
        {({ submitForm }) => {
          return (
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>
                  <div>Create Invoice</div>
                </Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form className="t-m-0 t-space-y-4">
                  <TextInput
                    required
                    name="invoice_amount"
                    label="Invoice amount"
                  />
                  <TextInput
                    required
                    name="task_short_name"
                    label="Invoice name"
                  />
                  <Combobox
                    menuPortalTarget={document.body}
                    withForm
                    required
                    label="Invoice type"
                    name="invoice_type"
                    options={invoiceTypeOptions}
                    menuPlacement="auto"
                  />
                  <Combobox
                    menuPortalTarget={document.body}
                    withForm
                    label="Task"
                    name="task_id"
                    options={taskOptions}
                    menuPlacement="auto"
                  />
                  <Combobox
                    menuPortalTarget={document.body}
                    withForm
                    label="Season"
                    name="season"
                    options={seasonOptions}
                    menuPlacement="top"
                  />
                  <TextInput name="description" label="Description" />
                </Form>{" "}
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  customType="primary"
                  onClick={submitForm}
                >
                  Create Invoice
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
