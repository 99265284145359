import { ConditionalLink } from "components/conditionalLink";
// @ts-ignore
import GoogleAuth from "components/design/authWithGoogle";
import Loader from "components/design/loader";
import RightSlider from "components/design/RightSlider";
import { Button } from "components/DesignSystem/Button/Button";
import Help from "components/help/help";
import { LogoutIcon } from "components/icons/LogoutIcon";
import ProfileHelpIcon from "components/icons/ProfileHelpIcon";
import Logo from "components/inkleLogo";
import Left from "components/landingContent";
import { PHONE } from "constants/googleAuth";
import { usePageTitle } from "hooks/usePageTitle";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//@ts-ignore
import WhatsappIcon from "static/images/whatsappIcon.png";
import { useGetCrmUserQuery } from "store/apis/onboarding";

const PhoneVerify = () => {
  usePageTitle("Phone verify");
  const { hash } = useLocation();
  const crmAccessToken = hash?.split("#payload=")[1];
  const [showHelp, setShowHelp] = useState(false);
  const { data, isLoading, isError } = useGetCrmUserQuery(
    { crmAccessToken },
    { skip: crmAccessToken === undefined }
  );
  const { logoutUser } = useContext(authContext);
  const history = useHistory();

  useEffect(() => {
    if (isError) {
      history.push("/signin");
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      localStorage.setItem("magicLinkData", JSON.stringify(data));
    }
    if (data?.type) {
      localStorage.setItem("user_type", data.type);
    }
  }, [data]);

  const openModal = () => setShowHelp(true);
  const closeModal = () => setShowHelp(false);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="t-flex t-h-20 t-items-center t-justify-between">
        <Logo className="t-mb-0 t-ml-16" />
        <div className="t-mr-8 t-flex t-items-center t-gap-2">
          <span
            className="t-z-50 t-mr-8 t-flex t-items-center t-text-text-100 hover:t-text-purple"
            onClick={openModal}
          >
            <Button customType="transparent" size="small">
              <ProfileHelpIcon color="currentColor" />
              <span className="helpText t-ml-1 !t-text-body !t-font-medium">
                Help
              </span>
            </Button>
          </span>
          <span className="t-text-text-100 hover:t-text-purple">
            <Button customType="transparent" onClick={logoutUser}>
              <LogoutIcon color="currentColor" />
              <span className="t-ml-1 t-text-body t-font-medium">Logout</span>
            </Button>
          </span>
        </div>
      </div>
      <div className="parentDiv t-mt-[4%] t-h-4/5 sm:t-justify-end">
        <ConditionalLink to={`https://wa.me/${PHONE}`}>
          <button className="t-absolute t-bottom-8 t-right-8 t-z-50 t-flex t-h-10 t-w-10 t-items-center t-justify-center t-rounded-full t-border-0 t-bg-[transparent] t-bg-dark_green-30 t-shadow-hover-card-shadow t-drop-shadow-bubble">
            <img className="s t-h-full" src={WhatsappIcon} alt="WhatsappIcon" />
          </button>
        </ConditionalLink>
        <Left className="sm:t-hidden" />
        <GoogleAuth
          googleAccessToken={undefined}
          setLoading={undefined}
          onGoogleAuthFail={undefined}
        />
        {showHelp && (
          <RightSlider width="auto" show={showHelp} closeModal={closeModal}>
            <Help loggedOut closeModal={closeModal} show={undefined} />
          </RightSlider>
        )}
      </div>
    </>
  );
};

export default PhoneVerify;
