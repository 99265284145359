import Toast, {
  ToastCustomType,
  ToastSizeType,
} from "components/DesignSystem/Toast/Toast";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { removeToast } from "store/slices/toast";
import { RootState } from "store/store";

export const ToastContainer = () => {
  const dispatch = useDispatch();
  const { toast } = useSelector((state: RootState) => state.toast);

  const onClose = (id: string) => {
    dispatch(removeToast(id));
  };

  return (
    <div className="t-absolute t-right-0 t-bottom-0 t-z-toast t-max-h-96 t-overflow-auto t-pt-4 no-scrollbar">
      <AnimatePresence mode="popLayout">
        {toast.map(({ id, message, size, title, type }) => (
          <Toast.Provider key={id}>
            <Toast.Root
              open={Boolean(id)}
              customType={type}
              size={size}
              onOpenChange={() => onClose(id)}
            >
              <Toast.Title>{title}</Toast.Title>
              <Toast.Description>{message}</Toast.Description>
              <Toast.Close onClose={() => onClose(id)} />
            </Toast.Root>
          </Toast.Provider>
        ))}
      </AnimatePresence>
    </div>
  );
};
