import StatusTags from "components/design/StatusTags";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import { REFERRAL_STATUS, REWARD_STATUS } from "dictionaries";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { MyReferrals, useGetReferralQuery } from "store/apis/refrral";

export const ReferralTable = () => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data } = useGetReferralQuery({ groupId }, { skip: !groupId });
  const { my_referrals = [] } = data || {};

  const page = Number(query.get("page")) || 1;

  const noReferrals = my_referrals.length === 0;

  const createColumn = createColumnHelper<MyReferrals>();

  const columns = [
    createColumn.accessor("referral_date", {
      header: "Date",
      size: 20,
    }),

    createColumn.accessor("referee_email", {
      header: "Email",
      size: 40,
    }),

    createColumn.accessor("referral_status", {
      header: "Status",
      size: 20,
      cell: (info) => (
        <StatusTags
          statusName={info.getValue()}
          statusType={REFERRAL_STATUS[info.getValue()]}
        />
      ),
    }),
    createColumn.accessor("reward_status", {
      header: "Referral payout",
      size: 20,
      cell: (info) => (
        <>
          {info.getValue() !== "NA" ? (
            <StatusTags
              statusName={info.getValue()}
              // @ts-ignore
              statusType={REWARD_STATUS[info.getValue()]}
            />
          ) : (
            "-"
          )}
        </>
      ),
    }),
  ];

  const table = useReactTable({
    data: data?.my_referrals || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!data || noReferrals) {
    return <></>;
  }

  const { current_page, total_pages, per_page, total_count } = data;

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = page < total_pages ? page : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (page < total_pages) {
      update({ query: "page", value: page + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  return (
    <div>
      <div className="t-my-4 t-flex t-items-center t-justify-end t-text-subtext t-text-text-100">
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <Table.Container size="regular">
        <Table.Content>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body>
            {table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Content>
      </Table.Container>
    </div>
  );
};
