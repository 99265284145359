import classNames from "classnames";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  CHURNED,
  CHURNED_,
  DISSOLVED,
  DISSOLVED_,
  DISSOLVING,
  DISSOLVING_,
  READYTOONBOARD,
  READY_TO_ONBOARD,
  SUCCESSFULLYONBOARDED,
  SUCCESSFULLY_ONBOARDED,
} from "types/contants/crm";

export const OpsStagesColor = {
  READY_TO_ONBOARD: [READYTOONBOARD, "t-bg-orange"],
  SUCCESSFULLY_ONBOARDED: [SUCCESSFULLYONBOARDED, "t-bg-dark_green"],
  CHURNED: [CHURNED_, "t-bg-neutral-100"],
  DISSOLVING: [DISSOLVING, "t-bg-neutral-50"],
  DISSOLVED: [DISSOLVED, "t-bg-neutral-40"],
};

const OpsStages = [
  [READY_TO_ONBOARD, READYTOONBOARD],
  [SUCCESSFULLY_ONBOARDED, SUCCESSFULLYONBOARDED],
  [CHURNED, CHURNED_],
  [DISSOLVING, DISSOLVING_],
  [DISSOLVED, DISSOLVED_],
];

const OpsStage = ({
  currentStage,
  groupId,
  updateSalesStage,
}: {
  currentStage: keyof typeof OpsStagesColor;
  groupId: string;
  localUpdate?: boolean;
  updateSalesStage: ({
    groupId,
    payLoad,
  }: {
    groupId: string;
    payLoad: {};
  }) => any;
}) => {
  const { alertToast } = useToast();
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const updateCompanyStage = async (stage: string) => {
    try {
      await updateSalesStage({
        groupId: groupId,
        payLoad: { ops_stage: stage },
      }).unwrap();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <DropDown.Root
      open={dropdownOpen}
      onOpenChange={(open) => setdropdownOpen(open)}
    >
      <DropDown.Trigger className="all:unset">
        <Button
          customType="ghost_icon"
          onClick={() => {
            setdropdownOpen(true);
          }}
        >
          <ToolTip side="top" text={OpsStagesColor?.[currentStage]?.[0]}>
            <div
              className={classNames(
                `t-inline-block t-h-[15px] t-w-[15px] t-rounded-[50%]`,
                OpsStagesColor?.[currentStage]?.[1]
              )}
            />
          </ToolTip>
        </Button>
      </DropDown.Trigger>

      <DropDown.Portal>
        <DropDown.Content className="t-max-h-96 t-overflow-auto t-py-2">
          {OpsStages.map((stage: any) => {
            const [stageName, stage_text] = stage;

            return (
              <DropDown.Item
                className="t-flex t-cursor-pointer t-items-center t-gap-2 t-px-4 t-py-2"
                onClick={() => {
                  updateCompanyStage(stageName);
                }}
                key={stage_text}
              >
                <div
                  className={classNames(
                    "t-inline-block t-h-[15px] t-w-[15px] t-rounded-[50%]",
                    // @ts-ignore
                    OpsStagesColor?.[stageName]?.[1]
                  )}
                />
                <div>{stage_text}</div>
              </DropDown.Item>
            );
          })}
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
};

export default OpsStage;
