import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ChannelInfoSliderState = {
  isActive: boolean;
  isMediaSliderActive: boolean;
  setSideBarToExpandOnClose: boolean;
  messageIdForInfo: string;
  showMessageInfo: boolean;
  showShareViaWhatsapp: boolean;
};

const initialState: ChannelInfoSliderState = {
  isActive: false,
  isMediaSliderActive: false,
  setSideBarToExpandOnClose: false,
  messageIdForInfo: "",
  showMessageInfo: false,
  showShareViaWhatsapp: false,
};

export const channelInfoSlider = createSlice({
  name: "channelInfoSlider",
  initialState,
  reducers: {
    setChannelInfoSliderActive: (
      state,
      action: PayloadAction<{
        isActive: boolean;
        setSideBarToExpandOnClose?: boolean;
      }>
    ) => {
      state.isActive = action.payload.isActive;
      state.setSideBarToExpandOnClose =
        action.payload.setSideBarToExpandOnClose ??
        state.setSideBarToExpandOnClose;
    },
    toggleChannelInfoSlider: (state) => {
      state.isActive = !state.isActive;
    },
    setMediaSliderActive: (state, action: PayloadAction<boolean>) => {
      state.isMediaSliderActive = action.payload;
    },
    openMessageInfoSlider: (state, action: PayloadAction<string>) => {
      state.messageIdForInfo = action.payload;
      state.showMessageInfo = true;
    },
    closeMessageInfoSlider: (state) => {
      state.messageIdForInfo = "";
      state.showMessageInfo = false;
    },
    openShareViaWhatsappSlider: (state) => {
      state.showShareViaWhatsapp = true;
    },
    closeShareViaWhatsappSlider: (state) => {
      state.showShareViaWhatsapp = false;
    },
  },
});

export const {
  setChannelInfoSliderActive,
  toggleChannelInfoSlider,
  setMediaSliderActive,
  openMessageInfoSlider,
  closeMessageInfoSlider,
  openShareViaWhatsappSlider,
  closeShareViaWhatsappSlider,
} = channelInfoSlider.actions;

export default channelInfoSlider.reducer;
