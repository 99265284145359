import { useDispatch } from "react-redux";
import FinancialClosingHome from "static/images/FinancialClosing-Home.svg";
import { Button } from "components/DesignSystem/Button/Button";
import { openOnboardingWidget } from "store/slices/onboardingWidget";

export const BooksOnboarding = () => {
  const dispatch = useDispatch();

  return (
    <div className="t-w-full t-h-full t-flex t-flex-col t-gap-12 t-justify-center">
      <div className="t-flex t-justify-center t-w-full t-h-[20%]">
        <img src={FinancialClosingHome} alt="Financials" />
      </div>
      <div className="t-flex t-justify-center">
        <div className="t-flex t-flex-col t-gap-4 t-justify-center t-items-center">
          <div className="t-text-subtitle-sm">Monthly Closing</div>
          <div className="t-text-body t-text-text-30 t-w-[60%] t-flex t-flex-wrap t-justify-around t-items-center t-text-center">
            Complete onboarding to view and track updates of your bookkeeping
            process.
          </div>
          <Button
            customType="primary"
            size="small"
            onClick={() => dispatch(openOnboardingWidget("Books"))}
          >
            Complete onboarding
          </Button>
        </div>
      </div>
    </div>
  );
};
