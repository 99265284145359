import { useEffect, useRef } from "react";

export const FormikListener: <T extends {}>(arg: {
  values: T;
  callback: (v: T) => void;
}) => JSX.Element | null = ({ values, callback }) => {
  const stringValues = JSON.stringify(values);
  const rendered = useRef(false);

  useEffect(() => {
    if (rendered.current) {
      callback(values);
    }

    rendered.current = true;
  }, [stringValues]);

  return null;
};
