import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCompleteReconciliationMutation } from "store/apis/reconciliation";
import { RootState } from "store/store";

export const CompleteModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { alertToast } = useToast();
  const { reconcilationId } = useParams<{ reconcilationId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const {
    duplicateTransactionsList,
    csvTxnsToBeAddedList,
    manualTxnsToBeAddedList,
  } = useSelector((state: RootState) => state.reconciliation);
  const [completeReconciliation] = useCompleteReconciliationMutation();

  const onComplete = async () => {
    try {
      await completeReconciliation({
        entityId,
        groupId,
        reconcilationId,
        manualTxnsToBeAddedList,
        csvTxnsToBeAddedList,
        duplicateTransactionsList,
      }).unwrap();
      history.push(`/books/transactions/reconciliation/${search}`);
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Complete Reconciliation</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik initialValues={{}} onSubmit={onComplete}>
          {({ isSubmitting }) => (
            <Form className="t-m-0 t-w-full">
              <Modal.Body className="t-text-body t-text-text-100">
                This will update the transactions in Inkle Books as per your
                changes.
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-end">
                <Button
                  customType="primary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Complete
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
