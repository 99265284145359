import dayjs from "dayjs";
import { CPA_TEAM, INKLE_TEAM } from "./chatType";

export const ALL_TRANSACTIONS = "All transactions";
export const WHITE_COLOUR = "white";
export const BLACK_COLOUR = "black";
export const NEEDS_CLARIFICATION = "NEEDS_CLARIFICATION";
export const FOR_REVIEW = "FOR_REVIEW";
export const EXCLUDED = "EXCLUDED";
export const REVIEWED = "REVIEWED";
export const CLARIFICATION = "Clarification";
export const REMOVED_FROM_CLARIFY =
  "Transactions has been reviewed successfully and removed from the clarifications tab";
export const ADDED_TO_CLARIFY =
  "Transactions has been added to the clarifications tab successfully";

export const CLICKED_ROW_KEY = "uuid";
export const CONTRACTORS = "CONTRACTOR";
export const TRANSACTIONS = "Transactions";
export const ACCOUNTS = "Accounts";
export const MERCHANTS = "Merchants";
export const ALL_MERCHANTS = "All Merchants";

export const ZOHO_BOOKS = "Zoho Books";
export const QUICK_BOOKS = "QuickBooks";

// Transaction filter types
export const AMOUNT = "amount";
export const TRANSACTION_DATE = "transactionDate";
export const OTHERS = "others";

// Accounting Methods
export const ACCRUAL = "ACCRUAL";
export const CASH = "CASH";

// CSV Txn Type
export const DUPLICATE = "DUPLICATE";
export const MISSING = "MISSING";

export const SAMPLE_CSV =
  "https://inkle-django-files-prod.s3.ap-south-1.amazonaws.com/Sample+Open+Item+File.xlsx";

// COA
export const ASSET = 1;
export const LIABILITY = 2;
export const EQUITY = 3;
export const REVENUE = 4;
export const EXPENSE = 5;

// REPORTS
export const BALANCE_SHEET = "BALANCE_SHEET";
export const INCOME_STATEMENT = "INCOME_STATEMENT";
export const CASH_FLOW_STATEMENT = "CASH_FLOW";
export const SAVED_REPORTS = "SAVED_REPORTS";
export const GENERAL_LEDGER = "GENERAL_LEDGER";
export const TRIAL_BALANCE = "TRIAL_BALANCE";
export const DOWNLOAD_PDF = "DOWNLOAD_PDF";
export const DOWNLOAD_EXCEL = "DOWNLOAD_EXCEL";

// REPORTS_STATE
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";

export const INKLE_DEMO = "https://calendly.com/inklehq/inkle-demo";

// DASHBOARD
export const CONNECTIONS = "Connections";
export const RECONCILIATION = "Reconciliation";
export const PENDING_REVIEW_TRANSACTIONS = "Pending Review Transactions";
export const FINANCIAL_CLOSING = "Financial Closing";
export const CHAT_REQUESTS = "Chat Requests";
export const INFO_REQUESTED = "Info Requested";
export const DOCUMENT_REQUESTED = "Document Requested";
export const DOCUMENTS_REQUESTED = "Documents Requested";

//COA
export const STANDARD = "STANDARD";

// Date Filter
export const datePeriod = [
  { label: "Last 30 days", value: "last30days" },
  { label: "Last 90 days", value: "last90days" },
  { label: "Last month", value: "lastmonth" },
  { label: "Last 3 months", value: "last3months" },
  { label: `Current year - ${dayjs().get("year")}`, value: "currentYear" },
  {
    label: `Last year - ${dayjs().subtract(1, "year").get("year")}`,
    value: "lastYear",
  },
];

// Bookkeeping Tracker
export const IN_PROGRESS = "IN_PROGRESS";
export const COMPLETED = "COMPLETED";

export const SERVICE_TEAM_LABEL: { [key: string]: string } = {
  INKLE_TEAM: "Inkle",
  CPA_TEAM: "Tranquility",
};
