import { number, object, string } from "yup";

export const BalancingPaymentSchema = () =>
  object({
    amount: number().required("Please enter Amount"),
    scopeOfWork: string()
      .min(1)
      .required("Reason for balancing payment is required"),
  });

export const editPriceAndDescriptionSchema = () =>
  object({
    amount: number().required("Please enter Amount"),
    scopeOfWork: string().min(1).required("Scope of work is required"),
  });
