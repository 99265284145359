import { axiosInstance } from "./apiInstance";

export const createGroup = async ({ name }) => {
  const response = axiosInstance.post("/api/inkle/company/group/", {
    group_name: name,
  });

  return response.data;
};

export const getGroupUsers = async ({ groupId }) => {
  const response = await axiosInstance.get(
    `/api/inkle/company/group/${groupId}/members/`
  );
  return response.data;
};
