export const InkleIntragroup = () => (
  <svg
    width="227"
    height="56"
    viewBox="0 0 227 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.9999 18H30.0909V31.9535L21.9092 38H25.5455L35.9999 31.9535V18Z"
      fill="url(#paint0_linear_5723_7915)"
    />
    <path
      d="M15.9996 38L21.9086 38L21.9086 24.0465L30.0903 18L26.454 18L15.9996 24.0465L15.9996 38Z"
      fill="url(#paint1_linear_5723_7915)"
    />
    <path
      d="M46 19.9835C46 18.78 46.78 18 48.0058 18C49.2092 18 49.9893 18.78 49.9893 19.9835C49.9893 21.187 49.2092 21.9893 48.0058 21.9893C46.78 21.9893 46 21.187 46 19.9835ZM49.7887 37.0995H46.2229V23.7276H49.7887V37.0995Z"
      fill="url(#paint2_linear_5723_7915)"
    />
    <path
      d="M60.7289 30.3912C60.7289 28.43 59.7037 27.1374 58.1214 27.1374C56.6282 27.1374 55.5362 28.5192 55.5362 30.4135V37.0995H51.9704V23.7276H55.5362V25.2877C56.0042 24.2848 57.386 23.5716 58.7677 23.5716C62.1775 23.5716 64.2947 26.2905 64.2947 30.3912V37.0995H60.7289V30.3912Z"
      fill="url(#paint3_linear_5723_7915)"
    />
    <path
      d="M70.0119 37.0995H66.4461V18.156H70.0119V29.7227L74.536 23.7276H78.4362L73.8897 30.4135L78.9042 37.0995H74.38L70.0119 31.1044V37.0995Z"
      fill="url(#paint4_linear_5723_7915)"
    />
    <path
      d="M83.8049 37.0995H80.2391V18.156H83.8049V37.0995Z"
      fill="url(#paint5_linear_5723_7915)"
    />
    <path
      d="M94.6461 32.9988H98.4348C97.5211 35.4948 95.1364 37.2109 92.4175 37.2109C88.5174 37.2109 85.7761 34.3805 85.7761 30.369C85.7761 26.4911 88.6065 23.5493 92.3506 23.5493C96.0948 23.5493 98.9251 26.4911 98.9251 30.369C98.9251 30.7924 98.9028 31.2381 98.836 31.6393H89.5203C89.9214 32.9988 91.0135 33.8679 92.3952 33.8679C93.4427 33.8679 94.2673 33.5113 94.6461 32.9988ZM89.5203 29.0763H95.181C94.7799 27.7614 93.7324 26.9146 92.3506 26.9146C90.9689 26.9146 89.9214 27.7614 89.5203 29.0763Z"
      fill="url(#paint6_linear_5723_7915)"
    />
    <path
      d="M201.169 24.9333V27.2133C201.699 25.7883 203.449 24.8112 205.281 24.8112C208.783 24.8112 211.103 27.2947 211.103 31.0607C211.103 34.8063 208.803 37.2899 205.281 37.2899C203.47 37.2899 201.719 36.3127 201.169 34.8674V42.2366H198.971V24.9333H201.169ZM208.925 31.0607C208.925 28.6179 207.317 26.9893 205.017 26.9893C202.737 26.9893 201.149 28.6179 201.169 31.0403C201.169 33.4424 202.778 35.0913 205.037 35.0913C207.337 35.0913 208.925 33.4831 208.925 31.0607Z"
      fill="#41395C"
    />
    <path
      d="M188.564 31.0611C188.564 33.5243 189.826 35.0918 191.78 35.0918C193.592 35.0918 194.895 33.4022 194.895 31.0408V24.9337H197.093V37.1478H194.895V34.8678C194.426 36.2725 193.042 37.2903 191.414 37.2903C188.319 37.2903 186.365 34.8271 186.365 31.0611V24.9337H188.564V31.0611Z"
      fill="#41395C"
    />
    <path
      d="M172.779 31.0206C172.779 27.4989 175.384 24.7914 178.764 24.7914C182.123 24.7914 184.708 27.4989 184.708 31.0206C184.708 34.5627 182.123 37.2701 178.764 37.2701C175.384 37.2701 172.779 34.5627 172.779 31.0206ZM178.764 26.9696C176.626 26.9696 174.977 28.7203 174.977 31.0206C174.977 33.3413 176.626 35.0716 178.764 35.0716C180.901 35.0716 182.53 33.3413 182.53 31.0206C182.53 28.7203 180.901 26.9696 178.764 26.9696Z"
      fill="#41395C"
    />
    <path
      d="M171.819 24.7914V26.9696C168.806 26.9492 167.076 28.5167 167.076 31.2649V37.148H164.898V24.9339H167.076V27.4378C168.053 25.3614 170.129 24.7914 171.819 24.7914Z"
      fill="#41395C"
    />
    <path
      d="M160.73 36.4966V34.868C160.18 36.293 158.43 37.2701 156.679 37.2701C153.28 37.2701 150.898 34.7866 150.898 31.0206C150.898 27.275 153.28 24.7914 156.679 24.7914C158.45 24.7914 160.201 25.8296 160.73 27.2139V24.9339H162.908V36.4966C162.908 40.2626 160.588 42.7461 157.147 42.7461C154.318 42.7461 152.119 41.1176 151.447 38.1862H153.748C154.318 39.774 155.641 40.5679 157.147 40.5679C159.264 40.5679 160.73 38.9597 160.73 36.4966ZM153.096 31.0206C153.096 33.4634 154.745 35.0716 156.923 35.0716C159.122 35.0716 160.73 33.4634 160.73 31.041C160.73 28.6999 159.122 26.9696 156.923 26.9696C154.745 26.9696 153.096 28.5778 153.096 31.0206Z"
      fill="#41395C"
    />
    <path
      d="M146.659 37.148V34.868C146.109 36.293 144.358 37.2701 142.526 37.2701C139.025 37.2701 136.704 34.7866 136.704 31.0206C136.704 27.275 139.005 24.7914 142.526 24.7914C144.338 24.7914 146.109 25.7686 146.659 27.2139V24.9339H148.837V37.148H146.659ZM138.903 31.0206C138.903 33.4634 140.491 35.0716 142.791 35.0716C145.071 35.0716 146.659 33.4634 146.659 31.041C146.659 28.6389 145.03 26.9696 142.771 26.9696C140.47 26.9696 138.903 28.5778 138.903 31.0206Z"
      fill="#41395C"
    />
    <path
      d="M135.663 24.7914V26.9696C132.65 26.9492 130.92 28.5167 130.92 31.2649V37.148H128.742V24.9339H130.92V27.4378C131.897 25.3614 133.973 24.7914 135.663 24.7914Z"
      fill="#41395C"
    />
    <path
      d="M122.401 22.3482H124.05V24.9335H126.92V27.0099H124.05V32.9134C124.05 34.3587 124.64 34.9898 126.024 34.9898H126.92V37.1476H125.821C123.032 37.1476 121.851 35.9669 121.851 33.0355V27.0099H119.958V24.9335H121.851L122.401 22.3482Z"
      fill="#41395C"
    />
    <path
      d="M116.061 31.0206C116.061 28.5574 114.798 26.9696 112.844 26.9696C111.032 26.9696 109.73 28.6796 109.73 31.041V37.148H107.531V24.9339H109.73V27.2139C110.198 25.8093 111.582 24.7914 113.211 24.7914C116.305 24.7914 118.259 27.2546 118.259 31.0206V37.148H116.061V31.0206Z"
      fill="#41395C"
    />
    <path d="M105.397 37.148H103.198V19.8447H105.397V37.148Z" fill="#41395C" />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7915"
        x1="21.9092"
        y1="28"
        x2="35.9999"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7915"
        x1="30.0903"
        y1="28"
        x2="15.9996"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7915"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7915"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5723_7915"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5723_7915"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5723_7915"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
