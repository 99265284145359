import { stringify } from "qs";
import { emptyApi } from "./emptyApi";

export const metricsApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getMetrics: build.query<
      {
        cash_balance: number;
        burn_rate_end_date: string;
        burn_rate_start_date: string;
        group_burn_rate: number;
        group_gross_burn: number;
        entity_burn_data: {
          burn_rate_end_date: string;
          burn_rate_start_date: string;
          current_balance: number | null;
          gross_burn: number | null;
          burn_rate: number | null;
        }[];
        runway: number;
      },
      {
        groupId: string;
        burn_rate_start_date: string;
        burn_rate_end_date: string;
      }
    >({
      query: ({ groupId, burn_rate_end_date, burn_rate_start_date }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/metrics/${stringify(
            {
              burn_rate_start_date,
              burn_rate_end_date,
            },
            { skipNulls: true, addQueryPrefix: true }
          )}`,
        };
      },
    }),

    getInsights: build.query<
      {
        top_five_merchants: {
          merchant_name: string;
          logo: string | null;
          amount: number;
        }[];
        top_five_categories: {
          category_name: string;
          amount: number;
        }[];
      },
      {
        groupId: string;
        entityId: string;
        start_date: string;
        end_date: string;
      }
    >({
      query: ({ groupId, entityId, end_date, start_date }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/insights/${stringify(
            {
              start_date,
              end_date,
            },
            { skipNulls: true, addQueryPrefix: true }
          )}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetMetricsQuery, useGetInsightsQuery } = metricsApis;
