import { Divider } from "components/design/Divider";
import { DownloadIcon } from "components/icons/Download";
import { Children, FC, MouseEvent, ReactNode } from "react";
import { FileIcon } from "utils/fileTypeIcon";
import { Button } from "components/DesignSystem/Button/Button";

type HeaderProps = {
  status?: ReactNode;
  children: ReactNode;
};

type FileProps = {
  children: ReactNode;
  fileType?: string;
  onFileClick?: () => void;
  onDownload?: () => void;
  isLoading?: boolean;
};

const Root: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <div className="t-rounded t-border-neutral-0 t-flex t-border t-border-solid t-overflow-hidden t-flex-col t-bg-white t-box-content t-w-64">
      {children}
    </div>
  );
};

const Header: FC<HeaderProps> = ({ children, status }) => {
  return (
    <div className="t-border-neutral-0 t-border-b t-border-solid t-w-full t-flex t-items-center t-justify-between t-p-2 t-pl-3 t-gap-2 t-border-0">
      <span className="t-text-subtext t-text-text-100">{children}</span>
      <span className="t-text-body-sm">{status}</span>
    </div>
  );
};

const Body: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="t-p-3 t-flex t-gap-2 t-flex-col">{children}</div>;
};

const File: FC<FileProps> = ({
  children,
  fileType,
  onFileClick,
  onDownload,
  isLoading,
}) => {
  const onDownloadClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDownload?.();
  };

  return (
    <button
      className="all:unset t-flex t-justify-between t-items-center t-cursor-pointer t-select-none"
      onClick={onFileClick}
      disabled={isLoading}
    >
      <div className="t-flex t-gap-2 t-text-subtext-sm t-items-center t-w-64">
        <FileIcon fileType={fileType} width="24px" height="24px" />
        <div className="t-text-ellipsis t-whitespace-nowrap t-overflow-x-hidden">
          {children}
        </div>
      </div>
      <Button
        customType="ghost_icon"
        size="small"
        onClick={onDownloadClick}
        disabled={isLoading}
        isLoading={isLoading}
      >
        <span className="t-text-text-30">
          <DownloadIcon color="currentColor" strokeWidth="1.5" />
        </span>
      </Button>
    </button>
  );
};

const Description: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <div className="t-text-body-sm t-text-text-30">{children}</div>;
};

const Footer: FC<{ children: ReactNode }> = ({ children }) => {
  const childrenCount = Children.count(children);

  const childrens = Children.map(children, (child, index) => (
    <>
      {child}
      {index !== childrenCount - 1 && (
        <div className="t-rotate-90 t-w-[40px]">
          <Divider />
        </div>
      )}
    </>
  ));

  return (
    <div className="t-border-neutral-0 t-border-t t-border-solid t-border-0 t-w-full t-flex t-items-center t-justify-evenly">
      {childrens}
    </div>
  );
};

const ChatCard = { Root, Header, Footer, Body, File, Description };

export default ChatCard;
