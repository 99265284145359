import { Countries, CrmCountries } from "types/Models/countries";
import { CrmProfileResponse } from "types/Models/profile";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getCrmUser: build.query<CrmProfileResponse, { crmAccessToken: string }>({
      query: ({ crmAccessToken }) => {
        return {
          url: `/api/inkle/users/verify-user-from-crm/?payload=${crmAccessToken}`,
        };
      },
    }),

    getCountriesData: build.query<Countries[], void>({
      query: () => "api/inkle/company/countries-data/",
    }),

    createGroupAndEntities: build.mutation({
      query: ({ payload }) => {
        return {
          url: "/api/inkle/company/group/entities/",
          method: "POST",
          body: payload,
        };
      },
    }),

    createSeasonSummary: build.mutation({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/season_summary`,
          method: "POST",
          body: payload,
        };
      },
    }),

    onBoardingUpdateSeasonSummary: build.mutation({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/onboarding/season_summary/`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    getCrmCountriesData: build.query<CrmCountries[], void>({
      query: () => "api/inkle/serviceteams/foreign_service-providers/countries",
    }),
  }),
});

export const {
  useGetCrmUserQuery,
  useGetCountriesDataQuery,
  useCreateGroupAndEntitiesMutation,
  useCreateSeasonSummaryMutation,
  useOnBoardingUpdateSeasonSummaryMutation,
  useGetCrmCountriesDataQuery,
} = extendedApi;
