export const BellIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_569_9522)">
        <path
          d="M12 2.45459C10.4219 2.45459 8.90858 3.08158 7.79314 4.19701C6.67682 5.31333 6.04984 6.82663 6.04984 8.40475C6.04984 11.2537 5.44061 13.0237 4.87845 14.0539C4.59693 14.5707 4.32251 14.91 4.13157 15.1116C4.03566 15.2119 3.96106 15.2794 3.91577 15.3167C3.89268 15.3354 3.87758 15.3469 3.87136 15.3522L3.8687 15.354C3.56409 15.5627 3.4291 15.9464 3.53656 16.3007C3.6449 16.6595 3.97527 16.9046 4.35004 16.9046H19.65C20.0247 16.9046 20.3551 16.6595 20.4634 16.3007C20.5709 15.9464 20.4359 15.5636 20.1313 15.354L20.1286 15.3522C20.1224 15.3469 20.1073 15.3354 20.0851 15.3167C20.0398 15.2794 19.9643 15.2119 19.8684 15.1116C19.6784 14.91 19.4031 14.5707 19.1215 14.0539C18.5594 13.0237 17.9502 11.2546 17.9502 8.40475C17.9502 6.82663 17.3232 5.31333 16.2077 4.19701C15.0914 3.08158 13.5781 2.45459 12 2.45459ZM17.6287 14.8673C17.6935 14.9863 17.7583 15.0982 17.8232 15.2048L6.17683 15.2039C6.24166 15.0982 6.30649 14.9863 6.37132 14.8673C7.08445 13.5601 7.75052 11.5042 7.75052 8.40475C7.75052 7.27689 8.19811 6.19609 8.99472 5.39948C9.79222 4.60198 10.873 4.15438 12 4.15438C13.127 4.15438 14.2087 4.60198 15.0053 5.39948C15.8028 6.19609 16.2504 7.27689 16.2504 8.40475C16.2504 11.5051 16.9155 13.5601 17.6287 14.8673Z"
          fill="currentColor"
        />
        <path
          d="M11.2318 19.4106C10.9977 19.0037 10.478 18.8636 10.0711 19.0976C9.66422 19.3317 9.52414 19.8513 9.75817 20.2583C9.98092 20.6455 10.3015 20.9674 10.6878 21.1918C11.0741 21.4162 11.5125 21.5352 11.9593 21.5368C12.4061 21.5384 12.8453 21.4226 13.2332 21.2011C13.6211 20.9795 13.9441 20.66 14.1696 20.2744C14.4066 19.8691 14.2703 19.3485 13.8652 19.1115C13.4599 18.8745 12.9392 19.0107 12.7022 19.4159C12.6271 19.5445 12.5194 19.651 12.3901 19.7249C12.2608 19.7988 12.1144 19.8373 11.9655 19.8368C11.8165 19.8362 11.6704 19.7966 11.5416 19.7218C11.4129 19.647 11.306 19.5397 11.2318 19.4106Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_569_9522">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
