export const checkValidEmail = (email) => {
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  if (validEmailRegex.test(email)) return true;
  else return false;
};

export const checkValidPhone = (phone) => {
  const validPhoneRegex = RegExp(
    /^\s*(?:\+?(\d{1,3}))?[-.(]*(\d{3})[-.)]*(\d{3})[-.]*(\d{4})(?: *x(\d+))?\s*$/i
  );
  if (validPhoneRegex.test(phone)) return true;
  else return false;
};

export const checkValidPhoneInput = (mobile, country, setAction) => {
  let validPhoneLength = country?.format
    ?.split("")
    ?.filter((el) => el === ".")?.length;
  if (mobile?.length === validPhoneLength) setAction(false);
  else setAction(true);
};
