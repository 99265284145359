import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { PriceInput } from "components/PriceInput/PriceInput";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { openingBalanceEditSchema } from "formValidations/openingBalanceEditSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEditBankAccountMutation } from "store/apis/bankConnections";
import { Connection, ConnectionBankAccount } from "store/apis/booksConnections";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { onEnterKey } from "utils/onEnterKey";

export const EditOpeningBalance = ({
  open,
  onClose,
  connection,
  bankAccount,
}: {
  open: boolean;
  onClose(): void;
  connection: Connection;
  bankAccount: ConnectionBankAccount;
}) => {
  const [editBankAccount, { isLoading }] = useEditBankAccountMutation();
  const entityId = useCurrentEntityId();
  const { uuid } = useCurrentGroupContext();
  const { successToast, alertToast } = useToast();

  const onSubmit = async (values: {
    opening_balance: number;
    opening_balance_date: string;
  }) => {
    try {
      await editBankAccount({
        groupId: uuid,
        entityId,
        bankId: connection.uuid,
        bankAccountId: bankAccount.uuid,
        payload: {
          opening_balance: String(values.opening_balance),
          opening_balance_date: dayjs(values.opening_balance_date).format(
            YYYY_MM_DD
          ),
        },
      }).unwrap();
      onClose();
      successToast({ message: "Opening balance updated successfully" });
    } catch (error: any) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root modal={false} open={open} onOpenChange={onClose}>
      <Formik
        validateOnChange={false}
        validationSchema={openingBalanceEditSchema}
        initialValues={{
          opening_balance: Number(bankAccount.opening_balance) || 0,
          opening_balance_date: bankAccount.opening_balance_date
            ? formatDate(bankAccount.opening_balance_date)
            : "",
        }}
        onSubmit={onSubmit}
      >
        {({ submitForm }) => (
          <Modal.Content useCustomOverlay>
            <Modal.Header>
              <Modal.Title>Edit opening balance</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Form className="t-m-0" onKeyDown={onEnterKey(submitForm)}>
              <Modal.Body>
                <div className="t-flex t-flex-col t-gap-4">
                  <PriceInput
                    required
                    label="Opening balance"
                    name="opening_balance"
                  />
                  <Field name="opening_balance_date">
                    {({ field }: FieldProps) => {
                      return (
                        <div>
                          <DateInput
                            {...field}
                            portalId="opening_balance_date"
                            required
                            name="opening_balance_date"
                            label="Date of opening balance"
                            block
                          />
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button>Cancel</Button>

                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  customType="primary"
                >
                  Update
                </Button>
              </Modal.FooterButtonGroup>
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};
