import classNames from "classnames";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetTransaction } from "hooks/useGetTransaction";
import { useToast } from "hooks/useToast";
import React from "react";
import { useSelector } from "react-redux";
import { useUpdateTransactionMutation } from "store/apis/transactions";
import { RootState } from "store/store";
import { Transaction } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";

export const Memo = ({ transactionId }: { transactionId: string }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const [addMemo, { isLoading: isUpdating }] = useUpdateTransactionMutation();
  const { data: transactionDetail } = useGetTransaction({
    transactionId,
  });

  const {
    transaction: {
      memo,
      category,
      linked_transaction = "",
    } = {} as Transaction,
  } = transactionDetail || {};

  const { uuid: transaction_category_uuid = "" } = category || {};

  const onChange = debounce(async (e: React.ChangeEvent<HTMLFormElement>) => {
    const { value } = e.target;

    try {
      await addMemo({
        groupId,
        entityId,
        previewTxnId: transactionId,
        payload: {
          memo: value,
          linked_transaction_uuid: linked_transaction,
          transaction_category_uuid,
        },
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  }, 1000);

  return (
    <Formik initialValues={{ memo: memo || "" }} onSubmit={() => {}}>
      <Form className="t-m-0 t-w-full" onChange={onChange}>
        <div className="t-flex t-items-center t-gap-1.5">
          <TextArea
            name="memo"
            placeholder="Add your memo here"
            label="Memo"
            block
            rows={3}
          />
          <span
            className={classNames("t-flex t-animate-spin", {
              "t-invisible": !isUpdating,
              "t-block": isUpdating,
            })}
          >
            <LoadingIcon />
          </span>
        </div>
      </Form>
    </Formik>
  );
};
