import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React from "react";
import { useDeleteShareholderMutation } from "store/apis/Shareholders";
import { BackendError } from "types/utils/error";

const ShareHolderDelete = ({
  isOpen,
  close,
  shareholderId,
}: {
  isOpen: boolean;
  close: () => void;
  shareholderId: string;
}) => {
  const [deleteShareholder, { isLoading }] = useDeleteShareholderMutation();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();

  const handleClick = async () => {
    try {
      await deleteShareholder({ groupId, shareholderId }).unwrap();
      close();
      successToast({ message: "Shareholder deleted successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Delete Shareholder</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Are you sure you want to delete this shareholder? This action can
            not be undone.
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <Button
            customType={"danger"}
            onClick={handleClick}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ShareHolderDelete;
