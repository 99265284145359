import qs from "qs";
import { emptyApi } from "./emptyApi";
import { JournalEntry } from "types/Models/journalEntry";
import { Pagination } from "types/Models/pagination";

const journalEntries = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllJournalEntries: build.query<
      { journal_entries: JournalEntry[] } & Pagination,
      {
        groupId: string;
        entityId: string;
        pageNum?: number;
        searchTerm?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        sortCol?: "AMOUNT" | "DATE";
        sortOrder?: "ASC" | "DSC";
      }
    >({
      query: ({
        groupId,
        entityId,
        pageNum,
        searchTerm,
        startDate,
        endDate,
        sortCol,
        sortOrder,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
            start_date: startDate,
            end_date: endDate,
            sort_col: sortCol,
            sort_order: sortOrder,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger-entries/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["JOURNAL_ENTRIES"] : []),
    }),

    deleteJournalEntry: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        ledgerId: string;
        event_update_type: string;
      }
    >({
      query: ({ groupId, entityId, ledgerId, event_update_type }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger-entry/${ledgerId}/`,
          method: "DELETE",
          params: {
            event_update_type: event_update_type,
          },
        };
      },
      invalidatesTags: ["JOURNAL_ENTRIES"],
    }),
  }),
});

export const { useGetAllJournalEntriesQuery, useDeleteJournalEntryMutation } =
  journalEntries;
