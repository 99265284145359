import React from "react";

export const Inkle = () => {
  return (
    <svg
      width="53"
      height="20"
      viewBox="0 0 53 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-0.000244141 2.08895C-0.000244141 0.885488 0.779775 0.105469 2.00552 0.105469C3.20898 0.105469 3.989 0.885488 3.989 2.08895C3.989 3.2924 3.20898 4.09471 2.00552 4.09471C0.779775 4.09471 -0.000244141 3.2924 -0.000244141 2.08895ZM3.78842 19.2048H0.222619V5.83304H3.78842V19.2048Z"
        fill="url(#paint0_linear_2609_127608)"
      />
      <path
        d="M14.7286 12.4966C14.7286 10.5354 13.7034 9.24284 12.1211 9.24284C10.6279 9.24284 9.53589 10.6246 9.53589 12.5189V19.2048H5.97008V5.83304H9.53589V7.39308C10.0039 6.3902 11.3856 5.67704 12.7674 5.67704C16.1772 5.67704 18.2944 8.39596 18.2944 12.4966V19.2048H14.7286V12.4966Z"
        fill="url(#paint1_linear_2609_127608)"
      />
      <path
        d="M24.0115 19.2048H20.4457V0.261472H24.0115V11.828L28.5356 5.83304H32.4357L27.8893 12.5189L32.9037 19.2048H28.3796L24.0115 13.2098V19.2048Z"
        fill="url(#paint2_linear_2609_127608)"
      />
      <path
        d="M37.8044 19.2048H34.2386V0.261472H37.8044V19.2048Z"
        fill="url(#paint3_linear_2609_127608)"
      />
      <path
        d="M48.6456 15.1041H52.4343C51.5205 17.6002 49.1359 19.3162 46.417 19.3162C42.5169 19.3162 39.7757 16.4859 39.7757 12.4743C39.7757 8.59654 42.606 5.65475 46.3501 5.65475C50.0942 5.65475 52.9245 8.59654 52.9245 12.4743C52.9245 12.8978 52.9023 13.3435 52.8354 13.7447H43.5197C43.9209 15.1041 45.0129 15.9733 46.3947 15.9733C47.4421 15.9733 48.2667 15.6167 48.6456 15.1041ZM43.5197 11.1817H49.1805C48.7793 9.86685 47.7318 9.01998 46.3501 9.01998C44.9684 9.01998 43.9209 9.86685 43.5197 11.1817Z"
        fill="url(#paint4_linear_2609_127608)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2609_127608"
          x1="-0.000244141"
          y1="9.71085"
          x2="52.9245"
          y2="9.71085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B08FD" />
          <stop offset="1" stopColor="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2609_127608"
          x1="-0.000244141"
          y1="9.71085"
          x2="52.9245"
          y2="9.71085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B08FD" />
          <stop offset="1" stopColor="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2609_127608"
          x1="-0.000244141"
          y1="9.71085"
          x2="52.9245"
          y2="9.71085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B08FD" />
          <stop offset="1" stopColor="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2609_127608"
          x1="-0.000244141"
          y1="9.71085"
          x2="52.9245"
          y2="9.71085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B08FD" />
          <stop offset="1" stopColor="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2609_127608"
          x1="-0.000244141"
          y1="9.71085"
          x2="52.9245"
          y2="9.71085"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B08FD" />
          <stop offset="1" stopColor="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
