import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useSelector, useDispatch } from "react-redux";
import {
  CellContext,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "react-table-8.10.7";
import {
  DocumentRequest,
  useUploadRequestedDocumentMutation,
  useDeleteRequestedDocumentMutation,
  useGetDocumentRequestQuery,
} from "store/apis/requestDocument";
import {
  openRequestDocumentsModal,
  toggleDocumentRequestEdit,
} from "store/slices/requestDocumentsInChat";
import { RootState } from "store/store";
import { ArrayElement } from "types/utils/ArrayElement";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { Preview } from "components/PreviewModal";
import { useModal } from "hooks/useModal";

const UploadCell = (
  info: CellContext<ArrayElement<DocumentRequest["requested_documents"]>, void>
) => {
  const request = info.row.original;
  const { successToast, alertToast } = useToast();

  const groupId = useSelector(
    (state: RootState) => state.requestDocumentsInChat.groupId
  );

  const [uploadDocumentRequest, { isLoading: uploading }] =
    useUploadRequestedDocumentMutation();
  const [deleteRequestedDocument, { isLoading: deleting }] =
    useDeleteRequestedDocumentMutation();

  const onUpload = async (files: FileType[]) => {
    try {
      if (groupId) {
        await uploadDocumentRequest({
          groupId: groupId,
          file: files[0] as File,
          requestedDocumentId: request.uuid,
        }).unwrap();
      }
      successToast({ message: "File has been uploaded!" });
    } catch (error) {
      alertToast({ message: "Failed to upload, please try again!" });
    }
  };

  const onDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    try {
      if (groupId) {
        await deleteRequestedDocument({
          groupId: groupId,
          requestedDocumentId: request.uuid,
        }).unwrap();
      }
      successToast({ message: "File has been deleted!" });
    } catch (error) {
      alertToast({ message: "Failed to delete, please try again!" });
    }
  };

  const { open, close, isOpen } = useModal();

  return (
    <>
      <FileInput
        onFileClick={open}
        file={request?.document}
        onDelete={onDelete}
        onDrop={onUpload}
        isUploading={uploading}
        isDeleting={deleting}
      />
      <Preview
        showModal={isOpen}
        closeModal={close}
        previewId={request?.document?.uuid}
      />
    </>
  );
};

const UploadRequestedDocuments = () => {
  const groupId = useSelector(
    (state: RootState) => state.requestDocumentsInChat.groupId
  );
  const requestId = useSelector(
    (state: RootState) => state.requestDocumentsInChat.requestId
  );

  const { data: documentsRequest, isLoading } = useGetDocumentRequestQuery(
    {
      groupId: groupId!,
      requestId: requestId!,
    },
    { skip: !groupId || !requestId, refetchOnMountOrArgChange: true }
  );

  const columnHelper =
    createColumnHelper<ArrayElement<DocumentRequest["requested_documents"]>>();

  const columns = [
    columnHelper.accessor("name", {
      header: "Document Name",
      size: 25,
    }),
    columnHelper.accessor("description", {
      header: "Description",
      size: 40,
    }),
    columnHelper.display({
      header: "Upload document",
      size: 35,
      cell: UploadCell,
    }),
  ];

  const table = useReactTable({
    data: documentsRequest?.requested_documents || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 5,
      maxSize: 100,
    },
  });

  if (isLoading) {
    return (
      <div className="t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <Table.Container>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{
                    width: `${header.getSize()}%`,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell
                  key={cell.id}
                  style={{
                    width: `${cell.column.getSize()}%`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const UploadMode = () => {
  const isOpen = useSelector(
    (state: RootState) => state.requestDocumentsInChat.isModalOpen
  );
  const { isCpa } = useRoleBasedView();

  const dispatch = useDispatch();

  const closeModal = () =>
    dispatch(
      openRequestDocumentsModal({ isModalOpen: false, channelId: null })
    );

  const onEditClick = () => dispatch(toggleDocumentRequestEdit(true));

  return (
    <Modal.Root open={isOpen} onOpenChange={closeModal}>
      <Modal.Content size="xxl">
        <Modal.Header>
          <Modal.Title>Request Documents</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <UploadRequestedDocuments />
        </Modal.Body>
        {isCpa && (
          <Modal.Footer>
            <div className="t-flex t-justify-start t-gap-2">
              <Button type="button" onClick={onEditClick}>
                Edit
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
