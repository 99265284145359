import React from "react";

export const GradientArrow = () => {
  return (
    <svg
      width="28"
      height="17"
      viewBox="0 0 28 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2609_127603)">
        <path
          d="M3 8.5L25 8.5M25 8.5L20.5 13M25 8.5L20.5 4"
          stroke="url(#paint0_linear_2609_127603)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2609_127603"
          x1="2.00049"
          y1="8.39453"
          x2="27.0005"
          y2="8.39453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#635BFF" />
          <stop offset="1" stopColor="#8A09FE" />
        </linearGradient>
        <clipPath id="clip0_2609_127603">
          <rect
            width="16"
            height="28"
            fill="white"
            transform="translate(-0.000244141 16.6055) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
