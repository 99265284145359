import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

// styles
import "static/styles/components/help.css";

// images
import CopyIcon from "static/images/CopyPurple.svg";
import FormIcon from "static/images/Form.svg";
import HelpChatIcon from "static/images/HelpChat.svg";
import HelpMeetingIcon from "static/images/HelpMeetingRequest.svg";
import WhatsappIcon from "static/images/HelpWhatsapp.svg";
import MailIcon from "static/images/Mail.svg";
import PhoneIcon from "static/images/PhoneIcon.svg";
import WhatsNewIcon from "static/images/WhatsNew.svg";
import WhiteBack from "static/images/WhiteArrowLeft.svg";
import CloseIcon from "static/images/WhiteCloseIcon.svg";

// constants
import {
  CHAT,
  CONTACT_US,
  DEFAULT_TITLE,
  EMAIL,
  MEETING_REQUEST,
  PHONE,
  REDIRECT,
  SAME_MODAL,
  SEE_WHATS_NEW,
  WHATSAPP,
  WHATSNEW,
} from "../../constants/help";

// components
import CopyCode from "../design/copyCode";
import WhatsNew from "./whatsNew";
import WhatsNewHeader from "./whatsNewHeader";

// utils
import { ANAND_PHONE } from "constants/meetingRequestUrls";
import { checkExternalRoute } from "../../utils/checkExternalRoute";
import { openLink } from "../../utils/openLink";

const Help = ({
  closeModal,
  show,
  loggedOut,
}: {
  closeModal: () => void;
  show?: boolean;
  loggedOut: boolean;
}) => {
  const history = useHistory();
  const [title, setTitle] = useState(DEFAULT_TITLE);
  const [isExtra, setExtra] = useState(false);
  const [extraComponent, setExtraComponent] = useState(<></>);
  const [extraHeader, setExtraHeader] = useState(<></>);

  useEffect(() => {
    setExtra(false);
  }, [show]);

  const chatOption = loggedOut
    ? [
        {
          icon: FormIcon,
          title: CONTACT_US,
          content: "Submit a query using our contact form",
          alt: "FormIcon",
          action: REDIRECT,
          actionValue: "https://www.inkle.io/company/contact",
        },
        {
          icon: MailIcon,
          title: EMAIL,
          content: "Email your queries at hello@inkle.io",
          alt: "MailIcon",
          action: REDIRECT,
          actionValue: "mailto:hello@inkle.io",
        },
        {
          icon: PhoneIcon,
          title: "Call us",
          content: ANAND_PHONE,
          alt: "Call us",
          action: REDIRECT,
          actionValue: `tel:${ANAND_PHONE}`,
        },
      ]
    : [
        {
          icon: WhatsNewIcon,
          title: WHATSNEW,
          content: "See all the new product updates",
          alt: "WhatsNew",
          action: SAME_MODAL,
          actionValue: "",
        },
        {
          icon: HelpChatIcon,
          title: CHAT,
          content: "Send us your query on chat",
          alt: "HelpChatIcon",
          action: REDIRECT,
          actionValue: "/chat",
        },
        {
          icon: HelpMeetingIcon,
          title: MEETING_REQUEST,
          content: "Discuss your query on a meet",
          alt: "HelpMeetingIcon ",
          action: REDIRECT,
          actionValue: "/meetingrequest",
        },
      ];

  const topics = useMemo(
    () => [
      ...chatOption,
      {
        icon: WhatsappIcon,
        title: WHATSAPP,
        content: "Connect via WhatsApp",
        alt: "Whatsapp Icon ",
        action: REDIRECT,
        actionValue: `https://wa.me/${PHONE}`,
      },
    ],
    []
  );

  const handleClick = (action: string, actionValue: string) => {
    if (action === REDIRECT) {
      if (checkExternalRoute(actionValue)) openLink(actionValue);
      else history.push(actionValue);
      closeModal();
    } else {
      setExtra(true);
      setTitle(SEE_WHATS_NEW);
      setExtraHeader(<WhatsNewHeader />);
      setExtraComponent(<WhatsNew />);
    }
  };

  const setDefault = () => {
    setExtra(false);
    setTitle(DEFAULT_TITLE);
    setExtraHeader(<></>);
    setExtraComponent(<></>);
  };

  const closeExtra = () => {
    setDefault();
    setExtra(false);
  };

  const closeHelp = () => {
    setDefault();
    closeModal();
  };

  const getCopyText = () => PHONE;

  return (
    <div className="helpContainer">
      <div className="helpHeader">
        {isExtra && (
          <img
            onClick={closeExtra}
            src={WhiteBack}
            className="backBtn"
            alt="back btn"
          />
        )}
        <span className="headerTitle">{title}</span>
        <img
          className="crossBtn"
          onClick={closeHelp}
          src={CloseIcon}
          alt="close"
        />
      </div>
      {isExtra && extraHeader}
      {isExtra ? (
        extraComponent
      ) : (
        <div className="helpTopicsContainer">
          {!loggedOut && <p className="helpTopicsTitle">Topics</p>}
          {topics?.map(({ icon, title, content, alt, action, actionValue }) => {
            return (
              <div className="helpTopicCards" key={title}>
                {(title === "Chat" || title === CONTACT_US) && (
                  <p className="helpTopicsTitle contact ">Contact us</p>
                )}
                <div
                  className="helpTopicCard t-cursor-pointer"
                  onClick={() => handleClick(action, actionValue)}
                >
                  <img className="cardIcon" src={icon} alt={alt} />
                  <div className="cardContent">
                    <span className="cardTitle">
                      {title}
                      {title === WHATSAPP && (
                        <span onClick={(e) => e.stopPropagation()}>
                          <CopyCode
                            getCopyText={getCopyText}
                            codeCopiedText="Number Copied"
                            copyIcon={CopyIcon}
                          />
                        </span>
                      )}
                    </span>
                    <span className="content">{content}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Help;
