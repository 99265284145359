import { Input } from "components/design/input";
import { Form, Formik } from "formik";
import { FileObject } from "types/Models/fileObject";
import { W_FORM_TYPES } from "types/Models/merchant";

import { Button } from "./DesignSystem/Button/Button";
import { FileInput } from "./FileInput/FileInput";

type WSeriesUploadFormProps = {
  file?: Pick<FileObject, "name" | "file_type" | "is_previewable" | "uuid">;
  openPreview: () => void;
  onSubmit: (values: { form_type: W_FORM_TYPES; file?: File }) => void;
  form_document?: FileObject;
  w_form_choice: W_FORM_TYPES;
  onDelete: () => void;
  isDeleting: boolean;
};

export const WSeriesUploadForm = ({
  form_document,
  w_form_choice,
  file,
  openPreview,
  onSubmit,
  onDelete,
  isDeleting,
}: WSeriesUploadFormProps) => {
  return (
    <Formik
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        form_type: w_form_choice || "W-8BEN-E",
        file: file ? new File([], file.name) : undefined,
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="w-100 m-0 d-flex flex-column gap-3">
          <div>
            <div className="w-series-form-modal-input-label mb-2 after:t-font-bold after:t-text-red after:t-content-['_*']">
              Select the form you're trying to upload
            </div>
            <div className="d-flex w-100 align-items-end gap-4">
              <div
                className="d-flex gap-3 w-100"
                role="group"
                aria-labelledby="form-selector-radio-group"
              >
                <Input
                  block
                  label={
                    <div className="w-series-form-modal-input-label">
                      W-8 BEN-E
                    </div>
                  }
                  type="radio"
                  value="W-8BEN-E"
                  name="form_type"
                  required
                  disabled={Boolean(form_document)}
                />
                <Input
                  block
                  label={
                    <div className="w-series-form-modal-input-label">
                      W-8 BEN
                    </div>
                  }
                  value="W-8BEN"
                  type="radio"
                  name="form_type"
                  required
                  disabled={Boolean(form_document)}
                />
                <Input
                  block
                  label={
                    <div className="w-series-form-modal-input-label">W-9</div>
                  }
                  value="W-9"
                  type="radio"
                  name="form_type"
                  required
                  disabled={Boolean(form_document)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex w-100 align-items-end gap-4">
            <div>
              <div className="w-series-form-modal-input-label mb-3">
                Upload W-form
              </div>
              <FileInput
                onFileClick={openPreview}
                onDelete={onDelete}
                name="file"
                withForm
                label="W-form.pdf"
                isDeleting={isDeleting}
                accept={{ "application/pdf": [".pdf"] }}
              />
            </div>
          </div>
          <div className="t-w-full t-flex t-justify-end t-mt-4">
            <Button
              disabled={
                (!Boolean(values["file"]) && !Boolean(form_document)) ||
                values["form_type"] === "NOT_SPECIFIED"
              }
              type="submit"
              isLoading={isSubmitting}
              customType="primary"
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
