import { AFTER_AUTH_REDIRECT_TO } from "constants/storageKeys";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyGetUserProfileQuery } from "store/apis/profile";

export const useRedirectOnAuth = () => {
  const { authtoken, updateUser } = useContext(authContext);
  const history = useHistory();
  const [getProfile] = useLazyGetUserProfileQuery();

  useEffect(() => {
    const checkIfProfileReady = async () => {
      try {
        const profile = await getProfile().unwrap();
        updateUser({ data: { data: profile } });

        if (
          !profile?.is_group_created ||
          (profile?.is_restricted &&
            profile.member &&
            Object.keys(profile.member).length > 0)
        ) {
          setTimeout(() => checkIfProfileReady(), 3000);
        } else {
          history.replace(localStorage.getItem(AFTER_AUTH_REDIRECT_TO) || "/");
        }
      } catch (error) {}
    };

    const checkIfPublicTaskCreated = async () => {
      try {
        const profile = await getProfile().unwrap();

        if (profile?.public_task_id) {
          history.push(`/filings/${profile?.public_task_id}`);
        } else {
          setTimeout(() => checkIfPublicTaskCreated(), 3000);
        }
      } catch (error) {}
    };

    if (authtoken.is_public_user) {
      checkIfPublicTaskCreated();
    } else {
      checkIfProfileReady();
    }
  }, [authtoken.is_public_user]);
};
