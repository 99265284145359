import successAnimation from "components/Connections/SuccessAnimation.json";
import Modal from "components/DesignSystem/Modal/Modal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import Lottie from "lottie-react";
import { useState, useEffect } from "react";
import { useCreateEsignCardMutation } from "store/apis/eSign";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import {
  DefaultStreamChatGenerics,
  useChannelActionContext,
  useChannelStateContext,
} from "stream-chat-react";
import { BackendError } from "types/utils/error";

export const DocuSignModal = () => {
  const { alertToast } = useToast();
  const { updateMultiple } = useUpdateQuery();
  const query = useQuery();
  const envelopeId = query.get("envelopeId");
  const event = query.get("event");
  const messageId = query.get("messageId");
  const isOpen = Boolean(envelopeId) && event === "Send";
  const [open, setOpen] = useState(isOpen);
  const [createEsignCard] = useCreateEsignCardMutation();
  const { channel } = useChannelStateContext();
  const dispatch = useAppDispatch();

  const { jumpToMessage } =
    useChannelActionContext<DefaultStreamChatGenerics>("MessageActionsBox");

  const groupId = channel.data?.group_uuid;

  const createChatCard = async () => {
    try {
      await createEsignCard({
        groupId: groupId as string,
        envelope_id: envelopeId!,
      }).unwrap();
      setOpen(false);
      updateMultiple([
        { query: "envelopeId", value: null },
        { query: "event", value: null },
      ]);
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  useEffect(() => {
    if (messageId && event) {
      dispatch(setScrollToMessageId(messageId));
      jumpToMessage(messageId);
    }

    if (event && event !== "Send") {
      updateMultiple([
        { query: "event", value: null },
        { query: "messageId", value: null },
      ]);
    }
  }, [event, messageId]);

  useEffect(() => {
    if (isOpen && groupId) {
      createChatCard();
    }
  }, [isOpen, groupId]);

  return (
    <Modal.Root open={open}>
      <Modal.Content>
        <Modal.Body className="t-flex t-gap-3 t-flex-col t-justify-center t-w-full t-items-center">
          <Lottie className="t-w-52" animationData={successAnimation} />
          <span className="t-text-subtitle-sm t-text-text-100">
            Document sent successfully
          </span>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
