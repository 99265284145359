import { array, object, string } from "yup";

export const esignDocSchema = () =>
  object({
    groupId: string().required("Group Id is required"),
    channelUrl: string().required("Channel Url is required"),
    signerProfileIds: array()
      .min(1, "Please add at least one signer.")
      .required("Signers are required"),
  });
