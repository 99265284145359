import dayjs from "dayjs";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ConditionalLink } from "components/conditionalLink";
import { Badge } from "components/Task/Badge";
import { formatDate } from "utils/formatDate";
import AddTaskEntityModal from "components/tasks/modal/AddTaskEntityModal";
import { useModal } from "hooks/useModal";
import { Button } from "components/DesignSystem/Button/Button";
import PaymentEngine from "components/fileTax/step2";
import { Task } from "types/Models/task";
import { useDispatch, useSelector } from "react-redux";
import { setShowPricingModal } from "store/slices/task";
import { useGetTaskQuery } from "store/apis/task";
import { useState } from "react";
import { RootState } from "store/store";

type TaskTemplateProp = {
  title: string;
  uuid: string;
  base_task_template_uuid: string;
};

type TaskDataProp = {
  task_id?: string;
  current_state_id?: string;
  current_state_name?: string;
};

const Price = ({
  isFree,
  discountedPrice,
  price,
}: {
  isFree?: boolean;
  discountedPrice?: string;
  price: string;
}) => {
  const isDiscountApplied = price !== discountedPrice;
  const finalPrice = price === "$FREE" ? "FREE" : price;

  if (isFree) {
    return (
      <>
        <span className="t-block t-text-center t-text-subtitle">Free</span>
        <span className="t-block t-w-full t-text-center t-text-body-sm t-text-red-40 t-line-through">
          {finalPrice}
        </span>
      </>
    );
  }

  if (isDiscountApplied) {
    return (
      <>
        <span className="t-block t-text-center t-text-subtitle">
          {discountedPrice}
        </span>
        <span className="t-block t-w-full t-text-center t-text-body-sm t-text-red-40 t-line-through">
          {finalPrice}
        </span>
      </>
    );
  }

  return <span className="t-block t-w-full t-text-center">{finalPrice}</span>;
};

type DeadlineProps = {
  month?: string;
  date?: string;
  title?: string;
  description: string;
  extension?: string;
  price: string;
  dynamicPricing?: boolean;
  cta?: string;
  onViewMore?: () => void | undefined;
  taskTemplate?: TaskTemplateProp | null;
  taskData?: TaskDataProp | null;
  isFree?: boolean;
  discountedPrice?: string;
};

type DeadlineCompProps = {
  price: string;
  date?: string;
  title?: string;
  deadlineStatus?: {
    displayName: string;
    type:
      | "FORM_FILING"
      | "UPLOAD_DOCUMENTS"
      | "PAYMENT"
      | "REVIEW"
      | "FILING_IN_PROGRESS"
      | "SUBMITTED"
      | "TASK_DEFINING";
  };
  description: string;
  extension?: string | undefined;
  plan?: string;
  dynamicPricing?: boolean | undefined;
  finalPrice?: string;
  isFree?: boolean;
  discountedPrice?: string;
  taskId?: string;
  taskTemplateId?: string;
  deadlinePassed: boolean;
};

const DeadlineComponent = ({
  date,
  title,
  deadlineStatus,
  description,
  extension,
  dynamicPricing,
  finalPrice,
  discountedPrice,
  isFree,
  price,
  taskId,
  taskTemplateId,
  deadlinePassed,
}: DeadlineCompProps) => {
  const [taskIdForDetail, setTaskIdForDetail] = useState("");
  const addTaskModal = useModal();
  const dispatch = useDispatch();
  const {
    data: task,
    isLoading,
    refetch,
    isSuccess,
    isError,
    error,
  } = useGetTaskQuery({ taskId: taskIdForDetail! }, { skip: !taskIdForDetail });

  const mainId = !Boolean((task?.state as { uuid: string })?.uuid)
    ? task?.task_states?.[0].uuid
    : (task?.state as { uuid: string })?.uuid;

  const refetchTask = async () => await refetch().unwrap();

  return (
    <div className="t-group t-relative t-flex t-items-start t-gap-3 t-bg-white t-p-4 md:t-items-center md:t-gap-5">
      {date && (
        <>
          <div className="t-hidden t-h-[70px] t-shrink-0 t-grow-0 t-basis-[67px] md:t-block" />
          <AnimatePresence>
            <motion.div
              key={date}
              transition={{ duration: 0.45, delay: -0.1 }}
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -30, opacity: 0 }}
              className={cx(
                "t-absolute t-hidden t-h-[70px] t-w-[67px] t-shrink-0 t-grow-0 t-flex-col t-items-center t-justify-center t-rounded-lg t-bg-neutral-0 md:t-flex"
              )}
            >
              <span className="t-block t-whitespace-nowrap t-text-body t-font-bold">
                {dayjs(date).format("DD MMM")}
              </span>
              <span className="t-text-body-sm t-uppercase">
                {dayjs(date).format("YYYY")}
              </span>
            </motion.div>
          </AnimatePresence>
        </>
      )}

      <div className="t-grow t-overflow-hidden">
        <div className="t-flex md:t-items-center md:t-gap-5 t-justify-between">
          <div className="t-w-2/3 t-flex-col t-gap-1 t-space-y-2 md:t-flex md:t-space-y-0">
            {taskId ? (
              <ConditionalLink
                target="_blank"
                to={`/filings/${taskId}`}
                className={"!t-text-text-60 t-no-underline"}
              >
                <Button customType="link">{title}</Button>
              </ConditionalLink>
            ) : (
              <div>
                <Button
                  disabled={deadlinePassed || !taskTemplateId}
                  customType="link"
                  onClick={() =>
                    taskTemplateId && !deadlinePassed && addTaskModal.open()
                  }
                >
                  {title}
                </Button>
              </div>
            )}

            {date && (
              <div className="t-flex t-items-start t-gap-2">
                <div className="t-inline-flex t-hidden t-rounded t-bg-purple-0 t-px-2 t-py-0.5 t-text-body-sm t-text-purple-50 sm:t-block ">
                  {formatDate(date)}
                </div>

                {deadlineStatus && deadlineStatus?.displayName && (
                  <>
                    <div className="t-hidden t-h-5 t-border-b-0 t-border-l t-border-r-0 t-border-t-0 t-border-solid t-border-neutral-0  sm:t-block" />
                    <div className="t-hidden t-gap-2 t-truncate t-text-body sm:t-block">
                      <Badge
                        type={deadlineStatus?.type}
                        children={deadlineStatus?.displayName}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="t-flex t-items-baseline t-gap-3">
              <p className="t-m-0 t-max-w-[320px] t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-text-body-sm t-tracking-normal md:t-text-body">
                {description}
              </p>

              <Button
                customType="link"
                onClick={() => {
                  if (taskId) {
                    setTaskIdForDetail(taskId);
                    dispatch(setShowPricingModal(true));
                  } else {
                    addTaskModal.open();
                  }
                }}
                size="small"
                disabled={!taskId && !taskTemplateId}
              >
                <div className="!t-w-24">View more</div>
              </Button>
            </div>
            {extension && (
              <p className="t-m-0 t-text-body-sm t-text-pink-70">{extension}</p>
            )}
          </div>
          <div className="t-block t-truncate t-text-body sm:t-hidden">
            {deadlineStatus && deadlineStatus?.displayName && (
              <Badge
                type={deadlineStatus?.type}
                children={deadlineStatus?.displayName}
              />
            )}
          </div>
        </div>
      </div>
      {addTaskModal.isOpen && (
        <AddTaskEntityModal
          to="/deadlines"
          taskTemplateId={taskTemplateId}
          handleClose={addTaskModal.close}
          disableLaunch={deadlinePassed}
        />
      )}
      {isSuccess && !isLoading && taskId === taskIdForDetail && (
        <PaymentEngine
          pricingData={task?.subtitle}
          pricingAmount={task?.payment_amount}
          next={undefined}
          title={title}
          mainId={mainId}
          groupId={task?.group_uuid}
          task={task}
          isArchived={task?.is_archived}
          updateAssignee={refetchTask}
          refetchTask={refetchTask}
          isFromServices={false}
          onClose={setTaskIdForDetail}
        />
      )}
    </div>
  );
};

export const Deadline = ({
  date,
  title,
  description,
  extension,
  price,
  dynamicPricing,
  onViewMore,
  taskData,
  taskTemplate,
  discountedPrice,
  isFree,
}: DeadlineProps) => {
  const deadlinePassed =
    !taskData && dayjs(date || dayjs()).isBefore(dayjs(), "day");
  let deadlineStatus: DeadlineCompProps["deadlineStatus"] = {
    displayName: "",
    type: "TASK_DEFINING",
  };

  if (taskTemplate?.base_task_template_uuid) {
    deadlineStatus = {
      displayName: "Not launched",
      type: "TASK_DEFINING",
    };
  }

  if (taskData?.task_id && !taskData.current_state_id) {
    deadlineStatus = {
      displayName: "Not started",
      type: "TASK_DEFINING",
    };
  }

  if (
    taskData?.current_state_name === "Successfully Filed" ||
    taskData?.current_state_name === "Completed"
  ) {
    deadlineStatus = {
      displayName: "Successfully filed",
      type: "SUBMITTED",
    };
  } else if (taskData?.current_state_name === "Fill form") {
    deadlineStatus = {
      displayName: "Filing in progress",
      type: "FILING_IN_PROGRESS",
    };
  } else if (deadlinePassed) {
    deadlineStatus = {
      displayName: "Passed",
      type: "TASK_DEFINING",
    };
  } else if (taskData?.current_state_id) {
    deadlineStatus = {
      displayName: "Filing in progress",
      type: "FILING_IN_PROGRESS",
    };
  }

  const finalPrice = price === "$FREE" ? "FREE" : price;

  return taskData?.task_id ? (
    <div
      className={cx({
        "t-text-text-60 hover:t-text-text-60": !dayjs(date).isBefore(dayjs()),
      })}
    >
      <DeadlineComponent
        description={description}
        dynamicPricing={dynamicPricing}
        extension={extension}
        date={date}
        deadlineStatus={deadlineStatus}
        finalPrice={finalPrice}
        price={price}
        title={title}
        discountedPrice={discountedPrice}
        isFree={isFree}
        taskId={taskData.task_id}
        deadlinePassed={deadlinePassed}
      />
    </div>
  ) : (
    <>
      <div
        className={cx({
          "t-text-text-60 hover:t-text-text-60": !dayjs(date).isBefore(dayjs()),
        })}
      >
        <DeadlineComponent
          description={description}
          dynamicPricing={dynamicPricing}
          extension={extension}
          date={date}
          deadlineStatus={deadlineStatus}
          finalPrice={finalPrice}
          discountedPrice={discountedPrice}
          isFree={isFree}
          title={title}
          price={price}
          taskTemplateId={taskTemplate?.base_task_template_uuid}
          deadlinePassed={deadlinePassed}
        />
      </div>
    </>
  );
};

/* @tw */
const commonClassnames =
  "t-border-[1.5px] t-border-solid t-border-neutral-0 t-overflow-hidden t-rounded-xl";

export const CalendarDeadline = (props: DeadlineProps) => (
  <div
    className={cx(commonClassnames, {
      "t-transition-shadow t-duration-150 hover:t-drop-shadow-[0px_0px_12px_rgba(141,141,141,0.12)]":
        !dayjs(props.date).isBefore(dayjs()),
    })}
  >
    <Deadline {...props} />
  </div>
);
