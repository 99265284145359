import { axiosInstance } from "./apiInstance";
import qs from "qs";

export const getNearestExpireRA = async ({
  page_num,
  search_term,
  days_to_expiry,
}) => {
  let url = "/api/inkle/agent/manage/";
  let queryUrl = qs.stringify(
    {
      page_num,
      search_term,
      days_to_expiry,
    },
    { skipNulls: true }
  );

  if (queryUrl) {
    url = url.replace("agent/manage/", "agent/manage?");
    url += queryUrl;
  }
  return await axiosInstance.get(url);
};
