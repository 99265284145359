import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import SortDescendingIcon from "static/images/SortDescending.svg";
import { SORT_TYPE } from "./mailboxDictonaries";
import { useUpdateQuery } from "hooks/useQuery";
import classNames from "classnames";
import { NEWEST, OLDEST } from "constants/mailRoom";
import { Button } from "components/DesignSystem/Button/Button";

export const SortDropDown = ({ setSortType, sortType }) => {
  const { update } = useUpdateQuery();
  const handleSortClick = (value) => {
    setSortType(value);
    update({
      query: "sort_by",
      value: value.type,
    });
  };

  return (
    <Dropdown className="sort-by-dropdown">
      <Dropdown.Toggle className="t-p-0">
        <Button block>
          <span className="t-flex t-gap-1 t-items-center">
            <img src={SortDescendingIcon} alt="sort" loading="lazy" />
            <span>Sort By: {sortType.name}</span>{" "}
          </span>
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <span className="sort-dropdown-menu">
          <Dropdown.Item
            href="#"
            onClick={() => handleSortClick(SORT_TYPE[NEWEST])}
            className={classNames({
              "selected-sort": sortType.type === NEWEST,
            })}
          >
            Newest first
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => handleSortClick(SORT_TYPE[OLDEST])}
            className={classNames("", {
              "selected-sort": sortType.type === OLDEST,
            })}
          >
            Oldest first
          </Dropdown.Item>
        </span>
      </Dropdown.Menu>
    </Dropdown>
  );
};
