import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useRemoveTransactionCategoryMutation } from "store/apis/chartOfAccounts";
import { deleteCOAModalClose } from "store/slices/chartOfAccounts";
import { RootState } from "store/store";

export const DeleteCategory = () => {
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { uuid: selectedCategory, open } = useSelector(
    (state: RootState) => state.chartOfAccounts.deleteCOA
  );

  const [deleteTransactionCategory, { isLoading }] =
    useRemoveTransactionCategoryMutation();

  const onClose = () => {
    dispatch(deleteCOAModalClose());
  };

  const deleteCategory = async (values: { name: string }) => {
    const payload = {
      category_uuid: selectedCategory,
      name: values.name,
    };
    try {
      await deleteTransactionCategory({ groupId, entityId, payload }).unwrap();
      successToast({ title: "Account deleted" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
    onClose();
  };

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Modal.Content size="large">
        <Modal.Header>
          <Modal.Title>Delete account?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik initialValues={{ name: "" }} onSubmit={deleteCategory}>
          {({ isSubmitting }) => (
            <Form className="all:unset">
              <Modal.Body>
                <div className="t-text-subtitle t-mb-5">
                  Are you sure you want to delete this account? These changes
                  will reflect everywhere.
                </div>
                <TextInput
                  block
                  label="Please enter the name of the account to delete it permanently. "
                  name="name"
                />
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-end t-gap-3">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  customType="danger"
                  isLoading={isSubmitting || isLoading}
                  disabled={isSubmitting || isLoading}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
