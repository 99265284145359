import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { GroupSelector } from "components/GroupSelector/GroupSelector";
import { PriceInput } from "components/PriceInput/PriceInput";
import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";
import { Form, Formik, useFormikContext } from "formik";
import { createNonChatTicketSchema } from "formValidations/ticketSchema";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import NonPricedTicketTag from "static/images/NonPricedTicketTag.svg";
import PricedTicketTag from "static/images/PricedTicketTag.svg";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { useCreateChatTicketMutation } from "store/apis/chatTicket";
import { useGetGroupDataQuery } from "store/apis/group";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";

type TicketPayload = {
  ticket_title: string;
  company_group_id: string;
  group_entity_id: string;
  is_priced_ticket: "PRICED_TICKETS" | "UNPRICED_TICKETS";
  price: number;
  enablePayment: boolean;
  tags: string[];
  assignees: string[];
  createChatChannel: boolean;
  channelName: string;
  isNonChatTicket: boolean;
};

type CreateTicketProps = {
  isNonChatTicket: boolean;
  show: boolean;
  closeModal: () => void;
  currentEntityUuid?: string;
  groupId?: string;
  channelId?: string;
};

const GroupAndEntitySelector = ({
  isGroupExist,
}: {
  isGroupExist: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext<TicketPayload>();
  const { company_group_id } = values;

  const { data, isFetching } = useGetGroupDataQuery(
    {
      groupId: company_group_id,
    },
    { skip: !company_group_id, refetchOnMountOrArgChange: true }
  );

  const { entities = [] } = data || {};

  return (
    <>
      {!isGroupExist && (
        <GroupSelector
          label="Select group"
          menuPortalTarget={document.body}
          maxMenuHeight={250}
          name="company_group_id"
          isClearable={false}
          required
          withForm
          onChange={() => {
            setFieldValue("group_entity_id", "");
          }}
        />
      )}

      <Combobox
        key={company_group_id}
        required
        isLoading={isFetching}
        isDisabled={!company_group_id || isFetching}
        menuPortalTarget={document.body}
        label="Select entity"
        withForm
        name="group_entity_id"
        options={entities?.map((e) => ({
          label: (
            <div className="t-flex t-gap-2 t-items-center">
              <ReactCountryFlag countryCode={e.country_code} svg />
              {e.name}
            </div>
          ),
          value: e.uuid,
        }))}
      />
    </>
  );
};

export const CreateTicket = ({
  isNonChatTicket,
  show,
  closeModal,
  currentEntityUuid,
  groupId,
  channelId,
}: CreateTicketProps) => {
  const { isAdmin, isCustomer } = useRoleBasedView();
  const [createNonChatTicket] = useCreateChatTicketMutation();
  const { alertToast, successToast } = useToast();

  const { data: tagsData = [] } = useGetTagsForCategoryQuery({
    tagType: "TICKETS_TAG",
  });

  const { data: assigneesData = [] } = useGetServiceTeamQuery(
    { accessible_teams: isAdmin ? INKLE_TEAM_AND_CPA_TEAM : null },
    {
      skip: isCustomer,
    }
  );

  const { messageId } = useSelector(
    (state: RootState) => state.ticket.createTicketData
  );

  const onSubmit = async ({
    assignees,
    company_group_id,
    group_entity_id,
    is_priced_ticket,
    price,
    tags,
    ticket_title,
    enablePayment,
    createChatChannel,
    channelName,
    isNonChatTicket,
  }: TicketPayload) => {
    try {
      const isPricedTicket = is_priced_ticket === "PRICED_TICKETS";

      await createNonChatTicket({
        ticket_title,
        company_group_id,
        group_entity_id: group_entity_id || "",
        is_priced_ticket: isPricedTicket,
        is_price_finalized: enablePayment,
        ticket_price: isPricedTicket ? price : 0,
        tag_ids: tags.join(","),
        assignee_profile_ids: assignees.join(","),
        is_non_chat_ticket: isNonChatTicket || false,
        channel_url: isNonChatTicket ? "" : channelId || "",
        message_id: isNonChatTicket ? "" : messageId,
        create_ticket_channel: createChatChannel,
        ticket_channel_name: channelName,
      }).unwrap();
      successToast({ message: "Ticket created successfully" });
      closeModal();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const ticketTypeOptions = [
    {
      name: "Unpriced tickets",
      img: NonPricedTicketTag,
      value: "UNPRICED_TICKETS",
    },
    { name: "Priced tickets", img: PricedTicketTag, value: "PRICED_TICKETS" },
  ].map(({ name, img, value }) => ({
    label: (
      <div className="t-flex t-items-center t-gap-2">
        <img src={img} alt="PricedTicketTag" />
        {name}
      </div>
    ),
    value,
  }));

  return (
    <Modal.Root open={show} onOpenChange={closeModal} modal={false}>
      <Formik
        enableReinitialize
        initialValues={{
          isNonChatTicket,
          ticket_title: "",
          company_group_id: groupId || "",
          group_entity_id: currentEntityUuid || "",
          is_priced_ticket: "UNPRICED_TICKETS",
          price: 0,
          enablePayment: false,
          tags: [],
          assignees: [],
          createChatChannel: false,
          channelName: "",
        }}
        onSubmit={onSubmit}
        validationSchema={createNonChatTicketSchema}
        validateOnChange
        validateOnBlur
      >
        {({
          isValid,
          isSubmitting,
          values: { is_priced_ticket, createChatChannel, isNonChatTicket },
          submitForm,
          setFieldValue,
        }) => (
          <Modal.Content useCustomOverlay>
            <Modal.Header>
              <Modal.Title>Create Ticket</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-flex-col t-gap-5 t-m-0 t-w-full">
                <TextInput
                  required
                  block
                  label="Title"
                  name="ticket_title"
                  placeholder="Add title"
                />
                {!currentEntityUuid && (
                  <GroupAndEntitySelector isGroupExist={Boolean(groupId)} />
                )}

                <Combobox
                  label="Ticket type"
                  required
                  menuPortalTarget={document.body}
                  withForm
                  name="is_priced_ticket"
                  isMulti={false}
                  isClearable={false}
                  defaultValue={ticketTypeOptions[0]}
                  options={ticketTypeOptions}
                  onChange={() => {
                    setFieldValue("price", 0);
                    setFieldValue("enablePayment", false);
                  }}
                />

                {Boolean(is_priced_ticket === "PRICED_TICKETS") && (
                  <>
                    <PriceInput
                      required
                      name="price"
                      label="Amount"
                      allowNegative={false}
                    />

                    <SwitchField
                      label="Enable invoice on creation"
                      name="enablePayment"
                    />
                  </>
                )}

                <Combobox
                  label="Tags"
                  menuPlacement="top"
                  menuPortalTarget={document.body}
                  withForm
                  isMulti
                  isSearchable
                  isClearable
                  name="tags"
                  options={tagsData.map(({ title, uuid }) => ({
                    label: title,
                    value: uuid,
                  }))}
                />

                <Combobox
                  required
                  label="Assigned to"
                  menuPlacement="top"
                  menuPortalTarget={document.body}
                  withForm
                  isMulti
                  isSearchable
                  isClearable
                  name="assignees"
                  options={assigneesData.map(({ name, uuid }) => ({
                    label: name,
                    value: uuid,
                  }))}
                />

                {Boolean(isNonChatTicket) && (
                  <>
                    <SwitchField
                      label="Create chat channel for this ticket"
                      name="createChatChannel"
                      onChange={() => setFieldValue("channelName", "")}
                    />
                    {Boolean(createChatChannel) && (
                      <TextInput
                        required
                        autoFocus
                        block
                        label="Channel name"
                        name="channelName"
                        placeholder="Enter the channel name"
                      />
                    )}
                  </>
                )}
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button
                customType="secondary"
                onClick={closeModal}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                customType="primary"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={submitForm}
              >
                Create
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};
