import { FORBIDDEN_PREFIXES } from "components/teamMembers/forbiddenPrefix";
import { object, string } from "yup";

export const newMember = object({
  email: string()
    .email("Please enter a valid email id")
    .test({
      name: "prefix-check",
      message: "Please do not use a team email",
      test: (email) => {
        const name = email?.match(/[^@]+/g) || "";

        return !FORBIDDEN_PREFIXES.some((prefix) => prefix === name[0]);
      },
    })
    .required("Email is required"),
  groupRole: string().required("Please select group role").not(["No Access"]),
});

export const newTeam = object({
  team: string().required("Please select team").not(["No Access"]),
});
