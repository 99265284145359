export const EmptyPerks = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <g clip-path="url(#clip0_2859_14199)">
        <rect
          width="113.75"
          height="68.75"
          transform="translate(17.5 73.75)"
          fill="#F3F3F5"
        />
        <rect
          width="47.5"
          height="68.75"
          transform="translate(83.75 73.75)"
          fill="#E7E6EB"
        />
      </g>
      <g clip-path="url(#clip1_2859_14199)">
        <rect
          width="114.485"
          height="13.4109"
          transform="translate(35.718 29.001) rotate(16.3256)"
          fill="#F3F3F5"
        />
        <rect
          width="46.448"
          height="13.4401"
          transform="translate(101.084 48.251) rotate(16.3256)"
          fill="#E7E6EB"
        />
      </g>
      <path
        d="M103.204 34.8219L94.0862 40.2736C93.432 40.6648 92.5832 40.4162 92.2435 39.7339L87.5082 30.2245C84.1498 24.0839 75.0437 25.0691 73.0763 31.7858C71.8567 35.9496 74.2435 40.3138 78.4073 41.5334L104.765 49.2537C108.929 50.4733 113.293 48.0865 114.512 43.9227C116.48 37.206 109.344 31.4635 103.204 34.8219Z"
        fill="#F3F3F5"
      />
      <path
        d="M103.203 34.8227L93.3131 40.7365C93.0302 40.9057 92.8216 41.1754 92.7289 41.4917L91.5858 45.3944L104.765 49.2546C108.928 50.4742 113.293 48.0874 114.512 43.9236C116.48 37.2068 109.344 31.4644 103.203 34.8227Z"
        fill="#E7E6EB"
      />
      <g clip-path="url(#clip2_2859_14199)">
        <mask
          id="mask0_2859_14199"
          mask-type="luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="45"
          width="16"
          height="16"
        >
          <path d="M15.2698 60.2715H0V45.0017H15.2698V60.2715Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2859_14199)">
          <path
            d="M6.86619 59.8036C7.18877 60.4275 8.08099 60.4275 8.40357 59.8036L10.4577 55.8307C10.54 55.6715 10.6698 55.5418 10.829 55.4595L14.8018 53.4053C15.4258 53.0827 15.4258 52.1905 14.8018 51.8679L10.829 49.8137C10.6698 49.7314 10.54 49.6017 10.4577 49.4425L8.40357 45.4697C8.08099 44.8457 7.18877 44.8457 6.86619 45.4697L4.81203 49.4425C4.72971 49.6017 4.59999 49.7314 4.44079 49.8137L0.467914 51.8679C-0.155971 52.1905 -0.155971 53.0827 0.467914 53.4053L4.44079 55.4595C4.59999 55.5418 4.72971 55.6715 4.81203 55.8307L6.86619 59.8036Z"
            fill="#E7E6EB"
          />
        </g>
      </g>
      <g clip-path="url(#clip3_2859_14199)">
        <mask
          id="mask1_2859_14199"
          mask-type="luminance"
          maskUnits="userSpaceOnUse"
          x="129"
          y="3"
          width="16"
          height="16"
        >
          <path
            d="M144.636 18.3867H130V3.75055H144.636V18.3867Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_2859_14199)">
          <path
            d="M136.581 17.9382C136.89 18.5362 137.745 18.5362 138.054 17.9382L140.023 14.1302C140.102 13.9776 140.227 13.8533 140.379 13.7744L144.187 11.8054C144.785 11.4962 144.785 10.641 144.187 10.3318L140.379 8.36292C140.227 8.28403 140.102 8.1597 140.023 8.00704L138.054 4.19907C137.745 3.60104 136.89 3.60104 136.581 4.19907L134.612 8.00704C134.533 8.1597 134.409 8.28403 134.256 8.36292L130.448 10.3318C129.85 10.641 129.85 11.4962 130.448 11.8054L134.256 13.7744C134.409 13.8533 134.533 13.9776 134.612 14.1302L136.581 17.9382Z"
            fill="#E7E6EB"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2859_14199">
        <rect
          width="113.75"
          height="68.75"
          fill="white"
          transform="translate(17.5 73.75)"
        />
      </clipPath>
      <clipPath id="clip1_2859_14199">
        <rect
          width="114.485"
          height="13.4109"
          fill="white"
          transform="translate(35.718 29.001) rotate(16.3256)"
        />
      </clipPath>
      <clipPath id="clip2_2859_14199">
        <rect
          width="15.2698"
          height="15.2698"
          fill="white"
          transform="matrix(1 0 0 -1 0 60.2695)"
        />
      </clipPath>
      <clipPath id="clip3_2859_14199">
        <rect
          width="14.6362"
          height="14.6362"
          fill="white"
          transform="matrix(1 0 0 -1 130 18.3867)"
        />
      </clipPath>
    </defs>
  </svg>
);
