import { ReactNode } from "react";

export type TaskType =
  | "FORM_FILING"
  | "UPLOAD_DOCUMENTS"
  | "PAYMENT"
  | "REVIEW"
  | "FILING_IN_PROGRESS"
  | "SUBMITTED"
  | "TASK_DEFINING"
  | "ACTIVE"
  | "FORMER"
  | "EXPIRED"
  | "ACTION_REQUIRED"
  | "PROCESSING"
  | "ARCHIVED"
  | "COMPLETED";

type BadgeProps = {
  type: TaskType;
  children: ReactNode;
  className?: string;
};

const BADGE_TYPE_CLASSES = {
  /* @tw */
  PAYMENT: "t-bg-blue-0 t-text-blue-90",
  /* @tw */
  FORM_FILING: "t-bg-orange-10 t-text-orange-80",
  /* @tw */
  TASK_DEFINING: "t-bg-red-10 t-text-red",
  /* @tw */
  UPLOAD_DOCUMENTS: "t-bg-yellow-10 t-text-yellow-90",
  /* @tw */
  REVIEW: "t-bg-pink-20 t-text-pink-90",
  /* @tw */
  FILING_IN_PROGRESS: "t-bg-purple-0 t-text-purple-90",
  /* @tw */
  SUBMITTED: "t-bg-dark_green-10 t-text-dark_green-90",
  /* @tw */
  ACTIVE: "t-bg-dark_green-10 t-text-dark_green-90",
  /* @tw */
  FORMER: "t-bg-neutral-0 t-text-text-100",
  /* @tw */
  EXPIRED: "t-bg-red-10 t-text-red",
  /* @tw */
  ACTION_REQUIRED: "t-bg-red-10 t-text-red",
  /* @tw */
  PROCESSING: "t-bg-neutral-0 t-text-neutral-70",
  /* @tw */
  ARCHIVED: "t-bg-neutral-0 t-text-neutral-70",
  /* @tw */
  COMPLETED: "t-bg-dark_green-10 t-text-dark_green",
};

export const Badge = ({ children, type, className = "" }: BadgeProps) => {
  return (
    <div
      className={`${BADGE_TYPE_CLASSES[type]} t-w-max t-max-w-36 t-break-words t-rounded t-px-2 t-py-1 t-text-body-sm t-font-normal 
        t-line-clamp-1 ${className}`}
    >
      {children}
    </div>
  );
};
