import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskTemplateStats: build.query<
      any,
      {
        templateId: string;
      }
    >({
      query: ({ templateId }) => {
        return {
          url: `api/inkle/tasks/v1/${templateId}/stats/`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetTaskTemplateStatsQuery } = extendedApi;
