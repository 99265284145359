import QueryString from "qs";
import { emptyApi } from "./emptyApi";

export type FilingData = {
  "1099": boolean;
  dft: boolean;
  "7004": boolean;
  fbar: boolean;
  cft: boolean;
  "1120": boolean;
  "5472": boolean;
  "926": boolean;
};

export type TaxQuoteYear = {
  company_name: string;
  entity_name: string;
  coupon?: string;
  filing_data: FilingData;
  subsidiary_count: number | null;
};

type TaxQuote = {
  2023: TaxQuoteYear;
  2024?: TaxQuoteYear;
};

export type BooksQuoteYear = {
  company_name: string;
  entity_name: string;
  coupon?: string;
  tier_id: string;
  historical_cleanup_start_month: string | null;
  pro_start_month: string | null;
  discounted_pro_plan_total: number | null;
};

type BooksQuote = {
  2023?: BooksQuoteYear;
  2024?: BooksQuoteYear;
};

export type SalesQuoteRequest = {
  tax_quote: TaxQuote;
  books_quote: BooksQuote;
};

type SheetLink = {
  uuid: string;
  quote_sheet_id: string;
  sheet_link: null | string;
  status: "IN_PROGRESS" | "FAILED" | "COMPLETED";
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    generateSalesQuotes: build.mutation<SheetLink, SalesQuoteRequest>({
      query: (req) => {
        return {
          url: `/api/inkle/inklecalendar/sales-quote/`,
          method: "POST",
          body: req,
        };
      },
    }),
    getCalculatedTieredPrice: build.query<
      { pricing: number },
      {
        historical_cleanup_start_month?: string | null;
        pro_start_month?: string | null;
        tier_id?: string | null;
      }
    >({
      query: (req) => {
        const params = QueryString.stringify(req, {
          skipNulls: true,
          addQueryPrefix: true,
        });
        return {
          url: `/api/inkle/inklecalendar/cleanup-pricing/${params}`,
        };
      },
    }),
    getSheetLink: build.query<SheetLink, { linkId: string }>({
      query: ({ linkId }) =>
        `/api/inkle/inklecalendar/sales-quote/?sales_tax_file_id=${linkId}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGenerateSalesQuotesMutation,
  useLazyGetSheetLinkQuery,
  useLazyGetCalculatedTieredPriceQuery,
} = extendedApi;
