export const ColorCheckSquare = ({ color }: { color?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_446_33329)">
      <path
        d="M3.45703 11.8142V4.6814C3.45703 4.51563 3.52014 4.35666 3.63246 4.23945C3.74479 4.12224 3.89714 4.0564 4.056 4.0564H15.4362C15.595 4.0564 15.7474 4.12224 15.8597 4.23945C15.9721 4.35666 16.0352 4.51563 16.0352 4.6814V16.5564C16.0352 16.7222 15.9721 16.8812 15.8597 16.9983C15.7474 17.1156 15.595 17.1814 15.4362 17.1814H10.3151"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.74609 12.4939L4.95443 17.4939L2.55859 14.9939"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_446_33329">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
