import { array, object, string } from "yup";

export const investorSchema = () =>
  object({
    name: string().required("Fund name is required"),
    contacts: array().of(
      object({
        name: string().required("Contact name is required"),
        email: string()
          .email("Invalid email address")
          .required("Email is required"),
        linkedIn_url: string().required("LinkedIn url is required"),
      })
    ),
  });
