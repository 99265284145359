export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const GMAIL_LINK = "https://mail.google.com/mail/";
export const OUTLOOK_LINK = "https://outlook.live.com/mail/";
export const INCORRECT_OTP =
  "OTP/ token is incorrect. Please enter the correct OTP";
export const LINK_EXPIRED = "Sign up/in link expired/used";
export const TOKEN = "token";
export const PAYLOAD = "payload";

export const EMPTY_OTP = {
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
  otp5: "",
  otp6: "",
};

export const DELETE = "Delete";
export const BACKSPACE = "Backspace";
export const ARROW_LEFT = "ArrowLeft";
export const ARROW_RIGHT = "ArrowRight";
export const OTP_SUCCESS = "Link sent successfully";
export const OTP_SENT_SUCCESSFULLY = "OTP sent successfully";

export const RESTRICTED_EMAILS = [
  "yahoo.com",
  "gmail.com",
  "ymail.com",
  "hotmail.com",
  "outlook.com",
  "icloud.com",
  "live.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "yahoo.co.uk",
  "yahoo.com.br",
  "yahoo.co.in",
  "rediffmail.com",
  "firstbase.io",
];
