import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDeleteCapTableMutation } from "store/apis/capTable";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const DeleteModal = ({
  isOpen,
  close,
  capTableId,
}: { capTableId: string } & ModalProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { successToast, alertToast } = useToast();

  const [deleteCapTable, { isLoading: isDeleting }] =
    useDeleteCapTableMutation();

  const onDelete = async () => {
    try {
      await deleteCapTable({ groupId, entityId, capTableId }).unwrap();
      close();
      successToast({ message: "Cap Table deleted" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Delete File</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>You can’t undo this action.</Modal.Body>

          <Modal.FooterButtonGroup>
            <Button onClick={close}>Cancel</Button>
            <Button
              customType="danger"
              onClick={onDelete}
              isLoading={isDeleting}
              disabled={isDeleting}
            >
              Delete
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
