import { ReactNode, forwardRef } from "react";
import DashboardContainer from "./dashboard/DashboardContainer";
import classNames from "classnames";

export const DashboardLayout = forwardRef<
  HTMLDivElement,
  {
    header?: ReactNode;
    children: ReactNode;
    className?: string;
  }
>(({ header, children, className }, ref) => {
  return (
    <DashboardContainer
      ref={ref}
      className={classNames("t-h-full", className || "")}
    >
      {header && (
        <DashboardContainer.Header className="t-px-10 t-z-header">
          {header}
        </DashboardContainer.Header>
      )}
      <DashboardContainer.Content className="t-my-5">
        <div className="t-px-10 t-h-full t-w-full">{children}</div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
});
