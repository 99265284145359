import React from "react";
import { useContext, useEffect, useState } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { profileData } from "./apis/profileData";
import { NO_GROUP_ACCESS_ROUTES } from "./constants/routeState";
import { useMatchRoutes } from "./hooks/useMatchRoutes";

//context
import authContext from "./jwt_context&axios/authContext";
import { AFTER_AUTH_REDIRECT_TO } from "constants/storageKeys";
import { useDispatch } from "react-redux";
import { setSubscriptionInactiveStatus } from "store/slices/subscriptionModal";
import { INVESTOR } from "constants/userTypes";
import Loader from "components/design/loader";
import { isArray } from "cypress/types/lodash";

export const ProtectedRoutes = ({ component, ...props }: RouteProps) => {
  const { pathname, search } = useLocation();
  const fullPath = pathname + search;
  const [authChecked, setAuthCheck] = useState<boolean>();
  const { user, updateUser } = useContext(authContext);
  const noGroupAccessRoutes = useMatchRoutes(NO_GROUP_ACCESS_ROUTES);
  const history = useHistory();
  const dispatch = useDispatch();
  const INCOMPLETE = "INCOMPLETE";

  useEffect(() => {
    if (pathname === "/inklecalendar") history.push("/inklecalendar");
    else if (pathname === "/form-7004-extension-request-2022")
      history.push("/form-7004-extension-request-2022");
    else if (!user) {
      localStorage.setItem(AFTER_AUTH_REDIRECT_TO, fullPath);
      history.push("/signin");
    } else {
      profileData()
        .then((res) => {
          updateUser(res);
          setAuthCheck(true);

          const inactiveSubscriptions = [
            res.data.data?.platform_subscription,
            ...(res.data.data?.addon_subscriptions || []),
          ].filter((s) => s.subscription_status === INCOMPLETE);

          dispatch(setSubscriptionInactiveStatus(inactiveSubscriptions));

          if (
            res.data.data.type === INVESTOR &&
            !res.data.data?.is_group_created
          ) {
            history.replace("/raise/investor");
            return;
          }

          if (!res.data.data?.is_group_created) {
            history.replace("/profile");
            return;
          }

          if (res.data.data?.is_restricted) {
            if (noGroupAccessRoutes?.path === "/handbook") {
              history.replace("/handbook");
              return;
            } else if (noGroupAccessRoutes?.path === "/forum") {
              history.replace("/forum");
              return;
            }
            history.replace("/verify");
          } else if (res.data.data?.is_archived) {
            history.replace("/archived");
          } else if (pathname === "/verify") {
            history.replace("/");
          } else if (pathname === "/archived") {
            history.replace("/");
          }
        })
        .catch(() => setAuthCheck(true));
    }
  }, [user]);

  return <Route {...props} component={authChecked ? component : Loader} />;
};
