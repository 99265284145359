import React, { useState } from "react";
import classNames from "classnames";

// style
import "../../../static/styles/components/entity.css";

// constants
import { relationshipOptions } from "../../../constants/entity";

const EntityRelationShipOption = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Parent");

  const relationshipOptionModal = () => {
    setShowOptions(true);
  };

  const setOption = (e, option) => {
    e.stopPropagation();
    setSelectedOption(option);
    setShowOptions(false);
  };

  return (
    <div className="selectRelationshipDiv">
      <div className="selectRelationship" onClick={relationshipOptionModal}>
        {selectedOption}
      </div>
      <div
        className={classNames("relationshipOptionModal", {
          "d-none": !showOptions,
        })}
      >
        {relationshipOptions.map((option) => {
          return (
            <p
              key={option.id}
              className={classNames("relationshipOptions", {
                consultantDashBoardSelectedSortOption:
                  option.name === selectedOption,
              })}
              onClick={(e) => setOption(e, option.name)}
            >
              {option.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default EntityRelationShipOption;
