import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import CopyCode from "components/design/copyCode";
import Loader from "components/design/loader";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { Trash } from "components/icons/Trash";
import { REQUESTED_INFO_CUSTOM_TYPE } from "constants/chatType";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useAddClarificationMutation,
  useDeleteOpenItemTxnMutation,
  useGetOpenItemsQuery,
  useSendClarificationRequestMutation,
} from "store/apis/openItem";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { OpenItem } from "types/Models/openItem";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import ToolTip from "components/design/toolTip";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { ApplyToAllIcon } from "components/icons/ApplyToAllIcon";

type RequestInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  csvId: string;
  channelUrl: string;
};

const RequestInfoColumn = (info: CellContext<OpenItem, string>) => {
  const { alertToast } = useToast();
  const { isAdmin, isCpa } = useRoleBasedView();
  const { information_request, uuid } = info.row.original;
  const { uuid: groupId } = useCurrentGroupContext();

  const [addRequestInfo, { isLoading: isUpdating }] =
    useAddClarificationMutation();

  const handleCommentChange = debounce(
    async (e: ChangeEvent<HTMLTextAreaElement>, uuid: string) => {
      try {
        const { value } = e.target;

        await addRequestInfo({
          groupId,
          openItemId: uuid,
          informationRequest: value,
        }).unwrap();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    },
    1000
  );

  return (
    <Formik
      initialValues={{
        information_request: information_request || "",
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values }) => (
        <Form className="all:unset">
          <div className="t-flex t-items-center t-gap-1.5">
            <TextArea
              name="information_request"
              disabled={!Boolean(isAdmin || isCpa)}
              cols={100}
              rows={1}
              onChange={(e) => handleCommentChange(e, uuid)}
              value={values?.information_request}
              placeholder="Enter information..."
            />

            <span
              className={classNames("t-flex t-animate-spin", {
                "t-invisible": !isUpdating,
                "t-block": isUpdating,
              })}
            >
              <LoadingIcon />
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const DeleteOpenItem = (info: CellContext<OpenItem, string>) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [deleteOpenItemTxn, { isLoading }] = useDeleteOpenItemTxnMutation();
  const uuid = info.getValue();

  const DeleteTxn = async (openItemId: string) => {
    try {
      await deleteOpenItemTxn({ groupId, openItemId }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };
  return (
    <Button
      customType="ghost_icon"
      onClick={() => DeleteTxn(uuid)}
      disabled={isLoading}
      isLoading={isLoading}
    >
      {isLoading ? "" : <Trash />}
    </Button>
  );
};

const ApplyToAll = ({
  info,
  copyTextToAll,
  isUpdating,
}: {
  info: CellContext<OpenItem, string | undefined>;
  copyTextToAll: () => void;
  isUpdating: boolean;
}) => {
  const { isCpa } = useRoleBasedView();

  const showCopyToAll = Boolean(
    Number(info.row.id) === 0 && isCpa && info.table.getRowCount() > 1
  );

  return (
    <div className="t-flex t-items">
      {Number(info.row.id) !== 0 && (
        <span
          className={classNames("t-flex t-animate-spin t-ml-2", {
            "t-invisible": !isUpdating,
            "t-block": isUpdating,
          })}
        >
          <LoadingIcon />
        </span>
      )}
      {showCopyToAll && (
        <ToolTip text="Apply to all">
          <Button
            customType="ghost_icon"
            size="small"
            onClick={copyTextToAll}
            isLoading={isUpdating}
            disabled={isUpdating}
          >
            <ApplyToAllIcon color="currentColor" />
          </Button>
        </ToolTip>
      )}
    </div>
  );
};

export const RequestInfo = ({
  isOpen,
  onClose,
  csvId,
  channelUrl,
}: RequestInfoProps) => {
  const dispatch = useDispatch();
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();

  const { data: requestedTxn, isLoading } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const [sendRequestInfo, { isLoading: isRequestSending }] =
    useSendClarificationRequestMutation();

  const [addRequestInfo, { isLoading: isUpdating }] =
    useAddClarificationMutation();

  const { open_items = [] } = requestedTxn || {};

  const handleComment = async ({
    uuid,
    informationRequest,
  }: {
    uuid: string;
    informationRequest: string;
  }) => {
    try {
      await addRequestInfo({
        groupId,
        openItemId: uuid,
        informationRequest,
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const copyTextToAll = async () => {
    const openItemsIds = open_items.map(({ uuid }) => uuid).slice(1);
    const informationRequest = open_items?.[0].information_request;

    if (informationRequest) {
      await Promise.all(
        openItemsIds.map((uuid) =>
          handleComment({
            uuid,
            informationRequest,
          })
        )
      );
    }
  };

  const columnHelper = createColumnHelper<OpenItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "Date",
        cell: (info) => {
          const { date } = info.getValue();
          return (
            <div className="t-text-subtext t-text-text-30">
              {dayjs(date).format(DD_MMM_YYYY)}
            </div>
          );
        },
        header: "Date",
        size: 15,
      }),
      columnHelper.accessor((row) => row, {
        id: "Merchant",
        cell: (info) => {
          const { merchant, logo } = info.getValue();
          if (!Boolean(merchant)) {
            return "-";
          }

          return (
            <div className="t-flex t-gap-2 t-items-center">
              <Avatar src={logo || ""} alt={merchant!} />
              <div className="t-text-subtext t-text-text-60">{merchant}</div>
            </div>
          );
        },
        header: "Merchant",
        size: 10,
      }),
      columnHelper.accessor("description", {
        id: "Description",
        size: 15,
        cell: (info) => {
          const description = info.getValue();
          return (
            <ConditionalToolTip condition={description}>
              <span className="t-line-clamp-1 t-flex t-justify-start">
                {description || "-"}
              </span>
            </ConditionalToolTip>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "Amount",
        cell: (info) => {
          const { amount } = info.getValue();
          return (
            <div className="t-flex t-justify-end t-mr-10 t-text-subtext t-text-text-60">
              <AmountSuperScript amount={Number(amount)!} />
            </div>
          );
        },
        header: () => (
          <div className="t-flex t-justify-end t-mr-10">Amount</div>
        ),
        size: 10,
      }),
      columnHelper.accessor("information_request", {
        id: "RequestInformation",
        cell: RequestInfoColumn,
        header: () => (
          <div className="after:t-font-bold after:t-text-red after:t-content-['_*']">
            Request Information
          </div>
        ),
        size: 40,
      }),
      columnHelper.display({
        id: "Applytoall",
        cell: (info) => (
          <ApplyToAll
            info={info}
            copyTextToAll={copyTextToAll}
            isUpdating={isUpdating}
          />
        ),
        size: 10,
      }),
      columnHelper.accessor("uuid", {
        id: "Delete",
        cell: DeleteOpenItem,
        header: "",
        size: 5,
      }),
    ],
    [open_items, isUpdating]
  );

  const table = useReactTable({
    data: open_items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 5,
      maxSize: 100,
    },
  });

  const isAllRequestInfoAdded =
    open_items?.every(({ information_request }) => information_request) &&
    open_items.length !== 0;

  const onSubmit = async () => {
    try {
      await sendRequestInfo({
        channelUrl,
        csvId,
        customType: REQUESTED_INFO_CUSTOM_TYPE,
        groupId,
      }).unwrap();
      onClose();
      dispatch(setToOpenChatId(channelUrl));
      dispatch(openFloatingChat());
      successToast({ message: "Request sent" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content size="xxl">
        <Modal.Header>
          <Modal.Title>Request Info</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          {isLoading && open_items.length === 0 && <Loader />}
          {!isLoading && open_items.length > 0 && (
            <Table.Container>
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          key={header.id}
                          style={{
                            width: `${header.getSize()}%`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  <AnimatePresence>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <motion.tr
                          layout
                          key={row.original.uuid}
                          transition={{ duration: 0.1, ease: "easeOut" }}
                          animate={{ x: 0 }}
                          exit={{ x: -1000 }}
                          className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                        >
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Table.Cell
                                key={cell.id}
                                style={{
                                  width: `${cell.column.getSize()}%`,
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Table.Cell>
                            );
                          })}
                        </motion.tr>
                      );
                    })}
                  </AnimatePresence>
                </Table.Body>
              </Table.Content>
            </Table.Container>
          )}
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end">
          <Button
            customType="primary"
            disabled={!isAllRequestInfoAdded || isRequestSending}
            onClick={onSubmit}
            isLoading={isRequestSending}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
