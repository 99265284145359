import React from "react";

const BroadcastIcon = ({ color = "#706A85" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.875 6.25V15.9141C11.8741 16.0179 11.8993 16.1204 11.9485 16.2119C11.9977 16.3034 12.0691 16.381 12.1563 16.4375L13.0157 17.0078C13.0989 17.0634 13.1943 17.0982 13.2938 17.1092C13.3933 17.1202 13.494 17.1072 13.5874 17.0712C13.6808 17.0352 13.7642 16.9774 13.8306 16.9024C13.8969 16.8275 13.9443 16.7377 13.9688 16.6406L15 12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12.5C15.8288 12.5 16.6237 12.1708 17.2097 11.5847C17.7958 10.9986 18.125 10.2038 18.125 9.37499C18.125 8.54619 17.7958 7.75133 17.2097 7.16528C16.6237 6.57923 15.8288 6.24999 15 6.24999H11.875C11.875 6.24999 7.61719 6.24999 3.52344 2.8203C3.43238 2.74496 3.32183 2.69697 3.20461 2.68191C3.08738 2.66685 2.96829 2.68534 2.86115 2.73523C2.75401 2.78512 2.66321 2.86437 2.59929 2.96378C2.53536 3.06319 2.50094 3.17868 2.5 3.29687V15.4531C2.50094 15.5713 2.53536 15.6868 2.59929 15.7862C2.66321 15.8856 2.75401 15.9649 2.86115 16.0147C2.96829 16.0646 3.08738 16.0831 3.20461 16.0681C3.32183 16.053 3.43238 16.005 3.52344 15.9297C7.61719 12.5 11.875 12.5 11.875 12.5H15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BroadcastIcon;
