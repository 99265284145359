import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteTransactionMutation } from "store/apis/transactions";
import { closeSlider } from "store/slices/transactions";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { DeleteJournalEntryTable } from "./DeleteJournalEntryTable";
import * as RECURRING_JOUNAL_ENTRY_UPDATE_TYPES from "constants/recurringJorunalEntryUpdateTypes";

export const DeleteJournalEntry = ({
  close,
  isOpen,
  transactionId,
}: ModalProps & {
  transactionId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const entityId = useCurrentEntityId();
  const [deleteTransaction, { isLoading: isTransactionDeleting }] =
    useDeleteTransactionMutation();

  const onTransactionDelete = async () => {
    try {
      await deleteTransaction({
        entityId,
        groupId,
        transactionId,
        event_update_type: RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT,
      }).unwrap();
      close();
      dispatch(closeSlider(transactionId));
      successToast({ message: "Journal Entry deleted!" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const {
    close: closeDeleteJournalEntryTableModal,
    isOpen: isDeleteJournalEntryTableModalOpen,
    open: openDeleteJournalEntryTableModal,
  } = useModal();

  const onDeleteRelatedEntries = () => {
    close();
    openDeleteJournalEntryTableModal();
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-text-text-30 t-text-subtext">
            Deleting this journal entry will cause changes in all associated
            reports.
            <br />
            This cannot be undone.
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button onClick={onDeleteRelatedEntries}>
              Delete related entries
            </Button>
            <Button
              customType="danger"
              isLoading={isTransactionDeleting}
              disabled={isTransactionDeleting}
              onClick={() => onTransactionDelete()}
            >
              Delete this entry
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      {isDeleteJournalEntryTableModalOpen && (
        <DeleteJournalEntryTable
          transactionId={transactionId}
          close={closeDeleteJournalEntryTableModal}
          isOpen={isDeleteJournalEntryTableModalOpen}
        />
      )}
    </>
  );
};
