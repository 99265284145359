import classNames from "classnames";
import { Badge } from "components/design/badge";
import ConditionalDynamicToolTip from "components/design/conditionalDynamicToolTip";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { PERCENT } from "constants/stripeCouponTypes";
import { CANT_EDIT_BECAUSE_TASK_ARCHIVED } from "constants/task";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { acceptAdhocPay } from "../../apis/acceptAdhocPay";
import authContext from "../../jwt_context&axios/authContext";
import ActiveTaskLog from "../../static/images/ActiveTaskLog.svg";
import GreenInfo from "../../static/images/GreenInfo.svg";
import TaskLog from "../../static/images/TaskLog.svg";
import { updateTaskState } from "./adhocTaskFunctions";
import TaskButtonBar from "./TaskButtonBar";
import TaskLogModal from "./taskLogModal";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { BackendError } from "types/utils/error";

export const AdhocAcceptPay = ({
  pricingAmount,
  prev,
  next,
  title,
  hasCustomerApproved,
  groupId,
  task,
  isArchived,
  parentRef,
}: {
  pricingAmount: any;
  prev: any;
  next: any;
  title: any;
  hasCustomerApproved: any;
  groupId: any;
  task: any;
  isArchived: any;
  parentRef: any;
}) => {
  const { alertToast } = useToast();
  const { authtoken, groupEntities } = useContext(authContext);
  const [isChecked, setChecked] = useState(false);
  const [isShowLog, setShowLog] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasCustomerApproved) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [hasCustomerApproved]);

  const updateToPrevState = () => updateTaskState(prev);
  const updateToNextState = async () => {
    try {
      if (!authtoken?.is_service_user && !authtoken?.is_any_service_user)
        await acceptAdhocPay(next, groupEntities?.data?.groups[0].uuid);
      else {
        await acceptAdhocPay(next, groupId);
      }
      window.location.reload();
    } catch (e: any) {
      alertToast({
        message: e?.response?.data?.error?.message,
      });
    }
  };

  const openLogModal = () => setShowLog(true);
  const closeLogModal = () => setShowLog(false);

  const openPaymentModal = () => {
    dispatch(setUsableCredit(true));
    dispatch(setPaymentTitle("Adhoc task"));
    setPaymentModalOpen(true);
  };

  const isPaid = task?.payment_status === "PAID";

  const coupon = task?.coupon;

  const discountOff =
    coupon?.discount_type === PERCENT
      ? `${coupon?.discount_rate}%`
      : `$${coupon?.discount_rate}`;

  let finalPrice = coupon?.discounted_price || pricingAmount;

  return loading ? (
    <div className="w-100">
      <Loader />
    </div>
  ) : (
    <div
      className={classNames("taxing scopeOfWorkContainer", {
        disabledForm: isArchived,
      })}
    >
      <div className="d-flex justify-content-between w-100">
        <h6 className="pricingSubtitle">Scope of Work and Inkle Fee</h6>
        <div className="actionContainer">
          <ConditionalDynamicToolTip
            condition={isArchived && CANT_EDIT_BECAUSE_TASK_ARCHIVED}
          >
            <img
              role="button"
              onClick={!isArchived ? openLogModal : () => {}}
              onMouseEnter={() => {
                setHovered(true);
              }}
              onMouseLeave={() => {
                setHovered(false);
              }}
              className="icon"
              src={isHovered ? ActiveTaskLog : TaskLog}
              alt="TaskLog"
            />
          </ConditionalDynamicToolTip>
        </div>
      </div>
      <div className="d-flex justify-content-between w-100">
        <span className="scopeOfWork content">{title}</span>
      </div>
      <div className="t-mt-8 t-flex t-w-full t-rounded-lg t-bg-surface-lighter-grey t-p-3">
        <div
          className={classNames(
            "t-border-transparent t-ml-3 t-w-1/2 t-border t-border-solid t-border-[transparent] t-text-text-100",
            {
              "!t-border-r-text-100": Boolean(task?.government_fee),
            }
          )}
        >
          <div className="t-text-overline">INKLE SERVICE FEE</div>
          {coupon && (
            <div className="t-mt-2 t-flex t-items-center">
              <span className="t-mr-2 t-text-body-sm t-text-text-30">
                {coupon.coupon_code}
              </span>
              <Badge color="light-green">
                <span className="t-text-caption t-font-medium">
                  {discountOff} off
                </span>
              </Badge>
            </div>
          )}
          <div className="t-mt-2 t-text-subtitle">${finalPrice}</div>
        </div>
        {Boolean(task?.government_fee) && (
          <div className="t-ml-8 t-w-1/2">
            <div className="t-text-overline">GOVERNMENT FEE</div>
            <div className="t-mt-2 t-text-subtitle">${task.government_fee}</div>
          </div>
        )}
      </div>
      {!authtoken?.is_service_user && !authtoken?.is_any_service_user && (
        <div className="pleaseNote w-100">
          <img src={GreenInfo} alt="GreenInfo" />
          <span className="title">PLEASE NOTE</span>
          <br />
          <span className="content">
            Inkle fee varies according to the defined scope of work. If the
            scope of work is edited, the Inkle fee stands to change. Please
            accept the inkle fee ONLY after the scope of work is finalized and
            discussed with Inkle admin.
          </span>
        </div>
      )}

      <div className="pricingCheckbox d-flex justify-content-end mb-4 w-100 t-mt-8">
        <ConditionalDynamicToolTip
          condition={isArchived && CANT_EDIT_BECAUSE_TASK_ARCHIVED}
        >
          <span>
            <Checkbox
              label="I accept the Inkle pricing for this filing"
              defaultChecked={hasCustomerApproved}
              checked={isChecked}
              onChange={() => setChecked((isChecked) => !isChecked)}
              disabled={isArchived || hasCustomerApproved}
              name="acceptInklePricing"
            />
          </span>
        </ConditionalDynamicToolTip>
      </div>
      {!isArchived && !hasCustomerApproved && (
        <TaskButtonBar parentRef={parentRef} justifyContent="between">
          <Button onClick={updateToPrevState}>Previous</Button>
          {task?.is_prepayment_required ? (
            <Button
              customType="primary"
              disabled={isPaid || !isChecked}
              onClick={openPaymentModal}
            >
              {isPaid ? "Paid" : "Pay now"}
            </Button>
          ) : (
            <Button
              customType="primary"
              disabled={hasCustomerApproved || !isChecked}
              onClick={updateToNextState}
            >
              Next
            </Button>
          )}
        </TaskButtonBar>
      )}
      {isShowLog && (
        <TaskLogModal
          closeModal={closeLogModal}
          taskLogs={task.adhoc_data_updates}
        />
      )}
      {isPaymentModalOpen && (
        <CheckoutModal
          open={isPaymentModalOpen}
          type="task"
          onClose={() => setPaymentModalOpen(false)}
          onInvoicePaid={updateToNextState}
          task={task}
        />
      )}
    </div>
  );
};
