const AuthFooter = () => {
  return (
    <span className="acknowledge">
      By continuing, you agree to Inkle's{" "}
      <a
        href="https://www.inkle.io/utility-pages/terms-conditions"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        href="https://www.inkle.io/utility-pages/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </span>
  );
};

export default AuthFooter;
