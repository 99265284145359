export const FOREIGN_CA = "FOREIGN_CA";
export const INVESTOR = "INVESTOR";

export const PROFILE_PIC_UPDATE = "Profile picture updated";
export const PROFILE_UPDATED = "Profile updated";
export const PASSWORD_UPDATE = "Password updated";
export const PASSWORD_UPDATE_FAIL = "Password update failed";

export const USER_PROFILE =
  "https://inkle-django-files.s3.amazonaws.com/static/uploads/Screenshot_2022-09-28_at_7.14.03_PM.png";
