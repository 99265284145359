import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import CallingGif from "components/animation/callRingingAnimation.json";
import Lottie from "lottie-react";
import React, { useState } from "react";
import PhoneCallError from "static/images/PhoneCallError.svg";
import { useMakePhoneCallFromCRMMutation } from "store/apis/chat";

export const CallFromAdmin = ({
  groupDetail,
  isOpen,
  close,
}: {
  groupDetail: { mobile: string; groupUserId: string; groupName: string };
  isOpen: boolean;
  close: () => void;
}) => {
  const [showPhoneCallingModal, setShowPhoneCallingModal] = useState(false);
  const [showErrorStateModal, setShowErrorStateModal] = useState(false);

  const [makeCall, { isLoading }] = useMakePhoneCallFromCRMMutation();
  const { mobile, groupUserId, groupName } = groupDetail;

  const onCallHandler = async () => {
    try {
      await makeCall({ groupUserId, mobile });
      setShowErrorStateModal(false);
      close();
      setShowPhoneCallingModal(true);
      setTimeout(() => {
        setShowPhoneCallingModal(false);
      }, 10000);
    } catch (e) {
      close();
      setShowErrorStateModal(true);
    }
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="small">
          <Modal.Header>
            <Modal.Title>Call user</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-mb-0 t-pr-3">
            Please ensure you want to call at &nbsp;
            <b>{mobile}</b> of <b>{groupName}</b> by proceeding further.
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <div>
                <Button onClick={close}>Cancel</Button>
              </div>
              <div>
                <Button
                  customType="primary"
                  isLoading={isLoading}
                  onClick={onCallHandler}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root
        open={showPhoneCallingModal}
        onOpenChange={() => setShowPhoneCallingModal(false)}
      >
        <Modal.Content size="small">
          <Modal.Header>
            <Modal.Title>Connecting your call</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-mb-11 t-mt-5 t-flex t-justify-center">
              <Lottie
                className="t-h-24 t-w-24"
                animationData={CallingGif}
                loop
              />
            </div>
            <div className="t-flex t-gap-4">
              <div className="t-flex t-h-6 t-w-6 t-justify-center t-rounded-[50%] t-border t-bg-i-surface-grey t-text-purple-50">
                1
              </div>
              <div>
                You'll receive a call on <b>{mobile}</b>
              </div>
            </div>
            <br />
            <div className="t-flex t-gap-4">
              <div className="t-flex t-h-6 t-w-6 t-justify-center t-rounded-[50%] t-bg-i-surface-grey t-text-purple-50">
                2
              </div>
              <div>Connecting you to an agent</div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root
        open={showErrorStateModal}
        onOpenChange={() => setShowErrorStateModal(false)}
      >
        <Modal.Content size="small">
          <div className="t-flex t-justify-end t-p-4 t-items-center">
            <Modal.Close />
          </div>
          <Modal.Body className="!t-mb-0 t-pt-2">
            <div className="t-flex t-flex-col">
              <div className="t-flex t-justify-center">
                <img
                  src={PhoneCallError}
                  alt="PhoneCallError"
                  width="160px"
                  height="99px"
                />
              </div>
              <div className="t-flex t-justify-center">
                Something went wrong!
              </div>
              <div className="t-flex t-justify-center t-pt-5">
                <Button customType="primary" onClick={onCallHandler}>
                  Try Again
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
