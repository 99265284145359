import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { AddEditAddress } from "components/fileTax/AddEditAddress";
import { Cross } from "components/icons/Cross";
import { AUTOFILL_ADDRESS_TYPE_TAG } from "constants/billing";
import { MAILROOM_ADDRESS } from "constants/mailRoom";
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikValues,
} from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import GrayPencil from "static/images/GrayPencil.svg";
import {
  useGetAutofillQuery,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { AddressAutofill } from "types/Models/addressAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { BackendError } from "types/utils/error";
import { RemoveForwardMessageModal } from "./RemoveForwardMessageAddress";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";

export const DefaultForwardMessage = ({
  isOpen,
  close,
  openForwardModal,
  defaultForwardAddress,
}: {
  isOpen: boolean;
  close: () => void;
  openForwardModal: () => void;
  defaultForwardAddress?: string;
}) => {
  const { data: tagsData = [] } = useGetTagsForCategoryQuery({
    tagType: AUTOFILL_ADDRESS_TYPE_TAG,
  });
  const entityId = useCurrentEntityId();

  const mailroomAddressTagId = tagsData.find(
    ({ title }) => title === MAILROOM_ADDRESS
  )?.uuid;

  const { uuid: groupId } = useCurrentGroupContext();
  const { data: autofills = [] } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "addresses",
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { alertToast, successToast } = useToast();
  const addModal = useModal();
  const deleteModal = useModal();
  const editModal = useModal();
  const [detailsForEdit, setDetailsForEdit] = useState<
    BankAutofill | AddressAutofill | PersonaAutofill | undefined
  >();
  const [updateAutofill, { isLoading: isUpdating }] =
    useUpdateAutofillMutation();

  const openEdit = (
    autofillDetails: BankAutofill | AddressAutofill | PersonaAutofill
  ) => {
    setDetailsForEdit(autofillDetails);
    editModal.open();
  };

  const openDelete = (
    autofillDetails: BankAutofill | AddressAutofill | PersonaAutofill,
    setFieldValue: FormikHelpers<{}>["setFieldValue"]
  ) => {
    if (defaultForwardAddress) {
      setDetailsForEdit(autofillDetails);
      deleteModal.open();
      return;
    }

    setFieldValue("address", "");
    setDetailsForEdit(undefined);
  };

  const updateMailroomForwardAddress = async (values: FormikValues) => {
    try {
      const currentSelectedAutofillDetails = autofills.find(
        ({ uuid }) => uuid === values.address
      );
      await updateAutofill({
        fields: {
          ...currentSelectedAutofillDetails,
          tag_id: mailroomAddressTagId,
        },
        groupId,
        uuid: currentSelectedAutofillDetails?.uuid || "",
        autofillKey: "addresses",
        entityId,
      }).unwrap();
      successToast({ message: "Address saved" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onClose = () => {
    addModal.close();
    editModal.close();
    openForwardModal();
  };

  const closeRemoveModal = () => {
    setDetailsForEdit(undefined);
    deleteModal.close();
  };

  return (
    <>
      {isOpen && (
        <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
          <Modal.Content useCustomOverlay>
            <Modal.Header>
              <Modal.Title>Default Forwarding Address</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Formik
              validateOnChange={false}
              enableReinitialize
              initialValues={{
                address: detailsForEdit?.uuid || defaultForwardAddress || "",
              }}
              onSubmit={updateMailroomForwardAddress}
            >
              {({ values, setFieldValue }) => {
                const currentSelectedAutofillDetails = autofills.find(
                  ({ uuid }) => uuid === values.address
                );
                const shouldShowDetailsCard = Boolean(
                  currentSelectedAutofillDetails
                );

                return (
                  <Form className="t-m-0">
                    <Modal.Body className="t-flex t-gap-4 t-flex-col t-w-full">
                      <div>
                        <SelectAutofillCombobox
                          type="addresses"
                          name="address"
                          label="Address"
                          withForm
                          entityId={entityId}
                          selected={values.address}
                          onSelect={(newValue) =>
                            setFieldValue("address", newValue.uuid)
                          }
                        />

                        {shouldShowDetailsCard && (
                          <div className="t-mt-3">
                            <div className="t-border t-border-solid t-border-neutral-0 t-p-4 t-rounded-lg">
                              <div className="t-flex t-justify-between">
                                <div className="t-text-body t-flex t-items-center">
                                  {
                                    currentSelectedAutofillDetails?.autofill_string
                                  }
                                </div>
                                <div className="t-flex t-gap-2">
                                  <Button
                                    onClick={() =>
                                      openEdit(currentSelectedAutofillDetails!)
                                    }
                                    customType="transparent"
                                    size="small"
                                    type="button"
                                  >
                                    <img
                                      src={GrayPencil}
                                      className="t-h-4 t-m-1"
                                      alt="GrayPencil"
                                    />
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      openDelete(
                                        currentSelectedAutofillDetails!,
                                        setFieldValue
                                      )
                                    }
                                    customType="transparent"
                                    size="small"
                                    type="button"
                                  >
                                    <span className="t-text-text-30">
                                      <Cross />
                                    </span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="t-justify-end t-flex t-gap-3">
                      <Button onClick={close}>Cancel</Button>
                      <Button
                        customType="primary"
                        isLoading={isUpdating}
                        disabled={isUpdating || !Boolean(values.address)}
                        type="submit"
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Content>
        </Modal.Root>
      )}
      <AddEditAddress
        isOpenAdd={addModal.isOpen}
        isOpenEdit={editModal.isOpen}
        onClose={onClose}
        onSuccess={setDetailsForEdit}
        groupId={groupId}
        entityId={entityId}
        isEditFlow={editModal.isOpen}
        autofillKey="addresses"
        //@ts-ignore
        initialValue={detailsForEdit}
      />
      {detailsForEdit?.uuid && (
        <RemoveForwardMessageModal
          isOpen={deleteModal.isOpen}
          close={closeRemoveModal}
          addressIdToRemove={detailsForEdit?.uuid}
        />
      )}
    </>
  );
};
