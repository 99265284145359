import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { Search } from "components/DesignSystem/Search/Search";
import { ArrowRight } from "components/icons/ArrowRight";
import { LoadingIcon } from "components/icons/LoadingIcon";
import Modal from "components/Modal/Modal";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  useGetAllCouponsQuery,
  useGetAllEmailsQuery,
  useGetAllSalesCRMGroupsQuery,
  useUpdateGroupDataMutation,
} from "store/apis/salesCrm";
import { debounce } from "utils/debouncing";

import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik, FormikValues } from "formik";
import { useToast } from "hooks/useToast";
import {
  CouponResponse,
  EmailResponse,
  ReferrerModalProps,
} from "types/Models/salescrm";

type ModalProps = {
  groupId: string;
  attributionType: "COUPON" | "CUSTOMER" | "TEXT";
  payload: any;
  setPayload: (newState: React.SetStateAction<{} | null>) => void;
  setShowConfirmModal: (newState: React.SetStateAction<boolean>) => void;
};

const ConfirmModal = ({
  groupId,
  attributionType,
  payload,
  setPayload,
  showConfirmModal,
  setShowConfirmModal,
}: ModalProps & { showConfirmModal: boolean }) => {
  const { alertToast } = useToast();
  const [updateGroup, { isLoading: isPatchBtnLoading }] =
    useUpdateGroupDataMutation();

  const updateAttribution = async () => {
    try {
      const payLoad =
        attributionType === "CUSTOMER"
          ? { referer_email_id: payload?.referer_email_id }
          : { coupon_code: payload?.coupon_code };

      await updateGroup({ groupId, payLoad }).unwrap();
      setPayload(null);
    } catch (error: any) {
      setPayload(null);
      alertToast({ message: error?.data?.error?.message });
    }
    setShowConfirmModal(false);
  };

  return (
    <Modal.Root
      open={showConfirmModal}
      onOpenChange={() => {
        setPayload(null);
        setShowConfirmModal(false);
      }}
    >
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="t-w-[440px]">
          <Modal.Close />
          <Modal.Title className="t-px-3 t-pb-5">Are you sure?</Modal.Title>
          <Divider />
          <Modal.Description>
            <div className="t-px-3 t-py-5">
              {attributionType === "CUSTOMER" ? (
                <div>
                  Credits may be deposited to {payload?.referer_email_id}&nbsp;
                  immediately and cannot be reversed.
                </div>
              ) : (
                <div>
                  Discount may be applied immediately and cannot be reversed.
                </div>
              )}
            </div>
            <div className="t-mt-4 t-flex t-justify-end t-gap-3">
              <Button
                onClick={() => {
                  setShowConfirmModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={updateAttribution}
                isLoading={isPatchBtnLoading}
                disabled={isPatchBtnLoading}
                customType="primary"
              >
                Confirm
              </Button>
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

const DescriptionComponent = ({
  groupId,
  attributionType,
  payload,
  setPayload,
  setShowAddAttributionModal,
  setShowConfirmModal,
}: ModalProps & {
  setShowAddAttributionModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const { alertToast } = useToast();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [userProfiles, setUserProfiles] = useState<EmailResponse[]>([]);

  const [updateGroup, { isLoading: isPatchBtnLoading }] =
    useUpdateGroupDataMutation();

  const { isFetching } = useGetAllSalesCRMGroupsQuery({
    pageNumber,
    searchTerm,
    viewFilter: "SALES_CRM",
  });

  const { data: allEmails, isLoading } = useGetAllEmailsQuery(
    {
      pageNumber,
      searchTerm,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: allCoupons = [] } = useGetAllCouponsQuery();

  let { current_page, total_pages, profiles } = allEmails || {};

  const handleSearchEmail = debounce((e: any) => {
    const { value } = e.target;
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchTerm(null);
    }
    setPageNumber(1);
  });

  const setPagination = debounce(() => {
    if (pageNumber < totalPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  });

  const updateText = async (values: FormikValues) => {
    try {
      await updateGroup({
        groupId,
        payLoad: { referer_text: values?.referer_text },
      }).unwrap();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
    setShowAddAttributionModal(false);
  };

  useEffect(() => {
    setPageNumber(current_page);
    setTotalPages(total_pages);
    if (pageNumber === 1) {
      setUserProfiles(profiles);
    } else {
      setUserProfiles((users) => {
        if (users?.length > 0) return [...users, ...profiles];
        return [...profiles];
      });
    }
  }, [profiles]);

  if (attributionType === "CUSTOMER") {
    return (
      <div className="t-mt-5">
        <DropDown.Root>
          <DropDown.Trigger asChild>
            <div className="t-flex t-flex-col t-gap-[6px]">
              <div className="t-font-sans t-text-caption t-text-neutral-80">
                Add customer
              </div>
              <div className="secondary-border t-flex t-items-center t-justify-between  t-px-2 t-py-2.5 t-text-body t-font-medium t-drop-shadow-i-dropdown">
                {!isLoading ? (
                  <>
                    <div className="t-flex t-items-center t-gap-1">
                      <span>{payload?.referer_email_id}</span>
                    </div>

                    <div className="t-flex t-gap-2">
                      {(isPatchBtnLoading || isFetching) && (
                        <div className="t-origin-center t-animate-spin">
                          <LoadingIcon />
                        </div>
                      )}
                      <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                        <ArrowRight stroke="2.5" color="currentColor" />
                      </div>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </DropDown.Trigger>
          <DropDown.Portal>
            <DropDown.Content
              sideOffset={8}
              side="bottom"
              className="t-w-[455px]"
            >
              <div className="t-flex t-h-12 t-items-center t-justify-center t-p-2">
                <Search
                  placeholder="Search referrer..."
                  onChange={handleSearchEmail}
                  block
                  autoFocus
                />
              </div>
              <div
                className="t-flex t-max-h-72 t-flex-col t-overflow-auto"
                id="scrollableDiv"
              >
                <InfiniteScroll
                  dataLength={userProfiles?.length}
                  next={setPagination}
                  hasMore={true}
                  inverse={false}
                  scrollableTarget="scrollableDiv"
                  className="t-flex t-flex-col t-items-start"
                  loader={
                    <h6 className="t-w-full t-text-center">Loading...</h6>
                  }
                  endMessage={
                    <p className="t-text-center">
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {userProfiles?.map(({ email, uuid }: EmailResponse) => (
                    <DropDown.Item
                      textValue=""
                      onSelect={() => {
                        setPayload({ referer_email_id: email, uuid: uuid });
                      }}
                      key={uuid}
                    >
                      {email}
                    </DropDown.Item>
                  ))}
                </InfiniteScroll>
              </div>
            </DropDown.Content>
          </DropDown.Portal>
        </DropDown.Root>

        <div className="t-mt-4 t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowAddAttributionModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowConfirmModal(true);
              setShowAddAttributionModal(false);
              setSearchTerm(null);
            }}
            customType="primary"
          >
            Add
          </Button>
        </div>
      </div>
    );
  }

  if (attributionType === "COUPON") {
    return (
      <div className="t-mt-5">
        <DropDown.Root>
          <DropDown.Trigger asChild>
            <div className="t-flex t-flex-col t-gap-[6px]">
              <div className="t-font-sans t-text-caption t-text-neutral-80">
                Add coupon
              </div>
              <div className="secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-2.5 t-text-body t-font-medium t-drop-shadow-i-dropdown">
                <div className="t-flex t-w-full t-justify-between t-gap-2">
                  <div>{payload?.coupon_code ? payload?.coupon_code : ""}</div>
                  <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                    <ArrowRight stroke="2.5" color="currentColor" />
                  </div>
                </div>
              </div>
            </div>
          </DropDown.Trigger>
          <DropDown.Content
            sideOffset={8}
            side="bottom"
            className="t-w-[455px] t-max-h-72 t-overflow-auto"
          >
            {allCoupons?.map(({ uuid, coupon_code }: CouponResponse) => (
              <DropDown.Item
                onSelect={() => {
                  setPayload({ coupon_code });
                }}
                key={uuid}
              >
                {coupon_code}
              </DropDown.Item>
            ))}
          </DropDown.Content>
        </DropDown.Root>
        <div className="t-mt-4 t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowAddAttributionModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowConfirmModal(true);
              setShowAddAttributionModal(false);
            }}
            customType="primary"
          >
            Add
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="t-mt-5">
      <Formik initialValues={{ referer_text: "" }} onSubmit={updateText}>
        {({ values }) => {
          return (
            <Form className="all:unset">
              <TextInput name="referer_text" label="Add text" />
              <div className="t-mt-4 t-flex t-justify-end t-gap-3">
                <Button
                  onClick={() => {
                    setShowAddAttributionModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" customType="primary">
                  Add
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const ReferrerModal = ({
  groupId,
  groups,
  showAddAttributionModal,
  setShowAddAttributionModal,
  pageNumber: groupPageNumber,
  searchTerm: groupSearchTerm,
  attributionType,
}: ReferrerModalProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [payload, setPayload] = useState<object | null>(null);

  return (
    <>
      <Modal.Root
        open={showAddAttributionModal}
        onOpenChange={() => {
          setPayload(null);
          setShowAddAttributionModal(false);
        }}
      >
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Close />
            <Modal.Title className="t-pb-5 ">Add attribution</Modal.Title>
            <Divider />
            <Modal.Description>
              <DescriptionComponent
                attributionType={attributionType}
                groupId={groupId}
                setShowAddAttributionModal={setShowAddAttributionModal}
                setShowConfirmModal={setShowConfirmModal}
                payload={payload}
                setPayload={setPayload}
              />
            </Modal.Description>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
      <ConfirmModal
        groupId={groupId}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        attributionType={attributionType}
        payload={payload}
        setPayload={setPayload}
      />
    </>
  );
};

export default ReferrerModal;
