import React from "react";
import * as RDialog from "@radix-ui/react-dialog";
import Modal from "components/DesignSystem/Modal/Modal";
import { Button } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import TaxPage from "./taxPage";
import { useHistory } from "react-router-dom";

export const FilingDetails = (props: {
  isFromServices?: boolean;
  parentRoute?: string;
  addEntityInUrl?: boolean;
}) => {
  const history = useHistory();

  const handleBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  };

  return (
    <RDialog.Root open={true} onOpenChange={handleBack}>
      <RDialog.Portal>
        <Modal.Overlay className="!t-opacity-80 t-bg-image-preview-overlay !t-bg-surface-transparent" />
        <RDialog.Content className="t-fixed t-z-modal t-top-[50%] t-left-[50%] t-translate-x-[-50%] t-translate-y-[-50%] t-w-full t-pr-6 t-h-full t-bg-surface t-grid t-grid-rows-[3%_97%]">
          <div className="t-flex t-w-full t-justify-end t-mt-2">
            <RDialog.Close asChild>
              <span className="t-text-text-100">
                <Button customType="icon" size="small" onClick={handleBack}>
                  <Cross color="currentColor" />
                </Button>
              </span>
            </RDialog.Close>
          </div>
          <TaxPage {...props} />
        </RDialog.Content>
      </RDialog.Portal>
    </RDialog.Root>
  );
};
