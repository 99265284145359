import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { DividerLine } from "components/icons/DividerLine";
import { Form, Formik } from "formik";
import { useFilters } from "hooks/useFilter";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { usePagination } from "hooks/usePagination";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import {
  useCreateChatBroadcastMutation,
  useGetBroadcastChannelsQuery,
} from "store/apis/broadcast";
import { useLazyGetAllGroupsQuery } from "store/apis/crm";
import { useGetAllBaseTaskTemplatesQuery } from "store/apis/task";
import { useGetTaskStatesQuery } from "store/apis/taskList";
import { CompanyGroup } from "types/Models/groups";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";

export const BroadcastMessage = ({ onClose }: { onClose: () => void }) => {
  const { values, updateFilter } = useFilters({
    initialValue: {
      BRAND_NAME: [] as string[],
      SUBSCRIPTION: [] as string[],
      AGENT: [] as string[],
      CHANNEL_TYPE: [] as string[],
      TASK_STATUS: [] as string[],
      SEASON: [] as string[],
    },
  });

  const updateFilterCallback =
    (name: Parameters<typeof updateFilter>[0]) =>
    (newValues: Parameters<typeof updateFilter>[1]) => {
      updateFilter(name, newValues);
    };

  const [deselectedChannels, setDeselectedChannels] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [groupSearchValue, setGroupSearchValue] = useState("");
  const [templateSearchValue, setTempateSearchValue] = useState("");

  const { data: agents } = useGetAssignAgentsQuery();
  const { data: taskBaseTemplates } = useGetAllBaseTaskTemplatesQuery({
    searchString: templateSearchValue,
  });

  const {
    pageNum,
    setTotalPage,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
  } = usePagination();
  const { successToast, alertToast } = useToast();

  const filters = {
    seasons: values.SEASON.join(","),
    channel_types: values.CHANNEL_TYPE.join(","),
    task_status_ids: values.TASK_STATUS.join(","),
    subscription_types: values.SUBSCRIPTION.join(","),
    agent_ids: values.AGENT.join(","),
    group_ids: values.BRAND_NAME.join(","),
  };

  const isFilterValuesEmpty = Object.values(filters).every((v) => !v);

  const { data: broadcastChannels } = useGetBroadcastChannelsQuery({
    ...filters,
    search_term: search,
    page_num: pageNum,
  });

  useEffect(() => {
    if (broadcastChannels) {
      setTotalPage(broadcastChannels.total_pages);
    }
  }, [Boolean(broadcastChannels), broadcastChannels?.total_pages]);

  const { data: taskStatuses } = useGetTaskStatesQuery();

  const { data: groups, loadNext } = usePaginatedQuery<{
    company_groups?: CompanyGroup[];
  }>(useLazyGetAllGroupsQuery, "company_groups", {
    search_term: groupSearchValue,
  });

  const onGroupSearch = debounce((value) => setGroupSearchValue(value));
  const onBroadcastChannelSearch = debounce((value) => setSearch(value));

  const taskStatusTypes = [
    {
      uuid: "not_started",
      name: "Not Started",
    },
    ...(taskStatuses || []),
  ];

  const channelTypes = [
    {
      label: "General Help",
      value: "GROUP_CUSTOM_TYPE",
    },
    {
      label: "Mailroom",
      value: "MAILROOM_CHANNEL",
    },
    {
      label: "Announcemets",
      value: "ANNOUNCEMENTS_CHANNEL",
    },
    {
      label: "Books channel",
      value: "BOOKS_CHANNEL",
    },
    ...(taskBaseTemplates?.map((t) => ({ value: t.uuid, label: t.title })) ||
      []),
  ];

  const [createBroadcast, { isLoading: broadcasting }] =
    useCreateChatBroadcastMutation();

  const broadcastMessage = async ({ message }: { message: string }) => {
    try {
      await createBroadcast({
        message,
        filters,
        excludedChannels: deselectedChannels.join(","),
      }).unwrap();
      successToast({ message: "Broadcast message will be sent!" });
      onClose();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Broadcast Message</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="t-h-full">
        <DashboardContainer className="t-flex t-flex-col t-gap-4 t-h-full">
          <DashboardContainer.Header>
            <div className="t-flex t-flex-col t-gap-4">
              <div className="t-flex t-gap-4 t-items-center t-justify-between">
                <Search
                  onChange={(e) => onBroadcastChannelSearch(e.target.value)}
                  placeholder="Search"
                />
                {broadcastChannels && (
                  <Pagination
                    totalItemCount={broadcastChannels?.total_count}
                    totalPage={broadcastChannels?.total_pages}
                    currentPage={broadcastChannels?.current_page}
                    itemsPerPage={broadcastChannels?.per_page}
                    goToFirstPage={goToFirstPage}
                    goToPrevPage={goToPrevPage}
                    goToNextPage={goToNextPage}
                    goToLastPage={goToLastPage}
                  />
                )}
              </div>
              <Filter.Root
                defaultValue="BRAND_NAME"
                title="Filter"
                capsule={
                  <>
                    {values.BRAND_NAME.length > 0 && (
                      <Chip
                        onClose={() => updateFilter("BRAND_NAME", [])}
                        isActive
                        filterType="BRAND_NAME"
                      >
                        Brand Name ({values.BRAND_NAME.length})
                      </Chip>
                    )}

                    {values.SUBSCRIPTION.length > 0 && (
                      <Chip
                        onClose={() => updateFilter("SUBSCRIPTION", [])}
                        isActive
                        filterType="SUBSCRIPTION"
                      >
                        Subscription ({values.SUBSCRIPTION.length})
                      </Chip>
                    )}

                    {values.AGENT.length > 0 && (
                      <Chip
                        onClose={() => updateFilter("AGENT", [])}
                        isActive
                        filterType="AGENT"
                      >
                        Agent ({values.AGENT.length})
                      </Chip>
                    )}

                    {values.CHANNEL_TYPE.length > 0 && (
                      <Chip
                        onClose={() => updateFilter("CHANNEL_TYPE", [])}
                        isActive
                        filterType="CHANNEL_TYPE"
                      >
                        Channel ({values.CHANNEL_TYPE.length})
                      </Chip>
                    )}

                    {values.TASK_STATUS.length > 0 && (
                      <Chip
                        onClose={() => updateFilter("TASK_STATUS", [])}
                        isActive
                        filterType="TASK_STATUS"
                      >
                        Task status ({values.TASK_STATUS.length})
                      </Chip>
                    )}

                    {values.SEASON.length > 0 && (
                      <Chip
                        onClose={() => updateFilter("SEASON", [])}
                        isActive
                        filterType="SEASON"
                      >
                        Season ({values.SEASON.length})
                      </Chip>
                    )}
                  </>
                }
              >
                <Filter.Portal>
                  <Filter.List>
                    <Filter.ListItem value="BRAND_NAME">
                      Brand Name
                    </Filter.ListItem>
                    <Filter.ListItem value="SUBSCRIPTION">
                      Subscriptions
                    </Filter.ListItem>
                    <Filter.ListItem value="AGENT">Agent</Filter.ListItem>
                    <Filter.ListItem value="CHANNEL_TYPE">
                      Channel
                    </Filter.ListItem>
                    <Filter.ListItem value="TASK_STATUS">
                      Task status
                    </Filter.ListItem>
                    <Filter.ListItem value="SEASON">Season</Filter.ListItem>
                  </Filter.List>
                  <Filter.Body value="BRAND_NAME" block>
                    {groups?.company_groups && (
                      <MultiSelectFilter
                        onSearchValueChange={onGroupSearch}
                        hasMore={true}
                        totalCount={groups.company_groups.length}
                        loadNext={loadNext}
                        onChange={updateFilterCallback("BRAND_NAME")}
                        options={groups.company_groups.map((group) => ({
                          value: group.uuid,
                          label: group.name,
                        }))}
                        selected={values.BRAND_NAME}
                      />
                    )}
                  </Filter.Body>

                  <Filter.Body value="SUBSCRIPTION" block>
                    <MultiSelectFilter
                      onChange={(newValues) =>
                        updateFilter("SUBSCRIPTION", newValues)
                      }
                      options={[
                        {
                          value: "PRO",
                          label: "Pro",
                        },
                        {
                          value: "STANDARD",
                          label: "Standard",
                        },
                        {
                          value: "FREE",
                          label: "Free",
                        },
                        {
                          value: "UNSUBSCRIBED",
                          label: "Unsubscribed",
                        },
                        {
                          value: "OVERDUE",
                          label: "Overdue",
                        },
                        {
                          value: "PAYG",
                          label: "PAYG",
                        },
                        {
                          value: "BYOA",
                          label: "BYOA",
                        },
                      ]}
                      selected={values.SUBSCRIPTION}
                    />
                  </Filter.Body>

                  {agents && (
                    <Filter.Body value="AGENT" block>
                      <MultiSelectFilter
                        onChange={updateFilterCallback("AGENT")}
                        options={agents?.map((agent) => ({
                          value: agent.profile_id,
                          label: agent.name,
                        }))}
                        selected={values.AGENT}
                      />
                    </Filter.Body>
                  )}

                  <Filter.Body value="CHANNEL_TYPE" block>
                    <MultiSelectFilter
                      onChange={updateFilterCallback("CHANNEL_TYPE")}
                      options={channelTypes}
                      selected={values.CHANNEL_TYPE}
                    />
                  </Filter.Body>

                  {taskStatuses && (
                    <Filter.Body value="TASK_STATUS" block>
                      <MultiSelectFilter
                        onChange={updateFilterCallback("TASK_STATUS")}
                        options={taskStatusTypes?.map((status) => ({
                          value: status.uuid,
                          label: status.name,
                        }))}
                        selected={values.TASK_STATUS}
                      />
                    </Filter.Body>
                  )}

                  <Filter.Body value="SEASON" block>
                    <MultiSelectFilter
                      onChange={updateFilterCallback("SEASON")}
                      options={[
                        "2021",
                        "2022",
                        "2023",
                        "2024",
                        "2025",
                        "2026",
                      ].map((v) => ({ label: v, value: v }))}
                      selected={values.SEASON}
                    />
                  </Filter.Body>
                </Filter.Portal>
              </Filter.Root>
            </div>
          </DashboardContainer.Header>
          <DashboardContainer.Content className="t-h-[350px]">
            <div className="t-h-full">
              {broadcastChannels?.channels.length === 0 && (
                <div className="t-flex t-justify-center t-items-center t-h-full t-flex-col">
                  <p className="t-text-subtext t-text-text-100 t-m-0">
                    No channels found
                  </p>
                  <p className="t-text-body-sm t-text-text-30">
                    Please change the filters to see more channels.
                  </p>
                </div>
              )}
              {broadcastChannels?.channels.map((channel) => (
                <label
                  key={channel.uuid}
                  htmlFor={channel.uuid}
                  className="t-text-body t-flex t-items-start py-2 px-2 gap-2 hover:t-bg-surface-lighter-grey t-cursor-pointer"
                >
                  <div className="t-p-0.5">
                    <Checkbox
                      name={channel.uuid}
                      onChange={(e) =>
                        setDeselectedChannels((s) => {
                          if (!e.target.checked) {
                            return [...s, channel.uuid];
                          }
                          return s.filter((c) => c !== channel.uuid);
                        })
                      }
                      checked={
                        deselectedChannels.includes(channel.uuid)
                          ? false
                          : !isFilterValuesEmpty
                      }
                    />
                  </div>
                  <div className="t-cursor-pointer">
                    <div className="t-flex t-gap-1 t-items-center">
                      <span className="t-text-subtext">{channel.name}</span>
                      {channel.season && (
                        <span className="t-text-neutral-20">
                          <DividerLine />
                        </span>
                      )}
                      <span>{channel.season}</span>
                    </div>
                    <div className="t-flex t-gap-1 t-items-center">
                      <span className="t-flex t-gap-1 t-items-center t-text-text-30">
                        {channel.company_entity && (
                          <span className="t-flex t-gap-1 t-items-center">
                            <ReactCountryFlag
                              svg
                              countryCode={channel.company_entity.country_code}
                            />
                            <span>{channel.company_entity.name}</span>
                          </span>
                        )}
                        {channel.company_entity && channel.group_name && (
                          <span className="t-text-neutral-20">
                            <DividerLine />
                          </span>
                        )}
                        <span>{channel.group_name}</span>
                      </span>
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Modal.RawClose asChild>
          <Button>Cancel</Button>
        </Modal.RawClose>
        <Modal.Root>
          <Modal.Trigger asChild>
            <Button
              customType="primary"
              disabled={broadcastChannels?.channels.length === 0}
            >
              Next
            </Button>
          </Modal.Trigger>
          <Formik initialValues={{ message: "" }} onSubmit={broadcastMessage}>
            {({ submitForm }) => (
              <Modal.Content>
                <Modal.Header>
                  <Modal.Title>Broascast Message</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Form className="t-m-0">
                  <Modal.Body>
                    <TextArea
                      name="message"
                      placeholder="Enter the broadcast message"
                    />
                  </Modal.Body>
                </Form>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button type="button">Go back</Button>
                  </Modal.RawClose>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={broadcasting}
                    disabled={broadcasting}
                  >
                    Broadcast
                  </Button>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            )}
          </Formik>
        </Modal.Root>
      </Modal.FooterButtonGroup>
    </>
  );
};
