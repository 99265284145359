export const BOOKKEEPING_SOFTWARE = "BOOKKEEPING_SOFTWARE";
export const BOOKKEEPING = "BOOKKEEPING";
export const PLATFORM = "PLATFORM";
export const REGISTERED_AGENT = "REGISTERED_AGENT";
export const MAILROOM = "MAILROOM";
export const BOOKKEEPING_SOFTWARE_MANAGE = "BOOKKEEPING_SOFTWARE_MANAGE";
export const BOOKKEEPING_MANAGE = "BOOKKEEPING_MANAGE";
export const REGISTERED_AGENT_MANAGE = "REGISTERED_AGENT_MANAGE";
export const MAILROOM_MANAGE = "MAILROOM_MANAGE";
export const _MANAGE = "_MANAGE";
export const GROUP_BOOKS_CONSOLIDATION = "GROUP_BOOKS_CONSOLIDATION";
export const GROUP_BOOKS_CONSOLIDATION_MANAGE =
  "GROUP_BOOKS_CONSOLIDATION_MANAGE";
export const BOOKS_PLUS_PLAN = "BOOKS_PLUS_PLAN";
export const BOOKS_PLUS_PLAN_MANAGE = "BOOKS_PLUS_PLAN_MANAGE";
