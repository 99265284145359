import { default as classNames, default as cx } from "classnames";

const TicketIcon = ({ priority = "P0" }) => {
  const colours = {
    P0: {
      foregroundColour: "#D97682",
    },
    P1: {
      foregroundColour: "#F8C471",
    },
    P2: {
      foregroundColour: "#B8B5C2",
    },
  };
  return (
    <div
      className={cx("t-p-3 t-rounded-full", {
        "t-bg-red-10": priority === "P0",
        "t-bg-yellow-10": priority === "P1",
        "t-bg-neutral-0": priority === "P2",
      })}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1994 3.53C13.4253 3.48404 13.6283 3.36146 13.7742 3.18299C13.9201 3.00452 13.9999 2.78114 14 2.55062V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0H1C0.734785 0 0.480431 0.105357 0.292895 0.292893C0.105359 0.48043 1.90547e-06 0.734784 1.90547e-06 1V2.55062C0.000128294 2.78114 0.0798866 3.00452 0.22578 3.18299C0.371674 3.36146 0.574744 3.48404 0.800627 3.53C1.13838 3.60004 1.44167 3.78435 1.65941 4.05188C1.87716 4.31941 1.99604 4.65381 1.99604 4.99875C1.99604 5.34369 1.87716 5.67809 1.65941 5.94562C1.44167 6.21315 1.13838 6.39746 0.800627 6.4675C0.574323 6.51354 0.370931 6.6365 0.224989 6.81548C0.0790466 6.99446 -0.000448739 7.21844 1.90547e-06 7.44938V9C1.90547e-06 9.26522 0.105359 9.51957 0.292895 9.70711C0.480431 9.89464 0.734785 10 1 10H13C13.2652 10 13.5196 9.89464 13.7071 9.70711C13.8946 9.51957 14 9.26522 14 9V7.44938C13.9999 7.21886 13.9201 6.99547 13.7742 6.81701C13.6283 6.63854 13.4253 6.51596 13.1994 6.47C12.8616 6.39996 12.5583 6.21565 12.3406 5.94812C12.1228 5.68059 12.004 5.34619 12.004 5.00125C12.004 4.65631 12.1228 4.32191 12.3406 4.05438C12.5583 3.78685 12.8616 3.60254 13.1994 3.5325V3.53ZM1 7.45C1.56481 7.33494 2.07251 7.02825 2.43715 6.58183C2.80179 6.13542 3.00097 5.57672 3.00097 5.00031C3.00097 4.4239 2.80179 3.8652 2.43715 3.41879C2.07251 2.97238 1.56481 2.66568 1 2.55062V1H4.5V9H1V7.45Z"
          fill={colours[priority].foregroundColour}
        />
      </svg>
    </div>
  );
};
export default TicketIcon;
