export const Home = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_6262_43)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.19255 1.61231C7.4135 1.4112 7.70153 1.29974 8.0003 1.29974C8.29907 1.29974 8.5871 1.4112 8.80805 1.61231L8.81753 1.62094L13.8136 6.33868C13.9346 6.4501 14.0314 6.5852 14.0979 6.73563C14.1656 6.88855 14.2006 7.05393 14.2006 7.22116V13C14.2006 13.3182 14.0742 13.6235 13.8491 13.8485C13.6241 14.0736 13.3189 14.2 13.0006 14.2H9.99999C9.68173 14.2 9.3765 14.0736 9.15146 13.8485C8.92641 13.6235 8.79999 13.3182 8.79999 13V10.2H7.19999V13C7.19999 13.3182 7.07356 13.6235 6.84852 13.8485C6.62347 14.0736 6.31825 14.2 5.99999 14.2H2.99999C2.68173 14.2 2.3765 14.0736 2.15146 13.8485C1.92642 13.6235 1.79999 13.3182 1.79999 13V7.22123C1.8 7.054 1.83497 6.88855 1.90265 6.73563C1.96923 6.5852 2.06601 6.45009 2.18696 6.33867L7.19255 1.61231ZM8.0003 2.77493L3.19999 7.30763V12.8H5.79999V9.99998C5.79999 9.68172 5.92642 9.37649 6.15146 9.15145C6.3765 8.92641 6.68173 8.79998 6.99999 8.79998H8.99999C9.31825 8.79998 9.62347 8.92641 9.84852 9.15145C10.0736 9.3765 10.2 9.68172 10.2 9.99998V12.8H12.8006V7.30763L8.0003 2.77493Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6262_43">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
