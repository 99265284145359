import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { useUpdateChatTicketMutation } from "store/apis/chatTicket";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { object, string } from "yup";

export const CreateChannelModal = ({
  close,
  isOpen,
  ticketId,
  messageId,
}: ModalProps & { ticketId: string; messageId?: string | null }) => {
  const { alertToast, successToast } = useToast();
  const [updateChatTicket] = useUpdateChatTicketMutation();

  const onSubmit = async ({ channelName }: { channelName: string }) => {
    try {
      await updateChatTicket({
        ticket_id: ticketId,
        create_ticket_channel: true,
        ticket_channel_name: channelName,
      }).unwrap();
      successToast({ message: "Chat channel created" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        initialValues={{ channelName: "" }}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
        validationSchema={object().shape({
          channelName: string().required("Channel name is required"),
        })}
      >
        {({ isValid, isSubmitting, submitForm }) => {
          return (
            <Form className="t-m-0">
              <Modal.Content>
                <Modal.Header>
                  <Modal.Title>Create chat channel</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <TextInput
                    label="Channel name"
                    placeholder="Enter channel name"
                    name="channelName"
                  />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Button onClick={close} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={submitForm}
                  >
                    Confirm
                  </Button>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Form>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
