import classNames from "classnames";
import { ReactNode } from "react";
import { useDrop, useDrag } from "react-dnd";
import { useParams } from "react-router-dom";

type FileFolderDnDWrapperProps = {
  type: "FILE" | "FOLDER";
  uuid: string;
  children: ({
    isOver,
    isDragging,
  }: {
    isOver: boolean;
    isDragging: boolean;
  }) => ReactNode;
  onDrop: ({ folderId, fileId }: { folderId: string; fileId: string }) => void;
};

export const FileFolderDnDWrapper = ({
  type,
  uuid,
  onDrop,
  children,
}: FileFolderDnDWrapperProps) => {
  const handleDrop = ({ fileId }: { fileId: string }) => {
    onDrop({
      folderId: uuid,
      fileId,
    });
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "file",
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "file",
      item: { fileId: uuid },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [uuid]
  );

  const ref = type === "FILE" ? drag : drop;

  return <div ref={ref}>{children({ isOver, isDragging })}</div>;
};

export const BreadcrumbDND = ({
  name,
  folderId,
  onDrop,
}: {
  name: string;
  folderId: string;
  onDrop: ({ folderId, fileId }: { folderId: string; fileId: string }) => void;
}) => {
  const { docId } = useParams<{ docId: string }>();

  const [{ isOver, isDroppable }, drop] = useDrop(() => ({
    accept: "file",
    drop: ({ fileId }: { fileId: string }) => onDrop({ fileId, folderId }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDroppable: docId !== folderId,
    }),
  }));

  const ref = docId !== folderId ? drop : null;

  return (
    <div
      ref={ref}
      className={classNames({
        "t-border-purple t-bg-purple-0 t-scale-110 t-border t-border-solid t-rounded-lg t-px-1.5 t-transform t-transition t-duration-300 t-ease-in-out":
          isOver && isDroppable,
      })}
    >
      {name}
    </div>
  );
};
