import { useBillingtag } from "hooks/useBillingtag";
import { useGetAutofillQuery } from "store/apis/autofill";

export const useBillingAddress = ({
  groupId,
  entityId,
}: {
  groupId?: string;
  entityId?: string;
}) => {
  const { uuid: billingTagId } = useBillingtag();

  const { data: billingAddresses = [], isLoading: isAddressLoading } =
    useGetAutofillQuery(
      {
        groupId: groupId!,
        entityId: entityId!,
        autofillKey: "addresses",
        tagId: billingTagId,
      },
      { skip: !groupId || !billingTagId || !entityId }
    );

  return {
    isLoading: isAddressLoading,
    billingAddresses,
    billingTagId,
  };
};
