export const InkleIntragroupSymbol = () => (
  <svg
    width="56"
    height="57"
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.9997 16.3574H32.9089V33.1016L23.0908 40.3574H27.4544L39.9997 33.1016V16.3574Z"
      fill="url(#paint0_linear_5723_7964)"
    />
    <path
      d="M16.0003 40.3574L23.0911 40.3574L23.0911 23.6132L32.9092 16.3574L28.5456 16.3574L16.0003 23.6132L16.0003 40.3574Z"
      fill="url(#paint1_linear_5723_7964)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7964"
        x1="23.0908"
        y1="28.3574"
        x2="39.9997"
        y2="28.3574"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7964"
        x1="32.9092"
        y1="28.3574"
        x2="16.0003"
        y2="28.3574"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
