export const NoChannelSelected = () => (
  <svg
    width="168"
    height="105"
    viewBox="0 0 168 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="33" width="135" height="70" rx="8" fill="#E7E6EB" />
    <rect x="49" y="20" width="44" height="6" rx="3" fill="#B8B5C2" />
    <rect x="49" y="40" width="89" height="4" rx="2" fill="#E7E6EB" />
    <rect y="35" width="135" height="70" rx="8" fill="white" />
    <rect x="16" y="60" width="44" height="6" rx="3" fill="#CFCDD6" />
    <rect x="16" y="75" width="89" height="4" rx="2" fill="#E7E6EB" />
  </svg>
);
