import ConditionalToolTip from "components/design/conditionalToolTip";
import { Input } from "components/design/input";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Search } from "components/DesignSystem/Search/Search";
import Modal from "components/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useEffect, useRef, useState } from "react";
import {
  useGetTaskAccessListFCAQuery,
  useGiveFCATaskAccessMutation,
} from "store/apis/teamSetting";
import { debounce } from "utils/debouncing";

export const TaskName = ({ task_title }: { task_title: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ConditionalToolTip
      condition={ref.current?.clientWidth === 320 && task_title}
    >
      <div className="t-ml-1 t-max-w-80 t-line-clamp-1" ref={ref}>
        {task_title}
      </div>
    </ConditionalToolTip>
  );
};

export const TaskAccessModal = ({
  providerId,
  onClose,
}: {
  providerId: string;
  onClose: () => void;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [searchTerm, setSearchTerm] = useState<null | string>(null);
  const [selectedTasks, setSelectedTasks] = useState(new Set<string>());
  const [giveFCATaskAccess, { isLoading: isAssign }] =
    useGiveFCATaskAccessMutation();

  const { data, isLoading, isFetching } = useGetTaskAccessListFCAQuery(
    {
      searchTerm,
      providerId,
      groupId,
    },
    { skip: !providerId || !groupId, refetchOnMountOrArgChange: true }
  );

  const { task_list: taskList = [], is_all_access_allowed } = data || {};

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value || null);
  });

  useEffect(() => {
    const alreadyTaskAccess = taskList
      ?.filter(({ has_task_access }) => has_task_access)
      .map(({ uuid }) => uuid)
      .reduce((acc, c) => acc.add(c), new Set<string>());

    setSelectedTasks(alreadyTaskAccess);

    return () => {
      setSelectedTasks(new Set());
      setSearchTerm(null);
    };
  }, [isFetching]);

  const onTaskSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    if (name === "allTask") {
      if (checked) {
        return setSelectedTasks((s) => {
          const newList = taskList.reduce(
            (acc, c) => acc.add(c.uuid),
            new Set<string>()
          );
          return newList;
        });
      }
      setSelectedTasks(new Set());
      return;
    }

    if (checked) {
      return setSelectedTasks((s) => {
        const newList = new Set(Array.from(s));
        newList.add(name);
        return newList;
      });
    }

    setSelectedTasks((s) => {
      const newList = new Set(Array.from(s));
      newList.delete(name);
      return newList;
    });
  };

  const onSubmit = async ({ accessType }: { accessType: string }) => {
    try {
      if (accessType === "allTaskIncludesFuture") {
        await giveFCATaskAccess({
          providerId,
          groupId,
          allAccessAllowed: true,
        }).unwrap();
      } else {
        await giveFCATaskAccess({
          providerId,
          groupId,
          taskIdList: Array.from(selectedTasks),
        }).unwrap();
      }
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const noTaskFound = taskList.length === 0 && !isLoading;

  return (
    <Modal.Root open={Boolean(providerId)} onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Title>Grant Task Access</Modal.Title>
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              accessType: is_all_access_allowed
                ? "allTaskIncludesFuture"
                : "selectedTask",
            }}
          >
            {({ values: { accessType } }) => (
              <Form className="all:unset t-flex t-flex-col t-justify-center t-pt-8">
                <div
                  role="group"
                  aria-labelledby="form-selector-radio-group"
                  className="t-ml-1 t-flex t-flex-col t-gap-2"
                >
                  <Input
                    label={
                      <div className="t-cursor-pointer t-text-subtitle-sm t-font-medium">
                        All filings (includes future filings as well)
                      </div>
                    }
                    type="radio"
                    value="allTaskIncludesFuture"
                    name="accessType"
                    /* @tw */
                    customInputClass="!t-h-4 !t-w-4 t-cursor-pointer"
                  />
                  <Input
                    label={
                      <div className="t-cursor-pointer t-text-subtitle-sm t-font-medium">
                        Select filings
                      </div>
                    }
                    value="selectedTask"
                    type="radio"
                    name="accessType"
                    /* @tw */
                    customInputClass="!t-h-4 !t-w-4 t-cursor-pointer"
                  />
                  {accessType === "selectedTask" && (
                    <div className="t-ml-8 t-flex t-flex-col t-gap-3">
                      <Search
                        onChange={handleSearch}
                        customSize="regular"
                        placeholder="Search for Task"
                        block
                        autoFocus
                      />
                      {noTaskFound ? (
                        <div className="t-flex t-h-56 t-items-center t-justify-center ">
                          No task found
                        </div>
                      ) : (
                        <div className="t-flex t-h-56 t-flex-col t-gap-4 t-overflow-auto t-p-2">
                          <Checkbox
                            onChange={onTaskSelect}
                            checked={taskList.every(({ uuid }) =>
                              selectedTasks.has(uuid)
                            )}
                            label={
                              <span className="t-text-subtext">
                                Select all filings
                              </span>
                            }
                            name="allTask"
                          />
                          {taskList.map(({ task_title, uuid }) => {
                            return (
                              <Checkbox
                                key={uuid}
                                onChange={onTaskSelect}
                                checked={selectedTasks.has(uuid)}
                                label={<TaskName task_title={task_title} />}
                                name={uuid}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="t-flex t-justify-end t-gap-2.5 t-pt-4">
                  <Button
                    customType="secondary"
                    type="reset"
                    onClick={onClose}
                    disabled={isAssign}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    isLoading={isAssign}
                    disabled={isAssign}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Modal.Close />
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
