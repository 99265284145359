import * as RDialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { Cross } from "components/icons/Cross";
import { ReactNode, useMemo, useState } from "react";
import ImageGallery from "react-image-gallery";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import PreviewImageName from "static/images/PreviewImageName.svg";
import { closePreview } from "store/slices/chat";
import { RootState } from "store/store";

export const PreviewModal = ({
  children,
  title,
  open,
  onClose,
}: {
  children: ReactNode;
  title: ReactNode;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <RDialog.Root open={open} onOpenChange={onClose}>
      <RDialog.Portal>
        <Modal.Overlay className="!t-opacity-80 t-bg-image-preview-overlay !t-bg-surface-transparent" />
        <RDialog.Content className="t-fixed t-z-modal t-top-[50%] t-left-[50%] t-translate-x-[-50%] t-translate-y-[-50%] t-w-full t-px-6 t-h-full t-grid t-grid-rows-[10%_90%]">
          <div className="t-flex t-w-full t-justify-between t-items-center">
            <Modal.Title className="t-text-neutral-0 t-text-subtext t-flex t-gap-2 t-items-center">
              {title}
            </Modal.Title>
            <RDialog.Close asChild>
              <span className="t-text-white">
                <Button customType="transparent">
                  <Cross color="currentColor" />
                </Button>
              </span>
            </RDialog.Close>
          </div>
          {children}
        </RDialog.Content>
      </RDialog.Portal>
    </RDialog.Root>
  );
};
export const NavButton = ({
  onClick,
  disabled,
  navPostion,
}: {
  onClick: React.MouseEventHandler<HTMLElement>;
  disabled: boolean;
  navPostion: "right" | "left";
}) => {
  if (disabled) {
    return null;
  }

  if (navPostion === "left") {
    return (
      <div className="t-fixed t-left-[1.6%] t-top-1/2 t-translate-y-[-50%] t-z-dropdown sm:t-hidden">
        <Button customType="icon" onClick={onClick}>
          <div className="t-rotate-180">
            <ArrowRight />
          </div>
        </Button>
      </div>
    );
  }
  if (navPostion === "right") {
    return (
      <div className="t-fixed t-right-[1.6%] t-top-1/2 t-translate-y-[-50%] t-z-dropdown sm:t-hidden">
        <Button customType="icon" onClick={onClick} disabled={disabled}>
          <ArrowRight />
        </Button>
      </div>
    );
  }

  return null;
};

export const ChatPreviewModal = () => {
  const dispatch = useDispatch();
  const { previewFiles } = useSelector((state: RootState) => state.chat);
  const { files, index } = previewFiles;
  const totalFiles = files.length;
  const isFile = Boolean(totalFiles);
  const [currentImage, setCurrentImage] = useState(index || 0);

  const onClose = () => {
    dispatch(closePreview());
  };
  const isSingleFile = totalFiles === 1;

  const images = useMemo(() => {
    return files.map((file) => ({
      original: file.image_url || "",
      originalAlt: file.fallback,
      /* @tw */
      originalClass: isSingleFile ? "!t-relative" : undefined,
    }));
  }, [files]);

  return (
    <PreviewModal
      onClose={onClose}
      title={
        <>
          <img src={PreviewImageName} alt={images[currentImage]?.originalAlt} />
          {images[currentImage]?.originalAlt}
        </>
      }
      open={isFile}
    >
      <ImageGallery
        /* @tw */
        additionalClass={classNames(
          "t-self-center t-w-10/12 t-justify-self-center",
          /* @tw */
          { "t-top-28": !isSingleFile }
        )}
        items={images}
        showFullscreenButton={false}
        infinite={false}
        startIndex={index}
        showIndex={true}
        onSlide={(index) => setCurrentImage(index)}
        renderLeftNav={(onClick, disabled) => (
          <NavButton onClick={onClick} disabled={disabled} navPostion="left" />
        )}
        renderRightNav={(onClick, disabled) => (
          <NavButton onClick={onClick} disabled={disabled} navPostion="right" />
        )}
        renderItem={(item) => (
          <Zoom>
            <img
              src={item.original}
              alt={item.originalAlt}
              className="t-object-contain t-max-h-[calc(100vh-230px)] t-w-full"
            />
          </Zoom>
        )}
      />
    </PreviewModal>
  );
};
