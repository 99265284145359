import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import { getNearestExpireRA } from "../../apis/registeredAgent";
import {
  ALL_RA,
  DAYS_TO_EXPIRY_THIRTY,
  DAYS_TO_EXPIRY_THOUSAND,
  NEARING_DEADLINE,
  SubTabCategory,
} from "../../constants/regAgent";
import { Button } from "../design/button";
import CommonTable from "../design/commonTable";
import Loader from "../design/loader";
import NoDataAvailable from "../design/noDataAvailable";
import SubcategoryTab from "../design/subcategoryTab";
import CrmTabBar from "./crmTabs/crmTabBar";
import RegAgentFilter from "./regAgentFilter";

const RegAgentDeadline = () => {
  usePageTitle("RA Deadline");
  const { alertToast } = useToast();
  const history = useHistory();
  const { isCpa, isAdmin } = useRoleBasedView();
  const [loading, setLoading] = useState(true);
  const [nearestExpireRA, setNearestExpireRA] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [daysToExpiry, setDaysToExpiry] = useState(30);
  const [selectTab, setSelectTab] = useState(NEARING_DEADLINE);

  useEffect(() => {
    const nearestDeadlineDebouncing = setTimeout(async () => {
      await getAllNearestExpireRA();
    }, 500);
    return () => clearTimeout(nearestDeadlineDebouncing);
  }, [pageNumber, searchTerm, daysToExpiry]);

  const getAllNearestExpireRA = async () => {
    setLoading(true);
    try {
      const response = await getNearestExpireRA({
        page_num: pageNumber,
        search_term: searchTerm,
        days_to_expiry: daysToExpiry,
      });
      setNearestExpireRA(response.data.data.agent);
      setTotalPageCount(response.data.data.total_pages);
      setLoading(false);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
      setLoading(false);
    }
  };

  const openAddTaskComponent = (groupId) => {
    history.push({
      pathname: `/crm/${groupId}/filings`,
      state: {
        openAddTaskFromRA: true,
      },
    });
  };

  const handleDeadlineFilter = () => {
    if (selectTab === ALL_RA) {
      setDaysToExpiry(DAYS_TO_EXPIRY_THIRTY);
      setSelectTab(NEARING_DEADLINE);
      setPageNumber(1);
    } else if (selectTab === NEARING_DEADLINE) {
      setSelectTab(ALL_RA);
      setDaysToExpiry(DAYS_TO_EXPIRY_THOUSAND);
      setPageNumber(1);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "name",
        width: "30%",
        className: "groupNameCell",
        Cell: ({
          row: {
            original: { group_name },
          },
        }) => {
          return <div className="raCellData">{group_name}</div>;
        },
      },
      {
        Header: "Agent Name",
        accessor: "agentname",
        width: "25%",
        className: "groupNameCell",
        Cell: ({
          row: {
            original: { agent_name },
          },
        }) => {
          return <div className="raCellData">{agent_name}</div>;
        },
      },
      {
        Header: "Deadline",
        accessor: "deadline",
        width: "25%",
        className: "groupNameCell",
        Cell: ({
          row: {
            original: { expiration_date },
          },
        }) => {
          return (
            <div className="raCellData">{formatDate(expiration_date)}</div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: "26%",
        className: "addtaskButtonRA",
        Cell: ({
          row: {
            original: { group_id },
          },
        }) => {
          return (
            <Button size="small" onClick={() => openAddTaskComponent(group_id)}>
              Add Filing
            </Button>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => nearestExpireRA, [nearestExpireRA]);

  return (
    <div className="addTeamMemberContent">
      {(isCpa || isAdmin) && <CrmTabBar selectedTabIs="admin/radeadlines" />}
      <div className="headerDiv">
        <SubcategoryTab
          subTabData={SubTabCategory}
          onTabChange={handleDeadlineFilter}
          selectedCat={selectTab}
          isRASubTab
        />
      </div>
      <div className="serviceTeamFilter">
        <RegAgentFilter filter={searchTerm} setFilter={setSearchTerm} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="addTeamMemberContentBody">
          <div
            className="addTeamMemberSelectTable 
              consultantDashBoard"
          >
            <CommonTable
              tableData={data}
              tableDataDependencies={[data]}
              tableColumns={columns}
              tableColumnsDependencies={[columns]}
              pageCount={totalPageCount}
              setPageNumber={setPageNumber}
              initialPage={pageNumber}
              pagination
              path="radeadlines"
            />
          </div>
        </div>
      )}
      {nearestExpireRA.length === 0 && !loading && <NoDataAvailable />}
    </div>
  );
};

export default RegAgentDeadline;
