export const InkleMailroomSymbolSmall = () => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.51091 6.39214L1.84387 3.44712V9.09901C1.84387 9.60818 1.4311 10.0209 0.921933 10.0209C0.412764 10.0209 0 9.60818 0 9.09901V0.953177C0 0.444008 0.412764 0.0312433 0.921933 0.0312433C0.960391 0.0312433 0.9983 0.0335982 1.03552 0.0381716C1.21776 0.0231257 1.44682 0.0270831 1.7493 0.0323088C1.93757 0.0355616 2.1543 0.0393057 2.40587 0.0392643L5.90534 0.0392634L9.30583 0.0392278C9.69816 0.0392273 10.0185 0.0324565 10.2836 0.0268546C10.6208 0.0197282 10.8685 0.0144933 11.061 0.0274948C11.0667 0.0273898 11.0724 0.0273371 11.0782 0.0273371C11.5874 0.0273371 12.0001 0.4401 12.0001 0.94927V9.0951C12.0001 9.60427 11.5874 10.017 11.0782 10.017C10.569 10.017 10.1562 9.60427 10.1562 9.0951V3.53655L6.31077 6.40634C6.07208 6.58447 5.74313 6.57863 5.51091 6.39214Z"
      fill="url(#paint0_linear_5960_17575)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5960_17575"
        x1="0"
        y1="5.02024"
        x2="12.0001"
        y2="5.02024"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
