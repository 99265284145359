import qs from "qs";
import { Entity, ForeignQualifiedState, FQPayload } from "types/Models/entity";
import {
  GroupTaskList,
  ServiceProviders,
  Tag,
  TaskAccessListFCA,
} from "types/Models/FCA";
import { Group } from "types/Models/group";
import { emptyApi } from "./emptyApi";

export type GroupUser = {
  uuid: string;
  name: string;
  email: string;
  profile_id: string;
  status: string;
  mobile: string;
  secondary_mobile?: string;
  created_at: string;
  role_name: string;
  tags: Tag[];
};

export const teamSettingsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getGroupData: build.query<Group, { groupId: string }>({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/company/group/${groupId}`,
        };
      },
      providesTags: (result) => {
        const { team = [] } = result || {};
        return result
          ? [
              ...team.map(({ uuid }) => ({
                type: "TeamSettingGroups" as "TeamSettingGroups",
                id: uuid,
              })),
              { type: "TeamSettingGroups", id: "TEAM" },
            ]
          : [
              {
                type: "TeamSettingGroups" as "TeamSettingGroups",
                id: "TEAM",
              },
            ];
      },
    }),

    updateEntity: build.mutation<
      Entity,
      { groupUuid: string; entityUuid: string; data: any }
    >({
      query: ({ groupUuid, entityUuid, data }) => {
        return {
          url: `/api/inkle/company/group/${groupUuid}/entities/${entityUuid}/`,
          method: "put",
          body: data,
        };
      },
      invalidatesTags: [{ type: "TeamSettingGroups", id: "TEAM" }],
    }),

    invite: build.mutation({
      query: ({
        profileId,
        groupId,
      }: {
        profileId: string;
        groupId: string;
      }) => {
        return {
          url: `/api/inkle/company/v2/group/${groupId}/invite_user/`,
          method: "post",
          body: { profile_id: profileId },
        };
      },
      invalidatesTags: (result: any) => {
        return result ? [{ type: "TeamSettingGroups", id: "TEAM" }] : [];
      },
    }),

    getCountryStates: build.query<
      {
        name: string;
        country: string;
        state_id: string;
      }[],
      { countryId: string }
    >({
      query: ({ countryId }) => {
        return {
          url: `/api/inkle/company/country/${countryId}/states/`,
        };
      },
    }),

    getStateFields: build.query({
      query: ({ groupId, stateId, entityId }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/state/${stateId}/`,
        };
      },
    }),

    updateForeignQ: build.mutation<
      ForeignQualifiedState,
      { groupId: string; payLoad: FQPayload }
    >({
      query: ({ groupId, payLoad }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/state/`,
          method: "put",
          body: payLoad,
        };
      },
      invalidatesTags: (result, _, req) => (result ? [{ type: "Groups" }] : []),
    }),

    deleteForeignQState: build.mutation<
      ForeignQualifiedState,
      {
        groupId: string;
        data: { entity_id: string; state_map_id: string };
      }
    >({
      query: ({ groupId, data }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/state/`,
          method: "delete",
          body: data,
        };
      },
      invalidatesTags: (result, _, req) => (result ? [{ type: "Groups" }] : []),
    }),

    archiveTeamMember: build.mutation<
      Group,
      {
        profile_id: string;
        group_id: string;
        archiveAction: string;
      }
    >({
      query: ({ group_id, profile_id, archiveAction }) => {
        return {
          url: `/api/inkle/company/group/${group_id}/team/${archiveAction}/`,
          method: "post",
          body: { profile_id },
        };
      },
      invalidatesTags: ["TeamMember"],
    }),

    getTeamMembers: build.query<
      {
        active_group_users_count: number;
        available_seats: number;
        extra_seat_amount: number;
        free_seats: number;
        group_users: GroupUser[];
      },
      { groupId: string }
    >({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/team/`,
        };
      },
      providesTags: ["TeamMember"],
    }),

    getGroupRequest: build.query<{ data: Group[] }, { groupId: string }>({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/join/`,
        };
      },
      providesTags: ["RequestedTeamMember"],
    }),

    groupJoinAction: build.mutation({
      query: ({
        groupId,
        requestId,
        type,
        payload,
      }: {
        groupId: string;
        requestId: string;
        type: string;
        payload: any;
      }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/request/${requestId}/${type}/`,
          method: "put",
          body: payload,
        };
      },
      invalidatesTags: ["RequestedTeamMember", "TeamMember"],
    }),

    addTeamMember: build.mutation<
      Group,
      {
        id: string;
        body: {
          email: string;
          group_role_id?: string;
          all_access_allowed?: boolean;
          task_id_list?: string[];
        };
      }
    >({
      query: ({ id, body }) => {
        return {
          url: `/api/inkle/company/group/${id}/team/`,
          method: "post",
          body: body,
        };
      },
      invalidatesTags: ["RequestedTeamMember", "TeamMember"],
    }),
    updateTeamSeats: build.mutation<
      null,
      {
        seat_count: number;
        group_id: string;
      }
    >({
      query: ({ group_id, seat_count }) => {
        return {
          url: `/api/inkle/company/group/${group_id}/team/modify/`,
          method: "post",
          body: { seat_count },
        };
      },
      invalidatesTags: ["TeamMember"],
    }),
    updateTeamSuperAdmin: build.mutation<
      { is_super_admin: boolean },
      {
        profile_id: string;
        make_super_admin: boolean;
        group_id: string;
      }
    >({
      query: ({ profile_id, make_super_admin, group_id }) => {
        return {
          url: `/api/inkle/company/group/${group_id}/team/super_user/`,
          method: "post",
          body: { profile_id, make_super_admin },
        };
      },
      invalidatesTags: ["TeamMember"],
    }),

    getTaskAccessListFCA: build.query<
      TaskAccessListFCA,
      { searchTerm?: null | string; providerId: string; groupId: string }
    >({
      query: ({ searchTerm, providerId, groupId }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/serviceteams/group/${groupId}/service-provider/${providerId}/task/${queryUrl}`,
        };
      },
    }),

    getGroupTaskList: build.query<
      GroupTaskList[],
      { searchTerm?: null | string; groupId: string }
    >({
      query: ({ searchTerm, groupId }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/serviceteams/group/${groupId}/task/${queryUrl}`,
        };
      },
    }),

    getServiceProviders: build.query<
      {
        service_providers: ServiceProviders[];
        current_page: number;
        per_page: number;
        total_count: number;
        total_pages: number;
      },
      {
        groupId: string;
        pageNum?: number;
        query?: string;
      }
    >({
      query: ({ groupId, pageNum, query }) => {
        let queryUrl = qs.stringify(
          { page_num: pageNum, search_term: query },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/serviceteams/group/${groupId}/service-provider/${queryUrl}`,
        };
      },
      providesTags: [{ type: "FCA" }],
    }),

    giveFCATaskAccess: build.mutation<
      { has_task_access: boolean; task_title: string; uuid: string }[],
      {
        providerId: string;
        taskIdList?: string[];
        allAccessAllowed?: boolean;
        groupId: string;
      }
    >({
      query: ({ providerId, taskIdList, allAccessAllowed, groupId }) => {
        return {
          url: `/api/inkle/serviceteams/group/${groupId}/service-provider/${providerId}/task/`,
          method: "POST",
          body: {
            task_id_list: taskIdList,
            all_access_allowed: allAccessAllowed,
          },
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "FCA" }] : []),
    }),

    inviteServiceProvider: build.mutation<
      ServiceProviders,
      {
        groupId: string;
        taskIdList?: string[];
        allAccessAllowed?: boolean;
        email: string;
        orgName: string;
      }
    >({
      query: ({ groupId, taskIdList, allAccessAllowed, orgName, email }) => {
        return {
          url: `/api/inkle/serviceteams/group/${groupId}/service-provider/`,
          method: "POST",
          body: {
            task_id_list: taskIdList,
            all_access_allowed: allAccessAllowed,
            email,
            org_name: orgName,
          },
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "FCA" }] : []),
    }),

    getTeamSettingTags: build.query<Tag[], void>({
      query: () => "/api/inkle/tags/COMPANY_GROUP_USER_MAP_TAG/",
    }),

    addServiceProvidersTag: build.mutation<
      Tag[],
      {
        serviceProviderId: string;
        tagId: string;
      }
    >({
      query: ({ serviceProviderId, tagId }) => {
        return {
          url: `/api/inkle/tags/service_provider/${serviceProviderId}/tag/${tagId}/`,
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "FCA" }] : []),
    }),

    removeServiceProvidersTag: build.mutation<
      Tag[],
      {
        serviceProviderId: string;
        tagId: string;
      }
    >({
      query: ({ serviceProviderId, tagId }) => {
        return {
          url: `/api/inkle/tags/group_user_map/${serviceProviderId}/tag/${tagId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "FCA" }] : []),
    }),
    revokeTeamMemberInvite: build.mutation<
      Group,
      {
        groupId: string;
        email: string;
      }
    >({
      query: ({ groupId, email }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/team/`,
          method: "delete",
          body: { email },
        };
      },
      invalidatesTags: ["RequestedTeamMember", "TeamMember"],
    }),
    updateTeamMember: build.mutation<
      Group,
      {
        id: string;
        body: {
          email: string;
          group_role_id?: string;
          all_access_allowed?: boolean;
          task_id_list?: string[];
          secondary_mobile?: string;
          current_timezone?: string;
          linkedin_url?: string;
          profile_id?: string;
        };
      }
    >({
      query: ({ id, body }) => {
        return {
          url: `/api/inkle/company/group/${id}/team/`,
          method: "put",
          body: body,
        };
      },
      invalidatesTags: ["RequestedTeamMember", "TeamMember"],
    }),
  }),
});

export const {
  useGetGroupDataQuery,
  useInviteMutation,
  useUpdateEntityMutation,
  useGetCountryStatesQuery,
  useGetStateFieldsQuery,
  useDeleteForeignQStateMutation,
  useArchiveTeamMemberMutation,
  useGetTeamMembersQuery,
  useGetGroupRequestQuery,
  useGroupJoinActionMutation,
  useAddTeamMemberMutation,
  useUpdateForeignQMutation,
  useUpdateTeamSeatsMutation,
  useUpdateTeamSuperAdminMutation,
  useGetTaskAccessListFCAQuery,
  useGiveFCATaskAccessMutation,
  useGetServiceProvidersQuery,
  useInviteServiceProviderMutation,
  useGetGroupTaskListQuery,
  useLazyGetGroupDataQuery,
  useGetTeamSettingTagsQuery,
  useAddServiceProvidersTagMutation,
  useRemoveServiceProvidersTagMutation,
  useRevokeTeamMemberInviteMutation,
  useUpdateTeamMemberMutation,
} = teamSettingsApi;
