import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { DateFilter } from "components/Filters/DateFilter";
import { formatDate } from "utils/formatDate";

export const JournalEntryFilter = ({
  values,
  updateFilter,
  getUpdateFilter,
}: {
  values: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
  };
  updateFilter: <S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
    }[S]
  ) => void;
  getUpdateFilter: (
    name: "START_DATE" | "END_DATE"
  ) => (newValues: string) => void;
}) => {
  return (
    <Filter.Root
      defaultValue="journalEntryDate"
      title={
        <span className="t-text-body t-font-medium t-leading-none">
          Filters
        </span>
      }
      capsule={
        <>
          {values.START_DATE && (
            <Chip
              onClose={() => {
                updateFilter("START_DATE", "");
                updateFilter("END_DATE", "");
              }}
              isActive
              filterType="journalEntryDate"
            >
              From: {formatDate(values.START_DATE)} To:{" "}
              {formatDate(values.END_DATE)}
            </Chip>
          )}
        </>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="journalEntryDate">Date</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="journalEntryDate" block>
          <DateFilter values={values} updateFilter={updateFilter} />
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
