import React from "react";

export const FolderIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1720_954)">
        <path
          d="M13.5556 13H2.46125C2.33902 12.9997 2.22189 12.951 2.13546 12.8645C2.04903 12.7781 2.00033 12.661 2 12.5387V5H13.5C13.6326 5 13.7598 5.05268 13.8536 5.14645C13.9473 5.24021 14 5.36739 14 5.5V12.5556C14 12.6735 13.9532 12.7865 13.8698 12.8698C13.7865 12.9532 13.6735 13 13.5556 13Z"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 5V3.5C2 3.36739 2.05268 3.24021 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H5.79313C5.92556 3.00006 6.05255 3.05266 6.14625 3.14625L8 5"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1720_954">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
