import qs from "qs";
import { emptyApi } from "./emptyApi";
import { Pagination } from "types/Models/pagination";
import { Intro } from "./vcIntro";

export type Contact = {
  uuid?: string;
  name: string;
  email: string;
  linkedIn_url?: string;
  is_profile_verified?: boolean;
  is_invited?: boolean | null;
};

export type VentureCapitalist = {
  uuid: string;
  name: string;
  website?: string;
  notes?: string;
  vc_type?: string;
  min_cheque?: string;
  rounds?: string[];
  location?: string;
  contacts?: Contact[];
};

export const vcCrmApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllVCs: build.query<
      Pagination & { vcs: VentureCapitalist[] },
      { pageNum?: number }
    >({
      query: ({ pageNum }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return { url: `/api/inkle/reference/crm/vc/${queryUrl}` };
      },
      providesTags: () => [{ type: "VENTURE_CAPITALIST" }],
    }),

    getVCData: build.query<VentureCapitalist, { vcId: string }>({
      query: ({ vcId }) => {
        return { url: `/api/inkle/reference/vc/${vcId}/` };
      },
      providesTags: () => [{ type: "SINGLE_VENTURE_CAPITALIST" }],
    }),

    createVC: build.mutation({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/reference/crm/vc/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "VENTURE_CAPITALIST" }] : [],
    }),

    editVC: build.mutation({
      query: ({ vcId, payload }) => {
        return {
          url: `/api/inkle/reference/vc/${vcId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: "SINGLE_VENTURE_CAPITALIST" },
              { type: "VENTURE_CAPITALIST" },
              { type: "VC_CONTACTS" },
            ]
          : [],
    }),

    getVCSlider: build.query<Intro, { groupId: string; vcIntroId: string }>({
      query: ({ groupId, vcIntroId }) => {
        return {
          url: `/api/inkle/reference/group/${groupId}/vc/intros/${vcIntroId}/`,
        };
      },
    }),

    getVCContacts: build.query<Contact[], { vcId: string }>({
      query: ({ vcId }) => {
        return { url: `/api/inkle/reference/vc/${vcId}/contacts/` };
      },
      providesTags: () => [{ type: "VC_CONTACTS" }],
    }),

    inviteVCs: build.mutation({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/reference/invite/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "VC_CONTACTS" }] : []),
    }),
  }),
});

export const {
  useGetAllVCsQuery,
  useGetVCDataQuery,
  useCreateVCMutation,
  useGetVCSliderQuery,
  useEditVCMutation,
  useGetVCContactsQuery,
  useInviteVCsMutation,
} = vcCrmApis;
