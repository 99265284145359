import RightSlider from "components/design/RightSlider";
import { Button } from "components/DesignSystem/Button/Button";
import Help from "components/help/help";
import { LogoutIcon } from "components/icons/LogoutIcon";
import ProfileHelpIcon from "components/icons/ProfileHelpIcon";
import Logo from "components/inkleLogo";
import authContext from "jwt_context&axios/authContext";
import React, { useContext, useState } from "react";

const OnboardingHeader = () => {
  const [showHelp, setShowHelp] = useState(false);
  const { logoutUser } = useContext(authContext);

  const openModal = () => setShowHelp(true);
  const closeModal = () => setShowHelp(false);

  return (
    <div className="t-flex t-h-20 t-items-center t-justify-between">
      <Logo className="t-mb-0 t-ml-8" />
      <div className="t-mr-8 t-flex t-items-center t-gap-2">
        <span
          className="t-z-50 t-mr-8 t-flex t-items-center t-text-text-100 hover:t-text-purple"
          onClick={openModal}
        >
          <Button customType="transparent" size="small">
            <ProfileHelpIcon color="currentColor" />
            <span className="helpText t-ml-1 !t-text-body !t-font-medium">
              Help
            </span>
          </Button>
        </span>
        <span className="t-text-text-100 hover:t-text-purple">
          <Button customType="transparent" onClick={logoutUser}>
            <LogoutIcon color="currentColor" />
            <span className="t-ml-1 t-text-body t-font-medium">Logout</span>
          </Button>
        </span>
      </div>
      {showHelp && (
        <RightSlider width="auto" show={showHelp} closeModal={closeModal}>
          <Help loggedOut closeModal={closeModal} show={undefined} />
        </RightSlider>
      )}
    </div>
  );
};

export default OnboardingHeader;
