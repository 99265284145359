import { RefundDetails } from "types/Models/billing";
import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { BankLogos } from "dictionaries";
import { useDispatch, useSelector } from "react-redux";
import { closeBillingInvoiceSlider } from "store/slices/billingInvoiceSlider";
import { RootState } from "store/store";
import BigGreenTick from "static/images/BigGreenTick.svg";
import { SOURCE_BANK, SOURCE_TITLE } from "constants/invoiceSource";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";

export const RefundSuccess = ({
  refundDetails,
  adjustedAmount,
}: {
  refundDetails: RefundDetails;
  adjustedAmount: string;
}) => {
  const dispatch = useDispatch();
  const showBillingSlider = useSelector(
    (state: RootState) => state.billingInvoiceSlider.showBillingSlider
  );
  const cardDetail = refundDetails.card_details;
  const cardBrand = BankLogos.hasOwnProperty(cardDetail?.brand)
    ? BankLogos[cardDetail.brand]
    : BankLogos["default"];
  const refundToBank = refundDetails.destination === SOURCE_BANK;

  return (
    <div>
      <div className="t-p-5 t-flex t-flex-col t-justify-center t-items-center t-mx-auto">
        <img src={BigGreenTick} alt="BigGreenTick" />
        {refundToBank ? (
          <>
            <div className="t-text-subtitle t-text-text-100 t-mt-3">
              Refund initiated!
            </div>
            <div className="t-text-body t-text-text-60 t-text-center">
              It may take 5-10 days to process and appear on the bank statement
              when being refunded to original bank source.
            </div>
          </>
        ) : (
          <div className="t-text-subtitle t-text-text-100 t-mt-3">
            Inkle credits added
          </div>
        )}
      </div>
      <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid t-m-5 t-mt-2">
        <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-3 t-border-solid t-text-subtext t-text-text-100">
          Refund details
        </div>
        <div className="t-p-4 t-flex t-gap-6 t-flex-col t-mt-3">
          <InfoItem label="Refund amount">
            <AmountSuperScript amount={refundDetails.amount} />
          </InfoItem>
          <InfoItem label="Refund reason">{refundDetails.reason}</InfoItem>
          <InfoItem label="Refund to">
            {SOURCE_TITLE[refundDetails.destination]}
          </InfoItem>
          <InfoItem label="Refund initiated on">
            {dayjs(refundDetails.refund_created_date).format(DD_MMM_YYYY)}
          </InfoItem>
          <InfoItem label="Adjusted total">
            <AmountSuperScript amount={Number(adjustedAmount)} />
          </InfoItem>

          {refundToBank && (
            <InfoItem label="Payment Method">
              <div className="t-text-subtext t-text-text-100 t-flex t-items-center t-gap-0.5">
                <img className="t-h-5" src={cardBrand} alt="cardBrand" />
                **** {cardDetail.last_four}
              </div>
            </InfoItem>
          )}
        </div>
      </div>

      <div
        className={classNames({
          "t-px-5 t-py-3 t-bg-surface t-shadow-tasks-slider-bottom t-fixed t-bottom-0 t-w-[480px]":
            showBillingSlider,
        })}
      >
        <Button
          customType="secondary"
          block
          onClick={() => dispatch(closeBillingInvoiceSlider())}
        >
          Done
        </Button>
      </div>
    </div>
  );
};
