import Loader from "components/design/loader";
import { useToast } from "hooks/useToast";
import { parse } from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { useSetCallbackGmailQuery } from "store/apis/stakeholderUpdate";

export const GoogleRedirecting = () => {
  const { alertToast, successToast } = useToast();
  const history = useHistory();
  const { search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });
  const { code, error } = query;
  const {
    data,
    error: gmailError,
    isError,
    isSuccess,
  } = useSetCallbackGmailQuery({
    error: error as string,
    code: code as string,
  });
  const { callback_url, status } = data || {};

  if (isSuccess && status && callback_url) {
    successToast({ message: status, title: "Completed" });
    history.push(callback_url);
  }

  if (isError) {
    try {
      const error = JSON.parse(
        // @ts-ignore
        gmailError?.data?.error?.message
      );
      alertToast({ message: error?.status });
      history.push(error?.callback_url);
    } catch (error) {}
  }

  return (
    <div className="t-flex t-h-screen t-w-full t-items-center t-justify-center">
      <Loader />
    </div>
  );
};
