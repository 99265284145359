import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import StatusTags from "components/design/StatusTags";
import { Slider } from "components/DesignSystem/Slider/Slider";
import Table from "components/DesignSystem/Table/Table";
import { DocumentPreviewModal } from "components/PreviewModal";
import { CLICKED_TRANSFER_LINE_ITEM_IN_TABLE } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import React, { useEffect, useMemo, useState } from "react";
import { Column, useTable } from "react-table";
import { TransferPricingTransfer } from "types/Models/TransferPricingTransfer";
import { formatDate } from "utils/formatDate";
import TPSlider from "./TPSlider";

// import { ViewTransferDetails } from "./ViewTransferDetails/ViewTransferDetails";

interface PropsI {
  transferList: TransferPricingTransfer[];
  isTransferDataLoading: boolean;
  setInstructionsModal: (newState: React.SetStateAction<boolean>) => void;
}

export function TransferTable({
  transferList,
  isTransferDataLoading,
  setInstructionsModal,
}: PropsI) {
  const [selectedRowData, setSelectedRowData] =
    React.useState<TransferPricingTransfer>();
  const [openSlider, setOpenSlider] = useState(false);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    const updatedSelectedRow = transferList.find(
      ({ uuid }) => uuid === selectedRowData?.uuid
    );
    setSelectedRowData(updatedSelectedRow);
  }, [transferList]);

  const columns = useMemo(
    () =>
      [
        {
          Header: (
            <div className="t-text-body t-text-text-100">Date created</div>
          ),
          accessor: "created_date",
          Cell: ({ row }: { row: { original: TransferPricingTransfer } }) => {
            return (
              <span className="t-text-body">
                {formatDate(row.original.created_datetime)}
              </span>
            );
          },
          width: "12%",
        },
        {
          Header: (
            <div className="t-text-left t-text-body t-text-text-100">
              Description
            </div>
          ),
          accessor: "statement",
          Cell: ({ row }: { row: { original: TransferPricingTransfer } }) => {
            return (
              <span className="t-text-body">
                {row.original.purpose_code?.description}
              </span>
            );
          },
          width: "38%",
        },
        {
          Header: (
            <div className="t-text-right t-text-body t-text-text-100">
              Net Amount
            </div>
          ),
          accessor: "amount",
          Cell: ({ row }: { row: { original: TransferPricingTransfer } }) => {
            return (
              <div className="t-text-right  t-text-body">
                <AmountSuperScript
                  amount={row.original.amount.amount}
                  //@ts-ignore
                  currencyCode={row.original.amount.currency}
                />
              </div>
            );
          },
          width: "16%",
        },
        {
          Header: (
            <div className=" t-text-right t-text-body t-text-text-100">
              Settled Amount
            </div>
          ),
          accessor: "settled_amount",
          Cell: ({ row }: { row: { original: TransferPricingTransfer } }) => {
            return (
              <div className="t-text-right  t-text-body">
                {row.original?.settled_amount?.amount ? (
                  <AmountSuperScript
                    amount={row.original.settled_amount.amount}
                    //@ts-ignore
                    currencyCode={row.original.settled_amount.currency}
                  />
                ) : (
                  "-"
                )}
              </div>
            );
          },
          width: "17%",
        },
        {
          Header: (
            <div className="t-text-center t-text-body t-text-text-100">
              Status
            </div>
          ),
          accessor: "status",
          Cell: ({ row }: { row: { original: TransferPricingTransfer } }) => {
            return (
              <div className="t-flex t-justify-center t-text-subtitle-sm t-font-medium t-capitalize t-text-i-text-light">
                <StatusTags
                  statusType={row.original.status}
                  statusName={row.original.status}
                />
              </div>
            );
          },
          width: "16%",
        },
      ] as Column<TransferPricingTransfer>[],
    [transferList, selectedRowData]
  );

  const dynamicColumns = useMemo(() => {
    if (openSlider) {
      const filteredColumns = columns.filter(
        (column) =>
          //@ts-ignore
          column.accessor !== "settled_amount" && column.accessor !== "status"
      );

      return filteredColumns.map((column, index) => {
        let columnWidth = "20%";
        if (index === 1) {
          columnWidth = "60%";
        }

        return {
          ...column,
          width: columnWidth,
        };
      });
    } else {
      return columns;
    }
  }, [openSlider, columns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: dynamicColumns,
      data: transferList,
    });

  const handleRowClick = (row: { original: TransferPricingTransfer }) => {
    setSelectedRowData(row.original);
  };

  const onRowClick = (e: any, row: { original: TransferPricingTransfer }) => {
    trackEvent(CLICKED_TRANSFER_LINE_ITEM_IN_TABLE);
    if (openSlider && row.original === selectedRowData) {
      setOpenSlider(false);
      return;
    }
    e.stopPropagation();
    handleRowClick(row);
    setOpenSlider(true);
  };

  useEffect(() => {
    const event = document.addEventListener("click", () => {
      setOpenSlider(false);
      setSelectedRowData(undefined);
    });
    //@ts-ignore
    return () => document.removeEventListener("click", event);
  }, []);

  return (
    <div className="t-flex t-h-full t-gap-2">
      <Table.Content
        {...getTableProps()}
        className="all:unset t-w-full t-overflow-auto t-rounded t-border t-border-solid t-border-i-neutral-10"
      >
        <Table.Head
          style={{ backgroundColor: "#F2F2F5" }}
          className="!t-rounded"
        >
          {headerGroups.map((headerGroup) => (
            <Table.Row
              {...headerGroup.getHeaderGroupProps()}
              style={{ gap: 0 }}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="t-text-start t-font-medium"
                  {...column.getHeaderProps({})}
                  style={{ width: column?.width }}
                  key={column.id}
                >
                  {column.render("Header")}
                </th>
              ))}
            </Table.Row>
          ))}
        </Table.Head>

        <>
          {isTransferDataLoading ? (
            <Loader />
          ) : (
            <Table.BodyWrapper className="t-overflow-auto">
              {!transferList.length ? (
                <div className="t-flex t-h-[calc(100vh-340px)] t-items-center t-justify-center t-gap-4 t-text-subtitle t-leading-5 t-text-text-30">
                  Your TP transfers will show up here
                </div>
              ) : (
                <Table.Body {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <Table.Row
                        onClick={(e) => onRowClick(e, row)}
                        className={classNames(
                          "t-flex t-h-[67px] t-cursor-pointer t-items-center t-justify-center t-border-b t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-px-4 t-py-2 hover:t-bg-purple-0",
                          {
                            "t-bg-purple-0":
                              row.original.uuid === selectedRowData?.uuid,
                          }
                        )}
                        {...row.getRowProps()}
                        key={row.id}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps({})}
                              style={{ width: cell.column?.width }}
                              key={cell.column.id}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              )}
            </Table.BodyWrapper>
          )}
        </>
      </Table.Content>

      {openSlider && (
        <Slider
          open={openSlider}
          width={650}
          style={{ width: "650px" }}
          className="t-h-max t-max-h-[647px] t-overflow-auto t-rounded t-border t-border-solid t-border-neutral-0 !t-pl-6 t-shadow"
          onClick={(e) => e.stopPropagation()}
        >
          <TPSlider
            setOpenSlider={setOpenSlider}
            selectedRowData={selectedRowData}
            setInstructionsModal={setInstructionsModal}
          />
        </Slider>
      )}
      <DocumentPreviewModal />
    </div>
  );
}
