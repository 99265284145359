import classNames from "classnames";
import { Children, ReactNode } from "react";

export const AvatarGroup = ({
  max,
  children,
  variant = "fill",
  size = "small",
  superScript = true,
  remainingText,
  className = "",
}: {
  max?: number;
  children: ReactNode;
  variant?: "fill" | "no-fill";
  size?: "small" | "regular" | "large";
  superScript?: boolean;
  remainingText?: string;
  className?: string;
}) => {
  const childrens = Children.toArray(children);
  const showedImage = childrens.slice(0, max);
  const remaining = childrens.length - showedImage.length;

  return (
    <div
      className={classNames("t-flex", {
        "t--space-x-2": superScript,
        "t-gap-1": !superScript,
      })}
    >
      {Children.map(showedImage, (child) => child)}
      {Boolean(remaining) && (
        <span
          className={classNames(
            "t-flex t-items-center t-justify-center t-rounded-full t-text-overline t-font-medium t-tracking-[-0.0125rem] t-ring-1 t-ring-white",
            className || "",
            {
              "t-bg-purple t-text-text": variant === "fill",
              "t-bg-surface-lighter-grey t-border t-border-neutral-10 t-border-solid  t-text-text-30":
                variant === "no-fill",
              "t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6": size === "small",
              "t-min-h-8 t-min-w-8 t-max-h-8 t-max-w-8 t-text-subtitle-sm":
                size === "regular",
              "t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10": size === "large",
            }
          )}
        >
          <>{remainingText || `+${remaining}`}</>
        </span>
      )}
    </div>
  );
};
