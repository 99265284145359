export const InprogressAgentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <g clip-path="url(#clip0_1718_11911)">
      <path
        d="M14.5 8.83329V13.8333C14.5 14.4963 14.2366 15.1322 13.7678 15.6011C13.2989 16.0699 12.663 16.3333 12 16.3333H8.5C8.36739 16.3333 8.24021 16.2806 8.14645 16.1868C8.05268 16.0931 8 15.9659 8 15.8333C8 15.7007 8.05268 15.5735 8.14645 15.4797C8.24021 15.386 8.36739 15.3333 8.5 15.3333H12C12.3978 15.3333 12.7794 15.1753 13.0607 14.8939C13.342 14.6126 13.5 14.2311 13.5 13.8333H12C11.6022 13.8333 11.2206 13.6753 10.9393 13.3939C10.658 13.1126 10.5 12.7311 10.5 12.3333V9.83329C10.5 9.43546 10.658 9.05393 10.9393 8.77263C11.2206 8.49132 11.6022 8.33329 12 8.33329H13.4781C13.3835 7.29388 12.9951 6.30284 12.3582 5.47594C11.7214 4.64904 10.8624 4.02042 9.88163 3.6635C8.90085 3.30657 7.83875 3.23609 6.8194 3.46027C5.80005 3.68445 4.86553 4.19405 4.125 4.92954C3.20416 5.83823 2.6362 7.04459 2.5225 8.33329H4C4.39782 8.33329 4.77936 8.49132 5.06066 8.77263C5.34196 9.05393 5.5 9.43546 5.5 9.83329V12.3333C5.5 12.7311 5.34196 13.1126 5.06066 13.3939C4.77936 13.6753 4.39782 13.8333 4 13.8333H3C2.60218 13.8333 2.22064 13.6753 1.93934 13.3939C1.65804 13.1126 1.5 12.7311 1.5 12.3333V8.83329C1.5014 7.54573 1.88476 6.28752 2.60156 5.21793C3.31836 4.14834 4.33638 3.31546 5.52674 2.8247C6.71711 2.33393 8.02632 2.20736 9.28866 2.461C10.551 2.71463 11.7097 3.33708 12.6181 4.24954C13.2179 4.85223 13.6929 5.56734 14.0158 6.35391C14.3387 7.14047 14.5033 7.98302 14.5 8.83329Z"
        fill="#CFCDD6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1718_11911">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.833252)"
        />
      </clipPath>
    </defs>
  </svg>
);
