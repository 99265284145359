import { Shareholder } from "types/Models/Shareholders";
import { emptyApi } from "./emptyApi";

export const shareholder = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllShareholders: build.query<
      Shareholder[],
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/autofill/group/${groupId}/entity/${entityId}/shareholder/`,
        };
      },
      providesTags: (result) => (result ? ["SHAREHOLDERS"] : []),
    }),

    createShareholder: build.mutation<
      Shareholder,
      {
        groupId: string;
        entityId: string;
        payload: {
          instance_id: string;
          ownership_stake?: string;
          shareholder_type?: string;
          country_id: string;
          tin?: string;
          address_id?: string;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/autofill/group/${groupId}/entity/${entityId}/shareholder/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["SHAREHOLDERS"] : []),
    }),

    deleteShareholder: build.mutation<
      Shareholder,
      {
        groupId: string;
        shareholderId: string;
      }
    >({
      query: ({ groupId, shareholderId }) => {
        return {
          url: `/api/inkle/autofill/group/${groupId}/shareholder/${shareholderId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["SHAREHOLDERS"] : []),
    }),

    updateShareholder: build.mutation<
      Shareholder,
      {
        groupId: string;
        shareholderId: string;
        payload: {
          instance_id: string;
          ownership_stake?: string;
          shareholder_type?: string;
          country_id: string;
          tin?: string;
          address_id?: string;
        };
      }
    >({
      query: ({ groupId, payload, shareholderId }) => {
        return {
          url: `/api/inkle/autofill/group/${groupId}/shareholder/${shareholderId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["SHAREHOLDERS"] : []),
    }),
  }),
});

export const {
  useGetAllShareholdersQuery,
  useCreateShareholderMutation,
  useDeleteShareholderMutation,
  useUpdateShareholderMutation,
} = shareholder;
