import { Button } from "components/DesignSystem/Button/Button";
import {
  useGetStakeholderUpdateQuery,
  useUpdateStakeholderUpdateMutation,
} from "store/apis/stakeholderUpdate";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useGetEntitiesQuery } from "store/apis/group";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { Header } from "components/DesignSystem/Header/Header";
import { InvestorUpdateNewPreivewModal } from "components/InvestorUpdateNewPreivewModal/InvestorUpdateNewPreivewModal";
import { debounce } from "utils/debouncing";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import { InvestorUpdateEditor } from "components/InvestorUpdateEditor";
import { useAnalytics } from "hooks/useAnalytics";
import { INVESTOR_UPDATE_PRIVIEWED } from "constants/analyticsEvents";

import "@blocknote/core/style.css";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";

export const ViewInvestorUpdate = () => {
  const { url, path } = useRouteMatch();
  const { investorUpdateId } = useParams<{ investorUpdateId?: string }>();
  const { search } = useLocation();
  const { data: { groups: [group] = [null] } = {} } = useGetEntitiesQuery();
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const { alertToast } = useToast();

  const {
    data: stakeholderUpdate,
    isLoading,
    isSuccess: stakeholderUpdateLoaded,
  } = useGetStakeholderUpdateQuery(
    {
      groupId: group?.uuid!,
      updateId: investorUpdateId!,
    },
    {
      skip: !group?.uuid || !investorUpdateId,
    }
  );

  const [updateStakeholderUpdate, { isLoading: updatingStakeHolder }] =
    useUpdateStakeholderUpdateMutation();

  const saveBlocks = debounce(async (blocks: Record<string, any>[]) => {
    try {
      if (stakeholderUpdate && group?.uuid) {
        await updateStakeholderUpdate({
          groupId: group.uuid,
          updateId: stakeholderUpdate?.uuid!,
          payload: {
            ...stakeholderUpdate,
            block_json: JSON.stringify(blocks),
          },
        }).unwrap();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  }, 500);

  const initialBlocks =
    stakeholderUpdateLoaded &&
    stakeholderUpdate?.block_json &&
    typeof stakeholderUpdate?.block_json === "string"
      ? JSON.parse(stakeholderUpdate?.block_json)
      : [];

  const onTitleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (stakeholderUpdate && group?.uuid) {
        await updateStakeholderUpdate({
          groupId: group.uuid,
          updateId: stakeholderUpdate?.uuid!,
          payload: {
            ...stakeholderUpdate,
            title: e.target.value,
          },
        }).unwrap();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  if (!group) {
    return null;
  }

  const onPreviewModalClose = () => {
    history.push(`${url}${search}`);
  };

  const onPreview = () => {
    trackEvent(INVESTOR_UPDATE_PRIVIEWED);
    history.push(`${url}/send-updated/${search}`);
  };

  const addInvestorUpdatesBreadcrumbs = [
    {
      name: "Investor Updates",
      link: `/community/investor-updates`,
    },
    { name: stakeholderUpdate?.title || " " },
  ];

  const headerValue = stakeholderUpdate?.title;

  if (!stakeholderUpdateLoaded) {
    return (
      <div className="t-flex t-h-full t-w-full t-items-center t-justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="t-flex t-h-full t-w-full t-flex-col">
      <Header
        title={
          <div className="t-flex t-items-center t-gap-3">
            {investorUpdateId && isLoading ? (
              <span className="t-flex t-animate-spin">
                <LoadingIcon />
              </span>
            ) : (
              <input
                onChange={onTitleChange}
                className="all:unset t-w-44 t-border t-border-l-0 t-border-r-0 t-border-t-0 t-border-dotted t-text-body t-font-semibold md:t-text-[20px] md:t-font-bold"
                defaultValue={headerValue}
              />
            )}
          </div>
        }
        right={
          <div className="t-hidden t-items-center t-gap-3 md:t-flex">
            <span className="t-text-body">
              {updatingStakeHolder && (
                <span className="t-flex t-animate-spin">
                  <LoadingIcon />
                </span>
              )}
              {stakeholderUpdate && !updatingStakeHolder && (
                <span className="t-flex t-items-center t-gap-2 t-transition-all ">
                  <img src={DoubleGreenTick} alt="Saved" />
                  Changes Autosaved
                </span>
              )}
            </span>
            <Button onClick={onPreview} customType="primary" size="small">
              Send update
            </Button>
          </div>
        }
        breadcrumbs={addInvestorUpdatesBreadcrumbs}
      />
      <div className="t-h-full t-overflow-auto">
        <div className="t-w-[900px] t-mx-auto t-px-10">
          <InvestorUpdateEditor
            config={{
              onEditorContentChange(editor) {
                saveBlocks(editor.topLevelBlocks);
              },
              initialContent: initialBlocks,
            }}
          />
        </div>
      </div>

      <Switch>
        <Route
          path={`${path}/send-updated/`}
          render={() => (
            <InvestorUpdateNewPreivewModal
              show={true}
              onChangeShow={onPreviewModalClose}
            />
          )}
        />
      </Switch>
    </div>
  );
};
