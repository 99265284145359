export const CYMESSAGE = (
  <p>
    Toggling this will NOT change your entity's <br /> preset official tax year
    (which you've already <br /> registered with the IRS at the time of
    formation). <br /> It is simply a different view. <br /> If you wish to
    change the official year (or you <br /> know your entity definitely follows
    a Fiscal Year),
  </p>
);
export const FYMESSAGE = (
  <p>
    Toggling this will NOT change your entity's <br /> preset official tax year
    (which you've already <br /> registered with the IRS at the time of
    formation). <br /> It is simply a different view. <br /> If you wish to
    change the official year (or you <br /> know your entity definitely follows
    a Calendar Year),
  </p>
);

export const CYMONTHS = [
  { monthId: 1, monthName: "jan", totalDays: 31 },
  { monthId: 2, monthName: "feb", totalDays: 28 },
  { monthId: 3, monthName: "mar", totalDays: 31 },
  { monthId: 4, monthName: "apr", totalDays: 30 },
  { monthId: 5, monthName: "may", totalDays: 31 },
  { monthId: 6, monthName: "jun", totalDays: 30 },
  { monthId: 7, monthName: "jul", totalDays: 31 },
  { monthId: 8, monthName: "aug", totalDays: 31 },
  { monthId: 9, monthName: "sep", totalDays: 30 },
  { monthId: 10, monthName: "oct", totalDays: 31 },
  { monthId: 11, monthName: "nov", totalDays: 30 },
  { monthId: 12, monthName: "dec", totalDays: 31 },
];

export const FYMONTHS = [
  { monthId: 4, monthName: "apr", totalDays: 30 },
  { monthId: 5, monthName: "may", totalDays: 31 },
  { monthId: 6, monthName: "jun", totalDays: 30 },
  { monthId: 7, monthName: "jul", totalDays: 31 },
  { monthId: 8, monthName: "aug", totalDays: 31 },
  { monthId: 9, monthName: "sep", totalDays: 30 },
  { monthId: 10, monthName: "oct", totalDays: 31 },
  { monthId: 11, monthName: "nov", totalDays: 30 },
  { monthId: 12, monthName: "dec", totalDays: 31 },
  { monthId: 1, monthName: "jan", totalDays: 31 },
  { monthId: 2, monthName: "feb", totalDays: 28 },
  { monthId: 3, monthName: "mar", totalDays: 31 },
];

export const DATA_YEAR = new Date().getFullYear();
export const TAX_DEADLINE_TYPE = 0;
export const COMPLIANCE_DEADLINE_TYPE = 1;

export const LEFT_TOOL_TIP_MESSAGE =
  "Sorry, currently we don't have data from any other season";
export const RIGHT_TOOL_TIP_MESSAGE =
  "Sorry, currently we don't have data from any other season";

export const CALENDER_YEAR = "CY : Calendar Year(Jan-Dec)";
export const FISCAL_YEAR = "FY : Fiscal Year(April - March)";

export const COMPLIANCE_CALENDER_YEAR_TOOL =
  "Compliance is always in Calendar Year (Jan-Dec)";

export const TAX_ROUTE = "/tax";
export const COMPLIANCE_ROUTE = "/compliance";

export const ACTION_REQUIRED = "ACTION_REQUIRED";
export const WITH_INKLE = "WITH_INKLE";
export const COMPLETED = "COMPLETED";
export const ACTIVE = "ACTIVE";
