import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Price } from "components/Price";
import { ConditionalLink } from "components/conditionalLink";
import { BOOKKEEPING, BOOKS_PLUS_PLAN, PLATFORM } from "constants/addons";
import { CALENDAR, FISCAL } from "constants/calendarTypes";
import {
  DD_MMM_YYYY,
  FILTER_DATE_INPUT_FORMAT,
  YYYY_MM_DD,
} from "constants/date";
import {
  ACCOUNTING_SOFTWARE,
  BANK_ACCOUNTS,
  BOOKKEEPING_PLANS,
  BULLET_POINT,
  CHECKBOX,
  CLIENT_CONFIRMATION,
  CTA,
  CUSTOM_FIELD,
  ENTER_INFORMATION,
  FILE_UPLOAD,
  IMAGE,
  INCORPORATED_YEAR,
  INPUT,
  MOBILE_INPUT,
  NOTES,
  PLATFORM_PLAN,
  SUBTITLE,
  TITLE,
} from "constants/fieldTypes";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import dayjs, { Dayjs } from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { FormikListener } from "pages/Books/Invoicing/AddInvoice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MultiValue, SingleValue as SingleValueType } from "react-select";
import QuickBooks from "static/images/QuickBooks.svg";
import ZohoBooks from "static/images/ZohoBooks.svg";
import { useOnBoardingUpdateSeasonSummaryMutation } from "store/apis/onboarding";
import {
  useGetOnboardingProductsQuery,
  useUpdateStepFieldMutation,
} from "store/apis/productOnboarding";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { setBookkeepingSoftware } from "store/slices/booksOnboarding";
import { Step, StepComponent } from "types/Models/onboarding";
import { Subscription } from "types/Models/subscription";
import { CALENDAR_TYPES } from "types/contants/calendarTypes";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { BooksBankAccounts } from "./Tax/BooksBankAccounts";
import { Link } from "components/icons/Link";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { INKLE_BOOKS_PRICING_URL } from "constants/inklePricing";
import { ProductCard } from "components/ProductCard/ProductCard";
import { Badge } from "components/design/badge";
import { MONTHLY, YEARLY } from "constants/billingCycles";
import { FileInput, FileType } from "components/FileInput/FileInput";

type HistoricalSeasonSummaryValues = {
  season_year: string;
  start_date: string;
  end_date: string;
  freq_of_reports: string;
  multiple_months: boolean;
};

type EnterInformationValues = {
  historical_season_summary_data: HistoricalSeasonSummaryValues[];
  season_type: CALENDAR_TYPES;
  create_historical_seasons: boolean;
  start_date: Dayjs;
};

const IncorporatedYear = ({
  fieldId,
  value,
  isCurrentStepCompleted,
}: {
  fieldId?: string;
  value: boolean;
  isCurrentStepCompleted: boolean;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [updateField] = useUpdateStepFieldMutation();

  const onIncorporatedBeforeChange = async (value: string) => {
    const payloadValue = value === "YES" ? true : false;
    try {
      await updateField({
        groupId,
        fieldId,
        payload: { value: payloadValue },
      }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <div className="t-mb-6 t-mt-3">
      <Radio.Root
        onValueChange={onIncorporatedBeforeChange}
        defaultValue={value ? "YES" : "NO"}
      >
        <Radio.Content>
          <div className="t-text-subtext t-text-text-60">
            Do you have one or more entity incorporated before{" "}
            {dayjs().startOf("year").format(DD_MMM_YYYY)}?
          </div>
          <div className="t-flex t-w-[30%] t-items-center">
            <Radio.Item value="YES">Yes</Radio.Item>
            <Radio.Item value="NO">No</Radio.Item>
          </div>
        </Radio.Content>
      </Radio.Root>
    </div>
  );
};

const TaxPlatformPlans = ({
  fieldId,
  value,
  isCurrentStepCompleted,
}: {
  fieldId?: string;
  value: string | { selected_subscription_plan: { uuid: string } };
  isCurrentStepCompleted: boolean;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [radioValue, setRadioValue] = useState("");

  const { data: platfromSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      subscription_types: PLATFORM,
    },
    { skip: !groupId }
  );

  const { data: products } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) => p.name === PRODUCT_ONBOARDING.TAX
  );

  const isCurrentYearIncorporated = !platformOnboarding?.steps
    ?.filter(({ step_key }) => step_key === "company_info")?.[0]
    ?.field_values?.filter(
      ({ field_key }) => field_key === "current_year_incorporated"
    )?.[0]?.value;

  const [updateField] = useUpdateStepFieldMutation();

  const taxSubscription = platfromSubscriptions[0] as Subscription | undefined;

  useEffect(() => {
    const updateStepSubsciption = async (subscriptionId: string) => {
      try {
        await updateField({
          groupId,
          fieldId,
          payload: { value: subscriptionId },
        }).unwrap();
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    };

    if (taxSubscription?.uuid) {
      updateStepSubsciption(taxSubscription?.uuid);
    }
  }, [fieldId, groupId, taxSubscription?.uuid]);

  return (
    <div className="t-flex t-flex-col t-gap-5">
      <div className="t-flex t-flex-col t-gap-2">
        {platfromSubscriptions.map(
          ({ subscription_name, amount, description, billing_cycle, uuid }) => (
            <div
              className="t-px-5 t-py-4 t-border t-border-solid t-border-neutral-10 t-flex t-flex-col t-gap-2 t-shadow-light-30 t-rounded-lg"
              key={uuid}
            >
              <div className="t-text-subtitle">
                <div className="t-flex t-justify-between">
                  <div className="t-text-body t-font-medium">
                    {subscription_name}
                  </div>
                  {isCurrentYearIncorporated && (
                    <div className="t-flex t-text-body-sm t-text-neutral-70 t-bg-neutral-0 t-rounded-3xl t-px-3 t-items-center">
                      Next payment:{" "}
                      {dayjs()
                        .add(1, "year")
                        .startOf("year")
                        .format(DD_MMM_YYYY)}
                    </div>
                  )}
                </div>
                <div className="t-flex t-gap-1 t-items-end">
                  <Price
                    amount={amount}
                    cycle={billing_cycle}
                    size="small"
                    {...(isCurrentYearIncorporated
                      ? { type: "STRIKE_THROUGH" }
                      : {})}
                  />
                  {isCurrentYearIncorporated && (
                    <div className="t-text-subtext">Free</div>
                  )}
                </div>
              </div>
              {isCurrentYearIncorporated && (
                <div className="t-text-body-sm t-text-text-30">
                  Current year is free for entities incorporated this year.
                </div>
              )}
              <div className="t-flex t-justify-between t-items-center">
                <ConditionalLink
                  to="https://www.inkle.io/pricing#InkleTax"
                  className="t-text-body-sm t-text-purple t-m-0 t-flex t-items-center t-gap-1"
                >
                  <Link color="currentColor" />
                  See everything you get with Inkle Tax
                </ConditionalLink>
              </div>
            </div>
          )
        )}
      </div>

      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-text-subtext t-pl-0.5">
          Unlock the Inkle platform:
        </div>
        <ul className="t-text-body t-flex t-flex-col t-gap-2 t-text-text-60">
          <li>3 users ($100/year per additional user)</li>
          <li>Dedicated support on chat</li>
          <li>Chat, Filings, Documents, Perks and more</li>
          <li>Custom deadline monitoring and alerts</li>
          <li>Compliance tracking and oversight</li>
        </ul>
      </div>
    </div>
  );
};

const BookkeepingPlans = ({
  fieldId,
  value,
  isCurrentStepCompleted,
  currentStep,
}: {
  fieldId?: string;
  value: {
    subscription_name: string;
    uuid: string;
    product_content_type_id: number;
    stripe_product_id: string;
    stripe_price_id: string;
    description: string;
    is_redeemable: boolean;
    upgrade_level: number;
    amount: string;
    billing_cycle: string;
    subscription_type: string;
    metadata: null;
    bookkeeping_product_type: string;
  };
  isCurrentStepCompleted: boolean;
  currentStep: Step;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [updateField, { isLoading }] = useUpdateStepFieldMutation();

  const [requireBookService, setRequireBookService] = useState(
    Boolean(currentStep.subscription?.uuid)
  );

  const { data: bookkeepingSubscriptionData = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
      subscription_types: BOOKKEEPING,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const handleServiceSelection = async (
    values: SingleValueType<OptionData> | MultiValue<OptionData>
  ) => {
    try {
      if (values as SingleValueType<OptionData>) {
        const payload = {
          value: (values as SingleValueType<OptionData>)?.value as string,
        };
        await updateField({ groupId, fieldId, payload }).unwrap();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const removeServiceSelection = async () => {
    try {
      const payload = {
        value: null,
      };
      await updateField({ groupId, fieldId, payload }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const handleServiceRequireChange = (value: boolean) => {
    setRequireBookService(value);
    if (!value) {
      removeServiceSelection();
    }
  };

  const salesPersonOptions = bookkeepingSubscriptionData.map(
    ({ subscription_name, amount, description, billing_cycle, uuid }) => ({
      label: (
        <div className="t-w-full t-px-2 t-py-1.5">
          <div className="t-flex t-justify-between t-w-full">
            <div>{subscription_name}</div>
            <div className="t-text-dark_green">
              {Number(amount) !== 0 && (
                <Price
                  amount={amount}
                  cycle={billing_cycle}
                  size="small"
                  className="t-text-dark_green"
                />
              )}
            </div>
          </div>
          <div className="t-mt-2 t-text-body-sm t-text-text-60 t-whitespace-break-spaces">
            {description}
          </div>
        </div>
      ),
      value: uuid,
    })
  );

  const selectedBookkeepingService = salesPersonOptions.find(
    ({ value }) => value === currentStep.subscription?.uuid
  );

  return (
    <div className="t-flex t-flex-col t-gap-3 t-mt-4">
      <div className="t-flex t-justify-between">
        <div className="t-text-subtext-sm t-text-text-100">
          Do you require a bookkeeping service?
        </div>
        <Switch
          checked={requireBookService}
          name="requireBookService"
          onCheckedChange={handleServiceRequireChange}
          size="small"
        />
      </div>
      {requireBookService && (
        <Combobox
          components={{
            ClearIndicator: () => null,
          }}
          size="small"
          menuPortalTarget={document.body}
          name="service"
          label=""
          placeholder="Choose a service"
          options={salesPersonOptions}
          isLoading={isLoading}
          onChange={handleServiceSelection}
          creatable={false}
          withForm={false}
          isSearchable={false}
          value={selectedBookkeepingService}
        />
      )}
    </div>
  );
};

const BooksPlan = ({
  fieldId,
  value,
  isCurrentStepCompleted,
  currentStep,
}: {
  fieldId?: string;
  value: {
    subscription_name: string;
    uuid: string;
    product_content_type_id: number;
    stripe_product_id: string;
    stripe_price_id: string;
    description: string;
    is_redeemable: boolean;
    upgrade_level: number;
    amount: string;
    billing_cycle: "YEARLY" | "MONTHLY" | "QUARTERLY";
    subscription_type: string;
    metadata: null;
    bookkeeping_product_type: string;
  };
  isCurrentStepCompleted: boolean;
  currentStep: Step;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [radioValue, setRadioValue] = useState(
    value?.subscription_type || "FREE"
  );
  const [selctedBillingCycle, setSelctedBillingCycle] = useState(
    value?.billing_cycle || MONTHLY
  );
  const [updateField, { isLoading }] = useUpdateStepFieldMutation();

  const { data: bookkeepingSubscriptionPlans = [] } = useGetSubscriptionsQuery(
    {
      subscription_types: BOOKS_PLUS_PLAN,
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const selectedPlusPlan = bookkeepingSubscriptionPlans.find(
    (subscriptions) =>
      subscriptions.billing_cycle === (selctedBillingCycle || MONTHLY)
  );

  const activeBooksPlusPlan = bookkeepingSubscriptionPlans?.find(
    ({ group_subscription = [] }) => group_subscription?.length > 0
  );

  const updateBooksPlan = async ({
    plan,
    selectedPlusPlanId,
  }: {
    plan: string;
    selectedPlusPlanId: string | null;
  }) => {
    const subscriptionId = plan === "FREE" ? null : selectedPlusPlanId;

    try {
      await updateField({
        groupId,
        fieldId,
        payload: { value: subscriptionId },
      }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onBooksPlanChange = ({
    planType,
    plusPlan,
  }: {
    planType: "FREE" | typeof BOOKS_PLUS_PLAN;
    plusPlan: "YEARLY" | "MONTHLY";
  }) => {
    const isFreePlan = planType === "FREE";
    setSelctedBillingCycle(isFreePlan ? MONTHLY : plusPlan);
    setRadioValue(planType);

    const selectedPlusPlanId = bookkeepingSubscriptionPlans.find(
      (subscriptions) => subscriptions.billing_cycle === plusPlan
    );

    const planId = isFreePlan ? null : selectedPlusPlanId?.uuid!;

    updateBooksPlan({
      plan: planType,
      selectedPlusPlanId: planId,
    });
  };

  if (activeBooksPlusPlan) {
    return (
      <ProductCard
        title={
          <div className="t-flex t-gap-2">
            {activeBooksPlusPlan.subscription_name}
            {activeBooksPlusPlan.group_subscription && (
              <div className="t-text-body">
                <Badge color="light-green">Active</Badge>
              </div>
            )}
          </div>
        }
        price={
          <Price
            amount={activeBooksPlusPlan.amount}
            size="small"
            cycle={activeBooksPlusPlan.billing_cycle}
          />
        }
        description={
          <div className="t-text-body-sm t-text-text-30">
            Take your accounting to the next level with premium features and
            automation.
          </div>
        }
        action={<></>}
      />
    );
  }

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-text-h6 t-text-text-100">Choose a plan</div>
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-text-subtext-sm t-text-text-100">
          Choose a subscription
        </div>
        <Radio.Root
          value={radioValue}
          defaultValue={radioValue}
          disabled={isLoading}
          onValueChange={(plan) => {
            onBooksPlanChange({
              planType: plan as "FREE" | typeof BOOKS_PLUS_PLAN,
              plusPlan: "MONTHLY",
            });
          }}
        >
          <Radio.Content>
            <div className="t-flex t-flex-col t-gap-2">
              <div className="t-px-4 t-py-3 t-rounded t-border t-border-solid t-border-neutral-0 hover:t-border-purple-20 t-flex t-flex-col t-gap-2">
                <Radio.Item asChild value="FREE">
                  <div className="t-flex t-justify-between t-text-subtitle t-items-center t-h-full">
                    <div className="t-text-body t-font-medium">Basic Plan</div>
                    <div className="t-text-dark_green t-text-subtitle-sm">
                      Free
                    </div>
                  </div>
                </Radio.Item>
                <div className="t-text-body-sm t-text-text-60">
                  Everything you need to get started with accounting.
                </div>
              </div>
            </div>
            <div className="t-px-4 t-py-3 t-rounded t-border t-border-solid t-border-neutral-0 hover:t-border-purple-20 t-flex t-flex-col t-gap-2">
              <div className="t-flex t-justify-between t-text-subtitle t-items-center t-h-full">
                <Radio.Item asChild value={BOOKS_PLUS_PLAN}>
                  <div className="t-flex t-justify-between t-text-subtitle t-items-center t-h-full">
                    <div className="t-text-body t-font-medium">Plus Plan</div>
                  </div>
                </Radio.Item>
                <ToggleGroup
                  defaultValue={selctedBillingCycle}
                  value={selctedBillingCycle}
                  onValueChange={(plusPlan) => {
                    if (plusPlan) {
                      onBooksPlanChange({
                        planType: BOOKS_PLUS_PLAN,
                        plusPlan: plusPlan as "YEARLY" | "MONTHLY",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  <ToggleGroupItem value={MONTHLY}>Monthly</ToggleGroupItem>
                  <ToggleGroupItem value={YEARLY}>Annually</ToggleGroupItem>
                </ToggleGroup>
              </div>

              <Price
                amount={selectedPlusPlan?.amount}
                size="medium"
                cycle={selectedPlusPlan?.billing_cycle}
              />
              <div className="t-text-body-sm t-text-text-60">
                Take your accounting to the next level with premium features and
                automation.
              </div>
              <ConditionalLink
                to={INKLE_BOOKS_PRICING_URL}
                className="t-w-max t-text-purple t-bg-surface-transparent hover:t-text-purple-80 hover:t-underline t-text-body"
              >
                <Link size="16" color="currentColor" />
                <span className="t-ml-1 hover:t-underline">
                  See everything you get with Plus Plan
                </span>
              </ConditionalLink>
            </div>
          </Radio.Content>
        </Radio.Root>
      </div>
    </div>
  );
};

const AccountingSoftware = ({
  fieldId,
  value,
}: {
  fieldId?: string;
  value: { bookkeeping_software: string };
}) => {
  const bookkeeping_software = value?.bookkeeping_software || "";
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast } = useToast();
  const dispatch = useDispatch();

  const [updateField, { isLoading }] = useUpdateStepFieldMutation();

  const onUpdateField = async (selectedSoftware: string) => {
    dispatch(setBookkeepingSoftware(selectedSoftware));
    try {
      const value =
        bookkeeping_software === selectedSoftware ? "" : selectedSoftware;
      const payload = { value: value };
      await updateField({ groupId, fieldId, payload }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <div className="t-h-full t-w-full t-flex t-flex-col t-justify-between">
      <div className="t-flex t-flex-col t-gap-6">
        <div className="t-flex t-flex-col t-gap-3 t-items-center">
          <div className="t-flex t-gap-2.5">
            <button
              className={classNames(
                "all:unset t-py-1 t-px-2 t-flex t-gap-1 t-text-text-60 t-bg-i-surface-grey t-rounded-md t-h-7 t-items-center t-w-max",
                {
                  "t-border t-border-solid t-border-purple":
                    bookkeeping_software === "QuickBooks",
                }
              )}
              onClick={() => onUpdateField("QuickBooks")}
            >
              <img src={QuickBooks} alt="QuickBooks" />
              <span>Quickbooks</span>
            </button>
            <button
              className={classNames(
                "all:unset t-py-1 t-px-2 t-flex t-gap-1 t-text-text-60 t-bg-i-surface-grey t-rounded-md t-h-7 t-items-center t-w-max",
                {
                  "t-border t-border-solid t-border-purple":
                    bookkeeping_software === "Zoho Books",
                }
              )}
              onClick={() => onUpdateField("Zoho Books")}
            >
              <img src={ZohoBooks} alt="ZohoBooks" />
              <span>Zoho Books</span>
            </button>
          </div>
          {bookkeeping_software === "" && (
            <>
              <div>or</div>
              <div className="t-flex t-items-center">
                <Checkbox
                  label="I don’t use any accounting software"
                  onChange={() => onUpdateField("")}
                />
              </div>
            </>
          )}
        </div>
        {bookkeeping_software === "QuickBooks" && (
          <div className="t-text-body t-text-text-30">
            <div>
              Steps to add our bookkeepers in your{" "}
              <ConditionalLink
                to="www.quickbooks.com"
                className="t-text-purple hover:!t-underline"
              >
                Quickbooks
              </ConditionalLink>
            </div>
            <ul>
              <li>
                If you don't already have a QuickBooks Online account, create
                one.
              </li>
              <li>
                Click the settings icon in the top right corner and select
                "Manage Team".
              </li>
              <li>Click the Add User button.</li>
              <li>
                Select the Role as Admin and add{" "}
                <ConditionalLink
                  to="mailto:admin@inkle.io"
                  className="t-text-purple hover:!t-underline"
                >
                  admin@inkle.io
                </ConditionalLink>{" "}
                as the user.
              </li>
            </ul>
          </div>
        )}
        {bookkeeping_software === "Zoho Books" && (
          <div className="t-text-body t-text-text-30">
            <div>
              Steps to add our bookkeepers in your{" "}
              <ConditionalLink
                to="https://www.zoho.com/in/books/"
                className="t-text-purple hover:!t-underline"
              >
                Zoho Books
              </ConditionalLink>
            </div>
            <ul>
              <li>
                If you don't already have a Zoho Books account, create one and
                select the US Data center for your account.
              </li>
              <li>
                Click the settings icon in the top right corner and select Users
                & Roles.
              </li>
              <li>
                Click the All Users tab and click Invite User in the top right
                corner.
              </li>
              <li>
                Select the Role as Admin and add{" "}
                <ConditionalLink
                  to="mailto:team_alpha@inkle.io"
                  className="t-text-purple hover:!t-underline"
                >
                  team_alpha@inkle.io
                </ConditionalLink>{" "}
                as the user.
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const EnterInformation = ({
  value,
  isCurrentStepCompleted,
}: {
  value: string;
  isCurrentStepCompleted: boolean;
}) => {
  const { alertToast } = useToast();
  const { name } = useCurrentGroupContext();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [updateSeasonSummary, { isLoading }] =
    useOnBoardingUpdateSeasonSummaryMutation();

  const onChange = debounce(async (values: EnterInformationValues) => {
    const payload = {
      ...values,
      start_date: values?.start_date
        ? dayjs(values?.start_date).format(YYYY_MM_DD)
        : "",
    };

    if (!isCurrentStepCompleted && dayjs(payload.start_date).isValid()) {
      try {
        await updateSeasonSummary({
          groupId,
          entityId,
          payload,
        }).unwrap();
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    }
  });

  const booksSeasons = [
    { label: "January to December", value: CALENDAR },
    { label: "April to March", value: FISCAL },
  ].map(({ label, value }) => ({
    label,
    value,
  }));

  return (
    <Formik
      initialValues={{
        season_type: CALENDAR,
        create_historical_seasons: false,
        start_date: dayjs("01-01-2024").format(DD_MMM_YYYY),
      }}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form className="t-m-0 t-w-full">
            <FormikListener
              values={values}
              callback={(v) => onChange(v as EnterInformationValues)}
            />
            <div className="t-flex t-flex-col t-gap-4">
              <div className="t-text-h6 t-text-text-100">Enter information</div>
              <Combobox
                components={{
                  ClearIndicator: () => null,
                }}
                size="small"
                menuPortalTarget={document.body}
                name="season_type"
                label="Bookkeeping season starts from"
                placeholder="Select season"
                options={booksSeasons}
                isLoading={isLoading}
                creatable={false}
                withForm={false}
                isSearchable={false}
                onChange={(
                  values: SingleValueType<OptionData> | MultiValue<OptionData>
                ) => {
                  const seasonType = (values as SingleValueType<OptionData>)
                    ?.value as string;
                  const currentYear = dayjs().year();
                  setFieldValue("season_type", seasonType);
                  if (seasonType === CALENDAR) {
                    setFieldValue(
                      "start_date",
                      dayjs().startOf("year").format(DD_MMM_YYYY)
                    );
                  } else if (seasonType === FISCAL) {
                    setFieldValue(
                      "start_date",
                      dayjs(`04-01-${currentYear}`).format(DD_MMM_YYYY)
                    );
                  }
                }}
                value={booksSeasons.find(
                  ({ value }) => value === values.season_type
                )}
              />
              <Field name="start_date">
                {({ field }: FieldProps) => {
                  return (
                    <DateInput
                      portalId="onboarding-portal-id"
                      {...field}
                      label="Bookkeeping on Inkle starts from"
                      placeholder={FILTER_DATE_INPUT_FORMAT}
                      onDateChange={(date) => setFieldValue("start_date", date)}
                      showMonthYearPicker
                    />
                  );
                }}
              </Field>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const CustomFieldComponents = ({
  component,
  currentStep,
}: {
  component: StepComponent;
  currentStep: Step;
}) => {
  switch (component.content) {
    case ACCOUNTING_SOFTWARE:
      return (
        <AccountingSoftware fieldId={component.uuid} value={component.value} />
      );

    case BOOKKEEPING_PLANS:
      return (
        <BookkeepingPlans
          fieldId={component.uuid}
          value={component.value}
          isCurrentStepCompleted={Boolean(currentStep?.status === "COMPLETED")}
          currentStep={currentStep}
        />
      );

    case BOOKS_PLUS_PLAN:
      return (
        <BooksPlan
          fieldId={component.uuid}
          value={component.value}
          isCurrentStepCompleted={Boolean(currentStep?.status === "COMPLETED")}
          currentStep={currentStep}
        />
      );

    case ENTER_INFORMATION:
      return (
        <EnterInformation
          value={component.value}
          isCurrentStepCompleted={Boolean(currentStep?.status === "COMPLETED")}
        />
      );

    case INCORPORATED_YEAR:
      return (
        <IncorporatedYear
          fieldId={component.uuid}
          value={component.value}
          isCurrentStepCompleted={Boolean(currentStep?.status === "COMPLETED")}
        />
      );

    case PLATFORM_PLAN:
      return (
        <TaxPlatformPlans
          fieldId={component.uuid}
          value={component.value}
          isCurrentStepCompleted={Boolean(currentStep?.status === "COMPLETED")}
        />
      );

    default:
      return <></>;
  }
};

type ComponentGeneratorProps = {
  component: StepComponent;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldId: string
  ) => Promise<void>;
  handleFilesDrop?: (
    files: File[],
    fieldId: string,
    tagName?: string
  ) => Promise<void>;
  handleFileDelete?: (fieldDocumentMapId: string) => Promise<void>;
  name: string;
  handleCheckBoxChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldId: string
  ) => Promise<void>;
  isCurrentStepDisabled: boolean;
  currentStep: Step;
};

export const ComponentGenerator = ({
  component,
  handleChange,
  handleFilesDrop,
  handleFileDelete,
  name,
  handleCheckBoxChange,
  isCurrentStepDisabled,
  currentStep,
}: ComponentGeneratorProps) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFileDeleting, setIsFileDeleting] = useState(false);
  const [fileDeletingFileId, setFileDeletingFileId] = useState("");

  const onFileUpload = async (
    files: File[],
    fieldId: string,
    tagName?: string
  ) => {
    setIsFileUploading(true);
    await handleFilesDrop?.(files, fieldId, tagName);
    setIsFileUploading(false);
  };

  const onFileDelete = async (fieldDocumentMapId: string) => {
    setIsFileDeleting(true);
    setFileDeletingFileId(fieldDocumentMapId);
    await handleFileDelete?.(fieldDocumentMapId);
    setIsFileDeleting(false);
  };

  switch (component.type) {
    case TITLE:
      return (
        <span className="t-text-h5 t-text-text-100 t-block">
          {component.content}
        </span>
      );

    case SUBTITLE: {
      return (
        <span
          className="t-text-body-sm t-text-text-30 t-mb-6 t-block"
          dangerouslySetInnerHTML={{ __html: component.content }}
        ></span>
      );
    }

    case INPUT:
      return (
        <div className="t-mb-8">
          <TextInput
            label={component.content}
            placeholder={component?.placeholder}
            disabled={!component?.is_editable || isCurrentStepDisabled}
            onChange={(e) => handleChange?.(e, component.uuid)}
            block
            name={name}
          />
        </div>
      );

    case MOBILE_INPUT:
      return (
        <MobileInput
          label={component.content}
          customSize="small"
          onChange={(e) => handleChange?.(e, component.uuid)}
          name={name}
          disabled={isCurrentStepDisabled}
        />
      );

    case FILE_UPLOAD:
      const filesToShow = component.value.map((file: any) => ({
        uploaded: !file.status,
        file: file,
        isLoading: file.status === "DELETING" || file.status === "UPLOADING",
      }));

      return (
        <div className="t-my-4">
          <FileInput
            label={component.content}
            onDelete={(e, i) => {
              onFileDelete?.(component.value[i || 0].field_document_map_id);
            }}
            files={filesToShow}
            multiple
            disabled={isCurrentStepDisabled}
            isUploading={isFileUploading}
            isDeleting={isFileDeleting}
            fileDeletingUuid={fileDeletingFileId}
            onDrop={(files: FileType[]) => {
              onFileUpload?.(
                files as File[],
                component.uuid,
                component.tag_name
              );
            }}
          />
        </div>
      );

    case IMAGE:
      return (
        <div className="t-w-full t-mb-6 t-text-center">
          <img src={component.content} alt={component.field_key} />
        </div>
      );

    case CTA:
      return (
        <div className="t-flex t-justify-center">
          <ConditionalLink to={component.content}>
            <Button
              customType="secondary"
              size="small"
              type="button"
              disabled={isCurrentStepDisabled}
            >
              {component.placeholder}
            </Button>
          </ConditionalLink>
        </div>
      );

    case BANK_ACCOUNTS:
      return <BooksBankAccounts />;

    case BULLET_POINT:
      return (
        <li className="t-text-body-sm t-text-text-30 t-mb-1">
          {component.content}
        </li>
      );

    case CHECKBOX:
      return (
        <div className="t-mt-4">
          <Checkbox
            name={name}
            label={
              <div className="t-font-sans t-text-caption t-text-text-100 t-pb-1.5">
                {component.content}
              </div>
            }
            alignTop
            onChange={(e) => handleCheckBoxChange?.(e, component.uuid)}
            disabled={isCurrentStepDisabled}
          />
        </div>
      );

    case NOTES:
      return (
        <div className="t-p-2 t-rounded t-border-blue-70 t-border-[0.5px] t-border-solid t-bg-blue-10 t-text-body-sm t-text-text-60 t-my-3">
          {component.content}
        </div>
      );

    case CUSTOM_FIELD: {
      return (
        <CustomFieldComponents
          component={component}
          currentStep={currentStep}
        />
      );
    }

    case CLIENT_CONFIRMATION:
      return null;

    default:
      return null;
  }
};
