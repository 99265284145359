import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";

//style
import "../../../static/styles/components/groupSelect.css";

//components
import DropDownItem from "../dropDownItem";

//api
import { getCompanyGroups } from "../../../apis/getCompanyGroups";
import { putSwitchGroup } from "../../../apis/putSwitchGroup";
import { useMatchRoutes } from "../../../hooks/useMatchRoutes";
import { useSelector } from "react-redux";
import { Button } from "components/DesignSystem/Button/Button";

export default function GroupSelect() {
  const [groups, setGroups] = useState([]);
  const history = useHistory();
  const { dashboardSidebarExpand, isCollapsedSidebarHovered } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    getCompanyGroups()
      .then((res) => {
        setGroups(res.data.data.company_groups);
      })
      .catch((err) => {});
  }, []);
  function switchGroup(uuid) {
    putSwitchGroup({ uuid }).then(() => {
      window.location.reload();
    });
  }

  return (
    <div className="groupSelect">
      <div>
        <button
          type="button"
          id="dropDownButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="t-p-1 t-overflow-hidden t-text-ellipsis t-border-none t-bg-surface t-w-full t-flex t-items-center t-m-0 t-outline-none t-justify-between"
        >
          <div className="t-flex t-gap-1">
            <p className="dropdownHeaderContainerTitle t-m-0">
              {groups?.[0]?.name}
            </p>
            <p
              className={classNames(
                "dropdownHeaderContainerSubtitle t-m-0",
                {}
              )}
            >
              Group
            </p>
          </div>
          <i className="fas fa-angle-down dropIcon"></i>
        </button>

        <ul
          className="dropdown-menu sm:!t-px-2 t-ml-3"
          aria-labelledby="dropDownButton"
        >
          {groups?.map((element) => {
            return (
              <DropDownItem
                key={element.uuid}
                onClick={() => switchGroup(element.uuid)}
                className={classNames("t-p-1 px-2", {
                  "t-text-purple-50": element.uuid === groups?.[0]?.uuid,
                })}
              >
                {element.name}
              </DropDownItem>
            );
          })}
          <DropDownItem>
            <Link to="/profile">
              <Button customType="primary">Create new group</Button>
            </Link>
          </DropDownItem>
        </ul>
      </div>
    </div>
  );
}
