export const InvestorUpdates = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.70005 3.69996V10.8H13.3V3.69996H2.70005ZM1.30005 3.49996C1.30005 2.83722 1.83731 2.29996 2.50005 2.29996H13.5C14.1628 2.29996 14.7 2.83722 14.7 3.49996V11C14.7 11.6627 14.1628 12.2 13.5 12.2H2.50005C1.83731 12.2 1.30005 11.6627 1.30005 11V3.49996Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.50503 11.5051C9.7784 11.2317 10.2216 11.2317 10.495 11.5051L12.495 13.5051C12.7683 13.7784 12.7683 14.2217 12.495 14.495C12.2216 14.7684 11.7784 14.7684 11.505 14.495L9.50503 12.495C9.23166 12.2217 9.23166 11.7784 9.50503 11.5051Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.49495 11.5051C6.76831 11.7784 6.76831 12.2217 6.49495 12.495L4.49495 14.495C4.22158 14.7684 3.77836 14.7684 3.505 14.495C3.23163 14.2217 3.23163 13.7784 3.505 13.5051L5.505 11.5051C5.77836 11.2317 6.22158 11.2317 6.49495 11.5051Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00003 6.79999C6.38663 6.79999 6.70003 7.11339 6.70003 7.49999V8.99999C6.70003 9.38659 6.38663 9.69999 6.00003 9.69999C5.61343 9.69999 5.30003 9.38659 5.30003 8.99999V7.49999C5.30003 7.11339 5.61343 6.79999 6.00003 6.79999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99994 5.79999C8.38654 5.79999 8.69994 6.11339 8.69994 6.49999V8.99999C8.69994 9.38659 8.38654 9.69999 7.99994 9.69999C7.61334 9.69999 7.29994 9.38659 7.29994 8.99999V6.49999C7.29994 6.11339 7.61334 5.79999 7.99994 5.79999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 4.79996C10.3866 4.79996 10.7 5.11336 10.7 5.49996V8.99996C10.7 9.38656 10.3866 9.69996 10 9.69996C9.6134 9.69996 9.3 9.38656 9.3 8.99996V5.49996C9.3 5.11336 9.6134 4.79996 10 4.79996Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99994 0.63327C8.38654 0.63327 8.69994 0.946671 8.69994 1.33327L8.69994 2.6666C8.69994 3.0532 8.38654 3.3666 7.99994 3.3666C7.61334 3.3666 7.29994 3.0532 7.29994 2.6666L7.29994 1.33327C7.29994 0.946671 7.61334 0.63327 7.99994 0.63327Z"
      fill="currentColor"
    />
  </svg>
);
