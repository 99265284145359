import { setAddonData, setAddonType } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import { Subscription } from "types/Models/subscription";
import { useAppDispatch } from "./useAppDispatch";

export const useUpgradePlan = () => {
  const dispatch = useAppDispatch();

  const upgrade = ({ addon }: { addon: Subscription }) => {
    dispatch(toggleCartSubscription(false));
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type!));
  };

  return { upgrade };
};
