import cn from "classnames";

import "../../static/styles/components/badge.css";
import ConditionalToolTip from "./conditionalToolTip";

export const Badge = ({
  color = "yellow",
  trim = false,
  children,
  size = "medium",
  fitContent = false,
}) => {
  return (
    <ConditionalToolTip condition={trim && children}>
      <div
        className={cn("custom-badge", {
          "custom-badge-yellow": color === "yellow",
          "custom-badge-green": color === "green",
          "custom-badge-red": color === "red",
          "custom-badge-purple": color === "purple",
          "custom-badge-blue": color === "blue",
          "t-bg-orange-30 t-text-orange-80": color === "orange",
          "custom-badge-light-green": color === "light-green",
          "t-bg-blue-0 t-text-blue-70": color === "blue70",
          "t-bg-neutral-10 t-text-text-100": color === "neutral",
          "custom-badge-trim": trim,
          "t-bg-purple t-text-white": color === "dark-purple",
          "t-bg-purple-0 t-text-purple": color === "light-purple",
          "t-bg-neutral-0 t-text-text-30": color === "neutral",
          "t-w-fit": fitContent,
          " t-bg-red-20 t-text-red-80": color == "dark-red",
          [size]: size,
        })}
      >
        {children}
      </div>
    </ConditionalToolTip>
  );
};
