const SmallOptionIcon = ({ color = "white", backgroundColor = "white" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_654_54365)">
        <path
          d="M12 12.75C12.4142 12.75 12.75 12.4142 12.75 12C12.75 11.5858 12.4142 11.25 12 11.25C11.5858 11.25 11.25 11.5858 11.25 12C11.25 12.4142 11.5858 12.75 12 12.75Z"
          fill={backgroundColor}
          stroke={color}
        />
        <path
          d="M12 8.5C12.4142 8.5 12.75 8.16421 12.75 7.75C12.75 7.33579 12.4142 7 12 7C11.5858 7 11.25 7.33579 11.25 7.75C11.25 8.16421 11.5858 8.5 12 8.5Z"
          fill={backgroundColor}
          stroke={color}
        />
        <path
          d="M12 17C12.4142 17 12.75 16.6642 12.75 16.25C12.75 15.8358 12.4142 15.5 12 15.5C11.5858 15.5 11.25 15.8358 11.25 16.25C11.25 16.6642 11.5858 17 12 17Z"
          fill={backgroundColor}
          stroke={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_654_54365">
          <rect
            width="16"
            height="16"
            fill={backgroundColor}
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SmallOptionIcon;
