import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import { resetAllAddonDataPayload } from "store/slices/addons";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const CancelBookkeepingPlusPlanModal = ({
  close,
  isOpen,
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { addonData } = useAppSelector((state) => state.addonsSlice);
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const entityId = useCurrentEntityId();

  const onClose = () => {
    close();
    dispatch(resetAllAddonDataPayload());
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        groupId,
        entityId,
        subscriptionId:
          addonData?.group_subscription?.[0]?.group_subscription_id,
      }).unwrap();
      successToast({ message: "Subscription cancelled successfully" });
      onClose();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
      onClose();
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel subscription</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            Are you sure you want to cancel the subscription?
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={handleCancelSubscription}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Cancel subscription
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
