import { useToast } from "hooks/useToast";
import { useContext, useState } from "react";
import { assignUserToTask } from "../apis/task";
import authContext from "../jwt_context&axios/authContext";
import "../static/styles/components/taskAssignee.css";
import { Badge } from "./design/badge";
import { Button } from "./DesignSystem/Button/Button";
import DropDown from "./DesignSystem/Dropdown/Dropdown";
import CirclePlus from "./icons/circlePlus";
import Pencil from "./icons/pencil";
import SmallPencil from "./icons/SmallPencil";

export const TaskAssignee = ({
  members,
  taskId,
  selectedId,
  updateTaskWithUser,
  size,
}) => {
  const [isLoading, setLoading] = useState(false);
  const { alertToast } = useToast();
  const [userId, setUserId] = useState(selectedId);
  const { authtoken } = useContext(authContext);

  const onSelect = async (value) => {
    // Retaining the previous value so that we can show loader when removing
    setUserId((prevId) => value || prevId);
    const selectedUser = members.find((m) => m.uuid === value);
    try {
      setLoading(true);
      await assignUserToTask({ taskId, userId: value });
      await updateTaskWithUser({
        taskId,
        user: selectedUser || { name: "", uuid: "" },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alertToast({ message: "Failed to assign user" });
    }
  };

  const onRemove = (event) => {
    event.stopPropagation();
    onSelect(null);
  };

  const selectedUser = members.find((m) => m.uuid === selectedId);
  const isServiceNonAdminUser =
    authtoken.is_any_service_user && authtoken.is_foreign_ca;

  if (isServiceNonAdminUser) {
    return (
      <div className="d-flex">
        <Badge color="blue">{selectedUser?.name || "No assignee"}</Badge>
      </div>
    );
  }

  return (
    <DropDown.Root>
      <DropDown.Trigger className="all:unset" asChild>
        {selectedUser ? (
          <span role="button">
            <div className="d-flex task-assign-assigned -t-mt-1">
              <Badge color="purple" size="small">
                {selectedUser.name}
              </Badge>
              <div className="task-assignee-pencil">
                {size === "small" ? <SmallPencil /> : <Pencil />}
              </div>
            </div>
          </span>
        ) : (
          <span role="button">
            <CirclePlus />
          </span>
        )}
      </DropDown.Trigger>
      <DropDown.Portal>
        <DropDown.Content
          sideOffset={8}
          align="end"
          className="t-relative -t-mr-4 t-mt-2  t-max-h-72  t-overflow-auto t-w-64 t-rounded-lg t-bg-surface t-drop-shadow-dropdown sm:t-max-h-52"
        >
          {members.map(({ name, uuid }) => (
            <DropDown.Item
              as="button"
              eventKey={uuid}
              className="button-reset task-assign-item"
              key={uuid}
              onSelect={() => onSelect(uuid)}
            >
              {name}

              {isLoading && uuid === userId && (
                <i className="fas fa-spinner fa-spin task-assign-icon" />
              )}

              {selectedId === uuid && !isLoading && (
                <button className="button-reset" onClick={onRemove}>
                  <i className="far fa-times-circle task-assign-icon" />
                </button>
              )}
            </DropDown.Item>
          ))}
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
};
