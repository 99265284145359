import DashboardContainer from "components/dashboard/DashboardContainer";
import Loader from "components/design/loader";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { CallFromAdmin } from "components/DesignSystem/phonecall/CallFromAdmin";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { CallNotesModal } from "components/MessageInput/AdminCall/CallNotesModal";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import CallBackIcon from "static/images/CallBackIcon.svg";
import IncomingCallIcon from "static/images/IncomingCallIcon.svg";
import MissedCallIcon from "static/images/MissedCallIcon.svg";
import OutgoingCallIcon from "static/images/OutgoingCallIcon.svg";
import SmallNotesIcon from "static/images/SmallNotesIcon.svg";
import { AgentDetails, useGetListOfAllPhoneCallsQuery } from "store/apis/chat";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import { CallHistoryFilter } from "./CallHistoryFilter/CallHistoryFilter";

export const CallHistory = () => {
  const query = useQuery();
  const { update } = useUpdateQuery();
  const pageNum = Number(query.get("page") || 1);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    authtoken: { uuid: currentUserProfileId },
  } = useContext(authContext);

  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      GROUPS: [] as string[],
      AGENT: [] as string[],
      CALL_ACTIVITY: [] as string[],
      START_DATE: "" as string,
      END_DATE: "" as string,
      SELECT_PERIOD: "" as string,
    },
  });

  const filterAsArg = {
    agents: filterValues.AGENT.join(",") || null,
    callActivity: filterValues.CALL_ACTIVITY.join(",") || null,
    endDate: filterValues?.END_DATE
      ? dayjs(filterValues.END_DATE).format(YYYY_MM_DD)
      : null,
    startDate: filterValues?.START_DATE
      ? dayjs(filterValues.START_DATE).format(YYYY_MM_DD)
      : null,
    groups: filterValues.GROUPS.join(",") || null,
    searchQuery: searchQuery || null,
  };

  const { data: callsHistory, isLoading } = useGetListOfAllPhoneCallsQuery(
    {
      pageNum,
      ...filterAsArg,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [groupUserPhoneCallId, setGroupUserPhoneCallId] = useState("");
  const [calledAgentProfileId, setCalledAgentProfileId] = useState("");

  const [companyGroupForCall, setCompanyGroupForCall] = useState({
    mobile: "",
    groupUserId: "",
    groupName: "",
  });

  const showNotesModal = useModal();
  const callModal = useModal();

  const {
    calls = [],
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 25,
  } = callsHistory || {};

  const onPageNumChange = (currentPage: number) => {
    update({ query: "page", value: currentPage });
  };

  const {
    setTotalPage,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
  } = usePagination({
    pageNumber: pageNum,
    onPageNumChange,
  });

  useEffect(() => {
    setTotalPage(total_pages);
  }, [setTotalPage, total_pages]);

  const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "page", value: 1 });
    setSearchQuery(e.target.value);
  });

  const openNotes = (groupId: string, agentProfileId: string) => {
    setGroupUserPhoneCallId(groupId);
    setCalledAgentProfileId(agentProfileId);
    showNotesModal.open();
  };

  const openCallModal = (groupDetail: {
    mobile: string;
    groupUserId: string;
    groupName: string;
  }) => {
    setCompanyGroupForCall(groupDetail);
    callModal.open();
  };

  const columnHelper = createColumnHelper<AgentDetails>();

  const columns = [
    columnHelper.accessor("company_group_user_name", {
      header: "CUSTOMER",
      size: 20,
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <div className="t-text-text-60 t-flex t-flex-col">
            <div className="t-text-body">{info.getValue()}</div>
            <div className="t-text-body-sm">{rowData.company_group_name}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("call_type", {
      header: "CALL ACTIVITY",
      size: 15,
      cell: (info) => {
        switch (info.getValue()) {
          case "MISSED": {
            return (
              <div className="t-flex t-gap-2 t-items-center t-text-body t-text-red">
                <img src={MissedCallIcon} alt="MissedCallIcon" />
                <div>Missed call</div>
              </div>
            );
          }
          case "INCOMING": {
            return (
              <div className="t-flex t-gap-2 t-items-center t-text-body t-text-text-60">
                <img src={IncomingCallIcon} alt="IncomingCallIcon" />
                <div>Incoming</div>
              </div>
            );
          }
          case "OUTGOING": {
            return (
              <div className="t-flex t-gap-2 t-items-center t-text-body t-text-text-60">
                <img src={OutgoingCallIcon} alt="OutgoingCallIcon" />
                <div>Outgoing</div>
              </div>
            );
          }
          default: {
            return <>-</>;
          }
        }
      },
    }),
    columnHelper.accessor("called_agent.name", {
      header: "AGENT",
      size: 20,
      cell: (info) => (
        <div className="t-text-text-60 t-text-body">{info.getValue()}</div>
      ),
    }),
    columnHelper.accessor("created_at", {
      header: "DATE",
      size: 15,
      cell: (info) => (
        <div className="t-text-text-60 t-text-body">
          {formatDate(info.getValue())}
        </div>
      ),
    }),
    columnHelper.accessor("time", {
      header: "Time",
      size: 10,
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <div className="t-text-text-60 t-text-body">
            {dayjs(rowData.created_at).format("LT")}
          </div>
        );
      },
    }),
    columnHelper.display({
      header: "NOTES",
      size: 10,
      cell: (info) => (
        <Button
          customType="ghost_icon"
          onClick={() =>
            openNotes(
              info.row.original.uuid,
              info.row.original.called_agent.profile_id
            )
          }
        >
          <img src={SmallNotesIcon} alt="SmallNotesIcon" />
        </Button>
      ),
    }),
    columnHelper.display({
      header: "CALL BACK",
      size: 10,
      cell: (info) => (
        <Button
          customType="icon"
          size="small"
          onClick={() =>
            openCallModal({
              mobile: info.row.original.company_group_user_phone,
              groupUserId: info.row.original.company_group_user_map_id,
              groupName: info.row.original.company_group_name,
            })
          }
        >
          <img src={CallBackIcon} alt="CallBackIcon" />
        </Button>
      ),
    }),
  ];

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const table = useReactTable({
    data: calls,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className=" t-p-5 ">
      <DashboardContainer.Header>
        <div className="t-flex t-flex-col t-gap-3 t-mb-5">
          <div className="t-flex t-gap-4 t-items-center t-justify-between">
            <div className="t-w-1/2">
              <Search onChange={onChange} placeholder="Search" block />
            </div>
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
          <CallHistoryFilter
            updateFilter={updateFilter}
            values={filterValues}
          />
        </div>
      </DashboardContainer.Header>
      <TableUI table={table}>
        {({ row }) => (
          <Table.Row key={row.id} className="hover:t-bg-surface-lighter-grey">
            {row.getVisibleCells().map((cell) => {
              return (
                <Table.Cell
                  key={cell.id}
                  style={{
                    width: `${cell.column.getSize()}%`,
                    padding: "12px 8px",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              );
            })}
          </Table.Row>
        )}
      </TableUI>

      <CallNotesModal
        isOpen={showNotesModal.isOpen}
        close={showNotesModal.close}
        viewOnly={calledAgentProfileId !== currentUserProfileId}
        groupUserPhoneCallId={groupUserPhoneCallId}
      />

      <CallFromAdmin
        groupDetail={companyGroupForCall}
        isOpen={callModal.isOpen}
        close={callModal.close}
      />
    </div>
  );
};
