import { emptyApi } from "./emptyApi";
import qs from "qs";
import { CompanyGroup } from "types/Models/groups";

type GetAllGroupsProps = {
  page_num?: number;
  search_term?: string | null;
  tag_filter?: string | null;
  subscription_filter?: string | null;
  sort_filter?: string | null;
  bank_filter?: string | null;
  stage_filter?: string | null;
  agent_filter?: string | null;
  salesperson_filter?: string | null;
  crm_view_filter?: string | null;
};

type CompanyGroupResponse = {
  company_groups: CompanyGroup[];
  current_page: number;
  total_count: number;
  total_pages: number;
  per_page: number;
};

type MarkAsVipCompanyGroupResponse = {
  uuid: string;
  name: string;
  sales_stage: string;
  ops_stage: string;
  crm_type: string;
  tags: any | null;
  assigned_agent: string | null;
  task: any | null;
  connected_banks: [
    {
      uuid: string;
      in_error_state: boolean;
      bank_brand: {
        name: string;
      };
    }
  ];
  subscription_name: string;
  is_subscription_required: boolean;
  is_perk_redemption_allowed: boolean;
  last_quote_sent: any | null;
  referer: any | null;
  referer_text: any | null;
  coupon_code: string;
  founder: {
    uuid: string;
    email: string;
    first_name: string;
    last_name: string;
    user_type: string;
  };
  is_restricted: boolean;
  is_rejected: boolean;
  is_created_from_crm: boolean;
  is_vip: boolean;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllGroups: build.query<CompanyGroupResponse, GetAllGroupsProps>({
      query: ({
        page_num,
        search_term,
        tag_filter,
        subscription_filter,
        sort_filter,
        bank_filter,
        stage_filter,
        agent_filter,
        salesperson_filter,
        crm_view_filter,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num,
            search_term,
            tag_filter,
            subscription_filter,
            sort_filter,
            bank_filter,
            stage_filter,
            agent_filter,
            salesperson_filter,
            crm_view_filter,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/company/v2/provider/groups/${queryUrl}`,
        };
      },
      providesTags: (result) => {
        const { company_groups = [] } = result || {};
        return result
          ? [
              ...company_groups.map(({ uuid }) => ({
                type: "CrmCompanyGroups" as "CrmCompanyGroups",
                id: uuid,
              })),
            ]
          : [{ type: "CrmCompanyGroups" as "CrmCompanyGroups", id: "GROUP" }];
      },
    }),

    updateCompanyStage: build.mutation<
      {
        group_id: string;
        stage: string;
      },
      {
        groupId: string;
        stage: string;
      }
    >({
      query: ({ groupId, stage }) => {
        return {
          url: `/api/inkle/company/v2/group/${groupId}/`,
          method: "put",
          body: {
            stage,
          },
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [{ type: "CrmCompanyGroups", id: result?.group_id }]
          : [{ type: "CrmCompanyGroups", id: "GROUP" }];
      },
    }),
    markCompanyAsVip: build.mutation<
      MarkAsVipCompanyGroupResponse,
      {
        groupId: string;
      }
    >({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/company/v2/group/${groupId}/mark_vip/`,
          method: "POST",
        };
      },
      invalidatesTags: ["SalesCrmCompanyGroups", "SliderCRM"],
    }),
    archiveGroup: build.mutation({
      query: ({ groupId, payload }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/archival/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "SalesCrmCompanyGroups", id: "LIST" }] : [],
    }),
    archiveUser: build.mutation({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/users/pregroup-profiles/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: "SalesCrmProfiles" as "SalesCrmProfiles",
                id: "LIST",
              },
            ]
          : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllGroupsQuery,
  useUpdateCompanyStageMutation,
  useLazyGetAllGroupsQuery,
  useMarkCompanyAsVipMutation,
  useArchiveGroupMutation,
  useArchiveUserMutation,
} = extendedApi;
