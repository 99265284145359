import TickWithCircle from "static/images/TickWithCircle.svg";
import OnboardingCompletedBanner from "static/images/OnboardingCompletedBanner.svg";
import { useHistory } from "react-router-dom";

export const OnboardingCompleteBanner = () => {
  const history = useHistory();

  return (
    <div className="t-flex t-flex-col t-items-center t-h-full t-justify-center">
      <div className="t-text-subtitle t-text-text-100">
        That's it! We will take it from here.
      </div>
      <div className="t-text-body-sm t-text-60 t-mt-1">
        It will take up to 1 business day to activate your account.
      </div>
      <img
        height="104px"
        src={TickWithCircle}
        alt="TickWithCircle"
        className="t-mt-6 t-mb-14"
      />
      <img
        onClick={() => history.push("/")}
        width="85%"
        src={OnboardingCompletedBanner}
        alt="OnboardingCompletedBanner"
        className="hover:t-shadow-dark-60 t-rounded-xl"
        role="button"
      />
    </div>
  );
};
