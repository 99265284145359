export const InkleIntragroupSymbolSmall = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0003 0.376953H8.45493V8.74904L3.5459 12.377H5.72769L12.0003 8.74904V0.376953Z"
      fill="url(#paint0_linear_5960_17638)"
    />
    <path
      d="M0.000637054 12.377L3.54605 12.377L3.54605 4.00486L8.45508 0.376955L6.27329 0.376955L0.000636339 4.00486L0.000637054 12.377Z"
      fill="url(#paint1_linear_5960_17638)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5960_17638"
        x1="3.5459"
        y1="6.37695"
        x2="12.0003"
        y2="6.37695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5960_17638"
        x1="8.45508"
        y1="6.37695"
        x2="0.000636542"
        y2="6.37695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
