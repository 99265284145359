import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { EmptyInvoiceList } from "components/Illustrations/EmptyInvoiceList";
import { InvoiceCustomerModal } from "components/InvoiceCustomerModal/InvoiceCustomerModal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import {
  CLICKED_ADD_CUSTOMER_IN_INVOICE,
  CLICKED_CUSTOMER_LINE_ITEM_IN_TABLE,
} from "constants/analyticsEvents";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { parse, stringify } from "qs";
import { useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  GroupInvoiceCustomer,
  useGetAllInvoiceCustomersListQuery,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import { SetupInoviceSetting } from "./SetupInoviceSetting";

export const InvoiceCustomers = () => {
  const group = useCurrentGroupContext();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [customerId, setCustomerId] = useState<string | null>(null);

  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const entityId = useCurrentEntityId();

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const {
    data: invoiceSettings,
    isSuccess: settingsLoaded,
    isLoading: isSettingsLoading,
  } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const query = useQuery();
  const { update } = useUpdateQuery();
  const page = query.get("page");
  const { trackEvent } = useAnalytics();

  const { goToFirstPage, goToPrevPage, goToLastPage, goToNextPage, pageNum } =
    usePagination({
      pageNumber: Number(page || 1),
      onPageNumChange: (page) => update({ query: "page", value: page }),
    });

  const searchValue = query.get("search");

  const {
    data: invoiceCustomers,
    isLoading: loadingCustomers,
    isSuccess: loadedCustomers,
  } = useGetAllInvoiceCustomersListQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      pageNum,
      searchTerm: searchValue,
    },
    { skip: !group?.uuid || !entityId }
  );

  const paginationData = {
    totalPage: invoiceCustomers?.total_pages!,
    currentPage: invoiceCustomers?.current_page!,
    itemsPerPage: invoiceCustomers?.per_page!,
    totalItemCount: invoiceCustomers?.total_count!,
  };

  const createColumn = createColumnHelper<GroupInvoiceCustomer>();

  const columns = [
    createColumn.accessor("email", {
      size: 25,
      header: "Name",
      cell: (info) => (
        <div>
          <p className="t-m-0">{info.row.original.name}</p>
          <p className="t-m-0">{info.getValue()}</p>
        </div>
      ),
    }),
    createColumn.accessor("latest_invoice_on", {
      size: 25,
      header: "LAST INVOICED ON",
      cell: (info) =>
        info.getValue() ? dayjs(info.getValue()).format(DD_MMM_YYYY) : "N/A",
    }),
    createColumn.accessor("total_amount", {
      id: "Total inovice",
      size: 22,
      header: () => (
        <p className="t-m-0 t-text-right"> TOTAL INVOICED AMOUNT</p>
      ),
      cell: (info) => (
        <div className="t-text-right">
          <AmountSuperScript amount={Number(info.getValue() || "0")} />
        </div>
      ),
    }),
    createColumn.accessor("paid_amount", {
      size: 13.5,
      id: "AMOUNT PAID",
      header: () => <p className="t-m-0 t-text-right">AMOUNT PAID</p>,
      cell: (info) => (
        <div className="t-text-right">
          <AmountSuperScript amount={Number(info.getValue() || "0")} />
        </div>
      ),
    }),
    createColumn.accessor("due_amount", {
      size: 14.5,
      header: () => <div className="t-text-right">AMOUNT DUE</div>,
      cell: (info) => (
        <div className="t-text-right">
          <AmountSuperScript amount={info.getValue()} />
        </div>
      ),
    }),
  ];

  const customers = useMemo(
    () => invoiceCustomers?.group_customers,
    [invoiceCustomers]
  );

  const table = useReactTable({
    data: customers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 10,
    },
  });

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "search", value: e.target.value });
  };

  const handleAddCustomer = () => {
    setCustomerId("NEW");
    trackEvent(CLICKED_ADD_CUSTOMER_IN_INVOICE);
  };

  const handleCustomerRowClick = (rowUuid: string) => {
    history.push(`${url}/${rowUuid}${search}`);
    trackEvent(CLICKED_CUSTOMER_LINE_ITEM_IN_TABLE);
  };

  if (settingsLoaded && !invoiceSettings) {
    return <SetupInoviceSetting />;
  }

  if (loadingCustomers || isSettingsLoading) {
    return (
      <div className="t-flex t-justify-center t-h-full t-w-full t-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="t-text-text-60 t-flex t-gap-3 t-flex-col t-relative">
        <div className="t-flex t-gap-5 t-flex-col">
          <div className="t-flex t-justify-between t-gap-5 t-mb-3">
            <div className="t-w-1/2">
              <Search
                block
                onChange={onSearch}
                value={searchValue || ""}
                placeholder="Search"
              />
            </div>

            <Button
              onClick={handleAddCustomer}
              size="small"
              customType="primary"
            >
              Add customer
            </Button>
          </div>

          <div className="t-flex t-justify-end">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </div>
        <TableUI table={table} size="small">
          {({ row }) => (
            <Table.Row
              key={row.id}
              onRowClick={() => handleCustomerRowClick(row.original.uuid)}
              className="t-cursor-pointer"
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}%` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          )}
        </TableUI>
        {customers?.length === 0 && !loadingCustomers && (
          <div className="t-flex t-flex-col t-items-center t-justify-center t-h-72 t-text-text-60 t-gap-3">
            <EmptyInvoiceList />
            {searchValue ? (
              <>
                <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                  <p className="t-m-0 t-text-subtitle-sm">
                    No results found for your search
                  </p>
                  <p className="t-m-0 t-text-body-sm">
                    Try clearing the search.
                  </p>
                </div>
                <Button
                  onClick={() => update({ query: "search", value: "" })}
                  size="small"
                  customType="primary"
                >
                  Clear search
                </Button>
              </>
            ) : (
              <>
                <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                  <p className="t-m-0 t-text-subtitle-sm">Add first customer</p>
                  <p className="t-m-0 t-text-body-sm">
                    Add & manage receivables for customers
                  </p>
                </div>
                <Button
                  onClick={() => setCustomerId("NEW")}
                  size="small"
                  customType="primary"
                >
                  Add customer
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      <InvoiceCustomerModal
        customerId={customerId}
        setCustomerId={setCustomerId}
      />
    </>
  );
};
