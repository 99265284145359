export const EmptyCart = () => (
  <svg
    width="150"
    height="117"
    viewBox="0 0 150 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.1557 50.8973C27.7892 48.7591 29.436 46.8059 31.6054 46.8059H116.184C118.333 46.8059 119.975 48.7257 119.641 50.8492L109.898 112.849C109.631 114.552 108.164 115.806 106.441 115.806H42.234C40.5292 115.806 39.0723 114.578 38.7843 112.897L28.1557 50.8973Z"
      fill="#F3F3F5"
      stroke="#CFCDD6"
    />
    <rect
      x="61.4262"
      y="7.68301"
      width="8"
      height="44.9241"
      rx="4"
      transform="rotate(30 61.4262 7.68301)"
      fill="#F3F3F5"
      stroke="#CFCDD6"
    />
    <rect
      x="109.535"
      y="42.835"
      width="8"
      height="44.9241"
      rx="4"
      transform="rotate(109.359 109.535 42.835)"
      fill="#F3F3F5"
      stroke="#CFCDD6"
    />
    <path
      d="M69.8613 85.3445C70.7278 83.8281 72.1283 83.1278 73.8613 83.3445C75.2585 83.5191 76.1627 84.1219 76.8613 85.3445"
      stroke="#CFCDD6"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <circle cx="61.8613" cy="79.3059" r="3" fill="#CFCDD6" />
    <circle cx="85.8613" cy="79.3059" r="3" fill="#CFCDD6" />
    <circle cx="106.861" cy="12.3059" r="4.5" stroke="#E7E6EB" />
    <circle cx="9.86133" cy="39.3059" r="2.5" stroke="#E7E6EB" />
    <circle cx="133.861" cy="99.3059" r="2.5" stroke="#E7E6EB" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.85947 89.1387C4.45844 89.6354 6.50327 91.6802 7 94.2792C7.49674 91.6802 9.54156 89.6354 12.1405 89.1387C9.54156 88.6419 7.49674 86.5971 7 83.9981C6.50327 86.5971 4.45844 88.6419 1.85947 89.1387ZM0.861328 89.0272C4.14008 88.9095 6.77085 86.2788 6.88853 83H7.11147C7.22915 86.2788 9.85992 88.9095 13.1387 89.0272V89.2501C9.85992 89.3678 7.22915 91.9986 7.11147 95.2773H6.88853C6.77085 91.9986 4.14008 89.3678 0.861328 89.2501V89.0272Z"
      fill="#CFCDD6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.859 70.1387C140.458 70.6354 142.503 72.6802 143 75.2792C143.497 72.6802 145.542 70.6354 148.141 70.1387C145.542 69.6419 143.497 67.5971 143 64.9981C142.503 67.5971 140.458 69.6419 137.859 70.1387ZM136.861 70.0272C140.14 69.9095 142.771 67.2788 142.889 64H143.111C143.229 67.2788 145.86 69.9095 149.139 70.0272V70.2501C145.86 70.3678 143.229 72.9986 143.111 76.2773H142.889C142.771 72.9986 140.14 70.3678 136.861 70.2501V70.0272Z"
      fill="#CFCDD6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.8369 6C35.3772 6.48551 37.3758 8.48415 37.8613 11.0244C38.3468 8.48415 40.3455 6.48551 42.8857 6C40.3455 5.51449 38.3468 3.51585 37.8613 0.975595C37.3758 3.51585 35.3772 5.51449 32.8369 6ZM31.8613 5.89105C35.066 5.77603 37.6374 3.20469 37.7524 0H37.9703C38.0853 3.20469 40.6566 5.77603 43.8613 5.89105V6.10895C40.6566 6.22397 38.0853 8.79532 37.9703 12H37.7524C37.6374 8.79532 35.066 6.22397 31.8613 6.10895V5.89105Z"
      fill="#CFCDD6"
    />
  </svg>
);
