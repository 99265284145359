import { Filter } from "components/DesignSystem/Filter/Filter";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useFilters } from "hooks/useFilter";
import { parse } from "qs";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getFilterStatus } from "store/selector/transactionFilter";
import { setTxnFilters } from "store/slices/transactionFilter";
import {
  Amount,
  CapsuleFilters,
  CategoryFilter,
  DateRangeFilter,
  OtherFilters,
  VendorsFilter,
} from "./Filter";

export const StripeTxnFilter = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });
  const { appliedFilterCount } = useAppSelector(getFilterStatus);

  useEffect(() => {
    delete query.company;
    delete query.entity;
    delete query.page;
    delete query.selected_transaction_id;
    delete query.reset_filter;
    delete query.page;
    dispatch(setTxnFilters(query));
  }, [search]);

  const { values: dates, updateFilter: updateDateFilter } = useFilters({
    initialValue: {
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "" as string,
    },
  });

  return (
    <Filter.Root
      defaultValue="category"
      title={
        <span className="t-text-body t-font-medium t-leading-none">
          Filters {appliedFilterCount ? <>({appliedFilterCount})</> : ""}
        </span>
      }
      capsule={<CapsuleFilters />}
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="transactionDate">Date</Filter.ListItem>
          <Filter.ListItem value="vendors">Vendors</Filter.ListItem>
          <Filter.ListItem value="category">Category</Filter.ListItem>
          <Filter.ListItem value="amount">Amount</Filter.ListItem>
          <Filter.ListItem value="others">Others</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="transactionDate" block>
          <DateRangeFilter values={dates} updateFilter={updateDateFilter} />
        </Filter.Body>

        <Filter.Body value="vendors" block>
          <VendorsFilter />
        </Filter.Body>
        <Filter.Body value="category" block>
          <CategoryFilter />
        </Filter.Body>
        <Filter.Body value="amount" block>
          <Amount />
        </Filter.Body>
        <Filter.Body value="others">
          <OtherFilters />
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
