import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Toast from "components/DesignSystem/Toast/Toast";
import { StartBookkeepingDateEmptyScreen } from "components/GeneralLedger/GeneralLedgerEmptyScreen";
import { ConditionalLink } from "components/conditionalLink";
import { CaretDown } from "components/icons/CaretDown";
import { Redirect } from "components/icons/Redirect";
import {
  BALANCE_SHEET,
  DOWNLOAD_EXCEL,
  DOWNLOAD_PDF,
  SAVED_REPORTS,
} from "constants/bookkeeping";
import { REPORT_TYPE } from "dictionaries";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ReportsType } from "pages/Books/Reports/Reports";
import { parse } from "qs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  useLazyNewInteractiveReportQuery,
  useNewInteractiveReportQuery,
  useSaveInteractiveReportMutation,
} from "store/apis/reports";
import { getFilterStatus } from "store/selector/reportFilter";
import { Account } from "types/Models/report";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import { AddReportToClosingModal } from "./AddReportToClosingModal";
import { ReportFilter } from "./ReportFilter";
import ReportsAccordion from "./ReportsAccordion";
import { useGetMoneyInTransitQuery } from "store/apis/generalLedger";
import { MoneyInTransitBanner } from "components/MoneyTransitBanner/MoneyInTransitBanner";
import { FilterValue } from "react-table";
import { FiltersValues } from "store/slices/transactionFilter";
import { useQuery } from "hooks/useQuery";
import { redirectToCategories } from "utils/redirectToCategories";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";

export const ReportDownload = ({ reportType }: { reportType: ReportsType }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const { filters } = useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;

  const [
    downloadReport,
    {
      data: interactiveReport,
      isLoading: isDownloading,
      isSuccess: isDownloadSuccess,
      isFetching,
    },
  ] = useLazyNewInteractiveReportQuery();

  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const onDownload = async (
    downloadType: "DOWNLOAD_EXCEL" | "DOWNLOAD_PDF"
  ) => {
    try {
      const { document_id, excel_document_id } = await downloadReport({
        groupId,
        entityId,
        reportType: reportType,
        action: downloadType,
        accountingMethod: filters.accountingMethod?.[0].value as string,
        startDate: startDate.value ? (startDate?.value as string) : null,
        endDate: endDate.value ? (endDate?.value as string) : null,
        showNonZeroAccounts: filters?.others?.[0]?.value as string,
      }).unwrap();

      const { download_url } = await getPreviewUrl({
        groupId: groupId,
        fileId:
          downloadType === DOWNLOAD_EXCEL ? excel_document_id : document_id,
      }).unwrap();

      openLink(download_url);

      successToast({ message: "Report downloaded" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };
  return (
    <Dropdown.Root>
      <Dropdown.Trigger
        asChild
        className="t-group"
        disabled={isDownloading || isFetching}
      >
        <div>
          <Button
            customType="primary"
            size="small"
            onClick={(e) => e.stopPropagation()}
            isLoading={isDownloading || isFetching}
            disabled={isDownloading || isFetching}
          >
            <div className="t-flex t-items-center t-gap-1.5">
              Export
              <div className="group-data-state-open:t-rotate-180">
                <CaretDown />
              </div>
            </div>
          </Button>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className="t-w-24"
          sideOffset={4}
          align="end"
          side="bottom"
        >
          <Dropdown.Item
            onSelect={() => {
              onDownload(DOWNLOAD_PDF);
            }}
          >
            PDF
          </Dropdown.Item>
          <Dropdown.Item onSelect={() => onDownload(DOWNLOAD_EXCEL)}>
            Excel
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const ReportTopBar = ({ reportType }: { reportType: ReportsType }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const { isCpa, isForeignCA, isCustomer } = useRoleBasedView();
  const [showToast, setShowToast] = useState(false);
  const { filters } = useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;

  const { data: moneyInTransit = [] } = useGetMoneyInTransitQuery(
    {
      groupId,
      entityId,
      reportType: reportType,
      startDate: startDate.value as string,
      endDate: endDate.value as string,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    isOpen: isAddToClosingModalOpen,
    close: closeAddToClosingModal,
    open: openAddToClosing,
  } = useModal();

  const [
    saveInteractiveReport,
    { data: savedInteractiveReport, isLoading, isSuccess },
  ] = useSaveInteractiveReportMutation();

  const [
    downloadReport,
    {
      data: interactiveReport,
      isLoading: isDownloading,
      isSuccess: isDownloadSuccess,
    },
  ] = useLazyNewInteractiveReportQuery();

  const viewLink = isCustomer
    ? `/books/live-financial/${savedInteractiveReport?.uuid}/?entity=${entityId}`
    : `/books/live-financial/${savedInteractiveReport?.uuid}/?company=${groupId}&entity=${entityId}`;

  const onSave = async () => {
    try {
      await saveInteractiveReport({
        groupId,
        entityId,
        reportType,
        accountingMethod: filters.accountingMethod?.[0].value as string,
        startDate: startDate.value ? (startDate?.value as string) : null,
        endDate: endDate.value ? (endDate?.value as string) : null,
        showNonZeroAccounts: filters?.others?.[0]?.value as string,
      }).unwrap();
      setShowToast(true);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onAddToMonthlyClosing = async () => {
    openAddToClosing();
    await downloadReport({
      groupId,
      entityId,
      reportType: reportType,
      action: "DOWNLOAD_PDF",
      accountingMethod: filters.accountingMethod?.[0].value as string,
      startDate: startDate.value ? (startDate?.value as string) : null,
      endDate: endDate.value ? (endDate?.value as string) : null,
      showNonZeroAccounts: filters?.others?.[0]?.value as string,
    }).unwrap();
  };

  return (
    <div className="t-flex t-flex-col t-gap-4 t-sticky t-top-0 t-bg-white t-pb-6 t-z-filter-head">
      {moneyInTransit.length > 0 && (
        <MoneyInTransitBanner transactions={moneyInTransit} />
      )}
      <div className="t-flex t-justify-between t-w-full">
        <div className="t-flex t-gap-2">
          <ReportFilter reportType={reportType} />
        </div>
        <div className="t-flex t-gap-3 t-items-start">
          {(isCpa || isForeignCA) && (
            <Button
              size="small"
              onClick={onAddToMonthlyClosing}
              isLoading={isDownloading}
              disabled={isDownloading}
            >
              Add to closing
            </Button>
          )}
          <Button
            size="small"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onSave}
          >
            {isLoading ? "Saving" : " Save"}
          </Button>

          <ReportDownload reportType={reportType} />
        </div>
        {showToast && (
          <div className="t-fixed t-bottom-20 t-right-0">
            <Toast.Provider>
              <Toast.Root
                open={showToast}
                customType="success"
                size="regular"
                onOpenChange={() => setShowToast(false)}
              >
                <Toast.Title>Report saved</Toast.Title>
                <Toast.Description></Toast.Description>
                <Toast.Close onClose={() => setShowToast(false)} />
                <Toast.Action
                  onAction={() => openLink(viewLink)}
                  altText="View"
                >
                  View
                </Toast.Action>
              </Toast.Root>
            </Toast.Provider>
          </div>
        )}
        {interactiveReport?.document_id && interactiveReport?.type && (
          <AddReportToClosingModal
            open={isAddToClosingModalOpen}
            onClose={closeAddToClosingModal}
            reportType={interactiveReport?.type}
            documentId={interactiveReport?.document_id!}
          />
        )}
      </div>
    </div>
  );
};

const ReportView = ({ reportType }: { reportType: ReportsType }) => {
  const { isAdmin } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { filters, getFilterName, capsuleFilters } =
    useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;

  const { data, isLoading, isSuccess, isFetching } =
    useNewInteractiveReportQuery(
      {
        groupId,
        entityId,
        reportType: reportType,
        accountingMethod: filters.accountingMethod?.[0].value as string,
        startDate: startDate.value ? (startDate.value as string) : null,
        endDate: endDate.value ? (endDate.value as string) : null,
        showNonZeroAccounts: filters.others?.[0].value as string,
      },
      {
        refetchOnMountOrArgChange: true,
        skip:
          !groupId || !entityId || !reportType || reportType === SAVED_REPORTS,
      }
    );

  const { report_data, type } = data || {};
  const { accounts = [] } = report_data || {};

  const isEmpty = !report_data || accounts?.length === 0;

  return (
    <Async.Root {...{ isEmpty, isLoading, isSuccess }}>
      <Async.Empty>
        <StartBookkeepingDateEmptyScreen />
      </Async.Empty>
      <Async.Success>
        <ReportsAccordion.Root
          className="t-pb-20 t-relative"
          reportType={type!}
          accountingMethod={filters.accountingMethod?.[0].value as string}
          startDate={startDate.value ? (startDate.value as string) : undefined}
          endDate={endDate.value ? (endDate.value as string) : undefined}
          showNonZeroAccounts={filters.others?.[0].value as boolean}
          isLoading={isFetching}
        >
          {report_data && (
            <ReportsAccordion.Trigger
              data={accounts}
              action={({ uuid }) => (
                <ConditionalLink
                  to={redirectToCategories({
                    uuid,
                    groupId,
                    entityId,
                    capsuleFilters,
                    getFilterName,
                    isAdmin,
                  })}
                >
                  <Redirect size={16} />
                </ConditionalLink>
              )}
            />
          )}
        </ReportsAccordion.Root>
        <TransactionSlider />
      </Async.Success>
    </Async.Root>
  );
};

export const InteractiveReport = ({
  reportType,
}: {
  reportType: ReportsType;
}) => {
  usePageTitle(REPORT_TYPE[reportType!] || "Report");

  return (
    <div className="t-flex t-flex-col t-h-full t-w-full">
      <ReportTopBar reportType={reportType} />
      <ReportView reportType={reportType} />
    </div>
  );
};
