import { ChannelForwardChannelSelector } from "components/ChannelForwardChannelSelector/ChannelForwardChannelSelector";
import { ChannelHeader } from "components/ChannelHeader/ChannelHeader";
import { ChannelMediaSlider } from "components/ChannelMediaSlider/ChannelMediaSlider";
import { ChannelMessageSearch } from "components/ChannelMessageSearch/ChannelMessageSearch";
import { ChannelPendingActions } from "components/ChannelinfoSlider/ChannelPendingActions";
import { ChannelPinnedItem } from "components/ChannelinfoSlider/ChannelPinnedItem";
import { ChannelInfoSlider } from "components/ChannelinfoSlider/ChannelinfoSlider";
import { Button } from "components/DesignSystem/Button/Button";
import { MessageInfoSlider } from "components/WhatsappSharedSlider/MessageInfoSlider";
import { WhatsappForwardedSlider } from "components/WhatsappSharedSlider/WhatsappForwardedSlider";
import { Channel } from "components/chat/Channel";
import { ChannelList } from "components/chat/ChannelList";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { ArrowLeft } from "components/icons/ArrowLeft";

export const ChatPane = ({
  channelId,
  setChannelId,
  messageId,
  header,
  hideHeader,
}: {
  channelId?: string | null;
  setChannelId(value: string | null): void;
  messageId?: string;
  header?: React.ReactNode;
  hideHeader?: boolean;
}) => {
  return (
    <DashboardContainer className="t-border-0 t-border-l t-border-solid t-border-neutral-0 t-w-full t-h-full t-overflow-hidden">
      {header && (
        <DashboardContainer.Header>{header}</DashboardContainer.Header>
      )}
      <DashboardContainer.Content className="t-w-full t-overflow-auto t-bg-white t-h-full">
        {channelId ? (
          <div className="t-w-full t-h-full">
            <Channel
              Header={(props) => (
                <ChannelHeader
                  {...props}
                  hideHeader={hideHeader}
                  actionsAvailable={false}
                  backButton={
                    <Button
                      size="small"
                      customType="ghost_icon"
                      onClick={() => setChannelId(null)}
                    >
                      <ArrowLeft size="16px" />
                    </Button>
                  }
                />
              )}
              channelId={channelId}
              messageId={messageId}
              messageMaxWidth="360px"
            >
              <ChannelMessageSearch
                onSelect={(channelId) => setChannelId(channelId)}
              />
              <ChannelInfoSlider />
              <ChannelPendingActions />
              <ChannelPinnedItem />
              <ChannelMediaSlider />
              <WhatsappForwardedSlider />
              <MessageInfoSlider />
              <ChannelForwardChannelSelector />
            </Channel>
          </div>
        ) : (
          <ChannelList onSelect={(channelId) => setChannelId(channelId)} />
        )}
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
