import classNames from "classnames";
import React, { ReactNode } from "react";
import { useDrag } from "react-dnd";

export const SuggestedDocumentDND = ({
  children,
  data,
  isUploaded,
}: {
  children: ReactNode;
  data: string;
  isUploaded: boolean;
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "doc",
      item: { data: data },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data]
  );

  const ref = isUploaded ? null : drag;

  return (
    <div
      ref={ref}
      className={classNames({
        "t-opacity-70": isUploaded,
        "t-cursor-grab": !isUploaded,
      })}
    >
      {children}
    </div>
  );
};
