import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PracticeBillingType } from "types/Models/billing";

export type BillingDetails = {
  invoice_emails: string[];
  street_address: string;
  country: string;
  state: string;
  city: string;
  zipcode: string;
};

type InitialStateType = {
  billingMethod: {
    method: PracticeBillingType;
    addressAndContact: BillingDetails;
  };
};

const initialState: InitialStateType = {
  billingMethod: {
    method: "NOT_SELECTED",
    addressAndContact: {
      invoice_emails: [],
      street_address: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
    },
  },
};

const practiceBilling = createSlice({
  name: "practiceBilling",
  initialState,
  reducers: {
    setBillingMethod: (
      state,
      action: PayloadAction<InitialStateType["billingMethod"]["method"]>
    ) => {
      state.billingMethod.method = action.payload;
    },

    setBillingDetails: (
      state,
      action: PayloadAction<
        InitialStateType["billingMethod"]["addressAndContact"]
      >
    ) => {
      state.billingMethod.addressAndContact = action.payload;
    },

    resetBillingMethod: (state) => {
      state.billingMethod = initialState.billingMethod;
    },
  },
});

export const { setBillingDetails, setBillingMethod, resetBillingMethod } =
  practiceBilling.actions;

export default practiceBilling.reducer;
