import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import { CREDIT_CARD_DELETED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useDeleteCardMutation } from "store/apis/billing";

const DeleteCardModal = ({
  show,
  closeModal,
  paymentMethodId,
  entityId,
}: {
  show: boolean;
  closeModal: () => void;
  paymentMethodId: string;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const {
    authtoken: { email },
  } = useContext(authContext);
  const [deleteCard, { isLoading }] = useDeleteCardMutation();
  const { trackEvent } = useAnalytics();

  const deleteSavedCard = async () => {
    try {
      await deleteCard({ groupId, paymentMethodId, entityId }).unwrap();
      trackEvent(CREDIT_CARD_DELETED, {
        group_id: groupId,
        email: email,
      });
      successToast({ message: "Card deleted successfully" });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-p-0 md:!t-w-[440px]">
          <Modal.Close />
          <Modal.Title className="!t-p-6">Delete this card?</Modal.Title>
          <div className="t-border t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-p-6">
            <div className="t-text-body-lg t-text-text-black">
              Are you sure you want to delete this card?
            </div>
            <div className="t-mt-8 t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={isLoading}
                customType="danger"
                isLoading={isLoading}
                onClick={deleteSavedCard}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default DeleteCardModal;
