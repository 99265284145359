import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TaskReviewProps {
  showTaskReview: boolean;
  showTaskReviewStep: boolean;
  taskUpdatedSection: any;
}

const initialState: TaskReviewProps = {
  showTaskReview: false,
  showTaskReviewStep: false,
  taskUpdatedSection: undefined,
};

export const TaskReview = createSlice({
  name: "taskReview",
  initialState,
  reducers: {
    openTaskReview: (state) => {
      state.showTaskReview = true;
    },
    closeTaskReview: (state) => {
      state.showTaskReview = false;
    },
    setShowTaskReviewStep: (state, action: PayloadAction<boolean>) => {
      state.showTaskReviewStep = action.payload;
    },
    setTaskUpdatedSection: (state, action: PayloadAction<any>) => {
      state.taskUpdatedSection = action.payload;
    },
    resetTaskReview: (state) => {
      return { ...initialState, showTaskReviewStep: state.showTaskReviewStep };
    },
  },
});

export const {
  openTaskReview,
  closeTaskReview,
  setShowTaskReviewStep,
  setTaskUpdatedSection,
  resetTaskReview,
} = TaskReview.actions;

export default TaskReview.reducer;
