import React, { useMemo } from "react";

// components
import CommonModal from "../design/modal";
import CommonTable from "../design/commonTable";

// images
import AcceptedChecks from "../icons/AcceptedChecks";

// utils
import { getDate, getTime } from "../../utils/formatDayJs";

const TaskLogModal = ({ closeModal, taskLogs }) => {
  const columns = useMemo(
    () => [
      {
        Header: "SCOPE OF WORK",
        accessor: "scopeOfWork",
        width: "50%",
        Cell: ({
          row: {
            original: { scope_of_work, created_at },
          },
        }) => (
          <>
            <span className="logScopeOfWork">{scope_of_work}</span>
            <br />
            <span className="logCreatedAt">
              {getDate(created_at)} | {getTime(created_at)}
            </span>
          </>
        ),
      },
      {
        Header: "PRICE",
        accessor: "price",
        width: "20%",
        textAlign: "right",
        Cell: ({
          row: {
            original: { price },
          },
        }) => <span className="logScopeOfWork">${price}</span>,
      },
      {
        Header: "Acceptance",
        accessor: "accepted",
        width: "30%",
        textAlign: "center",
        Cell: ({
          row: {
            original: { has_customer_approved },
          },
        }) => (
          <span className="logScopeOfWork">
            {has_customer_approved ? (
              <>
                <AcceptedChecks />
                &nbsp; Accepted
              </>
            ) : (
              "- -"
            )}
          </span>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => taskLogs, [taskLogs]);

  return (
    <CommonModal
      className="adhocTaskLogModal"
      show
      closeModal={closeModal}
      modalTitle="Log"
      body={
        <CommonTable
          tableData={data}
          tableDataDependencies={[data]}
          tableColumns={columns}
          tableColumnsDependencies={[columns]}
          headerBg="transparent"
        />
      }
    />
  );
};

export default TaskLogModal;
