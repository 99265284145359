import React, { ReactNode, useState } from "react";
import { InputProps } from "../TextInput/TextInput";
import classNames from "classnames";
import ReactCountryFlag from "react-country-flag";
import DropDownArrow from "static/images/DropdownArrow.svg";
import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
} from "libphonenumber-js/min";
import Dropdown from "../Dropdown/Dropdown";
import rawCountries from "./rawCountries";
import { Search } from "../Search/Search";
import { debounce } from "utils/debouncing";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Field, FieldProps } from "formik";

type MobileInputProps = InputProps & {
  label?: ReactNode;
  customSize?: "small" | "regular" | "large";
  updateIsValidPhoneNumber?: (valid: boolean) => void;
};

const INPUT_SIZES = {
  small: "t-h-8",
  regular: "t-h-10",
  large: "t-h-12",
};

export const TextInputWithDropDown = ({
  DropDownComponent,
  customSize = "regular",
  valid,
  touched = false,
  ...props
}: {
  DropDownComponent?: JSX.Element;
  valid: boolean;
  touched?: boolean;
} & InputProps) => {
  let inputProps = { ...props };
  delete inputProps.className;
  const isInvalid = touched && !valid && !props?.disabled;

  return (
    <>
      {props.label && (
        <label
          className="t-text-body-sm t-text-text-30 t-mb-1.5"
          htmlFor={props.name}
        >
          {props?.label}
        </label>
      )}
      <div
        className={classNames(
          `t-flex t-w-full t-gap-4 t-h-full t-rounded t-bg-surface-lighter-grey t-border t-border-neutral-10 t-border-solid`,
          props.className || "",
          {
            "t-border-red": isInvalid,
            "t-cursor-no-drop t-text-neutral-30 t-opacity-50": props.disabled,
            "hover:t-border-purple-50 focus-within:t-border-purple-50":
              !props.disabled,
          }
        )}
      >
        <span>{DropDownComponent}</span>
        <input
          className={classNames(
            "!t-border-0 t-bg-surface-lighter-grey t-text-body !t-p-0 !t-py-2 t-outline-none",
            INPUT_SIZES[customSize]
          )}
          type="tel"
          id={props.name}
          {...inputProps}
        />
      </div>
      {isInvalid && (
        <span className="t-text-body-sm t-text-red t-mt-[6px]">
          Please enter a valid number
        </span>
      )}
    </>
  );
};
export const CountryDropDown = ({
  selectedCountry,
  setSelectedCountry,
  disabled,
}: {
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
  disabled: boolean | undefined;
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const countrySelectedHandler = (countryName: string) => {
    setSelectedCountry(countryName.toUpperCase());
    setSearchTerm("");
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild disabled={disabled}>
        <span className="t-flex t-h-full t-items-center t-gap-1.5 t-border-0 t-border-r t-border-solid t-border-r-neutral-0 t-px-2">
          <ReactCountryFlag countryCode={selectedCountry} svg />
          <img src={DropDownArrow} alt="dropdown" />
        </span>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          align="start"
          sideOffset={2}
          className="t-h-[300px] t-w-[284px] t-p-2 t-overflow-scroll"
        >
          <DashboardContainer className="t-h-full">
            <DashboardContainer.Header>
              <Search
                onChange={(e) => setSearchTerm(e.target.value || "")}
                value={searchTerm}
                autoFocus
                block
                className="t-w-full"
              />
            </DashboardContainer.Header>
            <DashboardContainer.Content>
              {rawCountries
                .filter((country) => {
                  let countryName = country[0] as string;

                  return countryName.toLowerCase().startsWith(searchTerm);
                })
                .map((country) => (
                  <Dropdown.Item
                    key={country[2] as string}
                    onClick={() => countrySelectedHandler(country[2] as string)}
                    className="t-flex t-items-center t-gap-2 !t-p-3"
                    textValue=""
                  >
                    <ReactCountryFlag
                      style={{
                        width: "20px",
                        height: "12px",
                      }}
                      countryCode={country[2] as string}
                      svg
                    />
                    <span className="t-text-body t-text-neutral-80">
                      {country[0] as string} (+{country[3]})
                    </span>
                  </Dropdown.Item>
                ))}
            </DashboardContainer.Content>
          </DashboardContainer>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

export const MobileInput = (props: MobileInputProps) => {
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  return (
    <Field name={props?.name} {...props}>
      {({ field, form: { touched, setFieldValue } }: FieldProps) => {
        const defaultCountry = "US";
        let numberFormatter = new AsYouType(defaultCountry);

        const displayValue = numberFormatter.input(
          field?.value || `+${getCountryCallingCode(defaultCountry)}`
        );

        const country =
          (numberFormatter.getCountry() as CountryCode) || defaultCountry;

        numberFormatter.reset();

        const resetInput = (country: string) => {
          const countryCode = `+${getCountryCallingCode(
            country as CountryCode
          )}`;

          setFieldValue(field?.name, countryCode);
        };

        const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(field?.name, numberFormatter.input(e.target.value));
          props.updateIsValidPhoneNumber?.(numberFormatter.isValid());
          numberFormatter.reset();
        };

        const validateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
          field.onBlur(e);
          numberFormatter.input(field?.value);
          setIsValidPhoneNumber(numberFormatter.isValid());
        };

        return (
          <TextInputWithDropDown
            {...{ ...field, ...props }}
            value={displayValue}
            DropDownComponent={
              <CountryDropDown
                selectedCountry={country}
                setSelectedCountry={resetInput}
                disabled={props.disabled}
              />
            }
            valid={isValidPhoneNumber}
            touched={touched[field?.name] as boolean}
            onChange={inputChangeHandler}
            onBlur={validateNumber}
          />
        );
      }}
    </Field>
  );
};
