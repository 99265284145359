import * as Accordion from "@radix-ui/react-accordion";
import cx from "classnames";
import * as BOOKKEEPIN_SUMMAY_STATUSES from "constants/bookkeepingMonthlySummaryStatus";
import React, { MouseEvent, ReactNode } from "react";
import ArrowDownLight from "static/images/ArrowDownLight.svg";
import CompletedSummary from "static/images/CompletedSummary.svg";
import InProgressSummary from "static/images/InProgressSummary.svg";
import UpcomingSummary from "static/images/UpcomingSummary.svg";
import UserSwitch from "static/images/UserSwitch.svg";
import BankAccounts from "static/images/BankAccounts.svg";
import Platform from "static/images/Platform.svg";
import Season from "static/images/Season.svg";
import NotApplicable from "static/images/NotApplicable.svg";
import { BOOKKEEPING_MONTHLY_SUMMARY_STATUS } from "types/contants/bookkeepingMonthlySummaryStatus";
import { Button } from "components/DesignSystem/Button/Button";
import { DeleteIcon } from "components/icons/delete";

const statusIconMap = {
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_STARTED]: UpcomingSummary,
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_PROGRESS]: InProgressSummary,
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CLIENT_REVIEW]: UserSwitch,
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CPA_REVIEW]: UserSwitch,
  [BOOKKEEPIN_SUMMAY_STATUSES.COMPLETED]: CompletedSummary,
  [BOOKKEEPIN_SUMMAY_STATUSES.BANK_CONNECT]: BankAccounts,
  [BOOKKEEPIN_SUMMAY_STATUSES.SEASON]: Season,
  [BOOKKEEPIN_SUMMAY_STATUSES.PLATFORM]: Platform,
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_APPLICABLE]: NotApplicable,
};

const statusColorMap = {
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_STARTED]: "t-bg-purple-20",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_PROGRESS]: "t-bg-orange-20",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CLIENT_REVIEW]: "t-bg-red-20",
  [BOOKKEEPIN_SUMMAY_STATUSES.IN_CPA_REVIEW]: "t-bg-red-20",
  [BOOKKEEPIN_SUMMAY_STATUSES.COMPLETED]: "t-bg-green-20",
  [BOOKKEEPIN_SUMMAY_STATUSES.BANK_CONNECT]: "t-bg-purple-10",
  [BOOKKEEPIN_SUMMAY_STATUSES.SEASON]: "t-bg-purple-10",
  [BOOKKEEPIN_SUMMAY_STATUSES.PLATFORM]: "t-bg-purple-10",
  [BOOKKEEPIN_SUMMAY_STATUSES.NOT_APPLICABLE]: "t-bg-surface-grey",
};

export const BookkeepingMonthlySummary = () => <div></div>;

const BookkeepingStatus = ({
  status,
}: {
  status: BOOKKEEPING_MONTHLY_SUMMARY_STATUS;
}) => (
  <div
    className={cx(
      "t-flex t-h-12 t-w-12 t-items-center t-justify-center t-rounded-md",
      statusColorMap[status]
    )}
  >
    <img src={statusIconMap[status]} alt={status} />
  </div>
);

export const BookkeepingAccordionTrigger = React.forwardRef(
  (
    {
      name,
      description,
      status,
      statusBadge,
      isDeleteAllowed,
      onDelete,
    }: {
      name: string | ReactNode;
      description: string | ReactNode;
      status: BOOKKEEPING_MONTHLY_SUMMARY_STATUS;
      statusBadge?: ReactNode;
      isDeleteAllowed?: boolean;
      onDelete?: (e: MouseEvent<HTMLButtonElement>) => void;
    },
    forwardedRef: React.Ref<HTMLButtonElement>
  ) => (
    <Accordion.Header className="t-m-0">
      <Accordion.Trigger ref={forwardedRef} asChild>
        <button className="all:unset t-group t-flex t-w-full t-cursor-pointer t-items-center t-gap-3 t-text-body">
          <BookkeepingStatus status={status} />
          <div>
            <p className="t-m-0 t-mb-0.5 t-text-subtitle-sm">{name}</p>
            <p className="t-m-0 t-text-neutral">{description}</p>
          </div>
          <div className="t-flex t-gap-3 t-items-center t-ml-auto">
            {statusBadge}
            <span className="t-flex t-gap-2 t-items-center">
              {isDeleteAllowed && (
                <Button customType="ghost_icon" size="small" onClick={onDelete}>
                  <DeleteIcon />
                </Button>
              )}
              <img
                src={ArrowDownLight}
                alt="Arrow down"
                className="t-ml-auto t-transform t-transition-transform group-data-state-open:-t-rotate-180 group-data-state-closed:-t-rotate-90"
              />
            </span>
          </div>
        </button>
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

export const BookkeepingAccordionItem = React.forwardRef(
  (
    { children, ...props }: Accordion.AccordionItemProps,
    forwardedRef: React.Ref<HTMLDivElement>
  ) => (
    <Accordion.Item
      ref={forwardedRef}
      {...props}
      className="t-rounded-md t-border t-border-solid t-border-neutral-10 t-p-3 data-[state=open]:t-shadow-[0px_3.35664px_10.9091px_rgba(0,0,0,0.1)] data-state-open:t-border-purple-30"
    >
      {children}
    </Accordion.Item>
  )
);
