import { send1099File } from "apis/merchants";
import { ClockWiseWait } from "components/icons/ClockWiseWait";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import Check from "static/images/Checks.svg";
import "static/styles/components/wSeriesFormModal.css";
import { Merchant } from "types/Models/merchant";
import { Button } from "./design/button";
import CommonModal from "./design/modal";
import { DocumentPreviewModal } from "./PreviewModal";
import { WSeriesEmailForm } from "./WSeriesEmailForm";

type FileUpload1099Props = {
  taskId: string;
  groupId: string;
  merchant: Merchant;
  updateMerchant: (merchant: Merchant) => void;
  editable?: boolean;
};

export const FileSendUpload1099 = ({
  merchant,
  groupId,
  taskId,
  updateMerchant,
  editable = true,
}: FileUpload1099Props): JSX.Element => {
  const { alertToast, successToast } = useToast();
  const { isCustomer } = useRoleBasedView();
  const { form_1099_document, form_status, email } = merchant;
  const [showModal, setModal] = useState(false);

  let icon = null;
  const hasSentWSeriesForm = form_status === "SENT";

  if (hasSentWSeriesForm) {
    icon = <ClockWiseWait />;
  }

  if (form_1099_document) {
    icon = <img src={Check} alt="check" />;
  }

  const onEmailSubmit = async ({ email }: { email: string }) => {
    try {
      const { data } = await send1099File({
        merchantDocMapId:
          merchant.form_1099_document?.recipients_document?.uuid!,
        groupId,
        email,
      });
      updateMerchant(data);
      setModal(false);
      successToast({ message: "Email has been sent" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const buttonText = form_1099_document ? "1099" : "Send copy";

  return (
    <>
      <Button
        size="medium"
        color="bordered"
        onClick={() => setModal(true)}
        addClassName="align-center"
        disabled={Boolean(!form_1099_document) && isCustomer}
      >
        <span className="d-inline-block me-1">{icon}</span>
        {buttonText}
      </Button>
      {/* @ts-ignore */}
      <CommonModal
        className="w-series-form-modal"
        show={showModal}
        modalTitle={
          <span className="w-series-form-modal-title">1099 form</span>
        }
        closeModal={() => setModal(false)}
        body={
          <div className="w-series-form-modal-body d-flex flex-column gap-4">
            <WSeriesEmailForm
              formStatus={form_status}
              onSubmit={onEmailSubmit}
              email={email}
              disabled={!Boolean(form_1099_document)}
              label="Send a copy of 1099 form"
            />
          </div>
        }
      />

      <DocumentPreviewModal />
    </>
  );
};
