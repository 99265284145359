import { BorderedStar } from "components/icons/BorderedStar";
import { GoldenStar } from "components/icons/GoldenStar";
import React from "react";

export const DynamicStar = ({ isActive }: { isActive: boolean }) => {
  if (isActive) {
    return <GoldenStar />;
  }
  return <BorderedStar />;
};
