import { AddonsModal } from "components/Addons/AddonsModal";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Button } from "components/DesignSystem/Button/Button";
import { LargeCard } from "components/home/Home";
import { MAILROOM } from "constants/addons";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import {
  setAddonData,
  setAddonPayload,
  setAddonType,
  setCloseCheckoutModal,
} from "store/slices/addons";
import { RootState } from "store/store";
import { Subscription } from "types/Models/subscription";

export const MailRoomHomeCard = ({
  isMailroomSubscribed,
}: {
  isMailroomSubscribed: boolean;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !entityId || !groupId }
  );

  const { showCheckoutModal, addonPayload, addonType } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const history = useHistory();
  const { url } = useRouteMatch();

  const dispatch = useDispatch();

  const mailroomSubscriptionData = allSubscriptions?.find(
    (subscription) => subscription.subscription_type === "MAILROOM"
  );

  const { amount, stripe_coupon } = mailroomSubscriptionData || {};
  const updatedAmount = stripe_coupon?.discounted_amount.toString() || amount;

  const updatedMailroomSubscription = {
    ...mailroomSubscriptionData,
    amount: Number(updatedAmount),
  };

  const selectedSubscription = allSubscriptions?.find(
    ({ uuid }) => uuid === addonPayload?.uuid
  );

  const updatedSubscription = {
    ...selectedSubscription,
    amount: Number(updatedAmount),
  };

  const { link } = useConstructInternalLink();

  const openMailroomAddon = () => {
    if (isMailroomSubscribed) {
      history.push(link(`${url}/inbox`));
      return;
    }

    dispatch(setAddonData(updatedMailroomSubscription));
    dispatch(setAddonType(MAILROOM));
  };

  const closeCheckoutModal = () => {
    dispatch(setCloseCheckoutModal());
  };

  const btnText = isMailroomSubscribed ? "View mails" : "Get started";

  if (!mailroomSubscriptionData) {
    return null;
  }

  return (
    <>
      <LargeCard>
        <div
          role="button"
          onClick={openMailroomAddon}
          className="t-border t-border-solid t-rounded-lg t-p-5 t-border-neutral-0 t-h-full t-w-full t-bg-[url('static/images/MailRoomHomePageBG.svg')] t-bg-auto t-bg-no-repeat t-bg-right"
        >
          <div className="t-h-full t-w-1/2 t-flex t-flex-col t-justify-between">
            <div className="t-w-full">
              <div className="t-text-subtitle t-text-text-100">
                Inkle Mailroom
              </div>
              <span className="t-text-body t-text-text-30">
                View and manage all your mails in one place.
              </span>
            </div>
            <div>
              <Button size="small">{btnText}</Button>
            </div>
          </div>
        </div>
      </LargeCard>

      {(addonType === MAILROOM || addonPayload?.type === MAILROOM) && (
        <AddonsModal />
      )}

      {showCheckoutModal &&
        (addonType === MAILROOM || addonPayload?.type === MAILROOM) &&
        updatedSubscription && (
          <CheckoutModal
            entityId={entityId}
            type="subscription"
            title="Subscription"
            onClose={closeCheckoutModal}
            open={showCheckoutModal}
            subscription={updatedSubscription as Subscription}
            onSubscribed={closeCheckoutModal}
          />
        )}
    </>
  );
};
