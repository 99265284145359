import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  CREATED_BRAND_FAILURE,
  CREATED_BRAND_SUCCESS,
  CREATED_ENTITIES_FAILURE,
  CREATED_ENTITIES_SUCCESS,
} from "constants/analyticsEvents";
import { DEFAULT_PREINCORP_ENTITY } from "constants/entity";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/onBoarding";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useAnalytics } from "hooks/useAnalytics";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BlackArrowLeft from "static/images/BlackArrowLeft.svg";
import {
  useCreateGroupAndEntitiesMutation,
  useGetCountriesDataQuery,
} from "store/apis/onboarding";
import { debounce } from "utils/debouncing";
import { BrandCreation } from "./BrandCreation";
import { EntityCreation } from "./EntityCreation";
import { ServiceTeamCreation } from "./ServiceTeam/ServiceTeamCreation";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { multiEntityCreation } from "formValidations/multiEntityCreation";

export const checkFirstEmptyEntity = (
  entityData: any,
  submitCount?: number
) => {
  if (submitCount === 0) return false;
  const { country, state, formationType, entityName, suffix } = entityData;
  return (
    country === "" ||
    state === "" ||
    formationType === "" ||
    entityName === "" ||
    suffix === ""
  );
};

export const BrandEntityCreation = ({
  groupAvailable,
  setCreateBrand,
  groupName = "",
  isFromAdmin = false,
  moveBack,
  onAdminSubmit,
  preIncorporated,
  setPreincorporated,
  isCreating,
}: {
  groupAvailable?: boolean;
  setCreateBrand?: (newState: React.SetStateAction<boolean>) => void;
  groupName?: string;
  isFromAdmin?: boolean;
  moveBack?: () => void;
  onAdminSubmit?: (
    values: {
      state_id: string;
      formation_type_id: string;
      company_name: string;
    }[]
  ) => void;
  preIncorporated: boolean;
  setPreincorporated: (checked: boolean) => void;
  isCreating: boolean;
}) => {
  const { alertToast } = useToast();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const [nameOfBrand, setNameOfBrand] = useState(groupName);
  const [submitted, setSubmitted] = useState(false);

  const goBack = () => setCreateBrand?.(false);
  const sendToVerify = () => history.push("/verify");

  const { data: countries = [], isLoading } = useGetCountriesDataQuery();
  const [createGroupAndEntities] = useCreateGroupAndEntitiesMutation();
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
  const { isUserTypeForeignCA, isForeignCA } = useRoleBasedView();
  const { pathname } = useLocation();
  const isForeignServiceProviderFlow =
    pathname === "/profile" && isUserTypeForeignCA;

  const usCountryId = countries.find(
    ({ code_alpha_2 }) => code_alpha_2 === "US"
  )?.uuid;

  const delawareStateId = countries
    .find(({ code_alpha_2 }) => code_alpha_2 === "US")
    ?.states.find((s) => s.name === "Delaware")?.uuid;

  const cCorpFormationId = countries
    .find(({ code_alpha_2 }) => code_alpha_2 === "US")
    ?.formation_type.find((s) => s.name === "C Corp")?.uuid;

  const initialEntity = preIncorporated
    ? {
        ...DEFAULT_PREINCORP_ENTITY,
        entityName: Boolean(nameOfBrand) ? `${nameOfBrand}'s Entity` : "",
      }
    : {
        country: usCountryId,
        state: delawareStateId,
        formationType: cCorpFormationId,
        entityName: "",
        suffix: "Inc.",
        foreignQualificationStateIds: [],
      };

  const initialValues = {
    brandName: nameOfBrand,
    // One default country US
    entityData: [initialEntity],
    hasUsEntity: "YES",
    foreignQualification: "NO",
  };

  const onBrandEntityCreation = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    const { brandName, entityData: entitiesFromForm } = values;
    const entity: {
      state_id: string;
      formation_type_id: string;
      company_name: string;
      foreign_qualification_state_ids: string[];
    }[] = [];

    const payload = {
      group_name: brandName,
      entity_data: entity,
      preincorporation_user: preIncorporated,
    };

    const addedEntities = entitiesFromForm;

    for (let i = 0; i < addedEntities.length; i++) {
      const {
        state,
        formationType,
        entityName,
        suffix,
        foreignQualificationStateIds,
      } = addedEntities[i];
      if (state && formationType && entityName && suffix) {
        entity.push({
          state_id: state,
          formation_type_id: formationType,
          company_name: `${entityName} ${suffix}`,
          foreign_qualification_state_ids: foreignQualificationStateIds || [],
        });
      }
    }

    if (entity.length === 0) {
      alertToast({
        message: "Please add atleast one entity with all the details.",
      });
      return;
    }

    if (isFromAdmin && onAdminSubmit) {
      return onAdminSubmit(entity);
    }

    try {
      await createGroupAndEntities({ payload }).unwrap();
      trackEvent(CREATED_BRAND_SUCCESS, {
        brand_name: brandName,
      });

      trackEvent(CREATED_ENTITIES_SUCCESS, {
        brand_name: brandName,
        entities: entity.map((entityData: any) => ({
          name: entityData?.name,
          country: entityData?.country,
        })),
      });

      sendToVerify();
    } catch (err: any) {
      trackEvent(CREATED_BRAND_FAILURE, {
        brand_name: brandName,
      });

      trackEvent(CREATED_ENTITIES_FAILURE, {
        brand_name: brandName,
        entities: entity.map((entityData: any) => ({
          name: entityData?.name,
          country: entityData.country,
        })),
      });
      alertToast({ message: err?.data?.error?.message });
      formikHelpers.setSubmitting(false);
    }
  };

  const handleSubmit = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    setSubmitted(true);
    await onBrandEntityCreation(values, formikHelpers);
    setSubmitted(false);
  };

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={classNames({
        "lg:t-pr-8": !isFromAdmin,
      })}
    >
      <div
        className={classNames(
          "t-align-center t-w-full t-my-0 t-flex t-text-h6 t-text-text-100",
          {
            "t-justify-center": isForeignServiceProviderFlow,
          }
        )}
      >
        {groupAvailable && (
          <div
            className={classNames(
              "-t-mt-0 sm:-t-ml-6  sm:-t-mt-0 t-cursor-pointer",
              {
                "md:-t-ml-12": !isForeignServiceProviderFlow,
                "md:-t-ml-16 t-pr-4": isForeignServiceProviderFlow,
              }
            )}
            onClick={goBack}
            aria-hidden="true"
          >
            <img src={BlackArrowLeft} alt="BlackArrowLeft" className="t-ml-4" />
          </div>
        )}
        {!isFromAdmin && (
          <div>
            {isForeignServiceProviderFlow ? (
              <div className="t-w-[600px] t-flex t-justify-center">
                <div className="t-flex t-flex-col t-w-full t-items-start">
                  <div className="t-text-h4 t-mb-2">Organisation details</div>
                  <div className="t-text-body t-text-text-30">
                    Tell us about your organisation and what you do
                  </div>
                </div>
              </div>
            ) : (
              <p
                className={classNames("t-ml-0 t-text-h4 t-mb-6", {
                  "md:t-ml-1": groupAvailable,
                })}
              >
                Input your brand and create entities
              </p>
            )}
          </div>
        )}
      </div>
      {isForeignServiceProviderFlow ? (
        <ServiceTeamCreation />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={!isFromAdmin && multiEntityCreation}
          initialValues={initialValues}
          enableReinitialize={preIncorporated}
          validateOnBlur={false}
        >
          {({ isSubmitting, errors, values, submitCount }) => {
            return (
              <Form className="all:unset t-flex t-flex-col">
                {!isFromAdmin && (
                  <div className="t-mb-6">
                    <BrandCreation
                      onChange={(e) =>
                        // @ts-ignore // Ignoring for compatability
                        debounce(setNameOfBrand(e.target.value), 1000)
                      }
                    />
                  </div>
                )}
                <EntityCreation
                  notPreincorporated={preIncorporated}
                  setNotPreincorporated={setPreincorporated}
                  isFromAdmin={isFromAdmin}
                />

                <div
                  className={classNames(
                    "t-py-6  t-flex t-flex-row t-bg-surface t-sticky t-bottom-0 t-items-center",
                    {
                      "t-justify-end": isFromAdmin,
                      "t-justify-between": !isFromAdmin,
                    }
                  )}
                >
                  {!isFromAdmin && (
                    <div>
                      <Checkbox
                        name="termspolicy"
                        label={
                          <div>
                            I agree to Inkle's
                            <ConditionalLink to={TERMS_AND_CONDITIONS}>
                              &nbsp;Terms of Service&nbsp;
                            </ConditionalLink>
                            and
                            <ConditionalLink to={PRIVACY_POLICY}>
                              &nbsp;Privacy Policy
                            </ConditionalLink>
                            .
                          </div>
                        }
                        onChange={(e) =>
                          setIsCheckBoxSelected(e.target.checked)
                        }
                      />
                    </div>
                  )}
                  <div
                    className={classNames({
                      "t-flex t-gap-2": isFromAdmin,
                    })}
                  >
                    {isFromAdmin && (
                      <Button type="button" onClick={moveBack}>
                        Back
                      </Button>
                    )}
                    <Button
                      customType="primary"
                      type="submit"
                      disabled={
                        isSubmitting ||
                        (!isFromAdmin && !isCheckBoxSelected) ||
                        isCreating
                      }
                      isLoading={submitted || isCreating}
                    >
                      {isFromAdmin ? "Create" : "Continue"}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
