export const Docs = ({ size = "24" }: { size?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19.6824 19.4609H3.73516C3.64785 19.4609 3.56139 19.4438 3.48073 19.4103C3.40007 19.3769 3.32678 19.3279 3.26504 19.2662C3.2033 19.2045 3.15433 19.1312 3.12092 19.0505C3.0875 18.9699 3.07031 18.8834 3.07031 18.7961V7.96094H19.6016C19.7922 7.96094 19.975 8.03666 20.1098 8.17145C20.2446 8.30624 20.3203 8.48907 20.3203 8.67969V18.8231C20.3203 18.9922 20.2531 19.1545 20.1334 19.2741C20.0138 19.3938 19.8516 19.4609 19.6824 19.4609Z"
      stroke="currentColor"
      strokeWidth="1.4375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.07031 7.96094V5.80469C3.07031 5.61407 3.14604 5.43126 3.28083 5.29646C3.41562 5.16167 3.59844 5.08594 3.78906 5.08594H8.52382C8.61716 5.08562 8.70963 5.1037 8.79597 5.13916C8.88231 5.17462 8.96079 5.22676 9.02696 5.29259L11.6953 7.96094"
      stroke="currentColor"
      strokeWidth="1.4375"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
