export const NotAllowed = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3630_13655)">
      <path
        d="M12.6819 12.2868L4.1969 3.80176"
        stroke="#a09cad"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.43945 14.0439C11.7532 14.0439 14.4395 11.3577 14.4395 8.04395C14.4395 4.73024 11.7532 2.04395 8.43945 2.04395C5.12574 2.04395 2.43945 4.73024 2.43945 8.04395C2.43945 11.3577 5.12574 14.0439 8.43945 14.0439Z"
        stroke="#a09cad"
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_3630_13655">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.439453 0.0439453)"
        />
      </clipPath>
    </defs>
  </svg>
);
