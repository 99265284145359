import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import { useCallback, useState } from "react";
import { useToast } from "./useToast";

export const useCopyToClipboard = () => {
  const { alertToast, successToast } = useToast();
  const [text, setText] = useState("");

  const copyToClipboard = useCallback(async (value: string) => {
    try {
      if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(value);
        setText(value);
        successToast({ message: COPIED_TEXT });
      } else {
        throw new Error("writeText not supported");
      }
    } catch (e) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
  }, []);

  return { text, copyToClipboard };
};
