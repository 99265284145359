import qs from "qs";
import { emptyApi } from "./emptyApi";
import { PerksResponse } from "types/Models/perks";

type Tag = {
  uuid?: string;
  color_hex?: string;
  bg_color_hex?: string;
  tag_category?: number;
  title?: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getIndividualPerk: build.query<
      {
        added_by?: string;
        description?: string;
        heading?: string;
        icon?: string;
        icon_bg_color?: string;
        is_redeemed?: string;
        linkedin_url?: string;
        name?: string;
        offer_description?: string;
        perk_id?: string;
        perk_type?: string;
        tags?: Tag[];
        website?: string;
      },
      { groupId: string; perkSlug: string }
    >({
      query: ({ groupId, perkSlug }) =>
        `/api/inkle/reference/group/${groupId}/slug/${perkSlug}/get/`,
      providesTags: ["PerkList"],
    }),
    redeemDeal: build.mutation<
      void,
      { perkId: string; groupId: string; perkType: string }
    >({
      query: ({ perkId, groupId, perkType }) => {
        return {
          url: `/api/inkle/reference/group/${groupId}/redeem/perk/${perkId}/${perkType}/`,
          method: "POST",
        };
      },
      invalidatesTags: ["PerkList"],
    }),
    getAllDeals: build.query<
      { perks: PerksResponse[]; current_page: number; total_pages: number },
      {
        groupId?: string | null;
        perk_tag_uuids?: string | null;
        search_term?: string | null;
        page_num?: number | null;
        sort_by?: string | null;
      }
    >({
      query: ({ groupId, perk_tag_uuids, search_term, page_num, sort_by }) => {
        let queryUrl = qs.stringify(
          {
            search_term,
            page_num,
            perk_tag_uuids,
            sort_by,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return { url: `/api/inkle/reference/group/${groupId}/get/${queryUrl}` };
      },
    }),
  }),
});
export const {
  useGetIndividualPerkQuery,
  useRedeemDealMutation,
  useGetAllDealsQuery,
  useLazyGetAllDealsQuery,
} = extendedApi;
