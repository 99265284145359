import classNames from "classnames";
import React from "react";
import Placeholder from "static/images/placeholder.jpg";

// components
import CommonCard from "../design/Card";

import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useUpdateQuery } from "hooks/useQuery";

const PerksCard = ({ perk, isPreview }) => {
  const {
    name = "Company Name",
    icon = null,
    offer_description = "Your Offer",
    is_redeemed,
    icon_bg_color,
  } = perk;
  const { url } = useRouteMatch();
  const queryParam = useQuery();
  const currentSort = queryParam.get("sort") || "";

  const history = useHistory();
  const { update } = useUpdateQuery();

  const openModal = (id) => {
    history.push(`${url}/${id}`);
    update({ query: "sort", value: currentSort });
  };
  const imageIcon = icon && isPreview ? icon.preview : icon;
  return (
    <span
      className={classNames({
        redeemedCard: is_redeemed,
      })}
      onClick={() => !isPreview && openModal(perk.slug)}
    >
      <CommonCard
        hover={false}
        newClassName="perksCard t-overflow-hidden !t-border-neutral-10 t-group !t-rounded-lg !t-p-0 t-h-full"
      >
        <div
          style={{ backgroundColor: icon_bg_color }}
          className="t-h-[100px] t-overflow-hidden t-bg-neutral-0 t-w-full bottom-border t-border t-px-[15%] t-flex t-items-center t-justify-center t-flex-col !t-border-neutral-10"
        >
          <img
            src={icon ? imageIcon : Placeholder}
            alt="perks logo"
            className="t-max-h-[70%] t-transform t-transition-transform group-hover:t-scale-[0.90]"
            style={{ objectFit: "contain", alignSelf: "stretch" }}
          />
        </div>
        <div className="t-px-6 t-py-4 t-transform t-transition-transform">
          <div className="t-mb-2 t-text-subtitle t-text-text-100 t-truncate">
            {name}
          </div>
          {offer_description && (
            <div
              className={classNames(
                "t-text-subtext-sm t-leading-[16px] t-flex t-items-center t-justify-center  t-h-[56px] t-bg-surface-lighter-grey t-rounded t-self-stretch t-leading-6 t-text-center t-tracking-tighter t-p-2  group-hover:t-bg-purple-0 big-dashed-border",
                {
                  disabledDetailsContainer: is_redeemed,
                }
              )}
            >
              <div className="t-line-clamp-2 t-text-text-30 group-hover:t-text-purple">
                {offer_description || ""}
              </div>
            </div>
          )}
        </div>
      </CommonCard>
    </span>
  );
};

export default PerksCard;
