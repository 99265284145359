import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const getBooksCrmFilter = (state: RootState) => state.bookkeepingTracker;

export const bookkeepingTracker = createSelector(
  [getBooksCrmFilter],
  ({ filters }) => {
    const subscription_plans = Object.values(filters).filter(
      ({ type }) => type === "subscription_plans"
    )[0];

    const teams = Object.values(filters).filter(
      ({ type }) => type === "teams"
    )[0];

    const assignees = Object.values(filters).filter(
      ({ type }) => type === "assignees"
    )[0];

    const appliedFiltersCount = [subscription_plans, teams, assignees]
      .filter(({ value }) => {
        if (
          (Array.isArray(value) && value?.length === 0) ||
          [...value].length === 0
        ) {
          return null;
        }
        return value;
      })
      .flatMap(({ value }) => [...value])?.length;

    const capsuleFilters = Object.values(filters).filter((filter) => {
      if (Array.isArray(filter.value) && filter.value?.length === 0) {
        return null;
      }
      return filter.value;
    });

    return {
      appliedFiltersCount,
      capsuleFilters,
      filters: {
        subscription_plans,
        teams,
        assignees,
      },
    };
  }
);
