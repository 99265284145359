import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { ProductCard } from "components/ProductCard/ProductCard";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Cart } from "components/icons/Cart";
import dayjs from "dayjs";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useGetAllProductsQuery } from "store/apis/products";
import advancedFormat from "dayjs/plugin/advancedFormat";
import BillingSubscription from "components/billing/BillingSubscription";
import { Subscription } from "components/icons/Subscription";
import { LongArrowRight } from "components/icons/LongArrowRight";
import { CheckSquare } from "components/icons/Navbar/CheckSquare";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useGetInProgressTaskCountQuery } from "store/apis/task";
import { AddProductToCart } from "components/AddProductToCart/AddProductToCart";
import { CartModal } from "components/CartModal/CartModal";
import { currency } from "utils/Currency";
import { PayDebt } from "components/PayDebt/PayDebt";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { Search } from "components/DesignSystem/Search/Search";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";

dayjs.extend(advancedFormat);

export const Product = () => {
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const generalHelpChannel = group.secondary_channel_url;
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const entityId = useCurrentEntityId();

  const {
    data: products,
    isLoading,
    error,
  } = useGetAllProductsQuery(
    {
      gorupId: groupId!,
    },
    { skip: !groupId }
  );

  const { data: { in_progress_task_count } = {} } =
    useGetInProgressTaskCountQuery(
      {
        groupId: groupId!,
      },
      { skip: !groupId }
    );

  const { data: allAddOnSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !entityId || !groupId }
  );

  const activeSubscriptionsCount = allAddOnSubscriptions.filter((s) =>
    Boolean(s.group_subscription)
  ).length;

  const { link } = useConstructInternalLink();
  const query = useQuery();
  const search = query.get("search") || "";
  const { update } = useUpdateQuery();

  if (!products && isLoading && !error) {
    return (
      <div className="t-h-screen t-w-full t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  if (!products) {
    return null;
  }

  return (
    <div className="t-flex t-flex-col t-gap-5 t-pb-16 t-relative">
      {/* <div>
        <Search
          onChange={(e) => update({ query: "search", value: e.target.value })}
          defaultValue={search}
        />
      </div> */}

      <div>
        <p className="t-text-subtext t-mb-3 t-text-text-30">Subscriptions</p>
        <BillingSubscription />
      </div>
      <>
        {Object.entries(products).map(([category, products]) => (
          <div key={category}>
            <p className="t-text-subtext t-mb-3 t-text-text-30">{category}</p>
            <div className="t-grid md:t-grid-cols-2 lg:t-grid-cols-3 t-gap-5">
              {products.map((product) => (
                <ProductCard
                  key={product.uuid}
                  title={
                    <HighlightSearchTerm
                      text={product.title}
                      searchTerm={search}
                    />
                  }
                  description={
                    <HighlightSearchTerm
                      text={product.display_text}
                      searchTerm={search}
                    />
                  }
                  price={
                    !isNaN(Number(product.approx_price)) ? (
                      <p className="t-m-0 t-text-subtext t-text-text-100">
                        <AmountSuperScript
                          amount={Number(product.approx_price)}
                        />
                        {product.billing_period && (
                          <span className="t-text-body-sm t-text-text-30">
                            /{product.billing_period}
                          </span>
                        )}
                      </p>
                    ) : (
                      <span />
                    )
                  }
                  action={
                    <ConditionalLink
                      to={link(`${url}/add-to-cart/${product.uuid}`)}
                    >
                      <Button
                        onClick={() => console.log(product.uuid)}
                        size="small"
                      >
                        <div className="t-flex t-gap-2 t-items-center">
                          <Cart /> Add to cart
                        </div>
                      </Button>
                    </ConditionalLink>
                  }
                />
              ))}
            </div>
          </div>
        ))}
        <div>
          <p className="t-text-subtext t-mb-3 t-text-text-30">
            Quarterly Add-ons
          </p>
          <div className="t-grid md:t-grid-cols-2 lg:t-grid-cols-3 t-gap-5">
            <ProductCard
              title="Sales Tax Monthly and Quarterly filings"
              description="Regular compliance monitoring for all 
        US states"
              price={
                <p className="t-m-0 t-text-subtext t-text-text-100">
                  <AmountSuperScript amount={50} />
                  <span className="t-text-body-sm t-text-text-30">/filing</span>
                </p>
              }
              action={
                <ConditionalLink to={`/chat/${generalHelpChannel}`}>
                  <Button size="small">Contact us</Button>
                </ConditionalLink>
              }
            />

            <ProductCard
              title="Estimated Income Tax Calculation"
              description="Register for any US state through our dashboard and chat feature."
              price={
                <p className="t-m-0 t-text-subtext t-text-text-100">
                  <AmountSuperScript amount={50} />
                  <span className="t-text-body-sm t-text-text-30">
                    /once anually
                  </span>
                </p>
              }
              action={
                <ConditionalLink to={`/chat/${generalHelpChannel}`}>
                  <Button size="small">Contact us</Button>
                </ConditionalLink>
              }
            />
          </div>
        </div>
        <Switch>
          <Route exact path={`${path}/add-to-cart/:productId`}>
            <AddProductToCart onClose={() => history.push(link(url))} />
          </Route>
          <Route path={`${path}/cart`}>
            <CartModal open onClose={() => history.push(link(url))} />
          </Route>
          <Route exact path={`${path}/payment-pending`}>
            <PayDebt isOpen={true} onClose={() => history.push(link(url))} />
          </Route>
        </Switch>
      </>
    </div>
  );
};
