import { getAutofillFields } from "apis/autofill";
import Loader from "components/design/loader";
import NoDataAvailable from "components/design/noDataAvailable";
import ToolTip from "components/design/toolTip";
import { FAILED_COPIED_TEXT } from "constants/documents";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import CopyIcon from "static/images/CopyPurple.svg";
import BlackInfo from "static/images/InfoBlack.svg";
import Modal from "components/DesignSystem/Modal/Modal";
import { Button } from "components/DesignSystem/Button/Button";
import GrayPencil from "static/images/GrayPencil.svg";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const MailboxAddress = ({
  show,
  closeModal,
  groupId,
  openAddModal,
  isForwardAddressRequired,
  defaultAddressAutofillString,
}) => {
  const { alertToast, successToast } = useToast();
  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const entityId = useCurrentEntityId();
  const { isCustomer } = useRoleBasedView();

  useEffect(() => {
    const getAutofillAddress = async () => {
      try {
        const response = await getAutofillFields({
          groupId,
          autofillKey: "addresses",
          entityId,
        });
        setAddress(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alertToast({ message: error?.response?.data?.error?.message });
      }
    };
    getAutofillAddress();
  }, []);

  const copyAddress = async (copyText) => {
    try {
      await navigator.clipboard.writeText(copyText);
      successToast({ message: "Address copied" });
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal} modal={false}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>Addresses</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-flex t-flex-col t-gap-4 t-mb-4">
            <div className="t-flex t-flex-col t-gap-2">
              <div className="t-flex t-gap-2">
                <div className="t-text-subtitle-sm t-text-text-60">
                  Mailroom address
                </div>
                <ToolTip
                  side="right"
                  text={
                    <div className="t-text-left">
                      This is your virtual mailroom address.
                      <br /> This address cannot be your Home or Office address.
                    </div>
                  }
                >
                  <img src={BlackInfo} alt="info" />
                </ToolTip>
              </div>
              <Card>
                <Card.Body className="t-flex t-items-center t-justify-between">
                  <div className="autofill-address">
                    16192, Coastal Highway, Lewes, DE 19958
                  </div>
                  <Button
                    customType="ghost_icon"
                    onClick={() =>
                      copyAddress("16192, Coastal Highway, Lewes, DE 19958")
                    }
                    size="small"
                  >
                    <img src={CopyIcon} alt="copy-icon" />
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="t-flex t-flex-col t-mt-2 t-mb-4">
            <div className="t-flex t-flex-col t-gap-2">
              <div className="t-flex t-gap-1">
                <div className="t-text-subtitle-sm t-text-text-60">
                  Default forwarding address
                </div>
              </div>
              {isForwardAddressRequired ? (
                <div>
                  <Button customType="primary" onClick={openAddModal}>
                    Add Address
                  </Button>
                </div>
              ) : (
                <Card>
                  <Card.Body className="t-flex t-items-center t-justify-between">
                    <div className="t-text-body t-flex t-items-center">
                      {defaultAddressAutofillString}
                    </div>
                    <div className="t-flex">
                      <Button
                        customType="ghost_icon"
                        size="small"
                        type="button"
                        onClick={openAddModal}
                      >
                        <img
                          src={GrayPencil}
                          className="t-h-4 t-m-1"
                          alt="GrayPencil"
                        />
                      </Button>
                      <Button
                        customType="ghost_icon"
                        onClick={() =>
                          copyAddress(defaultAddressAutofillString)
                        }
                        size="small"
                        type="button"
                      >
                        <img src={CopyIcon} alt="copy-icon" />
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
