import classNames from "classnames";

import { ArrowDown } from "components/icons/ArrowDown";
import { Field } from "formik";
import { InputHTMLAttributes } from "react";
import { Label } from "../TextInput/TextInput";

type SelectDropDownI = {
  label?: string;
  block?: boolean;
  dropdownSize?: "small" | "regular";
} & InputHTMLAttributes<HTMLSelectElement>;

export function SelectDropDown({
  label,
  dropdownSize = "regular",
  ...props
}: SelectDropDownI) {
  return (
    <div
      className={classNames("t-relative", { "t-block t-w-full": props.block })}
    >
      {label && (
        <Label htmlFor={props.name} required={props?.required}>
          {label}
        </Label>
      )}
      <Field {...props}>
        {({
          field,
          form: { touched, errors },
        }: {
          field: any;
          form: { touched: any; errors: any };
        }) => {
          const hasError = touched[field.name] && errors[field.name];
          const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            field.onChange(e);
            props.onChange?.(e);
          };

          return (
            <>
              <select
                className={classNames(
                  `all:unset form-input w-full t-box-border  t-flex t-w-[100%] t-items-center t-justify-center t-rounded t-border t-border-solid t-font-sans disabled:t-cursor-not-allowed disabled:t-text-neutral-30 ${
                    hasError ? "t-border-red " : "t-border-neutral-10 "
                  } t-border-neutral-10 t-bg-surface-lighter-grey t-px-4 t-pr-5 t-text-body t-text-text-100 t-transition-all`,
                  {
                    "t-h-12": dropdownSize === "regular",
                    "t-h-10": dropdownSize === "small",
                  }
                )}
                {...{ ...field, ...props }}
                onChange={onChange}
              />

              <div
                className={classNames("t-absolute t-right-5 t-top-10", {
                  "t-top-4": !Boolean(label),
                })}
              >
                <ArrowDown />
              </div>

              {touched[field.name] && errors[field.name] && (
                <div className="t-absolute t-mt-1.5 t-text-caption t-text-red">
                  {errors[field.name]}
                </div>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
}
