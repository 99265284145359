import React from "react";

export const BorderedStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
  >
    <path
      d="M9.5161 1.53201L11.1495 5.42451C11.189 5.51898 11.2536 5.60086 11.3362 5.66132C11.4189 5.72179 11.5164 5.75855 11.6184 5.76764L15.7992 6.12904C15.9101 6.13796 16.0159 6.17958 16.1032 6.24864C16.1905 6.3177 16.2553 6.41109 16.2895 6.517C16.3236 6.62291 16.3256 6.73658 16.2952 6.84362C16.2647 6.95066 16.2032 7.04627 16.1184 7.11834L12.9466 9.88514C12.8695 9.95286 12.8122 10.0402 12.7808 10.1379C12.7493 10.2356 12.7449 10.3399 12.768 10.4399L13.7187 14.556C13.7438 14.6635 13.7367 14.776 13.6983 14.8795C13.66 14.9831 13.592 15.073 13.5028 15.1381C13.4137 15.2033 13.3074 15.2408 13.1971 15.2459C13.0868 15.251 12.9775 15.2236 12.8827 15.167L9.28969 12.9873C9.20239 12.9342 9.10218 12.9062 9 12.9062C8.89783 12.9062 8.79762 12.9342 8.71031 12.9873L5.11735 15.167C5.02254 15.2236 4.91319 15.251 4.80291 15.2459C4.69263 15.2408 4.58629 15.2033 4.49716 15.1381C4.40803 15.073 4.34003 14.9831 4.30166 14.8795C4.26328 14.776 4.25621 14.6635 4.28133 14.556L5.23196 10.4399C5.25506 10.3399 5.25066 10.2356 5.21921 10.1379C5.18777 10.0402 5.13046 9.95286 5.05336 9.88514L1.88156 7.11834C1.79677 7.04627 1.73525 6.95066 1.70481 6.84362C1.67436 6.73658 1.67635 6.62291 1.71053 6.517C1.7447 6.41109 1.80952 6.3177 1.89679 6.24864C1.98406 6.17958 2.08985 6.13796 2.20078 6.12904L6.38156 5.76764C6.48356 5.75855 6.58115 5.72179 6.6638 5.66132C6.74644 5.60086 6.81101 5.51898 6.85055 5.42451L8.48391 1.53201C8.52753 1.43137 8.59963 1.34569 8.69133 1.2855C8.78302 1.22531 8.89032 1.19324 9 1.19324C9.10969 1.19324 9.21698 1.22531 9.30868 1.2855C9.40037 1.34569 9.47247 1.43137 9.5161 1.53201Z"
      stroke="#E7E6EB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
