import { Channel, ChannelFilters, ChannelSort } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ChannelSearchPreview } from "./ChannelSearchPreview";
import InfiniteScroll from "react-infinite-scroll-component";

export const ChannelSearchList = ({
  channels,
  hasNextPage,
  loadNextPage,
  windowElementId,
  unreplied,
  onSelect,
  searchValue,
}: {
  channels?: Channel<DefaultStreamChatGenerics>[];
  hasNextPage: boolean;
  loadNextPage: () => void;
  windowElementId: string;
  unreplied: boolean;
  onSelect?: (channelId: string) => void;
  searchValue: string;
}) => {
  if (!channels) {
    return null;
  }

  if (channels.length === 0) {
    return null;
  }

  return (
    <InfiniteScroll
      dataLength={channels.length}
      next={loadNextPage}
      hasMore={hasNextPage}
      scrollableTarget={windowElementId}
      loader={
        <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
          Loading...
        </div>
      }
    >
      {channels.map((channel) => (
        <ChannelSearchPreview
          key={channel.id}
          searchValue={searchValue}
          onSelect={onSelect}
          channel={channel}
          unreplied={unreplied}
        />
      ))}
    </InfiniteScroll>
  );
};
