import React from "react";

export const GoldenStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <g clip-path="url(#clip0_2260_4076)">
      <path
        d="M7.40146 2.08039L8.67185 5.10789C8.7026 5.18136 8.75282 5.24504 8.8171 5.29207C8.88138 5.3391 8.95728 5.36769 9.03662 5.37476L12.2883 5.65585C12.3746 5.66279 12.4569 5.69516 12.5248 5.74887C12.5926 5.80259 12.6431 5.87522 12.6696 5.9576C12.6962 6.03997 12.6978 6.12838 12.6741 6.21164C12.6504 6.29489 12.6026 6.36925 12.5366 6.42531L10.0697 8.57726C10.0097 8.62993 9.96513 8.69786 9.94067 8.77383C9.91621 8.8498 9.91279 8.93098 9.93076 9.00874L10.6701 12.2102C10.6897 12.2938 10.6842 12.3813 10.6543 12.4618C10.6245 12.5423 10.5716 12.6123 10.5023 12.6629C10.4329 12.7136 10.3502 12.7427 10.2645 12.7467C10.1787 12.7507 10.0936 12.7294 10.0199 12.6854L7.22537 10.9901C7.15747 10.9488 7.07952 10.9269 7.00006 10.9269C6.92059 10.9269 6.84265 10.9488 6.77474 10.9901L3.98021 12.6854C3.90648 12.7294 3.82142 12.7507 3.73565 12.7467C3.64987 12.7427 3.56717 12.7136 3.49785 12.6629C3.42852 12.6123 3.37564 12.5423 3.34579 12.4618C3.31594 12.3813 3.31044 12.2938 3.32998 12.2102L4.06935 9.00874C4.08733 8.93098 4.0839 8.8498 4.05944 8.77383C4.03499 8.69786 3.99041 8.62993 3.93045 8.57726L1.46349 6.42531C1.39754 6.36925 1.3497 6.29489 1.32602 6.21164C1.30234 6.12838 1.30388 6.03997 1.33046 5.9576C1.35704 5.87522 1.40746 5.80259 1.47534 5.74887C1.54321 5.69516 1.6255 5.66279 1.71177 5.65585L4.96349 5.37476C5.04283 5.36769 5.11873 5.3391 5.18301 5.29207C5.24729 5.24504 5.29751 5.18136 5.32826 5.10789L6.59865 2.08039C6.63258 2.00211 6.68866 1.93547 6.75998 1.88865C6.83129 1.84184 6.91474 1.81689 7.00006 1.81689C7.08537 1.81689 7.16882 1.84184 7.24014 1.88865C7.31146 1.93547 7.36753 2.00211 7.40146 2.08039Z"
        fill="#FFCC00"
      />
    </g>
    <defs>
      <clipPath id="clip0_2260_4076">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
