export const CONSULTATION_CALL_WITH_ANAND = "CONSULTATION";
export const INTRODUCTION_TO_US_LAW_FIRMS = "INTRO_US_LAW";
export const SUBSCRIBE_TO_RA_MAILROOM = "SUBSCRIBE_RA_MAILROOM";
export const US_BANK_ACCOUNT = "US_BANK_ACCOUNT";
export const PREINCORPORATION_EXPENSES = "PRE_INCORP_EXPENSE";
export const INDIA_US_STARTUP_HANDBOOK = "HANDBOOK";
export const INKLE_TALKS = "TALKS";

export const CHECKLIST_UPDATE_TOAST_MESSAGE = "Checklist has been updated";
export const PRE_INCORP_EXPENSES_DOWNLOADED_MESSAGE = "Downloaded";
export const PREINCORPORATION_EXPENSES_FILE_LINK =
  "https://inkle-django-files-prod.s3.ap-south-1.amazonaws.com/Pre-incorporation+expense+management.xlsx";
export const INKLE_TALKS_LINK = "https://www.inkle.io/resources/events/talks";
