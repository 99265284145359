import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import {
  addGroupNotes,
  addGroupNotesWithDates,
  getGroupNotes,
} from "../../apis/serviceTeamGroups";
import "../../static/styles/components/salesCrm.css";
import DateInputBox from "../design/dateInput";
import CommonModal from "../design/modal";
import NoDataAvailable from "../design/noDataAvailable";

export const CrmNotes = ({
  showModal,
  closeModal,
  clickedNote,
  needDateInput,
  setLoad,
  refetch,
}) => {
  const { alertToast } = useToast();
  const [notes, setNotes] = useState([]);
  const [notesPayload, setNotesPayload] = useState({
    description: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [datePayload, setDatePayload] = useState({
    last_contact_date: clickedNote.last_contact_date,
    next_contact_date: clickedNote.next_contact_date || "",
    description: "",
  });
  const handleDateChange = async (e) => {
    const { name, value } = e.target;
    setDatePayload({ ...datePayload, [name]: value });
  };

  const { uuid } = clickedNote;
  const { next_contact_date } = datePayload;

  const handleChange = (e) => {
    const { value } = e.target;
    setNotesPayload({ description: value });
    setDatePayload({ ...datePayload, description: value });
  };

  useEffect(() => {
    if (uuid) fetchNotes(true);
  }, [currentPage, uuid]);

  const postNotes = async () => {
    try {
      if (needDateInput) {
        await addGroupNotesWithDates({ groupId: uuid, payload: datePayload });
        setDatePayload({
          last_contact_date: clickedNote.last_contact_date,
          next_contact_date: clickedNote.next_contact_date || "",
          description: "",
        });
        setNotesPayload({
          description: "",
        });
        fetchNotes();
        closeModal();
        refetch?.();
      } else {
        await addGroupNotes({ groupId: uuid, payload: notesPayload });
        setNotesPayload({
          description: "",
        });
        fetchNotes();
      }
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const fetchNotes = debounce(async (paginate) => {
    try {
      if (paginate) {
        const response = await getGroupNotes({ groupId: uuid, currentPage });
        setNotes((prevNotes) => prevNotes.concat(response.data.data.notes));
        setTotalPage(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      }
      if (!paginate) {
        const response = await getGroupNotes({ groupId: uuid, currentPage: 1 });
        if (currentPage === 1) {
          setNotes(response.data.data.notes);
        }
        if (currentPage !== 1) setNotes([]);
        setCurrentPage(response.data.data.current_page);
        setTotalPage(response.data.data.total_pages);
      }
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  });

  const setPagination = () => {
    if (currentPage < totalPage) setCurrentPage((page) => page + 1);
  };

  const checkDisable = () => {
    if (notesPayload.description === "") return false;
    return true;
  };

  return (
    <CommonModal
      show={showModal}
      className="groupNotesModal groupLevelnotesModal"
      closeModal={closeModal}
      modalTitle="Notes"
      body={
        <div id="scrollableDiv" className="scrollableNotes">
          {notes.length > 0 ? (
            <InfiniteScroll
              dataLength={notes.length}
              next={setPagination}
              hasMore={true}
              inverse={true}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              scrollableTarget="scrollableDiv"
            >
              {notes?.map(({ uuid, description, created_at, created_by }) => {
                return (
                  <div key={uuid} className="noteData">
                    <span className="noteDescription">
                      {description} <br />
                      <span className="noteDetails">
                        {created_by.name},{formatDate(created_at)}
                      </span>
                    </span>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : (
            <NoDataAvailable noDataText="No Notes Available" />
          )}
        </div>
      }
      footer={
        <>
          <textarea
            onChange={handleChange}
            className="salesCrmNotesInput"
            placeholder="Add a note ...."
            value={notesPayload.description}
          />
          <span
            className={classNames("dateButtonSection", {
              notesButtonSection: needDateInput,
            })}
          >
            {needDateInput && (
              <span>
                Next date of contact:
                <DateInputBox
                  value={next_contact_date}
                  onChange={handleDateChange}
                  name="next_contact_date"
                  calendarIcon
                />
              </span>
            )}
            <Button onClick={postNotes} disable={!checkDisable()}>
              Save
            </Button>
          </span>
        </>
      }
    />
  );
};
