export const TRANSFER_PRICING_AUDIT = "TRANSFER_PRICING_AUDIT";
export const TRANSFER_PRICING_HEALTH_CHECK = "TRANSFER_PRICING_HEALTH_CHECK";
export const TRANSFER_PRICING_BENCHMARKING_STUDY =
  "TRANSFER_PRICING_BENCHMARKING_STUDY";

export const INTRAGROUP_FAQS = [
  {
    value: "Question1",
    question: "How funds are moved?",
    answer:
      "We open a Virtual Account with JP Morgan Chase for your Indian entity. You need to make a domestic wire from US Bank A/C to this Virtual Account, and then funds are credited in INR in Indian Bank A/C after currency conversion. Funds do not touch Inkle anywhere.",
  },
  {
    value: "Question2",
    question: "Is this only about fund transfers? What else is included?",
    answer:
      "No, it also includes compliance. You can pre-fix the purpose code and download e-FIRA for free from the dashboard to claim GST exemptions.",
  },
  {
    value: "Question3",
    question: "What fees are charged? Is GST applicable on fees?",
    answer:
      "There is a flat 0.25% forex markup fee on live mid-market rates. There are no other hidden fees, such as flat fees or GST deductions. Since we don't charge a flat fee, GST is not applicable.",
  },
];
