import dayjs from "dayjs";

export function formatDayjs(deadline, format) {
  if (deadline) return dayjs(deadline).format(format);
}

export const getMonth = (deadline) => {
  if (deadline) return dayjs(deadline).get("month");
};

export const convertSecondsToDays = (seconds) => {
  return Math.floor(seconds / 86400);
};

export const getDate = (date) => date?.substring(0, 10);
export const getTime = (date) => dayjs(date).format("hh:mmA");
