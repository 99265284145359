import { Button } from "components/DesignSystem/Button/Button";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NoTransaction from "static/images/NoTransaction.svg";
import { openFloatingChat } from "store/slices/chat";
import { openStartFinancialClosingModal } from "store/slices/financialClosing";

export const NoMonthFound: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isCustomer } = useRoleBasedView();

  const chatWithUs = () => {
    dispatch(openFloatingChat());
  };

  const myPlan = () => {
    history.push("/billing");
  };

  return (
    <div className="t-flex t-h-full t-flex-col t-items-center t-justify-center t-gap-6">
      <img src={NoTransaction} alt="No Transaction" />
      <div className="t-flex t-flex-col t-items-center t-gap-2">
        <div className="t-text-subtitle t-leading-5 t-text-i-text-dark">
          {isCustomer ? "Financial Reports" : "Start Bookkeeping"}
        </div>
        <p className="all:unset t-text-center t-text-body t-font-medium t-text-i-text-light">
          {isCustomer
            ? "All your bookkeeping services details will appear here"
            : "Add data & reports for monthly closing here for user review & payments."}
        </p>
      </div>
      {isCustomer ? (
        <div className="t-flex t-gap-2">
          <Button customType="primary" size="small" onClick={chatWithUs}>
            Chat with us
          </Button>
          <Button size="small" onClick={myPlan}>
            My Plan
          </Button>
        </div>
      ) : (
        <Button
          customType="primary"
          size="small"
          onClick={() => dispatch(openStartFinancialClosingModal())}
        >
          Start Bookkeeping
        </Button>
      )}
    </div>
  );
};
