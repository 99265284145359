import { Button } from "components/DesignSystem/Button/Button";
import {
  COMPLETED,
  IN_PROGRESS,
} from "constants/bookkeepingMonthlySummaryStatus";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { MouseEvent } from "react";

export const BookkeepingCardFooter = ({
  onMarkAsComplete,
  onChatWithUs,
  onEdit,
  status,
  onStartClosing,
}: {
  onMarkAsComplete?: () => void;
  onChatWithUs?: (e: MouseEvent<HTMLButtonElement>) => void;
  onEdit?: () => void;
  onStartClosing?: () => void;
  status: string;
}) => {
  const { isCpa, isAdmin, isCustomer } = useRoleBasedView();

  if (status === COMPLETED) {
    return null;
  }

  if (status === IN_PROGRESS) {
    return (
      <div className="t-mr-auto">
        <Button customType="primary" size="small" onClick={onStartClosing}>
          Start annual closing
        </Button>
      </div>
    );
  }

  if (isCpa || isAdmin) {
    return (
      <div className="t-mr-auto">
        <Button customType="primary" size="small" onClick={onEdit}>
          Edit Financials
        </Button>
      </div>
    );
  }

  if (isCustomer)
    return (
      <div className="t-flex t-items-start t-gap-2" onClick={onMarkAsComplete}>
        <Button customType="primary" size="small">
          Mark as complete
        </Button>
        <Button customType="secondary" size="small" onClick={onChatWithUs}>
          Chat with us
        </Button>
      </div>
    );

  return null;
};
