export const EmptySearch = () => (
  <svg
    width="126"
    height="112"
    viewBox="0 0 126 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3478 77.9543C20.0921 75.0402 17.1927 73 13.8165 73C9.28982 73 5.62025 76.6675 5.62025 81.1915C5.62025 81.449 5.63214 81.7037 5.65539 81.9551C2.46069 82.3941 0 85.1333 0 88.4468C0 92.066 2.93565 95 6.55696 95H30.443C34.0643 95 37 92.066 37 88.4468C37 85.4068 34.9289 82.8503 32.1199 82.1099C31.3751 79.0313 28.6003 76.7447 25.2911 76.7447C23.8295 76.7447 22.472 77.1908 21.3478 77.9543Z"
      fill="#F3F3F5"
    />
    <path
      d="M57 72C68.0457 72 77 63.0457 77 52C77 40.9543 68.0457 32 57 32C45.9543 32 37 40.9543 37 52C37 63.0457 45.9543 72 57 72Z"
      stroke="#CFCDD6"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1425 66.1425L85 80"
      stroke="#CFCDD6"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.652 34.9571C105.908 32.0414 108.807 30 112.184 30C116.71 30 120.38 33.6696 120.38 38.1962C120.38 38.4538 120.368 38.7087 120.345 38.9602C123.539 39.3995 126 42.1403 126 45.4557C126 49.077 123.064 52.0127 119.443 52.0127H95.557C91.9357 52.0127 89 49.077 89 45.4557C89 42.414 91.0711 39.856 93.8801 39.1151C94.6249 36.0348 97.3997 33.7468 100.709 33.7468C102.171 33.7468 103.528 34.1932 104.652 34.9571Z"
      fill="#F3F3F5"
    />
  </svg>
);
