import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { useQuery } from "hooks/useQuery";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import React, { useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  Profile,
  useGetAllPreGroupProfilesQuery,
} from "store/apis/salesCrmProfile";
import { ApproveModal, RejectModal } from "./StatusModal";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import { usePageTitle } from "hooks/usePageTitle";
import { CrmUnarchiveAction } from "components/CrmTableDropdown/CrmUnarchiveActionDropdown";

export const ArchivedUserCrm = () => {
  usePageTitle("Archived Users");
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [approveUserProfileId, setApproveUserProfileId] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const query = useQuery();
  const searchTerm = query.get("search_term") || null;

  const {
    data: preGroupProfiles,
    isSuccess,
    isLoading,
  } = useGetAllPreGroupProfilesQuery({
    pageNumber,
    searchTerm,
    archived: true,
  });

  const {
    profiles = [],
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 1,
  } = preGroupProfiles || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    setPageNumber(1);
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      pageNumber < total_pages ? pageNumber : total_pages;
    setPageNumber(localCurrentPage - 1);
  };

  const goToNextPage = () => {
    if (pageNumber < total_pages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const goToLastPage = () => {
    setPageNumber(total_pages);
  };

  const approveHandler = (profileId: string) => {
    setShowModal(true);
    setApproveUserProfileId(profileId);
  };

  const rejectHandler = (profileId: string) => {
    setShowRejectModal(true);
    setApproveUserProfileId(profileId);
  };

  const columnHelper = createColumnHelper<Profile>();

  const columns = [
    columnHelper.display({
      id: "name",
      header: "Name",
      size: 15,
      cell: ({ row }) => {
        const { first_name, last_name } = row.original;
        return (
          <div>
            {first_name} {last_name}
          </div>
        );
      },
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: "Email",
      size: 15,
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor("is_email_verified", {
      id: "is_email_verified",
      header: "Email OTP",
      size: 10,
      cell: ({ value }: any) => (
        <>
          {value ? (
            <img src={GreenTick} alt="GreenTick" />
          ) : (
            <img src={CrossRed} alt="CrossRed" />
          )}
        </>
      ),
    }),
    columnHelper.accessor("is_mobile_verified", {
      id: "is_mobile_verified",
      header: "Mobile OTP",
      size: 10,
      cell: ({ value }: any) => (
        <>
          {value ? (
            <img src={GreenTick} alt="GreenTick" />
          ) : (
            <img src={CrossRed} alt="CrossRed" />
          )}
        </>
      ),
    }),
    columnHelper.display({
      id: "status",
      header: "Status",
      size: 20,
      cell: ({ row }: any) => {
        if (
          !row.original?.is_email_verified &&
          !row.original?.is_mobile_verified
        ) {
          return <div>-</div>;
        }
        if (row.original?.is_rejected) {
          return (
            <div
              className="t-flex t-h-10 t-w-24 t-items-center t-justify-center
        t-rounded t-bg-red-10 t-p-2 t-text-button t-text-red"
            >
              Rejected
            </div>
          );
        }
        if (row.original?.is_restricted) {
          return (
            <div className="t-flex t-gap-2">
              <Button onClick={() => approveHandler(row.original?.uuid)}>
                Approve
              </Button>
              <Button
                customType="secondary"
                onClick={() => {
                  rejectHandler(row.original?.uuid);
                }}
              >
                Reject
              </Button>
            </div>
          );
        }
        if (!row.original?.is_restricted && !row.original?.is_rejected) {
          return (
            <div
              className="t-flex t-h-10 t-w-24 t-items-center t-justify-center
                t-rounded t-bg-green-10 t-p-2 t-text-button t-text-dark_green"
            >
              Approved
            </div>
          );
        }
      },
    }),
    columnHelper.display({
      id: "group",
      header: "Group",
      size: 20,
      cell: ({ row }) => {
        const { group } = row.original;
        return <div>{group?.name}</div>;
      },
    }),
    columnHelper.display({
      id: "threedots",
      header: "",
      size: 5,
      cell: (info) => {
        const { uuid, name } = info.row.original;
        return <CrmUnarchiveAction actionOn={"profile"} uuid={uuid!} />;
      },
    }),
  ];

  const table = useReactTable({
    data: profiles,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const isEmpty = profiles.length === 0;

  return (
    <>
      <div className="t-pb-5 t-flex t-justify-between t-items-center">
        <Search />
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
        <Async.Empty>
          <EmptyScreen text="No users found" />
        </Async.Empty>
        <Async.Success>
          <>
            <Table.Container>
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          key={header.id}
                          style={{ width: `${header.getSize()}%` }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  {table.getRowModel().rows.map((row) => (
                    <Table.Row key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Content>
            </Table.Container>
            <ApproveModal
              showModal={showModal}
              setShowModal={setShowModal}
              approveUserProfileId={approveUserProfileId}
            />
            <RejectModal
              showRejectModal={showRejectModal}
              setShowRejectModal={setShowRejectModal}
              approveUserProfileId={approveUserProfileId}
            />
          </>
        </Async.Success>
      </Async.Root>
    </>
  );
};
