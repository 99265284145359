import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { usePagination } from "hooks/usePagination";
import TaskTabBar from "./tasks/taskTabBar";
import { TableUI } from "./design/TableUI";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { Alarm, useGetAllAlarmsQuery } from "store/apis/alarm";
import { useMemo } from "react";
import { TaskStatus } from "./TaskStatus/TaskStatus";
import { formatDate } from "utils/formatDate";
import { Search } from "./DesignSystem/Search/Search";
import { Filter } from "./DesignSystem/Filter/Filter";
import { Button } from "./DesignSystem/Button/Button";
import { useFilters } from "hooks/useFilter";
import { DateFilter } from "./Filters/DateFilter";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { Chip } from "./DesignSystem/Chips/Chips";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { CompanyGroup } from "types/Models/groups";
import { useLazyGetAllGroupsQuery } from "store/apis/crm";
import { MultiGroupFilter } from "./Filters/MultiGroupFilter";

const createColumn = createColumnHelper<Alarm>();

const columns = [
  createColumn.accessor("task.name", {
    header: "Filing",
    size: 25,
  }),
  createColumn.accessor("deadline", {
    header: "Deadline",
    size: 25,
    cell: (info) => formatDate(info.getValue()),
  }),
  createColumn.accessor("task.current_state.uuid", {
    header: "Status",
    size: 25,
    cell: (info) => (
      <TaskStatus
        statusType={info.row.original.task?.current_state?.type}
        state={info.row.original.task?.current_state?.name}
        status={info.row.original.task?.current_state?.name}
      />
    ),
  }),
  createColumn.display({
    header: "Actions",
    size: 25,
    cell: () => (
      <div className="t-flex t-gap-2">
        <Button size="small">Filed externaly</Button>
        <Button size="small" customType="primary-outlined">
          Launch
        </Button>
      </div>
    ),
  }),
];

export const DeadlinesTracker = () => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  const search = query.get("search") || "";
  const status = query.get("status") || "UPCOMING";

  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      GROUPS: [] as string[],
      START_DATE: "" as string,
      END_DATE: "" as string,
      SELECT_PERIOD: "" as string,
    },
  });

  const pagination = usePagination();

  const paginationData = {
    totalPage: 10,
    currentPage: 1,
    itemsPerPage: 20,
    totalItemCount: 10,
    ...pagination,
  };

  const { data: alarms } = useGetAllAlarmsQuery({
    page_num: paginationData.pageNum,
    search_term: search,
    company_groups: filterValues.GROUPS?.join(","),
    date_from: filterValues.START_DATE,
    date_to: filterValues.END_DATE,
  });

  const data = useMemo(() => alarms?.alarms || [], [alarms]);

  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 5,
    },
    enableRowSelection: true,
  });

  return (
    <div className="t-w-full">
      <TaskTabBar defaultValue="admin/deadlines-tracker" />

      <div className=" t-flex t-flex-col t-gap-4 t-p-4">
        <div className="t-flex t-justify-between t-items-center t-mr-4">
          <ToggleGroup
            onValueChange={(value) => {
              if (value) {
                update({
                  query: "status",
                  value: value !== "UPCOMING" ? value : null,
                });

                if (value === "UPCOMING") {
                  updateFilter("END_DATE", "");
                  updateFilter("START_DATE", dayjs().format(DD_MMM_YYYY));
                }

                if (value === "PASSED") {
                  updateFilter("START_DATE", "");
                  updateFilter("END_DATE", dayjs().format(DD_MMM_YYYY));
                }
              }
            }}
            value={status}
          >
            <ToggleGroupItem value="UPCOMING">Upcoming</ToggleGroupItem>
            <ToggleGroupItem value="PASSED">Passed</ToggleGroupItem>
          </ToggleGroup>
          <Pagination {...paginationData} />
        </div>

        <Search
          onChange={(e) => update({ query: "search", value: e.target.value })}
          defaultValue={search}
        />

        <Filter.Root
          defaultValue="GROUP"
          capsule={
            <>
              {Boolean(filterValues?.START_DATE) && (
                <Chip
                  onClose={() => {
                    updateFilter("START_DATE", "");
                    updateFilter("SELECT_PERIOD", "");
                  }}
                  isActive
                  filterType="DATE"
                >
                  From {dayjs(filterValues.START_DATE).format(DD_MMM_YYYY)}
                </Chip>
              )}
              {Boolean(filterValues?.END_DATE) && (
                <Chip
                  onClose={() => {
                    updateFilter("END_DATE", "");
                    updateFilter("SELECT_PERIOD", "");
                  }}
                  isActive
                  filterType="DATE"
                >
                  To {dayjs(filterValues.END_DATE).format(DD_MMM_YYYY)}
                </Chip>
              )}
            </>
          }
        >
          <Filter.Portal>
            <Filter.List>
              <Filter.ListItem value="GROUP">Company group</Filter.ListItem>
              <Filter.ListItem value="DATE">Date</Filter.ListItem>
            </Filter.List>
            <Filter.Body value="GROUP" block>
              <MultiGroupFilter
                values={filterValues}
                updateFilter={updateFilter}
              />
            </Filter.Body>
            <Filter.Body value="DATE">
              <DateFilter values={filterValues} updateFilter={updateFilter} />
            </Filter.Body>
          </Filter.Portal>
        </Filter.Root>

        <TableUI table={table} />
      </div>
    </div>
  );
};
