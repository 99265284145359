import { TxnCategories } from "types/Models/books";

export const flattenTypes = ({
  accounts,
  topLevel = true,
}: {
  accounts: TxnCategories[];
  topLevel?: boolean;
}): TxnCategories[] => {
  return accounts?.flatMap(({ types, ...account }) => [
    { ...account, topLevel },
    ...(types ? flattenTypes({ accounts: types, topLevel: false }) : []),
  ]);
};
