import { Button } from "components/DesignSystem/Button/Button";
import React from "react";

const WFormHeader = ({
  createCompanyWForm,
}: {
  createCompanyWForm: () => void;
}) => {
  return (
    <div className="t-flex t-items-center t-justify-between t-pb-2">
      <span className="t-text-body-lg t-font-semibold t-text-text-100">
        W-Forms
      </span>
      <Button customType="primary" size="regular" onClick={createCompanyWForm}>
        Generate W-form
      </Button>
    </div>
  );
};

export default WFormHeader;
