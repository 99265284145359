import { send1099File } from "apis/merchants";
import { useToast } from "hooks/useToast";
import { FC, useState } from "react";
import { Merchant } from "types/Models/merchant";
import Loader from "./design/loader";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";
interface IWSeriesFormSendCopyProps {
  show: boolean;
  closeModal: () => void;
  taskId: string;
  groupId: string;
  merchant: Merchant;
  updateMerchant: (merchant: Merchant) => void;
}

export const WSeriesFormSendCopy: FC<IWSeriesFormSendCopyProps> = ({
  show,
  closeModal,
  taskId,
  groupId,
  merchant,
  updateMerchant,
}) => {
  const { email } = merchant || {};
  const { alertToast, successToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const sendCopy = async () => {
    try {
      setIsLoading(true);
      const { data } = await send1099File({
        groupId,
        merchantDocMapId:
          merchant.form_1099_document?.recipients_document
            ?.task_document_merchant_id!,
        email,
      });
      setIsLoading(false);
      updateMerchant(data);
      successToast({ message: "Email has been sent" });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Share Form 1099</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                Are you sure you want to send Form 1099 recipient copy to{" "}
                <b>{email}</b>? This action can not be undone.
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button customType="primary" onClick={sendCopy}>
                Send
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
