import { updateTaskMerchantFilingStatus } from "apis/merchants";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import GeneratingContractors from "static/images/GeneratingContractors.svg";
import "static/styles/components/taskContractors.css";
import { useGetTaskMerchantsQuery } from "store/apis/taskMerchants";
import { Merchant, MERCHANT_TASK_STATUS_TYPES } from "types/Models/merchant";
import { FileSendUpload1099 } from "./1099FileSendUpload";
import { AnalyseBankStatement } from "./AnalyseBankStatement";
import CommonTable from "./design/commonTable";
import Loader from "./design/loader";
import { NoContractor } from "./NoContractor";
import { TaskContractorFilling } from "./TaskContractorFilling";
import { WSeriesFormModal } from "./WSeriesFormModal";

export const TaskContractors = ({
  withFormProgress,
  groupId,
  description,
  noContractorsFound,
  taskSeason,
  entityId,
}: {
  withFormProgress?: boolean;
  groupId: string;
  description: ReactNode | string;
  noContractorsFound: boolean;
  taskSeason: string;
  entityId: string;
}): JSX.Element | null => {
  const { taskId } = useParams<{ taskId: string }>();
  const {
    data: merchants = [],
    isLoading: isContractorLoading,
    refetch,
  } = useGetTaskMerchantsQuery({
    groupId,
    taskId,
    formType: withFormProgress ? "W-9" : undefined,
    season: "2022",
    entityId,
  });
  const { alertToast } = useToast();
  const [isStatusLoading, setStatusLoading] = useState(false);
  const { isCustomer } = useRoleBasedView();

  const updateContractors = async () => {
    await refetch().unwrap();
  };

  const updateFilingStatus = useCallback(
    (merchantId: string) => async (status: MERCHANT_TASK_STATUS_TYPES) => {
      try {
        setStatusLoading(true);
        await updateTaskMerchantFilingStatus({
          taskId,
          merchantId,
          status,
        });
        await updateContractors();
        setStatusLoading(false);
      } catch (error: any) {
        setStatusLoading(false);
        alertToast({ message: error?.response?.data?.error?.message });
      }
    },
    [taskId]
  );

  const columns = useMemo(
    () => [
      {
        width: "33%",
        Header: "Name",
        accessor: "name",
      },
      {
        width: "33%",
        Header: "Email",
        accessor: "email",
        id: "Email",
        Cell: ({
          value,
          row: {
            original: { email },
          },
        }: {
          value: string;
          row: {
            original: Merchant;
          };
        }) => email || "-",
      },
      {
        width: "33%",
        Header: withFormProgress ? "W-9" : "W-Series",
        id: "w-9",
        Cell: ({
          row: { original },
        }: {
          value: string;
          row: {
            original: Merchant;
          };
        }) => (
          <WSeriesFormModal
            merchant={original}
            taskId={taskId}
            groupId={groupId}
            updateMerchant={updateContractors}
            editable={!withFormProgress}
            season={taskSeason}
          />
        ),
      },
    ],
    [groupId, taskId, withFormProgress]
  );

  const columnsWithFormProgress = useMemo(
    () => [
      ...columns.map((c) => ({ ...c, width: "20%" })),
      {
        width: "20%",
        Header: "1099 Forms",
        id: "",
        Cell: ({
          row: { original },
        }: {
          value: string;
          row: {
            original: Merchant;
          };
        }) => (
          <FileSendUpload1099
            merchant={original}
            taskId={taskId}
            groupId={groupId}
            updateMerchant={updateContractors}
            editable={!withFormProgress}
          />
        ),
      },
      {
        width: "20%",
        Header: "Status",
        id: "Status",
        Cell: ({
          row: { original },
        }: {
          value: string;
          row: {
            original: Merchant;
          };
        }) => (
          <TaskContractorFilling
            filingStatus={original.filing_status}
            onUpdate={updateFilingStatus(original.uuid)}
            isLoading={isStatusLoading}
            editable={!isCustomer}
          />
        ),
      },
    ],
    [columns, isCustomer, isStatusLoading, updateFilingStatus]
  );

  let finalColumns = withFormProgress ? columnsWithFormProgress : columns;

  if (isContractorLoading) {
    return <Loader />;
  }

  if (noContractorsFound && merchants?.length === 0) {
    return <NoContractor />;
  }

  if (merchants?.length === 0) {
    return <AnalyseBankStatement />;
  }

  return (
    <div className="task-contractors pb-3 overflow-auto">
      {description}
      {/* @ts-ignore */}
      <CommonTable tableData={merchants || []} tableColumns={finalColumns} />
    </div>
  );
};
