import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = { openInvestorSlider: false, investorEdit: false };

export const investor = createSlice({
  name: "investor",
  initialState,
  reducers: {
    setOpenInvestorSlider: (state, action: PayloadAction<boolean>) => {
      state.openInvestorSlider = action.payload;
    },
    setInvestorEditable: (state, action: PayloadAction<boolean>) => {
      state.investorEdit = action.payload;
    },
  },
});

export const { setInvestorEditable, setOpenInvestorSlider } = investor.actions;

export default investor.reducer;
