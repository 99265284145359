export const PhoneCallIcon = ({
  color = "currentColor",
}: {
  color?: string;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78125 7.79999C6.29576 8.8625 7.15492 9.71946 8.21875 10.2312C8.29722 10.2684 8.38402 10.2845 8.47059 10.2779C8.55717 10.2713 8.64054 10.2423 8.7125 10.1937L10.275 9.14999C10.344 9.10317 10.4238 9.0746 10.5069 9.06695C10.5899 9.0593 10.6736 9.07282 10.75 9.10624L13.675 10.3625C13.775 10.4041 13.8585 10.4774 13.9127 10.5711C13.9669 10.6649 13.9888 10.7738 13.975 10.8812C13.8823 11.6048 13.5291 12.2698 12.9815 12.7518C12.4339 13.2339 11.7295 13.4998 11 13.5C8.74566 13.5 6.58365 12.6045 4.98959 11.0104C3.39553 9.41634 2.5 7.25433 2.5 4.99999C2.50016 4.27049 2.76613 3.56604 3.24814 3.01846C3.73015 2.47088 4.39516 2.1177 5.11875 2.02499C5.22615 2.01119 5.33511 2.03309 5.42884 2.08729C5.52258 2.1415 5.59589 2.22502 5.6375 2.32499L6.89375 5.25624C6.92642 5.33146 6.94009 5.41356 6.93355 5.4953C6.92701 5.57704 6.90046 5.65592 6.85625 5.72499L5.8125 7.31249C5.76603 7.38429 5.73876 7.46681 5.73329 7.55216C5.72782 7.63752 5.74433 7.72284 5.78125 7.79999V7.79999Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
