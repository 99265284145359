export const relationshipOptions = [
  { key: 1, name: "Parent" },
  { key: 2, name: "Full Subsidiary" },
  { key: 3, name: "Partial Subsidiary" },
  { key: 4, name: "Affiliate" },
];

export const ENTITY_PATH = "/entities";

export const USA_COUNTRY_UUID = "530ce334-b166-4109-9a63-b3d6ab3b49ab";
export const DELAWARE_STATE_UUID = "e4f8e9be-7437-402f-8ea8-0b1e8a7d4060";
export const USA_DELAWARE_CCORP_UUID = "ffe77bf1-bd50-4ad2-bb72-7340cc91068f";
export const PREINCORP_FORMATION_TYPE = "Inc.";

export const DEFAULT_PREINCORP_ENTITY = {
  country: USA_COUNTRY_UUID,
  state: DELAWARE_STATE_UUID,
  formationType: USA_DELAWARE_CCORP_UUID,
  entityName: "",
  suffix: PREINCORP_FORMATION_TYPE,
};
