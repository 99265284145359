import { Button } from "components/DesignSystem/Button/Button";
import {
  BareInput,
  ErrorMessage,
} from "components/DesignSystem/TextInput/TextInput";
import MinusCircle from "components/icons/MinusCircle";
import { PlusCircle } from "components/icons/PlusCircle";
import { useField, Field } from "formik";
import { ChangeEvent, ComponentProps } from "react";

export const NumericStepper = (props: ComponentProps<typeof Field>) => {
  const [field, meta, helpers] = useField(props);

  const increment = () => {
    const value = Number(field.value);
    helpers.setValue(value + 1);
  };

  const decrement = () => {
    const value = Number(field.value);
    helpers.setValue(value - 1);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      return;
    }

    helpers.setValue(e.target.value);
  };

  return (
    <div>
      <div className="t-inline-flex">
        <Button
          size="small"
          customType="ghost_icon"
          onClick={decrement}
          type="button"
        >
          <span className="t-text-text-30">
            <MinusCircle color="currentColor" />
          </span>
        </Button>
        <div className="t-w-10">
          <BareInput
            customSize="small"
            onChange={onChange}
            value={field.value}
          />
        </div>
        <Button
          size="small"
          customType="ghost_icon"
          onClick={increment}
          type="button"
        >
          <span className="t-text-text-30">
            <PlusCircle />
          </span>
        </Button>
      </div>
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};
