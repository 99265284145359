import { AddressModal } from "components/AutoFill/AddressModal";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik, FormikValues } from "formik";
import { directorAutofill } from "formValidations/AddFilingDirectorSchema";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import {
  useCreateAutofillMutation,
  useGetAutofillQuery,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import {
  useAddAPAutoFillMutation,
  useUpdateAPAutoFillMutation,
} from "store/apis/directorsAndOfficers";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { AddressAutofill } from "types/Models/addressAutofill";
import { DirectorAutofill } from "types/Models/directorAutofill";

type AddFilingDirectorProps = {
  isLoading: boolean;
  open: boolean;
  onClose: () => void;
  groupId: string;
  entityId: string;
  directorUuid?: string;
  updateTaskDirector?: (values: {
    persona_autofill: any;
    address: any;
  }) => void;
  onDirectorCreate: (director: DirectorAutofill) => void;
};

export const AddFilingDirectorModal = ({
  isLoading,
  open,
  onClose,
  groupId,
  entityId,
  directorUuid,
  updateTaskDirector,
  onDirectorCreate,
}: AddFilingDirectorProps) => {
  const addressAutoFillKey = "addresses";
  const personaAutoFillKey = "authorized_persons";
  const directorAutofillKey = "DIRECTOR";
  const { data: addressAutofills = [], isLoading: isAddressAutofillLoading } =
    useGetAutofillQuery(
      {
        groupId,
        autofillKey: addressAutoFillKey,
        entityId,
      },
      { skip: !groupId || !entityId }
    );

  const { data: personaAutofills = [], isLoading: isPersonaAutofillLoading } =
    useGetAutofillQuery(
      {
        groupId,
        autofillKey: personaAutoFillKey,
        entityId,
      },
      { skip: !groupId || !entityId }
    );

  const { data: directorAutofills = [], isLoading: isDirectorAutofillLoading } =
    useGetAutofillQuery(
      {
        groupId,
        autofillKey: directorAutofillKey,
        entityId,
      },
      { skip: !groupId || !entityId }
    );
  const { data: countries } = useGetCountriesDataQuery();
  const [createAutofill, { isLoading: isCreating }] =
    useCreateAutofillMutation();
  const [editAutofill] = useUpdateAutofillMutation();
  const [createOfficerAP] = useAddAPAutoFillMutation();
  const [updateAPAutofill] = useUpdateAPAutoFillMutation();

  const { alertToast, successToast } = useToast();
  const {
    isOpen: showAddAddressModal,
    close: closeAddAddressModal,
    open: openAddAddressModal,
  } = useModal();

  const [initialValue, setInitialValue] = useState({
    fullName: "",
    address: "",
    city: "",
    country: "",
    state: "",
    street_address: "",
    zipcode: "",
  });

  const addNewAddress = async (values: FormikValues) => {
    try {
      // @ts-ignore
      const newAddress: AddressAutofill = await createAutofill({
        groupId,
        autofillKey: addressAutoFillKey,
        fields: values,
        entityId,
      }).unwrap();
      setInitialValue((initialValue) => ({
        ...initialValue,
        address: newAddress.uuid || "",
        city: newAddress.city || "",
        country: newAddress.country || "",
        state: newAddress.state || "",
        street_address: newAddress.street_address || "",
        zipcode: newAddress.zipcode || "",
      }));
      closeAddAddressModal();
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const updateAddressState = (address: {
    city: any;
    country: any;
    state: any;
    street_address: any;
    zipcode: any;
    uuid: any;
  }) => {
    setInitialValue((initialValue) => ({
      ...initialValue,
      address: address.uuid || "",
      city: address.city || "",
      country: address.country || "",
      state: address.state || "",
      street_address: address.street_address || "",
      zipcode: address.zipcode || "",
    }));
  };

  useEffect(() => {
    if (directorUuid || "") {
      const directorForUpdate = directorAutofills.find(
        ({ uuid }) => uuid === directorUuid || ""
      );
      if (directorForUpdate) {
        setInitialValue({
          fullName: directorForUpdate.persona_autofill?.uuid || "",
          address: directorForUpdate.address?.uuid || "",
          city: directorForUpdate.address?.city || "",
          country: directorForUpdate.address?.country || "",
          state: directorForUpdate.address?.state || "",
          street_address: directorForUpdate.address?.street_address || "",
          zipcode: directorForUpdate.address?.zipcode || "",
        });
      }
    }
  }, [directorAutofills.length, directorUuid || ""]);

  const createNewPersona = async (name: string) => {
    try {
      const addedPersona = await createAutofill({
        groupId,
        entityId,
        autofillKey: personaAutoFillKey,
        fields: { first_name: name, last_name: "" },
      }).unwrap();
      setInitialValue((initialValue) => ({
        ...initialValue,
        fullName: addedPersona?.uuid || "",
      }));
      successToast({ message: "Authorized person added successfullly!" });
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const editDirector = async (values: FormikValues) => {
    try {
      const persona = personaAutofills.find(
        ({ uuid }) => uuid === values.fullName
      );
      const address = addressAutofills.find(
        ({ uuid }) => uuid === values.address
      );

      await updateAPAutofill({
        entityId,
        personaType: "DIRECTOR",
        personaId: directorUuid || "",
        payload: {
          address_autofill_id: values.address,
          persona_autofill_id: values.fullName,
        },
      }).unwrap();
      updateTaskDirector?.({
        address: address,
        persona_autofill: persona,
      });
      onClose();
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const addDirector = async (values: FormikValues) => {
    try {
      const directorPayload = {
        persona_autofill_id: values.fullName,
        address_autofill_id: values.address,
      };

      const director = await createOfficerAP({
        entityId,
        personaType: "DIRECTOR",
        payload: directorPayload,
      }).unwrap();
      onDirectorCreate(director);
      successToast({ message: "Director added successfullly!" });
      onClose();
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const updatePersonaDetails = (
    personaValue: SingleValue<OptionData> | MultiValue<OptionData>,
    validateForm: () => void
  ) => {
    setInitialValue((initialValue) => ({
      ...initialValue,
      //@ts-ignore
      fullName: personaValue?.value,
    }));
    setTimeout(() => {
      validateForm();
    }, 100);
  };

  const updateAddressFields = (
    addressValue: SingleValue<OptionData> | MultiValue<OptionData>,
    validateForm: () => void
  ) => {
    const selectedAddress = addressAutofills.find(
      //@ts-ignore
      (address) => address.uuid === addressValue?.value
    );

    if (selectedAddress) {
      updateAddressState(selectedAddress);
      setTimeout(() => {
        validateForm();
      }, 100);
    }
  };

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Formik
        initialValues={initialValue}
        onSubmit={directorUuid ? editDirector : addDirector}
        validationSchema={directorAutofill}
        enableReinitialize
      >
        {({ isSubmitting, values, submitForm, isValid, validateForm }) => {
          const currentAddress = addressAutofills.find(
            ({ uuid }) => uuid === values.address
          );

          const currentPersona = personaAutofills.find(
            ({ uuid }) => uuid === values.fullName
          );

          return (
            <Form className="all:unset">
              <Modal.Content useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>
                    {directorUuid ? "Edit" : "Add New"} Director
                  </Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-flex-col t-gap-5">
                  {isPersonaAutofillLoading ||
                  isAddressAutofillLoading ||
                  isDirectorAutofillLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <Combobox
                        menuPortalTarget={document.body}
                        onChange={(
                          personaValue:
                            | SingleValue<OptionData>
                            | MultiValue<OptionData>
                        ) => {
                          updatePersonaDetails(personaValue, validateForm);
                        }}
                        withForm
                        components={{ ClearIndicator: () => null }}
                        name="fullName"
                        label="Full Name"
                        placeholder="John cena"
                        options={personaAutofills.map((autofill) => ({
                          label: (
                            <div className="t-text-body t-w-full t-truncate">
                              {autofill.first_name} {autofill.last_name}
                            </div>
                          ),
                          value: autofill.uuid,
                        }))}
                        creatable
                        onCreateOption={createNewPersona}
                        value={{
                          label: (
                            <div className="t-text-body t-w-full t-truncate">
                              {currentPersona?.first_name || ""}{" "}
                              {currentPersona?.last_name || ""}
                            </div>
                          ),
                          value: currentPersona?.uuid || "",
                        }}
                        isLoading={isCreating}
                      />
                      <Combobox
                        actions={
                          <Button
                            customType="link"
                            onClick={openAddAddressModal}
                          >
                            Add new address
                          </Button>
                        }
                        menuPortalTarget={document.body}
                        components={{ ClearIndicator: () => null }}
                        onChange={(
                          address:
                            | SingleValue<OptionData>
                            | MultiValue<OptionData>
                        ) => {
                          updateAddressFields(address, validateForm);
                        }}
                        withForm
                        name="address"
                        label="Address"
                        options={addressAutofills.map((autofill) => ({
                          label: (
                            <div className="t-text-body t-w-full t-truncate">
                              {autofill.autofill_string}
                            </div>
                          ),
                          value: autofill.uuid,
                        }))}
                        value={{
                          value: values.address,
                          label: (
                            <div className="t-text-body t-w-full t-truncate">
                              {currentAddress?.autofill_string || ""}
                            </div>
                          ),
                        }}
                      />
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-end t-gap-3">
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    type="submit"
                    customType="primary"
                    isLoading={isSubmitting || isLoading}
                    disabled={isSubmitting || isLoading || !isValid}
                    onClick={submitForm}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          );
        }}
      </Formik>
      <AddressModal
        isLoading={isCreating}
        initialValues={{}}
        onSubmit={addNewAddress}
        onClose={closeAddAddressModal}
        open={showAddAddressModal}
      />
    </Modal.Root>
  );
};
