import { Children, ReactNode } from "react";
import cx from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "../Button/Button";

const Root = ({ children }: { children: ReactNode }) => {
  const childrenLength = Children.count(children);
  return (
    <div className="t-flex t-gap-2">
      {Children.map(children, (child, index) => {
        if (index != childrenLength - 1) {
          return (
            <>
              <div className="t-text-body-sm ">{child}</div>
              <div className="t-text-neutral-30 t-text-[9px] t-leading-5">
                /
              </div>
            </>
          );
        } else {
          return <div className="t-text-body-sm">{child}</div>;
        }
      })}
    </div>
  );
};
const Item = ({ children, link }: { children: ReactNode; link?: string }) => {
  return (
    <ConditionalLink to={link}>
      <div
        className={cx(" t-text-text-60 ", {
          "hover:t-text-purple": Boolean(link),
        })}
      >
        {children}
      </div>
    </ConditionalLink>
  );
};

const Breadcrumb = {
  Root,
  Item,
};
export default Breadcrumb;
