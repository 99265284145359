import * as RadioGroupR from "@radix-ui/react-radio-group";
import classNames from "classnames";
import { ReactNode } from "react";

export type ContentProps = {
  children?: ReactNode;
  className?: string;
  text?: string;
};

export const RadioGroupItem = ({ value }: { value: string }) => {
  return (
    <RadioGroupR.Item
      value={value}
      id={value}
      className="all:unset t-bg-white t-min-w-[18px] t-min-h-[18px] t-rounded-full t-outline-none t-cursor-default t-border t-border-solid t-border-neutral hover:t-border-purple hover:t-border-1 data-state-checked:t-border-purple-50 t-flex t-justify-center t-items-center"
    >
      <RadioGroupR.Indicator>
        <div className="t-w-2 t-h-2 t-bg-purple-50 t-rounded-[50%]" />
      </RadioGroupR.Indicator>
    </RadioGroupR.Item>
  );
};

const Item = ({
  children,
  className = "",
  value = "",
  ...props
}: RadioGroupR.RadioGroupItemProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <label
      className={classNames(
        "t-flex t-gap-3 t-items-center t-w-full t-h-5",
        className
      )}
      htmlFor={value}
    >
      <RadioGroupItem value={value} />
      <div className="t-w-full t-h-full t-text-text-100 t-cursor-pointer t-align-middle">
        {children}
      </div>
    </label>
  );
};

const Content = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames("t-flex t-flex-col", {
        [className]: className,
        "t-gap-2": !className,
      })}
    >
      {children}
    </div>
  );
};

const RadioGroup = {
  ...RadioGroupR,
  Item: Item,
  Content: Content,
};

export default RadioGroup;
