import Loader from "components/design/loader";
import NoDataAvailable from "components/design/noDataAvailable";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetVendorsQuery } from "store/apis/vendors";
import { setDefaultChannel } from "store/slices/chat";
import { MerchantTable } from "./merchantTable";

export const MerchantsPanel = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const query = useQuery();
  const selectedSeason = query.get("season") || "2023";
  const page = Number(query.get("page")) || 1;
  let selectedEntityId = useCurrentEntityId();
  const vendorsTypeFromQuery = query.get("vendorsType");
  const searchTerm = query.get("search_term");
  const { updateMultiple } = useUpdateQuery();

  const {
    data: merchantData,
    isLoading,
    isSuccess,
    refetch,
  } = useGetVendorsQuery(
    {
      groupId,
      search_term: searchTerm,
      type: vendorsTypeFromQuery === "wForm" ? "VENDORS" : null,
      page_num: page,
      season: selectedSeason,
      entityId: selectedEntityId,
    },
    { skip: !groupId || !selectedEntityId }
  );

  const {
    merchants = [],
    channel_url,
    current_page,
    per_page,
    total_count,
    total_pages,
  } = merchantData || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  useEffect(() => {
    dispatch(setDefaultChannel(channel_url));
  }, [channel_url]);

  const noMerchant = merchants.length === 0;
  let sortedMerchants = [...merchants].sort((a, b) => {
    return a.transaction_amount - b.transaction_amount;
  });

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <MerchantTable
      merchants={sortedMerchants}
      groupId={groupId}
      type={vendorsTypeFromQuery}
      totalPages={total_pages}
      paginationData={paginationData}
      noMerchant={noMerchant}
    />
  );
};
