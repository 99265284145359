import React, { useContext } from "react";

//style
import "../static/styles/common.css";
import "../static/styles/components/landingContent.css";

//components
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Stepper } from "./DesignSystem/Stepper/Stepper";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { FOREIGN_CA } from "constants/userTypes";
import { INVESTOR } from "constants/onBoarding";
import { InvitationContext } from "contexts/InvitationContext";

function Left({ className }) {
  const { currentStep } = useSelector((state) => state.onboarding);
  const { isUserTypeForeignCA, isInvestor, isForeignCA } = useRoleBasedView();
  const userType = localStorage.getItem("user_type");
  const isInvited = useContext(InvitationContext);
  const isFCA = isUserTypeForeignCA || isForeignCA;

  const hideStep3 = isInvited || isInvestor || userType === INVESTOR;

  const hideStep4 = isFCA || userType === FOREIGN_CA;

  const step3 =
    isUserTypeForeignCA || userType === FOREIGN_CA
      ? ["Organisation details"]
      : ["Brand and entity details"];

  const step4 = ["Subscribe & pay $300/year"];

  const onboardingSteps = [
    "Email verification",
    "Mobile verification",
    ...(hideStep3 ? [] : step3),
    ...(hideStep3 || hideStep4 ? [] : step4),
  ];

  return (
    <div
      className={classNames("leftBox t-py-0 t-pr-0 t-mr-8", {
        [className]: Boolean(className),
      })}
    >
      <div className="leftBoxContent ">
        <div className="t-text-h4 t-mb-5 t-text-text-100">
          {isInvited ? "Complete your profile!" : "Complete your sign up!"}
        </div>
        <Stepper>
          {onboardingSteps.map((step, index) => {
            const isCompleted = currentStep - 1 > index;
            const isActive = currentStep - 1 === index;
            const isPending = currentStep - 1 < index;
            return (
              <Stepper.Step
                key={step}
                step={index + 1}
                isActive={isActive}
                isPending={isPending}
                isCompleted={isCompleted}
                notFirstStep={index > 0}
                notLastStep={index < onboardingSteps.length - 1}
              >
                {step}
              </Stepper.Step>
            );
          })}
        </Stepper>
      </div>
    </div>
  );
}

export default Left;
