import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { Form, Formik, FormikValues } from "formik";
import { personaAutofill } from "formValidations/autofillSchema";
import { useGetCountriesQuery } from "store/apis/autofill";

type PersonaModalProps = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (v: FormikValues) => void;
  initialValues: any;
  entityId: string;
};

export const PersonaModal = ({
  isLoading,
  open,
  onClose,
  initialValues,
  onSubmit,
  entityId,
}: PersonaModalProps) => {
  const { id } = initialValues;
  const localInitialValues = id
    ? initialValues
    : {
        first_name: "",
        last_name: "",
        email: "",
        address_id: null,
        citizenship: "",
        tax_id: "",
        ownership_stake: "",
      };

  const { data: countries = [] } = useGetCountriesQuery({});

  return (
    <Modal.Root onOpenChange={onClose} open={open}>
      {open && (
        <Formik
          onSubmit={onSubmit}
          initialValues={localInitialValues}
          validationSchema={personaAutofill}
        >
          {({ values, submitForm, setFieldValue }) => {
            return (
              <Modal.Content asChild>
                <Form>
                  <Modal.Header>
                    <Modal.Title>{id ? "Edit" : "Add"} Persona</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-flex t-flex-col t-gap-4">
                    <TextInput block label="First name" name="first_name" />
                    <TextInput block label="Last name" name="last_name" />
                    <TextInput block label="Email" name="email" />
                    <SelectAutofillCombobox
                      type="addresses"
                      entityId={entityId}
                      name="address_id"
                      selected={values.address_id}
                      label="Address"
                      withForm
                      onSelect={(autofill) =>
                        setFieldValue("address_id", autofill.uuid)
                      }
                    />
                    <SelectDropDown name="citizenship" label="Citizenship">
                      <option>Select Citizenship</option>
                      {countries?.map(
                        (country: {
                          iso_code: string;
                          name: string;
                          uuid: string;
                        }) => {
                          return (
                            <option key={country.uuid} value={country?.name}>
                              {country?.name}
                            </option>
                          );
                        }
                      )}
                    </SelectDropDown>
                    <TextInput block label="Tax ID" name="tax_id" />
                    <TextInput
                      block
                      label="Ownership stake"
                      name="ownership_stake"
                    />
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button
                      type="reset"
                      customType="secondary"
                      size="regular"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      onClick={submitForm}
                      customType="primary"
                      size="regular"
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      Save
                    </Button>
                  </Modal.FooterButtonGroup>
                </Form>
              </Modal.Content>
            );
          }}
        </Formik>
      )}
    </Modal.Root>
  );
};
