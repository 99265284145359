import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Formik, FormikValues, Form } from "formik";
import { FC } from "react";
import { editPriceAndDescriptionSchema } from "formValidations/editPriceAndDescriptionSchema";
import { Button } from "components/DesignSystem/Button/Button";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { useToast } from "hooks/useToast";
import { useUpdateTaskMutation } from "store/apis/task";
import { useParams } from "react-router-dom";

interface IEditPriceAndDescriptionProps {
  show: boolean;
  closeModal: () => void;
  finalPrice: string;
}

export const EditPriceAndDescription: FC<IEditPriceAndDescriptionProps> = ({
  show,
  closeModal,
  finalPrice,
}) => {
  const { alertToast, successToast } = useToast();
  const [updateTask, { isLoading }] = useUpdateTaskMutation();
  const { taskId } = useParams<{ taskId: string }>();

  const handleSubmit = async (values: FormikValues) => {
    try {
      const { amount, scopeOfWork } = values;
      await updateTask({
        task_id: taskId,
        operation: "TASK_PRICE_UPDATE",
        price: amount,
        description: scopeOfWork,
      }).unwrap();

      successToast({ message: "Updated successfully!" });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit price and description</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{ amount: finalPrice, scopeOfWork: "" }}
          onSubmit={handleSubmit}
          validationSchema={editPriceAndDescriptionSchema}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form className="t-m-0" onKeyDown={(e) => e.stopPropagation()}>
                <Modal.Body className="t-overflow-auto t-max-h-[450px]">
                  <div>
                    <TextInput
                      block
                      name="amount"
                      placeholder="Enter amount"
                      label="Amount"
                      type="number"
                    />
                  </div>
                  <div className="t-mt-4">
                    <TextArea
                      placeholder="Enter Scope of work"
                      label="Scope of work"
                      name="scopeOfWork"
                      block
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button type="button" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      customType="primary"
                      isLoading={isSubmitting || isLoading}
                      disabled={!isValid || isLoading}
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
