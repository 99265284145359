import React from "react";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

export const useTokenValidation = () => {
  try {
    const accessToken = JSON.parse(localStorage.getItem("authTokens"));
    const user = jwt_decode(accessToken?.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    return accessToken?.access && !isExpired;
  } catch (error) {}
};
