export const NOT_STARTED = "Not Started";
export const SUCCESSFULLY_FILLED = "Successfully filed";
export const FILL_FORM = "Fill form";
export const FILING_IN_PROGRESS = "Filing in progress";
export const UPLOAD_DOCUMENTS = "Upload documents";

export const NOT_CREATED = "not_created";

export const NOT_STARTED_KEY = "not_started";
export const NEXT_CONTACT_DATE = "next_contact_date";
export const NOT_COMPLETED = "not_completed";
