export const WhatsNewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_532_9172)">
        <path
          d="M7.96889 16.6948L3.12577 14.9135C2.98336 14.861 2.86048 14.766 2.7737 14.6415C2.68691 14.5169 2.64038 14.3688 2.64038 14.217C2.64038 14.0652 2.68691 13.917 2.7737 13.7925C2.86048 13.6679 2.98336 13.573 3.12577 13.5204L7.96889 11.7392C8.06973 11.7022 8.16131 11.6438 8.23725 11.5678C8.31319 11.4919 8.37165 11.4003 8.40858 11.2995L10.1898 6.45635C10.2424 6.31394 10.3373 6.19107 10.4619 6.10428C10.5864 6.01749 10.7346 5.97096 10.8864 5.97096C11.0382 5.97096 11.1863 6.01749 11.3109 6.10428C11.4354 6.19107 11.5304 6.31394 11.583 6.45635L13.3642 11.2995C13.4011 11.4003 13.4596 11.4919 13.5355 11.5678C13.6115 11.6438 13.7031 11.7022 13.8039 11.7392L18.647 13.5204C18.7894 13.573 18.9123 13.6679 18.9991 13.7925C19.0859 13.917 19.1324 14.0652 19.1324 14.217C19.1324 14.3688 19.0859 14.5169 18.9991 14.6415C18.9123 14.766 18.7894 14.861 18.647 14.9135L13.8039 16.6948C13.7031 16.7317 13.6115 16.7902 13.5355 16.8661C13.4596 16.9421 13.4011 17.0336 13.3642 17.1345L11.583 21.9776C11.5304 22.12 11.4354 22.2429 11.3109 22.3297C11.1863 22.4165 11.0382 22.463 10.8864 22.463C10.7346 22.463 10.5864 22.4165 10.4619 22.3297C10.3373 22.2429 10.2424 22.12 10.1898 21.9776L8.40858 17.1345C8.37165 17.0336 8.31319 16.9421 8.23725 16.8661C8.16131 16.7902 8.06973 16.7317 7.96889 16.6948Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8901 2.21323V6.71323"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3901 7.46323V10.4632"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6401 4.46323H19.1401"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8901 8.96323H22.8901"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_532_9172">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.390137 0.713226)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
