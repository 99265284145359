export const COOKIE_NAME = "sessionid";
export const LAST_ANSWER = "lastAnswer";
export const TOTAL_ANSWERED = "totalAnswered";
export const TOTAL_SUBMITTED_UUID = "totalSubmittedUuid";
export const IS_FINAL = "isFinal";
export const SELECT_ANSWER_SWAL = "Please Select An Answer";
export const QUOTATION_BUTTON_TEXT_INTI = "Get instant quotation";
export const QUOTATION_BUTTON_TEXT_UPDATED = "Sign in to Inkle";

export const GO_TO_INKLE = "https://www.inkle.io/";
export const GO_TO_INKLE_CALENDAR = "https://www.inkle.io/company/contact";

export const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const REMINDER_MODAL_DATA = {
  title: "Reminder",
  buttonName: "Subscribe",
};

export const DOWNLOAD_MODAL_DATA = {
  title: "Download calendar",
  buttonName: "Download",
};

export const QUOTATION_MODAL_DATA = {
  title: "Inkle quotation",
  buttonName: "Done",
};

export const REMINDER = "Reminder";
export const NULL_VALUE = "null";
export const DOWNLOAD = "Download calendar";

export const COMPLIANCE = "COMPLIANCE";
export const TAX = "TAX";
export const DEFAULT_OPTION = `{"a":"defaultAnswer"}`;
export const DEFAULT_ANSWER = "defaultAnswer";

export const FINISH_PAYMENT = "Finish payment";
export const FINISH_PAYMENT_DETAILS =
  "Click on Go to Payment to create your account and pay.";
export const ADD_COMPANY = "Add company details";
export const ADD_COMPANY_DETAILS =
  "After completing payment, you’ll be able to add more infomation about your company(Ownership, Shareholder, etc.)";
export const SIGN_DOCUMENTS = "Sign documents";
export const SIGN_DOCUMENTS_DETAILS =
  "We’ll generate all the necessary forms you’ll need to sign and setup your company dashboard.";
