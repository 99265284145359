import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MessageToScrollTo = {
  messageId?: string;
};

const initialState: MessageToScrollTo = {
  messageId: "",
};

export const messageToScrollTo = createSlice({
  name: "messageToScrollTo",
  initialState,
  reducers: {
    setScrollToMessageId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.messageId = action.payload;
    },
  },
});

export const { setScrollToMessageId } = messageToScrollTo.actions;

export default messageToScrollTo.reducer;
