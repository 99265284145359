import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    setNoContractorsFound: build.mutation<
      { task_id: string; are_contractors_present: boolean },
      { taskId: string; groupId: string; isContractorsPresent: boolean }
    >({
      query: ({ taskId, groupId, isContractorsPresent }) => ({
        url: `/api/inkle/tasks/${taskId}/group/${groupId}/merchants/`,
        body: {
          are_contractors_present: isContractorsPresent,
        },
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useSetNoContractorsFoundMutation } = extendedApi;
