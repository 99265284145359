import { axiosInstance } from "./apiInstance";

export const getEntities = async () => {
  return await axiosInstance.get("/api/inkle/company/group/entities/");
};

export const getEntityTree = async ({ groupUuid }) => {
  return await axiosInstance.get(
    `/api/inkle/company/group/${groupUuid}/entities/tree/`
  );
};
