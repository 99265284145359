import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { CALL_NO_RESPONSE } from "constants/CallAdmin";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import {
  useEditCallDetailsMutation,
  useGetCallDetailsQuery,
} from "store/apis/chat";
import { object, string } from "yup";

export const CallNotesModal = ({
  viewOnly,
  isOpen,
  close,
  groupUserPhoneCallId,
}: {
  viewOnly?: boolean;
  isOpen: boolean;
  close: () => void;
  groupUserPhoneCallId?: string;
}) => {
  const { data: callDetails, isLoading } = useGetCallDetailsQuery(
    {
      groupUserPhoneCallId,
    },
    { skip: !groupUserPhoneCallId }
  );

  const [updateCallDetails, { isLoading: isUpdating }] =
    useEditCallDetailsMutation();

  const { notes, call_status } = callDetails || {};

  const { successToast, alertToast } = useToast();

  const shouldShowCustomerPick = call_status === CALL_NO_RESPONSE;

  const handleSubmit = async ({
    call_connected,
    notes,
  }: {
    call_connected: string | null;
    notes: string;
  }) => {
    try {
      await updateCallDetails({
        groupUserPhoneCallId,
        notes: notes,
        ...(shouldShowCustomerPick &&
          call_connected && {
            callConnected: call_connected === "YES" ? true : false,
          }),
      }).unwrap();
      successToast({ message: "Note added successfully" });
      close();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Formik
          initialValues={{
            is_call_connected_status: shouldShowCustomerPick,
            call_connected: "",
            notes: notes || "",
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={() =>
            object({
              call_connected: string().when("is_call_connected_status", {
                is: true,
                then: string().required(),
                otherwise: string().notRequired(),
              }),
              notes: string().min(1).required("Answer required"),
            })
          }
          validateOnMount
        >
          {({ submitForm, setFieldValue, isValid }) => (
            <>
              <Modal.Header>
                <Modal.Title>Notes</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form className="t-m-0 t-space-y-6">
                  {!viewOnly && shouldShowCustomerPick && (
                    <div>
                      <Label required htmlFor="call_connected">
                        Did the customer pick up the call?
                      </Label>
                      <Radio.Root
                        name="call_connected"
                        onValueChange={(e) => {
                          setFieldValue("call_connected", e);
                        }}
                      >
                        <Radio.Content className="">
                          <div className="t-flex t-items-center t-gap-6">
                            <Radio.Item
                              asChild
                              value="YES"
                              key="YES"
                              className="!t-w-fit"
                            >
                              <div className="t-text-body t-text-text-100">
                                Yes
                              </div>
                            </Radio.Item>
                            <Radio.Item asChild value="NO" key="NO">
                              <div className="t-text-body t-text-text-100">
                                No
                              </div>
                            </Radio.Item>
                          </div>
                        </Radio.Content>
                      </Radio.Root>
                    </div>
                  )}
                  <div>
                    <ConditionalToolTip
                      condition={
                        viewOnly &&
                        "Only Inkle Admin who initiated the call can edit notes"
                      }
                    >
                      <span>
                        <TextArea
                          label="Notes"
                          name="notes"
                          placeholder="Add notes from the call"
                          disabled={viewOnly}
                        />
                      </span>
                    </ConditionalToolTip>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                {!viewOnly ? (
                  <>
                    <Modal.RawClose asChild>
                      <Button type="button">Cancel</Button>
                    </Modal.RawClose>
                    <Button
                      customType="primary"
                      isLoading={isLoading || isUpdating}
                      disabled={!isValid || isLoading || isUpdating}
                      onClick={submitForm}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Modal.RawClose asChild>
                    <Button customType="primary">Close</Button>
                  </Modal.RawClose>
                )}
              </Modal.FooterButtonGroup>
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
