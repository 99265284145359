import { Divider } from "@mui/material";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Price } from "components/Price";
import { REGISTERED_AGENT, REGISTERED_AGENT_MANAGE } from "constants/addons";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { MONTHLY } from "constants/stripeCouponPeriods";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import { resetAllAddonDataPayload, setAddonType } from "store/slices/addons";
import { RootState } from "store/store";

const getSelectedStates = ({ addonData }: any) => {
  const OtherStates = addonData?.group_subscription?.filter(
    (ele: any) => ele.type === "Others"
  )?.[0];

  const OtherStatesUpdated =
    OtherStates?.selectedStates?.map((obj: any) => {
      return {
        amount: OtherStates?.amount,
        ra_state_name: obj?.ra_state_name,
        group_subscription_id: obj?.group_subscription_id,
      };
    }) || [];

  const specialStates =
    addonData?.group_subscription
      ?.filter((ele: any) => ele.type !== "Others")
      ?.map((obj: any) => {
        return {
          amount: obj?.amount,
          ...obj.selectedStates?.[0],
        };
      }) || [];

  const statesSelected = [...specialStates, ...OtherStatesUpdated];
  return statesSelected;
};

export const RegisteredAgentManage = () => {
  const dispatch = useDispatch();
  const { addonType, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonType === REGISTERED_AGENT_MANAGE
  );
  const [showCancelStateModal, setShowCancelStateModal] = useState(false);
  const entityId = useCurrentEntityId();

  return (
    <>
      <Modal.Root
        open={showAddonModal}
        onOpenChange={() => {
          setShowAddonModal(false);
          dispatch(setAddonType(""));
          dispatch(resetAllAddonDataPayload());
        }}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Manage plan - Registered Agent</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-gap-5 t-p-5 t-border-solid t-border t-border-neutral-0 t-rounded-lg">
              <div className="t-text-subtitle">Current states</div>
              <Divider />
              {getSelectedStates({ addonData })?.map((ele: any) => (
                <div
                  className="t-flex t-justify-between t-text-body t-text-text-100"
                  key={ele?.ra_state_name}
                >
                  <div>{ele?.ra_state_name}</div>
                  <div>
                    <Price amount={ele.amount} size="medium" cycle={MONTHLY} />
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-between">
            <Button
              customType="ghost"
              onClick={() => {
                setShowCancelStateModal(true);
                setShowAddonModal(false);
              }}
            >
              Cancel subscription
            </Button>
            <Button
              customType="primary"
              onClick={() => {
                dispatch(setAddonType(REGISTERED_AGENT));
                setShowAddonModal(false);
              }}
            >
              Add more states
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <RegisteredAgentSelectCancelState
        showCancelStateModal={showCancelStateModal}
        setShowCancelStateModal={setShowCancelStateModal}
      />
    </>
  );
};

export const RegisteredAgentCancel = ({
  showCancelModal,
  setShowCancelModal,
  cancelSubsId,
}: {
  showCancelModal: boolean;
  setShowCancelModal: (newState: React.SetStateAction<boolean>) => void;
  cancelSubsId: string;
}) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const stateName = getSelectedStates({ addonData })?.find(
    ({ group_subscription_id }) => group_subscription_id === cancelSubsId
  )?.ra_state_name;
  const entityId = useCurrentEntityId();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        groupId,
        subscriptionId: cancelSubsId,
        entityId,
      }).unwrap();
      setShowCancelModal(false);
      dispatch(setAddonType(""));
      successToast({ message: "Subscription cancelled successfully" });
    } catch (error: any) {
      setShowCancelModal(false);
      dispatch(setAddonType(""));
      alertToast({ message: error?.message || SOMETHING_WENT_WRONG });
    }
  };

  return (
    <Modal.Root
      open={showCancelModal}
      onOpenChange={() => {
        setShowCancelModal(false);
        dispatch(setAddonType(""));
      }}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel subscription</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            Are you sure you want to cancel this subscription? Your registered
            agent services will be stopped in {stateName}.
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowCancelModal(false);
              dispatch(setAddonType(""));
            }}
          >
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={handleCancelSubscription}
            isLoading={isLoading}
          >
            Cancel subscription
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export const RegisteredAgentSelectCancelState = ({
  showCancelStateModal,
  setShowCancelStateModal,
}: {
  showCancelStateModal: boolean;
  setShowCancelStateModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const dispatch = useDispatch();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelSubsId, setCancelSubsId] = useState("");

  return (
    <>
      <Modal.Root
        open={showCancelStateModal}
        onOpenChange={() => {
          setShowCancelStateModal(false);
          dispatch(setAddonType(""));
        }}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Cancel subscription</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Formik
            onSubmit={(values) => {
              setCancelSubsId(values?.toCancel);
              setShowCancelModal(true);
              setShowCancelStateModal(false);
            }}
            initialValues={{ toCancel: "" }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="all:unset">
                <Modal.Body>
                  <div className="t-text-body t-text-text-60 t-mb-6">
                    Please select the state for which you wish to cancel the
                    subscription:
                  </div>
                  <Radio.Root
                    onValueChange={(e) => {
                      setFieldValue("toCancel", e);
                    }}
                  >
                    <Radio.Content>
                      {getSelectedStates({ addonData })?.map((ele: any) => (
                        <Radio.Item
                          asChild
                          value={ele?.group_subscription_id}
                          key={ele?.group_subscription_id}
                        >
                          <div className="t-flex t-justify-between t-text-body t-text-text-100 t-w-full">
                            <div>{ele?.ra_state_name}</div>
                            <Price
                              amount={ele.amount}
                              size="medium"
                              cycle={MONTHLY}
                            />
                          </div>
                        </Radio.Item>
                      ))}
                    </Radio.Content>
                  </Radio.Root>
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-end t-gap-3">
                  <Button
                    type="button"
                    onClick={() => {
                      setShowCancelStateModal(false);
                      dispatch(setAddonType(""));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    isLoading={isSubmitting}
                    disabled={!values?.toCancel}
                  >
                    Continue
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Content>
      </Modal.Root>
      <RegisteredAgentCancel
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
        cancelSubsId={cancelSubsId}
      />
    </>
  );
};
