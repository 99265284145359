import {
  CurrentGroupContext,
  CurrentGroupContextT,
} from "contexts/CurrentGroupContext";
import { useContext } from "react";

export const useCurrentGroupContext = (): CurrentGroupContextT => {
  if (!CurrentGroupContext) {
    throw new Error("Only use inside CurrentGroupContext");
  }

  return useContext(CurrentGroupContext);
};
