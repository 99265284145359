import { http, HttpResponse } from "msw";
import { baseURL } from "store/apis/emptyApi";
import { MOCK_ENTITY_ID, MOCK_GROUP_ID } from "./group";

export const allRules = {
  data: {
    rules_data: [
      {
        name: "Bookeeepingvivi",
        uuid: "6aaa372f-2018-4fbc-b711-be6ee4c39bae",
        start_date: "2023-12-27",
        end_date: "2024-03-26",
        entity_id: "7b2b0e1b-1ea1-41b4-abb9-1cfa2f6d8bfe",
        apply_to_txns_with_no_category: true,
        apply_to_future_txns: true,
        category: {
          name: "New parent account test",
          uuid: "e1938650-a90b-48c8-a5c8-4b2a9b3d41ad",
        },
        conditions: [
          {
            uuid: "53805b56-6cff-4c04-befb-b728196e9170",
            condition_operand: {
              name: "Cashflow",
              uuid: "f212b119-175f-4c9b-aa35-9980c4743b8c",
            },
            condition_operator: {
              name: "Is",
              uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4",
            },
            value: "DEBIT",
            value_type: "CHOICE",
          },
        ],
      },
    ],
    total_pages: 1,
    current_page: 1,
    total_count: 1,
    per_page: 20,
  },
};

export const ruleEngineHandlers = [
  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/`,
    () => {
      return HttpResponse.json(allRules);
    }
  ),
];
