import { BALANCE_SHEET } from "constants/bookkeeping";
import { ReportsType } from "pages/Books/Reports/Reports";
import { createContext } from "react";

export type ReportAccordionContextType = {
  maxIndent?: number;
  reportType: ReportsType;
  accountingMethod?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  showNonZeroAccounts?: boolean;
  isLoading?: boolean;
};

export const ReportAccordionContext = createContext<ReportAccordionContextType>(
  {
    maxIndent: 3,
    reportType: BALANCE_SHEET,
  }
);
