import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { Seperator } from "components/icons/Chat/Seperator";
import { Chat } from "components/icons/LeftNav/Chat";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { ChangeEvent, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  ticketApis,
  useGetCustomerChatTicketsQuery,
} from "store/apis/chatTicket";
import {
  setSliderAccordionValue,
  setTicketSliderTab,
} from "store/slices/ticket";
import { ChatTicket } from "types/Models/chatTicket";
import { debounce } from "utils/debouncing";
import { TicketSlider } from "./TicketSlider";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";

const Payment = ({ ticket }: { ticket: ChatTicket }) => {
  const { close, isOpen, open: onPay } = useModal();
  const dispatch = useDispatch();

  const onTicketPaid = () => {
    close();
    dispatch(ticketApis.util.invalidateTags(["CustomerChatTickets"]));
  };

  const {
    is_priced_ticket,
    total_unpaid_invoice_amount,
    total_paid_invoice_amount,
    ticket_payments,
  } = ticket;

  const unpaid_invoices =
    ticket_payments && ticket_payments.length > 0
      ? ticket_payments
          .filter(
            (ticket_payment) =>
              !ticket_payment.is_ticket_paid &&
              ticket_payment.is_price_finalized &&
              ticket_payment.ticket_invoice
          )
          .map(({ ticket_invoice }) => {
            return ticket_invoice;
          })
          .filter(Boolean)
      : [];

  if (!is_priced_ticket) {
    return <>-</>;
  }

  if (Number(total_unpaid_invoice_amount) == 0) {
    return (
      <div className="t-text-text-30 t-text-body-sm">
        Paid <AmountSuperScript amount={Number(total_paid_invoice_amount)} />
      </div>
    );
  }

  if (
    total_unpaid_invoice_amount &&
    unpaid_invoices &&
    Number(total_unpaid_invoice_amount) > 0 &&
    unpaid_invoices!.length > 0
  ) {
    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onPay();
          }}
        >
          <span className="t-mr-1">Pay</span>
          <AmountSuperScript amount={Number(total_unpaid_invoice_amount)} />
        </Button>

        <CheckoutModal
          type="invoices"
          onClose={close}
          open={isOpen}
          // @ts-ignore
          invoices={unpaid_invoices}
          onInvoicePaid={onTicketPaid}
        />
      </span>
    );
  }

  return <></>;
};

export const TicketsList = ({
  resolved_status,
}: {
  resolved_status: boolean;
}) => {
  const { search } = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const { uuid: groupId, secondary_channel_url } = useCurrentGroupContext();
  const query = useQuery();
  const previewTicketId = query.get("ticketUuid");
  const dispatch = useDispatch();
  const { update } = useUpdateQuery();

  const {
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPrevPage,
    pageNum,
    setTotalPage,
  } = usePagination();

  const { data, isFetching, isSuccess } = useGetCustomerChatTicketsQuery(
    {
      group_id: groupId,
      page_num: pageNum,
      resolved_status,
      search_term: searchTerm,
    },
    { skip: !groupId }
  );

  const {
    tickets = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  useEffect(() => {
    if (total_pages) {
      setTotalPage(total_pages);
    }
  }, [total_pages]);

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
  });

  const isEmpty = tickets.length === 0;

  const createColumn = createColumnHelper<ChatTicket>();

  const date = resolved_status
    ? [
        createColumn.accessor("created_on", {
          size: 15,
          header: "created on",
          id: "created_on",
          cell: (info) => {
            return <>{dayjs(info.getValue()).format(DD_MMM_YYYY)}</>;
          },
        }),
        createColumn.accessor("resolved_at", {
          size: 15,
          header: "resolved on",
          id: "resolved_at",
          cell: (info) => {
            return <>{dayjs(info.getValue()).format(DD_MMM_YYYY)}</>;
          },
        }),
      ]
    : [
        createColumn.accessor("created_on", {
          size: 15,
          header: "created on",
          id: "created_on",
          cell: (info) => {
            return <>{dayjs(info.getValue()).format(DD_MMM_YYYY)}</>;
          },
        }),
      ];

  const updated = resolved_status
    ? []
    : [
        createColumn.accessor("does_contain_notes", {
          size: 15,
          header: "UPDATE",
          id: "does_contain_notes",
          cell: (info) => {
            const does_contain_notes = info.getValue();

            if (!does_contain_notes) {
              return "-";
            }

            return (
              <Button
                size="small"
                onClick={(e) => {
                  update({
                    query: "ticketUuid",
                    value: info.row.original.uuid,
                  });
                  dispatch(setSliderAccordionValue("UPDATES"));
                  e.stopPropagation();
                }}
              >
                Check updates
              </Button>
            );
          },
        }),
      ];

  const columns = [
    createColumn.accessor("ticket_title", {
      size: 45,
      id: "ticket_title",
      header: "TITLE",
      cell: (info) => {
        const ticket_title = info.getValue();
        const { name, entity } = info.row.original;

        return (
          <div className="t-flex t-flex-col">
            <div className="t-truncate t-max-w-72">{ticket_title}</div>
            <div className="t-flex t-items-center gap-2">
              <div className="t-text-text-30">{name}</div>
              {entity && (
                <>
                  <Seperator />
                  <ReactCountryFlag
                    style={{
                      width: "14px",
                      height: "10px",
                      marginBottom: "3px",
                    }}
                    countryCode={entity.code_alpha_2}
                    svg
                  />
                  <div className="t-text-text-30">{entity.name}</div>
                </>
              )}
            </div>
          </div>
        );
      },
    }),

    ...date,

    createColumn.accessor("is_priced_ticket", {
      size: 15,
      header: "PAY",
      id: "is_priced_ticket",
      cell: (info) => {
        return <Payment ticket={info.row.original} />;
      },
    }),

    createColumn.accessor("channel_url", {
      size: 10,
      header: "CHAT",
      id: "channel_url",
      cell: (info) => {
        return (
          <Button
            customType="ghost_icon"
            size="small"
            onClick={(e) => {
              update({
                query: "ticketUuid",
                value: info.row.original.uuid,
              });
              dispatch(setTicketSliderTab("CHAT"));
              e.stopPropagation();
            }}
          >
            <Chat />
          </Button>
        );
      },
    }),

    ...updated,
  ];

  const table = useReactTable({
    data: tickets || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <DashboardContainer>
      <DashboardContainer.Header className="t-sticky t-top-0 t-z-header t-bg-surface">
        <div className="t-bg-surface t-flex t-gap-5">
          <div className="t-w-1/2">
            <Search block onChange={handleSearch} placeholder="Search" />
          </div>

          <div className="t-ml-auto">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        <Async.Root {...{ isLoading: isFetching, isSuccess, isEmpty }}>
          <Async.Empty>
            <EmptyScreen text="No tickets found" />
          </Async.Empty>
          <Async.Success>
            <TableUI table={table}>
              {({ row }) => (
                <Table.Row
                  key={row.id}
                  onClick={() =>
                    update({ query: "ticketUuid", value: row.original.uuid })
                  }
                  className={classNames(
                    "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                    {
                      "t-bg-surface-lighter-grey":
                        previewTicketId === row.original.uuid,
                    }
                  )}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              )}
            </TableUI>
          </Async.Success>
        </Async.Root>
        <TicketSlider />
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
