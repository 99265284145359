import React from "react";
import StepCheckMark from "static/images/StepCheckMark.svg";
import TPStepEllipse from "static/images/TPStepEllipse.svg";
import TPCurrentStepEllipse from "static/images/TPCurrentStepEllipse.svg";
import classNames from "classnames";

const TransferSteps = ({ currentStep }: { currentStep: number }) => {
  return (
    <div>
      <div>
        <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
          <div>
            {currentStep > 1 ? (
              <img
                src={StepCheckMark}
                width="14px"
                height="14px"
                alt="current"
              />
            ) : currentStep === 1 ? (
              <img
                src={TPCurrentStepEllipse}
                width="14px"
                height="14px"
                alt="current"
              />
            ) : (
              <img
                src={TPStepEllipse}
                width="14px"
                height="14px"
                alt="notchecked"
              />
            )}
          </div>
          <div
            className={classNames("t-text-text-100", {
              "t-text-neutral-40": currentStep < 1,
            })}
          >
            Amount
          </div>
        </div>
        <div
          className={classNames(
            "t-ml-1.5 t-h-[56px] t-border t-border-b-0 t-border-l-2 t-border-r-0 t-border-t-0 t-border-solid  t-pl-[22px] t-text-body-sm t-text-text-100",
            {
              "t-border-purple-50": currentStep > 1,
              "t-border-purple-10": currentStep <= 1,
            }
          )}
        ></div>
      </div>
      <div>
        <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
          <div>
            {currentStep > 2 ? (
              <img
                src={StepCheckMark}
                width="14px"
                height="14px"
                alt="current"
              />
            ) : currentStep === 2 ? (
              <img
                src={TPCurrentStepEllipse}
                width="14px"
                height="14px"
                alt="current"
              />
            ) : (
              <img
                src={TPStepEllipse}
                width="14px"
                height="14px"
                alt="notchecked"
              />
            )}
          </div>
          <div
            className={classNames("t-text-text-100", {
              "t-text-neutral-40": currentStep < 2,
            })}
          >
            Purpose Code
          </div>
        </div>
        <div
          className={classNames(
            "t-ml-1.5 t-h-[56px] t-border t-border-b-0 t-border-l-2 t-border-r-0 t-border-t-0 t-border-solid  t-pl-[22px] t-text-body-sm t-text-text-100",
            {
              "t-border-purple-50": currentStep > 2,
              "t-border-purple-10": currentStep <= 2,
            }
          )}
        ></div>
      </div>
      <div>
        <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
          <div>
            {currentStep > 3 ? (
              <img
                src={StepCheckMark}
                width="14px"
                height="14px"
                alt="current"
              />
            ) : currentStep === 3 ? (
              <img
                src={TPCurrentStepEllipse}
                width="14px"
                height="14px"
                alt="current"
              />
            ) : (
              <img
                src={TPStepEllipse}
                width="14px"
                height="14px"
                alt="notchecked"
              />
            )}
          </div>
          <div
            className={classNames("t-text-text-100", {
              "t-text-neutral-40": currentStep < 3,
            })}
          >
            Review
          </div>
        </div>
        <div
          className={classNames(
            "t-ml-1.5 t-h-[56px] t-border t-border-b-0 t-border-l-2 t-border-r-0 t-border-t-0 t-border-solid  t-pl-[22px] t-text-body-sm t-text-text-100",
            {
              "t-border-purple-50": currentStep > 3,
              "t-border-purple-10": currentStep <= 3,
            }
          )}
        ></div>
      </div>
      <div>
        <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
          <div>
            <img
              src={TPStepEllipse}
              width="14px"
              height="14px"
              alt="notchecked"
            />
          </div>
          <div
            className={classNames("t-text-text-100", {
              "t-text-neutral-40": currentStep < 4,
            })}
          >
            Transfer Money
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferSteps;
