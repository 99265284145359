import { Button } from "components/DesignSystem/Button/Button";
import { Divider } from "components/design/Divider";
import { useDispatch } from "react-redux";
import {
  VentureCapitalist,
  useGetVCDataQuery,
  useGetVCSliderQuery,
} from "store/apis/vcCrm";
import { setEditable } from "store/slices/raiseCrm";
import LinkedInLogo from "static/images/LinkedInLogo.svg";
import { ConditionalLink } from "components/conditionalLink";
import { ReactNode } from "react";
import Loader from "components/design/loader";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useInvestorDetailQuery } from "store/apis/raiseProfiles";
import { setInvestorEditable } from "store/slices/investor";

const ViewItem = ({ title, value }: { title?: string; value?: ReactNode }) => {
  return (
    <div className="t-flex t-flex-col t-gap-1 t-w-full">
      <div className="t-text-body-sm t-text-30">{title}</div>
      <div className="t-text-subtext">{value || "-"}</div>
    </div>
  );
};

const ContactView = ({
  name,
  email,
  linkedin,
  index,
}: {
  name: string;
  email: string;
  linkedin?: string;
  index: number;
}) => {
  const { isCustomer } = useRoleBasedView();
  return (
    <div className="t-flex t-flex-col t-gap-2 t-w-full">
      <div className="t-text-subtext-sm">
        Contact {index > 0 && <>{index + 1}</>}
      </div>
      <div className="t-flex t-flex-col t-w-full t-gap-3">
        <div className="t-flex t-gap-3">
          <ViewItem title="Contact name" value={name} />
          {!isCustomer && <ViewItem title="Contact email" value={email} />}
        </div>
        <div className="t-flex t-flex-col t-gap-1 t-w-full">
          <div className="t-text-body-sm t-text-30">Contact LinkedIn</div>
          {linkedin ? (
            <ConditionalLink
              to={linkedin}
              className="t-flex t-items-center t-gap-2 t-text-subtext t-text-text-100 t-cursor-pointer hover:!t-underline hover:!t-text-purple"
            >
              <img src={LinkedInLogo} alt="logo" />
              {name}
            </ConditionalLink>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};

export const InvestorView = ({
  investorId,
  children,
}: {
  investorId: string;
  children?: (investorData?: VentureCapitalist) => ReactNode;
}) => {
  const dispatch = useDispatch();
  const { isCpa, isCustomer, isInvestor } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();

  let investorData: VentureCapitalist | undefined;

  const { data: crmVCData, isLoading } = useGetVCDataQuery(
    { vcId: investorId },
    { skip: !investorId || isCustomer }
  );

  const { data: vcData } = useGetVCSliderQuery(
    { groupId, vcIntroId: investorId },
    { skip: !investorId || !isCustomer || !groupId }
  );

  const { data: vcProfile } = useInvestorDetailQuery({}, { skip: !isInvestor });

  if (isInvestor) {
    investorData = vcProfile;
  } else if (isCustomer) {
    investorData = vcData?.vc;
  } else {
    investorData = crmVCData;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {children ? children(investorData) : <></>}
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <div className="t-border t-border-solid t-border-neutral-10 t-flex t-flex-col t-gap-4 t-rounded-lg t-my-5 t-mx-4 t-p-4 ">
          <div className="t-text-subtitle">Investor details</div>
          <Divider />
          <div className="t-flex t-flex-col t-gap-6">
            <ViewItem title="Name of investor" value={investorData?.name} />
            <div className="t-flex t-flex-col t-gap-6">
              {investorData?.contacts?.map(
                ({ name, email, linkedIn_url, uuid }, index) => (
                  <ContactView
                    key={uuid}
                    name={name}
                    email={email}
                    linkedin={linkedIn_url}
                    index={index}
                  />
                )
              )}
            </div>

            <ViewItem title="Type" value={investorData?.vc_type} />
            <ViewItem
              title="Website"
              value={
                <ConditionalLink
                  to={investorData?.website}
                  className="t-cursor-pointer hover:!t-underline t-text-purple"
                >
                  {investorData?.website}
                </ConditionalLink>
              }
            />
            <ViewItem title="Thesis" value={investorData?.notes} />
            <ViewItem title="Location" value={investorData?.location} />
            <ViewItem
              title="Min cheque size"
              value={investorData?.min_cheque}
            />
          </div>
        </div>
        {Boolean(isCpa || isInvestor) && (
          <div className="t-px-5 t-py-3 t-flex t-gap-2 t-sticky t-bottom-0 t-bg-white">
            <Button
              customType="primary"
              block
              onClick={() =>
                isInvestor
                  ? dispatch(setInvestorEditable(true))
                  : dispatch(setEditable(true))
              }
            >
              Edit
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
