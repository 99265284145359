/* eslint-disable no-unused-vars */
import { groupStage } from "apis/groupStage";
import "bootstrap/dist/css/bootstrap.min.css";
import { CrmTableDropdown } from "components/CrmTableDropdown/CrmTableDropdown";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Switch } from "components/DesignSystem/Switch/Switch";
import Modal from "components/Modal/Modal";
import { Divider } from "components/design/Divider";
import { Badge } from "components/design/badge";
import ConditionalToolTip from "components/design/conditionalToolTip";
import ToolTip from "components/design/toolTip";
import { PlusIcon } from "components/icons/PlusIcon";
import { PREINCORPORATION } from "constants/onBoarding";
import {
  NOT_CREATED,
  NOT_STARTED,
  NOT_STARTED_KEY,
} from "constants/taskStatus";
import { CRM_SUBSCRIPTION_TYPE } from "dictionaries";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "react-table";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import {
  useGetAllSalesCRMGroupsQuery,
  useUpdateGroupDataMutation,
} from "store/apis/salesCrm";
import { getTags } from "../../apis/getGroupTags";
import { SOMETHING_WENT_WRONG } from "../../constants/apiCallError";
import "../../static/styles/components/addTeamMember.css";
import "../../static/styles/components/salesCrm.css";
import CommonTable from "../design/commonTable";
import Loader from "../design/loader";
import { SalesPerson } from "../serviceTeam/SalesPerson";
import TagCreation from "../tagCreation/tagCreation";
import AddCrmEntityTableModal from "./AddCrmEntityTableModal";
import { Header } from "./Header";
import NewGroupCreationModal from "./NewGroupEntityModal";
import QuoteModal from "./QuoteModal";
import ReferrerModal from "./ReferrerModal";
import { useGetAllTaskStatesQuery } from "store/apis/taskTags";

export default function SalesCrm() {
  usePageTitle("Sales CRM");
  const { alertToast } = useToast();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const { isAdmin } = useRoleBasedView();
  const [showTagModal, setShowTagModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const pageNumber = Number(query.get("page") || 1);
  const [pageCount, setPageCount] = useState(1);
  const searchTerm = query.get("search_term") || null;
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [searchPageCount, setSearchPageCount] = useState(1);
  const [load, setLoad] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [stages, setStages] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [crmStages, setCrmStages] = useState([]);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [tags, setTags] = useState([]);
  const currentPageItemCount = groups?.length || 0;
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [userDataPayload, setUserDataPayLoad] = useState();
  const [groupName, setGroupName] = useState("");
  const [userGroupId, setUserGroupId] = useState("");
  const [showAddAttributionModal, setShowAddAttributionModal] = useState(false);
  const [payWall, setPayWall] = useState(null);
  const [booksPayWall, setBooksPayWall] = useState(null);
  const [perks, setPerks] = useState(null);
  const [showMoveToOpsModal, setShowMoveToOpsModal] = useState(false);
  const viewFilter = "SALES_CRM";
  const selectedTaskState = query.get("task_state_filter_uuids") || null;
  const [taskStates, setTaskStates] = useState([]);
  const [attributionType, setAttributionType] = useState("");
  const [generateReport, setGenerateReport] = useState(false);
  const { data: taskStatesData = [] } = useGetAllTaskStatesQuery({});

  useEffect(() => {
    getTags().then((res) => {
      setTags(res.data.data);
    });

    setTaskStates([
      ...taskStatesData,
      { name: NOT_STARTED, uuid: NOT_STARTED_KEY },
      { name: "Not Created", uuid: NOT_CREATED },
    ]);
  }, [showTagModal]);

  const { data: assignMembers = [] } = useGetAssignAgentsQuery(null, {
    skip: !isAdmin,
  });

  const {
    data: group,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllSalesCRMGroupsQuery({
    pageNumber: pageNumber,
    searchTerm,
    viewFilter: viewFilter,
    taskState: selectedTaskState,
    generate_report: generateReport,
  });

  const [updateGroup, { data: patchedResp, isLoading: isPatchBtnLoading }] =
    useUpdateGroupDataMutation();

  const {
    company_groups = [],
    total_pages = 1,
    total_count = 1,
    current_page = 1,
    per_page = 25,
  } = group || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      pageNumber < total_pages ? pageNumber : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNumber < total_pages) {
      update({ query: "page", value: pageNumber + 1 });
    }
  };
  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  useEffect(() => {
    if (generateReport && group?.download_url) {
      window.open(group.download_url);
      setGenerateReport(false);
    }
  }, [group?.download_url]);

  useEffect(() => {
    getAllSaleCrm();
  }, [total_pages, total_count, isFetching, group]);

  useEffect(() => {
    getTags().then((res) => {
      setTags(res.data.data);
    });
  }, [showTagModal]);

  useEffect(() => {
    const getStages = async () => {
      let viewFilter = "SALES_CRM";
      const { data } = await groupStage({ viewFilter });
      setStages(data);
    };
    const getAllStages = async () => {
      const { data } = await groupStage({});
      setAllStages(data);
    };
    const getCrmStages = async () => {
      let viewFilter = "OPS_CRM";
      const { data } = await groupStage({ viewFilter });
      setCrmStages(data);
    };
    if (isAdmin) {
      getStages();
      getAllStages();
      getCrmStages();
    }
  }, []);

  const updateCompanyStage = useCallback(
    (groupId, companyStage) => {
      setGroups((groups) =>
        crmStages.some((stages) => stages.stage_text === companyStage)
          ? groups.filter((group) => group.uuid !== groupId)
          : groups.map((group) =>
              group.uuid === groupId ? { ...group, stage: companyStage } : group
            )
      );
    },
    [crmStages]
  );

  const getAllSaleCrm = async () => {
    try {
      setGroups(company_groups);
      setPageCount(total_pages);
    } catch (error) {
      alertToast({ message: SOMETHING_WENT_WRONG });
    }
  };

  const approveHandler = (groupId) => {
    setShowModal(true);
    setUserGroupId(groupId);
  };

  const rejectHandler = (groupId) => {
    setShowRejectModal(true);
    setUserGroupId(groupId);
  };

  const referralModalHandler = (groupId, type) => {
    setUserGroupId(groupId);
    setAttributionType(type);
    setShowAddAttributionModal(true);
  };

  const approveUser = async () => {
    try {
      await updateGroup({
        payLoad: { accept_group: true },
        groupId: userGroupId,
      }).unwrap();
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowModal(false);
  };

  const rejectUser = async () => {
    try {
      await updateGroup({
        payLoad: { accept_group: false },
        groupId: userGroupId,
      }).unwrap();
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowRejectModal(false);
  };

  const moveToOps = async () => {
    try {
      await updateGroup({
        payLoad: { crm_type: "OPS_CRM" },
        groupId: userGroupId,
      }).unwrap();
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowMoveToOpsModal(false);
  };

  useEffect(() => {
    if (perks !== null) {
      const updatePerks = async () => {
        try {
          await updateGroup(
            {
              payLoad: { is_perk_redemption_allowed: perks },
              groupId: userGroupId,
            },
            {
              skip: perks,
            }
          ).unwrap();
          setPerks(null);
        } catch (err) {
          alertToast({ message: err?.data?.error?.message });
        }
      };
      updatePerks();
    }
  }, [perks]);

  useEffect(() => {
    if (payWall !== null) {
      const updatePayWall = async () => {
        try {
          await updateGroup(
            {
              payLoad: { is_subscription_required: payWall },
              groupId: userGroupId,
            },
            {
              skip: payWall,
            }
          ).unwrap();
          setPayWall(null);
        } catch (err) {
          alertToast({ message: err?.data?.error?.message });
        }
      };
      updatePayWall();
    }
  }, [payWall]);

  useEffect(() => {
    if (booksPayWall !== null) {
      const updateBooksPayWall = async () => {
        try {
          await updateGroup(
            {
              payLoad: { is_books_subscription_required: booksPayWall },
              groupId: userGroupId,
            },
            {
              skip: booksPayWall,
            }
          ).unwrap();
          setBooksPayWall(null);
        } catch (err) {
          alertToast({ message: err?.data?.error?.message });
        }
      };
      updateBooksPayWall();
    }
  }, [booksPayWall]);

  const onDebtWallChange = (groupId) => async (checked) => {
    try {
      await updateGroup({
        payLoad: { pending_dues_allowed: !checked },
        groupId: groupId,
      });
    } catch (err) {
      alertToast({ message: err?.data?.error?.message });
    }
  };

  const pre = true;

  const columns = useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "group_name",
        width: "20%",
        textAlign: "left",
        Cell: ({ row }) => (
          <>
            {row.original?.name ? (
              <span className="t-flex t-max-w-[210px] t-gap-2 t-truncate">
                <ToolTip side="top" text={row.original?.name}>
                  <Link
                    className="startTask"
                    to={`/admin/crm/${row.original.uuid}`}
                    target="_blank"
                    aria-hidden={true}
                  >
                    {row.original?.name}
                  </Link>
                </ToolTip>
                {row.original.subscription_name &&
                  row.original.subscription_name !== "NOT_STARTED" && (
                    <Badge
                      size="small"
                      color={
                        CRM_SUBSCRIPTION_TYPE[row.original.subscription_name]
                      }
                    >
                      {row.original.subscription_name?.[0]}
                    </Badge>
                  )}
                {row.original?.is_created_from_crm && (
                  <ToolTip text="Group created from Inkle">
                    <div className="t-flex t-h-6 t-w-5 t-items-center t-justify-center t-rounded t-bg-purple-50 t-align-middle t-text-subtitle t-text-white">
                      i
                    </div>
                  </ToolTip>
                )}
                {row.original?.founder?.user_type === PREINCORPORATION && (
                  <ToolTip text="Pre-incorporation user">
                    <div className="t-rounded t-px-2 t-py-1 t-text-subtext-sm t-text-blue-70 t-bg-blue-10">
                      Pre
                    </div>
                  </ToolTip>
                )}
              </span>
            ) : (
              <span aria-hidden={true}>{row?.original?.name}</span>
            )}
          </>
        ),
      },
      {
        Header: "Lead Founder",
        accessor: "founder",
        width: "13%",
        Cell: ({ value }) => {
          const emptyName = !value?.first_name && !value?.last_name && "-";
          return (
            <ConditionalToolTip condition={value?.email}>
              <span className="t-flex t-max-w-[210px] t-truncate">
                <span>
                  {value?.first_name || ""} {value?.last_name || ""}
                  {emptyName}
                </span>
              </span>
            </ConditionalToolTip>
          );
        },
      },
      {
        Header: "Sales Person",
        width: "19%",
        accessor: "sales_stage",
        textAlign: "center",
        Cell: ({ row }) => {
          const { sales_person, uuid } = row.original;
          return <SalesPerson assignedAgent={sales_person} groupId={uuid} />;
        },
      },
      {
        Header: "Referrer",
        width: "11%",
        accessor: "referer",
        textAlign: "left",
        Cell: ({ row }) => {
          const attribuionExists =
            row.original?.referer?.email ||
            row.original?.coupon_code ||
            row.original?.referer_text;
          return (
            <span>
              {attribuionExists ? (
                row.original?.referer?.email ? (
                  <ToolTip side="top" text={row.original?.referer?.event}>
                    <span className="t-block t-max-w-[210px] t-truncate">
                      {row.original?.referer?.email}
                    </span>
                  </ToolTip>
                ) : (
                  <span className="t-block t-max-w-[210px] t-truncate">
                    {row.original?.coupon_code || row.original?.referer_text}
                  </span>
                )
              ) : (
                <>
                  <DropDown.Root>
                    <DropDown.Trigger asChild>
                      <div className="t-flex t-h-8 t-w-8 t-rounded t-p-2 hover:t-bg-i-surface-grey">
                        <PlusIcon color="currentColor" />
                      </div>
                    </DropDown.Trigger>
                    <DropDown.Portal>
                      <DropDown.Content className="t-w-[180px] t-py-2">
                        <DropDown.Item
                          onClick={() =>
                            referralModalHandler(row.original?.uuid, "COUPON")
                          }
                        >
                          Add coupon
                        </DropDown.Item>
                        <DropDown.Item
                          onClick={() =>
                            referralModalHandler(row.original?.uuid, "CUSTOMER")
                          }
                        >
                          Add customer
                        </DropDown.Item>
                        <DropDown.Item
                          onClick={() =>
                            referralModalHandler(row.original?.uuid, "TEXT")
                          }
                        >
                          Add text
                        </DropDown.Item>
                      </DropDown.Content>
                    </DropDown.Portal>
                  </DropDown.Root>
                </>
              )}
            </span>
          );
        },
      },
      {
        Header: "Status",
        width: "10%",
        accessor: "status",
        textAlign: "left",
        Cell: ({ row }) => {
          const { is_restricted, is_rejected, uuid } = row.original;
          if (is_rejected) {
            return <img src={CrossRed} alt="rejected" />;
          }
          if (is_restricted) {
            return (
              <div className="t-flex t-gap-2">
                <Button customType="icon" onClick={() => approveHandler(uuid)}>
                  <img src={GreenTick} alt="approve" />
                </Button>
                <Button customType="icon" onClick={() => rejectHandler(uuid)}>
                  <img src={CrossRed} alt="reject" />
                </Button>
              </div>
            );
          }
          if (!is_restricted && !is_rejected) {
            return <img src={GreenTick} alt="approved" />;
          }
        },
      },
      {
        Header: "Quote",
        width: "5%",
        accessor: "last_quote_sent",
        Cell: ({ row }) => {
          const { uuid, last_quote_sent } = row.original;
          return (
            <QuoteModal userGroupId={uuid} last_quote_sent={last_quote_sent} />
          );
        },
      },
      {
        Header: "Tax Pay Wall",
        width: "7.5%",
        accessor: "is_subscription_required",
        Cell: ({ row }) => {
          const { is_subscription_required, uuid } = row.original;
          return (
            <Switch
              defaultChecked={is_subscription_required}
              size="small"
              name="paywallSwitch"
              onCheckedChange={() => {
                setUserGroupId(uuid);
                setPayWall(!is_subscription_required);
              }}
            />
          );
        },
      },
      {
        Header: "Books Pay Wall",
        width: "7.5%",
        accessor: "is_books_subscription_required",
        Cell: ({ row }) => {
          const { is_books_subscription_required, uuid } = row.original;
          return (
            <Switch
              defaultChecked={is_books_subscription_required}
              size="small"
              name="booksPaywallSwitch"
              onCheckedChange={() => {
                setUserGroupId(uuid);
                setBooksPayWall(!is_books_subscription_required);
              }}
            />
          );
        },
      },
      {
        Header: "Perks",
        width: "5%",
        accessor: "is_perk_redemption_allowed",
        Cell: ({ row }) => {
          const { is_perk_redemption_allowed, uuid } = row.original;
          return (
            <Switch
              defaultChecked={is_perk_redemption_allowed}
              size="small"
              name="perkSwitch"
              onCheckedChange={() => {
                setUserGroupId(uuid);
                setPerks(!is_perk_redemption_allowed);
              }}
            />
          );
        },
      },
      {
        Header: "",
        id: "threedots",
        size: "5%",
        Cell: (info) => {
          const { uuid, name } = info.row.original;
          return (
            <CrmTableDropdown
              actionOn="group"
              uuid={uuid}
              showMoveToOpsOption
            />
          );
        },
      },
    ],
    [allStages, updateCompanyStage, tags, groups]
  );

  const data = useMemo(() => groups, [groups]);

  function getGroups(groups) {
    setGroups(groups);
  }

  const handleClose = () => {
    setShowTagModal(false);
  };

  return (
    <>
      <div className="addTeamMemberContent">
        <div className="form">
          <div className="t-flex">
            <Header
              setShowModal={setShowProfileForm}
              searchedTerm={searchTerm}
              taskStates={taskStates}
              selectedTaskState={selectedTaskState}
              update={update}
              exportCsv={() => setGenerateReport(true)}
              isDownloadingCsv={isFetching}
            />
          </div>

          {isLoading || !isSuccess ? (
            <Loader />
          ) : (
            <div className="addTeamMemberContentBody">
              <Modal.Root
                open={showModal}
                onOpenChange={() => setShowModal(false)}
              >
                <Modal.Portal>
                  <Modal.Overlay />
                  <Modal.Content>
                    <Modal.Close />
                    <Modal.Title>
                      <span className="t-text-subtitle">Approve the user?</span>
                    </Modal.Title>
                    <Divider />
                    <div className=" t-py-6 t-align-middle t-text-button t-leading-4">
                      Are you sure that you want to approve the user?
                    </div>
                    <div className="t-flex t-justify-end t-gap-3">
                      <Button onClick={() => setShowModal(false)}>
                        Cancel
                      </Button>
                      <Button
                        customType="primary"
                        isLoading={isPatchBtnLoading}
                        onClick={approveUser}
                      >
                        Approve
                      </Button>
                    </div>
                  </Modal.Content>
                </Modal.Portal>
              </Modal.Root>

              <Modal.Root
                open={showRejectModal}
                onOpenChange={() => setShowRejectModal(false)}
              >
                <Modal.Portal>
                  <Modal.Overlay />
                  <Modal.Content>
                    <Modal.Close />
                    <Modal.Title>
                      <span className="t-text-subtitle">Reject the user?</span>
                    </Modal.Title>
                    <Divider />
                    <div className=" t-py-6 t-align-middle t-text-button t-leading-4">
                      Are you sure that you want to reject the user?
                    </div>
                    <div className="t-flex t-justify-end t-gap-3">
                      <Button onClick={() => setShowModal(false)}>
                        Cancel
                      </Button>
                      <Button
                        customType="primary"
                        isLoading={isPatchBtnLoading}
                        onClick={rejectUser}
                      >
                        Reject
                      </Button>
                    </div>
                  </Modal.Content>
                </Modal.Portal>
              </Modal.Root>

              <Modal.Root
                open={showMoveToOpsModal}
                onOpenChange={() => setShowMoveToOpsModal(false)}
              >
                <Modal.Portal>
                  <Modal.Overlay />
                  <Modal.Content>
                    <Modal.Close />
                    <Modal.Title>
                      <span className="t-text-subtitle">
                        Move the group to Ops?
                      </span>
                    </Modal.Title>
                    <Divider />
                    <div className=" t-py-6 t-align-middle t-text-button t-leading-4">
                      Are you sure that you want to move the group to Ops CRM?
                    </div>
                    <div className="t-flex t-justify-end t-gap-3">
                      <Button onClick={() => setShowMoveToOpsModal(false)}>
                        No
                      </Button>
                      <Button
                        customType="primary"
                        isLoading={isPatchBtnLoading}
                        onClick={moveToOps}
                      >
                        Yes
                      </Button>
                    </div>
                  </Modal.Content>
                </Modal.Portal>
              </Modal.Root>

              <ReferrerModal
                groups={groups}
                pageNumber={pageNumber}
                searchTerm={searchTerm}
                viewFilter={viewFilter}
                showAddAttributionModal={showAddAttributionModal}
                setShowAddAttributionModal={setShowAddAttributionModal}
                updateGroup={updateGroup}
                groupId={userGroupId}
                attributionType={attributionType}
              />

              {showProfileForm && (
                <NewGroupCreationModal
                  showModal={showProfileForm}
                  setShowModal={setShowProfileForm}
                  setShowEntityModal={setShowEntityModal}
                  setUserDataPayLoad={setUserDataPayLoad}
                  userDataPayload={userDataPayload}
                  setGroupName={setGroupName}
                />
              )}
              {showEntityModal && (
                <AddCrmEntityTableModal
                  setShowGroupModal={setShowProfileForm}
                  showModal={showEntityModal}
                  setModalShow={setShowEntityModal}
                  userDataPayload={userDataPayload}
                  groupName={groupName}
                />
              )}
              <div className="addTeamMemberSelectTable">
                {searchTerm?.length > 2 ? (
                  <CommonTable
                    tableData={data}
                    tableDataDependencies={[data]}
                    tableColumns={columns}
                    tableColumnsDependencies={[columns]}
                    setPageNumber={setSearchPageNumber}
                    pageCount={searchPageCount}
                    path="salescrm"
                  />
                ) : (
                  <CommonTable
                    tableData={data}
                    tableDataDependencies={[data]}
                    tableColumns={columns}
                    tableColumnsDependencies={[columns]}
                    pageCount={pageCount}
                    initialPage={pageNumber}
                    path="salescrm"
                  />
                )}
              </div>
              <div className="t-p-4">
                <Pagination
                  {...paginationData}
                  goToFirstPage={goToFirstPage}
                  goToPrevPage={goToPrevPage}
                  goToNextPage={goToNextPage}
                  goToLastPage={goToLastPage}
                />
              </div>
            </div>
          )}
        </div>
        <TagCreation show={showTagModal} handleClose={handleClose} />
      </div>
    </>
  );
}
