export const identify = (payload) => {
  if (window.analytics?.identify) {
    window.analytics.identify(payload.email, payload);
    if (process.env.NODE_ENV === "development") {
      console.log("GTM::IDENTITY", payload);
    }
  }

  if (window.dataLayer) {
    window.dataLayer.push({ ...payload, event: "PUSH_IDENTITY" });

    if (process.env.NODE_ENV === "development") {
      console.log("GTM::DATA_LAYER::IDENTITY", payload);
    }
  }
};

export const track = (event, payload) => {
  if (window.analytics?.track) {
    window.analytics.track(event, payload);
    if (process.env.NODE_ENV === "development") {
      console.log(`GTM::TRACK:${event}`, payload);
    }
  }
};

export const postConversionToReditus = ({ email }) => {
  if (window.gr && typeof window.gr === "function") {
    window.gr("track", "conversion", { email });
  }
};
