import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { BankLogos } from "dictionaries";
import { useDispatch, useSelector } from "react-redux";
import { closeBillingInvoiceSlider } from "store/slices/billingInvoiceSlider";
import { RootState } from "store/store";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { SOURCE_BANK, SOURCE_TITLE } from "constants/invoiceSource";

export const RefundDetails = () => {
  const dispatch = useDispatch();
  const { showBillingSlider, invoice } = useSelector(
    (store: RootState) => store.billingInvoiceSlider
  );

  return (
    <>
      {invoice?.refund_data?.map((refundDetails) => {
        const cardDetail = refundDetails.card_details;
        const cardBrand = BankLogos.hasOwnProperty(cardDetail?.brand)
          ? BankLogos[cardDetail.brand]
          : BankLogos["default"];
        const refundToBank = refundDetails.destination === SOURCE_BANK;

        return (
          <div key={refundDetails.uuid}>
            <div className="t-py-4 t-px-6 t-flex t-gap-6 t-flex-col t-mt-3 ">
              <InfoItem label="Refund amount">
                <AmountSuperScript amount={refundDetails.amount} />
              </InfoItem>
              <InfoItem label="Refund reason">{refundDetails.reason}</InfoItem>
              <InfoItem label="Refund to">
                {SOURCE_TITLE[refundDetails.destination]}
              </InfoItem>
              <InfoItem label="Refund initiated on">
                {dayjs(refundDetails.refund_created_date).format(DD_MMM_YYYY)}
              </InfoItem>
              <InfoItem label="Adjusted total">
                <AmountSuperScript
                  amount={Number(invoice.refund_adjusted_amount)}
                />
              </InfoItem>

              {refundToBank && (
                <InfoItem label="Payment Method">
                  <div className="t-text-subtext t-text-text-100 t-flex t-items-center t-gap-0.5">
                    <img className="t-h-5" src={cardBrand} alt="cardBrand" />
                    **** {cardDetail.last_four}
                  </div>
                </InfoItem>
              )}
            </div>

            <div
              className={classNames({
                "t-px-5 t-py-3 t-bg-surface t-shadow-tasks-slider-bottom t-fixed t-bottom-0 t-w-[480px]":
                  showBillingSlider,
              })}
            >
              <Button
                customType="secondary"
                block
                onClick={() => dispatch(closeBillingInvoiceSlider())}
              >
                Done
              </Button>
            </div>
          </div>
        );
      })}
    </>
  );
};
