export const REDIRECT = "redirect";
export const SAME_MODAL = "sameModal";
export const FAQS = "FAQs";
export const WHATSNEW = "See what’s new?";
export const CHAT = "Chat";
export const MEETING_REQUEST = "Request a meeting";
export const DEFAULT_TITLE = "What can we help you with?";
export const SEE_WHATS_NEW = "See what’s new";
export const WHATSAPP = "WhatsApp";
export const PHONE = 16282774045;
export const CONTACT_US = "Contact us";
export const EMAIL = "Send us an email";
