import { ConditionalLink } from "components/conditionalLink";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { InfoSolid } from "components/InfoSolid";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useUploadReconcileCSVMutation } from "store/apis/reconciliation";
import { mixed, object, string } from "yup";
import { CsvColumnsMap } from "./CsvColumnsMap";
import { FileInput } from "components/FileInput/FileInput";

export const Reconcile = ({
  isOpen,
  onClose,
  bankAccountId,
}: {
  isOpen: boolean;
  onClose: () => void;
  bankAccountId: string;
}) => {
  const { alertToast } = useToast();
  const {
    isOpen: isCsvColumnsMapModalOpen,
    close: csvColumnsMapModalClose,
    open,
  } = useModal();
  const [uploadReconcileCSV, { data: uploadedCsvData }] =
    useUploadReconcileCSVMutation();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: ledger } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const { accounts = [] } = ledger || {};

  const reconcilieBank = accounts.find(
    ({ account: { uuid } }) => uuid === bankAccountId
  )?.account;

  const onSubmit = async (values: {
    reconcilation_ending_date: string;
    customer_statement_ending_balance: string;
    customer_statement_starting_balance: string;
    csv?: File;
  }) => {
    if (values.csv) {
      try {
        await uploadReconcileCSV({
          groupId,
          entityId,
          payload: {
            entity_bank_account_id: reconcilieBank?.uuid!,
            csv: values.csv,
            reconcilation_ending_date: dayjs(values.reconcilation_ending_date)
              .endOf("month")
              .format("MM-DD-YYYY"),
            customer_statement_ending_balance:
              values.customer_statement_ending_balance,
            customer_statement_starting_balance:
              values.customer_statement_starting_balance,
          },
        }).unwrap();
        open();
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  const reconciliationStartDate = reconcilieBank?.last_reconciled_date
    ? dayjs(reconcilieBank?.last_reconciled_date)
    : dayjs(reconcilieBank?.first_transaction_date);

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Formik
          initialValues={{
            reconcilation_ending_date: "",
            customer_statement_ending_balance: "",
            // @ts-ignore
            csv: "",
            customer_statement_starting_balance:
              reconcilieBank?.last_reconcilation_status === "COMPLETED"
                ? reconcilieBank.last_reconciled_balance || ""
                : "",
          }}
          onSubmit={onSubmit}
          validationSchema={object({
            reconcilation_ending_date: string().required("Please select date"),
            customer_statement_ending_balance: string().required(
              "Add statement ending balance"
            ),
            csv: mixed().required("File is required"),
          })}
          validateOnMount
          validateOnChange
        >
          {({
            values: { csv },
            setFieldValue,
            isValid,
            isSubmitting,
            submitForm,
          }) => (
            <Form className="t-m-0 t-w-full">
              <Modal.Content>
                <Modal.Header>
                  <div className="t-w-full">
                    <Modal.Title>Reconciliation</Modal.Title>
                  </div>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-gap-4 t-flex-col t-w-full">
                  {Boolean(reconcilieBank?.last_reconciled_date) ? (
                    <p className="t-text-body t-text-text-30 t-m-0">
                      Last reconciled until:{" "}
                      <span className="t-text-text-100">
                        {dayjs(reconcilieBank?.last_reconciled_date).format(
                          DD_MMM_YYYY
                        )}
                      </span>
                    </p>
                  ) : (
                    <p className="t-text-body t-text-text-30 t-m-0">
                      Starting date:{" "}
                      <span className="t-text-text-100">
                        {reconciliationStartDate.format(DD_MMM_YYYY)}
                      </span>
                    </p>
                  )}
                  <TextInput
                    name="bank_account"
                    label="Bank account"
                    disabled
                    value={`${reconcilieBank?.nickname} - ${reconcilieBank?.mask}`}
                  />
                  <PriceInput
                    disabled={Boolean(reconcilieBank?.last_reconciled_balance)}
                    name="customer_statement_starting_balance"
                    label="Enter statement starting balance on the above date"
                  />

                  <Field name="reconcilation_ending_date">
                    {({ field }: FieldProps) => {
                      return (
                        <DateInput
                          block
                          showMonthYearPicker
                          maxDate={
                            new Date(
                              dayjs()
                                .set("month", dayjs().month() - 1)
                                .format("MM-DD-YYYY")
                            )
                          }
                          minDate={
                            new Date(
                              reconciliationStartDate.format("MM-DD-YYYY")
                            )
                          }
                          dateFormat="dd-MMM-yyyy"
                          {...field}
                          value={
                            field.value &&
                            dayjs(field.value)
                              .endOf("month")
                              .format(DD_MMM_YYYY)
                          }
                          label={
                            <span className="t-w-full t-flex t-items-center t-justify-center t-gap-1">
                              <span>Select the next ending date</span>
                              <ConditionalToolTip condition="This is the date until which you want transactions from bank statement to be reconciled with transactions in Inkle Books.">
                                <span className="t-text-text-30">
                                  <InfoSolid size="14" />
                                </span>
                              </ConditionalToolTip>
                            </span>
                          }
                          placeholder="MM-YYYY"
                        />
                      );
                    }}
                  </Field>
                  <PriceInput
                    name="customer_statement_ending_balance"
                    label="Enter statement ending balance on the above date"
                  />
                  <div>
                    <FileInput
                      name="csv"
                      withForm
                      onDelete={() => setFieldValue("csv", null)}
                      label="Upload bank statement in .CSV format"
                      file={
                        csv && {
                          name: csv.name,
                          file_type: "CSV",
                          is_previewable: false,
                          uuid: "",
                        }
                      }
                      accept={{ "text/csv": [".csv"] }}
                    />
                    <p className="t-m-0 t-text-body-sm t-mt-3">
                      <ConditionalLink
                        to="https://docs.google.com/spreadsheets/d/e/2PACX-1vRPFV01D9Tk2KWIjqXHeu1aSCMUq-bVOCY93LvfEXphj2A_jFWy7Xiy5MWg_7ZE9cI6lksThNGjZejA/pub?output=csv"
                        className="t-text-purple"
                      >
                        Click here
                      </ConditionalLink>{" "}
                      to see the supported format for bank statement
                    </p>

                    <div className="t-p-2 t-rounded t-border-blue-70 t-border-[0.5px] t-border-solid t-bg-blue-10 t-text-body-sm t-text-text-60 t-my-3 t-flex t-gap-1 t-items-start">
                      <span className="t-flex t-text-blue t-mt-0.5">
                        <InfoFilledSmall color="currentColor" />
                      </span>
                      <p className="t-m-0 t-text-text-100">
                        Please ensure the uploaded bank statement starts from or
                        before the last reconciled date:{" "}
                        {reconciliationStartDate.format(DD_MMM_YYYY)}
                      </p>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="t-flex t-items-center t-justify-end t-gap-3">
                  <Button
                    type="reset"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={submitForm}
                  >
                    Next
                  </Button>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          )}
        </Formik>
      </Modal.Root>
      <CsvColumnsMap
        isOpen={isCsvColumnsMapModalOpen}
        onClose={csvColumnsMapModalClose}
        uploadedCsvData={uploadedCsvData}
      />
    </>
  );
};
