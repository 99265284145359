import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import CoinVertical from "static/images/CoinVertical.svg";
import InfoGray from "static/images/InfoGray.svg";

export const IntragroupServiceLaunchBody = ({
  amount,
  description,
  seasons,
  defaultTaskTemplate,
}: {
  amount: string;
  description: string;
  seasons: string[];
  defaultTaskTemplate: string;
}) => {
  const { entities } = useCurrentGroupContext();

  return (
    <div className="t-flex t-gap-4 t-flex-col t-w-full">
      <div className="t-flex t-w-full t-gap-4">
        <SelectDropDown label="Entity" name="entity" block>
          <option key="select" value="">
            Select entity
          </option>
          {entities?.map((element) => {
            return (
              <option key={element.uuid} value={element.uuid}>
                {element.name}
              </option>
            );
          })}
        </SelectDropDown>
        {!defaultTaskTemplate && (
          <SelectDropDown label="Season" name="season" block>
            <option value="">Select season</option>
            {seasons.map((year) => {
              return (
                <option value={year} key={year}>
                  {year}
                </option>
              );
            })}
          </SelectDropDown>
        )}
      </div>
      <div className="t-flex t-flex-col t-gap-1 t-mt-2">
        <div className="t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
          <img height="15" className="t-mr-2" src={CoinVertical} alt="coin" />
          PRICE
        </div>
        <div className="t-text-subtitle-sm t-text-text-100">${amount}</div>
      </div>
      <div className="t-flex t-flex-col t-gap-1">
        <div className="t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
          <img height="15" className="t-mr-2" src={InfoGray} alt="coin" />
          ABOUT
        </div>
        <div
          className="t-text-body-sm t-text-text-100 t-font-light"
          dangerouslySetInnerHTML={{
            __html:
              description?.length > 200
                ? description.substring(0, 160) + "....."
                : description,
          }}
        ></div>
      </div>
    </div>
  );
};
