import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { FC } from "react";
import ShareNetwork from "static/images/ShareNetwork.svg";
import { useUploadCompletedTaskFileMutation } from "store/apis/task";
import { Merchant } from "types/Models/merchant";
import ToolTip from "./design/toolTip";
import { Button } from "./DesignSystem/Button/Button";
import { FileInput, FileType } from "./FileInput/FileInput";
import { DocumentPreviewModal } from "./PreviewModal";
import { WSeriesFormSendCopy } from "./WSeriesFormSendCopy";

interface IForm1099FilesProps {
  merchant: Merchant;
  taskId: string;
  groupId: string;
  updateMerchant: (merchant: Merchant) => void;
  editable: boolean;
  isSuccessPage: boolean;
}

export const Form1099Files: FC<IForm1099FilesProps> = ({
  merchant,
  taskId,
  groupId,
  updateMerchant,
  editable,
  isSuccessPage,
}) => {
  const { isCustomer } = useRoleBasedView();
  const openPreview = useDocPreview();
  const {
    open: openSendCopyModal,
    close: closeSendCopyModal,
    isOpen: showSendCopyModal,
  } = useModal();

  const [uploadDoc, { isLoading: isUploading, originalArgs }] =
    useUploadCompletedTaskFileMutation();
  const { alertToast } = useToast();

  const onDrop = async ({
    form1099DoucmentType,
    newFiles,
  }: {
    newFiles: FileType[];
    form1099DoucmentType: string;
  }) => {
    try {
      const file = newFiles[0];
      const fileToSign =
        "uuid" in file! ? { file_id: file.uuid } : { file: file };
      await uploadDoc({
        task_id: taskId,
        group_id: groupId,
        txn_merchant_id: merchant.uuid,
        form_1099_document_type: form1099DoucmentType,
        ...fileToSign,
      }).unwrap();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <div className="t-flex t-items-center t-gap-3 t-group">
      <div className="t-flex t-gap-2 t-items-center">
        <ToolTip text="Payer 1099 Form">
          <span>
            {!merchant.form_1099_document?.payers_document && !isCustomer ? (
              <FileInput
                onDrop={(files) =>
                  onDrop({
                    newFiles: files,
                    form1099DoucmentType: "PAYERS_DOCUMENT",
                  })
                }
                isUploading={
                  isUploading &&
                  originalArgs?.form_1099_document_type === "PAYERS_DOCUMENT"
                }
                variant="icon"
                withInkleDocs
              />
            ) : (
              <>
                {merchant.form_1099_document?.payers_document && (
                  <FileInput
                    file={merchant.form_1099_document?.payers_document}
                    variant="icon"
                    onFileClick={({ uuid: fileId }) => openPreview(fileId)}
                  />
                )}
              </>
            )}
          </span>
        </ToolTip>
        <ToolTip text="Recipient 1099 Form">
          <span>
            {!merchant.form_1099_document?.recipients_document &&
            !isCustomer ? (
              <FileInput
                onDrop={(files) =>
                  onDrop({
                    newFiles: files,
                    form1099DoucmentType: "RECIPIENTS_DOCUMENT",
                  })
                }
                isUploading={
                  isUploading &&
                  originalArgs?.form_1099_document_type ===
                    "RECIPIENTS_DOCUMENT"
                }
                variant="icon"
                withInkleDocs
              />
            ) : (
              <>
                {merchant.form_1099_document?.recipients_document && (
                  <FileInput
                    file={merchant.form_1099_document?.recipients_document}
                    variant="icon"
                    onFileClick={({ uuid: fileId }) => openPreview(fileId)}
                  />
                )}
              </>
            )}
          </span>
        </ToolTip>
      </div>
      {merchant.form_1099_document?.recipients_document?.uuid && (
        <Button customType="transparent" onClick={openSendCopyModal}>
          <img src={ShareNetwork} className="t-h-5" alt="ShareNetwork" />
        </Button>
      )}
      <WSeriesFormSendCopy
        show={showSendCopyModal}
        closeModal={closeSendCopyModal}
        taskId={taskId}
        groupId={groupId}
        merchant={merchant}
        updateMerchant={updateMerchant}
      />

      <DocumentPreviewModal />
    </div>
  );
};
