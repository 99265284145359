import classNames from "classnames";
import { ArrowRight } from "components/icons/ArrowRight";
import React from "react";
import WhiteCheck from "static/images/WhiteCheck.svg";

const UpdateTaskPriceHeader = ({
  step,
  setStep,
}: {
  step: number;
  setStep: (v: number) => void;
}) => {
  return (
    <div className="t-mr-5 t-w-full t-border t-border-solid t-border-surface-transparent t-border-t-neutral-0 t-px-6 t-pt-4 t-text-body-sm">
      <div className="t-flex t-w-full t-items-center t-justify-between">
        <div className="t-flex t-items-center">
          <button
            onClick={() => setStep(0)}
            className={classNames(
              "all:unset p-0 t-flex t-items-center t-text-neutral",
              {
                "t-text-text-black": step >= 0,
              }
            )}
          >
            <div
              className={classNames(
                "t-mr-2 t-flex t-h-6 t-w-6 t-items-center t-justify-center t-rounded-full t-p-2 t-text-body",
                {
                  "t-border-solid t-border-purple-50 t-bg-purple-0 t-text-purple-50":
                    step === 0,
                  "t-bg-purple t-text-surface": step > 0,
                }
              )}
            >
              {step > 0 ? <img src={WhiteCheck} alt="WhiteCheck" /> : 1}
            </div>
            <span
              className={classNames({
                "t-text-neutral": step !== 0,
                "t-text-neutral-80": step === 0,
              })}
            >
              Upload final document
            </span>
          </button>
          <ArrowRight />
          <button
            className={classNames(
              "all:unset p-0 t-flex t-items-center t-text-neutral",
              {
                "t-text-text-black": step >= 1,
              }
            )}
            onClick={() => setStep(1)}
          >
            <div
              className={classNames(
                "t-mr-2 t-flex t-h-6 t-w-6 t-items-center t-justify-center t-rounded-full  t-p-2 t-text-body",
                {
                  "t-border-solid t-border-purple-50 t-bg-purple-0 t-text-purple-50":
                    step === 1,
                  "t-bg-neutral-10 t-text-neutral-30": step < 1,
                }
              )}
            >
              2
            </div>
            <span
              className={classNames({
                "t-text-neutral": step !== 1,
                "t-text-neutral-80": step === 1,
              })}
            >
              Balance payment
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateTaskPriceHeader;
