import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface InvestorUpdateState {
  mailType: "Gmail" | "Inkle";
}

const initialState: InvestorUpdateState = {
  mailType: "Inkle",
};

export const investorUpdate = createSlice({
  name: "investorUpdate",
  initialState,
  reducers: {
    setMailType: (
      state,
      action: PayloadAction<InvestorUpdateState["mailType"]>
    ) => {
      state.mailType = action.payload;
    },
  },
});

export const { setMailType } = investorUpdate.actions;

export default investorUpdate.reducer;
