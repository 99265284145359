import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import {
  NEW_FILE_UPLOADED,
  NEW_FILE_UPLOAD_FAILED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import { uploadMultipleFiles } from "../../apis/documents";
import {
  ADD_FOLDER_ACTION,
  FILE_UPLOAD_MESSAGE,
  UPLOAD_FILE_ACTION,
} from "../../constants/documents";
import { AddFileIcon } from "../icons/AddFile";
import { UploadFileIcon } from "../icons/uploadFile";

const AddActionDropdown = ({
  groupId,
  openAddFolderModal,
  setLoad,
  ParentId,
  setLoadingFileUploadStart,
  uploadPayload,
  setUploadPayload,
  open,
}) => {
  const { alertToast, successToast } = useToast();
  const { trackEvent } = useAnalytics();
  const query = useQuery();
  const searchTerm = query.get("search");

  const openCreateFolder = () => {
    openAddFolderModal(true);
  };

  useEffect(() => {
    if (uploadPayload.length > 0) {
      uploadfile();
    }
  }, [uploadPayload]);

  const uploadfile = async () => {
    try {
      setLoadingFileUploadStart(true);
      await uploadMultipleFiles({
        groupId,
        uploadPayload,
        parentUuid: ParentId,
      });
      trackEvent(NEW_FILE_UPLOADED, {
        parent_uuid: ParentId,
        file: uploadPayload,
      });
      setUploadPayload([]);
      setLoad((prev) => !prev);
      setLoadingFileUploadStart(false);
      successToast({ message: FILE_UPLOAD_MESSAGE });
    } catch (error) {
      setUploadPayload([]);
      trackEvent(NEW_FILE_UPLOAD_FAILED, {
        parent_uuid: ParentId,
        file: uploadPayload,
        error: error?.response?.data?.error?.message,
      });
      setLoadingFileUploadStart(false);
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };
  const AddDropdownData = [
    {
      Icon: UploadFileIcon,
      title: UPLOAD_FILE_ACTION,
      id: 1,
    },
    {
      Icon: AddFileIcon,
      title: ADD_FOLDER_ACTION,
      id: 2,
    },
  ];
  return (
    <DropDown.Root modal={false}>
      <DropDown.Trigger asChild disabled={searchTerm}>
        <div>
          <Button size="small" customType="primary" disabled={searchTerm}>
            Upload
          </Button>
        </div>
      </DropDown.Trigger>
      <DropDown.Portal>
        <DropDown.Content sideOffset={8}>
          {AddDropdownData.map(({ id, Icon, title }) => (
            <DropDown.Item
              key={id}
              className="t-flex t-items-center t-gap-2 hover:t-text-purple data-[disabled]:t-text-neutral-30"
              onSelect={title === UPLOAD_FILE_ACTION ? open : openCreateFolder}
            >
              <Icon color="currentColor" />
              {title}
            </DropDown.Item>
          ))}
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
};

export default AddActionDropdown;
