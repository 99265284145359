import React, { useContext, useEffect } from "react";
import FloatingChatIcon from "static/images/FloatingChat.svg";
import FloatingChatOpenedIcon from "static/images/FloatingChatOpened.svg";
import {
  openFloatingChat,
  closeFloatingChat,
  setToOpenChatId,
} from "store/slices/chat";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import "static/styles/components/floatingChat.css";
import { useMessageCount } from "hooks/useMessageCount";
import cx from "classnames";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { Channel as ChatChannel } from "../chat/Channel";
import { SlackIntegration } from "components/SlackIntegration";
import authContext from "jwt_context&axios/authContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import PhoneCall from "../../static/images/PhoneCall.svg";
import { Button } from "components/DesignSystem/Button/Button";
import CircleCross from "static/images/CircleCross.svg";
import { ChannelList } from "components/chat/ChannelList";
import { VideoCamera } from "components/icons/VideoCamera";

export const FloatingChat = () => {
  const { isOpen, toOpenChatId, isMessagesOpened } = useSelector(
    (state: RootState) => state.chat
  );
  const right = useSelector((state: RootState) => state.chat.position.right);
  const bottom = useSelector((state: RootState) => state.chat.position.bottom);
  const { messages = 0 } = useMessageCount() || {};
  const formattedCount = messages > 99 ? "99+" : messages;
  const dispatch = useDispatch();
  const { secondary_channel_url } = useCurrentGroup();
  const { authtoken } = useContext(authContext);
  const { isCustomer } = useRoleBasedView();

  const handleFloatingChat = (): void => {
    if (isOpen) dispatch(closeFloatingChat());
    else dispatch(openFloatingChat());
  };

  useEffect(() => {
    if (secondary_channel_url) {
      dispatch(setToOpenChatId(secondary_channel_url));
    }
  }, [secondary_channel_url]);

  return (
    <>
      {/* FIXME: Permissioning */}
      {true && (
        <div
          style={{ right, bottom }}
          className="floatingChatWrapper t-fixed t-z-[1001]"
        >
          <div
            className={cx(
              "t-w-[432px] t-bg-i-surface-grey t-absolute t-bottom-[60px] t-right-0 t-rounded-lg !t-h-[620px] t-origin-bottom-right t-duration-300 t-ease-in-out t-z-[1001] t-shadow-floating-chat",
              {
                "t-scale-0": !isOpen,
                "t-scale-100": isOpen,
              }
            )}
          >
            {isOpen && (
              <div className="t-rounded-lg t-relative t-flex t-w-full t-z-[1001]">
                <div className="t-absolute -t-top-[18px] -t-right-[24px] t-z-[1004]">
                  <Button
                    customType="text"
                    onClick={() => dispatch(closeFloatingChat())}
                  >
                    <img
                      src={CircleCross}
                      alt="close"
                      width="48px"
                      height="48px"
                    />
                  </Button>
                </div>
                {isMessagesOpened ? (
                  <div className="t-h-[620px] t-border t-border-solid t-border-neutral-10 t-w-full  t-rounded-lg t-overflow-auto t-z-[1003]">
                    <ChatChannel
                      channelId={toOpenChatId}
                      messageMaxWidth="360px"
                    />
                  </div>
                ) : (
                  <div className="t-h-[620px] t-rounded-lg t-border t-border-solid t-border-neutral-10 t-w-full">
                    <div className="t-rounded-t-lg t-p-4 t-pb-0 t-flex t-justify-between t-bg-white">
                      <div className="t-text-h5 t-self-baseline">Chat</div>
                      <div className="t-flex t-gap-2">
                        {!authtoken.is_any_service_user && (
                          <SlackIntegration
                            hideOnceAdded
                            floating
                            reverse={undefined}
                          />
                        )}
                      </div>
                    </div>
                    <div className="t-h-[570px] t-rounded-b-lg t-w-full t-overflow-auto t-bg-white">
                      <ChannelList onSelect={() => {}} unreplied={true} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            className="t-h-14 t-w-14 t-border-0 t-rounded-[50%] t-bg-purple hover:t-bg-purple-80 t-drop-shadow-bubble t-z-[1001]"
            onClick={handleFloatingChat}
          >
            <img
              src={isOpen ? FloatingChatOpenedIcon : FloatingChatIcon}
              alt="floatingChatIcon"
            />
          </button>
          {!isOpen && messages > 0 && (
            <span className="t-absolute t-bottom-full t-left-full t-flex t-h-6 t-w-6 -t-translate-x-[80%] t-translate-y-[70%] t-items-center t-justify-center t-rounded-full t-bg-red-50 t-text-caption t-text-neutral-0">
              <span className="">{formattedCount}</span>
            </span>
          )}
        </div>
      )}
    </>
  );
};
