import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardState {
  dashboardSidebarExpand: boolean;
  isCollapsedSidebarHovered: boolean;
  booksSidebarExpand: boolean;
  isBooksCollapsedSidebarHovered: boolean;
  isSnackbar: boolean;
  isSidebarExpandable: boolean;
}

const initialState: DashboardState = {
  dashboardSidebarExpand:
    localStorage.getItem("dashboardSidebarExpanded") === null
      ? true
      : JSON.parse(localStorage.getItem("dashboardSidebarExpanded") || "true"),
  isCollapsedSidebarHovered: false,
  booksSidebarExpand:
    localStorage.getItem("booksSidebarExpanded") === null
      ? false
      : JSON.parse(localStorage.getItem("booksSidebarExpanded") || "false"),
  isBooksCollapsedSidebarHovered: false,
  isSnackbar: false,
  isSidebarExpandable: true,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    expandDashboardSidebar: (state) => {
      localStorage.setItem("dashboardSidebarExpanded", JSON.stringify(true));
      state.dashboardSidebarExpand = true;
    },
    collapseDashboardSidebar: (state) => {
      localStorage.setItem("dashboardSidebarExpanded", JSON.stringify(false));
      state.dashboardSidebarExpand = false;
    },
    updateCollapsedSidebarHover: (state, action: PayloadAction<boolean>) => {
      state.isCollapsedSidebarHovered = action.payload;
    },
    expandBooksSidebar: (state) => {
      localStorage.setItem("booksSidebarExpanded", JSON.stringify(true));
      state.booksSidebarExpand = true;
    },
    collapseBooksSidebar: (state) => {
      localStorage.setItem("booksSidebarExpanded", JSON.stringify(false));
      state.booksSidebarExpand = false;
    },
    updateBooksCollapsedSidebarHover: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isBooksCollapsedSidebarHovered = action.payload;
    },
    setIsSnackbar: (state, action: PayloadAction<boolean>) => {
      state.isSnackbar = action.payload;
    },
    setIsSidebarExpandable: (state, action: PayloadAction<boolean>) => {
      state.isSidebarExpandable = action.payload;
    },
  },
});

export const {
  expandDashboardSidebar,
  collapseDashboardSidebar,
  updateCollapsedSidebarHover,
  expandBooksSidebar,
  collapseBooksSidebar,
  updateBooksCollapsedSidebarHover,
  setIsSnackbar,
  setIsSidebarExpandable,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
