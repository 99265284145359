import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useParams } from "react-router-dom";
import { useDeleteEntityStateDataMutation } from "store/apis/entity";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

const DeleteJurisdictions = ({
  close,
  isOpen,
  stateId,
}: ModalProps & {
  stateId: string;
}) => {
  const { successToast, alertToast } = useToast();
  const { entityId } = useParams<{ entityId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const [deleteEntityStateData, { isLoading }] =
    useDeleteEntityStateDataMutation();

  const onDelete = async () => {
    try {
      await deleteEntityStateData({
        groupId,
        entityId,
        stateId: stateId,
      }).unwrap();
      successToast({ message: "Jurisdiction deleted!" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };
  return (
    <Modal.Root onOpenChange={close} open={isOpen}>
      <Modal.Content size="small">
        <Modal.Header>
          <Modal.Title>Delete Jurisdiction</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-text-subtext">
          Are you sure, you want to delete this jurisdiction? This action can
          not be undone
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={onDelete}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DeleteJurisdictions;
