import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldAttributes, FieldProps } from "formik";
import { NumericFormat } from "react-number-format";
import { ComponentProps, MouseEvent, ReactNode } from "react";

export const NumericInput = ({
  fieldProps = {},
  numericProps = {},
  label,
  storeNumeric = false,
  required = false,
}: {
  fieldProps?: FieldAttributes<any>;
  numericProps?: ComponentProps<typeof NumericFormat> &
    ComponentProps<typeof TextInput>;
  label?: ReactNode;
  storeNumeric?: boolean;
  required?: boolean;
}) => (
  <Field {...fieldProps}>
    {({ field, form }: FieldProps) => (
      <>
        <NumericFormat
          {...field}
          required={required}
          label={label}
          {...numericProps}
          onClick={(event: MouseEvent<HTMLInputElement>) => {
            // @ts-ignore
            if (event.target.value?.includes?.("0.00")) {
              // @ts-ignore
              event.target?.select();
            }
          }}
          customInput={TextInput}
          onValueChange={(values, ...rest) => {
            const { value, floatValue } = values;
            numericProps.onValueChange?.(values, ...rest);
            if (storeNumeric) {
              return form.setFieldValue(field.name, floatValue);
            }

            return form.setFieldValue(field.name, value);
          }}
          onChange={() => {}}
        />
      </>
    )}
  </Field>
);
