import classNames from "classnames";
import { ChatPane } from "components/ChatPane/ChatPane";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { RuleEngine } from "components/RuleEngine/RuleEngine";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useState } from "react";
import { useDrop } from "react-dnd";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import uploadCloud from "static/images/uploadCloud.svg";
import { setTransactionsInChat } from "store/slices/transactionsInChat";
import { Transaction, TxnAccountType } from "types/Models/books";
import { TransactionTable } from "../../../components/Transaction/TransactionTable";
import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { useActionItems } from "hooks/useActionItems";
import { useAppDispatch } from "hooks/useAppDispatch";
import { resetTxnFilters } from "store/slices/transactionFilter";
import { parse, stringify } from "qs";
import { EmptyScreen } from "../EmptyScreen";
import { Button } from "components/DesignSystem/Button/Button";
import { Stripe } from "components/icons/Logos/Stripe";
import { GradientArrow } from "components/icons/GradientArrow";
import { Inkle } from "components/icons/Logos/Inkle";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { useBankConnect } from "hooks/useBankConnect";
import { useRoleBasedView } from "hooks/useRoleBasedView";

const TxnTable = ({ txnAccountType }: { txnAccountType?: TxnAccountType }) => {
  const dispatch = useAppDispatch();
  const [channelId, setChannelId] = useState<string | null>();
  const entityId = useCurrentEntityId();

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "transaction",
    drop: ({ transaction }: { transaction: string }) => onDrop(transaction),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const onDrop = async (transaction: string) => {
    const txn: Transaction = JSON.parse(transaction);
    const transactionsToSendtoChat = {
      id: txn.uuid,
      amount: txn.amount,
      source: txn.source,
      date: txn.date,
      vendor: {
        logo: txn.logo,
        name: txn.merchant,
      },
      isCreditCard: txn.is_credit_card,
    };
    dispatch(
      setTransactionsInChat({
        transactionsInChat: [transactionsToSendtoChat],
        entityId: entityId,
      })
    );
  };
  return (
    <div className="t-flex t-overflow-y-auto t-h-full t-gap-5">
      <div className="t-h-full t-grow t-pb-5">
        <TransactionTable
          setChannelId={setChannelId}
          txnAccountType={txnAccountType}
        />
      </div>
      <div
        className="t-h-full t-w-[360px] t-shrink-0 t-grow-0 t-sticky t-top-0"
        ref={drop}
      >
        {isOver && (
          <div className="t-absolute t-z-10 t-flex t-h-[-webkit-fill-available] t-w-[-webkit-fill-available] t-flex-col t-items-center t-justify-center t-gap-3 t-border t-border-dashed t-border-neutral-70 t-bg-[#f3f3f580] t-h-full t-w-[-moz-available]">
            <img
              src={uploadCloud}
              alt="upload"
              className="t-animate-drag-and-drop"
            />
            <div>Drag and drop here</div>
          </div>
        )}
        <ChatPane setChannelId={setChannelId} channelId={channelId} />
      </div>
    </div>
  );
};

const StripeTable = () => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCpa } = useRoleBasedView();

  const { onConnect, isLoading: isConnectBankLoading } = useBankConnect();

  const { stripeConnection, isLoading, isSuccess } = useGetAllConnections({
    groupId,
    entityId,
  });

  const isEmpty = stripeConnection?.bank_accounts?.length === 0;

  return (
    <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={isEmpty}>
      <Async.Empty>
        <EmptyScreen
          className="t-h-[70dvh]"
          text="Inkle Books now natively integrates with Stripe"
          cta={
            <Button
              size="small"
              customType="primary"
              isLoading={isConnectBankLoading}
              disabled={isConnectBankLoading}
              onClick={() =>
                onConnect({
                  connectionId: stripeConnection?.uuid!,
                  invokedFrom: isCpa
                    ? `/books/transactions/stripe?company=${groupId}&entity=${entityId}`
                    : `/books/transactions/stripe?entity=${entityId}`,
                })
              }
            >
              Connect
            </Button>
          }
        >
          <div className="t-flex t-gap-5 t-items-center">
            <Stripe />
            <GradientArrow />
            <Inkle />
          </div>
        </EmptyScreen>
      </Async.Empty>
      <Async.Success>
        <TxnTable txnAccountType="STRIPE" />
      </Async.Success>
    </Async.Root>
  );
};

export const Transactions = () => {
  const dispatch = useAppDispatch();
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });

  const searchQuery = stringify(
    {
      company: query.company || null,
      entity: query.entity || null,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const {
    action_items_count,
    isBankReconnectRequired,
    isReconciliationRequired,
  } = useActionItems();

  const isRuleEnginePage = pathname.includes("/rules");
  const isStripePage = pathname.includes("/stripe");

  const resetFilters = () => {
    dispatch(resetTxnFilters());
  };

  return (
    <div className="t-flex t-h-full">
      <DashboardContainer className="t-h-full t-grow-0 t-shrink">
        <DashboardContainer.Header className="t-z-header">
          <Header
            title="Transactions"
            bottom={
              <Tab.Root defaultValue={pathname} value="MATCH">
                <Tab.List>
                  <Tab.NavTrigger
                    exact
                    to={`${url}${searchQuery}`}
                    value={!isRuleEnginePage && !isStripePage ? "MATCH" : ""}
                    className="t-flex t-items-center"
                    onClick={resetFilters}
                  >
                    Banks & Cards
                    {action_items_count > 0 &&
                      (isBankReconnectRequired || isReconciliationRequired) && (
                        <ActionRequiredPopOver type="ALL" />
                      )}
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/stripe${searchQuery}`}
                    value={isStripePage ? "MATCH" : ""}
                    onClick={resetFilters}
                  >
                    Stripe
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/rules${searchQuery}`}
                    value={isRuleEnginePage ? "MATCH" : ""}
                  >
                    Rules
                  </Tab.NavTrigger>
                </Tab.List>
              </Tab.Root>
            }
          />
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          <div className="t-pl-6 t-h-full t-w-full">
            <Switch>
              <Route exact path={path}>
                <TxnTable txnAccountType="EXCLUDE_STRIPE" />
              </Route>
              <Route exact path={`${path}/stripe`}>
                <StripeTable />
              </Route>
              <Route exact path={`${path}/rules`}>
                <div className="t-my-5">
                  <RuleEngine />
                </div>
              </Route>
            </Switch>
          </div>
        </DashboardContainer.Content>
      </DashboardContainer>
    </div>
  );
};
