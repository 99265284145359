import { DeleteModal } from "components/design/deleteModal";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { FormikValues } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCreateAutofillMutation,
  useDeleteAutofillMutation,
  useGetAutofillQuery,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import { AddressAutofill } from "types/Models/addressAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { AddressModal } from "./AddressModal";
import { AutofillTable } from "./AutofillTable";
import { BankModal } from "./BankModal";
import { PersonaModal } from "./PersonaModal";
import { DashboardLayout } from "components/DashboardLayout";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

type RouteParams = {
  groupId?: string;
};

export const AutofillPage = ({
  type,
  parentUrl,
}: {
  type: "addresses" | "bank_accounts" | "authorized_persons";
  parentUrl?: string;
}) => {
  const { alertToast } = useToast();
  const params: RouteParams = useParams();
  const { uuid } = useCurrentGroupContext();

  let groupId = "";
  if (!params?.groupId) {
    groupId = uuid;
  } else {
    groupId = params?.groupId;
  }
  const [formValues, setFormValues] = useState({});
  const [openModal, setOpenModalType] = useState<
    "addresses" | "bank_accounts" | "authorized_persons" | null
  >(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAutofillId, setDeleteAutofillId] = useState<null | string>(null);
  const [createAutofill, { isLoading }] = useCreateAutofillMutation();
  const [editAutofill, { isLoading: isUpdating }] = useUpdateAutofillMutation();
  const [deleteAutofill] = useDeleteAutofillMutation();
  const { isAdmin } = useRoleBasedView();
  const entityId = useCurrentEntityId();
  const { data: autofills = [], isLoading: isAutofillLoading } =
    useGetAutofillQuery(
      { groupId, autofillKey: type, entityId },
      { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
    );

  const stripAndSetFormValues = (autofill: any) => {
    const ignoreFields = ["uuid", "autofill_string", "added_by", "created_at"];
    setFormValues(
      Object.entries(autofill).reduce(
        (acc, [key, value]) =>
          ignoreFields.includes(key) ? acc : { [key]: value, ...acc },
        {}
      )
    );
  };

  const onSubmit = async (values: FormikValues) => {
    try {
      if (!values.id) {
        await createAutofill({
          groupId,
          autofillKey: type,
          fields: values,
          entityId,
        }).unwrap();
      } else {
        await editAutofill({
          groupId,
          autofillKey: type,
          fields: values,
          uuid: values.id,
          entityId,
        }).unwrap();
      }
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const onDelete = async () => {
    try {
      if (deleteAutofillId) {
        await deleteAutofill({
          groupId,
          autofillKey: type,
          uuid: deleteAutofillId,
          entityId,
        }).unwrap();
      }
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
    setDeleteModal(false);
  };

  const onEdit = ({
    autofill,
    type,
  }: {
    autofill: PersonaAutofill | BankAutofill | AddressAutofill;
    type: "addresses" | "bank_accounts" | "authorized_persons";
  }) => {
    stripAndSetFormValues({ id: autofill.uuid, ...autofill });
    setOpenModalType(type);
  };

  const onClose = () => {
    setOpenModalType(null);
    stripAndSetFormValues({});
  };

  const onAddButtonClick = () => {
    setOpenModalType(type);
  };

  const openDeleteModal = ({ uuid }: { uuid: string }) => {
    setDeleteModal(true);
    setDeleteAutofillId(uuid);
  };

  const getTitle = () => {
    if (type === "addresses") {
      return "Addresses";
    } else if (type === "bank_accounts") {
      return "Banks";
    } else if (type === "authorized_persons") {
      return "Personas";
    }
  };

  if (isAutofillLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }
  const autofillBreadcrumbs = [
    {
      name: isAdmin ? "Auto-Fill" : "Autofill",
      link: isAdmin ? `/crm/${groupId}/autofills` : parentUrl,
    },
    { name: getTitle() || " " },
  ];

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title={getTitle()}
          right={
            <div className="t-flex t-gap-3">
              <EntitySelector />
              <Button
                onClick={onAddButtonClick}
                customType="primary"
                size="small"
              >
                <i className="fas fa-plus t-mr-2" />
                Add
              </Button>
            </div>
          }
          breadcrumbs={autofillBreadcrumbs}
        />
      }
    >
      <div className="t-flex t-w-full t-flex-col t-gap-2">
        <AutofillTable
          items={autofills}
          onEdit={onEdit}
          onDelete={openDeleteModal}
          type={type}
        />
        <AddressModal
          isLoading={isLoading || isUpdating}
          initialValues={formValues}
          onSubmit={onSubmit}
          onClose={onClose}
          open={openModal === "addresses"}
        />
        <BankModal
          isLoading={isLoading || isUpdating}
          initialValues={formValues}
          onSubmit={onSubmit}
          onClose={onClose}
          open={openModal === "bank_accounts"}
        />
        <PersonaModal
          entityId={entityId}
          isLoading={isLoading || isUpdating}
          initialValues={formValues}
          onSubmit={onSubmit}
          onClose={onClose}
          open={openModal === "authorized_persons"}
        />
        <DeleteModal
          text="Delete this autofill"
          onClick={onDelete}
          show={deleteModal}
          closeModal={() => setDeleteModal(false)}
        />
      </div>
    </DashboardLayout>
  );
};
