import { ReactNode } from "react";

export const InfoItem = ({
  label,
  children,
}: {
  label: string | ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <span className="!t-pb-0 t-font-sans t-text-caption t-text-text-30">
        {label}
      </span>
      <span className="t-text-subtext">{children}</span>
    </div>
  );
};
