import React from "react";

import "static/styles/components/design/searchBox.css";

export const SearchBox = ({ placeholder, onChange, value, className = "" }) => {
  return (
    <input
      placeholder={placeholder}
      className={`searchBox ms-3 ${className}`}
      onChange={onChange}
      defaultValue={value}
      autoFocus
    />
  );
};
