import { ConditionalLink } from "components/conditionalLink";
import { useFilePreviewUrl } from "hooks/useFilePreviewUrl";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import { FileIcon } from "react-file-utils";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { setPreviewFiles } from "store/slices/chat";
import { Gallery } from "./Gallery";
import { ScrappedContent } from "./ScrappedContent";
import { Image } from "./Image";

export type AttachmentType = {
  asset_url?: string;
  type?: string;
  image_url?: string;
  fallback?: string;
  title_link?: string;
  og_scrape_url?: string;
  title?: string;
  thumb_url?: string;
  mime_type?: string;
  original_height?: number;
  original_width?: number;
};

export type AttachmentProps = {
  attachments: AttachmentType[];
  sent: boolean;
};

const PdfFile = ({
  children,
  url,
  fileType,
}: {
  children: ReactNode;
  url: string;
  fileType: string;
}) => {
  const { preview } = useFilePreviewUrl();
  const { alertToast } = useToast();

  const onPreview = async () => {
    try {
      const response = await preview({ url });
      window.open(response);
    } catch (error: any) {
      alertToast({ message: error });
    }
  };

  if (fileType === "application/pdf") {
    return (
      <button
        onClick={onPreview}
        className="all:unset t-flex t-cursor-pointer t-gap-2"
      >
        {children}
      </button>
    );
  }

  return (
    <ConditionalLink
      to={url}
      className="all:unset t-flex t-cursor-pointer t-gap-2"
    >
      {children}
    </ConditionalLink>
  );
};

export const Attachment = ({ attachments, sent }: AttachmentProps) => {
  const dispatch = useDispatch();
  const images = attachments.filter(
    (a) => a.type === "image" && !(a.og_scrape_url || a.title_link)
  );
  const isImage = images.length === 1;
  const image = images[0];
  const isGallery = images.length > 1;
  const videos = attachments.filter(
    (e) => e.type === "video" && !(e.og_scrape_url || e.title_link)
  );
  const audios = attachments.filter(
    (e) => e.type === "audio" && !(e.og_scrape_url || e.title_link)
  );
  const files = attachments.filter(
    (e) => e.type === "file" && !(e.og_scrape_url || e.title_link)
  );
  const scrappedContents = attachments.filter(
    (e) => e.og_scrape_url || e.title_link
  );

  const lastScrappedContent = scrappedContents.at(-1);

  const onImageClick = () => {
    dispatch(setPreviewFiles({ files: [image] }));
  };

  return (
    <>
      {lastScrappedContent && (
        <ScrappedContent sent={sent} attachment={lastScrappedContent} />
      )}
      {isGallery && <Gallery images={images} />}
      {isImage && (
        <div onClick={onImageClick}>
          <Image
            height={image.original_height || 266}
            width={image.original_width}
            src={image.image_url}
            alt={image.fallback}
            className="t-max-h-[266px] t-w-full t-rounded t-object-cover"
          />
        </div>
      )}
      {videos.length > 0 &&
        videos.map(({ asset_url }) => (
          <ReactPlayer
            key={asset_url}
            controls
            height="100%"
            url={asset_url}
            width="100%"
            className="t-rounded [&_video]:t-rounded"
          />
        ))}

      {audios.map(({ asset_url }) => (
        <audio controls className="t-rounded" key={asset_url}>
          <source data-testid="audio-source" src={asset_url} type="audio/mp3" />
        </audio>
      ))}

      {files.map((file) => (
        <PdfFile
          url={file?.asset_url || ""}
          fileType={file?.mime_type!}
          key={file.asset_url}
        >
          <FileIcon mimeType={file.mime_type} version="2" />
          <span className="t-break-all">{file.title}</span>
        </PdfFile>
      ))}
    </>
  );
};
