import classNames from "classnames";
import { Form, Formik, FormikState } from "formik";
import { emailSchema } from "formValidations/emailSchema";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import CopyIconBlack from "static/images/CopyIconBlack.svg";
import { useSendReferralEmailMutation } from "store/apis/refrral";
import CopyCode from "./design/copyCode";
import { Button } from "./DesignSystem/Button/Button";
import { TextInput } from "./DesignSystem/TextInput/TextInput";
import { OutlinedPaperPlaneRight } from "./icons/OutlinedPaperPlaneRight";
import { RaiseApp } from "./icons/raise";

export const ReferCard = ({
  link,
  smallCard = false,
}: {
  link?: boolean;
  smallCard?: boolean;
}) => {
  const { alertToast, successToast } = useToast();
  const {
    authtoken: { referral_link },
  } = useContext(authContext);
  const [sendInvite] = useSendReferralEmailMutation();
  const getCopyText = () => referral_link;

  const onSubmit = async (
    { email }: { email: string },
    {
      resetForm,
    }: {
      resetForm: (nextState?: Partial<FormikState<{ email: string }>>) => void;
    }
  ) => {
    try {
      const { message } = await sendInvite({ email }).unwrap();
      successToast({ message });
      resetForm();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };
  return (
    <div
      className={classNames("t-rounded-lg t-bg-surface !t-shadow-card", {
        "t-w-[34.5rem]": !smallCard,
        "t-w-[408px]": smallCard,
      })}
    >
      <div className="t-flex t-items-center t-justify-between t-border-0 t-border-b t-border-solid t-border-neutral-10 t-px-6 t-py-4">
        <div className="t-text-body-lg t-font-semibold t-leading-5 t-tracking-normal">
          <RaiseApp color="#41395C" /> Referral
        </div>
      </div>
      <div className="t-flex t-flex-col t-gap-9 t-px-6 t-pb-4 t-pt-3">
        <span className="t-flex t-flex-col t-gap-2">
          <label className="t-text-body t-text-text-60" htmlFor="email">
            Invite your friend to Inkle and you each receive
            <b> $100 Inkle Credits!</b>
          </label>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={onSubmit}
            validationSchema={emailSchema}
          >
            {/* @ts-ignore */}
            {({ isSubmitting, values: { email } }) => {
              return (
                <Form className="t-m-0 t-flex t-w-full t-flex-col t-justify-between t-gap-4">
                  <div className="t-flex t-gap-3">
                    <TextInput
                      block
                      name="email"
                      type="email"
                      placeholder="Email address"
                    />
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      <div className="t-text-neutral">
                        <OutlinedPaperPlaneRight />
                      </div>
                    </Button>
                  </div>
                  <CopyCode
                    getCopyText={getCopyText}
                    defaultText={referral_link}
                    copyIcon={CopyIconBlack}
                    codeCopiedText="Referral link copied"
                    /* @tw */
                    addClassName="all:unset t-border t-h-[20px] t-border-neutral-10 t-flex t-items-center t-justify-center !t-py-4 t-rounded t-border-solid t-text-text-60 t-w-full t-text-body-sm !t-bg-surface t-drop-shadow-button"
                    alert={false}
                    reverse
                  />
                </Form>
              );
            }}
          </Formik>
        </span>
      </div>
    </div>
  );
};
