import { axiosInstance } from "./apiInstance";

export const getAutofillFields = async ({ groupId, autofillKey, entityId }) => {
  const { data } = await axiosInstance.get(
    `api/inkle/autofill/group/${groupId}/entity/${entityId}/autofill_data/`,
    {
      params: {
        autofill_type: autofillKey,
      },
    }
  );

  return data;
};

export const createAutofill = async ({
  groupId,
  autofillKey,
  fields,
  entityId,
}) => {
  const { data } = await axiosInstance.post(
    `api/inkle/autofill/group/${groupId}/entity/${entityId}/autofill_data/`,
    fields,
    {
      params: {
        autofill_type: autofillKey,
      },
    }
  );

  return data;
};
