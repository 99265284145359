export const LinkBreak = () => (
  <>
    <span className="t-sr-only">LinkBreak</span>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.50004L12.7675 6.76754C13.2229 6.29604 13.4749 5.66453 13.4692 5.00904C13.4635 4.35355 13.2006 3.72652 12.737 3.26299C12.2735 2.79947 11.6465 2.53655 10.991 2.53086C10.3355 2.52516 9.70401 2.77715 9.2325 3.23254L8.5 4.00004"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00004 8.5L3.23254 9.2325C2.77715 9.70401 2.52516 10.3355 2.53086 10.991C2.53655 11.6465 2.79947 12.2735 3.26299 12.737C3.72652 13.2006 4.35355 13.4635 5.00904 13.4692C5.66453 13.4749 6.29604 13.2229 6.76754 12.7675L7.50004 12"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10H13.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6H4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12V13.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 2.5V4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
