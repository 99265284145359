export const US = "United States";
export const NEARING_DEADLINE = "Nearing Deadline";
export const ALL_RA = "All RA";
export const SubTabCategory = [
  { id: 22, title: NEARING_DEADLINE },
  { id: 32, title: ALL_RA },
];
export const DAYS_TO_EXPIRY_THOUSAND = 10000;
export const DAYS_TO_EXPIRY_THIRTY = 30;

export const US_UUID = "530ce334-b166-4109-9a63-b3d6ab3b49ab";

export const ACTIVE = "ACTIVE";
export const EXPIRED = "EXPIRED";
export const US_COORDINATES: [number, number] = [
  -96.02041333103696, 36.3935453170887,
];

export const COORDINATES = {
  Arizona: [-113, 35],
  Arkansas: [-94, 36],
  California: [-120.2484600728731, 36.99927180619292],
  Colorado: [-106, 39],
  Connecticut: [-73.5, 42.3],
  Georgia: [-85, 34],
  Indiana: [-87, 41],
  Illinois: [-90, 41],
  Louisiana: [-93, 32],
  Minnesota: [-96, 47],
  Mississippi: [-91, 33],
  Montana: [-110, 48],
  "New Mexico": [-107, 35],
  "North Dakota": [-101, 48],
  Oklahoma: [-98, 36.5],
  Pennsylvania: [-79, 41.5],
  Tennessee: [-88, 36.588193756687254],
  Virginia: [-79, 38.4],
  Delaware: [-76.5, 39.58816454911891],
  "West Virginia": [-81.96840843590736, 39],
  Wisconsin: [-90.45529514685556, 45],
  Wyoming: [-108, 44],
  Alabama: [-88, 34],
  Florida: [-82.5, 29],
  Idaho: [-115, 44.47415973848672],
  Kansas: [-100, 39],
  Maryland: [-78, 40],
  "New Jersey": [-75.5, 41.35740912295314],
  "North Carolina": [-79.5, 36],
  "South Carolina": [-81.5, 34.5],
  Washington: [-121, 47.912206136751465],
  Vermont: [-73.5, 45],
  Utah: [-112.5, 40],
  Iowa: [-94, 43],
  Kentucky: [-86, 38.2],
  Maine: [-70, 46],
  Massachusetts: [-72.82168074792526, 43.08370784751334],
  Michigan: [-85.54968750343666, 44.207325946732664],
  Missouri: [-93.33298370113864, 39.40066418035584],
  Nebraska: [-100.55318999919277, 42.20059602936303],
  Nevada: [-117.5507114482, 40.30045275223185],
  "New Hampshire": [-72.50384141841254, 44.05969186385157],
  "New York": [-76.47066452422652, 43.46023768263151],
  Ohio: [-83.39099265785002, 41.21133968821275],
  Oregon: [-121.31595292320402, 44.795322822829834],
  "Rhode Island": [-72.38932807365674, 42.50067556410718],
  "South Dakota": [-101.05782719503007, 44.907349481364736],
  Texas: [-99.82707500354051, 32.112479583609712],
  Alaska: [-113.65038482367798, 27.621127168601217],
  Hawaii: [-102.46596016824968, 25.21625734660377],
};
