import classNames from "classnames";
import React, {
  ComponentProps,
  createContext,
  forwardRef,
  useContext,
} from "react";
import { ArrowLightLine } from "components/icons/ArrowLightLine";

type TableContextType = {
  layout?: "table" | "flex" | "fixed";
  size?: "small" | "regular" | "large";
  isLoading?: boolean;
};

const TableContext = createContext<TableContextType>({
  layout: "table",
  size: "regular",
  isLoading: false,
});

const Head = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
) => (
  <thead
    {...props}
    className={classNames(
      "t-rounded-t-lg t-text-text-30 t-bg-surface t-border-0 t-border-solid t-border-neutral-30 t-border-b t-sticky t-top-0 t-z-table-head " +
        props.className || ""
    )}
  />
);

const HeadCell = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >
) => {
  const { size } = useContext(TableContext);

  return (
    <th
      className={classNames("t-text-subtext-sm t-uppercase", {
        "t-p-2": size === "small",
        "t-p-4": size === "regular",
      })}
      {...props}
    />
  );
};

const Row = forwardRef<
  HTMLTableRowElement,
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > & { onRowClick?: () => void }
>((props, ref) => {
  const { layout } = useContext(TableContext);

  return (
    <tr
      onClick={props.onRowClick}
      {...props}
      ref={ref}
      className={classNames(
        "t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body " +
          props.className || "",
        {
          "t-flex t-items-center": layout === "flex",
          "hover:t-bg-surface-lighter-grey t-cursor-pointer": Boolean(
            props.onRowClick
          ),
        }
      )}
    />
  );
});

const HeadRow = forwardRef<HTMLTableRowElement, ComponentProps<typeof Row>>(
  (props, ref) => {
    return <Row ref={ref} className="!t-border-none" {...props} />;
  }
);

const BodyWrapper = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
) => <div className="t-overflow-auto" {...props} />;

const Body = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
) => <tbody {...props}></tbody>;

const Container = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  > &
    TableContextType
) => {
  return (
    <TableContext.Provider
      value={{
        layout: props.layout || "table",
        size: props.size || "small",
        isLoading: props.isLoading,
      }}
    >
      <div className="t-h-full t-w-full t-overflow-x-auto" {...props} />
    </TableContext.Provider>
  );
};

const Cell = ({
  children,
  ...props
}: React.PropsWithChildren<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >
>) => {
  const { size, isLoading } = useContext(TableContext);

  return (
    <td
      className={classNames({
        "t-p-2": size === "small",
        "t-p-4": size === "regular",
      })}
      {...props}
    >
      {isLoading ? (
        <div className="t-bg-neutral-10 t-rounded-md t-my-2 t-h-5 t-animate-pulse"></div>
      ) : (
        children
      )}
    </td>
  );
};

const Content = (
  props: React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
) => {
  const { layout } = useContext(TableContext);
  return (
    <table
      className={classNames("t-w-full", props.className, {
        "t-table-fixed": layout === "fixed",
      })}
      {...props}
    />
  );
};

const HeadCellSort = ({
  nextSortOrder = "asc",
  isSorted,
}: {
  nextSortOrder?: "asc" | "desc" | false;
  isSorted?: "asc" | "desc" | false;
}) => {
  return (
    <span
      className={classNames(
        "t-flex group-hover:t-visible group-hover:t-opacity-100 t-transition-opacity",
        {
          "t-invisible t-opacity-0": !isSorted,
          "t-rotate-180": nextSortOrder === "asc",
        }
      )}
    >
      <ArrowLightLine />
    </span>
  );
};

const Table = {
  Container,
  Content,
  Body,
  BodyWrapper,
  Row,
  Head,
  HeadCell,
  Cell,
  HeadCellSort,
  HeadRow,
};

export default Table;
