export const LogoutIcon = ({ color = "#110733" }: { color: string }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1056_21255)">
        <path
          d="M8.625 3.61633H4.25C4.08424 3.61633 3.92527 3.68218 3.80806 3.79939C3.69085 3.9166 3.625 4.07557 3.625 4.24133V16.7413C3.625 16.9071 3.69085 17.0661 3.80806 17.1833C3.92527 17.3005 4.08424 17.3663 4.25 17.3663H8.625"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.625 10.4913H17.375"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 7.36633L17.375 10.4913L14.25 13.6163"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1056_21255">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.491333)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
