import React from "react";

export const OutlinedPaperPlaneRight = ({
  color = "currentColor",
  size = "20",
}: {
  color?: string;
  size?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.1484 9.45313L3.95314 2.06251C3.84199 2.0011 3.71477 1.97501 3.58843 1.98771C3.46208 2.0004 3.3426 2.05128 3.24589 2.13357C3.14918 2.21586 3.07982 2.32565 3.04707 2.44834C3.01431 2.57102 3.01969 2.70077 3.06251 2.82032L5.54689 9.78907C5.60162 9.92437 5.60162 10.0756 5.54689 10.2109L3.06251 17.1797C3.01969 17.2992 3.01431 17.429 3.04707 17.5517C3.07982 17.6744 3.14918 17.7842 3.24589 17.8664C3.3426 17.9487 3.46208 17.9996 3.58843 18.0123C3.71477 18.025 3.84199 17.9989 3.95314 17.9375L17.1484 10.5469C17.2461 10.4928 17.3276 10.4136 17.3843 10.3174C17.441 10.2213 17.4709 10.1116 17.4709 10C17.4709 9.88836 17.441 9.77876 17.3843 9.68258C17.3276 9.58641 17.2461 9.50718 17.1484 9.45313V9.45313Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.625 10H10.625"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
