import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PatternInput } from "components/PatternInput/PatternInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { ASSOCIATED_TYPE } from "dictionaries";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { newAccount } from "formValidations/coaCreation";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  useAddTransactionCategoryMutation,
  useGetAutoGenerateNextAvailableIdentiferQuery,
  useGetDefaultCategoryTagQuery,
  useGetStartingDateQuery,
  useGetTransactionCategoryQuery,
} from "store/apis/chartOfAccounts";
import { TxnCategories } from "types/Models/books";
import { associatedReport } from "utils/associatedReport";
import { CategoryTag } from "./EditCategory";

export const DateSelector = ({
  isDisabled = false,
}: {
  isDisabled?: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext<{
    start_date: string;
    dateSelector: string;
  }>();

  const handleChange = ({ value }: any) => {
    switch (value) {
      case "yearBeginning": {
        setFieldValue("start_date", dayjs().startOf("year").format(YYYY_MM_DD));
        return;
      }
      case "monthBeginning": {
        setFieldValue(
          "start_date",
          dayjs().startOf("month").format(YYYY_MM_DD)
        );
        return;
      }

      case "today": {
        setFieldValue("start_date", dayjs().format(YYYY_MM_DD));
        return;
      }

      case "custom": {
        setFieldValue("dateSelector", "custom");
        setFieldValue("start_date", "");
        return;
      }
    }
  };

  return (
    <>
      {values.dateSelector !== "custom" ? (
        <Combobox
          isDisabled={isDisabled}
          components={{ ClearIndicator: () => null }}
          backspaceRemovesValue={false}
          menuPortalTarget={document.getElementById("root")}
          name="dateSelector"
          label="Starting date"
          withForm
          placeholder="Select date"
          onChange={handleChange}
          options={[
            { label: "Beginning of the year", value: "yearBeginning" },
            { label: "Beginning of the month", value: "monthBeginning" },
            { label: "Today", value: "today" },
            { label: "Custom", value: "custom" },
          ]}
          block
        />
      ) : (
        <Field name="start_date">
          {({ field }: FieldProps) => {
            return (
              <DateInput
                disabled={isDisabled}
                {...field}
                label="Starting date"
                placeholder="Select date"
                onDateChange={(date) => {
                  if (!date) {
                    setFieldValue("dateSelector", "");
                  }
                  setFieldValue(
                    "start_date",
                    date ? dayjs(date).format(YYYY_MM_DD) : ""
                  );
                }}
                block
              />
            );
          }}
        </Field>
      )}
    </>
  );
};

export const AddNewAccount = ({
  showAddAccount,
  setShowAddAccount,
  openPreviousModal,
  parentId,
  onSuccess,
  categoryName,
}: {
  showAddAccount: boolean;
  setShowAddAccount: (newState: React.SetStateAction<boolean>) => void;
  openPreviousModal: () => void;
  parentId: string;
  categoryName?: string;
  onSuccess?: ({
    uuid,
    name,
    category_type,
  }: Pick<TxnCategories, "uuid" | "name" | "category_type">) => void;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [submitCount, setSubmitCount] = useState(0);
  const [createTransactionCategory, { isLoading }] =
    useAddTransactionCategoryMutation();

  const { data: selectedParentCategoryDetails, isFetching } =
    useGetTransactionCategoryQuery(
      { categoryId: parentId, entityId, groupId },
      { skip: !parentId || !entityId || !groupId }
    );

  const { data: { identifier: availableIdentifier } = { identifier: 0 } } =
    useGetAutoGenerateNextAvailableIdentiferQuery(
      { groupId, entityId, parentId },
      {
        skip: !parentId || !entityId || !groupId,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: { default_tag } = {} } = useGetDefaultCategoryTagQuery(
    {
      entityId,
      parentId,
    },
    { skip: !entityId || !parentId }
  );

  const { data: startDateData } = useGetStartingDateQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { start_date } = startDateData || {};

  const createAccountCategory = async (values: {
    parent_uuid: string;
    account_name: string;
    account_id: string;
    description: string;
    start_date: string;
    opening_balance: string;
    category_tag: string;
  }) => {
    const payload = {
      parent_uuid: values.parent_uuid,
      name: values.account_name,
      identifier: Number(values.account_id),
      description: values.description,
      start_date: values.start_date,
      opening_balance: Number(values.opening_balance),
      category_tag: values.category_tag,
    };
    try {
      const { uuid, name, category_type } = await createTransactionCategory({
        groupId,
        entityId,
        payload,
      }).unwrap();
      onSuccess?.({ uuid, name, category_type });
      successToast({ title: "Category added" });
      setShowAddAccount(false);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onBack = () => {
    setShowAddAccount(false);
    openPreviousModal();
  };

  const {
    name: parent_account,
    identifier_prefix,
    uuid: parent_uuid,
  } = selectedParentCategoryDetails || {};

  const { isBalanceSheetAssociated, isIncomeStatementAssociated } =
    associatedReport(identifier_prefix!);

  return (
    <Modal.Root open={showAddAccount} onOpenChange={setShowAddAccount}>
      <Formik
        initialValues={{
          parent_uuid: parent_uuid || "",
          parent_account: parent_account || "",
          account_id_prefix: identifier_prefix,
          type: ASSOCIATED_TYPE[identifier_prefix!] || "",
          account_name: categoryName || "",
          account_id: availableIdentifier ? availableIdentifier.toString() : "",
          start_date: start_date || "",
          opening_balance: "0",
          description: "",
          dateSelector: "",
          isBalanceSheetAssociated,
          category_tag: default_tag || "",
        }}
        onSubmit={createAccountCategory}
        validationSchema={newAccount}
        validateOnBlur={submitCount > 0 ? true : false}
        validateOnChange={submitCount > 0 ? true : false}
        enableReinitialize
      >
        {({ isSubmitting, values, submitForm, setFieldValue }) => (
          <Form className="all:unset">
            <Modal.Content>
              {isFetching ? (
                <Loader />
              ) : (
                <>
                  <Modal.Header>
                    <Modal.Title>Add New Category</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>

                  <Modal.Body>
                    <div className="t-flex t-flex-col t-gap-5">
                      <TextInput
                        name="parent_account"
                        label="Selected parent category"
                        disabled
                      />
                      <TextInput name="account_name" label="Category name" />
                      <div className="t-flex t-gap-4">
                        <div className="t-w-1/2">
                          <PatternInput
                            fieldProps={{
                              name: "account_id",
                              value: values.account_id,
                            }}
                            patternProps={{
                              format: `${values.account_id_prefix}####`,
                              mask: "_",
                              allowEmptyFormatting: true,
                            }}
                            label="Category ID"
                          />
                        </div>
                        <div className="t-w-1/2">
                          <TextInput name="type" label="Type" disabled />
                        </div>
                      </div>
                      <TextInput
                        name="description"
                        label="Description/explanation of category (optional)"
                      />
                      {isBalanceSheetAssociated && (
                        <div className="t-flex t-gap-4">
                          <div className="t-w-1/2">
                            <Field name="start_date">
                              {({ field }: FieldProps) => {
                                return (
                                  <DateInput
                                    disabled
                                    {...field}
                                    label="Starting date"
                                    placeholder="Select date"
                                    block
                                  />
                                );
                              }}
                            </Field>
                          </div>
                          <div className="t-w-1/2">
                            <PriceInput
                              disabled={!Boolean(values.start_date)}
                              name="opening_balance"
                              label={`Account balance as on ${
                                values.start_date
                                  ? dayjs(values.start_date)
                                      .subtract(1, "days")
                                      .format(DD_MMM_YYYY)
                                  : "_"
                              }`}
                            />
                          </div>
                        </div>
                      )}

                      <CategoryTag parentId={parent_uuid!} />
                      <div className="t-flex t-flex-col">
                        <Label>Associated Reports</Label>
                        {isBalanceSheetAssociated && (
                          <Tag tagType="yellow">Balance Sheet</Tag>
                        )}
                        {isIncomeStatementAssociated && (
                          <Tag tagType="orange">Income Statement</Tag>
                        )}
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="t-flex t-justify-end t-gap-3">
                    <Button onClick={onBack} type="reset">
                      Back
                    </Button>
                    <Button
                      type="submit"
                      customType="primary"
                      isLoading={isLoading}
                      disabled={isLoading || isSubmitting}
                      onClick={() => {
                        submitForm();
                        setSubmitCount((prev) => prev + 1);
                      }}
                    >
                      Create
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
