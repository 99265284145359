import { FileObject } from "types/Models/fileObject";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getPreviewUrl: build.query<
      {
        download_url: string;
        preview_url: string;
        timestamp: string;
      },
      { groupId: string; fileId: any }
    >({
      query: ({ groupId, fileId }) => ({
        url: `/api/inkle/documents/group/${groupId}/file/${fileId}/url/`,
      }),
    }),
    getFolderOrFile: build.query<
      FileObject,
      { groupId: string; fileId: string }
    >({
      query: ({ groupId, fileId }) => ({
        url: `/api/inkle/documents/group/${groupId}/file/${fileId}/details/`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPreviewUrlQuery,
  useLazyGetPreviewUrlQuery,
  useGetFolderOrFileQuery,
  useLazyGetFolderOrFileQuery,
} = extendedApi;
