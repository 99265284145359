import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import { TableUI } from "components/design/TableUI";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Link } from "components/DesignSystem/Link/Link";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetAllFSPGroupsQuery } from "store/apis/practiceCrm";
import { FSP_GROUP } from "types/Models/group";
import { debounce } from "utils/debouncing";
import { InvitePracticeCrm } from "./InvitePracticeCrm";
import { PracticeCrmFilter } from "./PracticeCrmFilter";

export const PracticeCrm = () => {
  const query = useQuery();
  const { update } = useUpdateQuery();
  const pageNum = Number(query.get("page") || 1);
  const [searchQuery, setSearchQuery] = useState("");
  const { isForeignCA } = useRoleBasedView();
  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      UNPAID: false,
      PAID: false,
      COUNTRY: [] as string[],
    },
  });
  const invitePracticeModal = useModal();

  const filterAsArg = {
    paid: filterValues.PAID || null,
    unpaid: filterValues.UNPAID || null,
    country_codes: filterValues.COUNTRY.join(",") || null,
  };

  const { data: fspGroups, isLoading } = useGetAllFSPGroupsQuery(
    {
      page_num: pageNum,
      search_term: searchQuery || null,
      ...filterAsArg,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    foreign_service_providers = [],
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 20,
  } = fspGroups || {};

  const onPageNumChange = (currentPage: number) => {
    update({ query: "page", value: currentPage });
  };

  const {
    setTotalPage,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
  } = usePagination({
    pageNumber: pageNum,
    onPageNumChange,
  });

  useEffect(() => {
    setTotalPage(total_pages);
  }, [setTotalPage, total_pages]);

  const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "page", value: 1 });
    setSearchQuery(e.target.value);
  });

  const columnHelper = createColumnHelper<FSP_GROUP>();

  const columns = [
    columnHelper.accessor("service_team_name", {
      header: "SERVICE TEAM",
      size: 20,
      cell: (info) => {
        const rowData = info.row.original;
        const name = info.getValue();

        if (!name) {
          return <div className="t-text-text-60 t-text-body">-</div>;
        }
        return (
          <Link
            to={
              isForeignCA
                ? `/practice/practicecrm/${rowData.uuid}`
                : `/admin/practicecrm/${rowData.uuid}`
            }
            className="!t-underline t-truncate t-max-w-64 t-text-purple"
            target="_blank"
          >
            {name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("country.code", {
      header: "COUNTRY",
      size: 20,
      cell: (info) => {
        const countryCode = info.getValue();
        if (!countryCode) {
          return <>-</>;
        }

        return (
          <ToolTip text={info.row.original.country?.name}>
            <span>
              <ReactCountryFlag
                style={{
                  width: "24px",
                  height: "16px",
                }}
                svg
                countryCode={countryCode!}
              />
            </span>
          </ToolTip>
        );
      },
    }),
    columnHelper.accessor("admin_details.name", {
      header: "ADMIN DETAILS",
      size: 30,
      cell: (info) => {
        const rowData = info.row.original;
        const adminName = info.getValue();

        if (!adminName) {
          return <div className="t-text-text-60 t-text-body">-</div>;
        }

        return (
          <div className="t-flex t-flex-col t-gap-">
            <div className="t-text-text-60 t-text-body">{adminName}</div>
            <div className="t-text-text-60 t-text-body-sm">
              {rowData.admin_details?.email}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("number_of_clients", {
      header: "NO. OF CLIENTS",
      size: 10,
      cell: (info) => (
        <div className="t-text-text-60 t-text-body">{info.getValue()}</div>
      ),
    }),
    columnHelper.accessor("due_amount", {
      header: () => (
        <span className="t-flex t-justify-end t-ml-auto t-mr-8">
          AMOUNT DUE
        </span>
      ),
      size: 20,
      cell: (info) => {
        const dueAmount = info.getValue();
        if (!dueAmount) {
          return <div className="t-flex t-justify-end t-mr-8">-</div>;
        }

        return (
          <div className="t-flex  t-justify-end t-whitespace-nowrap t-mr-8 t-text-red">
            <AmountSuperScript amount={dueAmount} />
          </div>
        );
      },
    }),
  ];

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const table = useReactTable({
    data: foreign_service_providers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <DashboardContainer className="t-h-full">
      <DashboardContainer.Header>
        <Header
          title="Practice CRM"
          bottom={
            <div className="t-flex t-flex-col t-gap-3 t-mb-4 t-mt-2">
              <div className="t-flex t-gap-4 t-items-center t-justify-between">
                <div className="t-w-1/2">
                  <Search onChange={onChange} placeholder="Search" block />
                </div>
                <Button
                  customType="primary"
                  size="small"
                  onClick={invitePracticeModal.open}
                >
                  Invite practice
                </Button>
              </div>
              <div className="t-flex t-gap-4 t-items-center t-justify-between">
                <PracticeCrmFilter
                  updateFilter={updateFilter}
                  values={filterValues}
                />
                <Pagination
                  {...paginationData}
                  goToFirstPage={goToFirstPage}
                  goToPrevPage={goToPrevPage}
                  goToNextPage={goToNextPage}
                  goToLastPage={goToLastPage}
                />
              </div>
            </div>
          }
        />
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-pl-6 t-pt-2">
        <TableUI table={table}>
          {({ row }) => (
            <Table.Row key={row.id} className="hover:t-bg-surface-lighter-grey">
              {row.getVisibleCells().map((cell) => {
                return (
                  <Table.Cell
                    key={cell.id}
                    style={{
                      width: `${cell.column.getSize()}%`,
                      padding: "12px 8px",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          )}
        </TableUI>
      </DashboardContainer.Content>
      {invitePracticeModal.isOpen && (
        <InvitePracticeCrm
          isOpen={invitePracticeModal.isOpen}
          close={invitePracticeModal.close}
        />
      )}
    </DashboardContainer>
  );
};
