import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as APPS from "constants/apps";

export type ALL_APPS = (typeof APPS)[keyof typeof APPS];

export type SidebarState = {
  [key in ALL_APPS]: {
    isExpanded: boolean;
    isHovered: boolean;
    isExpandable: boolean;
  };
};

const initialState: SidebarState = Object.entries(APPS).reduce(
  (acc, [key, value]) => {
    const appName = key as ALL_APPS;
    acc[appName] = {
      isExpanded: localStorage.getItem(`${appName}_SIDEBAR`) === "true",
      isExpandable: true,
      isHovered: false,
    };
    return acc;
  },
  {} as SidebarState
);

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    expandSidebar: (state, action: PayloadAction<{ name: ALL_APPS }>) => {
      localStorage.setItem(
        `${action.payload.name}_SIDEBAR`,
        JSON.stringify(true)
      );
      state[action.payload.name].isExpanded = true;
    },
    collapseSidebar: (state, action: PayloadAction<{ name: ALL_APPS }>) => {
      localStorage.setItem(
        `${action.payload.name}_SIDEBAR`,
        JSON.stringify(false)
      );
      state[action.payload.name].isExpanded = false;
    },
    updateCollapsedSidebarHover: (
      state,
      action: PayloadAction<{ name: ALL_APPS; isHovered: boolean }>
    ) => {
      state[action.payload.name].isHovered = action.payload.isHovered;
    },
    setISidebarExpandable: (
      state,
      action: PayloadAction<{ name: ALL_APPS; isExpandable: boolean }>
    ) => {
      state[action.payload.name].isExpandable = action.payload.isExpandable;
    },
  },
});

export const {
  expandSidebar,
  collapseSidebar,
  updateCollapsedSidebarHover,
  setISidebarExpandable,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
