import React, { useState } from "react";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowRight } from "components/icons/ArrowRight";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { useDispatch } from "react-redux";
import {
  resetAllAddonDataPayload,
  setAddonPayload,
  setAddonType,
  setOpenCheckoutModal,
} from "store/slices/addons";
import Modal from "components/DesignSystem/Modal/Modal";
import { Price } from "components/Price";
import { Button } from "components/DesignSystem/Button/Button";
import { BOOKKEEPING_SOFTWARE } from "constants/addons";
import { Form, Formik } from "formik";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { useAddProductToCartMutation } from "store/apis/products";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const BooksSoftware = () => {
  const dispatch = useDispatch();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonData?.subscription_type === BOOKKEEPING_SOFTWARE
  );

  const group = useCurrentGroup();
  const { uuid: groupId } = group;
  const entityId = useCurrentEntityId();

  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const [addToCart, { isLoading: addingToCart }] =
    useAddProductToCartMutation();

  const {
    dropdownContent = [],
    subscription_name,
    billing_cycle,
  } = addonData || {};

  const handleAddonNext = async (values: {
    subscriptionData: {
      uuid: string;
      subscriptionName: string;
      amount: string;
    };
  }) => {
    const subscription = subscriptions?.find(
      (s) => s.uuid === values?.subscriptionData?.uuid
    );

    if (isCartSubscriptionActive && groupId && values && subscription) {
      await addToCart({
        groupId: groupId,
        entityId,
        payload: {
          product_id: values?.subscriptionData?.uuid,
          product_content_type_id: subscription.product_content_type_id,
          selected_tier_amount: values?.subscriptionData?.amount,
        },
      });

      dispatch(setAddonType(""));
      setShowAddonModal(false);
      return;
    }
    dispatch(
      setAddonPayload({
        title: values?.subscriptionData?.subscriptionName,
        amount: values?.subscriptionData?.amount,
        uuid: values?.subscriptionData?.uuid,
        type: addonData?.subscription_type,
      })
    );
    dispatch(setUsableCredit(false));
    dispatch(setPaymentTitle(values?.subscriptionData?.subscriptionName));
    dispatch(setAddonType(""));
    dispatch(setOpenCheckoutModal());
    setShowAddonModal(false);
  };

  const onClose = () => {
    setShowAddonModal(false);
    dispatch(setAddonType(""));
    dispatch(resetAllAddonDataPayload());
  };

  //@ts-ignore
  const quickBooksArray = dropdownContent?.[0] || [];
  //@ts-ignore
  const zohoBooksArray = dropdownContent?.[1] || [];

  return (
    <Modal.Root open={showAddonModal} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{subscription_name}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            selectedSoftware: "",
            subscriptionData: { subscriptionName: "", amount: "", uuid: "" },
          }}
          onSubmit={handleAddonNext}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form className="all:unset">
              <Modal.Body>
                <div className="t-flex t-flex-col t-gap-4">
                  <p className="t-text-text-60 t-text-body t-mb-0">
                    Select an accounting software: QuickBooks USA or Zoho Books
                    USA. We recommend QBO for active US entities, and Zoho Books
                    if you want something free. See a full comparison&nbsp;
                    <ConditionalLink
                      to="https://docs.google.com/spreadsheets/d/15F5-szKJhZ5KCxS2H0CI6hiH7hLOv3ZGa9sNq3OUx5E/edit#gid=0"
                      className="all:unset t-text-blue-70 !t-underline t-cursor-pointer"
                    >
                      here
                    </ConditionalLink>
                    &nbsp;or ping us for advice.
                  </p>

                  <div className="t-w-[280px]">
                    <DropDown.Root>
                      <DropDown.Trigger asChild>
                        <div className="t-flex t-flex-col t-gap-[6px]">
                          <div className="t-font-sans t-text-caption t-text-neutral-80">
                            Software
                          </div>
                          <div className="secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-2.5 t-text-body t-font-medium t-drop-shadow-i-dropdown">
                            <>
                              <div className="t-flex t-items-center t-justify-between t-w-full">
                                <div className="t-text-body">
                                  {values?.selectedSoftware ||
                                    "Select software"}
                                </div>
                                <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                                  <ArrowRight
                                    stroke="2.5"
                                    color="currentColor"
                                  />
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </DropDown.Trigger>
                      <DropDown.Portal>
                        <DropDown.Content
                          sideOffset={8}
                          side="bottom"
                          className="t-w-[280px]"
                        >
                          <>
                            <DropDown.Item
                              onSelect={() => {
                                setFieldValue("selectedSoftware", "QuickBooks");
                                setFieldValue("subscriptionData", "");
                              }}
                            >
                              QuickBooks
                            </DropDown.Item>
                            <DropDown.Item
                              onSelect={() => {
                                setFieldValue("selectedSoftware", "Zoho Books");
                                setFieldValue("subscriptionData", "");
                              }}
                            >
                              Zoho Books
                            </DropDown.Item>
                          </>
                        </DropDown.Content>
                      </DropDown.Portal>
                    </DropDown.Root>
                  </div>
                  <div className="t-w-full t-flex t-items-end t-gap-10">
                    <div className="t-w-[280px]">
                      <DropDown.Root>
                        <DropDown.Trigger asChild>
                          <div className="t-flex t-flex-col t-gap-[6px]">
                            <div className="t-font-sans t-text-caption t-text-neutral-80">
                              Plan
                            </div>
                            <div className="secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-2.5 t-text-body t-font-medium t-drop-shadow-i-dropdown">
                              <>
                                <div className="t-flex t-items-center t-justify-between t-w-full">
                                  <div className="t-text-body">
                                    {values?.subscriptionData?.subscriptionName
                                      ? values?.subscriptionData
                                          ?.subscriptionName
                                      : "Select plan"}
                                  </div>
                                  <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                                    <ArrowRight
                                      stroke="2.5"
                                      color="currentColor"
                                    />
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </DropDown.Trigger>
                        <DropDown.Portal>
                          <DropDown.Content
                            sideOffset={8}
                            side="bottom"
                            className="t-w-[280px] t-max-h-40 t-overflow-auto"
                          >
                            {values?.selectedSoftware === "QuickBooks"
                              ? quickBooksArray?.map(
                                  ({
                                    subscription_name,
                                    amount,
                                    uuid,
                                  }: any) => (
                                    <DropDown.Item
                                      key={uuid}
                                      onSelect={() =>
                                        setFieldValue("subscriptionData", {
                                          subscriptionName: subscription_name,
                                          amount: amount,
                                          uuid: uuid,
                                        })
                                      }
                                    >
                                      {subscription_name}
                                    </DropDown.Item>
                                  )
                                )
                              : zohoBooksArray?.map(
                                  ({
                                    subscription_name,
                                    amount,
                                    uuid,
                                  }: any) => (
                                    <DropDown.Item
                                      key={uuid}
                                      onSelect={() =>
                                        setFieldValue("subscriptionData", {
                                          subscriptionName: subscription_name,
                                          amount: amount,
                                          uuid: uuid,
                                        })
                                      }
                                    >
                                      {subscription_name}
                                    </DropDown.Item>
                                  )
                                )}
                          </DropDown.Content>
                        </DropDown.Portal>
                      </DropDown.Root>
                    </div>
                    <div>
                      <div className="t-text-text-30 t-text-body-sm">
                        Monthly subscription
                      </div>
                      <div className="t-text-body">
                        {values?.subscriptionData?.amount
                          ? `$${values?.subscriptionData?.amount}`
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="t-flex t-justify-between">
                    <div className="t-text-body-sm t-text-text-30">Total</div>
                    <div className="t-text-body">
                      {values?.subscriptionData?.amount && (
                        <Price
                          size="medium"
                          cycle={billing_cycle!}
                          amount={values?.subscriptionData?.amount}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-end t-gap-3">
                <Button type="button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  isLoading={isSubmitting}
                  disabled={!values?.subscriptionData?.amount || isSubmitting}
                >
                  Next
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
