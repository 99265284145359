import { createSelector } from "@reduxjs/toolkit";
import { FilterName } from "store/slices/generalLedger";
import { RootState } from "store/store";

const getLedgerFilter = (state: RootState) => state.ledgerFilter;

export const getLedgerFilterStatus = createSelector(
  [getLedgerFilter],
  ({ filters }) => {
    const amount = Object.values(filters).filter(
      ({ type }) => type === "amount"
    );
    const transactionDate = Object.values(filters).filter(
      ({ type }) => type === "transactionDate"
    );
    const category = Object.values(filters).filter(
      ({ type }) => type === "category"
    );
    const others = Object.values(filters).filter(
      ({ type }) => type === "others"
    );
    const accountingMethod = Object.values(filters).filter(
      ({ type }) => type === "accounting"
    );

    const appliedFiltersCount = [
      ...amount,
      ...transactionDate,
      ...others,
      ...category,
      ...accountingMethod,
    ].filter(({ value }) => {
      if (Array.isArray(value) && (value as unknown as [])?.length === 0) {
        return null;
      }
      return value;
    })?.length;

    const capsuleFilters = Object.values(filters).filter(({ value }) => {
      if (Array.isArray(value) && (value as unknown as [])?.length === 0) {
        return null;
      }
      return value;
    });

    const getFilterName = (name: string) => {
      for (let key in filters) {
        // @ts-ignore
        if (filters.hasOwnProperty(key) && filters[key].name === name) {
          return key as FilterName;
        }
      }
    };

    return {
      appliedFiltersCount,
      capsuleFilters,
      getFilterName,
      filters: {
        amount,
        transactionDate,
        others,
        category,
        accountingMethod,
      },
    };
  }
);
