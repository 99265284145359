import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { PlusIcon } from "components/icons/PlusIcon";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { FileObject } from "types/Models/fileObject";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";

const QuoteModal = ({
  userGroupId,
  last_quote_sent,
}: {
  userGroupId: string;
  last_quote_sent: FileObject;
}) => {
  const [openPreview] = useLazyGetPreviewUrlQuery();
  const quoteModalHandler = async ({
    last_quote_sent,
    groupId,
  }: {
    groupId: string;
    last_quote_sent: FileObject;
  }) => {
    try {
      const { data } = await openPreview({
        groupId,
        fileId: last_quote_sent?.uuid,
      });
      openLink(data?.preview_url);
    } catch (error) {}
  };

  return (
    <>
      <span>
        {last_quote_sent ? (
          <Button
            customType="ghost_icon"
            onClick={() => {
              quoteModalHandler({ groupId: userGroupId, last_quote_sent });
            }}
          >
            <FileIcon fileType={"PDF"} width="32px" height="32px" />
          </Button>
        ) : (
          <ConditionalLink target="_blank" to={`crm/${userGroupId}/quotes`}>
            <Button customType="icon">
              <PlusIcon color="#41395C" />
            </Button>
          </ConditionalLink>
        )}
      </span>
    </>
  );
};

export default QuoteModal;
