import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Link } from "react-router-dom";

//style
import "../../static/styles/components/autofill.css";

//components
import { Button } from "components/DesignSystem/Button/Button";
import { getAutofillFields } from "../../apis/autofill";
import { useRoleBasedView } from "hooks/useRoleBasedView";

const ignoreFields = [
  "uuid",
  "autofill_string",
  "added_by",
  "created_at",
  "title",
];

export const Autofill = ({
  label,
  autofillKey,
  groupId,
  autofillPrefix,
  autofillSuffix,
  entityId,
}) => {
  const [autofillFields, setAutofillFields] = useState([]);
  const { setFieldValue } = useFormikContext();
  const { isCustomer, isForeignCA } = useRoleBasedView();

  const onSelect = (autofillId) => {
    const autofill = autofillFields.find(({ uuid }) => uuid === autofillId);

    Object.entries(autofill).forEach(([key, value]) => {
      if (typeof value === "string" && !ignoreFields.includes(key)) {
        const updateKey = autofillSuffix
          ? `${autofillPrefix}_${key}_${autofillSuffix}`
          : `${autofillPrefix}_${key}`;
        setFieldValue(updateKey, value);
      }
    });
  };

  useEffect(() => {
    const fetchAutofillFields = async () => {
      try {
        const { data } = await getAutofillFields({
          groupId,
          autofillKey,
          entityId,
        });
        setAutofillFields(data);
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
        }
      }
    };

    fetchAutofillFields();
  }, []);

  const autofillBaseLink = isCustomer
    ? "/tax/entities/autofill"
    : isForeignCA
    ? `/practice/crm/${groupId}/autofills`
    : `/admin/crm/${groupId}/autofills`;

  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle as="div" className="button-reset p-0">
        <Button customType="primary" size="regular" type="button">
          <span className="autofill-toggle-button">
            {label}{" "}
            <i className="fas fa-angle-down autofill-toggle-button-arrow" />
          </span>
        </Button>
      </Dropdown.Toggle>

      {autofillFields.length > 0 && (
        <Dropdown.Menu>
          {autofillFields.map(({ uuid, autofill_string }) => (
            <Dropdown.Item eventKey={uuid} key={uuid}>
              {autofill_string}
            </Dropdown.Item>
          ))}
          <Dropdown.ItemText disabled>
            <Link
              to={`${autofillBaseLink}/${autofillKey}`}
              className="!t-text-purple"
            >
              Add more
            </Link>
          </Dropdown.ItemText>
        </Dropdown.Menu>
      )}

      {autofillFields.length === 0 && (
        <Dropdown.Menu>
          <Dropdown.ItemText disabled>
            Autofill is empty, go to{" "}
            <Link
              to={`${autofillBaseLink}/${autofillKey}`}
              className="text-primary"
            >
              Autofill
            </Link>{" "}
            section to add one
          </Dropdown.ItemText>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
