export const EmptyInvoiceList = () => {
  return (
    <svg
      width="151"
      height="153"
      viewBox="0 0 151 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#F3F3F5"
      />
      <g filter="url(#filter0_d_442_14140)">
        <path
          d="M118.5 35H32.5C29.7386 35 27.5 37.2386 27.5 40V145C27.5 147.761 29.7386 150 32.5 150H118.5C121.261 150 123.5 147.761 123.5 145V40C123.5 37.2386 121.261 35 118.5 35Z"
          fill="white"
        />
      </g>
      <path
        d="M65.5 50H39.5C37.8431 50 36.5 51.3431 36.5 53C36.5 54.6569 37.8431 56 39.5 56H65.5C67.1569 56 68.5 54.6569 68.5 53C68.5 51.3431 67.1569 50 65.5 50Z"
        fill="#E7E6EB"
      />
      <path
        d="M83.5 63H39.5C37.8431 63 36.5 64.3431 36.5 66C36.5 67.6569 37.8431 69 39.5 69H83.5C85.1569 69 86.5 67.6569 86.5 66C86.5 64.3431 85.1569 63 83.5 63Z"
        fill="#B8B5C2"
      />
      <path
        d="M65.5 77H39.5C37.8431 77 36.5 78.3431 36.5 80C36.5 81.6569 37.8431 83 39.5 83H65.5C67.1569 83 68.5 81.6569 68.5 80C68.5 78.3431 67.1569 77 65.5 77Z"
        fill="#E7E6EB"
      />
      <path
        d="M83.5 90H39.5C37.8431 90 36.5 91.3431 36.5 93C36.5 94.6569 37.8431 96 39.5 96H83.5C85.1569 96 86.5 94.6569 86.5 93C86.5 91.3431 85.1569 90 83.5 90Z"
        fill="#B8B5C2"
      />
      <path
        d="M65.5 104H39.5C37.8431 104 36.5 105.343 36.5 107C36.5 108.657 37.8431 110 39.5 110H65.5C67.1569 110 68.5 108.657 68.5 107C68.5 105.343 67.1569 104 65.5 104Z"
        fill="#E7E6EB"
      />
      <path
        d="M83.5 117H39.5C37.8431 117 36.5 118.343 36.5 120C36.5 121.657 37.8431 123 39.5 123H83.5C85.1569 123 86.5 121.657 86.5 120C86.5 118.343 85.1569 117 83.5 117Z"
        fill="#B8B5C2"
      />
      <defs>
        <filter
          id="filter0_d_442_14140"
          x="21.5"
          y="26"
          width="108"
          height="127"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_442_14140"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_442_14140"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
