import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InitialState = {
  addOrEditRAModal: {
    show: boolean;
    raId: string;
  };
  silder: {
    show: boolean;
    raId: string;
  };
};

const initialState: InitialState = {
  addOrEditRAModal: {
    show: false,
    raId: "",
  },
  silder: {
    show: false,
    raId: "",
  },
};

export const registerAgent = createSlice({
  name: "registerAgent",
  initialState,
  reducers: {
    openAddOrEditModal: (state, action: PayloadAction<string | undefined>) => {
      state.addOrEditRAModal = {
        show: true,
        raId: action?.payload || "",
      };
    },

    closeAddOrEditModal: (state) => {
      state.addOrEditRAModal = initialState.addOrEditRAModal;
    },

    openSlider: (state, action: PayloadAction<string | undefined>) => {
      state.silder = {
        show: true,
        raId: action?.payload || "",
      };
    },

    closeSlider: (state) => {
      state.silder = initialState.silder;
    },
  },
});

export const {
  openAddOrEditModal,
  closeAddOrEditModal,
  openSlider,
  closeSlider,
} = registerAgent.actions;

export default registerAgent.reducer;
