export const InkleBoardSymbol = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M34 34H22V22L34 34Z" fill="url(#paint0_linear_5723_7797)" />
    <path
      d="M40 16H16L22 22L34 22L34 34L40 40V16Z"
      fill="url(#paint1_linear_5723_7797)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7797"
        x1="22"
        y1="28"
        x2="34"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7797"
        x1="16"
        y1="28"
        x2="40"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
