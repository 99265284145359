import { useState } from "react";

export const useFilters = <T extends {}>({
  initialValue,
}: {
  initialValue: T;
}) => {
  const [value, setValue] = useState(initialValue);

  const updateFilter = <S extends keyof T>(name: S, newValue: T[S]) => {
    setValue((value) => ({ ...value, [name]: newValue }));
  };

  const updateFilterCallback =
    (name: Parameters<typeof updateFilter>[0]) =>
    (newValues: Parameters<typeof updateFilter>[1]) => {
      updateFilter(name, newValues);
    };

  return {
    updateFilter,
    values: value,
    getUpdateFilter: updateFilterCallback,
  };
};
