import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Chip } from "components/DesignSystem/Chips/Chips";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { Search } from "components/DesignSystem/Search/Search";
import { EmptyPerks } from "components/Illustrations/EmptyPerks";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { PerkTag, useGetAllPerkTagsQuery } from "store/apis/perksCrm";
import { useLazyGetAllDealsQuery } from "store/apis/rewards";
import { PerksResponse } from "types/Models/perks";
import { debounce } from "utils/debouncing";
import { PerkCategoryFilter } from "./perkFilter/PerkCategoryFilter";
import PerksCard from "./perksCard";
import PerksModal from "./perksModal";

export const Perks = () => {
  usePageTitle("Perks");
  const { url } = useRouteMatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const [query, setQuery] = useState("");
  const queryParam = useQuery();
  const { update } = useUpdateQuery();
  const currentSort = queryParam.get("sort") || "RECENTLY_ADDED";

  const categories = queryParam.get("category")?.split(",").filter(Boolean);

  const updateCategories = (categoryId: string) => {
    const categories = queryParam.get("category")
      ? queryParam.get("category")?.split(",") || []
      : [];
    const index = categories.indexOf(categoryId);
    if (index > -1) {
      categories.splice(index, 1);
    } else {
      categories.push(categoryId);
    }
    update({ query: "category", value: categories.join(",") });
  };

  const updateSort = (sort: string) => {
    update({ query: "sort", value: sort });
  };

  const { data: perkCategories } = useGetAllPerkTagsQuery();

  const {
    data: perksData,
    isLoading,
    loadNext,
    pageNum,
  } = usePaginatedQuery<{
    perks: PerksResponse[];
  }>(
    // @ts-ignore
    useLazyGetAllDealsQuery,
    "perks",
    {
      groupId: groupId,
      perk_tag_uuids: categories?.join(","),
      search_term: query,
      sort_by: currentSort,
    },
    ["groupId"]
  );

  const { perks, total_pages = 1 } = perksData || {};

  const setSearchQuery = debounce((e: any) => {
    const { value } = e.target;
    setQuery(value);
  });

  const categoriesIdMap = perkCategories?.reduce((acc, category) => {
    acc[category.uuid] = category;
    return acc;
  }, {} as Record<string, PerkTag | undefined>);

  return (
    <>
      <div className="t-w-full t-h-full">
        <div className="t-w-full t-h-full t-relative">
          <div
            id="scrollableDiv"
            className={classNames("t-overflow-y-auto t-h-full")}
          >
            <div className="t-sticky t-top-0 t-bg-white t-z-filter-head">
              <div className="t-flex t-w-full t-items-center t-justify-between t-gap-3 t-pb-6 t-pr-2 sm:t-flex-wrap">
                <Search
                  placeholder="Search for a perk"
                  onChange={setSearchQuery}
                  customSize="regular"
                  className="t-w-2/4"
                />
                <div className="t-flex t-gap-2 t-items-center">
                  <ConditionalLink to={`${url}/create`}>
                    <Button size="small" customType="primary">
                      Submit your perk
                    </Button>
                  </ConditionalLink>
                </div>
              </div>
            </div>
            <div className="t-flex t-gap-8 t-items-start">
              <div className="t-w-64 t-shrink-0 t-bg-surface-lighter-grey t-py-1.5 t-rounded">
                <RadioGroup.Root
                  className="t-mb-6"
                  onValueChange={updateSort}
                  value={currentSort}
                >
                  <div className="t-py-1.5 t-pt-6 t-px-3">
                    <RadioGroup.Item value="RECENTLY_ADDED">
                      <span className="t-text-body t-text-text-30 t-flex t-items-center t-h-full">
                        Recently added
                      </span>
                    </RadioGroup.Item>
                  </div>
                  <div className="t-py-1.5 t-px-3">
                    <RadioGroup.Item value="MOST_POPULAR">
                      <span className="t-text-body t-text-text-30 t-flex t-items-center t-h-full">
                        Popular
                      </span>
                    </RadioGroup.Item>
                  </div>
                  <div className="t-py-1.5 t-px-3">
                    <RadioGroup.Item value="NAME_ASCENDING">
                      <span className="t-text-body t-text-text-30 t-flex t-items-center t-h-full">
                        Ascending (A-Z)
                      </span>
                    </RadioGroup.Item>
                  </div>
                  <div className="t-py-1.5 t-px-3">
                    <RadioGroup.Item value="NAME_DESCENDING">
                      <span className="t-text-body t-text-text-30 t-flex t-items-center t-h-full">
                        Descending (Z-A)
                      </span>
                    </RadioGroup.Item>
                  </div>
                </RadioGroup.Root>

                <div className="t-m-0 t-px-3 t-mb-1">
                  {categories && categories?.length > 0 ? (
                    <Checkbox
                      onChange={() =>
                        update({ query: "category", value: null })
                      }
                      checked={categories?.length === perkCategories?.length}
                      indeterminate={
                        perkCategories &&
                        categories?.length > 0 &&
                        categories?.length < perkCategories?.length
                      }
                      name="ALL_CATEGORY"
                      label={
                        <span className="t-text-subtitle-sm">Categories</span>
                      }
                    />
                  ) : (
                    <span className="t-text-subtitle-sm">Categories</span>
                  )}
                </div>

                <div>
                  <PerkCategoryFilter
                    selected={categories || []}
                    onSelect={updateCategories}
                  />
                </div>
              </div>

              <div className="t-grow  t-flex t-flex-col t-gap-4">
                <div className="t-flex t-gap-3 t-flex-wrap">
                  {categories?.map(
                    (category) =>
                      categoriesIdMap?.[category] && (
                        <Chip
                          isActive
                          onClose={() => updateCategories(category)}
                          key={category}
                        >
                          {categoriesIdMap?.[category]?.title}
                        </Chip>
                      )
                  )}
                </div>
                {isLoading && <Loader />}
                {perks?.length === 0 && (
                  <div className="t-flex t-items-center t-justify-center t-flex-col t-h-96 t-gap-3">
                    <EmptyPerks />
                    <p>No perks found</p>
                    {/* <Button>Suggest a perk</Button> */}
                  </div>
                )}
                {perks && perks?.length > 0 && (
                  <InfiniteScroll
                    dataLength={perks.length}
                    style={{ flexGrow: 1 }}
                    next={loadNext}
                    hasMore={pageNum < total_pages}
                    scrollableTarget="scrollableDiv"
                    loader={"Loading more perks.."}
                    className={classNames(
                      "perksCardContainer t-grid t-gap-5 2xl:t-grid-cols-3 t-grid-cols-2"
                    )}
                  >
                    {perks?.map((perk) => (
                      <PerksCard
                        perk={perk}
                        isPreview={false}
                        key={perk?.perk_id}
                      />
                    ))}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route exact path={`${url}/:perkSlug`}>
          <PerksModal parentUrl={url} />
        </Route>
      </Switch>
    </>
  );
};
