import React, { ReactNode, useEffect, useRef, useState } from "react";
import ScrollBackBtn from "static/images/CaretCircleLeft.svg";
import ScrollNextBtn from "static/images/CaretCircleRight.svg";
import { Button } from "components/DesignSystem/Button/Button";
import classNames from "classnames";

const HorizontalScroll = ({
  children,
  scrollWidth = 240,
  maxWidth,
}: {
  children: ReactNode;
  scrollWidth?: number;
  maxWidth: string;
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showScrollBackBtn, setShowScrollBackBtn] = useState(false);
  const [showScrollNextBtn, setShowScrollNextBtn] = useState(false);

  useEffect(() => {
    handleScroll();
  }, [children]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= scrollWidth;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += scrollWidth;
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      if (scrollContainerRef.current.scrollLeft === 0) {
        setShowScrollBackBtn(false);
      } else {
        setShowScrollBackBtn(true);
      }

      if (
        scrollContainerRef.current.scrollWidth -
          scrollContainerRef.current.clientWidth ===
        scrollContainerRef.current.scrollLeft
      ) {
        setShowScrollNextBtn(false);
      } else {
        setShowScrollNextBtn(true);
      }
    }
  };

  return (
    <div
      className={classNames("t-flex  t-w-fit t-items-center t-gap-2", {
        [maxWidth]: true,
      })}
    >
      {showScrollBackBtn && (
        <Button customType="ghost_icon" onClick={scrollLeft}>
          <img src={ScrollBackBtn} alt="ScrollBackBtn" />
        </Button>
      )}
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className="t-mt-4 t-w-full t-overflow-x-auto t-scroll-smooth t-whitespace-nowrap no-scrollbar"
      >
        {children}
      </div>
      {showScrollNextBtn && (
        <Button customType="ghost_icon" onClick={scrollRight}>
          <img src={ScrollNextBtn} alt="ScrollNextBtn" />
        </Button>
      )}
    </div>
  );
};

export default HorizontalScroll;
