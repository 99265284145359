import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { usePageTitle } from "hooks/usePageTitle";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { TicketsList } from "./TicketsList";

export const Tickets = () => {
  usePageTitle("Tickets");
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const isResolvedPage = pathname.includes("/resolved");

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Tickets"
          bottom={
            <Tab.Root defaultValue={pathname} value="MATCH">
              <Tab.List>
                <Tab.NavTrigger
                  exact
                  value={!isResolvedPage ? "MATCH" : ""}
                  to={`${url}${search}`}
                >
                  Unresolved
                </Tab.NavTrigger>
                <Tab.NavTrigger
                  exact
                  value={isResolvedPage ? "MATCH" : ""}
                  to={`${url}/resolved${search}`}
                >
                  Resolved
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      }
    >
      <Switch>
        <Route path={`${path}/resolved`}>
          <TicketsList resolved_status={true} />
        </Route>
        <Route path={path}>
          <TicketsList resolved_status={false} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};
