import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { SliderNavigation } from "components/DesignSystem/SliderNavigation/SliderNavigation";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { Cross } from "components/icons/Cross";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { RemainingLife } from "pages/Books/FixedAsset/FixedAsset";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetFixedAssetByIdQuery } from "store/apis/fixedAsset";
import { closeSlider, openSlider } from "store/slices/fixedAsset";
import { RootState } from "store/store";

const InfoItem = ({
  label,
  children,
}: {
  label: ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <Label className="!t-pb-0">{label}</Label>
      <span className="t-text-subtext">{children}</span>
    </div>
  );
};

const useSilderFixedAsset = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { isSliderOpen, previewFixedAssetId } = useSelector(
    (store: RootState) => store.fixedAsset
  );

  return useGetFixedAssetByIdQuery(
    { entityId, groupId, fixedAssetId: previewFixedAssetId },
    { skip: !groupId || !entityId || !previewFixedAssetId || !isSliderOpen }
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const { data } = useSilderFixedAsset();
  const { asset_name } = data || {};

  const { previewFixedAssetId, allFixedAssetIds } = useSelector(
    (store: RootState) => store.fixedAsset
  );

  const close = () => {
    dispatch(closeSlider());
  };

  const currentRuleIndex = allFixedAssetIds.findIndex(
    (ruleId) => ruleId === previewFixedAssetId
  );

  const dataLength = allFixedAssetIds.length;

  const goToNext = () => {
    if (currentRuleIndex + 1 < allFixedAssetIds.length) {
      dispatch(openSlider(allFixedAssetIds[currentRuleIndex + 1]));
    }
  };

  const goToPrev = () => {
    if (currentRuleIndex > 0) {
      dispatch(openSlider(allFixedAssetIds[currentRuleIndex - 1]));
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          goToPrev();
          return;

        case "ArrowRight":
          goToNext();
          return;

        default:
          return;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentRuleIndex]);

  return (
    <div className="t-w-full t-px-5 t-py-3 t-border t-border-solid t-border-b t-border-l-0 t-border-t-0 t-border-r-0 t-border-neutral-0 t-bg-surface t-flex t-justify-between t-sticky t-top-0 t-z-header">
      <div className="t-text-subtitle-sm t-break-words t-max-w-32 t-line-clamp-2">
        {asset_name}
      </div>
      <div className="t-flex t-gap-2 t-items-center">
        <SliderNavigation
          dataLength={dataLength}
          currentIndex={currentRuleIndex}
          onPrev={goToPrev}
          onNext={goToNext}
        />
        <Button customType="ghost_icon" size="small" onClick={close}>
          <Cross color="currentColor" />
        </Button>
      </div>
    </div>
  );
};

const SliderBody = () => {
  const { data, isLoading, isSuccess } = useSilderFixedAsset();
  const {
    asset_name,
    description,
    purchase_price = 0,
    salvage_value = 0,
    depreciation_start_date,
    useful_life,
    depreciation_amount = 0,
    asset_account,
    accumulated_depreciation_account,
    depreciation_account,
    accumulated_depreciation_amount = 0,
    current_value = 0,
    frequency,
    remaining_life = 0,
  } = data || {};

  return (
    <Async.Root {...{ isEmpty: false, isLoading, isSuccess }}>
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-flex-col">
          <Header />

          <div className="t-p-5 t-flex t-gap-4 t-flex-col t-pb-24">
            <SliderAccordion.Root
              type="multiple"
              defaultValue={["DETAILS", "DEPRECIATION", "CATEGORY"]}
              className="t-flex t-gap-4 t-flex-col"
            >
              <SliderAccordion.Item value="DETAILS">
                <SliderAccordion.Trigger>Asset Details</SliderAccordion.Trigger>
                <SliderAccordion.Content>
                  <span className="t-grid t-gap-6 t-grid-cols-2">
                    <InfoItem label="Asset name">
                      <div className="t-break-words t-max-w-40">
                        {asset_name}
                      </div>
                    </InfoItem>
                    <InfoItem label="Description">
                      <div className="t-break-words t-max-w-40">
                        {description}
                      </div>
                    </InfoItem>
                  </span>
                </SliderAccordion.Content>
              </SliderAccordion.Item>

              <SliderAccordion.Item value="DEPRECIATION">
                <SliderAccordion.Trigger>Depreciation</SliderAccordion.Trigger>
                <SliderAccordion.Content>
                  <span className="t-grid t-gap-6 t-grid-cols-2">
                    <InfoItem label="Purchase price">
                      <AmountSuperScript amount={purchase_price} />
                    </InfoItem>

                    <InfoItem label="Salvage value">
                      <AmountSuperScript amount={salvage_value} />
                    </InfoItem>

                    <InfoItem label="Depreciation amount">
                      <AmountSuperScript amount={depreciation_amount} />
                    </InfoItem>

                    <InfoItem label="Current value">
                      <AmountSuperScript amount={current_value} />
                    </InfoItem>

                    <InfoItem label="Depreciation start date">
                      {dayjs(depreciation_start_date).format(DD_MMM_YYYY)}
                    </InfoItem>

                    <InfoItem label="Depreciation method">
                      Straight line
                    </InfoItem>

                    <InfoItem label="Useful life (in years)">
                      {useful_life}
                    </InfoItem>

                    <InfoItem label="Remaining life">
                      <RemainingLife
                        frequency={frequency!}
                        remainingLife={remaining_life}
                      />
                    </InfoItem>

                    <InfoItem label="Frequency of depreciation">
                      <div className="first-letter:t-uppercase t-lowercase">
                        {frequency}
                      </div>
                    </InfoItem>
                  </span>
                </SliderAccordion.Content>
              </SliderAccordion.Item>

              <SliderAccordion.Item value="CATEGORY">
                <SliderAccordion.Trigger>Category</SliderAccordion.Trigger>
                <SliderAccordion.Content>
                  <span className="t-grid t-gap-6 t-grid-cols-1">
                    <InfoItem label="Asset account">
                      {asset_account?.name}
                    </InfoItem>

                    <InfoItem label="Depreciation expense account">
                      {depreciation_account?.name}
                    </InfoItem>

                    <InfoItem label="Accumulated depreciation account">
                      {accumulated_depreciation_account?.name}
                    </InfoItem>

                    <InfoItem label="Accumulated depreciation amount">
                      <AmountSuperScript
                        amount={accumulated_depreciation_amount}
                      />
                    </InfoItem>
                  </span>
                </SliderAccordion.Content>
              </SliderAccordion.Item>
            </SliderAccordion.Root>
          </div>
        </div>
      </Async.Success>
    </Async.Root>
  );
};

export const AssetSlider = () => {
  const dispatch = useDispatch();

  const { isSliderOpen, previewFixedAssetId } = useSelector(
    (store: RootState) => store.fixedAsset
  );

  const close = () => {
    dispatch(closeSlider());
  };

  return (
    <Slider open={isSliderOpen} onClose={close} withOverlay type="fixed">
      <SliderBody key={previewFixedAssetId} />
    </Slider>
  );
};
