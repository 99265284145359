import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import Tab from "components/DesignSystem/Tab/Tab";
import { ALL, COMPLETED, ONGOING } from "constants/task";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { parse, stringify } from "qs";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  useGetTaskListForServiceTeamQuery,
  useGetTaskListForUserQuery,
} from "store/apis/taskList";
import { RootState } from "store/store";
import { RandDServiceTable } from "./RandDServiceTable";
// import { RequestServiceActions } from "./RequestServiceActions";
// import { ServiceActionModal } from "./ServiceListModal/ServiceActionModal";

export const RandDServices = () => {
  usePageTitle("R&D Services");
  const { update } = useUpdateQuery();
  const query = useQuery();
  const serviceType = query.get("services");
  const search = query.get("search");
  const rRef = useRef(null);
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCpa, isCustomer } = useRoleBasedView();
  const {
    open: openServiceActionModal,
    close: closeServiceActionModal,
    isOpen: showServiceActionModal,
  } = useModal();

  const { ongoing_count, count, completed_count } = useSelector(
    (state: RootState) => state.serviceList.taskCounts
  );
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const queryData = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const { data: userTaskData, isLoading: isUserLoading } =
    useGetTaskListForUserQuery(
      {
        taskCategoryType: "BOOKS",
      },
      { skip: isCpa, refetchOnMountOrArgChange: true }
    );

  const { data: cpaTaskList, isLoading: isAdminLoading } =
    useGetTaskListForServiceTeamQuery(
      {
        groupId,
        taskCategoryType: "BOOKS",
      },
      { skip: isCustomer || !groupId, refetchOnMountOrArgChange: true }
    );

  const { count: totalCount } = (userTaskData || cpaTaskList || {}) as {
    count?: number;
  };
  const noServiceRequested = totalCount === 0;

  const tabs = [
    { name: "All", value: "ALL", taskCount: count },
    { name: "Ongoing", value: "ONGOING", taskCount: ongoing_count },
    {
      name: "Completed",
      value: "COMPLETED",
      taskCount: completed_count,
    },
  ];

  const handleTabChange = (value: string) => {
    update({ query: "services", value: value || null });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search", value: value || null });
  };

  if (isUserLoading || isAdminLoading) {
    return <Loader />;
  }

  //   if (noServiceRequested) {
  //     return (
  //       <DashboardLayout ref={rRef} header={<Header v2 title="Services" />}>
  //         <RequestServiceActions
  //           contentType={"card"}
  //           openServiceActionModal={openServiceActionModal}
  //         />
  //         {showServiceActionModal && (
  //           <ServiceActionModal
  //             isOpen={showServiceActionModal}
  //             close={closeServiceActionModal}
  //           />
  //         )}
  //       </DashboardLayout>
  //     );
  //   }

  return (
    <Tab.Root
      asChild
      defaultValue="ONGOING"
      onValueChange={handleTabChange}
      value={serviceType || "ONGOING"}
    >
      <DashboardLayout
        ref={rRef}
        header={
          <Header
            v2
            title="R&D Tax Credits"
            right={
              <ConditionalLink to={`/credits/services/addtask${queryData}`}>
                <Button size="small" customType="primary">
                  Request a service
                </Button>
              </ConditionalLink>
            }
            bottom={
              <Tab.List>
                {tabs.map(({ name, value, taskCount }) => (
                  <Tab.Trigger
                    value={value}
                    key={value}
                    suffix={taskCount?.toString()}
                    customType="primary"
                  >
                    {name}
                  </Tab.Trigger>
                ))}
              </Tab.List>
            }
          />
        }
      >
        <div className="t-w-1/2">
          <Search
            placeholder="Search..."
            onChange={handleChange}
            value={search || ""}
            block
          />
        </div>
        <div>
          <Tab.Content value={ALL} className="t-h-full">
            <RandDServiceTable />
          </Tab.Content>
          <Tab.Content value={ONGOING} className="t-h-full">
            <RandDServiceTable />
          </Tab.Content>
          <Tab.Content value={COMPLETED} className="t-h-full">
            <RandDServiceTable />
          </Tab.Content>
        </div>
      </DashboardLayout>
    </Tab.Root>
  );
};
