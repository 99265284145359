import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { useRoleBasedView } from "hooks/useRoleBasedView";

import {
  CALL_CONNECTED,
  CALL_DISCONNECTED,
  CALL_NO_RESPONSE,
} from "constants/CallAdmin";
import CallReceive from "static/images/AdminCallAccept.svg";
import CallReject from "static/images/AdminCallReject.svg";
import CallUnresponse from "static/images/AdminCallUnresponse.svg";

import { Button } from "components/DesignSystem/Button/Button";
import { CallNotesModal } from "components/MessageInput/AdminCall/CallNotesModal";
import { useModal } from "hooks/useModal";

import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";

const CallButton = ({
  onClick,
  groupUserPhoneCallId,
  isOpen,
  close,
  btnText,
  viewOnly,
}: {
  onClick: () => void;
  groupUserPhoneCallId: string;
  isOpen: boolean;
  close: () => void;
  btnText: string;
  viewOnly: boolean;
}) => {
  return (
    <>
      <Button size="small" customType="primary" block onClick={onClick}>
        {btnText}
      </Button>
      <CallNotesModal
        isOpen={isOpen}
        close={close}
        viewOnly={viewOnly}
        groupUserPhoneCallId={groupUserPhoneCallId}
      />
    </>
  );
};

const AdminCallCardBtn = ({
  status,
  callerProfileId,
  groupUserPhoneCallId,
}: {
  status: string;
  callerProfileId: string;
  groupUserPhoneCallId: string;
}) => {
  const {
    authtoken: { uuid },
  } = useContext(authContext);

  const notesModal = useModal();

  if (callerProfileId !== uuid) {
    return (
      <>
        <CallButton
          isOpen={notesModal.isOpen}
          close={notesModal.close}
          groupUserPhoneCallId={groupUserPhoneCallId}
          btnText="View Notes"
          onClick={notesModal.open}
          viewOnly
        />
      </>
    );
  } else if (
    status === CALL_DISCONNECTED ||
    status === CALL_CONNECTED ||
    status === CALL_NO_RESPONSE
  ) {
    return (
      <CallButton
        isOpen={notesModal.isOpen}
        close={notesModal.close}
        groupUserPhoneCallId={groupUserPhoneCallId}
        btnText="Edit Notes"
        onClick={notesModal.open}
        viewOnly={false}
      />
    );
  }
  return null;
};

const CallIcon = {
  [CALL_NO_RESPONSE]: CallUnresponse,
  [CALL_CONNECTED]: CallReceive,
  [CALL_DISCONNECTED]: CallReject,
};

export type AdminCallCardStatusType =
  | typeof CALL_NO_RESPONSE
  | typeof CALL_CONNECTED
  | typeof CALL_DISCONNECTED;

type CustomData = {
  header: string;
  metadata: {
    call_log: {
      call_status: AdminCallCardStatusType;
      caller: { name: string; profile_id: string };
      receiver: { name: string; profile_id: string };
      uuid: string;
    };
  };
};

export const AdminCallCard = ({ customData }: { customData: CustomData }) => {
  const { isAdmin } = useRoleBasedView();
  const { header, metadata } = customData;
  const {
    call_log: { call_status, caller, receiver, uuid },
  } = metadata;

  return (
    <ChatCard.Content>
      <ChatCard.Header
        icon={<img src={CallIcon[call_status]} alt={call_status} />}
        status={call_status}
      >
        {header}
      </ChatCard.Header>
      <ChatCard.Body>
        <div className="t-min-w-52 t-text-body t-text-text-100">
          {caller?.name + " (Inkle)"}
          {" <> "}
          {receiver?.name}
        </div>
        {isAdmin && (
          <div className="t-flex t-flex-col t-gap-2 t-mt-4 t-w-full">
            <AdminCallCardBtn
              key={call_status}
              status={call_status}
              callerProfileId={caller?.profile_id}
              groupUserPhoneCallId={uuid}
            />
          </div>
        )}
      </ChatCard.Body>
    </ChatCard.Content>
  );
};
