import { http, HttpResponse } from "msw";
import { baseURL } from "store/apis/emptyApi";

export const MOCK_GROUP_ID = "6cb4fec8-9784-4b22-afef-7d9c30a0855d";
export const CURRENT_MOCK_GROUP_ID = "6cb4fec8-9784-4b22-afef-7d9c30a0855d";
export const MOCK_ENTITY_ID = "6f0fe4eb-8bef-40b5-b970-4e19d76c3433";
export const MOCK_ENTITY_COUNTRY_ID = "530ce334-b166-4109-9a63-b3d6ab3b49ab";
export const MOCK_TRANSACTION_ID = "8df8f484-540b-4305-b6d2-7d559f1deaea";
export const MOCK_DOCUMENT_HOME_ID = "f14e5d8d-3a03-4256-bca2-f566bc335811";

export const mockGroupData = {
  success: true,
  data: {
    groups: [
      {
        uuid: CURRENT_MOCK_GROUP_ID,
        name: "New Acme",
        documents_group_id: "f14e5d8d-3a03-4256-bca2-f566bc335811",
        group_channel_url: null,
        secondary_channel_url: "7aaddfca-15d8-412d-852a-405e43dd8f21",
        books_2023_channel_url: "c1eba341-a951-4e9d-9f0d-b6db785884d5",
        books_channels: {
          "2023": "c1eba341-a951-4e9d-9f0d-b6db785884d5",
          "2024": "aa929fb1-41d6-4841-b8e6-8b4fb26b7476",
        },
        private_channel_url: "e9190da4-5c54-4371-bb5a-e4cd8312b46b",
        mailbox_channel_url: null,
        unsorted_folder_id: "5311c699-4ab1-451a-9ab4-924afcffb2b5",
        entities: [
          {
            name: "US Entity 2",
            formation_id: "ffe77bf1-bd50-4ad2-bb72-7340cc91068f",
            state_id: "c39ef5b9-47e0-4fba-933f-13a20aa90564",
            state: "California",
            country: "United States",
            country_id: "530ce334-b166-4109-9a63-b3d6ab3b49ab",
            country_code: "US",
            formation_type: "C Corp",
            date_of_incorporation: "2024-02-01",
            tax_year_end: "APR_MAR",
            uuid: "6f0fe4eb-8bef-40b5-b970-4e19d76c3433",
            has_ip: false,
            data_attributes: [
              {
                key: "UIN",
                value_regex: "*",
                formation_type: "C Corp",
                uuid: "662f6326-4d79-472f-9c3e-4c81ec58120f",
                tooltip: null,
                can_edit: true,
              },
            ],
            data_attrs: [],
          },
          {
            name: "Indian Entity Limited",
            formation_id: "eb3794c9-c759-43de-baf4-4284aba81f66",
            state_id: "e3ab4b3a-b45a-431a-be88-c19e12eb9c3b",
            state: "Karnataka",
            country: "India",
            country_id: "92d1b01e-8014-4056-9997-787e733f6a82",
            country_code: "IN",
            formation_type: "Pvt Ltd Company",
            date_of_incorporation: null,
            tax_year_end: "JAN_DEC",
            uuid: "4ac08a34-1caa-40fd-9f7d-b940b8316c67",
            has_ip: false,
            data_attributes: [
              {
                key: "UIN",
                value_regex: "*",
                formation_type: "Pvt Ltd Company",
                uuid: "446b8724-5639-4c42-9f0f-2be406d22b57",
                tooltip: null,
                can_edit: true,
              },
              {
                key: "UIN",
                value_regex: "*",
                formation_type: "Pvt Ltd Company",
                uuid: "c5924864-a4e8-4103-9187-ca37cd28d23b",
                tooltip: null,
                can_edit: true,
              },
            ],
            data_attrs: [],
            parent_entity_data: {
              parent_entity_name: "US Entity Inc",
              parent_entity_uuid: "16d3a9f6-1f2e-4aab-8a88-5800263b612d",
              relationship_type: "parent-entity",
            },
          },
          {
            name: "US Entity Inc",
            formation_id: "ffe77bf1-bd50-4ad2-bb72-7340cc91068f",
            state_id: "e4f8e9be-7437-402f-8ea8-0b1e8a7d4060",
            state: "Delaware",
            country: "United States",
            country_id: "530ce334-b166-4109-9a63-b3d6ab3b49ab",
            country_code: "US",
            formation_type: "C Corp",
            date_of_incorporation: "2023-02-01",
            tax_year_end: "JAN_DEC",
            uuid: "16d3a9f6-1f2e-4aab-8a88-5800263b612d",
            has_ip: false,
            data_attributes: [
              {
                key: "UIN",
                value_regex: "*",
                formation_type: "C Corp",
                uuid: "896d81dc-8112-45cd-be5c-23ae721a75e1",
                tooltip: null,
                can_edit: true,
              },
              {
                key: "UIN",
                value_regex: "*",
                formation_type: "C Corp",
                uuid: "7a176574-5cfb-4368-aa28-a4a8b821e52b",
                tooltip: null,
                can_edit: true,
              },
              {
                key: "UIN",
                value_regex: "*",
                formation_type: "C Corp",
                uuid: "cfcb0a2a-d46c-446c-a3e5-a769b50d7262",
                tooltip: null,
                can_edit: true,
              },
              {
                key: "UIN",
                value_regex: "*",
                formation_type: "C Corp",
                uuid: "662f6326-4d79-472f-9c3e-4c81ec58120f",
                tooltip: null,
                can_edit: true,
              },
            ],
            data_attrs: [],
            is_parent_entity: true,
            foreign_qualified_states: [
              {
                state_id: "c39ef5b9-47e0-4fba-933f-13a20aa90564",
                state: "California",
                uuid: "eda245e4-4048-43a7-8130-0b170d456d88",
                foreign_qualified_data_attributes: [
                  {
                    key: "California File Number",
                    value_regex: "'*'",
                    state: "California",
                    uuid: "7a9242f5-4f04-40cd-b5f2-3ceb400c48df",
                    tooltip: "",
                    can_edit: true,
                    data_type: "STRING",
                    value: "1234567",
                  },
                  {
                    key: "California Filing Date",
                    value_regex: "'*'",
                    state: "California",
                    uuid: "0bbfc416-ddf9-467f-b96c-49cb0469071f",
                    tooltip: "",
                    can_edit: true,
                    data_type: "DATE",
                    value: "2022-02-10",
                  },
                ],
              },
            ],
          },
        ],
        roles: [
          [
            {
              name: "Admin",
              uuid: "c76e56af-4f16-4604-9b7e-d5bd68163f9d",
            },
            {
              name: "Member",
              uuid: "1341a9e6-20b1-43c8-bd96-5f25bd5ec74a",
            },
            {
              name: "Foreign Entity Accountant",
              uuid: "29b14192-7a12-4cbe-9151-11c2bc909730",
            },
          ],
        ],
        is_fiscal_customer: false,
        subscription_type: "NOT_STARTED",
        has_mailroom_subscription: false,
        new_bookkeeping_customer: false,
        billing_email: "sandeep@inkle.io",
        onboarding_task_uuid: null,
        is_stream_group: true,
        onboarding_details: {
          text: "Schedule a call with our onboarding expert",
          button_text: "Book Now",
          link: "https://calendly.com/inklehq/onboarding",
        },
        due_amount: 0,
        invoices_count: 0,
        addon_subscriptions: [
          {
            uuid: "e7821cdb-3d8d-4bee-874e-408cc2526c9c",
            started_on: "2024-05-02T10:49:52.503419Z",
            stripe_subscription_id: "sub_1PBxD1IrZqF6fWDHqc2Peplo",
            subscription_name: "Virtual Mailroom",
            subscription_description: "All in one place Mailroom",
            subscription_status: "ACTIVE",
            subscription_uuid: "617e9097-90ad-46cc-a2e4-cc6f7fbf1cc3",
            amount: "15",
            upgrade_level: 0,
            billing_cycle: "MONTHLY",
            stripe_coupon: {
              coupon_code: "YC-ALUM",
              discounted_amount: 0.0,
              discount_type: "PERCENT",
              discount_rate: 100,
              duration_type: "MONTHLY",
              duration_period: 12,
              coupon_expiry: "2025-05-02T10:49:52.503813Z",
              coupon_status: "Active",
            },
            subscription_type: "MAILROOM",
          },
          {
            uuid: "5b493e65-c4f6-47c2-9970-688d36331087",
            started_on: "2024-05-02T10:43:55.131775Z",
            stripe_subscription_id: "sub_1PBx7GIrZqF6fWDHY1jUKrIp",
            subscription_name: "Virtual Mailroom",
            subscription_description: "All in one place Mailroom",
            subscription_status: "ACTIVE",
            subscription_uuid: "617e9097-90ad-46cc-a2e4-cc6f7fbf1cc3",
            amount: "15",
            upgrade_level: 0,
            billing_cycle: "MONTHLY",
            stripe_coupon: {
              coupon_code: "YC-ALUM",
              discounted_amount: 0.0,
              discount_type: "PERCENT",
              discount_rate: 100,
              duration_type: "MONTHLY",
              duration_period: 12,
              coupon_expiry: "2025-05-02T10:43:55.132506Z",
              coupon_status: "Active",
            },
            subscription_type: "MAILROOM",
          },
          {
            uuid: "3da5f70a-df39-49a0-b637-9086b498618d",
            started_on: "2024-05-02T10:38:28.515774Z",
            stripe_subscription_id: "sub_1PBx1zIrZqF6fWDHB1uM7P3G",
            subscription_name: "Inkle Registered Agent (DE) - New",
            subscription_description: "All in one Registered agent DE",
            subscription_status: "ACTIVE",
            subscription_uuid: "bf3069f4-450e-4c25-a531-0d49dcd89689",
            amount: "5",
            upgrade_level: 0,
            billing_cycle: "MONTHLY",
            stripe_coupon: {
              coupon_code: "YC-ALUM",
              discounted_amount: 0.0,
              discount_type: "PERCENT",
              discount_rate: 100,
              duration_type: "MONTHLY",
              duration_period: 12,
              coupon_expiry: "2025-05-02T10:38:28.516208Z",
              coupon_status: "Active",
            },
            subscription_type: "REGISTERED_AGENT",
          },
          {
            uuid: "1a8de67e-cfe7-41f7-b4f5-c8f43f3a8bc4",
            started_on: "2024-04-05T11:56:25.622758Z",
            stripe_subscription_id: "sub_1P2BNcIrZqF6fWDHZXhCZ756",
            subscription_name: "Bookkeeping - Pro",
            subscription_description:
              "Monthly bookkeeping on an accrual basis with compliance oversight.",
            subscription_status: "ACTIVE",
            subscription_uuid: "832bfb06-8b27-4594-99fe-05a01e901f86",
            amount: 349,
            upgrade_level: 2,
            billing_cycle: "MONTHLY",
            stripe_coupon: {
              coupon_code: "YC-ALUM",
              discounted_amount: 261.75,
              discount_type: "PERCENT",
              discount_rate: 25,
              duration_type: "MONTHLY",
              duration_period: 6,
              coupon_expiry: "2024-10-05T11:56:25.623290Z",
              coupon_status: "Active",
            },
            subscription_type: "BOOKKEEPING",
            current_tier: {
              lower_tier: 10000,
              upper_tier: 19999,
            },
          },
          {
            uuid: "c253f41d-2a18-4a44-b67f-0a4b3b737fa3",
            started_on: "2024-03-19T10:23:15.513838Z",
            stripe_subscription_id: "sub_1Ovzp8IrZqF6fWDHsRMY4LVb",
            subscription_name: "Group Books Consolidation",
            subscription_description: "",
            subscription_status: "ACTIVE",
            subscription_uuid: "484d1690-bc49-4839-935e-4a815a2438c5",
            amount: "400",
            upgrade_level: 1,
            billing_cycle: "QUARTERLY",
            stripe_coupon: null,
            subscription_type: "GROUP_BOOKS_CONSOLIDATION",
          },
        ],
        platform_subscription: {
          is_subscription_required: true,
          plan_access_data: {},
          uuid: "05c9ce04-1fa5-4a8f-ac79-3213c3c2d215",
          subscription_name: "Standard Plan",
          subscription_uuid: "86bd78d4-35bf-4b0b-b696-ab667a3c7168",
          subscription_description:
            '<p>Includes everything in Free Plan and more:</p>\r\n\r\n<br/>\r\n\r\n<p>For every $1 you spend here, you get 1 transaction "bookkept" for free.</p>\r\n\r\n<br/>\r\n\r\n<ul>\r\n<li>Free Tier + 3 seats</li>\r\n<li>Constant Chat Access</li>\r\n<li>Annual Books Cleanup</li>\r\n<li>Intercompany Module</li>\r\n<li>Documents, Perks, Teams</li>\r\n<li>QBO/Zoho Discounts</li>\r\n</ul>',
          upgrade_level: 1,
          stripe_subscription_id: "sub_1PDLPBIrZqF6fWDHfCnFDkDK",
          subscription_status: "ACTIVE",
          amount: "30",
          billing_cycl: "MONTHLY",
          stripe_coupon: {
            coupon_code: "YC-ALUM",
            discounted_amount: 15.0,
            discount_type: "PERCENT",
            discount_rate: 50,
            duration_type: "MONTHLY",
            duration_period: 6,
            coupon_expiry: "2024-11-06T06:52:10.583475Z",
            coupon_status: "Active",
          },
          ongoing_extended_month: false,
          extended_month_used: false,
        },
      },
    ],
  },
};

export const groupMockHanders = [
  http.get(`${baseURL}api/inkle/company/group/entities/`, () =>
    HttpResponse.json(mockGroupData)
  ),
];
