import { currency } from "utils/Currency";
import qs from "qs";
import { Currency } from "types/Models/Currency";
import {
  TransferPricingInvoice,
  TransferPricingInvoiceDetails,
} from "types/Models/TransferPricingInvoice";
import { TransferPricingPartner } from "types/Models/TransferPricingPartner";
import {
  QuoteResponse,
  TPAccountInfo,
  TransferPricingTransfer,
} from "types/Models/TransferPricingTransfer";
import { TransferPricingAccountInfo } from "types/Models/TransferPricingAccountInfo";
import { emptyApi } from "./emptyApi";
import { PurposeCode } from "types/Models/PurposeCode";

export type CreateInvoicePayload = {
  tpx_partner_uuid: string;
  transaction_type: string;
  purpose_code_uuid: string;
  inv_number: string;
  inv_amount: string;
  inv_description: string;
  inv_creation_date?: string;
  receivable_amount: string;
  currency_uuid: string;
  invoice?: Blob | string;
  has_invoice: boolean;
  indian_entity_address_id?: string;
  us_entity_address_id?: string;
  create_proforma: boolean;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getInvoices: build.query<
      TransferPricingInvoice[],
      { partnerId: string; searchTerm?: string; groupId: string }
    >({
      query: ({ groupId, partnerId, searchTerm }) =>
        `/api/inkle/transferpricing/group/${groupId}/invoice/list/?tpx_partner_uuid=${partnerId}&search_term=${searchTerm}`,
      providesTags: (result) => (result ? [{ type: "TP_INVOICES" }] : []),
    }),

    getInvoice: build.query<
      TransferPricingInvoiceDetails,
      { groupId: string; invoiceId: string }
    >({
      query: ({ groupId, invoiceId }) =>
        `/api/inkle/transferpricing/group/${groupId}/invoice/${invoiceId}/`,
      keepUnusedDataFor: 1,
      providesTags: (result) =>
        result ? [{ type: "TP_INVOICES", id: result.uuid }] : [],
    }),

    editInvoice: build.mutation<
      TransferPricingInvoiceDetails,
      {
        groupId: string;
        invoice: Partial<CreateInvoicePayload>;
        invoiceId: string;
      }
    >({
      query: ({ groupId, invoice, invoiceId }) => {
        const form = Object.entries(invoice).reduce((acc, [key, value]) => {
          const formattedValue =
            typeof value === "boolean" ? value.toString() : value;

          if (formattedValue) {
            acc.append(key, formattedValue);
          }

          return acc;
        }, new FormData());

        return {
          url: `/api/inkle/transferpricing/group/${groupId}/invoice/${invoiceId}/`,
          method: "put",
          body: form,
        };
      },
      invalidatesTags: (result, _, request) =>
        result ? [{ type: "TP_INVOICES" }] : [],
    }),

    createInvoice: build.mutation<
      TransferPricingInvoiceDetails,
      { groupId: string; invoice: CreateInvoicePayload }
    >({
      query: ({ groupId, invoice }) => {
        const form = Object.entries(invoice).reduce((acc, [key, value]) => {
          const formattedValue =
            typeof value === "boolean" ? value.toString() : value;

          if (formattedValue) {
            acc.append(key, formattedValue);
          }

          return acc;
        }, new FormData());

        return {
          url: `/api/inkle/transferpricing/group/${groupId}/invoice/`,
          body: form,
          method: "post",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "TP_INVOICES" }] : []),
    }),

    deleteDraftInvoice: build.mutation<
      void,
      { groupId: string; invoiceId: string }
    >({
      query: ({ groupId, invoiceId }) => {
        return {
          url: `/api/inkle/transferpricing/group/${groupId}/invoice/${invoiceId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: "TP_INVOICES" }],
    }),

    deleteProformaInvoice: build.mutation<
      void,
      { groupId: string; fileId: string }
    >({
      query: ({ groupId, fileId }) => ({
        url: `/api/inkle/documents/group/${groupId}/file/delete/`,
        method: "post",
        body: {
          file_uuid: fileId,
        },
      }),
      invalidatesTags: (result) => [{ type: "TP_INVOICES" }],
    }),

    getTransferPricingAccountInfo: build.query<
      TransferPricingAccountInfo,
      { groupId: string }
    >({
      query: ({ groupId }) => {
        return `/api/inkle/transferpricing/group/${groupId}/account/info/`;
      },
    }),

    getPartnerAddressInfo: build.query<
      TransferPricingPartner,
      { groupId: string; partnerId: string }
    >({
      query: ({ groupId, partnerId }) =>
        `/api/inkle/transferpricing/group/${groupId}/address/partner/?tpx_partner_uuid=${partnerId}`,
      providesTags: (result) =>
        result ? [{ type: "TP_PARTNER_ADDRESS" }] : [],
    }),

    getCurrencies: build.query<Currency[], void>({
      query: () => `/api/inkle/transferpricing/currency/list/`,
    }),

    getPurposeCodes: build.query<PurposeCode[], void>({
      query: () => `api/inkle/transferpricing/purpose_code/list/`,
    }),

    getTransfers: build.query<
      TransferPricingTransfer[],
      { groupId: string; searchTerm?: string; userId: string }
    >({
      query: ({ groupId, userId, searchTerm }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/tp-payments/group/${groupId}/transfer/list/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? [{ type: "TP_TRANSFERS" }] : []),
    }),

    getTPAccountInfo: build.query<TPAccountInfo, { groupId: string }>({
      query: ({ groupId }) => `/api/inkle/tp-payments/group/${groupId}/account`,
      providesTags: (result) => (result ? [{ type: "TP_ACCOUNTINFO" }] : []),
    }),

    getQuote: build.query<QuoteResponse, { amount: number }>({
      query: ({ amount }) => {
        let queryUrl = qs.stringify(
          {
            amount: amount,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/tp-payments/quote/${queryUrl}`,
        };
      },
    }),

    getPurposeCodeQuote: build.query({
      query: () => {
        return {
          url: "/api/inkle/tp-payments/purpose_code/list",
        };
      },
    }),

    markTransferInitiated: build.mutation<
      TransferPricingTransfer,
      {
        groupId: string;
        tpPaymentId: string;
        payLoad: {
          amount: number;
          currency: string;
          purpose_code_uuid: string;
        };
      }
    >({
      query: ({ groupId, tpPaymentId, payLoad }) => {
        return {
          url: `/api/inkle/tp-payments/group/${groupId}/transfer/${tpPaymentId}/`,
          method: "PATCH",
          body: payLoad,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "TP_TRANSFERS" }] : []),
    }),

    createTransfer: build.mutation<
      TransferPricingTransfer,
      {
        groupId: string;
        payLoad: {
          amount: number;
          currency: string;
          purpose_code_uuid: string;
        };
      }
    >({
      query: ({ groupId, payLoad }) => {
        return {
          url: `/api/inkle/tp-payments/group/${groupId}/transfer/`,
          method: "post",
          body: payLoad,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "TP_TRANSFERS" }] : []),
    }),

    acceptTOS: build.mutation<TPAccountInfo, { groupId: string }>({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/tp-payments/group/${groupId}/tos_acceptance/`,
          method: "post",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "TP_ACCOUNTINFO" }] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTransferPricingAccountInfoQuery,
  useGetCurrenciesQuery,
  useGetTransfersQuery,
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useGetPartnerAddressInfoQuery,
  useGetPurposeCodesQuery,
  useDeleteProformaInvoiceMutation,
  useGetInvoiceQuery,
  useEditInvoiceMutation,
  useDeleteDraftInvoiceMutation,
  useGetTPAccountInfoQuery,
  useMarkTransferInitiatedMutation,
  useLazyGetQuoteQuery,
  useGetPurposeCodeQuoteQuery,
  useCreateTransferMutation,
  useAcceptTOSMutation,
} = extendedApi;
