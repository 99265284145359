import classNames from "classnames";
import React from "react";

const OnboardingOptionListCard = ({
  title,
  details,
  icon,
  selected,
}: {
  title: string;
  details: string;
  icon: string;
  selected: boolean;
}) => {
  return (
    <div
      className={classNames(
        "t-w-[292px] t-h-[306px] t-py-5 t-px-6 t-border t-border-solid t-rounded-lg t-flex t-flex-col t-items-center t-bg-surface hover:t-shadow-light-60",
        {
          "t-border-neutral-10": !selected,
          "t-border-purple-40": selected,
        }
      )}
    >
      <img src={icon} alt="title" className="t-h-[134px] t-w-134px t-mt-5" />
      <div className="t-text-h6 t-text-text-100">{title}</div>
      <div className="t-text-body t-text-text-30 t-text-center t-mt-1">
        {details}
      </div>
    </div>
  );
};

export default OnboardingOptionListCard;
