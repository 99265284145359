import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { ArrowLeft } from "components/icons/ArrowLeft";
import Modal from "components/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useContext, useEffect, useState } from "react";
import PoweredByStripe from "static/images/PoweredByStripe.svg";
import { useSavePaymentMethodMutation } from "store/apis/subscriptions";
import authContext from "../../jwt_context&axios/authContext";
import { SavePaymentResponse } from "../../types/Models/SavedCards";
import DefaultCardModal from "./DefaultCardModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";

export const AddCardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#706A85",
      fontSize: "16px",
      "::placeholder": {
        color: "#706A85",
      },
    },
    invalid: {
      color: "#FA755A",
      iconColor: "#FA755A",
    },
  },
};

type AddCardModalProps = {
  entityId: string;
  show: boolean;
  closeModal: () => void;
  setShowCardAddedModal?: (newState: React.SetStateAction<boolean>) => void;
  setShowSavedCards?: (newState: React.SetStateAction<boolean>) => void;
  setSavedCards?: (
    newState: React.SetStateAction<SavePaymentResponse[]>
  ) => void;
  cardsAdded?: boolean;
  ispaymentFlow?: boolean;
};

export const AddCardModal = ({
  show,
  closeModal,
  setShowCardAddedModal,
  setShowSavedCards,
  setSavedCards,
  cardsAdded,
  ispaymentFlow,
  entityId,
}: AddCardModalProps) => {
  const { alertToast, successToast } = useToast();
  const { authtoken } = useContext(authContext);
  const [showDefaultCardModal, setShowDefaultCardModal] = useState(false);
  const [isDefault, setIsDefault] = useState(!cardsAdded);
  const { uuid: groupId } = useCurrentGroupContext();
  const [isComplete, setIsComplete] = useState(false);
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const stripe = useStripe();
  // @ts-ignore
  const elements = useElements();

  const { isForeignCA } = useRoleBasedView();

  const [savePaymentMethod, { isLoading }] = useSavePaymentMethodMutation();

  useEffect(() => {
    setIsComplete(false);
  }, [show]);

  useEffect(() => {
    setDisabledButton(isLoading || !stripe || !elements || !isComplete);
  }, [isLoading, stripe, elements, isComplete]);

  const onSubmit = async () => {
    try {
      // @ts-ignore
      const paymentElement = elements.getElement(CardElement);

      // @ts-ignore
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: paymentElement!,
        billing_details: {
          name: authtoken.name,
          email: authtoken.email,
          phone: authtoken.mobile,
        },
      });

      const data = await savePaymentMethod({
        groupId,
        paymentMethodId: result?.paymentMethod?.id || "",
        isDefaultCard: !cardsAdded ? true : isDefault,
        entityId,
      }).unwrap();
      successToast({ message: "Card added successfully" });
      setIsDefault(false);
      setIsSaveBtnClicked(false);
      setSavedCards?.(data);
      if (isLoading === false) {
        closeModal();
        setShowDefaultCardModal(false);
        setShowCardAddedModal?.(true);
      }
      setTimeout(() => {
        setShowCardAddedModal?.(false);
        setShowSavedCards?.(true);
      }, 2000);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      setIsSaveBtnClicked(false);
    }
  };

  const handleCardChange = (event: any) => {
    setIsComplete(event.complete);
  };

  const saveCard = () => {
    if (isDefault) {
      setShowDefaultCardModal(true);
    } else {
      setIsSaveBtnClicked(true);
      onSubmit();
    }
  };

  return (
    <>
      <Modal.Root open={show} onOpenChange={closeModal}>
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content
            className={classNames("t-overflow-auto sm:t-w-[90vw]", {
              "t-h-[200px] t-w-[300px]": showDefaultCardModal && !isForeignCA,
              "t-h-[360px] t-w-[546px]": !showDefaultCardModal && !isForeignCA,
              "t-h-[300px] t-w-[546px]": isForeignCA,
            })}
          >
            <Modal.Title className="t-flex t-items-center t-gap-4">
              {ispaymentFlow && (
                <Button customType="transparent" onClick={closeModal}>
                  <ArrowLeft />
                </Button>
              )}
              Add a card
            </Modal.Title>
            {!isForeignCA && (
              <Modal.Description className="t-pb-2">
                <span className="t-mt-3 t-block t-text-subtitle-sm t-font-light t-text-red-50">
                  <span>
                    We strongly recommend using the US entity's corporate credit
                    card.
                  </span>
                </span>
              </Modal.Description>
            )}
            <div className="t-mt-3 t-flex t-flex-col">
              <div className="t-flex t-flex-col t-gap-4 ">
                <div className="t-rounded-lg t-border t-border-solid t-border-neutral-0 t-p-3">
                  <CardElement
                    options={AddCardStyle}
                    onChange={handleCardChange}
                  />
                </div>
                <Checkbox
                  checked={isDefault}
                  label=" Mark as default"
                  name="isDefault"
                  onChange={() => setIsDefault((prev) => !prev)}
                />
              </div>
              <div className="d-flex justify-content-end t-mt-7 t-gap-3">
                <Button
                  color="purple"
                  size="regular"
                  type="submit"
                  block
                  customType="primary"
                  disabled={
                    disabledButton ||
                    isSaveBtnClicked ||
                    (!cardsAdded && !isDefault)
                  }
                  onClick={saveCard}
                  isLoading={isLoading}
                >
                  Save Card
                </Button>
              </div>
              <img
                src={PoweredByStripe}
                className={classNames("t-mx-auto t-mt-6  t-flex")}
                alt="Powered by stripe"
              />
            </div>
            <Modal.Close />
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
      <DefaultCardModal
        entityId={entityId}
        show={showDefaultCardModal}
        closeModal={() => setShowDefaultCardModal(false)}
        addCard={onSubmit}
        cardAddLoading={isLoading}
      />
    </>
  );
};
