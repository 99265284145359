export const InkleTaxSymbolSmall = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.63144 3.6224L2.15958 9.01947L0.618509 7.49946C-0.20617 6.68605 -0.20617 5.36726 0.618509 4.55385L4.59715 0.629587C5.42183 -0.183821 6.7589 -0.18382 7.58358 0.629587L7.63144 0.676794C8.45612 1.4902 8.45612 2.809 7.63144 3.6224Z"
      fill="url(#paint0_linear_5960_17650)"
    />
    <path
      d="M4.36851 8.4166L9.84036 3.01953L11.3814 4.53954C12.2061 5.35295 12.2061 6.67175 11.3814 7.48515L7.4028 11.4094C6.57812 12.2228 5.24105 12.2228 4.41637 11.4094L4.36851 11.3622C3.54383 10.5488 3.54383 9.23001 4.36851 8.4166Z"
      fill="url(#paint1_linear_5960_17650)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5960_17650"
        x1="0"
        y1="4.5195"
        x2="8.24995"
        y2="4.5195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5960_17650"
        x1="3.75"
        y1="7.5195"
        x2="11.9999"
        y2="7.5195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
