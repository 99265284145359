import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import { ActionCard } from "components/ActionCard/ActionCard";
import { ActionRequiredModal } from "components/ActionRequiredModal/ActionRequiredModal";
import CardAdd from "components/billing/CardAdd";
import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { OnboardingHomeCard } from "components/OnboardingHomeCard/OnboardingHomeCard";
import { PreIncorporationDiscount } from "components/PreIncorporationDiscount/PreIncorporationDiscount";
import { ReferHomeCard } from "components/ReferHomeCard/ReferHomeCard";
import FreeSubscriptionModal from "components/tasks/task7004/FreeSubscriptionModal";
import { TPHomeCard } from "components/TPHomeCard/TPHomeCard";
import { TP_HOME_PAGE_CARD_CLICK } from "constants/analyticsEvents";
import { PREINCORPORATION } from "constants/onBoarding";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { INTRODUCTION_TO_US_LAW_FIRMS } from "constants/resource";
import { ACTIVE } from "constants/tax";
import { motion } from "framer-motion";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LockOnboarding from "static/images/LockOnboarding.svg";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import { useGetTPAccountInfoQuery } from "store/apis/transferPricing";
import { openOnboardingWidget } from "store/slices/onboardingWidget";
import {
  closeCardAddModal,
  closeFreeSubscription,
} from "store/slices/subscriptionModal";
import { RootState } from "store/store";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/dashboardHome.css";
import "../../static/styles/components/home.css";
import WelcomeUser from "../design/welcomeUser";
import { ForeignCAHome } from "./ForeignCAHome";
import PreIncorporateCard from "./PreIncorporateCard";
import { SubscriptionModal } from "components/SubscriptionModal/SubscriptionModal";

const SmallCard = ({ children }: { children: ReactNode }) => (
  <motion.div layout className="md:t-w-[300px] t-w-full md:t-h-[180px]">
    {children}
  </motion.div>
);

export const LargeCard = ({
  children,
  block,
}: {
  children: ReactNode;
  block?: boolean;
}) => (
  <motion.div
    layout
    className={classNames(
      "t-w-full hover:t-shadow-md t-transition-shadow t-rounded-lg t-overflow-hidden",
      {
        "md:t-w-[462px] md:t-h-[196px]": !block,
        "md:t-w-full md:t-min-h-[196px]": block,
      }
    )}
  >
    {children}
  </motion.div>
);

export const Home = () => {
  usePageTitle("Home");
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { authtoken } = useContext(authContext);
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCustomer, isForeignCA } = useRoleBasedView();

  const { isOpenFreeSubscription, isOpenCardAddModal } = useSelector(
    (state: RootState) => state.subscriptionModal
  );

  const { email, name, mobile, user_type } = authtoken;
  const { platform_subscription: platformSubscription } = authtoken || {};

  const subscriptionModal = useModal();

  const { data: accountInfo, isSuccess: isInfoLoaded } =
    useGetTPAccountInfoQuery({ groupId }, { skip: !groupId });

  const { data: products } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) => p.name === PRODUCT_ONBOARDING.TAX
  );
  const isTaxOnboardingInComplete =
    platformOnboarding && platformOnboarding.status !== "COMPLETED";

  const isStatusInactive = accountInfo?.status !== "active";
  const receiverUuid = accountInfo?.receiver?.uuid;
  const senderUuid = accountInfo?.sender?.uuid;

  const isTPOnboardingPending =
    isInfoLoaded && (!senderUuid || !receiverUuid || isStatusInactive);

  const showCardsOnSubcription =
    isTaxOnboardingInComplete &&
    platformSubscription?.subscription_status !== ACTIVE;

  const onTpCardClick = () => {
    trackEvent(TP_HOME_PAGE_CARD_CLICK, {
      screen: "Home",
      name,
      email,
      mobile,
    });
  };

  const tpLink =
    isTPOnboardingPending && accountInfo?.tpx_task_uuid
      ? `/filings/${accountInfo.tpx_task_uuid}`
      : "/intragroup/transfers/";

  const goToPreIncorpPackage = () => {
    localStorage.setItem(
      "preIncorpSelectedPoint",
      JSON.stringify(INTRODUCTION_TO_US_LAW_FIRMS)
    );
    history.push("/resources");
  };

  const onRaiseClick = () => {
    history.push(`/community/raise`);
  };

  return (
    <div className="t-w-full">
      {isForeignCA && <ForeignCAHome />}
      {isCustomer ? (
        <DashboardLayout
          header={
            <Header
              v2
              className="!t-border-0"
              title={`Welcome ${authtoken?.first_name}`}
            />
          }
        >
          <motion.div layout className="t-pb-8 t-flex t-gap-6 t-flex-wrap">
            {isTaxOnboardingInComplete && (
              <LargeCard>
                <OnboardingHomeCard
                  onClick={() => dispatch(openOnboardingWidget())}
                  progress={platformOnboarding?.progress}
                />
              </LargeCard>
            )}

            {showCardsOnSubcription ? (
              <div className="t-flex t-flex-col t-mt-3 t-bg-surface-lighter-grey t-gap-4 t-items-center t-align-middle t-text-center t-py-12 t-rounded-lg t-w-full">
                <img src={LockOnboarding} alt="Lock" />
                <div className="t-flex t-flex-col t-gap-2">
                  <div className="t-text-subtitle-sm">Locked Features</div>
                  <div className="t-flex t-text-body">
                    <button
                      className="all:unset t-flex t-text-purple"
                      onClick={subscriptionModal.open}
                    >
                      Subscribe to Inkle Tax&nbsp;
                    </button>
                    <div>to unlock more features.</div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <LargeCard>
                  <ActionCard
                    name="Raise"
                    className="t-bg-[url('static/images/RaiseHome.svg')] hover:t-cursor-pointer"
                    content={
                      <span className="t-text-body t-text-text-30 t-mb-10">
                        Aids founders fundraise, network & scale effectively.
                        Get an intro to active investors now.
                      </span>
                    }
                    action={<Button size="small">Get started</Button>}
                    onClick={onRaiseClick}
                  />
                </LargeCard>
                {user_type === PREINCORPORATION && (
                  <LargeCard>
                    <PreIncorporationDiscount onClick={goToPreIncorpPackage} />
                  </LargeCard>
                )}

                <LargeCard>
                  <div onClick={onTpCardClick} className="t-h-full">
                    <ConditionalLink to={tpLink} className="t-text-text-100">
                      <TPHomeCard />
                    </ConditionalLink>
                  </div>
                </LargeCard>

                {user_type === PREINCORPORATION && (
                  <ConditionalLink to="/resources">
                    <LargeCard>
                      <PreIncorporateCard />
                    </LargeCard>
                  </ConditionalLink>
                )}

                <LargeCard>
                  <ReferHomeCard />
                </LargeCard>
              </>
            )}
          </motion.div>

          <SubscriptionModal
            onPaymentDone={subscriptionModal.close}
            show={subscriptionModal.isOpen}
            onClose={subscriptionModal.close}
          />
          <FreeSubscriptionModal
            openSubscription={isOpenFreeSubscription}
            closeSubscription={() => dispatch(closeFreeSubscription())}
          />
          {isOpenCardAddModal && (
            <CardAdd
              openAddCard={isOpenCardAddModal}
              closeAddCard={() => dispatch(closeCardAddModal())}
            />
          )}
        </DashboardLayout>
      ) : (
        <WelcomeUser />
      )}
    </div>
  );
};
