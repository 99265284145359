import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import { useUpdateTeamSuperAdminMutation } from "store/apis/teamSetting";

const AdminUpdateModal = ({
  show,
  closeModal,
  profileId,
  updateArchiveToast,
  isSuperAdmin,
}: {
  show: boolean;
  closeModal: () => void;
  profileId: string;
  updateArchiveToast: (v: string) => void;
  isSuperAdmin: boolean;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [updateSuperAdmin, { data, isSuccess }] =
    useUpdateTeamSuperAdminMutation();

  useEffect(() => {
    if (isSuccess) {
      const toastMessage = data?.is_super_admin
        ? "User access granted"
        : "User access revoked";
      updateArchiveToast(toastMessage);
    }
  }, [isSuccess]);

  const updateGroupSuperAdmin = async () => {
    try {
      await updateSuperAdmin({
        group_id: groupId,
        profile_id: profileId,
        make_super_admin: isSuperAdmin ? false : true,
      }).unwrap();
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <>
      <Modal.Root open={show} onOpenChange={closeModal}>
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="!t-w-[360px] !t-p-0 md:!t-w-[400px]">
            <Modal.Close />
            <Modal.Title className="!t-p-6">
              {isSuperAdmin ? "Remove" : "Make"} owner
            </Modal.Title>
            <div className="t-border t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-p-6">
              <div className="t-text-body-lg t-text-text-black">
                {isSuperAdmin
                  ? "Are you sure you want to revoke the Owner access from the user?"
                  : "Are you sure you want to give the Owner access to the user?"}
              </div>
              <div className="t-mt-8 t-flex t-justify-end t-gap-3">
                <Button onClick={closeModal}>Cancel</Button>
                <Button
                  customType={isSuperAdmin ? "danger" : "primary"}
                  onClick={updateGroupSuperAdmin}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
    </>
  );
};

export default AdminUpdateModal;
