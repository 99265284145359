const SmallPencil = ({ color = "#2D7BB9" }) => {
  return (
    <>
      <span className="t-sr-only">Pencil</span>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_20_24644)">
          <path
            d="M8.10518 16.8888H4.61377C4.44801 16.8888 4.28904 16.8229 4.17183 16.7057C4.05462 16.5885 3.98877 16.4295 3.98877 16.2638V12.7724C3.98885 12.6068 4.05459 12.4481 4.17158 12.331L13.806 2.69659C13.9232 2.57946 14.0821 2.51367 14.2478 2.51367C14.4134 2.51367 14.5724 2.57946 14.6896 2.69659L18.181 6.18565C18.2981 6.30285 18.3639 6.46175 18.3639 6.62744C18.3639 6.79313 18.2981 6.95204 18.181 7.06924L8.54658 16.706C8.42946 16.823 8.27072 16.8887 8.10518 16.8888Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.4888 5.01367L15.8638 9.38867"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_20_24644">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0.86377 0.0136719)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SmallPencil;
