import "../static/styles/components/errorFallback.css";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";

export const ErrorFallback = () => {
  const refresh = () => window.location.reload();

  return (
    <Modal.Root open={true}>
      <Modal.Content>
        <Modal.Body>
          <p>Oops!</p>
          <p>Something went wrong, please try refreshing.</p>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button onClick={refresh} customType="primary" size="large">
            Refresh
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
