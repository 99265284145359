import classNames from "classnames";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Badge } from "components/Task/Badge";
import {
  COMPLETED,
  IN_CLIENT_REVIEW,
} from "constants/bookkeepingMonthlySummaryStatus";
import { YYYY_MM_DD } from "constants/date";
import { PAYG, PRO } from "constants/subscriptionType";
import dayjs from "dayjs";
import { BILLING_INFO_PLAN_DESCRIPTION } from "dictionaries";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetBillingInvoiceQuery } from "store/apis/billingInvoice";
import {
  useGetMonthlyBillingDetailsQuery,
  useMarkMonthCompletedMutation,
} from "store/apis/financialClosing";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import {
  closeBillingModal,
  openInvoiceModal,
  setBillingInfoMonthId,
} from "store/slices/financialClosing";
import { RootState } from "store/store";
import { BillingInvoice } from "types/Models/billing";
import { MonthlyClosingBillingInfo } from "types/Models/overview";

const PlanInfo = ({
  billingInfo,
}: {
  billingInfo?: MonthlyClosingBillingInfo;
}) => {
  const {
    membership,
    slab_min_value,
    slab_max_value,
    expenses,
    number_of_transactions,
  } = billingInfo || {};

  const Item = ({
    children,
    title,
    description,
    condition,
  }: {
    children: ReactNode;
    title: string;
    description: ReactNode;
    condition: boolean;
  }) => {
    if (!condition) {
      return null;
    }

    return (
      <div className="t-flex t-flex-col t-text-text-60 t-gap-2">
        <span className="t-text-overline t-capitalize">{title}</span>
        <>{children}</>
        <span className="t-text-body-sm">{description}</span>
      </div>
    );
  };

  return (
    <div className="t-flex t-justify-between t-items-center">
      <Item
        condition={Boolean(membership)}
        title="BOOKKEEPING PLAN"
        description={BILLING_INFO_PLAN_DESCRIPTION[membership!]}
      >
        <div
          className={classNames(
            "t-w-max t-rounded t-px-2 t-py-1 t-text-overline",
            {
              "t-bg-yellow-30": membership === PRO,
              "t-bg-purple-20": membership !== PRO,
            }
          )}
        >
          {membership}
        </div>
      </Item>
      {membership === PRO && (
        <Item
          condition={Boolean(expenses)}
          title="VOLUME OF TRANSACTION"
          description="Excluding Transfer Pricing Volumes"
        >
          <div className="t-text-subtitle-sm">
            <AmountSuperScript amount={expenses!} />
          </div>
        </Item>
      )}
      {membership === PAYG && (
        <Item
          condition={Boolean(number_of_transactions?.toString())}
          title="NUMBER OF TRANSACTIONS"
          description=""
        >
          <div className="t-text-subtitle-sm">{number_of_transactions}</div>
        </Item>
      )}
      <Item
        condition={
          Boolean(slab_min_value?.toString()) &&
          Boolean(slab_max_value?.toString())
        }
        title="SLAB"
        description={
          <ConditionalLink to="https://www.inkle.io/pricing#Bookkeeping">
            View Pricing Slabs
          </ConditionalLink>
        }
      >
        <div className="t-text-subtitle-sm">
          <span>
            <AmountSuperScript amount={slab_min_value!} />
          </span>
          -
          <span>
            <AmountSuperScript amount={slab_max_value!} />
          </span>
        </div>
      </Item>
    </div>
  );
};

const BillingInfo = ({
  billingInfo,
}: {
  billingInfo?: MonthlyClosingBillingInfo;
}) => {
  const {
    total,
    coupon,
    discount,
    amount_paid,
    available_bookkeeping_transactions,
  } = billingInfo || {};

  const Item = ({
    title,
    children,
    condition,
  }: {
    title: ReactNode;
    children: ReactNode;
    condition: boolean;
  }) => {
    if (!condition) {
      return null;
    }

    return (
      <div className="t-flex t-justify-between t-items-center">
        {title}
        <span className="t-text-subtitle-sm">{children}</span>
      </div>
    );
  };

  return (
    <div className="t-flex t-flex-col t-gap-4 t-text-text-60">
      <Item
        condition={Boolean(total)}
        title={<span className="t-text-subtitle-sm">Total</span>}
      >
        <AmountSuperScript amount={total!} />
      </Item>
      <Item
        condition={Boolean(available_bookkeeping_transactions?.toString())}
        title={
          <span className="t-text-subtitle-sm">
            Available transaction credits
          </span>
        }
      >
        <AmountSuperScript amount={available_bookkeeping_transactions!} />
      </Item>
      <Item
        condition={Boolean(coupon)}
        title={
          <span className="t-text-body-sm">
            Discounts
            <span className="t-text-purple"> ({coupon} Applied)</span>
          </span>
        }
      >
        -<AmountSuperScript amount={discount!} />
      </Item>
      <Item
        condition={Boolean(amount_paid)}
        title={<span className="t-text-body-sm">Advance paid</span>}
      >
        -<AmountSuperScript amount={amount_paid!} />
      </Item>
    </div>
  );
};

const BalanceAmount = ({
  billingInfo,
}: {
  billingInfo?: MonthlyClosingBillingInfo;
}) => {
  const { balance_amount, payment_required } = billingInfo || {};

  if (Boolean(balance_amount?.toString())) {
    return (
      <div className="t-flex t-justify-between t-items-center">
        <span className="t-text-subtitle-sm t-text-text-60">
          Balance Amount
        </span>
        <div className="t-flex t-gap-3 t-items-center">
          {!payment_required && <Badge type="COMPLETED">Paid</Badge>}
          <span className="t-text-subtitle t-text-red">
            <AmountSuperScript amount={Number(balance_amount)} />
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export const Billing = () => {
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();
  const [markMonthCompleted] = useMarkMonthCompletedMutation();
  const [showPayModal, setShowPayModal] = useState(false);
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { monthId, open } = useSelector(
    (state: RootState) => state.financialClosing.billingInfoModal
  );
  const { data: billingInfo, isLoading } = useGetMonthlyBillingDetailsQuery(
    {
      groupId,
      entityId,
      monthId,
    },
    { skip: !groupId || !entityId || !monthId, refetchOnMountOrArgChange: true }
  );

  const [getInvoice] = useLazyGetBillingInvoiceQuery();

  const { invoices, bookkeeping_status, payment_required } = billingInfo || {};

  const [allInvoiceInfo, setAllInvoiceInfo] = useState<BillingInvoice[]>([]);

  useEffect(() => {
    const fetchInvoicesInfo = async () => {
      if (invoices) {
        try {
          const invoiceInfo = await Promise.all(
            invoices.map(async (invoice) => {
              const data = await getInvoice({
                groupId,
                invoiceId: invoice.invoice_id,
                entityId,
              }).unwrap();
              return data;
            })
          );
          setAllInvoiceInfo(invoiceInfo);
        } catch (error) {
          alertToast({ message: "Failed to fetch invoice info" });
        }
      }
    };

    fetchInvoicesInfo();
  }, [invoices?.length, isLoading]);

  const onBillingClose = () => {
    dispatch(closeBillingModal());
    dispatch(setBillingInfoMonthId(""));
  };

  const onSubmit = () => {
    if (bookkeeping_status === IN_CLIENT_REVIEW && !payment_required) {
      onPaymentSuccess();
      onBillingClose();
    } else {
      dispatch(setPaymentTitle("Bookkeeping"));
      dispatch(setUsableCredit(true));
      dispatch(closeBillingModal());
      setShowPayModal(true);
    }
  };

  const onCheckoutModalClose = () => {
    setShowPayModal(false);
    dispatch(setBillingInfoMonthId(""));
  };

  const onPaymentSuccess = async () => {
    try {
      await markMonthCompleted({
        groupId,
        entityId,
        uuid: monthId,
      }).unwrap();
      successToast({ message: "Payment done!" });
      onCheckoutModalClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onViewInvoice = () => {
    dispatch(openInvoiceModal(monthId));
    onBillingClose();
  };

  return (
    <>
      <Modal.Root open={open} onOpenChange={onBillingClose}>
        <Modal.Content size="large">
          {isLoading ? (
            <Loader />
          ) : (
            <Formik onSubmit={onSubmit} initialValues={{ reviewed: false }}>
              {({ values: { reviewed }, setFieldValue }) => (
                <Form className="all:unset">
                  <Modal.Header>
                    <Modal.Title>Billing Details</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-flex t-gap-4 t-flex-col">
                    <PlanInfo billingInfo={billingInfo} />
                    <Divider />
                    <BillingInfo billingInfo={billingInfo} />
                    <Divider />
                    <BalanceAmount billingInfo={billingInfo} />
                    <Divider />
                    {bookkeeping_status === IN_CLIENT_REVIEW && (
                      <div className="t-bg-blue-0 t-rounded-md t-p-3.5 t-flex t-gap-2 t-flex-col">
                        <div className="t-text-text-black t-text-body-sm">
                          Please review the reports & pay balance amount to
                          start bookkeeping for next month
                        </div>
                        <Checkbox
                          name="reviewed"
                          label="I have reviewed the reports"
                          onChange={(e) => {
                            const { checked } = e.target;
                            setFieldValue("reviewed", checked);
                          }}
                        />
                      </div>
                    )}
                  </Modal.Body>
                  <Modal.Footer className="t-flex t-justify-end">
                    {bookkeeping_status === COMPLETED ? (
                      <Button
                        type="button"
                        customType="secondary"
                        size="small"
                        onClick={onViewInvoice}
                      >
                        View Invoices
                      </Button>
                    ) : (
                      <Button
                        customType="primary"
                        disabled={!reviewed}
                        size="small"
                        type="submit"
                      >
                        {bookkeeping_status === IN_CLIENT_REVIEW &&
                        !payment_required ? (
                          <>Mark Complete</>
                        ) : (
                          <>Pay Now</>
                        )}
                      </Button>
                    )}
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Content>
      </Modal.Root>
      {showPayModal && allInvoiceInfo.length > 0 && (
        <CheckoutModal
          entityId={entityId}
          type="invoices"
          onInvoicePaid={onPaymentSuccess}
          open={showPayModal}
          onClose={onCheckoutModalClose}
          invoices={allInvoiceInfo}
        />
      )}
    </>
  );
};
