import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import { CREDIT_CARD_MADE_DEFAULT } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useSavePaymentMethodMutation } from "store/apis/subscriptions";

const DefaultCardModal = ({
  show,
  closeModal,
  paymentMethodId,
  addCard,
  cardAddLoading,
  entityId,
}: {
  show: boolean;
  closeModal: () => void;
  paymentMethodId?: string;
  addCard?: () => void;
  cardAddLoading?: boolean;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const {
    authtoken: { email },
  } = useContext(authContext);
  const [savePaymentMethod, { isLoading }] = useSavePaymentMethodMutation();
  const { trackEvent } = useAnalytics();

  const updateDefaultCard = async () => {
    try {
      if (paymentMethodId) {
        await savePaymentMethod({
          groupId,
          paymentMethodId,
          isDefaultCard: true,
          entityId,
        }).unwrap();
        trackEvent(CREDIT_CARD_MADE_DEFAULT, {
          group_id: groupId,
          email: email,
        });
        successToast({ message: "Card marked as default" });
        closeModal();
      }
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-p-0 md:!t-w-[440px]">
          <Modal.Close />
          <Modal.Title className="!t-p-6">Mark as default</Modal.Title>
          <div className="t-border t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-p-6">
            <div className="t-text-body-lg t-text-text-black">
              This card will be charged for all subscription payments. Are you
              sure you want to go ahead?
            </div>
            <div className="t-mt-8 t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={isLoading || cardAddLoading}
                customType="primary"
                isLoading={isLoading || cardAddLoading}
                onClick={() =>
                  Boolean(paymentMethodId) ? updateDefaultCard() : addCard?.()
                }
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default DefaultCardModal;
