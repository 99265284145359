import * as RDialog from "@radix-ui/react-dialog";
import cx from "classnames";
import { HTMLAttributes } from "react";
import Cross from "static/images/X.svg";

const DialogOverlay = ({
  uncontrolledZIndex = false,
  ...props
}: RDialog.DialogOverlayProps &
  React.RefAttributes<HTMLDivElement> & {
    uncontrolledZIndex?: boolean;
  }) => (
  <RDialog.Overlay
    {...props}
    className={cx(
      "t-fixed t-inset-0 t-bg-neutral-100 t-opacity-30",
      props.className || "",
      {
        "t-z-modal": !uncontrolledZIndex,
      }
    )}
  />
);

const Body = (props: HTMLAttributes<HTMLDivElement>) => (
  <div className="t-pt-5" {...props} />
);

const DialogContent = ({
  uncontrolledZIndex = false,
  ...props
}: RDialog.DialogContentProps &
  React.RefAttributes<HTMLDivElement> & {
    uncontrolledZIndex?: boolean;
  }) => {
  return (
    <RDialog.Content
      {...props}
      className={cx(
        "t-fixed t-left-1/2 t-top-1/2 t-min-w-[80%]  -t-translate-x-1/2 -t-translate-y-1/2 t-transform t-rounded-lg t-bg-white t-p-6 md:t-min-w-[500px]",
        props.className || "",
        {
          "t-z-modal": !uncontrolledZIndex,
        }
      )}
    />
  );
};

const TitleContent = (
  props: RDialog.DialogTitleProps & React.RefAttributes<HTMLHeadingElement>
) => {
  return (
    <RDialog.Title
      {...props}
      className={cx(
        "t-m-0 t-pb-1 t-text-h6 t-leading-6",
        props.className || ""
      )}
    />
  );
};

const Close = (props: RDialog.DialogCloseProps) => (
  <RDialog.Close
    {...props}
    className={cx(
      "all:unset t-absolute t-right-6 t-top-6",
      props.className || ""
    )}
  >
    <img src={Cross} alt="Close Modal" />
  </RDialog.Close>
);

const Description = (props: RDialog.DialogDescriptionProps) => (
  <RDialog.Description
    {...props}
    className={cx("all:unset", props.className || "")}
  />
);

const Modal = {
  ...RDialog,
  Title: TitleContent,
  Body,
  Content: DialogContent,
  UnStyledContent: RDialog.Content,
  Overlay: DialogOverlay,
  Close,
  Description: Description,
};

export default Modal;
