import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PayloadType {
  uuid: string;
  step: string;
  status: string;
  display_text: string;
}

export interface TaskListState {
  taskCounts: {
    ongoing_count: number;
    count: number;
    completed_count: number;
    archived_count: number;
  };
  taskId: string;
  sliderOpened: boolean;
  step: string;
  status: string;
  display_text: string;
  sliderHeight: number;
}

const initialState: TaskListState = {
  taskCounts: {
    ongoing_count: 0,
    count: 0,
    completed_count: 0,
    archived_count: 0,
  },
  taskId: "",
  sliderOpened: false,
  step: "",
  status: "",
  display_text: "",
  sliderHeight: 0,
};

export const taskList = createSlice({
  name: "taskList",
  initialState,
  reducers: {
    setTaskCount: (
      state,
      action: PayloadAction<TaskListState["taskCounts"]>
    ) => {
      state.taskCounts = action.payload;
    },
    openTaskSlider: (state, action: PayloadAction<PayloadType>) => {
      state.taskId = action.payload?.uuid;
      state.status = action.payload?.status;
      state.step = action.payload?.step;
      state.display_text = action.payload?.display_text;
      state.sliderOpened = true;
    },
    closeTaskSlider: (state) => {
      state.taskId = "";
      state.status = "";
      state.step = "";
      state.display_text = "";
      state.sliderOpened = false;
    },
    setTaskSliderHeight: (state, action: PayloadAction<number>) => {
      state.sliderHeight = action.payload;
    },
  },
});

export const {
  setTaskCount,
  openTaskSlider,
  closeTaskSlider,
  setTaskSliderHeight,
} = taskList.actions;

export default taskList.reducer;
