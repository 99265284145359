import { useEffect } from "react";
import uniqBy from "lodash.uniqby";
import { Channel, ChannelFilters, Event } from "stream-chat";
import { useChatContext, moveChannelUp } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { shouldChannelListUpdate } from "utils/shouldChannelListUpdate";
import { useRoleBasedView } from "./useRoleBasedView";

export const useMessageNewListener = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  setChannels: React.Dispatch<
    React.SetStateAction<Array<Channel<StreamChatGenerics>>>
  >,
  lockChannelOrder = false,
  allowNewMessagesFromUnfilteredChannels = true,
  filters?: ChannelFilters<StreamChatGenerics>
) => {
  const { client } = useChatContext<StreamChatGenerics>(
    "useMessageNewListener"
  );
  const { isCustomer } = useRoleBasedView();

  const comparableFilter = JSON.stringify(filters);

  useEffect(() => {
    const handleEvent = async (event?: Event<StreamChatGenerics>) => {
      let update = await shouldChannelListUpdate({
        client,
        event,
        filters,
        isCustomer,
      });

      setChannels((channels) => {
        if (!update) {
          return channels;
        }

        const channelInList =
          channels.filter((channel) => channel?.cid === event?.cid).length > 0;

        if (
          !channelInList &&
          allowNewMessagesFromUnfilteredChannels &&
          event?.channel_type
        ) {
          const channel = client.channel(event.channel_type, event.channel_id);
          return uniqBy([channel, ...channels], "cid");
        }

        if (!lockChannelOrder)
          return moveChannelUp({ channels, cid: event?.cid || "" });

        return channels;
      });
    };

    client.on("message.new", handleEvent);

    return () => {
      client.off("message.new", handleEvent);
    };
  }, [lockChannelOrder, comparableFilter]);
};
