export const NoVendors = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="156"
      height="149"
      viewBox="0 0 156 149"
      fill="none"
    >
      <g clip-path="url(#clip0_3774_106161)">
        <path
          d="M149.132 48.1914H6.87375V140.713H149.132V48.1914Z"
          fill="#E7E6EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.743 14.1477H125.168C125.71 20.2415 127.785 26.3352 131.614 32.4344C135.438 38.5227 137.518 44.6219 138.044 50.7102H155.296C154.597 44.6165 151.943 38.5227 147.019 32.4344C142.095 26.3406 139.441 20.2469 138.743 14.1477Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.578 14.1477H125.168C125.71 20.2415 127.785 26.3352 131.614 32.4344C135.438 38.5227 137.518 44.6219 138.044 50.7102H120.792C120.418 44.6165 118.934 38.5227 116.193 32.4344C113.452 26.3406 111.968 20.2469 111.583 14.1477H111.578Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.578 14.1477H98.0038C98.2204 20.2415 99.1196 26.3406 100.766 32.4344C102.413 38.5281 103.312 44.6219 103.529 50.7102H120.781C120.407 44.6165 118.923 38.5227 116.182 32.4344C113.441 26.3406 111.957 20.2469 111.573 14.1477H111.578Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.4188 14.1477H98.0092C98.2258 20.2415 99.125 26.3406 100.772 32.4344C102.418 38.5281 103.318 44.6219 103.534 50.7102H86.2821C86.2225 44.6165 85.9083 38.5227 85.3504 32.4344C84.7925 26.346 84.4946 20.2469 84.4188 14.1477Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.4188 14.1477H70.8446C70.7525 20.2415 70.4654 26.3406 69.9292 32.4344C69.3929 38.5227 69.1221 44.6219 69.03 50.7102H86.2821C86.2225 44.6165 85.9083 38.5227 85.3504 32.4344C84.7925 26.346 84.4946 20.2469 84.4188 14.1477Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.2596 14.1477H70.85C70.7579 20.2415 70.4708 26.3406 69.9346 32.4344C69.3983 38.5227 69.1275 44.6219 69.0354 50.7102H51.7833C52.0325 44.6165 52.8938 38.5227 54.5188 32.4344C56.1438 26.3406 57.0213 20.2469 57.265 14.1477H57.2596Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.2596 14.1477H43.6692C43.2792 20.2415 41.8004 26.3406 39.0867 32.4344C36.3783 38.5227 34.9267 44.6219 34.5204 50.7102H51.7725C52.0217 44.6165 52.8829 38.5227 54.5079 32.4344C56.1329 26.3406 57.0104 20.2469 57.2542 14.1477H57.2596Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.095 14.1477H43.6692C43.2792 20.2415 41.8004 26.3406 39.0867 32.4344C36.3783 38.5227 34.9267 44.6219 34.5204 50.7102H17.2683C17.8262 44.6165 19.8683 38.5173 23.6654 32.4344C27.4733 26.3352 29.5371 20.2469 30.095 14.1477Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.095 14.1477H16.4938C15.7788 20.2415 13.1354 26.3352 8.23333 32.4344C3.33667 38.5173 0.715 44.6165 0 50.7102H17.2683C17.8263 44.6165 19.8683 38.5173 23.6654 32.4344C27.4733 26.3352 29.5371 20.2469 30.095 14.1477Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.0054 140.476H100V85.2306C100 84.8162 99.5465 84.4761 98.9851 84.4761H58.0149C57.4589 84.4761 57 84.8162 57 85.2306V140.476H57.0054Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.247 50.1685H138V57.2643C138 62.3072 141.879 66.4347 146.624 66.4347C151.369 66.4347 155.253 62.3072 155.253 57.2643V50.1685H155.247Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.748 50.1685H137.995V57.2643C137.995 62.3072 134.117 66.4347 129.372 66.4347C124.627 66.4347 120.743 62.3072 120.743 57.2643V50.1685H120.748Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.2496 50.1685H103.496V57.2643C103.496 62.3072 99.6179 66.4347 94.8729 66.4347C90.1279 66.4347 86.2442 62.3072 86.2442 57.2643V50.1685H86.2496Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.2496 50.1685H69.0029V57.2643C69.0029 62.3072 72.8812 66.4347 77.6262 66.4347C82.3712 66.4347 86.255 62.3072 86.255 57.2643V50.1685H86.2496Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.7563 50.1685H69.0029V57.2643C69.0029 62.3072 65.1246 66.4347 60.3796 66.4347C55.6346 66.4347 51.7508 62.3072 51.7508 57.2643V50.1685H51.7563Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.7563 50.1685H34.5096V57.2643C34.5096 62.3072 38.3879 66.4347 43.1329 66.4347C47.8779 66.4347 51.7617 62.3072 51.7617 57.2643V50.1685H51.7563Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2575 50.1685H34.5042V57.2643C34.5042 62.3072 30.6258 66.4347 25.8808 66.4347C21.1358 66.4347 17.2521 62.3072 17.2521 57.2643V50.1685H17.2575Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2575 50.1685H0.00541687V57.2643C0.00541687 62.3072 3.88375 66.4347 8.62875 66.4347C13.3737 66.4347 17.2575 62.3072 17.2575 57.2643V50.1685Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139 16.0698H16.0004C15.9625 11.9078 18.3538 8.06982 25.7719 8.06982H129.191C136.587 8.06982 138.935 11.9404 139 16.0698Z"
          fill="#B8B5C2"
        />
        <path d="M155.995 140.031H0V148.887H155.995V140.031Z" fill="#B8B5C2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.748 50.1685H103.502V57.2643C103.502 62.3072 107.38 66.4347 112.125 66.4347C116.87 66.4347 120.754 62.3072 120.754 57.2643V50.1685H120.748Z"
          fill="#B8B5C2"
        />
      </g>
      <defs>
        <clipPath id="clip0_3774_106161">
          <rect
            width="156"
            height="148.812"
            fill="white"
            transform="translate(0 0.0698242)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
