import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "cypress/types/lodash";
import { SubscriptionInProfile } from "types/Models/subscription";

export interface SubscriptionModalState {
  isOpen: boolean;
  redirectPath: string;
  isOpenFreeSubscription: boolean;
  isOpenCardAddModal: boolean;
  subscriptionsInactive: SubscriptionInProfile[];
  requiredSubscriptionId: string;
  isUpgradePlanModalOpen: boolean;
}

const initialState: SubscriptionModalState = {
  isOpen: false,
  redirectPath: "/filings",
  isOpenFreeSubscription: false,
  isOpenCardAddModal: false,
  subscriptionsInactive: [],
  requiredSubscriptionId: "",
  isUpgradePlanModalOpen: false,
};

export const subscriptionModalSlice = createSlice({
  name: "subscriptionModal",
  initialState,
  reducers: {
    openSubscriptionModal: (state) => {
      state.isOpen = true;
    },
    closeSubscriptionModal: (state) => {
      state.isOpen = false;
    },
    setRedirectPath: (state, action: PayloadAction<string>) => {
      state.redirectPath = action.payload;
    },
    openFreeSubscription: (state) => {
      state.isOpenFreeSubscription = true;
    },
    closeFreeSubscription: (state) => {
      state.isOpenFreeSubscription = false;
    },
    openCardAddModal: (state) => {
      state.isOpenCardAddModal = true;
    },
    closeCardAddModal: (state) => {
      state.isOpenCardAddModal = false;
    },
    setSubscriptionInactiveStatus: (
      state,
      action: PayloadAction<SubscriptionInProfile[]>
    ) => {
      state.subscriptionsInactive = action.payload;
    },
    setRequiredSubscriptionId: (state, action: PayloadAction<string>) => {
      state.requiredSubscriptionId = action.payload;
    },
    openUpgradePlanModal: (state) => {
      state.isUpgradePlanModalOpen = true;
    },
    closeUpgradePlanModal: (state) => {
      state.isUpgradePlanModalOpen = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openSubscriptionModal,
  closeSubscriptionModal,
  setRedirectPath,
  openFreeSubscription,
  closeFreeSubscription,
  openCardAddModal,
  closeCardAddModal,
  setSubscriptionInactiveStatus,
  setRequiredSubscriptionId,
  openUpgradePlanModal,
  closeUpgradePlanModal,
} = subscriptionModalSlice.actions;

export default subscriptionModalSlice.reducer;
