import { useUpdateQuery } from "hooks/useQuery";
import React from "react";
import ReactPaginate from "react-paginate";

//style
import "../../static/styles/components/paginate.css";

export default function PaginatedItems({
  path,
  setPageNumber,
  pageCount,
  initialPage,
}) {
  const { update } = useUpdateQuery();

  const handlePageClick = (event) => {
    if (setPageNumber) {
      // with search pagination
      setPageNumber(event.selected + 1);
      update({ query: "page", value: event.selected + 1 });
    } else {
      // without search pagination
      update({ query: "page", value: event.selected + 1 });
    }
  };

  return (
    <div className="paginateTab">
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        forcePage={initialPage ? initialPage - 1 : undefined}
      />
    </div>
  );
}
