import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import { useQuery } from "hooks/useQuery";
import React from "react";
import Check7004 from "static/images/Check7004.svg";

const Task7004Complete = ({
  showTaskComplete,
  setShowTaskComplete,
}: {
  showTaskComplete: boolean;
  setShowTaskComplete: (v: boolean) => void;
}) => {
  const query = useQuery();
  const refCode = query.get("ref");

  const signUpLink = refCode ? `/signup?ref=${refCode}` : "/signup";

  return (
    <Modal.Root
      open={showTaskComplete}
      onOpenChange={() => setShowTaskComplete(false)}
    >
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="t-w-[500px] sm:!t-w-[90vw] sm:!t-min-w-[90vw]">
          <div className="t-my-8 t-px-[18%] t-text-center sm:t-px-[10%]">
            <img src={Check7004} alt="Check7004" className="t-h-16" />
            <div className="t-mt-3 t-text-h6 t-font-semibold">
              Thank you for submitting
            </div>
            <div className="t-my-3 t-text-body">
              We are processing your form and You will be hearing from us soon!
            </div>
            <ConditionalLink
              to={signUpLink}
              className="t-flex t-justify-center"
            >
              <Button customType="primary">Signup and track your form</Button>
            </ConditionalLink>
          </div>
          <Modal.Close className="all:unset" />
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default Task7004Complete;
