export const MapPin = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_492_1884)">
        <path
          d="M6.10159 3.92696C7.50466 2.51703 9.40698 1.72394 11.3912 1.72168C13.3755 1.72394 15.2778 2.51703 16.6808 3.92696C18.0839 5.33689 18.8731 7.24851 18.8754 9.24245C18.8754 15.6778 12.0716 20.5381 11.7816 20.7415C11.6672 20.8221 11.5309 20.8653 11.3912 20.8653C11.2515 20.8653 11.1153 20.8221 11.0009 20.7415C10.7108 20.5381 3.90704 15.6778 3.90704 9.24245C3.90929 7.24851 4.69853 5.33689 6.10159 3.92696Z"
          fill="#784EFF"
        />
        <path
          d="M13.8861 9.11587V11.7496C13.8861 11.8704 13.8384 11.9864 13.7533 12.0719C13.6682 12.1573 13.5529 12.2054 13.4326 12.2054H12.2986C12.1783 12.2054 12.0629 12.1573 11.9779 12.0719C11.8928 11.9864 11.845 11.8704 11.845 11.7496V10.6101C11.845 10.5496 11.8211 10.4916 11.7786 10.4489C11.736 10.4062 11.6784 10.3821 11.6182 10.3821H10.711C10.6509 10.3821 10.5932 10.4062 10.5507 10.4489C10.5081 10.4916 10.4842 10.5496 10.4842 10.6101V11.7496C10.4842 11.8704 10.4365 11.9864 10.3514 12.0719C10.2663 12.1573 10.151 12.2054 10.0307 12.2054H8.89669C8.7764 12.2054 8.66102 12.1573 8.57596 12.0719C8.4909 11.9864 8.44311 11.8704 8.44311 11.7496V9.11587C8.4431 9.05279 8.45612 8.99039 8.48135 8.93262C8.50658 8.87486 8.54346 8.82298 8.58967 8.78028L10.8576 6.63003L10.8607 6.6269C10.9442 6.55059 11.053 6.5083 11.1659 6.5083C11.2788 6.5083 11.3876 6.55059 11.4711 6.6269C11.472 6.62801 11.4731 6.62906 11.4742 6.63003L13.7421 8.78028C13.7879 8.82321 13.8243 8.87518 13.8491 8.93294C13.8739 8.99069 13.8865 9.05298 13.8861 9.11587Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_492_1884">
          <rect
            width="21.7722"
            height="21.8786"
            fill="white"
            transform="translate(0.278503 0.353516)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
