import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useMarkTransferInitiatedMutation } from "store/apis/transferPricing";
import { TransferPricingTransfer } from "types/Models/TransferPricingTransfer";
import { ModalProps } from "types/utils/modal";

export const PaymentInitiateModal = ({
  isOpen,
  close,
  tpPaymentId,
  isCheckBoxSelected,
  setIsCheckBoxSelected,
  amount,
  currency,
  purposeCodeUuid,
  setCurrentTransferDetails,
}: ModalProps & {
  tpPaymentId: string;
  amount: number;
  currency: string;
  purposeCodeUuid: string;
  isCheckBoxSelected: boolean;
  setIsCheckBoxSelected: (newState: React.SetStateAction<boolean>) => void;
  setCurrentTransferDetails?: (
    newState: React.SetStateAction<TransferPricingTransfer | undefined>
  ) => void;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();

  const [markTransferInitiated, { isLoading: isInitiatedLoading }] =
    useMarkTransferInitiatedMutation();

  const handleCheckBoxClose = () => {
    close();
    setIsCheckBoxSelected(false);
  };

  const handleInitiatedPayment = async () => {
    try {
      const resp = await markTransferInitiated({
        groupId,
        tpPaymentId: tpPaymentId,
        payLoad: {
          amount: amount,
          currency: currency,
          purpose_code_uuid: purposeCodeUuid,
        },
      }).unwrap();
      setCurrentTransferDetails?.(resp);
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      setIsCheckBoxSelected(false);
    }
    handleCheckBoxClose();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={handleCheckBoxClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Payment Confirmation</Modal.Title>
          <Modal.Close />
        </Modal.Header>

        <Modal.Body>
          Please confirm that you have initiated the payment to the Virtual A/C
          for this transfer.
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-3">
            <Button onClick={handleCheckBoxClose}>No</Button>
            <Button
              customType="primary"
              isLoading={isInitiatedLoading}
              disabled={isInitiatedLoading}
              onClick={handleInitiatedPayment}
            >
              Yes, I confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
