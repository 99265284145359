import { DD_MMM_YYYY, MMM_YYYY, YYYY } from "constants/date";
import { TIME_FORMAT } from "constants/date";
import dayjs from "dayjs";

export const formatDate = (date?: any) => {
  return date ? dayjs(date).format(DD_MMM_YYYY) : dayjs().format(DD_MMM_YYYY);
};

export const formatTime = (date?: any) => {
  return date ? dayjs(date).format(TIME_FORMAT) : dayjs().format(TIME_FORMAT);
};

export const formatTimeToMonthYear = (date?: any) => {
  return date ? dayjs(date).format(MMM_YYYY) : dayjs().format(MMM_YYYY);
};

export const formatDateToYear = (date?: any) => {
  return date ? dayjs(date).format(YYYY) : dayjs().format(YYYY);
};
