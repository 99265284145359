import BookkeepingAccordionContent from "components/BookkeepingAccordion/BookkeepingAccordionContent";
import { BookkeepingCardContainer } from "components/BookkeepingCards/BookkeepingCardContainer";
import { BookkeepingCardFooter } from "components/BookkeepingCards/BookkeepingCardFooter";
import { BookkeepingCardHeader } from "components/BookkeepingCards/BookkeepingCardHeader";
import { BookkeepingCardSummary } from "components/BookkeepingCards/BookkeepingCardSummary";
import Loader from "components/design/loader";
import NewModal from "components/DesignSystem/Modal/Modal";
import { DocumentPreviewModal } from "components/PreviewModal";
import { BalanceSheetIcon } from "components/icons/Navbar/BalanceSheetIcon";
import { CashflowIcon } from "components/icons/Navbar/CashflowIcon";
import { PNLIcon } from "components/icons/Navbar/PNLIcon";
import {
  COMPLETED,
  IN_PROGRESS,
  NOT_STARTED,
  PENDING_REVIEW,
} from "constants/bookkeepingMonthlySummaryStatus";
import { FormikValues } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useDeleteReportMutation,
  useGetSeasonSummaryQuery,
  useGetTransactionMonthlySummariesQuery,
  useUpdateSeasonSummaryMutation,
} from "store/apis/financialClosing";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { openFloatingChat } from "store/slices/chat";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { FileObject } from "types/Models/fileObject";
import { MonthlySummariesResponse } from "types/Models/overview";
import { openLink } from "utils/openLink";
import { AddSummaryModal, Reports } from "./AddSummaryModal";
import { PaymentModal } from "./PaymentModal";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";

const SeasonReports = ({
  status,
  profitLossStatement,
  balanceSheet,
  cashFlowStatement,
}: {
  status: string;
  profitLossStatement: FileObject | null;
  balanceSheet: FileObject | null;
  cashFlowStatement: FileObject | null;
}) => {
  const openPreview = useDocPreview();
  const { uuid: groupId } = useCurrentGroupContext();
  const [download] = useLazyGetPreviewUrlQuery();

  const downloadReport = async (fileId: string) => {
    try {
      const { data } = await download({ groupId, fileId });
      const { download_url } = data || {};
      openLink(download_url);
    } catch (error) {}
  };

  const isAnyReport =
    Boolean(cashFlowStatement) ||
    Boolean(balanceSheet) ||
    Boolean(profitLossStatement);

  if (!isAnyReport) {
    return null;
  }
  return (
    <div className="t-flex t-flex-col t-gap-2">
      {cashFlowStatement && (
        <BookkeepingAccordionContent.SummaryReport
          onPreview={() => {
            openPreview(cashFlowStatement.uuid);
          }}
          icon={<CashflowIcon />}
          label="Cashflow Statement"
          onDownload={() => downloadReport(cashFlowStatement.uuid)}
        />
      )}
      {balanceSheet && (
        <BookkeepingAccordionContent.SummaryReport
          icon={<BalanceSheetIcon />}
          onPreview={() => {
            openPreview(balanceSheet.uuid);
          }}
          label="Balance Sheet"
          onDownload={() => downloadReport(balanceSheet.uuid)}
        />
      )}
      {profitLossStatement && (
        <BookkeepingAccordionContent.SummaryReport
          onPreview={() => {
            openPreview(profitLossStatement.uuid);
          }}
          icon={<PNLIcon />}
          label="PNL Statement"
          onDownload={() => downloadReport(profitLossStatement.uuid)}
        />
      )}
      <DocumentPreviewModal />
    </div>
  );
};

const Container = ({
  season,
  onStartClosing,
  onMarkAsComplete,
}: {
  season: MonthlySummariesResponse;
  onStartClosing: (uuid: string) => void;
  onMarkAsComplete: (uuid: string) => void;
}) => {
  const { isCpa, isAdmin } = useRoleBasedView();
  const dispatch = useDispatch();
  const {
    status,
    uuid,
    name,
    profit_loss_statement,
    balance_sheet,
    cash_flow_statement,
    expenses,
    number_of_transactions,
    transfer_pricing_volume,
  } = season;

  const DISCRIPTION = {
    [PENDING_REVIEW]:
      isCpa || isAdmin
        ? "Awaiting client review"
        : "Hey, we need your final review on the annual financial reports we've built for this entity.",

    [COMPLETED]:
      isCpa || isAdmin
        ? "Books for Fiscal year 2022 have been closed successfully."
        : "Congrats! Your books for Fiscal year 2022 have been closed successfully.",
  };

  if (status === NOT_STARTED) return null;

  if (status === IN_PROGRESS) {
    return (
      <BookkeepingCardContainer status={status}>
        <div className="t-mr-auto t-flex t-flex-col t-gap-4">
          <BookkeepingCardHeader
            title={name}
            description="Please start yearly closing for the client and fill in the metrics."
          />
          <BookkeepingCardFooter
            status={status}
            onStartClosing={() => onStartClosing(uuid)}
          />
        </div>
      </BookkeepingCardContainer>
    );
  }

  if (status === PENDING_REVIEW || status === COMPLETED)
    return (
      <BookkeepingCardContainer status={status}>
        <BookkeepingCardHeader title={name} description={DISCRIPTION[status]} />
        <div className="t-flex t-flex-col t-gap-14">
          <div className="t-flex t-flex-col t-gap-2">
            <BookkeepingCardSummary
              expenseVolume={expenses}
              transferPricingVolume={transfer_pricing_volume}
              noOfTransactions={number_of_transactions}
            />
            <SeasonReports
              status={status}
              profitLossStatement={profit_loss_statement}
              balanceSheet={balance_sheet}
              cashFlowStatement={cash_flow_statement}
            />
          </div>
          <BookkeepingCardFooter
            onMarkAsComplete={() => onMarkAsComplete(uuid)}
            onChatWithUs={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              dispatch(openFloatingChat());
            }}
            onEdit={() => onStartClosing(uuid)}
            status={status}
          />
        </div>
      </BookkeepingCardContainer>
    );

  return null;
};

export const AnnualSummary = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [seasonId, setSeasonId] = useState("");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: monthlySummaries = [] } =
    useGetTransactionMonthlySummariesQuery(
      {
        groupId,
        entityId,
      },
      { skip: !groupId || !entityId }
    );

  const {
    data: seasonSummary,
    isLoading: isGetSeasonLoading,
    isFetching: isGetSeasonFetching,
    isSuccess: isGetSeasonSuccess,
  } = useGetSeasonSummaryQuery(
    { entityId, groupId, uuid: seasonId, getUnpaidInvoices: true },
    {
      skip: !groupId || !entityId || !seasonId,
      refetchOnMountOrArgChange: true,
    }
  );
  const { unpaid_invoices = [] } = seasonSummary || {};
  const isUnpaid = unpaid_invoices?.length > 0;
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();

  const [upadteSeasonSummary, { isLoading: isSeasonSummaryUpdating }] =
    useUpdateSeasonSummaryMutation();
  const [deleteReport] = useDeleteReportMutation();

  const annualClosingStart = async (values: FormikValues) => {
    const {
      number_of_transactions,
      expenses,
      profit_loss_statement,
      cash_flow_statement,
      balance_sheet,
    } = values;

    const localExpenses = expenses === "" ? null : expenses;
    const localNumberOfTransactions =
      number_of_transactions === "" ? null : number_of_transactions;

    try {
      const files: any = {
        profit_loss_statement:
          // @ts-ignore
          profit_loss_statement?.uuid || profit_loss_statement,
        // @ts-ignore
        cash_flow_statement: cash_flow_statement?.uuid || cash_flow_statement,
        // @ts-ignore
        balance_sheet: balance_sheet?.uuid || balance_sheet,
      };
      const formData = new FormData();
      formData.append("status", PENDING_REVIEW);
      formData.append("uuid", seasonId);
      formData.append("number_of_transactions", localNumberOfTransactions);
      formData.append("expenses", localExpenses);
      for (var key in files) {
        if (files[key]) {
          formData.append(key, files[key]);
        }
      }
      await upadteSeasonSummary({
        groupId,
        entityId,
        payload: formData,
      }).unwrap();
      setIsModalOpen(false);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onStartClosing = (uuid: string) => {
    setIsModalOpen(true);
    setSeasonId(uuid);
  };

  const onMarkAsComplete = (uuid: string) => {
    setIsPaymentModalOpen(true);
    setSeasonId(uuid);
  };

  const onProceedToPay = async () => {
    try {
      if (isUnpaid) {
        setIsPaymentModalOpen(false);
        dispatch(setUsableCredit(false));
        dispatch(setPaymentTitle("Bookkeeping"));
        setShowCheckoutModal(true);
      } else {
        await upadteSeasonSummary({
          groupId,
          entityId,
          payload: {
            uuid: seasonId,
            status: COMPLETED,
          },
        }).unwrap();
        setIsPaymentModalOpen(false);
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onPaymentSuccess = async () => {
    try {
      await upadteSeasonSummary({
        groupId,
        entityId,
        payload: {
          uuid: seasonId,
          status: COMPLETED,
        },
      }).unwrap();
      setIsPaymentModalOpen(false);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onReportDelete = async ({ field }: { field: Reports }) => {
    try {
      await deleteReport({
        entityId,
        groupId,
        field,
        uuid: seasonId,
        reportFrom: "season_summary",
      }).unwrap();
      successToast({ message: `Report deleted` });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-4">
      {monthlySummaries.map((season) => (
        <Container
          season={season}
          onStartClosing={onStartClosing}
          onMarkAsComplete={onMarkAsComplete}
          key={season.uuid}
        />
      ))}
      {isModalOpen && (
        <NewModal.Root
          open={isModalOpen}
          onOpenChange={(open) => {
            setSeasonId("");
            setIsModalOpen(open);
          }}
        >
          <NewModal.Content size="regular">
            {isGetSeasonFetching || !isGetSeasonSuccess ? (
              <Loader />
            ) : (
              <>
                <NewModal.Header>
                  <NewModal.Title>
                    Closing for {seasonSummary?.name}
                  </NewModal.Title>
                  <NewModal.Close />
                </NewModal.Header>
                <AddSummaryModal
                  onReportDelete={({ field }) => onReportDelete({ field })}
                  summaryFor="year"
                  summary={seasonSummary}
                  onSubmit={annualClosingStart}
                />
              </>
            )}
          </NewModal.Content>
        </NewModal.Root>
      )}

      {isPaymentModalOpen && (
        <PaymentModal
          isOpen={isPaymentModalOpen}
          setIsOpen={setIsPaymentModalOpen}
          uuid={seasonId}
          onProceedToPay={onProceedToPay}
          inLoading={isSeasonSummaryUpdating}
        />
      )}
      {showCheckoutModal && (
        <CheckoutModal
          type="invoices"
          onInvoicePaid={onPaymentSuccess}
          open={showCheckoutModal}
          onClose={() => setShowCheckoutModal(false)}
          invoices={unpaid_invoices}
          entityId={entityId}
        />
      )}
    </div>
  );
};
