import { Header } from "components/DesignSystem/Header/Header";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetEntitiesQuery } from "store/apis/group";
import { useGetStakeholderUpdateQuery } from "store/apis/stakeholderUpdate";
import { InvestorUpdateEditor } from "components/InvestorUpdateEditor";

export const InvestorUpdatePreviewPage = () => {
  const { investorUpdateId } = useParams<{ investorUpdateId: string }>();
  const { data: { groups: [group] = [null] } = {} } = useGetEntitiesQuery();

  const {
    data: stakeholderUpdate,
    isLoading,
    isSuccess: stakeholderUpdateLoaded,
  } = useGetStakeholderUpdateQuery(
    {
      updateId: investorUpdateId,
      groupId: group?.uuid!,
    },
    {
      skip: !group?.uuid,
    }
  );

  if (isLoading) {
    return (
      <div className="t-flex t-h-full t-w-full t-items-center t-justify-center">
        <div className="t-h-12 t-w-12 t-animate-spin t-rounded-full t-border-4 t-border-solid t-border-purple t-border-t-surface-transparent" />
      </div>
    );
  }
  const investorUpdatesBreadcrumbs = [
    {
      name: "Investor Updates",
      link: `/community/investor-updates`,
    },
    { name: stakeholderUpdate?.title || " " },
  ];

  const initialBlocks =
    stakeholderUpdateLoaded &&
    stakeholderUpdate?.block_json &&
    typeof stakeholderUpdate?.block_json === "string"
      ? JSON.parse(stakeholderUpdate?.block_json)
      : [];

  return (
    <div className="t-h-full t-w-full">
      <Header
        title={
          <div className="t-flex t-items-center t-gap-3">
            <span>{stakeholderUpdate?.title}</span>
          </div>
        }
        breadcrumbs={investorUpdatesBreadcrumbs}
      />
      <div className="t-m-auto t-py-8 md:t-w-8/12">
        <InvestorUpdateEditor
          config={{
            initialContent: initialBlocks,
            editable: false,
          }}
        />
      </div>
    </div>
  );
};
