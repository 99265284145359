import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TransactionState {
  showAddOrEditModal: boolean;
  merchantsToMerge: string[];
}

const initialState: TransactionState = {
  showAddOrEditModal: false,
  merchantsToMerge: [],
};

export const vendors = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    openAddOrEditModal: (state) => {
      state.showAddOrEditModal = true;
    },
    closeAddOrEditModal: (state) => {
      state.showAddOrEditModal = false;
    },
    setMerchantToMerge: (state, action: PayloadAction<string[]>) => {
      state.merchantsToMerge = action.payload;
    },
  },
});

export const { openAddOrEditModal, closeAddOrEditModal, setMerchantToMerge } =
  vendors.actions;

export default vendors.reducer;
