import IcalDownload from "static/images/IcalDownload.svg";
import IcalReminder from "static/images/IcalSubscribe.svg";
import IcalWhatsapp from "static/images/IcalWhatsapp.svg";
import Envelope from "static/images/Envelope.svg";
import CalendarBlankMeeting from "static/images/CalendarBlankMeeting.svg";
import { openLink } from "utils/openLink";
import { PHONE } from "constants/help";
import LinkIcon from "static/images/LinkIcon.svg";

import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";

import GoogleCalendar from "static/images/GoogleCalendar.svg";

const CalendarButtonAction = ({
  setShowCalendlyModal,
  openShareModal,
  openReminderModal,
  openEmailPdfModal,
  calendarPdfLoading,
  downloadCalendar,
  addToCalendar,
}: {
  setShowCalendlyModal: () => void;
  openShareModal: () => void;
  openReminderModal: () => void;
  setQuotation?: () => void;
  setSendQuotation?: () => void;
  openEmailPdfModal?: () => void;
  downloadCalendar: () => void;
  calendarPdfLoading?: boolean;
  addToCalendar: () => void;
}) => {
  return (
    <>
      <div className="md:t-hidden">
        <ToolTip text="Schedule a meeting">
          <span>
            <Button customType="icon" onClick={setShowCalendlyModal}>
              <img src={CalendarBlankMeeting} alt="Book a zoom call" />
            </Button>
          </span>
        </ToolTip>
      </div>
      <ToolTip text="Copy unique link">
        <span>
          <Button customType="icon" onClick={openShareModal}>
            <img src={LinkIcon} alt="IcalShare" />
          </Button>
        </span>
      </ToolTip>
      <div className="md:t-hidden">
        <ToolTip text="Reach out on WhatsApp">
          <span>
            <Button
              customType="icon"
              onClick={() => openLink(`https://wa.me/${PHONE}`)}
            >
              <img src={IcalWhatsapp} alt="IcalWhatsapp" />
            </Button>
          </span>
        </ToolTip>
      </div>
      <ToolTip text="Subscribe to reminders">
        <span>
          <Button customType="icon" onClick={openReminderModal}>
            <img src={IcalReminder} alt="IcalReminder" />
          </Button>
        </span>
      </ToolTip>
      <ToolTip text="Add to calendar">
        <span>
          <Button customType="icon" onClick={addToCalendar}>
            <img
              className="t-w-[26px]"
              src={GoogleCalendar}
              alt="Add to google calendar"
            />
          </Button>
        </span>
      </ToolTip>
    </>
  );
};

export default CalendarButtonAction;
