export const CallFromChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.9624 2.5C10.8105 2.72761 11.5838 3.17428 12.2047 3.79519C12.8256 4.41611 13.2723 5.18941 13.4999 6.0375"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44385 4.43127C9.95392 4.56674 10.4191 4.83464 10.7923 5.20782C11.1655 5.581 11.4334 6.0462 11.5688 6.55627"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.78125 7.80002C6.29576 8.86253 7.15492 9.71949 8.21875 10.2313C8.29722 10.2684 8.38402 10.2845 8.47059 10.2779C8.55717 10.2714 8.64054 10.2424 8.7125 10.1938L10.275 9.15002C10.344 9.1032 10.4238 9.07463 10.5069 9.06698C10.5899 9.05933 10.6736 9.07285 10.75 9.10627L13.675 10.3625C13.775 10.4041 13.8585 10.4774 13.9127 10.5712C13.9669 10.6649 13.9888 10.7739 13.975 10.8813C13.8823 11.6049 13.5291 12.2699 12.9815 12.7519C12.4339 13.2339 11.7295 13.4999 11 13.5C8.74566 13.5 6.58365 12.6045 4.98959 11.0104C3.39553 9.41637 2.5 7.25436 2.5 5.00002C2.50016 4.27052 2.76613 3.56607 3.24814 3.01849C3.73015 2.47091 4.39516 2.11773 5.11875 2.02502C5.22615 2.01122 5.33511 2.03312 5.42884 2.08733C5.52258 2.14153 5.59589 2.22505 5.6375 2.32502L6.89375 5.25627C6.92642 5.33149 6.94009 5.41359 6.93355 5.49533C6.92701 5.57707 6.90046 5.65595 6.85625 5.72502L5.8125 7.31252C5.76603 7.38432 5.73876 7.46684 5.73329 7.5522C5.72782 7.63755 5.74433 7.72287 5.78125 7.80002V7.80002Z"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
