import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useGetReconciliationStatusQuery } from "store/apis/reconciliation";
import { RootState } from "store/store";
import { CompleteModal } from "./CompleteModal";
import { ReconciliationConsole } from "./ReconciliationConsole";
import { ReconciliationTable } from "./ReconciliationTable";
import { DashboardLayout } from "components/DashboardLayout";

export const ReconciliationTxn = () => {
  usePageTitle("Reconciliation");
  const { search } = useLocation();
  const { reconcilationId } = useParams<{ reconcilationId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { includedCredits, includedDebits } = useSelector(
    (state: RootState) => state.reconciliation
  );

  const {
    data: csvTxn,
    isLoading,
    isSuccess,
  } = useGetReconciliationStatusQuery(
    {
      reconcilationId,
      entityId,
      groupId,
    },
    { skip: !groupId || !entityId }
  );
  const {
    entity_bank_account_id,
    statement_ending_balance = 0,
    inkle_total_balance = 0,
  } = csvTxn || {};

  const { data: ledger } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const { accounts = [] } = ledger || {};

  const reconcilieBank = accounts.find(
    ({ account: { uuid } }) => uuid === entity_bank_account_id
  )?.account;

  const {
    open: openCompleteModal,
    close: closeCompleteModal,
    isOpen: isCompleteModalOpen,
  } = useModal();

  const breadcrumbs = [
    {
      name: "Reconciliation",
      link: `/books/data-sources/reconciliation${search}`,
    },
    {
      name: reconcilieBank ? (
        `${reconcilieBank?.nickname} - ${reconcilieBank?.mask}`
      ) : (
        <span className="t-flex t-animate-spin">
          <LoadingIcon />
        </span>
      ),
    },
  ];

  const newBalance = parseFloat(
    (inkle_total_balance + includedCredits + includedDebits).toFixed(2)
  );
  const difference = parseFloat(
    (statement_ending_balance - newBalance).toFixed(2)
  );

  const reconciliationHeader = (
    <Header
      v2
      title="Reconciliation"
      breadcrumbs={breadcrumbs}
      right={
        <Button
          size="small"
          customType="primary"
          onClick={openCompleteModal}
          disabled={difference !== 0}
        >
          Complete
        </Button>
      }
    />
  );

  if (isLoading || !isSuccess) {
    return (
      <DashboardLayout header={reconciliationHeader}>
        <Loader />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout header={reconciliationHeader}>
      <div className="t-pt-0 t-flex t-flex-col t-gap-5">
        <div className="t-flex t-gap-6 t-flex-col">
          <ReconciliationConsole />
          <ReconciliationTable />
        </div>
      </div>
      <CompleteModal
        isOpen={isCompleteModalOpen}
        onClose={closeCompleteModal}
      />
    </DashboardLayout>
  );
};
