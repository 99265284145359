import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Transactions } from "types/Models/books";

const initialState = {
  isOpen: false,
  updateTxnResponse: {},
};

export const similarTransactionsSlice = createSlice({
  name: "similarTransactions",
  initialState,
  reducers: {
    openSimilarTxnsModal: (state) => {
      state.isOpen = true;
    },
    closeSimilarTxnModal: (state) => {
      state.isOpen = false;
    },
    setUpdateTxnResponse: (state, action: PayloadAction<Transactions>) => {
      state.updateTxnResponse = action.payload;
    },
  },
});

export const {
  openSimilarTxnsModal,
  closeSimilarTxnModal,
  setUpdateTxnResponse,
} = similarTransactionsSlice.actions;

export default similarTransactionsSlice.reducer;
