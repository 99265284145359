import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useAddTeamMemberMutation } from "store/apis/teamSetting";

type ResendInviteProps = {
  show: boolean;
  closeModal: () => void;
  groupId: string;
  email: string;
  groupRoleId: string;
};

export const ResendInvite = ({
  show,
  closeModal,
  groupId,
  email,
  groupRoleId,
}: ResendInviteProps) => {
  const { alertToast, successToast } = useToast();
  const [addTeamMember, { isLoading }] = useAddTeamMemberMutation();

  const resendInvite = async () => {
    try {
      const body = {
        email: email,
        group_role_id: groupRoleId,
      };
      await addTeamMember({ id: groupId, body }).unwrap();
      successToast({
        message: "Invite resent successfully",
        title: "Group user",
      });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Resend invite</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-100">
              Are you sure you want to resend the invite?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={isLoading}
                customType="primary"
                isLoading={isLoading}
                onClick={resendInvite}
              >
                Resend
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
