import React from "react";

export const Info = ({ stroke = "2", size = "17", color = "currentColor" }) => (
  <>
    <span className="t-sr-only">InfoIcon</span>
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 14.875C12.0208 14.875 14.875 12.0208 14.875 8.5C14.875 4.97918 12.0208 2.125 8.5 2.125C4.97918 2.125 2.125 4.97918 2.125 8.5C2.125 12.0208 4.97918 14.875 8.5 14.875Z"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96875 7.96875H8.5V11.6875H9.03125"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36719 6.375C8.80729 6.375 9.16406 6.01823 9.16406 5.57812C9.16406 5.13802 8.80729 4.78125 8.36719 4.78125C7.92709 4.78125 7.57031 5.13802 7.57031 5.57812C7.57031 6.01823 7.92709 6.375 8.36719 6.375Z"
        fill={color}
      />
    </svg>
  </>
);
