import Loader from "components/design/loader";
import { useToast } from "hooks/useToast";
import Bolt from "static/images/Bolt.svg";
import { useGetPlaidBanksQuery } from "store/apis/bankConnections";
import { LinkBankAccount } from "./LinkBankAccount";

export const TaskConnectBank = ({
  groupId,
  renderActions,
  entityId,
}: {
  groupId: string;
  entityId: string;
  renderActions: ({
    isBankConnected,
  }: {
    isBankConnected: boolean;
  }) => JSX.Element;
}) => {
  const { successToast } = useToast();

  const {
    data: bankAccounts,
    isLoading,
    refetch,
  } = useGetPlaidBanksQuery({
    groupId,
  });

  const onConnect = () => {
    successToast({
      message: "Connected bank account and fetched all the transactions",
    });
    refetch();
  };

  if (isLoading) {
    return (
      <div className="t-mx-auto t-w-full">
        <Loader addClassName="t-h-auto t-py-4" />
      </div>
    );
  }

  if (bankAccounts?.length === 0) {
    return (
      <>
        <div className="t-mx-auto t-flex t-w-3/5 t-flex-col t-items-center t-justify-center t-gap-4 t-rounded-xl t-border t-border-solid t-border-neutral-20 t-px-3 t-py-8">
          <img src={Bolt} alt="Bolt icon" />
          <p className="t-m-0 t-text-body t-text-neutral-50">
            Please connect your US bank account
          </p>
          <LinkBankAccount
            entityId={entityId}
            groupId={groupId}
            connectBankText="Connect bank"
            onConnect={onConnect}
          />
        </div>
        {renderActions({ isBankConnected: false })}
      </>
    );
  }

  return (
    <>
      <div className="t-mx-auto t-flex t-w-3/5 t-flex-col t-items-center t-justify-center t-gap-4  t-rounded-xl t-border t-border-solid t-border-neutral-20 t-px-3 t-py-8">
        <p className="t-m-0 t-text-body t-text-neutral-50">
          You have already connected
        </p>
        <div className="t-w-6/12">
          {bankAccounts?.map(
            ({
              item_data: {
                bank_brand: { name, logo_url },
                uuid,
              },
              accounts_data,
            }) => (
              <div className="t-flex t-gap-3" key={uuid}>
                <img
                  className="t-h-8 t-w-8 t-rounded-full"
                  src={logo_url}
                  alt={name}
                />
                <p className="">{name}</p>
                <p className="t-ml-auto t-text-neutral-50">
                  {accounts_data.length} accounts
                </p>
              </div>
            )
          )}
        </div>
        <LinkBankAccount
          groupId={groupId}
          entityId={entityId}
          connectBankText="Connect another bank"
          onConnect={onConnect}
        />
      </div>
      {renderActions({ isBankConnected: true })}
    </>
  );
};
