const ProfileHelpIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1056_21248)">
        <path
          d="M10.5 15C11.0178 15 11.4375 14.5803 11.4375 14.0625C11.4375 13.5447 11.0178 13.125 10.5 13.125C9.98223 13.125 9.5625 13.5447 9.5625 14.0625C9.5625 14.5803 9.98223 15 10.5 15Z"
          fill={color}
        />
        <path
          d="M10.5 11.25V10.625C11.8805 10.625 13 9.64531 13 8.4375C13 7.22969 11.8805 6.25 10.5 6.25C9.11953 6.25 8 7.22969 8 8.4375V8.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 17.5C14.6421 17.5 18 14.1421 18 10C18 5.85786 14.6421 2.5 10.5 2.5C6.35786 2.5 3 5.85786 3 10C3 14.1421 6.35786 17.5 10.5 17.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1056_21248">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProfileHelpIcon;
