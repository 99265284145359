import { CrossOutlined } from "./icons/CrossOutlined";

export const CrossListItem = ({ children }: { children: React.ReactNode }) => (
  <li className="t-flex t-items-center t-gap-2">
    <span className="t-flex t-text-red">
      <CrossOutlined />
    </span>
    <span className="t-font-medium">{children}</span>
  </li>
);
