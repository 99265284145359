import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { TemplateButtonIcon } from "components/icons/TemplateButton";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { ChatTemplate } from "types/Models/ChatTemplate";
import CreateTemplate from "./CreateTemplate";

interface CreateStepModalProps {
  dropdownOpen: boolean;
  setdropdownOpen: (v: boolean) => void;
  closeCreateTemplateModal: () => void;
  templateData: ChatTemplate;
}

const CreateStepModal = ({
  setdropdownOpen,
  dropdownOpen,
  closeCreateTemplateModal,
  templateData,
}: CreateStepModalProps) => {
  const modalTitle = templateData.uuid ? "Template update" : "Template create";
  const { isOpen } = useSelector((state: RootState) => state.chat);

  return (
    <DropDown.Root
      onOpenChange={(open) => setdropdownOpen(open)}
      open={dropdownOpen}
      modal={false}
    >
      <DropDown.Trigger className="all:unset">
        <Button customType="transparent">
          <TemplateButtonIcon />
        </Button>
      </DropDown.Trigger>
      <DropDown.Portal>
        <DropDown.Content
          onClick={(e: any) => e.stopPropagation()}
          className={classNames(
            "t-mb-[24px] t-ml-[-1em] t-w-[calc(100vw-18vw-476px)] t-rounded-lg t-bg-surface t-p-4 t-drop-shadow-dropdown sm:t-w-[94vw]",
            { "-t-ml-3 !t-w-[400px]": isOpen }
          )}
          align="start"
        >
          <span className="t-mb-2 t-flex t-items-center">
            <>
              <Button
                customType="transparent"
                onClick={closeCreateTemplateModal}
              >
                <i className="fas fa-arrow-left"></i>
              </Button>
              &nbsp;&nbsp;&nbsp;
            </>
            <span className="t-text-body-lg t-font-semibold t-text-text-100">
              {modalTitle}
            </span>
          </span>
          <CreateTemplate
            closeAddStep={closeCreateTemplateModal}
            templateData={templateData}
          />
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
};

export default CreateStepModal;
