export const MyEntities = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.300049 13.5C0.300049 13.1134 0.613449 12.8 1.00005 12.8H15C15.3866 12.8 15.7 13.1134 15.7 13.5C15.7 13.8866 15.3866 14.2 15 14.2H1.00005C0.613449 14.2 0.300049 13.8866 0.300049 13.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.65152 1.65146C1.87656 1.42642 2.18179 1.29999 2.50005 1.29999H8.50005C8.81831 1.29999 9.12353 1.42642 9.34858 1.65146C9.57362 1.8765 9.70005 2.18173 9.70005 2.49999V13.5C9.70005 13.8866 9.38665 14.2 9.00005 14.2C8.61345 14.2 8.30005 13.8866 8.30005 13.5V2.69999H2.70005V13.5C2.70005 13.8866 2.38665 14.2 2.00005 14.2C1.61345 14.2 1.30005 13.8866 1.30005 13.5V2.49999C1.30005 2.18173 1.42648 1.8765 1.65152 1.65146Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.30005 5.99999C8.30005 5.61339 8.61345 5.29999 9.00005 5.29999H13.5C13.8183 5.29999 14.1235 5.42642 14.3486 5.65146C14.5736 5.8765 14.7 6.18173 14.7 6.49999V13.5C14.7 13.8866 14.3866 14.2 14 14.2C13.6134 14.2 13.3 13.8866 13.3 13.5V6.69999H9.00005C8.61345 6.69999 8.30005 6.38659 8.30005 5.99999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.30005 4.49999C3.30005 4.11339 3.61345 3.79999 4.00005 3.79999H6.00005C6.38665 3.79999 6.70005 4.11339 6.70005 4.49999C6.70005 4.88659 6.38665 5.19999 6.00005 5.19999H4.00005C3.61345 5.19999 3.30005 4.88659 3.30005 4.49999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.30005 8.49999C4.30005 8.11339 4.61345 7.79999 5.00005 7.79999H7.00005C7.38665 7.79999 7.70005 8.11339 7.70005 8.49999C7.70005 8.88659 7.38665 9.19999 7.00005 9.19999H5.00005C4.61345 9.19999 4.30005 8.88659 4.30005 8.49999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.30005 11C3.30005 10.6134 3.61345 10.3 4.00005 10.3H6.00005C6.38665 10.3 6.70005 10.6134 6.70005 11C6.70005 11.3866 6.38665 11.7 6.00005 11.7H4.00005C3.61345 11.7 3.30005 11.3866 3.30005 11Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3 11C10.3 10.6134 10.6134 10.3 11 10.3H12C12.3866 10.3 12.7 10.6134 12.7 11C12.7 11.3866 12.3866 11.7 12 11.7H11C10.6134 11.7 10.3 11.3866 10.3 11Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3 8.49999C10.3 8.11339 10.6134 7.79999 11 7.79999H12C12.3866 7.79999 12.7 8.11339 12.7 8.49999C12.7 8.88659 12.3866 9.19999 12 9.19999H11C10.6134 9.19999 10.3 8.88659 10.3 8.49999Z"
      fill="currentColor"
    />
  </svg>
);
