export const PencilWithPaper = ({ color = "#706A85" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6 13.5039H3C2.86739 13.5039 2.74022 13.4512 2.64645 13.3574C2.55268 13.2636 2.5 13.1365 2.5 13.0039V10.2101C2.49978 10.1452 2.51236 10.0808 2.53702 10.0208C2.56169 9.96073 2.59796 9.90612 2.64375 9.8601L10.1438 2.3601C10.1903 2.31286 10.2457 2.27534 10.3069 2.24973C10.3681 2.22413 10.4337 2.21094 10.5 2.21094C10.5663 2.21094 10.632 2.22413 10.6931 2.24973C10.7543 2.27534 10.8097 2.31286 10.8563 2.3601L13.6438 5.1476C13.691 5.19413 13.7285 5.24958 13.7541 5.31074C13.7797 5.3719 13.7929 5.43755 13.7929 5.50385C13.7929 5.57016 13.7797 5.6358 13.7541 5.69696C13.7285 5.75812 13.691 5.81358 13.6438 5.8601L6 13.5039Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 13.5H6"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4L12 7.5"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
