import classNames from "classnames";
import HoverCard from "components/DesignSystem/HoverCard/HoverCard";
import { ConditionalLink } from "components/conditionalLink";
import { ArrowRight } from "components/icons/ArrowRight";
import { InkleBooksSymbolSmall } from "components/icons/Logos/InkleBooksSymbolSmall";
import { InkleCommunitySymbolSmall } from "components/icons/Logos/InkleCommunitySymbolSmall";
import { InkleMailroomSymbolSmall } from "components/icons/Logos/InkleMailroomSymbolSmall";
import { InkleSalesTaxSymbolSmall } from "components/icons/Logos/InkleSalesTaxSymbolSmall";
import {
  ComponentProps,
  ComponentType,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useId,
  useRef,
  useState,
} from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { InkleTaxSymbolSmall } from "components/icons/Logos/InkleTaxSymbolSmall";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { ProfileDropdown } from "components/ProfileDropdown/ProfileDropdown";
import { InkleIntragroupSymbolSmall } from "components/icons/Logos/InkleIntragroupSymbolSmall";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { InklePractice } from "components/icons/Logos/InklePractice";
import { InklePracticePro } from "components/icons/Logos/InklePracticePro";
import { InklePracticeSymbol } from "components/icons/Logos/InklePracticeSymbol";
import { InklePracticeProSymbol } from "components/icons/Logos/InklePracticeProSymbol";
import { InkleAdminTax } from "components/icons/Logos/InkleAdminTax";
import { InkleTaxSymbol } from "components/icons/Logos/InkleTaxSymbol";
import { InkleTaxCreditSymbolSmall } from "components/icons/Logos/InkleTaxCreditSymbolSmall";
import { Timeout } from "react-number-format/types/types";

const LeftBarOpenContext = createContext<{
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}>({ open: true });

export const LeftBarLogo = ({
  logo: LogoFromProps,
  symbol: SymbolFromProps,
  url: urlFromprops,
}: {
  logo: ComponentType;
  symbol: ComponentType;
  url?: string;
}) => {
  const { open, setOpen } = useContext(LeftBarOpenContext);
  const { url } = useRouteMatch();

  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();

  let Logo = LogoFromProps;
  let Symbol = SymbolFromProps;

  if (isForeignCA && !isAdmin && !isCpa) {
    Logo = InklePractice;
    Symbol = InklePracticeSymbol;
  }

  if (isCpa && !isAdmin && !isForeignCA) {
    Logo = InklePracticePro;
    Symbol = InklePracticeProSymbol;
  }

  if (isAdmin) {
    Logo = InkleAdminTax;
    Symbol = InkleTaxSymbol;
  }

  return (
    <div className="t-z-10">
      <ConditionalLink
        to={urlFromprops || url}
        className={classNames(
          "t-block t-bg-surface-lighter-grey t-w-full t-overflow-visible",
          {
            "t-flex t-justify-center": !open,
          }
        )}
      >
        {open ? <Logo /> : <Symbol />}
      </ConditionalLink>
      <div className="t-relative t-hidden md:t-block">
        <button
          className={classNames(
            "t-absolute t-right-0 t-bottom-0 t-h-8 t-w-8 t-flex t-justify-center t-items-center all:unset t-text-text-60 t-rounded-full t-border-solid t-border t-border-neutral-0 t-bg-surface t-translate-x-1/2 t-translate-y-1/2 t-z-sidebar t-transition-all",
            {
              "t-rotate-180": open,
            }
          )}
          onClick={() => setOpen && setOpen((o) => !o)}
        >
          <ArrowRight color="currentColor" />
        </button>
      </div>
    </div>
  );
};

export const LeftBarProfileDropdownItem = ({
  icon: Icon,
  children,
  suffix,
  to,
  useParentPath = true,
  type = "regular",
  onClick,
  ...props
}: {
  icon: ComponentType;
  children: ReactNode;
  suffix?: ReactNode;
  to?: string;
  useParentPath?: boolean;
  type?: "danger" | "regular";
  onClick?: () => void;
} & ComponentProps<typeof ConditionalLink>) => {
  const { path } = useRouteMatch();

  if (to) {
    return (
      <ConditionalLink
        {...props}
        to={useParentPath ? `${path}${to}` : to}
        className={classNames(
          "t-relative t-py-2 t-px-4 t-flex t-gap-2 t-items-center t-border-solid t-border-0 t-transition-all t-no-underline hover:t-bg-neutral-0 t-rounded",
          {
            "t-group/regular": type === "regular",
            "t-group/danger hover:t-bg-red-10": type === "danger",
          }
        )}
      >
        <span className="t-flex group-[&]/regular:t-text-text-30 group-[&]/danger:t-text-red">
          <Icon />
        </span>
        <span className="t-text-body  group-[&]/danger:t-text-red group-[&]/regular:t-text-text-60">
          {children}
        </span>
        {suffix}
      </ConditionalLink>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        "t-relative t-px-4 py-2 t-flex t-gap-2 t-items-center t-border-solid t-border-0 t-transition-all t-no-underline hover:t-bg-neutral-0 t-rounded all:unset",
        {
          "t-group/regular": type === "regular",
          "t-group/danger hover:t-bg-red-10": type === "danger",
        }
      )}
    >
      <span className="t-flex group-[&]/regular:t-text-text-100 group-[&]/danger:t-text-red t-text-text-30">
        <Icon />
      </span>
      <span className="t-text-body group-[&]/active-link:t-text-text-100 group-[&]/danger:t-text-red group-[&]/active-link:t-font-medium t-text-text-60">
        {children}
      </span>
      {suffix}
    </button>
  );
};

export const LeftBarProfileDropdown = ({
  name,
  org,
  email,
  imageUrl,
  children,
}: {
  name: string;
  org?: string | null;
  imageUrl: string;
  // userType: "PRO" | "STANDARD" | "CONSULTANT" | "ADMIN" | "FCPA" | "CPA";
  email: string;
  children: ReactNode;
}) => {
  const { open } = useContext(LeftBarOpenContext);
  const [pop, setPop] = useState(false);
  const closeRef = useRef<Timeout>();

  const onClose = () => {
    if (closeRef.current) {
      clearTimeout(closeRef.current);
    }

    closeRef.current = setTimeout(() => {
      setPop(false);
    }, 200);
  };

  const onOpen = () => {
    closeRef.current && clearTimeout(closeRef.current);
    setPop(true);
  };

  return (
    <HoverCard.Root open={pop} openDelay={300}>
      <HoverCard.Trigger
        asChild
        onClick={(e) => e.preventDefault()}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <button className="t-flex t-gap-3 all:unset t-p-4 t-w-full t-border-t t-border-0 t-border-neutral-10 t-border-solid t-bg-surface-lighter-grey">
          <img
            src={imageUrl}
            alt="profile"
            className="t-w-8 t-h-8 t-rounded-full"
          />
          {open && (
            <>
              <div className="t-flex t-flex-col t-justify-center">
                <span className="t-text-text-100 t-font-medium t-truncate t-max-w-36 t-block t-text-subtitle-sm">
                  {name}
                </span>
                {org && (
                  <span className="t-text-body-sm t-text-text-30 t-truncate t-max-w-36 t-block">
                    {org}
                  </span>
                )}
              </div>
              <span className="t-ml-auto">
                <ArrowRight />
              </span>
            </>
          )}
        </button>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          className="t-min-w-[256px]"
          align="end"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          side="right"
          sideOffset={10}
          alignOffset={16}
        >
          <div className="t-w-full t-p-2 t-font-medium">
            <div className="t-flex t-gap-3 all:unset t-w-full t-py-2 t-px-3">
              <img
                src={imageUrl}
                alt="profile"
                className="t-w-8 t-h-8 t-rounded-full"
              />
              <div className="t-flex t-flex-col">
                <span className="t-text-text-100 t-font-medium t-truncate t-max-w-36 t-block t-text-subtitle-sm">
                  {name}
                </span>
                <span className="t-text-body-sm t-text-text-30 t-truncate t-block">
                  {email}
                </span>
              </div>
              {/* Make props standup that they are pro */}
              {/* <span className="t-ml-auto">
                <Tag tagType="green" icon={false}>
                  {userType}
                </Tag>
              </span> */}
            </div>

            <div className="t-py-2">
              <LeftBarSeparator />
            </div>

            <div className="t-flex t-gap-2 t-flex-col">{children}</div>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export const LeftBarApps = ({
  current,
  useExistingSearch,
  search,
}: {
  current: string;
  useExistingSearch?: boolean;
  search?: string;
}) => {
  return (
    <div className="t-shrink-0">
      {!current.includes("/tax") && (
        <LeftBarItem
          useParentPath={false}
          to={useExistingSearch ? `/tax${search}` : "/tax"}
          icon={InkleTaxSymbolSmall}
        >
          Tax
        </LeftBarItem>
      )}

      {!current.includes("/books") && (
        <LeftBarItem
          useParentPath={false}
          to={useExistingSearch ? `/books${search}` : "/books"}
          icon={InkleBooksSymbolSmall}
        >
          Books
        </LeftBarItem>
      )}

      {!current.includes("mailroom") && (
        <LeftBarItem
          useParentPath={false}
          to={useExistingSearch ? `/mailroom${search}` : "/mailroom"}
          icon={InkleMailroomSymbolSmall}
        >
          Mailroom
        </LeftBarItem>
      )}

      {!current.includes("/intragroup") && (
        <LeftBarItem
          useParentPath={false}
          to={useExistingSearch ? `/intragroup${search}` : "/intragroup"}
          icon={InkleIntragroupSymbolSmall}
        >
          Intragroup
        </LeftBarItem>
      )}

      {!current.includes("/sales-tax") && (
        <LeftBarItem
          useParentPath={false}
          to={useExistingSearch ? `/sales-tax${search}` : "/sales-tax"}
          icon={InkleSalesTaxSymbolSmall}
        >
          Sales Tax
        </LeftBarItem>
      )}

      {!current.includes("/credits") && (
        <LeftBarItem
          useParentPath={false}
          to={useExistingSearch ? `/credits${search}` : "/credits"}
          icon={InkleTaxCreditSymbolSmall}
        >
          R&D
        </LeftBarItem>
      )}

      {!current.includes("/community") && (
        <LeftBarItem
          useParentPath={false}
          to={useExistingSearch ? `/community${search}` : "/community"}
          icon={InkleCommunitySymbolSmall}
        >
          Community
        </LeftBarItem>
      )}
    </div>
  );
};

export const LeftBarSeparator = ({ name }: { name?: string }) => {
  const { open } = useContext(LeftBarOpenContext);

  return (
    <div className="t-flex t-items-center">
      {name && open && (
        <span className="t-text-body-xs t-whitespace-nowrap t-px-4 t-text-text-30">
          {name}
        </span>
      )}
      <span className="t-h-px t-bg-neutral-10 t-w-full" />
    </div>
  );
};

const Anchor = (props: ComponentProps<typeof NavLink>) => (
  <a
    {...props}
    className={
      typeof props.className === "function"
        ? props.className?.(false)
        : props.className
    }
    href={props.to.toString()}
    style={
      typeof props.style === "function" ? props.style?.(false) : props.style
    }
  />
);

export const LeftBarItem = ({
  icon: Icon,
  children,
  suffix,
  to,
  useParentPath = true,
  ...props
}: {
  icon: ComponentType;
  children: ReactNode;
  suffix?: ReactNode;
  to: string;
  useParentPath?: boolean;
} & ComponentProps<typeof NavLink>) => {
  const { path } = useRouteMatch();
  const { open } = useContext(LeftBarOpenContext);
  const id = useId();

  const isExternal =
    to?.startsWith("https://") ||
    to?.startsWith("http://") ||
    to?.startsWith("mailto") ||
    to?.startsWith("tel");

  const LinkComponent = isExternal ? Anchor : NavLink;

  return (
    <LinkComponent
      {...props}
      to={useParentPath ? `${path}${to}` : to}
      target={to.includes("http") ? "_blank" : undefined}
      // @ts-ignore
      className={(isActive) =>
        classNames(
          "t-relative t-py-2 t-px-4 t-flex t-gap-2 t-items-center t-border-solid t-border-0 before:t-content-[''] before:t-w-[3px] before:t-h-full before:t-absolute before:t-left-0 t-transition-all t-no-underline",
          {
            "t-bg-surface-lighter-grey hover:t-bg-neutral-0 hover:before:t-bg-purple-30 t-group/non-active-link":
              !isActive,
            "t-bg-neutral-0 hover:before:t-border-l-[3px] before:t-bg-purple-50 t-group/active-link":
              isActive,
            "t-justify-center": !open,
            // "py-2": open,
          }
        )
      }
    >
      <LayoutGroup id={id}>
        <AnimatePresence>
          {!open && (
            <motion.span
              animate={{ opacity: 1, position: "static" }}
              exit={{ opacity: 0, position: "absolute" }}
              initial={{ opacity: 0, position: "absolute" }}
              transition={{ delay: 0, duration: 0.1 }}
              className="t-flex group-[&]/active-link:t-text-text-100 t-text-text-30"
            >
              <Icon />
            </motion.span>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {open && (
            <motion.div
              animate={{ opacity: 1, position: "static" }}
              exit={{ opacity: 0, position: "absolute" }}
              initial={{ opacity: 0, position: "absolute" }}
              transition={{ delay: 0, duration: 0.1 }}
              className="t-flex t-gap-2 t-items-center"
            >
              <span className="t-flex group-[&]/active-link:t-text-text-100 t-text-text-30">
                <Icon />
              </span>
              <span className="t-text-body group-[&]/active-link:t-text-text-100 group-[&]/active-link:t-font-medium t-text-text-60 t-whitespace-nowrap t-overflow-hidden group-hover/non-active-link:t-text-text-100">
                {children}
              </span>

              {suffix}
            </motion.div>
          )}
        </AnimatePresence>
      </LayoutGroup>
    </LinkComponent>
  );
};

export const LeftBarTag = ({
  type,
  children,
}: {
  type: "red" | "blue";
  children: ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "t-p-1 t-text-body-sm t-rounded-full t-leading-[10px] t-font-medium",
        {
          "t-bg-red-20 t-text-red-80": type === "red",
          "t-bg-blue-20 t-text-blue-80": type === "blue",
        }
      )}
    >
      {children}
    </div>
  );
};

export const LeftBar = ({
  children,
  logo,
  profile,
  useInternalLink,
}: {
  children: ReactNode;
  logo?: ReactNode;
  profile?: ReactNode;
  useInternalLink?: boolean;
}) => {
  const [open, setOpen] = useState(true);
  return (
    <LeftBarOpenContext.Provider value={{ open, setOpen }}>
      <div
        className={classNames(
          "t-bg-surface-lighter-grey t-h-full t-flex t-flex-col t-transition-all t-duration-30",
          {
            "md:t-w-[240px]": open,
            "md:t-w-[64px]": !open,
          }
        )}
      >
        <DashboardContainer className="t-h-full">
          {logo && (
            <DashboardContainer.Header className="t-border-solid t-border-b t-border-0 t-border-neutral-0 t-mb-6 ">
              {logo}
            </DashboardContainer.Header>
          )}
          <DashboardContainer.Content className="t-flex t-flex-col">
            {children}
            <div className="t-sticky t-bottom-0">
              {profile || <ProfileDropdown useInternalLink={useInternalLink} />}
            </div>
          </DashboardContainer.Content>
        </DashboardContainer>
      </div>
    </LeftBarOpenContext.Provider>
  );
};
