import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import {
  Combobox,
  ComboboxProps,
} from "components/DesignSystem/Combobox/Combobox";
import React from "react";
import { useDispatch } from "react-redux";
import { openEditSplitTransactionModal } from "store/slices/splitTransaction";
import { closeSlider } from "store/slices/transactions";
import { Transactions } from "types/Models/books";

export const SplitMerchantOnPopover = ({
  onCreateMerchant,
  props,
  transactions,
}: {
  onCreateMerchant?: (name: string) => Promise<void>;
  props: ComboboxProps;
  transactions?: Transactions;
}) => {
  const dispatch = useDispatch();

  const openSplitTransaction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(closeSlider(transactions?.transaction.uuid!));
    transactions && dispatch(openEditSplitTransactionModal(transactions));
  };

  return (
    <ToolTip
      propClass="!t-z-modal"
      text={
        <div
          className="t-min-w-64 t-text-left"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="t-text-subtitle-sm">Vendor</div>
          <div className="t-my-2 t-flex t-flex-col t-gap-0.5">
            {transactions?.split_transactions?.map((transaction) => (
              <div
                className="t-flex t-justify-between t-items-center"
                key={transaction.uuid}
              >
                <div>{transaction.merchant}</div>
                <div>
                  <AmountSuperScript amount={transaction.amount} />
                </div>
              </div>
            ))}
          </div>
          <button
            className="all:unset t-underline t-subtext-sm"
            onClick={openSplitTransaction}
          >
            Edit split transaction
          </button>
        </div>
      }
    >
      <div>
        <Combobox
          aria-label="Merchant selector"
          creatable
          onCreateOption={(name) => onCreateMerchant?.(name)}
          {...props}
        />
      </div>
    </ToolTip>
  );
};
