import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { DateFilter } from "components/Filters/DateFilter";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useState } from "react";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import { useLazyGetAllGroupsQuery } from "store/apis/crm";
import { CompanyGroup } from "types/Models/groups";
import { debounce } from "utils/debouncing";

export const CallHistoryFilter = ({
  updateFilter,
  values,
}: {
  updateFilter: <
    S extends
      | "GROUPS"
      | "AGENT"
      | "CALL_ACTIVITY"
      | "START_DATE"
      | "END_DATE"
      | "SELECT_PERIOD"
  >(
    name: S,
    newValue: {
      GROUPS: string[];
      AGENT: string[];
      CALL_ACTIVITY: string[];
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
    }[S]
  ) => void;
  values: {
    GROUPS: string[];
    AGENT: string[];
    CALL_ACTIVITY: string[];
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
  };
}) => {
  const updateFilterCallback =
    (name: Parameters<typeof updateFilter>[0]) =>
    (newValues: Parameters<typeof updateFilter>[1]) => {
      updateFilter(name, newValues);
    };
  const [groupSearchValue, setGroupSearchValue] = useState("");
  const { data: agents } = useGetAssignAgentsQuery();

  const { data: groups, loadNext } = usePaginatedQuery<{
    company_groups?: CompanyGroup[];
  }>(useLazyGetAllGroupsQuery, "company_groups", {
    search_term: groupSearchValue,
  });

  const onGroupSearch = debounce((value) => setGroupSearchValue(value));

  return (
    <Filter.Root
      defaultValue="GROUPS"
      title="Filters"
      capsule={
        <>
          {values?.GROUPS.length > 0 && (
            <Chip
              onClose={() => updateFilter("GROUPS", [])}
              isActive
              filterType="GROUPS"
            >
              Brand Name ({values.GROUPS.length})
            </Chip>
          )}

          {values?.AGENT.length > 0 && (
            <Chip
              onClose={() => updateFilter("AGENT", [])}
              isActive
              filterType="AGENT"
            >
              Agent ({values.AGENT.length})
            </Chip>
          )}

          {values?.CALL_ACTIVITY.length > 0 && (
            <Chip
              onClose={() => updateFilter("CALL_ACTIVITY", [])}
              isActive
              filterType="CALL_ACTIVITY"
            >
              Call Activity ({values.CALL_ACTIVITY.length})
            </Chip>
          )}
          {Boolean(values?.START_DATE) && (
            <Chip
              onClose={() => {
                updateFilter("START_DATE", "");
                updateFilter("SELECT_PERIOD", "");
              }}
              isActive
              filterType="CALL_DATE"
            >
              From {dayjs(values.START_DATE).format(DD_MMM_YYYY)}
            </Chip>
          )}
          {Boolean(values?.END_DATE) && (
            <Chip
              onClose={() => {
                updateFilter("END_DATE", "");
                updateFilter("SELECT_PERIOD", "");
              }}
              isActive
              filterType="CALL_DATE"
            >
              To {dayjs(values.END_DATE).format(DD_MMM_YYYY)}
            </Chip>
          )}
        </>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="GROUPS">Company group</Filter.ListItem>
          <Filter.ListItem value="CALL_ACTIVITY">Call activity</Filter.ListItem>
          <Filter.ListItem value="AGENT">Agents</Filter.ListItem>
          <Filter.ListItem value="CALL_DATE">Date</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="GROUPS" block>
          {groups?.company_groups && (
            <MultiSelectFilter
              onSearchValueChange={onGroupSearch}
              hasMore={true}
              totalCount={groups.company_groups.length}
              loadNext={loadNext}
              onChange={updateFilterCallback("GROUPS")}
              options={groups.company_groups.map((group) => ({
                value: group.uuid,
                label: group.name,
              }))}
              selected={values.GROUPS}
            />
          )}
        </Filter.Body>
        <Filter.Body value="CALL_ACTIVITY" block>
          <MultiSelectFilter
            onChange={updateFilterCallback("CALL_ACTIVITY")}
            options={[
              { name: "Missed call", uuid: "MISSED" },
              { name: "Incoming", uuid: "INCOMING" },
              { name: "Outgoing", uuid: "OUTGOING" },
            ].map(({ name, uuid }) => ({ label: name, value: uuid }))}
            selected={values?.CALL_ACTIVITY}
          />
        </Filter.Body>
        {agents && (
          <Filter.Body value="AGENT" block>
            <MultiSelectFilter
              onChange={updateFilterCallback("AGENT")}
              options={agents?.map((agent) => ({
                value: agent.profile_id,
                label: agent.name,
              }))}
              selected={values?.AGENT}
            />
          </Filter.Body>
        )}
        <Filter.Body value="CALL_DATE" block>
          <DateFilter values={values} updateFilter={updateFilter} />
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
