export const Perks = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.7 5.69999V7.29999H13.3V5.69999H2.7ZM1.3 5.49999C1.3 4.83725 1.83726 4.29999 2.5 4.29999H13.5C14.1627 4.29999 14.7 4.83725 14.7 5.49999V7.49999C14.7 8.16273 14.1627 8.69999 13.5 8.69999H2.5C1.83726 8.69999 1.3 8.16273 1.3 7.49999V5.49999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 7.29999C3.3866 7.29999 3.7 7.61339 3.7 7.99999V12.3H12.3V7.99999C12.3 7.61339 12.6134 7.29999 13 7.29999C13.3866 7.29999 13.7 7.61339 13.7 7.99999V12.5C13.7 12.8182 13.5736 13.1235 13.3485 13.3485C13.1235 13.5736 12.8183 13.7 12.5 13.7H3.5C3.18174 13.7 2.87652 13.5736 2.65148 13.3485C2.42643 13.1235 2.3 12.8182 2.3 12.5V7.99999C2.3 7.61339 2.6134 7.29999 3 7.29999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 4.29999C8.3866 4.29999 8.7 4.61339 8.7 4.99999V13C8.7 13.3866 8.3866 13.7 8 13.7C7.6134 13.7 7.3 13.3866 7.3 13V4.99999C7.3 4.61339 7.6134 4.29999 8 4.29999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5544 2.44559C10.2146 2.10583 9.70344 2.14067 9.4623 2.41385L9.46117 2.41513C9.12089 2.79873 8.91557 3.40705 8.80539 3.99959C8.7904 4.08023 8.77752 4.15858 8.76646 4.23366C8.84157 4.22262 8.91996 4.20976 9.00064 4.19478C9.59327 4.08481 10.2016 3.87988 10.5852 3.54036M8 4.99999C7.3 4.99999 7.3 4.99982 7.3 4.99964L7.3 4.99833L7.30001 4.99594L7.30005 4.98897L7.30031 4.96657C7.3006 4.94795 7.30115 4.92207 7.3022 4.88965C7.3043 4.82487 7.30841 4.73364 7.31646 4.62184C7.33251 4.3991 7.36454 4.09025 7.42899 3.74366C7.55314 3.07594 7.81645 2.15993 8.41329 1.48672C9.26347 0.524352 10.7168 0.628085 11.5444 1.45564C12.3727 2.28394 12.4748 3.73689 11.5135 4.58837C10.8402 5.1844 9.92385 5.44737 9.25608 5.57128C8.90955 5.63559 8.60075 5.66755 8.37805 5.68357C8.26627 5.6916 8.17506 5.6957 8.11029 5.69779C8.07788 5.69884 8.05201 5.69939 8.0334 5.69968L8.01101 5.69994L8.00405 5.69998L8.00166 5.69999L8.00035 5.69999C8.00017 5.69999 8 5.69999 8 4.99999ZM8 4.99999L8.00035 5.69999C7.8147 5.69999 7.6363 5.62624 7.50503 5.49496C7.37375 5.36369 7.3 5.18529 7.3 4.99964L8 4.99999ZM10.5544 2.44559C10.8935 2.78473 10.8601 3.29679 10.5852 3.54036L10.5544 2.44559Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.45565 1.45564C5.28321 0.628081 6.73654 0.524351 7.58673 1.48673C8.18355 2.15995 8.44686 3.07594 8.57102 3.74366C8.63546 4.09025 8.66749 4.3991 8.68354 4.62184C8.6916 4.73364 8.6957 4.82487 8.6978 4.88965C8.69885 4.92207 8.6994 4.94795 8.69969 4.96657L8.69995 4.98897L8.69999 4.99594L8.7 4.99833L8.7 4.99925V4.99964C8.7 4.99982 8.7 4.99999 8 4.99999C8 5.69999 7.99983 5.69999 7.99965 5.69999H7.99926L7.99834 5.69999L7.99595 5.69998L7.98899 5.69994L7.9666 5.69968C7.94799 5.69939 7.92212 5.69884 7.88971 5.69779C7.82494 5.6957 7.73373 5.6916 7.62195 5.68357C7.39925 5.66755 7.09045 5.63559 6.74392 5.57128C6.07615 5.44737 5.1599 5.18452 4.48663 4.58849L4.48649 4.58837C3.52518 3.73689 3.62735 2.28394 4.45565 1.45564ZM8 4.99999V5.69999C8.18565 5.69999 8.3637 5.62624 8.49497 5.49496C8.62625 5.36369 8.7 5.18564 8.7 4.99999H8ZM7.23354 4.23366C7.22248 4.15858 7.2096 4.08023 7.19461 3.99959C7.08443 3.40705 6.87911 2.79873 6.53883 2.41513L6.5377 2.41385L6.53771 2.41385C6.29656 2.14067 5.78536 2.10583 5.4456 2.44559C5.10646 2.78473 5.13981 3.29667 5.41462 3.54024M7.23354 4.23366C7.15843 4.22262 7.08004 4.20976 6.99936 4.19478C6.40673 4.08481 5.79837 3.87988 5.41476 3.54036"
      fill="currentColor"
    />
  </svg>
);
