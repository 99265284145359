import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import { useArchiveTeamMemberMutation } from "store/apis/teamSetting";

const ArchiveMemberModal = ({
  show,
  closeModal,
  profileId,
  isArchived,
  updateArchiveToast,
}: {
  show: boolean;
  closeModal: () => void;
  profileId: string;
  isArchived: boolean;
  updateArchiveToast: (v: string) => void;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [archiveTeamMember, { data, isSuccess, isLoading }] =
    useArchiveTeamMemberMutation();

  useEffect(() => {
    if (isSuccess) {
      const toastMessage = data?.is_archived_user
        ? "Member has been archived"
        : "Member has been unarchived";
      updateArchiveToast(toastMessage);
    }
  }, [isSuccess]);

  const archiveMember = async () => {
    try {
      await archiveTeamMember({
        profile_id: profileId,
        group_id: groupId,
        archiveAction: isArchived ? "unarchive" : "archive",
      }).unwrap();
      successToast({
        message: isArchived
          ? "Member has been unarchived"
          : "Member has been archived",
        title: "Group user",
      });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <>
      <Modal.Root open={show} onOpenChange={closeModal}>
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="!t-w-[440px]">
            <Modal.Header>
              <Modal.Title>
                {isArchived ? "Unarchive" : "Archive"} user
              </Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <div className="t-text-body t-text-text-100">
                {isArchived
                  ? "Are you sure you want to unarchive this user?"
                  : "Are you sure you want to archive this user? If you proceed, the user won't be able to access the platform anymore"}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="t-flex t-justify-end t-gap-3">
                <Button onClick={closeModal}>Cancel</Button>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  customType={isArchived ? "primary" : "danger"}
                  onClick={archiveMember}
                >
                  Confirm
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
    </>
  );
};

export default ArchiveMemberModal;
