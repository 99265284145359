import React, { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";

//components
import { Button } from "components/DesignSystem/Button/Button";
import { InvoiceTopayModal } from "./invoiceTopayModal";
import { useDispatch } from "react-redux";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { ConditionalLink } from "components/conditionalLink";
import { useParams } from "react-router-dom";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { useGetEntitiesQuery, useGetGroupDataQuery } from "store/apis/group";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useGetBillingInvoicesQuery } from "store/apis/billingInvoice";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";

export const PayUnpaid = ({
  unPaidInvoice,
  PayUnpaid,
  invoicesToPay,
  paySelectedBill,
  setShowInvoiceData,
  showInvoiceData,
  dueAmount,
  invoicesCount,
  subscriptionPage = false,
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { uuid: groupId } = useCurrentGroupContext();
  const openPayall = query.get("payall");
  const { groupId: groupIdFromParams } = useParams();
  const { isCustomer } = useRoleBasedView();
  const { data: { groups: [group] = [null] } = {} } = useGetEntitiesQuery(
    undefined,
    {
      skip: !isCustomer,
    }
  );
  const { update } = useUpdateQuery();

  let updatedBills = invoicesToPay.reduce((prev, cur) => {
    let newAmount = cur.amount.replace("$", "");
    return prev + +newAmount;
  }, 0);

  const entityId = useCurrentEntityId();

  const { data: { due_amount = 0, total_count } = {}, isLoading } =
    useGetBillingInvoicesQuery(
      {
        groupId: groupId || groupIdFromParams,
        pageNumber: 1,
        paymentStatuses: "PENDING,IN_PROGRESS,WORK_IN_PROGRESS",
        entityId,
      },
      {
        skip: !(groupId || groupIdFromParams) || !entityId,
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (openPayall) {
      openInvoiceModal();
    }
  }, [openPayall]);

  const checkDisable = () => true;

  const openInvoiceModal = () => {
    setShowInvoiceData(true);
    dispatch(setUsableCredit(true));
    dispatch(setPaymentTitle("Billings"));
  };
  const closeInvoiceModal = () => {
    setShowInvoiceData(false);
    update({ query: "payall", value: null });
  };

  const payUnpaid = () => {
    update({ query: "payall", value: null });
    PayUnpaid(due_amount);
  };

  const billingPath = isCustomer
    ? "/billing/invoices"
    : `/crm/${groupId}/billing/invoices`;

  return (
    <>
      {invoicesToPay.length === 0 ? (
        total_count > 0 && (
          <div className="t-mb-4">
            <Card className="!t-rounded">
              <Card.Body className="t-p-2">
                <div className="t-flex t-w-full t-items-center t-justify-between t-gap-2 sm:t-flex-col sm:t-items-start">
                  <div className="t-flex  t-items-center">
                    <span className="t-text-text-100 t-text-subtitle-sm">
                      Total Due:
                    </span>
                    <span className="t-mx-1 t-text-subtitle-sm t-text-red t-mr-2">
                      <AmountSuperScript amount={due_amount} />
                    </span>
                    <span className="t-text-text-30 t-text-subtext-sm">
                      ({total_count} invoices)
                    </span>
                  </div>
                  <div className="t-flex t-gap-4">
                    {subscriptionPage && (
                      <ConditionalLink to={billingPath}>
                        <Button>Show all invoices</Button>{" "}
                      </ConditionalLink>
                    )}
                    <Button
                      customType="danger"
                      size="small"
                      checkDisable={checkDisable}
                      onClick={openInvoiceModal}
                      disabled={due_amount === 0}
                    >
                      Pay All Dues
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )
      ) : (
        <Card className="paySelectedInvoices">
          <Card.Body className="!t-p-2">
            <span className="t-text-body">
              {invoicesToPay.length} Invoices selected
            </span>
            <Button
              size="small"
              customType="primary"
              checkDisable={checkDisable}
              onClick={paySelectedBill}
              disabled={updatedBills === 0}
            >
              <div className="t-flex t-gap-1">
                <span>Pay</span>
                <AmountSuperScript amount={Number(updatedBills)} />
              </div>
            </Button>
          </Card.Body>
        </Card>
      )}
      {showInvoiceData && (
        <InvoiceTopayModal
          show={showInvoiceData}
          closeModal={closeInvoiceModal}
          unPaidInvoice={unPaidInvoice}
          PayUnpaid={payUnpaid}
          totalAmount={due_amount}
        />
      )}
    </>
  );
};
