export const EmptySearchMessage = () => {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.0001 21H18.0001C16.674 21 15.4022 21.5268 14.4646 22.4645C13.5269 23.4021 13.0001 24.6739 13.0001 26V76C12.9943 76.9535 13.264 77.8883 13.7768 78.6921C14.2896 79.496 15.0237 80.1346 15.8907 80.5312C16.5514 80.8389 17.2713 80.9989 18.0001 81C19.1739 80.9972 20.3087 80.5787 21.2032 79.8188C21.2181 79.809 21.2318 79.7975 21.2438 79.7844L31.2813 71H73.0001C74.3262 71 75.5979 70.4732 76.5356 69.5355C77.4733 68.5979 78.0001 67.3261 78.0001 66V26C78.0001 24.6739 77.4733 23.4021 76.5356 22.4645C75.5979 21.5268 74.3262 21 73.0001 21ZM55.5001 53.5H35.5001C34.837 53.5 34.2012 53.2366 33.7323 52.7678C33.2635 52.2989 33.0001 51.663 33.0001 51C33.0001 50.337 33.2635 49.7011 33.7323 49.2322C34.2012 48.7634 34.837 48.5 35.5001 48.5H55.5001C56.1631 48.5 56.799 48.7634 57.2679 49.2322C57.7367 49.7011 58.0001 50.337 58.0001 51C58.0001 51.663 57.7367 52.2989 57.2679 52.7678C56.799 53.2366 56.1631 53.5 55.5001 53.5ZM55.5001 43.5H35.5001C34.837 43.5 34.2012 43.2366 33.7323 42.7678C33.2635 42.2989 33.0001 41.663 33.0001 41C33.0001 40.337 33.2635 39.7011 33.7323 39.2322C34.2012 38.7634 34.837 38.5 35.5001 38.5H55.5001C56.1631 38.5 56.799 38.7634 57.2679 39.2322C57.7367 39.7011 58.0001 40.337 58.0001 41C58.0001 41.663 57.7367 42.2989 57.2679 42.7678C56.799 43.2366 56.1631 43.5 55.5001 43.5Z"
        fill="#F3F3F5"
      />
      <path
        d="M74.6667 84.3334C83.8714 84.3334 91.3334 76.8714 91.3334 67.6667C91.3334 58.4619 83.8714 51 74.6667 51C65.4619 51 58 58.4619 58 67.6667C58 76.8714 65.4619 84.3334 74.6667 84.3334Z"
        fill="#F3F3F5"
        fill-opacity="0.7"
        stroke="#E7E6EB"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.452 79.452L98 91"
        stroke="#E7E6EB"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="67.5"
        y="65.5"
        width="1"
        height="4"
        rx="0.5"
        fill="#CFCDD6"
        stroke="#CFCDD6"
      />
      <rect
        x="80.5"
        y="65.5"
        width="1"
        height="4"
        rx="0.5"
        fill="#CFCDD6"
        stroke="#CFCDD6"
      />
      <path
        d="M73 71.05C73.2447 70.3781 73.785 70 74.5 70C75.215 70 75.7553 70.3781 76 71.05"
        stroke="#CFCDD6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
