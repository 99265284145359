import qs from "qs";
import { BooksActionItems, ExportTxnResult } from "types/Models/books";
import { emptyApi } from "./emptyApi";
import { TransactionQuery } from "./transactions";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    exportTxn: build.query<ExportTxnResult, TransactionQuery>({
      query: ({
        groupId,
        entityId,
        page_num,
        searchTerm,
        transactionIds,
        startDate,
        endDate,
        fromIds,
        manualEntry,
        bankStatement,
        vendors,
        categoryIds,
        uncategorized,
        cashFlow,
        minAmount,
        maxAmount,
        hasMemo,
        hasComments,
        hasInvoices,
        sortCol,
        sortOrder,
        hasNoVendors,
        splitTransactions,
        fetch_page_num,
        jump_txn_id,
        notReviewed,
        txnAccountType,
        pageSize,
      }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
            transaction_ids: transactionIds,
            start_date: startDate?.value,
            end_date: endDate?.value,
            journal_entry: manualEntry?.value,
            bank_statement: bankStatement?.value,
            //@ts-ignore
            from_ids: fromIds?.value?.join(","),
            //@ts-ignore
            merchants: vendors?.value?.join(","),
            //@ts-ignore
            category_ids: categoryIds?.value?.join(","),
            uncategorized: uncategorized?.value,
            cash_flow: cashFlow?.value,
            min_amount: minAmount,
            max_amount: maxAmount,
            has_memo: hasMemo?.value,
            has_comments: hasComments?.value,
            has_invoices: hasInvoices?.value,
            has_no_merchants: hasNoVendors?.value,
            has_split_transactions: splitTransactions?.value,
            sort_col: sortCol,
            sort_order: sortOrder,
            not_reviewed: notReviewed?.value,
            fetch_page_num,
            jump_txn_id,
            txn_account_type: txnAccountType || null,
            page_size: pageSize,
          },
          {
            filter: (prefix, value) =>
              value === false || value === "" ? undefined : value,
            skipNulls: true,
          }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/entity_transactions/txn_export/`,
          params: {
            ...qs.parse(queryUrl),
          },
        };
      },
    }),
    booksActionItems: build.query<
      BooksActionItems,
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/action_items/`,
        };
      },
      providesTags: ["REPORTS"],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyExportTxnQuery, useBooksActionItemsQuery } = extendedApi;
