import React from "react";

export const Stripe = () => {
  return (
    <svg
      width="60"
      height="26"
      viewBox="0 0 60 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.9997 13.0234C59.9997 8.75105 57.9357 5.37988 53.9907 5.37988C50.029 5.37988 47.6321 8.75105 47.6321 12.99C47.6321 18.0134 50.4618 20.5501 54.5233 20.5501C56.5042 20.5501 58.0022 20.0995 59.1341 19.4653V16.1276C58.0022 16.695 56.7039 17.0454 55.056 17.0454C53.4414 17.0454 52.0099 16.478 51.8268 14.5087H59.9664C59.9664 14.2918 59.9997 13.4239 59.9997 13.0234ZM51.7768 11.438C51.7768 9.55212 52.9254 8.76774 53.974 8.76774C54.9894 8.76774 56.0714 9.55212 56.0714 11.438H51.7768Z"
        fill="#635BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2069 5.37988C39.5756 5.37988 38.5269 6.14757 37.9443 6.68162L37.7279 5.64691H34.0659V25.1062L38.2273 24.2217L38.2439 19.4987C38.8432 19.9326 39.7254 20.5501 41.1902 20.5501C44.1698 20.5501 46.883 18.1469 46.883 12.8565C46.8663 8.01673 44.1198 5.37988 41.2069 5.37988ZM40.2081 16.8786C39.226 16.8786 38.6434 16.5281 38.2439 16.0942L38.2273 9.90258C38.6601 9.4186 39.2593 9.08483 40.2081 9.08483C41.7229 9.08483 42.7715 10.7871 42.7715 12.9733C42.7715 15.2097 41.7395 16.8786 40.2081 16.8786Z"
        fill="#635BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3398 4.39452L32.5179 3.49332V0.105469L28.3398 0.989982V4.39452Z"
        fill="#635BFF"
      />
      <path
        d="M32.5179 5.66309H28.3398V20.2659H32.5179V5.66309Z"
        fill="#635BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8623 6.89772L23.5959 5.66274H20.0005V20.2656H24.1619V10.369C25.144 9.08397 26.8085 9.31762 27.3245 9.50119V5.66274C26.7919 5.46247 24.8443 5.09532 23.8623 6.89772Z"
        fill="#635BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5396 2.04102L11.4781 2.90884L11.4614 16.2767C11.4614 18.7466 13.3091 20.5657 15.7726 20.5657C17.1376 20.5657 18.1363 20.3154 18.6856 20.015V16.6271C18.1529 16.8441 15.5229 17.6118 15.5229 15.1418V9.21726H18.6856V5.66251H15.5229L15.5396 2.04102Z"
        fill="#635BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28701 9.90258C4.28701 9.25171 4.81966 9.00138 5.70188 9.00138C6.96694 9.00138 8.56491 9.38523 9.82997 10.0695V6.14757C8.44839 5.59684 7.08346 5.37988 5.70188 5.37988C2.32283 5.37988 0.0756836 7.14891 0.0756836 10.1029C0.0756836 14.709 6.40099 13.9747 6.40099 15.9607C6.40099 16.7284 5.73517 16.9787 4.80302 16.9787C3.42144 16.9787 1.65701 16.4113 0.258784 15.6436V19.6155C1.80682 20.2831 3.3715 20.5668 4.80302 20.5668C8.26529 20.5668 10.6456 18.8478 10.6456 15.8605C10.629 10.8872 4.28701 11.7717 4.28701 9.90258Z"
        fill="#635BFF"
      />
    </svg>
  );
};
