export const InkleMailroomSymbol = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.0219 30.7452L19.6877 24.8551V36.1561C19.6877 37.1745 18.8622 38 17.8439 38C16.8255 38 16 37.1745 16 36.1561V19.8645C16 18.8461 16.8255 18.0206 17.8439 18.0206C17.9255 18.0206 18.0058 18.0259 18.0847 18.0362C18.4469 18.0074 18.9012 18.0152 19.4987 18.0256C19.8752 18.0321 20.3087 18.0395 20.8118 18.0395L27.8108 18.0395L34.6117 18.0394C35.3964 18.0394 36.0371 18.0259 36.5672 18.0146C37.2294 18.0007 37.7189 17.9903 38.1009 18.0145C38.1192 18.014 38.1376 18.0137 38.1561 18.0137C39.1745 18.0137 40 18.8393 40 19.8576V36.1492C40 37.1676 39.1745 37.9931 38.1561 37.9931C37.1378 37.9931 36.3123 37.1676 36.3123 36.1493V25.0343L28.6216 30.7736C28.1442 31.1299 27.4863 31.1182 27.0219 30.7452Z"
      fill="url(#paint0_linear_5723_7698)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7698"
        x1="16"
        y1="28"
        x2="40"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
