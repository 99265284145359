import { generateContractorForm } from "apis/merchants";
import Loader from "components/design/loader";
import { PublicWFormUploadFile } from "components/PublicWFormUploadFile";
import { PublicWFormUploadSuccess } from "components/PublicWFormUploadSuccess";
import {
  Answer,
  QuestionAnswerList,
  QuestionAnswers,
} from "components/QuestionAnswers";
import { W_8BEN, W_8BEN_E, W_9 } from "constants/wSeriesFormTypes";
import { ContractorPageLayout } from "ContractorPageLayout";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUpdateWFormMutation } from "store/apis/groupWForms";
import { W_FORM_TYPES } from "types/Models/merchant";

const IS_US_BASED = "IS_US_BASED";
const INDIVIDUAL_OR_ENTITY = "INDIVIDUAL_OR_ENTITY";

const INDIVIDUAL = "INDIVIDUAL";
const ENTITY = "ENTITY";

const YES = "YES";
const NO = "NO";

export const ContractorFormSelector = () => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [activeStep, setActiveStep] = useState("IS_US_BASED");
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  let { merchantId } = useParams<{ merchantId: string }>();
  const query = useQuery();
  let companyWForm = query.get("companywform");
  const [updateWForm] = useUpdateWFormMutation();
  const [showUploadFileScreen, setShowUploadFileScreen] = useState(false);
  const [showSuccessfullyUpload, setShowSuccessfullyUpload] = useState(false);

  const onChange = async (values: Record<string, string>) => {
    setLoading(true);
    try {
      let formType: W_FORM_TYPES | null = null;

      if (values[IS_US_BASED] === YES) {
        formType = W_9;
      }

      if (values[INDIVIDUAL_OR_ENTITY] === INDIVIDUAL) {
        formType = W_8BEN;
      }

      if (values[INDIVIDUAL_OR_ENTITY] === ENTITY) {
        formType = W_8BEN_E;
      }

      if (formType) {
        if (companyWForm) {
          const data = await updateWForm({
            group_id: groupId,
            company_w_form_id: merchantId,
            w_form_type: formType,
          }).unwrap();
          history.push(
            `/contractor-form/${merchantId}/form?form_url=${data.form_url}`
          );
        } else {
          const { data: formId } = await generateContractorForm({
            merchantId,
            formType,
          });
          history.push(
            `/contractor-form/${merchantId}/form?form_url=${formId}`
          );
        }
      }

      if (values[IS_US_BASED] === NO) {
        setActiveStep(INDIVIDUAL_OR_ENTITY);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  if (showSuccessfullyUpload) {
    return (
      <ContractorPageLayout>
        <PublicWFormUploadSuccess />
      </ContractorPageLayout>
    );
  }

  if (showUploadFileScreen) {
    return (
      <ContractorPageLayout>
        <PublicWFormUploadFile
          closeShowUploadFileScreen={() => setShowUploadFileScreen(false)}
          setShowSuccessfullyUpload={setShowSuccessfullyUpload}
        />
      </ContractorPageLayout>
    );
  }
  return (
    <ContractorPageLayout>
      <QuestionAnswerList
        disabled={isLoading}
        onChange={onChange}
        active={activeStep}
        note="Please answer the questionnaire for us to determine which form is required to be filled by you "
        openShowUploadFileScreen={() => setShowUploadFileScreen(true)}
      >
        <QuestionAnswers
          question="Are you a US based Entity/Individual?"
          questionKey={IS_US_BASED}
        >
          <Answer answer="Yes" answerKey={YES} />
          <Answer answer="No" answerKey={NO} />
        </QuestionAnswers>
        <QuestionAnswers
          question="Are you an Individual or an Entity?"
          questionKey={INDIVIDUAL_OR_ENTITY}
        >
          <Answer answer="Individual" answerKey={INDIVIDUAL} />
          <Answer answer="Entity" answerKey={ENTITY} />
        </QuestionAnswers>
      </QuestionAnswerList>

      {isLoading && (
        <div className="position-absolute inset-0">
          <Loader />
        </div>
      )}
    </ContractorPageLayout>
  );
};
