import { Button } from "components/DesignSystem/Button/Button";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import Loader from "components/design/loader";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useGetPurposeCodeQuoteQuery } from "store/apis/transferPricing";
import { setPurposeCode } from "store/slices/transferpricing";
import { object, string } from "yup";

export const PurposeCodeStep = ({
  setCurrentStep,
}: {
  setCurrentStep: (v: number) => void;
}) => {
  const { data: purposeCodes, isLoading } = useGetPurposeCodeQuoteQuery({});
  const dispatch = useDispatch();

  const handlePurposeCodeSubmit = (values: any) => {
    dispatch(
      setPurposeCode(
        purposeCodes.find(
          ({ uuid }: { uuid: string }) => uuid === values.purpose_code_uuid
        )
      )
    );
    setCurrentStep(3);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="t-pb-8 t-text-h4">
        What’s the reason for your transfer?
      </div>
      <Formik
        initialValues={{ purpose_code_uuid: purposeCodes?.[0]?.uuid }}
        validationSchema={() =>
          object({
            purpose_code_uuid: string()
              .min(1)
              .required("Please select purpose code"),
          })
        }
        onSubmit={handlePurposeCodeSubmit}
      >
        <Form>
          <SelectDropDown
            name="purpose_code_uuid"
            label="Select the purpose code"
          >
            {purposeCodes?.map((ele: any) => (
              <option value={ele.uuid} key={ele.uuid}>
                {`${ele.code} 
                ${ele.description}`}
              </option>
            ))}
          </SelectDropDown>
          <div className="t-flex t-justify-end t-gap-3 t-pt-10">
            <Button onClick={() => setCurrentStep(1)}>Back</Button>
            <Button customType="primary" type="submit">
              Continue
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
