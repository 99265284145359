import { Cross } from "components/icons/Cross";
import { motion } from "framer-motion";

const Task7004Chat = () => {
  return (
    <div className="floatingChatComponent t-origin-bottom-right t-scale-100 t-duration-300 t-ease-in-out">
      <div className="w-100 h-100 chat-page-wrapper chatPageContainer floating-chat customerChat">
        <div id="chatPage" className="d-flex w-100 channelListComp !t-h-full">
          <div className="sendbird-app__channellist-wrap" />
          <div
            role="presentation"
            className="chat-dropzone custom-chat add-position"
          >
            <input
              multiple
              type="file"
              tabIndex={-1}
              style={{ display: "none" }}
            />
            <div className="sendbird-conversation">
              <span className="chatcustomHeader">
                <div>
                  <div
                    className="floatingChatHeader chat-header"
                    style={{
                      borderTopLeftRadius: "0",
                      borderTopRightRadius: "0",
                    }}
                  >
                    <span className="t-flex t-items-center">
                      <a
                        className="text-decoration-none floatingChatTitle t-flex t-flex-col t-gap-1"
                        target="_blank"
                        rel="noreferrer"
                        href="/filings/d03dd908-2ba3-400d-9f2f-f9013c4898e5"
                      >
                        <span className="t-flex t-gap-2">
                          <span className="t-min-text-[16px] t-max-w-[14rem] t-truncate">
                            Form 7004 - 2022
                          </span>
                        </span>
                        <span className="t-flex t-w-max t-items-center t-gap-2 t-text-body-sm t-text-purple-70">
                          <img
                            title="India"
                            className="t-rounded"
                            src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/us.svg"
                            style={{
                              display: "inline-block",
                              width: "1.5em",
                              height: "1.5em",
                              verticalAlign: "middle",
                            }}
                            alt=""
                          />
                          <span className="t-max-w-[14rem] t-truncate t-text-neutral-0">
                            Entity
                          </span>
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              </span>
              <div className="sendbird-conversation__messages">
                <div className="sendbird-conversation__scroll-container">
                  <div className="sendbird-conversation__padding" />
                  <div className="sendbird-conversation__messages-padding">
                    <motion.div
                      key="firstMessage"
                      transition={{ duration: 1, delay: 0.1 }}
                      initial={{ scale: 1.3, opacity: 1, x: 60, y: 240 }}
                      animate={{ opacity: 1, scale: 1, x: 0, y: -60 }}
                      exit={{ x: -100, opacity: 0 }}
                    >
                      <div className="sendbird-msg-hoc sendbird-msg--scroll-ref  ">
                        <div
                          className="sendbird-msg-hoc sendbird-msg--scroll-ref  "
                          style={{ marginBottom: "2px" }}
                        >
                          <div className="sendbird-message-hoc__message-content sendbird-message-content incoming">
                            <div className="sendbird-message-content__middle">
                              <span className="sendbird-message-content__middle__sender-name sendbird-label sendbird-label--caption-2 sendbird-label--color-onbackground-2">
                                Ananya S
                              </span>
                              <div
                                className="sendbird-message-content__middle__body-container"
                                style={{
                                  background: "#fff",
                                  borderRadius: "4px 14px 14px 14px",
                                }}
                              >
                                <span className="sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1">
                                  <div className="sendbird-message-content__middle__message-item-body sendbird-text-message-item-body incoming  reactions">
                                    Hi there, <br />
                                    <br /> This Filing is to get a 6-month
                                    extension on your Federal Tax filing (Form
                                    1120). <br /> The deadline for your tax
                                    filing is in mid-April for calendar
                                    companies.
                                    <br />
                                    <br /> It is free and this takes the
                                    artificial deadline pressure off both you
                                    and our team.
                                    <br />
                                    <br /> Kindly make a start on this by
                                    pushing the Filing to "In Progress".
                                    <br /> Thanks.
                                  </div>
                                </span>

                                <span className="sendbird-message-content__middle__body-container__created-at right  sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-2">
                                  7:18 PM
                                </span>
                              </div>
                            </div>
                            <div className="sendbird-message-content__right  use-reactions ">
                              <div className="sendbird-message-content-menu   incoming">
                                <div className="sendbird-message-content-menu__reaction-menu sendbird-message-item-reaction-menu">
                                  <div
                                    className="sendbird-context-menu"
                                    style={{ display: "inline" }}
                                  >
                                    <button
                                      className="sendbird-message-item-reaction-menu__trigger sendbird-iconbutton "
                                      type="button"
                                      style={{ height: "32px", width: "32px" }}
                                    >
                                      <span className="sendbird-iconbutton__inner">
                                        <div
                                          className="sendbird-message-item-reaction-menu__trigger__icon sendbird-icon sendbird-icon-emoji-more sendbird-icon-color--content-inverse"
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            width: "24px",
                                            minWidth: "24px",
                                            height: "24px",
                                            minHeight: "24px",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 64 64"
                                          >
                                            <path
                                              className="icon-emoji-more_svg__fill"
                                              d="M32.097 3.22c2.65 0 5.255.357 7.763 1.054a2.134 2.134 0 01-1.144 4.111 24.669 24.669 0 00-6.619-.899c-13.603 0-24.63 11.027-24.63 24.63s11.027 24.63 24.63 24.63 24.63-11.027 24.63-24.63c0-2.227-.295-4.413-.87-6.518a2.13 2.13 0 011.494-2.62 2.13 2.13 0 012.62 1.494 28.895 28.895 0 011.023 7.644c0 15.96-12.938 28.897-28.897 28.897-15.96 0-28.897-12.937-28.897-28.897C3.2 16.157 16.138 3.22 32.097 3.22zm10.705 34.792a2.133 2.133 0 012.024 2.808c-1.873 5.623-6.937 9.488-12.729 9.488-5.792 0-10.856-3.865-12.73-9.488a2.134 2.134 0 011.875-2.803l.15-.005h21.41zm-3.477 4.266H24.867l.294.382c1.539 1.887 3.718 3.113 6.115 3.342l.314.024.507.015c2.617 0 5.037-1.188 6.743-3.151l.193-.23.292-.382zM21.392 21.954c1.087 0 1.985.814 2.116 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.134-2.133zm21.41 0c1.088 0 1.985.814 2.117 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.133-2.133zM54.853 0a.8.8 0 01.8.8v7.786h7.76a.8.8 0 01.8.8v2.667a.8.8 0 01-.8.8h-7.76v7.758a.8.8 0 01-.8.8h-2.666a.8.8 0 01-.8-.8v-7.758h-7.785a.8.8 0 01-.8-.8V9.387a.8.8 0 01.8-.8l7.784-.001V.8a.8.8 0 01.8-.8h2.667z"
                                              fill="#000"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="sendbird-message-content-menu__normal-menu sendbird-message-item-menu">
                                  <div
                                    className="sendbird-context-menu"
                                    style={{ display: "inline" }}
                                  >
                                    <button
                                      className="sendbird-message-item-menu__trigger sendbird-iconbutton "
                                      type="button"
                                      style={{ height: "32px", width: "32px" }}
                                    >
                                      <span className="sendbird-iconbutton__inner">
                                        <div
                                          className="sendbird-message-item-menu__trigger__icon sendbird-icon sendbird-icon-more sendbird-icon-color--content-inverse"
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            width: "24px",
                                            minWidth: "24px",
                                            height: "24px",
                                            minHeight: "24px",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 64 64"
                                          >
                                            <path
                                              className="icon-more_svg__fill"
                                              d="M32 45.333a5.333 5.333 0 110 10.666 5.333 5.333 0 010-10.666zM32 28a5.333 5.333 0 110 10.668A5.333 5.333 0 0132 28zm0-17.333c2.946 0 5.333 2.387 5.333 5.333S34.946 21.333 32 21.333 26.667 18.946 26.667 16s2.387-5.333 5.333-5.333z"
                                              fill="#000"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                    <motion.div
                      key="secondMessage"
                      transition={{ duration: 1, delay: 1 }}
                      initial={{ scale: 1.3, opacity: 0, x: -60, y: 180 }}
                      animate={{ opacity: 1, scale: 1, x: 0, y: -60 }}
                      exit={{ x: -100, opacity: 0 }}
                    >
                      <div className="sendbird-msg-hoc sendbird-msg--scroll-ref  ">
                        <div className=" sendbird-separator">
                          <div className="sendbird-separator__left sendbird-color--onbackground-4--background-color" />
                          <div className="sendbird-separator__right sendbird-color--onbackground-4--background-color" />
                        </div>
                        <div
                          className="sendbird-msg-hoc sendbird-msg--scroll-ref  "
                          style={{ marginBottom: "2px" }}
                        >
                          <div className="sendbird-message-hoc__message-content sendbird-message-content outgoing">
                            <div className="sendbird-message-content__left use-reactions outgoing ">
                              <div className="sendbird-message-content-menu use-reactions  outgoing">
                                <div className="sendbird-message-content-menu__normal-menu sendbird-message-item-menu">
                                  <div
                                    className="sendbird-context-menu"
                                    style={{ display: "inline" }}
                                  >
                                    <button
                                      className="sendbird-message-item-menu__trigger sendbird-iconbutton "
                                      type="button"
                                      style={{ height: "32px", width: "32px" }}
                                    >
                                      <span className="sendbird-iconbutton__inner">
                                        <div
                                          className="sendbird-message-item-menu__trigger__icon sendbird-icon sendbird-icon-more sendbird-icon-color--content-inverse"
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            width: "24px",
                                            minWidth: "24px",
                                            height: "24px",
                                            minHeight: "24px",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 64 64"
                                          >
                                            <path
                                              className="icon-more_svg__fill"
                                              d="M32 45.333a5.333 5.333 0 110 10.666 5.333 5.333 0 010-10.666zM32 28a5.333 5.333 0 110 10.668A5.333 5.333 0 0132 28zm0-17.333c2.946 0 5.333 2.387 5.333 5.333S34.946 21.333 32 21.333 26.667 18.946 26.667 16s2.387-5.333 5.333-5.333z"
                                              fill="#000"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="sendbird-message-content-menu__reaction-menu sendbird-message-item-reaction-menu">
                                  <div
                                    className="sendbird-context-menu"
                                    style={{ display: "inline" }}
                                  >
                                    <button
                                      className="sendbird-message-item-reaction-menu__trigger sendbird-iconbutton "
                                      type="button"
                                      style={{ height: "32px", width: "32px" }}
                                    >
                                      <span className="sendbird-iconbutton__inner">
                                        <div
                                          className="sendbird-message-item-reaction-menu__trigger__icon sendbird-icon sendbird-icon-emoji-more sendbird-icon-color--content-inverse"
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            width: "24px",
                                            minWidth: "24px",
                                            height: "24px",
                                            minHeight: "24px",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 64 64"
                                          >
                                            <path
                                              className="icon-emoji-more_svg__fill"
                                              d="M32.097 3.22c2.65 0 5.255.357 7.763 1.054a2.134 2.134 0 01-1.144 4.111 24.669 24.669 0 00-6.619-.899c-13.603 0-24.63 11.027-24.63 24.63s11.027 24.63 24.63 24.63 24.63-11.027 24.63-24.63c0-2.227-.295-4.413-.87-6.518a2.13 2.13 0 011.494-2.62 2.13 2.13 0 012.62 1.494 28.895 28.895 0 011.023 7.644c0 15.96-12.938 28.897-28.897 28.897-15.96 0-28.897-12.937-28.897-28.897C3.2 16.157 16.138 3.22 32.097 3.22zm10.705 34.792a2.133 2.133 0 012.024 2.808c-1.873 5.623-6.937 9.488-12.729 9.488-5.792 0-10.856-3.865-12.73-9.488a2.134 2.134 0 011.875-2.803l.15-.005h21.41zm-3.477 4.266H24.867l.294.382c1.539 1.887 3.718 3.113 6.115 3.342l.314.024.507.015c2.617 0 5.037-1.188 6.743-3.151l.193-.23.292-.382zM21.392 21.954c1.087 0 1.985.814 2.116 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.134-2.133zm21.41 0c1.088 0 1.985.814 2.117 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.133-2.133zM54.853 0a.8.8 0 01.8.8v7.786h7.76a.8.8 0 01.8.8v2.667a.8.8 0 01-.8.8h-7.76v7.758a.8.8 0 01-.8.8h-2.666a.8.8 0 01-.8-.8v-7.758h-7.785a.8.8 0 01-.8-.8V9.387a.8.8 0 01.8-.8l7.784-.001V.8a.8.8 0 01.8-.8h2.667z"
                                              fill="#000"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sendbird-message-content__middle">
                              <div className="sendbird-message-content__middle__body-container">
                                <div className="sendbird-message-content__middle__body-container__created-at left ">
                                  <div className="sendbird-message-content__middle__body-container__created-at__component-container">
                                    <div className=" sendbird-message-status">
                                      <span className="sendbird-message-status__text sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-2">
                                        2:41 PM
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <span
                                  className="sendbird-label sendbird-label--body-1 sendbird-label--color-oncontent-1"
                                  style={{
                                    backgroundColor: "#481DD6",
                                    borderRadius: "14px 4px 0 0",
                                  }}
                                >
                                  <div
                                    className="sendbird-message-content__middle__message-item-body sendbird-text-message-item-body outgoing  reactions"
                                    style={{ color: "white" }}
                                  >
                                    Thank you for loading this filing, I will
                                    begin filling out the details immediately
                                  </div>
                                </span>
                                <div className="sendbird-message-content-reactions primary ">
                                  <div className=" sendbird-emoji-reactions outgoing t-bg-surface">
                                    <div className="sendbird-emoji-reactions__reaction-badge sendbird-tooltip-wrapper">
                                      <div className="sendbird-tooltip-wrapper__children">
                                        <div
                                          className=" sendbird-reaction-badge--selected"
                                          role="button"
                                          tabIndex={0}
                                        >
                                          <div className="sendbird-reaction-badge__inner">
                                            <div className="sendbird-reaction-badge__inner__icon">
                                              <div
                                                className=" sendbird-image-renderer"
                                                style={{
                                                  width: "100%",
                                                  minWidth: "20px",
                                                  maxWidth: "400px",
                                                  height: "20px",
                                                }}
                                              >
                                                <div
                                                  className="sendbird-image-renderer__image"
                                                  style={{
                                                    width: "100%",
                                                    minWidth: "20px",
                                                    maxWidth: "400px",
                                                    height: "20px",
                                                    position: "absolute",
                                                    backgroundRepeat:
                                                      "no-repeat",
                                                    backgroundPosition:
                                                      "center center",
                                                    backgroundSize: "cover",
                                                    backgroundImage:
                                                      'url("https://static.sendbird.com/icons/emoji_thumbsup.png")',
                                                    borderRadius: "50%",
                                                  }}
                                                />
                                                <img
                                                  className="sendbird-image-renderer__hidden-image-loader"
                                                  src="https://static.sendbird.com/icons/emoji_sweat_smile.png"
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <span className="sendbird-reaction-badge__inner__count sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-1">
                                              1
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="sendbird-context-menu"
                                      style={{
                                        display: "inline",
                                        marginTop: "-4px",
                                      }}
                                    >
                                      <div
                                        className="sendbird-emoji-reactions__add-reaction-badge sendbird-reaction-badge--is-add"
                                        role="button"
                                        tabIndex={0}
                                      >
                                        <div className="sendbird-reaction-badge__inner">
                                          <div className="sendbird-reaction-badge__inner__icon">
                                            <div
                                              className=" sendbird-icon sendbird-icon-emoji-more sendbird-icon-color--on-background-3"
                                              role="button"
                                              tabIndex={0}
                                              style={{
                                                width: "20px",
                                                minWidth: "20px",
                                                height: "20px",
                                                minHeight: "20px",
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 64 64"
                                              >
                                                <path
                                                  className="icon-emoji-more_svg__fill"
                                                  d="M32.097 3.22c2.65 0 5.255.357 7.763 1.054a2.134 2.134 0 01-1.144 4.111 24.669 24.669 0 00-6.619-.899c-13.603 0-24.63 11.027-24.63 24.63s11.027 24.63 24.63 24.63 24.63-11.027 24.63-24.63c0-2.227-.295-4.413-.87-6.518a2.13 2.13 0 011.494-2.62 2.13 2.13 0 012.62 1.494 28.895 28.895 0 011.023 7.644c0 15.96-12.938 28.897-28.897 28.897-15.96 0-28.897-12.937-28.897-28.897C3.2 16.157 16.138 3.22 32.097 3.22zm10.705 34.792a2.133 2.133 0 012.024 2.808c-1.873 5.623-6.937 9.488-12.729 9.488-5.792 0-10.856-3.865-12.73-9.488a2.134 2.134 0 011.875-2.803l.15-.005h21.41zm-3.477 4.266H24.867l.294.382c1.539 1.887 3.718 3.113 6.115 3.342l.314.024.507.015c2.617 0 5.037-1.188 6.743-3.151l.193-.23.292-.382zM21.392 21.954c1.087 0 1.985.814 2.116 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.134-2.133zm21.41 0c1.088 0 1.985.814 2.117 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.133-2.133zM54.853 0a.8.8 0 01.8.8v7.786h7.76a.8.8 0 01.8.8v2.667a.8.8 0 01-.8.8h-7.76v7.758a.8.8 0 01-.8.8h-2.666a.8.8 0 01-.8-.8v-7.758h-7.785a.8.8 0 01-.8-.8V9.387a.8.8 0 01.8-.8l7.784-.001V.8a.8.8 0 01.8-.8h2.667z"
                                                  fill="#000"
                                                  fillRule="evenodd"
                                                />
                                              </svg>
                                            </div>
                                          </div>
                                          <span className=" sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-1" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sendbird-message-content__right  use-reactions " />
                          </div>
                        </div>
                      </div>
                    </motion.div>
                    <motion.div
                      key="thirdMessage"
                      transition={{ duration: 1, delay: 2 }}
                      initial={{ scale: 1.3, opacity: 0, x: 60, y: 120 }}
                      animate={{ opacity: 1, scale: 1, x: 0, y: -60 }}
                      exit={{ x: -100, opacity: 0 }}
                    >
                      <div className="sendbird-msg-hoc sendbird-msg--scroll-ref  t-mt-1">
                        <div
                          className="sendbird-msg-hoc sendbird-msg--scroll-ref  "
                          style={{ marginBottom: "2px" }}
                        >
                          <div className="sendbird-message-hoc__message-content sendbird-message-content incoming">
                            <div className="sendbird-message-content__middle">
                              <span className="sendbird-message-content__middle__sender-name sendbird-label sendbird-label--caption-2 sendbird-label--color-onbackground-2">
                                Ananya S
                              </span>
                              <div
                                className="sendbird-message-content__middle__body-container"
                                style={{
                                  background: "#fff",
                                  borderRadius: "4px 14px 14px 14px",
                                }}
                              >
                                <span className="sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1">
                                  <div className="sendbird-message-content__middle__message-item-body sendbird-text-message-item-body incoming  ">
                                    Thank you for the swift response, let us
                                    know if you have any questions.
                                  </div>
                                </span>
                                <span className="sendbird-message-content__middle__body-container__created-at right  sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-2">
                                  3:20 PM
                                </span>
                              </div>
                            </div>
                            <div className="sendbird-message-content__right  use-reactions ">
                              <div className="sendbird-message-content-menu   incoming">
                                <div className="sendbird-message-content-menu__reaction-menu sendbird-message-item-reaction-menu">
                                  <div
                                    className="sendbird-context-menu"
                                    style={{ display: "inline" }}
                                  >
                                    <button
                                      className="sendbird-message-item-reaction-menu__trigger sendbird-iconbutton "
                                      type="button"
                                      style={{ height: "32px", width: "32px" }}
                                    >
                                      <span className="sendbird-iconbutton__inner">
                                        <div
                                          className="sendbird-message-item-reaction-menu__trigger__icon sendbird-icon sendbird-icon-emoji-more sendbird-icon-color--content-inverse"
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            width: "24px",
                                            minWidth: "24px",
                                            height: "24px",
                                            minHeight: "24px",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 64 64"
                                          >
                                            <path
                                              className="icon-emoji-more_svg__fill"
                                              d="M32.097 3.22c2.65 0 5.255.357 7.763 1.054a2.134 2.134 0 01-1.144 4.111 24.669 24.669 0 00-6.619-.899c-13.603 0-24.63 11.027-24.63 24.63s11.027 24.63 24.63 24.63 24.63-11.027 24.63-24.63c0-2.227-.295-4.413-.87-6.518a2.13 2.13 0 011.494-2.62 2.13 2.13 0 012.62 1.494 28.895 28.895 0 011.023 7.644c0 15.96-12.938 28.897-28.897 28.897-15.96 0-28.897-12.937-28.897-28.897C3.2 16.157 16.138 3.22 32.097 3.22zm10.705 34.792a2.133 2.133 0 012.024 2.808c-1.873 5.623-6.937 9.488-12.729 9.488-5.792 0-10.856-3.865-12.73-9.488a2.134 2.134 0 011.875-2.803l.15-.005h21.41zm-3.477 4.266H24.867l.294.382c1.539 1.887 3.718 3.113 6.115 3.342l.314.024.507.015c2.617 0 5.037-1.188 6.743-3.151l.193-.23.292-.382zM21.392 21.954c1.087 0 1.985.814 2.116 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.134-2.133zm21.41 0c1.088 0 1.985.814 2.117 1.866l.017.267v5.353a2.133 2.133 0 01-4.25.268l-.017-.268v-5.353c0-1.178.955-2.133 2.133-2.133zM54.853 0a.8.8 0 01.8.8v7.786h7.76a.8.8 0 01.8.8v2.667a.8.8 0 01-.8.8h-7.76v7.758a.8.8 0 01-.8.8h-2.666a.8.8 0 01-.8-.8v-7.758h-7.785a.8.8 0 01-.8-.8V9.387a.8.8 0 01.8-.8l7.784-.001V.8a.8.8 0 01.8-.8h2.667z"
                                              fill="#000"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="sendbird-message-content-menu__normal-menu sendbird-message-item-menu">
                                  <div
                                    className="sendbird-context-menu"
                                    style={{ display: "inline" }}
                                  >
                                    <button
                                      className="sendbird-message-item-menu__trigger sendbird-iconbutton "
                                      type="button"
                                      style={{ height: "32px", width: "32px" }}
                                    >
                                      <span className="sendbird-iconbutton__inner">
                                        <div
                                          className="sendbird-message-item-menu__trigger__icon sendbird-icon sendbird-icon-more sendbird-icon-color--content-inverse"
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            width: "24px",
                                            minWidth: "24px",
                                            height: "24px",
                                            minHeight: "24px",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 64 64"
                                          >
                                            <path
                                              className="icon-more_svg__fill"
                                              d="M32 45.333a5.333 5.333 0 110 10.666 5.333 5.333 0 010-10.666zM32 28a5.333 5.333 0 110 10.668A5.333 5.333 0 0132 28zm0-17.333c2.946 0 5.333 2.387 5.333 5.333S34.946 21.333 32 21.333 26.667 18.946 26.667 16s2.387-5.333 5.333-5.333z"
                                              fill="#000"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className="sendbird-conversation__footer">
                <div className="sendbird-message-input-wrapper">
                  <form className="sendbird-message-input-wrapper__message-input">
                    <div className="sendbird-message-input t-bg-[white]">
                      <div
                        id="sendbird-message-input-text-field"
                        className="sendbird-message-input--textarea sendbird-message-input-text-field"
                        contentEditable="true"
                        role="textbox"
                        aria-label="Text Input"
                      />
                      <span className="sendbird-message-input--placeholder sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-3">
                        Enter message or drop files
                      </span>
                      <button
                        className="sendbird-message-input--attach sendbird-iconbutton "
                        type="button"
                        style={{ height: "32px", width: "32px" }}
                      >
                        <span className="sendbird-iconbutton__inner">
                          <div
                            className=" sendbird-icon sendbird-icon-attach sendbird-icon-color--content-inverse"
                            role="button"
                            tabIndex={0}
                            style={{
                              width: "20px",
                              minWidth: "20px",
                              height: "20px",
                              minHeight: "20px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 64 64"
                            >
                              <path
                                className="icon-attach_svg__fill"
                                d="M55.334 28.926l-24.506 23.34c-5.222 4.973-13.74 4.973-18.962 0-5.149-4.903-5.149-12.797 0-17.7l24.506-23.34c3.138-2.988 8.278-2.988 11.416 0 3.064 2.919 3.064 7.594 0 10.513L23.255 45.077c-1.055 1.005-2.815 1.005-3.87.001-.98-.933-.98-2.39 0-3.325l22.64-21.535a2.667 2.667 0 00-3.676-3.864L15.709 37.89a7.578 7.578 0 00-.001 11.05c3.113 2.966 8.11 2.966 11.224 0l24.533-23.338c5.272-5.021 5.272-13.217 0-18.238-5.197-4.95-13.573-4.95-18.77 0L8.187 30.704c-7.356 7.005-7.356 18.419 0 25.424 7.281 6.935 19.036 6.935 26.318 0l24.506-23.34a2.666 2.666 0 10-3.678-3.862z"
                                fill="#000"
                                fillRule="evenodd"
                              />
                            </svg>
                          </div>
                          <input
                            className="sendbird-message-input--attach-input"
                            type="file"
                          />
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="sendbird-conversation__footer__typing-indicator">
                  <span className="sendbird-conversation__footer__typing-indicator__text sendbird-label sendbird-label--caption-2 sendbird-label--color-onbackground-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="chatPage"
          className="d-flex w-100 channelListComp !t-hidden sm:!t-flex"
        >
          <div className="sendbird-app__channellist-wrap" />
        </div>
      </div>
    </div>
  );
};

export default Task7004Chat;
