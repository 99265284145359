import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Table from "components/DesignSystem/Table/V2/Table";
import { DateFilter } from "components/Filters/DateFilter";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import TransactionColumn from "components/Transaction/TransactionColumn";
import { TRANSACTIONS } from "constants/chatType";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetInsightsQuery } from "store/apis/metrics";
import {
  useGetAllTransactionsDataQuery,
  usePushToChatMutation,
} from "store/apis/transactions";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { openSlider } from "store/slices/transactions";
import { Transactions } from "types/Models/books";
import { formatDate } from "utils/formatDate";
import { getDateRange } from "utils/getDateRange";

const Card = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <div className="t-p-5 t-border t-border-solid t-border-neutral-0 t-rounded-lg t-overflow-hidden t-gap-6 t-flex t-flex-col">
      <p className="t-m-0 t-text-subtitle-sm t-text-text-60">{title}</p>
      {children}
    </div>
  );
};

export const TopFiveVendors = ({
  top_five_merchants,
  isInsightsSuccess,
  isInsightsLoading,
  isInsightsFetching,
}: {
  top_five_merchants: Array<{
    amount: number;
    logo: string | null;
    merchant_name: string;
  }>;
  isInsightsSuccess: boolean;
  isInsightsLoading: boolean;
  isInsightsFetching: boolean;
}) => {
  return (
    <Card title="Top Vendors">
      <Async.Root
        {...{
          isLoading: isInsightsLoading,
          isEmpty: top_five_merchants.length === 0,
          isSuccess: isInsightsSuccess,
        }}
      >
        <Async.Empty>
          <EmptyScreen text="No vendors found" className="!t-h-60" />
        </Async.Empty>
        <Async.Success>
          <div className="t-grid t-grid-cols-1 t-gap-5">
            {top_five_merchants.map(({ amount, logo, merchant_name }, i) => (
              <div
                key={i}
                className="t-grid t-grid-cols-2 t-justify-between t-text-text-60 t-text-body t-gap-2 t-items-center"
              >
                <div className="t-flex t-gap-4 t-items-center">
                  {isInsightsFetching ? (
                    <>
                      <div className="t-h-8 t-w-8 t-animate-pulse t-bg-neutral-20 t-rounded-full t-py-2" />
                      <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-4/5" />
                    </>
                  ) : (
                    <>
                      <Avatar alt={merchant_name} src={logo!} />
                      {merchant_name}
                    </>
                  )}
                </div>
                <div className="t-text-end t-justify-self-end">
                  {isInsightsFetching ? (
                    <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-28" />
                  ) : (
                    <AmountSuperScript amount={amount} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </Async.Success>
      </Async.Root>
    </Card>
  );
};

export const TopFiveCategories = ({
  top_five_categories,
  isInsightsSuccess,
  isInsightsLoading,
  isInsightsFetching,
}: {
  top_five_categories: Array<{
    amount: number;
    category_name: string;
  }>;
  isInsightsSuccess: boolean;
  isInsightsLoading: boolean;
  isInsightsFetching: boolean;
}) => {
  return (
    <Card title="Top Categories">
      <Async.Root
        {...{
          isLoading: isInsightsLoading,
          isEmpty: top_five_categories.length === 0,
          isSuccess: isInsightsSuccess,
        }}
      >
        <Async.Empty>
          <EmptyScreen text="No categories found" className="!t-h-60" />
        </Async.Empty>
        <Async.Success>
          <div className="t-grid t-grid-cols-1 t-gap-5">
            {top_five_categories.map(({ amount, category_name }, i) => (
              <div
                key={i}
                className="t-grid t-grid-cols-2 t-justify-between t-text-text-60 t-text-body t-gap-2 t-items-center"
              >
                <>
                  {isInsightsFetching ? (
                    <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-4/5" />
                  ) : (
                    <>{category_name}</>
                  )}
                </>

                <div className="t-text-end t-justify-self-end">
                  {isInsightsFetching ? (
                    <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-28" />
                  ) : (
                    <AmountSuperScript amount={amount} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </Async.Success>
      </Async.Root>
    </Card>
  );
};

export const TopInsights = () => {
  const defaultDateRange = getDateRange("currentYear");
  const { values, updateFilter } = useFilters({
    initialValue: {
      START_DATE: dayjs(defaultDateRange.startDate).format(DD_MMM_YYYY),
      END_DATE: dayjs(defaultDateRange.endDate).format(DD_MMM_YYYY),
      SELECT_PERIOD: "currentYear",
    },
  });

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useAppDispatch();

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      sortCol: "AMOUNT",
      sortOrder: "ASC",
      startDate: {
        value: values.START_DATE
          ? dayjs(values.START_DATE).format(YYYY_MM_DD)
          : null,
      },
      endDate: {
        value: values.END_DATE
          ? dayjs(values.END_DATE).format(YYYY_MM_DD)
          : null,
      },
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    data: insights,
    isLoading: isInsightsLoading,
    isSuccess: isInsightsSuccess,
    isFetching: isInsightsFetching,
  } = useGetInsightsQuery(
    {
      groupId,
      entityId,
      start_date: values.START_DATE
        ? dayjs(values.START_DATE).format(YYYY_MM_DD)
        : "",
      end_date: values.END_DATE
        ? dayjs(values.END_DATE).format(YYYY_MM_DD)
        : "",
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const { top_five_categories = [], top_five_merchants = [] } = insights || {};

  const [pushToChat] = usePushToChatMutation();
  const { successToast, alertToast } = useToast();

  const columnHelper = createColumnHelper<Transactions>();

  const { channel_url } = transactionData || {};

  const onRowClick = (
    transactionId: string,
    e: Event | React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(openSlider(transactionId));
  };

  const sendToChat = async ({
    transactionIds,
  }: {
    transactionIds: string[];
  }) => {
    if (channel_url) {
      dispatch(setToOpenChatId(channel_url));
      try {
        const payload = {
          channel_url: channel_url,
          custom_type: TRANSACTIONS,
          transaction_ids: transactionIds.join(","),
        };
        await pushToChat({ groupId, payload, entityId }).unwrap();
        successToast({ message: "Transaction sent to chat" });
      } catch (error) {
        alertToast({ message: "Failed to send to chat" });
      }
      dispatch(openFloatingChat());
    }
  };

  const { sliderTransactions } = useAppSelector((state) => state?.transactions);

  const columns = [
    columnHelper.accessor("transaction.date", {
      id: "DATE",
      size: 13,
      header: () => <span className="px-1">Date</span>,
      cell: (info) => (
        <div className="t-text-subtext t-text-text-30 t-px-1">
          {dayjs(info.getValue()).format(DD_MMM_YYYY)}
        </div>
      ),
    }),

    columnHelper.accessor("transaction.from", {
      id: "logo",
      size: 5,
      header: () => (
        <div className="t-flex t-justify-center t-w-full">Source</div>
      ),
      cell: (info) => {
        const transaction = info.row.original.transaction;

        return <TransactionColumn.Source transaction={transaction} />;
      },
    }),

    columnHelper.accessor("transaction.merchant", {
      id: "merchant",
      size: 23,
      header: "Vendor",
      cell: (info) => {
        return <TransactionColumn.Merchant info={info} />;
      },
    }),

    columnHelper.accessor("transaction.category", {
      id: "category",
      size: 28,
      header: "Category",
      cell: (info) => {
        return <TransactionColumn.Category info={info} />;
      },
    }),

    columnHelper.accessor("transaction.amount", {
      id: "AMOUNT",
      size: 6,
      header: () => (
        <span className="t-flex t-justify-end t-ml-auto">Amount</span>
      ),
      cell: (info) => {
        const amount = info.getValue();
        const {
          transaction: { is_credit_card },
        } = info.row.original || {};

        return (
          <TransactionColumn.Amount
            amount={amount}
            isCreditCard={is_credit_card}
          />
        );
      },
    }),

    columnHelper.accessor("invoices", {
      id: "invoice",
      size: 3,
      header: "Invoice",
      cell: TransactionColumn.Invoice,
    }),

    columnHelper.accessor("transaction", {
      id: "threedots",
      size: 5,
      header: "",
      cell: (info) => (
        <TransactionColumn.Actions
          info={info}
          onRowClick={onRowClick}
          sendToChat={(transactionIds) =>
            sendToChat({ transactionIds: [transactionIds] })
          }
        />
      ),
    }),
  ];

  const data = useMemo(() => {
    return transactionData?.transactions.slice(0, 5) || [];
  }, [transactionData?.transactions]);

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <DashboardContainer className="t-relative t-gap-6">
      <DashboardContainer.Header className="t-sticky t-top-0 t-z-header">
        <div className="t-bg-surface t-flex t-flex-col t-gap-6">
          <p className="t-m-0 t-text-subtitle t-text-text-100">Top Insights</p>
          <div className="t-flex t-gap-2">
            <Filter.Root
              defaultValue="DATE"
              title="Filter"
              capsule={
                <>
                  {values.START_DATE && (
                    <Chip
                      onClose={() => {
                        updateFilter(
                          "START_DATE",
                          dayjs(defaultDateRange.startDate).format(DD_MMM_YYYY)
                        );
                        updateFilter(
                          "END_DATE",
                          dayjs(defaultDateRange.endDate).format(DD_MMM_YYYY)
                        );
                      }}
                      isActive
                      isRemovable={false}
                      filterType="DATE"
                    >
                      From: {formatDate(values.START_DATE)} To:{" "}
                      {formatDate(values.END_DATE)}
                    </Chip>
                  )}
                </>
              }
            >
              <Filter.Portal>
                <Filter.List>
                  <Filter.ListItem value="DATE">Date</Filter.ListItem>
                </Filter.List>

                <Filter.Body value="DATE" block>
                  <DateFilter values={values} updateFilter={updateFilter} />
                </Filter.Body>
              </Filter.Portal>
            </Filter.Root>
          </div>
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        <div className="t-grid t-grid-cols-2 t-gap-5">
          <TopFiveVendors
            isInsightsFetching={isInsightsFetching}
            isInsightsLoading={isInsightsLoading}
            top_five_merchants={top_five_merchants}
            isInsightsSuccess={isInsightsSuccess}
          />

          <TopFiveCategories
            top_five_categories={top_five_categories}
            isInsightsSuccess={isInsightsSuccess}
            isInsightsLoading={isInsightsLoading}
            isInsightsFetching={isInsightsFetching}
          />

          <div className="t-col-span-2">
            <Card title="Top Spends">
              <Async.Root
                {...{
                  isLoading: isLoading,
                  isEmpty: transactionData?.transactions.length === 0,
                  isSuccess: isSuccess,
                }}
              >
                <Async.Empty>
                  <EmptyScreen
                    text="No transactions found"
                    className="t-w-full"
                  >
                    <span className="t-text-i-neutral-10">
                      <MagnifyingGlass size="149" />
                    </span>
                  </EmptyScreen>
                </Async.Empty>
                <Async.Success>
                  <Table.Container className="t-h-full" isLoading={isFetching}>
                    <Table.Content>
                      <Table.Head>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <Table.HeadRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <Table.HeadCell
                                className="t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group"
                                key={header.id}
                                style={{ width: `${header.getSize()}%` }}
                              >
                                <span className="t-flex t-gap-1 t-items-center">
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </span>
                              </Table.HeadCell>
                            ))}
                          </Table.HeadRow>
                        ))}
                      </Table.Head>
                      <Table.Body>
                        {table.getRowModel().rows.map((row) => (
                          <>
                            <Table.Row
                              key={row.id}
                              className={classNames(
                                "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                                {
                                  "t-bg-surface-lighter-grey":
                                    sliderTransactions.find(
                                      ({ transactionId }) =>
                                        transactionId ===
                                        row?.original?.transaction?.uuid
                                    ),
                                }
                              )}
                              onClick={(e) =>
                                onRowClick(row.original.transaction.uuid, e)
                              }
                            >
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <Table.Cell
                                    key={cell.id}
                                    style={{
                                      width: `${cell.column.getSize()}%`,
                                    }}
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </Table.Cell>
                                );
                              })}
                            </Table.Row>
                          </>
                        ))}
                      </Table.Body>
                    </Table.Content>
                  </Table.Container>
                </Async.Success>
                <TransactionSlider />
              </Async.Root>
            </Card>
          </div>
        </div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
