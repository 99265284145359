import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { PriceInput } from "components/PriceInput/PriceInput";
import { accountTypes } from "constants/connections";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { editManualBankSchema } from "formValidations/addManualBankSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useGetBankAccountByIdQuery,
  useGetEntityItemBankAccountsQuery,
  useUpdateBankAccountMutation,
} from "store/apis/bankConnections";
import { closeEditBankModal, resetEditBankId } from "store/slices/connections";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { object, string } from "yup";

const EditModal = ({
  close,
  isOpen,
  accountId,
  editBankId,
  openChat,
}: ModalProps & {
  editBankId: string;
  accountId: string;
  openChat: () => void;
}) => {
  const { isCustomer } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [updateBankAccount] = useUpdateBankAccountMutation();
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();

  const { data: bankAccount, isFetching } = useGetBankAccountByIdQuery(
    {
      entityBankAccountId: accountId,
      entityId,
      entityItemId: editBankId,
      groupId,
    },
    {
      skip: !entityId || !groupId || !editBankId || !accountId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { account } = bankAccount || {};

  const {
    account_number,
    opening_balance_date,
    account_type,
    opening_balance,
  } = account || {};

  const onSubmit = async (values: {
    account_number: string;
    account_type: string;
    opening_balance: number;
    opening_balance_date: string;
  }) => {
    const { opening_balance, ...rest } = values;
    try {
      await updateBankAccount({
        ...rest,
        entityBankAccountId: accountId,
        entityId,
        groupId,
        entityItemId: editBankId,
        opening_balance: opening_balance.toString(),
        opening_balance_date: dayjs(values.opening_balance_date).format(
          YYYY_MM_DD
        ),
      }).unwrap();
      successToast({ message: "Account updated" });
      dispatch(resetEditBankId());
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        initialValues={{
          account_number: account_number || "",
          opening_balance_date:
            dayjs(opening_balance_date).format(DD_MMM_YYYY) || "",
          account_type: account_type || "",
          opening_balance: opening_balance || 0,
        }}
        enableReinitialize
        validateOnChange
        validateOnMount
        validationSchema={editManualBankSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, submitForm, values: { account_type } }) => (
          <Form>
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <div className="t-w-full">
                  <Modal.Title>Edit bank account</Modal.Title>
                  <Modal.Subtitle>
                    {isFetching ? (
                      <span className="t-flex  t-animate-spin t-h-7 t-w-5 t-items-center t-justify-center">
                        <LoadingIcon />
                      </span>
                    ) : (
                      <>
                        {bankAccount?.account?.nickname}{" "}
                        {bankAccount?.account?.mask}{" "}
                      </>
                    )}
                  </Modal.Subtitle>
                </div>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-flex t-gap-4 t-flex-col">
                {isFetching ? (
                  <div className="t-w-full t-flex t-items-center t-justify-center t-h-full">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <TextInput
                      required
                      name="account_number"
                      label="Account number"
                    />
                    <Combobox
                      withForm
                      required
                      name="account_type"
                      label="Account type"
                      options={accountTypes}
                      menuPortalTarget={document.body}
                      value={
                        account_type
                          ? {
                              label: account_type,
                              value: account_type,
                            }
                          : null
                      }
                    />
                    <PriceInput
                      required
                      name="opening_balance"
                      label="Opening balance"
                    />
                    <Field name="opening_balance_date">
                      {({ field }: FieldProps) => {
                        return (
                          <DateInput
                            {...field}
                            required
                            name="opening_balance_date"
                            maxDate={new Date()}
                            label="Date of opening balance"
                            portalId="opening_balance_date"
                          />
                        );
                      }}
                    </Field>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-between t-items-center">
                {isCustomer && (
                  <div className="t-flex t-gap-1 t-w-full t-items-center">
                    Need help?
                    <Button type="button" customType="link" onClick={openChat}>
                      Chat with us.
                    </Button>
                  </div>
                )}
                <div className="t-flex t-gap-3 t-justify-end t-w-full">
                  <Button type="reset" onClick={close} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};

export const EditBankAccount = () => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isCustomer } = useRoleBasedView();
  const [accountId, setAccountId] = useState("");

  const {
    push,
    location: { search },
  } = useHistory();

  const { editBankId, isEditBankModalOpen } = useSelector(
    (store: RootState) => store.connections
  );

  const { data: accounts = [], isLoading } = useGetEntityItemBankAccountsQuery(
    {
      entityId,
      groupId,
      entityItemId: editBankId,
    },
    { skip: !entityId || !groupId || !editBankId }
  );

  const banksAccounts =
    accounts.map(({ account: { mask, nickname, uuid } }) => ({
      value: uuid,
      label: `${nickname} ${mask ? "- " + mask : ""}`,
    })) || [];

  const onClose = () => {
    dispatch(closeEditBankModal());
  };

  const openChat = () => {
    onClose();
    push(`/books/chat${search}`);
  };

  const { close, isOpen, open } = useModal();

  const onSubmit = async ({ bankAccount }: { bankAccount: string }) => {
    setAccountId(bankAccount);
    open();
    onClose();
  };

  useEffect(() => {
    if (banksAccounts.length === 1) {
      setAccountId(banksAccounts[0].value);
      open();
      onClose();
    }
  }, [banksAccounts.length, isEditBankModalOpen]);

  return (
    <>
      <Modal.Root open={isEditBankModalOpen} onOpenChange={onClose}>
        <Formik
          initialValues={{ bankAccount: "" }}
          enableReinitialize
          onSubmit={onSubmit}
          validateOnChange
          validateOnMount
          validationSchema={object({
            bankAccount: string().required("Please select bank account"),
          })}
        >
          {({ isSubmitting, submitForm, isValid }) => (
            <Form>
              <Modal.Content useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>
                    Edit Bank account -{" "}
                    {isLoading ? (
                      <span className="t-flex  t-animate-spin t-h-7 t-w-5 t-items-center t-justify-center">
                        <LoadingIcon />
                      </span>
                    ) : (
                      accounts[0]?.bank_brand.name
                    )}
                  </Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  {isLoading ? (
                    <div className="t-w-full t-flex t-items-center t-justify-center t-h-full">
                      <Loader />
                    </div>
                  ) : (
                    <Combobox
                      name="bankAccount"
                      options={banksAccounts}
                      label="Choose an account"
                      placeholder="Select or type"
                      menuPortalTarget={document.body}
                      required
                      withForm
                    />
                  )}
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-between t-items-center">
                  {isCustomer && (
                    <div className="t-flex t-gap-1 t-w-full t-items-center">
                      Need help?
                      <Button
                        type="button"
                        customType="link"
                        onClick={openChat}
                      >
                        Chat with us.
                      </Button>
                    </div>
                  )}
                  <div className="t-flex t-gap-3 t-justify-end t-w-full">
                    <Button
                      type="reset"
                      onClick={onClose}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      disabled={isSubmitting || !isValid}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          )}
        </Formik>
      </Modal.Root>
      <EditModal
        isOpen={isOpen}
        close={close}
        accountId={accountId}
        editBankId={editBankId}
        openChat={openChat}
      />
    </>
  );
};
