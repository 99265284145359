import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ChannelPendingActionsSliderState = {
  isActive: boolean;
  setSideBarToExpandOnClose: boolean;
};

const initialState: ChannelPendingActionsSliderState = {
  isActive: false,
  setSideBarToExpandOnClose: false,
};

export const channelPendingActions = createSlice({
  name: "channelPendingActions",
  initialState,
  reducers: {
    setChannelPendingActionsActive: (
      state,
      action: PayloadAction<{
        isActive: boolean;
        setSideBarToExpandOnClose?: boolean;
      }>
    ) => {
      state.isActive = action.payload.isActive;
      state.setSideBarToExpandOnClose =
        action.payload.setSideBarToExpandOnClose ??
        state.setSideBarToExpandOnClose;
    },
  },
});

export const { setChannelPendingActionsActive } = channelPendingActions.actions;

export default channelPendingActions.reducer;
