import { emptyApi } from "./emptyApi";
import qs from "qs";

type InternalTeamTaskList = {
  is_all_access_allowed: true;
  task_list: {
    task_title: string;
    uuid: string;
    has_task_access: boolean;
  }[];
};

export type AllInkleTeam = {
  name: string;
  uuid: string;
  country: null | string;
  requirements: null | string;
  domain: string;
};

export type InternalTeamResponse = AllInkleTeam & {
  task_access_count: number;
  is_all_access_allowed: boolean;
  logo: string | null;
};

export const internalTeamSettings = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getInternalTeams: build.query<InternalTeamResponse[], { groupId: string }>({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/serviceteams/group/${groupId}/service_teams/`,
        };
      },
      providesTags: ["INTERNAL_TEAM_SETTINGS"],
    }),
    addInternalTeam: build.mutation<
      InternalTeamResponse,
      {
        groupId: string;
        payload: {
          all_access_allowed?: boolean;
          task_id_list?: string[];
        };
        teamId: string;
      }
    >({
      query: ({ groupId, payload, teamId }) => {
        return {
          url: `/api/inkle/serviceteams/group/${groupId}/team/${teamId}/access/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["INTERNAL_TEAM_SETTINGS"],
    }),
    editInternalTeamAccess: build.mutation<
      InternalTeamResponse,
      {
        groupId: string;
        payload: {
          all_access_allowed?: boolean;
          task_id_list?: string[];
        };
        teamId: string;
      }
    >({
      query: ({ groupId, teamId, payload }) => {
        return {
          url: `/api/inkle/serviceteams/group/${groupId}/team/${teamId}/task/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["INTERNAL_TEAM_SETTINGS"],
    }),
    deleteInternalTeamAccess: build.mutation<
      InternalTeamResponse,
      {
        groupId: string;
        teamId: string;
      }
    >({
      query: ({ groupId, teamId }) => {
        return {
          url: `/api/inkle/serviceteams/group/${groupId}/team/${teamId}/access/`,
          method: "delete",
        };
      },
      invalidatesTags: ["INTERNAL_TEAM_SETTINGS"],
    }),
    getAllInkleCPATeams: build.query<
      AllInkleTeam[],
      { accessibleTeams?: string }
    >({
      query: ({ accessibleTeams }) => {
        let queryUrl = qs.stringify(
          {
            accessible_teams_string: accessibleTeams,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/serviceteams/all_service_teams/${queryUrl}`,
        };
      },
    }),
    getInternalTeamTaskAccessList: build.query<
      InternalTeamTaskList,
      { groupId: string; teamId: string; searchTerm?: string | null }
    >({
      query: ({ groupId, teamId, searchTerm }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/serviceteams/group/${groupId}/team/${teamId}/task/${queryUrl}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInternalTeamsQuery,
  useAddInternalTeamMutation,
  useEditInternalTeamAccessMutation,
  useDeleteInternalTeamAccessMutation,
  useGetAllInkleCPATeamsQuery,
  useGetInternalTeamTaskAccessListQuery,
} = internalTeamSettings;
