import { useToast } from "hooks/useToast";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import randomBytes from "randombytes";
import { useLazyGetConnectionAuthUrlQuery } from "store/apis/booksConnections";
import { BackendError } from "types/utils/error";

export const useBankConnect = () => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [getAuthUrl, { isLoading, originalArgs }] =
    useLazyGetConnectionAuthUrlQuery();

  const onConnect = async ({
    connectionId,
    entityIdInArg,
    invokedFrom,
  }: {
    connectionId: string;
    entityIdInArg?: string;
    invokedFrom: string;
  }) => {
    if (groupId && (entityId || entityIdInArg)) {
      try {
        const stateKey = randomBytes(16).toString("hex");
        localStorage.setItem("connection_oauth_state_key", stateKey);

        localStorage.setItem(
          stateKey,
          JSON.stringify({
            entityId: entityId || entityIdInArg,
            groupId,
            connectionId,
            invokedFrom,
          })
        );

        const { authorization_url } = await getAuthUrl({
          groupId,
          connectionId,
          state: stateKey,
        }).unwrap();
        window.location.href = authorization_url;
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  return { isLoading, originalArgs, onConnect };
};
