import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MessageForwardState = {
  isOpen: boolean;
  messageId?: string | null;
};

const initialState: MessageForwardState = {
  isOpen: false,
};

export const messageForward = createSlice({
  name: "messageForward",
  initialState,
  reducers: {
    setMessageToForward: (
      state,
      action: PayloadAction<{ messageId: string }>
    ) => {
      state.messageId = action.payload.messageId;
      state.isOpen = true;
    },

    closeMessageForward: (state) => {
      state.isOpen = false;
      state.messageId = null;
    },
  },
});

export const { setMessageToForward, closeMessageForward } =
  messageForward.actions;

export default messageForward.reducer;
