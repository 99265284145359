import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ONBOARDING_PRODUCTS } from "types/contants/onboardingProducts";

export interface OnboardingWidgetState {
  isOpen: boolean;
  product: ONBOARDING_PRODUCTS;
}

const initialState: OnboardingWidgetState = {
  isOpen: false,
  product: "Tax",
};

export const onboardingWidgetSlice = createSlice({
  name: "onboardingWidget",
  initialState,
  reducers: {
    openOnboardingWidget: (
      state,
      action: PayloadAction<ONBOARDING_PRODUCTS | undefined>
    ) => {
      state.isOpen = true;
      state.product = action.payload || state.product;
    },
    closeOnboardingWidget: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openOnboardingWidget, closeOnboardingWidget } =
  onboardingWidgetSlice.actions;

export default onboardingWidgetSlice.reducer;
