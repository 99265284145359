import { useGetPreviewUrlQuery } from "store/apis/previewUrl";

export const usePreviewUrl = ({
  fileId,
  groupId,
}: {
  groupId: string;
  fileId: string;
}) => {
  return useGetPreviewUrlQuery(
    {
      groupId: groupId,
      fileId,
    },
    { skip: !Boolean(fileId) || !groupId, pollingInterval: 300000 }
  );
};
