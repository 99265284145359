import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { ConditionalLink } from "components/conditionalLink";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { DeleteIcon } from "components/icons/delete";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { CellProps, Column, Row } from "react-table";
import {
  useDeleteBillingInvoiceMutation,
  useGetBillingInvoicesQuery,
} from "store/apis/billingInvoice";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { RootState } from "store/store";
import { BillingInvoice } from "types/Models/billing";
import { formatDate } from "utils/formatDate";
import { getFileDownloadUrlWithNewName } from "../../apis/documents";
import {
  FULL_REFUND,
  IN_PROGRESS,
  PAID,
  PARTIAL_REFUND,
} from "../../constants/billing";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/billing.css";
import { FileIcon } from "../../utils/fileTypeIcon";
import { openLink } from "../../utils/openLink";
import Loader from "../design/loader";
import GlobalFilter from "./billingFilter";
import { PayUnpaid } from "./payUnpaid";
import {
  PaymentStatus,
  ProductType,
  SelectedSeason,
} from "store/slices/billingFilters";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { BackendError } from "types/utils/error";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import Table from "components/DesignSystem/Table/V2/Table";
import { openBillingInvoiceSlider } from "store/slices/billingInvoiceSlider";
import { BillingInvoiceSlider } from "./BillingInvoiceSlider";
import ThreeDots from "static/images/ThreeDots.svg";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { INKLE_CREDITS, SourceTitleKeys } from "constants/invoiceSource";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { Tag } from "components/DesignSystem/Tag/Tag";
import Slider from "components/DesignSystem/Slider/Slider";
import { BillingSlider } from "components/BillingSlider/BillingSlider";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useMatchRoutes } from "hooks/useMatchRoutes";

const RefundTag = ({
  isPartialRefund,
  refundAmount,
  refundSource,
  refundDate,
}: {
  isPartialRefund: boolean;
  refundAmount: number;
  refundSource: SourceTitleKeys;
  refundDate: string;
}) => {
  const refundToBank = `A refund of $${refundAmount} was initiated on ${dayjs(
    refundDate
  ).format(DD_MMM_YYYY)}.`;
  const refundToCredit = `Added ${refundAmount} Inkle credits (1 credit = 1$)`;
  const tooltipText =
    refundSource === INKLE_CREDITS ? refundToCredit : refundToBank;

  return (
    <ConditionalToolTip condition={tooltipText}>
      <span>
        <Tag tagType="gray">
          {isPartialRefund ? "Partial Refund" : "Full Refund"}
        </Tag>
      </span>
    </ConditionalToolTip>
  );
};

const filteredFilters = (
  filterType?: SelectedSeason | PaymentStatus | ProductType | null
) => {
  return Object.entries(filterType || {})
    .map((ele) => {
      const name = ele?.[0];
      const value = ele?.[1];
      if (value) {
        if (name === "unpaid") {
          return "PENDING,IN_PROGRESS,WORK_IN_PROGRESS";
        }
        if (name === "fullrefund") {
          return "FULL_REFUND";
        }
        if (name === "partialrefund") {
          return "PARTIAL_REFUND";
        }
        return name.toUpperCase();
      }
    })
    .filter(Boolean)
    ?.join(",");
};

export const Billing = ({ crmId }: { crmId?: string }) => {
  usePageTitle("Billing");
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const { alertToast, successToast } = useToast();
  const { groupEntities } = useContext(authContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showUnpaidPaymentModal, setShowUnpaidPaymentModal] = useState(false);
  const [invoicesToPay, setInvoicesToPay] = useState<BillingInvoice[]>([]);
  const { isAdmin } = useRoleBasedView();
  const [multipleBill, setMultipleBill] = useState<Row<BillingInvoice>[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showInvoiceData, setShowInvoiceData] = useState(false);
  const dispatch = useDispatch();
  const { uuid } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const groupId = uuid || crmId;
  const [
    deleteInvoice,
    { isLoading: deletingInvoice, originalArgs: deletingArgs },
  ] = useDeleteBillingInvoiceMutation();
  const [deleteInvoiceId, setDeleteInvoiceId] = useState<string | null>();
  const [amount, setAmount] = useState<number>();
  const showBillingSlider = useSelector(
    (store: RootState) => store.billingInvoiceSlider.showBillingSlider
  );

  const {
    filters: { selectedSeason, paymentStatus, productType },
  } = useSelector((state: RootState) => state.billingFilters);

  const productTypes: string = filteredFilters(productType);
  const paymentStatuses: string = filteredFilters(paymentStatus);
  const selectedSeasons: string = filteredFilters(selectedSeason);

  const {
    data: {
      group_invoices: invoices = [],
      total_pages = 0,
      current_page = 0,
      per_page = 0,
      total_count = 0,
      due_amount: dueAmount = 0,
    } = {},
    isLoading,
  } = useGetBillingInvoicesQuery(
    {
      groupId: groupId!,
      pageNumber,
      searchTerm,
      selectedSeasons: selectedSeasons,
      productTypes: productTypes,
      paymentStatuses: paymentStatuses,
      entityId,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  useEffect(() => {
    if (current_page) {
      setPageNumber(current_page);
    }
  }, [current_page]);

  const unPaidInvoice = invoices?.filter(
    (invoice) =>
      !isNaN(Number(invoice.amount.replace("$", ""))) && invoice.status !== PAID
  );

  const openCheckoutModal = (invoice: BillingInvoice) => {
    dispatch(setUsableCredit(true));
    dispatch(setPaymentTitle("Billings payment"));
    setInvoicesToPay([invoice]);
    setShowPaymentModal(true);
  };

  const isInvoicePaid = (status: string) => {
    return [PAID, PARTIAL_REFUND, FULL_REFUND].includes(status);
  };

  const closeCheckoutModal = () => {
    setShowPaymentModal(false);
  };

  const openPreviewAndDownloadLink = async (
    uuid: string,
    documentFilename: string
  ) => {
    try {
      const response = await getFileDownloadUrlWithNewName({
        groupId,
        fileId: uuid,
        documentFilename,
      });
      openLink(response.data.data.preview_url);
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const confirmDelete = async () => {
    if (groupId && deleteInvoiceId && entityId) {
      try {
        setDeleteInvoiceId(null);
        await deleteInvoice({
          groupId,
          entityId,
          invoiceId: deleteInvoiceId,
        }).unwrap();
        successToast({ message: "Invoice deleted" });
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    }
  };
  const columnHelper = createColumnHelper<BillingInvoice>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Invoice Name",
        size: 20,
        cell: (info) => {
          const rowData = info.row.original;
          const refundDetails =
            Array.isArray(rowData.refund_data) && rowData.refund_data[0];
          return (
            <div className="t-flex t-items-center t-gap-2">
              <div>{info.getValue()}</div>
              {refundDetails && (
                <RefundTag
                  isPartialRefund={refundDetails.type === "PARTIAL"}
                  refundAmount={refundDetails.amount}
                  refundSource={refundDetails.destination}
                  refundDate={refundDetails.refund_created_date}
                />
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("uuid", {
        header: "Connected Filing",
        size: 35,
        cell: (info) => {
          const rowData = info.row.original;
          return (
            <span onClick={(e) => e.stopPropagation()}>
              {rowData?.task?.uuid ? (
                <ConditionalLink
                  to={`/filings/${rowData?.task?.uuid}`}
                  target="__blank"
                  className="t-text-purple hover:t-text-purple hover:!t-underline"
                >
                  {rowData?.short_name}
                </ConditionalLink>
              ) : (
                <>{rowData?.short_name}</>
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("created_at", {
        header: "Date of Creation",
        size: 14,
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor("document.uuid", {
        header: "Invoice",
        size: 8,
        cell: (info) => {
          const rowData = info.row.original;
          return (
            <span className="invoiceViewDownload">
              {rowData?.document ? (
                <span
                  className="invoiceIcon"
                  onClick={() =>
                    openPreviewAndDownloadLink(
                      rowData?.document?.uuid,
                      rowData.document_filename
                    )
                  }
                >
                  <FileIcon
                    fileType={rowData?.document?.file_type}
                    width="30px"
                    height="30px"
                  />
                </span>
              ) : (
                <>-</>
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("receipt_filename", {
        header: "Receipt",
        size: 8,
        cell: (info) => {
          const rowData = info.row.original;
          return (
            <span className="invoiceViewDownload">
              {Boolean(rowData?.receipt_document) ? (
                <span
                  className="invoiceIcon"
                  onClick={() =>
                    openPreviewAndDownloadLink(
                      rowData?.receipt_document?.uuid,
                      rowData.receipt_filename
                    )
                  }
                >
                  <FileIcon
                    fileType={rowData?.receipt_document?.file_type}
                    width="30px"
                    height="30px"
                  />
                </span>
              ) : (
                <>-</>
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("paid_on", {
        header: "Pay",
        size: 12,
        cell: (info) => {
          const invoice = info.row.original;
          const { status, amount, paid_on } = invoice;

          let tooltipText =
            isInvoicePaid(status) &&
            paid_on &&
            `Paid $${amount} on ${formatDate(paid_on)}`;

          if (Array.isArray(invoice?.refund_data)) {
            tooltipText = `Paid $${amount} on ${formatDate(
              paid_on
            )} with a refund of $${invoice.refund_data[0].amount}.`;
          }

          return (
            <ConditionalToolTip
              condition={
                Boolean(isInvoicePaid(status) && paid_on) && tooltipText
              }
            >
              <span>
                <Button
                  customType="danger"
                  size="small"
                  block
                  disabled={checkDisable({ amount, status })}
                  onClick={() => openCheckoutModal(invoice)}
                >
                  {isInvoicePaid(status) ? (
                    <span className="t-flex t-gap-1">
                      <span>Paid</span>{" "}
                      <AmountSuperScript amount={Number(amount)} />
                    </span>
                  ) : checkDisable({ amount, status }) ? (
                    "Pay"
                  ) : (
                    <span className="t-flex t-gap-1">
                      <span>Pay</span>{" "}
                      <AmountSuperScript amount={Number(amount)} />
                    </span>
                  )}
                </Button>
              </span>
            </ConditionalToolTip>
          );
        },
      }),
      columnHelper.display({
        header: " ",
        size: 4,
        cell: (info) => {
          const invoice = info.row.original;
          const isRefunded = invoice.refund_data;

          if (!isAdmin && !isRefunded) {
            return null;
          }

          return (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown.Root>
                <Dropdown.Trigger asChild>
                  <div>
                    <Button size="small" customType="ghost_icon">
                      <img
                        src={ThreeDots}
                        alt="Action"
                        className="t-select-none"
                      />
                    </Button>
                  </div>
                </Dropdown.Trigger>
                <Dropdown.Portal>
                  <Dropdown.Content
                    sideOffset={8}
                    side="bottom"
                    className="t-mr-16"
                  >
                    {isAdmin &&
                      !isRefunded &&
                      isInvoicePaid(invoice.status) && (
                        <Dropdown.Item
                          onSelect={() => openInvoiceSlider(invoice)}
                        >
                          Initiate Refund
                        </Dropdown.Item>
                      )}
                    {isRefunded && (
                      <Dropdown.Item
                        onSelect={() => openInvoiceSlider(invoice)}
                      >
                        View Refund
                      </Dropdown.Item>
                    )}
                    {isAdmin && (
                      <Dropdown.Item
                        disabled={isInvoicePaid(invoice.status)}
                        onSelect={() => setDeleteInvoiceId(invoice.uuid)}
                      >
                        Delete
                      </Dropdown.Item>
                    )}
                  </Dropdown.Content>
                </Dropdown.Portal>
              </Dropdown.Root>
            </div>
          );
        },
      }),
    ],
    [groupEntities, deletingInvoice, deletingArgs?.invoiceId]
  );

  const checkDisable = ({
    amount,
    status,
  }: {
    amount: string;
    status: string;
  }) => {
    return (
      isInvoicePaid(status) ||
      status === IN_PROGRESS ||
      isNaN(Number(amount.replace("$", "")))
    );
  };

  const data =
    useMemo(
      () =>
        invoices?.map((bill) => {
          if (isNaN(Number(bill.amount))) return bill;
          return { ...bill, amount: bill?.amount };
        }),
      [invoices]
    ) || [];

  const paySelectedBill = () => {
    dispatch(setUsableCredit(true));
    setShowPaymentModal(true);
  };

  const getMultipuleBill = (data: Row<BillingInvoice>[]) => {
    const updatedBills = data
      .filter(
        (ele) =>
          !isNaN(Number(ele.original.amount.replace("$", ""))) &&
          ele.original.status !== PAID
      )
      .map((r) => r.original);

    setInvoicesToPay(updatedBills);
    setMultipleBill(data);
  };

  const openUnpaidBillModal = (amount: number) => {
    setAmount(amount);
    setShowUnpaidPaymentModal(true);
    setShowInvoiceData(false);
  };

  const closeUnpaidBillModal = () => {
    setShowUnpaidPaymentModal(false);
  };

  const openInvoiceSlider = (invoice: BillingInvoice) => {
    dispatch(openBillingInvoiceSlider(invoice));
  };

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  const { link } = useConstructInternalLink();

  return (
    <div className="addTeamMemberContent billingComponent d-flex flex-column t-pb-16">
      <div className="t-flex t-flex-col">
        <GlobalFilter
          setSearchTerm={setSearchTerm}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
        <PayUnpaid
          PayUnpaid={openUnpaidBillModal}
          paySelectedBill={paySelectedBill}
          unPaidInvoice={unPaidInvoice}
          invoicesToPay={invoicesToPay}
          showInvoiceData={showInvoiceData}
          setShowInvoiceData={setShowInvoiceData}
          dueAmount={dueAmount}
          invoicesCount={total_count}
        />
        <div className="form">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {invoices?.length === 0 ? (
                <span className="noTaskFound">No invoices available</span>
              ) : (
                <>
                  {/* @ts-ignore */}
                  <TableUI table={table}>
                    {({ row }) => (
                      <Table.Row
                        onRowClick={() => {
                          history.push(
                            link(`/${row.original.uuid}`, {
                              keys: ["entity"],
                              includeUrl: true,
                            })
                          );
                        }}
                        key={row.original.uuid}
                        className="hover:t-bg-surface-lighter-grey"
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <Table.Cell
                              key={cell.id}
                              style={{
                                width: `${cell.column.getSize()}%`,
                                padding: "12px 8px",
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Table.Cell>
                          );
                        })}
                      </Table.Row>
                    )}
                  </TableUI>
                  {showBillingSlider && <BillingInvoiceSlider />}
                  {showPaymentModal && (
                    <CheckoutModal
                      entityId={entityId}
                      type="invoices"
                      open={showPaymentModal}
                      onClose={closeCheckoutModal}
                      invoices={invoicesToPay}
                      onInvoicePaid={closeCheckoutModal}
                    />
                  )}
                  {showUnpaidPaymentModal && (
                    <CheckoutModal
                      type="invoices"
                      entityId={entityId}
                      open={showUnpaidPaymentModal}
                      onClose={closeUnpaidBillModal}
                      invoices={unPaidInvoice}
                      onInvoicePaid={closeUnpaidBillModal}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Switch>
        <Route exact path={`${path}/:invoiceId`}>
          <Slider.Root
            open
            onOpenChange={() => history.push(`${url}${search}`)}
          >
            <Slider.Content open>
              <BillingSlider openCheckoutModal={openCheckoutModal} />
            </Slider.Content>
          </Slider.Root>
        </Route>
      </Switch>

      <Modal.Root
        open={Boolean(deleteInvoiceId)}
        onOpenChange={() => setDeleteInvoiceId(null)}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Delete Invoice?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="">
              Are you sure you want to delete this invoice? This action can not
              be undone.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={() => setDeleteInvoiceId(null)}>Cancel</Button>
              <Button onClick={confirmDelete} customType="danger">
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
