import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type BooksOnboarding = {
  bookkeepingSoftware: string;
};

const initialState: BooksOnboarding = {
  bookkeepingSoftware: "",
};

export const booksOnboarding = createSlice({
  name: "booksOnboarding",
  initialState,
  reducers: {
    setBookkeepingSoftware: (state, action: PayloadAction<string>) => {
      if (action.payload === state.bookkeepingSoftware) {
        state.bookkeepingSoftware = "";
      } else {
        state.bookkeepingSoftware = action.payload;
      }
    },
  },
});

export const { setBookkeepingSoftware } = booksOnboarding.actions;

export default booksOnboarding.reducer;
