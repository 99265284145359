import { array, object, string } from "yup";

export const splitTransactionsSchema = () =>
  object({
    transactions: array().of(
      object({
        description: string().required("Description is required"),
        amount: string()
          .required("Amount is required")
          .test(
            "is-nonzero",
            "Amount must be greater than zero",
            (value) => parseFloat(value || "0") !== 0
          ),
        category: string().required("Category is required"),
      })
    ),
  });
