import classNames from "classnames";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { COPIED_TEXT, FAILED_COPIED_TEXT } from "../../constants/documents";

const CopyCode = ({
  defaultText = "",
  getCopyText = () => {},
  codeCopiedText = "Code Copied!",
  copyIcon,
  addClassName = "",
  alert = true,
  reverse = false,
}) => {
  const [copied, setCopied] = useState(false);
  const { alertToast, successToast } = useToast();

  const copyToClipBoard = async () => {
    try {
      if (!copied) {
        await navigator.clipboard.writeText(getCopyText());
        setCopied(true);
        alert && successToast({ message: COPIED_TEXT });
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
  };

  return (
    <button
      className={classNames("redeemBtn p-0", {
        disableRedeemBtn: copied,
        [addClassName]: true,
      })}
      disabled={copied}
      type="button"
      onClick={copyToClipBoard}
    >
      {copied ? (
        <>{codeCopiedText}</>
      ) : (
        <span className="t-flex t-items-center t-gap-2">
          <span
            className={classNames({
              "t-order-2": reverse,
              "t-order-1": !reverse,
            })}
          >
            {defaultText || getCopyText()}
          </span>
          <img
            src={copyIcon}
            alt="copy icon"
            className={classNames("copyIcon", {
              "t-order-2": !reverse,
              "t-order-1": reverse,
            })}
          />
        </span>
      )}
    </button>
  );
};

export default CopyCode;

// import { render, screen } from "@testing-library/react";
// import userEvent from "@testing-library/user-event";
// import CopyCode from "components/design/copyCode";

// test("Don't show component when no data", () => {
//   render(<CopyCode codeCopiedText="code Copied" />);
//   const copyButton = screen.getByRole("button");
//   expect(copyButton).toHaveClass("redeemBtn");
//   userEvent.click(copyButton);
// });
