import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = { openSlider: false, enableEdit: false };

export const raiseCrm = createSlice({
  name: "raiseCrm",
  initialState,
  reducers: {
    setOpenSlider: (state, action: PayloadAction<boolean>) => {
      state.openSlider = action.payload;
    },
    setEditable: (state, action: PayloadAction<boolean>) => {
      state.enableEdit = action.payload;
    },
    createProfile: (state) => {
      state.enableEdit = true;
      state.openSlider = true;
    },
  },
});

export const { setEditable, setOpenSlider, createProfile } = raiseCrm.actions;

export default raiseCrm.reducer;
