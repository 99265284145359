import { LoadingToast } from "components/design/LoadingToast";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { csvColumnMappingSchema } from "formValidations/csvColumnMappingSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { usePopulateCSVTransactionsMutation } from "store/apis/transactions";
import { CSVParsedResponse, CSVResponse } from "types/Models/reconciliation";
import { BackendError } from "types/utils/error";

export const CSVColumnMapping = ({
  close,
  uploadedCsvData,
  setCurrentStep,
  setCsvTransactions,
}: {
  close: () => void;
  uploadedCsvData?: CSVParsedResponse;
  setCurrentStep: (newState: React.SetStateAction<number>) => void;
  setCsvTransactions: (
    newState: React.SetStateAction<CSVResponse[] | undefined>
  ) => void;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [populateCSVTransactions, { isLoading }] =
    usePopulateCSVTransactionsMutation();

  const { labels_in_csv, csv, entity_transaction_labels } =
    uploadedCsvData || {};

  const options = labels_in_csv?.map((value) => ({
    label: value,
    value: value,
  }));

  const { bank_account } = csv || {};
  const { account } = bank_account || {};
  const { mask, nickname } = account || {};
  const source = `${nickname} •••• ${mask}`;

  const isSVBBank = entity_transaction_labels?.some(
    (value) =>
      value.toLowerCase().includes("credit") ||
      value.toLowerCase().includes("debit")
  )!;

  const onSubmit = async (values: {
    date_field: string;
    description_field: string;
    merchant_field: string;
    amount_field: string;
    credit_field: string;
    debit_field: string;
    memo_field: string;
    bank_csv_id: string;
    isSVBBank: boolean;
    source: string;
  }) => {
    const { isSVBBank, source, ...restPayload } = values;
    try {
      const csvTransactions = await populateCSVTransactions({
        entityId,
        groupId,
        ...restPayload,
      }).unwrap();
      setCsvTransactions(csvTransactions);
      successToast({ message: "CSV parsed!" });
      setCurrentStep(3);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          date_field: "",
          description_field: "",
          merchant_field: "",
          amount_field: "",
          credit_field: "",
          debit_field: "",
          memo_field: "",
          bank_csv_id: csv?.uuid!,
          isSVBBank,
          source,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={csvColumnMappingSchema}
        validateOnChange
      >
        {({ submitForm, values: { isSVBBank }, isSubmitting }) => (
          <Form className="t-m-0 t-w-full">
            <Modal.Body className="t-flex t-gap-5 t-flex-col t-w-full">
              <div className="t-flex t-gap-6">
                <Combobox
                  label="Date"
                  required
                  name="date_field"
                  withForm
                  placeholder="Select property"
                  menuPortalTarget={document.getElementById("root")}
                  options={options}
                  block
                />
                <Combobox
                  required
                  label="Description"
                  menuPortalTarget={document.getElementById("root")}
                  name="description_field"
                  withForm
                  placeholder="Select property"
                  options={options}
                  block
                />
              </div>
              {isSVBBank && (
                <div className="t-flex t-gap-6">
                  <Combobox
                    required
                    label="Credit"
                    menuPortalTarget={document.getElementById("root")}
                    name="credit_field"
                    withForm
                    placeholder="Select property"
                    options={options}
                    block
                  />
                  <Combobox
                    required
                    label="Debit"
                    menuPortalTarget={document.getElementById("root")}
                    name="debit_field"
                    withForm
                    placeholder="Select property"
                    options={options}
                    block
                  />
                </div>
              )}
              <div className="t-flex t-gap-6">
                {!isSVBBank && (
                  <Combobox
                    required
                    label="Amount"
                    menuPortalTarget={document.getElementById("root")}
                    name="amount_field"
                    withForm
                    placeholder="Select property"
                    options={options}
                    block
                  />
                )}
                <Combobox
                  label="Vendor"
                  menuPortalTarget={document.getElementById("root")}
                  name="merchant_field"
                  withForm
                  placeholder="Select property"
                  options={options}
                  block
                />
              </div>
              <div className="t-flex t-gap-6">
                <Combobox
                  label="Memo"
                  menuPortalTarget={document.getElementById("root")}
                  name="memo_field"
                  withForm
                  placeholder="Select property"
                  options={options}
                  block
                />
                <TextInput label="Source" disabled name="source" />
              </div>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button onClick={close} type="reset" disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                customType="primary"
                type="submit"
                onClick={submitForm}
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Next
              </Button>
            </Modal.FooterButtonGroup>
          </Form>
        )}
      </Formik>

      <LoadingToast loading={isLoading} title="Please wait">
        Populating transactions...
      </LoadingToast>
    </>
  );
};
