import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Trash } from "components/icons/Trash";

export const InvitePracticeFields = ({
  domain,
  userNumber,
  remove,
}: {
  domain: string;
  userNumber: number;
  remove: (index: number) => void;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-4">
      {userNumber !== 0 && (
        <div className="t-flex t-items-center t-justify-between t-mt-2 -t-mb-2">
          <div className="t-text-subtitle t-text-text-100">Team Member</div>
          <Button
            customType="ghost_icon"
            size="small"
            onClick={() => remove(userNumber)}
            data-testid="add-another-practice-button"
          >
            <Trash />
          </Button>
        </div>
      )}
      <div className="t-flex t-gap-2.5">
        <TextInput
          label="First Name"
          name={`invite_users.${userNumber}.first_name`}
          placeholder="John"
          required
        />
        <TextInput
          label="Last Name"
          name={`invite_users.${userNumber}.last_name`}
          placeholder="Doe"
          required
        />
      </div>
      <TextInput
        label="Email"
        name={`invite_users.${userNumber}.email`}
        placeholder={`example@${domain}`}
        required
      />
    </div>
  );
};
