import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { REJECT } from "constants/groupRequest";
import { useToast } from "hooks/useToast";
import { useGroupJoinActionMutation } from "store/apis/teamSetting";

type RejectRequestProps = {
  show: boolean;
  closeModal: () => void;
  groupId: string;
  requestId: string;
};

export const RejectRequest = ({
  show,
  closeModal,
  groupId,
  requestId,
}: RejectRequestProps) => {
  const { alertToast, successToast } = useToast();
  const [handleGroupJoinRequest, { isLoading }] = useGroupJoinActionMutation();

  const rejectRequest = async () => {
    try {
      await handleGroupJoinRequest({
        type: REJECT,
        requestId: requestId,
        payload: {},
        groupId: groupId,
      }).unwrap();
      successToast({
        message: "Request rejected successfully",
        title: "Group user",
      });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Reject request</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-100">
              Are you sure you want to reject the request?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={isLoading}
                customType="danger"
                isLoading={isLoading}
                onClick={rejectRequest}
              >
                Reject
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
