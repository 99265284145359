import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  StreamChat,
  ChannelFilters,
  ChannelSort,
  MessageResponse,
  MessageFilters,
  SearchOptions,
} from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const usePaginatedMessages = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  client: StreamChat<StreamChatGenerics>,
  filters: ChannelFilters<StreamChatGenerics>,
  sort: ChannelSort<StreamChatGenerics>,
  options: SearchOptions<StreamChatGenerics>,
  search?: string | MessageFilters<StreamChatGenerics>,
  skip?: Boolean
) => {
  const {
    channelsQueryState: { setError },
  } = useChatContext();
  const [messages, setMessages] = useState<
    Array<{
      message: MessageResponse<StreamChatGenerics>;
    }>
  >([]);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState<string>();
  const showMentionedMessages = useSelector(
    (state: RootState) => state.chatSearch.showMentionedMessages
  );

  // memoize props
  const filterString = useMemo(() => JSON.stringify(filters), [filters]);
  const sortString = useMemo(() => JSON.stringify(sort), [sort]);
  const searchWithMentionFilter = search ? { text: { $in: search } } : {};
  const searchValue = showMentionedMessages
    ? ({
        "mentioned_users.id": {
          $contains: client?.user?.id,
        },
        ...searchWithMentionFilter,
      } as unknown as MessageFilters<StreamChatGenerics>)
    : search;

  let searchString: string = "";

  if (typeof searchValue === "string") {
    searchString = searchValue;
  }

  if (typeof searchValue == "object") {
    searchString = JSON.stringify(searchValue);
  }

  const queryMessages = useCallback(
    async (queryType?: string) => {
      setError(null);

      if (!next) {
        setLoading(true);
      }

      const newOptions = {
        limit: options?.limit ?? 10,
        next,
        ...options,
      };

      try {
        if (searchValue) {
          const messageQueryResponse = await client.search(
            filters,
            searchValue,
            { ...newOptions, sort: { updated_at: -1 } }
          );

          setMessages((messages) =>
            queryType === "reload"
              ? messageQueryResponse.results
              : [...messages, ...messageQueryResponse.results]
          );
          setNext(messageQueryResponse.next);
        }
      } catch (err) {
        console.warn(err);
        setError(err as Error);
      }

      setLoading(false);
    },
    [filterString, sortString, searchString, next]
  );

  const loadNextPage = () => {
    queryMessages();
  };

  useEffect(() => {
    if (skip) {
      return;
    }
    queryMessages("reload");
  }, [filterString, sortString, searchString]);

  const clear = () => {
    setMessages([]);
  };

  return {
    messages,
    clear,
    next,
    loadNextPage,
    setMessages,
    loading,
  };
};
