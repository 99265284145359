export const InklePractice = () => (
  <svg
    width="209"
    height="56"
    viewBox="0 0 209 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5723_7817)">
      <mask
        id="mask0_5723_7817"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="18"
        width="20"
        height="20"
      >
        <path d="M36 18H16V38H36V18Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5723_7817)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26 21.3645C25.2774 21.3645 24.6916 21.9503 24.6916 22.6729H21.3271C21.3271 20.0921 23.4192 18 26 18C28.5808 18 30.6729 20.0921 30.6729 22.6729C30.6729 25.2537 28.5808 27.3458 26 27.3458V23.9813C26.7226 23.9813 27.3084 23.3955 27.3084 22.6729C27.3084 21.9503 26.7226 21.3645 26 21.3645ZM32.6355 28C32.6355 27.2774 32.0497 26.6916 31.3271 26.6916V23.3271C33.9079 23.3271 36 25.4192 36 28C36 30.5808 33.9079 32.6729 31.3271 32.6729C28.7463 32.6729 26.6542 30.5808 26.6542 28H30.0187C30.0187 28.7226 30.6045 29.3084 31.3271 29.3084C32.0497 29.3084 32.6355 28.7226 32.6355 28ZM20.6729 29.3084C19.9503 29.3084 19.3645 28.7226 19.3645 28C19.3645 27.2774 19.9503 26.6916 20.6729 26.6916C21.3955 26.6916 21.9813 27.2774 21.9813 28H25.3458C25.3458 25.4192 23.2537 23.3271 20.6729 23.3271C18.0921 23.3271 16 25.4192 16 28C16 30.5808 18.0921 32.6729 20.6729 32.6729V29.3084ZM26 34.6355C26.7226 34.6355 27.3084 34.0497 27.3084 33.3271H30.6729C30.6729 35.9079 28.5808 38 26 38C23.4192 38 21.3271 35.9079 21.3271 33.3271C21.3271 30.7463 23.4192 28.6542 26 28.6542V32.0187C25.2774 32.0187 24.6916 32.6045 24.6916 33.3271C24.6916 34.0497 25.2774 34.6355 26 34.6355Z"
          fill="url(#paint0_linear_5723_7817)"
        />
      </g>
    </g>
    <path
      d="M46 19.9835C46 18.78 46.78 18 48.0058 18C49.2092 18 49.9893 18.78 49.9893 19.9835C49.9893 21.187 49.2092 21.9893 48.0058 21.9893C46.78 21.9893 46 21.187 46 19.9835ZM49.7887 37.0995H46.2229V23.7276H49.7887V37.0995Z"
      fill="url(#paint1_linear_5723_7817)"
    />
    <path
      d="M60.7289 30.3912C60.7289 28.43 59.7037 27.1374 58.1214 27.1374C56.6282 27.1374 55.5362 28.5192 55.5362 30.4135V37.0995H51.9704V23.7276H55.5362V25.2877C56.0042 24.2848 57.386 23.5716 58.7677 23.5716C62.1775 23.5716 64.2947 26.2905 64.2947 30.3912V37.0995H60.7289V30.3912Z"
      fill="url(#paint2_linear_5723_7817)"
    />
    <path
      d="M70.0119 37.0995H66.4461V18.156H70.0119V29.7227L74.536 23.7276H78.4362L73.8897 30.4135L78.9042 37.0995H74.38L70.0119 31.1044V37.0995Z"
      fill="url(#paint3_linear_5723_7817)"
    />
    <path
      d="M83.8049 37.0995H80.2391V18.156H83.8049V37.0995Z"
      fill="url(#paint4_linear_5723_7817)"
    />
    <path
      d="M94.6461 32.9988H98.4348C97.5211 35.4948 95.1364 37.2109 92.4175 37.2109C88.5174 37.2109 85.7761 34.3805 85.7761 30.369C85.7761 26.4911 88.6065 23.5493 92.3506 23.5493C96.0948 23.5493 98.9251 26.4911 98.9251 30.369C98.9251 30.7924 98.9028 31.2381 98.836 31.6393H89.5203C89.9214 32.9988 91.0135 33.8679 92.3952 33.8679C93.4427 33.8679 94.2673 33.5113 94.6461 32.9988ZM89.5203 29.0763H95.181C94.7799 27.7614 93.7324 26.9146 92.3506 26.9146C90.9689 26.9146 89.9214 27.7614 89.5203 29.0763Z"
      fill="url(#paint5_linear_5723_7817)"
    />
    <path
      d="M189.459 33.8729H191.803C190.96 36.1754 188.802 37.7584 186.335 37.7584C182.799 37.7584 180.311 35.1475 180.311 31.447C180.311 27.8699 182.881 25.1562 186.273 25.1562C189.644 25.1562 192.255 27.8494 192.255 31.3443C192.255 31.6526 192.235 31.961 192.173 32.2488H182.572C182.881 34.3046 184.34 35.682 186.314 35.682C187.753 35.682 188.904 34.9419 189.459 33.8729ZM182.593 30.6247H189.994C189.686 28.6511 188.205 27.2532 186.273 27.2532C184.361 27.2532 182.901 28.6306 182.593 30.6247Z"
      fill="#41395C"
    />
    <path
      d="M172.78 27.356C170.539 27.356 169.018 28.9801 169.018 31.447C169.018 33.914 170.539 35.5381 172.78 35.5381C174.466 35.5381 175.761 34.613 176.316 32.9889H178.639C178.002 35.9698 175.802 37.7584 172.78 37.7584C169.224 37.7584 166.798 35.2503 166.798 31.447C166.798 27.6643 169.224 25.1562 172.78 25.1562C175.782 25.1562 178.002 26.9448 178.639 29.9052H176.316C175.741 28.2811 174.466 27.356 172.78 27.356Z"
      fill="#41395C"
    />
    <path
      d="M162.409 21.6214C162.409 20.8402 162.923 20.3057 163.745 20.3057C164.526 20.3057 165.04 20.8402 165.04 21.6214C165.04 22.4232 164.526 22.9577 163.745 22.9577C162.923 22.9577 162.409 22.4232 162.409 21.6214ZM164.855 37.6567H162.635V25.3219H164.855V37.6567Z"
      fill="#41395C"
    />
    <path
      d="M156.242 22.71H157.908V25.3208H160.806V27.4178H157.908V33.3796C157.908 34.8393 158.504 35.4766 159.902 35.4766H160.806V37.6557H159.696C156.88 37.6557 155.687 36.4634 155.687 33.503V27.4178H153.775V25.3208H155.687L156.242 22.71Z"
      fill="#41395C"
    />
    <path
      d="M146.255 27.356C144.014 27.356 142.493 28.9801 142.493 31.447C142.493 33.914 144.014 35.5381 146.255 35.5381C147.941 35.5381 149.236 34.613 149.791 32.9889H152.114C151.477 35.9698 149.277 37.7584 146.255 37.7584C142.698 37.7584 140.272 35.2503 140.272 31.447C140.272 27.6643 142.698 25.1562 146.255 25.1562C149.256 25.1562 151.477 26.9448 152.114 29.9052H149.791C149.215 28.2811 147.941 27.356 146.255 27.356Z"
      fill="#41395C"
    />
    <path
      d="M135.97 37.6565V35.354C135.415 36.7931 133.647 37.7799 131.797 37.7799C128.261 37.7799 125.917 35.2718 125.917 31.4685C125.917 27.6858 128.241 25.1777 131.797 25.1777C133.627 25.1777 135.415 26.1645 135.97 27.6242V25.3216H138.17V37.6565H135.97ZM128.138 31.4685C128.138 33.9355 129.741 35.5596 132.064 35.5596C134.367 35.5596 135.97 33.9355 135.97 31.4891C135.97 29.0632 134.326 27.3775 132.044 27.3775C129.721 27.3775 128.138 29.0015 128.138 31.4685Z"
      fill="#41395C"
    />
    <path
      d="M124.866 25.1777V27.3775C121.823 27.3569 120.076 28.9399 120.076 31.7152V37.6565H117.876V25.3216H120.076V27.8503C121.062 25.7534 123.159 25.1777 124.866 25.1777Z"
      fill="#41395C"
    />
    <path
      d="M110.583 31.3242H106.554V37.6561H104.333V20.1816H110.398C113.955 20.1816 116.36 22.2786 116.36 25.7118C116.36 29.0628 113.996 31.3242 110.583 31.3242ZM110.398 22.3814H106.554V29.1039H110.583C112.639 29.1039 114.14 27.7059 114.14 25.7118C114.14 23.7176 112.618 22.3814 110.398 22.3814Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7817"
        x1="16"
        y1="28"
        x2="36"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7817"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7817"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7817"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5723_7817"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5723_7817"
        x1="46"
        y1="27.6054"
        x2="98.9251"
        y2="27.6054"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <clipPath id="clip0_5723_7817">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(16 18)"
        />
      </clipPath>
    </defs>
  </svg>
);
