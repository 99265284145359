import { CANT_EDIT_BECAUSE_TASK_ARCHIVED } from "constants/task";
export const Message = ({ current, data, uuid, isArchived }) => {
  const indexing = new Map();
  const stateAccess = new Map();
  data?.forEach((element, index) => {
    indexing.set(element.uuid, index);
    stateAccess.set(
      element.uuid,
      element.is_cpa_state && element.is_customer_state
    );
  });

  if (isArchived) {
    return CANT_EDIT_BECAUSE_TASK_ARCHIVED;
  } else if (
    indexing?.get(current) < indexing?.get(uuid) &&
    !stateAccess.get(uuid)
  ) {
    return "The filing is being filed by our team. Please reach out to us in filing chat for any changes!";
  } else if (indexing?.get(current) > indexing?.get(uuid)) {
    return "Please complete the previous steps to continue!";
  } else if (indexing?.get(current) < indexing?.get(uuid)) {
    return "Please use the edit button to start editing this step!";
  } else {
    return false;
  }
};
