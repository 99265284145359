import { Button } from "components/DesignSystem/Button/Button";
import { CallFromChatIcon } from "components/icons/Chat/CallFromChatIcon";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useMakePhoneCallFromCRMMutation } from "store/apis/chat";
import { useChannelStateContext } from "stream-chat-react";
import { BackendError } from "types/utils/error";
import { CallNotesModal } from "./CallNotesModal";

export const CallNotes = ({
  mobile,
  groupId,
  close,
}: {
  mobile: string;
  groupId: string;
  close: () => void;
}) => {
  const noteModal = useModal();
  const { alertToast } = useToast();
  const { channel } = useChannelStateContext();
  const channelId = channel.id || "";

  const [makeCall, { isLoading }] = useMakePhoneCallFromCRMMutation();

  const callUser = async () => {
    try {
      await makeCall({ groupUserId: groupId, mobile, channelId });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <div>
      <Button
        size="small"
        customType="icon"
        onClick={callUser}
        isLoading={isLoading}
        disabled={isLoading}
      >
        <CallFromChatIcon />
      </Button>
      <CallNotesModal isOpen={noteModal.isOpen} close={noteModal.close} />
    </div>
  );
};
