export const InkleTaxCreditSymbol = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7324 7.59666C12.7324 10.3937 10.4649 12.6611 7.66793 12.6611C4.87092 12.6611 2.6035 10.3937 2.6035 7.59666C2.6035 4.79965 4.87092 2.53223 7.66793 2.53223C10.4649 2.53223 12.7324 4.79965 12.7324 7.59666Z"
      fill="url(#paint0_linear_2_2133)"
    />
    <path
      d="M22.8612 7.59666C22.8612 10.3937 20.5938 12.6611 17.7968 12.6611C14.9998 12.6611 12.7323 10.3937 12.7323 7.59666C12.7323 4.79965 14.9998 2.53223 17.7968 2.53223C20.5938 2.53223 22.8612 4.79965 22.8612 7.59666Z"
      fill="url(#paint1_linear_2_2133)"
    />
    <path
      d="M22.8612 17.7255C22.8612 20.5225 20.5938 22.7899 17.7968 22.7899C14.9998 22.7899 12.7323 20.5225 12.7323 17.7255C12.7323 14.9285 14.9998 12.6611 17.7968 12.6611C20.5938 12.6611 22.8612 14.9285 22.8612 17.7255Z"
      fill="url(#paint2_linear_2_2133)"
    />
    <path
      d="M12.7323 17.7255C12.7323 20.5225 10.4649 22.7899 7.66791 22.7899C4.87091 22.7899 2.60349 20.5225 2.60349 17.7255C2.60349 14.9285 4.87091 12.6611 7.66791 12.6611C10.4649 12.6611 12.7323 14.9285 12.7323 17.7255Z"
      fill="url(#paint3_linear_2_2133)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_2133"
        x1="2.6035"
        y1="7.59666"
        x2="12.7324"
        y2="7.59666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_2133"
        x1="12.7323"
        y1="7.59666"
        x2="22.8612"
        y2="7.59666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_2133"
        x1="12.7323"
        y1="17.7255"
        x2="22.8612"
        y2="17.7255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_2133"
        x1="2.60349"
        y1="17.7255"
        x2="12.7323"
        y2="17.7255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
