import { axiosInstance } from "./apiInstance";

export const getInvoices = async ({
  invoices,
  groupId,
  entityId,
}: {
  invoices: string[];
  groupId: string;
  entityId: string;
}) => {
  return await axiosInstance.get(
    `/api/inkle/payments/${groupId}/entity/${entityId}`,
    {
      params: {
        invoices: invoices.join(","),
      },
    }
  );
};
