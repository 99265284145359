import NoContractorImg from "static/images/NoContractor.svg";

export const NoContractor = () => {
  return (
    <div className="t-w-full t-h-full t-flex t-justify-center t-items-center">
      <div className="t-w-2/3 t-text-center">
        <img src={NoContractorImg} alt="NoContractor" />
        <div className="t-text-subtitle-sm t-text-text-100 t-mt-5">
          We couldn’t find any contractors
        </div>
        <div className="t-text-body t-text-text-30 t-mt-1">
          You don’t require any Form 1099 filing
        </div>
      </div>
    </div>
  );
};
