import { Divider } from "@mui/material";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { Price } from "components/Price";
import { BOOKKEEPING_SOFTWARE_MANAGE } from "constants/addons";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import { resetAllAddonDataPayload, setAddonType } from "store/slices/addons";
import { RootState } from "store/store";

export const BooksSoftwareManage = () => {
  const dispatch = useDispatch();
  const { addonType, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonType === BOOKKEEPING_SOFTWARE_MANAGE
  );
  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <>
      <Modal.Root
        open={showAddonModal}
        onOpenChange={() => {
          setShowAddonModal(false);
          dispatch(setAddonType(""));
          dispatch(resetAllAddonDataPayload());
        }}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Manage Plan</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-p-5 t-border-solid t-border t-border-neutral-0 t-rounded-lg">
              <div className="t-mb-5">
                <div className="t-text-subtitle">US Accounting Software</div>
                <Price
                  amount={addonData?.group_subscription?.[0]?.amount}
                  size="medium"
                />
              </div>
              <Divider />
              <div className="t-mt-5">
                <div className="t-flex t-flex-col t-gap-1.5">
                  <div className="t-text-body-sm t-text-neutral-20">
                    Current software
                  </div>
                  <div className="t-flex t-justify-between t-rounded t-border t-border-solid t-border-neutral-0 t-bg-surface-lighter-grey t-text-body t-text-neutral-30 t-px-3 t-py-2">
                    <div>
                      {addonData?.group_subscription?.[0]?.subscriptionName}
                    </div>
                    <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                      <ArrowRight stroke="2.5" color="#B8B5C2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-between">
            <Button
              customType="ghost"
              onClick={() => {
                setShowAddonModal(false);
                setShowCancelModal(true);
              }}
            >
              Cancel subscription
            </Button>
            <ConditionalLink to={"/chat"}>
              <Button customType="primary">Contact us</Button>
            </ConditionalLink>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <BooksSoftwareCancel
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
      />
    </>
  );
};

export const BooksSoftwareCancel = ({
  showCancelModal,
  setShowCancelModal,
}: {
  showCancelModal: boolean;
  setShowCancelModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const entityId = useCurrentEntityId();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        groupId,
        entityId,
        subscriptionId: addonData?.group_subscription?.[0]?.groupSubscriptionId,
      }).unwrap();
      setShowCancelModal(false);
      dispatch(setAddonType(""));
      successToast({ message: "Subscription cancelled successfully" });
    } catch (error: any) {
      setShowCancelModal(false);
      dispatch(setAddonType(""));
      alertToast({ message: error?.message || SOMETHING_WENT_WRONG });
    }
  };
  return (
    <Modal.Root
      open={showCancelModal}
      onOpenChange={() => {
        dispatch(setAddonType(""));
        setShowCancelModal(false);
      }}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel subscription</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            Are you sure you want to cancel this subscription? You won’t be able
            to access the accounting software anymore.
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowCancelModal(false);
              dispatch(setAddonType(""));
            }}
          >
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={handleCancelSubscription}
            isLoading={isLoading}
          >
            Cancel subscription
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
