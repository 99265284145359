import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { Link } from "components/DesignSystem/Link/Link";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import { DocumentPreviewModal } from "components/PreviewModal";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useDocPreview } from "hooks/useDocPreview";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { CreditLedger, useGetCreditsQuery } from "store/apis/refrral";
import { ArrayElement } from "types/utils/ArrayElement";
import { FileIcon } from "utils/fileTypeIcon";
import { formatDate } from "utils/formatDate";

export const CreditsTable = () => {
  const { update } = useUpdateQuery();
  const group = useCurrentGroup();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;

  const {
    data: creditHistoryData,
    isLoading,
    isSuccess,
    // refetch,
  } = useGetCreditsQuery(
    { groupId: group.uuid!, pageNum: page },
    { skip: !group.uuid }
  );

  const openPreview = useDocPreview();

  const {
    company_group_credits_ledger = [],
    current_page,
    per_page,
    total_count,
    total_pages,
  } = creditHistoryData || {};

  const createColumn =
    createColumnHelper<
      ArrayElement<CreditLedger["company_group_credits_ledger"]>
    >();

  const columns = [
    createColumn.accessor("created_date", {
      cell: (info) => formatDate(info.getValue()),
      header: "Date",
    }),

    createColumn.accessor("event_type", {
      cell: (info) => {
        const tasks = info.row.original.tasks;
        const taskTitles = tasks?.map(({ task_title }) => task_title).join(",");
        const taskId = tasks?.[0]?.task_id;
        const creditEvent = info.getValue();
        const isTaskEvent = creditEvent === "TASK";

        if (isTaskEvent && tasks?.length === 1) {
          return (
            <Link to={`/filings/${taskId}`}>
              <div className="t-text-purple t-text-body">{taskTitles}</div>
            </Link>
          );
        }

        if (isTaskEvent && tasks && tasks?.length > 1) {
          return (
            <ToolTip text={taskTitles as string}>
              <div className="t-text-body t-text-text-60 t-truncate t-w-4/5">
                {taskTitles}
              </div>
            </ToolTip>
          );
        }

        const reason =
          creditEvent.charAt(0) + creditEvent.substring(1).toLowerCase();
        return (
          <div className="t-text-body t-text-text-60">
            {info.row.original.description || reason}
          </div>
        );
      },

      header: "Description",
    }),

    createColumn.accessor("receipt.receipt_document", {
      header: "Receipt",
      cell: (info) => {
        const receipt = info.getValue();
        return (
          <span>
            {Boolean(receipt) ? (
              <Button
                customType="transparent"
                onClick={() => openPreview(receipt.uuid)}
              >
                <FileIcon
                  fileType={receipt.file_type}
                  width="30px"
                  height="30px"
                />
              </Button>
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),

    createColumn.accessor("credits", {
      header: () => <p className="t-m-0 t-text-right t-mr-16">Inkle credits</p>,
      cell: (info) => {
        const operation = info.row.original.operation;

        return (
          <div
            className={classNames(
              "t-text-body t-font-medium t-text-right t-mr-16",
              {
                "t-text-red": operation === "DEBIT",
                "t-text-dark_green": operation !== "DEBIT",
              }
            )}
          >
            {operation === "DEBIT" ? "-" : "+"}
            {info.getValue()}
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: company_group_credits_ledger,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (
    isLoading ||
    !isSuccess ||
    current_page === undefined ||
    per_page === undefined ||
    total_count === undefined ||
    total_pages === undefined
  ) {
    return <Loader />;
  }

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = page < total_pages ? page : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (page < total_pages) {
      update({ query: "page", value: page + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  return (
    <div>
      <div className="t-my-4 t-flex t-items-center t-justify-end t-text-subtext t-text-text-100">
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <Table.Container size="regular">
        <Table.Content>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body>
            {table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Content>
      </Table.Container>
      <DocumentPreviewModal />
    </div>
  );
};
