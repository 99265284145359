import { RESTRICTED_EMAILS } from "constants/magicLink";
import { array, object, string } from "yup";

export const practiceTeamManagementInvite = object({
  invite_users: array().of(
    object().shape({
      first_name: string().required("First name is required"),
      last_name: string().required("Last name is required"),
      email: string()
        .email("Should be a valid email")
        .required("Email is required")
        .test({
          name: "Domain",
          exclusive: false,
          message: "Please use a work email",
          test: (value) => {
            const userDomain = value?.split("@")?.[1] || "";
            return !RESTRICTED_EMAILS.includes(userDomain);
          },
        }),
    })
  ),
});
