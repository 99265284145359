import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { journalEntrySchema } from "formValidations/JournalEntrySchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import {
  useGetRelatedLedgerEntryQuery,
  useAddLedgerEntryMutation,
} from "store/apis/generalLedger";
import { InvoiceType } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { AddJournalEntryTable } from "./AddJournalEntryTable";
import { roundToFixedDecimal } from "utils/roundtoFixedDecimal";
import { JournalEntry } from "types/Models/journalEntry";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { AnimatePresence } from "framer-motion";
import { AccordionAnimation } from "components/AccordionAnimation";
import { RecurringJournalEntrySettings } from "./AddJournalEntryModal";

export type Transaction = {
  id: string;
  merchant?: string;
  category?: string;
  description?: string | null;
  credit: number;
  debit: number;
  invoice?: InvoiceType;
};

export const JournalEntryFormWarrper = ({
  children,
}: {
  children: ({
    isValidTransactions,
    isAmountMatch,
  }: {
    isValidTransactions: boolean;
    isAmountMatch: boolean;
  }) => ReactNode;
}) => {
  const {
    values: { transactions },
    isValid,
  } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const noEntryData = transactions.some(
    ({ description, category }) => !Boolean(category) || !Boolean(description)
  );

  const totalDebit = transactions.reduce(
    (acc, { debit }) => acc + Number(debit),
    0
  );

  const totalCredit = transactions.reduce(
    (acc, { credit }) => acc + Number(credit),
    0
  );

  const isAmountMatch =
    roundToFixedDecimal({ numberToRound: totalDebit }) ===
    roundToFixedDecimal({ numberToRound: totalCredit });

  const isValidTransactions =
    transactions.length !== 0 && isValid && !noEntryData && isAmountMatch;

  return <>{children({ isValidTransactions, isAmountMatch })}</>;
};

export const DuplicateJournalEntryModal = ({
  close,
  isOpen,
  currentRowData,
  transactionId,
}: { currentRowData: JournalEntry; transactionId: string } & ModalProps) => {
  const { alertToast, successToast } = useToast();
  const [addLedgerEntry] = useAddLedgerEntryMutation();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const { entry_name, date } = currentRowData || {};

  const { data: relatedLedgerEntry } = useGetRelatedLedgerEntryQuery(
    {
      entityId,
      groupId,
      transactionId,
    },
    {
      skip: !entityId || !groupId || !transactionId || !isOpen,
      refetchOnMountOrArgChange: true,
    }
  );

  const { transactions = [], ledger_entry } = relatedLedgerEntry || {};

  const initialTransactions: Transaction[] = transactions.map(
    ({
      transaction: {
        description,
        amount,
        category,
        uuid,
        memo,
        logo,
        merchant_uuid,
      },
      invoices,
    }) => ({
      id: "",
      description: description || null,
      category: category?.uuid,
      merchant: merchant_uuid!,
      uuid,
      memo: memo || null,
      logo: logo || null,
      debit: amount < 0 ? Math.abs(amount) : 0,
      credit: amount > 0 ? Math.abs(amount) : 0,
      invoice: invoices?.[0] || null,
    })
  );

  const initialValues = {
    transaction_date: date,
    ledger_entry_name: "".concat(entry_name!).concat(" - Duplicate"),
    transactions: initialTransactions,
    is_recurring_enabled: ledger_entry?.settings?.is_recurring_enabled || false,
    frequency: ledger_entry?.settings?.frequency,
    end_date: ledger_entry?.settings?.end_date,
    max_recurring_count: ledger_entry?.settings?.max_recurring_count,
  };

  const onSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    const { transactions, transaction_date, ledger_entry_name } = values;

    const transactionList = transactions.map(
      ({ credit, debit, category, description, invoice, merchant }) => ({
        amount: Math.abs(Number(credit)) || Math.abs(Number(debit)) * -1,
        description: description!,
        invoice_id: invoice?.uuid || null,
        merchant_data_id: merchant!,
        transaction_category_id: category!,
      })
    );

    try {
      await addLedgerEntry({
        entityId,
        groupId,
        transactions: transactionList,
        transaction_date: dayjs(transaction_date).format(YYYY_MM_DD),
        ledger_entry_name,
        is_recurring_enabled: values.is_recurring_enabled,
        max_recurring_count: values.end_date
          ? undefined
          : Number(values.max_recurring_count),
        frequency: values.frequency,
        end_date:
          values.end_date && !values.max_recurring_count
            ? dayjs(values.end_date).format(YYYY_MM_DD)
            : undefined,
      }).unwrap();

      successToast({ message: "Journal Entry has been populated!" });
      resetForm();
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validationSchema={journalEntrySchema}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form className="t-m-0 t-w-full">
            <JournalEntryFormWarrper>
              {({ isValidTransactions, isAmountMatch }) => (
                <Modal.Content size="xxxl" useCustomOverlay>
                  <Modal.Header>
                    <div className="t-w-full">
                      <Modal.Title>Add Journal Entry</Modal.Title>
                      <Modal.Subtitle>
                        Please make sure the total debit amount matches the
                        total credit amount
                      </Modal.Subtitle>
                    </div>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-pb-0 t-flex t-gap-4 t-flex-col">
                    <div>
                      <div className="t-flex t-gap-6 t-justify-center t-items-center">
                        <div className="t-flex t-gap-6">
                          <Field name="transaction_date">
                            {({ field }: FieldProps) => {
                              return (
                                <DateInput
                                  label="Date"
                                  {...field}
                                  name="transaction_date"
                                  portalId="journal_entry_date"
                                  required
                                  block
                                  maxDate={new Date()}
                                  placeholder="DD-MMM-YYYY"
                                />
                              );
                            }}
                          </Field>
                          <TextInput
                            name="ledger_entry_name"
                            label="Journal entry title"
                            required
                            placeholder="#1"
                          />
                        </div>
                        <div className="t-ml-auto">
                          <SwitchField
                            name="is_recurring_enabled"
                            label="Recurring entry"
                          />
                        </div>
                      </div>

                      <AnimatePresence>
                        {values.is_recurring_enabled && (
                          <AccordionAnimation>
                            <div className="t-flex t-gap-6 t-pt-4">
                              <RecurringJournalEntrySettings />
                            </div>
                          </AccordionAnimation>
                        )}
                      </AnimatePresence>
                    </div>
                    <AddJournalEntryTable />
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button onClick={close} type="reset">
                      Cancel
                    </Button>

                    <ConditionalToolTip
                      condition={
                        !isAmountMatch && (
                          <span>
                            Total debits and credits
                            <br /> should match
                          </span>
                        )
                      }
                    >
                      <span>
                        <Button
                          customType="primary"
                          type="submit"
                          onClick={submitForm}
                          disabled={!isValidTransactions || isSubmitting}
                          isLoading={isSubmitting}
                        >
                          Add
                        </Button>
                      </span>
                    </ConditionalToolTip>
                  </Modal.FooterButtonGroup>
                </Modal.Content>
              )}
            </JournalEntryFormWarrper>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
