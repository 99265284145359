import { Merchant, W_FORM_TYPES } from "types/Models/merchant";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskMerchants: build.query<
      Merchant[],
      {
        taskId: string;
        groupId: string;
        season: string;
        formType?: string;
        entityId: string;
      }
    >({
      query: ({ taskId, groupId, season, formType, entityId }) => ({
        url: `/api/inkle/tasks/${taskId}/group/${groupId}/entity/${entityId}/merchants/`,
        params: {
          season,
          form_type: formType,
        },
      }),
      providesTags: ["Merchants"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTaskMerchantsQuery } = extendedApi;
