import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChooseFile {
  paymentHeaderStep: number;
}

const initialState: ChooseFile = {
  paymentHeaderStep: 0,
};

export const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentHeaderStep: (state, action: PayloadAction<number>) => {
      state.paymentHeaderStep = action.payload;
    },
  },
});

export const { setPaymentHeaderStep } = payment.actions;

export default payment.reducer;
