import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import React from "react";
import { NewCompliance } from "./Compliance/NewCompliance";
import { MissingDataPoints } from "./MissingDataPoints/MissingDataPoints";

const DeadlineAlarm = () => {
  return (
    <DashboardLayout
      header={
        <Header
          title="Deadline"
          v2
          right={<EntitySelector right={0} showGoToEntity />}
        />
      }
    >
      <section className="t-gap-x-4 t-flex t-h-full">
        <div className="t-w-4/6 t-h-full t-overflow-auto">
          <NewCompliance />
        </div>
        <div className="t-sticky t-top-0 t-w-2/6 t-h-[calc(100%-40px)]">
          <MissingDataPoints />
        </div>
      </section>
    </DashboardLayout>
  );
};

export default DeadlineAlarm;
