import classNames from "classnames";
import React from "react";

const SubcategoryTab = ({
  onTabChange,
  selectedCat,
  subTabData,
  isRASubTab,
}) => {
  const getTitle = (title) => {
    return isRASubTab ? title : title.toLowerCase();
  };

  return (
    <div className="taskStatusButtonDiv">
      {subTabData.map(({ title, id }, i) => {
        return (
          <div
            key={id}
            className={classNames("taskStatusButtons", {
              selectedTaskStatusButtons: title === selectedCat,
              unSelectedTaskStatusButtons: title !== selectedCat,
              "t-ml-5": i === 0,
            })}
            onClick={onTabChange}
          >
            {getTitle(title)}
          </div>
        );
      })}
    </div>
  );
};

export default SubcategoryTab;
