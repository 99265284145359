export const Help = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.3" cx="20" cy="20" r="20" fill="#DDD3FF" />
    <g clipPath="url(#clip0_583_39598)">
      <path
        d="M20 25C20.5178 25 20.9375 24.5803 20.9375 24.0625C20.9375 23.5447 20.5178 23.125 20 23.125C19.4822 23.125 19.0625 23.5447 19.0625 24.0625C19.0625 24.5803 19.4822 25 20 25Z"
        fill="#9A7AFF"
      />
      <path
        d="M20 25C20.5178 25 20.9375 24.5803 20.9375 24.0625C20.9375 23.5447 20.5178 23.125 20 23.125C19.4822 23.125 19.0625 23.5447 19.0625 24.0625C19.0625 24.5803 19.4822 25 20 25Z"
        stroke="#9A7AFF"
      />
      <path
        d="M20 21.25V20.625C21.3805 20.625 22.5 19.6453 22.5 18.4375C22.5 17.2297 21.3805 16.25 20 16.25C18.6195 16.25 17.5 17.2297 17.5 18.4375V18.75"
        stroke="#9A7AFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 27.5C24.1421 27.5 27.5 24.1421 27.5 20C27.5 15.8579 24.1421 12.5 20 12.5C15.8579 12.5 12.5 15.8579 12.5 20C12.5 24.1421 15.8579 27.5 20 27.5Z"
        stroke="#9A7AFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_583_39598">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
