import { useGetAllConnectionsQuery } from "store/apis/booksConnections";

export const useGetAllConnections = ({
  groupId,
  entityId,
}: {
  groupId: string;
  entityId: string;
}) => {
  const { data: directBanks, ...states } = useGetAllConnectionsQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const stripeConnection = directBanks?.find(
    ({ connection_provider }) => connection_provider === "STRIPE"
  );

  const connections = directBanks?.filter(
    ({ connection_provider }) => connection_provider !== "STRIPE"
  );

  return { stripeConnection, connections, ...states };
};
