import { LoadingToast } from "components/design/LoadingToast";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import isEqual from "lodash/isEqual";
import { useParams } from "react-router-dom";
import {
  useEditEntityDataAttributesMutation,
  useGetEntityDataAttributesQuery,
} from "store/apis/entity";
import { EntityDataAttribute } from "types/Models/entity";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { FieldBuilder } from "./FieldBuilder";
import Jurisdictions from "./Jurisdictions";

const EntityFormEngine = () => {
  const { entityId } = useParams<{ entityId: string }>();
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();

  const [editEntityDataAttributes, { isLoading: isUpdating }] =
    useEditEntityDataAttributesMutation();

  const {
    data: currentEntityDetails = [],
    isLoading,
    isSuccess,
  } = useGetEntityDataAttributesQuery(
    {
      groupId: groupId!,
      entityId,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const initialValues = currentEntityDetails.reduce((acc, field) => {
    acc[field.name] = field.value;
    return acc;
  }, {} as Record<string, EntityDataAttribute["value"]>);

  const onChange = debounce(
    async ({
      name,
      value,
    }: {
      name: string;
      value: EntityDataAttribute["value"];
    }) => {
      const payload = { [name]: value };
      const initialValue = initialValues[name];

      const isSameValue = isEqual(
        JSON.stringify(value),
        JSON.stringify(initialValue)
      );

      if (isSameValue) {
        return;
      }

      try {
        await editEntityDataAttributes({
          groupId,
          entityId,
          payload,
        }).unwrap();
        successToast({ message: "Entity updated successfully" });
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  );

  const isEmpty = currentEntityDetails?.length === 0;

  return (
    <Async.Root isEmpty={isEmpty} isLoading={isLoading} isSuccess={isSuccess}>
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-flex-col t-gap-6 md:t-w-1/2 sm:t-w-full">
          <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize
          >
            <Form className="t-flex t-flex-col t-gap-5 t-m-0 t-border t-border-solid t-px-6 t-py-5 t-border-neutral-10 t-rounded-lg">
              {currentEntityDetails.map((field) => (
                <FieldBuilder
                  field={field}
                  onChange={onChange}
                  key={field.uuid}
                />
              ))}
            </Form>
          </Formik>
          <Jurisdictions />
          <LoadingToast loading={isUpdating}>Updating...</LoadingToast>
        </div>
      </Async.Success>
    </Async.Root>
  );
};

export default EntityFormEngine;
