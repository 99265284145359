import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChooseFile {
  credits: number;
  creditApplicable: boolean;
  usableCredit: boolean;
  paymentTitle: string;
  creditAmountToAdd: number;
  isScheduledPayment: boolean;
}

const initialState: ChooseFile = {
  credits: 0,
  creditApplicable: false,
  usableCredit: false,
  paymentTitle: "",
  creditAmountToAdd: 0,
  isScheduledPayment: false,
};

export const credits = createSlice({
  name: "credits",
  initialState,
  reducers: {
    setCredits: (state, action: PayloadAction<number>) => {
      state.credits = action.payload;
    },
    setCreditApplicable: (state, action: PayloadAction<boolean>) => {
      state.creditApplicable = action.payload;
    },
    setUsableCredit: (state, action: PayloadAction<boolean>) => {
      state.usableCredit = action.payload;
    },
    setPaymentTitle: (state, action: PayloadAction<string>) => {
      state.paymentTitle = action.payload;
    },
    setCreditAmountToAdd: (state, action: PayloadAction<number>) => {
      state.creditAmountToAdd = action.payload;
    },
    setScheduledPayment: (state, action: PayloadAction<boolean>) => {
      state.isScheduledPayment = action.payload;
    },
  },
});

export const {
  setCredits,
  setCreditApplicable,
  setUsableCredit,
  setPaymentTitle,
  setCreditAmountToAdd,
  setScheduledPayment,
} = credits.actions;

export default credits.reducer;
