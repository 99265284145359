import React from "react";
import { Dropdown } from "react-bootstrap";
import Pencil from "./icons/pencil";
import cx from "classnames";
import { MERCHANT_TASK_STATUS_TYPES } from "types/Models/merchant";
import {
  COMPLETED,
  IN_PROGRESS,
  TBD,
  NOT_REQUIRED,
  REQUIRED,
  VERIFYING,
} from "constants/taskContractorFilingStates";
import GrayPencil from "static/images/GrayPencil.svg";
import AssignedUser from "static/images/AssignedUser.svg";
import { Tag } from "./DesignSystem/Tag/Tag";

type TaskContractorFillingProps = {
  filingStatus: MERCHANT_TASK_STATUS_TYPES;
  onUpdate: (status: MERCHANT_TASK_STATUS_TYPES) => any;
  isLoading: boolean;
  editable?: boolean;
};

const TEXT_MAP = {
  [COMPLETED]: "Completed",
  [IN_PROGRESS]: "In Progress",
  [TBD]: "TBD",
  [REQUIRED]: "Required",
  [NOT_REQUIRED]: "Not Required",
  [VERIFYING]: "Verifying",
};

const TAG_COLORS = {
  [COMPLETED]: "green",
  [IN_PROGRESS]: "orange",
  [TBD]: "gray",
  [REQUIRED]: "red",
  [NOT_REQUIRED]: "transparent",
  [VERIFYING]: "gray",
};

const FilingItem = ({
  onSelect,
  selectedStatus,
  status,
}: {
  onSelect: TaskContractorFillingProps["onUpdate"];
  status: MERCHANT_TASK_STATUS_TYPES;
  selectedStatus: MERCHANT_TASK_STATUS_TYPES;
}) => {
  return (
    <Dropdown.Item
      as="button"
      eventKey={status}
      className="button-reset task-assign-item"
    >
      <span
        className={cx("sendDataTag", {
          inactive: selectedStatus === status,
        })}
        onClick={() => onSelect(status)}
      >
        {TEXT_MAP[status]}
      </span>
      {selectedStatus === status && (
        <div className="w-25">
          <img src={AssignedUser} className="assignCheck w-100" alt="check" />
        </div>
      )}
    </Dropdown.Item>
  );
};

export const TaskContractorFilling = ({
  filingStatus,
  onUpdate,
  isLoading,
  editable = false,
}: TaskContractorFillingProps) => {
  const finalStatus = filingStatus;

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        as="button"
        className="task-assign-toggle button-reset"
        disabled={!editable}
      >
        <div className="d-flex gap-2 ">
          {/* @ts-ignore */}
          <Tag tagType={TAG_COLORS[finalStatus]}>
            <span className="t-whitespace-nowrap t-inline-block t-w-full t-overflow-hidden t-text-ellipsis t-text-body-sm t-line-clamp-1">
              {TEXT_MAP[finalStatus]}
            </span>
          </Tag>
          {editable && (
            <img src={GrayPencil} className="t-h-5" alt="GrayPencil" />
          )}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu key={finalStatus}>
        <FilingItem
          selectedStatus={filingStatus}
          status={COMPLETED}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={IN_PROGRESS}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={TBD}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={REQUIRED}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={NOT_REQUIRED}
          onSelect={onUpdate}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
