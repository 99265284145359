export const CheckSquare = ({ stroke = "1.6" }: { stroke?: string }) => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.14844 14.1771V5.61768C4.14844 5.41876 4.22417 5.228 4.35896 5.08735C4.49375 4.94669 4.67657 4.86768 4.8672 4.86768H18.5234C18.714 4.86768 18.8969 4.94669 19.0317 5.08735C19.1665 5.228 19.2422 5.41876 19.2422 5.61768V19.8677C19.2422 20.0666 19.1665 20.2574 19.0317 20.398C18.8969 20.5387 18.714 20.6177 18.5234 20.6177H12.3781"
      stroke="currentColor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6953 14.9927L5.94531 20.9927L3.07031 17.9927"
      stroke="currentColor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
