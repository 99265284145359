import React from "react";

const WhatsNewHeader = () => {
  return (
    <div className="whatsNewHeader">
      Check all the Inkle product updates here⚡️
    </div>
  );
};

export default WhatsNewHeader;
