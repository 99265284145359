import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Tab from "components/DesignSystem/Tab/Tab";
import { Reconciliation } from "components/Reconciliation/Reconciliation";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import { openStartFinancialClosingModal } from "store/slices/financialClosing";
import { Header } from "../../../components/DesignSystem/Header/Header";
import { Summary } from "../../../components/FinancialClosing/Summary";
import { BooksOnboarding } from "./BooksOnboarding";
import { BooksQuotes } from "components/FinancialClosing/BooksQuotes";

export const FinancialClosing = () => {
  usePageTitle("Monthly Closing");
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) => p.name === PRODUCT_ONBOARDING.BOOKS
  );
  const isBooksOnboardingComplete =
    platformOnboarding && platformOnboarding.status === "COMPLETED";

  const isBooksQuotes = pathname.includes("/quotes");

  if (isLoading || !isSuccess) {
    return (
      <DashboardLayout header={<Header v2 title="Monthly Closing" />}>
        <Loader />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Monthly Closing"
          right={
            isBooksOnboardingComplete && !isBooksQuotes ? (
              <Button
                customType="primary"
                size="small"
                onClick={() => dispatch(openStartFinancialClosingModal())}
              >
                Start bookkeeping
              </Button>
            ) : (
              <></>
            )
          }
          bottom={
            <Tab.Root defaultValue={pathname} value="MATCH">
              <Tab.List>
                <Tab.NavTrigger
                  exact
                  to={`${url}${search}`}
                  value={!isBooksQuotes ? "MATCH" : ""}
                  className="t-flex t-items-center"
                >
                  Overview
                </Tab.NavTrigger>
                <Tab.NavTrigger
                  exact
                  to={`${url}/quotes${search}`}
                  value={isBooksQuotes ? "MATCH" : ""}
                >
                  Quotes
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      }
    >
      <Switch>
        <Route path={`${path}/quotes`}>
          <BooksQuotes />
        </Route>
        <Route path={path}>
          {isBooksOnboardingComplete ? (
            <Summary />
          ) : (
            <div className="t-flex t-flex-col t-gap-6 t-px-5 t-pb-1 t-h-full">
              <BooksOnboarding />
            </div>
          )}
        </Route>
      </Switch>
    </DashboardLayout>
  );
};
