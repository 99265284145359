import { getFileDownloadUrlWithNewName } from "apis/documents";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React from "react";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { TaskCompletedDocuments } from "types/Models/task";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";

type FilingReviewSectionDisplayProps = {
  sectionTitle: string;
  sectionFields: any;
  repeatable_fields: any;
};

type SectionFieldValue =
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | Iterable<React.ReactNode>
  | null
  | undefined;

export type SectionFieldsFieldProps = {
  type: string;
  value: SectionFieldValue;
  field_label: any;
};

export const FilingInfo = ({
  label,
  value,
}: {
  label: string;
  value?: SectionFieldValue;
}) => {
  return (
    <div className="t-space-y-1">
      <div
        className="t-text-text-30 t-text-body-sm"
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      ></div>
      <div className="t-text-text-100 t-text-subtext">{value || "-"}</div>
    </div>
  );
};

const DocumentInfo = ({ file }: { file: TaskCompletedDocuments }) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [getPreviewUrl, { isLoading }] = useLazyGetPreviewUrlQuery();

  const openPreviewAndDownloadLink = async (fileId: string) => {
    try {
      const { preview_url } = await getPreviewUrl({
        groupId,
        fileId,
      }).unwrap();
      openLink(preview_url, "_blank");
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  return (
    <span
      key={file.uuid}
      className="t-flex t-gap-2 t-cursor-pointer"
      onClick={() => openPreviewAndDownloadLink(file?.uuid)}
    >
      {isLoading ? (
        <Loader size="small" />
      ) : (
        <FileIcon fileType={file?.file_type} width="20px" height="20px" />
      )}
      <Button customType="link">{file.name}</Button>
    </span>
  );
};

export const FilingReviewSectionDisplay = ({
  sectionTitle,
  sectionFields,
  repeatable_fields,
}: FilingReviewSectionDisplayProps) => {
  const checkBox = "checkbox";
  const selectAutofill = "select_autofill";
  const uploadedFiles: TaskCompletedDocuments[] =
    sectionFields?.find(
      (field: SectionFieldsFieldProps) => field.type === "file_upload"
    )?.documents || [];
  console.log({ uploadedFiles });

  return (
    <div className="t-border t-rounded-lg t-border-solid t-border-neutral-10 t-p-6 t-pb-0">
      <div className="t-border t-border-solid t-border-t-0 t-border-l-0 t-border-r-0 t-border-neutral-10 t-w-full t-text-text-100 t-text-subtext t-pb-3 t-mb-4">
        {sectionTitle}
      </div>
      <div className="t-mb-6">
        <div className="t-grid t-gap-x-20 t-gap-y-6 t-grid-cols-2">
          {sectionFields?.map(
            (
              field: SectionFieldsFieldProps,
              index: React.Key | null | undefined
            ) => {
              if (field.type === checkBox) {
                return (
                  <ToolTip
                    key={index}
                    text="This cannot be changed in review information"
                  >
                    <div className="t-col-span-2">
                      <Checkbox
                        label={
                          <div className="t-text-text-30 t-text-body-sm t-mt-1">
                            {field.field_label}
                          </div>
                        }
                        checked={field.value ? true : false}
                        disabled
                        alignTop
                      />
                    </div>
                  </ToolTip>
                );
              }

              if (field.type === selectAutofill) {
                return null;
              }

              return (
                <FilingInfo
                  key={index}
                  label={field.field_label}
                  value={field.value}
                />
              );
            }
          )}
        </div>
        <div className="t-space-y-6 t-mt-4">
          {repeatable_fields?.map(
            (repeatableField: { fields: any[]; id: number }, index: number) => {
              return (
                <div
                  key={repeatableField.id}
                  className="t-border t-rounded-lg t-border-solid t-border-neutral-10 t-p-4"
                >
                  <div className="t-text-text-100 t-text-subtext t-mb-4">
                    {sectionTitle} {index + 1}
                  </div>
                  <div className="t-grid t-gap-x-20 t-gap-y-6 t-grid-cols-2">
                    {repeatableField.fields?.map((field, index) => {
                      if (field.type === selectAutofill) {
                        return null;
                      }

                      return (
                        <FilingInfo
                          key={index}
                          label={field.field_label}
                          value={field.value}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      {uploadedFiles.length > 0 && (
        <div className="t-space-y-1 t-mb-6">
          <div className="t-text-text-30 t-text-body-sm">Documents</div>
          <div className="t-space-y-2 t-flex t-flex-col">
            {uploadedFiles.map((file) => (
              <DocumentInfo key={file.uuid} file={file} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
