import React from "react";

export default function Headset({ color = "#110733" }) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_318_33094)">
        <path
          d="M12.25 11.4375V11.875C12.25 12.3391 12.0656 12.7842 11.7374 13.1124C11.4092 13.4406 10.9641 13.625 10.5 13.625H7.4375"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.25 7.5H10.5C10.2679 7.5 10.0454 7.59219 9.88128 7.75628C9.71719 7.92038 9.625 8.14294 9.625 8.375V10.5625C9.625 10.7946 9.71719 11.0171 9.88128 11.1812C10.0454 11.3453 10.2679 11.4375 10.5 11.4375H12.25V7.5ZM12.25 7.5C12.25 6.81056 12.1142 6.12787 11.8504 5.49091C11.5865 4.85395 11.1998 4.2752 10.7123 3.78769C10.2248 3.30018 9.64605 2.91347 9.00909 2.64963C8.37213 2.3858 7.68944 2.25 7 2.25C6.31056 2.25 5.62787 2.3858 4.99091 2.64963C4.35395 2.91347 3.7752 3.30018 3.28769 3.78769C2.80018 4.2752 2.41347 4.85395 2.14963 5.49091C1.8858 6.12787 1.75 6.81056 1.75 7.5M1.75 7.5V10.5625C1.75 10.7946 1.84219 11.0171 2.00628 11.1812C2.17038 11.3453 2.39294 11.4375 2.625 11.4375H3.5C3.73206 11.4375 3.95462 11.3453 4.11872 11.1812C4.28281 11.0171 4.375 10.7946 4.375 10.5625V8.375C4.375 8.14294 4.28281 7.92038 4.11872 7.75628C3.95462 7.59219 3.73206 7.5 3.5 7.5H1.75Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_318_33094">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
