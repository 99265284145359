import React, { useContext, useState } from "react";

// components
import { Button } from "../design/button";
import { SendEmail } from "../icons/Email";
import CopyCode from "../design/copyCode";

// images
import VisitIcon from "../../static/images/VisitLink.svg";
import CopyIcon from "../../static/images/CopyPurple.svg";

// constants
import {
  INTRO_EMAIL,
  PROMO_CODE,
  SPECIAL_URL,
  GENERIC,
  SPECIAL_EMAIL,
} from "../../constants/perks";
import { WHITE, OLD_LAVENDER } from "../../constants/perks";

// utils
import { openLink } from "../../utils/openLink";
import { useDispatch } from "react-redux";
import { setRedirectPath } from "store/slices/subscriptionModal";
import { PERK_REDEEMED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import authContext from "jwt_context&axios/authContext";
import { useToast } from "hooks/useToast";
import { useRedeemDealMutation } from "store/apis/rewards";
import { useParams } from "react-router-dom";

const PerkBtn = ({
  isRedeemed,
  perkType,
  perkId,
  groupId,
  redeemDetails,

  emailRedeem,
  setEmailRedeem,
}) => {
  const { authtoken } = useContext(authContext);
  const [redeemResponse, setRedeemResponse] = useState(null);
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const [redeemDeal, { isLoading }] = useRedeemDealMutation();
  const { alertToast, successToast } = useToast();
  const { perkSlug } = useParams();
  const redeemPerk = async () => {
    try {
      const res = await redeemDeal({
        perkId,
        groupId,
        perkType,
      }).unwrap();
      if (perkType === "INTRO_EMAIL") {
        successToast({ message: "Email Sent Successfully" });
      }

      trackEvent(PERK_REDEEMED, {
        group_id: groupId,
        email: authtoken.email,
        perk_type: perkType,
        perk_id: perkId,
        perk_name: perkSlug,
      });
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
      dispatch(setRedirectPath("/perks"));
    }
  };

  const getCopyText = () => redeemDetails?.[0]?.promo_code;
  const getSpecialEmail = () => redeemDetails?.[0]?.special_email;
  const getSpecialUrl = () => redeemDetails?.[0]?.url;
  const showEmailBtn = () => setEmailRedeem(true);

  const handleSpecialUrl = () => {
    openLink(getSpecialUrl(), "_blank");
  };

  const getBtnText = () => {
    switch (perkType) {
      case PROMO_CODE: {
        return <CopyCode getCopyText={getCopyText} copyIcon={CopyIcon} />;
      }
      case SPECIAL_URL: {
        return (
          <button className="redeemBtn" onClick={handleSpecialUrl}>
            Visit Link
            <img src={VisitIcon} alt="VisitIcon" />
          </button>
        );
      }
      case INTRO_EMAIL: {
        return (
          <button className="redeemBtn">
            Email Sent
            <SendEmail color={OLD_LAVENDER} />
          </button>
        );
      }
      case SPECIAL_EMAIL: {
        return <CopyCode getCopyText={getSpecialEmail} copyIcon={CopyIcon} />;
      }
      case GENERIC: {
        return <></>;
      }
      default:
        return <></>;
    }
  };

  return (
    <>
      {!isRedeemed ? (
        <>
          {perkType === INTRO_EMAIL ? (
            <button
              onClick={!emailRedeem ? showEmailBtn : redeemPerk}
              className="custom-button custom-button-small custom-button-color-purple"
            >
              <div>
                {!emailRedeem ? (
                  <>Reveal Instruction</>
                ) : (
                  <>
                    Send Email <SendEmail color={WHITE} />
                  </>
                )}
              </div>
            </button>
          ) : (
            <Button size="small" color="purple" onClick={redeemPerk}>
              Reveal Instructions
            </Button>
          )}
        </>
      ) : (
        getBtnText()
      )}
    </>
  );
};

export default PerkBtn;
