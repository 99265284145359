export const PROMO_CODE = "PROMO_CODE";
export const SPECIAL_URL = "SPECIAL_URL";
export const INTRO_EMAIL = "INTRO_EMAIL";
export const GENERIC = "GENERIC";
export const SPECIAL_EMAIL = "SPECIAL_EMAIL";

export const EMAIL_SENT_SUCCESSFULLY = "Email sent successfully";
export const ALREADY_INTRODUCED =
  "You have already introduced to this company.";

export const shade10Purple = "#5622FF";

export const SPECIAL_URL_DETAILS = [
  "Click on the magiclink",
  "You would’ve redeemed the perk",
];

export const INTRO_EMAIL_DETAILS = [
  "Click on Send Email",
  "We will automatically fire an intro mail for you",
];

export const WHITE = "#ffffff";
export const OLD_LAVENDER = "#706a85";
export const MIKADO_YELLOW_20 = "#fef5e7";
export const OCHRE = "#C67E19";
export const BROWN_NOSE = "#6b4326";

export const PERKS_PATH = "/perks";
export const CREATE_PERK_PATH = "/createperk";
export const TRANSPARENT = "transparent";

export const PERK_PROVIDER_LINK =
  "https://www.inkle.io/perks#perk-provider-form";
export const PERK_PROVIDER = "https://forms.gle/yWMbGNpjsm2qskyZ7";

export const COMPANY_INFORMATION = "COMPANY_INFORMATION";
export const PERK_INFORMATION = "PERK_INFORMATION";
export const REDEMPTION_INSTRUCTION = "REDEMPTION_INSTRUCTION";

export const DEFAULT_PERK_DESCRIPTION =
  "Your detailed description goes here. Please start filling the form  to see a preview of how your perk card will appear on our platform";
export const ADDING_PERK = "Adding a Perk...";

export const PERKS_US_BANK_FILTER = "PERKS_US_BANK_FILTER";
export const PERKS_US_LAW_FIRM_FILTER = "PERKS_US_LAW_FIRM_FILTER";
