import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { BareInput } from "components/DesignSystem/TextInput/TextInput";
import { ENTER_KEY_PRESS } from "../../../constants/documents";

export const RenameFileFolderModal = ({
  renameFolder,
  closeRenameModal,
  payload,
  handleChange,
  renameModalShow,
  folderName,
  itemType = "file",
  onKeyDown,
}) => {
  const isDisabled = Boolean(payload?.file_name === folderName);

  const checkKey = (e) => {
    if (e.key === ENTER_KEY_PRESS && !isDisabled) onKeyDown();
  };

  return (
    <Modal.Root open={renameModalShow} onOpenChange={closeRenameModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            Rename <span className="t-lowercase t-ml-1">{itemType}</span>
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <BareInput
            autoFocus
            onKeyDown={checkKey}
            name="file_name"
            value={payload?.file_name}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button onClick={closeRenameModal}>Cancel</Button>
          <Button
            customType="primary"
            onClick={renameFolder}
            disabled={isDisabled}
          >
            Save
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
