import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

type MoveToOpsModalProps = {
  show: boolean;
  close: () => void;
  handleMoveToSales: () => void;
  isLoading: boolean;
};

export const MoveToOpsModal = ({
  show,
  close,
  handleMoveToSales,
  isLoading,
}: MoveToOpsModalProps) => {
  return (
    <Modal.Root open={show} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Move the group to Ops?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Are you sure that you want to move the group to Ops CRM?
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={close}>No</Button>
          <Button
            customType="primary"
            onClick={handleMoveToSales}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
