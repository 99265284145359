import { TASK_STATE_TO_COLOR } from "dictionaries";
import qs from "qs";
import { SelecteFile } from "types/Models/documents";
import { Pagination } from "types/Models/pagination";
import {
  Task,
  TaskCompletedDocuments,
  TaskDocumentInstance,
  TaskTags,
} from "types/Models/task";
import { emptyApi } from "./emptyApi";
import { BillingInvoice } from "types/Models/billing";

export type ChannelTask = {
  uuid: string;
  name: string;
  deadline: null | string; // Change 'string' to a more specific date type if needed
  eligibility?: string;
  channel: {
    channel_url: string;
    name: string;
  };
  private_channel: {
    channel_url: string;
    name: string;
  };
  is_archived: boolean;
  current_state: {
    uuid: string;
    type: keyof typeof TASK_STATE_TO_COLOR;
    name: string;
  };
  state_details: {
    updated_at: string;
  };
  is_bundle: boolean;
  is_parent_task: boolean;
};

type Entity = {
  name: string;
  country_name: string;
  code_alpha_2: string;
  uuid: string;
};

type CompanyGroup = {
  uuid: string;
  documents_group_id: string;
  assigned_agent?: {
    name: string;
    agent_id: string;
    profile_id: string;
  };
  books_2023_channel: {
    channel_url: string;
    name: string;
  };
  books_channels: {
    [key: string]: {
      channel_url: string;
      name: string;
    };
  };
  announcements_channel: {
    channel_url: string;
    name: string;
  };
  mailbox_channel: {
    channel_url: string;
    name: string;
  };
  secondary_channel: {
    channel_url: string;
    name: string;
  };
  private_channel: {
    channel_url: string;
    name: string;
  };
};

export type TaskChannel = {
  tasks: ChannelTask[];
  entity?: Entity;
  company_group?: CompanyGroup;
};

export const taskApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTask: build.query<Task, { taskId: string }>({
      query: ({ taskId }) => `api/inkle/tasks/${taskId}/`,
      providesTags: (result) =>
        result ? [{ type: "Tasks", id: result.uuid } as const] : [],
    }),
    getTaskTags: build.query<TaskTags, void>({
      query: () => `api/inkle/tags/TASK_TAG/`,
    }),

    getTaskFromChannelId: build.query<TaskChannel, { channelId: string }>({
      query: ({ channelId }) => `/api/inkle/tasks/channel/${channelId}/task/`,
    }),

    uploadToFormInstance: build.mutation<
      { doc_instance_uuid: string; doc_data: TaskDocumentInstance[] },
      {
        groupId: string;
        formInstanceId: string;
        tag: string;
        fieldKey: string;
        file?: File;
        file_id?: string;
      }
    >({
      query: ({ groupId, formInstanceId, tag, fieldKey, file, file_id }) => {
        const formData = new FormData();
        Object.entries({ field_key: fieldKey, file, tag, file_id }).forEach(
          ([key, value]) => value && formData.append(key, value)
        );

        return {
          url: `/api/inkle/tasks/group/${groupId}/form_instance/${formInstanceId}/file_upload/`,
          method: "post",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),

    deleteFileFromTask: build.mutation<
      { doc_instance_uuid: string; doc_data: TaskDocumentInstance[] },
      {
        fileId: string;
        fieldKey: string;
        formInstanceId: string;
        groupId: string;
      }
    >({
      query: ({ fileId, fieldKey, formInstanceId, groupId }) => {
        const formData = new FormData();
        Object.entries({ field_key: fieldKey, file_id: fileId }).forEach(
          ([key, value]) => value && formData.append(key, value)
        );

        return {
          url: `/api/inkle/tasks/group/${groupId}/form_instance/${formInstanceId}/file_delete/`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),
    uploadInkleDocToDocInstance: build.mutation<
      { doc_instance_uuid: string; doc_data: TaskDocumentInstance[] },
      {
        taskId: string;
        docInstanceId: string;
        tag: string;
        fieldKey: string;
        file: SelecteFile;
      }
    >({
      query: ({ taskId, docInstanceId, tag, fieldKey, file }) => {
        const formData = new FormData();
        Object.entries({
          field_key: fieldKey,
          file_id: file.uuid,
          tag,
        }).forEach(([key, value]) => value && formData.append(key, value));

        return {
          url: `/api/inkle/tasks/${taskId}/doc_instance/${docInstanceId}/file_upload/`,
          method: "post",
          body: formData,
        };
      },
      async onQueryStarted(
        { taskId, fieldKey, file },
        { dispatch, queryFulfilled }
      ) {
        try {
          dispatch(
            taskApis.util.updateQueryData("getTask", { taskId }, (draft) => {
              const fieldIndex =
                draft.doc_data.data[0].documents_needed.findIndex(
                  (f) => f.field_key === fieldKey
                );

              if (fieldIndex > -1) {
                draft.doc_data.data[0].documents_needed[
                  fieldIndex
                ].documents.push({
                  name: file.name,
                  uuid: file.uuid,
                  file_type: file.file_type,
                  is_previewable: file.is_previewable,
                  status: "UPLOADING",
                });
              }
            })
          );

          const { data: newDocumentInstance } = await queryFulfilled;

          dispatch(
            taskApis.util.updateQueryData("getTask", { taskId }, (draft) => {
              draft.doc_data.data = newDocumentInstance.doc_data;
            })
          );
        } catch {}
      },
    }),
    getTaskByBaseTemplateId: build.query<
      {
        task_uuid: string;
        task_title: string;
        task_deadline: string;
        current_state: { uuid: string; type: string; name: string };
      },
      { groupId: string; templateId: string; season: string }
    >({
      query: ({ groupId, templateId, season }) => {
        let queryUrl = qs.stringify(
          {
            season,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/tasks/group/${groupId}/base_template/${templateId}/task/${queryUrl}`,
        };
      },
      providesTags: () => {
        return [{ type: "TaskTemplate", id: "LIST" }];
      },
    }),
    uploadCompletedTaskFile: build.mutation<
      { data: TaskCompletedDocuments },
      {
        task_id: string;
        folder_id?: string;
        group_id: string;
        file?: File;
        file_id?: string;
        txn_merchant_id?: string;
        form_1099_document_type?: string;
      }
    >({
      query: ({
        task_id,
        folder_id,
        group_id,
        file,
        file_id,
        txn_merchant_id,
        form_1099_document_type,
      }) => {
        const formData = new FormData();
        Object.entries({
          folder_id: folder_id,
          file,
          txn_merchant_id,
          form_1099_document_type,
        }).forEach(([key, value]) => value && formData.append(key, value));
        return {
          url: `/api/inkle/tasks/group/${group_id}/task/${task_id}/file/`,
          method: "post",
          body: Boolean(file_id)
            ? { file_id: file_id, txn_merchant_id, form_1099_document_type }
            : formData,
        };
      },
      invalidatesTags: [{ type: "Tasks" }, { type: "Merchants" }],
    }),
    deleteCompletedTaskFile: build.mutation<
      { data: TaskCompletedDocuments[] },
      {
        task_document_map_id: string;
        task_id: string;
        group_id: string;
        // docUuid is used to show loader on deleting file
        docUuid?: string;
      }
    >({
      query: ({ task_document_map_id, task_id, group_id }) => {
        return {
          url: `/api/inkle/tasks/group/${group_id}/task/${task_id}/file/`,
          method: "PUT",
          body: {
            task_document_map_id,
          },
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),

    createOnboardingTask: build.mutation({
      query: ({
        payload,
      }: {
        payload: {
          task_template_id?: string;
          title: string;
          payment_category: number;
          payment_amount: string;
          company_entity_ids: string[];
          adhoc_scope_of_work: string;
        };
      }) => {
        return {
          url: `/api/inkle/tasks/company/task/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "TP_ACCOUNTINFO" }] : []),
    }),

    getAllConsultantDashboardData: build.query<
      Pagination & { tasks: Task },
      {
        page_num: number;
        search_term?: string;
        task_list_type?: string;
        task_state_filter_uuids: string;
        task_template_filter_uuids?: string;
        task_tag_filter_uuids?: string;
        task_sort_by?: string;
        etd_type?: string;
      }
    >({
      query: ({
        page_num,
        search_term,
        task_list_type,
        task_state_filter_uuids,
        task_template_filter_uuids,
        task_tag_filter_uuids,
        task_sort_by,
        etd_type,
      }) => {
        let queryUrl = qs.stringify(
          {
            task_list_type,
            page_num,
            search_term,
            task_state_filter_uuids,
            task_template_filter_uuids,
            task_tag_filter_uuids,
            task_sort_by,
            etd_type,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/tasks/my_tasks/${queryUrl}`,
        };
      },
      providesTags: (result) =>
        result ? ["All_SERVICE_PROVIDERS", "Tasks"] : [],
    }),
    updateTask: build.mutation<
      any,
      {
        task_id: string;
        operation: string;
        price: string;
        description: string;
      }
    >({
      query: ({ task_id, operation, price, description }) => {
        // `/api/inkle/tasks/form_instance/${formId}/`;
        return {
          url: `/api/inkle/tasks/${task_id}/update/`,
          method: "PUT",
          body: {
            operation,
            price,
            description,
          },
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),
    updateTaskFormData: build.mutation<
      any,
      {
        formId: string;
        payload: any;
        taskId: string;
      }
    >({
      query: ({ formId, payload }) => {
        return {
          url: `/api/inkle/tasks/form_instance/${formId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ({ taskId }) => [{ type: "Tasks", id: taskId }],
    }),
    sendDocumentToReview: build.mutation<
      any,
      {
        payload: any;
        channel_id: string;
      }
    >({
      query: ({ channel_id, payload }) => {
        return {
          url: `/api/inkle/tasks/channel/${channel_id}/send_document_to_review/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),
    markDocumentAsReview: build.mutation<
      any,
      {
        document_ids: string;
        channel_id: string;
        message_id: string;
        group_id: string;
        task_id: string;
      }
    >({
      query: ({ document_ids, channel_id, message_id, group_id, task_id }) => {
        return {
          url: `/api/inkle/tasks/group/${group_id}/channel/${channel_id}/mark_documents_reviewed/`,
          method: "POST",
          body: { document_ids, message_id, task_id },
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),
    getTaskInvoices: build.query<
      { amount: string; name: string; status: string }[],
      { groupId: string; taskId: string }
    >({
      query: ({ groupId, taskId }) =>
        `/api/inkle/tasks/group/${groupId}/task/${taskId}/task_invoices/`,
    }),
    markTaskAsReviewed: build.mutation<
      any,
      {
        taskId: string;
      }
    >({
      query: ({ taskId }) => {
        return {
          url: `/api/inkle/tasks/${taskId}/customer_action/`,
          method: "PUT",
          body: { mark_form_as_reviewed: true },
        };
      },
    }),
    updateState: build.mutation<
      Task,
      {
        taskId: string;
        newStateId: string;
      }
    >({
      query: ({ taskId, newStateId }) => {
        return {
          url: `/api/inkle/tasks/${taskId}`,
          method: "PUT",
          body: { new_state_id: newStateId },
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),
    amendFiling: build.mutation<
      Task,
      {
        groupId: string;
        taskId: string;
        payload: any;
      }
    >({
      query: ({ groupId, taskId, payload }) => {
        return {
          url: `/api/inkle/tasks/group/${groupId}/task/${taskId}/amendment/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: [{ type: "Tasks" }],
    }),

    getInProgressTaskCount: build.query<
      { in_progress_task_count: string },
      { groupId: string }
    >({
      query: ({ groupId }) =>
        `/api/inkle/tasks/in_progress_task_count/group/${groupId}/`,
      providesTags: (res) => (res ? ["Tasks"] : []),
    }),

    getAllBaseTaskTemplates: build.query<
      { uuid: string; title: string }[],
      { searchString?: string }
    >({
      query: ({ searchString }) =>
        `/api/inkle/tasks/base-task-templates/?search_string=${searchString}`,
    }),

    getTaskSlaMetrics: build.query<
      {
        average_completed_time: {
          days: string;
          hours: string;
        };
        completed_filings_count: number;
        launched_filings_count: number;
      },
      { season: string }
    >({
      query: ({ season }) => {
        const queryUrl = qs.stringify({
          season,
        });
        return `api/inkle/tasks/sla_metrics/?${queryUrl}`;
      },
    }),

    generateTaskInvoice: build.mutation<
      BillingInvoice,
      { taskId: string; groupId: string }
    >({
      query: ({ groupId, taskId }) => {
        return {
          url: `/api/inkle/tasks/group/${groupId}/tasks/${taskId}/create_invoice/`,
          method: "POST",
        };
      },
    }),

    generateTaskInvoiceForFCA: build.mutation<
      BillingInvoice,
      { taskId: string; serviceTeamId: string }
    >({
      query: ({ serviceTeamId, taskId }) => {
        return {
          url: `api/inkle/tasks/service-team/${serviceTeamId}/tasks/${taskId}/create_invoice/`,
          method: "POST",
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetInProgressTaskCountQuery,
  useGetTaskQuery,
  useUploadToFormInstanceMutation,
  useDeleteFileFromTaskMutation,
  useUploadInkleDocToDocInstanceMutation,
  useGetTaskTagsQuery,
  useGetTaskByBaseTemplateIdQuery,
  useUploadCompletedTaskFileMutation,
  useDeleteCompletedTaskFileMutation,
  useGetTaskFromChannelIdQuery,
  useLazyGetTaskFromChannelIdQuery,
  useCreateOnboardingTaskMutation,
  useGetAllConsultantDashboardDataQuery,
  useUpdateTaskMutation,
  useSendDocumentToReviewMutation,
  useMarkDocumentAsReviewMutation,
  useGetTaskInvoicesQuery,
  useMarkTaskAsReviewedMutation,
  useUpdateStateMutation,
  useAmendFilingMutation,
  useGetAllBaseTaskTemplatesQuery,
  useUpdateTaskFormDataMutation,
  useGetTaskSlaMetricsQuery,
  useGenerateTaskInvoiceMutation,
  useGenerateTaskInvoiceForFCAMutation,
} = taskApis;
