import { FC, ReactNode } from "react";
import FolderType from "static/images/FolderType.svg";
import uploadCloud from "static/images/uploadCloud.svg";

export const DragNDropArea: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="t-absolute t-z-10 t-flex  t-flex-col t-items-center t-justify-center t-gap-3 t-border t-border-dashed t-border-neutral-70 t-bg-[#f3f3f580] t-h-full t-w-full">
      <img src={uploadCloud} alt="upload" className="t-animate-drag-and-drop" />
      <span className="t-flex t-items-center t-text-text-black">
        <span>Drop files to upload them to:</span>
        <img
          src={FolderType}
          alt="folder"
          className="t-h-6 t-w-6 t-ml-2 t-mr-1"
        />
        {children}
      </span>
    </div>
  );
};
