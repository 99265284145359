import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useEffect, useState } from "react";
import { useLazyGetFolderOrFileQuery } from "store/apis/previewUrl";
import { FileObject } from "types/Models/fileObject";

export const useMultipleFileDetails = (fileIds: string[]) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const [getFileData] = useLazyGetFolderOrFileQuery();
  const [fileDetail, setFilesDetail] = useState<FileObject[]>([]);

  useEffect(() => {
    const getAll = async () => {
      const res = await Promise.all(
        fileIds.map(async (file) => {
          try {
            return await getFileData({ groupId, fileId: file }).unwrap();
          } catch (error) {
            return null;
          }
        })
      );
      const files = res.filter((file) => file !== null) as FileObject[];
      setFilesDetail(files);
    };
    getAll();
  }, [JSON.stringify(fileIds)]);

  return fileDetail;
};
