export const InkleTaxCredits = () => (
  <svg
    width="168"
    height="56"
    viewBox="0 0 168 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.3657 24.1767C26.3657 26.4509 24.5221 28.2944 22.248 28.2944C19.9738 28.2944 18.1302 26.4509 18.1302 24.1767C18.1302 21.9025 19.9738 20.0589 22.248 20.0589C24.5221 20.0589 26.3657 21.9025 26.3657 24.1767Z"
      fill="url(#paint0_linear_2_2136)"
    />
    <path
      d="M34.6013 24.1767C34.6013 26.4509 32.7577 28.2944 30.4835 28.2944C28.2093 28.2944 26.3657 26.4509 26.3657 24.1767C26.3657 21.9025 28.2093 20.0589 30.4835 20.0589C32.7577 20.0589 34.6013 21.9025 34.6013 24.1767Z"
      fill="url(#paint1_linear_2_2136)"
    />
    <path
      d="M34.6013 32.4122C34.6013 34.6864 32.7577 36.53 30.4835 36.53C28.2093 36.53 26.3657 34.6864 26.3657 32.4122C26.3657 30.138 28.2093 28.2944 30.4835 28.2944C32.7577 28.2944 34.6013 30.138 34.6013 32.4122Z"
      fill="url(#paint2_linear_2_2136)"
    />
    <path
      d="M26.3657 32.4122C26.3657 34.6864 24.5221 36.53 22.248 36.53C19.9738 36.53 18.1302 34.6864 18.1302 32.4122C18.1302 30.138 19.9738 28.2944 22.248 28.2944C24.5221 28.2944 26.3657 30.138 26.3657 32.4122Z"
      fill="url(#paint3_linear_2_2136)"
    />
    <path
      d="M46.0711 20.065C46.0711 18.8121 46.8511 18 48.0769 18C49.2803 18 50.0604 18.8121 50.0604 20.065C50.0604 21.3179 49.2803 22.1531 48.0769 22.1531C46.8511 22.1531 46.0711 21.3179 46.0711 20.065ZM49.8598 37.884H46.294V23.9629H49.8598V37.884Z"
      fill="url(#paint4_linear_2_2136)"
    />
    <path
      d="M60.8 30.9002C60.8 28.8585 59.7749 27.5128 58.1925 27.5128C56.6993 27.5128 55.6073 28.9513 55.6073 30.9234V37.884H52.0415V23.9629H55.6073V25.587C56.0753 24.5429 57.4571 23.8005 58.8388 23.8005C62.2486 23.8005 64.3659 26.6311 64.3659 30.9002V37.884H60.8V30.9002Z"
      fill="url(#paint5_linear_2_2136)"
    />
    <path
      d="M70.083 37.884H66.5172V18.1624H70.083V30.2042L74.6072 23.9629H78.5073L73.9608 30.9234L78.9753 37.884H74.4511L70.083 31.6427V37.884Z"
      fill="url(#paint6_linear_2_2136)"
    />
    <path
      d="M83.876 37.884H80.3102V18.1624H83.876V37.884Z"
      fill="url(#paint7_linear_2_2136)"
    />
    <path
      d="M94.7172 33.6148H98.5059C97.5922 36.2135 95.2075 38 92.4886 38C88.5885 38 85.8473 35.0534 85.8473 30.877C85.8473 26.8399 88.6776 23.7773 92.4217 23.7773C96.1659 23.7773 98.9962 26.8399 98.9962 30.877C98.9962 31.3179 98.9739 31.7819 98.9071 32.1995H89.5914C89.9925 33.6148 91.0846 34.5197 92.4663 34.5197C93.5138 34.5197 94.3384 34.1485 94.7172 33.6148ZM89.5914 29.5313H95.2521C94.851 28.1624 93.8035 27.2807 92.4217 27.2807C91.04 27.2807 89.9925 28.1624 89.5914 29.5313Z"
      fill="url(#paint8_linear_2_2136)"
    />
    <path
      d="M107.629 38.0732H105.383V20.3932H111.56C115.159 20.3932 117.592 22.5148 117.592 25.9884C117.592 28.6508 116.115 30.6268 113.807 31.3548L118.279 38.0732H115.616L111.394 31.6668H107.629V38.0732ZM111.56 22.6188H107.629V29.4204H111.747C113.827 29.4204 115.346 28.006 115.346 25.9884C115.346 23.9708 113.807 22.6188 111.56 22.6188ZM126.476 29.0252L129.929 32.686C130.761 31.5004 131.385 30.1068 131.655 28.6716H133.901C133.527 30.6892 132.612 32.6236 131.405 34.2252L135.045 38.0732H132.175L130.012 35.7644C128.41 37.262 126.559 38.198 124.791 38.198C121.442 38.198 119.175 36.326 119.175 33.518C119.175 30.9804 120.839 29.4412 123.023 28.3596C121.983 27.1532 121.193 25.926 121.193 24.4492C121.193 21.9948 123.085 20.2892 125.831 20.2892C128.597 20.2892 130.844 22.6812 130.844 25.614H128.701C128.535 23.7004 127.287 22.39 125.789 22.39C124.375 22.39 123.481 23.3052 123.481 24.6156C123.481 25.7388 124.354 26.8412 126.476 29.0252ZM128.577 34.2668L124.437 29.8988C122.482 30.7932 121.463 31.9788 121.463 33.3932C121.463 34.9324 122.794 36.0348 124.874 36.0348C126.101 36.0348 127.412 35.3484 128.577 34.2668ZM142.046 38.0732H136.575V20.3932H141.858C147.474 20.3932 150.594 23.5548 150.594 29.2332C150.594 34.87 147.495 38.0732 142.046 38.0732ZM141.858 22.6188H138.822V35.8268H142.046C146.06 35.8268 148.348 33.4348 148.348 29.2332C148.348 24.9692 146.039 22.6188 141.858 22.6188Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_2136"
        x1="18.1302"
        y1="24.1767"
        x2="26.3657"
        y2="24.1767"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_2136"
        x1="26.3657"
        y1="24.1767"
        x2="34.6013"
        y2="24.1767"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_2136"
        x1="26.3657"
        y1="32.4122"
        x2="34.6013"
        y2="32.4122"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_2136"
        x1="18.1302"
        y1="32.4122"
        x2="26.3657"
        y2="32.4122"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2_2136"
        x1="46.0711"
        y1="28"
        x2="98.9962"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2_2136"
        x1="46.0711"
        y1="28"
        x2="98.9962"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2_2136"
        x1="46.0711"
        y1="28"
        x2="98.9962"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2_2136"
        x1="46.0711"
        y1="28"
        x2="98.9962"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2_2136"
        x1="46.0711"
        y1="28"
        x2="98.9962"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
