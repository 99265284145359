import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { etdUpdateValidation } from "formValidations/EtdUpdateReasonSchema";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import {
  useUpdateTaskAssigneeAdminMutation,
  useUpdateTaskAssigneeProviderMutation,
} from "store/apis/serviceTeam";
import { BackendError } from "types/utils/error";

export const ETDUpdateReason = ({
  isOpen,
  close,
  selectedDate,
  taskId,
}: {
  isOpen: boolean;
  close: () => void;
  selectedDate?: string;
  taskId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [updateTaskDeliveryTimeAdmin, { isLoading }] =
    useUpdateTaskAssigneeAdminMutation();
  const [updateTaskDeliveryTime, { isLoading: isUpdatingDeliveryTime }] =
    useUpdateTaskAssigneeProviderMutation();
  const { isAdmin } = useRoleBasedView();

  let updateTaskAssignee = isAdmin
    ? updateTaskDeliveryTimeAdmin
    : updateTaskDeliveryTime;

  const updateETD = async ({
    date,
    reason,
  }: {
    date: string;
    reason: string;
  }) => {
    try {
      const estimatedDeliveryTime = dayjs(date).format(YYYY_MM_DD);
      await updateTaskAssignee({
        taskId,
        estimatedDeliveryTime,
        reason,
      }).unwrap();

      successToast({ message: "ETD set successfully" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        initialValues={{
          date: selectedDate || "",
          reason: "",
        }}
        onSubmit={updateETD}
        validationSchema={etdUpdateValidation}
      >
        {({ submitForm }) => {
          return (
            <Modal.Content useCustomOverlay size="small">
              <Modal.Header>
                <Modal.Title>Reason For Update</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form className="t-m-0 t-space-y-4">
                  <div>
                    <Field name="date">
                      {({ field }: FieldProps) => (
                        <DateInput
                          {...field}
                          saveFormat={YYYY_MM_DD}
                          portalId="etd-date"
                          label="Selected ETD"
                          block
                          minDate={dayjs().toDate()}
                          required
                        />
                      )}
                    </Field>
                  </div>
                  <TextArea required name="reason" label="Reason" />
                </Form>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button type="button" onClick={close}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  customType="primary"
                  onClick={submitForm}
                  disabled={isLoading || isUpdatingDeliveryTime}
                  isLoading={isLoading || isUpdatingDeliveryTime}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
