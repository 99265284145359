import classNames from "classnames";
import {
  DD_MMM_YYYY,
  FILTER_DATE_INPUT_FORMAT,
  YYYY_MM_DD,
} from "constants/date";
import dayjs from "dayjs";
import { Field, FieldInputProps, getIn, useFormikContext } from "formik";
import { ReactElement, ReactNode } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { checkForMonthDisable, CustomHeader } from "../DatePicker/DatePicker";
import {
  BareInput,
  ErrorMessage,
  InputProps,
  Label,
} from "../TextInput/TextInput";
import { createPortal } from "react-dom";
import { CalendarBlank } from "components/icons/CalendarBlank";

type DateInputProps = {
  label?: ReactNode;
  placeholder?: string;
  required?: boolean;
  onDateChange?: (v: Date) => void;
  dateFormat?: string;
  saveFormat?: string;
  customDatePickerInputTrigger?: ReactNode;
} & FieldInputProps<any> &
  InputProps &
  ReactDatePickerProps;

const CustomInput = ({
  required,
  label,
  placeholder,
  hideError,
  ...props
}: DateInputProps) => {
  const { errors, touched } = useFormikContext();
  let hasError = getIn(errors, props.name) && getIn(touched, props.name);

  return (
    <div className="t-relative t-block t-w-full">
      <div className="t-flex t-items-center">
        {Boolean(label) && (
          <Label htmlFor={props.name} required={required}>
            {label}
          </Label>
        )}
      </div>
      <BareInput
        {...props}
        label={label}
        placeholder={placeholder}
        required={required}
        customType={hasError ? "error" : props.customType}
        id={props.name}
      />
      {!hideError && hasError && (
        <ErrorMessage>
          {/* @ts-ignore */}
          {getIn(errors, props.name)}
        </ErrorMessage>
      )}
    </div>
  );
};

export const DateInput = ({
  label,
  required = false,
  placeholder = DD_MMM_YYYY,
  onDateChange,
  dateFormat = FILTER_DATE_INPUT_FORMAT,
  saveFormat = DD_MMM_YYYY,
  showMonthDropdown = true,
  showYearDropdown = true,
  customDatePickerInputTrigger,
  ...props
}: DateInputProps) => {
  const excludeMonths = props.excludeDates?.map((date) => dayjs(date).month());
  const excludeYear = props.excludeDates?.map((date) => dayjs(date).year());
  const { setFieldValue } = useFormikContext();

  const onChange = (date: Date) => {
    if (props.name) {
      const savingDate = date ? dayjs(date).format(saveFormat) : null;
      setFieldValue(props.name, savingDate);
      onDateChange?.(date);
    }
  };

  const finalPortalId = `date-portal-${props.portalId}`;

  return (
    <>
      {/* We can't style the portal created by date picker, that's why */}
      {props.portalId &&
        createPortal(
          <div className="t-pointer-events-auto" id={finalPortalId} />,
          document.body
        )}
      <ReactDatePicker
        {...props}
        portalId={finalPortalId}
        renderCustomHeader={(p) => (
          <CustomHeader
            {...p}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            showMonthYearPicker={props.showMonthYearPicker}
            maxDate={props.maxDate}
            minDate={props.minDate}
          />
        )}
        dayClassName={(day) => {
          const today = dayjs().format(YYYY_MM_DD);
          const dayDate = dayjs(day).format(YYYY_MM_DD);
          /* @tw */
          return classNames(
            "all:unset t-h-6 t-w-6 t-cursor-pointer t-flex t-justify-center  t-text-text-100 t-items-center hover:t-bg-purple-0 t-text-body-sm t-select-none t-rounded aria-disabled:!t-text-text-30 aria-disabled:!t-bg-surface-grey aria-disabled:t-pointer-events-none aria-selected:!t-text-text aria-selected:!t-bg-purple aria-selected:hover:!t-bg-purple",
            {
              /* @tw */
              "t-border t-border-solid t-border-purple": today === dayDate,
            }
          );
        }}
        weekDayClassName={() => {
          /* @tw */
          return classNames(
            "all:unset t-w-6 t-flex t-justify-center t-items-center t-text-body-sm t-select-none"
          );
        }}
        /* @tw */
        calendarClassName="all:unset !t-bg-white t-flex t-flex-col t-gap-4 [&>span]:t-hidden"
        /* @tw */
        popperClassName={classNames(
          "all:unset reactDatePicker t-shadow-secondary t-bg-white t-z-datePicker t-rounded",
          {
            "t-w-[280px]": !props.showTimeSelectOnly,
          }
        )}
        /* @tw */
        wrapperClassName={classNames("all:unset t-relative", {
          "t-w-full": props.block,
        })}
        monthClassName={(date) => {
          const excludedMonthDisabled =
            excludeMonths?.includes(date.getMonth()) &&
            excludeYear?.includes(date.getFullYear());

          const {
            disableMaxMonthYear,
            disableMinMonthYear,
            minDateMonth,
            maxDateMonth,
          } = checkForMonthDisable({
            date,
            minDate: props.minDate,
            maxDate: props.maxDate,
          });

          const maxMonthDisabled =
            date.getMonth() > maxDateMonth! && disableMaxMonthYear;

          const minMonthDisabled =
            date.getMonth() < minDateMonth! && disableMinMonthYear;

          /* @tw */
          return classNames(
            "all:unset t-h-6 t-cursor-pointer !t-flex t-justify-center t-items-center hover:t-bg-purple-0 t-text-body-sm t-select-none t-rounded  t-text-text-100 aria-disabled:!t-text-text-30 aria-disabled:!t-bg-surface-grey aria-disabled:t-pointer-events-none aria-selected:!t-text-text aria-selected:!t-bg-purple-50 aria-selected:hover:!t-bg-purple-50 !t-m-0",
            {
              "!t-text-text-30 !t-bg-surface-grey t-pointer-events-none":
                excludedMonthDisabled || maxMonthDisabled || minMonthDisabled,
            }
          );
        }}
        showPopperArrow={false}
        onChange={onChange}
        name={props.name}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        // portalId="root-portal-for-calendar"
        required={required}
        customInput={
          customDatePickerInputTrigger || (
            <CustomInput
              label={label}
              required={required}
              placeholder={placeholder}
              {...props}
            />
          )
        }
        {...(props.value ? { selected: new Date(props.value) } : {})}
        autoComplete="off"
        preventOpenOnFocus={true}
        value={undefined}
      />
    </>
  );
};
