import Loader from "components/design/loader";
import { useEffect } from "react";

export const Payment3DSConfirmation = () => {
  useEffect(() => {
    window.top?.postMessage("3DS-authentication-complete");
  }, []);

  return (
    <div className="t-h-screen t-w-screen t-flex t-justify-center t-items-center">
      <Loader />
    </div>
  );
};
