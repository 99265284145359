import { Button } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import { Info } from "components/icons/Info";
import { useModal } from "hooks/useModal";
import { Transactions } from "types/Models/books";
import { LinkTransactionModal } from "./LinkTransactionModal";

export const MoneyInTransitBanner = ({
  transactions,
}: {
  transactions?: Transactions[];
}) => {
  const { isOpen, open, close, toggle } = useModal();

  return (
    <>
      <div className="t-flex t-justify-between t-p-3 t-border t-border-solid t-bg-red-0 t-border-red-10 t-rounded-lg">
        <div className="t-flex t-gap-2 t-items-center">
          <Info color="#BF1A2F" stroke="1.5" />
          <div className="t-text-body-sm t-text-text-60">
            There are some pending transfers as on selected date. Please change
            dates or categories to resolve.
          </div>
        </div>
        <div className="t-flex t-gap-2 t-items-center">
          <Button customType="danger-link" size="small" onClick={open}>
            View Transfers
          </Button>
          <button className="all:unset" onClick={() => {}}>
            <Cross />
          </button>
        </div>
      </div>
      <LinkTransactionModal
        isOpen={isOpen}
        close={close}
        toggle={toggle}
        transactions={transactions}
      />
    </>
  );
};
