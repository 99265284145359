import React from "react";
import { Modal } from "react-bootstrap";

//components
import { Button } from "../../design/button";
import EntityRelationShipOption from "./entityRelationShipOption";

//style
import "../../../static/styles/components/entity.css";

const DefinedRelationShip = ({
  defineModalShow,
  setShowDefineRelationshipModal,
}) => {
  const handleClose = () => {
    setShowDefineRelationshipModal(false);
  };

  return (
    <Modal
      className="defineRelationShipModal"
      show={defineModalShow}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title className="entityPopupTitle">
          Define Relationship and IP Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="titleRow">
          <div className="entityTitle">Entity</div>
          <div className="typeTitle">Type</div>
          <div className="relationShipTitle">Relationship</div>
        </div>
        <div className="titleRow">
          <div className="entityTitle">Podd inc.</div>
          <div className="typeTitle">
            <EntityRelationShipOption /> <span className="ofText">of</span>
          </div>
          <div className="relationShipTitle">
            <EntityRelationShipOption />
          </div>
        </div>
        <div className="titleRow">
          <div className="entityTitle">Podd India Pvt. Ltd.</div>
          <div className="typeTitle">
            <EntityRelationShipOption /> <span className="ofText">of</span>
          </div>
          <div className="relationShipTitle">
            <EntityRelationShipOption />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="small" onClick={handleClose}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DefinedRelationShip;
