import { AvatarGroup } from "components/DesignSystem/AvatarGroup/AvatarGroup";
import { TicketAssigneeDropdown } from "./TicketAssigneeDropdown";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { CellContext } from "react-table-8.10.7";
import { ChatTicket } from "types/Models/chatTicket";

export const TicketAssigneeCell = (
  info: CellContext<ChatTicket, ChatTicket["assignee"]>
) => {
  const currentTicketAssignees = info.row.original.assignee || [];
  return (
    <div className="t-flex t-items-center" key={info.row.original.uuid}>
      <div className="t-min-w-14" onClick={(e) => e.stopPropagation()}>
        <AvatarGroup max={2} variant="no-fill">
          {currentTicketAssignees.map(
            ({ assignee_name, assignee_profile_url, assignee_uuid }) => (
              <div
                className="t-rounded-full t-ring-white t-ring-2"
                key={assignee_uuid}
              >
                <Avatar alt={assignee_name} src={assignee_profile_url || ""} />
              </div>
            )
          )}
        </AvatarGroup>
      </div>
      <TicketAssigneeDropdown
        currentTicketAssignees={currentTicketAssignees}
        ticketId={info.row.original.uuid}
      />
    </div>
  );
};
