import { sendSlackCode } from "apis/homeApis";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { closeSlackModal, openSlackModal } from "store/slices/slack";
import { RootState } from "store/store";

export const SlackConnectedModal = () => {
  const showSlackModal = useSelector(
    (state: RootState) => state.slack.showSlackModal
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { alertToast } = useToast();
  let query = useQuery();
  const code = query.get("code");

  useEffect(() => {
    const sendCode = async () => {
      try {
        await sendSlackCode(code);
        dispatch(openSlackModal());
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    };

    if (code) {
      sendCode();
    }
  }, [code, history]);

  return (
    <Modal.Root
      open={showSlackModal}
      onOpenChange={() => dispatch(closeSlackModal())}
    >
      <Modal.Content size="small">
        <Modal.Header>
          <Modal.Title>Slack connected</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-30 t-mb-8">
            Inkle has been successfully added to your Slack workspace.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end">
            <Button
              customType="primary"
              onClick={() => dispatch(closeSlackModal())}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
