import { usePageTitle } from "hooks/usePageTitle";
import React from "react";

//css
import "../../static/styles/components/handBook.css";

const Handbook = () => {
  usePageTitle("Handbook");
  let url =
    "https://docs.google.com/document/d/1GdskVSJhTD3MGJcacp974w8TvvrNlEoP8-k8lHjyQsI/edit?usp=sharing";

  return (
    <div className="handBook">
      <div className="t-absolute t-w-full t-h-[60px] t-bg-surface"></div>
      <iframe
        className="addMargin"
        title="handbook"
        src={`${url}`}
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};

export default Handbook;
