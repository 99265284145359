export const SendEmail = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 3.9375L9 10.125L2.25 3.9375"
        stroke={color}
        strokeWidth="1.15625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 3.9375H15.75V13.5C15.75 13.6492 15.6907 13.7923 15.5852 13.8977C15.4798 14.0032 15.3367 14.0625 15.1875 14.0625H2.8125C2.66332 14.0625 2.52024 14.0032 2.41475 13.8977C2.30926 13.7923 2.25 13.6492 2.25 13.5V3.9375Z"
        stroke={color}
        strokeWidth="1.15625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.76953 9L2.42578 13.9008"
        stroke={color}
        strokeWidth="1.15625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5742 13.9008L10.2305 9"
        stroke={color}
        strokeWidth="1.15625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
