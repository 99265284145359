import { AdminCallCardStatusType } from "components/ChatCard/AdminCallCard/AdminCallCard";
import qs from "qs";
import { MessageUIComponentProps } from "stream-chat-react";
import { emptyApi } from "./emptyApi";

type Result = {
  member_id: string;
  member_name: string;
  member_image_url: string;
  member_assigned_status: boolean;
};
export type AgentDetails = {
  called_agent: {
    name: string;
    email: string;
    profile_id: string;
  };
  company_group_name: string;
  company_group_user_name: string;
  company_group_user_phone: string;
  company_group_uuid: string;
  created_at: string;
  uuid: string;
  call_type: string;
  company_group_user_map_id: string;
  time: string;
};

export type CallHistoryResponse = {
  total_pages: number;
  total_count: number;
  current_page: number;
  per_page: number;
  calls: AgentDetails[];
};

type AllSharedMessageResponse = {
  messages_forwarded_on_whatsapp: MessageUIComponentProps["message"][];
  forwarded_messages_count: number;
};

export const chatApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    createMessageDraft: build.mutation<
      { message_draft: string },
      { channelUrl: string; message: string | null }
    >({
      query: ({ channelUrl, message }) => ({
        url: `/api/inkle/messaging/channel/${channelUrl}/message_draft/`,
        body: {
          message_draft: message,
        },
        method: "POST",
      }),
    }),
    getDraftMessage: build.query<
      { message_draft: string },
      { channelUrl: string }
    >({
      query: ({ channelUrl }) => ({
        url: `/api/inkle/messaging/channel/${channelUrl}/message_draft/`,
      }),
    }),

    getUnresolvedTicketMessageIds: build.query<
      {
        ticket_messages: {
          ticket_uuid: string;
          message_id: string;
        }[];
      },
      { channelUrl: string }
    >({
      query: ({ channelUrl }) => ({
        url: `/api/inkle/tasks/channel/${channelUrl}/ticket-messages/`,
      }),
      providesTags: ["ChatTickets"],
    }),

    getListOfAllPhoneCalls: build.query<
      CallHistoryResponse,
      {
        pageNum: number;
        agents: string | null;
        callActivity: string | null;
        endDate: string | null;
        groups: string | null;
        startDate: string | null;
        searchQuery: string | null;
      }
    >({
      query: ({
        pageNum,
        agents,
        callActivity,
        endDate,
        groups,
        startDate,
        searchQuery,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            agent_profile_id: agents,
            call_type: callActivity,
            to_date: endDate,
            company_group_id: groups,
            from_date: startDate,
            search_term: searchQuery,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/company/v2/phone_call/get/${queryUrl}`,
        };
      },
      providesTags: ["CALL_HISTORY"],
    }),

    makeCustomerPhoneCall: build.mutation<AgentDetails, { groupId: string }>({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/company/v2/group/${groupId}/phone_call/`,
          method: "POST",
        };
      },
    }),
    toggleChannelReplied: build.mutation<
      { channel_url: string; needs_reply: boolean },
      { channelId: string; channelNeedsReply: boolean }
    >({
      query: ({ channelId, channelNeedsReply }) => {
        return {
          url: `/api/inkle/messaging/channel/${channelId}/reply_status/`,
          method: "POST",
          body: {
            channel_needs_reply: channelNeedsReply,
          },
        };
      },
    }),
    sendAnnouncement: build.mutation({
      query: ({ message, group_ids }) => {
        return {
          url: `/api/inkle/messaging/announcements/`,
          method: "POST",
          body: { message, group_ids },
        };
      },
    }),
    deleteMessage: build.mutation<
      any,
      { channel_id: string; message_id: string }
    >({
      query: ({ channel_id, message_id }) => {
        return {
          url: `/api/inkle/messaging/channel/${channel_id}/message/${message_id}/`,
          method: "DELETE",
        };
      },
    }),
    getAnnouncementsGroups: build.query<
      {
        group_name: string;
        group_uuid: string;
      }[],
      {
        searchTerm?: string | null;
        taskTemplateIds?: string | null;
        taskStatusIds?: string | null;
        seasons?: string | null;
      }
    >({
      query: ({ searchTerm, taskTemplateIds, taskStatusIds, seasons }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
            task_template_ids: taskTemplateIds,
            task_status_ids: taskStatusIds,
            seasons: seasons,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/messaging/announcements/${queryUrl}`,
        };
      },
    }),

    forwardMessage: build.mutation<
      void,
      { channelIds: string[]; messageId: string; message?: string }
    >({
      query: ({ messageId, channelIds, message }) => {
        return {
          url: `/api/inkle/messaging/message/${messageId}/`,
          method: "POST",
          body: {
            channel_ids: channelIds,
            message_type: "FORWARD",
            text: message,
          },
        };
      },
    }),

    getMembersCanBeAddedToChannel: build.query<
      {
        name: string;
        profile_url: string | null;
        email: string;
        member_uuid: string;
        member_id: string;
      }[],
      { channelId: string; groupId: string }
    >({
      query: ({ channelId, groupId }) => {
        return {
          url: `/api/inkle/messaging/group/${groupId}/channel/${channelId}/possible-members/`,
        };
      },
    }),

    addMemberToChannel: build.mutation<
      void,
      { channelId: string; memberId: string; groupId: string }
    >({
      query: ({ channelId, memberId, groupId }) => {
        return {
          url: `/api/inkle/messaging/group/${groupId}/channel/${channelId}/member/${memberId}/invite/`,
          method: "POST",
        };
      },
    }),

    getAllChannelMember: build.query<
      {
        name: string;
        profile_url: string | null;
        email: string;
        member_uuid: string;
        mobile: string | null;
        profile_id: string;
      }[],
      { channelId: string; groupId: string }
    >({
      query: ({ channelId, groupId }) => {
        return {
          url: `/api/inkle/messaging/group/${groupId}/channel/${channelId}/members/`,
        };
      },
    }),
    getMessageDetails: build.query<
      {
        forwarded_on_whatsapp: {
          forwarded_by: {
            name: string;
            email: string;
          };
          forwarded_to: {
            name: string;
            email: string;
            to_mobile: string;
            profile_url?: string;
          };
          delivery_status: string;
          time_stamp: Date;
        }[];
        forwarded_on_email: {
          forwarded_by: {
            name: string;
            email: string;
          };
          forwarded_to: {
            name: string;
            email: string;
            profile_url?: string;
          };
          delivery_status: string;
          time_stamp: Date;
        }[];
        forwarded_on_slack: [
          {
            forwarded_to: {
              name: string;
              email: string;
              profile_url: string;
            };
            delivery_status: string;
            time_stamp: Date;
          }
        ];
      },
      {
        channelId: string;
        groupId: string;
        messageId: string;
      }
    >({
      query: ({ channelId, groupId, messageId }) => {
        return {
          url: `/api/inkle/messaging/group/${groupId}/channel/${channelId}/message/${messageId}/details/`,
        };
      },
      providesTags: ["MESSAGE_DETAILS"],
    }),

    forwardMessageToWhatsapp: build.mutation<
      void,
      {
        channelId: string;
        messageId: string;
        groupId: string;
        payload: {
          text: string;
          to_users: {
            email: string;
            mobile: string;
          }[];
        };
      }
    >({
      query: ({ channelId, messageId, groupId, payload }) => {
        return {
          url: `/api/inkle/messaging/group/${groupId}/channel/${channelId}/message/${messageId}/forward-to-whatsapp/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["MESSAGE_DETAILS", "ALL_SHARED_MESSAGE"],
    }),

    getAllSharedMessage: build.query<
      AllSharedMessageResponse,
      { channelId: string; groupId: string }
    >({
      query: ({ channelId, groupId }) => {
        return {
          url: `/api/inkle/messaging/group/${groupId}/channel/${channelId}/details`,
        };
      },
      providesTags: ["ALL_SHARED_MESSAGE"],
    }),
    makePhoneCallFromCRM: build.mutation<
      AgentDetails,
      { groupUserId: string; mobile: string; channelId?: string }
    >({
      query: ({ groupUserId, mobile, channelId }) => {
        return {
          url: `/api/inkle/serviceteams/call/`,
          method: "POST",
          body: {
            group_user_id: groupUserId,
            mobile_number: mobile,
            channel_url: channelId,
          },
        };
      },
      invalidatesTags: ["CALL_HISTORY"],
    }),
    getChatSLAMetrics: build.query<
      {
        average_chat_response_time: {
          hours: number;
          minutes: number;
        };
      },
      { duration: number }
    >({
      query: ({ duration }) => {
        return {
          url: `/api/inkle/messaging/sla_metrics/?duration=${duration}`,
        };
      },
    }),

    getCallDetails: build.query<
      { notes: string; call_status: AdminCallCardStatusType },
      { groupUserPhoneCallId?: string }
    >({
      query: ({ groupUserPhoneCallId }) => {
        return {
          url: `/api/inkle/serviceteams/call/${groupUserPhoneCallId}/`,
        };
      },
      providesTags: (result) => (result ? ["CALL_DETAILS"] : []),
    }),

    editCallDetails: build.mutation<
      { notes: string },
      { groupUserPhoneCallId?: string; notes: string; callConnected?: boolean }
    >({
      query: ({ groupUserPhoneCallId, notes, callConnected }) => {
        return {
          url: `/api/inkle/serviceteams/call/${groupUserPhoneCallId}/`,
          method: "PATCH",
          body: { notes, call_connected: callConnected },
        };
      },
      invalidatesTags: (result) => (result ? ["CALL_DETAILS"] : []),
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateMessageDraftMutation,
  useGetDraftMessageQuery,
  useGetUnresolvedTicketMessageIdsQuery,
  useGetListOfAllPhoneCallsQuery,
  useMakeCustomerPhoneCallMutation,
  useToggleChannelRepliedMutation,
  useDeleteMessageMutation,
  useGetAnnouncementsGroupsQuery,
  useSendAnnouncementMutation,
  useForwardMessageMutation,
  useGetMembersCanBeAddedToChannelQuery,
  useAddMemberToChannelMutation,
  useGetAllChannelMemberQuery,
  useForwardMessageToWhatsappMutation,
  useGetMessageDetailsQuery,
  useGetAllSharedMessageQuery,
  useMakePhoneCallFromCRMMutation,
  useGetChatSLAMetricsQuery,
  useGetCallDetailsQuery,
  useEditCallDetailsMutation,
} = chatApi;
