import {
  deleteMerchantFile,
  updateMerchantForm,
  updateTaskMerchant,
} from "apis/merchants";
import { Divider } from "components/design/Divider";
import { ClockWiseWait } from "components/icons/ClockWiseWait";
import { NOT_SPECIFIED } from "constants/wSeriesFormTypes";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import Check from "static/images/Checks.svg";
import "static/styles/components/wSeriesFormModal.css";
import { FileObject } from "types/Models/fileObject";
import { Merchant, W_FORM_TYPES } from "types/Models/merchant";
import { Button } from "./design/button";
import CommonModal from "./design/modal";
import { DocumentPreviewModal } from "./PreviewModal";
import { WSeriesEmailForm } from "./WSeriesEmailForm";
import { WSeriesUploadForm } from "./WSeriesUploadForm";

type WSeriesFormModalProps = {
  taskId: string;
  groupId: string;
  merchant: Merchant;
  updateMerchant: (merchant: Merchant) => void;
  editable?: boolean;
  season: string;
};

export const WSeriesFormModal = ({
  merchant,
  groupId,
  taskId,
  updateMerchant,
  editable = true,
  season,
}: WSeriesFormModalProps): JSX.Element => {
  const { w_form_document, form_status, w_form_choice, name, email, uuid } =
    merchant || {};
  const { alertToast, successToast } = useToast();
  const openPreview = useDocPreview();
  const [showModal, setModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  let icon = null;
  const hasSentWSeriesForm = form_status === "SENT";

  if (hasSentWSeriesForm) {
    icon = <ClockWiseWait />;
  }

  if (form_status === "RECEIVED") {
    icon = <img src={Check} alt="check" />;
  }

  const onEmailSubmit = async ({ email }: { email: string }) => {
    try {
      if (taskId) {
        const { data } = await updateTaskMerchant({
          taskId,
          groupId,
          merchantId: uuid,
          email,
          season,
        });
        updateMerchant(data);
      } else {
        const { data } = await updateMerchantForm({
          groupId,
          merchantId: uuid,
          email,
          season,
        });
        updateMerchant(data);
      }
      setModal(false);
      successToast({ message: "Email has been sent" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const onFormTypeSubmit = async ({
    form_type,
    file,
  }: {
    form_type: W_FORM_TYPES;
    file?: File;
  }) => {
    try {
      if (taskId) {
        const { data } = await updateTaskMerchant({
          taskId,
          groupId,
          merchantId: uuid,
          form_type,
          file,
          season,
        });
        updateMerchant(data);
      } else {
        const { data } = await updateMerchantForm({
          groupId,
          merchantId: uuid,
          form_type,
          file,
          season,
        });
        updateMerchant(data);
      }
      setModal(false);
      successToast({ message: "Your form has been saved" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const onDelete = async () => {
    try {
      setDeleting(true);
      const { data } = await deleteMerchantFile({
        groupId,
        merchantId: uuid,
        season: merchant.w_form_season,
      });
      updateMerchant(data);
      setDeleting(false);
      successToast({ message: "Form has been removed" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
      setDeleting(false);
    }
  };

  const fileToShow = w_form_document;
  const nonFormText = form_status === "SENT" ? "W-Series" : "Add form";
  const buttonText =
    w_form_choice === NOT_SPECIFIED ? nonFormText : w_form_choice;

  const onClick = () => {
    if (!editable && w_form_document) {
      openPreview(w_form_document.uuid);

      return;
    }
    setModal(true);
  };

  return (
    <>
      <Button
        size="medium"
        color="bordered"
        onClick={onClick}
        addClassName="align-center"
      >
        <span className="d-inline-block me-1">{icon}</span>
        {buttonText}
      </Button>
      {/* @ts-ignore */}
      <CommonModal
        className="w-series-form-modal"
        show={showModal}
        modalTitle={
          <span className="w-series-form-modal-title">Contractor | {name}</span>
        }
        closeModal={() => setModal(false)}
        body={
          <div className="w-series-form-modal-body d-flex flex-column gap-4">
            <WSeriesEmailForm
              formStatus={form_status}
              onSubmit={onEmailSubmit}
              email={email}
              label="Request contractor for W-form"
            />
            <div className="w-75 mx-auto">
              <Divider>Or</Divider>
            </div>

            <WSeriesUploadForm
              isDeleting={isDeleting}
              onDelete={onDelete}
              file={fileToShow}
              onSubmit={onFormTypeSubmit}
              openPreview={() => openPreview(w_form_document?.uuid!)}
              form_document={w_form_document}
              w_form_choice={w_form_choice}
            />
          </div>
        }
      />

      <DocumentPreviewModal />
    </>
  );
};
