import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";
import { Contact, VentureCapitalist } from "./vcCrm";

export type Intro = {
  uuid: string;
  notes: string;
  deck_link: string;
  status: "INKLE_APPROVED" | "VC_APPROVED" | "INITIATED";
  vc_interest: "Accepted" | "Rejected";
  comment: string;
  company_group: {
    uuid: string;
    name: string;
  };
  raised_by: {
    uuid: string;
    name: string;
  };
  vc: VentureCapitalist;
};

export const vcIntroApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getVcIntros: build.query<
      Pagination & { vc_intros: Intro[] },
      { groupId: string }
    >({
      query: ({ groupId }) => `api/inkle/reference/group/${groupId}/vc/intros/`,
      providesTags: () => ["VC_INTROS"],
    }),

    acceptVcIntro: build.mutation<Intro, { groupId: string; introId: string }>({
      query: ({ groupId, introId }) => ({
        url: `api/inkle/reference/group/${groupId}/vc/${introId}/intro/`,
        method: "POST",
        body: {
          req_status: "SUCCESS",
        },
      }),
      invalidatesTags: (res) => (res ? ["VC_INTROS"] : []),
    }),
  }),
});

export const { useGetVcIntrosQuery, useAcceptVcIntroMutation } = vcIntroApis;
