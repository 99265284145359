import React from "react";
import cx from "classnames";

// images
import GoogleIcon from "../static/images/Google.svg";

const GoogleBtn = ({ onClick, ...props }) => {
  return (
    <div
      className={cx("signinPad googleBtn t-ml-5", props.className || "")}
      onClick={onClick}
    >
      <span className="googleLogo">
        <img className="googleImg" src={GoogleIcon} alt="google icon" />
      </span>
      <span className="googleBtnText">Continue With Google</span>
    </div>
  );
};

export default GoogleBtn;
