import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { ArrowRight } from "components/icons/ArrowRight";
import { LinkBankAccount } from "components/LinkBankAccount";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { AccountCard } from "pages/Books/DataSources/DataSourcesList";
import { useDispatch } from "react-redux";
import BankCircular from "static/images/BankCircular.svg";
import Plaid from "static/images/Plaid.svg";
import { useGetAllConnectionsQuery } from "store/apis/booksConnections";
import { openAddMaualBankModal } from "store/slices/connections";

export const ConnectionsModal = ({
  onConnect,
  close,
  entityIdFromParent,
  showAddManualBank = true,
  onComplete,
}: {
  onConnect: ({
    connectionId,
    invokedFrom,
    entityIdInArg,
  }: {
    connectionId: string;
    invokedFrom: string;
    entityIdInArg?: string;
  }) => void;
  close: () => void;
  entityIdFromParent?: string;
  showAddManualBank?: boolean;
  onComplete?: () => void;
}) => {
  const { isCpa } = useRoleBasedView();
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: connections, isLoading } = useGetAllConnectionsQuery(
    {
      groupId: groupId!,
      entityId: entityId! || entityIdFromParent!,
    },
    { skip: !groupId || (!entityId && !entityIdFromParent) }
  );

  return (
    <div>
      <p className="t-mb-2 t-text-body-lg">Banking</p>
      <div className="t-flex t-flex-col t-gap-3">
        {connections?.map((connection) => (
          <AccountCard
            key={connection.uuid}
            logo={
              <img
                className="t-w-8 t-h-8 t-rounded-full"
                src={connection.logo_url}
                alt=""
              />
            }
            title={connection.name}
            description="Direct API integration for fetching transactions data"
            CTA={
              <>
                {!connection.is_connection_available ? (
                  <Button
                    size="small"
                    onClick={() =>
                      onConnect({
                        connectionId: connection.uuid,
                        invokedFrom: isCpa
                          ? `/books/data-sources?company=${groupId}&entity=${entityId}`
                          : `/books/data-sources?entity=${entityId}`,
                      })
                    }
                  >
                    Connect <ArrowRight color="currentColor" />
                  </Button>
                ) : (
                  <Tag tagType="green">
                    <span className="t-text-body-sm">Connected</span>
                  </Tag>
                )}
              </>
            }
          />
        ))}
        <AccountCard
          logo={
            <img src={Plaid} className="t-rounded-full t-w-6 t-h-6" alt="" />
          }
          title="Other banking institutions"
          description="Connect other banks securely via Plaid"
          CTA={
            groupId && (
              <LinkBankAccount
                groupId={groupId}
                onComplete={onComplete}
                entityId={entityId}
              >
                {({ ready, connectPlaid }) => (
                  <Button
                    size="small"
                    onClick={(e) => {
                      if (document.body) {
                        document.body.style.pointerEvents = "auto";
                      }
                      connectPlaid(e);
                    }}
                    disabled={!ready}
                  >
                    Connect <ArrowRight color="currentColor" />
                  </Button>
                )}
              </LinkBankAccount>
            )
          }
        />
        {showAddManualBank && (
          <AccountCard
            logo={
              <img
                src={BankCircular}
                className="t-rounded-full t-w-8 t-h-8 "
                alt=""
              />
            }
            title="Add bank/card"
            description={
              <>
                Manually enter bank account or
                <br />
                credit card details.
              </>
            }
            CTA={
              groupId && (
                <Button
                  size="small"
                  onClick={() => {
                    close();
                    dispatch(openAddMaualBankModal());
                  }}
                >
                  Add details <ArrowRight color="currentColor" />
                </Button>
              )
            }
          />
        )}
      </div>
    </div>
  );
};
