import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { billingAddressSchema } from "formValidations/billingInfo";
import { useBillingtag } from "hooks/useBillingtag";
import { useServiceTeamId } from "hooks/useServiceTeamId";
import { useToast } from "hooks/useToast";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { useUpdatePracticeAutofillMutation } from "store/apis/practiceAutofill";
import { useUpdateBillingInfoMutation } from "store/apis/practiceBilling";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const EditBillingAddress = ({
  close,
  isOpen,
  autofillAddress,
  invoice_emails,
  autoFillId,
}: ModalProps & {
  autoFillId: string;
  invoice_emails: string[];
  autofillAddress: {
    country: string;
    street_address: string;
    state: string;
    city: string;
    zipcode: string;
  };
}) => {
  const serviceTeamId = useServiceTeamId();
  const { successToast, alertToast } = useToast();
  const [updateBillingInfo] = useUpdateBillingInfoMutation();
  const [updateAutofill] = useUpdatePracticeAutofillMutation();
  const { uuid: billingTagId } = useBillingtag();

  const { data: countries, isLoading: isCountriesLoading } =
    useGetCountriesDataQuery();

  const updateBillingInfoEmails = async ({
    invoice_emails,
  }: {
    invoice_emails: string[];
  }) => {
    try {
      await updateBillingInfo({
        serviceTeamId,
        invoiceEmails: invoice_emails,
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const updateBillingAddress = async ({
    autoFillId,
    ...address
  }: {
    autoFillId: string;
    country: string;
    street_address: string;
    state: string;
    city: string;
    zipcode: string;
  }) => {
    try {
      await updateAutofill({
        serviceTeamId,
        autoFillId: autoFillId,
        autofillKey: "addresses",
        autoFill: { ...address, tag_id: billingTagId },
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onSubmit = async ({
    autoFillId,
    invoice_emails,
    ...address
  }: {
    autoFillId: string;
    invoice_emails: string[];
    country: string;
    street_address: string;
    state: string;
    city: string;
    zipcode: string;
  }) => {
    try {
      await Promise.all([
        updateBillingInfoEmails({ invoice_emails }),
        updateBillingAddress({
          autoFillId,
          ...address,
        }),
      ]);

      successToast({ message: "Billing address updated successfully" });
      close();
    } catch (error) {}
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        initialValues={{ ...autofillAddress, invoice_emails, autoFillId }}
        onSubmit={onSubmit}
        validationSchema={billingAddressSchema}
        validateOnChange
      >
        {({ isValid, isSubmitting, submitForm, setFieldValue, values }) => (
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Bill to</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-flex-col t-gap-6 t-m-0">
                <Combobox
                  required
                  placeholder="Add emails"
                  formatCreateLabel={(inputText) => `Add ${inputText}`}
                  isMulti
                  label="Billing emails"
                  withForm
                  name="invoice_emails"
                  creatable
                  components={{
                    DropdownIndicator: () => null,
                    NoOptionsMessage: () => null,
                  }}
                  onCreateOption={(value) =>
                    setFieldValue("invoice_emails", [
                      ...(values.invoice_emails || []),
                      value,
                    ])
                  }
                  value={values.invoice_emails?.map((email) => ({
                    label: email,
                    value: email,
                  }))}
                />
                <TextInput
                  label="Billing Address"
                  name="street_address"
                  placeholder="Input billing address"
                  required
                />

                <Combobox
                  required
                  isLoading={isCountriesLoading}
                  menuPortalTarget={document.body}
                  name="country"
                  withForm
                  label="Country"
                  placeholder="Select Country"
                  options={
                    countries?.map((country) => ({
                      value: country.name,
                      label: country.name,
                    }))!
                  }
                  value={
                    values.country
                      ? { label: values.country, value: values.country }
                      : null
                  }
                />
                <Combobox
                  required
                  isDisabled={!values.country}
                  menuPortalTarget={document.body}
                  name="state"
                  withForm
                  label="State"
                  placeholder="Select State"
                  options={
                    countries
                      ?.find((c) => c.name === values.country)
                      ?.states?.map((state) => ({
                        value: state.name,
                        label: state.name,
                      }))!
                  }
                  value={
                    values.state
                      ? { label: values.state, value: values.state }
                      : null
                  }
                />

                <TextInput
                  required
                  label="City"
                  name="city"
                  placeholder="Input city"
                />

                <TextInput
                  required
                  label="Zip code"
                  name="zipcode"
                  placeholder="Input zip code"
                />
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button onClick={close} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                onClick={submitForm}
                customType="primary"
                isLoading={isSubmitting}
                disabled={!isValid || isSubmitting}
              >
                Save
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};
