export const Reward = ({ stroke = "1.34167" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6016 7.32031H3.78906C3.39211 7.32031 3.07031 7.64211 3.07031 8.03906V10.9141C3.07031 11.311 3.39211 11.6328 3.78906 11.6328H19.6016C19.9985 11.6328 20.3203 11.311 20.3203 10.9141V8.03906C20.3203 7.64211 19.9985 7.32031 19.6016 7.32031Z"
      stroke="currentColor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8828 11.6328V18.1016C18.8828 18.2922 18.8071 18.475 18.6723 18.6098C18.5375 18.7446 18.3547 18.8203 18.1641 18.8203H5.22656C5.03594 18.8203 4.85312 18.7446 4.71833 18.6098C4.58354 18.475 4.50781 18.2922 4.50781 18.1016V11.6328"
      stroke="currentColor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6953 7.32031V18.8203"
      stroke="currentColor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7653 6.30501C14.741 7.32025 11.6953 7.32025 11.6953 7.32025C11.6953 7.32025 11.6953 4.27454 12.7106 3.25032C13.1157 2.84525 13.6651 2.61768 14.2379 2.61768C14.8108 2.61768 15.3602 2.84525 15.7653 3.25032C16.1703 3.6554 16.3979 4.2048 16.3979 4.77766C16.3979 5.35054 16.1703 5.89994 15.7653 6.30501Z"
      stroke="currentColor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.62532 6.30501C8.64954 7.32025 11.6953 7.32025 11.6953 7.32025C11.6953 7.32025 11.6953 4.27454 10.68 3.25032C10.2749 2.84525 9.72553 2.61768 9.15266 2.61768C8.5798 2.61768 8.0304 2.84525 7.62532 3.25032C7.22024 3.6554 6.99268 4.2048 6.99268 4.77766C6.99268 5.35054 7.22024 5.89994 7.62532 6.30501Z"
      stroke="currentColor"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
