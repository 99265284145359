export const InkleMailroom = () => (
  <svg
    width="216"
    height="56"
    viewBox="0 0 216 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.1855 30.287L19.0736 25.3785V34.7965C19.0736 35.6451 18.3857 36.3331 17.537 36.3331C16.6884 36.3331 16.0005 35.6451 16.0005 34.7965V21.2201C16.0005 20.3715 16.6884 19.6836 17.537 19.6836C17.6044 19.6836 17.6707 19.6879 17.7358 19.6963C18.038 19.6721 18.4171 19.6787 18.9161 19.6873C19.2299 19.6927 19.5911 19.699 20.0104 19.6989L25.8429 19.6989L31.5103 19.6988C32.1642 19.6988 32.6982 19.6876 33.1399 19.6782C33.6935 19.6665 34.1023 19.6579 34.421 19.6783C34.4354 19.6779 34.4498 19.6777 34.4643 19.6777C35.3129 19.6777 36.0008 20.3657 36.0008 21.2143V34.7907C36.0008 35.6393 35.3129 36.3272 34.4643 36.3272C33.6157 36.3272 32.9277 35.6393 32.9277 34.7907V25.5277L26.5186 30.3107C26.1208 30.6076 25.5725 30.5978 25.1855 30.287Z"
      fill="url(#paint0_linear_5723_7689)"
    />
    <path
      d="M46 20.7726C46 19.5691 46.78 18.7891 48.0058 18.7891C49.2092 18.7891 49.9893 19.5691 49.9893 20.7726C49.9893 21.976 49.2092 22.7783 48.0058 22.7783C46.78 22.7783 46 21.976 46 20.7726ZM49.7887 37.8885H46.2229V24.5167H49.7887V37.8885Z"
      fill="url(#paint1_linear_5723_7689)"
    />
    <path
      d="M60.7289 31.1803C60.7289 29.2191 59.7037 27.9265 58.1214 27.9265C56.6282 27.9265 55.5362 29.3082 55.5362 31.2026V37.8885H51.9704V24.5167H55.5362V26.0767C56.0042 25.0738 57.386 24.3607 58.7677 24.3607C62.1775 24.3607 64.2947 27.0796 64.2947 31.1803V37.8885H60.7289V31.1803Z"
      fill="url(#paint2_linear_5723_7689)"
    />
    <path
      d="M70.0119 37.8885H66.4461V18.9451H70.0119V30.5117L74.536 24.5167H78.4362L73.8897 31.2026L78.9042 37.8885H74.38L70.0119 31.8935V37.8885Z"
      fill="url(#paint3_linear_5723_7689)"
    />
    <path
      d="M83.8049 37.8885H80.2391V18.9451H83.8049V37.8885Z"
      fill="url(#paint4_linear_5723_7689)"
    />
    <path
      d="M94.6461 33.7878H98.4348C97.5211 36.2839 95.1364 37.9999 92.4175 37.9999C88.5174 37.9999 85.7761 35.1696 85.7761 31.158C85.7761 27.2802 88.6065 24.3384 92.3506 24.3384C96.0948 24.3384 98.9251 27.2802 98.9251 31.158C98.9251 31.5815 98.9028 32.0272 98.836 32.4283H89.5203C89.9214 33.7878 91.0135 34.657 92.3952 34.657C93.4427 34.657 94.2673 34.3004 94.6461 33.7878ZM89.5203 29.8654H95.181C94.7799 28.5505 93.7324 27.7036 92.3506 27.7036C90.9689 27.7036 89.9214 28.5505 89.5203 29.8654Z"
      fill="url(#paint5_linear_5723_7689)"
    />
    <path
      d="M106.127 37.8781H103.925V20.5469H106.127L111.979 29.9465L117.851 20.5469H120.054V37.8781H117.851V24.7472L112.876 32.5564H111.102L106.127 24.7472V37.8781Z"
      fill="#41395C"
    />
    <path
      d="M131.739 37.8781V35.5945C131.189 37.0217 129.435 38.0005 127.6 38.0005C124.093 38.0005 121.769 35.5129 121.769 31.7408C121.769 27.9891 124.073 25.5016 127.6 25.5016C129.415 25.5016 131.189 26.4803 131.739 27.9279V25.6443H133.921V37.8781H131.739ZM123.971 31.7408C123.971 34.1876 125.561 35.7984 127.865 35.7984C130.149 35.7984 131.739 34.1876 131.739 31.7612C131.739 29.3552 130.108 27.6833 127.845 27.6833C125.541 27.6833 123.971 29.2941 123.971 31.7408Z"
      fill="#41395C"
    />
    <path
      d="M136.108 21.9742C136.108 21.1993 136.618 20.6692 137.433 20.6692C138.208 20.6692 138.718 21.1993 138.718 21.9742C138.718 22.7694 138.208 23.2995 137.433 23.2995C136.618 23.2995 136.108 22.7694 136.108 21.9742ZM138.534 37.8781H136.332V25.6443H138.534V37.8781Z"
      fill="#41395C"
    />
    <path
      d="M142.969 37.8781H140.767V20.5469H142.969V37.8781Z"
      fill="#41395C"
    />
    <path
      d="M152.012 25.5016V27.6833C148.994 27.6629 147.261 29.2329 147.261 31.9855V37.8781H145.079V25.6443H147.261V28.1522C148.24 26.0725 150.32 25.5016 152.012 25.5016Z"
      fill="#41395C"
    />
    <path
      d="M152.974 31.7408C152.974 28.2134 155.584 25.5016 158.968 25.5016C162.333 25.5016 164.922 28.2134 164.922 31.7408C164.922 35.2886 162.333 38.0005 158.968 38.0005C155.584 38.0005 152.974 35.2886 152.974 31.7408ZM158.968 27.6833C156.827 27.6833 155.176 29.4368 155.176 31.7408C155.176 34.0652 156.827 35.7984 158.968 35.7984C161.109 35.7984 162.74 34.0652 162.74 31.7408C162.74 29.4368 161.109 27.6833 158.968 27.6833Z"
      fill="#41395C"
    />
    <path
      d="M166.439 31.7408C166.439 28.2134 169.049 25.5016 172.434 25.5016C175.798 25.5016 178.387 28.2134 178.387 31.7408C178.387 35.2886 175.798 38.0005 172.434 38.0005C169.049 38.0005 166.439 35.2886 166.439 31.7408ZM172.434 27.6833C170.293 27.6833 168.641 29.4368 168.641 31.7408C168.641 34.0652 170.293 35.7984 172.434 35.7984C174.575 35.7984 176.206 34.0652 176.206 31.7408C176.206 29.4368 174.575 27.6833 172.434 27.6833Z"
      fill="#41395C"
    />
    <path
      d="M188.835 31.7408C188.835 29.2737 187.571 27.6833 185.614 27.6833C183.799 27.6833 182.494 29.396 182.494 31.7612V37.8781H180.292V25.6443H182.494V27.9279C182.963 26.5211 184.349 25.5016 185.981 25.5016C187.938 25.5016 189.447 26.5007 190.283 28.1726C191.078 26.4803 192.465 25.5016 194.218 25.5016C197.501 25.5016 199.581 27.9687 199.581 31.7408V37.8781H197.378V31.7408C197.378 29.2737 196.114 27.6833 194.136 27.6833C192.342 27.6833 191.037 29.396 191.037 31.7408V37.8781H188.835V31.7408Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7689"
        x1="16.0005"
        y1="27.9995"
        x2="36.0008"
        y2="27.9995"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7689"
        x1="46"
        y1="28.3945"
        x2="98.9251"
        y2="28.3945"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7689"
        x1="46"
        y1="28.3945"
        x2="98.9251"
        y2="28.3945"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7689"
        x1="46"
        y1="28.3945"
        x2="98.9251"
        y2="28.3945"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5723_7689"
        x1="46"
        y1="28.3945"
        x2="98.9251"
        y2="28.3945"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5723_7689"
        x1="46"
        y1="28.3945"
        x2="98.9251"
        y2="28.3945"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
