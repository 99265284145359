import { object, string } from "yup";

export const csvColumnMappingSchema = object({
  date_field: string().required("Property not selected"),
  description_field: string().required("Property not selected"),
  amount_field: string().when("isSVBBank", {
    is: false,
    then: string().required("Property not selected"),
    otherwise: string().notRequired(),
  }),

  credit_field: string().when("isSVBBank", {
    is: true,
    then: string().required("Property not selected"),
    otherwise: string().notRequired(),
  }),

  debit_field: string().when("isSVBBank", {
    is: true,
    then: string().required("Property not selected"),
    otherwise: string().notRequired(),
  }),
});
