import classNames from "classnames";
import { MONTHLY, YEARLY } from "constants/billingCycles";
import { QUARTERLY } from "constants/complianceFrequencies";
import { ReactNode } from "react";
import "static/styles/components/price.css";

type PriceProps = {
  amount?: ReactNode;
  size?: "large" | "medium" | "small";
  cycle?: "YEARLY" | "MONTHLY" | "QUARTERLY";
  className?: string;
  type?: "NORMAL" | "STRIKE_THROUGH";
};

const PRICE_TYPE_CLASSES = {
  /* @ts */
  STRIKE_THROUGH: "t-text-neutral-40 t-line-through t-items-center",
};

export const Price = ({
  amount = "0",
  size = "large",
  cycle,
  className = "t-text-text-30",
  type = "NORMAL",
}: PriceProps): JSX.Element => {
  let cycleText;

  if (cycle === YEARLY) {
    cycleText = "/ year";
  }
  if (cycle === MONTHLY) {
    cycleText = "/ month";
  }
  if (cycle === QUARTERLY) {
    cycleText = "/ quarter";
  }

  return (
    <div>
      <span
        className={classNames({
          "t-text-subtitle": size === "medium",
          "t-text-h4": size === "large",
          "t-text-subtext": size === "small",
          [PRICE_TYPE_CLASSES.STRIKE_THROUGH]: type === "STRIKE_THROUGH",
        })}
      >
        ${amount}
      </span>
      {cycleText && (
        <span
          className={classNames(`${className}`, {
            "t-text-body-sm": size === "small",
            "t-text-body-lg": size !== "small",
            [PRICE_TYPE_CLASSES.STRIKE_THROUGH]: type === "STRIKE_THROUGH",
          })}
        >
          {cycleText}
        </span>
      )}
    </div>
  );
};
