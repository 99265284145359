export const NEW_LEAD = "NEW_LEAD";
export const COLD_LEAD = "COLD_LEAD";
export const WARM_LEAD = "WARM_LEAD";
export const HOT_LEAD = "HOT_LEAD";
export const LOST_LEAD = "LOST_LEAD";
export const SPAM_LEAD = "SPAM_LEAD";

export const NEWLEAD = "New lead";
export const COLDLEAD = "Cold lead";
export const WARMLEAD = "Warm lead";
export const HOTLEAD = "Hot lead";
export const LOSTLEAD = "Lost lead";
export const SPAMLEAD = "Spam lead";

export const READY_TO_ONBOARD = "READY_TO_ONBOARD";
export const SUCCESSFULLY_ONBOARDED = "SUCCESSFULLY_ONBOARDED";
export const CHURNED = "CHURNED";
export const DISSOLVED = "DISSOLVED";
export const DISSOLVING = "DISSOLVING";

export const READYTOONBOARD = "Ready to Onboard";
export const SUCCESSFULLYONBOARDED = "Successfully Onboarded";
export const CHURNED_ = "Churned";
export const DISSOLVED_ = "Dissolved";
export const DISSOLVING_ = "Dissolving";

export const TASK_TEMPLATE_FILTER = "task_template_filter";
