import {
  CHROMIUM,
  DESKTOP,
  EDGE_CHROMIUM,
  EDGE_LEGACY,
  FIREFOX,
  IOS_PHONE,
  IOS_TABLET,
  OPERA,
  PHONE,
  SAFARI,
  SAMSUNG_INTERNET,
  TABLET,
} from "constants/deviceInfo";
import { useMemo } from "react";

export const useDeviceInfo = () => {
  const userAgent = navigator?.userAgent;
  const browser = useMemo(() => {
    switch (true) {
      case /Firefox/.test(userAgent):
        return FIREFOX;
      case /SamsungBrowser/.test(userAgent):
        return SAMSUNG_INTERNET;
      case /Opera|OPR/.test(userAgent):
        return OPERA;
      case /Edge/.test(userAgent):
        return EDGE_LEGACY;
      case /Edg/.test(userAgent):
        return EDGE_CHROMIUM;
      case /Chrome/.test(userAgent):
        return CHROMIUM;
      case /Safari/.test(userAgent):
        return SAFARI;
      default:
        return "unknown";
    }
  }, [userAgent]);

  const device = useMemo(() => {
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      if (/iPad/.test(navigator.userAgent)) {
        return IOS_TABLET;
      } else {
        return TABLET;
      }
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        return IOS_PHONE;
      } else {
        return PHONE;
      }
    }
    return DESKTOP;
  }, [userAgent]);

  const isIosDevice = IOS_PHONE === device || IOS_TABLET === device;

  return { browser, device, isIosDevice };
};
