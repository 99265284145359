import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { useState } from "react";
import { ArchivedGroupCrm } from "./ArchivedGroupCrm";
import { ArchivedUserCrm } from "./ArchivedUserCrm";

export const ArchivedCrm = () => {
  const [showDataFor, setShowDataFor] = useState("Group");

  return (
    <div className="t-px-6 t-py-5 t-flex t-flex-col">
      <span className="t-mb-3.5 t-flex  t-rounded t-w-max">
        <ToggleGroup
          defaultValue="Group"
          onValueChange={(value) => setShowDataFor(value)}
        >
          <ToggleGroupItem value="Group" aria-label="Center aligned">
            Group
          </ToggleGroupItem>
          <ToggleGroupItem value="Profile" aria-label="Center aligned">
            Profile
          </ToggleGroupItem>
        </ToggleGroup>
      </span>
      {showDataFor === "Profile" ? <ArchivedUserCrm /> : <ArchivedGroupCrm />}
    </div>
  );
};
