import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentGroup } from "hooks/useCurrentGroup";

export const MeetWithCPA = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const group = useCurrentGroup();
  const generalHelpChannel = group.secondary_channel_url;

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="small">
        <Modal.Header>
          <Modal.Title>Meet With CPA</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          Please contact us via Inkle Chat to schedule a meeting with the CPA.
          We will check the CPA's availability and get back to you with possible
          options.
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button onClick={close}>Cancel</Button>
          <ConditionalLink to={`/chat/${generalHelpChannel}`}>
            <Button customType="primary" onClick={close}>
              Go to chat
            </Button>
          </ConditionalLink>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
