import Toast, { ToastSizeType } from "components/DesignSystem/Toast/Toast";
import { ReactNode } from "react";

export const LoadingToast = ({
  children,
  title,
  size = "regular",
  loading,
}: {
  loading: boolean;
  title?: string;
  children: ReactNode;
  size?: ToastSizeType;
}) => {
  return (
    <div className="t-fixed t-right-0 t-bottom-0 t-z-toast">
      <Toast.Provider duration={20000}>
        <Toast.Root open={Boolean(loading)} customType="loading" size={size}>
          <Toast.Title>{title}</Toast.Title>
          <Toast.Description>{children}</Toast.Description>
        </Toast.Root>
      </Toast.Provider>
    </div>
  );
};
