import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ChannelPinnedItemsSliderState = {
  isActive: boolean;
  setSideBarToExpandOnClose: boolean;
};

const initialState: ChannelPinnedItemsSliderState = {
  isActive: false,
  setSideBarToExpandOnClose: false,
};

export const channelPinnedItems = createSlice({
  name: "channelPinnedItems",
  initialState,
  reducers: {
    setChannelPinnedItemsActive: (
      state,
      action: PayloadAction<{
        isActive: boolean;
        setSideBarToExpandOnClose?: boolean;
      }>
    ) => {
      state.isActive = action.payload.isActive;
      state.setSideBarToExpandOnClose =
        action.payload.setSideBarToExpandOnClose ??
        state.setSideBarToExpandOnClose;
    },
  },
});

export const { setChannelPinnedItemsActive } = channelPinnedItems.actions;

export default channelPinnedItems.reducer;
