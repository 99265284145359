import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type FinancialClosingInitialStateType = {
  isHistorical: boolean;
  startDate: string;
  endDate: string;
  multipleMonths: boolean;
  seasonSummaryId: string;
  seasonType: string;
  seasonYear: string;
};

type FinancialClosingState = {
  billingInfoModal: { monthId: string; open: boolean };
  invoiceModal: string;
  isStartBookkeepingOpen: boolean;
  financialClosingInitialState: FinancialClosingInitialStateType | undefined;
  deleteCard: { monthId: string; open: boolean };
};

const initialState: FinancialClosingState = {
  billingInfoModal: { monthId: "", open: false },
  invoiceModal: "",
  isStartBookkeepingOpen: false,
  financialClosingInitialState: undefined,
  deleteCard: {
    monthId: "",
    open: false,
  },
};

export const financialClosing = createSlice({
  name: "financialClosing",
  initialState,
  reducers: {
    setBillingInfoMonthId: (state, action: PayloadAction<string>) => {
      state.billingInfoModal.monthId = action.payload;
    },
    openBillingModal: (state) => {
      state.billingInfoModal.open = true;
    },
    closeBillingModal: (state) => {
      state.billingInfoModal = { ...state.billingInfoModal, open: false };
    },
    openInvoiceModal: (state, action: PayloadAction<string>) => {
      state.invoiceModal = action.payload;
    },
    closeInvoiceModal: (state) => {
      state.invoiceModal = "";
    },
    openStartFinancialClosingModal: (state) => {
      state.isStartBookkeepingOpen = true;
    },
    closeStartFinancialClosingModal: (state) => {
      state.isStartBookkeepingOpen = false;
    },
    setFinancialClosingInitialState: (
      state,
      action: PayloadAction<FinancialClosingInitialStateType>
    ) => {
      state.financialClosingInitialState = action.payload;
    },

    openDeleteMonthCardModal: (state, action: PayloadAction<string>) => {
      state.deleteCard = { monthId: action.payload, open: true };
    },

    closeDeleteMonthCardModal: (state) => {
      state.deleteCard = { monthId: "", open: false };
    },
  },
});

export const {
  setBillingInfoMonthId,
  closeBillingModal,
  openBillingModal,
  openInvoiceModal,
  closeInvoiceModal,
  openStartFinancialClosingModal,
  closeStartFinancialClosingModal,
  setFinancialClosingInitialState,
  openDeleteMonthCardModal,
  closeDeleteMonthCardModal,
} = financialClosing.actions;

export default financialClosing.reducer;
