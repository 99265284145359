import {
  BlockSchema,
  defaultBlockSchema,
  defaultProps,
  BlockNoteEditorOptions,
} from "@blocknote/core";
import {
  BlockNoteView,
  useBlockNote,
  createReactBlockSpec,
  ReactSlashMenuItem,
  getDefaultReactSlashMenuItems,
} from "@blocknote/react";
import classNames from "classnames";
import { US } from "constants/regAgent";
import { Form, Formik, FormikHelpers } from "formik";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import ReactCountryFlag from "react-country-flag";
import { RiCashLine, RiBarChartLine, RiFile2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useLazyGetEntityBanksQuery } from "store/apis/bankConnections";
import {
  useGetStakeholderUpdateQuery,
  useAddShuReportMutation,
  useDeleteShuReportMutation,
  useGetPrimaryMetricChoicesQuery,
  useAddShuPrimaryMetricMutation,
  useGetShuCashBalancesQuery,
  useAddShuCashBalanceMutation,
  useDeleteShuCashBalanceMutation,
  useUpdateShuCashBalanceMutation,
} from "store/apis/stakeholderUpdate";
import { Entity } from "types/Models/entity";
import { Combobox } from "./DesignSystem/Combobox/Combobox";
import { TextInput } from "./DesignSystem/TextInput/TextInput";
import { Button } from "./DesignSystem/Button/Button";
import { Checkbox } from "./DesignSystem/Checkbox/Checkbox";
import { CashBalance } from "types/Models/stakeholderUpdate";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { FileInput, FileType } from "./FileInput/FileInput";

const ReportBlock = ({
  reportID,
  onSave,
  editable,
}: {
  reportID?: string;
  editable?: boolean;
  onSave: (uuid: string) => void;
}) => {
  const { investorUpdateId } = useParams<{ investorUpdateId?: string }>();
  const group = useCurrentGroup();
  const { alertToast } = useToast();

  const { data: stakeholderUpdate } = useGetStakeholderUpdateQuery(
    {
      groupId: group?.uuid!,
      updateId: investorUpdateId!,
    },
    {
      skip: !group?.uuid || !investorUpdateId,
    }
  );

  const selectedReport = stakeholderUpdate?.reports?.find(
    ({ uuid }) => uuid === reportID
  );

  const [addShuReport, { isLoading: isUploading }] = useAddShuReportMutation();
  const [deleteShuReport, { isLoading: isDeleting }] =
    useDeleteShuReportMutation();

  if (!group) {
    return null;
  }

  const onReportDrop = async (files: FileType[]) => {
    try {
      if (stakeholderUpdate && group?.uuid && group?.unsorted_folder_id) {
        const report = await addShuReport({
          groupId: group.uuid,
          updateId: stakeholderUpdate?.uuid,
          payload: {
            title: "Title",
            description: "Description",
            reportFile: files[0] as File,
            folderId: group.unsorted_folder_id,
          },
        }).unwrap();
        onSave(report.uuid);
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onDeleteReport = async (fileId: string) => {
    try {
      if (stakeholderUpdate && group?.uuid) {
        await deleteShuReport({
          groupId: group.uuid,
          updateId: stakeholderUpdate.uuid,
          reportId: fileId,
        }).unwrap();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  if (reportID && selectedReport) {
    return (
      <FileInput
        file={selectedReport.report_file}
        disabled={!editable}
        onDelete={onDeleteReport.bind(this, selectedReport.uuid)}
        isDeleting={isDeleting}
      />
    );
  }

  if (!editable) {
    return null;
  }

  return (
    <div>
      <FileInput onDrop={onReportDrop} isUploading={isUploading} />
    </div>
  );
};

const MetricBlock = ({
  metricID,
  onSave,
  editable,
}: {
  metricID?: string;
  editable?: boolean;
  onSave: (uuid: string) => void;
}) => {
  const { investorUpdateId } = useParams<{ investorUpdateId?: string }>();
  const group = useCurrentGroup();
  const { alertToast } = useToast();

  const { data: primaryMetricChoices } = useGetPrimaryMetricChoicesQuery();

  const { data: stakeholderUpdate } = useGetStakeholderUpdateQuery(
    {
      groupId: group?.uuid!,
      updateId: investorUpdateId!,
    },
    {
      skip: !group?.uuid || !investorUpdateId,
    }
  );

  const selectedMetric = stakeholderUpdate?.primary_metrics?.find(
    ({ uuid }) => uuid === metricID
  );

  const [addPrimaryMetric, { isLoading: addingPrimaryMetric }] =
    useAddShuPrimaryMetricMutation();

  if (!group) {
    return null;
  }

  const unUsedPrimaryMetrics = primaryMetricChoices;

  const createAndAddPrimaryMetric: <
    T extends {
      primary_metric_id: string;
      value: string;
    }
  >(
    values: T,
    helpers: FormikHelpers<T>
  ) => void = async ({ primary_metric_id, value }) => {
    const primaryMetricPayload = {
      primary_metric_id: primary_metric_id,
      value: Number(value),
    };

    try {
      if (stakeholderUpdate?.uuid && group.uuid) {
        const metric = await addPrimaryMetric({
          groupId: group.uuid,
          updateId: stakeholderUpdate?.uuid,
          payload: primaryMetricPayload,
        }).unwrap();

        onSave(metric.uuid);
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  if (metricID && selectedMetric) {
    return (
      <div
        className={classNames(
          "all:unset t-my-1 t-inline-flex t-h-8 t-justify-between t-px-2 t-items-center t-text-body-sm t-bg-purple-0 t-border t-border-solid t-border-purple-40 t-rounded",
          { "t-pointer-events-none": !editable }
        )}
      >
        {selectedMetric.primary_metric.title}: {selectedMetric.value}
      </div>
    );
  }

  if (!editable) {
    return null;
  }

  return (
    <div className="t-p-5 t-border t-border-solid t-border-neutral-10 t-rounded-lg">
      <Formik
        onSubmit={createAndAddPrimaryMetric}
        initialValues={{
          primary_metric_id: "",
          value: "",
        }}
      >
        {({ values }) => {
          const selectedMetric = primaryMetricChoices?.find(
            (m) => m.uuid === values.primary_metric_id
          );

          return (
            <Form className="t-m-0 t-flex t-my-1 t-flex-col t-gap-3">
              <div className="t-flex t-flex-col t-gap-3">
                <Combobox
                  withForm
                  label="Select the metric"
                  placeholder="Metric"
                  options={unUsedPrimaryMetrics?.map((choice) => {
                    return { value: choice.uuid, label: choice.title };
                  })}
                  name="primary_metric_id"
                >
                  <option value="">Select Metric</option>
                  {unUsedPrimaryMetrics?.map((choice) => {
                    return (
                      <option value={choice.uuid} key={choice.uuid}>
                        {choice.title}
                      </option>
                    );
                  })}
                </Combobox>

                <TextInput
                  required
                  placeholder="Input value here"
                  name="value"
                  label={selectedMetric?.title || "Value"}
                  icon={
                    selectedMetric?.metric_type === "PERCENT" && (
                      <span className="t-text-neutral-40">%</span>
                    )
                  }
                  type="number"
                />
              </div>

              <div>
                <Button
                  isLoading={addingPrimaryMetric}
                  disabled={addingPrimaryMetric}
                >
                  <span>Save metric</span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const CashBalanceBlockUI = ({ editable }: { editable?: boolean }) => {
  const group = useCurrentGroup();
  const { alertToast } = useToast();

  const { investorUpdateId } = useParams<{ investorUpdateId?: string }>();
  const { data: cashBalances } = useGetShuCashBalancesQuery(
    {
      groupId: group?.uuid!,
      updateId: investorUpdateId!,
    },
    { skip: Boolean(!group?.uuid || !investorUpdateId) }
  );

  const [addShuCashBalance] = useAddShuCashBalanceMutation();

  const [deleteShuCashBalance] = useDeleteShuCashBalanceMutation();

  const [getEntityBankAccount] = useLazyGetEntityBanksQuery();

  const [updateShuCashBalance] = useUpdateShuCashBalanceMutation();

  const { data: stakeholderUpdate } = useGetStakeholderUpdateQuery(
    {
      groupId: group?.uuid!,
      updateId: investorUpdateId!,
    },
    {
      skip: !group?.uuid || !investorUpdateId,
    }
  );

  if (!group) {
    return null;
  }

  const onEntitySelection = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const entityId = e.target.name;
    const entity = group?.entities?.find(({ uuid }) => entityId === uuid);

    try {
      if (!e.target.checked && stakeholderUpdate) {
        await Promise.all(
          stakeholderUpdate.cash_balances
            .filter((cb) => cb.entity_uuid === entityId)
            .map((cashBalance) =>
              deleteShuCashBalance({
                groupId: group?.uuid!,
                updateId: stakeholderUpdate.uuid,
                updateCbId: cashBalance.uuid,
              }).unwrap()
            )
        );

        return;
      }

      if (entity?.country_code === US || entity?.country_code === "US") {
        const bankAccounts = await getEntityBankAccount({
          groupId: group?.uuid!,
          entityId: entityId,
        }).unwrap();

        const aggridatedAccoutsCardName =
          bankAccounts.aggregate.accounts > 0
            ? `Aggregated balance (${bankAccounts.aggregate.accounts} accounts)`
            : "Aggregated balance ";

        await addShuCashBalance({
          groupId: group?.uuid!,
          updateId: stakeholderUpdate?.uuid!,
          payload: {
            balance: bankAccounts.aggregate.current_balance,
            account_name: aggridatedAccoutsCardName,
            edited: false,
            entity_id: entityId,
          },
        }).unwrap();

        return;
      }

      return await addShuCashBalance({
        groupId: group.uuid!,
        updateId: stakeholderUpdate?.uuid!,
        payload: {
          balance: 1,
          account_name: "Cash Balance",
          edited: true,
          entity_id: entityId,
        },
      }).unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onCashBalanceChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const cashBalance = stakeholderUpdate?.cash_balances.find(
      ({ uuid }) => uuid === e.target.name
    );
    try {
      if (stakeholderUpdate && group.uuid && cashBalance) {
        await updateShuCashBalance({
          updateId: stakeholderUpdate?.uuid,
          groupId: group.uuid,
          cashBalanceId: cashBalance?.uuid,
          payload: {
            ...cashBalance,
            entity_id: cashBalance.entity_uuid,
            balance: Number(e.target.value),
            edited: true,
          },
        }).unwrap();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const entitiesGroupedById: { [key: string]: Entity } | undefined =
    group?.entities?.reduce(
      (acc, entity) => ({ [entity.uuid]: entity, ...acc }),
      {} as { [key: string]: Entity }
    );

  const cashBalanceGroupdByEntity: { [key: string]: CashBalance } | undefined =
    cashBalances?.reduce(
      (acc, cashBalance) => ({
        [cashBalance.entity_uuid]: cashBalance,
        ...acc,
      }),
      {}
    );

  return (
    <div
      className={classNames(
        "t-p-5 t-my-1 t-inline-flex t-flex-col t-bg-surface t-border t-border-solid t-border-neutral-10 t-rounded-lg t-w-full",
        { "t-pointer-events-none": !editable }
      )}
    >
      {editable && (
        <>
          <p className="!t-text-body !t-mb-2">
            Select entities to add their cash balance
          </p>

          <div className="t-grid t-gap-2 t-grid-cols-2 t-mb-6">
            {group?.entities?.map((entity) => (
              <div
                className="t-flex t-justify-between t-rounded t-border t-border-solid t-border-neutral-20 t-p-4"
                key={entity.uuid}
              >
                <Checkbox
                  name={entity.uuid}
                  onChange={onEntitySelection}
                  label={entity.name}
                  key={String(
                    Boolean(cashBalanceGroupdByEntity?.[entity.uuid])
                  )}
                  defaultChecked={Boolean(
                    cashBalanceGroupdByEntity?.[entity.uuid]
                  )}
                />
                <ReactCountryFlag
                  countryCode={entity.country_code}
                  svg
                  title={entity.country}
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                  }}
                  className="t-rounded"
                />
              </div>
            ))}
          </div>
        </>
      )}

      <div className="t-flex t-flex-col t-gap-6">
        {cashBalanceGroupdByEntity &&
          entitiesGroupedById &&
          Object.entries(cashBalanceGroupdByEntity).map(
            ([entity, cashBalance]) => {
              return (
                <div className="t-flex t-flex-col t-gap-4" key={entity}>
                  <div className="t-flex t-flex-col t-gap-2">
                    <div className="t-flex t-gap-2">
                      <span className="t-font-semibold">
                        {entitiesGroupedById[entity].name}
                      </span>
                      <ReactCountryFlag
                        countryCode={entitiesGroupedById[entity].country_code}
                        svg
                        title={entitiesGroupedById?.[entity].country}
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                        }}
                        className="t-rounded"
                      />
                    </div>
                    <div className="t-flex t-flex-col t-gap-4">
                      <div className="t-flex t-flex-col t-gap-2">
                        <Formik onSubmit={() => {}} initialValues={{}}>
                          <Form className="t-w-full t-m-0">
                            <TextInput
                              label={cashBalance.account_name}
                              customPrefix="$"
                              onChange={onCashBalanceChange}
                              name={cashBalance.uuid}
                              defaultValue={cashBalance.balance}
                            />
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

const CashBalanceBlock = createReactBlockSpec({
  type: "cashBalance",
  propSchema: {
    ...defaultProps,
  },
  containsInlineContent: false,
  render: ({ editor }) => <CashBalanceBlockUI editable={editor.isEditable} />,
});

const Metrics = createReactBlockSpec({
  type: "metric",
  propSchema: {
    ...defaultProps,
    metricID: {
      default: "",
    },
  },
  containsInlineContent: false,
  render: ({
    block: {
      id,
      props: { metricID },
    },
    editor,
  }) => {
    const saveMetricBlock = (newMetricId: string) => {
      editor.updateBlock(id, {
        // @ts-ignore
        props: { metricID: newMetricId },
      });
    };

    return (
      <MetricBlock
        editable={editor.isEditable}
        onSave={saveMetricBlock}
        metricID={metricID}
      />
    );
  },
});

const FileBlock = createReactBlockSpec({
  type: "file",
  propSchema: {
    ...defaultProps,
    reportID: {
      default: "",
    },
  },
  containsInlineContent: false,
  render: ({
    block: {
      id,
      props: { reportID },
    },
    editor,
  }) => {
    const saveReportBlock = (newReportId: string) => {
      editor.updateBlock(id, {
        // @ts-ignore
        props: { reportID: newReportId },
      });
    };

    return (
      <ReportBlock
        editable={editor.isEditable}
        onSave={saveReportBlock}
        reportID={reportID}
      />
    );
  },
});

// Ignore image block
const { image, ...customSchema }: BlockSchema = {
  ...defaultBlockSchema,
  cashBalance: CashBalanceBlock,
  metric: Metrics,
  file: FileBlock,
};

const insertCashBalance: ReactSlashMenuItem<typeof customSchema> = {
  name: "Cash Balance",
  execute: async (editor) => {
    editor.insertBlocks(
      [
        {
          type: "cashBalance",
        },
      ],
      editor.getTextCursorPosition().block,
      "before"
    );
  },
  aliases: ["cash", "balance", "custom", "block"],
  group: "Inkle commands",
  icon: <RiCashLine />,
  hint: "Add your cash balance from back accounts connected",
};

// Creates a slash menu item for inserting an image block.
const insertMetric: ReactSlashMenuItem<typeof customSchema> = {
  name: "Metrics",
  execute: async (editor) => {
    editor.insertBlocks(
      [
        {
          type: "metric",
        },
      ],
      editor.getTextCursorPosition().block,
      "before"
    );
  },
  aliases: ["track", "metric"],
  group: "Inkle commands",
  icon: <RiBarChartLine />,
  hint: "Send your metrics for this update.",
};

const insertReport: ReactSlashMenuItem<typeof customSchema> = {
  name: "Reports (Files)",
  execute: (editor) => {
    editor.insertBlocks(
      [
        {
          type: "file",
        },
      ],
      editor.getTextCursorPosition().block,
      "before"
    );
  },
  aliases: ["file", "report", "attachment"],
  group: "Inkle commands",
  icon: <RiFile2Line />,
  hint: "Attatch files, send reports for the receiver to download",
};

export const InvestorUpdateEditor = ({
  config,
}: {
  config: Partial<BlockNoteEditorOptions<BlockSchema>>;
}) => {
  const editor = useBlockNote({
    blockSchema: customSchema,
    slashMenuItems: [
      insertCashBalance,
      insertMetric,
      insertReport,
      ...getDefaultReactSlashMenuItems(customSchema),
    ],
    ...config,
  });

  return <BlockNoteView editor={editor} theme="light" />;
};
