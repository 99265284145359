import { RestrictedRoute } from "RestrictedRoute";
import { AppLayout } from "components/AppLayout/AppLayout";
import { LeftBar, LeftBarItem, LeftBarLogo } from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import { Raise as RaiseIcon } from "components/icons/LeftNav/Community/Raise";
import { Switch } from "react-router-dom";
import { RaiseInvestor } from "./RaiseInvestor";
import { InkleCommunity } from "components/icons/Logos/InkleCommunity";
import { InkleCommunitySymbol } from "components/icons/Logos/InkleCommunitySymbol";
import { Events } from "components/icons/LeftNav/Community/Events";
import { FAQs } from "components/icons/LeftNav/Community/FAQs";

const Navbar = () => {
  return (
    <>
      <LeftBarItem exact to="/" icon={RaiseIcon}>
        Raise
      </LeftBarItem>
      <LeftBarItem
        useParentPath={false}
        to="https://www.inkle.io/resources/events/talks"
        icon={Events}
      >
        Events
      </LeftBarItem>
      <LeftBarItem
        useParentPath={false}
        to="https://www.inkle.io/raise"
        icon={FAQs}
      >
        FAQs
      </LeftBarItem>
      <div className="t-mt-auto" />
    </>
  );
};

export const RaiseInvestorDashboard = () => {
  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo logo={InkleCommunity} symbol={InkleCommunitySymbol} />
          }
        >
          <Navbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          logo={
            <LeftBarLogo logo={InkleCommunity} symbol={InkleCommunitySymbol} />
          }
          nav={
            <LeftBar>
              <Navbar />
            </LeftBar>
          }
        />
      }
    >
      <Switch>
        <RestrictedRoute
          exact
          path="/raise/investor"
          component={RaiseInvestor}
        />
      </Switch>
    </AppLayout>
  );
};
