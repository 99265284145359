import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { accountTypes } from "constants/connections";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { addManualBankSchema } from "formValidations/addManualBankSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useCreateManualBankAccountMutation,
  useGetAllBankBrandsQuery,
} from "store/apis/booksConnections";
import { closeAddMaualBankModal } from "store/slices/connections";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { AddBankSuccessModal } from "./AddBankSuccessModal";

type AddManualBankValues = {
  bank_name_id: string;
  account_number: string;
  account_type: string;
  opening_balance: number;
  account_opening_date: string;
};

const AccountName = () => {
  const {
    setFieldValue,
    values: { bank_name_id },
  } = useFormikContext<AddManualBankValues>();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: bankBrands, isSuccess } = useGetAllBankBrandsQuery(
    { groupId },
    { skip: !groupId }
  );

  const bankBrandsOptions = bankBrands?.map(({ name, uuid }) => ({
    value: uuid,
    label: name,
  }));

  const created = Boolean(bank_name_id)
    ? {
        value: bank_name_id,
        label: bank_name_id,
      }
    : null;

  const selected =
    bankBrandsOptions?.find(({ value }) => value === bank_name_id) || created;

  return (
    <Combobox
      isDisabled={!isSuccess}
      withForm
      required
      creatable
      name="bank_name_id"
      label="Account name"
      placeholder="Select or type"
      options={bankBrandsOptions}
      menuPortalTarget={document.body}
      value={selected}
      onCreateOption={(name) => setFieldValue("bank_name_id", name)}
    />
  );
};

export const AddManualBank = () => {
  const { alertToast } = useToast();
  const { isAddBankModalOpen: isOpen } = useSelector(
    (store: RootState) => store.connections
  );
  const dispatch = useDispatch();
  const { isCustomer } = useRoleBasedView();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const [createManualBankAccount, { data: addedBank }] =
    useCreateManualBankAccountMutation();

  const { entity_bank_account_id } = addedBank || {};

  const {
    push,
    location: { search },
  } = useHistory();

  const onClose = () => {
    dispatch(closeAddMaualBankModal());
  };

  const {
    isOpen: isSuccessModalOpen,
    close: closeSuccessModal,
    open: openSuccessModal,
  } = useModal();

  const openChat = () => {
    onClose();
    push(`/books/chat${search}`);
  };

  const onSubmit = async (
    values: AddManualBankValues,
    { resetForm }: FormikHelpers<AddManualBankValues>
  ) => {
    try {
      await createManualBankAccount({
        ...values,
        account_opening_date: dayjs(values.account_opening_date).format(
          YYYY_MM_DD
        ),
        opening_balance: values.opening_balance.toString(),
        entityId,
        groupId,
      }).unwrap();
      openSuccessModal();
      resetForm();
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose} modal={false}>
        <Formik
          initialValues={{
            bank_name_id: "",
            account_number: "",
            account_type: "",
            opening_balance: 0,
            account_opening_date: "",
          }}
          onSubmit={onSubmit}
          validateOnChange
          validationSchema={addManualBankSchema}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Modal.Content useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>Add a Bank/Card</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-gap-4 t-flex-col">
                  <AccountName />
                  <TextInput
                    required
                    name="account_number"
                    label="Account number"
                  />
                  <Combobox
                    withForm
                    required
                    name="account_type"
                    label="Account type"
                    options={accountTypes}
                    menuPortalTarget={document.body}
                  />
                  <PriceInput
                    required
                    name="opening_balance"
                    label="Opening balance"
                  />
                  <Field name="account_opening_date">
                    {({ field }: FieldProps) => {
                      return (
                        <DateInput
                          {...field}
                          required
                          portalId="add-manual-bank-date"
                          maxDate={new Date()}
                          label="Date of opening balance"
                        />
                      );
                    }}
                  </Field>
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-between t-items-center">
                  {isCustomer && (
                    <div className="t-flex t-gap-1 t-w-full t-items-center">
                      Need help?
                      <Button
                        type="button"
                        customType="link"
                        onClick={openChat}
                      >
                        Chat with us.
                      </Button>
                    </div>
                  )}
                  <div className="t-flex t-gap-3 t-justify-end t-w-full">
                    <Button
                      type="reset"
                      onClick={onClose}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Add
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          )}
        </Formik>
      </Modal.Root>
      <AddBankSuccessModal
        isOpen={isSuccessModalOpen}
        close={closeSuccessModal}
        accountId={entity_bank_account_id!}
      />
    </>
  );
};
