import React from "react";

export const PriceLedger = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 4H13.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 8H6.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 12H7.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clip-path="url(#clip0_1039_6395)">
      <path
        d="M11.5 6.25V7"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 12V12.75"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 8.25C13.25 8.08585 13.2177 7.9233 13.1548 7.77165C13.092 7.61999 13 7.48219 12.8839 7.36612C12.7678 7.25004 12.63 7.15797 12.4784 7.09515C12.3267 7.03233 12.1642 7 12 7H11C10.6685 7 10.3505 7.1317 10.1161 7.36612C9.8817 7.60054 9.75 7.91848 9.75 8.25C9.75 8.58152 9.8817 8.89946 10.1161 9.13388C10.3505 9.3683 10.6685 9.5 11 9.5H12.25C12.5815 9.5 12.8995 9.6317 13.1339 9.86612C13.3683 10.1005 13.5 10.4185 13.5 10.75C13.5 11.0815 13.3683 11.3995 13.1339 11.6339C12.8995 11.8683 12.5815 12 12.25 12H10.75C10.4185 12 10.1005 11.8683 9.86612 11.6339C9.6317 11.3995 9.5 11.0815 9.5 10.75"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1039_6395">
        <rect
          width="8"
          height="8"
          fill="white"
          transform="translate(7.5 5.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
