export const Link = ({
  size = "16",
  color = "#5D6676",
}: {
  size?: string;
  color?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_667_12864)">
        <path
          d="M12.3637 18L11.4318 18.9318C10.5856 19.7644 9.44467 20.2288 8.25756 20.224C7.07044 20.2192 5.93332 19.7455 5.0939 18.906C4.25447 18.0666 3.78075 16.9295 3.77592 15.7424C3.77108 14.5553 4.23553 13.4143 5.06809 12.5681L7.3284 10.3125C8.13917 9.50005 9.22972 9.02814 10.377 8.99329C11.5242 8.95843 12.6414 9.36327 13.5 10.125"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.6363 5.99997L12.5681 5.06809C13.4144 4.23553 14.5553 3.77108 15.7424 3.77592C16.9295 3.78075 18.0666 4.25447 18.9061 5.0939C19.7455 5.93332 20.2192 7.07044 20.224 8.25756C20.2289 9.44467 19.7644 10.5856 18.9319 11.4318L16.6716 13.6922C15.8602 14.5039 14.7693 14.975 13.6221 15.0089C12.4749 15.0429 11.358 14.6373 10.5 13.875"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_667_12864">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
