import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

//API
import { getNewFeatures } from "../../apis/whatsnew";

//css
import "../../static/styles/components/salesCrm.css";

//images
import { Redirect } from "components/icons/Redirect";
import DefaultFeature from "../../static/images/DefaultFeature.svg";

// constant
import { MONTH_NAMES } from "../../constants/inkleCalendar";

// utils
import { getMonth } from "../../utils/formatDayJs";
import { monthFullName } from "../../utils/booksDataSet";
import { openLink } from "../../utils/openLink";
import { Button } from "components/design/button";

const WhatsNew = () => {
  const [features, setFeatures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const currentMonth = useRef(-1);

  useEffect(() => {
    fetchNewFeature(true);
  }, [currentPage]);

  const fetchNewFeature = async (paginate) => {
    if (paginate) {
      const response = await getNewFeatures({ pageNum: currentPage });
      setFeatures((prevFeatures) =>
        prevFeatures.concat(response.data.data.new_features)
      );
      setCurrentPage(response.data.data.current_page);
      setTotalPage(response.data.data.total_pages);
    } else {
      const response = await getNewFeatures({ pageNum: currentPage });
      if (currentPage === 1) setFeatures(response.data.data.new_features);
      else setFeatures([]);
      setCurrentPage(response.data.data.current_page);
      setTotalPage(response.data.data.total_pages);
    }
  };

  const checkDifferentMonth = (date) => {
    let featureReleaseMonth = getMonth(date);
    if (featureReleaseMonth === currentMonth.current) return false;
    else {
      currentMonth.current = featureReleaseMonth;
      return true;
    }
  };

  const getMonthAndYear = (date) =>
    monthFullName(MONTH_NAMES[getMonth(date)].toUpperCase()) +
    " " +
    new Date(date).getFullYear();

  const setPagination = () =>
    currentPage < totalPage && setCurrentPage(currentPage + 1);
  const redirectToFeature = (url) => openLink(url);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 600,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
      className="helpTopicsContainer whatsNewContainer"
    >
      <InfiniteScroll
        dataLength={features.length * totalPage}
        next={setPagination}
        style={{ display: "flex", flexDirection: "column" }}
        inverse={false}
        hasMore={true}
        scrollableTarget="scrollableDiv"
      >
        {features.map(
          ({ feature_name, description, release_date, url, uuid, icon }) => (
            <div className="helpTopicCards" key={uuid}>
              {checkDifferentMonth(release_date) && (
                <p className="helpTopicsTitle contact">
                  {getMonthAndYear(release_date)}
                </p>
              )}
              <div className="helpTopicCard whatsnew">
                <img
                  className="cardIcon"
                  src={icon || DefaultFeature}
                  alt="icon"
                />
                <div className="cardContent">
                  <div className="d-flex whatsnewTitle">
                    <span className="cardTitle">{feature_name}</span>
                    <Button
                      color="transparent"
                      onClick={() => redirectToFeature(url)}
                    >
                      <Redirect />
                    </Button>
                  </div>
                  <span className="content">{description}</span>
                </div>
              </div>
            </div>
          )
        )}
      </InfiniteScroll>
    </div>
  );
};

export default WhatsNew;
