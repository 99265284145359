import { Button } from "components/DesignSystem/Button/Button";
import { Paperclip } from "components/icons/Chat/Paperclip";
import { PaperPlaneRight } from "components/icons/Chat/PaperPlaneRight";
import { Smiley } from "components/icons/Chat/Smiley";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCallback, useEffect, useId } from "react";
import { UploadButton } from "react-file-utils";
import {
  AttachmentPreviewList,
  AutoCompleteTextarea,
  ChatAutoCompleteProps,
  LoadingIndicator,
  MessageInputProps,
  useChannelActionContext,
  useChannelStateContext,
  useComponentContext,
  useMessageInputContext,
} from "stream-chat-react";
import {
  CustomTrigger,
  DefaultStreamChatGenerics,
} from "stream-chat-react/dist/types/types";

import { default as classNames, default as cx } from "classnames";
import ChatTemplate from "components/chat/ChatTemplate";
import { Cross } from "components/icons/Cross";
import { MessageBody } from "components/MessageBubble/MessageBubble";
import {
  CHAT_CARD_TYPE,
  TASK_REVIEW_AND_PAY,
  TASK_REVIEW_DOCUMENT,
  TRANSACTIONS,
} from "constants/chatType";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useCreateMessageDraftMutation,
  useGetDraftMessageQuery,
} from "store/apis/chat";
import {
  useGetTaskFromChannelIdQuery,
  useSendDocumentToReviewMutation,
} from "store/apis/task";
import { setDropedFile } from "store/slices/chat";
import {
  clearCustomAttachment,
  setActiveChannelGroupId,
} from "store/slices/reviewAndBalancePayment";
import { RootState } from "store/store";
import { debounce } from "utils/debouncing";
import { CustomAttachmentPreviewList } from "./CustomAttachmentPreviewList";
import { TaskActionsFromChat } from "./TaskActionsFromChat";
import { useAppSelector } from "hooks/useAppSelector";
import { TransactionsInChatCard } from "./TransactionsInChatCard/TransactionsInChatCard";
import { usePushToChatMutation } from "store/apis/transactions";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { setTransactionsInChat } from "store/slices/transactionsInChat";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { EmojiPicker } from "stream-chat-react/emojis";
import { MessageInputInfo } from "components/MessageInputInfo/MessageInputInfo";
import { ChannelFrozenBlocker } from "./ChannelFrozenBlocker";
import { AdminCall } from "./AdminCall/AdminCall";

export const MessageInput = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
  V extends CustomTrigger = CustomTrigger
>(
  props: MessageInputProps<StreamChatGenerics, V> & ChatAutoCompleteProps
) => {
  const {
    AutocompleteSuggestionItem: SuggestionItem,
    AutocompleteSuggestionList: SuggestionList,
  } = useComponentContext<StreamChatGenerics, V>("ChatAutoComplete");
  const [pushToChat, { isLoading: pushingToChat }] = usePushToChatMutation();
  const [templateOpen, setTemplateOpen] = useState(false);
  const { files } = useSelector((state: RootState) => state.chat);
  const isSearchActive = useSelector(
    (state: RootState) => state.chatSearch.isActive
  );
  const { customAttachment: attachments, docReviewPayload } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );

  const transactionsInChat = useAppSelector(
    (state) => state.transactionsInChat.transactionsInChat
  );

  const entityId = useAppSelector((state) => state.transactionsInChat.entityId);

  const attachmentForReview = attachments.length !== 0;

  const id = useId();
  const dispatch = useDispatch();
  const { isForeignCA, isAdmin, isCpa } = useRoleBasedView();

  const {
    handleSubmit,
    isUploadEnabled,
    uploadNewFiles,
    maxFilesLeft,
    numberOfUploads,
  } = useMessageInputContext();

  const { channel } = useChannelStateContext();
  const channelId = channel.id || "";

  const [createUpdateDraft] = useCreateMessageDraftMutation();
  const [sendDocumentToReview, { isLoading }] =
    useSendDocumentToReviewMutation();
  const { alertToast } = useToast();
  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: channelId,
    },
    {
      skip: !channelId,
    }
  );

  const { tasks, company_group: companyGroup, entity } = channelTask || {};
  const task =
    tasks?.find((task) => task.is_parent_task) || tasks?.[1] || tasks?.[0];
  const { uuid: groupUuid } = useCurrentGroupContext();
  const { taskId: taskUuid } = useParams<{ taskId: string }>();

  const { data: { message_draft } = { message_draft: "" } } =
    useGetDraftMessageQuery(
      { channelUrl: channelId },
      {
        refetchOnMountOrArgChange: true,
        skip: !channelId,
      }
    );

  const messageInput = useMessageInputContext<StreamChatGenerics, V>(
    "ChatAutoComplete"
  );
  const {
    cooldownRemaining,
    disabled,
    emojiSearchIndex,
    textareaRef: innerRef,
  } = messageInput;

  const placeholder = "Type your message";

  const onInput = useCallback(() => {
    createUpdateDraft({
      channelUrl: channelId,
      message: innerRef.current?.innerHTML || "",
    });
  }, [channelId]);

  const debouncedOnInput = useCallback(debounce(onInput, 300), [onInput]);

  const updateInnerRef = useCallback(
    (ref: HTMLTextAreaElement | null) => {
      if (innerRef) {
        innerRef.current = ref;
      }
    },
    [innerRef]
  );

  useEffect(() => {
    if (files) {
      uploadNewFiles(files);
      dispatch(setDropedFile(null));
    }
  }, [files]);

  useEffect(() => {
    if (transactionsInChat.length > 0) {
      innerRef.current?.focus();
    }
  }, [transactionsInChat.length]);

  useEffect(() => {
    if (companyGroup?.uuid) {
      dispatch(setActiveChannelGroupId(companyGroup?.uuid));
    }
  }, [companyGroup?.uuid]);

  useEffect(() => {
    messageInput.setText(message_draft || "");
  }, [message_draft]);

  useEffect(() => {
    if (innerRef.current && !isSearchActive) {
      innerRef.current?.focus();
    }
  }, [channel.id, isSearchActive]);

  const {
    acceptedFiles = [],
    multipleUploads,
    quotedMessage,
  } = useChannelStateContext<StreamChatGenerics>("MessageInputV2");

  const { setQuotedMessage } =
    useChannelActionContext<StreamChatGenerics>("MessageActionsBox");

  if (channel.data?.frozen) {
    return <ChannelFrozenBlocker />;
  }

  const removeDraftMessage = () => {
    createUpdateDraft({
      channelUrl: channelId,
      message: null,
    });
  };

  const sendReviewDocumentMessage = async () => {
    try {
      const inkleDocs: string[] = [];
      const localFiles: Blob[] = [];
      attachments.forEach((attachment) => {
        if ("uuid" in attachment) {
          inkleDocs.push(attachment.uuid);
        } else {
          //@ts-ignore
          localFiles.push(attachment);
        }
      });
      const groupId = companyGroup?.uuid || groupUuid;
      const taskId = task?.uuid || taskUuid;
      const formData = new FormData();
      formData.append("file_ids", inkleDocs.join(","));
      formData.append("message", messageInput.text);
      localFiles.forEach((file) => formData.append("files", file));
      formData.append("task_id", taskId);
      formData.append("group_id", groupId);
      if (entity?.uuid) {
        formData.append("entity_id", entity?.uuid);
      }
      formData.append("custom_type", CHAT_CARD_TYPE);

      if (docReviewPayload.document_operation === TASK_REVIEW_DOCUMENT) {
        formData.append("document_operation", TASK_REVIEW_DOCUMENT);
      }

      if (docReviewPayload.document_operation === TASK_REVIEW_AND_PAY) {
        formData.append("document_operation", TASK_REVIEW_AND_PAY);
        formData.append("price", docReviewPayload?.amount!);
        formData.append("description", docReviewPayload?.description!);
        //@ts-ignore
        formData.append("payment_required", true);
      }

      await sendDocumentToReview({
        channel_id: channelId,
        payload: formData,
      }).unwrap();
      dispatch(clearCustomAttachment());
      messageInput.setText("");
      removeDraftMessage();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const sendTransactionsToChat = async () => {
    if (channel.id) {
      try {
        const payload = {
          channel_url: channel.id,
          custom_type: TRANSACTIONS,
          transaction_ids: transactionsInChat.map((txn) => txn.id).join(","),
          message: messageInput.text,
        };
        const { message_id } = await pushToChat({
          groupId: groupUuid,
          payload,
          entityId,
        }).unwrap();
        // successToast({ message: "Transaction sent to chat" });
        dispatch(setScrollToMessageId(message_id));
        dispatch(
          setTransactionsInChat({ transactionsInChat: [], entityId: "" })
        );
        messageInput.setText("");
      } catch (error) {
        alertToast({ message: "Failed to send to chat" });
      }
    }
  };

  const handleOwnSubmit = (e: any, customMessage: any) => {
    if (transactionsInChat.length > 0) {
      sendTransactionsToChat();
    } else if (attachmentForReview) {
      sendReviewDocumentMessage();
    } else {
      props.handleSubmit?.(e) || messageInput.handleSubmit(e, customMessage);
    }
    removeDraftMessage();
  };

  const handleSubmitFromButton = (e: any) => {
    if (transactionsInChat.length > 0) {
      sendTransactionsToChat();
    } else if (attachmentForReview) {
      sendReviewDocumentMessage();
    } else {
      handleSubmit(e);
    }
    removeDraftMessage();
  };

  const handleOwnOnChange = (e: any) => {
    props.onChange?.(e) || messageInput.handleChange(e);
    debouncedOnInput();
  };

  const emojiReplace = async (word: string) => {
    const found = (await emojiSearchIndex?.search(word)) || [];
    const emoji = found
      .filter(Boolean)
      .slice(0, 10)
      .find(({ emoticons }: any) => !!emoticons?.includes(word));
    if (!emoji || !("native" in emoji)) return null;
    return emoji.native;
  };

  const selectTemplate = (message: string) => {
    messageInput.setText(`${messageInput.text}${message}`);
    debouncedOnInput();
    innerRef.current?.focus();
  };

  const contentPresentInInput =
    messageInput.text.length > 0 ||
    messageInput.fileOrder.length > 0 ||
    messageInput.imageOrder.length > 0;

  const isMessageSending = pushingToChat || isLoading;

  return (
    <div className="t-relative">
      <MessageInputInfo />
      <div
        className="t-relative t-mx-[min(16px,4%)] t-mb-4 t-cursor-text t-rounded t-border t-border-solid t-border-neutral-10 t-bg-white t-p-3"
        onClick={() => innerRef.current?.focus()}
      >
        {isLoading ? (
          <span className="t-flex t-items-center">
            <span className="t-flex t-animate-spin">
              <LoadingIcon />
            </span>
          </span>
        ) : (
          <div className="t-flex t-flex-col t-gap-3 ">
            {quotedMessage && (
              <div className="t-relative t-flex t-flex-col t-gap-2 t-rounded t-bg-surface-lighter-grey t-p-3">
                <MessageBody
                  // @ts-ignore
                  message={quotedMessage.forwarded_message || quotedMessage}
                  otherData={{ sentByTheUser: false }}
                />
                <div className="t-absolute t-right-0 t-top-0 t-z-10">
                  <Button
                    customType="ghost_icon"
                    onClick={() => setQuotedMessage(undefined)}
                  >
                    <span className="t-fill-surface-lighter-grey">
                      <Cross />
                    </span>
                  </Button>
                </div>
              </div>
            )}
            {transactionsInChat.length > 0 && (
              <TransactionsInChatCard transactionsInChat={transactionsInChat} />
            )}
            {isUploadEnabled && !!numberOfUploads && <AttachmentPreviewList />}
            {attachmentForReview && <CustomAttachmentPreviewList />}

            <div className="t-flex t-flex-col ">
              <AutoCompleteTextarea
                additionalTextareaProps={{
                  ...messageInput.additionalTextareaProps,
                  id: "message-textarea",
                }}
                aria-label={cooldownRemaining ? "Slow Mode ON" : placeholder}
                className="t-w-full t-resize-none t-border-0 t-outline-none"
                closeCommandsList={messageInput.closeCommandsList}
                closeMentionsList={messageInput.closeMentionsList}
                // containerClassName="str-chat__textarea str-chat__message-textarea-react-host"
                disabled={disabled || !!cooldownRemaining}
                disableMenUploadButtontions={messageInput.disableMentions}
                // dropdownClassName="str-chat__emojisearch"
                grow={true} // :TODO:
                style={{ fontSize: "14px" }}
                handleSubmit={handleOwnSubmit}
                innerRef={updateInnerRef}
                height={18}
                // itemClassName="str-chat__emojisearch__item"
                // listClassName="str-chat__emojisearch__list"
                loadingComponent={LoadingIndicator}
                maxRows={10} // :TODO:
                minRows={2}
                minChar={0}
                onBlur={props.onBlur}
                onChange={handleOwnOnChange}
                onFocus={props.onFocus}
                onPaste={props.onPaste || messageInput.onPaste}
                placeholder={cooldownRemaining ? "Slow Mode ON" : placeholder}
                replaceWord={emojiReplace}
                rows={props.rows || 1}
                shouldSubmit={messageInput.shouldSubmit}
                showCommandsList={messageInput.showCommandsList}
                showMentionsList={messageInput.showMentionsList}
                SuggestionItem={SuggestionItem}
                SuggestionList={SuggestionList}
                trigger={messageInput.autocompleteTriggers || {}}
                value={props.value || messageInput.text}
              />
              <div className="t-flex t-w-full t-items-end t-justify-between">
                <div className="t-flex t-items-center t-gap-3">
                  <UploadButton
                    accept={acceptedFiles?.join(",")}
                    aria-label="File upload"
                    className="t-hidden"
                    data-testid="file-input"
                    disabled={!isUploadEnabled || maxFilesLeft === 0}
                    id={id}
                    multiple={multipleUploads}
                    onFileChange={uploadNewFiles}
                  />
                  {(isCpa || isAdmin) && !isForeignCA && (
                    <ChatTemplate
                      selectTemplate={selectTemplate}
                      dropdownOpen={templateOpen}
                      setDropdownOpen={setTemplateOpen}
                    />
                  )}
                  <label
                    className={classNames("t-cursor-pointer", {
                      "t-pointer-events-none t-cursor-not-allowed":
                        attachmentForReview || transactionsInChat.length > 0,
                    })}
                    htmlFor={id}
                  >
                    <span
                      className={classNames({
                        "t-text-neutral-50": !(
                          attachmentForReview || transactionsInChat.length > 0
                        ),
                        "t-text-neutral-10":
                          attachmentForReview || transactionsInChat.length > 0,
                      })}
                    >
                      <Paperclip />
                    </span>
                  </label>

                  <div
                    className="str-chat__emojiselect-wrapper"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <EmojiPicker
                      popperOptions={{ placement: "top-start" }}
                      pickerProps={{
                        theme: "light",
                      }}
                      buttonClassName="all:unset"
                      ButtonIconComponent={() => (
                        <div className="t-text-neutral-50">
                          <Smiley />
                        </div>
                      )}
                    />
                  </div>
                  {isAdmin && <AdminCall />}
                  {(isCpa || isAdmin) && (
                    <span onClick={(e) => e.stopPropagation()}>
                      <TaskActionsFromChat />
                    </span>
                  )}
                </div>

                <div>
                  <Button
                    onClick={handleSubmitFromButton}
                    customType="ghost_icon"
                    disabled={!contentPresentInInput}
                    size="small"
                    isLoading={isMessageSending}
                  >
                    {
                      <span
                        className={cx("t-transition-all", {
                          "t-text-purple": contentPresentInInput,
                          "t-text-neutral-40": !contentPresentInInput,
                        })}
                      >
                        <PaperPlaneRight />
                      </span>
                    }
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
