import { useState } from "react";
import { BrandEntityCreation } from "signInSignUp/BrandEntityCreation";
import { useGetDomainRecognizationQuery } from "store/apis/serviceTeam";
import BrandOption from "./brandOption";

const OnBoardingActionRight = () => {
  const [isCreateBrand, setCreateBrand] = useState(false);
  const [preIncorporated, setPreincorporated] = useState(false);
  const { data: groups = [] } = useGetDomainRecognizationQuery();
  const groupAvailable = groups?.length > 0;

  return (
    <div className="onBoardingActionRightContainer !t-ml-2 lg:t-border t-border-0 t-border-solid t-border-[transparent] t-h-full t-border-l-neutral-10 md:!t-pl-12 sm:!t-m-0 sm:!t-w-full sm:t-px-5 t-flex-1">
      {isCreateBrand ? (
        <BrandEntityCreation
          groupAvailable={groupAvailable}
          setCreateBrand={setCreateBrand}
          preIncorporated={preIncorporated}
          setPreincorporated={setPreincorporated}
        />
      ) : (
        <BrandOption setCreateBrand={setCreateBrand} />
      )}
    </div>
  );
};

export default OnBoardingActionRight;
