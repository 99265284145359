import { postNewTag } from "apis/postNewTag";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Tag from "components/design/Tag";
import { PlusIcon } from "components/icons/PlusIcon";
import { TICKETS_TAG } from "constants/ticket";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import {
  ControlProps,
  MultiValue,
  SingleValue,
  components,
} from "react-select";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { useUpdateChatTicketMutation } from "store/apis/chatTicket";
import { setSelectedChatTicket } from "store/slices/chat";
import { ChatTicket } from "types/Models/chatTicket";

const Control = ({ children, ...props }: ControlProps<OptionData>) => (
  <components.Control {...props}>
    {children}
    <div className="t-text-text-30 t-rounded t-py-2 t-px-3">
      <PlusIcon color={"#706A85"} />
    </div>
  </components.Control>
);

const TicketTagsCombobox = ({
  ticketId,
  currentTags,
}: {
  ticketId: string;
  currentTags?: ChatTicket["tags"];
}) => {
  const currentTicketTags = currentTags || [];
  const dispatch = useDispatch();
  const [updateChatTicket, { isLoading: isTicketUpdateLoading }] =
    useUpdateChatTicketMutation();
  const { alertToast } = useToast();
  const {
    data: tags = [],
    refetch: refetchTags,
    isFetching: isFetchingTags,
  } = useGetTagsForCategoryQuery({
    tagType: "TICKETS_TAG",
  });
  const updateTicketTags = async (batch_tag_ids: string, ticket_id: string) => {
    try {
      const currentTagValues = { batch_tag_ids: batch_tag_ids };
      const res = await updateChatTicket({
        ticket_id: ticket_id,
        ...currentTagValues,
      }).unwrap();
      dispatch(setSelectedChatTicket(res));
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };
  const onTagsChange = (
    values?: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    if (values && values instanceof Array) {
      let newValues = values.map((tags) => tags.value);
      let updatedTagsString = newValues.join(",") || "TAGS_REMOVED";
      updateTicketTags(updatedTagsString, ticketId);
    }
  };
  const addTicketTag = async (tagName: string) => {
    return await postNewTag({
      tagType: TICKETS_TAG,
      payload: { title: tagName, bg_color_hex: null, color_hex: null },
    });
  };
  const onTagCreate = async (tagName: string) => {
    const {
      data: { data },
    } = await addTicketTag(tagName);
    await refetchTags();
    const newTag = data;
    const updatedTags = currentTicketTags.map(
      (currentTicketTag) => currentTicketTag.uuid
    );
    updateTicketTags([...updatedTags, newTag.uuid].join(","), ticketId);
  };
  const handleSelectAll = () => {
    updateTicketTags(tags.map((t) => t.uuid).join(","), ticketId);
  };
  return (
    <div className=" t-w-full! t-flex">
      <div onClick={(e) => e.stopPropagation()} className="t-flex t-gap-1">
        <Combobox
          components={{
            Control: Control,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            Placeholder: () => null,
            LoadingIndicator: () => <Loader size="small" />,
          }}
          menuPortalTarget={document.body}
          isMulti
          styles={{
            menu: () => ({ width: "400px" }),
          }}
          name=""
          options={tags.map((t) => ({
            label: t.title,
            value: t.uuid,
          }))}
          value={currentTicketTags?.map((tag) => ({
            value: tag.uuid,
            label: (
              <Tag
                size="small"
                key={tag.uuid}
                {...tag}
                bg_color_hex="#F3F3F5"
                color_hex="#706A85"
              />
            ),
          }))}
          onChange={onTagsChange}
          creatable
          onCreateOption={onTagCreate}
          actions={
            <div>
              <Button customType="text" size="small" onClick={handleSelectAll}>
                Select All
              </Button>
            </div>
          }
          isLoading={isTicketUpdateLoading}
          backspaceRemovesValue={false}
        />
      </div>
    </div>
  );
};

export default TicketTagsCombobox;
