import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TransactionsInChat = {
  id: string;
  amount: number;
  source?: string;
  date: string;
  vendor: {
    name?: string;
    logo_url?: string;
  };
  isCreditCard: boolean;
};

const initialState = {
  transactionsInChat: [] as TransactionsInChat[],
  entityId: "",
};

export const transactionsInChat = createSlice({
  name: "transactionsInChat",
  initialState,
  reducers: {
    setTransactionsInChat: (
      state,
      action: PayloadAction<typeof initialState>
    ) => {
      state.transactionsInChat = action.payload.transactionsInChat;
      state.entityId = action.payload.entityId;
    },
  },
});

export const { setTransactionsInChat } = transactionsInChat.actions;
export default transactionsInChat.reducer;
