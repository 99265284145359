export const allMessages = "allMessages";
export const techSupport = "techSupport";
export const generalHelp = "generalHelp";

export const ALL_MESSAGES = "All messages";
export const INKLE_TEAM_AND_CPA_TEAM = "INKLE_TEAM, CPA_TEAM";
export const INKLE_TEAM = "INKLE_TEAM";
export const CPA_TEAM = "CPA_TEAM";

export const NOCHANNELFORASSIGNTOYOU = "NOCHANNELFORASSIGNTOYOU";
export const GENERAL_HELP = "/chat/generalhelp";

export const NEW_MESSAGE_COUNT_UPDATE = "NEW_MESSAGE_COUNT_UPDATE";
export const CHAT_TICKET = "Tickets";

export const ANNOUNCEMENTS_CHANNEL = "ANNOUNCEMENTS_CHANNEL";
export const MESSAGE_TYPE_DELETED = "deleted";

export const DELETED_MESSAGE_TEXT = "This message was deleted";
export const CHAT_CARD_TYPE = "card_type";
export const TASK_REVIEW_DOCUMENT = "TASK_REVIEW_DOCUMENT";
export const TASK_REVIEW_AND_PAY = "TASK_REVIEW_AND_PAY";
export const TASK_ADDITIONAL_PAYMENT = "TASK_ADDITIONAL_PAYMENT";
export const TASK_AMENDMENT_COMPLETED = "TASK_AMENDMENT_COMPLETED";
export const TICKET_CARD = "TICKET_CARD";
export const CALL_LOG_CUSTOM_TYPE = "CALL_LOG_CUSTOM_TYPE";

export const OPEN_ITEM_CUSTOM_TYPE = "OPEN_ITEM_CUSTOM_TYPE";
export const REQUESTED_INFO_CUSTOM_TYPE = "REQUESTED_INFO_CUSTOM_TYPE";
export const ESIGN_REQUEST_CUSTOM_TYPE = "ESIGN_REQUEST_CUSTOM_TYPE";

export const REQUESTED_DOCUMENTS_CUSTOM_TYPE =
  "REQUESTED_DOCUMENTS_CUSTOM_TYPE";

export const TRANSACTIONS = "TRANSACTIONS";
