import { Button } from "components/DesignSystem/Button/Button";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { timezones } from "data/timezones";
import { useToast } from "hooks/useToast";
import type { User } from "types/Models/user";
import React, { useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import { updateAdditionalInfo, updateName } from "../../apis/profileData";
import { PROFILE_UPDATED } from "../../constants/userTypes";
import { profileUpdateSchema } from "../../formValidations/profileUpdateSchema";
import "../../static/styles/components/profileUpdate.css";

import "../../static/styles/components/profileUpdate.css";
import Pencil from "components/icons/pencil";
import { EmailUpdate } from "./EmailUpdate";
import { MobileUpdate } from "./MobileUpdate";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";

const timezoneOptions = timezones
  .map(({ value, text }) => [value, `${value} - ${text}`])
  .sort((a, b) => a[0].localeCompare(b[0]));

type TeamUpdate = {
  linkedIn_url: string;
  secondary_mobile: string;
  current_timezone: string;
};

type ProfileUpdateProps = {
  user: User;
  updateUser: (user: User) => void;
  isCustomerView?: boolean;
  updateUserProfile?: (user: TeamUpdate) => void;
};

export const ProfileUpdate = ({
  user,
  updateUser,
  isCustomerView = true,
  updateUserProfile,
}: ProfileUpdateProps) => {
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [showMobileModal, setShowMobileModal] = useState<boolean>(false);
  const updateProfile = updateUserProfile || updateAdditionalInfo;
  const { successToast, alertToast } = useToast();

  const onSubmit = async (values: User | TeamUpdate) => {
    const firstNameChanged = user.first_name !== (values as User).first_name;
    const lastNameChanged = user.last_name !== (values as User).last_name;
    const secondaryMobile = values.secondary_mobile;

    const finalValues = {
      ...values,
      secondary_mobile: secondaryMobile,
    };

    try {
      if (firstNameChanged || lastNameChanged) {
        const updatedProfile: unknown = await updateName(finalValues as User);
        updateUser?.(updatedProfile as User);
      }

      const updatedProfile: unknown = await updateProfile(finalValues);
      updateUser?.(updatedProfile as User);

      successToast({ message: PROFILE_UPDATED });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const {
    email,
    first_name,
    last_name,
    mobile,
    linkedIn_url,
    current_timezone,
    secondary_mobile,
  } = user;

  const cpaInitialValues = {
    secondary_mobile: secondary_mobile ? `${secondary_mobile}` : "",
    current_timezone,
    linkedIn_url,
  };

  const customerInitialValues = {
    email,
    first_name,
    last_name,
    mobile: mobile,
    secondary_mobile: secondary_mobile ? `${secondary_mobile}` : "",
    current_timezone,
    linkedIn_url,
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={
          isCustomerView ? customerInitialValues : cpaInitialValues
        }
        validationSchema={isCustomerView ? profileUpdateSchema : null}
        enableReinitialize
      >
        {({ values, dirty, isSubmitting }: FormikValues) => {
          return (
            <Form className="all:unset t-flex t-flex-col t-gap-6">
              {isCustomerView && (
                <>
                  <div className="t-flex t-gap-3">
                    <TextInput label="First name" name="first_name" />
                    <TextInput label="Last name" name="last_name" />
                  </div>

                  <div className="t-flex t-flex-col t-gap-1.5">
                    <div className="t-text-body-sm t-text-text-30">Email</div>
                    <div
                      className="t-flex t-gap-1 t-items-center hover:t-cursor-pointer"
                      onClick={() => setShowEmailModal(true)}
                    >
                      <div className="t-text-body">{values?.email}</div>
                      <Pencil color="grey" size="14" />
                    </div>
                  </div>

                  <div className="t-flex t-flex-col t-gap-1.5">
                    <div className="t-text-body-sm t-text-text-30">
                      Primary mobile number
                    </div>
                    <div
                      className="t-flex t-w-fit t-gap-1 t-items-center hover:t-cursor-pointer"
                      onClick={() => setShowMobileModal(true)}
                    >
                      <div className="t-text-body">{`${values?.mobile}`}</div>
                      <Pencil color="grey" size="14" />
                    </div>
                  </div>
                </>
              )}
              <div>
                <MobileInput
                  label="Secondary mobile number"
                  name="secondary_mobile"
                  value={values?.secondary_mobile}
                />
              </div>

              <TextInput
                label="LinkedIn"
                name="linkedIn_url"
                placeholder="Your linkedIn url"
                value={values?.linkedIn_url}
              />
              <SelectDropDown label="Time zone" name="current_timezone">
                <option value="">Select timezone</option>
                {timezoneOptions?.map((option) => {
                  return (
                    <option value={option[0]} key={option[0]}>
                      {option[1]}
                    </option>
                  );
                })}
              </SelectDropDown>

              <div className="t-flex t-justify-end">
                <Button
                  customType="primary"
                  size="regular"
                  disabled={!dirty || isSubmitting}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Update
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <EmailUpdate
        email={email}
        showEmailModal={showEmailModal}
        setShowEmailModal={setShowEmailModal}
      />
      <MobileUpdate
        showMobileModal={showMobileModal}
        setShowMobileModal={setShowMobileModal}
        mobile={mobile}
      />
    </>
  );
};
