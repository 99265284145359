import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { AvatarGroup } from "components/DesignSystem/AvatarGroup/AvatarGroup";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import {
  CHAT_REQUESTS,
  CONNECTIONS,
  DOCUMENTS_REQUESTED,
  DOCUMENT_REQUESTED,
  INFO_REQUESTED,
  PENDING_REVIEW_TRANSACTIONS,
  RECONCILIATION,
} from "constants/bookkeeping";
import { REQUESTED_INFO_CUSTOM_TYPE } from "constants/chatType";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { ReactNode, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import BankCircular from "static/images/BankCircular.svg";
import EmptyActionItem from "static/images/EmptyActionItem.svg";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useBooksActionItemsQuery } from "store/apis/books";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { useChatContext } from "stream-chat-react";
import { BankItems } from "types/Models/books";
import { BooksOnboarding } from "./BooksOnboarding";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { CaretDown } from "components/icons/CaretDown";
import classNames from "classnames";
import { BooksServices } from "components/icons/BooksServices";
import UpgradeBooksPlusPlan from "components/BooksDashboard/UpgradeBooksPlusPlan";

const Card = ({
  title,
  children,
}: {
  title?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="t-border t-border-solid t-border-neutral-0 t-w-full t-rounded t-shadow-light-30 t-overflow-hidden">
      <div className="t-p-4 t-text-subtitle t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-flex t-gap-2 t-items-center">
        {title}
      </div>
      <>{children}</>
    </div>
  );
};

const ItemCard = ({
  logo,
  text,
  action,
}: {
  logo?: ReactNode;
  text: string;
  action: ReactNode;
}) => {
  return (
    <div className="t-p-4 t-flex t-justify-between t-border-b t-border-solid t-border-neutral-0 t-border-0 t-flex-wrap t-gap-2">
      <div className="t-flex t-gap-2 t-items-center">
        {logo}
        <div className="t-flex t-flex-col t-gap-0.5">
          <div className="t-text-subtext">{text}</div>
        </div>
      </div>
      {action}
    </div>
  );
};

const ActionItemsCard = ({ item }: any) => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  //@ts-ignore
  switch (item?.item_type) {
    case CONNECTIONS:
      const connectionItem = item as BankItems;
      return (
        <ItemCard
          logo={
            <Avatar alt={CONNECTIONS} size="large" src={connectionItem?.logo} />
          }
          text={`${connectionItem?.bank_name} accounts are disconnected`}
          action={
            <ConditionalLink to={`/books/data-sources${search}`}>
              <Button customType="danger" size="small">
                Reconnect
              </Button>
            </ConditionalLink>
          }
        />
      );

    case RECONCILIATION:
      return (
        <ItemCard
          logo={<Avatar alt={RECONCILIATION} size="large" src={BankCircular} />}
          text="Reconcile transactions in Inkle Books"
          action={
            <ConditionalLink to={`/books/transactions/reconciliation${search}`}>
              <Button customType="secondary" size="small">
                Reconcile
              </Button>
            </ConditionalLink>
          }
        />
      );

    case PENDING_REVIEW_TRANSACTIONS:
      return (
        <ItemCard
          logo={
            <Avatar
              alt={item?.transaction_count?.toString()}
              size="large"
              src=""
            />
          }
          text={`Pending review of ${item?.transaction_count} transactions`}
          action={
            <ConditionalLink
              to={`/books/transactions${search}&notReviewed=true`}
            >
              <Button customType="secondary" size="small">
                Review
              </Button>
            </ConditionalLink>
          }
        />
      );

    case CHAT_REQUESTS:
      let displayText = item?.header;
      let displayCount = item?.transaction_count;

      if (item?.header === INFO_REQUESTED) {
        displayText = `Information requested for ${item?.transaction_count} transactions`;
      }

      if (item?.header.includes(DOCUMENT_REQUESTED)) {
        displayCount = item?.text.replace(DOCUMENT_REQUESTED, "");
        displayText = DOCUMENT_REQUESTED;
      }

      if (item?.header.includes(DOCUMENTS_REQUESTED)) {
        displayCount = item?.header.replace(DOCUMENTS_REQUESTED, "");
        displayText = DOCUMENTS_REQUESTED;
      }

      const jumpToMsg = () => {
        dispatch(openFloatingChat());
        dispatch(setToOpenChatId(item?.channel_url!));
        dispatch(setScrollToMessageId(item?.id));
      };

      return (
        <ItemCard
          logo={<Avatar alt={displayCount?.toString()} size="large" src="" />}
          text={displayText}
          action={
            <Button customType="secondary" size="small" onClick={jumpToMsg}>
              Add info
            </Button>
          }
        />
      );

    default:
      return null;
  }
};

const BankConnections = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { search } = useLocation();

  const {
    data: banks,
    isLoading,
    isSuccess,
  } = useGetEntityBanksQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const { current_balance: totalBalance = 0 } = banks?.aggregate || {};

  const bankLogos =
    banks?.accounts.map(({ bank_brand: { logo, name } }) => ({ logo, name })) ||
    [];

  const isEmpty = banks?.accounts.length === 0;

  return (
    <Card
      title={
        <div className="t-flex t-justify-between t-items-center t-w-full">
          Current cash balance
          <ConditionalLink to={`/books/data-sources${search}`}>
            <Button customType="link" size="small">
              View all
            </Button>
          </ConditionalLink>
        </div>
      }
    >
      <Async.Root {...{ isEmpty, isLoading, isSuccess }}>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <div className="t-flex t-items-center t-justify-center t-text-center t-flex-col t-gap-3 t-bg-[url('/src/static/images/TotalbalanceUI.svg')] t-bg-no-repeat t-bg-cover t-overflow-hidden t-bg-center t-py-6">
            <AvatarGroup max={3} variant="no-fill" size="regular">
              {bankLogos.map(({ logo, name }) => (
                <Avatar src={logo} alt={name} size="regular" key={name} />
              ))}
            </AvatarGroup>
            <h4 className="t-text-h4 t-text-text-black">
              {<AmountSuperScript amount={totalBalance} />}
            </h4>
          </div>
        </Async.Success>
      </Async.Root>
    </Card>
  );
};

const RequestService = () => {
  const { search } = useLocation();

  return (
    <div className="t-p-6 t-bg-books-services t-rounded-lg t-border t-border-solid t-border-neutral-0 t-shadow-light-30 t-flex t-justify-between">
      <div className="t-flex t-gap-7 t-flex-col">
        <div className="t-flex t-gap-1.5 t-flex-col">
          <span className="t-text-subtitle">Bookkeeping services</span>
          <span className="t-text-body t-text-text-30">
            Request custom bookkeeping <br /> services for your company.
          </span>
        </div>
        <ConditionalLink to={`/books/services${search}`}>
          <Button size="small">Start now</Button>
        </ConditionalLink>
      </div>
      <span className="t-text-purple-10 t-self-end">
        <BooksServices />
      </span>
    </div>
  );
};

const ActionItems = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { client } = useChatContext();
  const { isCustomer } = useRoleBasedView();
  const [viewAll, setViewAll] = useState(false);

  const {
    data: booksActionItems,
    isLoading,
    isSuccess,
  } = useBooksActionItemsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { action_items_response, channel_url } = booksActionItems || {};

  const actionItems =
    action_items_response?.filter(({ item_type }) =>
      isCustomer
        ? item_type !== "Reconciliation" && item_type !== "Financial Closing"
        : item_type !== "Financial Closing"
    ) || [];

  const { messages: chatActionItems, loading } = usePaginatedMessages(
    client,
    {
      id: channel_url,
    },
    {},
    {},
    {
      custom_type: {
        $in: [REQUESTED_INFO_CUSTOM_TYPE],
      },
      card_status: { $eq: "REQUESTED" },
    },
    !channel_url
  );

  const chatCards = chatActionItems?.map(
    ({
      message: {
        custom_data: { header, transaction_count },
        id,
      },
    }: any) => ({
      header,
      transaction_count,
      id,
      channel_url,
      item_type: CHAT_REQUESTS,
    })
  );

  const allBooksActionItems = actionItems.flatMap(({ items, item_type }) =>
    items.map((item) => ({ ...item, item_type }))
  );

  const allItems = [...allBooksActionItems, ...chatCards];

  const lessBooksActionItems = viewAll ? allItems : allItems.slice(0, 5);

  const totalActionItemCount = allBooksActionItems.length + chatCards.length;
  const isEmpty = totalActionItemCount === 0;

  return (
    <Card title="Your action items">
      <Async.Root {...{ isEmpty, isLoading: isLoading || loading, isSuccess }}>
        <Async.Empty>
          <div className="t-w-full t-flex t-justify-center t-items-center t-h-52">
            <img src={EmptyActionItem} alt="noActions" />
          </div>
        </Async.Empty>
        <Async.Success>
          {lessBooksActionItems.map((item) => (
            <ActionItemsCard item={item} key={item.item_type} />
          ))}
        </Async.Success>
      </Async.Root>
      {totalActionItemCount > 5 && (
        <button
          className="all:unset t-flex t-w-full t-p-2 t-items-center t-justify-center t-select-none t-text-body-sm t-text-text-100"
          onClick={() => setViewAll((prev) => !prev)}
        >
          <span className="t-w-16">{viewAll ? "View less" : "View all"}</span>
          <span
            className={classNames(
              "t-transform t-transition t-duration-300 t-ease-in-out",
              { "-t-rotate-180": viewAll, "t-rotate-0": !viewAll }
            )}
          >
            <CaretDown />
          </span>
        </button>
      )}
    </Card>
  );
};

export const BooksHome = () => {
  usePageTitle("Books Dashboard");
  const { isCustomer } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    authtoken: { first_name },
  } = useContext(authContext);

  const { data: products, isLoading } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) => p.name === PRODUCT_ONBOARDING.BOOKS
  );

  const isBooksOnboardingComplete =
    platformOnboarding && platformOnboarding.status === "COMPLETED";

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout header={<Header v2 title={`Welcome ${first_name}`} />}>
      <div className="t-flex t-gap-4 t-flex-col">
        <UpgradeBooksPlusPlan />
        {isBooksOnboardingComplete ? (
          <div className="t-flex t-w-full t-gap-5 md:t-flex-nowrap t-flex-wrap">
            <div className="t-flex t-gap-5 t-flex-col md:t-w-[55%] t-w-full">
              <ActionItems />
            </div>
            <div className="md:t-w-[45%] t-sticky t-top-0 t-h-full t-flex t-gap-5 t-flex-col t-w-full">
              <BankConnections />
              {isCustomer && <RequestService />}
            </div>
          </div>
        ) : (
          <BooksOnboarding />
        )}
      </div>
    </DashboardLayout>
  );
};
