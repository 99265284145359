import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { useModal } from "hooks/useModal";
import { InternalTeamResponse } from "store/apis/internalTeamSetting";
import { OptionDropdown } from "components/icons/optionDropdown";
import { DeleteInternalTeam } from "./DeleteInternalTeam";

type InternalTeamOptionsProps = { team: InternalTeamResponse };

export const InternalTeamOptions = ({ team }: InternalTeamOptionsProps) => {
  const {
    open: openDeleteTeamModal,
    close: closeDeleteTeamModal,
    isOpen: showDeleteTeamModal,
  } = useModal();
  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild className="t-group">
          <div className="t-text-text-30">
            <OptionDropdown color="currentColor" />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            sideOffset={8}
            side="bottom"
            className="t-mr-10 t-min-w-40"
          >
            <Dropdown.Item
              key="delete"
              onSelect={openDeleteTeamModal}
              className="hover:!t-text-purple hover:!t-bg-purple-0"
            >
              Remove team
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <DeleteInternalTeam
        open={showDeleteTeamModal}
        onClose={closeDeleteTeamModal}
        teamId={team.uuid}
      />
    </>
  );
};
