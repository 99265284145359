import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { useGetCrmCountriesDataQuery } from "store/apis/onboarding";

export const PracticeCrmFilter = ({
  updateFilter,
  values,
}: {
  updateFilter: <S extends "PAID" | "UNPAID" | "COUNTRY">(
    name: S,
    newValue: {
      UNPAID: boolean;
      PAID: boolean;
      COUNTRY: string[];
    }[S]
  ) => void;
  values: {
    UNPAID: boolean;
    PAID: boolean;
    COUNTRY: string[];
  };
}) => {
  const updateFilterCallback =
    (name: Parameters<typeof updateFilter>[0]) =>
    (newValues: Parameters<typeof updateFilter>[1]) => {
      updateFilter(name, newValues);
    };
  const { data: countries } = useGetCrmCountriesDataQuery();

  return (
    <Filter.Root
      defaultValue="AMOUNT"
      title="Filters"
      capsule={
        <>
          {values?.UNPAID && (
            <Chip
              onClose={() => updateFilter("UNPAID", false)}
              isActive
              filterType="UNPAID"
            >
              Unpaid
            </Chip>
          )}
          {values?.PAID && (
            <Chip
              onClose={() => updateFilter("PAID", false)}
              isActive
              filterType="PAID"
            >
              Paid
            </Chip>
          )}

          {values?.COUNTRY.length > 0 && (
            <Chip
              onClose={() => updateFilter("COUNTRY", [])}
              isActive
              filterType="COUNTRY"
            >
              Country ({values.COUNTRY.length})
            </Chip>
          )}
        </>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="AMOUNT">Amount</Filter.ListItem>
          <Filter.ListItem value="COUNTRY">Country</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="AMOUNT" block>
          <div className="t-flex t-flex-col t-gap-3">
            <Checkbox
              label="Unpaid"
              name="unpaid"
              onChange={(e) => updateFilter("UNPAID", e.target.checked)}
              checked={values?.UNPAID}
            />
            <Checkbox
              label="Paid"
              name="paid"
              onChange={(e) => updateFilter("PAID", e.target.checked)}
              checked={values?.PAID}
            />
          </div>
        </Filter.Body>
        {countries && (
          <Filter.Body value="COUNTRY" block>
            <MultiSelectFilter
              onChange={updateFilterCallback("COUNTRY")}
              options={countries?.map((country) => ({
                value: country.code,
                label: country.name,
              }))}
              selected={values?.COUNTRY}
            />
          </Filter.Body>
        )}
      </Filter.Portal>
    </Filter.Root>
  );
};
