export const PdfFile = () => {
  return (
    <>
      <span className="t-sr-only">PDF file</span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_157_13559)">
          <path
            d="M16.875 11.875H14.375V16.25"
            stroke="currentColor"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.25 14.375H14.375"
            stroke="currentColor"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.75 15H5C5.4144 15 5.81183 14.8354 6.10485 14.5424C6.39788 14.2493 6.5625 13.8519 6.5625 13.4375C6.5625 13.0231 6.39788 12.6257 6.10485 12.3326C5.81183 12.0396 5.4144 11.875 5 11.875H3.75V16.25"
            stroke="currentColor"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.75 11.875V16.25H10C10.5802 16.25 11.1366 16.0195 11.5468 15.6093C11.957 15.1991 12.1875 14.6427 12.1875 14.0625C12.1875 13.4823 11.957 12.9259 11.5468 12.5157C11.1366 12.1055 10.5802 11.875 10 11.875H8.75Z"
            stroke="currentColor"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.75 8.75V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.875L16.25 6.875V8.75"
            stroke="currentColor"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.875 2.5V6.875H16.25"
            stroke="currentColor"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_157_13559">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
