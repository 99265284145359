import classNames from "classnames";
import { MultilevelAccordionContext } from "components/ChartOfAccountsAccordion/MultilevelAccordionContext";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import Table from "components/DesignSystem/Table/V2/Table";
import { ArrowRight } from "components/icons/ArrowRight";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import React, {
  Fragment,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { openSlider } from "store/slices/transactions";
import { RootState } from "store/store";
import { GeneralLedger } from "types/Models/books";
import { parse } from "qs";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { AccordionAnimation } from "components/AccordionAnimation";

type RootTypes = {
  /**
   * Maximum indent level, default 4
   */
  maxIndent?: number;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Root = ({ maxIndent = 4, children, ...props }: RootTypes) => {
  return (
    <MultilevelAccordionContext.Provider value={{ maxIndent }}>
      <div {...props}>{children}</div>
    </MultilevelAccordionContext.Provider>
  );
};

const Header = ({
  children,
  ...props
}: { children: ReactNode } & React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>) => {
  return (
    <header
      {...props}
      className="t-py-3 t-text-text-30 t-text-subtext-sm t-border t-border-t t-border-solid t-border-b-0 t-border-x-0 t-border-neutral-10 t-rounded-t-lg t-flex t-justify-between t-w-full t-gap-24 t-items-center"
    >
      {children}
    </header>
  );
};

const EntriesCard = ({ entriesCount }: { entriesCount: number }) => {
  return (
    <div className="t-bg-surface-lighter-grey t-px-1.5 t-py-0.5 t-border t-border-solid t-border-neutral-0 t-rounded">
      {entriesCount} entries
    </div>
  );
};

const TableLastRow = ({
  startDate,
  endDate,
  totalDebits,
  totalCredits,
}: {
  startDate: string;
  endDate: string;
  totalDebits: number;
  totalCredits: number;
}) => {
  return (
    <Table.Row className="!t-p-2 t-border-none">
      <Table.Cell className="t-text-body-sm t-text-text-30 t-p-2">
        {dayjs(startDate).format(DD_MMM_YYYY)} to{" "}
        {dayjs(endDate).format(DD_MMM_YYYY)}
      </Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell className="t-text-body-sm t-pl-2">
        <AmountSuperScript amount={Math.abs(totalDebits)} />
      </Table.Cell>
      <Table.Cell className="t-text-text-30 t-text-body-sm t-pl-2">
        <AmountSuperScript amount={totalCredits} />
      </Table.Cell>
      <Table.Cell></Table.Cell>
    </Table.Row>
  );
};

export const GeneralLedgerTable = ({
  tableData,
  startDate,
  endDate,
  totalCredits,
  totalDebits,
}: {
  tableData: GeneralLedger["transactions"];
  startDate: string;
  endDate: string;
  totalCredits: number;
  totalDebits: number;
}) => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<any>();
  const { sliderTransactions } = useSelector(
    (state: RootState) => state?.transactions
  );
  const columns = [
    columnHelper.accessor("date", {
      id: "date",
      size: 350,
      header: () => <span>Date</span>,
      cell: (info) => (
        <div className="t-text-body-sm t-text-text-60">
          {dayjs(info.getValue()).format(DD_MMM_YYYY)}
        </div>
      ),
    }),
    columnHelper.accessor("merchant", {
      id: "merchant",
      size: 300,
      header: () => "Vendor",
      cell: (info) => {
        const { logo, name } = info.getValue();
        if (!Boolean(name)) {
          return "-";
        }
        return (
          <div className="t-flex t-gap-2 t-items-center">
            <Avatar src={logo || ""} alt={name} />
            <div className="t-text-body-sm t-text-text-60">{name}</div>
          </div>
        );
      },
    }),
    // columnHelper.accessor("memo", {
    //   id: "memo",
    //   size: 350,
    //   header: () => <span>Memo</span>,
    //   cell: (info) => (
    //     <div className="t-text-subtext t-text-text-60">
    //       {info.getValue() || "-"}
    //     </div>
    //   ),
    // }),
    columnHelper.accessor("amount", {
      id: "debit",
      size: 200,
      cell: (info) => (
        <div className="t-text-body-sm t-text-text-60">
          {info.getValue() < 0 && (
            <AmountSuperScript amount={Number(-info.getValue())} />
          )}
        </div>
      ),
      header: () => <span>Debit</span>,
    }),
    columnHelper.accessor("amount", {
      id: "credit",
      size: 200,
      header: () => <span>Credit</span>,
      cell: (info) => (
        <div className="t-text-body-sm t-text-text-60">
          {info.getValue() >= 0 && (
            <AmountSuperScript amount={Number(info.getValue())} />
          )}
        </div>
      ),
    }),
    columnHelper.accessor("running_total", {
      id: "running_total",
      header: () => <div className="t-flex t-justify-end">Running Total</div>,
      cell: (info) => {
        return (
          <div className="t-text-body-sm t-text-text-60 t-flex t-justify-end">
            <AmountSuperScript amount={Number(info.getValue())} />
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onRowClick = (
    transactionId: string,
    e: Event | React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(openSlider(transactionId));
  };

  return (
    <Table.Container className="t-h-full t-mb-3">
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row
              key={headerGroup.id}
              className="t-border t-border-y t-border-solid t-border-x-0 t-border-neutral-0"
            >
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: header.getSize() }}
                  className="t-py-2.5 t-px-2 t-text-body-sm t-text-text-30 t-uppercase t-truncate"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row
              key={row.id}
              className={classNames(
                "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                {
                  "t-bg-surface-lighter-grey": sliderTransactions.find(
                    ({ transactionId }) => transactionId === row.original.uuid
                  ),
                }
              )}
              onClick={(e) => onRowClick(row.original.uuid, e)}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.Cell
                  key={cell.id}
                  style={{ width: cell.column.getSize(), height: "40px" }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
          <TableLastRow
            startDate={startDate}
            endDate={endDate}
            totalCredits={totalCredits}
            totalDebits={totalDebits}
          />
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const Trigger = ({
  data,
  level = 0,
  parentUUID,
}: {
  data?: GeneralLedger[];
  level?: number;
  parentUUID?: string | null;
}) => {
  const { search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });

  const categoryId = query.category;

  const getParentType = (
    currentData: GeneralLedger[] | undefined,
    uuid: string
  ): GeneralLedger | null => {
    if (!currentData) {
      return null;
    }
    for (const category of currentData) {
      if (category.types && category.types.some((type) => type.uuid === uuid)) {
        return category;
      }
      const parentType = getParentType(category.types, uuid);
      if (parentType) {
        return parentType;
      }
    }
    return null;
  };

  useEffect(() => {
    if (categoryId && data) {
      const findAndSetActiveParents = (
        currentData: GeneralLedger[],
        parentId?: string | null
      ) => {
        currentData.forEach((category) => {
          if (category.uuid === categoryId) {
            setActiveParents(category, parentId);
          } else if (category.types) {
            findAndSetActiveParents(category.types, category.uuid);
          }
        });
      };

      const setActiveParents = (
        ledger: GeneralLedger,
        parentId?: string | null
      ) => {
        let currentType: GeneralLedger | null = ledger;
        while (currentType?.uuid !== parentId && currentType?.uuid) {
          setActiveAccordion((prev) => new Set([...prev, currentType?.uuid!]));
          currentType = getParentType(data, currentType.uuid);
        }
      };

      findAndSetActiveParents(data, parentUUID);
    }
  }, [categoryId, data, parentUUID]);

  const { maxIndent } = useContext(MultilevelAccordionContext);
  let nextLevel = level;
  if (nextLevel <= maxIndent) {
    nextLevel = nextLevel + 1;
  }

  const [activeAccordion, setActiveAccordion] = useState<Set<string>>(
    new Set()
  );

  const toggleAccordion = (uuid: string, types: GeneralLedger[]) => {
    if (!types) {
      return;
    } else if (activeAccordion.has(uuid)) {
      setActiveAccordion((prev) => {
        const newList = new Set(Array.from(prev));
        newList.delete(uuid);
        return newList;
      });
    } else {
      setActiveAccordion((prev) => {
        const newList = new Set(Array.from(prev));
        newList.add(uuid);
        return newList;
      });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      {data?.map(
        ({
          uuid,
          name,
          types,
          transactions,
          identifier,
          summary_amount,
          summary_count,
          transactions_count,
          running_total,
          start_date,
          end_date,
          total_debits,
          total_credits,
          show_data,
          opening_balance,
        }) => {
          return (
            <div className={classNames("t-w-full")} key={uuid}>
              <button
                className={classNames(
                  "all:unset t-flex t-justify-between t-w-full t-pr-3 t-gap-24 t-items-center t-pl-1 t-rounded"
                )}
                onClick={() => toggleAccordion(uuid, types)}
              >
                <div
                  className={classNames("t-flex t-flex-col t-w-full", {
                    // "t-ml-4": nextLevel >= 1,
                  })}
                >
                  <div
                    className={classNames(
                      "t-flex t-items-center t-justify-between t-w-full t-gap-2 t-py-2 hover:t-bg-surface-lighter-grey t-px-2",
                      {
                        "t-border t-border-t t-border-solid t-border-b-0 t-border-x-0 t-border-neutral-0":
                          activeAccordion.has(uuid),
                      }
                    )}
                  >
                    <div className="t-flex t-gap-2 t-items-center">
                      <div
                        className={classNames(
                          "t-transform t-transition t-duration-300 t-ease-in-out",
                          { "t-rotate-90": activeAccordion.has(uuid) }
                        )}
                      >
                        <ArrowRight stroke="1.5" color="currentColor" />
                      </div>
                      <div className="t-text-text-60 t-text-body-sm">
                        {identifier}-{name}
                      </div>
                    </div>
                    <div className="t-text-body-sm t-flex t-gap-2 t-items-center">
                      <EntriesCard entriesCount={summary_count} />
                      Total: <AmountSuperScript amount={summary_amount} />
                    </div>
                  </div>
                  {activeAccordion.has(uuid) && (
                    <div
                      className="t-text-body-sm t-text-text-60 t-w-full t-flex t-justify-end t-gap-4 t-items-center t-border t-border-t t-border-solid t-border-b-0 t-border-x-0 t-border-neutral-0 t-py-2 t-cursor-auto t-px-2"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Starting Balance: {"  "}
                      <AmountSuperScript amount={opening_balance} />
                    </div>
                  )}
                </div>
              </button>
              <AnimatePresence>
                {activeAccordion.has(uuid) && types && (
                  <AccordionAnimation>
                    <div
                      className={classNames({
                        "t-ml-4": nextLevel >= 1,
                      })}
                    >
                      <>
                        {transactions?.length > 0 && (
                          <div className="t-mb-8">
                            {nextLevel === 1 && (
                              <div className="t-flex t-px-3 t-rounded t-flex-col">
                                <div className="t-flex t-justify-between t-items-center hover:t-bg-surface-lighter-grey t-border t-border-t t-border-solid t-border-b-0 t-border-x-0 t-border-neutral-0 t-py-[11px] t-text-text-60 t-text-body-sm t-px-2">
                                  <div>
                                    {identifier}-{name}
                                  </div>
                                  <div>
                                    Running Total:{" "}
                                    <AmountSuperScript amount={running_total} />
                                  </div>
                                </div>
                                <div className="t-text-body-sm t-text-text-60 t-w-full t-flex t-justify-end t-gap-4 t-items-center hover:t-bg-surface-lighter-grey t-border t-border-t t-border-solid t-border-b-0 t-border-x-0 t-border-neutral-0 t-p-2">
                                  <EntriesCard
                                    entriesCount={transactions_count}
                                  />
                                  <div>
                                    Starting Balance: {"  "}
                                    <AmountSuperScript
                                      amount={opening_balance}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {show_data && (
                              <div
                                className={classNames({
                                  "t-ml-4 t-w-[calc(100%-32px)]":
                                    nextLevel >= 1,
                                })}
                              >
                                <GeneralLedgerTable
                                  tableData={transactions}
                                  startDate={start_date}
                                  endDate={end_date}
                                  totalDebits={total_debits}
                                  totalCredits={total_credits}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <Trigger
                          data={types}
                          level={nextLevel}
                          parentUUID={uuid}
                        />
                      </>
                    </div>
                  </AccordionAnimation>
                )}
              </AnimatePresence>
            </div>
          );
        }
      )}
    </Fragment>
  );
};

const GeneralLedgerAccordion = { Root, Trigger, Header };

export default GeneralLedgerAccordion;
