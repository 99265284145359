import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";

export const ConditionalLink = ({
  to,
  children,
  target,
  className = "",
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to?: string;
  children?: ReactNode;
  target?: string;
  className?: string;
}) => {
  if (!to) {
    return <>{children}</>;
  }

  const isExternal =
    to?.startsWith("https://") ||
    to?.startsWith("http://") ||
    to?.startsWith("mailto") ||
    to?.startsWith("tel");

  if (isExternal) {
    let externalTarget = target || "_blank";
    return (
      <a
        {...props}
        href={to}
        target={externalTarget}
        rel={externalTarget === "_blank" ? "noreferrer" : undefined}
        className={cx("text-decoration-none", {
          [className]: className,
        })}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      {...props}
      target={target}
      rel={target === "_blank" ? "noreferrer" : undefined}
      activeClassName="active-navbar"
      to={to}
      className={cx("text-decoration-none", {
        [className]: className,
      })}
    >
      {children}
    </NavLink>
  );
};
