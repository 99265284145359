import Modal from "components/DesignSystem/Modal/Modal";
import React from "react";
import { useDropzone } from "react-dropzone";
import { ModalProps } from "types/utils/modal";
import ChooseFromComp from "static/images/ChooseFromComp.svg";
import Folder from "static/images/Folder.svg";
import {
  UploadFromInkleDocsFileSelectorModal,
  UploadFromInkleDocsFileSelectorModalProps,
} from "components/UploadFromInkleDocsFileSelectorModal/UploadFromInkleDocsFileSelectorModal";
import { useModal } from "hooks/useModal";
import { SelecteFile } from "types/Models/documents";
import { FileType } from "components/FileInput/FileInput";

type UploadFromSelectorModalProps = {
  allowSingleUpload?: boolean;
  onDrop: (files: FileType[]) => void;
  accept?: { [key: string]: string[] };
} & ModalProps &
  Pick<UploadFromInkleDocsFileSelectorModalProps, "fileTag" | "groupId">;

export const UploadFromSelectorModal = ({
  close,
  isOpen,
  allowSingleUpload = false,
  onDrop: onDropFromProps,
  groupId,
  fileTag,
  accept = {
    "image/*": [".png", ".jpeg", ".jpg", ".webp", ".avif"],
    "application/pdf": [".pdf"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "text/csv": [".csv"],
  },
}: UploadFromSelectorModalProps) => {
  const onDrop = (files: File[]) => {
    onDropFromProps(files);
    close();
  };

  const onUpload = (file: SelecteFile) => {
    onDropFromProps([file]);
    close();
  };

  const { open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxSize: 25000000,
    accept,
    maxFiles: allowSingleUpload ? 1 : Infinity,
  });

  const uploadFromInkle = useModal();

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="regular">
          <Modal.Header>
            Upload from
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-mb-6">
            <div
              className="t-flex t-flex-col t-items-center t-justify-center t-gap-5"
              {...getRootProps()}
            >
              <button
                className="all:unset t-flex t-w-96 t-cursor-pointer t-items-center t-justify-center t-gap-2 t-rounded t-border t-border-dashed t-border-neutral-20 t-py-10 t-text-subtitle-sm hover:t-border-purple-40 focus-visible:t-border-purple-40"
                onClick={uploadFromInkle.open}
                title="Choose from Inkle Document"
              >
                <img src={Folder} alt="Inkle Doc" />
                Choose from Inkle Document
              </button>
              <>OR</>
              <button
                className="all:unset t-flex t-w-96 t-cursor-pointer t-items-center t-justify-center t-gap-2 t-rounded t-border t-border-dashed t-border-neutral-20 t-py-10 t-text-subtitle-sm hover:t-border-purple-40 focus-visible:t-border-purple-40"
                onClick={open}
                title="Choose a file from computer"
              >
                <input {...getInputProps()} data-testid="file-input" />
                <img src={ChooseFromComp} alt="device" />
                Choose a file from computer
              </button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      {uploadFromInkle.isOpen && (
        <UploadFromInkleDocsFileSelectorModal
          close={uploadFromInkle.close}
          isOpen={uploadFromInkle.isOpen}
          onUpload={onUpload}
          groupId={groupId}
          fileTag={fileTag}
        />
      )}
    </>
  );
};
