export const UploadFileIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79289 1.79289C2.98043 1.60536 3.23478 1.5 3.5 1.5H9.5C9.63261 1.5 9.75979 1.55268 9.85355 1.64645L13.3536 5.14645C13.4473 5.24021 13.5 5.36739 13.5 5.5V13.5C13.5 13.7652 13.3946 14.0196 13.2071 14.2071C13.0196 14.3946 12.7652 14.5 12.5 14.5H3.5C3.23478 14.5 2.98043 14.3946 2.79289 14.2071C2.60536 14.0196 2.5 13.7652 2.5 13.5V2.5C2.5 2.23478 2.60536 1.98043 2.79289 1.79289ZM9.29289 2.5L3.5 2.5L3.5 13.5H12.5V5.70711L9.29289 2.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 1.5C9.77614 1.5 10 1.72386 10 2V5H13C13.2761 5 13.5 5.22386 13.5 5.5C13.5 5.77614 13.2761 6 13 6H9.5C9.22386 6 9 5.77614 9 5.5V2C9 1.72386 9.22386 1.5 9.5 1.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64645 7.14645C7.84171 6.95118 8.15829 6.95118 8.35355 7.14645L10.1036 8.89645C10.2988 9.09171 10.2988 9.40829 10.1036 9.60355C9.90829 9.79882 9.59171 9.79882 9.39645 9.60355L8 8.20711L6.60355 9.60355C6.40829 9.79882 6.09171 9.79882 5.89645 9.60355C5.70118 9.40829 5.70118 9.09171 5.89645 8.89645L7.64645 7.14645Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7C8.27614 7 8.5 7.22386 8.5 7.5V11.5C8.5 11.7761 8.27614 12 8 12C7.72386 12 7.5 11.7761 7.5 11.5V7.5C7.5 7.22386 7.72386 7 8 7Z"
        fill={color}
      />
    </svg>
  );
};
