import Modal from "components/DesignSystem/Modal/Modal";
import {
  ANAND_GENERIC_MEET_LINK,
  CPA_CONSULTATION,
} from "constants/meetingRequestUrls";
import { useDispatch, useSelector } from "react-redux";
import { closeMeetWithTranqModal } from "store/slices/chat";
import { RootState } from "store/store";

export const MeetingWithTranq = () => {
  const isMeetWithTranqModalOpen = useSelector(
    (state: RootState) => state.chat.isMeetWithTranqModalOpen
  );
  const dispatch = useDispatch();

  let modalTitle = "Meet With Admin";
  if (isMeetWithTranqModalOpen === CPA_CONSULTATION) {
    modalTitle = "Meet With CPA";
  } else if (isMeetWithTranqModalOpen === ANAND_GENERIC_MEET_LINK) {
    modalTitle = "Meet With CEO";
  }

  return (
    <Modal.Root
      open={Boolean(isMeetWithTranqModalOpen)}
      onOpenChange={() => dispatch(closeMeetWithTranqModal())}
    >
      <Modal.Content size="xxl">
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="700"
            className="t-mt-0"
            src={isMeetWithTranqModalOpen}
            title="Inkle Calender"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
