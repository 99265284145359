import { SlackIntegration } from "components/SlackIntegration";
import { useToast } from "hooks/useToast";
import { useContext } from "react";
import { useDropzone } from "react-dropzone";
import { PROFILE_PIC_UPDATE, USER_PROFILE } from "../../constants/userTypes";
import authContext from "../../jwt_context&axios/authContext";
//@ts-ignore
import loadingIcon from "static/images/loading.gif";

import { Divider } from "components/design/Divider";
import { useProfilePicUpdateMutation } from "store/apis/settings";

export const ProfilePicUpdate = () => {
  const { alertToast, successToast } = useToast();
  const { authtoken, updateUser } = useContext(authContext);

  const [profilePicUpdate, { isLoading }] = useProfilePicUpdateMutation();

  const onDrop = (acceptedFiles: File[]) => {
    updateProfilePic(acceptedFiles[0]);
  };

  const updateProfilePic = async (acceptedFiles: File) => {
    try {
      const response = await profilePicUpdate(acceptedFiles);
      updateUser({ data: response });
      successToast({ message: PROFILE_PIC_UPDATE });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const { open, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
  });

  return (
    <div>
      {authtoken.is_slack_added && (
        <div className="t-mb-6">
          <div className="t-mb-3 t-text-body-sm">Connections</div>
          <div className="t-mb-6 t-w-full">
            {!authtoken.is_any_service_user && (
              <SlackIntegration
                reverse={true}
                hideOnceAdded={false}
                floating={false}
              />
            )}
          </div>
          <Divider />
        </div>
      )}
      <div className="t-flex t-items-center t-gap-8 t-pl-2.5 !t-rounded-full">
        <input {...getInputProps()} />
        <div className="t-flex t-flex-col t-gap-2">
          <div className="t-text-body-sm t-text-text-30 -t-ml-2">
            Profile picture
          </div>
          <div
            className="t-relative t-group t-inline-block t-cursor-pointer t-rounded-full"
            onClick={open}
          >
            {isLoading ? (
              <img
                src={loadingIcon}
                className="t-rounded-full t-w-20 t-h-20"
                alt="loading..."
              />
            ) : (
              <>
                <img
                  className="t-w-20 t-h-20 t-cursor-pointer !t-rounded-full"
                  src={authtoken.profile_url || USER_PROFILE}
                  alt="Avatar"
                />
                <div className="t-opacity-0 !t-rounded-full group-hover:t-opacity-50 group-hover:t-bg-purple-100 t-absolute t-inset-0 t-flex t-items-center t-justify-center"></div>
                <div className="t-absolute group-hover:!t-visible t-invisible t-text-white t-top-[40%] t-left-[30%] ">
                  Edit
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
