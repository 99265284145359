import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import * as ContextMenuPrimitive from "@radix-ui/react-context-menu";

type ContentProps = {
  className?: string;
};

const Item = ({
  className = "",
  ...props
}: ContextMenuPrimitive.ContextMenuItemProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => (
  <ContextMenuPrimitive.Item
    {...props}
    className={cx(
      "t-group t-w-full t-cursor-pointer t-truncate !t-border-none t-px-5 t-py-2 t-font-medium hover:t-bg-i-surface-light-purple focus-visible:t-outline-none data-[disabled]:t-cursor-not-allowed data-[disabled]:t-text-neutral-30 data-[disabled]:hover:t-bg-surface-transparent data-[highlighted]:t-bg-surface-lighter-grey hover:t-text-text-100",
      {
        [className]: className,
      }
    )}
  />
);

const Content = ({
  className = "",
  children,
  ...props
}: ContextMenuPrimitive.ContextMenuContentProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <ContextMenuPrimitive.Content {...props} asChild>
      <motion.div
        className={cx(
          "primary-border t-z-dropdown t-rounded-md t-bg-surface t-text-body t-drop-shadow-i-dropdown t-overflow-auto",
          {
            [className]: className,
          }
        )}
        initial={{ y: -10 }}
        animate={{ y: 0 }}
        exit={{ y: -10 }}
      >
        {children}
      </motion.div>
    </ContextMenuPrimitive.Content>
  );
};

const Portal = (props: ContextMenuPrimitive.ContextMenuPortalProps) => (
  <AnimatePresence>
    <ContextMenuPrimitive.Portal {...props} />
  </AnimatePresence>
);

const ContextMenu = {
  ...ContextMenuPrimitive,
  Portal: Portal,
  Item: Item,
  Content: Content,
};

export default ContextMenu;
