import classNames from "classnames";
import React, { useEffect, useState } from "react";
import OtpInput from "./ReactOtpInput";

const OtpBox = ({
  otp,
  setOtp,
  getOtp,
  inCorrectOtp,
  width,
  needOtpDetail = false,
  setInvalidOtp,
  className,
}) => {
  useEffect(() => {
    if (otp.length === 6) {
      if (needOtpDetail) setInvalidOtp(false);
      getOtp(otp);
    } else if (needOtpDetail) setInvalidOtp(true);
  }, [otp]);

  const handleChange = (e) => {
    setOtp(e);
  };

  return (
    <form
      className={classNames("otpContainer", {
        inCorrectOtp: inCorrectOtp,
        [className]: Boolean(className),
      })}
      style={{ width: width }}
    >
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
        className="otpInputBox"
        shouldAutoFocus
      />
    </form>
  );
};
export default OtpBox;
