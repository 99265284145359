import ChartOfAccountsAccordion from "components/ChartOfAccountsAccordion/ChartOfAccountsAccordion";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useModal } from "hooks/useModal";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useDispatch } from "react-redux";
import ThreeDots from "static/images/ThreeDots.svg";
import {
  deleteCOAModalOpen,
  editCOAModalOpen,
  setBookingStartDateModal,
} from "store/slices/chartOfAccounts";
import { TxnCategories } from "types/Models/books";
import { DeleteCategory } from "./DeleteCategory";
import { EditCategory } from "./EditCategory";
import { ParentSelector } from "./ParentSelector";
import { Search } from "components/DesignSystem/Search/Search";
import Pencil from "components/icons/pencil";
import { StartDateModal } from "./StartDateModal";
import {
  useGetCategoryActionsQuery,
  useGetStartingDateQuery,
  useLazyGetExportCOAQuery,
} from "store/apis/chartOfAccounts";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { debounce } from "utils/debouncing";
import { ChangeEvent, useState } from "react";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { usePageTitle } from "hooks/usePageTitle";
import { CaretDown } from "components/icons/CaretDown";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { MergeCategories } from "components/MergeCategories/MergeCategories";
import { MoveCategories } from "components/MoveCategories/MoveCategories";

const ExportCOA = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const [exportCOA, { isLoading: isExporting }] = useLazyGetExportCOAQuery();

  const onExport = async (exportType: "PDF" | "EXCEL") => {
    try {
      const { download_url } = await exportCOA({
        groupId,
        entityId,
        exportType,
        hiddenCategory: ["BANK_TRANSFER", "PAY_DOWN_CREDIT"],
      }).unwrap();
      openLink(download_url);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild className="t-group" disabled={isExporting}>
        <div>
          <Button
            size="small"
            onClick={(e) => e.stopPropagation()}
            isLoading={isExporting}
            disabled={isExporting}
          >
            <div className="t-flex t-items-center t-gap-1.5">
              Export
              <div className="group-data-state-open:t-rotate-180">
                <CaretDown />
              </div>
            </div>
          </Button>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content sideOffset={4} align="end" side="bottom">
          <Dropdown.Item onSelect={() => onExport("PDF")}>PDF</Dropdown.Item>
          <Dropdown.Item onSelect={() => onExport("EXCEL")}>
            EXCEL
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

export const COATable = () => {
  usePageTitle("Chart of Accounts");
  const dispatch = useDispatch();
  const { update } = useUpdateQuery();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const query = useQuery();
  const searchTerm = query.get("search_term") || "";
  const [checkedCategories, setCheckedCategories] = useState<TxnCategories[]>(
    []
  );
  const mergeCategories = useModal();
  const moveCategories = useModal();

  const { isOpen, close, open } = useModal();

  const {
    chartOfAccounts: COAData,
    isLoading,
    isSuccess,
  } = useChartOfAccounts({
    hiddenCategory: ["BANK_TRANSFER", "PAY_DOWN_CREDIT"],
    search: searchTerm,
  });

  const { data: categoryActions } = useGetCategoryActionsQuery(
    {
      entityId,
      category_ids: checkedCategories.map((c) => c.uuid),
    },
    {
      skip: checkedCategories.length === 0 || !entityId,
    }
  );

  const { data: startDateData } = useGetStartingDateQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { start_date } = startDateData || {};

  const editHandler = ({ uuid }: TxnCategories) => {
    dispatch(editCOAModalOpen(uuid));
  };

  const deleteHandler = ({ uuid }: { uuid: string }) => {
    dispatch(deleteCOAModalOpen(uuid));
  };

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search_term", value: value || null });
  });

  const openBookkeepingStartDateModal = () => {
    dispatch(setBookingStartDateModal(true));
  };

  const isEmpty = COAData.length === 0;

  const onCheck = (category: TxnCategories, isChecked: boolean) => {
    const traverseAndAddCategories = (category: TxnCategories) => {
      if (category.types && category.types?.length > 0) {
        category.types.forEach((c) => {
          traverseAndAddCategories(c);
        });
      }

      return setCheckedCategories((categories) => {
        if (categories.find((c) => c.uuid === category.uuid)) {
          return categories;
        }

        return [...categories, category];
      });
    };

    const traverseAndRemoveCategories = (category: TxnCategories) => {
      if (category.types && category.types?.length > 0) {
        setCheckedCategories((categories) =>
          categories.filter((c) => c.uuid !== category.uuid)
        );
        category.types.forEach((c) => {
          traverseAndRemoveCategories(c);
        });
      }

      setCheckedCategories((categories) =>
        categories.filter((c) => c.uuid !== category.uuid)
      );
    };

    const removeCategoryAndUnCheckParentChain = (
      category: TxnCategories,
      currentCategory: TxnCategories
    ): boolean => {
      if (currentCategory.uuid === category.uuid) {
        traverseAndRemoveCategories(currentCategory);
        return true;
      }

      if (category.types && category.types?.length > 0) {
        for (const ca of category.types) {
          const hasCategory = removeCategoryAndUnCheckParentChain(
            ca,
            currentCategory
          );

          if (hasCategory) {
            setCheckedCategories((categories) =>
              categories.filter((ca) => ca.uuid !== category.uuid)
            );
            return hasCategory;
          }
        }

        return false;
      }

      return false;
    };

    if (isChecked) {
      return traverseAndAddCategories(category);
    }

    for (const COA of COAData) {
      new Promise<void>((res) => {
        const done = removeCategoryAndUnCheckParentChain(COA, category);
        if (done) {
          res();
        }
      });
    }
  };

  const onMergeSuccess = () => {
    setCheckedCategories([]);
    mergeCategories.close();
  };

  const onMoveSuccess = () => {
    setCheckedCategories([]);
    moveCategories.close();
  };

  const hasDifferentParent =
    !categoryActions?.is_categories_mergable.error.has_same_parent ||
    !categoryActions?.is_categories_movable.error.has_same_parent;

  const selectedHasParentCategories =
    categoryActions?.is_categories_mergable.error.is_leaf_category === false;

  const moveError = hasDifferentParent
    ? "Cannot move between different category types"
    : "Cannot move selected categories";
  let mergeError = hasDifferentParent
    ? "Cannot merge categories from different types"
    : "Cannot merge selected categories";

  if (selectedHasParentCategories) {
    mergeError = "Cannot merge categories which has children";
  }

  if (checkedCategories.length < 2) {
    mergeError = "Select minimum 2 categories to start merging";
  }

  return (
    <div className="t-pb-16 t-relative">
      <div className="t-flex t-justify-between t-gap-5 t-bg-white t-z-filter-head t-sticky t-top-0 t-flex-col">
        <div className="t-flex t-justify-between">
          <div className="t-w-2/3">
            <Search block onChange={handleChange} placeholder="Search" />
          </div>
          <div className="t-flex t-gap-1">
            <ExportCOA />
            <Button size="small" customType="primary" onClick={open}>
              Add category
            </Button>
          </div>
        </div>

        <div className="t-flex t-gap-2 t-items-center t-mb-5">
          {checkedCategories.length > 0 && (
            <div className="t-flex t-gap-3">
              <Checkbox
                onChange={() => setCheckedCategories([])}
                indeterminate={checkedCategories.length > 0}
                label={`${checkedCategories.length} Selected`}
              />

              <ConditionalToolTip
                condition={
                  !categoryActions?.is_categories_mergable.value && mergeError
                }
              >
                <span>
                  <Button
                    disabled={
                      checkedCategories.length < 2 ||
                      !categoryActions?.is_categories_mergable.value
                    }
                    onClick={mergeCategories.open}
                    size="small"
                    customType="primary-outlined"
                  >
                    Merge
                  </Button>
                </span>
              </ConditionalToolTip>
              <ConditionalToolTip
                condition={
                  !categoryActions?.is_categories_movable.value && moveError
                }
              >
                <span>
                  <Button
                    disabled={!categoryActions?.is_categories_movable.value}
                    onClick={moveCategories.open}
                    size="small"
                    customType="primary-outlined"
                  >
                    Move
                  </Button>
                </span>
              </ConditionalToolTip>
            </div>
          )}
          <div className="t-flex t-text-body t-gap-1 t-ml-auto">
            <div className="t-text-text-30">Starting date: </div>
            <div>{dayjs(start_date).format(DD_MMM_YYYY)}</div>
          </div>
          <Button
            customType="ghost_icon"
            size="small"
            onClick={openBookkeepingStartDateModal}
          >
            <Pencil />
          </Button>
        </div>
      </div>
      <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
        <Async.Empty>
          <EmptyScreen text="No Chart of Accounts found"></EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <ChartOfAccountsAccordion.Root>
            <ChartOfAccountsAccordion.Header>
              <div className="t-flex t-items-center t-justify-between t-w-full t-gap-2">
                <div className="t-w-80 t-pl-3 t-uppercase">Category Name</div>
                <div className="t-w-28 t-uppercase">Category ID</div>
                <div className="t-w-44 t-uppercase t-flex t-justify-end">
                  OPENING BALANCE
                </div>
                <div className="t-w-36 t-uppercase t-flex t-justify-center">
                  AS ON DATE
                </div>
              </div>
              <div className="t-w-full t-uppercase t-pl-2">Description</div>
            </ChartOfAccountsAccordion.Header>
            {isLoading ? (
              <Loader />
            ) : (
              <ChartOfAccountsAccordion.Trigger
                checkedCategories={checkedCategories}
                onCheck={onCheck}
                data={COAData}
                action={(category) => (
                  <Dropdown.Root>
                    <Dropdown.Trigger asChild>
                      <div>
                        <Button
                          size="small"
                          customType="ghost_icon"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img
                            src={ThreeDots}
                            alt="Action"
                            className="t-select-none"
                          />
                        </Button>
                      </div>
                    </Dropdown.Trigger>
                    <Dropdown.Portal>
                      <Dropdown.Content sideOffset={4} align="end">
                        <Dropdown.Item
                          onClick={(e) => e.stopPropagation()}
                          onSelect={() => editHandler(category)}
                        >
                          Edit account
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => e.stopPropagation()}
                          onSelect={() =>
                            deleteHandler({ uuid: category.uuid })
                          }
                          disabled={Boolean(category?.types)}
                          className="t-text-red"
                        >
                          <ConditionalToolTip
                            condition={
                              Boolean(category?.types) &&
                              "Can't delete a parent with multiple accounts "
                            }
                          >
                            <span>Delete account</span>
                          </ConditionalToolTip>
                        </Dropdown.Item>
                      </Dropdown.Content>
                    </Dropdown.Portal>
                  </Dropdown.Root>
                )}
              />
            )}
          </ChartOfAccountsAccordion.Root>
        </Async.Success>
        <DeleteCategory />
        <EditCategory />
      </Async.Root>
      <ParentSelector isOpen={isOpen} close={close} open={open} />
      <StartDateModal />

      <Modal.Root
        open={mergeCategories.isOpen}
        onOpenChange={mergeCategories.close}
      >
        <Modal.Content>
          <MergeCategories
            categories={checkedCategories}
            onSuccess={onMergeSuccess}
          />
        </Modal.Content>
      </Modal.Root>

      <Modal.Root
        open={moveCategories.isOpen}
        onOpenChange={moveCategories.close}
      >
        <Modal.Content
          onInteractOutside={(e) => e.preventDefault()}
          useCustomOverlay
        >
          <MoveCategories
            categories={checkedCategories}
            onSuccess={onMoveSuccess}
          />
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
