export const InkleCommunitySymbolSmall = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.0195312H6C9.31371 0.0195312 12 2.70582 12 6.01953H6C2.68629 6.01953 0 3.33324 0 0.0195312Z"
      fill="url(#paint0_linear_5960_17660)"
    />
    <path
      d="M0 6.01953H6C9.31371 6.01953 12 8.70582 12 12.0195H6C2.68629 12.0195 0 9.33324 0 6.01953Z"
      fill="url(#paint1_linear_5960_17660)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5960_17660"
        x1="0"
        y1="3.01953"
        x2="12"
        y2="3.01953"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5960_17660"
        x1="0"
        y1="9.01953"
        x2="12"
        y2="9.01953"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
