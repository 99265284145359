import classNames from "classnames";
import React, { HTMLAttributes, ReactNode } from "react";

export const ActionCard = ({
  name,
  action,
  content,
  ...props
}: {
  name: string;
  action: ReactNode;
  content: ReactNode;
} & any) => {
  return (
    <div
      {...props}
      className={classNames(
        "t-border t-border-solid t-rounded-lg t-p-5 t-border-neutral-10 t-h-full t-w-full t-text-text-100",
        props.className || ""
      )}
    >
      <p className="t-flex t-flex-col t-gap-1 t-mb-3">
        <span className="t-text-subtitle t-leading-normal">{name}</span>
        {content}
      </p>
      {action}
    </div>
  );
};
