export const SquareHalf = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2554_445)">
      <path
        d="M12.5 3H3.5C3.22386 3 3 3.22386 3 3.5V12.5C3 12.7761 3.22386 13 3.5 13H12.5C12.7761 13 13 12.7761 13 12.5V3.5C13 3.22386 12.7761 3 12.5 3Z"
        stroke="currentColor"
        stroke-width="1.6"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 3V13"
        stroke="currentColor"
        stroke-width="1.6"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 5H13"
        stroke="currentColor"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 7H13"
        stroke="currentColor"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 9H13"
        stroke="currentColor"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11H13"
        stroke="currentColor"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2554_445">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
