import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { store } from "store/store";
import {
  openFreeSubscription,
  setRequiredSubscriptionId,
  openUpgradePlanModal,
} from "store/slices/subscriptionModal";
import {
  handleDebterBlock,
  handleSubscribscriptionBlocker,
} from "store/apis/emptyApi";

//utils
const baseURL = process.env.REACT_APP_BASE_URL;

export const axiosInstance = axios.create({
  baseURL,
});

const getAccessToken = () => {
  let authInLocal;
  try {
    authInLocal = localStorage.getItem("authTokens");
    authInLocal = JSON.parse(authInLocal || "{}");
    if (!authInLocal.access) {
      window.location.replace(window.location.origin + "/signin");
    }
    const user = jwt_decode(authInLocal.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (isExpired) {
      window.location.replace(window.location.origin + "/signin");
    }

    if (authInLocal.access) {
      return authInLocal.access;
    }
    throw new Error("No access token present");
  } catch (error) {
    // try {
    //   const { access } = getAccessTokenUsingRefreshToken({
    //     refresh: authInLocal.refresh,
    //   });
    //   localStorage.setItem(
    //     "authTokens",
    //     JSON.stringify({ ...authInLocal, access })
    //   );
    // } catch (error) {
    //   return false;
    // }
    console.log(error);
  }
};

axiosInstance.interceptors.request.use(async (req) => {
  const accessToken = await getAccessToken();
  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
    req.headers["request-path"] = window.location.href;
  }

  return req;
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    let authInLocal = localStorage.getItem("authTokens");
    authInLocal = JSON.parse(authInLocal || "{}");
    if (error?.response?.status === 401) {
      localStorage.removeItem("authTokens");
      window.location.href = `${window.location.origin}/signin`;
    }

    if (error?.response?.status === 402) {
      if (error.response?.data?.error?.code === "pending_dues") {
        handleDebterBlock();
        return;
      }

      handleSubscribscriptionBlocker();

      if (authInLocal.is_public_user) {
        store.dispatch(openFreeSubscription());
      } else {
        const requiredSubscriptionId = error?.response?.data?.error?.message;
        store.dispatch(openUpgradePlanModal());
        store.dispatch(setRequiredSubscriptionId(requiredSubscriptionId));
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
