import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Capsule } from "components/Transaction/Filter";
import { Formik } from "formik";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { parse } from "qs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getVendorsFilterStatus } from "store/selector/vendorsFilterStatus";
import { FiltersValues } from "store/slices/transactionFilter";
import {
  setVendorsFilter,
  vendorsInitialState,
  vendorsSeasonOptions,
} from "store/slices/vendorsFilter";
import { RootState } from "store/store";

const CapsuleFilters = () => {
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const { capsuleFilters } = useSelector(getVendorsFilterStatus);
  const wFormVendor = useSelector(
    (state: RootState) => state.vendorsFilter.filters.vendorsType.value
  );
  const isWFormVendors = wFormVendor === "wForm";

  useEffect(() => {
    update({
      query: "vendorsType",
      value: wFormVendor,
    });
  }, [wFormVendor]);

  const removeFilter = (type: string) => {
    dispatch(
      setVendorsFilter({ [type]: vendorsInitialState.filters.season.value })
    );
    update({ query: type, value: null });
  };

  const toggleVendorsType = () => {
    dispatch(
      setVendorsFilter({ vendorsType: isWFormVendors ? "allVendors" : "wForm" })
    );
  };

  return (
    <>
      <Chip
        onClose={toggleVendorsType}
        isFixedFilter
        isActive={isWFormVendors}
        filterType=""
        onFixedFilterClick={toggleVendorsType}
      >
        W forms
      </Chip>
      {capsuleFilters.map(({ name, value, type }) => {
        if (type === "vendorsType") {
          return <></>;
        }

        return (
          <Capsule
            key={name}
            value={value}
            onCapsuleClick={() => removeFilter(type)}
            type={type}
            isRemovable={false}
          >
            <></>
          </Capsule>
        );
      })}
    </>
  );
};

const SeasonFilter = () => {
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getVendorsFilterStatus);
  const [season] = filters.season;
  const query = useQuery();
  const seasonFromQuery = query.get("season");

  const handleRadioCheck = (value: string) => {
    update({
      query: "season",
      value,
    });
  };

  return (
    <Formik
      initialValues={{
        season: seasonFromQuery || season.value,
      }}
      onSubmit={() => {}}
    >
      {({ values: { season } }) => (
        <div className="t-flex t-flex-col t-gap-3">
          <div className="t-text-caption t-text-neutral-80 t-w-full">
            Season
          </div>
          <Radio.Root
            onValueChange={handleRadioCheck}
            /* @ts-ignore */
            defaultValue={season || ""}
          >
            <Radio.Content>
              {vendorsSeasonOptions.map((season: string) => (
                <Radio.Item asChild value={season} key={season}>
                  {season}
                </Radio.Item>
              ))}
            </Radio.Content>
          </Radio.Root>
        </div>
      )}
    </Formik>
  );
};

export const isChecked = (array: FiltersValues["value"], uuid: string) => {
  if (Array.isArray(array)) {
    return array?.find((id: string) => id === uuid);
  }
  return false;
};

export const VendorsFilter = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { appliedFiltersCount } = useSelector(getVendorsFilterStatus);

  useEffect(() => {
    let query = parse(search, { ignoreQueryPrefix: true });
    delete query.company;
    delete query.entity;
    delete query.page;
    delete query.search_term;
    dispatch(setVendorsFilter(query));
  }, [search]);

  return (
    <div className="t-pt-2">
      <Filter.Root
        defaultValue="season"
        title={
          <span className="t-text-body t-font-medium t-leading-none">
            Filters {appliedFiltersCount ? <>({appliedFiltersCount})</> : ""}
          </span>
        }
        capsule={<CapsuleFilters />}
      >
        <Filter.Portal>
          <Filter.List>
            <Filter.ListItem value="season">Season</Filter.ListItem>
          </Filter.List>
          <Filter.Body value="season">
            <SeasonFilter />
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>
    </div>
  );
};
