import { Button } from "components/DesignSystem/Button/Button";
import {
  TASK_ADDITIONAL_PAYMENT,
  TASK_REVIEW_AND_PAY,
  TASK_REVIEW_DOCUMENT,
} from "constants/chatType";
import React, { FC, ReactNode } from "react";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";

type ChatCardButtonProps = {
  urlText: string;
  documentOperation?: string;
  isPaymentRequired?: boolean;
  isDocReviewed?: boolean;
  handleCardAction: () => void;
  disabled?: boolean;
};

export const ChatCardButton: FC<ChatCardButtonProps> = ({
  urlText,
  documentOperation,
  isPaymentRequired,
  isDocReviewed,
  disabled,
  handleCardAction,
}) => {
  const isBalancePayment = documentOperation === TASK_ADDITIONAL_PAYMENT;
  const isBalancePaymentPaid =
    isDocReviewed && isBalancePayment && !isPaymentRequired;

  const isDisabled = isBalancePaymentPaid || disabled;

  let btnContent: string | ReactNode = urlText;
  if (isBalancePaymentPaid) {
    btnContent = (
      <>
        <img src={DoubleGreenTick} alt="DoubleGreenTick" />
        <div className="t-text-subtext-sm t-text-dark_green t-ml-1">Paid</div>
      </>
    );
  }
  if (isDocReviewed && !isBalancePayment) {
    btnContent = (
      <>
        <img src={DoubleGreenTick} alt="DoubleGreenTick" />
        <div className="t-text-subtext-sm t-text-dark_green t-ml-1">
          {documentOperation === TASK_REVIEW_DOCUMENT && "Reviewed"}
          {documentOperation === TASK_REVIEW_AND_PAY && "Reviewed and paid"}
        </div>
      </>
    );
  }
  if (isDocReviewed && isPaymentRequired && !isBalancePayment) {
    btnContent = "Payment pending";
  }

  return (
    <Button
      customType="text"
      block
      disabled={isDisabled}
      onClick={handleCardAction}
    >
      {btnContent}
    </Button>
  );
};
