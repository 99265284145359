import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/Table";
import { DocumentPreviewModal } from "components/PreviewModal";
import { DownloadIcon } from "components/icons/Download";
import { ICON_DEFAULT_COLOR } from "constants/documents";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import { useMemo } from "react";
import { Column, useTable } from "react-table";
import { useGetAllGroupQuoteQuery } from "store/apis/calendar";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { FileObject } from "types/Models/fileObject";
import { Quote } from "types/Models/quote";
import { formatDate } from "utils/formatDate";
import { openLink } from "utils/openLink";
import { FileIcon } from "../../utils/fileTypeIcon";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

const PastQuotesTable = () => {
  const { alertToast } = useToast();
  const openPreview = useDocPreview();
  const { uuid: groupId } = useCurrentGroupContext();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();
  const entityId = useCurrentEntityId();
  const { data: allQuotes, isLoading: isQuotesLoading } =
    useGetAllGroupQuoteQuery({ groupId, entityId }, { skip: !groupId });

  const getDocUrl = async (fileId: string) => {
    try {
      const { download_url } = await getPreviewUrl({
        groupId,
        fileId,
      }).unwrap();
      openLink(download_url, "_blank");
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Quote",
          id: "QUOTE",
          Cell: ({ row: { original } }: { row: { original: Quote } }) => {
            const { quote_file, name } = original || {};

            return (
              <span className="t-flex t-items-center t-gap-2">
                <Button
                  onClick={() => openPreview(quote_file.uuid)}
                  customType="transparent"
                >
                  <span className="t-flex t-items-center t-gap-4 t-text-purple-50">
                    <FileIcon
                      fileType={quote_file?.file_type}
                      width="30px"
                      height="30px"
                    />

                    <div className="t-text-body">{name}</div>
                  </span>
                </Button>
                <Button
                  customType="transparent"
                  onClick={() => getDocUrl(quote_file?.uuid)}
                >
                  <DownloadIcon color={ICON_DEFAULT_COLOR} />
                </Button>
              </span>
            );
          },
          width: "20%",
        },
        {
          Header: "Sent by",
          accessor: "sent_by.name",
          Cell: ({ row }: { row: { original: Quote } }) => {
            return (
              <span className="t-text-body">{row.original.sent_by?.name}</span>
            );
          },
          width: "25%",
        },
        {
          Header: "Sent to",
          accessor: "SENT_TO",
          Cell: ({ row }: { row: { original: Quote } }) => {
            return (
              <span className="t-block t-w-full t-overflow-hidden t-text-ellipsis t-pr-4">
                <span className="t-whitespace-nowrap t-text-body">
                  {row.original.recipients.map((a) => a.email).join(", ")}
                </span>
              </span>
            );
          },
          width: "35%",
        },
        {
          Header: "Sent on",
          accessor: "created_at",
          Cell: ({ row }: { row: { original: Quote } }) => {
            return (
              <span className="t-text-body">
                {formatDate(row.original.created_at)}
              </span>
            );
          },
          width: "20%",
        },
      ] as Column<Quote>[],
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      data: allQuotes?.company_group_quotes || [],
    });
  const handleOpenQuote = (documentObject: FileObject) => {
    openPreview(documentObject.uuid);
  };

  return (
    <>
      <Table.Container className="t-pb-16">
        <Table.Content {...getTableProps()} className="all:unset">
          <Table.Head className="t-bg-surface-grey">
            {headerGroups.map((headerGroup) => (
              <Table.Row
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="t-text-start t-font-medium"
                    {...column.getHeaderProps({})}
                    style={{ width: column?.width }}
                    key={column.id}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </Table.Row>
            ))}
          </Table.Head>

          <>
            {isQuotesLoading ? (
              <Loader />
            ) : (
              <Table.BodyWrapper id="scrollableTxnTable">
                {!allQuotes?.company_group_quotes.length ? (
                  <div className="t-flex  t-items-center t-justify-center t-gap-4 t-text-subtitle t-leading-5 t-text-text-30 t-py-3">
                    No Quote found
                  </div>
                ) : (
                  <Table.Body {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <Table.Row
                          className="
          t-flex t-cursor-pointer  t-items-center t-justify-center t-border-b t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-gap-6 t-px-4 t-py-2 hover:t-bg-neutral-0"
                          {...row.getRowProps()}
                          onClick={() =>
                            openPreview(row.original.quote_file.uuid)
                          }
                          key={row.id}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps({})}
                                style={{
                                  width: cell.column?.width,
                                }}
                                key={cell.column.id}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                )}
              </Table.BodyWrapper>
            )}
          </>
        </Table.Content>
      </Table.Container>
      <DocumentPreviewModal />
    </>
  );
};

export default PastQuotesTable;
