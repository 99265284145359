import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/Table";
import { DeleteIcon } from "components/icons/delete";
import Pencil from "components/icons/pencil";
import { useMemo } from "react";
import { useTable } from "react-table";
import Search from "static/images/Search.svg";
import { AddressAutofill } from "types/Models/addressAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";

type AutofillTypeRowsProps = {
  items: PersonaAutofill[] | BankAutofill[] | AddressAutofill[];
  onDelete: ({ uuid }: { uuid: string }) => void;
  type: "addresses" | "bank_accounts" | "authorized_persons";
  onEdit: ({
    autofill,
    type,
  }: {
    autofill: PersonaAutofill | BankAutofill | AddressAutofill;
    type: "addresses" | "bank_accounts" | "authorized_persons";
  }) => void;
};

export const AutofillTable = ({
  items = [],
  onEdit,
  onDelete,
  type,
}: AutofillTypeRowsProps) => {
  const noAutofill = items?.length === 0;
  const data = useMemo(() => items, [items]);

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "autofill_string",
        width: "80%",
        Cell: ({ value }: { value: string }) => {
          return <div className="t-text-body">{value}</div>;
        },
      },
      {
        Header: "",
        accessor: "action",
        width: "20%",
        Cell: ({ row: { original } }: any) => {
          return (
            <div className="t-flex t-justify-end t-gap-2">
              <Button
                onClick={() => onEdit({ autofill: original, type })}
                customType="icon"
              >
                <span className="t-text-text-60">
                  <Pencil color="currentColor" size="20" />
                </span>
              </Button>
              <Button onClick={() => onDelete(original)} customType="icon">
                <span className="t-text-text-60">
                  <DeleteIcon color="currentColor" size="20" />
                </span>
              </Button>
            </div>
          );
        },
      },
    ],
    [type]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      // @ts-ignore
      data,
    });

  return (
    <Table.Container>
      <Table.Content {...getTableProps()} className="t-w-full t-overflow-auto">
        <Table.Head>
          {headerGroups.map((headerGroup: any) => (
            <Table.Row
              key={headerGroup.id}
              {...headerGroup.getHeaderGroupProps()}
              className="t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-3"
            >
              {headerGroup.headers.map((column: any) => (
                <th
                  className="t-text-start"
                  key={column.id}
                  {...column.getHeaderProps({})}
                  style={{ width: column?.width }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.BodyWrapper>
          {noAutofill && (
            <div className="t-flex t-h-[31rem] t-flex-col t-items-center t-justify-center t-gap-4 t-text-subtitle-sm t-font-medium t-text-text-30">
              <img src={Search} alt="No autofill found" />
              Look's like you don't have any autofill
            </div>
          )}
          <Table.Body
            {...getTableBodyProps()}
            className="all:unset t-inline-table t-w-full"
          >
            {rows.map((row: any) => {
              prepareRow(row);
              return (
                <Table.Row
                  className="t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent"
                  key={row.id}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        className="t-px-4 t-py-2"
                        key={cell.value}
                        {...cell.getCellProps({})}
                        style={{ width: cell.column?.width }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.BodyWrapper>
      </Table.Content>
    </Table.Container>
  );
};
