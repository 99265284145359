import { DashboardLayout } from "components/DashboardLayout";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Header } from "components/DesignSystem/Header/Header";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { TopInsights } from "components/TopInsights/TopInsights";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePageTitle } from "hooks/usePageTitle";
import { useGetMetricsQuery } from "store/apis/metrics";
import { DateRangeValue, getDateRange } from "utils/getDateRange";

export const Metrics = () => {
  usePageTitle("Metrics");
  const { uuid: groupId } = useCurrentGroupContext();
  const ranges = [
    { label: "Last month", value: "lastmonth" as const },
    { label: "Last 3 months", value: "last3months" as const },
    { label: "Last 6 months", value: "last6months" as const },
    { label: "Last 12 months", value: "last12months" as const },
  ];

  const defaultDateRange = getDateRange("last3months");
  const { values, updateFilter } = useFilters({
    initialValue: {
      START_DATE: defaultDateRange.startDate,
      END_DATE: defaultDateRange.endDate,
      RANGE: "last3months" as (typeof ranges)[number]["value"],
    },
  });

  const { data: metrics, isFetching } = useGetMetricsQuery({
    groupId,
    burn_rate_end_date: dayjs(values.END_DATE).format("YYYY-MM-DD"),
    burn_rate_start_date: dayjs(values.START_DATE).format("YYYY-MM-DD"),
  });

  const runwayEnd = dayjs().add(metrics?.runway || 0, "month");

  return (
    <DashboardLayout header={<Header v2 title="Metrics" />}>
      <div className="t-flex t-flex-col t-gap-6">
        <div className="t-flex t-gap-3 t-border t-border-solid t-border-neutral-0 t-p-5 t-shadow-light-30 t-rounded-lg t-flex-col">
          <div className="t-flex t-justify-between t-items-center">
            <span className="t-text-subtitle t-text-text-100">
              Cash Metrics
            </span>
            <Formik
              initialValues={{
                range: "last3months" as (typeof ranges)[number]["value"],
              }}
              onSubmit={(values) => {
                const range = getDateRange(values.range as DateRangeValue);
                updateFilter("END_DATE", range.endDate);
                updateFilter("START_DATE", range.startDate);
                updateFilter("RANGE", values.range);
              }}
            >
              {({ submitForm, values }) => {
                const rangeValue = ranges.find((r) => r.value === values.range);

                return (
                  <Modal.Root>
                    <Modal.Trigger asChild>
                      <Button size="small">Edit</Button>
                    </Modal.Trigger>
                    <Modal.Content>
                      <Modal.Header>
                        <Modal.Title>Gross Burn Rate</Modal.Title>
                        <Modal.Close />
                      </Modal.Header>
                      <Modal.Body>
                        <Form className="t-m-0">
                          <Combobox
                            menuPortalTarget={document.body}
                            name="range"
                            value={rangeValue || null}
                            options={ranges}
                            withForm
                            label="Calculate burn rate based on:"
                          />
                        </Form>
                      </Modal.Body>

                      <Modal.FooterButtonGroup>
                        <Modal.RawClose asChild>
                          <Button>Cancel</Button>
                        </Modal.RawClose>
                        <Modal.RawClose asChild>
                          <Button onClick={submitForm} customType="primary">
                            Confirm
                          </Button>
                        </Modal.RawClose>
                      </Modal.FooterButtonGroup>
                    </Modal.Content>
                  </Modal.Root>
                );
              }}
            </Formik>
          </div>
          <div className="t-flex t-gap-14 t-flex-wrap">
            <div className="t-flex t-flex-col t-flex-1">
              <div className="t-flex t-gap-3 t-h-8 t-items-center">
                <span className="t-text-subtitle-sm t-text-text-60">
                  Cash balance
                </span>
              </div>

              <span className="t-text-h3 t-pt-3">
                {isFetching ? (
                  <div className="t-bg-neutral-0 t-rounded-md t-my-2 t-h-11 t-animate-pulse-fast"></div>
                ) : (
                  <AmountSuperScript amount={metrics?.cash_balance || 0} />
                )}
              </span>

              <span className="t-pt-2 t-text-text-30 t-text-body">
                Cash balance in connected accounts.
              </span>
            </div>

            <div className="t-border-l-[3px] t-border-0 t-border-solid t-border-neutral-0" />

            <div className="t-flex t-flex-col t-flex-1">
              <div className="t-flex t-gap-3 t-h-8 t-items-center">
                <span className="t-text-subtitle-sm t-text-text-60">
                  Gross burn rate
                </span>
                <Tag tagType="blue" size="small" bordered rounded>
                  {ranges.find((r) => r.value === values.RANGE)?.label}
                </Tag>
              </div>

              <span className="t-text-h3 t-pt-3">
                {isFetching ? (
                  <div className="t-bg-neutral-0 t-rounded-md t-my-2 t-h-11 t-animate-pulse-fast"></div>
                ) : (
                  <AmountSuperScript amount={metrics?.group_burn_rate || 0} />
                )}
              </span>

              <span className="t-pt-2 t-text-text-30 t-text-body">
                Cash spends in a month.
              </span>
            </div>

            <div className="t-border-l-[3px] t-border-0 t-border-solid t-border-neutral-0" />

            <div className="t-flex t-flex-col t-flex-1">
              <div className="t-flex t-gap-3 t-justify-between t-h-8 t-items-center">
                <span className="t-text-subtitle-sm t-text-text-60">
                  Runway runrate
                </span>
              </div>

              {isFetching ? (
                <div className="t-bg-neutral-0 t-rounded-md t-my-2 t-h-11 t-animate-pulse-fast"></div>
              ) : (
                <span className="t-text-h3 t-pt-3">
                  {metrics?.group_burn_rate === 0 && metrics?.runway === 0 ? (
                    "N/A"
                  ) : (
                    <>
                      <span>{runwayEnd.format("MMM YYYY")}</span>
                      <span className="t-inline-block t-ml-2 t-text-subtitle">
                        ({metrics?.runway} months)
                      </span>
                    </>
                  )}
                </span>
              )}

              <span className="t-pt-2 t-text-text-30 t-text-body">
                Based on burn rate.
              </span>
            </div>
          </div>
        </div>

        <div className="t-flex t-gap-4 t-border t-border-solid t-border-neutral-0 t-p-5 t-shadow-light-30 t-rounded-lg t-flex-col">
          <TopInsights />
        </div>
      </div>
    </DashboardLayout>
  );
};
