import classNames from "classnames";
import {
  ErrorMessage,
  Label,
  NoteMessage,
} from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldProps } from "formik";
import { ReactNode, useRef } from "react";

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: ReactNode;
  name?: string;
  autoResize?: boolean;
  block?: boolean;
  note?: string;
}

export const TextArea = ({
  label,
  autoResize = true,
  block = false,
  note,
  ...props
}: TextAreaProps) => {
  const textArea = useRef<null | HTMLTextAreaElement>(null);

  return (
    <div
      className={classNames({
        "t-w-full": block,
      })}
    >
      <Field name={props.name} {...props}>
        {({ field, form: { touched, errors } }: FieldProps) => {
          const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            field.onChange(e);
            props.onChange?.(e);
          };
          const hasError = touched[field.name] && errors[field.name];
          return (
            <div>
              {Boolean(label) && (
                <Label required={props?.required} htmlFor={props.name}>
                  {label}
                </Label>
              )}

              <div>
                <div
                  data-value={field.value}
                  className={classNames(
                    "t-col-start-[1] t-w-full after:t-invisible after:t-col-start-[1] after:t-row-start-[2] after:t-whitespace-pre-wrap",
                    {
                      "t-inline-grid after:t-content-[attr(data-value)] after:t-word-break":
                        autoResize,
                    }
                  )}
                >
                  <textarea
                    id={field.name}
                    {...{ ...field, ...props }}
                    onChange={onChange}
                    ref={textArea}
                    className={classNames(
                      "t-col-start-[1] t-row-start-[2] t-w-full t-resize-none t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-p-3 t-text-body t-text-text-100 t-transition-all  focus:t-border-purple focus:t-bg-surface-transparent focus:t-outline-none",
                      {
                        "t-border-red focus:t-border-red": hasError,
                        "t-cursor-not-allowed t-opacity-60": props.disabled,
                      }
                    )}
                  />
                </div>
              </div>
              {hasError && (
                <ErrorMessage>
                  {/* @ts-ignore */}
                  {errors[field.name]}
                </ErrorMessage>
              )}
              {note && <NoteMessage>{note}</NoteMessage>}
            </div>
          );
        }}
      </Field>
    </div>
  );
};
