import { AddNewAccount } from "components/ChartOfAccounts/AddNewAccount";
import { AddNewParentAccount } from "components/ChartOfAccounts/AddNewParentAccount";
import { MultilevelAccordionContext } from "components/ChartOfAccountsAccordion/MultilevelAccordionContext";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { Search } from "components/DesignSystem/Search/Search";
import {
  ErrorMessage,
  TextInput,
} from "components/DesignSystem/TextInput/TextInput";
import { Field, Form, Formik, FormikValues, useFormikContext } from "formik";
import { parentSelectorValidator } from "formValidations/coaCreation";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowElbowDownRight from "static/images/ArrowElbowDownRight.svg";
import { editCOAModalOpen } from "store/slices/chartOfAccounts";
import { TxnCategories } from "types/Models/books";
import { debounce } from "utils/debouncing";

type ParentSelectorProps = {
  close: () => void;
  isOpen: boolean;
  open: () => void;
  categoryName?: string;
  onSuccess?: ({
    uuid,
    name,
    category_type,
  }: Pick<TxnCategories, "uuid" | "name" | "category_type">) => void;
};

export const CategoryDropdownContent = ({
  chartOfAccounts,
}: {
  chartOfAccounts: TxnCategories[];
}) => {
  return (
    <div className="t-max-h-72 t-overflow-auto">
      {chartOfAccounts?.map((account) => {
        return (
          <div key={account.uuid}>
            <Dropdown.Item
              className="t-bg-neutral-0 !t-text-text-60"
              disabled
              textValue=""
            >
              {account.identifier + " " + account.name}
            </Dropdown.Item>
            <AccountTypes types={account.types} level={0} />
          </div>
        );
      })}
    </div>
  );
};

export const AccountTypes = ({
  types,
  level = 0,
}: {
  types?: TxnCategories[];
  level: number;
}) => {
  const { setValues, values } = useFormikContext<{
    parent_account: string;
    parent_name: string;
    category_name?: string;
  }>();

  const { maxIndent } = useContext(MultilevelAccordionContext);

  if (level <= maxIndent) {
    level = level + 1;
  }
  if (!types || types?.length === 0) {
    return null;
  }

  const onSelectHandler = ({ type }: { type: TxnCategories }) => {
    setValues({
      ...values,
      parent_account: type.uuid,
      parent_name: type.name,
    });
  };

  return (
    <div>
      {types?.map((type) => (
        <div
          key={type.uuid}
          style={{ paddingLeft: level > 1 ? "16px" : "0px" }}
        >
          <Dropdown.Item
            className="t-flex t-gap-1.5"
            onSelect={() => onSelectHandler({ type })}
            textValue=""
            aria-label={type.identifier + " " + type.name}
          >
            <img src={ArrowElbowDownRight} alt="ArrowElbowDownRight" />
            {type.identifier + " " + type.name}
          </Dropdown.Item>
          <AccountTypes types={type.types} level={level} />
        </div>
      ))}
    </div>
  );
};

export const ParentSelector = ({
  isOpen,
  close,
  open,
  onSuccess,
  categoryName,
}: ParentSelectorProps) => {
  const [search, setSearch] = useState("");
  const [updatedCategory, setUpdatedCategory] = useState<string>(
    categoryName || ""
  );

  const { chartOfAccounts } = useChartOfAccounts({
    search,
    hiddenCategory: ["BANK_ACCOUNT", "BANK_TRANSFER", "PAY_DOWN_CREDIT"],
  });
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [showAddParentAccount, setShowAddParentAccount] = useState(false);
  const [selectedParentCategoryDetails, setSelectedParentCategoryDetails] =
    useState<string>("");

  const onContinue = async (values: FormikValues) => {
    setUpdatedCategory(values.category_name);
    setSelectedParentCategoryDetails(values?.parent_account);
    close();
    setShowAddAccount(true);
  };

  const onNewParentAccount = () => {
    close();
    setShowAddParentAccount(true);
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value.toLowerCase() || "");
  });

  const noCategoriesFound = chartOfAccounts.length === 0;

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Add New Category</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Formik
            initialValues={{
              parent_account: "",
              parent_name: "",
              category_name: categoryName,
            }}
            onSubmit={onContinue}
            validationSchema={parentSelectorValidator}
          >
            {({
              isSubmitting,
              values: { parent_name },
              errors: { parent_account },
            }) => {
              return (
                <Form className="all:unset t-w-full">
                  <Modal.Body className="t-flex t-gap-5 t-flex-col">
                    <TextInput
                      name="category_name"
                      label="Category Name"
                      placeholder="Enter category name"
                    />
                    <Field name="parent_account">
                      {() => (
                        <div>
                          <Dropdown.Root>
                            <Dropdown.Trigger
                              className="all:unset t-w-full"
                              aria-label="Location"
                            >
                              <label className="t-text-caption t-text-text-30 t-mb-1.5">
                                Location
                              </label>
                              <div className="all:unset form-input w-full t-box-border t-flex t-h-10 t-w-[100%] t-items-center t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-px-4 t-pr-5 t-font-sans t-text-body !t-font-medium t-text-text-100 t-transition-all">
                                <div className="t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-text-text-30">
                                  {parent_name
                                    ? parent_name
                                    : "Select a parent category"}
                                </div>
                              </div>
                            </Dropdown.Trigger>
                            <Dropdown.Portal>
                              <Dropdown.Content
                                sideOffset={6}
                                align="start"
                                className="t-relative t-w-[var(--radix-popper-anchor-width)]"
                              >
                                <Search
                                  placeholder="Search..."
                                  onChange={handleSearch}
                                  autoFocus
                                  customSize="regular"
                                  block
                                />
                                {noCategoriesFound ? (
                                  <div className="t-my-1.5 t-flex t-h-48 t-items-center t-justify-center">
                                    No categories found
                                  </div>
                                ) : (
                                  <CategoryDropdownContent
                                    chartOfAccounts={chartOfAccounts}
                                  />
                                )}
                                <div className="t-px-4 t-py-3 t-border t-border-solid t-border-b-0 t-border-l-0 t-border-r-0 t-border-neutral-0">
                                  <Button
                                    customType="link"
                                    onClick={onNewParentAccount}
                                  >
                                    Add a new parent category
                                  </Button>
                                </div>
                              </Dropdown.Content>
                            </Dropdown.Portal>
                          </Dropdown.Root>
                          {parent_account && (
                            <ErrorMessage>{parent_account}</ErrorMessage>
                          )}
                        </div>
                      )}
                    </Field>
                    <div className="t-self-start">
                      <Button customType="link" onClick={onNewParentAccount}>
                        Add a new parent category
                      </Button>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="t-flex t-gap-3 t-justify-end t-items-center">
                    <Button onClick={close} type="reset">
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      Continue
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal.Root>
      <AddNewAccount
        showAddAccount={showAddAccount}
        setShowAddAccount={setShowAddAccount}
        openPreviousModal={open}
        parentId={selectedParentCategoryDetails}
        categoryName={updatedCategory}
        onSuccess={onSuccess}
      />
      {showAddParentAccount && (
        <AddNewParentAccount
          showAddParentAccount={showAddParentAccount}
          setShowAddParentAccount={setShowAddParentAccount}
          openPreviousModal={open}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
