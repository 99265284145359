import React, { useEffect } from "react";
import { Billing } from "./billing";
import { useDispatch } from "react-redux";
import { setBillingFilters } from "store/slices/billingFilters";

export const BillingApp = ({ crmId }: { crmId?: string }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBillingFilters({
        type: "paymentStatus",
        name: "unpaid",
        checked: true,
      })
    );

    const currentUrl = window.location.href;
    const questionMarkIndex = currentUrl.indexOf("?");
    const queryString = currentUrl.substring(questionMarkIndex + 1).split("&");

    queryString.forEach((value: any, key: any) => {
      const keyPart = value.split("=")[0];
      const [type, name] = keyPart.split("_");
      if (type && name) {
        dispatch(
          setBillingFilters({
            type,
            name,
            checked: true,
          })
        );
      }
    });
  }, []);

  return <Billing crmId={crmId} />;
};
