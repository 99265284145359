import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type modal = { uuid: string; open: boolean };

const initialState: {
  editCOA: modal;
  deleteCOA: modal;
  isBookkeepingStartDateModalOpen: boolean;
} = {
  editCOA: {
    uuid: "",
    open: false,
  },
  deleteCOA: {
    uuid: "",
    open: false,
  },
  isBookkeepingStartDateModalOpen: false,
};

export const chartOfAccounts = createSlice({
  name: "chartOfAccounts",
  initialState,
  reducers: {
    editCOAModalOpen: (state, action: PayloadAction<string>) => {
      state.editCOA = { uuid: action.payload, open: true };
    },

    editCOAModalClose: (state) => {
      state.editCOA = initialState.editCOA;
    },

    deleteCOAModalOpen: (state, action: PayloadAction<string>) => {
      state.deleteCOA = { uuid: action.payload, open: true };
    },

    deleteCOAModalClose: (state) => {
      state.deleteCOA = initialState.deleteCOA;
    },

    setBookingStartDateModal: (state, action: PayloadAction<boolean>) => {
      state.isBookkeepingStartDateModalOpen = action.payload;
    },
  },
});

export const {
  editCOAModalOpen,
  editCOAModalClose,
  deleteCOAModalOpen,
  deleteCOAModalClose,
  setBookingStartDateModal,
} = chartOfAccounts.actions;

export default chartOfAccounts.reducer;
