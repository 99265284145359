import classNames from "classnames";
import { SearchBox } from "components/design/searchBox";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { PlusIcon } from "components/icons/PlusIcon";
import { TemplateButtonIcon } from "components/icons/TemplateButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetChatTemplatesQuery } from "store/apis/chatTemplate";
import { RootState } from "store/store";
import { ChatTemplate as ChatTemplateType } from "types/Models/ChatTemplate";
import { debounce } from "utils/debouncing";
import CreateStepModal from "./CreateStepModal";
import NoTemplateCard from "./NoTemplateCard";
import TemplateCard from "./TemplateCard";

const ChatTemplate = ({
  selectTemplate,
  dropdownOpen,
  setDropdownOpen,
}: {
  selectTemplate: (message: string) => void;
  dropdownOpen: boolean;
  setDropdownOpen: (v: boolean) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [createStep, setCreateStep] = useState(false);
  const [editTemplateData, setEditTemplateData] = useState({
    uuid: "",
    response_header: "",
    response_body: "",
  });
  const { isOpen } = useSelector((state: RootState) => state.chat);

  const { data: chatTemplates = [] } = useGetChatTemplatesQuery({
    searchTerm: searchTerm || null,
  });
  const chatTemplateAvailable = chatTemplates.length > 0;

  useEffect(() => {
    if (!dropdownOpen) {
      closeCreateTemplateModal();
    }
  }, [dropdownOpen]);

  const openCreateTemplateModal = () => {
    setCreateStep(true);
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, 300);

  const openEdit = (templateData: ChatTemplateType) => {
    setEditTemplateData(templateData);
    setCreateStep(true);
  };

  const closeCreateTemplateModal = () => {
    setCreateStep(false);
    setEditTemplateData({
      uuid: "",
      response_header: "",
      response_body: "",
    });
  };

  const setTemplate = (e: any, templateData: ChatTemplateType) => {
    selectTemplate(templateData.response_body);
    setDropdownOpen(false);
  };

  return (
    <>
      {!createStep ? (
        <DropDown.Root
          onOpenChange={(open) => setDropdownOpen(open)}
          open={dropdownOpen}
        >
          <DropDown.Trigger className="all:unset">
            <TemplateButtonIcon />
          </DropDown.Trigger>
          <DropDown.Portal>
            <DropDown.Content
              className={classNames(
                "t-mb-[24px] t-ml-[-1em] t-h-[310px]  t-w-[calc(100vw-18vw-476px)] t-rounded-lg t-bg-surface t-p-4 t-drop-shadow-dropdown sm:t-w-[94vw]",
                {
                  "-t-ml-3 !t-w-[400px]": isOpen,
                }
              )}
              align="start"
            >
              <div className="t-mb-2 t-flex t-items-center t-justify-between">
                <span className="t-align-center t-flex">
                  <span className="t-text-body-lg t-font-semibold t-text-text-100">
                    Canned responses
                  </span>
                </span>
                {(chatTemplateAvailable || searchTerm) && (
                  <span className="t-flex t-gap-2">
                    <SearchBox
                      placeholder="  Search"
                      onChange={handleSearch}
                      value={searchTerm}
                      className="t-h-[31px] !t-rounded !t-p-0 !t-pl-2 sm:t-w-20"
                    />
                    <Button
                      customType="primary"
                      size="small"
                      onClick={openCreateTemplateModal}
                    >
                      <PlusIcon /> Add
                    </Button>
                  </span>
                )}
              </div>
              {chatTemplateAvailable ? (
                <div className="t-h-[230px] t-overflow-scroll">
                  {chatTemplates.map((data: any) => (
                    <TemplateCard
                      key={data.uuid}
                      cardData={data}
                      editTemplate={openEdit}
                      setTemplate={setTemplate}
                    />
                  ))}
                </div>
              ) : (
                <NoTemplateCard openAddStep={openCreateTemplateModal} />
              )}
            </DropDown.Content>
          </DropDown.Portal>
        </DropDown.Root>
      ) : (
        <CreateStepModal
          setdropdownOpen={setDropdownOpen}
          dropdownOpen={dropdownOpen}
          closeCreateTemplateModal={closeCreateTemplateModal}
          templateData={editTemplateData}
        />
      )}
    </>
  );
};

export default ChatTemplate;
