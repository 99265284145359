import { EmptySearch } from "./Illustrations/EmptySearch";

export const EmptyChannelList = () => {
  return (
    <div className="t-h-full t-flex t-justify-center t-items-center t-flex-col">
      <div>
        <EmptySearch />
      </div>
      <span>No results found</span>
    </div>
  );
};
