import React from "react";

const MeetingRequestCalendly = ({ calendlyLink }) => {
  return (
    <div className="w-100">
      <div className="meetingRequestCalendly">
        <iframe
          width="100%"
          height="715"
          style={{ margin: "auto" }}
          src={calendlyLink}
          title="Inkle Calender"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default MeetingRequestCalendly;
