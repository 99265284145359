export const sortOptions = [
  { id: 1, title: "Default", value: "Default", sortKey: null },
  {
    id: 2,
    title: "Oldest Filing in progress",
    value: "Oldest FIP",
    sortKey: "OLD_BY_FILING_IN_PROGRESS",
  },
  {
    id: 3,
    title: "Latest Filing in progress",
    value: "Latest FIP",
    sortKey: "NEW_BY_FILING_IN_PROGRESS",
  },
  {
    id: 4,
    title: "Past Deadlines",
    value: "Past DL",
    sortKey: "PAST_DEADLINES",
  },
  {
    id: 5,
    title: "Upcoming Deadlines",
    value: "Coming DL",
    sortKey: "UPCOMING_DEADLINES",
  },
  {
    id: 6,
    title: "Recently moved filings(Past 24 hours)",
    value: "Recently moved",
    sortKey: "RECENTLY_MOVED_FILINGS",
  },
];

export const defaultSortOption = {
  id: 1,
  title: "Default",
  value: "Default",
  sortKey: null,
};

export const Admin = "Admin";
export const Member = "Member";
