import { ConditionalLink } from "components/conditionalLink";
import { AddTaskModal } from "components/crm/AddTaskModal";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import Tab from "components/DesignSystem/Tab/Tab";
import { TASK_LIST_TAB_CLICK } from "constants/analyticsEvents";
import { ALL, ARCHIVED, COMPLETED, ONGOING } from "constants/task";
import { useAnalytics } from "hooks/useAnalytics";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { closeTaskSlider, setTaskSliderHeight } from "store/slices/taskList";
import { RootState } from "store/store";
import { AccordionTable } from "./AccordionTable";
import { DashboardLayout } from "components/DashboardLayout";
import { usePageTitle } from "hooks/usePageTitle";

export const TaskList = () => {
  usePageTitle("Filings");
  const { url } = useRouteMatch();
  const { trackEvent } = useAnalytics();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const taskType = query.get("task");
  const search = query.get("search");
  const { authtoken } = useContext(authContext);
  const dispatch = useDispatch();
  const { email, name } = authtoken;
  const location = useLocation();
  const rRef = useRef(null);

  const { ongoing_count, count, completed_count, archived_count } = useSelector(
    (state: RootState) => state.taskList.taskCounts
  );

  const tabs = [
    { name: "Ongoing", value: ONGOING, taskCount: ongoing_count },
    {
      name: "Completed",
      value: COMPLETED,
      taskCount: completed_count,
    },
    { name: "Archived", value: ARCHIVED, taskCount: archived_count },
    { name: "All", value: ALL, taskCount: count },
  ];

  const handleTabChange = (value: string) => {
    update({ query: "task", value: value || null });
    trackEvent(TASK_LIST_TAB_CLICK, {
      screen: location.pathname,
      tab_name: value,
      email,
      name,
    });
    dispatch(closeTaskSlider());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search", value: value || null });
  };

  useEffect(() => {
    //@ts-ignore
    const yCoordinate = rRef.current.getBoundingClientRect();

    dispatch(setTaskSliderHeight(window.innerHeight - yCoordinate.y - 89));
  }, [window.innerHeight]);

  return (
    <>
      <Tab.Root
        asChild
        defaultValue={ONGOING}
        onValueChange={handleTabChange}
        value={taskType || ONGOING}
      >
        <DashboardLayout
          ref={rRef}
          header={
            <Header
              v2
              title="Filings"
              right={
                <div className="t-flex t-gap-2">
                  <ConditionalLink to={`${url}/addtask`}>
                    <Button size="small" customType="primary">
                      <i className="fas fa-plus t-mr-2" />
                      Add Filing
                    </Button>
                  </ConditionalLink>
                </div>
              }
              bottom={
                <Tab.List>
                  {tabs.map(({ name, value, taskCount }) => (
                    <Tab.Trigger
                      value={value}
                      key={value}
                      suffix={taskCount?.toString()}
                      customType="primary"
                    >
                      {name}
                    </Tab.Trigger>
                  ))}
                </Tab.List>
              }
            />
          }
        >
          <div className="t-w-1/2">
            <Search
              placeholder="Search..."
              onChange={handleChange}
              value={search || ""}
              block
            />
          </div>
          <div>
            <Tab.Content value={ALL} className="t-h-full">
              <AccordionTable />
            </Tab.Content>
            <Tab.Content value={ONGOING} className="t-h-full">
              <AccordionTable />
            </Tab.Content>
            <Tab.Content value={COMPLETED} className="t-h-full">
              <AccordionTable />
            </Tab.Content>
            <Tab.Content value={ARCHIVED} className="t-h-full">
              <AccordionTable />
            </Tab.Content>
          </div>
        </DashboardLayout>
      </Tab.Root>
      <AddTaskModal />
    </>
  );
};
