import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { SourceTransaction } from "components/Transaction/TransactionColumn";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import {
  useGetAllTransactionsDataQuery,
  useUpdateTransactionMutation,
} from "store/apis/transactions";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { TransactionsView } from "./BulkCategorise";

export const UnLinkTransaction = ({
  isOpen,
  close,
  transactionDetail,
}: { transactionDetail?: Transactions } & ModalProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { successToast, alertToast } = useToast();

  const [updateTransaction, { isLoading: isUpdating }] =
    useUpdateTransactionMutation();

  const onUnLink = async () => {
    try {
      await updateTransaction({
        groupId,
        entityId,
        previewTxnId: transactionDetail?.transaction?.uuid!,
        payload: {
          transaction_category_uuid:
            transactionDetail?.transaction.category?.uuid || "",
          memo: transactionDetail?.transaction?.memo || "",
          linked_transaction_uuid: "",
        },
      }).unwrap();
      close();
      successToast({ title: "Transaction Unlinked" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const transactionIds = [
    transactionDetail?.transaction.uuid || "",
    transactionDetail?.transaction.linked_transaction || "",
  ];

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: transactionIds.join(","),
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const { transactions = [] } = transactionData || {};

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title>Unlink transaction</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-flex t-gap-6 t-flex-col t-text-body">
          <span>
            Are you sure you want to unlink this transaction?
            <br /> <br />
            This will remove the existing category “
            {transactionDetail?.transaction.category?.name}” for both the
            transactions.
          </span>
          {isLoading ? (
            <Loader />
          ) : (
            <TransactionsView transactions={transactions} />
          )}
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="danger"
            onClick={onUnLink}
            isLoading={isUpdating}
            disabled={isUpdating}
          >
            Unlink
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export const ChangeCategory = ({
  isOpen,
  close,
  openLinkTransactionModal,
  sourceTransaction,
}: {
  openLinkTransactionModal: () => void;
  sourceTransaction: SourceTransaction | null;
} & ModalProps) => {
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [updateTransaction, { isLoading: isUpdating }] =
    useUpdateTransactionMutation();

  const onUnLink = async () => {
    const isLinkTransactionType =
      sourceTransaction?.category.categoryType === "BANK_TRANSFER" ||
      sourceTransaction?.category.categoryType === "PAY_DOWN_CREDIT";

    try {
      await updateTransaction({
        groupId,
        entityId,
        previewTxnId: sourceTransaction?.rowData?.transaction?.uuid!,
        payload: {
          transaction_category_uuid: isLinkTransactionType
            ? ""
            : sourceTransaction?.category?.categoryId || "",
          memo: sourceTransaction?.rowData?.transaction?.memo || "",
          linked_transaction_uuid: "",
        },
      }).unwrap();
      close();
      if (isLinkTransactionType) openLinkTransactionModal();
      successToast({ title: "Transaction Unlinked" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const transactionIds = [
    sourceTransaction?.rowData?.transaction.uuid || "",
    sourceTransaction?.rowData?.transaction.linked_transaction || "",
  ];

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: transactionIds.join(","),
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const { transactions = [] } = transactionData || {};

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title>Change category</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-flex t-gap-6 t-flex-col t-text-body">
          <span>
            Are you sure you want to change the category of this transaction?
            <br />
            <br />
            This will remove the existing category “
            {sourceTransaction?.rowData?.transaction?.category?.name}” and also
            <b className="t-text-red"> unlink </b> both the transactions.
          </span>
          {isLoading ? (
            <Loader />
          ) : (
            <TransactionsView transactions={transactions} />
          )}
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="primary"
            onClick={onUnLink}
            isLoading={isUpdating}
            disabled={isUpdating}
          >
            Make changes
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export const Uncategorise = ({
  isOpen,
  close,
  transactionDetail,
}: { transactionDetail?: Transactions } & ModalProps) => {
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [updateTransaction, { isLoading: isUpdating }] =
    useUpdateTransactionMutation();

  const onUncategorise = async () => {
    try {
      await updateTransaction({
        groupId,
        entityId,
        previewTxnId: transactionDetail?.transaction?.uuid!,
        payload: {
          transaction_category_uuid: "",
          memo: transactionDetail?.transaction.memo || "",
          linked_transaction_uuid: "",
        },
      }).unwrap();
      close();
      successToast({ title: "Uncategorised" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const transactionIds = [
    transactionDetail?.transaction.uuid || "",
    transactionDetail?.transaction.linked_transaction || "",
  ];

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: transactionIds.join(","),
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const { transactions = [] } = transactionData || {};

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title>Uncategorize</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-flex t-gap-6 t-flex-col t-text-body">
          <span>
            Are you sure you want to uncategorize this transaction?
            <br />
            <br />
            This will remove the existing category “
            {transactionDetail?.transaction.category?.name}” and also{" "}
            <b className="t-text-red"> unlink </b>
            both the transactions.
          </span>
          {isLoading ? (
            <Loader />
          ) : (
            <TransactionsView transactions={transactions} />
          )}
        </Modal.Body>
        <Modal.Body></Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="danger"
            onClick={onUncategorise}
            isLoading={isUpdating}
            disabled={isUpdating}
          >
            Uncategorize
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
