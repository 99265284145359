export const NEW_FOLDER_MESSAGE = "New folder has been created";
export const FOLDER_DELETE_MESSAGE = "Folder has been deleted";
export const FILE_UPLOAD_MESSAGE = "File has been uploaded";
export const FILE_DELETE_MESSAGE = "File has been deleted";
export const FILE_UPLOADING_MESSAGE = "Uploading file...";
export const TEAM_UPDATE_MESSAGE = "Team seats have been updated";

export const ICON_DEFAULT_COLOR = "#5D6676";

export const PREVIEW_ACTION = "Preview";
export const EDIT_ACTION = "Edit";
export const DELETE_ACTION = "Delete";
export const RENAME_ACTION = "Rename";
export const DOWNLOAD_ACTION = "Download";
export const MOVE_ACTION = "Move to";
export const COPY_LINK = "Copy link";
export const GO_TO_FOLDER_ACTION = "Go to folder";
export const UPLOAD_FILE_ACTION = "Upload File";
export const ADD_FOLDER_ACTION = "New Folder";
export const REUPLOAD_FILE = "Reupload";
export const DUPLICATE_ACTION = "Duplicate";
export const SHOW_DETAILS_ACTION = "View Details";

export const FOLDER_TYPE = "FOLDER";
export const FILE_TYPE = "FILE";
export const LOCKED_FOLDER = "LOCKED_FOLDER";

export const EMPTY_FOLDER_MESSAGE = "There are no items here";
export const COPIED_TEXT = "Copied to clipboard";
export const FAILED_COPIED_TEXT = "Failed to copy!";

export const CRM_FOLDER_ROUTE = "/crm/:groupId/documents/:docId";

export const ENTER_KEY_PRESS = "Enter";

export const DOCUMENT_VIEW_UPDATE = "DOCUMENT_VIEW_UPDATE";

// export const PDF = [PDF];
// export const IMAGES = ["IMAGE", "SVG", "JPG", "JPEG", "PNG", "GIF"];
// export const CSV = ["CSV"];
// export const DOC = ["DOC", "DOCX", "TXT", "PPT", "PPTX"];
// export const EXCEL = ["EXCEL", "XLSX", "XLSM", "XLSB", "XML", "XLR", "XLA"];
// export const FOLDER = ["FOLDER"];
// export const MULTI_FILES = ["MULTI_FILES"];

export const RECEIPTS_MAIL = "receipts@inkle.io";
