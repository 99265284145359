import { useEffect, useState } from "react";
import { useHistory, matchPath } from "react-router-dom";

export const useMatchRoutes = (data) => {
  const history = useHistory();
  const [matched, setMatched] = useState(() =>
    matchPath(history.location.pathname, {
      path: data,
    })
  );

  useEffect(() => {
    setMatched(
      matchPath(history.location.pathname, {
        path: data,
      })
    );
  }, [history.location.pathname]);

  return matched;
};
