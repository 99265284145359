export const Camera = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.375 3.5625H9.85906L9.11367 2.44469C9.07374 2.38483 9.01966 2.33575 8.95622 2.30179C8.89279 2.26783 8.82195 2.25004 8.75 2.25H5.25C5.17805 2.25004 5.10721 2.26783 5.04378 2.30179C4.98034 2.33575 4.92626 2.38483 4.88633 2.44469L4.14039 3.5625H2.625C2.2769 3.5625 1.94306 3.70078 1.69692 3.94692C1.45078 4.19306 1.3125 4.5269 1.3125 4.875V11C1.3125 11.3481 1.45078 11.6819 1.69692 11.9281C1.94306 12.1742 2.2769 12.3125 2.625 12.3125H11.375C11.7231 12.3125 12.0569 12.1742 12.3031 11.9281C12.5492 11.6819 12.6875 11.3481 12.6875 11V4.875C12.6875 4.5269 12.5492 4.19306 12.3031 3.94692C12.0569 3.70078 11.7231 3.5625 11.375 3.5625ZM8.96875 7.71875C8.96875 8.10813 8.85328 8.48877 8.63696 8.81253C8.42063 9.13629 8.11315 9.38863 7.75341 9.53764C7.39367 9.68665 6.99782 9.72564 6.61592 9.64967C6.23402 9.57371 5.88322 9.3862 5.60788 9.11087C5.33255 8.83553 5.14504 8.48473 5.06908 8.10283C4.99311 7.72093 5.0321 7.32508 5.18111 6.96534C5.33012 6.6056 5.58246 6.29812 5.90622 6.08179C6.22998 5.86547 6.61062 5.75 7 5.75C7.52214 5.75 8.0229 5.95742 8.39212 6.32663C8.76133 6.69585 8.96875 7.19661 8.96875 7.71875Z"
      fill="currentColor"
    />
  </svg>
);
