import { getFileDownloadUrl } from "apis/documents";
import CommonTable from "components/design/commonTable";
import { DeleteModal } from "components/design/deleteModal";
import Loader from "components/design/loader";
import NoDataAvailable from "components/design/noDataAvailable";
import { Button } from "components/DesignSystem/Button/Button";
import { DeleteIcon } from "components/icons/delete";
import { DownloadIcon } from "components/icons/Download";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import "static/styles/components/companyWForm.css";
import {
  useCreateWFormsMutation,
  useDeleteWFormsMutation,
  useGetWFormsQuery,
} from "store/apis/groupWForms";
import { fileAndFolder } from "types/Models/documents";
import { openLink } from "utils/openLink";
import WFormHeader from "./WFormHeader";

const WForms = () => {
  usePageTitle("W Forms");
  const history = useHistory();
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [companyWFormId, setCompanyFormId] = useState("");
  const { data, isLoading, isSuccess } = useGetWFormsQuery(
    { groupId, currentPage },
    { skip: !groupId }
  );
  const [createWForm] = useCreateWFormsMutation();
  const [deleteWForm] = useDeleteWFormsMutation();
  const {
    company_group_forms: companyGroupWForms = [],
    current_page = 1,
    total_pages = 1,
  } = data || {};
  const noWForms = companyGroupWForms.length === 0;

  useEffect(() => {
    setCurrentPage(current_page);
  }, [current_page]);

  const createCompanyWForm = async () => {
    try {
      const { uuid } = await createWForm({ groupId }).unwrap();
      history.push(`/contractor-form/${uuid}?companywform=true`);
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const deleteCompanyWForm = async () => {
    try {
      await deleteWForm({
        companyWFormId,
        groupId,
      }).unwrap();
      setDeleteModal(false);
      setCompanyFormId("");
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const openPreview = async ({
    fileId,
    groupId,
  }: {
    fileId: string;
    groupId: string;
  }) => {
    try {
      const response = await getFileDownloadUrl({
        groupId,
        fileId,
      });
      openLink(response.data.data.download_url);
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Entity Name",
        width: "40%",
        accessor: "entity_name",
        Cell: ({ value }: { value: string }) => (
          <span className="t-text-body t-font-medium t-text-purple-50">
            {value || "-"}
          </span>
        ),
      },
      {
        Header: "Date Created",
        width: "15%",
        accessor: "created_at",
        Cell: ({ value }: { value: string }) => (
          <span className="t-text-body t-font-light t-text-text-60">
            {value}
          </span>
        ),
      },
      {
        Header: "Form Type",
        width: "45%",
        accessor: "w_form_type",
        Cell: ({
          row: {
            original: { w_form_type, uuid, form_document },
          },
        }: {
          row: {
            original: {
              w_form_type: string;
              uuid: string;
              form_document: fileAndFolder;
            };
          };
        }) => (
          <div className="t-flex t-items-center t-justify-between t-pr-8">
            <span className="t-w-[100px] t-text-body t-font-semibold t-text-[black]">
              {w_form_type || "-"}
            </span>
            <div className="t-flex t-items-center">
              {form_document?.is_previewable ? (
                <Button
                  customType="transparent"
                  onClick={() =>
                    openPreview({
                      fileId: form_document.uuid,
                      groupId,
                    })
                  }
                >
                  <DownloadIcon color="#110733" size={20} />
                </Button>
              ) : (
                "-"
              )}
            </div>
            <Button
              customType="transparent"
              onClick={() => {
                setDeleteModal(true);
                setCompanyFormId(uuid);
              }}
            >
              <DeleteIcon color="#110733" size="20" />
            </Button>
          </div>
        ),
      },
    ],
    [groupId]
  );

  const tableData = useMemo(() => companyGroupWForms, [companyGroupWForms]);

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setCompanyFormId("");
  };

  if (isLoading || !isSuccess) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <WFormHeader createCompanyWForm={createCompanyWForm} />
        {noWForms ? (
          <NoDataAvailable />
        ) : (
          <div className="t-overflow-hidden t-rounded t-border t-border-solid t-border-neutral-10">
            <CommonTable
              tableData={tableData}
              tableColumns={columns}
              pagination
              setPageNumber={setCurrentPage}
              pageCount={total_pages}
              initialPage={currentPage}
              updateSort={undefined}
            />
          </div>
        )}
      </div>
      <DeleteModal
        show={deleteModal}
        closeModal={closeDeleteModal}
        onClick={deleteCompanyWForm}
        text="Delete w-form"
      />
    </div>
  );
};

export default WForms;
