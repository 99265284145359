export const InkleSalesTax = () => (
  <svg
    width="223"
    height="56"
    viewBox="0 0 223 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.375 32.375C33.7103 31.5721 36 29.9145 36 28C36 26.0855 33.7103 24.4279 30.375 23.625C30.7181 24.9414 30.9112 26.4274 30.9112 28C30.9112 29.5726 30.7181 31.0586 30.375 32.375Z"
      fill="url(#paint0_linear_6302_7182)"
    />
    <path
      d="M21.625 32.375C18.2897 31.5721 16 29.9145 16 28C16 26.0855 18.2897 24.4279 21.625 23.625C21.2819 24.9414 21.0888 26.4274 21.0888 28C21.0888 29.5726 21.2819 31.0586 21.625 32.375Z"
      fill="url(#paint1_linear_6302_7182)"
    />
    <path
      d="M21.625 32.375C22.4279 35.7103 24.0855 38 26 38C27.9145 38 29.5721 35.7103 30.375 32.375C29.0586 32.7181 27.5726 32.9112 26 32.9112C24.4274 32.9112 22.9414 32.7181 21.625 32.375Z"
      fill="url(#paint2_linear_6302_7182)"
    />
    <path
      d="M21.625 23.625C22.4279 20.2897 24.0855 18 26 18C27.9145 18 29.5721 20.2897 30.375 23.625C29.0586 23.2819 27.5726 23.0888 26 23.0888C24.4274 23.0888 22.9414 23.2819 21.625 23.625Z"
      fill="url(#paint3_linear_6302_7182)"
    />
    <path
      d="M46 20.065C46 18.8121 46.78 18 48.0058 18C49.2092 18 49.9893 18.8121 49.9893 20.065C49.9893 21.3179 49.2092 22.1531 48.0058 22.1531C46.78 22.1531 46 21.3179 46 20.065ZM49.7887 37.884H46.2229V23.9629H49.7887V37.884Z"
      fill="url(#paint4_linear_6302_7182)"
    />
    <path
      d="M60.7289 30.9002C60.7289 28.8585 59.7037 27.5128 58.1214 27.5128C56.6282 27.5128 55.5362 28.9513 55.5362 30.9234V37.884H51.9704V23.9629H55.5362V25.587C56.0042 24.5429 57.386 23.8005 58.7677 23.8005C62.1775 23.8005 64.2947 26.6311 64.2947 30.9002V37.884H60.7289V30.9002Z"
      fill="url(#paint5_linear_6302_7182)"
    />
    <path
      d="M70.0119 37.884H66.4461V18.1624H70.0119V30.2042L74.536 23.9629H78.4362L73.8897 30.9234L78.9042 37.884H74.38L70.0119 31.6427V37.884Z"
      fill="url(#paint6_linear_6302_7182)"
    />
    <path
      d="M83.8049 37.884H80.2391V18.1624H83.8049V37.884Z"
      fill="url(#paint7_linear_6302_7182)"
    />
    <path
      d="M94.6461 33.6148H98.4348C97.5211 36.2135 95.1364 38 92.4175 38C88.5174 38 85.7761 35.0534 85.7761 30.877C85.7761 26.8399 88.6065 23.7773 92.3506 23.7773C96.0948 23.7773 98.9251 26.8399 98.9251 30.877C98.9251 31.3179 98.9028 31.7819 98.836 32.1995H89.5203C89.9214 33.6148 91.0135 34.5197 92.3952 34.5197C93.4427 34.5197 94.2673 34.1485 94.6461 33.6148ZM89.5203 29.5313H95.181C94.7799 28.1624 93.7324 27.2807 92.3506 27.2807C90.9689 27.2807 89.9214 28.1624 89.5203 29.5313Z"
      fill="url(#paint8_linear_6302_7182)"
    />
    <path
      d="M150.706 33.8814H152.838C152.901 35.0939 154.113 36.0556 155.556 36.0765C156.894 36.0974 157.855 35.4702 157.855 34.4667C157.855 33.2751 156.894 32.7734 154.97 32.2507C153.026 31.7072 151.124 30.9755 151.124 28.6758C151.124 26.5225 153.047 25.1846 155.514 25.1846C157.939 25.1846 159.653 26.6689 159.862 28.9267H157.834C157.688 27.8814 156.726 27.1079 155.493 27.1079C154.301 27.087 153.382 27.7351 153.361 28.634C153.361 29.8675 154.887 30.2229 155.744 30.4528C157.646 30.9337 160.092 31.4981 160.092 34.4458C160.092 36.5155 158.127 37.9998 155.493 37.9998C153.131 37.9998 150.768 36.4319 150.706 33.8814Z"
      fill="#41395C"
    />
    <path
      d="M146.507 34.0281H148.89C148.033 36.3696 145.838 37.9793 143.329 37.9793C139.733 37.9793 137.204 35.3243 137.204 31.5612C137.204 27.9236 139.817 25.1641 143.266 25.1641C146.695 25.1641 149.35 27.9027 149.35 31.4567C149.35 31.7703 149.329 32.0839 149.266 32.3766H139.503C139.817 34.4671 141.301 35.8678 143.308 35.8678C144.772 35.8678 145.942 35.1152 146.507 34.0281ZM139.524 30.725H147.05C146.737 28.718 145.231 27.2965 143.266 27.2965C141.322 27.2965 139.838 28.6971 139.524 30.725Z"
      fill="#41395C"
    />
    <path
      d="M135.145 37.8744H132.887V20.1045H135.145V37.8744Z"
      fill="#41395C"
    />
    <path
      d="M128.199 37.8744V35.5329C127.634 36.9963 125.836 37.9998 123.955 37.9998C120.359 37.9998 117.976 35.4493 117.976 31.5817C117.976 27.7351 120.338 25.1846 123.955 25.1846C125.815 25.1846 127.634 26.188 128.199 27.6724V25.3309H130.435V37.8744H128.199ZM120.233 31.5817C120.233 34.0904 121.864 35.742 124.226 35.742C126.568 35.742 128.199 34.0904 128.199 31.6026C128.199 29.1358 126.526 27.4215 124.206 27.4215C121.843 27.4215 120.233 29.073 120.233 31.5817Z"
      fill="#41395C"
    />
    <path
      d="M104 32.5435H106.237C106.383 34.5086 108.223 35.8466 110.355 35.8466C112.55 35.8466 114.244 34.6759 114.265 32.9198C114.286 30.871 112.446 30.2438 110.46 29.763C107.721 29.094 104.544 28.655 104.544 24.7874C104.564 21.5261 107.157 20 110.314 20C113.366 20 116.021 21.6516 116.104 24.892H113.868C113.721 23.115 112.174 22.1533 110.314 22.1533C108.369 22.1533 106.801 23.0522 106.78 24.7456C106.76 26.7735 108.662 27.108 110.857 27.6306C113.533 28.2578 116.543 29.2822 116.523 32.8989C116.502 35.8466 113.868 37.979 110.376 37.9999C107.01 37.9999 104.125 35.972 104 32.5435Z"
      fill="#41395C"
    />
    <path
      d="M190.267 38.0169L194.725 31.7086L190.267 25.4004H192.98L196.071 29.7741L199.162 25.4004H201.895L197.437 31.7086L201.895 38.0169H199.162L196.071 33.6432L192.98 38.0169H190.267Z"
      fill="#41395C"
    />
    <path
      d="M186.393 38.0176V35.6625C185.825 37.1344 184.017 38.1438 182.124 38.1438C178.507 38.1438 176.11 35.5784 176.11 31.6883C176.11 27.8193 178.486 25.2539 182.124 25.2539C183.996 25.2539 185.825 26.2632 186.393 27.7562V25.4011H188.643V38.0176H186.393ZM178.381 31.6883C178.381 34.2116 180.021 35.8728 182.398 35.8728C184.753 35.8728 186.393 34.2116 186.393 31.7094C186.393 29.2281 184.711 27.5038 182.377 27.5038C180 27.5038 178.381 29.165 178.381 31.6883Z"
      fill="#41395C"
    />
    <path
      d="M171.236 38.0169H168.986V22.3935H163.54V20.1436H176.682V22.3935H171.236V38.0169Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6302_7182"
        x1="30.375"
        y1="28"
        x2="36"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6302_7182"
        x1="16"
        y1="28"
        x2="21.625"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6302_7182"
        x1="21.625"
        y1="35.1875"
        x2="30.375"
        y2="35.1875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6302_7182"
        x1="21.625"
        y1="20.8125"
        x2="30.375"
        y2="20.8125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_6302_7182"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_6302_7182"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_6302_7182"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_6302_7182"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_6302_7182"
        x1="46"
        y1="28"
        x2="98.9251"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
