export const OptionDropdown = ({ color }) => {
  return (
    <>
      <span className="t-sr-only">Option dropdown</span>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3281 12.125C13.3281 11.5727 12.8804 11.125 12.3281 11.125C11.7758 11.125 11.3281 11.5727 11.3281 12.125C11.3281 12.6773 11.7758 13.125 12.3281 13.125C12.8804 13.125 13.3281 12.6773 13.3281 12.125Z"
          fill={color}
        />
        <path
          d="M13.3281 6.125C13.3281 5.57272 12.8804 5.125 12.3281 5.125C11.7758 5.125 11.3281 5.57272 11.3281 6.125C11.3281 6.67728 11.7758 7.125 12.3281 7.125C12.8804 7.125 13.3281 6.67728 13.3281 6.125Z"
          fill={color}
        />
        <path
          d="M13.3281 18.125C13.3281 17.5727 12.8804 17.125 12.3281 17.125C11.7758 17.125 11.3281 17.5727 11.3281 18.125C11.3281 18.6773 11.7758 19.125 12.3281 19.125C12.8804 19.125 13.3281 18.6773 13.3281 18.125Z"
          fill={color}
        />
      </svg>
    </>
  );
};
