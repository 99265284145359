import { DeleteModal } from "components/design/deleteModal";
import { Button } from "components/DesignSystem/Button/Button";
import { DeleteIcon } from "components/icons/delete";
import Pencil from "components/icons/pencil";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useDeleteChatTemplateMutation } from "store/apis/chatTemplate";
import { ChatTemplate } from "types/Models/ChatTemplate";

const TemplateCard = ({
  cardData,
  editTemplate = () => {},
  setTemplate,
}: {
  cardData: ChatTemplate;
  editTemplate?: (data: ChatTemplate) => void;
  setTemplate: (e: any, data: ChatTemplate) => void;
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTemplate, { isLoading: isDeleting }] =
    useDeleteChatTemplateMutation();
  const { alertToast } = useToast();

  const deleteChatTemplate = async () => {
    try {
      await deleteTemplate({ templateId: cardData.uuid });
      setDeleteModal(false);
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const closeDeleteModal = (e: any) => {
    setDeleteModal(false);
  };

  const openDeleteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteModal(true);
    e.stopPropagation();
  };

  return (
    <div
      className="t-my-2 t-h-20 t-w-full t-cursor-pointer t-rounded !t-border-[2px] t-border-solid !t-border-neutral-0 t-bg-surface-lighter-grey t-p-3 hover:t-bg-neutral-0"
      onClick={(e) => {
        !deleteModal && setTemplate(e, cardData);
      }}
    >
      <div className="t-flex t-justify-between">
        <span className="t-text-subtitle-sm t-font-semibold t-text-text-100">
          {cardData.response_header}
        </span>
        <span className="t-flex">
          <Button
            customType="ghost_icon"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              editTemplate(cardData);
            }}
          >
            <Pencil color="currentColor" size="16" strokeWidth="1.5" />
          </Button>
          <Button
            customType="ghost_icon"
            size="small"
            onClick={openDeleteModal}
          >
            <DeleteIcon color="currentColor" />
          </Button>
        </span>
      </div>
      <div className="t-truncate t-text-body-sm t-font-light t-text-text-100">
        {cardData.response_body}
      </div>
      <DeleteModal
        show={deleteModal}
        closeModal={closeDeleteModal}
        onClick={deleteChatTemplate}
        text="Delete this template"
        isLoading={isDeleting}
      />
    </div>
  );
};

export default TemplateCard;
