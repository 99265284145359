import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

export const DeleteFilingAutofill = ({
  isOpen,
  close,
  handleDeleteBank,
  isLoading,
  title,
}: {
  isOpen: boolean;
  close: () => void;
  handleDeleteBank: () => void;
  isLoading: boolean;
  title: string;
}) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Remove this {title}?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Are you sure you want to remove this {title}?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-3">
            <Button onClick={close}>Cancel</Button>
            <Button
              customType="danger"
              onClick={handleDeleteBank}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
