import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ChatSearchState = {
  value: string;
  unread: boolean;
  isActive: boolean;
  showMentionedMessages: boolean;
};

const initialState: ChatSearchState = {
  value: "",
  unread: false,
  isActive: false,
  showMentionedMessages: false,
};

export const chatSearch = createSlice({
  name: "chatSearch",
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setUnreadFilter: (state, action: PayloadAction<boolean>) => {
      state.unread = action.payload;
    },
    setSearchActiveState: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setShowMentionedMessages: (state, action: PayloadAction<boolean>) => {
      state.showMentionedMessages = action.payload;
    },
  },
});

export const {
  setSearch,
  setUnreadFilter,
  setSearchActiveState,
  setShowMentionedMessages,
} = chatSearch.actions;

export default chatSearch.reducer;
