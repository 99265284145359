import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  QuoteResponse,
  TPAccountInfo,
} from "types/Models/TransferPricingTransfer";

export interface PurposeCode {
  uuid: string;
  code: string;
  description: string;
}

export interface TPPaymentsState {
  amount: number;
  purpose_code: PurposeCode;
  currency: string;
  accountInfo?: TPAccountInfo;
  tpPaymentId: string;
  quote: QuoteResponse;
}

const initialState: TPPaymentsState = {
  amount: 0,
  tpPaymentId: "",
  purpose_code: { code: "", uuid: "", description: "" },
  currency: "USD",
  accountInfo: undefined,
  quote: {
    receiver_amount: "",
    receiver_currency: "",
    sender_amount: "",
    sender_currency: "",
    mid_market_rate: "",
    inkle_rate: "",
  },
};

export const tpPaymentsSlice = createSlice({
  name: "tppayments",
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
    setPurposeCode: (state, action: PayloadAction<PurposeCode>) => {
      state.purpose_code = action.payload;
    },
    setAccountInfo: (state, action: PayloadAction<TPAccountInfo>) => {
      state.accountInfo = action.payload;
    },
    setTPPaymentId: (state, action: PayloadAction<string>) => {
      state.tpPaymentId = action.payload;
    },
    resetAllTPStates: (state) => {
      state.amount = 0;
      state.tpPaymentId = "";
      state.purpose_code = { code: "", uuid: "", description: "" };
      state.quote = {
        receiver_amount: "",
        receiver_currency: "",
        sender_amount: "",
        sender_currency: "",
        mid_market_rate: "",
        inkle_rate: "",
      };
    },
    setQuoteResponse: (state, action: PayloadAction<QuoteResponse>) => {
      state.quote = action.payload;
    },
  },
});

export const {
  setAmount,
  setPurposeCode,
  setAccountInfo,
  setTPPaymentId,
  resetAllTPStates,
  setQuoteResponse,
} = tpPaymentsSlice.actions;

export default tpPaymentsSlice.reducer;
