import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteRuleMutation } from "store/apis/ruleEngine";
import { closeDeleteModal, closeSlider } from "store/slices/ruleEngine";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";

export const DeleteRule = () => {
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const [deleteRule] = useDeleteRuleMutation();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { isDeleteModalOpen, deleteRuleId } = useSelector(
    (store: RootState) => store.ruleEngine
  );
  const close = () => {
    dispatch(closeDeleteModal());
  };

  const onDelete = async ({ uuid }: { uuid: string }) => {
    try {
      await deleteRule({ entityId, groupId, ruleId: uuid }).unwrap();
      successToast({ message: "Rule Deleted" });
      close();
      dispatch(closeSlider());
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isDeleteModalOpen} onOpenChange={close}>
      <Formik
        onSubmit={onDelete}
        initialValues={{ uuid: deleteRuleId }}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Modal.Content size="large">
              <Modal.Header>
                <Modal.Title>Delete rule</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-text-body  t-text-text-100">
                Are you sure you want to delete this rule?
                <br />
                It will stop categorising all the future transactions.
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button onClick={close} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  customType="danger"
                  onClick={submitForm}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Confirm delete
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
