import Modal from "./DesignSystem/Modal/Modal";

export const ThreeDSPaymentFrame = ({ url }: { url: string | null }) => {
  return (
    <Modal.Root open={Boolean(url)}>
      <Modal.Content>
        <div className="t-h-[600px]">
          {url && (
            <iframe
              src={url}
              title="Payment authorization"
              className="t-w-full t-h-full"
            />
          )}
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
