export const Redirect = ({ color = "#5F2EE5", size = 24 }) => {
  return (
    <>
      <span className="t-sr-only">Redirect</span>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.25 9.875V4.25H14.625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 11L20.25 4.25"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.25 14V20C17.25 20.1989 17.171 20.3897 17.0303 20.5303C16.8897 20.671 16.6989 20.75 16.5 20.75H4.5C4.30109 20.75 4.11032 20.671 3.96967 20.5303C3.82902 20.3897 3.75 20.1989 3.75 20V8C3.75 7.80109 3.82902 7.61032 3.96967 7.46967C4.11032 7.32902 4.30109 7.25 4.5 7.25H10.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
