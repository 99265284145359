import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowRight } from "components/icons/ArrowRight";
import { FC, useState } from "react";

type YearSelectorProps = {
  years: string[];
  onSelect: (year: string) => void;
  defaultYear?: string;
};

export const YearSelector: FC<YearSelectorProps> = ({
  years,
  onSelect,
  defaultYear,
}) => {
  const [selectedYear, setSelectedYear] = useState(defaultYear || "");

  const onYearSelect = (year: string) => {
    setSelectedYear(year);
    onSelect(year);
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger
        asChild
        className="t-group t-text-body t-bg-surface-lighter-grey t-w-36 t-rounded t-border t-border-neutral-0 t-border-solid t-px-3 t-py-1.5"
      >
        <div className="t-flex t-items-center t-justify-between" role="button">
          {selectedYear}
          <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
            <ArrowRight stroke="1.5" color="currentColor" />
          </div>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content className="t-w-36" sideOffset={8}>
          {years.map((year) => (
            <Dropdown.Item key={year} onSelect={() => onYearSelect(year)}>
              {year}
            </Dropdown.Item>
          ))}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
