import classNames from "classnames";
import { ComplianceTable } from "components/ComplianceTable/ComplianceTable";
import DashboardContainer from "components/dashboard/DashboardContainer";
import ToolTip from "components/design/toolTip";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Header } from "components/DesignSystem/Header/Header";
import { COORDINATES } from "constants/regAgent";
import { ACTION_REQUIRED, COMPLETED, WITH_INKLE } from "constants/tax";
import { FILING_STATES } from "dictionaries";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery } from "hooks/useQuery";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ArrowDown from "static/images/Arrowdown.svg";
import {
  useGetChoicesQuery,
  useGetFilingsQuery,
} from "store/apis/taxAndCompliance";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import ActionRequired from "../../static/images/ActionRequired.svg";
import Completed from "../../static/images/FilingCompleted.svg";
import None from "../../static/images/None.svg";
import WithInkle from "../../static/images/WithInkle.svg";
import { FilingsTable } from "./FilingsTable";
import { ForeignQualifications } from "./ForeignQualifications";
import { DashboardLayout } from "components/DashboardLayout";
import dayjs from "dayjs";
import { YYYY } from "constants/date";
import { useAnalytics } from "hooks/useAnalytics";
import { CLICKED_ENTITY_SWITCHER_IN_COMPLIANCE } from "constants/analyticsEvents";

const TaxCard = ({
  task,
  type,
  status,
}: {
  task: string;
  type: string;
  status: keyof typeof FILING_STATES;
}) => {
  let icon;

  switch (status) {
    case ACTION_REQUIRED:
      icon = ActionRequired;
      break;

    case WITH_INKLE:
      icon = WithInkle;
      break;

    case COMPLETED:
      icon = Completed;
      break;

    default:
      icon = None;
  }

  return (
    <div className="t-flex t-flex-col t-flex-[1_1_25%] t-items-center t-pt-[30px] t-pb-7 t-border t-border-neutral-0 t-shadow-card t-rounded-xl t-border-solid">
      <p className="t-text-subtitle t-text-text-100 t-my-0">{task}</p>
      <span className="t-mt-1.5 t-text-subtitle t-text-text-30">{type}</span>
      <ToolTip text={FILING_STATES[status] || "N/A"}>
        <div className="t-mt-8">
          <img src={icon} alt={FILING_STATES[status] || "N/A"} />
        </div>
      </ToolTip>
    </div>
  );
};

export const TaxAndCompliance = () => {
  usePageTitle("Tax & Compliance");
  const group = useCurrentGroup();
  const query = useQuery();
  let selectedEntityId = query.get("entity");
  const { data: seasons } = useGetChoicesQuery({ choice: "SEASON" });
  const { groupId } = useParams<{ groupId: string }>();
  const lastYear = dayjs().subtract(1, "year").format(YYYY);
  const [season, setSeason] = useState(lastYear);
  const { trackEvent } = useAnalytics();

  const { data } = useGetFilingsQuery(
    {
      groupId: group?.uuid || groupId,
      entityId: selectedEntityId!,
      season: season,
    },
    { skip: !selectedEntityId || !(group?.uuid || groupId) }
  );

  const selectedEntityDetails = group.entities?.find(
    (entity) => entity.uuid === selectedEntityId
  );

  const selectedEntityCountry = selectedEntityDetails?.country_code;

  const seasonSelector = (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <div className="t-w-[152px] t-h-8 t-rounded t-border t-border-neutral-0 t-border-solid t-bg-surface-lighter-grey t-px-3 t-py-2 t-flex t-justify-between t-items-center focus-within:t-border-purple">
          <span className="t-text-body">{season}</span>
          <img src={ArrowDown} alt="Dropdown icon" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content align="start" sideOffset={8} className="t-w-[152px]">
          {seasons
            ?.slice()
            .sort((a, b) => parseFloat(b) - parseFloat(a))
            .map((season: string, i: number) => (
              <Dropdown.Item
                className="t-text-body"
                key={i}
                onClick={() => setSeason(season)}
              >
                {season}
              </Dropdown.Item>
            ))}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );

  return (
    <DashboardLayout
      className="t-h-full"
      header={
        <Header
          v2
          title="Tax & Compliance"
          right={seasonSelector}
          left={
            <div
              onClick={() => trackEvent(CLICKED_ENTITY_SWITCHER_IN_COMPLIANCE)}
            >
              <EntitySelector />
            </div>
          }
        />
      }
    >
      <p className="t-mt-6 t-text-subtitle">Compliance Healthcheck</p>
      {selectedEntityCountry === "US" ? (
        <>
          {data?.home_state && (
            <ForeignQualifications
              activeStates={data?.state_filings.map(
                (filing) => filing.state_name
              )}
              homeState={data.home_state as keyof typeof COORDINATES}
            />
          )}
          <p className={classNames("t-mb-6 t-text-subtitle t-text-text-100")}>
            Federal Tax and Other Filings
          </p>
          <div className="t-flex t-gap-7">
            {data?.other_filings.map((filing) => {
              if (filing.task === "BE - 12 Survey" && season !== "2022") {
                return null;
              }

              return (
                <TaxCard
                  task={filing.task}
                  type={filing.type}
                  status={filing.status as keyof typeof FILING_STATES}
                  key={filing.task}
                />
              );
            })}
          </div>
          {selectedEntityId && (
            <FilingsTable
              stateFilings={data?.state_filings || []}
              groupId={group?.uuid || groupId}
              entityId={selectedEntityId}
              season={season}
            />
          )}
        </>
      ) : (
        <ComplianceTable />
      )}
    </DashboardLayout>
  );
};
