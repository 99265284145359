import { parse, stringify } from "qs";
import { useLocation, useRouteMatch } from "react-router-dom";

export const useConstructInternalLink = () => {
  const { url } = useRouteMatch();
  const location = useLocation();

  const constructInternalLink = (
    link: string,
    {
      keys = ["entity", "company"],
      includeUrl = false,
      moreQuery = {},
    }: {
      keys?: string[];
      includeUrl?: boolean;
      moreQuery?: Record<string, string | number | boolean>;
    } = {}
  ) => {
    const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
    const searchFromKeys = Object.fromEntries(
      Object.entries(parsedSearch || {}).filter(([key]) => keys.includes(key))
    );

    const search = stringify(
      { ...searchFromKeys, ...moreQuery },
      { skipNulls: true, addQueryPrefix: true }
    );

    return `${includeUrl ? url : ""}${link}${search}`;
  };

  return { link: constructInternalLink };
};
