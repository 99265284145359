import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { FileInput } from "components/FileInput/FileInput";
import { Preview } from "components/PreviewModal";
import Loader from "components/design/loader";
import { Cross } from "components/icons/Cross";
import Pencil from "components/icons/pencil";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import React, { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetAllCapTablesQuery,
  useUpdateCapTableMutation,
} from "store/apis/capTable";
import { CapTable } from "types/Models/capTable";
import { BackendError } from "types/utils/error";
import { FileIcon } from "utils/fileTypeIcon";

const SliderItem = ({
  label,
  content,
}: {
  label: string;
  content: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col">
      <Label>{label}</Label>
      <div className="t-text-subtext">{content}</div>
    </div>
  );
};

const CapTableSliderCard = ({ children }: { children: ReactNode }) => {
  return (
    <div className="t-p-5 t-m-5 t-border t-border-solid t-border-neutral-10 t-rounded-lg">
      {children}
    </div>
  );
};

const Header = ({
  isEditEnable,
  setIsEditEnable,
  setCapTableId,
}: {
  isEditEnable: boolean;
  setIsEditEnable: (newState: React.SetStateAction<boolean>) => void;
  setCapTableId: (newState: React.SetStateAction<string>) => void;
}) => {
  return (
    <div className="t-flex t-justify-between t-px-5 t-py-4 t-items-center t-border t-border-solid t-border-b t-border-l-0 t-border-r-0 t-border-t-0 t-border-neutral-10">
      <div className="t-text-subtitle">Cap Table</div>
      <div className="t-flex t-gap-3 t-items-center">
        {!isEditEnable && (
          <Button
            customType="ghost_icon"
            size="small"
            onClick={() => setIsEditEnable(true)}
          >
            <Pencil color="currentColor" size="16" />
          </Button>
        )}
        <Button
          customType="ghost_icon"
          size="small"
          onClick={() => {
            setIsEditEnable(false);
            setCapTableId("");
          }}
        >
          <Cross color="currentColor" size="16" />
        </Button>
      </div>
    </div>
  );
};

export const Info = () => {
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    isOpen: isPreviewModalOpen,
    close: onPreviewClose,
    open: openPreview,
  } = useModal();

  const { values } = useFormikContext<CapTable>();

  const { document } = values;
  const { uuid: previewId, file_type, name } = document || {};

  return (
    <CapTableSliderCard>
      <div className="t-flex t-flex-col t-gap-6">
        <SliderItem label="Note" content={values?.note ? values.note : "-"} />
        <SliderItem
          label="As On"
          content={dayjs(values?.valid_as_on).format(DD_MMM_YYYY)}
        />
        <div className="t-flex t-justify-between t-mr-10">
          <SliderItem label="Uploaded By" content={values?.uploaded_by?.name} />
          <SliderItem
            label="Uploaded On"
            content={dayjs(values?.created_at).format(DD_MMM_YYYY)}
          />
        </div>
        <SliderItem
          label="Cap Table File"
          content={
            <>
              <button
                onClick={openPreview}
                type="button"
                className="all:unset hover:t-bg-surface-grey t-flex t-gap-2 t-items-center t-p-2 t-rounded"
              >
                <FileIcon fileType={file_type} width="24px" height="24px" />
                <div className="t-text-subtext t-w-[350px] t-break-words">
                  {name}
                </div>
              </button>
              {isPreviewModalOpen && (
                <Preview
                  showModal={isPreviewModalOpen}
                  closeModal={onPreviewClose}
                  groupId={groupId}
                  previewId={previewId}
                />
              )}
            </>
          }
        />
      </div>
    </CapTableSliderCard>
  );
};

export const Edit = () => {
  const { values, setFieldValue } = useFormikContext<CapTable>();

  return (
    <CapTableSliderCard>
      <div
        className="t-flex t-flex-col t-gap-6"
        onClick={(e) => e.stopPropagation()}
      >
        <TextArea name="note" label="Note" />
        <Field name="valid_as_on">
          {({ field }: FieldProps) => {
            return (
              <DateInput
                {...field}
                maxDate={new Date()}
                label="As On"
                placeholder={DD_MMM_YYYY}
                onDateChange={(date) => {
                  setFieldValue("valid_as_on", date);
                }}
                portalId="asOnDate"
              />
            );
          }}
        </Field>
        <div className="t-flex t-justify-between t-mr-10">
          <SliderItem label="Uploaded By" content={values?.uploaded_by?.name} />
          <SliderItem
            label="Uploaded On"
            content={dayjs(values?.created_at).format(DD_MMM_YYYY)}
          />
        </div>
        <SliderItem
          label=""
          content={
            <FileInput label="Cap Table File" withForm name="document" />
          }
        />
      </div>
    </CapTableSliderCard>
  );
};

export const CapTableSlider = ({
  capTableId,
  setCapTableId,
}: {
  capTableId: string;
  setCapTableId: (newState: React.SetStateAction<string>) => void;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { entityId } = useParams<{ entityId: string }>();
  const [isEditEnable, setIsEditEnable] = useState(false);
  const { alertToast, successToast } = useToast();

  const { data: capTable, isLoading } = useGetAllCapTablesQuery({
    pageNum: 1,
    groupId,
    entityId,
  });

  const [updateCapTable, { isLoading: isUpdating }] =
    useUpdateCapTableMutation();

  const { cap_tables: capTableData = [] } = capTable || {};

  const currentCapTable = capTableData?.find(({ uuid }) => uuid === capTableId);

  const onSubmit = async (values: CapTable) => {
    try {
      const formData = new FormData();
      formData.append(
        "valid_as_on",
        dayjs(values.valid_as_on).format(YYYY_MM_DD)
      );
      formData.append("note", values.note);
      if (values.document instanceof File) {
        formData.append("document", values.document);
      }
      await updateCapTable({
        groupId,
        entityId,
        capTableId: currentCapTable?.uuid!,
        payload: formData,
      }).unwrap();
      successToast({ message: "CapTable updated" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onClick = ({
    submitForm,
  }: {
    submitForm: (() => Promise<void>) & (() => Promise<any>);
  }) => {
    if (isEditEnable) {
      submitForm();
      setIsEditEnable(false);
      return;
    }
    setIsEditEnable(true);
  };

  const onClose = () => {
    setIsEditEnable(false);
    setCapTableId("");
  };

  return (
    <>
      <div
        className={classNames({
          "t-fixed t-top-0 t-left-0 t-bg-text-100 t-opacity-20 t-w-full t-h-full t-z-overlay":
            capTableId,
          "t-w-0 t-h-0 t-invisible": !capTableId,
        })}
        onClick={onClose}
      />
      <AnimatePresence>
        {capTableId && (
          <motion.div
            transition={{ duration: 0.3, ease: "easeInOut" }}
            initial={{ right: -480 }}
            animate={{ right: 0 }}
            exit={{ right: -480 }}
            className="t-border t-border-solid t-border-neutral-10 t-fixed t-bg-surface t-w-[480px] t-h-full t-z-modal t-top-0 t-right-0 t-overflow-auto"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <div className="t-flex t-flex-col">
                <Header
                  setIsEditEnable={setIsEditEnable}
                  setCapTableId={setCapTableId}
                  isEditEnable={isEditEnable}
                />
                <Formik
                  onSubmit={onSubmit}
                  initialValues={currentCapTable!}
                  enableReinitialize
                >
                  {({ submitForm, isSubmitting }) => (
                    <Form className="t-m-0 t-w-full">
                      {isEditEnable ? <Edit /> : <Info />}
                      <div className="t-flex t-gap-3 t-p-4 t-bg-surface t-fixed t-bottom-0 t-shadow-tasks-slider-bottom t-w-[480px]">
                        {isEditEnable && (
                          <Button
                            onClick={() => setIsEditEnable(false)}
                            type="button"
                            block
                          >
                            Cancel
                          </Button>
                        )}
                        <Button
                          customType="primary"
                          type="button"
                          isLoading={isUpdating || isSubmitting}
                          disabled={isUpdating || isSubmitting}
                          onClick={() => onClick({ submitForm })}
                          block
                        >
                          {isEditEnable ? "Save" : "Edit"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
