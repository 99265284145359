import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { useGetTransaction } from "hooks/useGetTransaction";
import { useDispatch } from "react-redux";
import { openEditSplitTransactionModal } from "store/slices/splitTransaction";
import { Transactions } from "types/Models/books";

export const SplitTransactionDetail = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const { data: transactionDetail } = useGetTransaction({
    transactionId,
  });
  const dispatch = useDispatch();

  return (
    <div className="t-flex t-flex-col t-gap-1">
      <div className="t-flex t-justify-between">
        <Label className="!t-pb-0">Split transaction</Label>
        <Button
          customType="link"
          size="small"
          onClick={() =>
            dispatch(
              openEditSplitTransactionModal(transactionDetail as Transactions)
            )
          }
        >
          Edit
        </Button>
      </div>

      <div className="t-flex t-flex-col t-gap-1">
        {transactionDetail?.split_transactions &&
          transactionDetail?.split_transactions?.map((transaction) => (
            <div
              className="t-flex t-justify-between t-items-center t-text-text-100 t-text-body-sm"
              key={transaction.uuid}
            >
              <div>{transaction.description}</div>
              <AmountSuperScript amount={transaction.amount} />
            </div>
          ))}
      </div>
    </div>
  );
};
