import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplateType } from "./IntragroupService";

const emptyTemplate = {
  title: "",
  uuid: "",
  approx_price: "",
  description: "",
  seasons: [],
  default_task_template: "",
};

export type SalesTaxListState = {
  salesTaxTaskCounts: {
    ongoing_count: number;
    count: number;
    completed_count: number;
  };
  selectedSalesTaxTaskTemplate: TemplateType;
  showSalesTaxTaskLaunchModal: boolean;
  callbackUrlOnModalClose?: string;
};

const initialState: SalesTaxListState = {
  salesTaxTaskCounts: {
    ongoing_count: 0,
    count: 0,
    completed_count: 0,
  },
  selectedSalesTaxTaskTemplate: emptyTemplate,
  showSalesTaxTaskLaunchModal: false,
  callbackUrlOnModalClose: undefined,
};

export const salexTaxList = createSlice({
  name: "salexTaxList",
  initialState,
  reducers: {
    setSalesTaxTaskCount: (
      state,
      action: PayloadAction<SalesTaxListState["salesTaxTaskCounts"]>
    ) => {
      state.salesTaxTaskCounts = action.payload;
    },
    openSalesTaxTask: (state, action: PayloadAction<TemplateType>) => {
      state.selectedSalesTaxTaskTemplate = action.payload;
      state.showSalesTaxTaskLaunchModal = true;
    },
    closeSalesTaxTask: (state) => {
      state.selectedSalesTaxTaskTemplate = emptyTemplate;
      state.showSalesTaxTaskLaunchModal = false;
      state.callbackUrlOnModalClose = undefined;
    },
    setCallbackUrlOnModalClose: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.callbackUrlOnModalClose = action.payload;
    },
  },
});

export const {
  setSalesTaxTaskCount,
  openSalesTaxTask,
  closeSalesTaxTask,
  setCallbackUrlOnModalClose,
} = salexTaxList.actions;

export default salexTaxList.reducer;
