import { SummaryCard } from "components/BookkeepingAccordion/BookkeepingAccordionContent";
import { AmountSuperScript } from "components/design/AmountSuperScript";

type CompletedSummaryProps = {
  expenseVolume: null | string | number;
  transferPricingVolume: null | number | string;
  noOfTransactions: null | number;
};

export const BookkeepingCardSummary = ({
  expenseVolume,
  transferPricingVolume,
  noOfTransactions,
}: CompletedSummaryProps) => (
  <div className="t-mt-4 t-flex t-gap-2">
    <SummaryCard
      amount={<AmountSuperScript amount={+expenseVolume!} />}
      text="Expense Volume"
      description="Aggregate amount of all debits for this year"
      toolTip
    />
    <SummaryCard
      amount={noOfTransactions}
      text="Number of Txn"
      description="This is no of txns"
    />
  </div>
);
