import { APAutofillswPages } from "types/Models/apAutofills";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { DirectorAutofill } from "types/Models/directorAutofill";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAPAutofills: build.query<
      APAutofillswPages,
      {
        entityId: string;
        personaType: string;
        searchTerm?: string;
        isSort: boolean;
        pageNum?: number;
      }
    >({
      query: ({ entityId, personaType, searchTerm, isSort, pageNum }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
            sort_by_role: isSort,
            persona_type: personaType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/autofill/entity/${entityId}/personas/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? [{ type: "AP_AUTOFILLS" }] : []),
    }),

    getPersonaTypes: build.query<
      {
        name: string;
        uuid: string;
      }[],
      {
        entityId: string;
      }
    >({
      query: ({ entityId }) => {
        return {
          url: `/api/inkle/autofill/entity/${entityId}/persona-types/`,
        };
      },
    }),

    addAPAutoFill: build.mutation<
      APAutofillswPages & DirectorAutofill,
      {
        personaType: string;
        entityId: string;
        payload: any;
      }
    >({
      query: ({ personaType, entityId, payload }) => {
        let queryUrl = qs.stringify(
          {
            persona_type: personaType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/autofill/entity/${entityId}/personas/${queryUrl}`,
          method: "post",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["AP_AUTOFILLS", "AUTOFILL"] : []),
    }),

    updateAPAutoFill: build.mutation<
      APAutofillswPages & DirectorAutofill,
      {
        personaType: string;
        entityId: string;
        personaId: string;
        payload: any;
      }
    >({
      query: ({ personaType, entityId, personaId, payload }) => {
        let queryUrl = qs.stringify(
          {
            persona_type: personaType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/autofill/entity/${entityId}/persona/${personaId}/${queryUrl}`,
          method: "put",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "AP_AUTOFILLS" }, { type: "AUTOFILL" }] : [],
    }),
  }),
});

export const {
  useGetAPAutofillsQuery,
  useGetPersonaTypesQuery,
  useAddAPAutoFillMutation,
  useUpdateAPAutoFillMutation,
} = extendedApi;
