import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { Search } from "components/DesignSystem/Search/Search";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { BOOKS_STATUS, BOOKS_SUBSCRIPTION } from "dictionaries";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useBookkeepingCRMFilterOptionsQuery } from "store/apis/bookkeepingTracker";
import { useGetAllGroupsQuery } from "store/apis/crm";
import { bookkeepingTracker } from "store/selector/bookkeepingTracker";
import {
  BooksCrmFilterType,
  setBookkeepingTrackerFilter,
} from "store/slices/bookkeepingTracker";
import { CompanyGroup } from "types/Models/groups";
import { debounce } from "utils/debouncing";
import { Capsule } from "components/Transaction/Filter";
import { SERVICE_TEAM_LABEL } from "constants/bookkeeping";

export const FilterLoader = () => {
  return (
    <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2 t-h-full">
      <span className="t-flex t-origin-center t-animate-spin">
        <LoadingIcon />
      </span>
      Loading...
    </div>
  );
};

const CapsuleFilters = () => {
  const dispatch = useDispatch();
  const { capsuleFilters } = useSelector(bookkeepingTracker);

  const removeFilter = (type: BooksCrmFilterType) => {
    dispatch(
      setBookkeepingTrackerFilter({
        [type]: [],
      })
    );
  };

  return (
    <>
      {capsuleFilters.map(({ name, value, type }) => {
        return (
          <Capsule
            key={name}
            value={`(${value.length})`}
            onCapsuleClick={() => removeFilter(type)}
            type={type}
          >
            {name}
          </Capsule>
        );
      })}
    </>
  );
};

const AssigneeFilter = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(bookkeepingTracker);
  const assignees = filters.assignees.value;
  const selectedAssignees = new Set(Array.from(assignees));
  const { data, isLoading } = useBookkeepingCRMFilterOptionsQuery();
  const { assignees: assigneesOptions } = data || {};

  const onStatusesSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let newAssignees: Set<string> = new Set();
    if (checked) {
      newAssignees = new Set([...assignees, value]);
    } else {
      newAssignees = new Set(
        [...assignees]?.filter((assignee) => assignee !== value)
      );
    }

    dispatch(
      setBookkeepingTrackerFilter({
        assignees: [...newAssignees],
      })
    );
  };

  if (isLoading) {
    return <FilterLoader />;
  }

  return (
    <div className="t-flex t-h-[308px] t-flex-col t-overflow-y-auto t-gap-3">
      {assigneesOptions?.map(({ name, profile_id }) => {
        return (
          <Checkbox
            name={profile_id}
            key={profile_id}
            value={profile_id}
            onChange={onStatusesSelect}
            label={name}
            checked={selectedAssignees.has(profile_id)}
          />
        );
      })}
    </div>
  );
};

const SubscriptionPlanFilter = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(bookkeepingTracker);
  const subscriptionPlans = filters.subscription_plans?.value;
  const selectedSubscriptionPlans = new Set(Array.from(subscriptionPlans));
  const { data, isLoading } = useBookkeepingCRMFilterOptionsQuery();
  const { subscription_plans: subscription_plan_options } = data || {};

  const onPlanSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let newSubscriptionPlans: Set<string> = new Set();

    if (checked) {
      newSubscriptionPlans = new Set([...subscriptionPlans, value]);
    } else {
      newSubscriptionPlans = new Set(
        [...subscriptionPlans]?.filter(
          (subscription_plan) => subscription_plan !== value
        )
      );
    }

    dispatch(
      setBookkeepingTrackerFilter({
        subscription_plans: [...newSubscriptionPlans],
      })
    );
  };

  if (isLoading) {
    return <FilterLoader />;
  }
  type subscriptionValidType = "STANDARD" | "BYOA" | "PAYG" | "PRO";
  return (
    <div className="t-flex t-flex-col t-gap-3 t-overflow-y-auto">
      {subscription_plan_options?.map(
        (subscription_plan: subscriptionValidType) => {
          return (
            <Checkbox
              name={subscription_plan}
              value={subscription_plan}
              key={subscription_plan}
              onChange={onPlanSelect}
              label={BOOKS_SUBSCRIPTION[subscription_plan]}
              checked={selectedSubscriptionPlans.has(subscription_plan)}
            />
          );
        }
      )}
    </div>
  );
};

const TeamFilter = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(bookkeepingTracker);
  const teams = filters.teams?.value;
  const selectedTeams = new Set(Array.from(teams));
  const { data, isLoading } = useBookkeepingCRMFilterOptionsQuery();
  const { teams: teamOptions } = data || {};

  const onTeamSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let newTeams: Set<string> = new Set();

    if (checked) {
      newTeams = new Set([...teams, value]);
    } else {
      newTeams = new Set([...teams]?.filter((team) => team !== value));
    }

    dispatch(
      setBookkeepingTrackerFilter({
        teams: [...newTeams],
      })
    );
  };

  if (isLoading) {
    return <FilterLoader />;
  }

  return (
    <div className="t-flex t-flex-col t-gap-3 t-overflow-y-auto">
      {teamOptions?.map((team: string) => {
        return (
          <Checkbox
            name={team}
            value={team}
            key={team}
            onChange={onTeamSelect}
            label={SERVICE_TEAM_LABEL[team]}
            checked={selectedTeams.has(team)}
          />
        );
      })}
    </div>
  );
};

export const BooksCrmFilter = () => {
  const { appliedFiltersCount, filters } = useSelector(bookkeepingTracker);

  return (
    <div className="t-flex t-gap-2">
      <Filter.Root
        defaultValue="subscription_plans"
        title={
          <span className="t-text-body t-font-medium t-leading-none">
            Filters {appliedFiltersCount ? <>({appliedFiltersCount})</> : ""}
          </span>
        }
        capsule={<CapsuleFilters />}
      >
        <Filter.Portal>
          <Filter.List>
            {Object.entries(filters).map(([key, filter]) => {
              return (
                <Filter.ListItem value={filter.type} key={key}>
                  {filter.name}{" "}
                  {filter.value.length > 0 && <>({filter.value.length})</>}
                </Filter.ListItem>
              );
            })}
          </Filter.List>
          <Filter.Body value="subscription_plans" block>
            <SubscriptionPlanFilter />
          </Filter.Body>
          <Filter.Body value="teams" block>
            <TeamFilter />
          </Filter.Body>
          <Filter.Body value="assignees" block>
            <AssigneeFilter />
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>
    </div>
  );
};
