import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Search } from "components/DesignSystem/Search/Search";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { DividerLine } from "components/icons/DividerLine";
import { ANNOUNCEMENTS_CHANNEL } from "constants/chatType";
import { RANDOM_DATE_FOR_CHANNELS_LAST_MESSAGE } from "constants/date";
import { Formik } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import uniqBy from "lodash.uniqby";
import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { useForwardMessageMutation } from "store/apis/chat";
import { closeMessageForward } from "store/slices/messageForward";
import { Channel, ChannelFilters, ChannelSort } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

const ChannelSelector = ({
  sort,
  setChannelsSelected,
  selectedChannels,
}: {
  sort?: ChannelSort<DefaultStreamChatGenerics>;
  setChannelsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  selectedChannels: string[];
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { client } = useChatContext();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);

  let channelFilters: ChannelFilters<DefaultStreamChatGenerics> =
    useMemo(() => {
      const commonFilter = {
        members: { $in: [client.userID!] },
        custom_type: {
          $nin: [ANNOUNCEMENTS_CHANNEL],
        },
      };

      if (searchValue) {
        return {
          ...commonFilter,
          $or: [
            { group_name: { $autocomplete: searchValue } },
            {
              name: { $autocomplete: searchValue },
            },
          ],
        };
      }

      return commonFilter;
    }, [client.userID, searchValue]);

  const filterString = useMemo(
    () => JSON.stringify(channelFilters),
    [channelFilters]
  );
  const sortString = useMemo(() => JSON.stringify(sort), [sort]);

  const queryChannels = async (queryType: string, offset?: number) => {
    const newOptions = {
      limit: 25,
      offset,
    };

    try {
      const channelQueryResponse = await client.queryChannels(
        channelFilters,
        sort || {},
        newOptions
      );

      const newChannels =
        queryType === "reload"
          ? channelQueryResponse
          : uniqBy([...channels, ...channelQueryResponse], "cid");

      setChannels(newChannels);
      setOffset(newChannels.length);
      setHasNextPage(channelQueryResponse.length >= newOptions.limit);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    queryChannels("reload", 0);
    setOffset(0);
  }, [filterString, sortString, searchValue]);

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <Search
        placeholder="Start typing"
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div
        id="forward-channel-search-list"
        className="t-overflow-y-auto t-h-[400px]"
      >
        <InfiniteScroll
          dataLength={channels.length}
          next={() => queryChannels("load_next", offset)}
          hasMore={hasNextPage}
          scrollableTarget="forward-channel-search-list"
          loader={
            <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
              Loading...
            </div>
          }
        >
          {channels.map((channel) => (
            <div className="t-flex" key={channel.id}>
              {channel.id && (
                <label
                  htmlFor={channel.id}
                  className="t-text-body t-flex t-items-start py-2 px-2 gap-2 hover:t-bg-surface-lighter-grey t-cursor-pointer"
                >
                  <div className="t-p-0.5">
                    <Checkbox
                      name={channel.id}
                      checked={selectedChannels.includes(channel.id)}
                      key={channel.id}
                      onChange={(e) => {
                        setChannelsSelected((s) => {
                          if (channel.id) {
                            const newChannels = new Set(s);

                            if (e.target.checked) {
                              newChannels.add(channel.id);
                              return Array.from(newChannels);
                            }

                            newChannels.delete(channel.id);
                            return Array.from(newChannels);
                          }

                          return s;
                        });
                      }}
                    />
                  </div>
                  <div className="t-space-x-1">
                    <span>{channel.data?.group_name as string}</span>
                    <span>{channel.data?.name}</span>
                    {channel.data?.name &&
                      Boolean(channel.data?.season as string) && (
                        <span className="t-text-neutral-0">
                          <DividerLine />
                        </span>
                      )}{" "}
                    <span>{channel.data?.season as string}</span>
                  </div>
                </label>
              )}
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export const ChannelForwardChannelSelector = ({
  filters,
}: {
  filters?: ChannelFilters<DefaultStreamChatGenerics>;
}) => {
  const isOpen = useAppSelector((store) => store.messageForward.isOpen);
  const dispatch = useAppDispatch();
  const { groupId: groupIdFromParams } = useParams<{ groupId?: string }>();
  const { isAdmin, isCpa } = useRoleBasedView();
  const isCrmView = Boolean(groupIdFromParams);
  const [forwardChannels, setForwardChannels] = useState<string[]>([]);
  const [forwardMessage, { isLoading }] = useForwardMessageMutation();
  const messageId = useAppSelector((store) => store.messageForward.messageId);

  const channelSortKey =
    (isAdmin || isCpa) && !isCrmView
      ? "last_non_broadcast_message_at"
      : "last_message_at";

  let sort: ChannelSort = { [channelSortKey]: -1 };

  const onForward = async ({ message }: { message: string }) => {
    if (messageId) {
      await forwardMessage({
        messageId: messageId,
        channelIds: forwardChannels,
        message,
      }).unwrap();
      onCancel();
    }
  };

  const onCancel = () => {
    dispatch(closeMessageForward());
    setForwardChannels([]);
  };

  return (
    <Modal.Root
      open={isOpen}
      onOpenChange={() => dispatch(closeMessageForward())}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Forward Message</Modal.Title>
          <Modal.Close />
        </Modal.Header>

        <Modal.Body>
          <ChannelSelector
            sort={sort}
            selectedChannels={forwardChannels}
            setChannelsSelected={setForwardChannels}
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-2">
            <Button onClick={onCancel}>Cancel</Button>
            <Modal.Root>
              <Modal.Trigger asChild>
                <Button customType="primary">Proceed</Button>
              </Modal.Trigger>
              <Formik
                initialValues={{
                  message: "",
                }}
                onSubmit={onForward}
              >
                {({ submitForm }) => (
                  <Modal.Content>
                    <Modal.Header>
                      <Modal.Title>Add message</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body>
                      <Form className="t-m-0">
                        <TextArea
                          name="message"
                          placeholder="Type your message here"
                        />
                      </Form>
                    </Modal.Body>

                    <Modal.Footer>
                      <div className="t-flex t-gap-2 t-justify-end">
                        <Modal.RawClose asChild>
                          <Button type="button">Cancel</Button>
                        </Modal.RawClose>
                        <Button
                          onClick={submitForm}
                          customType="primary"
                          isLoading={isLoading}
                          disabled={isLoading}
                        >
                          Forward
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal.Content>
                )}
              </Formik>
            </Modal.Root>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
