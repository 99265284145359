// Get countries data API call
const baseURL = process.env.REACT_APP_BASE_URL;

export const getCountriesData = () => {
  return fetch(`${baseURL}api/inkle/company/countries-data/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log("Error", error));
};
