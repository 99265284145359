import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import authContext from "jwt_context&axios/authContext";
import { useCallback, useContext } from "react";
import { ANALYTICS_EVENTS } from "types/contants/analyticsEvents";
import { Events } from "types/Models/analytics";
import { identify, track } from "utils/analytics";

// from: https://stackoverflow.com/a/68572792
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

type Values<T> = T[keyof T];

type AllOverloads = {
  [Prop in ANALYTICS_EVENTS]: Prop extends keyof Events
    ? (key: Prop, data: Events[Prop]) => void
    : (key: Prop) => void;
};

type PossibleEventsAndPayloads = UnionToIntersection<Values<AllOverloads>>;

export const useAnalytics = () => {
  const { authtoken } = useContext(authContext) || {};
  const { name: groupName, uuid } = useCurrentGroupContext();

  const {
    email,
    name,
    mobile,
    is_service_user,
    is_any_service_user,
    is_inkle_admin,
  } = authtoken || {};

  const identifyUser = useCallback(
    () =>
      identify({
        name,
        email,
        mobile,
        is_service_user,
        is_any_service_user,
        is_inkle_admin,
        groupName,
        group_id: uuid,
      }),
    [
      email,
      groupName,
      uuid,
      is_any_service_user,
      is_inkle_admin,
      is_service_user,
      mobile,
      name,
    ]
  );

  const trackEvent = useCallback<PossibleEventsAndPayloads>(
    (event, payload) => {
      track(event, { ...payload, company_group: groupName });
    },
    [groupName]
  );

  return {
    identifyUser,
    trackEvent,
  };
};
