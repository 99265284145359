import React from "react";
import classNames from "classnames";

const Head = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
) => (
  <thead
    {...props}
    className={
      "t-block t-rounded-t-lg t-bg-i-surface-grey t-text-subtitle-sm t-font-medium t-text-text-30 " +
        props.className || ""
    }
  />
);

const Row = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >
) => (
  <tr
    className="t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-2"
    {...props}
  />
);

const StyledRow = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >
) => (
  <tr
    className="t-flex t-cursor-pointer t-items-center t-justify-center t-gap-6 t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent t-px-4 t-py-2"
    {...props}
  />
);

const BodyWrapper = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
) => <div {...props} className={"t-overflow-auto " + props.className || ""} />;

const Body = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
) => <tbody className="all:unset t-w-full" {...props}></tbody>;

const Container = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
) => {
  return (
    <>
      <div
        {...props}
        className={classNames(
          "t-h-full t-overflow-x-auto t-rounded-lg t-border t-border-solid t-border-i-neutral-10",
          props.className
        )}
      />
    </>
  );
};

const Content = (
  props: React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
) => <table {...props} />;

const Table = {
  Container,
  Content,
  Body,
  BodyWrapper,
  Row,
  Head,
  StyledRow,
};

export default Table;
