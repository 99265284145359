import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { TICKET_TYPES, TICKET_SORT_OPTIONS } from "constants/ticket";
import SortFunnelIcon from "static/images/SortFunnel.svg";

export const TicketFilter = ({
  tags,
  tagsFilter,
  selectedTag,
  assigneeFilter,
  assignees,
  selectedAssignee,
  ticketTypeFilter,
  paginationData,
  goToFirstPage,
  goToPrevPage,
  goToNextPage,
  goToLastPage,
  onSortTabSelect,
  selectedTicketType,
  selectedSortOptionObj,
  resolvedStatus,
}) => {
  return (
    <div className="t-mt-4 t-ml-6 t-flex t-items-center t-gap-2 t-relative">
      <div>
        <Dropdown.Root>
          <Dropdown.Trigger className="all:unset ">
            <Button size="small">
              <div className="t-flex t-gap-1.5">
                <img src={SortFunnelIcon} alt="SortFunnelIcon" />
                <div className="t-text-text-60">
                  Sort: {selectedSortOptionObj.label}
                </div>
              </div>
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content sideOffset={8} align="end" className="t-p-1.5">
              <Dropdown.Item
                onSelect={(e) =>
                  onSortTabSelect({
                    e,
                    sort: TICKET_SORT_OPTIONS.TICKET_TYPE_LATEST_SORT,
                  })
                }
              >
                {TICKET_SORT_OPTIONS.TICKET_TYPE_LATEST_SORT.label}
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={(e) =>
                  onSortTabSelect({
                    e,
                    sort: TICKET_SORT_OPTIONS.TICKET_TYPE_OLDEST_SORT,
                  })
                }
              >
                {TICKET_SORT_OPTIONS.TICKET_TYPE_OLDEST_SORT.label}
              </Dropdown.Item>
              {resolvedStatus && (
                <>
                  <Dropdown.Item
                    onSelect={(e) =>
                      onSortTabSelect({
                        e,
                        sort: TICKET_SORT_OPTIONS.TICKET_TYPE_RESOLVED_LATEST_SORT,
                      })
                    }
                  >
                    {TICKET_SORT_OPTIONS.TICKET_TYPE_RESOLVED_LATEST_SORT.label}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={(e) =>
                      onSortTabSelect({
                        e,
                        sort: TICKET_SORT_OPTIONS.TICKET_TYPE_RESOLVED_OLDEST_SORT,
                      })
                    }
                  >
                    {TICKET_SORT_OPTIONS.TICKET_TYPE_RESOLVED_OLDEST_SORT.label}
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </div>
      <Filter.Root
        defaultValue="TAG"
        title="Filter"
        capsule={
          <>
            {selectedAssignee && selectedAssignee.length > 0 && (
              <Chip
                onClose={() => assigneeFilter([])}
                isActive
                filterType="ASSIGNEE"
              >
                Assignees ({selectedAssignee.split(",").length})
              </Chip>
            )}

            {selectedTag && selectedTag.length > 0 && (
              <Chip onClose={() => tagsFilter([])} isActive filterType="TAG">
                Tags ({selectedTag.split(",").length})
              </Chip>
            )}
            {selectedTicketType && selectedTicketType.length > 0 && (
              <Chip
                onClose={() => ticketTypeFilter([])}
                isActive
                filterType="TICKET_TYPE"
              >
                Ticket type ({selectedTicketType.split(",").length})
              </Chip>
            )}
          </>
        }
      >
        <Filter.Portal>
          <Filter.List>
            <Filter.ListItem value="TAG">Tag</Filter.ListItem>
            <Filter.ListItem value="ASSIGNEE">Assignee</Filter.ListItem>
            <Filter.ListItem value="TICKET_TYPE">Type</Filter.ListItem>
          </Filter.List>
          <Filter.Body value="TAG" block>
            {tags && (
              <MultiSelectFilter
                onChange={tagsFilter}
                options={tags.map((tag) => ({
                  value: tag.uuid,
                  label: tag.title,
                }))}
                selected={selectedTag?.split(",")}
              />
            )}
          </Filter.Body>
          <Filter.Body value="ASSIGNEE" block>
            {tags && (
              <MultiSelectFilter
                onChange={assigneeFilter}
                options={assignees.map((assignee) => ({
                  value: assignee.uuid,
                  label: assignee.name,
                }))}
                selected={selectedAssignee?.split(",")}
              />
            )}
          </Filter.Body>
          <Filter.Body value="TICKET_TYPE" block>
            {
              <MultiSelectFilter
                onChange={ticketTypeFilter}
                options={TICKET_TYPES.map((ticket_type) => ({
                  value: ticket_type.value,
                  label: ticket_type.label,
                }))}
                selected={selectedTicketType?.split(",")}
              />
            }
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>

      <div className="t-right-10 t-absolute t-text-subtitle t-font-bold t-text-text-100">
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
    </div>
  );
};
