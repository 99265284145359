export const NoGroupRaiseProfile = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="50.5"
      cy="50"
      r="49.6604"
      fill="#F6F6F6"
      stroke="#E7E6EB"
      strokeWidth="0.679099"
    />
    <circle
      cx="50.5004"
      cy="49.999"
      r="41.3079"
      fill="#EDEDED"
      stroke="#E7E6EB"
      strokeWidth="0.679099"
    />
    <g clip-path="url(#clip0_192_17182)">
      <path
        d="M61.8429 48.1791C61.8429 50.3813 61.1899 52.534 59.9664 54.3651C58.7429 56.1961 57.004 57.6233 54.9694 58.466C52.9348 59.3088 50.6961 59.5293 48.5362 59.0996C46.3763 58.67 44.3924 57.6095 42.8352 56.0524C41.278 54.4952 40.2175 52.5112 39.7879 50.3513C39.3583 48.1915 39.5788 45.9527 40.4215 43.9181C41.2643 41.8836 42.6914 40.1446 44.5224 38.9211C46.3535 37.6977 48.5062 37.0447 50.7084 37.0447C53.6604 37.048 56.4906 38.2222 58.578 40.3096C60.6654 42.3969 61.8395 45.2271 61.8429 48.1791ZM77.0262 50.2036C77.0262 55.4087 75.4827 60.497 72.5909 64.825C69.699 69.1529 65.5888 72.5261 60.7798 74.5181C55.9709 76.51 50.6792 77.0312 45.5741 76.0157C40.4689 75.0002 35.7795 72.4937 32.0989 68.8131C28.4183 65.1325 25.9118 60.4431 24.8963 55.3379C23.8808 50.2328 24.402 44.9411 26.3939 40.1322C28.3859 35.3232 31.7591 31.2129 36.087 28.3211C40.415 25.4293 45.5033 23.8857 50.7084 23.8857C57.6861 23.8931 64.3758 26.6682 69.3098 31.6022C74.2437 36.5361 77.0189 43.2259 77.0262 50.2036ZM72.9773 50.2036C72.9741 47.2062 72.3664 44.2403 71.1906 41.4831C70.0148 38.726 68.2951 36.2343 66.1342 34.1571C63.9734 32.0799 61.4158 30.4598 58.6144 29.3937C55.8131 28.3275 52.8255 27.8373 49.8303 27.9524C37.9114 28.4129 28.4066 38.3403 28.4395 50.2668C28.4509 55.6962 30.4534 60.9328 34.0675 64.9846C35.5393 62.8498 37.4089 61.0188 39.574 59.5919C39.7586 59.47 39.978 59.4119 40.1987 59.4266C40.4195 59.4412 40.6293 59.5277 40.7962 59.6729C43.5474 62.0525 47.0633 63.3621 50.7008 63.3621C54.3383 63.3621 57.8543 62.0525 60.6054 59.6729C60.7723 59.5277 60.9822 59.4412 61.2029 59.4266C61.4237 59.4119 61.6431 59.47 61.8277 59.5919C63.9955 61.0181 65.8677 62.8491 67.3418 64.9846C70.9737 60.918 72.9801 55.6559 72.9773 50.2036Z"
        fill="#CFCDD6"
      />
    </g>
    <defs>
      <clipPath id="clip0_192_17182">
        <rect
          width="64.7823"
          height="64.7823"
          fill="white"
          transform="translate(18.3162 17.8154)"
        />
      </clipPath>
    </defs>
  </svg>
);
