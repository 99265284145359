import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { Slider } from "components/DesignSystem/Slider/Slider";
import Tab from "components/DesignSystem/Tab/Tab";
import { MessageBubbleUI } from "components/MessageBubble/MessageBubble";
import DashboardContainer from "components/dashboard/DashboardContainer";
import Loader from "components/design/loader";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { Cross } from "components/icons/Cross";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { setMediaSliderActive } from "store/slices/channelInfoSlider";
import { setPreviewFiles } from "store/slices/chat";
import { RootState } from "store/store";
import { Attachment } from "stream-chat";
import {
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { groupMessageAttatchmentsByMonth } from "./utils/groupMessageAttatchmentsByMonth";
import { groupMessageFileByMonth } from "./utils/groupMessageFileByMonth";
import { EmptyMediaSlider } from "components/TPPayments/Illustration/EmptyMediaSlider";
import { EmptyDocsSlider } from "components/TPPayments/Illustration/EmptyDocsSlider";

const MediaContent = () => {
  const { channel } = useChannelStateContext();
  const dispatch = useDispatch();
  const { client } = useChatContext();

  const {
    messages: messagesWithImages,
    loadNextPage,
    next,
    loading,
  } = usePaginatedMessages(
    client,
    {
      members: { $in: [client.userID!] },
      id: channel.id,
    },
    {},
    { limit: 30, sort: [{ created_at: -1 }] },
    {
      "attachments.type": { $in: ["image", "video"] },
    }
  );

  const messagesByMonth = groupMessageAttatchmentsByMonth(messagesWithImages);

  const { jumpToMessage } =
    useChannelActionContext<DefaultStreamChatGenerics>("MessageActionsBox");

  if (loading) {
    return <Loader />;
  }

  if (messagesWithImages.length === 0) {
    return (
      <div className="t-h-full t-w-full t-flex t-items-center t-flex-col t-justify-center t-text-text-30 t-gap-4 t-text-body">
        <EmptyMediaSlider />
        <p>No media files </p>
      </div>
    );
  }

  const onMessageClick = (messageId: string, image: Attachment) => () => {
    dispatch(setPreviewFiles({ files: [image] }));
    jumpToMessage(messageId);
  };

  return (
    <InfiniteScroll
      dataLength={messagesWithImages.length}
      next={loadNextPage}
      hasMore={Boolean(next)}
      scrollThreshold={0.6}
      scrollableTarget="media-scroll"
      loader={
        <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
          Loading...
        </div>
      }
    >
      <div className="t-overflow-hidden t-flex t-flex-col t-gap-3">
        {messagesByMonth.map(([name, attachments]) => (
          <div key={name}>
            <p className="t-mb-2 t-text-overline t-uppercase t-text-text-30">
              {name}
            </p>
            <div className="t-grid t-grid-cols-4 t-gap-3">
              {attachments.map((image) => (
                <img
                  onClick={onMessageClick(image.messageId, image)}
                  key={image.image_url}
                  loading="lazy"
                  src={image?.image_url}
                  className="t-h-16 t-w-16 t-object-cover t-rounded t-cursor-pointer t-border t-border-neutral-0 t-border-solid"
                  alt={image?.fallback}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </InfiniteScroll>
  );
};

const DocsContent = () => {
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();

  const {
    messages: messagesWithFiles,
    loadNextPage,
    next,
    loading,
  } = usePaginatedMessages(
    client,
    {
      members: { $in: [client.userID!] },
      id: channel.id,
    },
    {},
    { limit: 30, sort: [{ created_at: -1 }] },
    {
      "attachments.type": { $in: ["file"] },
    }
  );

  const messagesByMonth = groupMessageFileByMonth(messagesWithFiles);

  const { jumpToMessage } =
    useChannelActionContext<DefaultStreamChatGenerics>("MessageActionsBox");

  if (loading) {
    return <Loader />;
  }

  if (messagesWithFiles.length === 0) {
    return (
      <div className="t-h-full t-w-full t-flex t-items-center t-flex-col t-justify-center t-text-text-30 t-gap-4 t-text-body">
        <EmptyDocsSlider />
        <p>No documents</p>
      </div>
    );
  }

  const onMessageClick = (messageId: string) => () => {
    jumpToMessage(messageId);
  };

  return (
    <InfiniteScroll
      dataLength={messagesWithFiles.length}
      next={loadNextPage}
      hasMore={Boolean(next)}
      scrollThreshold={0.6}
      scrollableTarget="media-scroll"
      loader={
        <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
          Loading...
        </div>
      }
    >
      <div className="t-overflow-hidden t-flex t-flex-col t-gap-3">
        {messagesByMonth.map(([name, messages]) => {
          return (
            <div key={name}>
              <p className="t-mb-2 t-text-text-30 t-text-overline t-uppercase">
                {name}
              </p>
              {messages.map((message, index) => {
                const sentByUser = message.user?.id === client.userID;

                return (
                  <div
                    key={message.id}
                    onClick={onMessageClick(message.id)}
                    className={classNames("t-cursor-pointer", {
                      "t-group/single": messages.length === 1,
                      "t-group/bottom": messages.length - 1 === index,
                      "t-group/top": messages.length > 1 && index === 0,
                    })}
                  >
                    <MessageBubbleUI
                      hideOptions
                      theme="light"
                      otherData={{ sentByTheUser: sentByUser }}
                      message={message}
                      jumpToMessage={jumpToMessage}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export const ChannelMediaSlider = () => {
  const dispatch = useDispatch();
  const sliderOpen = useSelector(
    (store: RootState) => store.channelInfoSlider.isMediaSliderActive
  );

  const onOutslideSliderClick = () => {
    if (sliderOpen) {
      dispatch(setMediaSliderActive(false));
    }
  };

  return (
    <Slider
      open={sliderOpen}
      className="t-absolute t-w-[360px] !t-p-0 t-shadow-slider !t-h-full t-z-10"
      position="right"
      width={360}
    >
      <Tab.Root defaultValue="MEDIA" className="t-h-full">
        <DashboardContainer className="t-h-full">
          <DashboardContainer.Header>
            <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center ">
              <div className="t-flex t-gap-2 t-items-center">
                <Button
                  customType="ghost_icon"
                  size="small"
                  onClick={onOutslideSliderClick}
                >
                  <span className="t-text-text-30">
                    <ArrowLeft size="16" />
                  </span>
                </Button>
                <span className="t-text-subtitle">Media and Documents</span>
              </div>
              <Button
                customType="ghost_icon"
                size="small"
                onClick={onOutslideSliderClick}
              >
                <Cross />
              </Button>
            </div>

            <div className="t-flex t-flex-col t-gap-4 t-px-5 t-pt-0 t-border-b t-border-0 t-border-solid t-border-i-neutral-10">
              <Tab.List className="t-flex t-gap-2">
                <Tab.Trigger value="MEDIA">
                  <span className="t-text-body">Media</span>
                </Tab.Trigger>
                <Tab.Trigger value="DOCS">
                  <span className="t-text-body">Documents</span>
                </Tab.Trigger>
              </Tab.List>
            </div>
          </DashboardContainer.Header>
          <DashboardContainer.Content
            className="t-p-5 t-pt-4"
            id="media-scroll"
          >
            <div className="t-flex t-flex-col t-gap-4 t-h-full">
              <div className="t-h-full">
                <Tab.Content value="MEDIA" className="t-h-full">
                  <MediaContent />
                </Tab.Content>
                <Tab.Content value="DOCS" className="t-h-full">
                  <DocsContent />
                </Tab.Content>
              </div>
            </div>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </Slider>
  );
};
