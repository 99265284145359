import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

import { FC } from "react";
type IBalancingPaymentSuccessProps = {
  show: boolean;
  closeModal: () => void;
};

export const BalancingPaymentSuccess: FC<IBalancingPaymentSuccessProps> = ({
  show,
  closeModal,
}) => {
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Congratulations!</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-30 t-mb-8 t-text-center">
            Congratulations! Your filing has been successfully completed. Admin
            will close the filing for you
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end">
            <Button customType="primary" onClick={closeModal}>
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
