import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { PERK_PROVIDER } from "constants/perks";
import { Rewards } from "pages/Refer/Rewards";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Perks } from "./Perks";
import { DashboardLayout } from "components/DashboardLayout";

const PerksTab = () => {
  const { path, url } = useRouteMatch();

  return (
    <DashboardLayout header={<Header v2 title="Perks" />}>
      <Perks />
    </DashboardLayout>
  );
};

export default PerksTab;
