import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const emptyTemplate = {
  title: "",
  uuid: "",
  approx_price: "",
  description: "",
  seasons: [],
  default_task_template: "",
};

export type TemplateType = {
  title: string;
  uuid: string;
  approx_price: string;
  description: string;
  seasons: string[];
  default_task_template: string;
};

export type IntragroupListState = {
  intragroupTaskCounts: {
    ongoing_count: number;
    count: number;
    completed_count: number;
  };
  selectedIntragroupTaskTemplate: TemplateType;
  showIntragroupTaskLaunchModal: boolean;
  callbackUrlOnModalClose?: string;
};

const initialState: IntragroupListState = {
  intragroupTaskCounts: {
    ongoing_count: 0,
    count: 0,
    completed_count: 0,
  },
  selectedIntragroupTaskTemplate: emptyTemplate,
  showIntragroupTaskLaunchModal: false,
  callbackUrlOnModalClose: undefined,
};

export const intragroupList = createSlice({
  name: "intragroupList",
  initialState,
  reducers: {
    setIntragroupTaskCount: (
      state,
      action: PayloadAction<IntragroupListState["intragroupTaskCounts"]>
    ) => {
      state.intragroupTaskCounts = action.payload;
    },
    openIntragroupTask: (state, action: PayloadAction<TemplateType>) => {
      state.selectedIntragroupTaskTemplate = action.payload;
      state.showIntragroupTaskLaunchModal = true;
    },
    closeIntragroupTask: (state) => {
      state.selectedIntragroupTaskTemplate = emptyTemplate;
      state.showIntragroupTaskLaunchModal = false;
      state.callbackUrlOnModalClose = undefined;
    },
    setCallbackUrlOnModalClose: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.callbackUrlOnModalClose = action.payload;
    },
  },
});

export const {
  setIntragroupTaskCount,
  openIntragroupTask,
  closeIntragroupTask,
  setCallbackUrlOnModalClose,
} = intragroupList.actions;

export default intragroupList.reducer;
