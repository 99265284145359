import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Seperator } from "components/icons/Chat/Seperator";
import dayjs from "dayjs";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import CloseArrow from "static/images/CloseArrow.svg";
import StepCheckMark from "static/images/StepCheckMark.svg";
import TPStepEllipse from "static/images/TPStepEllipse.svg";
import {
  useGetTPAccountInfoQuery,
  useMarkTransferInitiatedMutation,
} from "store/apis/transferPricing";
import { FileIcon } from "utils/fileTypeIcon";
import { formatDate } from "utils/formatDate";
import { PaymentInitiateModal } from "./PaymentInitiateModal";

type TPSliderProps = {
  setOpenSlider: (v: boolean) => void;
  selectedRowData: any;
  setInstructionsModal: (newState: React.SetStateAction<boolean>) => void;
};

const TPSlider = ({
  setOpenSlider,
  selectedRowData,
  setInstructionsModal,
}: TPSliderProps) => {
  const { alertToast } = useToast();
  const openPreview = useDocPreview();
  const { uuid: groupId } = useCurrentGroupContext();
  const [openInitiatPaymentModal, setOpenInitiatePaymentModal] =
    useState(false);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);

  const [markTransferInitiated, { isLoading: isInitiatedLoading }] =
    useMarkTransferInitiatedMutation();

  const { data: accountInfo } = useGetTPAccountInfoQuery(
    { groupId },
    { skip: !groupId }
  );

  const {
    amount: { currency: netAmountCurrency, amount: netAmount },
    purpose_code: { uuid: purpose_code_uuid },
    initiated_date,
    received_datetime,
    settled_datetime,
    processing_datetime,
    settled_amount,
    payment_advice,
    inkle_rate,
    mid_market_rate,
    status,
    uuid,
    statement_descriptor,
  } = selectedRowData;

  const initiatedStep = Boolean(
    initiated_date ||
      received_datetime ||
      processing_datetime ||
      settled_datetime
  );

  const recievedInVACStep = Boolean(
    received_datetime || processing_datetime || settled_datetime
  );

  const { receiver_bank_account, tos_accepted } = accountInfo || {};

  const isSettled: boolean =
    settled_datetime &&
    status === "Settled" &&
    new Date(settled_datetime) <= new Date();

  const handleInitiatedPayment = async () => {
    try {
      await markTransferInitiated({
        groupId,
        tpPaymentId: uuid,
        payLoad: {
          amount: netAmount,
          currency: netAmountCurrency,
          purpose_code_uuid: purpose_code_uuid,
        },
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      setIsCheckBoxSelected(false);
    }
    setOpenInitiatePaymentModal(false);
  };

  const handleCheckBoxClose = () => {
    setOpenInitiatePaymentModal(false);
    setIsCheckBoxSelected(false);
  };

  const handleCheckBoxChange = () => {
    setOpenInitiatePaymentModal(true);
    setIsCheckBoxSelected(true);
  };

  return (
    <>
      <div className="t-flex t-flex-col t-gap-6">
        <div className="t-flex t-justify-between">
          <div className="t-flex t-flex-col">
            <div className="t-pl-1 t-text-subtitle">
              <AmountSuperScript
                amount={netAmount}
                currencyCode={netAmountCurrency}
              />
            </div>
            <div className="t-pl-1 t-text-body-sm">TP Payments</div>
          </div>
          <div className="t-flex t-gap-2">
            <Button
              customType="icon"
              size="small"
              onClick={() => setOpenSlider(false)}
            >
              <img src={CloseArrow} alt="Close" />
            </Button>
          </div>
        </div>
        {!initiatedStep ? (
          <div className="t-ml-1 t-w-full t-flex t-rounded t-border t-border-solid t-border-purple-30 t-p-2 t-gap-2 t-items-center">
            <Checkbox
              label="Select if you initiated payment"
              name="isPaymentInitiated"
              onChange={handleCheckBoxChange}
              checked={isCheckBoxSelected}
              disabled={!tos_accepted}
            />
            <Seperator />
            <Button
              size="small"
              customType="text"
              onClick={() => setInstructionsModal(true)}
            >
              Transfer Details
            </Button>
          </div>
        ) : (
          <div className="t-flex t-justify-start">
            <Button
              size="small"
              customType="text"
              onClick={() => setInstructionsModal(true)}
            >
              Transfer Details
            </Button>
          </div>
        )}
        <div>
          <div className="t-pl-1 t-text-overline">STATUS</div>
          <div className="t-flex t-flex-col t-pl-1">
            <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
              <div>
                {initiatedStep ? (
                  <img
                    src={StepCheckMark}
                    width="14px"
                    height="14px"
                    alt="checked"
                  />
                ) : (
                  <img
                    src={TPStepEllipse}
                    width="14px"
                    height="14px"
                    alt="notchecked"
                  />
                )}
              </div>
              <div>Payment initiated to VAC</div>
            </div>
            <div
              className={classNames(
                "t-ml-1.5 t-h-[56px] t-border t-border-b-0 t-border-l-2 t-border-r-0 t-border-t-0 t-border-solid t-pl-[22px] t-text-body-sm t-text-text-100",
                {
                  "t-border-purple-20": !initiatedStep,
                },
                {
                  "t-border-purple-50": initiatedStep,
                }
              )}
            >
              {initiated_date && <div>{formatDate(initiated_date)}</div>}
            </div>
          </div>
          <div className="t-flex t-flex-col t-pl-1">
            <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
              <div>
                {recievedInVACStep ? (
                  <img
                    src={StepCheckMark}
                    width="14px"
                    height="14px"
                    alt="checked"
                  />
                ) : (
                  <img
                    src={TPStepEllipse}
                    width="14px"
                    height="14px"
                    alt="notchecked"
                  />
                )}
              </div>
              <div className="t-flex t-gap-1">
                {recievedInVACStep ? (
                  <AmountSuperScript
                    amount={netAmount}
                    currencyCode={netAmountCurrency}
                  />
                ) : (
                  "Amount "
                )}
                <div>received in VAC</div>
              </div>
            </div>
            <div
              className={classNames(
                "t-ml-1.5 t-h-[56px] t-border t-border-b-0 t-border-l-2 t-border-r-0 t-border-t-0 t-border-solid t-pl-[22px] t-text-body-sm t-text-text-100",
                {
                  "t-border-purple-20": !recievedInVACStep,
                },
                {
                  "t-border-purple-50": recievedInVACStep,
                }
              )}
            >
              {received_datetime && (
                <div className="t-flex t-gap-2">
                  <div>{formatDate(received_datetime)}</div>
                  <div>{dayjs(received_datetime).format("LT")}</div>
                </div>
              )}
            </div>
          </div>
          <div className="t-flex t-flex-col t-pl-1">
            <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
              <div>
                {processing_datetime ? (
                  <img
                    src={StepCheckMark}
                    width="14px"
                    height="14px"
                    alt="checked"
                  />
                ) : (
                  <img
                    src={TPStepEllipse}
                    width="14px"
                    height="14px"
                    alt="notchecked"
                  />
                )}
              </div>
              <div>
                <div className="t-flex t-gap-1">
                  Amount converted
                  {inkle_rate ? (
                    <div className="t-flex t-gap-1">
                      <div>at</div>
                      <ToolTip
                        text={
                          <div className="t-flex t-flex-col t-items-center">
                            <div className="t-flex t-w-[160px] t-justify-between">
                              <span>Mid Market Rate:</span> {mid_market_rate}
                            </div>

                            <div className="t-flex t-w-full t-justify-between">
                              <span>Inkle Rate:</span> {inkle_rate}
                            </div>
                          </div>
                        }
                      >
                        <div className="t-underline">{` ${inkle_rate} USD/INR`}</div>
                      </ToolTip>
                    </div>
                  ) : (
                    " to INR"
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                "t-ml-1.5 t-h-[56px] t-border t-border-b-0 t-border-l-2 t-border-r-0 t-border-t-0 t-border-solid t-pl-[22px] t-text-body-sm t-text-text-100",
                {
                  "t-border-purple-20": !Boolean(processing_datetime),
                },
                { "t-border-purple-50": Boolean(processing_datetime) }
              )}
            >
              {processing_datetime && (
                <div className="t-flex t-gap-2">
                  <div>{formatDate(processing_datetime)}</div>
                  <div>{dayjs(processing_datetime).format("LT")}</div>
                </div>
              )}
            </div>
          </div>
          <div className="t-flex t-flex-col t-pl-1">
            <div className="t-flex t-gap-4 t-text-subtext t-text-text-100">
              <div>
                {isSettled ? (
                  <img
                    src={StepCheckMark}
                    width="14px"
                    height="14px"
                    alt="checked"
                  />
                ) : (
                  <img
                    src={TPStepEllipse}
                    width="14px"
                    height="14px"
                    alt="notchecked"
                  />
                )}
              </div>
              <div className="t-flex t-gap-1">
                {settled_amount ? (
                  <AmountSuperScript
                    amount={settled_amount.amount}
                    currencyCode={settled_amount.currency}
                  />
                ) : (
                  "Amount "
                )}
                received in {receiver_bank_account?.bank_name.slice(0, 4)} -
                &nbsp;
                {receiver_bank_account?.account_number.slice(-4)}
              </div>
            </div>
            <div className="t-pl-[22px]  t-text-body-sm t-text-text-100">
              {settled_datetime && (
                <div className="t-ml-2 t-flex t-gap-2">
                  <div className="t-border t-border-r-4 t-border-neutral-20">
                    {new Date(settled_datetime) > new Date()
                      ? "Expected at "
                      : ""}
                    {formatDate(settled_datetime)}
                  </div>
                  <div>{dayjs(settled_datetime).format("LT")}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="t-pl-3">
          {statement_descriptor && (
            <>
              <div className="t-text-overline">DESCRIPTION</div>
              <div className="t-text-body">{statement_descriptor}</div>
            </>
          )}
        </div>

        {initiatedStep && (
          <div className="t-pl-3">
            <div className="t-mb-2 t-text-overline">ATTACHMENTS</div>
            {payment_advice ? (
              <div className="t-gap-1 t-rounded t-border t-border-solid t-border-neutral-10 t-p-1">
                <Button
                  customType="ghost"
                  onClick={() => {
                    openPreview(payment_advice?.uuid);
                  }}
                >
                  <FileIcon fileType={"PDF"} width="32px" height="32px" />
                  <ToolTip text={payment_advice?.name}>
                    <div className="t-max-w-[320px] t-truncate t-pl-2 t-text-body">
                      {payment_advice?.name}
                    </div>
                  </ToolTip>
                </Button>
              </div>
            ) : (
              <div className="t-px-2 t-py-1.5 t-bg-surface-lighter-grey t-text-text-60 t-text-body-sm">
                Foreign Inward Remittance Advice (FIRA) will appear here within
                24 hours of settlement
              </div>
            )}
          </div>
        )}

        {/* 
        ATTACHMENTS- will do once api is ready
        div className="t-pl-3">
          <div className="t-text-overline">ATTACHMENTS</div>
          <Formik onSubmit={() => {}} initialValues={{}}>
            {({ isSubmitting, values }) => (
              <Form className="t-flext-flex-col t-m-0 t-gap-3 t-pt-2">
                <div>
                  <DropFileInput
                    name="file"
                    width={336}
                    isUploading={false}
                    uploaded={true}
                    onDelete={() => {}}
                    onDrop={onDrop}
                    label="Add Attachment"
                    isDeleting={false}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div> */}
      </div>
      <PaymentInitiateModal
        isOpen={openInitiatPaymentModal}
        close={() => setOpenInitiatePaymentModal(false)}
        tpPaymentId={uuid}
        amount={netAmount}
        currency={netAmountCurrency}
        purposeCodeUuid={purpose_code_uuid}
        isCheckBoxSelected={isCheckBoxSelected}
        setIsCheckBoxSelected={setIsCheckBoxSelected}
      />
    </>
  );
};

export default TPSlider;
