import { LoadingToast } from "components/design/LoadingToast";
import ContextMenu from "components/DesignSystem/ContextMenu/ContextMenu";
import { AddFileIcon } from "components/icons/AddFile";
import { UploadFileIcon } from "components/icons/uploadFile";
import {
  FILE_UPLOADING_MESSAGE,
  FILE_UPLOAD_MESSAGE,
  NEW_FOLDER_MESSAGE,
} from "constants/documents";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React, { ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import {
  useCreateFolderMutation,
  useUploadFileMutation,
} from "store/apis/documents";
import { BackendError } from "types/utils/error";
import { AddNewFolder } from "./documentsModal/AddNewFolder";

const RightClickMenu = ({
  children,
  refetch,
}: {
  children: ReactNode;
  refetch: () => void;
}) => {
  const query = useQuery();
  const searchTerm = query.get("search");
  const { alertToast, successToast } = useToast();
  const [uploadFiles, { isLoading }] = useUploadFileMutation();
  const [createFolder] = useCreateFolderMutation();
  const { uuid: groupId } = useCurrentGroupContext();
  const { docId } = useParams<{ docId: string }>();

  const onDrop = async (acceptedFiles: File[]) => {
    try {
      await uploadFiles({
        files: acceptedFiles,
        groupId,
        parentFolderId: docId,
      }).unwrap();
      successToast({ message: FILE_UPLOAD_MESSAGE });
      refetch();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const { isOpen, close, open: openModal } = useModal();

  const onCreateFolder = async ({ folder_name }: { folder_name: string }) => {
    try {
      await createFolder({
        folder_name,
        groupId,
        parentFolderId: docId,
      }).unwrap();
      successToast({ message: NEW_FOLDER_MESSAGE });
      close();
      refetch();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <>
      <ContextMenu.Root modal={false}>
        <ContextMenu.Trigger disabled={Boolean(searchTerm)}>
          {children}
        </ContextMenu.Trigger>
        <ContextMenu.Portal>
          <ContextMenu.Content>
            <ContextMenu.Item
              className="hover:t-text-purple data-[disabled]:t-text-neutral-30"
              onSelect={open}
            >
              <div
                {...getRootProps()}
                className="t-flex t-items-center t-gap-2"
              >
                <input {...getInputProps()} />
                <UploadFileIcon color="currentColor" />
                Upload File
              </div>
            </ContextMenu.Item>
            <ContextMenu.Item
              className="t-flex t-items-center t-gap-2 hover:t-text-purple data-[disabled]:t-text-neutral-30"
              onSelect={openModal}
            >
              <AddFileIcon color="currentColor" />
              New Folder
            </ContextMenu.Item>
          </ContextMenu.Content>
        </ContextMenu.Portal>
      </ContextMenu.Root>
      {isLoading && (
        <LoadingToast loading={isLoading}>
          {FILE_UPLOADING_MESSAGE}
        </LoadingToast>
      )}
      <AddNewFolder
        isOpen={isOpen}
        close={close}
        createFolder={onCreateFolder}
      />
    </>
  );
};

export default RightClickMenu;
