import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface fixedAssetState {
  isSliderOpen: boolean;
  previewFixedAssetId: string;
  allFixedAssetIds: string[];
}

const initialState: fixedAssetState = {
  isSliderOpen: false,
  previewFixedAssetId: "",
  allFixedAssetIds: [],
};

export const fixedAsset = createSlice({
  name: "fixedAsset",
  initialState,
  reducers: {
    openSlider: (state, action: PayloadAction<string>) => {
      state.previewFixedAssetId = action.payload;
      state.isSliderOpen = true;
    },

    closeSlider: (state) => {
      state.isSliderOpen = false;
      state.previewFixedAssetId = "";
    },

    setAllFixedAssetIds: (state, action: PayloadAction<string[]>) => {
      state.allFixedAssetIds = action.payload;
    },
  },
});

export const { openSlider, closeSlider, setAllFixedAssetIds } =
  fixedAsset.actions;

export default fixedAsset.reducer;
