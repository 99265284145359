import { useState } from "react";
export type ModalClose = (
  // Boolean added just because we call close on onOpenChangeEvent for Modal component
  arg?: boolean | (Partial<React.MouseEvent> & { takeConfirmation?: boolean })
) => void;

export const useModal = (defaultValue: boolean = false) => {
  const [isOpen, setOpenState] = useState(defaultValue);
  const [showConfirmation, setShowConfirmation] = useState(false);

  return {
    isOpen,
    showConfirmation,
    open: () => setOpenState(true),

    dismissConfirmation: () => {
      setShowConfirmation(false);
    },

    close: ((option) => {
      if (typeof option === "object" && option?.takeConfirmation) {
        return setShowConfirmation(true);
      }

      setShowConfirmation(false);
      setOpenState(false);
    }) as ModalClose,

    toggle: ((option) => {
      if (!isOpen && typeof option === "object" && option?.takeConfirmation) {
        return setShowConfirmation(true);
      }

      setOpenState((o) => !o);
    }) as ModalClose,
  };
};
