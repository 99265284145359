import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode } from "react";
import Globe from "static/images/Globe.svg";
import LinkedInLogo from "static/images/LinkedInLogo.svg";
import {
  useGetGroupsRaiseProfileQuery,
  useGetRaiseProfilesQuery,
} from "store/apis/raiseProfiles";

const FounderItem = ({
  name,
  linkedIn,
  email,
}: {
  name: string;
  linkedIn: string;
  email: string;
}) => {
  const { isInvestor } = useRoleBasedView();

  return (
    <div className="t-flex t-justify-between t-items-center">
      <div
        className="t-flex t-gap-3 t-items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <ConditionalLink to={linkedIn}>
          <img src={LinkedInLogo} alt="In" />
        </ConditionalLink>
        <div className="t-text-body">{name}</div>
      </div>
      {!isInvestor && (
        <ConditionalLink
          to={`mailto:${email}`}
          className="t-text-purple t-text-body"
        >
          {email}
        </ConditionalLink>
      )}
    </div>
  );
};

export const RaiseSliderItem = ({
  children,
  label,
  className,
}: {
  children: ReactNode;
  label: ReactNode;
  className?: string;
}) => {
  return (
    <div className={className}>
      <div className="t-text-body-sm t-text-text-30">{label}</div>
      <div className="t-text-body">{children || "-"}</div>
    </div>
  );
};

export const RaiseSliderCard = ({
  title,
  children,
  column = true,
}: {
  title: string | ReactNode;
  children: ReactNode;
  column?: boolean;
}) => {
  return (
    <div className="t-border-solid t-border t-border-neutral-10 t-rounded-lg">
      <div className="t-p-4 t-border-b t-border-neutral-10 t-border-solid t-border-0">
        {title}
      </div>
      <div
        className={classNames(
          "t-justify-between t-gap-6 t-p-4 t-flex t-flex-col"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const RaiseProfileInfo = ({
  currentIndex,
  children,
}: {
  currentIndex: number | null;
  children?: ReactNode;
}) => {
  const { isInvestor, isCustomer } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();

  const { data: intros, isLoading, isSuccess } = useGetRaiseProfilesQuery();

  const { data } = useGetGroupsRaiseProfileQuery(
    {
      groupId,
    },
    { skip: !groupId }
  );

  const raiseProfile = currentIndex !== null ? intros?.[currentIndex] : data;

  if (!raiseProfile?.founder || !raiseProfile?.company_group) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="t-flex t-flex-col t-gap-6 t-p-5 t-pb-0">
      <RaiseSliderCard title="Startup details">
        <RaiseSliderItem label="Brand name" className="t-col-span-2  ">
          <div className="t-flex t-gap-2 t-items-center">
            <div
              className="t-flex t-gap-2"
              onClick={(e) => e.stopPropagation()}
            >
              <ConditionalLink
                to={raiseProfile?.company_group?.group_linkedIn_url}
              >
                <img src={LinkedInLogo} alt="In" />
              </ConditionalLink>
              <ConditionalLink to={raiseProfile?.company_group?.group_website}>
                <img src={Globe} alt="website" />
              </ConditionalLink>
            </div>
            <div className="t-text-body">
              {raiseProfile?.company_group?.name}
            </div>
          </div>
        </RaiseSliderItem>
        <RaiseSliderItem
          className="t-col-span-2"
          label={`Startup description ${
            !isInvestor ? ` (max 150 characters)` : ""
          }`}
        >
          {raiseProfile?.company_group.description}
        </RaiseSliderItem>

        <RaiseSliderItem
          className="t-col-span-2"
          label={`Startup pitch ${!isInvestor ? `(max 150 words)` : ""}`}
        >
          {raiseProfile?.company_group.company_pitch}
        </RaiseSliderItem>
        <RaiseSliderItem className="t-col-span-2" label="Founded year">
          {raiseProfile?.details_for_investors?.year_founded}
        </RaiseSliderItem>
      </RaiseSliderCard>

      <RaiseSliderCard title="Founder details">
        <div className="t-flex t-flex-col t-gap-4">
          {raiseProfile?.details_for_investors?.cofounder_data?.map(
            ({
              cofounder_email,
              cofounder_linkedIn_url,
              cofounder_name,
              id,
            }) => (
              <FounderItem
                key={id}
                name={cofounder_name}
                email={cofounder_email}
                linkedIn={cofounder_linkedIn_url}
              />
            )
          )}
        </div>
      </RaiseSliderCard>

      {raiseProfile?.details_for_investors && (
        <RaiseSliderCard title="Other details">
          <RaiseSliderItem label="Raised till date">
            {!isNaN(
              Number(raiseProfile?.details_for_investors.raised_till_date)
            ) ? (
              <AmountSuperScript
                currencyCode="USD"
                amount={Number(
                  raiseProfile?.details_for_investors.raised_till_date
                )}
                noDecimal={true}
              />
            ) : (
              ""
            )}
          </RaiseSliderItem>
          <RaiseSliderItem label="Type of next round">
            {raiseProfile?.details_for_investors.round_type}
          </RaiseSliderItem>

          <RaiseSliderItem label="Markets catered to" className="t-col-span-2">
            {raiseProfile?.details_for_investors.markets.join(", ")}
          </RaiseSliderItem>
          <RaiseSliderItem label="Category of industry">
            {raiseProfile?.details_for_investors.industry_types.join(", ")}
          </RaiseSliderItem>
          <RaiseSliderItem label="Business model" className="t-col-span-2">
            {raiseProfile?.details_for_investors.business_models.join(", ")}
          </RaiseSliderItem>
          <RaiseSliderItem label="Current ARR">
            {raiseProfile?.details_for_investors.annual_revenue &&
              `$${raiseProfile?.details_for_investors.annual_revenue}`}
          </RaiseSliderItem>
        </RaiseSliderCard>
      )}
      {children}
    </div>
  );
};
