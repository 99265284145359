import { object, string } from "yup";
import { RESTRICTED_EMAILS } from "constants/magicLink";

export const teamManagementInvite = ({ domain }: { domain: string }) =>
  object({
    first_name: string().min(1, "Min. 1 character").required("Required"),
    last_name: string().required("Required"),
    email: string()
      .email("Should be a valid email")
      .required("Required")
      .test({
        name: "Domain",
        exclusive: false,
        message: `Please enter a ${domain} email`,
        test: (value) => {
          const userDomain = value?.split("@")?.[1];
          if (RESTRICTED_EMAILS.includes(domain)) {
            return true;
          }
          if (userDomain !== domain) {
            return false;
          }
          return true;
        },
      }),
  });
