import { AdminCallCardStatusType } from "components/ChatCard/AdminCallCard/AdminCallCardStatus";
import {
  CALL_CONNECTED,
  CALL_DISCONNECTED,
  CALL_NO_RESPONSE,
} from "constants/CallAdmin";
import {
  TICKET_CREATED,
  TICKET_RESOLVED,
  TICKET_PAYMENT,
  TICKET_UPDATED,
  TICKET_PAID,
} from "constants/ticket";
import { ReactNode } from "react";

export type TicketStatusType =
  | "IN_PROGRESS"
  | "COMPLETED"
  | typeof TICKET_CREATED
  | typeof TICKET_RESOLVED
  | typeof TICKET_PAYMENT
  | typeof TICKET_UPDATED
  | typeof TICKET_PAID
  | typeof CALL_NO_RESPONSE
  | typeof CALL_CONNECTED
  | typeof CALL_DISCONNECTED;

const headerIconBgColor = {
  IN_PROGRESS: "t-bg-yellow-30",
  COMPLETED: "t-bg-yellow-30",
  [TICKET_CREATED]: "t-bg-neutral-10",
  [TICKET_RESOLVED]: "t-bg-green-30",
  [TICKET_PAYMENT]: "t-bg-red-20",
  [TICKET_UPDATED]: "t-bg-yellow-30",
  [TICKET_PAID]: "t-bg-green-30",
  [CALL_NO_RESPONSE]: "",
  [CALL_CONNECTED]: "",
  [CALL_DISCONNECTED]: "",
  "": "t-bg-purple-30",
};

const Header = ({
  icon,
  children,
  status,
}: {
  icon?: ReactNode;
  children: ReactNode;
  status?: TicketStatusType | AdminCallCardStatusType;
}) => (
  <div className="t-flex t-gap-3.5 t-items-center t-border-solid t-border-0 t-border-b t-border-neutral-0 t-px-3 t-py-2">
    {icon && (
      <div
        className={`t-min-w-7 t-w-7 t-h-7 t-flex t-items-center t-justify-center t-rounded-sm ${
          headerIconBgColor[status || ""]
        }`}
      >
        {icon}
      </div>
    )}
    <span className=" t-text-subtext t-text-text-100 t-w-full">{children}</span>
  </div>
);

const Content = ({ children }: { children: ReactNode }) => (
  <div className="t-bg-surface t-border t-border-neutral-0 t-border-solid t-rounded t-mt-1.5 t-mx-1">
    {children}
  </div>
);

const Body = ({ children }: { children: ReactNode }) => (
  <div className="t-p-3">{children}</div>
);

const ChatCard = { Header, Content, Body };

export default ChatCard;
