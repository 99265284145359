import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import Tab from "components/DesignSystem/Tab/Tab";
import Table from "components/DesignSystem/Table/V2/Table";
import { Seperator } from "components/icons/Chat/Seperator";
import { EmptySearch } from "components/Illustrations/EmptySearch";
import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";
import { TICKET_SORT_OPTIONS } from "constants/ticket";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import NonPricedTicketTag from "static/images/NonPricedTicketTag.svg";
import PricedTicketTag from "static/images/PricedTicketTag.svg";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import {
  useGetChatTicketsQuery,
  useGetSingleChatTicketsQuery,
} from "store/apis/chatTicket";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { useChatContext } from "stream-chat-react";
import { ChatTicket, TicketNotes } from "types/Models/chatTicket";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import { CreateTicket } from "./CreateTicket";
import { TicketAssigneeCell } from "./TicketAssigneeCell";
import { TicketFilter } from "./TicketFilter";
import TicketIcon from "./TicketIcon";
import { TicketSlider } from "./TicketSlider";
import { TicketTags } from "./TicketTags";

const Ticket = ({ companyGroupUUID = "" }: { companyGroupUUID?: string }) => {
  usePageTitle("Tickets");
  const queryData = useQuery();
  const { isAdmin, isCustomer } = useRoleBasedView();
  const [resolvedStatus, setResolvedStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const selectedTag = queryData.get("tag_filter") || null;
  const selectedAssignee = queryData.get("assignee_profile_ids") || null;
  const selectedTicketType = queryData.get("ticket_types") || null;
  const isShared = queryData.get("shared");
  const { update } = useUpdateQuery();
  const pageNumber = Number(queryData.get("page") || 1);
  const ticketIdFromQueryParam = queryData.get("ticketUuid") || "";
  const { alertToast } = useToast();
  const [isTabSwitched, setIsTabSwitched] = useState(false);
  const [currentTicketId, setCurrentTicketId] = useState<string>(
    ticketIdFromQueryParam
  );
  const [ticketNotesPage, setTicketNotesPage] = useState(1);
  const [ticketNotes, setTicketNotes] = useState<TicketNotes[]>([]);
  const { client: chatClient } = useChatContext();
  const [openSlider, setOpenSlider] = useState(Boolean(ticketIdFromQueryParam));
  const isInsideGroup = Boolean(companyGroupUUID);

  const {
    close: closeCreateTicketModal,
    isOpen: isCreateTicketModalOpen,
    open: openCreateTicketModal,
  } = useModal();

  const onRowClick = (e: any, uuid: string) => {
    e.stopPropagation();
    handleRowClick(uuid);
    setTicketNotes([]);
    setTicketNotesPage(1);
    setOpenSlider(true);
  };

  const [selectedSortOptionObj, setSelectedSortOptionObj] = useState<{
    value: string;
    label: string;
  }>(
    resolvedStatus
      ? TICKET_SORT_OPTIONS.TICKET_TYPE_RESOLVED_LATEST_SORT
      : TICKET_SORT_OPTIONS.TICKET_TYPE_LATEST_SORT
  );

  const {
    data = {
      current_page: 0,
      tickets: [],
      total_count: 0,
      total_pages: isShared ? 1 : 0,
      unresolved_tickets: 0,
    },
    isLoading: isChatTicketsLoading,
    isFetching: isChatTicketsFetching,
  } = useGetChatTicketsQuery({
    page_num: pageNumber,
    assignee_profile_ids: selectedAssignee || null,
    company_group_uuids: companyGroupUUID,
    resolved_status: resolvedStatus,
    search_term: searchValue,
    tag_filter: selectedTag || null,
    type_filters: selectedTicketType || null,
    sort_by:
      selectedSortOptionObj.value ||
      (resolvedStatus
        ? TICKET_SORT_OPTIONS.TICKET_TYPE_RESOLVED_LATEST_SORT.value
        : TICKET_SORT_OPTIONS.TICKET_TYPE_LATEST_SORT.value),
  });

  const { current_page, tickets, total_count, total_pages } = data;

  const { data: ticketFromQueryParam } = useGetSingleChatTicketsQuery(
    {
      ticketUuid: ticketIdFromQueryParam,
    },
    {
      skip: !ticketIdFromQueryParam,
    }
  );

  const { data: assignees = [] } = useGetServiceTeamQuery(
    { accessible_teams: isAdmin ? INKLE_TEAM_AND_CPA_TEAM : null },
    {
      skip: isCustomer,
    }
  );

  const onSortTabSelect = ({
    e,
    sort,
  }: {
    e: Event;
    sort: { value: string; label: string };
  }) => {
    e.preventDefault();
    setSelectedSortOptionObj(sort);
    update({ query: "sort_by", value: sort.value });
  };

  const { data: tags = [] } = useGetTagsForCategoryQuery({
    tagType: "TICKETS_TAG",
  });

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      pageNumber < total_pages ? pageNumber : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (current_page < total_pages) {
      update({ query: "page", value: pageNumber + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const handleRowClick = (currentSelectedTicketUuid: string) => {
    try {
      setCurrentTicketId(currentSelectedTicketUuid);
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  useEffect(() => {
    if (!isChatTicketsFetching) {
      setIsTabSwitched(false);
    }
  }, [isTabSwitched, isChatTicketsFetching]);

  const assigneeFilter = (values: string[]) => {
    if (values) {
      update({ query: "assignee_profile_ids", value: values.join(",") });
    } else {
      update({ query: "assignee_profile_ids", value: null });
    }
  };

  const tagsFilter = (values: string[]) => {
    if (values) {
      update({ query: "tag_filter", value: values.join(",") });
    } else {
      update({ query: "tag_filter", value: null });
    }
  };

  const ticketTypeFilter = (values: string[]) => {
    if (values) {
      update({ query: "ticket_types", value: values.join(",") });
    } else {
      update({ query: "ticket_types", value: null });
    }
  };

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: 20,
    totalItemCount: total_count,
  };

  const onSearchQueryChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value.toLowerCase() || "");
  });

  const columnHelper = createColumnHelper<ChatTicket>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("ticket_title", {
        id: "title",
        header: "TITLE",
        size: 40,
        cell: ({ row }) => {
          return (
            <div className="t-flex t-justify-items-center gap-2 t-h-full t-items-center">
              {!resolvedStatus && (
                <TicketIcon priority={row.original.priority} />
              )}
              <div>
                {row.original.ticket_title}
                <div className="t-flex t-items-center gap-2">
                  <div className="t-text-[#706A85]">{row.original?.name}</div>
                  <Seperator />
                  {row.original?.entity && (
                    <>
                      <ReactCountryFlag
                        style={{
                          width: "14px",
                          height: "10px",
                          marginBottom: "3px",
                        }}
                        countryCode={row.original?.entity.code_alpha_2}
                        svg
                      />
                      <div className=" t-text-[#706A85]">
                        {row.original?.entity.name}
                      </div>
                      <Seperator />
                    </>
                  )}

                  <div className="t-text-[#706A85]">
                    {row.original.company_group?.name}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor("is_priced_ticket", {
        id: "type",
        header: "TYPE",
        size: 10,
        cell: ({ row }) => {
          return (
            <div className=" t-ml-2">
              {row.original?.is_priced_ticket ? (
                <img src={PricedTicketTag} alt="PricedTicketTag" />
              ) : (
                <img src={NonPricedTicketTag} alt="NonPricedTicketTag" />
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("assignee", {
        id: "assignee",
        header: "ASSIGNEE",
        cell: TicketAssigneeCell,
        size: resolvedStatus ? 10 : 20,
      }),
      columnHelper.accessor("created_on", {
        id: "created_on",
        header: "CREATED ON",
        cell: ({ row }) => {
          return (
            <div>
              <div>{formatDate(row.original.created_on)}</div>
            </div>
          );
        },
        size: 10,
      }),
      columnHelper.accessor("resolved_at", {
        id: "resolved_at",
        header: "RESOLVED ON",
        cell: ({ row }) => {
          return (
            <div>
              <div>
                {row.original.resolved_at
                  ? formatDate(row.original.resolved_at)
                  : "-"}
              </div>
            </div>
          );
        },
        size: 10,
      }),
      columnHelper.accessor("tags", {
        id: "tags",
        header: "TAGS",
        cell: TicketTags,
        size: 20,
      }),
    ],
    [resolvedStatus]
  );

  const tableData = useMemo(
    () =>
      ticketIdFromQueryParam && ticketFromQueryParam
        ? [ticketFromQueryParam]
        : tickets,
    [tickets, ticketFromQueryParam]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
    initialState: {
      columnVisibility: {
        tags: true,
        resolved_at: false,
        created_on: true,
        assignee: true,
        title: true,
      },
    },
  });

  if (!chatClient) {
    return <Loader />;
  }

  return (
    <>
      <div className="t-w-full t-h-5/6">
        <Header
          title="Tickets"
          bottom={
            <div className="t-mt-5 t-gap-x-6 ">
              <Tab.Root value="MATCH">
                <Tab.List>
                  <Tab.Trigger
                    value={!resolvedStatus ? "MATCH" : "UNMATCH"}
                    onClick={() => {
                      setResolvedStatus(false);
                      setIsTabSwitched(true);
                      table.setColumnVisibility({
                        resolved_at: false,
                      });
                      setSelectedSortOptionObj(
                        TICKET_SORT_OPTIONS.TICKET_TYPE_LATEST_SORT
                      );
                    }}
                  >
                    Unresolved
                  </Tab.Trigger>
                  <Tab.Trigger
                    value={resolvedStatus ? "MATCH" : "UNMATCH"}
                    onClick={() => {
                      setResolvedStatus(true);
                      setIsTabSwitched(true);
                      table.setColumnVisibility({
                        resolved_at: true,
                      });
                      setSelectedSortOptionObj(
                        TICKET_SORT_OPTIONS.TICKET_TYPE_RESOLVED_LATEST_SORT
                      );
                    }}
                  >
                    Resolved
                  </Tab.Trigger>
                </Tab.List>
              </Tab.Root>
            </div>
          }
          right={
            <Button
              customType="primary"
              onClick={openCreateTicketModal}
              size="small"
            >
              Add Ticket
            </Button>
          }
        />

        <div className="t-mt-4 t-ml-6">
          <Search
            className="t-w-[440px]"
            onChange={onSearchQueryChange}
            placeholder="Search"
          />
        </div>

        <TicketFilter
          tags={tags}
          tagsFilter={tagsFilter}
          selectedTag={selectedTag}
          assigneeFilter={assigneeFilter}
          assignees={assignees}
          selectedAssignee={selectedAssignee}
          ticketTypeFilter={ticketTypeFilter}
          paginationData={paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          onSortTabSelect={onSortTabSelect}
          selectedTicketType={selectedTicketType}
          selectedSortOptionObj={selectedSortOptionObj}
          resolvedStatus={resolvedStatus}
        />

        {isChatTicketsLoading || isTabSwitched ? (
          <Loader />
        ) : (
          <div className="t-py-4 t-px-6 t-w-full t-h-full">
            {tickets.length === 0 ? (
              <div className="t-flex t-justify-center t-items-center t-flex-col t-w-full t-h-2/3">
                <div>
                  <EmptySearch />
                </div>
                <span>No results found</span>
              </div>
            ) : (
              <Table.Container>
                <Table.Content>
                  <Table.Head>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup?.headers?.map((header) => (
                          <Table.HeadCell
                            key={header.id}
                            style={{ width: `${header.getSize()}%` }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </Table.HeadCell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {table.getRowModel().rows.map((row) => {
                      const {
                        original: { uuid },
                      } = row;
                      return (
                        <Table.Row
                          key={row.id}
                          className="hover:t-bg-surface-lighter-grey t-cursor-pointer"
                          onClick={(e) => onRowClick(e, uuid)}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <Table.Cell
                              key={cell.id}
                              style={{
                                width: `${cell.column.getSize()}%`,
                                height: "60px",
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Content>
              </Table.Container>
            )}

            {openSlider && (
              <TicketSlider
                currentTicketId={currentTicketId}
                setCurrentTicketId={setCurrentTicketId}
                resolvedStatus={resolvedStatus}
                openSlider={openSlider}
                setOpenSlider={setOpenSlider}
                ticketNotes={ticketNotes}
                setTicketNotes={setTicketNotes}
                ticketNotesPage={ticketNotesPage}
                setTicketNotesPage={setTicketNotesPage}
                chatClient={chatClient}
                isInsideGroup={isInsideGroup}
              />
            )}
          </div>
        )}
      </div>
      {isCreateTicketModalOpen && (
        <CreateTicket
          isNonChatTicket={true}
          show={isCreateTicketModalOpen}
          closeModal={closeCreateTicketModal}
        />
      )}
    </>
  );
};

export default Ticket;
