import { EnvelopeData } from "types/Models/esign";
import { baseURL, emptyApi } from "./emptyApi";
import { stringify } from "qs";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    createEnvelope: build.mutation<
      EnvelopeData,
      {
        groupId: string;
        signerProfileIds: string;
        channelUrl: string;
        file?: File | null;
        file_id?: string;
      }
    >({
      query: ({ groupId, signerProfileIds, channelUrl, file, file_id }) => {
        const formData = new FormData();
        if (file) {
          formData.append("file", file);
        }
        if (file_id) {
          formData.append("file_id", file_id);
        }
        formData.append("signer_profile_ids", signerProfileIds);
        formData.append("channel_url", channelUrl);

        return {
          url: `/api/inkle/esign/group/${groupId}/envelope/`,
          method: "POST",
          body: formData,
        };
      },
    }),

    createEsignCard: build.mutation<
      EnvelopeData,
      {
        groupId: string;
        envelope_id: string;
      }
    >({
      query: ({ groupId, envelope_id }) => ({
        url: `/api/inkle/messaging/group/${groupId}/e-sign/`,
        method: "POST",
        body: {
          envelope_id,
        },
      }),
    }),

    getSignatureUrl: build.query<
      { signature_url: string },
      {
        groupId: string;
        envelopeId: string;
        callbackUrl: string;
        getSenderView?: boolean;
      }
    >({
      query: ({ groupId, envelopeId, callbackUrl, getSenderView }) => {
        let queryUrl = stringify(
          {
            callback_url: callbackUrl,
            get_sender_view: getSenderView || null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `${baseURL}api/inkle/esign/group/${groupId}/envelope/${envelopeId}/url/${queryUrl}`,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateEnvelopeMutation,
  useCreateEsignCardMutation,
  useLazyGetSignatureUrlQuery,
} = extendedApi;
