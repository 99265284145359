import classNames from "classnames";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

// apis
import { profileData } from "../apis/profileData";

// auth
import authContext from "../jwt_context&axios/authContext";

//css
import "../static/styles/components/inkleLogo.css";

const Logo = ({ image = false, className = "", isExternal = false }) => {
  const history = useHistory();
  const { updateUser } = useContext(authContext);

  const handleLogoClick = () => {
    profileData().then((res) => {
      updateUser(res);
      if (res.data.data.is_restricted) {
        history.push("/verify");
      } else if (!res.data.data.is_group_created) {
        history.push("/profile");
      } else if (res.data.data?.is_archived) {
        history.push("/archived");
      } else {
        history.push("/");
      }
    });
  };

  if (isExternal) {
    return (
      <a href="https://www.inkle.io" target="_blank" rel="noreferrer">
        <div
          className={classNames("logo", {
            whiteLogo: image,
            [className]: className,
          })}
          aria-hidden="true"
        ></div>
      </a>
    );
  }

  return (
    <div
      className={classNames("logo", {
        whiteLogo: image,
        [className]: className,
      })}
      aria-hidden="true"
      onClick={handleLogoClick}
    ></div>
  );
};
export default Logo;
