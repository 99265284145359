import dayjs from "dayjs";
import { MessageResponse, Attachment } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const groupMessageAttatchmentsByMonth = (
  messages: {
    message: MessageResponse<DefaultStreamChatGenerics>;
  }[]
): [string, (Attachment & { messageId: string })[]][] =>
  messages.reduce((acc, message) => {
    const messageCreatedAt = message.message.created_at;
    const monthSent = dayjs(messageCreatedAt).format("MMMM");
    const messageSentPastWeek =
      dayjs().diff(dayjs(messageCreatedAt), "days") < 7;
    const messageSentPastMonth =
      dayjs().diff(dayjs(messageCreatedAt).format("YYYY-MM"), "month") === 1;
    const attachments =
      message.message.attachments
        ?.filter((m) => ["image", "video"].includes(m?.type || ""))
        .map((a) => ({ ...a, messageId: message.message.id })) || [];

    const lastWeekMessages = acc.find(([name]) => name === "Last week");
    const lasMonthMessages = acc.find(([name]) => name === "Last month");
    const currentMonthMessages = acc.find(([name]) => name === monthSent);

    if (messageSentPastWeek) {
      if (!lastWeekMessages) {
        acc = [...acc, ["Last week", []]];
      }

      return acc.map(([name, value]) => {
        if (name === "Last week") {
          return [name, [...value, ...attachments]];
        }

        return [name, value];
      });
    }

    if (messageSentPastMonth) {
      if (!lasMonthMessages) {
        acc = [...acc, ["Last month", []]];
      }

      return acc.map(([name, value]) => {
        if (name === "Last month") {
          return [name, [...value, ...attachments]];
        }

        return [name, value];
      });
    }

    if (!currentMonthMessages) {
      acc = [...acc, [monthSent, []]];
    }

    return acc.map(([name, value]) => {
      if (name === monthSent) {
        return [name, [...value, ...attachments]];
      }

      return [name, value];
    });
  }, [] as [string, (Attachment & { messageId: string })[]][]);
