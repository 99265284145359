import { stateUpdate, updateTaskPrice } from "apis/stateUpdate";
import { Button } from "components/DesignSystem/Button/Button";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DocumentPreviewModal } from "components/PreviewModal";
import { SelectFolderForUpload } from "components/SelectFolderForUpload";
import { FILE_DELETE_MESSAGE, FILE_UPLOAD_MESSAGE } from "constants/documents";
import { Form, Formik } from "formik";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import {
  useDeleteCompletedTaskFileMutation,
  useUploadCompletedTaskFileMutation,
} from "store/apis/task";
import { object, string } from "yup";
import UpdateTaskPriceHeader from "./UpdateTaskPriceHeader";

import { FileInput } from "components/FileInput/FileInput";
import Modal from "components/Modal/Modal";
import { useModal } from "hooks/useModal";

export const UpdateTaskPrice = ({
  show,
  closeModal,
  newStateId,
  groupId,
  taskId,
  task,
  setShow,
  isVariablePricing,
}) => {
  const { alertToast, successToast } = useToast();
  const openPreview = useDocPreview();
  const [uploadDoc, { isLoading: isUploading }] =
    useUploadCompletedTaskFileMutation();
  const [deleteDoc, { isLoading: isDeleting, originalArgs }] =
    useDeleteCompletedTaskFileMutation();
  const [step, setStep] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isBtnLoading, setBtnLoading] = useState(false);

  const uploadToFolderModal = useModal();

  const onDrop = (newFiles) => {
    const file = newFiles[0];

    if (file.uuid) {
      onInkleDocFileSelect(file);
      return;
    }
    setSelectedFiles(newFiles);
    uploadToFolderModal.open();
  };

  const CloseFileUploadModal = () => {
    setStep(0);
    closeModal();
  };

  const files = useMemo(
    () => task?.task_completed_documents?.map((docs) => docs) || [],
    [task?.task_completed_documents]
  );

  const updatePrice = async (payload) => {
    try {
      const response = await updateTaskPrice({ taskId, payload, groupId });
      await stateUpdate({ taskId, id: newStateId });
      CloseFileUploadModal();
      successToast({ message: response.data.data.message });
      window.location.reload();
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const onInkleDocFileSelect = async (file) => {
    try {
      await uploadDoc({
        task_id: taskId,
        group_id: groupId,
        file_id: file.uuid,
      }).unwrap();
      successToast({ message: FILE_UPLOAD_MESSAGE });
      setShow(true);
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });

      setShow(true);
    }
  };

  const uploadTaskCompletedFile = async (folderId) => {
    selectedFiles.map(async (selectedFile) => {
      try {
        await uploadDoc({
          task_id: taskId,
          folder_id: folderId,
          group_id: groupId,
          file: selectedFile,
        }).unwrap();
      } catch (e) {
        alertToast({ message: e?.data?.error?.message });
      }
    });
    setShow(true);
  };

  const onDelete = async (e, i) => {
    const { task_document_map_id = "", uuid = "" } = files.at(i) || {};
    try {
      await deleteDoc({
        docUuid: uuid,
        task_document_map_id,
        task_id: taskId,
        group_id: groupId,
      }).unwrap();
      successToast({ message: FILE_DELETE_MESSAGE });
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const goToNextStep = async () => {
    try {
      setBtnLoading(true);
      await stateUpdate({ taskId, id: newStateId });
      setBtnLoading(false);
      CloseFileUploadModal();
      window.location.reload();
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
      setBtnLoading(false);
    }
  };

  const descriptionSchema = () =>
    object({
      amount: string().min(1).required("Amount is required"),
      notes: string().min(1).required("Description is required"),
    });

  return (
    <>
      <Modal.Root open={show} onOpenChange={CloseFileUploadModal}>
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="!t-p-0">
            <div className="!t-p-6 !t-pb-0">
              <div className="t-mb-4">
                <Modal.Title>Update Filing</Modal.Title>
              </div>
              <Modal.Close />
            </div>
            {isVariablePricing && (
              <UpdateTaskPriceHeader step={step} setStep={setStep} />
            )}
            <div className="t-px-6 t-pb-5 t-pt-3">
              {step === 0 && (
                <>
                  <FileInput
                    isUploading={isUploading}
                    isDeleting={isDeleting}
                    fileDeletingUuid={originalArgs?.docUuid}
                    multiple
                    withInkleDocs
                    files={files}
                    onFileClick={({ uuid }) => openPreview(uuid)}
                    onDelete={onDelete}
                    onDrop={onDrop}
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg", ".webp", ".avif"],
                      "application/pdf": [".pdf"],
                      "application/vnd.ms-excel": [".xls"],
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        [".xlsx"],
                      "text/csv": [".csv"],
                    }}
                  />
                  <div className="t-mt-6 t-flex t-justify-end t-gap-4">
                    <Button onClick={CloseFileUploadModal}>Cancel</Button>
                    <Button
                      isLoading={isBtnLoading}
                      customType="primary"
                      onClick={() =>
                        isVariablePricing ? setStep(1) : goToNextStep()
                      }
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
              {step === 1 && (
                <Formik
                  validateOnMount
                  validationSchema={descriptionSchema}
                  onSubmit={updatePrice}
                  initialValues={{
                    amount: "",
                    notes: "",
                  }}
                >
                  {({ isValid, isSubmitting }) => (
                    <Form className="t-m-0 t-w-full">
                      <TextInput
                        block
                        name="amount"
                        type="number"
                        placeholder="Enter amount"
                        label="Amount"
                      />
                      <div className="t-mt-4">
                        <TextArea
                          name="notes"
                          label="Description"
                          rows={5}
                          placeholder="Enter description"
                        />
                      </div>
                      <div className="t-mt-3 t-flex t-justify-end t-gap-4">
                        <Button onClick={CloseFileUploadModal}>Cancel</Button>
                        <Button
                          customType="primary"
                          type="submit"
                          isLoading={isSubmitting}
                          disabled={!isValid}
                        >
                          Confirm
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>

      {uploadToFolderModal.isOpen && (
        <SelectFolderForUpload
          showModal={uploadToFolderModal.isOpen}
          closeModal={() => uploadToFolderModal.close()}
          onUpload={uploadTaskCompletedFile}
        />
      )}
      <DocumentPreviewModal />
    </>
  );
};
