import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { DashboardLayout } from "components/DashboardLayout";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { AddAsset } from "components/FixedAsset/AddAsset";
import { AssetSlider } from "components/FixedAsset/AssetSlider";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { usePagination } from "hooks/usePagination";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import NoFixedAsset from "static/images/NoFixedAsset.svg";
import { useGetFixedAssetQuery } from "store/apis/fixedAsset";
import { openSlider, setAllFixedAssetIds } from "store/slices/fixedAsset";
import { RootState } from "store/store";
import { FixedAssetResult, Frequency } from "types/Models/fixedAsset";
import { debounce } from "utils/debouncing";
import { pluralize } from "utils/pluralize";
import { EmptyScreen } from "../EmptyScreen";

export const RemainingLife = ({
  frequency,
  remainingLife,
}: {
  frequency: Frequency;
  remainingLife: number;
}) => {
  switch (frequency) {
    case "ANNUALLY": {
      return <>{pluralize(remainingLife, "year", "years")}</>;
    }

    case "QUARTERLY": {
      return <>{pluralize(remainingLife, "quarter", "quarters")}</>;
    }

    case "MONTHLY": {
      return <>{pluralize(remainingLife, "month", "months")}</>;
    }

    default:
      return <></>;
  }
};

export const FixedAsset = () => {
  usePageTitle("Fixed Assets");
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { close, isOpen, open } = useModal();
  const [searchTerm, setSearchTerm] = useState("");

  const { previewFixedAssetId } = useSelector(
    (store: RootState) => store.fixedAsset
  );

  const {
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPrevPage,
    pageNum,
    setTotalPage,
  } = usePagination();

  const { data, isLoading, isSuccess } = useGetFixedAssetQuery(
    {
      entityId,
      groupId,
      pageNum,
      searchTerm,
    },
    { skip: !entityId || !groupId }
  );

  const {
    fixed_assets = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  useEffect(() => {
    if (total_pages) {
      setTotalPage(total_pages);
    }
  }, [total_pages]);

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
  });

  const isEmpty = fixed_assets?.length === 0;
  const createColumn = createColumnHelper<FixedAssetResult>();

  const columns = [
    createColumn.accessor("asset_name", {
      size: 25,
      header: "ASSET NAME",
      cell: (info) => {
        const name = info.getValue();

        return <>{name}</>;
      },
    }),

    createColumn.accessor("remaining_life", {
      size: 15,
      header: "REMAINING LIFE",
      cell: (info) => {
        const { frequency, remaining_life } = info.row.original;

        return (
          <RemainingLife frequency={frequency} remainingLife={remaining_life} />
        );
      },
    }),

    createColumn.accessor("purchase_price", {
      size: 20,
      header: () => (
        <div className="t-flex t-justify-end t-whitespace-nowrap">
          PURCHASE PRICE
        </div>
      ),
      cell: (info) => {
        const purchase_price = info.getValue();

        return (
          <div className="t-flex t-justify-end t-whitespace-nowrap">
            <AmountSuperScript amount={purchase_price} />
          </div>
        );
      },
    }),

    createColumn.accessor("current_value", {
      size: 20,
      header: () => (
        <div className="t-flex t-justify-end t-whitespace-nowrap">
          CURRENT VALUE
        </div>
      ),
      cell: (info) => {
        const current_value = info.getValue();

        return (
          <div className="t-flex t-justify-end t-whitespace-nowrap">
            <AmountSuperScript amount={current_value} />
          </div>
        );
      },
    }),

    createColumn.accessor("depreciation_amount", {
      size: 20,
      header: () => (
        <div className="t-flex t-justify-end t-whitespace-nowrap">
          DEPRECIATION VALUE
        </div>
      ),
      cell: (info) => {
        const depreciation_amount = info.getValue();

        return (
          <div className="t-flex t-justify-end t-whitespace-nowrap">
            <AmountSuperScript amount={depreciation_amount} />
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: fixed_assets || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const openRuleSlider = (ruelId: string) => {
    const allFixedAssetIds = fixed_assets.map(({ uuid }) => uuid);

    dispatch(openSlider(ruelId));
    dispatch(setAllFixedAssetIds(allFixedAssetIds));
  };

  return (
    <DashboardLayout header={<Header v2 title="Fixed Assets" />}>
      <DashboardContainer className="t-relative t-gap-5">
        <DashboardContainer.Header className="t-sticky t-top-0 t-z-header t-flex t-flex-col t-gap-3">
          <div className="t-flex t-justify-between">
            <div className="t-w-1/2">
              <Search block placeholder="Search" onChange={handleSearch} />
            </div>
            <Button customType="primary" size="small" onClick={open}>
              Add asset
            </Button>
          </div>
          <div className="t-flex t-justify-end t-w-full">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
            <Async.Empty>
              <EmptyScreen
                text="Automate your fixed asset depreciation"
                cta={
                  <Button customType="primary" size="small" onClick={open}>
                    Add asset
                  </Button>
                }
              >
                <img src={NoFixedAsset} alt="No fixed asset found" />
              </EmptyScreen>
            </Async.Empty>
            <Async.Success>
              <TableUI table={table}>
                {({ row }) => (
                  <Table.Row
                    key={row.id}
                    onClick={() => openRuleSlider(row.original.uuid)}
                    className={classNames(
                      "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                      {
                        "t-bg-surface-lighter-grey":
                          previewFixedAssetId === row.original.uuid,
                      }
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                          className="t-py-4 t-px-2"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                )}
              </TableUI>
              <AssetSlider />
            </Async.Success>
          </Async.Root>
        </DashboardContainer.Content>
        {isOpen && <AddAsset isOpen={isOpen} close={close} />}
      </DashboardContainer>
    </DashboardLayout>
  );
};
