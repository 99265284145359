import classNames from "classnames";
import { Cross } from "components/icons/Cross";
import {
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useContext,
  useState,
} from "react";
import { Button } from "../Button/Button";
import { FilterContext } from "../Filter/FilterContext";

type ChipProps = {
  children: ReactNode;
  icon?: ReactNode;
  isActive?: boolean;
  isRemovable?: boolean;
  isFixedFilter?: boolean;
  size?: "regular" | "small";
  onClose: () => void;
  onFixedFilterClick?: () => void;
  filterType?: string;
} & HTMLAttributes<HTMLButtonElement>;

export const Chip = ({
  children,
  isActive = false,
  size = "regular",
  isRemovable = true,
  isFixedFilter = false,
  icon,
  onClose,
  onFixedFilterClick,
  filterType,
  ...props
}: ChipProps) => {
  const [hover, setHover] = useState(false);
  const { setOpen, onSelect } = useContext(FilterContext);

  const onRemove = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    onClose();
    setHover(false);
    setOpen(false);
  };

  const handleChipClick = () => {
    if (!filterType) {
      onRemove();
    }

    if (isActive && filterType) {
      onSelect?.(filterType);
      setOpen?.((open) => !open);
    }

    if (isFixedFilter && !isActive) {
      onFixedFilterClick?.();
    }
  };

  return (
    <button
      onMouseEnter={() => !filterType && setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleChipClick}
      {...props}
      className={classNames(
        "all:unset t-flex t-gap-1.5 t-items-center t-text-body-sm t-text-text-60 t-border t-border-solid t-border-neutral-0 t-w-max t-px-3 hover:t-border-purple-20 hover:t-bg-purple-0 t-select-none t-rounded-3xl",
        {
          "t-h-6": size === "small",
          "t-h-8": size === "regular",
          "!t-border-purple-40 !t-bg-purple-0": isActive,
          "!t-border-surface-transparent !t-bg-surface-grey":
            isFixedFilter && !hover && !isActive,
          "!t-bg-red-10 !t-border-red-30": hover,
        }
      )}
    >
      {icon}
      {children}
      {isActive && isRemovable && (
        <Button
          onMouseEnter={() => filterType && setHover(true)}
          onMouseLeave={() => filterType && setHover(false)}
          onClick={onRemove}
          customType="ghost_icon"
          size="extra-small"
        >
          <Cross size="12" color="currentColor" />
        </Button>
      )}
    </button>
  );
};
