import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import React, { useState } from "react";
import { ModalProps } from "types/utils/modal";
import { FileIcon } from "utils/fileTypeIcon";
import Prevfolder from "static/images/Prevfolder.svg";
import {
  useGetFolderContentQuery,
  useGetTagDocumentsQuery,
} from "store/apis/documents";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { FOLDER_TYPE } from "constants/documents";
import { SelecteFile } from "types/Models/documents";

export type UploadFromInkleDocsFileSelectorModalProps = {
  groupId?: string;
  fileTag?: string;
  onUpload: (file: SelecteFile) => void;
} & ModalProps;

export const UploadFromInkleDocsFileSelectorModal = ({
  isOpen,
  close,
  groupId: groupIdFromProps,
  fileTag,
  onUpload: onUploadFromProps,
}: UploadFromInkleDocsFileSelectorModalProps) => {
  const { uuid: currentGroupId } = useCurrentGroupContext();
  const { alertToast } = useToast();

  const [selectedFile, setSlectedFile] = useState<SelecteFile>({
    file_type: "IMAGE",
    is_previewable: false,
    name: "",
    uuid: "",
  });

  const groupId = currentGroupId || groupIdFromProps || "";

  const { data: folderWithTagFile, ...fileWithTagStates } =
    useGetTagDocumentsQuery(
      {
        groupId: groupId,
        tag_title: fileTag,
      },
      { skip: !Boolean(groupId) || !Boolean(isOpen) }
    );

  const { parents_list: parentList = [] } = folderWithTagFile || {};
  const serverFolderId = parentList.at(-1)?.uuid;
  const [localFolderId, setLocalFolderId] = useState("");
  const folderId = localFolderId || serverFolderId || "";

  const {
    data: folderData,
    refetch,
    ...folderStates
  } = useGetFolderContentQuery(
    {
      groupId: groupId,
      folderId: folderId,
    },
    { skip: Boolean(!folderId) || !Boolean(groupId) || !Boolean(isOpen) }
  );

  const { files = [], subfolders = [], parents_list = [] } = folderData || {};
  const folderContent = [...subfolders, ...files];
  const isEmpty = folderContent.length === 0;

  const isLoading = folderStates.isLoading || fileWithTagStates.isLoading;
  const isSuccess = folderStates.isSuccess && fileWithTagStates.isSuccess;
  const isFetching = folderStates.isFetching || fileWithTagStates.isFetching;

  const {
    [parents_list.length - 1]: currentFolder,
    [parents_list.length - 2]: parentFolder,
  } = parents_list;

  const folderClicked = ({ folderId }: { folderId: string }) => {
    try {
      setLocalFolderId(folderId);
      refetch().unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError).data?.error?.message,
      });
    }
  };

  const onBackButtonClick = () => {
    if (parentFolder?.uuid) {
      folderClicked({ folderId: parentFolder?.uuid });
    } else {
      close();
    }
  };

  const onUpload = () => {
    close();
    onUploadFromProps?.(selectedFile);
    setSlectedFile({
      file_type: "IMAGE",
      is_previewable: false,
      name: "",
      uuid: "",
    });
    close();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="regular">
        <Modal.Header>
          <Modal.Title>
            <div className="t-flex t-items-center t-gap-1">
              <Button
                customType="ghost_icon"
                size="small"
                onClick={onBackButtonClick}
                disabled={isFetching}
                isLoading={isFetching}
              >
                <img src={Prevfolder} alt="Back" />
              </Button>
              <span className="t-max-w-80 t-truncate t-text-body">
                {currentFolder?.name}
              </span>
            </div>
          </Modal.Title>

          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Async.Root
            isEmpty={isEmpty}
            isLoading={isLoading}
            isSuccess={isSuccess}
          >
            <Async.Empty>
              <div className="t-flex t-justify-center t-items-center t-w-full t-text-neutral-40 t-h-80">
                This folder is empty
              </div>
            </Async.Empty>
            <Async.Success>
              <div className="t-flex t-h-80 t-flex-col t-items-start t-gap-1 t-select-none">
                {folderContent.map(
                  ({ uuid, name, file_type, type, is_previewable }) => {
                    return (
                      <button
                        key={uuid}
                        onDoubleClick={() =>
                          type === FOLDER_TYPE &&
                          folderClicked({ folderId: uuid })
                        }
                        onClick={() =>
                          type !== FOLDER_TYPE &&
                          setSlectedFile({
                            uuid,
                            name,
                            file_type,
                            is_previewable,
                          })
                        }
                        className={classNames(
                          "all:unset t-flex t-w-full t-cursor-pointer t-items-center t-gap-2 t-border-solid t-border-surface-transparent t-px-2 t-py-1 hover:t-rounded hover:t-bg-purple-10 focus-visible:t-rounded focus-visible:t-bg-purple-10 focus-visible:t-outline focus-visible:t-outline-purple-30",
                          {
                            "t-rounded t-border-solid t-border-purple-30 t-bg-purple-10":
                              selectedFile.uuid === uuid,
                          }
                        )}
                      >
                        <FileIcon
                          fileType={file_type}
                          width="32px"
                          height="32px"
                        />
                        <div className="t-max-w-[25rem] t-truncate t-text-body-sm">
                          {name}
                        </div>
                      </button>
                    );
                  }
                )}
              </div>
            </Async.Success>
          </Async.Root>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-between t-items-center">
          {Boolean(selectedFile.uuid) && (
            <div className="t-flex t-gap-2 t-items-center t-text-body-sm">
              <FileIcon
                fileType={selectedFile.file_type}
                width="32px"
                height="32px"
              />
              <div className="t-max-w-[20rem] t-truncate">
                {selectedFile.name}
              </div>
            </div>
          )}
          <div className="t-ml-auto">
            <Button
              customType="primary"
              size="small"
              onClick={onUpload}
              disabled={!Boolean(selectedFile.uuid)}
            >
              Insert
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
