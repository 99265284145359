import { useState, useEffect } from "react";
import { useChatContext } from "stream-chat-react";

export const useUnreadFromChannels = ({
  channelUrls,
  queryOptions,
}: {
  channelUrls: string[];
  queryOptions: {
    limit: number;
  };
}) => {
  const { client } = useChatContext();
  const channelUrlsAsString = JSON.stringify(channelUrls);
  const [channelUnreadMap, setChannelUnreadMap] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    const getAndSetChannelUnread = async (userId: string) => {
      const queriedChannels = await client.queryChannels(
        {
          id: { $in: channelUrls },
          members: { $in: [client.userID!] },
        },
        [],
        { watch: true, ...queryOptions }
      );

      const unreadMap = queriedChannels.reduce((acc, channel) => {
        const unreadCount = channel.countUnread();
        return { ...acc, [channel.id!]: unreadCount };
      }, {} as Record<string, number>);

      setChannelUnreadMap((v) => ({ ...(v || {}), ...unreadMap }));
    };

    if (client.userID && channelUrls.length > 0) {
      getAndSetChannelUnread(client.userID);
    }
  }, [channelUrlsAsString]);

  return channelUnreadMap;
};
