import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useGetSubscriptionStatsQuery } from "store/apis/subscriptions";
import { useGetTaskTemplateStatsQuery } from "store/apis/taskTemplateStats";
import {
  useGetChatTicketsQuery,
  useGetTicketSLAMetricQuery,
} from "store/apis/chatTicket";
//context

//css
import "../../static/styles/components/design/comingSoon.css";
import { useChatContext, usePaginatedChannels } from "stream-chat-react";
import { OVERDUE } from "constants/subscriptionType";
import { defaultSortOption } from "constants/foreignCATask";
import { useEffect, useState } from "react";
import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import { usePageTitle } from "hooks/usePageTitle";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Button } from "components/DesignSystem/Button/Button";
import Pencil from "components/icons/pencil";
import { Smiley } from "components/icons/Chat/Smiley";
import { useGetTaskSlaMetricsQuery } from "store/apis/task";
import { useModal } from "hooks/useModal";
import Modal from "components/DesignSystem/Modal/Modal";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Form, Formik } from "formik";
import { useGetChatSLAMetricsQuery } from "store/apis/chat";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { useLazyGetAllServiceProviderTaskQuery } from "store/apis/serviceTeam";

const InfoItem = ({ header, info }) => {
  return (
    <div className="t-flex t-flex-col t-gap-y-2">
      <div>{header}</div>
      <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
        <h4>{info}</h4>
      </div>
    </div>
  );
};
export const ChatSLAMetricCard = () => {
  const [duration, setDuration] = useState(3);
  const { data, isLoading, isSuccess } = useGetChatSLAMetricsQuery({
    duration,
  });
  const { hours = 0, minutes = 0 } = data?.average_chat_response_time || {};

  const selectionOptions = [
    { label: "Last month", value: 1 },
    { label: "Last 3 months", value: 3 },
    { label: "Last 6 months", value: 6 },
    { label: "Last 12 months", value: 12 },
  ];

  return (
    <>
      <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-5 t-flex t-flex-col t-w-full t-gap-5">
        <div className=" t-flex t-justify-between">
          <div className=" t-flex t-gap-3 t-items-center">
            <span className=" t-text-subtitle">Chat</span>
            <Combobox
              size="small"
              name="duration"
              onChange={(value) => {
                if (value instanceof Array) {
                  return;
                }

                setDuration(value.value);
              }}
              menuPortalTarget={document.body}
              options={selectionOptions}
              value={selectionOptions.find(({ value }) => value === duration)}
              isSearchable={false}
              isClearable={false}
            />
          </div>
        </div>
        <Async.Root {...{ isLoading, isSuccess, isEmpty: false }}>
          <Async.Empty>-</Async.Empty>
          <Async.Success>
            <InfoItem
              header="Avg. chat response back time"
              info={`${hours} hrs ${minutes} mins`}
            />
          </Async.Success>
        </Async.Root>
      </div>
    </>
  );
};

const CsatSLAMetricCard = () => {
  const customerResponses = 156;
  return (
    <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-5 t-flex t-flex-col t-w-full t-gap-5">
      <div className=" t-flex t-justify-between">
        <div className=" t-flex t-gap-3 t-items-center">
          <span className=" t-text-subtitle">CSAT</span>
        </div>
      </div>
      <div className=" t-flex t-gap-[100px]">
        <InfoItem
          header="NPS score"
          info={
            <div className="t-flex t-gap-2 t-items-center">
              <div className=" t-text-green-70">
                <Smiley width="32" height="32" />
              </div>
              <span>61</span>
            </div>
          }
        />
        <InfoItem header="Customer Responses" info={customerResponses} />
      </div>
    </div>
  );
};

export const FilingsSLAMetricCard = () => {
  const [season, setSeason] = useState("2024");
  const { data, isLoading, isSuccess } = useGetTaskSlaMetricsQuery({
    season: season,
  });
  const {
    average_completed_time,
    completed_filings_count: filingsCompleted = 0,
    launched_filings_count: filingsLaunched = 0,
  } = data || {};
  const { days = 0, hours = 0 } = average_completed_time || {};

  const seasonOptions = [
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
  ];
  return (
    <div>
      <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-5 t-flex t-flex-col t-w-full t-gap-5">
        <div className=" t-flex t-justify-between">
          <div className=" t-flex t-gap-3 t-items-center">
            <span className=" t-text-subtitle">Filings</span>
            <Combobox
              size="small"
              name="season"
              onChange={(value) => {
                if (value instanceof Array) {
                  return;
                }

                setSeason(value.value);
              }}
              menuPortalTarget={document.body}
              options={seasonOptions}
              value={seasonOptions.find(({ value }) => value === season)}
              isSearchable={false}
              isClearable={false}
            />
          </div>
        </div>
        <Async.Root {...{ isLoading, isSuccess, isEmpty: false }}>
          <Async.Empty>-</Async.Empty>
          <Async.Success>
            <div className=" t-flex t-gap-[100px]">
              <InfoItem
                header="Filing completion time"
                info={`${days} days ${hours} hrs`}
              />
              <InfoItem header="Filings launched" info={filingsLaunched} />
              <InfoItem header="Filings completed" info={filingsCompleted} />
            </div>
          </Async.Success>
        </Async.Root>
      </div>
    </div>
  );
};

export const TicketsSLAMetricCard = () => {
  const [duration, setDuration] = useState(3);
  const { data, isLoading, isSuccess } = useGetTicketSLAMetricQuery({
    duration: duration,
  });
  const {
    average_resolution_time,
    tickets_created_count: ticketsCreatedCount = 0,
    resolved_tickets_count: resolvedTicketsCount = 0,
  } = data || {};
  const { days = 0, hours = 0 } = average_resolution_time || {};

  const selectionOptions = [
    { label: "Last month", value: 1 },
    { label: "Last 3 months", value: 3 },
    { label: "Last 6 months", value: 6 },
    { label: "Last 12 months", value: 12 },
  ];

  return (
    <div>
      <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-5 t-flex t-flex-col t-w-full t-gap-5">
        <div className=" t-flex t-justify-between">
          <div className=" t-flex t-gap-3 t-items-center">
            <span className=" t-text-subtitle">Tickets</span>
            <Combobox
              size="small"
              name="duration"
              onChange={(value) => {
                if (value instanceof Array) {
                  return;
                }

                setDuration(value.value);
              }}
              menuPortalTarget={document.body}
              options={selectionOptions}
              value={selectionOptions.find(({ value }) => value == duration)}
              isSearchable={false}
              isClearable={false}
            />
          </div>
        </div>
        <Async.Root {...{ isLoading, isSuccess, isEmpty: false }}>
          <Async.Empty>-</Async.Empty>
          <Async.Success>
            <div className=" t-flex t-gap-[100px]">
              <InfoItem
                header="Ticket completion time"
                info={`${days} days ${hours} hrs`}
              />
              <InfoItem header="Tickets created" info={ticketsCreatedCount} />
              <InfoItem header="Tickets resolved" info={resolvedTicketsCount} />
            </div>
          </Async.Success>
        </Async.Root>
      </div>
    </div>
  );
};
const WelcomeUser = () => {
  usePageTitle("Home");
  const { isAdmin, isCpaInkleServiceAdmin } = useRoleBasedView();
  const { data } = useGetSubscriptionStatsQuery(null, { skip: !isAdmin });
  const { data: templateDataFBAR } = useGetTaskTemplateStatsQuery(
    {
      templateId: process.env.REACT_APP_FBAR_TASK_TEMPLATE_ID,
    },
    {
      skip: !isCpaInkleServiceAdmin,
    }
  );
  const [overdueFilings, setOverdueFilings] = useState(null);
  const [recentlyMovedFilings, setRecentlyMovedFilings] = useState(null);
  let statList = [];
  if (templateDataFBAR) {
    statList = Object.entries(templateDataFBAR);
  }

  const { data: templateDataBooks } = useGetTaskTemplateStatsQuery(
    {
      templateId: process.env.REACT_APP_BOOKS_TASK_TEMPLATE_ID,
    },
    {
      skip: !isCpaInkleServiceAdmin,
    }
  );
  let statListBooks = [];
  if (templateDataBooks) {
    statListBooks = Object.entries(templateDataBooks);
  }

  const { data: templateData1120 } = useGetTaskTemplateStatsQuery(
    {
      templateId: process.env.REACT_APP_1120_TASK_TEMPLATE_ID,
    },
    {
      skip: !isCpaInkleServiceAdmin,
    }
  );
  let statList1120 = [];
  if (templateData1120) {
    statList1120 = Object.entries(templateData1120);
  }
  const { data: { unresolved_tickets } = { unresolved_tickets: 0 } } =
    useGetChatTicketsQuery({
      page_num: 1,
      assignee_profile_ids: null,
      resolved_status: false,
    });

  const { client } = useChatContext();
  const { channels } = usePaginatedChannels(
    client,
    {
      type: "messaging",
      needs_reply: true,
      members: { $in: [client.userID] },
    },
    {
      sort: 1,
    },
    {
      limit: 100,
    }
  );
  const [getAllServiceProviderTask] = useLazyGetAllServiceProviderTaskQuery();
  const fetchAndSaveActionItemsData = async () => {
    const overdueFilingsData = await getAllServiceProviderTask({
      page_num: 1,
      search_term: null,
      task_tag_filter_uuids: null,
      task_state_filter_uuids: OVERDUE.toLowerCase(),
      task_template_filter_uuids: null,
      assignee_uuids: null,
      task_sort_by: defaultSortOption.sortKey,
      subscriptionFilter: null,
      generate_report: false,
    });

    setOverdueFilings(overdueFilingsData);

    const recentlyMovedFilingsData = await getAllServiceProviderTask({
      page_num: 1,
      search_term: null,
      task_tag_filter_uuids: null,
      task_state_filter_uuids: null,
      task_template_filter_uuids: null,
      assignee_uuids: null,
      task_sort_by: "RECENTLY_MOVED_FILINGS",
      subscriptionFilter: null,
      generate_report: false,
    });

    setRecentlyMovedFilings(recentlyMovedFilingsData);
  };
  useEffect(() => {
    fetchAndSaveActionItemsData();
  }, []);

  const platformSubs = data?.platform_customers;
  return (
    <DashboardLayout header={<div />}>
      <div>
        {/* <p className="welcomeName">Welcome {authtoken.first_name}!</p> */}
        {isAdmin && (
          <>
            <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-4">
              <div className="t-p-2 t-font-semibold">
                Customer Subscriptions
              </div>
              <div className="t-flex t-flex-row t-items-center t-gap-10 t-p-2 t-font-normal sm:t-flex-col">
                <div>
                  <div>Platform Subscriptions</div>
                  <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                    <h4>{platformSubs || ""}</h4>
                    {/* <DOD previousDayData="" presentDayData="" /> */}
                  </div>
                </div>
                <div>
                  <div>Pro-Bookkeeping Add-On</div>
                  <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                    <h4>{data?.pro_customers}</h4>
                    {/* <DOD previousDayData="" presentDayData="" /> */}
                  </div>
                </div>
                <div>
                  <div>Onboarded</div>
                  <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                    <h4>{data?.onboarded_customers}</h4>
                    {/* <DOD previousDayData="" presentDayData="" /> */}
                  </div>
                </div>
                <div>
                  <div>Unconverted</div>
                  <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                    <h4>{data?.unconverted_customers}</h4>
                    {/* <DOD previousDayData="" presentDayData="" /> */}
                  </div>
                </div>
                <div>
                  <div>Churned</div>
                  <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                    <h4>{data?.churned_customers}</h4>
                    {/* <DOD previousDayData="" presentDayData="" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-4 my-4">
              <div className="t-p-2 t-font-semibold">Action Items</div>
              <div className=" t-flex t-flex-row t-items-center t-gap-10 t-p-2 t-font-normal sm:t-flex-col">
                <div>
                  <div>Overdue filings</div>
                  <ConditionalLink
                    to={"/filings?task_state_filter_uuids=overdue"}
                    className="all:unset t-cursor-pointer t-text-subtext-sm t-text-purple hover:t-text-purple-80"
                  >
                    <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                      <h4>{overdueFilings?.data?.data?.total_count || 0}</h4>
                    </div>
                  </ConditionalLink>
                </div>
                <div>
                  <div>Recently moved filings</div>
                  <ConditionalLink
                    to={"/filings?task_sort_by=RECENTLY_MOVED_FILINGS"}
                    className="all:unset t-cursor-pointer t-text-subtext-sm t-text-purple hover:t-text-purple-80"
                  >
                    <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                      <h4>
                        {recentlyMovedFilings?.data?.data?.total_count || 0}
                      </h4>
                    </div>
                  </ConditionalLink>
                </div>
                <div>
                  <div>Unreplied Chats</div>
                  <ConditionalLink
                    to={"/chat/unreplied"}
                    className="all:unset t-cursor-pointer t-text-subtext-sm t-text-purple hover:t-text-purple-80"
                  >
                    <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                      <h4>
                        {channels.length >= 100 ? "99+" : channels.length}
                      </h4>
                    </div>
                  </ConditionalLink>
                </div>
                <div>
                  <div>Unresolved Tickets</div>
                  <ConditionalLink
                    to={"/tickets"}
                    className="all:unset t-cursor-pointer t-text-subtext-sm t-text-purple hover:t-text-purple-80"
                  >
                    <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                      <h4>{unresolved_tickets}</h4>
                    </div>
                  </ConditionalLink>
                </div>
              </div>
            </div>
            <div className="t-flex t-flex-col">
              <div className="t-flex t-w-full t-gap-x-6 t-mb-8">
                <ChatSLAMetricCard />
                <CsatSLAMetricCard />
              </div>
              <div className="t-flex t-flex-col t-gap-y-6">
                <FilingsSLAMetricCard />
                <TicketsSLAMetricCard />
              </div>
            </div>

            <div className="t-my-4 t-rounded-lg t-border t-border-solid t-border-neutral-10">
              <iframe
                width="1000"
                height="600"
                src="https://analytics.june.so/a/6185/report/93562/dau/uLW-OA_uWJfFVOlqNsHPhg?insight_id=0&level=user"
                title="June - [object Object]"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <div className="t-mb-4 t-rounded-lg t-border t-border-solid t-border-neutral-10">
              <iframe
                width="1000"
                height="600"
                src="https://analytics.june.so/a/6185/report/93562/wau/7PnGA6AZPLc6DPTPSbTz7w?insight_id=1&level=user"
                title="June - [object Object]"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <div className="t-mb-4 t-rounded-lg t-border t-border-solid t-border-neutral-10">
              <iframe
                width="1000"
                height="600"
                src="https://analytics.june.so/a/6185/report/93562/mau/_1SlLdcE3MdmU5OMJtHo-g?insight_id=2&level=user"
                title="June - [object Object]"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </>
        )}
        <br />
        {isCpaInkleServiceAdmin && (
          <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-4">
            <div className="t-p-2 t-font-semibold">
              Books Filing Data - 2022
            </div>
            <div className=" t-flex t-flex-row t-items-center t-gap-10 t-p-2 t-font-normal sm:t-flex-col">
              {statListBooks.map((stat) => {
                return (
                  <div key={stat[1]}>
                    <div>{stat[0]}</div>
                    <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                      <h4>{stat[1]}</h4>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <br />
        {isCpaInkleServiceAdmin && (
          <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-4">
            <div className="t-p-2 t-font-semibold">1120 Filing Data - 2022</div>
            <div className=" t-flex t-flex-row t-items-center t-gap-10 t-p-2 t-font-normal sm:t-flex-col">
              {statList1120.map((stat) => {
                return (
                  <div key={stat[1]}>
                    <div>{stat[0]}</div>
                    <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                      <h4>{stat[1]}</h4>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <br />
        {isCpaInkleServiceAdmin && (
          <div className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-p-4">
            <div className="t-p-2 t-font-semibold">FBAR Filing Data</div>
            <div className=" t-flex t-flex-row t-items-center t-gap-10 t-p-2 t-font-normal sm:t-flex-col">
              {statList.map((stat) => {
                return (
                  <div key={stat[1]}>
                    <div>{stat[0]}</div>
                    <div className="t-flex t-flex-row t-font-semibold sm:t-justify-center">
                      <h4>{stat[1]}</h4>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default WelcomeUser;
