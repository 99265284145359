import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { InteractiveReport } from "components/Reports/InteractiveReport";
import { YourReports } from "components/Reports/YourReports";
import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  GENERAL_LEDGER,
  INCOME_STATEMENT,
  SAVED_REPORTS,
  TRIAL_BALANCE,
} from "constants/bookkeeping";
import { useActionItems } from "hooks/useActionItems";
import { usePageTitle } from "hooks/usePageTitle";
import { useRef } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

export type ReportsType =
  | typeof BALANCE_SHEET
  | typeof INCOME_STATEMENT
  | typeof CASH_FLOW_STATEMENT
  | typeof SAVED_REPORTS;

export type GeneralLedgerReportType =
  | typeof GENERAL_LEDGER
  | typeof TRIAL_BALANCE;

export const Reports = () => {
  usePageTitle("Live Financials");
  const rRef = useRef(null);
  const { isReconciliationRequired } = useActionItems();
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const isIncomeStatement = pathname.includes("income-statement");
  const isCashFlowStatement = pathname.includes("cash-flow-statement");
  const isSavedReports = pathname.includes("saved-reports");
  const isBlanceSheet =
    !isIncomeStatement && !isCashFlowStatement && !isSavedReports;

  return (
    <>
      <Tab.Root asChild defaultValue={pathname} value="MATCH">
        <DashboardLayout
          ref={rRef}
          header={
            <Header
              v2
              title={
                <div className="t-flex t-items-center t-gap-2 t-h-full">
                  Live Financials
                  {isReconciliationRequired && (
                    <ActionRequiredPopOver
                      type="RECONCILIATION"
                      btnText="Reconcile"
                    />
                  )}
                </div>
              }
              bottom={
                <Tab.List>
                  <Tab.NavTrigger
                    exact
                    to={`${url}${search}`}
                    value={isBlanceSheet ? "MATCH" : ""}
                  >
                    Balance Sheet
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/income-statement${search}`}
                    value={isIncomeStatement ? "MATCH" : ""}
                  >
                    Income Statement
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/cash-flow-statement${search}`}
                    value={isCashFlowStatement ? "MATCH" : ""}
                  >
                    Cash Flow Statement
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/saved-reports${search}`}
                    value={isSavedReports ? "MATCH" : ""}
                  >
                    Saved Reports
                  </Tab.NavTrigger>
                </Tab.List>
              }
            />
          }
        >
          <Switch>
            <Route exact path={path}>
              <InteractiveReport reportType={BALANCE_SHEET} />
            </Route>
            <Route exact path={`${path}/income-statement`}>
              <InteractiveReport reportType={INCOME_STATEMENT} />
            </Route>
            <Route exact path={`${path}/cash-flow-statement`}>
              <InteractiveReport reportType={CASH_FLOW_STATEMENT} />
            </Route>
            <Route exact path={`${path}/saved-reports`}>
              <YourReports />
            </Route>
          </Switch>
        </DashboardLayout>
      </Tab.Root>
    </>
  );
};
