import { pluralize } from "utils/pluralize";
import { array, object, string, number, mixed } from "yup";

const notRequired = {
  description: string().notRequired(),
  credit: number().notRequired(),
  debit: number().notRequired(),
  category: string().notRequired(),
};

const required = {
  description: string().required(),
  credit: number().required(),
  debit: number().required(),
  category: string().required(),
};

export const journalEntrySchema = () =>
  object({
    transactions: array().of(
      object().when({
        is: (value: Record<string, string>) => {
          return Object.entries(value).every(([key, value]) =>
            key === "id" ? true : !Boolean(value)
          );
        },
        then: object(notRequired),
        otherwise: object(required),
      })
    ),
    transaction_date: string().required("Please select date").nullable(),
    ledger_entry_name: string().required("Please enter journal entry title"),
    frequency: string().when("is_recurring_enabled", {
      is: true,
      then: string().required("Please select frequency"),
      otherwise: string().notRequired(),
    }),

    end_date: mixed().when(["is_recurring_enabled", "ends_on"], {
      is: (is_recurring_enabled: boolean, ends_on: string) => {
        return is_recurring_enabled && ends_on === "END_DATE";
      },
      then: string()
        .required("Please select end date")
        .typeError("Please select a valid end date"),
      otherwise: mixed().nullable(),
    }),

    max_recurring_count: mixed().when(["is_recurring_enabled", "ends_on"], {
      is: (is_recurring_enabled: boolean, ends_on: string) => {
        return is_recurring_enabled && ends_on === "NO_OF_ENTRIES";
      },
      then: () =>
        number()
          .test({
            name: "min recurring count",
            test: (value, context) => {
              if (context.parent.recurring_count && value) {
                if (value <= context.parent.recurring_count) {
                  return context.createError({
                    message: `${pluralize(
                      context.parent.recurring_count,
                      "journal entry",
                      "journal entries"
                    )} have already been created. Please enter a number greater than ${
                      context.parent.recurring_count
                    }`,
                  });
                }

                return true;
              }

              return true;
            },
          })
          .min(1, "Please enter a number greater than 0")
          .required("Please enter number of entries")
          .typeError("Please enter a valid number"),
      otherwise: mixed().nullable(),
    }),
  });
