import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { Price } from "components/Price";
import { Divider } from "components/design/Divider";
import { ArrowRight } from "components/icons/ArrowRight";
import { GROUP_BOOKS_CONSOLIDATION } from "constants/addons";
import { Form, Formik } from "formik";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAddProductToCartMutation } from "store/apis/products";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import {
  resetAllAddonDataPayload,
  setAddonPayload,
  setAddonType,
  setOpenCheckoutModal,
} from "store/slices/addons";
import { RootState } from "store/store";
import { capitalize } from "utils/capitalize";

export const GroupConsolidation = () => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonData?.subscription_type === GROUP_BOOKS_CONSOLIDATION
  );
  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const { data: subscriptions } = useGetSubscriptionsQuery({});

  const [addToCart, { isLoading: addingToCart }] =
    useAddProductToCartMutation();

  const {
    dropdownContent = [] as {
      subscription_name: string;
      amount: string;
      uuid: string;
      billing_cycle: "YEARLY" | "MONTHLY" | "QUARTERLY";
    }[],
    subscription_name,
  } = addonData || {};

  const handleAddonNext = async (values: {
    subscriptionData: {
      uuid: string;
      subscriptionName: string;
      amount: string;
      billingCycle: string;
    };
  }) => {
    const subscription = subscriptions?.find(
      (s) => s.uuid === values?.subscriptionData?.uuid
    );

    if (isCartSubscriptionActive && groupId && values && subscription) {
      await addToCart({
        groupId: groupId,
        entityId,
        payload: {
          product_id: values?.subscriptionData?.uuid,
          product_content_type_id: subscription.product_content_type_id,
          selected_tier_amount: values?.subscriptionData?.amount,
        },
      });
      dispatch(setAddonType(""));
      setShowAddonModal(false);
      return;
    }
    dispatch(
      setAddonPayload({
        title: values?.subscriptionData?.subscriptionName,
        amount: values?.subscriptionData?.amount,
        uuid: values?.subscriptionData?.uuid,
        billing_cycle: values?.subscriptionData?.billingCycle,
        type: addonData?.subscription_type,
      })
    );
    dispatch(setAddonType(""));
    dispatch(setOpenCheckoutModal());
    setShowAddonModal(false);
  };

  const onClose = () => {
    setShowAddonModal(false);
    dispatch(setAddonType(""));
    dispatch(resetAllAddonDataPayload());
  };

  return (
    <Modal.Root open={showAddonModal} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{subscription_name}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            subscriptionData: {
              subscriptionName: dropdownContent?.[0]?.subscription_name || "",
              amount: dropdownContent?.[0]?.amount || "",
              uuid: dropdownContent?.[0]?.uuid || "",
              billingCycle: dropdownContent?.[0]?.billing_cycle || "",
            },
          }}
          onSubmit={handleAddonNext}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className="t-m-0 t-w-full">
              <Modal.Body>
                <div className="t-flex t-flex-col t-gap-5">
                  <div className="t-text-body t-text-text-60">
                    Consolidated financials of all your entities
                  </div>
                  <div className="t-w-full t-flex t-gap-6 t-items-end">
                    <div className="t-w-[280px]">
                      <DropDown.Root>
                        <DropDown.Trigger asChild>
                          <div className="t-flex t-flex-col t-gap-[6px]">
                            <div className="t-font-sans t-text-caption t-text-neutral-80">
                              Frequency
                            </div>
                            <div className="secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-2.5 t-text-body t-font-medium t-drop-shadow-i-dropdown">
                              <>
                                <div className="t-flex t-items-center t-justify-between t-w-full">
                                  <div className="t-text-body">
                                    {values?.subscriptionData?.billingCycle
                                      ? capitalize(
                                          values?.subscriptionData?.billingCycle
                                        )
                                      : "Select plan"}
                                  </div>
                                  <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                                    <ArrowRight
                                      stroke="2.5"
                                      color="currentColor"
                                    />
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </DropDown.Trigger>
                        <DropDown.Portal>
                          <DropDown.Content
                            sideOffset={8}
                            side="bottom"
                            className="t-w-[280px] t-max-h-40 t-overflow-auto"
                          >
                            {dropdownContent?.map(
                              ({
                                billing_cycle,
                                subscription_name,
                                amount,
                                uuid,
                              }) => (
                                <DropDown.Item
                                  key={uuid}
                                  onSelect={() =>
                                    setFieldValue("subscriptionData", {
                                      subscriptionName: subscription_name,
                                      amount: amount,
                                      uuid: uuid,
                                      billingCycle: billing_cycle,
                                    })
                                  }
                                >
                                  {/* @ts-ignore */}
                                  {capitalize(billing_cycle)}
                                </DropDown.Item>
                              )
                            )}
                          </DropDown.Content>
                        </DropDown.Portal>
                      </DropDown.Root>
                    </div>
                    <div>
                      <div className="t-text-text-30 t-text-body-sm">
                        {capitalize(values?.subscriptionData?.billingCycle)}{" "}
                        subscription
                      </div>
                      <div className="t-text-body">
                        {values?.subscriptionData?.amount
                          ? `$${values?.subscriptionData?.amount}`
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="t-flex t-justify-between">
                    <div className="t-text-body-sm t-text-text-30">Total</div>
                    <div className="t-text-body">
                      {values?.subscriptionData?.amount && (
                        <Price
                          size="medium"
                          //@ts-ignore
                          cycle={values?.subscriptionData?.billingCycle}
                          amount={values?.subscriptionData?.amount}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-end t-gap-3">
                <Button type="button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  isLoading={isSubmitting}
                  disabled={!values?.subscriptionData?.amount || isSubmitting}
                >
                  Next
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
