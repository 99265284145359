import {
  BOOKKEEPING,
  BOOKKEEPING_MANAGE,
  BOOKKEEPING_SOFTWARE,
  BOOKKEEPING_SOFTWARE_MANAGE,
  BOOKS_PLUS_PLAN,
  BOOKS_PLUS_PLAN_MANAGE,
  GROUP_BOOKS_CONSOLIDATION,
  GROUP_BOOKS_CONSOLIDATION_MANAGE,
  MAILROOM,
  MAILROOM_MANAGE,
  REGISTERED_AGENT,
  REGISTERED_AGENT_MANAGE,
} from "constants/addons";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { BookkeepingPlan } from "./BookkeepingPlan";
import { BooksSoftware } from "./BooksSoftware";
import { MailRoom } from "./MailRoom";
import { BookKeepingProManage } from "./ManageModals/BookKeepingProManage";
import { BooksSoftwareManage } from "./ManageModals/BooksSoftwareManage";
import { MailRoomManage } from "./ManageModals/MailRoomManage";
import { RegisteredAgentManage } from "./ManageModals/RegisteredAgentManage";
import { RegisteredAgent } from "./RegisteredAgent";
import { GroupConsolidation } from "./GroupConsolidation";
import { GroupConsolidationManage } from "./ManageModals/GroupConsolidationManage";
import BookkeepingPlusPlan from "./BookkeepingPlusPlan";
import BookkeepingPlusPlanManage from "./ManageModals/BookkeepingPlusPlanManage";

export const AddonsModal = () => {
  const { addonType } = useSelector((state: RootState) => state.addonsSlice);

  switch (addonType) {
    case BOOKKEEPING_SOFTWARE: {
      return <BooksSoftware />;
    }
    case BOOKKEEPING: {
      return <BookkeepingPlan />;
    }
    case REGISTERED_AGENT: {
      return <RegisteredAgent />;
    }
    case MAILROOM: {
      return <MailRoom />;
    }
    case GROUP_BOOKS_CONSOLIDATION: {
      return <GroupConsolidation />;
    }
    case BOOKKEEPING_SOFTWARE_MANAGE: {
      return <BooksSoftwareManage />;
    }
    case BOOKKEEPING_MANAGE: {
      return <BookKeepingProManage />;
    }
    case REGISTERED_AGENT_MANAGE: {
      return <RegisteredAgentManage />;
    }
    case MAILROOM_MANAGE: {
      return <MailRoomManage />;
    }
    case GROUP_BOOKS_CONSOLIDATION_MANAGE: {
      return <GroupConsolidationManage />;
    }
    case BOOKS_PLUS_PLAN:
      return <BookkeepingPlusPlan />;

    case BOOKS_PLUS_PLAN_MANAGE:
      return <BookkeepingPlusPlanManage />;
    default:
      return <></>;
  }
};
