import { LoadingToast } from "components/design/LoadingToast";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { SAMPLE_CSV } from "constants/bookkeeping";
import { OPEN_ITEM_CUSTOM_TYPE } from "constants/chatType";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import {
  useSendClarificationRequestMutation,
  useAddOpenItemsMutation,
} from "store/apis/openItem";
import { closeUploadCSVModal } from "store/slices/openItem";
import { RootState } from "store/store";
import { openLink } from "utils/openLink";
import { OpenItemTable } from "./OpenItemTable";
import { UploadCSV } from "./UploadCSV";

export const AddOpenItem = () => {
  const { alertToast } = useToast();
  const dispatch = useDispatch();
  const { activeChannelId } = useSelector((state: RootState) => state.chat);
  const { activeChannelGroupId: groupId } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );
  const [
    uploadCSV,
    { data: uploadedCSV, isSuccess: isUploaded, isLoading: isUploading, reset },
  ] = useAddOpenItemsMutation();

  const [sendClarificationRequest, { isLoading: requestingForClarification }] =
    useSendClarificationRequestMutation();

  const { showUpdloadCsvModal: show, channelId } = useSelector(
    (state: RootState) => state.openItem
  );

  const { csv_id } = uploadedCSV || {};

  const onDrop = async (files: File[]) => {
    try {
      await uploadCSV({ groupId, file: files[0] }).unwrap();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onClose = () => {
    reset();
    dispatch(closeUploadCSVModal());
  };

  const onSendForClarification = async () => {
    try {
      if (csv_id && groupId) {
        await sendClarificationRequest({
          groupId,
          csvId: csv_id,
          channelUrl: channelId,
          customType: OPEN_ITEM_CUSTOM_TYPE,
        }).unwrap();
        onClose();
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <>
      <Modal.Root open={show} onOpenChange={onClose}>
        <Modal.Content size="xxl">
          <Modal.Header>
            <Modal.Title>Add Open Items</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {isUploaded ? (
              <OpenItemTable csvId={csv_id || ""} addOpenItem />
            ) : (
              <UploadCSV onDrop={onDrop} />
            )}
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button type="button" onClick={() => openLink(SAMPLE_CSV)}>
              Download sample file
            </Button>
            <Button
              customType="primary"
              type="submit"
              disabled={!isUploaded || requestingForClarification}
              onClick={onSendForClarification}
            >
              Request for clarification
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <LoadingToast loading={isUploading} title="Uploading">
        Processing your file...
      </LoadingToast>
    </>
  );
};
