import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "classnames";
import CalendarButtonAction from "components/calendar/CalendarButtonAction";
import { ConditionalLink } from "components/conditionalLink";
import MeetingRequestCalendly from "components/dashboard/MeetingRequestCalendly";
import CommonModal from "components/design/modal";
import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import Modal from "components/Modal/Modal";
import {
  ADDED_DEADLINES_TO_CALENDAR,
  OPENED_ADD_TO_CALENDAR_MODAL,
  OPENED_REMINDER_SUBSCRIPTION_MODAL,
  STARTED_DOWNLOAD_CALENDAR,
} from "constants/analyticsEvents";
import { CALENDAR } from "constants/calendarTypes";
import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import { ANAND_PHONE, meetWithAnand } from "constants/meetingRequestUrls";
import { CALENDAR_SESSION_ID } from "constants/session";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { useAnalytics } from "hooks/useAnalytics";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import ArrowDownLight from "static/images/ArrowDownLight.svg";
import CalendarBlankMeeting from "static/images/CalendarBlankMeeting.svg";
import Envelope from "static/images/Envelope.svg";
import EnvelopeSimple from "static/images/EnvelopeSimple.svg";
import GoogleCalendar from "static/images/GoogleCalendar.svg";
import IcalDownload from "static/images/IcalDownload.svg";
import IcalReminder from "static/images/IcalSubscribe.svg";
import LinkIcon from "static/images/LinkIcon.svg";
import PhoneIcon from "static/images/PhoneIcon.svg";
import ThreeDots from "static/images/ThreeDots.svg";
import WhatsappIcon from "static/images/WhatsappIcon.svg";
import {
  useAddToGoogleCaledarMutation,
  useLazyCalendarDownloadPdfQuery,
  useLazySendPdfToEmailQuery,
} from "store/apis/calendar";
import { RootState } from "store/store";
import { formatDate } from "utils/formatDate";
import { getSessionId } from "utils/sessionId";
import { ReminderModal } from "./ReminderModal";
import { openLink } from "utils/openLink";
import { PHONE } from "constants/help";
import IcalWhatsapp from "static/images/IcalWhatsapp.svg";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const ContactCalendar = () => {
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <div className="t-group">
            <Button size="small" customType="secondary">
              Contact us
              <img
                src={ArrowDownLight}
                className="t-ml-2 t-rotate-0 t-transform t-transition-transform group-data-state-open:t-rotate-180"
                alt="dropDown"
              />
            </Button>
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="t-z-50 -t-translate-x-12 t-transform t-rounded-lg t-bg-white t-px-3 t-py-4 t-drop-shadow-dropdown"
            sideOffset={10}
          >
            <DropdownItem
              asChild
              className="f t-flex t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
            >
              <ConditionalLink
                className="t-text-neutral-90"
                to="https://api.whatsapp.com/send?phone=16282774045&text=Hello,%20I%20have%20a%20question%20about%20https%3A%2F%2Fapp.inkle.io%2Finklecalendar"
              >
                <img src={WhatsappIcon} alt="Help in whatsapp" />
                <span>Say "Hi" on WhatsApp</span>
              </ConditionalLink>
            </DropdownItem>

            <DropdownItem
              onClick={() => setShowCalendlyModal(true)}
              className="f t-flex t-cursor-pointer t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
            >
              <img src={CalendarBlankMeeting} alt="Help in whatsapp" />
              <span>Schedule a meeting</span>
            </DropdownItem>

            <DropdownItem asChild>
              <ConditionalLink
                className="t-text-neutral-90"
                to="mailto:hello@inkle.io"
              >
                <img src={EnvelopeSimple} alt="Help in whatsapp" />
                <span>Send a mail</span>
              </ConditionalLink>
            </DropdownItem>

            <DropdownItem asChild>
              <ConditionalLink
                to={`tel:${ANAND_PHONE}`}
                className="all:unset t-cursor-pointer t-border-none t-bg-[transparent] t-outline-none"
                target="_blank"
                rel="noreferrer"
              >
                <img src={PhoneIcon} alt="call" />
                <span className="t-text-neutral-90">{ANAND_PHONE}</span>
              </ConditionalLink>
            </DropdownItem>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <CommonModal
        body={<MeetingRequestCalendly calendlyLink={meetWithAnand} />}
        show={showCalendlyModal}
        closeModal={() => setShowCalendlyModal(false)}
        modalTitle={undefined}
        footer={undefined}
        className={undefined}
        width="500"
        padding={undefined}
        header={undefined}
      />
    </>
  );
};

const DropdownItem = (props: DropdownMenu.DropdownMenuItemProps) => (
  <DropdownMenu.Item
    className="f t-flex t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
    {...props}
  />
);

export const BottomBar = ({
  total,
  makeSpaceForChatInMobile = false,
}: {
  total: number;
  makeSpaceForChatInMobile?: boolean;
}) => {
  const [addToGoogleCalendar, { isLoading: addingToCalendar }] =
    useAddToGoogleCaledarMutation();
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showPdfSendModal, setShowPdfSendModal] = useState(false);
  const [showAllIcons, setShowAllIcons] = useState(false);

  const { authtoken } = useContext(authContext);
  const entityId = useCurrentEntityId();
  const {
    open: openAddToCalendarModal,
    close: closeAddToCalendarModal,
    isOpen: isAddToCalendarModalOpen,
  } = useModal();

  const { email } = authtoken || {};

  const { trackEvent } = useAnalytics();

  const calendarType =
    useSelector((state: RootState) => state.calendar.calendarType) || CALENDAR;

  const calendarYear = useSelector((state: RootState) => state.calendar.year);
  const [
    getCalendarPdf,
    { isLoading: calendarPdfLoading, isFetching: calendarPdfFetching },
  ] = useLazyCalendarDownloadPdfQuery();

  const [
    sendPdfToEmail,
    {
      isLoading: calendarEmailPdfLoading,
      isFetching: calendarEmailPdfFetching,
    },
  ] = useLazySendPdfToEmailQuery();

  const [showCalendlyModal, setShowCalendlyModal] = useState(false);
  const { alertToast, successToast } = useToast();

  const copyToClipBoard = async () => {
    const calendarShareLink =
      `${
        window.location.href +
        "/" +
        getSessionId(CALENDAR_SESSION_ID) +
        "/" +
        calendarType
      }` || "";

    try {
      await navigator.clipboard.writeText(calendarShareLink);
      successToast({ message: COPIED_TEXT });
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
  };

  const onSubmitEmailPdf = async ({ email }: { email: string }) => {
    await sendPdfToEmail({ email, calendarYear, entityId }).unwrap();
    setShowPdfSendModal(false);
  };

  const downloadCalendar = async () => {
    const beforeDownload = dayjs();
    const { encoded_pdf } = await getCalendarPdf({
      calendarYear,
      entityId,
    }).unwrap();

    const timeDiff = dayjs().diff(beforeDownload, "seconds");

    trackEvent(STARTED_DOWNLOAD_CALENDAR, {
      time_taken: timeDiff,
    });

    const linkElement = document.createElement("a");
    linkElement.target = "_blank";
    linkElement.href = `data:application/pdf;base64,${encoded_pdf}`;
    linkElement.download = `Inkle US Tax Deadlines Calendar ${formatDate()}`;
    linkElement.click();
  };

  const closeReminderModal = () => {
    setShowReminderModal(false);
  };

  const openReminderModal = () => {
    trackEvent(OPENED_REMINDER_SUBSCRIPTION_MODAL);
    setShowReminderModal(true);
  };

  const addToCalendar = async ({ email }: { email: string }) => {
    await addToGoogleCalendar({ email, calendarYear, entityId }).unwrap();
    successToast({ message: "Please check your email to accept the invites" });
    trackEvent(ADDED_DEADLINES_TO_CALENDAR, { email });
    closeAddToCalendarModal();
  };

  const startAddToCalendar = () => {
    trackEvent(OPENED_ADD_TO_CALENDAR_MODAL);
    openAddToCalendarModal();
  };

  return (
    <>
      <div className="t-hidden t-w-full t-items-center t-bg-surface-lighter-grey md:t-flex">
        <div className="t-grow-1 t-shrink-0 t-basis-[470px] t-py-4 t-pl-7 sm:t-pr-4 md:t-flex md:t-pr-16"></div>
        <div
          className={cx(
            "t-flex t-w-full t-justify-between t-py-4 t-pl-5 t-pr-7"
          )}
        >
          <div />
          <div
            className={cx("t-flex t-gap-4", {
              "t-mr-20": makeSpaceForChatInMobile,
            })}
          >
            <CalendarButtonAction
              addToCalendar={startAddToCalendar}
              calendarPdfLoading={calendarPdfLoading || calendarPdfFetching}
              downloadCalendar={downloadCalendar}
              setShowCalendlyModal={() => setShowCalendlyModal(true)}
              openShareModal={copyToClipBoard}
              openReminderModal={openReminderModal}
              openEmailPdfModal={() => setShowPdfSendModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="t-fixed t-bottom-0 t-right-0 t-block t-w-full t-items-center t-justify-between  t-bg-white t-py-4 t-pl-4 t-pr-4 t-drop-shadow-drawer md:t-hidden md:t-pr-16 md:t-pt-0">
        <div className="t-flex t-flex-col t-gap-4">
          <div className="t-flex t-justify-between">
            <div
              className={cx("t-flex t-gap-2", {
                "t-pr-20": makeSpaceForChatInMobile,
              })}
            >
              <div
                className={cx(
                  "t-flex t-gap-2 t-transition-all t-duration-300",
                  {
                    "t-pointer-events-none t-opacity-0": showAllIcons,
                  }
                )}
              >
                <Button customType="icon" onClick={copyToClipBoard}>
                  <img src={LinkIcon} alt="IcalShare" />
                </Button>
                <Button
                  customType="icon"
                  onClick={() => openLink(`https://wa.me/${PHONE}`)}
                >
                  <img src={IcalWhatsapp} alt="IcalWhatsapp" />
                </Button>
                <Button customType="icon" onClick={startAddToCalendar}>
                  <img
                    className="t-w-[26px]"
                    src={GoogleCalendar}
                    alt="Add to google calendar"
                  />
                </Button>
                <Button
                  customType="icon"
                  onClick={() => setShowCalendlyModal(true)}
                >
                  <img src={CalendarBlankMeeting} alt="Book a zoom call" />
                </Button>
              </div>

              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <div>
                    <Button
                      customType="icon"
                      onClick={(e) => setShowAllIcons((s) => !s)}
                    >
                      <img src={ThreeDots} alt="Show more options" />
                    </Button>
                  </div>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                  <DropdownMenu.Content
                    sideOffset={10}
                    side="top"
                    className="t-z-50 -t-translate-x-4 t-transform t-rounded-lg t-bg-white t-px-3 t-py-4 t-drop-shadow-dropdown"
                  >
                    <DropdownItem
                      asChild
                      onClick={openReminderModal}
                      className="t-flex t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
                    >
                      <div>
                        <img src={IcalReminder} alt="IcalReminder" />
                        Subscribe to reminders
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      onClick={copyToClipBoard}
                      asChild
                      className="t-flex t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
                    >
                      <div>
                        <img src={LinkIcon} alt="IcalShare" />
                        Copy unique link
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      asChild
                      onClick={startAddToCalendar}
                      className="t-flex t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
                    >
                      <div>
                        <img
                          className="t-h-6 t-w-5"
                          src={GoogleCalendar}
                          alt="Add to google calendar"
                        />
                        <span>Add to calendar</span>
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setShowCalendlyModal(true)}
                      asChild
                      className="t-flex t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
                    >
                      <div>
                        <img
                          src={CalendarBlankMeeting}
                          alt="Book a zoom call"
                        />
                        Schedule a meeting
                      </div>
                    </DropdownItem>
                    <DropdownItem
                      asChild
                      className="t-flex t-gap-3 t-p-2 t-outline-none hover:t-bg-neutral-0"
                    >
                      <ConditionalLink
                        className="t-text-neutral-90"
                        to="https://api.whatsapp.com/send?phone=16282774045&text=Hello,%20I%20have%20a%20question%20about%20https%3A%2F%2Fapp.inkle.io%2Finklecalendar"
                      >
                        <img src={WhatsappIcon} alt="Help in whatsapp" />
                        Say “Hi” on Whatsapp
                      </ConditionalLink>
                    </DropdownItem>
                    <DropdownItem asChild>
                      <ConditionalLink
                        className="t-text-neutral-90"
                        to="mailto:hello@inkle.io"
                      >
                        <img src={EnvelopeSimple} alt="Help in whatsapp" />
                        <span>Send a mail</span>
                      </ConditionalLink>
                    </DropdownItem>
                  </DropdownMenu.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </div>
          </div>
        </div>
      </div>

      {/* @ts-ignore */}
      <ReminderModal isOpen={showReminderModal} onClose={closeReminderModal} />

      {/* @ts-ignore */}
      <CommonModal
        show={showPdfSendModal}
        closeModal={() => {
          setShowPdfSendModal(false);
        }}
        modalTitle={<p className="t-m-0 t-text-subtitle">Send Calendar</p>}
        body={
          <Formik
            onSubmit={onSubmitEmailPdf}
            initialValues={{
              email: "",
            }}
          >
            {({ isValid }) => (
              <Form className="t-m-0 t-mb-6 t-w-full">
                <div className="t-flex t-flex-col t-gap-4">
                  <TextInput block label="Email" name="email" type="email" />
                  <div className="t-flex t-justify-end t-gap-5">
                    <Button
                      type="submit"
                      customType="primary"
                      disabled={!isValid}
                      isLoading={
                        calendarEmailPdfFetching || calendarEmailPdfLoading
                      }
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        }
      />

      <CommonModal
        body={<MeetingRequestCalendly calendlyLink={meetWithAnand} />}
        show={showCalendlyModal}
        closeModal={() => setShowCalendlyModal(false)}
        modalTitle={undefined}
        footer={undefined}
        className={undefined}
        width="500"
        padding={undefined}
        header={undefined}
      />

      <Modal.Root
        open={isAddToCalendarModalOpen}
        onOpenChange={closeAddToCalendarModal}
      >
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="md:t-w-80">
            <Modal.Title>Add to calendar</Modal.Title>
            <Modal.Close />

            <Modal.Description className="t-mb-4 t-mt-2 t-block t-text-body t-text-text-60">
              Future changes won't get updated automatically, please add to your
              calendar again whenerver you change your settings
            </Modal.Description>

            <Formik
              onSubmit={addToCalendar}
              initialValues={{
                email: email,
              }}
            >
              <Form className="t-m-0 t-flex t-flex-col t-gap-4">
                <TextInput required type="email" name="email" label="Email" />
                <div className="t-flex t-justify-end">
                  <Button
                    isLoading={addingToCalendar}
                    disabled={addingToCalendar}
                    customType="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Formik>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
    </>
  );
};
