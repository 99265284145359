import { useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

//components
import EditForm from "./editForm";
import SuggestedDocument from "../documents/suggestedDocument/suggestedDocument";

//api
import { putDocData } from "../../apis/putDocData";
import { stateUpdate } from "../../apis/stateUpdate";

//style
import "../../static/styles/components/step1.css";

//images

//helper
import { Message } from "./helper";

//context
import authContext from "../../jwt_context&axios/authContext";
import classNames from "classnames";
import ConditionalDynamicToolTip from "components/design/conditionalDynamicToolTip";
import { FormUploadDocuments } from "components/FormUploadDocuments";
import { produce } from "immer";
import { useParams } from "react-router-dom";
import { Button } from "components/DesignSystem/Button/Button";
import TaskButtonBar from "./TaskButtonBar";
import { useAnalytics } from "hooks/useAnalytics";
import { SUGGESTED_DOCS_UPLOADED } from "constants/analyticsEvents";
import * as bootstrap from "bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { openTaskReview } from "store/slices/TaskReview";
import { FilingReviewModal } from "./FilingReviewModal";
import ToolTip from "components/design/toolTip";
import { SuggestedDocumentDND } from "./SuggestedDocumentDND";

const FormTooltip = ({ tooltipText }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      new bootstrap.Tooltip(ref.current);
    }
  }, []);
  return (
    <ToolTip text={tooltipText} side="right">
      <i className="fas fa-question-circle ms-1 d-inline-block" />
    </ToolTip>
  );
};

export default function DriveEngine({
  chk,
  next,
  prev,
  docId,
  mainId,
  current,
  task,
  data,
  uuid,
  inkleDocId,
  groupId,
  isArchived,
  isWseries,
  parentRef,
  isPrevPricingStep,
}) {
  const [apiLoading, setApiLoading] = useState(false);
  const { authtoken } = useContext(authContext);
  const { taskId } = useParams();
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { showTaskReviewStep, showTaskReview } = useSelector(
    (state) => state.taskReview
  );

  const documentsRedirect = () => {
    if (authtoken.is_any_service_user)
      return `/crm/${groupId}/documents/${inkleDocId}`;
    else return `/documents/${inkleDocId}`;
  };

  const stateUpdateToPreviousState = (e) => {
    stateUpdate({ taskId, id: prev }).then(() => {
      window.location.reload();
    });
  };

  /**
   * Checks if the suggested document has already been uploaded
   * by comparing with the array of uploaded documents for a field.
   */
  const isDocumentUploaded = (uploadedDocs, suggestedDoc) => {
    return uploadedDocs?.some(
      (uploadedDoc) => uploadedDoc?.uuid === suggestedDoc?.uuid
    );
  };

  const onSuggestedDocUpload = () => {
    trackEvent(SUGGESTED_DOCS_UPLOADED, task);
  };

  const openTaskReviewModal = (e) => {
    e?.preventDefault();
    if (showTaskReviewStep) {
      dispatch(openTaskReview());
    }
  };

  const onSubmit = (e) => {
    e?.preventDefault();
    setApiLoading(true);
    stateUpdate({ taskId, id: next }).then(() => {
      saveCheckFields();
    });
  };

  const saveCheckFields = () => {
    let newForm = produce(chk, (draft) => {
      for (let i = 0; i < chk.length; i++) {
        for (let j = 0; j < chk[i].documents_needed.length; j++) {
          const inputElement = document.getElementById(`checkbox-${j}-${i}`);

          if (inputElement?.checked) {
            draft[i].documents_needed[j].value = true;
          } else {
            draft[i].documents_needed[j].value = false;
          }
        }
      }
    });

    putDocData({ docId, body: { form_data: newForm } }).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <EditForm current={current} data={data} uuid={uuid} />
      <Form
        className={classNames("taxing", {
          disabledForm: !mainId || isArchived,
        })}
        onSubmit={showTaskReviewStep ? openTaskReviewModal : onSubmit}
        message={Message({ current, data, uuid })}
      >
        <div className="t-mb-5 t-flex t-flex-col t-gap-8">
          {chk?.map((e, i) => {
            return (
              <span className="t-flex t-flex-col t-gap-10" key={e}>
                <span className="t-text-subtitle t-text-text-100">
                  {e.description}
                </span>
                {e.documents_needed?.map((element, index) => {
                  if (element.type === "checkbox") {
                    return (
                      <div key={index}>
                        <p className={index === 0 ? "docSubHead" : ""}>
                          {index === 0 ? e.description : ""}
                        </p>
                        <ConditionalDynamicToolTip
                          condition={
                            isArchived &&
                            Message({ current, data, uuid, isArchived })
                          }
                        >
                          <label
                            id="check"
                            className="t-items-center t-font-semibold"
                          >
                            <input
                              className="t-mr-2"
                              type="checkbox"
                              name="check"
                              disabled={
                                isArchived ? isArchived : mainId ? false : true
                              }
                              id={`checkbox-${index}-${i}`}
                              defaultChecked={element.value}
                            />

                            {element.label}
                            {element.description && (
                              <FormTooltip tooltipText={element.description} />
                            )}
                          </label>
                        </ConditionalDynamicToolTip>
                      </div>
                    );
                  }

                  if (element.type === "file_upload") {
                    return (
                      <div key={index}>
                        <FormUploadDocuments
                          field={element}
                          docInstanceId={docId}
                          groupId={groupId}
                          suggestedDocUpload={onSuggestedDocUpload}
                        />
                        {element?.suggested_documents?.length ? (
                          <p className="t-my-2 t-text-overline t-text-neutral">
                            Suggested Document below. Drag and Drop to attach
                            your files.
                          </p>
                        ) : null}
                        <div className="t-flex t-max-w-[600px] t-flex-wrap t-items-center t-gap-2">
                          {element?.suggested_documents?.map((doc) => {
                            const isUploaded = isDocumentUploaded(
                              element?.documents,
                              doc
                            );

                            return (
                              <SuggestedDocumentDND
                                isUploaded={isUploaded}
                                key={doc?.uuid}
                                data={JSON.stringify(doc)}
                              >
                                <SuggestedDocument
                                  document={doc}
                                  groupId={groupId}
                                  isUploaded={isUploaded}
                                />
                              </SuggestedDocumentDND>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
              </span>
            );
          })}
        </div>
        {!isArchived && mainId && !apiLoading ? (
          <TaskButtonBar
            justifyContent={isWseries || isPrevPricingStep ? "end" : "between"}
            parentRef={parentRef}
          >
            {!isWseries && !isPrevPricingStep && (
              <Button type="button" onClick={stateUpdateToPreviousState}>
                Previous
              </Button>
            )}
            <div className="t-flex t-gap-4">
              {!isWseries && (
                <Button
                  type="button"
                  customType="primary-outlined"
                  onClick={saveCheckFields}
                >
                  Save
                </Button>
              )}
              <Button customType="primary" type="submit">
                Next
              </Button>
            </div>
          </TaskButtonBar>
        ) : (
          ""
        )}
        <div className="spaceFrm" />
      </Form>
      {showTaskReview && (
        <FilingReviewModal isArchived={isArchived} handleSubmit={onSubmit} />
      )}
    </>
  );
}
