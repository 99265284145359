import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import SmallPencil from "components/icons/SmallPencil";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { InvoiceSettings } from "store/apis/invoices";
import {
  ConfigureReminderEmail,
  emailReminderTimes,
  EmailReminderTimesType,
} from "./ConfigureReminderEmail";

export const ReminderSetting = ({
  invoiceSetting,
}: {
  invoiceSetting?: InvoiceSettings | null;
}) => {
  const reminderSettingModal = useModal();
  const invoiceReminderSetting = invoiceSetting?.reminder_settings;
  const emailConfigured = Boolean(invoiceReminderSetting);
  const { update } = useUpdateQuery();
  const query = useQuery();
  const openConfigure = query.get("openconfigure");
  const disableEmailConfigure =
    !invoiceSetting?.email ||
    !invoiceSetting?.entity_id ||
    !invoiceSetting?.invoice_prefix;

  useEffect(() => {
    if (openConfigure) {
      update({ query: "openconfigure", value: null });
      reminderSettingModal.open();
    }
  }, [openConfigure, reminderSettingModal, update]);

  return (
    <div className="t-border-solid t-border-neutral-0 t-rounded-lg t-p-4 t-border t-relative">
      <div
        className={classNames("t-flex t-gap-8 t-justify-between", {
          "t-items-center": !emailConfigured,
        })}
      >
        {!emailConfigured ? (
          <div className="t-text-body">
            Send email reminders for unpaid invoices around due date
          </div>
        ) : (
          <div className="t-text-body">
            Reminders are sent:
            <ul className="t-mb-0">
              {invoiceReminderSetting?.reminder_setting_types
                .split(",")
                .map((time) => (
                  <li key={time}>
                    {emailReminderTimes[time as EmailReminderTimesType].label}
                  </li>
                ))}
            </ul>
          </div>
        )}
        <ConditionalToolTip
          condition={
            !emailConfigured &&
            disableEmailConfigure &&
            "Please add your company details"
          }
        >
          <span>
            <Button
              size="small"
              customType="ghost_icon"
              onClick={reminderSettingModal.open}
              disabled={!emailConfigured && Boolean(disableEmailConfigure)}
            >
              <span className="t-flex t-text-blue-70">
                <SmallPencil color="currentColor" />
              </span>
            </Button>
          </span>
        </ConditionalToolTip>
      </div>
      {reminderSettingModal.isOpen && (
        <ConfigureReminderEmail
          isOpen={reminderSettingModal.isOpen}
          close={reminderSettingModal.close}
          invoiceSetting={invoiceSetting}
        />
      )}
    </div>
  );
};
