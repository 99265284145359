export const PlusIcon = ({
  color = "#ffffff",
  size = "16",
  stroke = "1.8",
}: {
  color?: string;
  size?: string;
  stroke?: string;
}) => {
  return (
    <>
      <span className="t-sr-only">Plus</span>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 8H13.5"
          stroke="currentColor"
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 2.5V13.5"
          stroke="currentColor"
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
