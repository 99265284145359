import { GROUP_BOOKS_CONSOLIDATION } from "constants/addons";
import { CALENDAR } from "constants/calendarTypes";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDispatch } from "react-redux";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { setAddonData, setAddonType } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import {
  openStartFinancialClosingModal,
  setFinancialClosingInitialState,
} from "store/slices/financialClosing";
import { setServiceActionType } from "store/slices/serviceList";
import { ServiceType } from "types/Models/services";
import { ServiceListCards } from "./ServiceList/ServiceListCards";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";

type RequestServiceActionsProps = {
  openServiceActionModal: () => void;
};

export const RequestServiceActions = ({
  openServiceActionModal,
}: RequestServiceActionsProps) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const onHistoricalCleanup = () => {
    dispatch(
      setFinancialClosingInitialState({
        isHistorical: true,
        startDate: "",
        endDate: "",
        multipleMonths: true,
        seasonSummaryId: "",
        seasonType: CALENDAR,
        seasonYear: "2023",
      })
    );
    dispatch(openStartFinancialClosingModal());
  };

  const onGroupMIS = () => {
    const groupConsolidation = allSubscriptions.filter(
      (ele) => ele.subscription_type === GROUP_BOOKS_CONSOLIDATION
    );

    const groupConsolidationMinAmount = groupConsolidation.reduce(
      (min, obj) => {
        return Number(obj?.amount) < Number(min)
          ? Number(obj?.amount)
          : Number(min);
      },
      Number(groupConsolidation?.[0]?.amount)
    );

    const groupConsolidationMappings =
      groupConsolidation
        ?.filter(
          (obj) =>
            obj?.group_subscription && obj?.group_subscription?.length > 0
        )
        ?.map((ele) => {
          return {
            groupSubscriptionId:
              ele?.group_subscription?.[0]?.group_subscription_id,
            subscriptionName: ele?.subscription_name,
            amount: ele?.amount,
            billingCycle: ele?.billing_cycle,
          };
        }) || [];

    const addon = {
      amount: groupConsolidationMinAmount,
      billing_cycle: "quarterly or monthly",
      description: "Consolidated financials of all your entities",
      dropdownContent: groupConsolidation,
      subscription_name: "Group Books Consolidation",
      subscription_type: GROUP_BOOKS_CONSOLIDATION,
      ...(groupConsolidationMappings?.length > 0
        ? { group_subscription: groupConsolidationMappings }
        : {}),
      uuid: "",
    };

    dispatch(toggleCartSubscription(true));
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type!));
  };

  const openServiceAction = (serviceType: ServiceType) => {
    dispatch(setServiceActionType(serviceType));
    openServiceActionModal();
  };

  return (
    <ServiceListCards
      onHistoricalCleanup={onHistoricalCleanup}
      onGroupMIS={onGroupMIS}
      openServiceAction={openServiceAction}
    />
  );
};
