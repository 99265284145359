import React from "react";
import { formatDate } from "utils/formatDate";

let taskDeadline = "2023-04-18";

const Task7004Header = () => {
  let deadline = Math.floor(
    (+new Date(taskDeadline) - +new Date()) / (24 * 60 * 60 * 1000)
  );

  return (
    <div className="pageHeaderDiv sm:t-block">
      <h3 className="headerTitle">Form 7004 (Extension Request)</h3>
      <div className="page-header-right sm:!t-block">
        <div className="deadlineTag  t-gap-5">
          {taskDeadline && (
            <span className="t-rounded-lg t-border t-border-solid t-border-neutral-10 t-bg-blue-0 t-px-4 t-py-1 t-text-subtitle-sm t-font-semibold">
              Due Date: {formatDate(taskDeadline)}
            </span>
          )}
          {deadline && (
            <span className="circleDeadlineTag t-text-red">
              <span className="circleDeadline !t-border-t-dotted t-border-[2px] !t-border-red !t-border-t-red">
                {deadline < 0 ? deadline * -1 : deadline}
              </span>
              days {deadline < 0 ? " overdue " : " left "}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Task7004Header;
