import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FormikValues, useFormikContext } from "formik";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { currency } from "utils/Currency";

export const AmountInput = ({
  isInitiateTransferModal,
}: {
  isInitiateTransferModal: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const amount = value?.replaceAll(",", "");

    if (!isNaN(+amount)) {
      setFieldValue(
        "amount",
        currency({
          amount: amount,
          maximumFractionDigits: 0,
        }).replace("$", "") || ""
      );
    } else {
      setFieldValue(
        "amount",
        currency({
          amount: values?.amount.replaceAll(",", ""),
          maximumFractionDigits: 0,
        }).replace("$", "") || ""
      );
    }
  };
  return (
    <TextInput
      onChange={handleAmountChange}
      label={isInitiateTransferModal ? "Amount" : "Send"}
      name="amount"
      icon={
        <div className="t-flex t-items-center t-gap-2">
          <ReactCountryFlag
            countryCode="US"
            svg
            style={{ width: "20px", height: "24px" }}
          />
          <div>USD</div>
        </div>
      }
      autoFocus
    />
  );
};
