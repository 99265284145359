import { YearSelector } from "components/design/YearSelector";
import { useDispatch, useSelector } from "react-redux";
import { setCalendarYear } from "store/slices/calender";
import { RootState } from "store/store";

export const CalendarYearSelector = () => {
  const dispatch = useDispatch();
  const calendarYear = useSelector((state: RootState) => state.calendar.year);

  return (
    <YearSelector
      defaultYear={calendarYear}
      years={["2022", "2023"]}
      onSelect={(year) => dispatch(setCalendarYear(year))}
    />
  );
};
