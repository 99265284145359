import * as Tabs from "@radix-ui/react-tabs";
import classNames from "classnames";
import cx from "classnames";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

const Suffix = ({ children }: { children: ReactNode }) => {
  return (
    <span className="t-rounded t-border t-border-solid t-px-1 t-py-0.5 t-text-center t-text-overline t-font-semibold group-data-[state=active]:t-border-purple group-data-[state=inactive]:t-border-neutral-10 group-data-[state=active]:t-bg-purple group-data-[state=active]:t-text-white">
      {children}
    </span>
  );
};

const BottomBorder = () => {
  return (
    <div className="t-h-1 t-w-full t-rounded-t-lg group-hover:t-bg-purple-20 group-data-[state=active]:!t-bg-purple" />
  );
};

export type Triggerprops = {
  customType?: "secondary" | "primary";
  children: ReactNode;
  suffix?: string | number;
} & Tabs.TabsTriggerProps &
  React.RefAttributes<HTMLButtonElement>;

export type Listprops = Tabs.TabsListProps &
  React.RefAttributes<HTMLDivElement>;

const TAB_TYPE_CLASSES = {
  /* @tw */
  PRIMARY:
    "t-text-text-60 t-text-body-lg hover:t-text-text-100 data-[state=active]:t-text-purple",
  /* @tw */
  SECONDARY:
    "t-rounded t-py-1.5 t-px-2 t-text-body-sm t-font-medium t-text-i-text-light data-[state=active]:t-bg-purple data-[state=active]:t-text-text data-[state=active]:t-shadow-active-tab",
};

const Trigger = ({
  children,
  suffix,
  customType = "primary",
  ...props
}: Triggerprops) => {
  return (
    <Tabs.Trigger
      {...props}
      className={cx("all:unset t-group t-cursor-pointer", {
        [TAB_TYPE_CLASSES.PRIMARY]: customType === "primary",
        [TAB_TYPE_CLASSES.SECONDARY]: customType === "secondary",
      })}
    >
      <button className="all:unset t-flex t-flex-col t-items-center t-gap-2 t-whitespace-nowrap	 ">
        <div className="t-flex t-items-center t-gap-2">
          {children}
          {suffix && <Suffix>{suffix}</Suffix>}
        </div>
        {customType === "primary" && <BottomBorder />}
      </button>
    </Tabs.Trigger>
  );
};

const NavTrigger = ({
  children,
  exact,
  replace,
  omitUrl,
  target,
  suffix,
  to,
  ...props
}: {
  children: ReactNode;
  omitUrl?: boolean;
  exact?: boolean;
  replace?: boolean;
  target?: string;
  to?: string;
} & Triggerprops) => {
  const { value } = props;

  return (
    <Tabs.Trigger {...props} asChild>
      <NavLink
        exact={Boolean(exact)}
        className={cx("all:unset t-group t-cursor-pointer", {
          [TAB_TYPE_CLASSES.PRIMARY]: true,
        })}
        to={to || value}
        replace={replace}
        target={target}
      >
        <button className="all:unset t-flex t-flex-col t-items-center t-gap-2">
          <div className="t-flex t-items-center t-gap-2">
            {children}
            {suffix && <Suffix>{suffix}</Suffix>}
          </div>
          <BottomBorder />
        </button>
      </NavLink>
    </Tabs.Trigger>
  );
};

const List = ({ className = "", ...props }: Listprops) => {
  return (
    <Tabs.List
      {...props}
      className={classNames(
        "t-flex t-overflow-x-auto	 hide-scrollbar t-gap-8 t-pr-2 t-pt-2",
        {
          [className]: className,
        }
      )}
    />
  );
};

const Tab = {
  ...Tabs,
  Trigger: Trigger,
  NavTrigger: NavTrigger,
  List: List,
};

export default Tab;
