import { ConditionalLink } from "components/conditionalLink";
import { Badge } from "components/design/badge";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Price } from "components/Price";
import { MAILROOM_MANAGE } from "constants/addons";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { MONTHLY } from "constants/stripeCouponPeriods";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import { resetAllAddonDataPayload, setAddonType } from "store/slices/addons";
import { RootState } from "store/store";

export const MailRoomManage = () => {
  const dispatch = useDispatch();
  const { addonType, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const updatedAmount =
    addonData?.stripe_coupon?.discounted_amount.toString() || addonData?.amount;
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonType === MAILROOM_MANAGE
  );
  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <>
      <Modal.Root
        open={showAddonModal}
        onOpenChange={() => {
          setShowAddonModal(false);
          dispatch(setAddonType(""));
          dispatch(resetAllAddonDataPayload());
        }}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Manage Plan</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-gap-4">
              <div className="t-p-5 t-border-solid t-border-neutral-0 t-border t-rounded-lg">
                <div className="t-mb-5 t-flex t-justify-between">
                  <div className="t-text-subtitle t-flex t-gap-2">
                    <div>Current Address</div>
                    <Badge color="light-green" size="small">
                      Active
                    </Badge>
                  </div>
                  <Price amount={updatedAmount} cycle={MONTHLY} size="medium" />
                </div>
                <div className="t-text-body-sm t-text-text-30">
                  16192 Coastal Hwy, Lewes, DE 19958
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-between">
            <Button
              customType="ghost"
              onClick={() => {
                setShowAddonModal(false);
                setShowCancelModal(true);
              }}
            >
              Cancel subscription
            </Button>
            <ConditionalLink to={"/chat"}>
              <Button customType="primary">Contact us</Button>
            </ConditionalLink>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <MailRoomCancel
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
      />
    </>
  );
};

export const MailRoomCancel = ({
  showCancelModal,
  setShowCancelModal,
}: {
  showCancelModal: boolean;
  setShowCancelModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const entityId = useCurrentEntityId();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        groupId,
        entityId,
        subscriptionId:
          addonData?.group_subscription?.[0]?.group_subscription_id,
      }).unwrap();
      successToast({ message: "Subscription cancelled successfully" });
      dispatch(setAddonType(""));
      setShowCancelModal(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      alertToast({ message: error?.message || SOMETHING_WENT_WRONG });
      dispatch(setAddonType(""));
      setShowCancelModal(false);
    }
  };

  return (
    <Modal.Root
      open={showCancelModal}
      onOpenChange={() => {
        setShowCancelModal(false);
        dispatch(setAddonType(""));
      }}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel subscription</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            Are you sure you want to cancel the subscription?
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowCancelModal(false);
              dispatch(setAddonType(""));
            }}
          >
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={handleCancelSubscription}
            isLoading={isLoading}
          >
            Cancel subscription
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
