import { Button } from "components/DesignSystem/Button/Button";
import {
  ACTION_REQUIRED,
  FORWARDED,
  FORWARD_REQUESTED,
  SHREDDED,
  SHRED_REQUESTED,
} from "constants/mailRoom";
import { useUpdateQuery } from "hooks/useQuery";
import { Dropdown } from "react-bootstrap";
import filterIcon from "static/images/filterIcon.svg";
import { STATUS_TYPE } from "./mailboxDictonaries";

export const FilterDropdown = ({
  checkedBoxes,
  handleCheckBox,
  setCheckedBoxes,
  filters,
}) => {
  const { update } = useUpdateQuery();
  const resetCheckbox = () => {
    setCheckedBoxes({
      ACTION_REQUIRED: false,
      FORWARD_REQUESTED: false,
      SHREDDED: false,
      FORWARDED: false,
      SHRED_REQUESTED: false,
    });

    Object.keys(checkedBoxes).forEach((ele) =>
      update({
        query: ele,
        value: null,
      })
    );
  };

  const isFilterApplied = Object.values(checkedBoxes).some((ele) => ele);
  const appliedFilters = filters.map((filter) => STATUS_TYPE[filter]);

  return (
    <Dropdown className="filter-dropdown">
      <Dropdown.Toggle className="d-flex align-items-center justify-content-between t-p-0">
        <Button block>
          {isFilterApplied ? (
            <span className="applied-filters">{appliedFilters.join(",")}</span>
          ) : (
            <span className="t-flex t-gap-1 t-items-center">
              <img src={filterIcon} alt="filter" loading="lazy" />
              <span>Filter</span>
            </span>
          )}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu className="t-w-52">
        <span className="checkbox-filters-comp">
          <div className="checkbox-filter">
            <input
              checked={checkedBoxes.ACTION_REQUIRED}
              type="checkbox"
              className="checkbox-input"
              onChange={handleCheckBox}
              name={ACTION_REQUIRED}
              id="action-required"
            />
            <label htmlFor="action-required">Action Required</label>
          </div>
          <div className="checkbox-filter">
            <input
              checked={checkedBoxes.FORWARD_REQUESTED}
              type="checkbox"
              className="checkbox-input"
              onChange={handleCheckBox}
              name={FORWARD_REQUESTED}
              id="forward-requested"
            />
            <label htmlFor="forward-requested">Ship Requested</label>
          </div>
          <div className="checkbox-filter">
            <input
              checked={checkedBoxes.SHRED_REQUESTED}
              type="checkbox"
              className="checkbox-input"
              onChange={handleCheckBox}
              name={SHRED_REQUESTED}
              id="shred-requested"
            />
            <label htmlFor="shred-requested" content="">
              Shred Requested
            </label>
          </div>
          <div className="checkbox-filter">
            <input
              checked={checkedBoxes.SHREDDED}
              type="checkbox"
              className="checkbox-input"
              onChange={handleCheckBox}
              name={SHREDDED}
              id="shredded"
            />
            <label htmlFor="shredded">Shredded</label>
          </div>
          <div className="checkbox-filter">
            <input
              checked={checkedBoxes.FORWARDED}
              type="checkbox"
              className="checkbox-input"
              onChange={handleCheckBox}
              name={FORWARDED}
              id="forwarded"
            />
            <label htmlFor="forwarded">Shipped</label>
          </div>
          <Button size="small" customType="primary" onClick={resetCheckbox}>
            Reset
          </Button>
        </span>
      </Dropdown.Menu>
    </Dropdown>
  );
};
