import { CellContext } from "react-table-8.10.7";
import { ChatTicket } from "types/Models/chatTicket";
import TicketTagsCombobox from "./TicketTagsCombobox";

export const TicketTags = (
  info: CellContext<ChatTicket, ChatTicket["tags"]>
) => {
  const currentTags = info.row.original.tags;
  const ticketId = info.row.original.uuid;
  return <TicketTagsCombobox currentTags={currentTags} ticketId={ticketId} />;
};
