import { Button } from "components/DesignSystem/Button/Button";
import { DocumentPreviewModal } from "components/PreviewModal";
import { DownloadIcon } from "components/icons/Download";
import { OLD_LAVENDER } from "constants/perks";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import React from "react";
import InvoiceFileText from "static/images/InvoiceFileText.svg";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { TaskFileType } from "types/Models/task";
import { openLink } from "utils/openLink";

const ReportCard = ({ fileData }: { fileData: TaskFileType }) => {
  const openPreview = useDocPreview();
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const downloadFile = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.stopPropagation();
      const { data } = await getPreviewUrl({
        groupId,
        fileId: fileData.uuid,
      });

      const { download_url } = data || {};
      openLink(download_url);
    } catch (e: any) {
      alertToast({ message: e.data?.error?.message });
    }
  };

  return (
    <>
      <div
        className="primary-border t-flex t-cursor-pointer t-items-center t-justify-between t-bg-surface t-px-4 t-py-2 hover:t-bg-purple-0"
        onClick={() => openPreview(fileData.uuid)}
      >
        <div className="t-flex t-items-center t-gap-3">
          <img src={InvoiceFileText} alt="InvoiceFileText" />
          <span className="t-text-body t-font-semibold  t-text-text-100">
            {fileData.name}
          </span>
        </div>
        <div className="t-flex t-items-center t-gap-8">
          {fileData.is_previewable && (
            <Button
              size="small"
              customType="transparent"
              onClick={() => openPreview(fileData.uuid)}
            >
              View
            </Button>
          )}
          <Button
            size="small"
            customType="ghost_icon"
            onClick={(e) => downloadFile(e)}
          >
            <DownloadIcon color={OLD_LAVENDER} size={16} strokeWidth="1.5" />
          </Button>
        </div>
      </div>
      <DocumentPreviewModal />
    </>
  );
};

export default ReportCard;
