import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  FINANCIAL_MODELLING,
  PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
  PRO_MONTHLY_BOOKKEEPING,
} from "types/Models/services";
import InfoBlue from "static/images/InfoBlue.svg";
import { ConditionalLink } from "components/conditionalLink";
import { useLocation } from "react-router-dom";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import ReactCountryFlag from "react-country-flag";

type CreateServiceFormProps = {
  amount: number | string;
};
const Label = ({
  country,
  country_code,
  name,
}: {
  name: string;
  country_code: string;
  country: string;
}) => (
  <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
    <ReactCountryFlag countryCode={country_code} svg title={country} />
    <span className="t-truncate t-max-w-36">{name}</span>
  </div>
);

const EntitySelector = () => {
  const { entities } = useCurrentGroupContext();
  const entityOptions = entities.map(
    ({ name, uuid, country, country_code }) => ({
      label: (
        <Label country={country} country_code={country_code} name={name} />
      ),
      value: uuid,
    })
  );
  const { values: entity } = useFormikContext();
  const selectedEntity = entityOptions.find(({ value }) => entity == value);
  return (
    <Combobox
      label="Entity"
      menuPlacement="auto"
      withForm
      name="entity"
      menuPortalTarget={document.body}
      size="small"
      tooltip
      creatable={false}
      isClearable={false}
      options={entityOptions}
      value={selectedEntity}
      required
    />
  );
};
const BaseServiceForm = ({ amount }: CreateServiceFormProps) => {
  const selectedYear = dayjs().year().toString();
  const {
    values: { startDate },
    setFieldValue,
  } = useFormikContext<{
    startDate: string;
  }>();
  const entityId = useCurrentEntityId();
  return (
    <>
      <Field name="startDate">
        {({ field }: FieldProps) => {
          return (
            <DateInput
              {...field}
              label={"From"}
              placeholder="Select month"
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              required
              portalId="startDate"
              openToDate={new Date(selectedYear || "")}
              maxDate={dayjs(selectedYear).endOf("year").toDate()}
            />
          );
        }}
      </Field>
      <Field name="endDate">
        {({ field }: FieldProps) => {
          return (
            <DateInput
              {...field}
              label="To"
              placeholder="Select month"
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              required
              portalId="endDate"
              openToDate={new Date(selectedYear || "")}
              maxDate={dayjs(selectedYear).endOf("year").toDate()}
              minDate={dayjs(startDate).toDate()}
              onDateChange={(date) =>
                setFieldValue(
                  "endDate",
                  dayjs(date).endOf("month").format(DD_MMM_YYYY)
                )
              }
            />
          );
        }}
      </Field>
      {!entityId && <EntitySelector />}
      <div>
        <div className="t-text-body-sm t-text-text-30 t-pb-1.5">Price</div>
        <div className="t-text-subtitle-sm t-text-text-100">${amount}</div>
      </div>
    </>
  );
};
const FinancialModellingForm = ({
  amount,
  search,
}: { search: String } & CreateServiceFormProps) => {
  return (
    <>
      <BaseServiceForm amount={amount} />

      <div className="t-flex t-items-center t-gap-1 t-bg-blue-10 t-border t-border-solid t-border-blue-30 t-px-3 t-py-2 t-rounded">
        <img src={InfoBlue} alt="InfoBlue" />
        <div className="t-text-body-sm t-text-text-100">
          Payment varies based on fundraising progress.
          <ConditionalLink
            to={`/books/chat${search}`}
            className="all:unset t-text-blue-70 t-cursor-pointer t-mx-1"
          >
            Chat with us
          </ConditionalLink>
          for details.
        </div>
      </div>
    </>
  );
};
const PaygServiceForm = ({
  amount,
  seasons,
}: { seasons: string[] } & CreateServiceFormProps) => {
  const {
    values: { seasons: selectedSeasons },
  } = useFormikContext<{ seasons: string[] }>();
  const entityId = useCurrentEntityId();
  return (
    <>
      <Combobox
        components={{
          DropdownIndicator: () => null,
        }}
        name="accounting_method"
        withForm
        menuPortalTarget={document.body}
        label="Accounting Method"
        options={[]}
        defaultValue={{ label: "Cash", value: "cash" }}
        isDisabled={true}
      />
      <Combobox
        name="season"
        withForm
        menuPortalTarget={document.body}
        label="Season"
        placeholder="Select year"
        options={seasons.map((season: string) => {
          return {
            label: season,
            value: season,
          };
        })}
        isClearable={false}
        required
      />
      {!entityId && <EntitySelector />}
    </>
  );
};
const ProBookkeepingServiceForm = ({ amount }: CreateServiceFormProps) => {
  const selectedYear = dayjs().year().toString();
  const entityId = useCurrentEntityId();
  return (
    <>
      <Combobox
        name="accounting_method"
        withForm
        menuPortalTarget={document.body}
        label="Accounting Method"
        options={[]}
        defaultValue={{ label: "Accrual", value: "accrual" }}
        isDisabled={true}
      />
      <Field name="startDate">
        {({ field }: FieldProps) => {
          return (
            <DateInput
              {...field}
              label="Start from"
              placeholder="Select month and year"
              dateFormat="MMMM-yyyy"
              showMonthYearPicker
              required
              portalId="startDate"
              openToDate={new Date(selectedYear || "")}
              maxDate={dayjs(selectedYear).endOf("year").toDate()}
            />
          );
        }}
      </Field>
      {!entityId && <EntitySelector />}
    </>
  );
};
export const CreateServiceForm = ({
  amount,
  seasons,
}: { seasons: string[] } & CreateServiceFormProps) => {
  const serviceActionType = useSelector(
    (state: RootState) => state.serviceList.serviceActionType
  );
  const { search } = useLocation();

  switch (serviceActionType) {
    case FINANCIAL_MODELLING: {
      return <FinancialModellingForm amount={amount} search={search} />;
    }
    case PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING: {
      return <PaygServiceForm amount={amount} seasons={seasons} />;
    }
    case PRO_MONTHLY_BOOKKEEPING: {
      return <ProBookkeepingServiceForm amount={amount} />;
    }
    default:
      return <BaseServiceForm amount={amount} />;
  }
};
