import { Agents } from "types/Models/agents";
import { emptyApi } from "./emptyApi";
import qs from "qs";

type PreGroupProfilesProps = {
  pageNumber?: number | null;
  searchTerm?: string | null;
  archived?: boolean | null;
};

export type Profile = {
  created_at?: string;
  current_timezone?: string;
  email?: string;
  first_name?: string;
  group?: { name: string; uuid: string };
  is_email_verified?: boolean;
  is_mobile_verified?: boolean;
  is_public_user?: boolean;
  is_restricted?: boolean;
  last_name?: string;
  linkedIn_url?: string;
  mobile?: string;
  name?: string;
  profile_url?: string;
  referral_link?: string;
  session_id?: string;
  type?: string;
  user_type?: string;
  uuid?: string;
  salesperson: Agents | null;
};

type PreGroupProfilesResponse = {
  profiles: Profile[];
  current_page: number;
  total_count: number;
  total_pages: number;
  per_page: number;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllPreGroupProfiles: build.query<
      PreGroupProfilesResponse,
      PreGroupProfilesProps
    >({
      query: ({ pageNumber, searchTerm, archived }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNumber,
            search_term: searchTerm,
            archived: archived,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/users/pregroup-profiles/${queryUrl}`,
        };
      },
      providesTags: (result) => {
        const { profiles = [] } = result || {};
        return result
          ? [
              ...profiles.map(({ uuid }: any) => ({
                type: "SalesCrmProfiles" as "SalesCrmProfiles",
                id: uuid,
              })),
              {
                type: "SalesCrmProfiles",
                id: "LIST",
              },
            ]
          : [
              {
                type: "SalesCrmProfiles" as "SalesCrmProfiles",
                id: "LIST",
              },
            ];
      },
    }),

    approveUserProfile: build.mutation({
      query: (profileId: string) => {
        return {
          url: `/api/inkle/users/unrestrict/`,
          method: "put",
          body: {
            profile_id: profileId,
          },
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [
              {
                type: "SalesCrmProfiles",
              },
            ]
          : [];
      },
    }),

    rejectUserProfile: build.mutation({
      query: (profileId: string) => {
        return {
          url: `/api/inkle/users/unrestrict/`,
          method: "put",
          body: {
            profile_id: profileId,
            reject: true,
          },
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [
              {
                type: "SalesCrmProfiles",
              },
            ]
          : [];
      },
    }),
    assignSalesPerson: build.mutation({
      query: ({
        profileId,
        agent_profile_id,
      }: {
        profileId: string;
        agent_profile_id: string;
      }) => {
        return {
          url: `/api/inkle/users/assign_agent/${profileId}/`,
          method: "PATCH",
          body: {
            agent_profile_id,
          },
        };
      },
      invalidatesTags: () => {
        return [
          {
            type: "SalesCrmProfiles",
          },
        ];
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllPreGroupProfilesQuery,
  useApproveUserProfileMutation,
  useRejectUserProfileMutation,
  useAssignSalesPersonMutation,
} = extendedApi;
