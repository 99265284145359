export const Filings = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.45005 3.44999V8.95624C3.45005 9.34284 3.13665 9.65624 2.75005 9.65624C2.36345 9.65624 2.05005 9.34284 2.05005 8.95624V3.24999C2.05005 2.93173 2.17648 2.6265 2.40152 2.40146C2.62656 2.17642 2.93179 2.04999 3.25005 2.04999H12.75C13.0683 2.04999 13.3735 2.17642 13.5986 2.40146C13.8236 2.6265 13.95 2.93173 13.95 3.24999V12.75C13.95 13.0682 13.8236 13.3735 13.5986 13.5985C13.3735 13.8236 13.0683 13.95 12.75 13.95H8.47505C8.08845 13.95 7.77505 13.6366 7.77505 13.25C7.77505 12.8634 8.08845 12.55 8.47505 12.55H12.55V3.44999H3.45005Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.49502 9.00501C8.76839 9.27838 8.76839 9.7216 8.49502 9.99496L4.49502 13.995C4.22166 14.2683 3.77844 14.2683 3.50507 13.995L1.50507 11.995C1.23171 11.7216 1.23171 11.2784 1.50507 11.005C1.77844 10.7316 2.22166 10.7316 2.49502 11.005L4.00005 12.51L7.50507 9.00501C7.77844 8.73165 8.22166 8.73165 8.49502 9.00501Z"
      fill="currentColor"
    />
  </svg>
);
