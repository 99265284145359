export const AddFileIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 4.5H8.20687L6.49625 2.78938C6.31125 2.60563 6.05625 2.5 5.79688 2.5H2.5C1.94875 2.5 1.5 2.94875 1.5 3.5V12.5375C1.5 13.0681 1.93187 13.5 2.4625 13.5H13.5562C14.0769 13.5 14.5 13.0769 14.5 12.5563V5.5C14.5 4.94875 14.0513 4.5 13.5 4.5ZM5.79 3.4975L6.7925 4.5H2.5V3.5L5.79 3.4975ZM13.5 12.5H2.5V5.5H13.5V12.5Z"
        fill={color}
      />
      <path
        d="M7.5 7.5V8.5H6.5C6.22375 8.5 6 8.72375 6 9C6 9.27625 6.22375 9.5 6.5 9.5H7.5V10.5C7.5 10.7763 7.72375 11 8 11C8.27625 11 8.5 10.7763 8.5 10.5V9.5H9.5C9.77625 9.5 10 9.27625 10 9C10 8.72375 9.77625 8.5 9.5 8.5H8.5V7.5C8.5 7.22375 8.27625 7 8 7C7.72375 7 7.5 7.22375 7.5 7.5Z"
        fill={color}
      />
    </svg>
  );
};
