import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

export const TicketTypeUpdateConfirm = ({
  isOpen,
  close,
  updateType,
}: {
  isOpen: boolean;
  close: () => void;
  updateType: () => void;
}) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="small">
        <Modal.Header>
          <Modal.Title>Confirm Ticket Change</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-60">
            Are you sure you want to change the priced ticket to an unpriced
            ticket?
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={() => {
              updateType();
              close();
            }}
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
