import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import QueryString from "qs";

export type FiltersValues = {
  name: string;
  value: boolean | string | null | string[];
  type: string;
  fixed?: boolean;
};

export interface TransactionFilterState {
  filters: {
    minAmount: FiltersValues;
    maxAmount: FiltersValues;
    startDate: FiltersValues;
    endDate: FiltersValues;
    hasComments: FiltersValues;
    hasInvoices: FiltersValues;
    lastMonth?: FiltersValues;
    uncategorised: FiltersValues;
    intercompany?: FiltersValues;
    fromPlaid: FiltersValues;
    fromCSV: FiltersValues;
    manuallyAddedTxn: FiltersValues;
    hasMemo: FiltersValues;
    manualEntry?: FiltersValues;
    cashFlow?: FiltersValues;
    categoryIds?: FiltersValues;
    vendors?: FiltersValues;
    fromIds?: FiltersValues;
    bankStatement?: FiltersValues;
    hasNoVendors?: FiltersValues;
    splitTransactions?: FiltersValues;
    notReviewed?: FiltersValues;
  };
}

export type FilterName =
  | "minAmount"
  | "maxAmount"
  | "startDate"
  | "endDate"
  | "hasComments"
  | "hasInvoices"
  | "lastMonth"
  | "uncategorised"
  | "intercompany"
  | "fromPlaid"
  | "fromCSV"
  | "manuallyAddedTxn"
  | "hasMemo"
  | "manualEntry"
  | "cashFlow"
  | "categoryIds"
  | "vendors"
  | "fromIds"
  | "bankStatement"
  | "hasNoVendors"
  | "splitTransactions"
  | "notReviewed";

const initialState: TransactionFilterState = {
  filters: {
    cashFlow: {
      name: "Cash Flow",
      value: null,
      type: "amount",
    },
    minAmount: { name: "Min Amount", value: null, type: "amount" },
    maxAmount: { name: "Max Amount", value: null, type: "amount" },

    startDate: { name: "Since", value: null, type: "transactionDate" },
    endDate: { name: "Till", value: null, type: "transactionDate" },

    hasComments: { name: "Has Comments", value: false, type: "others" },
    hasInvoices: { name: "Has Invoice", value: false, type: "others" },
    fromPlaid: { name: "Bank connection", value: false, type: "sources" },
    fromCSV: { name: "CSV", value: false, type: "sources" },
    manuallyAddedTxn: {
      name: "Manually added",
      value: false,
      type: "sources",
    },
    uncategorised: {
      name: "Uncategorised",
      value: false,
      type: "others",
      fixed: true,
    },
    hasMemo: {
      name: "Has Memo",
      value: false,
      type: "others",
      fixed: false,
    },
    categoryIds: {
      name: "Categories",
      value: null,
      type: "category",
      fixed: false,
    },
    vendors: {
      name: "Vendors",
      value: null,
      type: "vendors",
      fixed: false,
    },
    fromIds: {
      name: "Source",
      value: null,
      type: "from",
      fixed: false,
    },
    manualEntry: {
      name: "Manual Entry",
      value: false,
      type: "from",
      fixed: false,
    },
    bankStatement: {
      name: "Bank statement",
      value: false,
      type: "from",
      fixed: false,
    },
    notReviewed: {
      name: "Not Reviewed",
      value: false,
      type: "others",
      fixed: true,
    },
    hasNoVendors: {
      name: "Unknown Vendors",
      value: false,
      type: "others",
      fixed: true,
    },
    splitTransactions: {
      name: "Split Transactions",
      value: false,
      type: "others",
      fixed: false,
    },
  },
};

export const transactionFilter = createSlice({
  name: "transactionFilter",
  initialState,
  reducers: {
    setTxnFilters: (state, action: PayloadAction<QueryString.ParsedQs>) => {
      const payload = action.payload;

      const updatedValues = Object.keys(payload).reduce((acc, el) => {
        const value = payload[el] === "true" ? true : payload[el];
        const newState = {
          ...initialState.filters?.[el as FilterName],
          value,
        };

        return { ...acc, [el]: { ...newState } };
      }, {});

      state.filters = {
        ...state.filters,
        ...updatedValues,
      };
    },
    resetTxnFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export const { setTxnFilters, resetTxnFilters } = transactionFilter.actions;

export default transactionFilter.reducer;
