export const Accounting = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_1898)">
      <path
        d="M13 13.5V2.5C13 2.22386 12.7761 2 12.5 2L3.5 2C3.22386 2 3 2.22386 3 2.5V13.5C3 13.7761 3.22386 14 3.5 14H12.5C12.7761 14 13 13.7761 13 13.5Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3 2.69999L3.70001 2.69999V13.3H12.3V2.69999ZM12.5 1.29999C13.1628 1.29999 13.7 1.83725 13.7 2.49999V13.5C13.7 14.1627 13.1628 14.7 12.5 14.7H3.50001C2.83727 14.7 2.30001 14.1627 2.30001 13.5V2.49999C2.30001 1.83725 2.83727 1.29999 3.50001 1.29999L12.5 1.29999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.29999 3.99999C4.29999 3.61339 4.61339 3.29999 4.99999 3.29999H11C11.3866 3.29999 11.7 3.61339 11.7 3.99999V6.99999C11.7 7.38659 11.3866 7.69999 11 7.69999H4.99999C4.61339 7.69999 4.29999 7.38659 4.29999 6.99999V3.99999ZM5.69999 4.69999V6.29999H10.3V4.69999H5.69999Z"
        fill="currentColor"
      />
      <path
        d="M5.5 10C5.91421 10 6.25 9.66421 6.25 9.25C6.25 8.83579 5.91421 8.5 5.5 8.5C5.08579 8.5 4.75 8.83579 4.75 9.25C4.75 9.66421 5.08579 10 5.5 10Z"
        fill="currentColor"
      />
      <path
        d="M8 10C8.41421 10 8.75 9.66421 8.75 9.25C8.75 8.83579 8.41421 8.5 8 8.5C7.58579 8.5 7.25 8.83579 7.25 9.25C7.25 9.66421 7.58579 10 8 10Z"
        fill="currentColor"
      />
      <path
        d="M10.5 10C10.9142 10 11.25 9.66421 11.25 9.25C11.25 8.83579 10.9142 8.5 10.5 8.5C10.0858 8.5 9.75 8.83579 9.75 9.25C9.75 9.66421 10.0858 10 10.5 10Z"
        fill="currentColor"
      />
      <path
        d="M5.5 12.5C5.91421 12.5 6.25 12.1642 6.25 11.75C6.25 11.3358 5.91421 11 5.5 11C5.08579 11 4.75 11.3358 4.75 11.75C4.75 12.1642 5.08579 12.5 5.5 12.5Z"
        fill="currentColor"
      />
      <path
        d="M8 12.5C8.41421 12.5 8.75 12.1642 8.75 11.75C8.75 11.3358 8.41421 11 8 11C7.58579 11 7.25 11.3358 7.25 11.75C7.25 12.1642 7.58579 12.5 8 12.5Z"
        fill="currentColor"
      />
      <path
        d="M10.5 12.5C10.9142 12.5 11.25 12.1642 11.25 11.75C11.25 11.3358 10.9142 11 10.5 11C10.0858 11 9.75 11.3358 9.75 11.75C9.75 12.1642 10.0858 12.5 10.5 12.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6445_1898">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
