import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Switch } from "components/DesignSystem/Switch/Switch";
import Table from "components/DesignSystem/Table/V2/Table";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { TxnSource } from "dictionaries";
import { FormikValues } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import Bank from "static/images/Bank.svg";
import {
  ReconciliationTxn,
  useGetReconciliationStatusQuery,
} from "store/apis/reconciliation";
import {
  setCSVTxnsToBeAdded,
  setDuplicateTransaction,
  setIncludedCredits,
  setIncludedDebits,
  setManualTxnsToBeAdded,
} from "store/slices/reconciliation";
import { RootState } from "store/store";
import { AddTransactionManually } from "./AddTransactionManually";

export const ReconciliationTable = () => {
  const dispatch = useDispatch();
  const { successToast } = useToast();
  const { reconcilationId } = useParams<{ reconcilationId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();

  const entityId = useCurrentEntityId();

  const {
    includedCredits,
    includedDebits,
    duplicateTransactionsList,
    csvTxnsToBeAddedList,
    manualTxnsToBeAddedList,
  } = useSelector((state: RootState) => state.reconciliation);

  const [manuallyAddedTransactions, setManuallyAddedTransactions] = useState<
    ReconciliationTxn[]
  >([]);

  const [incledTxn, setIncludedTxn] = useState(new Set<string>());

  const { data: csvTxn, isSuccess } = useGetReconciliationStatusQuery(
    {
      reconcilationId,
      entityId,
      groupId,
    },
    { skip: !groupId || !entityId }
  );

  const {
    duplicate_txns = [],
    missing_txns = [],
    reconcilation_end_date,
    reconcilation_start_date,
  } = csvTxn || {};

  useEffect(() => {
    let credits = 0;
    let debits = 0;

    missing_txns.forEach((txn) => {
      if (txn.amount < 0) {
        debits += Number(txn.amount);
      }

      if (txn.amount > 0) {
        credits += Number(txn.amount);
      }
    });

    dispatch(setIncludedDebits(includedDebits + debits));
    dispatch(setIncludedCredits(includedCredits + credits));

    dispatch(setCSVTxnsToBeAdded(missing_txns));

    setIncludedTxn(
      missing_txns.reduce((acc, txn, index) => {
        acc.add(String(index));

        return acc;
      }, new Set<string>())
    );
  }, [missing_txns.length]);

  useEffect(() => {
    const duplicateTxnsWithInclude = duplicate_txns.map((txn) => ({
      ...txn,
      include: false,
    }));

    dispatch(setDuplicateTransaction(duplicateTxnsWithInclude));
  }, [duplicate_txns.length]);

  const allTransactions = [
    ...duplicate_txns,
    ...missing_txns,
    ...manuallyAddedTransactions,
  ];

  const data = useMemo(
    () => allTransactions,
    [isSuccess, manuallyAddedTransactions]
  );

  const {
    open: openAddTxnManuallyModal,
    close: closeAddTxnManuallyModal,
    isOpen: isAddTxnModalManuallyOpen,
  } = useModal();

  const columnHelper = createColumnHelper<ReconciliationTxn>();

  const onAddNewTxn = (values: FormikValues) => {
    const { amount, description, date, transaction_type } = values;
    const newAmount = transaction_type === "DEBIT" ? amount * -1 : amount;

    const manualTxn: ReconciliationTxn = {
      amount: newAmount,
      source: "MANUAL",
      description,
      date: dayjs(date).format("MM-DD-YYYY"),
    };

    setManuallyAddedTransactions((prev) => [...prev, manualTxn]);
    successToast({ title: "Transaction added!" });
    closeAddTxnManuallyModal();

    onToggleChange({
      checked: true,
      txn: manualTxn,
      source: "MANUAL",
      id: String(allTransactions.length),
    });
  };

  const onToggleChange = ({
    checked,
    txn,
    id,
    source,
  }: {
    checked: boolean;
    txn: ReconciliationTxn;
    id: string;
    source: string;
  }) => {
    const isDebit = Number(txn.amount) < 0;

    if (source === "INKLE_BOOKS") {
      let newArray = duplicateTransactionsList.map((transaction: any) =>
        transaction.txn_uuid === txn?.txn_uuid
          ? { ...transaction, include: checked }
          : transaction
      );
      dispatch(setDuplicateTransaction(newArray));
    } else if (source === "CSV") {
      let newArray = [];

      if (checked) {
        newArray = [...csvTxnsToBeAddedList, txn];
      } else {
        newArray = csvTxnsToBeAddedList.filter(
          ({ txn_uuid }: any) => txn_uuid !== txn?.txn_uuid
        );
      }
      dispatch(setCSVTxnsToBeAdded(newArray));
    } else {
      let newArray = [];

      if (checked) {
        newArray = [...manualTxnsToBeAddedList, txn];
      } else {
        newArray = manualTxnsToBeAddedList.filter(
          ({ txn_uuid }: any) => txn_uuid !== txn?.txn_uuid
        );
      }
      dispatch(setManualTxnsToBeAdded(newArray));
    }

    if (checked) {
      if (isDebit) {
        dispatch(setIncludedDebits(includedDebits + Number(txn.amount)));
      } else {
        dispatch(setIncludedCredits(includedCredits + Number(txn.amount)));
      }

      setIncludedTxn((s) => {
        const newList = new Set(Array.from(s));
        newList.add(id);
        return newList;
      });
    } else {
      if (isDebit) {
        dispatch(setIncludedDebits(includedDebits - Number(txn.amount)));
      } else {
        dispatch(setIncludedCredits(includedCredits - Number(txn.amount)));
      }

      setIncludedTxn((s) => {
        const newList = new Set(Array.from(s));
        newList.delete(id);
        return newList;
      });
    }
  };

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "Date",
      cell: (info) => {
        const { date } = info.getValue();
        return (
          <div className="t-flex t-items-center t-gap-2 t-p-3 t-text-subtext">
            {dayjs(date).format(DD_MMM_YYYY)}
          </div>
        );
      },
      header: () => <div className="t-p-3">Date</div>,
    }),
    columnHelper.accessor((row) => row, {
      id: "Merchant",
      cell: (info) => {
        const { merchant } = info.getValue();

        if (Boolean(merchant)) {
          return (
            <div className="t-flex t-items-center t-gap-2 t-py-2">
              <img src={Bank} alt="Bank" className="t-w-8 t-rounded-full" />
              <div className="t-w-48 t-truncate t-text-subtext">{merchant}</div>
            </div>
          );
        }

        return <div className="t-w-48 t-truncate t-text-subtext">-</div>;
      },
      header: () => "Merchant",
    }),
    columnHelper.accessor((row) => row, {
      id: "Description",
      cell: (info) => {
        const { description } = info.getValue();
        return (
          <div className="t-w-60 t-truncate t-text-subtext">
            {description || "-"}
          </div>
        );
      },
      header: () => "Description",
    }),
    columnHelper.accessor((row) => row, {
      id: "Amount",
      cell: (info) => {
        const { amount } = info.getValue();

        return (
          <div className="t-text-text-60 t-text-right t-pr-6">
            <AmountSuperScript amount={amount} />
          </div>
        );
      },
      header: () => <div className="t-text-right t-pr-6">Amount</div>,
    }),
    columnHelper.accessor((row) => row, {
      id: "Source",
      cell: (info) => {
        const { source } = info.getValue();

        return <div className="t-text-subtext">{TxnSource[source]}</div>;
      },
      header: () => "Source",
    }),
    columnHelper.accessor((row) => row, {
      id: "Include",
      cell: (info) => {
        const { source } = info.getValue();
        const { id } = info.row;

        const defaultChecked = source === "INKLE_BOOKS";

        return (
          <Switch
            size="small"
            defaultChecked={!defaultChecked}
            checked={incledTxn.has(id)}
            onCheckedChange={(checked) =>
              onToggleChange({ checked, txn: info.getValue(), id, source })
            }
          />
        );
      },
      header: () => "Include",
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <div className="t-flex t-justify-between t-items-center">
        <div className="t-flex t-gap-1 t-flex-col">
          <div className="t-text-subtitle t-text-text-100">
            Manage transactions
          </div>
          <div className="t-text-body-sm t-text-text-30">
            The following transactions are not matching in bank statement &
            Inkle Books. They can be missing or in duplicates.
          </div>
        </div>
        <Button size="small" onClick={openAddTxnManuallyModal}>
          Add manually
        </Button>
      </div>
      <Table.Container>
        <Table.Content>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.HeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="t-font-medium"
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </Table.HeadRow>
            ))}
          </Table.Head>
          <Table.Body>
            {table.getRowModel().rows.map((row) => {
              return (
                <Table.Row
                  key={row.id}
                  className={classNames("t-text-text-100", {
                    "t-opacity-50": row.original.source === "INKLE_BOOKS",
                  })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} style={{ width: cell.column.getSize() }}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Content>
      </Table.Container>
      <AddTransactionManually
        isOpen={isAddTxnModalManuallyOpen}
        onClose={closeAddTxnManuallyModal}
        onAddNewTxn={onAddNewTxn}
        minDate={reconcilation_start_date}
        maxDate={reconcilation_end_date}
      />
    </div>
  );
};
