export const Transactions = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_1479)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99999 3.79999C8.38659 3.79999 8.69999 4.11339 8.69999 4.49999V5.49999C8.69999 5.88659 8.38659 6.19999 7.99999 6.19999C7.61339 6.19999 7.29999 5.88659 7.29999 5.49999V4.49999C7.29999 4.11339 7.61339 3.79999 7.99999 3.79999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99999 9.79999C8.38659 9.79999 8.69999 10.1134 8.69999 10.5V11.5C8.69999 11.8866 8.38659 12.2 7.99999 12.2C7.61339 12.2 7.29999 11.8866 7.29999 11.5V10.5C7.29999 10.1134 7.61339 9.79999 7.99999 9.79999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99999 2.69999C5.07288 2.69999 2.69999 5.07288 2.69999 7.99999C2.69999 10.9271 5.07288 13.3 7.99999 13.3C10.9271 13.3 13.3 10.9271 13.3 7.99999C13.3 5.07288 10.9271 2.69999 7.99999 2.69999ZM1.29999 7.99999C1.29999 4.29968 4.29968 1.29999 7.99999 1.29999C11.7003 1.29999 14.7 4.29968 14.7 7.99999C14.7 11.7003 11.7003 14.7 7.99999 14.7C4.29968 14.7 1.29999 11.7003 1.29999 7.99999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.24999 6.19999C7.10412 6.19999 6.96422 6.25793 6.86108 6.36108C6.75793 6.46422 6.69999 6.60412 6.69999 6.74999C6.69999 6.89586 6.75793 7.03575 6.86108 7.1389C6.96422 7.24204 7.10412 7.29999 7.24999 7.29999H8.74999C9.26716 7.29999 9.76315 7.50543 10.1288 7.87113C10.4945 8.23683 10.7 8.73282 10.7 9.24999C10.7 9.76716 10.4945 10.2631 10.1288 10.6288C9.76315 10.9945 9.26716 11.2 8.74999 11.2H6.49999C6.11339 11.2 5.79999 10.8866 5.79999 10.5C5.79999 10.1134 6.11339 9.79999 6.49999 9.79999H8.74999C8.89586 9.79999 9.03575 9.74204 9.1389 9.6389C9.24204 9.53575 9.29999 9.39586 9.29999 9.24999C9.29999 9.10412 9.24204 8.96422 9.1389 8.86108C9.03575 8.75793 8.89586 8.69999 8.74999 8.69999H7.24999C6.73282 8.69999 6.23683 8.49454 5.87113 8.12885C5.50543 7.76315 5.29999 7.26716 5.29999 6.74999C5.29999 6.23282 5.50543 5.73683 5.87113 5.37113C6.23683 5.00543 6.73282 4.79999 7.24999 4.79999H9.49999C9.88659 4.79999 10.2 5.11339 10.2 5.49999C10.2 5.88659 9.88659 6.19999 9.49999 6.19999H7.24999Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6445_1479">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
