import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDeleteInternalTeamAccessMutation } from "store/apis/internalTeamSetting";

export const DeleteInternalTeam = ({
  open,
  onClose,
  teamId,
}: {
  open: boolean;
  onClose: () => void;
  teamId: string;
}) => {
  const [deleteInternalTeamAccess, { isLoading }] =
    useDeleteInternalTeamAccessMutation();
  const { uuid: groupId } = useCurrentGroupContext();
  const { successToast, alertToast } = useToast();

  const deleteTeamAccess = async () => {
    try {
      await deleteInternalTeamAccess({
        groupId,
        teamId,
      }).unwrap();
      successToast({
        message: "Team removed successfully",
        title: "Group",
      });
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      onClose();
    }
  };

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Modal.Content size="regular">
        <Modal.Header>
          <Modal.Title>Remove Team</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-100">
            Are you sure you want to remove the team?{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-gap-2">
            <div className="t-ml-auto t-flex t-gap-2">
              <Button onClick={onClose}>Cancel</Button>
              <Button
                customType="danger"
                onClick={deleteTeamAccess}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Remove
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
