import { updateTaskMerchantFilingStatus } from "apis/merchants";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTable } from "react-table";
import "static/styles/components/taskContractors.css";
import { useGetTaskMerchantsQuery } from "store/apis/taskMerchants";
import { Merchant, MERCHANT_TASK_STATUS_TYPES } from "types/Models/merchant";
import { AnalyseBankStatement } from "./AnalyseBankStatement";
import Loader from "./design/loader";
import { NoContractor } from "./NoContractor";
import Table from "components/DesignSystem/Table/V2/Table";
import { FileRequestOrUpload } from "./WformFileRequestOrUpload";
import { Form1099Files } from "./Form1099Files";
import { Tag } from "./DesignSystem/Tag/Tag";
import {
  RECEIVED,
  SENT,
  NOT_SENT,
  FORM_REQUIRED,
  AWAITING,
  UPLOADED,
  SUBMITTED,
} from "constants/merchantFormStatuses";
import { NOT_SPECIFIED } from "constants/wSeriesFormTypes";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
} from "react-table-8.10.7";
import BlueInfo from "static/images/InfoBlue.svg";
import { Button } from "./DesignSystem/Button/Button";
import { useModal } from "hooks/useModal";
import { useDispatch } from "react-redux";
import { EditContractor } from "./Task1099/EditContractor";
import GrayPencil from "static/images/GrayPencil.svg";
import { Task1099FilingStatus } from "./Task1099/Task1099FilingStatus";
import { IncompleteBooksOnboarding } from "./Task1099/IncompleteBooksOnboarding";

const W_FORM_TEXT_MAP = {
  [RECEIVED]: "Received",
  [SENT]: "Sent",
  [NOT_SENT]: "Not sent",
  [FORM_REQUIRED]: "Form required",
  [AWAITING]: "Awaiting",
  [UPLOADED]: "Uploaded",
  [SUBMITTED]: "Submitted",
};

const W_FORM_STATUS_COLORS = {
  [RECEIVED]: "green",
  [SENT]: "green",
  [NOT_SENT]: "red",
  [FORM_REQUIRED]: "red",
  [AWAITING]: "orange",
  [UPLOADED]: "green",
  [SUBMITTED]: "green",
};

export const WSeriesForm = ({
  withFormProgress,
  groupId,
  noContractorsFound,
  taskSeason,
  isSuccessPage = false,
  shouldLoadBooksOnboarding,
  entityId,
}: {
  withFormProgress?: boolean;
  groupId: string;
  noContractorsFound: boolean;
  taskSeason: string;
  isSuccessPage?: boolean;
  shouldLoadBooksOnboarding: boolean;
  entityId: string;
}): JSX.Element | null => {
  const { taskId } = useParams<{ taskId: string }>();
  const {
    data: merchants = [],
    isLoading: isContractorLoading,
    refetch,
  } = useGetTaskMerchantsQuery({
    groupId,
    taskId,
    season: taskSeason,
    entityId,
  });
  const { alertToast } = useToast();
  const [isStatusLoading, setStatusLoading] = useState(false);
  const { isCustomer } = useRoleBasedView();
  const [merchantForEdit, setMerchantForEdit] = useState<Merchant>();
  const history = useHistory();
  const {
    open: openEditMerchantModal,
    close: closeEditMerchantModal,
    isOpen: showEditMerchantModal,
  } = useModal();
  const dispatch = useDispatch();

  const updateContractors = async () => {
    await refetch().unwrap();
  };

  const updateFilingStatus = useCallback(
    (merchantId: string) => async (status: MERCHANT_TASK_STATUS_TYPES) => {
      try {
        setStatusLoading(true);
        await updateTaskMerchantFilingStatus({
          taskId,
          merchantId,
          status,
        });
        await updateContractors();
        setStatusLoading(false);
      } catch (error: any) {
        setStatusLoading(false);
        alertToast({ message: error?.response?.data?.error?.message });
      }
    },
    [taskId]
  );

  const openMerchantEditModal = (merchant: Merchant) => {
    setMerchantForEdit(merchant);
    openEditMerchantModal();
  };

  const columnHelper = createColumnHelper<Merchant>();
  const oldTask = taskSeason === "2022";
  const conditionalColumns = oldTask
    ? []
    : [
        columnHelper.accessor("filing_status", {
          cell: (info) => {
            const rowData = info.row.original;
            return (
              <Task1099FilingStatus
                filingStatus={rowData.filing_status}
                onUpdate={updateFilingStatus(rowData.uuid)}
                isLoading={isStatusLoading}
                isSuccessPage={isSuccessPage}
              />
            );
          },
          header: "1099 FILING",
          size: 17,
        }),
        columnHelper.accessor("form_1099_document", {
          cell: (info) => {
            const rowData = info.row.original;
            return (
              <Form1099Files
                merchant={rowData}
                taskId={taskId}
                groupId={groupId}
                updateMerchant={updateContractors}
                editable={false}
                isSuccessPage={isSuccessPage}
              />
            );
          },
          header: "1099 FORMS",
          size: 13,
        }),
      ];

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <div className="t-group t-flex t-justify-between t-w-11/12 t-gap-2">
            <div className="t-w-11/12 t-group">
              <div className="t-text-subtext t-text-text-60 t-truncate">
                {rowData.name}
              </div>

              <div className="t-text-body-sm t-text-text-60 t-truncate">
                {rowData.email}
              </div>
            </div>
            {!isSuccessPage && (
              <Button customType="transparent">
                <img
                  src={GrayPencil}
                  alt="GrayPencil"
                  className="t-h-4 group-hover:t-visible t-invisible"
                  onClick={() => openMerchantEditModal(rowData)}
                />
              </Button>
            )}
          </div>
        );
      },
      header: "CONTACT",
      size: oldTask ? 30 : 16,
    }),
    columnHelper.accessor("uuid", {
      cell: (info) => {
        const rowData = info.row.original;
        return (
          <div className="t-w-4/5">
            <FileRequestOrUpload
              merchant={rowData}
              taskId={taskId}
              groupId={groupId}
              updateMerchant={updateContractors}
              editable={!withFormProgress}
              wFormStatus={rowData.form_status}
              season={taskSeason}
              isSuccessPage={isSuccessPage}
            />
          </div>
        );
      },
      header: "W- FORMS",
      size: oldTask ? 25 : 20,
    }),
    columnHelper.accessor("w_form_choice", {
      cell: (info) =>
        info.getValue() === NOT_SPECIFIED ? (
          "-"
        ) : (
          <div className="t-text-body-sm t-w-fit t-text-neutral t-px-2 t-py-1 t-rounded t-bg-surface-grey t-truncate">
            {info.getValue()}
          </div>
        ),
      header: "TYPE",
      size: oldTask ? 20 : 12,
    }),
    columnHelper.accessor("form_status", {
      cell: (info) => {
        const rowData = info.row.original;
        return (
          //@ts-ignore
          <Tag tagType={W_FORM_STATUS_COLORS[rowData.form_status]}>
            <span className="t-whitespace-nowrap t-inline-block t-w-full t-overflow-hidden t-text-ellipsis t-text-body-sm t-line-clamp-1">
              {W_FORM_TEXT_MAP[rowData.form_status]}
            </span>
          </Tag>
        );
      },
      header: "W-FORM STATUS",
      size: oldTask ? 25 : 21,
    }),
    ...conditionalColumns,
  ];

  const table = useReactTable({
    data: merchants || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  if (isContractorLoading) {
    return <Loader />;
  }

  if (shouldLoadBooksOnboarding) {
    return <IncompleteBooksOnboarding groupId={groupId} />;
  }

  if (noContractorsFound && merchants?.length === 0) {
    return <NoContractor />;
  }

  if (merchants?.length === 0) {
    return <AnalyseBankStatement />;
  }

  const tableContent = (
    <Table.Container className="t-h-full">
      <Table.Content className="t-w-full">
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  className="t-text-subtext-sm t-uppercase t-py-2"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{ width: `${cell.column.getSize()}%` }}
                  className="t-py-2"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );

  if (isSuccessPage) {
    return <div className="t-w-full">{tableContent}</div>;
  }

  return (
    <div className="task-contractors pb-3 overflow-auto t-mx-4">
      <div className="t-my-4">
        <div className="t-flex t-justify-between t-items-center t-mb-3">
          <div className="t-text-subtitle-sm t-text-text-100">
            W Series and Form 1099
          </div>
          <Button
            size="small"
            onClick={() => history.push(`/books/merchants?company=${groupId}`)}
          >
            Add contractors
          </Button>
        </div>
        <div className="t-text-body t-text-text-60">
          Below is the list of contractors from your bank statements. Please add
          their contact details and collect W-series forms. If a contractor is
          missing, inform us in chat.
        </div>
      </div>
      <div className="t-bg-blue-0 t-px-4 t-py-3 t-rounded t-mt-5 t-mb-6 t-border t-border-solid t-border-blue-20">
        <div className="t-flex t-items-center t-gap-2 t-mb-1">
          <img src={BlueInfo} alt="BlueInfo" />
          <div className="t-text-blue-80 t-text-subtext-sm">PLEASE NOTE</div>
        </div>
        <div className="t-text-body-sm t-text-blue-90">
          Our team is working to compile this year's list of contractors. In the
          meantime, take a moment to review last year's list contractors.
        </div>
      </div>
      {/* @ts-ignore */}
      {tableContent}
      {merchantForEdit && showEditMerchantModal && (
        <EditContractor
          show={showEditMerchantModal}
          closeModal={closeEditMerchantModal}
          taskId={taskId}
          groupId={groupId}
          //@ts-ignore
          merchant={merchantForEdit}
          refetch={refetch}
          season={taskSeason}
        />
      )}
    </div>
  );
};
