import {
  BillingInfo,
  BillingInvoice,
  PracticeBillingType,
  SavedCard,
} from "types/Models/billing";
import { Profile } from "types/Models/user";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import dayjs from "dayjs";
import { YYYY_MM_DD } from "constants/date";
import { IncompletePaymentIntent } from "./subscriptions";

type BillingInvoicesResult = {
  group_invoices: BillingInvoice[];
  total_pages: number;
  current_page: number;
  total_count: number;
  per_page: number;
  due_amount: number;
};

export const practiceBillingApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    setBillingMethod: build.mutation<
      Profile,
      {
        serviceTeamId: string;
        billingType: PracticeBillingType;
      }
    >({
      query: ({ serviceTeamId, billingType }) => {
        return {
          url: `/api/inkle/serviceteams/${serviceTeamId}/update/`,
          method: "PUT",
          body: {
            billing_type: billingType,
          },
        };
      },
    }),

    getPendingInvoices: build.query<
      { due_amount: number; pending_invoices: BillingInvoice[] },
      {
        serviceTeamId: string;
      }
    >({
      query: ({ serviceTeamId }) => {
        return {
          url: `/api/inkle/billing/service-team/${serviceTeamId}/pending-invoices/`,
        };
      },
      providesTags: ["PRACTICE_INVOICES"],
    }),

    getPracticeInvoice: build.query<
      BillingInvoicesResult,
      {
        serviceTeamId: string;
        page_num?: number;
        search_term?: string;
        seasons?: string[];
        payment_statuses?: string[];
        from_date?: string;
        to_date?: string;
        invoicesId?: string;
      }
    >({
      query: ({
        serviceTeamId,
        page_num,
        search_term,
        seasons,
        payment_statuses,
        from_date,
        to_date,
        invoicesId,
      }) => {
        let params = qs.stringify(
          {
            page_num,
            search_term: search_term || null,
            seasons,
            payment_statuses,
            from_date: from_date ? dayjs(from_date).format(YYYY_MM_DD) : null,
            to_date: to_date ? dayjs(to_date).format(YYYY_MM_DD) : null,
            invoices: invoicesId,
          },
          {
            skipNulls: true,
          }
        );
        return {
          url: `/api/inkle/billing/service-team/${serviceTeamId}/invoices/`,
          params: {
            ...qs.parse(params),
          },
        };
      },
      providesTags: ["PRACTICE_INVOICES"],
    }),

    getMultipleInvoices: build.query<
      { uuid: string; status: string }[],
      {
        serviceTeamId: string;
        invoicesId?: string;
      }
    >({
      query: ({ serviceTeamId, invoicesId }) => {
        return {
          url: `api/inkle/payments/service-team/${serviceTeamId}/`,
          params: {
            invoices: invoicesId,
          },
        };
      },
      providesTags: ["PRACTICE_INVOICES"],
    }),

    getPracticeInvoiceById: build.query<
      BillingInvoice,
      {
        serviceTeamId: string;
        invoiceId: string;
      }
    >({
      query: ({ serviceTeamId, invoiceId }) => {
        return `/api/inkle/billing/service-team/${serviceTeamId}/invoice/${invoiceId}/`;
      },
      providesTags: ["PRACTICE_INVOICES"],
    }),

    savePracticePaymentMethod: build.mutation<
      SavedCard,
      {
        serviceTeamId: string;
        paymentMethodId: string;
        isDefaultCard: boolean;
      }
    >({
      query: ({ serviceTeamId, isDefaultCard, paymentMethodId }) => {
        return {
          url: `/api/inkle/payments/service-team/${serviceTeamId}/saved/`,
          body: {
            payment_method_id: paymentMethodId,
            is_default_card: isDefaultCard,
          },
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? ["PRACTICE_PAYMENT_METHODS"] : []),
    }),

    getPaymentMethods: build.query<
      SavedCard[],
      {
        serviceTeamId: string;
      }
    >({
      query: ({ serviceTeamId }) => {
        return `/api/inkle/payments/service-team/${serviceTeamId}/saved/`;
      },

      providesTags: ["PRACTICE_PAYMENT_METHODS"],
    }),

    deletePracticePaymentMethod: build.mutation<
      void,
      {
        serviceTeamId: string;
        paymentMethodId: string;
      }
    >({
      query: ({ serviceTeamId, paymentMethodId }) => {
        return {
          url: `/api/inkle/payments/service-team/${serviceTeamId}/payment/${paymentMethodId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["PRACTICE_PAYMENT_METHODS"] : []),
    }),

    initiatePayment: build.mutation<
      IncompletePaymentIntent,
      {
        serviceTeamId: string;
        invoices: string[];
      }
    >({
      query: ({ serviceTeamId, invoices }) => {
        return {
          url: `api/inkle/payments/service-team/${serviceTeamId}/`,
          body: {
            invoices,
          },
          method: "POST",
        };
      },
    }),

    getPracticeBillingInfo: build.query<
      BillingInfo,
      {
        serviceTeamId: string;
      }
    >({
      query: ({ serviceTeamId }) => ({
        url: `/api/inkle/billing/service-team/${serviceTeamId}/billing_info/`,
      }),
      providesTags: () => ["PRACTICE_BILLING_INFO"],
    }),

    updateBillingInfo: build.mutation<
      BillingInfo,
      {
        serviceTeamId: string;
        billingEmail?: string;
        invoiceEmails?: string[];
      }
    >({
      query: ({ serviceTeamId, billingEmail, invoiceEmails }) => ({
        url: `/api/inkle/billing/service-team/${serviceTeamId}/billing_info/`,
        method: "PATCH",
        body: {
          billing_email: billingEmail,
          invoice_emails: invoiceEmails,
        },
      }),
      invalidatesTags: (result) => (result ? ["PRACTICE_BILLING_INFO"] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPendingInvoicesQuery,
  useSetBillingMethodMutation,
  useGetPracticeInvoiceQuery,
  useLazyGetMultipleInvoicesQuery,
  useGetPracticeInvoiceByIdQuery,
  useGetPaymentMethodsQuery,
  useSavePracticePaymentMethodMutation,
  useDeletePracticePaymentMethodMutation,
  useInitiatePaymentMutation,
  useGetPracticeBillingInfoQuery,
  useUpdateBillingInfoMutation,
} = practiceBillingApi;
