import ConditionalToolTip from "components/design/conditionalToolTip";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Field, FieldProps, getIn } from "formik";
import React, { memo, useEffect } from "react";
import { useGetCountriesDataQuery } from "store/apis/onboarding";

const MultiValue = ({
  index,
  tooltipText,
  displayValue,
  remainingCount,
}: {
  index: any;
  tooltipText: string;
  displayValue?: string | null;
  remainingCount: number | null;
}) => {
  if (!index) {
    return (
      <ConditionalToolTip condition={tooltipText && tooltipText}>
        <span className="t-flex t-items-center t-max-h-6 t-overflow-hidden">
          <span>{displayValue}</span>
          {remainingCount !== null && <span>+{remainingCount}</span>}
        </span>
      </ConditionalToolTip>
    );
  }
  return null;
};

export const ForeignQualificationState = memo((info: any) => {
  const { data: countries = [] } = useGetCountriesDataQuery();
  const rowId = info.row.id;

  return (
    <Field>
      {({ form: { values } }: FieldProps) => {
        const selectedCountry = getIn(values, `entityData[${rowId}].country`);

        const selectedStateIds = getIn(
          values,
          `entityData[${rowId}].foreignQualificationStateIds`
        );

        const selectedStates =
          countries
            .find(({ uuid }) => uuid === selectedCountry)
            ?.states.filter((s) => selectedStateIds?.includes?.(s.uuid)) || [];

        const displayValue =
          selectedStates.length > 0 ? selectedStates[0].name : null;
        const remainingCount =
          selectedStates.length > 1 ? selectedStates.length - 1 : null;

        const tooltipText = selectedStates.map(({ name }) => name).join(",");

        return (
          <Combobox
            components={{
              ClearIndicator: () => null,
              MultiValue: ({ index }: { index: any }) => (
                <MultiValue
                  index={index}
                  displayValue={displayValue}
                  remainingCount={remainingCount}
                  tooltipText={tooltipText}
                />
              ),
            }}
            hideError
            menuPortalTarget={document.body}
            size="small"
            name={`entityData[${rowId}].foreignQualificationStateIds`}
            withForm
            isMulti
            value={
              selectedStates.length > 0
                ? selectedStates.map((selectedState) => ({
                    value: selectedState?.uuid,
                    label: selectedState?.name,
                  }))
                : []
            }
            options={
              countries
                .find(
                  (c) =>
                    c.uuid === getIn(values, `entityData[${rowId}].country`)
                )
                ?.states?.map(({ name, uuid }) => ({
                  label: name,
                  value: uuid,
                })) || []
            }
          />
        );
      }}
    </Field>
  );
});
