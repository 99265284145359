import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik, FormikValues } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useParams } from "react-router-dom";
import {
  useGetReconciliationStatusQuery,
  useUpdateStatementBalanceMutation,
} from "store/apis/reconciliation";

export const UpdateStatementBalance = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { successToast, alertToast } = useToast();
  const { reconcilationId } = useParams<{ reconcilationId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: csvTxn } = useGetReconciliationStatusQuery(
    {
      reconcilationId,
      entityId,
      groupId,
    },
    { skip: !groupId || !entityId }
  );
  const { statement_ending_balance, reconcilation_end_date } = csvTxn || {};

  const [updateReconcilationStatementBalance] =
    useUpdateStatementBalanceMutation();

  const onSubmit = async (values: FormikValues) => {
    try {
      await updateReconcilationStatementBalance({
        entityId,
        reconcilationId,
        groupId,
        statementEndingBalance: values.statementEndingBalance,
      }).unwrap();
      successToast({ message: "Statement balance updated!" });
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Update statement balance</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            statementEndingBalance: statement_ending_balance || 0,
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="t-m-0 t-w-full">
              <Modal.Body className="t-text-body t-text-text-100">
                <PriceInput
                  name="statementEndingBalance"
                  label={`Enter statement ending balance on ${dayjs(
                    reconcilation_end_date
                  ).format(DD_MMM_YYYY)}`}
                />
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-end t-gap-3 t-items-center">
                <Button type="reset" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
