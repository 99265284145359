import React from "react";
import { DynamicToolTip } from "./dynamicToolTip";

const ConditionalDynamicToolTip = ({ condition, children }) => {
  const ToolTipElement = condition ? DynamicToolTip : React.Fragment;
  const props = condition ? { text: condition } : {};

  return <ToolTipElement {...props}>{children}</ToolTipElement>;
};

export default ConditionalDynamicToolTip;
