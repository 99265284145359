import { BookkeepingPlan } from "components/Addons/BookkeepingPlan";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Button } from "components/DesignSystem/Button/Button";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { taskApis } from "store/apis/task";
import { AddonData } from "store/slices/addons";
import { Subscription } from "types/Models/subscription";

type DoBooksWithInkleProps = {
  field: any;
  groupId: string;
  entityId: string;
};

export const DoBooksWithInkle = ({
  field,
  groupId,
  entityId,
}: DoBooksWithInkleProps) => {
  const { data: subscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );
  const paygSubscriptionData =
    subscriptions?.find(
      (subscription) => subscription.bookkeeping_product_type === "BOOKS_PAYG"
    ) || {};
  const {
    open: openPaygSubscriptionModal,
    close: closePaygSubscriptionModal,
    isOpen: showPaygSubscriptionModal,
  } = useModal();
  const {
    open: openSubscriptionCheckoutModal,
    close: closeSubscriptionCheckoutModal,
    isOpen: showSubscriptionCheckoutModal,
  } = useModal();
  const dispatch = useDispatch();
  const { successToast } = useToast();
  const { taskId } = useParams<{ taskId: string }>();

  const closeCheckoutModal = () => {
    dispatch(taskApis.util.invalidateTags([{ type: "Tasks", id: taskId }]));
    closeSubscriptionCheckoutModal();
    closePaygSubscriptionModal();
  };

  const openCheckoutModal = () => {
    openSubscriptionCheckoutModal();
    closePaygSubscriptionModal();
  };

  return (
    <div className="t-flex t-gap-4 t-items-center t-justify-between t-p-1 t-pl-2 t-rounded t-border t-border-solid t-border-neutral-0 t-bg-purple-0">
      <div className="t-text-subtext t-text-text-60">{field.label}</div>
      <Button
        customType={field.cta.cta_type}
        size="small"
        onClick={openPaygSubscriptionModal}
        type="button"
      >
        {field.cta.cta_label}
      </Button>
      {showPaygSubscriptionModal && (
        <BookkeepingPlan
          subscriptionData={paygSubscriptionData as AddonData}
          onClose={closePaygSubscriptionModal}
          openCheckoutModal={openCheckoutModal}
        />
      )}
      <CheckoutModal
        entityId={entityId}
        type="subscription"
        title="Subscription"
        onClose={closeSubscriptionCheckoutModal}
        open={showSubscriptionCheckoutModal}
        subscription={paygSubscriptionData as Subscription}
        onSubscribed={closeCheckoutModal}
      />
    </div>
  );
};
