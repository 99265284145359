import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Link } from "components/DesignSystem/Link/Link";
import { FieldBuilder } from "components/Entity/EntityFormEngine/FieldBuilder";
import { AddEditAddress } from "components/fileTax/AddEditAddress";
import { Cross } from "components/icons/Cross";
import { PlusIcon } from "components/icons/PlusIcon";
import { NumericInput } from "components/NumericInput/NumericInput";
import { Formik, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import Check from "static/images/check.svg";
import { useGetAutofillQuery } from "store/apis/autofill";
import {
  useAddMissingDataPointMutation,
  useGetMissingDataPointsQuery,
} from "store/apis/deadlineAlarm";
import { AddressAutofill } from "types/Models/addressAutofill";
import { AutoFillType } from "types/Models/bankAutofill";
import { MissingDataPoint } from "types/Models/deadlineAlarm";
import { EntityDataAttribute } from "types/Models/entity";
import { BackendError } from "types/utils/error";

const MissingDataItem = ({
  name,
  fieldName,
  children,
}: {
  name: string;
  fieldName: string;
  children: React.ReactNode;
}) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [showAddDetail, setShowAddDetail] = useState(false);
  const { alertToast, successToast } = useToast();
  const [addMissingDataPoint, { isLoading }] = useAddMissingDataPointMutation();

  const { values } =
    useFormikContext<Record<string, EntityDataAttribute["value"]>>();

  const onAddDetails = async () => {
    try {
      await addMissingDataPoint({
        entityId,
        groupId,
        value: values[name] as string,
        missingDataPointId: name,
      }).unwrap();

      setShowAddDetail(false);
      successToast({
        message: "Details added",
      });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const containerVariants = {
    hidden: {
      x: 100,
      opacity: 0,
      transition: { duration: 0.8, ease: "easeInOut" },
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: { staggerChildren: 1, duration: 0.3, ease: "easeInOut" },
    },
    exit: {
      x: -100,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
  };

  if (showAddDetail) {
    return (
      <AnimatePresence>
        <motion.div
          className="t-grid t-grid-cols-4 t-gap-3"
          layout
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          key="addDetail"
        >
          <span className="t-col-span-3">{children}</span>
          <div className="t-flex t-items-center t-self-end">
            <Button
              customType="ghost_icon"
              size="small"
              onClick={onAddDetails}
              isLoading={isLoading}
              disabled={isLoading}
            >
              <img src={Check} alt="add" className="t-h-4 t-w-4" />
            </Button>
            <Button
              customType="ghost_icon"
              size="small"
              onClick={() => setShowAddDetail(false)}
              disabled={isLoading}
            >
              <span className="t-text-text-30">
                <Cross color="currentColor" stroke={1.5} size="16" />
              </span>
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    );
  }

  return (
    <motion.div
      className="t-grid t-grid-cols-6 t-gap-3 t-justify-between t-items-center t-text-text-30"
      layout
      initial="hidden"
      animate="visible"
      exit="exit"
      key="fieldDisplay"
      variants={containerVariants}
    >
      <span className="t-col-span-5 t-text-caption">{fieldName}</span>
      <Button
        size="small"
        customType="ghost_icon"
        onClick={() => setShowAddDetail(true)}
      >
        <PlusIcon size="16" color="currentColor" stroke="1.5" />
      </Button>
    </motion.div>
  );
};

const AutoFill = ({
  name,
  lable,
  autofillKey,
}: {
  name: string;
  lable: string;
  autofillKey: AutoFillType;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const editModal = useModal();
  const { values, setFieldValue } =
    useFormikContext<Record<string, EntityDataAttribute["value"]>>();

  const { data: autoFill = [], isLoading: isLoadingAddresses } =
    useGetAutofillQuery(
      {
        groupId,
        entityId,
        autofillKey: autofillKey,
      },
      { skip: !groupId || !entityId }
    );

  const value = values[name];

  const selectedAutofill = autoFill.find((address) => address.uuid === value);

  return (
    <>
      <Combobox
        isDisabled={isLoadingAddresses}
        isLoading={isLoadingAddresses}
        actions={
          // TODO: Add onClick to open modal for all type of autofill
          <Button customType="link" onClick={editModal.open}>
            Add address
          </Button>
        }
        components={{ ClearIndicator: () => null }}
        menuPortalTarget={document.body}
        value={
          selectedAutofill
            ? {
                label: selectedAutofill?.autofill_string,
                value: selectedAutofill?.uuid,
              }
            : null
        }
        withForm
        name={name}
        label={lable}
        options={autoFill.map((address) => {
          return {
            label: (
              <div className="t-text-body t-w-full t-truncate">
                {address.autofill_string}
              </div>
            ),
            value: address.uuid,
          };
        })}
      />
      {/* TODO: add modal for all type of autofill */}
      {editModal.isOpen && (
        <AddEditAddress
          entityId={entityId}
          isOpenAdd={editModal.isOpen}
          onClose={editModal.close}
          onSuccess={(address: AddressAutofill) => {
            setFieldValue(name, address.uuid);
          }}
          groupId={groupId}
          autofillKey={autofillKey}
        />
      )}
    </>
  );
};

const StakeholdersPercentage = ({
  name,
  lable,
}: {
  name: string;
  lable: string;
}) => {
  return (
    <NumericInput
      fieldProps={{ name }}
      storeNumeric
      numericProps={{
        fixedDecimalScale: true,
        decimalScale: 2,
        suffix: " %",
        max: 100,
        allowNegative: false,
      }}
      label={lable}
    />
  );
};

const MissingDataItemRender = ({ field }: { field: MissingDataPoint }) => {
  switch (field.app_name) {
    case "DATA_ATTRIBUTES":
      return (
        <FieldBuilder field={{ ...field?.data_attribute, name: field.uuid }} />
      );

    case "STAKEHOLDERS_PERCENTAGE":
      return (
        <StakeholdersPercentage name={field.uuid} lable={field.field_name} />
      );

    case "ADDRESS_AUTOFILL":
      return (
        <AutoFill
          name={field.uuid}
          lable={field.field_name}
          autofillKey="addresses"
        />
      );

    case "PERSONA_AUTOFILL":
      return (
        <AutoFill
          name={field.uuid}
          lable={field.field_name}
          autofillKey="addresses"
        />
      );

    default:
      return null;
  }
};

export const MissingDataPoints = () => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { isAdmin } = useRoleBasedView();

  const {
    data: missingDataPoints = [],
    isLoading,
    isSuccess,
  } = useGetMissingDataPointsQuery(
    { groupId, entityId },
    {
      skip: !entityId || !groupId,
    }
  );

  const isEmpty = missingDataPoints.length === 0;

  const initialValues = useMemo(() => {
    return missingDataPoints.reduce((acc, field) => {
      acc[field.uuid] = field?.data_attribute?.value;
      return acc;
    }, {} as Record<string, EntityDataAttribute["value"]>);
  }, [missingDataPoints]);

  const entityRoute = isAdmin
    ? `/admin/crm/${groupId}/entity/${entityId}`
    : `/tax/entities/entity/${entityId}`;

  return (
    <div className="t-border t-border-solid t-rounded-lg t-border-neutral-10 t-bg-surface-lighter-grey t-p-6 t-flex t-gap-6 t-flex-col t-h-full t-overflow-auto">
      <div className="t-flex t-flex-col t-gap-1">
        <div className="t-text-subtitle">
          <span className="t-text-red">Stay compliant!</span>
          <br />
          <span className="t-text-text-100">
            Add details to never miss a deadline.
          </span>
        </div>
        <div className="t-text-body-sm t-text-text-30">
          Get accurate deadline view after punching the information :-
        </div>
      </div>
      <Link to={entityRoute} target="_blank">
        <Button customType="primary" block>
          Go to main data store
        </Button>
      </Link>
      <Async.Root isEmpty={isEmpty} isLoading={isLoading} isSuccess={isSuccess}>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <Formik
            initialValues={initialValues}
            onSubmit={() => {}}
            enableReinitialize
          >
            <AnimatePresence>
              {missingDataPoints.map((field) => (
                <MissingDataItem
                  name={field.uuid}
                  fieldName={field.field_name}
                  key={field.field_key}
                >
                  <MissingDataItemRender field={field} key={field.uuid} />
                </MissingDataItem>
              ))}
            </AnimatePresence>
          </Formik>
        </Async.Success>
      </Async.Root>
    </div>
  );
};
