import { CartMedium } from "components/icons/CartMedium";
import { FREE_PLAN } from "constants/subscriptionType";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { ReactNode, useContext, useState } from "react";
import {
  openFreeSubscription,
  openSubscriptionModal,
  setRedirectPath,
} from "store/slices/subscriptionModal";
import Lightning from "static/images/Lightning.svg";
import { Button } from "components/DesignSystem/Button/Button";
import { useGetCartQuery } from "store/apis/products";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { Notification } from "components/Notification";
import { LeftBarLogo } from "components/Sidebar/LeftBar";
import { InkleBooks } from "components/icons/Logos/InkleBooks";
import { InkleBooksSymbol } from "components/icons/Logos/InkleBooksSymbol";
import { Hamburger } from "components/icons/Hamburger";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { ConditionalLink } from "components/conditionalLink";
import { CallUs } from "./CallUs";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";

export const TopBar = ({
  nav,
  logo,
  children,
  upgradeButton,
}: {
  nav?: ReactNode;
  logo?: ReactNode;
  children?: ReactNode;
  upgradeButton?: ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  const { uuid: groupId, entities } = useCurrentGroupContext();

  const entityId = entities[0]?.uuid;

  const { data: cart } = useGetCartQuery(
    { groupId: groupId, entityId },
    { skip: !groupId || !entityId }
  );

  const { isCustomer, isInvestor, isAdmin, isCpa } = useRoleBasedView();

  const { link } = useConstructInternalLink();

  return (
    <div className="t-flex t-px-10 md:t-justify-end t-gap-3 t-py-3 t-bg-surface-lighter-grey t-items-center md:t-bg-surface-transparent">
      <div className="md:t-hidden t-block">
        <Button customType="icon" onClick={() => setOpen(true)} size="small">
          <Hamburger />
        </Button>
      </div>

      <div className="md:t-hidden t-block">{logo}</div>

      {open && (
        <div className="t-fixed t-inset-0 t-z-sidebar t-h-full">
          <DashboardContainer className="t-h-full">
            <DashboardContainer.Header>
              <div className="t-flex t-gap-3 t-bg-surface-lighter-grey t-p-3 t-items-center">
                <Button
                  customType="icon"
                  onClick={() => setOpen(false)}
                  size="small"
                >
                  <Hamburger />
                </Button>
                <div>{logo}</div>
                {isCustomer && !isInvestor && Boolean(upgradeButton) && (
                  <div className="t-mr-4 t-flex t-items-center t-ml-auto">
                    {upgradeButton}
                  </div>
                )}
                {isCustomer && !isInvestor && (
                  <div className="t-ml-auto">
                    <ConditionalLink to={link("/settings/billing/cart")}>
                      <Button customType="icon" size="small">
                        <div className="t-text-text-30">
                          {cart && cart?.order_summary.items_count > 0 && (
                            <span className="t-border-solid t-border t-border-surface t-absolute t-right-1 t-top-1 t-h-2 t-w-2 t-rounded-full t-z-10 t-overflow-hidden">
                              <span className="t-bg-red-50 t-h-2 t-w-2 t-block"></span>
                            </span>
                          )}
                          <CartMedium />
                        </div>
                      </Button>
                    </ConditionalLink>
                  </div>
                )}
                {isCustomer && !isInvestor && (
                  <div className="t-ml-0">
                    <Notification />
                  </div>
                )}
              </div>
            </DashboardContainer.Header>
            <DashboardContainer.Content onClick={() => setOpen(false)}>
              {nav}
            </DashboardContainer.Content>
          </DashboardContainer>
        </div>
      )}

      <div className="t-ml-auto t-flex t-gap-3">
        {Boolean(upgradeButton) && upgradeButton}
        {isCustomer && <CallUs shouldShowUpgrade={!open} />}

        {isCustomer && !isInvestor && !open && (
          <ConditionalLink to={link("/settings/billing/cart")}>
            <Button customType="icon" size="small">
              <div className="t-text-text-30">
                {cart && cart?.order_summary.items_count > 0 && (
                  <span className="t-border-solid t-border t-border-surface t-absolute t-right-1 t-top-1 t-h-2 t-w-2 t-rounded-full t-z-10 t-overflow-hidden">
                    <span className="t-bg-red-50 t-h-2 t-w-2 t-block"></span>
                  </span>
                )}
                <CartMedium />
              </div>
            </Button>
          </ConditionalLink>
        )}

        {(isCustomer || isAdmin || isCpa) && !isInvestor && !open && (
          <Notification />
        )}
      </div>
      {children}
    </div>
  );
};
