import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import {
  IN_PROGRESS,
  PENDING,
  WORK_IN_PROGRESS,
} from "constants/billingInvoiceStatuses";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useModal } from "hooks/useModal";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLazyGetBillingInvoicesQuery } from "store/apis/billingInvoice";
import { BillingInvoice } from "types/Models/billing";
import { Pagination } from "types/Models/pagination";

export const PayDebt = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const group = useCurrentGroup();
  const groupId = group?.uuid;
  const entityId = useCurrentEntityId();

  const {
    isOpen: isPaymentModalOpen,
    open: openPaymentModal,
    close: closePaymentModal,
  } = useModal();

  const [pageNumber, setPageNumber] = useState(1);
  const [invoiceList, setInvoicesList] = useState<
    Pagination & { group_invoices: BillingInvoice[]; due_amount: number }
  >();

  const {
    group_invoices: invoices = [],
    total_pages = 0,
    current_page = 0,
    due_amount: dueAmount = 0,
  } = invoiceList || {};

  const [getInvoices, { isLoading }] = useLazyGetBillingInvoicesQuery();

  const loadMore = useCallback(async () => {
    if (groupId) {
      const invoicesRes = await getInvoices({
        groupId: groupId,
        pageNumber: pageNumber,
        entityId,
        paymentStatuses: [PENDING, IN_PROGRESS, WORK_IN_PROGRESS].join(","),
      }).unwrap();

      setInvoicesList((invoices) => {
        const groupInvoices = [
          ...(invoices?.group_invoices || []),
          ...invoicesRes.group_invoices,
        ].reduce((acc, c) => {
          const ids = acc.map((i) => i.uuid);

          if (ids.includes(c.uuid)) {
            return acc;
          }

          return [...acc, c];
        }, [] as BillingInvoice[]);

        const paginatedData = {
          ...invoicesRes,
          group_invoices: groupInvoices,
        };

        return paginatedData;
      });
    }
  }, [groupId, pageNumber]);

  const onInvoicePaid = () => {
    closePaymentModal();
    onClose();
  };

  useEffect(() => {
    loadMore();
  }, [loadMore]);

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Overdue Invoices</Modal.Title>
            <Modal.Close />
          </Modal.Header>

          <Modal.Body>
            {isLoading && <Loader />}
            {invoiceList && invoiceList?.group_invoices.length > 0 && (
              <div>
                <p className="t-text-red t-mb-6">
                  Please clear the overdue invoices to access the platform
                </p>
                <div className="t-flex t-flex-col t-gap-3">
                  <p className="t-text-subtext t-m-0">Overdue invoices</p>
                  <div
                    className="t-h-80 t-overflow-auto"
                    id="invoice-scroll-window"
                  >
                    <InfiniteScroll
                      className="t-flex t-flex-col t-gap-3  t-h-[330px] t-overflow-auto"
                      dataLength={invoiceList?.group_invoices.length}
                      next={() => setPageNumber((p) => p + 1)}
                      hasMore={current_page < total_pages}
                      scrollThreshold={0.5}
                      scrollableTarget={"invoice-scroll-window"}
                      loader={
                        <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
                          Loading...
                        </div>
                      }
                    >
                      {invoices
                        .filter((i) => !isNaN(Number(i.amount)))
                        .map((invoice) => (
                          <div
                            className="t-flex t-justify-between t-text-text-60"
                            key={invoice.uuid}
                          >
                            <p className="t-m-0">{invoice.name}</p>
                            <p className="t-m-0">
                              <AmountSuperScript
                                amount={
                                  invoice.discount
                                    ? Number(invoice.amount) -
                                      Number(invoice.discount)
                                    : Number(invoice.amount)
                                }
                              />
                            </p>
                          </div>
                        ))}
                    </InfiniteScroll>
                  </div>

                  <div className="t-border-0 t-border-solid t-border-t t-border-neutral-10" />

                  <div className="t-flex t-justify-between t-text-subtitle-sm">
                    <p className="t-m-0">Amount Overdue</p>
                    <p className="t-m-0">
                      <AmountSuperScript amount={dueAmount} />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button block customType="primary" onClick={openPaymentModal}>
              <div className="t-flex t-gap-2">
                <span>Pay</span> <AmountSuperScript amount={dueAmount} />
              </div>
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>

      {isPaymentModalOpen && invoiceList?.group_invoices && (
        <CheckoutModal
          entityId={entityId}
          type="invoices"
          onInvoicePaid={onInvoicePaid}
          open={isPaymentModalOpen}
          onClose={closePaymentModal}
          invoices={invoiceList.group_invoices
            .filter((i) => !isNaN(Number(i.amount)))
            .filter(Boolean)}
        />
      )}
    </>
  );
};
