import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import ThreeDots from "static/images/ThreeDots.svg";
import {
  useArchiveGroupMutation,
  useArchiveUserMutation,
} from "store/apis/crm";
import {
  useUpdateGroupDataMutation,
  useUpdateUserDataMutation,
} from "store/apis/salesCrm";
import { ConfirmationModal } from "./ConfirmationModal";

type CrmUnarchiveActionProps = {
  actionOn: "group" | "profile";
  uuid: string;
};

export const CrmUnarchiveAction = ({
  actionOn,
  uuid,
}: CrmUnarchiveActionProps) => {
  const deleteModal = useModal();
  const restoreModal = useModal();
  const isForProfile = actionOn === "profile";
  const { alertToast, successToast } = useToast();
  const [updateGroup, { isLoading: isUpdatingGroup }] =
    useUpdateGroupDataMutation();
  const [updateUser, { isLoading: isUpdatingUser }] =
    useUpdateUserDataMutation();
  const [archiveUser, { isLoading: isUserDeleting }] = useArchiveUserMutation();
  const [archiveGroup, { isLoading: isGroupDeleting }] =
    useArchiveGroupMutation();

  const handleRestore = async () => {
    try {
      if (isForProfile) {
        await updateUser({
          payload: { restore: true },
          profileId: uuid,
        }).unwrap();
        successToast({ message: "User restored successfully!" });
        restoreModal.close();
        return;
      }

      await updateGroup({
        payLoad: { restore: true },
        groupId: uuid,
      }).unwrap();
      successToast({ message: "Group restored successfully!" });
      restoreModal.close();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const handleDelete = async () => {
    try {
      if (isForProfile) {
        const payload = {
          profile_id: uuid,
          archive: true,
        };
        await archiveUser({ payload }).unwrap();
        successToast({
          message: `User archived successfully!"}`,
        });
        deleteModal.close();
        return;
      }

      const payload = { archive: true };
      await archiveGroup({ groupId: uuid, payload }).unwrap();
      successToast({ message: "Group archived successfully!" });
      deleteModal.close();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <img src={ThreeDots} alt="Show more options" />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={6} align="end">
            <Dropdown.Item onSelect={restoreModal.open} key="remove">
              Restore
            </Dropdown.Item>
            <Dropdown.Item onSelect={deleteModal.open} key="churned">
              Delete
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      {/* delete modal */}
      <ConfirmationModal
        show={deleteModal.isOpen}
        close={deleteModal.close}
        action={"Delete"}
        actionOn={actionOn}
        handleClick={handleDelete}
        isLoading={isUserDeleting || isGroupDeleting}
      />
      {/* restore modal */}
      <ConfirmationModal
        show={restoreModal.isOpen}
        close={restoreModal.close}
        action={"Restore"}
        actionOn={actionOn}
        handleClick={handleRestore}
        isLoading={isUpdatingGroup || isUpdatingUser}
      />
    </div>
  );
};
