import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { InvoiceSettings } from "components/InvoiceSettings/InvoiceSettings";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode } from "react";
import {
  Route,
  Switch,
  matchPath,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Tab from "components/DesignSystem/Tab/Tab";
import { AddInvoice } from "./AddInvoice";
import { InvoiceCustomers } from "./InvoiceCustomers";
import { InvoiceCustomerInfo } from "./InvoiceCustomerInfo";
import { Preview } from "components/PreviewModal";
import { InvoiceList } from "components/InvoiceList/InvoiceList";
import { parse, stringify } from "qs";
import { useAnalytics } from "hooks/useAnalytics";
import {
  CLICKED_CUSTOMERS_TAB_IN_INVOICE,
  CLICKED_SETTINGS_TAB_IN_INVOICE,
} from "constants/analyticsEvents";

const Layout = ({ children, url }: { children: ReactNode; url: string }) => {
  const { pathname } = useLocation();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { trackEvent } = useAnalytics();

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Invoicing"
          bottom={
            <Tab.Root defaultValue={pathname} value="MATCH">
              <Tab.List>
                <Tab.NavTrigger
                  exact
                  to={`${url}${search}`}
                  value={
                    matchPath(pathname, { path: url, exact: true })
                      ? "MATCH"
                      : ""
                  }
                >
                  Invoices
                </Tab.NavTrigger>

                <Tab.NavTrigger
                  exact
                  to={`${url}/customers${search}`}
                  value={
                    matchPath(pathname, { path: `${url}/customers` })
                      ? "MATCH"
                      : ""
                  }
                >
                  <div
                    onClick={() => trackEvent(CLICKED_CUSTOMERS_TAB_IN_INVOICE)}
                  >
                    Customers
                  </div>
                </Tab.NavTrigger>

                <Tab.NavTrigger
                  exact
                  to={`${url}/settings${search}`}
                  value={
                    matchPath(pathname, { path: `${url}/settings` })
                      ? "MATCH"
                      : ""
                  }
                >
                  <div
                    onClick={() => trackEvent(CLICKED_SETTINGS_TAB_IN_INVOICE)}
                  >
                    Settings
                  </div>
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      }
    >
      {children}
    </DashboardLayout>
  );
};

export const InvoicesHome = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );
  const breadcrumbs = [
    {
      name: "Invoicing",
      link: `${url}${location.search}`,
    },
  ];
  const group = useCurrentGroupContext();
  const history = useHistory();

  return (
    <Switch>
      <Route exact path={`${path}/customers`}>
        <Layout url={url}>
          <InvoiceCustomers />
        </Layout>
      </Route>

      <Route exact path={`${path}/settings`}>
        <Layout url={url}>
          <InvoiceSettings />
        </Layout>
      </Route>

      <Route path={`${path}/customers/:customerId`}>
        <InvoiceCustomerInfo
          breadcrumbs={[
            { name: "Invoicing", link: `${url}${search}` },
            { name: "Customers", link: `${url}/customers${search}` },
          ]}
        />
      </Route>

      <Route path={`${path}/preview/:previewId/`}>
        <Layout url={url}>
          <InvoiceList />
          <Preview
            closeModal={() => history.push(`${url}${search}`)}
            groupId={group.uuid}
            showModal={true}
          />
        </Layout>
      </Route>

      <Route path={`${path}/:invoiceId`}>
        <AddInvoice breadcrumbs={breadcrumbs} />
      </Route>

      <Route path={path}>
        <Layout url={url}>
          <InvoiceList />
        </Layout>
      </Route>
    </Switch>
  );
};
