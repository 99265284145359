export const InkleBooksSymbolSmall = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.26235 6.86621H6.34118C6.30728 6.86621 6.31481 6.93493 6.42195 6.98987C6.50767 7.03383 6.51987 7.18707 6.51525 7.2582V12.0195H8.47589V7.31133C8.40398 6.99317 7.84071 6.88202 7.56805 6.86621H7.26235Z"
      fill="url(#paint0_linear_5960_17654)"
    />
    <path
      d="M4.07387 5.15723H3.1527C3.1188 5.15723 3.12633 5.24874 3.23348 5.3219C3.31919 5.38043 3.33139 5.5845 3.32677 5.67921V12.0195H5.28742V5.74997C5.21551 5.32629 4.65223 5.17827 4.37958 5.15723H4.07387Z"
      fill="url(#paint1_linear_5960_17654)"
    />
    <path
      d="M0.939109 3.21973H0.017935C-0.0159655 3.21973 -0.00843208 3.33675 0.0987104 3.43031C0.184424 3.50516 0.196623 3.76611 0.192008 3.88723V11.9951H2.15265V3.97771C2.08074 3.43592 1.51746 3.24664 1.24481 3.21973H0.939109Z"
      fill="url(#paint2_linear_5960_17654)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.85712 1.73031H0V0.0195312H6.85712C7.70856 0.0195312 8.96665 0.267406 10.0365 1.03613C11.1501 1.83626 12 3.16229 12 5.15186V11.995H10.2857V5.15186C10.2857 3.71987 9.70699 2.90744 9.03485 2.42448C8.319 1.91013 7.43424 1.73031 6.85712 1.73031Z"
      fill="url(#paint3_linear_5960_17654)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5960_17654"
        x1="6.32324"
        y1="9.44285"
        x2="8.47589"
        y2="9.44285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5960_17654"
        x1="3.13477"
        y1="8.58837"
        x2="5.28742"
        y2="8.58837"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5960_17654"
        x1="0"
        y1="7.60742"
        x2="2.15265"
        y2="7.60742"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5960_17654"
        x1="0"
        y1="6.00725"
        x2="12"
        y2="6.00725"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
