export const OtherNotificationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <rect
        x="3.92462"
        y="3.98352"
        width="7.23077"
        height="7.23077"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="3.92462"
        y="14.7528"
        width="7.23077"
        height="7.23077"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="14.6939"
        y="3.98352"
        width="7.23077"
        height="7.23077"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="14.6939"
        y="14.7528"
        width="7.23077"
        height="7.23077"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
