import { axiosInstance } from "./apiInstance";

export const acceptAdhocPay = async (state, groupId) => {
  let path = window.location.href;
  var url = path.split("/");
  const taskId = url[url.length - 1];

  await axiosInstance.put(
    `/api/inkle/tasks/${taskId}/company_group/${groupId}/adhoc_approval_status/`,
    {
      new_state_id: state,
      customer_approval: true,
    }
  );
};
