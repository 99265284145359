import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { serviceTeamCreationSchema } from "formValidations/serviceTeamCreationSchema";
import { useToast } from "hooks/useToast";
import React from "react";
import { useHistory } from "react-router-dom";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { useCreateServiceTeamMutation } from "store/apis/serviceTeam";

export const ServiceTeamCreation = () => {
  const { alertToast } = useToast();
  const { data: countries, isLoading: isCountriesLoading } =
    useGetCountriesDataQuery();
  const [createServiceTeam, { isLoading }] = useCreateServiceTeamMutation();
  const { data: tagsData = [], isLoading: isTagLoading } =
    useGetTagsForCategoryQuery({
      tagType: "SERVICE_TEAM_TAG",
    });

  const checkBoxData = tagsData.map(({ uuid, title }) => ({
    label: title,
    value: uuid,
  }));

  const history = useHistory();

  const onSubmit = async (values: {
    name: string;
    country: string;
    requirements: string;
    tag_ids: string[];
  }) => {
    try {
      const payload = values;
      await createServiceTeam({ payload }).unwrap();
      history.push("/verify");
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  if (isCountriesLoading || isTagLoading) {
    return (
      <div className="t-h-full t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className="t-flex t-justify-center t-w-full t-mt-10">
      <Formik
        initialValues={{
          name: "",
          country: "",
          requirements: "",
          tag_ids: [],
        }}
        validationSchema={serviceTeamCreationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form className="all:unset t-flex t-flex-col t-w-[600px] t-gap-6 t-overflow-auto">
            <TextInput label="Organisation legal name" name="name" required />
            <Combobox
              required
              label="Country of registration"
              placeholder="Select Country"
              withForm
              options={countries?.map((country) => ({
                value: country.uuid,
                label: country.name,
              }))}
              name="country"
            />
            <Combobox
              required
              placeholder="Select the Services"
              label="Currently, what services do you provide? (this can be edited in future)"
              withForm
              isMulti
              options={checkBoxData}
              name="tag_ids"
            />
            <TextArea
              label="Let us know of any specific requirements from Inkle."
              name="requirements"
            />
            <div className="t-flex t-justify-end">
              <Button
                customType="primary"
                type="submit"
                isLoading={isLoading || isSubmitting}
                disabled={
                  isLoading ||
                  !isValid ||
                  values.tag_ids.length === 0 ||
                  isSubmitting
                }
              >
                Continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
