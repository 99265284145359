import React from "react";
import { AttachmentType } from "./Attachment";
import { SafeAnchor } from "stream-chat-react";
import ReactPlayer from "react-player";
import cx from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Image } from "./Image";

export const ScrappedContent = ({
  attachment,
  sent,
}: {
  attachment: AttachmentType;
  sent: boolean;
}) => {
  const {
    title_link,
    og_scrape_url,
    title,
    image_url,
    asset_url,
    type,
    fallback,
    thumb_url,
  } = attachment;
  const url = title_link || og_scrape_url;

  return (
    <div className="t-rounded t-bg-white">
      <ConditionalLink to={url} className="t-no-underline">
        {type === "image" && (
          <div className="t-p-2">
            <Image
              src={thumb_url}
              alt={fallback}
              height={266}
              className="t-w-full t-max-h-[266px] t-rounded t-object-cover"
            />
          </div>
        )}

        {type === "video" && (
          <div className="t-p-2">
            <ReactPlayer
              controls
              height="100%"
              url={asset_url}
              width="100%"
              className="t-rounded [&_video]:t-rounded"
            />
          </div>
        )}

        {type === "audio" && (
          <audio controls className="t-rounded">
            <source
              data-testid="audio-source"
              src={asset_url}
              type="audio/mp3"
            />
          </audio>
        )}

        <div
          className={cx("t-rounded t-p-2 t-text-text-60", {
            "t-bg-purple-20": sent,
            "t-bg-neutral-0": !sent,
          })}
        >
          <p className="t-m-0 t-text-subtitle-sm">{title}</p>
          <p className="t-m-0 t-text-body-sm">{og_scrape_url}</p>
        </div>
      </ConditionalLink>
    </div>
  );
};
