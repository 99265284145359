import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import TaskTabBar from "components/tasks/taskTabBar";
import {
  IN_PROGRESS,
  COMPLETED,
  SERVICE_TEAM_LABEL,
} from "../../constants/bookkeeping";
import { useEffect, useMemo, useState } from "react";
import { Search } from "components/DesignSystem/Search/Search";
import { BooksCrmFilter } from "./BookkeepingTrackerFilter";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  TransactionMonthlySummaryCards,
  useGroupMonthlyClosingQuery,
} from "store/apis/bookkeepingTracker";
import { debounce } from "utils/debouncing";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import DashboardContainer from "components/dashboard/DashboardContainer";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  CRM_SUBSCRIPTION_BADGE_MAP,
  CRM_SUBSCRIPTION_TYPE,
} from "dictionaries";
import { Badge } from "components/design/badge";
import ReactCountryFlag from "react-country-flag";
import { formatDate, formatTimeToMonthYear } from "utils/formatDate";
import { TableUI } from "components/design/TableUI";
import Table from "components/DesignSystem/Table/V2/Table";
import classNames from "classnames";
import ToolTip from "components/design/toolTip";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { usePagination } from "hooks/usePagination";
import { BookkeepingTrackerSlider } from "./BookkeepingTrackerSlider";
import { Header } from "components/DesignSystem/Header/Header";

const StatusTab = ({ setStatus }: { setStatus: (v: string) => void }) => {
  return (
    <div className="t-w-60">
      <ToggleGroup
        defaultValue="IN_PROGRESS"
        onValueChange={(value) => {
          setStatus(value);
        }}
      >
        <ToggleGroupItem value={IN_PROGRESS}>In progress</ToggleGroupItem>
        <ToggleGroupItem value={COMPLETED}>Completed</ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
};
const useSetupReactTable = (
  transaction_monthly_summary_cards: TransactionMonthlySummaryCards[],
  status: string
) => {
  const columnHelper = createColumnHelper<TransactionMonthlySummaryCards>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("group", {
        size: 30,
        id: "group_name",
        header: "GROUP NAME",
        cell: (info) => {
          const rowData = info.row.original;
          const group_name = rowData.group.name;
          const subscription_type = rowData.current_plan_name;

          return (
            <span className="t-flex t-gap-x-3">
              {group_name}{" "}
              <Badge
                size="small"
                color={CRM_SUBSCRIPTION_TYPE[subscription_type]}
              >
                {CRM_SUBSCRIPTION_BADGE_MAP[subscription_type]}
              </Badge>
            </span>
          );
        },
      }),
      columnHelper.accessor("entity", {
        size: 15,
        id: "entity_name",
        header: "ENTITY NAME",
        cell: (info) => {
          const rowData = info.getValue();

          return (
            <span className="t-flex t-gap-x-2 t-items-center">
              <ReactCountryFlag
                style={{
                  width: "14px",
                  height: "10px",
                  marginBottom: "3px",
                }}
                countryCode={rowData?.country_code || ""}
                svg
              />
              <span>{rowData?.name}</span>
            </span>
          );
        },
      }),
      columnHelper.accessor("ongoing_since", {
        size: 15,
        id: "books_date_status",
        header: status == IN_PROGRESS ? "ONGOING SINCE" : "COMPLETED TILL",
        cell: (info) => {
          const rowData = info.row.original;
          const dateToShow =
            status == IN_PROGRESS
              ? info.row.original.ongoing_since
              : info.row.original.completed_till;
          return (
            <span className="t-text-body">
              {dateToShow ? formatTimeToMonthYear(dateToShow) : "-"}
            </span>
          );
        },
      }),
      columnHelper.accessor("current_assigned_agent", {
        size: 15,
        id: "assignee",
        header: "ASSIGNEE",
        cell: (info) => {
          const rowData = info.getValue();

          return (
            <div className=" t-flex t-flex-col">
              {rowData?.team && rowData?.name ? (
                <>
                  <span className="t-text-subtext">
                    {SERVICE_TEAM_LABEL[rowData?.team] || "Team_Name"}
                  </span>
                  <span className="t-text-body-sm">
                    {rowData?.name || "Assignee_Name"}
                  </span>
                </>
              ) : (
                "-"
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("due_amount", {
        size: 15,
        id: "dues",
        header: (info) => {
          return (
            <div className="t-flex t-items-center">
              <span>Dues</span>
              <ToolTip text="Amount due for bookkeeping only">
                <div>
                  <InfoFilledSmall size="13" color="#706A85" />
                </div>
              </ToolTip>
            </div>
          );
        },
        cell: (info) => {
          const rowData = info.row.original;

          return (
            <span className="t-text-body">
              {info.getValue() > 0 ? `$${info.getValue()}` : "-"}
            </span>
          );
        },
      }),
    ];
  }, [status]);
  const data = useMemo(() => {
    return transaction_monthly_summary_cards;
  }, [transaction_monthly_summary_cards]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 105,
    },
  });

  return table;
};
const FilterTab = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: (v: string) => void;
}) => {
  const query = useQuery();
  const { update, updateMultiple } = useUpdateQuery();

  const {
    filters: { subscription_plans, teams, assignees },
  } = useSelector((state: RootState) => state.bookkeepingTracker);

  const handleSearch = debounce((e) => {
    const { value }: { value: string } = e.target;
    setSearchTerm(value);
    update({ query: "search_term", value });
  });
  return (
    <div className="t-flex t-gap-x-3 t-justify-items-center">
      <div className="t-w-[439px]">
        <Search placeholder="Search.." block onChange={handleSearch} />
      </div>
      <BooksCrmFilter />
    </div>
  );
};

export const BookkeepingTracker = () => {
  const [status, setStatus] = useState<string>(IN_PROGRESS);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const {
    filters: { subscription_plans, teams, assignees },
  } = useSelector((state: RootState) => state.bookkeepingTracker);

  const query = useQuery();
  const { update, updateMultiple } = useUpdateQuery();
  const previewEntityId = query.get("entityId");
  const {
    pageNum,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPrevPage,
    setTotalPage,
  } = usePagination();

  const { data, isLoading, isSuccess } = useGroupMonthlyClosingQuery({
    pageNum,
    searchTerm: searchTerm,
    subscriptionPlans: subscription_plans.value.join(","),
    teams: teams.value.join(","),
    assignees: assignees.value.join(","),
    showInProgress: status === IN_PROGRESS,
  });

  const {
    transaction_monthly_summary_cards = [],
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  useEffect(() => {
    if (total_pages) {
      setTotalPage(total_pages);
    }
  }, [total_pages]);

  const paginationData = {
    totalPage: total_pages,
    currentPage: pageNum,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const isEmpty = transaction_monthly_summary_cards.length === 0;

  const table = useSetupReactTable(transaction_monthly_summary_cards, status);

  return (
    <>
      <DashboardContainer className="t-h-[calc(100%-80px)]">
        <TaskTabBar defaultValue="admin/bookkeeping-tracker" />

        <div className="t-m-5">
          <StatusTab setStatus={setStatus} />
          <div className="t-flex t-justify-between t-mt-4 t-items-center">
            <FilterTab searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </div>
        <div className="t-m-5">
          <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
            <Async.Empty>
              <EmptyScreen text="No Bookkeeping found" className="!t-h-full" />
            </Async.Empty>
            <Async.Success>
              <TableUI table={table}>
                {({ row }) => (
                  <Table.Row
                    key={row.id}
                    onClick={() =>
                      update({
                        query: "entityId",
                        value: row.original.entity.uuid,
                      })
                    }
                    className={classNames(
                      "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                      {
                        "t-bg-surface-lighter-grey":
                          previewEntityId === row.original.entity.uuid,
                      }
                    )}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                )}
              </TableUI>
            </Async.Success>
          </Async.Root>
        </div>
        <BookkeepingTrackerSlider status={status} />
      </DashboardContainer>
    </>
  );
};
