import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/Modal/Modal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useAcceptTOSMutation } from "store/apis/transferPricing";
import { TP_TOS } from "types/contants/tpPayments";

export const TPOnboardingCard = ({
  setShowAddEntity,
  showAddEntityConfirm,
  setShowAddEntityConfirm,
  openTOSModal,
  setOpenTOSModal,
  onCompleteKyc,
}: {
  setShowAddEntity: (newState: React.SetStateAction<boolean>) => void;
  showAddEntityConfirm: boolean;
  setShowAddEntityConfirm: (newState: React.SetStateAction<boolean>) => void;
  openTOSModal: boolean;
  setOpenTOSModal: (newState: React.SetStateAction<boolean>) => void;
  onCompleteKyc: () => {};
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
  const [acceptTOSAPI, { isLoading: handlingAcceptTOS }] =
    useAcceptTOSMutation();

  const handleAcceptTOS = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      await acceptTOSAPI({ groupId }).unwrap();
      setOpenTOSModal(false);
      onCompleteKyc();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      setOpenTOSModal(false);
    }
  };

  return (
    <>
      <Modal.Root
        open={openTOSModal}
        onOpenChange={() => {
          setOpenTOSModal((prev) => !prev);
        }}
      >
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="t-w-[510px]">
            <Modal.Close />
            <Modal.Title>
              <span className="t-text-h6">TP Payments</span>
            </Modal.Title>

            <div className="t-py-8 t-align-middle t-text-body-lg t-leading-4">
              <Checkbox
                label={
                  <div>
                    I agree to the updated
                    <ConditionalLink to={TP_TOS}>
                      &nbsp;Terms of Service
                    </ConditionalLink>
                    &nbsp;for Inkle TP Payments.
                  </div>
                }
                name="termsandconditiononboarding"
                onChange={() => setIsCheckBoxSelected((prev) => !prev)}
                checked={isCheckBoxSelected}
              />
            </div>
            <div className="t-flex t-justify-end t-gap-3">
              <Button
                customType="primary"
                isLoading={handlingAcceptTOS}
                disabled={handlingAcceptTOS || !isCheckBoxSelected}
                onClick={handleAcceptTOS}
              >
                Confirm
              </Button>
            </div>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>

      <Modal.Root
        open={showAddEntityConfirm}
        onOpenChange={() => {
          setShowAddEntityConfirm((prev) => !prev);
        }}
      >
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content className="t-w-[440px] t-pb-4 t-pl-8 t-pt-5">
            <Modal.Title>
              <div className="t-pb-4 t-text-h6">Add Entity</div>
              <Divider />
            </Modal.Title>
            <Modal.Close />
            <Modal.Description>
              <div className="t-py-5 t-pr-8 t-align-middle t-text-body">
                Kindly add a non-US entity to start with Transfer Pricing
                Payments. Currently, we support US to India transfers.
              </div>
              <div className="t-flex t-justify-end t-gap-3 t-pt-4">
                <Button
                  customType="primary"
                  onClick={() => {
                    setShowAddEntityConfirm(false);
                    setShowAddEntity(true);
                  }}
                >
                  Add Entity
                </Button>
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
    </>
  );
};
