import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useDeleteMessageMutation } from "store/apis/chat";
import { setDeleteMessageData } from "store/slices/chat";
import { RootState } from "store/store";
import { useAnalytics } from "hooks/useAnalytics";
import { CHAT_MESSAGE_DELETED } from "constants/analyticsEvents";

const DeleteMessageModal = () => {
  const { alertToast, successToast } = useToast();
  const [deleteMessage, { isLoading }] = useDeleteMessageMutation();
  const dispatch = useDispatch();
  const { deleteMessageData } = useSelector((state: RootState) => state.chat);
  const { channelId, message, showDeleteMessageModal } = deleteMessageData;
  const { trackEvent } = useAnalytics();

  const closeModal = () => {
    dispatch(
      setDeleteMessageData({
        message: { id: "" },
        showDeleteMessageModal: false,
        channelId: "",
      })
    );
  };

  const deleteChannelMessage = async () => {
    try {
      await deleteMessage({
        channel_id: channelId,
        message_id: message?.id,
      }).unwrap();
      // @ts-ignore
      trackEvent(CHAT_MESSAGE_DELETED, message);
      successToast({ message: "Message deleted successfully" });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={showDeleteMessageModal} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-p-0 md:!t-w-[440px]">
          <Modal.Close />
          <Modal.Title className="!t-p-6">Delete this message</Modal.Title>
          <div className="t-border t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-p-6">
            <div className="t-text-body-lg t-text-text-black">
              Are you sure you want to delete this message?
            </div>
            <div className="t-mt-8 t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={isLoading}
                customType="danger"
                isLoading={isLoading}
                onClick={deleteChannelMessage}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default DeleteMessageModal;
