import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ModalTyes = "ADD_INVOICE" | "REVIEW_INVOICE" | "PAYMENT_INFORMATION";

const initialState = {
  value: "ADD_INVOICE",
};

export const invoiceModalTypes = createSlice({
  name: "InvoiceModalTypes",
  initialState,
  reducers: {
    setInvoiceModalType: (state, action: PayloadAction<ModalTyes>) => {
      state.value = action.payload;
    },
  },
});

export const { setInvoiceModalType } = invoiceModalTypes.actions;

export default invoiceModalTypes.reducer;
