import { TaskType } from "components/Task/Badge";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import {
  CompanyGroupResponse,
  CouponResponse,
  SliderCRM,
} from "types/Models/salescrm";

type GetAllSalesGroupsProps = {
  pageNumber?: number | number;
  searchTerm?: string | null;
  viewFilter: string | null;
  archived?: boolean;
  subscriptionFilter?: string | null;
  agentFilter?: string | null;
  salespersonFilter?: string | null;
  usStateFilter?: string | null;
  incorporationMonthsFilter?: string | null;
  selectedTag?: string | null;
  sortDueBy?: string | null;
  taskState?: string | null;
  notLaunchedTaskFilter?: string | null;
  subscriberTypeFilter?: string | null;
  base_task_template_id?: string;
  task_season?: string;
  generate_report?: boolean;
  service_team_id?: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllSalesCRMGroups: build.query<
      CompanyGroupResponse,
      GetAllSalesGroupsProps
    >({
      query: ({
        pageNumber,
        searchTerm,
        viewFilter,
        subscriptionFilter,
        agentFilter,
        salespersonFilter,
        usStateFilter,
        incorporationMonthsFilter,
        selectedTag,
        sortDueBy,
        taskState,
        notLaunchedTaskFilter,
        subscriberTypeFilter,
        base_task_template_id,
        task_season,
        generate_report,
        archived,
        service_team_id,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNumber,
            search_term: searchTerm,
            crm_type: viewFilter,
            subscription_type: subscriptionFilter,
            agent_filter: agentFilter,
            sales_person_filter: salespersonFilter,
            us_state_uuids: usStateFilter,
            us_incorp_months: incorporationMonthsFilter,
            tag_filter: selectedTag,
            task_state_filter_uuids: taskState,
            not_launched_task_filter: notLaunchedTaskFilter,
            active_subscribers: subscriberTypeFilter,
            base_task_template_id,
            task_season,
            sort_order: sortDueBy,
            sort_by: sortDueBy ? "due_amount" : null,
            generate_report,
            archived,
            service_team_id,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `api/inkle/company/v2/get_all_groups/${queryUrl}`,
        };
      },
      providesTags: (result) => {
        const { company_groups = [] } = result || {};
        return result
          ? [
              ...company_groups.map(({ uuid }) => ({
                type: "SalesCrmCompanyGroups" as "SalesCrmCompanyGroups",
                id: uuid,
              })),
              { type: "SalesCrmCompanyGroups", id: "LIST" },
            ]
          : [
              {
                type: "SalesCrmCompanyGroups" as "SalesCrmCompanyGroups",
                id: "LIST",
              },
            ];
      },
    }),

    createCompanyAndEntity: build.mutation({
      query: (groupEntityPayload) => {
        return {
          url: "api/inkle/company/group/entities/crm/",
          method: "post",
          body: groupEntityPayload,
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [
              { type: "SalesCrmCompanyGroups", id: "LIST" },
              { type: "SalesCrmProfiles", id: "LIST" },
            ]
          : [];
      },
    }),

    checkValidEmailMobile: build.mutation<
      {
        mobiles_already_exits: string[];
        emails_already_exits: string[];
      },
      { emails: string[] }
    >({
      query: (payload) => {
        return {
          url: `/api/inkle/users/check-valid-email-mobile/`,
          method: "post",
          body: payload,
        };
      },
    }),

    updateGroupData: build.mutation({
      query: ({ groupId, payLoad }) => {
        return {
          url: `api/inkle/company/v2/group/${groupId}/update_crm_group/`,
          method: "PATCH",
          body: payLoad,
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [
              { type: "SalesCrmCompanyGroups", id: "LIST" },
              {
                type: "SliderCRM",
                id: "LIST",
              },
              { type: "BOOKS_CRM" },
            ]
          : [];
      },
    }),

    updateUserData: build.mutation<
      any,
      { profileId: string; payload: { [key: string]: string | boolean } }
    >({
      query: ({ profileId, payload }) => {
        return {
          url: `api/inkle/users/profile/${profileId}/update/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: [{ type: "SalesCrmProfiles" }],
    }),

    getAllEmails: build.query({
      query: ({ searchTerm, pageNumber }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNumber,
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `api/inkle/company/v2/get_all_emails/${queryUrl}`,
          method: "get",
        };
      },
    }),

    getAllCoupons: build.query<CouponResponse[], void>({
      query: () => {
        return {
          url: "/api/inkle/users/get_all_coupons/",
        };
      },
    }),

    getSliderData: build.query<
      SliderCRM,
      { groupId: string; base_task_template_id?: string }
    >({
      query: ({ groupId, base_task_template_id }) => {
        let queryUrl = qs.stringify(
          {
            base_task_template_id,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/company/v2/group/${groupId}/slider/${queryUrl}`,
        };
      },
      providesTags: [
        {
          type: "SliderCRM" as "SliderCRM",
          id: "LIST",
        },
      ],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateCompanyAndEntityMutation,
  useGetAllSalesCRMGroupsQuery,
  useCheckValidEmailMobileMutation,
  useUpdateGroupDataMutation,
  useUpdateUserDataMutation,
  useGetAllEmailsQuery,
  useGetAllCouponsQuery,
  useGetSliderDataQuery,
} = extendedApi;
