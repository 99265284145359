import { Button } from "components/DesignSystem/Button/Button";
import { PlusIcon } from "components/icons/PlusIcon";
import React from "react";
import NoTemplate from "static/images/NoTemplate.svg";

const NoTemplateCard = ({ openAddStep }: { openAddStep: () => void }) => {
  return (
    <div className="t-mt-4 t-w-full  t-text-center">
      <div>
        <img src={NoTemplate} alt="NoTemplate" />
      </div>
      <div className="t-text-body t-text-text-30">
        Create your template response
      </div>
      <Button onClick={openAddStep} size="small">
        <PlusIcon color="#706A85" />
        <span className="t-text-text-6 t-ml-1 t-font-medium">Create</span>
      </Button>
    </div>
  );
};

export default NoTemplateCard;
