import qs from "qs";
import { ChatTemplate } from "types/Models/ChatTemplate";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getChatTemplates: build.query<
      ChatTemplate[],
      { searchTerm: string | null }
    >({
      query: ({ searchTerm }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/messaging/channel/templates/all/${queryUrl}`,
        };
      },
      providesTags: ["ChatTemplate"],
    }),
    createChatTemplate: build.mutation<
      ChatTemplate,
      { response_body: string; response_header: string }
    >({
      query: ({ response_body, response_header }) => {
        return {
          url: "/api/inkle/messaging/channel/templates/all/",
          method: "post",
          body: {
            response_body,
            response_header,
          },
        };
      },
      invalidatesTags: ["ChatTemplate"],
    }),
    updateChatTemplate: build.mutation<
      ChatTemplate,
      { templateId: string; response_body: string; response_header: string }
    >({
      query: ({ templateId, response_body, response_header }) => {
        return {
          url: `/api/inkle/messaging/channel/template/${templateId}/`,
          method: "put",
          body: {
            response_body,
            response_header,
          },
        };
      },
      invalidatesTags: ["ChatTemplate"],
    }),
    deleteChatTemplate: build.mutation<ChatTemplate, { templateId: string }>({
      query: ({ templateId }) => {
        return {
          url: `/api/inkle/messaging/channel/template/${templateId}/`,
          method: "delete",
        };
      },
      invalidatesTags: ["ChatTemplate"],
    }),
  }),
});

export const {
  useGetChatTemplatesQuery,
  useCreateChatTemplateMutation,
  useUpdateChatTemplateMutation,
  useDeleteChatTemplateMutation,
} = extendedApi;
