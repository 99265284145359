//constant
import { EMPTY_FOLDER_MESSAGE } from "../../constants/documents";

//icon
import EmptyFolder from "../../static/images/EmptyFolder.svg";

const NoFilenFolder = () => {
  return (
    <div className="emptyFolder">
      <img src={EmptyFolder} alt="empty folder" />
      <span className="noFilenFolderText">{EMPTY_FOLDER_MESSAGE}</span>
      <span className="DropandAdd">Drop files here</span>
      <span className="DropandAdd">or use “Add” button</span>
    </div>
  );
};

export default NoFilenFolder;
