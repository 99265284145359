import cx from "classnames";
import React, { HTMLAttributes, forwardRef } from "react";

type DashboardContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const Header = ({ children, className }: DashboardContainerProps) => (
  <div className={cx("t-flex-[0_0_auto]", className || "")}>{children}</div>
);

const Content = ({
  children,
  className = "",
  ...props
}: DashboardContainerProps & HTMLAttributes<HTMLDivElement>) => (
  <div className={cx("t-flex-auto t-overflow-y-auto", className)} {...props}>
    {children}
  </div>
);

const DashboardContainer = Object.assign(
  forwardRef<HTMLDivElement, DashboardContainerProps>(
    ({ children, className }, ref) => {
      return (
        <div
          ref={ref}
          className={cx("t-flex t-flex-col t-w-full", className || "")}
        >
          {children}
        </div>
      );
    }
  ),
  {
    Header,
    Content,
  }
);

export default DashboardContainer;
