import { TxnCategories } from "types/Models/books";
import Suggested from "static/images/Suggested.svg";

export const CategoryLabel = ({
  category,
  indentLevel,
}: {
  category: TxnCategories | undefined;
  indentLevel?: number;
}) => {
  if (!category) {
    return <></>;
  }

  const identifier =
    category?.category_type === "BANK_TRANSFER" ||
    category?.category_type === "PAY_DOWN_CREDIT" ||
    category?.category_type === "SUGGESTED_PARENT"
      ? ""
      : category?.identifier + " - ";

  const label =
    indentLevel === 0 ? category?.name : `${identifier}${category?.name}`;

  if (category?.category_type === "SUGGESTED") {
    return (
      <div className="t-flex t-justify-between t-w-full">
        <div className="t-truncate t-w-2/3">{label}</div>
        <div className="t-italic t-text-caption t-text-text-30 t-flex t-gap-1 t-items-center">
          Suggested
          <img src={Suggested} alt="suggested" />
        </div>
      </div>
    );
  }

  return <>{label}</>;
};
