export const Documents = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5562 13H2.4625C2.40176 13 2.34162 12.988 2.28551 12.9648C2.2294 12.9416 2.17841 12.9075 2.13546 12.8645C2.09252 12.8216 2.05845 12.7706 2.03521 12.7145C2.01196 12.6584 2 12.5982 2 12.5375V5H13.5C13.6326 5 13.7598 5.05268 13.8536 5.14645C13.9473 5.24021 14 5.36739 14 5.5V12.5562C14 12.6739 13.9532 12.7868 13.87 12.87C13.7868 12.9532 13.6739 13 13.5562 13Z"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 5V3.5C2 3.36739 2.05268 3.24022 2.14645 3.14645C2.24021 3.05268 2.36739 3 2.5 3H5.79375C5.85868 2.99978 5.92301 3.01236 5.98307 3.03702C6.04312 3.06169 6.09773 3.09796 6.14375 3.14375L8 5"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
