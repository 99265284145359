import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { OptionDropdown } from "components/icons/optionDropdown";
import React, { useContext, useState } from "react";
import { MakeAdminModal } from "./Modals/MakeAdminModal";
import { TeamMember } from "store/apis/teamSettingCPA";
import authContext from "jwt_context&axios/authContext";

export const MakeAdmin = ({ data }: { data: TeamMember }) => {
  const [showMakeAdmin, setShowMakeAdmin] = useState(false);
  const {
    authtoken: { email },
  } = useContext(authContext);

  return (
    <>
      <DropDown.Root modal={false}>
        <DropDown.Trigger className="all:unset">
          <Button customType="ghost_icon" size="small">
            <OptionDropdown color="currentColor" />
          </Button>
        </DropDown.Trigger>
        <DropDown.Portal>
          <DropDown.Content
            sideOffset={8}
            className="t-rounded-lg t-bg-surface t-p-2 t-drop-shadow-dropdown sm:t-w-[94vw]"
            align="end"
          >
            <DropDown.Item
              onSelect={() => setShowMakeAdmin(true)}
              disabled={data?.status === "INVITED" || data?.email === email}
            >
              Make admin
            </DropDown.Item>
            {/* <DropDown.Item>Remove member</DropDown.Item> */}
          </DropDown.Content>
        </DropDown.Portal>
      </DropDown.Root>
      <MakeAdminModal
        data={data}
        showMakeAdmin={showMakeAdmin}
        setShowMakeAdmin={setShowMakeAdmin}
      />
    </>
  );
};
