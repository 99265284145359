import { Form, Formik } from "formik";
import { emailSchema } from "../formValidations/emailSchema";
import { MERCHANT_FORM_STATUS_TYPES } from "types/Models/merchant";
import { TextInput } from "./DesignSystem/TextInput/TextInput";
import { Button } from "./DesignSystem/Button/Button";

type WSeriesEmailFormProps = {
  onSubmit: (values: { email: string }) => void;
  email: string;
  formStatus: MERCHANT_FORM_STATUS_TYPES;
  label: string;
  disabled?: boolean;
};

export const WSeriesEmailForm = ({
  onSubmit,
  email,
  formStatus,
  label,
  disabled = false,
}: WSeriesEmailFormProps) => {
  return (
    <Formik
      validateOnChange
      validationSchema={emailSchema}
      onSubmit={onSubmit}
      initialValues={{
        email: email || "",
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form className="w-100 m-0">
          <div className="d-flex w-100 align-items-center gap-4">
            <TextInput
              label={
                <span className="w-series-form-modal-input-label t-mb-3">
                  {label}
                </span>
              }
              block
              placeholder="Enter email"
              name="email"
              required
              customSize="regular"
            />
          </div>
          <div className="t-w-full t-flex t-justify-end t-mt-6">
            <div className="flex-shrink-0 t-self-end">
              <Button
                customType="primary"
                isLoading={isSubmitting}
                disabled={!isValid || disabled || isSubmitting}
              >
                {formStatus === "SENT" ? "Resend email" : "Send email"}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
