import { DocumentPreviewModal } from "components/PreviewModal";

import { SelecteFile } from "types/Models/documents";

import { useDocPreview } from "hooks/useDocPreview";
import Uploaded from "static/images/CircleNotch.svg";
import Draggable from "static/images/Draggable.svg";
import JPG from "static/images/JPG.svg";

type SuggestedDocumentProps = {
  document: SelecteFile;
  groupId: string;
  isUploaded?: boolean;
};

const SuggestedDocument = ({
  document,
  isUploaded = false,
}: SuggestedDocumentProps) => {
  const openPreview = useDocPreview();

  return (
    <>
      <div
        onClick={() => openPreview(document.uuid)}
        className="t-flex t-h-8 t-w-[180px] t-items-center t-justify-between t-gap-2 t-rounded t-border t-border-solid t-border-neutral-20 t-bg-neutral-0 t-p-1"
      >
        <img src={JPG} alt="file" />
        <span className="t-max-w-[calc(100%_-_40px)] t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-text-body-sm">
          {document?.name}
        </span>
        {isUploaded ? (
          <img src={Uploaded} alt="uploaded" />
        ) : (
          <img src={Draggable} alt="file" />
        )}
      </div>
      <DocumentPreviewModal />
    </>
  );
};

export default SuggestedDocument;
