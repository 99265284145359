import { FOREIGN_CA, INVESTOR, PREINCORPORATION } from "constants/onBoarding";

const { default: authContext } = require("jwt_context&axios/authContext");
const { useContext } = require("react");

export const useRoleBasedView = () => {
  const { authtoken } = useContext(authContext);

  if (!authtoken) {
    return { isPublicUser: true };
  }

  const {
    is_service_user,
    is_any_service_user,
    is_inkle_admin,
    is_foreign_ca,
    is_service_admin,
    is_super_admin,
    user_type,
    is_service_super_admin,
    is_inkle_super_admin,
    is_any_service_super_admin,
    type,
  } = authtoken;

  return {
    isCpa: is_service_user,
    isAdmin: is_inkle_admin,
    isCpaInkleServiceAdmin: is_service_admin,
    isCustomer:
      !is_service_user &&
      !is_any_service_user &&
      !is_inkle_admin &&
      type !== INVESTOR,
    isForeignCA: is_foreign_ca || user_type === FOREIGN_CA,
    isPublicUser: false,
    isGroupAdmin: is_super_admin,
    isPreIncorporated: user_type === PREINCORPORATION,
    isServiceSuperAdmin: is_service_super_admin,
    isInkleSuperAdmin: is_inkle_super_admin,
    isAnyServiceSuperAdmin: is_any_service_super_admin,
    isUserTypeForeignCA: user_type === FOREIGN_CA || is_foreign_ca,
    isInvestor: type === INVESTOR,
    isAnyServiceUser: is_any_service_user,
    isPracticePro:
      is_service_user &&
      !is_inkle_admin &&
      !(is_foreign_ca || user_type === FOREIGN_CA),
  };
};
