import { ReactNode } from "react";
import { motion } from "framer-motion";

export const MailroomSmallCard = ({
  children,
  header,
  status,
}: {
  children: ReactNode;
  header: string;
  status?: string;
}) => (
  <motion.div
    layout
    className="t-min-h-[156px] t-border t-border-solid t-border-neutral-0 t-rounded-lg hover:t-shadow-md t-transition-shadow t-w-[518px]"
  >
    <div className="t-p-4 t-border t-border-solid t-border-surface-grey t-border-t-0 t-border-l-0 t-border-r-0 t-flex t-gap-4 t-items-center">
      <div className="t-text-subtitle t-text-text-100">{header}</div>
      {status && (
        <div className="t-py-0.5 t-px-2 t-rounded t-bg-dark_green-10 t-flex t-items-center t-justify-center t-text-body-sm t-text-dark_green-70">
          Active
        </div>
      )}
    </div>
    <div className="t-p-4">{children}</div>
  </motion.div>
);
