import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";

//api
import { stateUpdate } from "../../apis/stateUpdate";

//style
import "../../static/styles/components/step3.css";

//context
import authContext from "../../jwt_context&axios/authContext";
import SuccessBox from "./step5";
import TaskInProgressSocialShare from "../TaskInprogressSocialShare";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import TaskButtonBar from "./TaskButtonBar";
import { useParams } from "react-router-dom";
import Modal from "components/DesignSystem/Modal/Modal";
import { ReviewForm as PreviewForm } from "./fillFormStepper/ReviewModal";
import { useGetTaskQuery } from "store/apis/task";
import { useSelector } from "react-redux";
import FilingInprogressIcon from "static/images/FilingInprogressIcon.svg";
import { InprogressAgentIcon } from "components/icons/InprogressAgentIcon";
import { InprogressPreparerIcon } from "components/icons/InprogressPreparerIcon";
import { InprogressETDIcon } from "components/icons/InprogressETDIcon";
import { InprogressDeadlineIcon } from "components/icons/InprogressDeadlineIcon";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { TASK_STATE_TO_COLOR } from "dictionaries";
import { ARCHIVED } from "constants/task";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { openLink } from "utils/openLink";
import { formatDate } from "utils/formatDate";

const ReviewForm = ({
  next,
  prev,
  txt,
  mainId,
  isArchived,
  task,
  isSuccessful,
  parentRef,
  isInprogress,
  isWSeries,
  isSectionGroupAvailable,
  isFromServices,
}) => {
  const { isCustomer } = useRoleBasedView();
  const [showSocialShare, setShowSocialShare] = useState();
  const { authtoken } = useContext(authContext);
  const shareable = task?.share_data?.is_sharable;
  const { taskId } = useParams();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { data } = useGetTaskQuery({ taskId }, { skip: !taskId });
  const { taskUpdatedSection } = useSelector((state) => state.taskReview);
  const sections =
    taskUpdatedSection ||
    // @ts-ignore
    data?.form_data?.data?.data?.section_group?.[0].sections;

  useEffect(() => {
    if (shareable) {
      const storedSocialShareTasks =
        JSON.parse(localStorage.getItem("socialShareTaskIds")) || [];
      const taskIncluded = storedSocialShareTasks.includes(task.uuid);
      const showedSocialShare = taskIncluded ? false : true;
      setShowSocialShare(showedSocialShare);
      setTimeout(() => {
        if (!taskIncluded) {
          localStorage.setItem(
            "socialShareTaskIds",
            JSON.stringify([...storedSocialShareTasks, task.uuid])
          );
        }
      }, 500);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const id = next;

    stateUpdate({ taskId, id }).then(() => {
      window.location.reload();
    });
  };

  const stateUpdateToPreviousState = (e) => {
    e.preventDefault();
    const id = prev;

    stateUpdate({ taskId, id }).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <div
        className={classNames("taxing t-h-full", {
          disabledForm: isArchived,
        })}
      >
        {!isArchived && mainId && !next && isSuccessful && (
          <SuccessBox task={task} parentRef={parentRef} isWSeries={isWSeries} />
        )}

        {isInprogress && (
          <div className="t-w-[500px] t-mx-auto t-text-center t-flex t-flex-col t-justify-center t-items-center t-mt-12">
            <img
              src={FilingInprogressIcon}
              alt="FilingInprogressIllustration"
            />
            <div className="t-flex t-flex-col t-gap-6 t-mt-12">
              <div className="t-flex t-flex-col t-gap-3">
                <div className="t-text-subtitle t-text-text-100">
                  Filing in progress
                </div>
                <div className="t-text-body t-text-text-30">
                  Our team is processing your filing. You'll be notified once
                  it's completed. Feel free to reach out with any questions.
                </div>
              </div>
              <hr className="t-w-3/4 t-text-neutral-10 t-mx-auto t-my-0" />
              <div className="t-flex t-flex-col t-gap-2">
                <div className="t-flex t-justify-between t-items-center">
                  <div className="t-flex t-items-center t-gap-1">
                    <InprogressAgentIcon />
                    <div className="t-text-body t-text-text-30">Agent:</div>
                  </div>
                  <div className="t-text-text-100 t-text-body">
                    {data?.assigned_agent?.name || "-"}
                  </div>
                </div>
                <div className="t-flex t-justify-between t-items-center">
                  <div className="t-flex t-items-center t-gap-1">
                    <InprogressPreparerIcon />
                    <div className="t-text-body t-text-text-30">Preparer:</div>
                  </div>
                  <div className="t-text-text-100 t-text-body">
                    {data?.assigned_to?.name || "-"}
                  </div>
                </div>
                <div className="t-flex t-justify-between t-items-center">
                  <div className="t-flex t-items-center t-gap-1">
                    <InprogressETDIcon />
                    <div className="t-text-body t-text-text-30">
                      Approx. time of delivery
                    </div>
                  </div>
                  <div className="t-text-text-100 t-text-body">
                    {data?.estimated_time_of_delivery?.date
                      ? formatDate(data.estimated_time_of_delivery.date)
                      : "-"}
                  </div>
                </div>
                <div className="t-flex t-justify-between t-items-center">
                  <div className="t-flex t-items-center t-gap-1">
                    <InprogressDeadlineIcon />
                    <div className="t-text-body t-text-text-30">Deadline</div>
                  </div>
                  <div className="t-text-text-100 t-text-body">
                    {data?.deadline ? formatDate(data.deadline) : "-"}
                  </div>
                </div>
              </div>
              {data.bundled_tasks?.length > 0 && (
                <div className="t-flex t-flex-col t-gap-6">
                  <hr className="t-w-3/4 t-text-neutral-10 t-mx-auto t-my-0" />
                  <div className="t-flex t-flex-col t-gap-3">
                    <div className="t-text-body-sm t-text-text-30 t-text-left">
                      Related filings
                    </div>
                    {data?.bundled_tasks?.map((bundledTask) => {
                      const taskStateType = bundledTask?.type || "Not Started";

                      return (
                        <div
                          key={bundledTask.uuid}
                          className="t-flex t-flex-col t-gap-2 t-text-left"
                        >
                          <div className="t-flex t-justify-between t-items-center t-gap-4 t-p-2 t-rounded t-border t-border-solid t-border-neutral-10">
                            <Button
                              size="small"
                              customType="link"
                              onClick={() =>
                                openLink(`/tax/filings/${bundledTask.uuid}`)
                              }
                            >
                              {bundledTask.name}
                            </Button>
                            <Tag
                              tagType={TASK_STATE_TO_COLOR[taskStateType]}
                              icon={false}
                              size="small"
                            >
                              {bundledTask.state}
                            </Tag>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {isSectionGroupAvailable && (
                <div className="t-w-full t-flex t-justify-center">
                  <Button
                    block
                    onClick={() => setShowPreviewModal(true)}
                    customType="primary-outlined"
                  >
                    Preview form
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {!isArchived && mainId && next && (
          <TaskButtonBar justifyContent="between" parentRef={parentRef}>
            <Button type="button" onClick={stateUpdateToPreviousState}>
              Previous
            </Button>
            <Button customType="primary" onClick={onSubmit} type="submit">
              {authtoken.is_service_user ? "Next" : "Submit"}
            </Button>
          </TaskButtonBar>
        )}
        <div className="spaceFrm" />
      </div>
      {isCustomer && shareable && (
        <TaskInProgressSocialShare
          show={showSocialShare}
          closeModal={() => setShowSocialShare(false)}
          task={task}
        />
      )}
      <Modal.Root
        open={showPreviewModal}
        onOpenChange={() => setShowPreviewModal(false)}
      >
        <Modal.Content size="xl">
          <Modal.Header>
            <Modal.Title>Preview form</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <PreviewForm sections={sections} />
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end">
            <Button
              customType="primary"
              onClick={() => setShowPreviewModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default ReviewForm;
