import { axiosInstance } from "./apiInstance";

export const addCompanyEntity = async (groupUuid, groupData) => {
  const response = await axiosInstance.post(
    `/api/inkle/company/group/${groupUuid}/entity/`,
    groupData
  );

  return response.data;
};
