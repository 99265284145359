import classNames from "classnames";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { BankLogo } from "components/icons/BankLogo";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFile from "static/images/UploadFile.svg";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { ModalProps } from "types/utils/modal";
import { TransactionsBankSelector } from "./TransactionsBankSelector";

type AddTransactionOptionsProps = ModalProps & {
  openAddTransactionModal: () => void;
  selectedAccountId?: string;
};

export const AddTransactionOptions = ({
  isOpen,
  close,
  openAddTransactionModal,
  selectedAccountId,
}: AddTransactionOptionsProps) => {
  const { alertToast } = useToast();
  const [csv, setCsv] = useState<File | undefined>(undefined);
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const {
    close: closeBankSelectorModal,
    isOpen: isBankSelectorModalOpen,
    open: openBankSelectorModal,
  } = useModal();
  const addtransactionManually = useModal();

  const { data: banks } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const selectedAccount = banks?.accounts.find(
    ({ account }) => account.uuid === selectedAccountId
  );

  const source = `${selectedAccount?.account.nickname} •••• ${selectedAccount?.account.mask}`;

  const onDropRejected = () => {
    alertToast({ message: "Please upload a CSV file exclusively." });
  };

  const onDrop = async (files: File[]) => {
    setCsv(files[0]);
    openTxnBankSelectorModal();
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop,
      maxSize: 25000000,
      accept: { "text/csv": [".csv"] },
      onDropRejected,
      multiple: false,
    });

  const openTxnBankSelectorModal = () => {
    if (selectedAccountId) {
      addtransactionManually.open();
    }
    openBankSelectorModal();
    close();
  };

  const onBankSelectorModalClose = () => {
    setCsv(undefined);
    closeBankSelectorModal();
  };

  const onBack = () => {
    onBankSelectorModalClose();
    openAddTransactionModal();
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="large">
          <Modal.Header>
            <div className="t-w-full">
              <Modal.Title>Add transactions</Modal.Title>
              {selectedAccountId && (
                <Modal.Subtitle>
                  <div className="t-flex t-gap-2 t-items-center t-text-subtext t-text-text-60">
                    <BankLogo size={20} />
                    SOURCE: {source}
                  </div>
                </Modal.Subtitle>
              )}
            </div>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-pb-10 t-flex t-gap-6 t-flex-col">
            <div className="t-flex t-flex-col t-cursor-pointer">
              <Label htmlFor="csv">
                Upload the bank statement exported in .csv format
              </Label>
              <div
                className={classNames(
                  "t-border-2 t-rounded-lg t-border-dashed t-border-neutral-20 t-px-6 t-py-8 t-text-body t-text-text-100 t-flex t-items-center t-justify-center t-flex-col t-gap-3 t-bg-surface-lighter-grey",
                  {
                    "!t-border-green !t-text-green-70": isDragAccept,
                    "!t-border-red-50 !t-text-red-50": isDragReject,
                  }
                )}
                {...getRootProps()}
                onClick={open}
              >
                <input {...getInputProps()} id="csv" />
                <img src={UploadFile} alt="Upload File" />
                Click or drag and drop to upload.
              </div>
            </div>
            <Divider>Or</Divider>
            <Button onClick={openTxnBankSelectorModal} size="large">
              Add transactions manually
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <span className="t-invisible">
              <Button>Footer</Button>
            </span>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <TransactionsBankSelector
        isOpen={isBankSelectorModalOpen}
        close={onBankSelectorModalClose}
        onBack={onBack}
        csv={csv}
        step={addtransactionManually.isOpen ? 2 : 1}
      />
    </>
  );
};
