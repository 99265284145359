import { Divider } from "@mui/material";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Cross } from "components/icons/Cross";
import { InfoSolid } from "components/InfoSolid";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { editCategorisationRuleSchema } from "formValidations/categorisationRuleSchema";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import randomBytes from "randombytes";
import React, { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useEditRuleMutation,
  useGetAllConditionsQuery,
  useGetRuleByIdQuery,
  useMarkRuleActiveMutation,
} from "store/apis/ruleEngine";
import { closeEditModal } from "store/slices/ruleEngine";
import { RootState } from "store/store";
import { ConditionResult } from "types/Models/ruleEngine";
import { BackendError } from "types/utils/error";
import {
  Categories,
  ConditionFunction,
  ConditionValue,
  RuleConsoleItems,
  RuleStepper,
  RuleValuesType,
  TransactionsPrview,
} from "./CreateRule";

type EditRuleValuesType = {
  name: string;
  startDate: string;
  endDate: string;
  assigned_category_id: string;
  ruleConditionData: {
    uuid: string;
    id: string;
    field: string;
    conditionFunction: string;
    conditionValue: string | number;
  }[];
};

const initialRuleConditionData = {
  uuid: "",
  field: "",
  conditionFunction: "",
  conditionValue: "",
};

const TitleAndDate = () => {
  const { values } = useFormikContext<EditRuleValuesType>();
  const { startDate, endDate } = values;

  return (
    <RuleConsoleItems title="Title">
      <div className="t-flex t-gap-4 t-flex-col">
        <TextInput
          name="name"
          label="Rule name"
          placeholder="Rule#1"
          required
          block
        />
        <div className="t-flex t-gap-4">
          <Field name="startDate">
            {({ field }: FieldProps) => {
              return (
                <DateInput
                  {...field}
                  maxDate={new Date(endDate)}
                  label="From"
                  placeholder="YYYY-MM-DD"
                  portalId="transaction-start"
                  block
                  required
                />
              );
            }}
          </Field>
          <Field name="endDate">
            {({ field }: FieldProps) => {
              return (
                <DateInput
                  {...field}
                  maxDate={new Date()}
                  minDate={new Date(startDate)}
                  label="To"
                  placeholder="YYYY-MM-DD"
                  portalId="transaction-date"
                  block
                  required
                />
              );
            }}
          </Field>
        </div>
      </div>
    </RuleConsoleItems>
  );
};

const ConditionWrapper = ({
  itemId,
  children,
}: {
  children: ({
    field,
    defaultConditionFunction,
    defaultField,
  }: {
    field: string | undefined;
    defaultField: ConditionResult | undefined;
    defaultConditionFunction:
      | {
          uuid: string;
          name: string;
          operator_type: string;
        }
      | undefined;
  }) => ReactNode;
  itemId: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { values } = useFormikContext<EditRuleValuesType>();
  const { ruleConditionData = [] } = values || {};

  const { field, conditionFunction } =
    ruleConditionData.find(({ id }) => id === itemId) || {};

  const { data: conditions = [] } = useGetAllConditionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const defaultField = conditions.find(({ uuid }) => uuid === field);

  const defaultConditionFunction = defaultField?.available_operators?.find(
    ({ uuid }) => uuid === conditionFunction
  );

  return <>{children({ field, defaultConditionFunction, defaultField })}</>;
};

const Conditions = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { values, setFieldValue } = useFormikContext<RuleValuesType>();
  const { ruleConditionData = [] } = values || {};

  const {
    data: conditions = [],
    isLoading,
    isSuccess,
  } = useGetAllConditionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const onFieldChange = (index: number) => {
    setFieldValue(`ruleConditionData[${index}].conditionFunction`, "");
    setFieldValue(`ruleConditionData[${index}].conditionValue`, "");
  };

  const addNewCondition = () => {
    if (ruleConditionData.length <= 3) {
      const newValues = [
        ...values.ruleConditionData,
        {
          id: randomBytes(10).toString("hex"),
          ...initialRuleConditionData,
        },
      ];
      setFieldValue("ruleConditionData", newValues);
    }
  };

  const onRemove = (conditionId: string) => {
    if (ruleConditionData.length > 1) {
      const newValues = ruleConditionData.filter(({ id }) => {
        return id !== conditionId;
      });

      setFieldValue("ruleConditionData", newValues);
    }
  };

  const fieldOptions = conditions.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  if (isSuccess) {
    return (
      <RuleConsoleItems title="Define Conditions">
        <AnimatePresence>
          {ruleConditionData.map(({ id, conditionValue }, i) => (
            <motion.div
              className="t-flex t-gap-4"
              key={id}
              layout
              transition={{ duration: 0.1, ease: "easeOut" }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -500, opacity: 0 }}
            >
              <ConditionWrapper itemId={id}>
                {({ field = "", defaultConditionFunction, defaultField }) => (
                  <div className="t-grid t-grid-cols-3 t-gap-4 t-w-full">
                    <Combobox
                      label={i === 0 ? "Field" : ""}
                      isDisabled={isLoading}
                      placeholder="Select"
                      menuPortalTarget={document.body}
                      name={`ruleConditionData[${i}].field`}
                      withForm
                      options={fieldOptions}
                      isClearable={false}
                      block
                      required
                      onChange={() => onFieldChange(i)}
                      value={
                        Boolean(defaultField)
                          ? {
                              label: defaultField?.name,
                              value: defaultField?.uuid || "",
                            }
                          : null
                      }
                    />
                    <ConditionFunction
                      index={i}
                      field={field}
                      defaultValue={
                        Boolean(defaultConditionFunction)
                          ? {
                              label: (
                                <span className="t-lowercase">
                                  {defaultConditionFunction?.name}
                                </span>
                              ),
                              value: defaultConditionFunction?.uuid || "",
                            }
                          : null
                      }
                    />
                    <ConditionValue
                      index={i}
                      field={field}
                      defaultValue={
                        Boolean(conditionValue)
                          ? {
                              label: conditionValue,
                              value: conditionValue,
                            }
                          : null
                      }
                    />
                  </div>
                )}
              </ConditionWrapper>
              <div className="t-self-end">
                <Button
                  customType="ghost_icon"
                  onClick={() => onRemove(id)}
                  disabled={ruleConditionData.length === 1}
                >
                  <Cross />
                </Button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        <div>
          <Button
            size="small"
            onClick={addNewCondition}
            disabled={ruleConditionData.length === 4}
          >
            Add a condition
          </Button>
        </div>
      </RuleConsoleItems>
    );
  }

  return null;
};

const RuleConsole = ({
  onSubmit,
  currentStep,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: (
    values: EditRuleValuesType,
    formikHelpers: FormikHelpers<EditRuleValuesType>
  ) => void | Promise<any>;
}) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { editRuleId } = useSelector((store: RootState) => store.ruleEngine);

  const { data, isFetching } = useGetRuleByIdQuery(
    { entityId, groupId, ruleId: editRuleId },
    { skip: !groupId || !entityId || !editRuleId }
  );

  const {
    uuid,
    category,
    conditions = [],
    name = "",
    start_date = "",
    end_date = "",
  } = data || {};

  const ruleConditionData = conditions.map(
    ({ condition_operand, condition_operator, uuid, value }) => ({
      uuid,
      id: randomBytes(10).toString("hex"),
      field: condition_operand.uuid,
      conditionFunction: condition_operator.uuid,
      conditionValue: value,
    })
  );

  const onClose = () => {
    dispatch(closeEditModal());
  };

  if (isFetching) {
    return (
      <Modal.Content size="large" useCustomOverlay>
        <Loader />
      </Modal.Content>
    );
  }

  return (
    <Formik
      key={uuid}
      initialValues={{
        name: name,
        startDate: dayjs(start_date).format(DD_MMM_YYYY),
        endDate: dayjs(end_date).format(DD_MMM_YYYY),
        assigned_category_id: category?.uuid || "",
        ruleConditionData,
      }}
      validateOnChange
      validationSchema={editCategorisationRuleSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values: { assigned_category_id } }) => (
        <Form>
          <Modal.Content size="large" useCustomOverlay>
            <Modal.Header>
              <div>
                <Modal.Title>Edit rule</Modal.Title>
                <Modal.Subtitle>
                  <RuleStepper
                    currentStep={currentStep}
                    onStepperClick={setCurrentStep}
                  />
                </Modal.Subtitle>
              </div>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <div className="t-flex t-flex-col t-gap-6">
                <TitleAndDate />
                <Divider />
                <Conditions />
                <Categories assigned_category_id={assigned_category_id} />
              </div>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                customType="primary"
                onClick={submitForm}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Preview transactions
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        </Form>
      )}
    </Formik>
  );
};

export const EditRuleModal = () => {
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [currentStep, setCurrentStep] = useState(1);
  const [markRuleActive] = useMarkRuleActiveMutation();
  const [editRule] = useEditRuleMutation();

  const { editRuleId, isEditModalOpen } = useSelector(
    (store: RootState) => store.ruleEngine
  );

  const { data } = useGetRuleByIdQuery(
    { entityId, groupId, ruleId: editRuleId },
    { skip: !groupId || !entityId || !editRuleId }
  );

  const { uuid: ruleId = "" } = data || {};

  const onClose = () => {
    setCurrentStep(1);
    dispatch(closeEditModal());
  };

  const onActive = async ({ ruleId }: { ruleId: string }) => {
    try {
      await markRuleActive({ entityId, groupId, ruleId }).unwrap();
      successToast({ message: "Rule Changed" });
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onEdit = async (
    {
      endDate,
      startDate,
      ruleConditionData,
      assigned_category_id,
      name,
    }: EditRuleValuesType,
    { resetForm }: FormikHelpers<EditRuleValuesType>
  ) => {
    try {
      const rule_condition_data = ruleConditionData.map(
        ({ conditionFunction, conditionValue, field, uuid }) => {
          if (Boolean(uuid)) {
            return {
              uuid,
              value: conditionValue,
              operator_id: conditionFunction,
              operand_id: field,
            };
          } else {
            return {
              value: conditionValue,
              operator_id: conditionFunction,
              operand_id: field,
            };
          }
        }
      );

      await editRule({
        entityId,
        groupId,
        rule_end_date: dayjs(endDate).format(YYYY_MM_DD),
        rule_start_date: dayjs(startDate).format(YYYY_MM_DD),
        rule_condition_data,
        assigned_category_id,
        name,
        ruleId,
      }).unwrap();
      setCurrentStep(2);
      resetForm();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isEditModalOpen} onOpenChange={onClose} modal={false}>
      {currentStep === 1 ? (
        <RuleConsole
          onSubmit={onEdit}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      ) : (
        <TransactionsPrview
          uuid={ruleId}
          onSubmit={onActive}
          close={onClose}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          linfoLabel={
            <div className="t-flex t-w-full t-border-solid t-border t-border-blue-70 t-rounded t-px-2 t-py-1.5 t-items-center t-gap-1 t-bg-blue-20">
              <span className="t-text-blue-80">
                <InfoSolid size="24" />
              </span>
              <span className="t-text-body-sm t-text-text-60">
                Note: This won't affect categorised transactions.
              </span>
            </div>
          }
          submitText="Edit and apply"
          modalTitle="Edit rule"
        />
      )}
    </Modal.Root>
  );
};
