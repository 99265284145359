import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { Header } from "components/DesignSystem/Header/Header";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import Modal from "components/Modal/Modal";
import { ConditionalLink } from "components/conditionalLink";
import { Badge } from "components/design/badge";
import ConditionalDynamicToolTip from "components/design/conditionalDynamicToolTip";
import ToolTip from "components/design/toolTip";
import {
  INVESTOR_UPDATE_ADD_CLICKED,
  INVESTOR_UPDATE_DRAFT_CLICKED,
  INVESTOR_UPDATE_HOME_VISITED,
} from "constants/analyticsEvents";
import { DD_MMM_YYYY } from "constants/date";
import { DRAFT, SHARED } from "constants/investorUpdateStatuses";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetEntitiesQuery } from "store/apis/group";
import {
  useDeleteStakeholderUpdateMutation,
  useDuplicateInvestorUpdateMutation,
  useGetAllStakeholderUpdatesQuery,
} from "store/apis/stakeholderUpdate";
import { StakeholderUpdate } from "types/Models/stakeholderUpdate";
import { BackendError } from "types/utils/error";

const STATUS_MAP: { [key: string]: string } = {
  [DRAFT]: "DRAFT",
  [SHARED]: "SENT",
};

export const InvestorUpdatesHome = () => {
  usePageTitle("Investor Updates");
  const history = useHistory();
  const { update } = useUpdateQuery();
  const { data: { groups: [group] = [null] } = {} } = useGetEntitiesQuery();
  const [deleteStakeholderUpdate, { isLoading: deletingStakeholderUpdate }] =
    useDeleteStakeholderUpdateMutation();
  const query = useQuery();
  const currentPage = Number(query.get("page")) || 1;
  const [deleteUpdateId, setDeleteUpdateId] = useState<null | string>(null);
  const { url } = useRouteMatch();
  const { alertToast, successToast } = useToast();

  const [duplicateUpdate, { isLoading: isDuplicating }] =
    useDuplicateInvestorUpdateMutation();

  const {
    data: allStakeholderUpdates,
    isLoading,
    isSuccess,
  } = useGetAllStakeholderUpdatesQuery(
    {
      pageNum: currentPage,
      groupId: group?.uuid!,
    },
    {
      skip: !group?.uuid,
    }
  );

  const { total_count, total_pages, current_page } =
    allStakeholderUpdates || {};

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };
  const goToPrevPage = () => {
    const localCurrentPage =
      currentPage < total_pages! ? currentPage : total_pages!;
    update({ query: "page", value: localCurrentPage - 1 });
  };
  const goToNextPage = () => {
    if (currentPage < total_pages!) {
      update({ query: "page", value: currentPage + 1 });
    }
  };
  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const groupId = group?.uuid;

  const onDelete = useCallback(
    async (updateId: string) => {
      if (groupId) {
        await deleteStakeholderUpdate({
          groupId: groupId,
          updateId: updateId,
        }).unwrap();
        setDeleteUpdateId(null);
      }
    },
    [deleteStakeholderUpdate, groupId]
  );

  const onDuplicate = async ({ updateId }: { updateId: string }) => {
    try {
      await duplicateUpdate({ groupId, updateId }).unwrap();
      successToast({ message: "Duplicate created" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const createColumn = createColumnHelper<StakeholderUpdate>();

  const columns = useMemo(
    () => [
      createColumn.accessor("title", {
        header: "Subject",
        size: 30,
        cell: (info) => (
          <div className="t-text-body t-font-medium t-text-text-100">
            {info.getValue()}
          </div>
        ),
      }),
      createColumn.accessor("status", {
        header: "Status",
        size: 20,
        cell: (info) => (
          <span className="t-flex t-text-body-sm">
            <Badge
              color={
                { [DRAFT]: "neutral", [SHARED]: "light-green" }[info.getValue()]
              }
            >
              <span>{STATUS_MAP[info.getValue()]}</span>
            </Badge>
          </span>
        ),
      }),
      createColumn.accessor("updated_at", {
        header: "Saved/Sent On",
        size: 35,
        cell: (info) => (
          <div>
            <ToolTip
              text={dayjs(info.getValue()).format("MMM DD, YYYY, hh:mm A")}
            >
              <span className="t-text-body t-font-medium t-text-text-100">
                {dayjs().diff(dayjs(info.getValue()), "hours") < 24
                  ? dayjs(info.getValue()).fromNow()
                  : dayjs(info.getValue()).format(DD_MMM_YYYY)}
              </span>
            </ToolTip>
          </div>
        ),
      }),
      // {
      //   Header: "Key Metric",
      //   width: "30%",
      //   accessor: "primary_metrics.primary_metric.title",
      //   Cell: ({
      //     value,
      //     row,
      //   }: {
      //     value: string;
      //     row: { original: StakeholderUpdate };
      //   }) => (
      //     <div className="t-text-body t-text-text-60">
      //       {value
      //         ? `${value}: ${row.original.primary_metrics?.value}${
      //             row.original.primary_metrics?.primary_metric.metric_type ===
      //             "PERCENT"
      //               ? "%"
      //               : ""
      //           }`
      //         : "-"}
      //     </div>
      //   ),
      // },
      createColumn.accessor("is_requests_available", {
        header: "",
        size: 15,
        cell: ({ row }: { row: { original: StakeholderUpdate } }) => {
          const isStatusSent = row.original.status === "SHARED" ? true : false;
          return (
            <DropDown.Root>
              <DropDown.Trigger asChild>
                <div className="t-justify-enthe d t-flex">
                  <img src={ThreeDots} alt="Open menu" />
                </div>
              </DropDown.Trigger>
              <DropDown.Portal>
                <DropDown.Content align="start">
                  <DropDown.Item
                    disabled={row.original.status !== DRAFT}
                    onSelect={() => setDeleteUpdateId(row.original.uuid)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ConditionalDynamicToolTip
                      condition={
                        row.original.status !== DRAFT &&
                        "Sent updates can't be deleted"
                      }
                    >
                      Delete
                    </ConditionalDynamicToolTip>
                  </DropDown.Item>
                  {isStatusSent && (
                    <DropDown.Item
                      onSelect={() =>
                        onDuplicate({ updateId: row.original.uuid })
                      }
                      onClick={(e) => e.stopPropagation()}
                    >
                      Duplicate
                    </DropDown.Item>
                  )}
                </DropDown.Content>
              </DropDown.Portal>
            </DropDown.Root>
          );
        },
      }),
    ],
    [onDelete]
  );

  const data = useMemo(
    () =>
      allStakeholderUpdates?.sh_updates.filter(
        (e) => e.title || e.primary_metrics
      ) || [],
    [allStakeholderUpdates?.sh_updates]
  );

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(INVESTOR_UPDATE_HOME_VISITED);
  }, [trackEvent]);

  const onRowClick =
    (updateId: string, status: string) =>
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      let redirectLink = `${url}/${updateId}`;
      if (status === SHARED) {
        redirectLink = `${url}/preview/${updateId}`;
      }

      trackEvent(INVESTOR_UPDATE_DRAFT_CLICKED, { updateId });
      history.push(redirectLink);
    };

  const paginationData = {
    totalPage: allStakeholderUpdates?.total_pages!,
    currentPage: allStakeholderUpdates?.current_page!,
    itemsPerPage: allStakeholderUpdates?.per_page!,
    totalItemCount: allStakeholderUpdates?.total_count!,
  };

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Investor Updates"
          right={
            <ConditionalLink
              to={`${url}/add`}
              onClick={() => trackEvent(INVESTOR_UPDATE_ADD_CLICKED)}
            >
              <Button size="small" customType="primary">
                Create an update
              </Button>
            </ConditionalLink>
          }
        />
      }
    >
      <div>
        {total_count && total_count === 0 && (
          <p className="t-m-0 t-w-3/4 t-text-text-60">
            Get started by creating your first update with the help of the
            sample draft prepared for you.
          </p>
        )}

        {isLoading && (
          <div className="t-flex t-h-full t-w-full t-items-center t-justify-center t-py-9">
            <div className="t-h-12 t-w-12 t-animate-spin t-rounded-full t-border-4 t-border-solid t-border-purple t-border-t-surface-transparent" />
          </div>
        )}

        {isSuccess && allStakeholderUpdates.sh_updates?.length > 0 && (
          <>
            <p className="t-m-0 t-mb-4 t-text-body-lg t-font-semibold">
              Recent Updates
            </p>
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
            <Table.Container>
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          key={header.id}
                          style={{ width: `${header.getSize()}%` }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  {table.getRowModel().rows.map((row) => (
                    <Table.Row
                      onClick={onRowClick(
                        row.original.uuid,
                        row.original.status
                      )}
                      key={row.id}
                      className="t-h-11"
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Table.Cell
                            key={cell.id}
                            style={{ width: `${cell.column.getSize()}%` }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Content>
            </Table.Container>
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </>
        )}
      </div>
      <Modal.Root
        open={Boolean(deleteUpdateId)}
        onOpenChange={() => setDeleteUpdateId(null)}
      >
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Title>
              Are you sure you want to delete the update?
            </Modal.Title>

            <div className="t-mt-4 t-flex t-justify-end t-gap-4">
              <Button onClick={() => setDeleteUpdateId(null)}>No</Button>
              <Button
                customType="primary"
                onClick={() => onDelete(deleteUpdateId!)}
                isLoading={deletingStakeholderUpdate}
              >
                Yes
              </Button>
            </div>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
    </DashboardLayout>
  );
};
