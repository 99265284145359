import { useBooksActionItemsQuery } from "store/apis/books";
import { useCurrentEntityId } from "./useCurrentEntityId";
import { useCurrentGroupContext } from "./useCurrentGroupContext";

export const useActionItems = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const {
    data: booksActionItems,
    isLoading,
    isSuccess,
  } = useBooksActionItemsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const {
    action_items_response = [],
    action_items_count = 0,
    channel_url,
  } = booksActionItems || {};

  const isBankReconnectRequired = action_items_response?.some(
    ({ item_type }) => item_type === "Connections"
  );

  const isReconciliationRequired = action_items_response?.some(
    ({ item_type }) => item_type === "Reconciliation"
  );

  return {
    action_items_response,
    action_items_count,
    isBankReconnectRequired,
    isReconciliationRequired,
    isLoading,
    isSuccess,
  };
};
