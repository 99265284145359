import Modal from "components/Modal/Modal";
import Logo from "components/inkleLogo";
import TransferSteps from "./TransferSteps";
import { useState } from "react";
import { AmountStep } from "./AmountStep";
import { PurposeCodeStep } from "./PurposeCodeStep";
import { ReviewStep } from "./ReviewStep";
import { TransferMoneyStep } from "./TransferMoneyStep";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { resetAllTPStates } from "store/slices/transferpricing";
import { FloatingChat } from "components/floatingChat/FloatingChat";
import { closeFloatingChat } from "store/slices/chat";
import { useHistory, useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

const InitiateTransferModal = ({ to }: { to: string }) => {
  const history = useHistory();
  const { search } = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();

  const onClose = () => {
    history.replace(`${to}${search}`);
    dispatch(resetAllTPStates());
    setCurrentStep(1);
    dispatch(closeFloatingChat());
  };

  return (
    <Modal.Root open={true} onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className=" t-h-full t-w-full t-overflow-y-scroll t-px-[86px] t-py-6">
          <Modal.Close className="all:unset t-pr-[40px]" />

          <Modal.Title className="t-mb-16 t-flex t-text-h6 t-text-text-100">
            <div className="t-flex t-w-full t-justify-between">
              <div className="t-flex">
                <div className="t-h-[26px] t-border t-border-b-0 t-border-l-0 t-border-t-0 t-border-solid t-border-neutral-20 t-pr-4">
                  <Logo className=" t-h-[24px] t-w-[65px]" />
                </div>
                <div className="t-pl-4">TP Payments</div>
              </div>
              {/* {currentStep === 4 && (
                <div className="helpSection t-border t-border-solid t-border-neutral-10 t-p-1 t-text-neutral-80 hover:t-text-purple">
                  <HelpIcon color="currentColor" width="20px" />
                </div>
              )} */}
            </div>
          </Modal.Title>
          <Modal.Description className="t-flex t-w-full">
            {currentStep < 4 && (
              <div className="t-mb-6 t-w-1/5">
                <TransferSteps currentStep={currentStep} />
              </div>
            )}
            <div
              className={classNames(" t-flex t-w-full t-justify-center", {
                "t-ml-0": currentStep === 4,
                "-t-ml-28": currentStep < 4,
              })}
            >
              {currentStep === 1 && (
                <AmountStep backPath={to} setCurrentStep={setCurrentStep} />
              )}
              {currentStep === 2 && (
                <PurposeCodeStep setCurrentStep={setCurrentStep} />
              )}
              {currentStep === 3 && (
                <ReviewStep setCurrentStep={setCurrentStep} />
              )}
              {currentStep === 4 && (
                <TransferMoneyStep setCurrentStep={setCurrentStep} />
              )}
              <OutsideClickHandler
                onOutsideClick={() => {
                  dispatch(closeFloatingChat());
                }}
              >
                <FloatingChat />
              </OutsideClickHandler>
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default InitiateTransferModal;
