import CardAdd from "components/billing/CardAdd";
import { SubscriptionModal } from "components/SubscriptionModal/SubscriptionModal";
import FreeSubscriptionModal from "components/tasks/task7004/FreeSubscriptionModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import {
  ComponentProps,
  ComponentType,
  ReactNode,
  useContext,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import { closeFreeSubscription } from "store/slices/subscriptionModal";

const RestrictedView = ({
  component: Component,
  render,
  publicUser,
}: {
  component?: ComponentType<any>;
  render: ReactNode;
  publicUser: boolean;
}) => {
  const history = useHistory();
  const onClose = () => history.push("/");
  const dispatch = useDispatch();
  const [isOpenFreeSubscriptionModal, setIsOpenFreeSubscriptionModal] =
    useState(true);
  const [isOpenAddCard, setIsOpenAddCard] = useState(false);

  const openAddCardModal = () => {
    setIsOpenFreeSubscriptionModal(false);
    setIsOpenAddCard(true);
  };

  const redirectHome = () => {
    onClose();
    dispatch(closeFreeSubscription());
  };

  return (
    <>
      {Component ? <Component /> : render}
      {publicUser ? (
        isOpenFreeSubscriptionModal ? (
          <FreeSubscriptionModal
            openSubscription={isOpenFreeSubscriptionModal}
            closeSubscription={redirectHome}
            closeModal={openAddCardModal}
          />
        ) : (
          <CardAdd
            openAddCard={isOpenAddCard}
            closeModal={() => setIsOpenAddCard(false)}
          />
        )
      ) : (
        <SubscriptionModal show onClose={onClose} />
      )}
    </>
  );
};

export const RestrictedRoute = ({
  component,
  ...props
}: ComponentProps<typeof Route>) => {
  const { authtoken } = useContext(authContext);
  const { pathname } = useLocation();
  const { platform_subscription: currentSubscription } = authtoken || {};
  const { isCustomer } = useRoleBasedView();

  const publicUserRestrictedRoutes = [
    "/billing",
    "/perks",
    "/mailroom",
    "/rewards",
    "/resources",
    "/settings",
    "/team",
    "/filings",
    "/chat",
    "/deadlines",
    "/rewards",
    "/rewards/referral",
    "/books",
  ];

  if (!currentSubscription?.is_subscription_required) {
    return <Route {...props} component={component} />;
  }

  const isRestricted =
    publicUserRestrictedRoutes.includes(pathname) && authtoken.is_public_user;

  if (isRestricted && isCustomer) {
    return (
      <Route
        {...props}
        render={(p) => (
          <RestrictedView
            component={component}
            render={props.render?.(p)}
            publicUser={authtoken.is_public_user}
          />
        )}
      />
    );
  }

  return <Route {...props} component={component} />;
};
