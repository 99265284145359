import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Slider } from "components/DesignSystem/Slider/Slider";
import Pencil from "components/icons/pencil";
import { Badge } from "components/Task/Badge";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseArrow from "static/images/CloseArrow.svg";
import { useGetRAByIdQuery } from "store/apis/registerAgent";
import { closeSlider, openAddOrEditModal } from "store/slices/registerAgent";
import { RootState } from "store/store";

const Item = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <div className="t-flex t-flex-col t-gap-0.5 t-text-body t-text-text-60">
      <span className="t-text-overline t-uppercase">{title}</span>
      {children}
    </div>
  );
};

export const RASilder = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const { show, raId } = useSelector(
    (state: RootState) => state.registerAgent.silder
  );
  const entityId = useCurrentEntityId();
  const { data: registerAgent, isLoading } = useGetRAByIdQuery(
    { groupId, raId, entityId },
    {
      skip: !groupId || !raId || !entityId,
    }
  );

  const {
    start_date,
    expiration_date,
    agent_name,
    street_address,
    country,
    state,
    city,
    zipcode,
    phone_number,
    status = "ACTIVE",
  } = registerAgent || {};

  const onEdit = () => {
    dispatch(openAddOrEditModal(raId));
  };

  return (
    <>
      {show && (
        <Slider
          open={show}
          width={640}
          className="t-h-max t-w-[40rem] t-overflow-auto t-rounded t-border t-border-solid t-border-neutral-0 !t-pl-6 t-shadow  sm:t-w-full"
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="t-flex t-items-stretch t-justify-between">
                <span className="t-flex t-flex-col">
                  <span className="t-text-subtitle t-text-text-black">
                    {state}
                  </span>
                </span>
                <span className="t-flex t-items-center t-gap-2">
                  <Badge type={status}>{status}</Badge>
                  <Button size="small" customType="icon" onClick={onEdit}>
                    <span className="t-text-neutral">
                      <Pencil color="currentColor" size="20" />
                    </span>
                  </Button>
                  <Button
                    customType="icon"
                    size="small"
                    onClick={() => dispatch(closeSlider())}
                  >
                    <img src={CloseArrow} alt="Close" />
                  </Button>
                </span>
              </div>
              <div className="t-mt-3 t-flex t-flex-col t-gap-6">
                <Item title="AGENT NAME">{agent_name}</Item>
                <div className="t-flex t-items-center t-gap-36">
                  <Item title="STARTING DATE">{start_date || "--"}</Item>
                  <Item title="EXPIRATION DATE">{expiration_date || "--"}</Item>
                </div>
                <Item title="ADDRESS">
                  <div className="t-max-w-96 t-break-words">
                    {street_address || "--"}
                  </div>
                </Item>
                <div className="t-flex  t-gap-36">
                  <Item title="COUNTRY">{country || "--"}</Item>
                  <Item title="CITY">
                    <div className="t-max-w-28 t-break-words">
                      {city || "--"}
                    </div>
                  </Item>
                </div>
                <Item title="ZIP CODE">{zipcode || "--"}</Item>
                <Item title="PHONE NO.">{phone_number || "--"}</Item>
              </div>
            </>
          )}
        </Slider>
      )}
    </>
  );
};
