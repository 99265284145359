import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";
import { ChannelTask } from "./task";

export type Alarm = {
  entity_id: string;
  entity_name: string;
  rule: {
    name: string;
    tax_year_type: string;
  };
  deadline: string;
  task: ChannelTask;
};

export const alarmApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAlarms: build.query<
      Pagination & { alarms: Alarm[] },
      {
        page_num: number;
        search_term?: string;
        company_tag_filter_uuids?: string;
        company_groups?: string;
        date_from?: string;
        date_to?: string;
      }
    >({
      query: (params) => {
        return {
          url: `/api/inkle/alarm/`,
          params,
        };
      },
      providesTags: () => ["ALARMS"],
    }),
  }),
});

export const { useGetAllAlarmsQuery } = alarmApis;
