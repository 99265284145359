import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { stringify } from "qs";
import { useHistory } from "react-router-dom";
import { useLazyGetAllTransactionsDataQuery } from "store/apis/transactions";
import {
  resetTransactionCardPreview,
  setTransactionHiddenByFilters,
} from "store/slices/transactionCardPreview";

export const TransactionHiddenByFilterConfirmation = () => {
  const { transactionHiddenByFilters, filtersApplied, transactionId } =
    useAppSelector((state) => state.transactionCardPreview);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [getTransactionPageNum, { isLoading }] =
    useLazyGetAllTransactionsDataQuery();

  const onRedirect = async () => {
    if (transactionId) {
      const data = await getTransactionPageNum({
        groupId: filtersApplied.groupId,
        entityId: filtersApplied.entityId,
        sortCol: filtersApplied.sortCol,
        sortOrder: filtersApplied.sortOrder,
        jump_txn_id: transactionId,
        fetch_page_num: true,
      }).unwrap();

      const query = stringify(
        {
          entity: filtersApplied.entityId,
          company: filtersApplied.groupId,
          selected_transaction_id: transactionId,
          // @ts-ignore
          page: data.page_number,
          reset_filter: true,
        },
        { addQueryPrefix: true }
      );
      dispatch(resetTransactionCardPreview());
      history.replace(`/books/transactions${query}`);
    }
  };

  return (
    <Modal.Root
      onOpenChange={() => dispatch(resetTransactionCardPreview())}
      open={transactionHiddenByFilters}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Filters Applied</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <p className="t-m-0">
            The selected transaction is hidden due to current filters. Do you
            want to reset the filters and view the transaction?
          </p>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button>Close</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={onRedirect}
            isLoading={isLoading}
            disabled={isLoading}
          >
            View transaction
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
