import { boolean, object, string } from "yup";

export const closingMonthSchema = object({
  multipleMonths: boolean(),
  isHistorical: boolean(),
  seasonSummaryId: string().when("isHistorical", {
    is: false,
    then: string().required("Please select season"),
    otherwise: string().notRequired(),
  }),
  seasonYear: string().when("isHistorical", {
    is: true,
    then: string().required("Please select season"),
    otherwise: string().notRequired(),
  }),
  endDate: string().when("multipleMonths", {
    is: true,
    then: string().required("Please select end date"),
    otherwise: string().notRequired(),
  }),
  startDate: string().required("Please select start date"),
});
