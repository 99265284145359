import { ForwardRefComponent, HTMLMotionProps, motion } from "framer-motion";
import { ComponentProps, ReactNode } from "react";

export const AccordionAnimation = ({
  children,
  ...props
}: ComponentProps<
  ForwardRefComponent<HTMLDivElement, HTMLMotionProps<"div">>
>) => {
  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      {...props}
    >
      {children}
    </motion.div>
  );
};
