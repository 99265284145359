import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { InvestorTable } from "components/RaiseCRM/InvestorTable";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { createProfile } from "store/slices/raiseCrm";

export const RaiseCRM = () => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  return (
    <div className="t-w-full">
      <Header
        title="Raise CRM"
        right={
          <div>
            <Button
              customType="primary"
              size="small"
              onClick={() => dispatch(createProfile())}
            >
              Add fund
            </Button>
          </div>
        }
        bottom={
          <Tab.Root defaultValue={pathname} value={pathname}>
            <Tab.List>
              <Tab.NavTrigger replace exact value={url}>
                Investor
              </Tab.NavTrigger>
              {/* <Tab.NavTrigger replace exact value={`${url}/founder`}>
                Founder
              </Tab.NavTrigger> */}
            </Tab.List>
          </Tab.Root>
        }
      />
      <Switch>
        <Route exact path={`${path}`}>
          <InvestorTable />
        </Route>
        <Route exact path={`${path}/founder`}></Route>
      </Switch>
    </div>
  );
};
