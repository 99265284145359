import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import React from "react";
import { FileIcon } from "utils/fileTypeIcon";
import { CustomData } from "types/Models/reviewDocumentAndBalancingPayment";
import { Button } from "components/DesignSystem/Button/Button";
import { DownloadIcon } from "components/icons/Download";

export const ReviewChatCardBody = ({
  customData,
  downloadFiles,
}: {
  customData: CustomData;
  downloadFiles: () => void;
}) => (
  <div className="t-flex t-justify-between t-items-center">
    <div className="t-flex t-gap-3 t-items-center">
      <FileIcon fileType="MULTI_FILES" width="24px" height="24px" />
      <div className="t-text-subtext-sm t-text-text-60">
        {customData?.ctas?.[0]?.metadata?.file_data?.length} attachments
      </div>
    </div>
    <Button customType="ghost_icon" size="small" onClick={downloadFiles}>
      <span className="t-text-text-30">
        <DownloadIcon color="currentColor" strokeWidth="1.5" />
      </span>
    </Button>
  </div>
);
