import { createSlice } from "@reduxjs/toolkit";

export type slackModalState = {
  showSlackModal: boolean;
};

const initialState: slackModalState = {
  showSlackModal: false,
};

export const slackSlice = createSlice({
  name: "slack",
  initialState,
  reducers: {
    openSlackModal: (state) => {
      state.showSlackModal = true;
    },
    closeSlackModal: (state) => {
      state.showSlackModal = false;
    },
  },
});

export const { openSlackModal, closeSlackModal } = slackSlice.actions;

export default slackSlice.reducer;
