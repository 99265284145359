import { array, number, object, string } from "yup";

const notRequired = {
  date: string().notRequired(),
  description: string().notRequired(),
  amount: string().notRequired(),
};

const required = {
  date: string().required(),
  description: string().required(),
  amount: string().required(),
};

export const manuallyTransactionsSchema = () =>
  object().shape({
    transactions: array().of(
      object().when({
        is: (value: Record<string, string>) => {
          return Object.entries(value).every(([key, value]) =>
            key === "id" || key === "amount" ? true : !value
          );
        },
        then: object(notRequired),
        otherwise: object(required),
      })
    ),
  });
