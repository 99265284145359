import Left from "components/landingContent";
import React from "react";

// styles
import "../../../static/styles/components/onboardingAction.css";

// components
import OnBoardingActionRight from "./onBoardingActionRight";

const OnBoardingAction = () => {
  return (
    <div className="t-items-start sm:t-overscroll-auto t-h-full sm:t-h-screen t-pt-12 t-flex xl:t-container">
      <Left className="t-hidden lg:t-block t-sticky" />
      <OnBoardingActionRight />
    </div>
  );
};

export default OnBoardingAction;
