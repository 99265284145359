import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { CurrencyCircleDollar } from "components/icons/CurrencyCircleDollar";
import { useDispatch } from "react-redux";
import { openAddRequestInfoModal } from "store/slices/openItem";
import { OpenItemStatus } from "types/Models/openItem";
import { pluralize } from "utils/pluralize";
import { ChatCardButton } from "./ChatCardButton";

export const RequestedInfoChatCard = ({
  customData,
  groupId,
  messageId,
  channelId,
}: {
  customData: {
    card_status: OpenItemStatus;
    csv_id: string;
    header: string;
    status: OpenItemStatus;
    transaction_count: 1;
    transaction_ids: string;
  };
  groupId: string;
  messageId: string;
  channelId: string;
}) => {
  const dispatch = useDispatch();

  const onAction = () => {
    dispatch(
      openAddRequestInfoModal({
        csvId: customData?.csv_id,
        groupId,
        messageId,
        channelId,
      })
    );
  };

  const urlText = customData.status === "REQUESTED" ? "Add Info" : "View Info";

  return (
    <ChatCard.Root>
      <ChatCard.Header>{customData.header}</ChatCard.Header>
      <ChatCard.Body>
        <div className="t-flex t-gap-3 t-items-center">
          <CurrencyCircleDollar color="#706A85" />
          <div className="t-text-subtitle-sm">
            {pluralize(
              customData?.transaction_count,
              "transaction",
              "transactions"
            )}
          </div>
        </div>
      </ChatCard.Body>
      <ChatCard.Footer>
        <ChatCardButton urlText={urlText} handleCardAction={onAction} />
      </ChatCard.Footer>
    </ChatCard.Root>
  );
};
