import { MissingDataPoint } from "types/Models/deadlineAlarm";
import { emptyApi } from "./emptyApi";

const deadlineAlarmAPi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getMissingDataPoints: build.query<
      MissingDataPoint[],
      { entityId: string; groupId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/alarm/group/${groupId}/entity/${entityId}/missing_data_points/`,
        };
      },
      providesTags: ["MISSING_DATA_POINTS"],
    }),

    addMissingDataPoint: build.mutation<
      MissingDataPoint,
      {
        entityId: string;
        groupId: string;
        value: string;
        missingDataPointId: string;
      }
    >({
      query: ({ groupId, entityId, value, missingDataPointId }) => ({
        url: `/api/inkle/alarm/group/${groupId}/entity/${entityId}/missing_data_points/${missingDataPointId}/`,
        method: "PATCH",
        body: { value },
      }),
      invalidatesTags: (result) =>
        result ? ["MISSING_DATA_POINTS", "ALL_COMPLIANCES"] : [],
    }),
  }),
  overrideExisting: false,
});

export const { useGetMissingDataPointsQuery, useAddMissingDataPointMutation } =
  deadlineAlarmAPi;
