import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Task7004State {
  data: {};
}

const initialState: Task7004State = {
  data: {},
};

export const task7004Slice = createSlice({
  name: "task7004",
  initialState,
  reducers: {
    setRedirectPath: (state, action: PayloadAction<string>) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRedirectPath } = task7004Slice.actions;

export default task7004Slice.reducer;
