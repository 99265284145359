import React, { ButtonHTMLAttributes } from "react";
import cx from "classnames";

// styles
import "../../static/styles/components/button.css";

export type ButtonProps = {
  size?: "medium" | "small";
  isLoading?: boolean;
  color?:
    | "primary-gradient"
    | "blue"
    | "purple"
    | "ochre"
    | "brownNose"
    | "cancel"
    | "transparent"
    | "bordered"
    | "white-transparent"
    | "white"
    | "delete"
    | "purple-border"
    | "red"
    | "tertiary";
  addClassName?: string;
  block?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
  size,
  isLoading,
  color = "primary-gradient",
  children,
  disabled,
  addClassName = "",
  block = false,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={cx("custom-button", {
        "custom-button-medium": size === "medium",
        "custom-button-color-blue": color === "blue",
        "custom-button-small": size === "small",
        "custom-button-color-purple": color === "purple",
        "custom-button-color-purple-border": color === "purple-border",
        "custom-button-color-ochre": color === "ochre",
        "custom-button-color-brownNose": color === "brownNose",
        "custom-button-color-cancel": color === "cancel",
        "custom-button-color-bordered": color === "bordered",
        "custom-button-color-transparent": color === "transparent",
        "custom-button-color-white-transparent": color === "white-transparent",
        "custom-button-color-white": color === "white",
        "custom-button-color-tertiary": color === "tertiary",
        "custom-button-color-delete": color === "delete",
        "custom-button-color-red": color === "red",
        "custom-button-disabled": disabled,
        "w-100": block,
        [addClassName]: Boolean(addClassName),
      })}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && (
        <span className="pe-1 d-inline-block">
          <i className="fas fa-spinner fa-spin"></i>
        </span>
      )}
      {children}
    </button>
  );
};
