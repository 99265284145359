import * as qs from "qs";
import { emptyApi } from "./emptyApi";
import { Pagination } from "types/Models/pagination";
import { Group } from "types/Models/group";
import { ArrayElement } from "types/utils/ArrayElement";

export type BroadcastchannelResponse = {
  url: string;
  name: string;
  group_name: string;
  season: string;
  uuid: string;
  company_entity?: Pick<
    ArrayElement<Group["entities"]>,
    "uuid" | "name" | "country_code"
  >;
};

type Filter = {
  channel_types: string;
  task_status_ids: string;
  seasons: string;
  subscription_types: string;
  agent_ids: string;
  group_ids: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    createChatBroadcast: build.mutation<
      void,
      { message: string; excludedChannels: string; filters: Filter }
    >({
      query: ({ excludedChannels, message, filters }) => {
        const query = qs.stringify(
          { ...filters, excluded_channels: excludedChannels },
          { addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/messaging/channel/broadcast/${query}`,
          method: "post",
          body: {
            message,
          },
        };
      },
      invalidatesTags: ["ChatBroadcast"],
    }),
    getBroadcastChannels: build.query<
      Pagination & { channels: BroadcastchannelResponse[] },
      Filter & { search_term: string; page_num: number }
    >({
      query: (queryParams) => {
        const query = qs.stringify(queryParams, { addQueryPrefix: true });

        return {
          url: `/api/inkle/messaging/channel/templates/broadcast/${query}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.channels.map(
                ({ url }) => ({ type: "ChannelListQuery", id: "NO" } as const)
              ),
            ]
          : [],
    }),
    getChannels: build.query<
      { url: string; name: string; group_name: string; season: string }[],
      {
        searchTerm: string;
        taskTemplateIds: string;
        taskStatusIds: string;
      }
    >({
      query: ({ searchTerm, taskTemplateIds, taskStatusIds }) =>
        `/api/inkle/messaging/channel/templates/broadcast/?search_term=${searchTerm}&task_template_ids=${taskTemplateIds}&task_status_ids=${taskStatusIds}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ url }) => ({ type: "ChannelListQuery", id: "NO" } as const)
              ),
            ]
          : [],
    }),
  }),
});

export const {
  useCreateChatBroadcastMutation,
  useGetChannelsQuery,
  useLazyGetBroadcastChannelsQuery,
  useGetBroadcastChannelsQuery,
} = extendedApi;
