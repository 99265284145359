import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  filtersApplied: {} as {
    groupId: string;
    entityId: string;
    [key: string]: any;
  },
  transactionHiddenByFilters: false,
  transactionId: null as string | null,
  page: null as number | null,
};

export const transactionCardPreview = createSlice({
  name: "transactionsInChat",
  initialState,
  reducers: {
    setFiltersAplied: (state, action: PayloadAction<any>) => {
      state.filtersApplied = action.payload;
    },
    setTransactionHiddenByFilters: (
      state,
      action: PayloadAction<{
        hidden: boolean;
        transactionId: string;
        page: number;
      }>
    ) => {
      state.transactionHiddenByFilters = action.payload.hidden;
      state.transactionId = action.payload.transactionId;
      state.page = action.payload.page;
    },
    resetTransactionCardPreview: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  setFiltersAplied,
  setTransactionHiddenByFilters,
  resetTransactionCardPreview,
} = transactionCardPreview.actions;
export default transactionCardPreview.reducer;
