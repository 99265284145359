import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { YYYY_MM_DD } from "../../constants/date";

const DateSelectBox = ({
  value,
  onChange,
  name,
  placeholderText = "Click to select a date",
  ...props
}) => {
  const setDate = (date) => {
    let updatedDate = dayjs(new Date(date)).format(YYYY_MM_DD);

    const e = {
      target: {
        name: name,
        value: updatedDate,
      },
    };
    onChange(e);
  };

  const getIST = (givenDate) => {
    return new Date(givenDate);
  };

  return (
    <DatePicker
      placeholderText={placeholderText}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      dateFormat="yyyy-MMM-dd"
      selected={value ? getIST(value) : ""}
      onChange={(date) => setDate(date)}
      {...props}
    />
  );
};

export default DateSelectBox;
