import { Divider } from "@mui/material";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { Price } from "components/Price";
import { BOOKKEEPING_MANAGE } from "constants/addons";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import { resetAllAddonDataPayload, setAddonType } from "store/slices/addons";
import { RootState } from "store/store";
import { currency } from "utils/Currency";

export const BookKeepingProManage = () => {
  const dispatch = useDispatch();
  const { addonType, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonType === BOOKKEEPING_MANAGE
  );
  const [showCancelModal, setShowCancelModal] = useState(false);
  const {
    subscription_name,
    bookkeeping_product_type,
    tier_information,
    amount: addonAmount,
    stripe_coupon,
  } = addonData || {};

  const tierInformation = tier_information?.find(
    ({ amount }) => amount === addonAmount
  ) || { lower_range: 0, higher_range: 0 };

  const isBookkeepingPro = bookkeeping_product_type === "BOOKS_PRO";

  return (
    <>
      <Modal.Root
        open={showAddonModal}
        onOpenChange={() => {
          setShowAddonModal(false);
          dispatch(setAddonType(""));
          dispatch(resetAllAddonDataPayload());
        }}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Manage Plan</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-gap-4">
              <div className="t-p-5 t-border-solid t-border-neutral-0 t-rounded-lg">
                <div className="t-mb-5">
                  <div className="t-text-subtitle">{subscription_name}</div>
                  <Price
                    amount={stripe_coupon?.discounted_amount || addonAmount}
                    size="medium"
                  />
                </div>
                {isBookkeepingPro && (
                  <>
                    <Divider />
                    <div className="t-mt-5 t-flex t-flex-col t-gap-2">
                      <div className="t-flex t-flex-col t-gap-1.5">
                        <div className="t-text-body-sm t-text-neutral-20">
                          Current tier
                        </div>
                        <div className="t-flex t-items-center t-justify-between t-rounded  t-border t-border-solid t-border-neutral-0 t-bg-surface-lighter-grey t-text-body t-text-neutral-30 t-px-3 t-py-2">
                          <div>{`${currency({
                            amount: tierInformation?.lower_range,
                            maximumFractionDigits: 0,
                          })} -
                        ${currency({
                          amount: tierInformation?.higher_range,
                          maximumFractionDigits: 0,
                        })}
                      `}</div>
                          <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                            <ArrowRight stroke="2.5" color="#B8B5C2" />
                          </div>
                        </div>
                      </div>
                      <div className="t-text-body-sm t-text-text-30">
                        Tier is based on your expense volume, contact us to know
                        more.
                      </div>
                    </div>
                  </>
                )}
              </div>
              {isBookkeepingPro && (
                <div className="t-text-body-sm t-text-text-30">
                  To view all tiers, &nbsp;
                  <ConditionalLink
                    className="all:unset t-text-purple !t-underline t-cursor-pointer"
                    to="https://www.inkle.io/pricing#Bookkeeping"
                  >
                    Click here
                  </ConditionalLink>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-between">
            <Button
              customType="ghost"
              onClick={() => {
                setShowCancelModal(true);
                setShowAddonModal(false);
              }}
            >
              Cancel subscription
            </Button>
            <ConditionalLink to={"/chat"}>
              <Button customType="primary">Contact us</Button>
            </ConditionalLink>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <BookKeepingProCancel
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
      />
    </>
  );
};

export const BookKeepingProCancel = ({
  showCancelModal,
  setShowCancelModal,
}: {
  showCancelModal: boolean;
  setShowCancelModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const entityId = useCurrentEntityId();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        groupId,
        entityId,
        subscriptionId:
          addonData?.group_subscription?.[0]?.group_subscription_id,
      }).unwrap();
      successToast({ message: "Subscription cancelled successfully" });
      setShowCancelModal(false);
      dispatch(setAddonType(""));
    } catch (error: any) {
      alertToast({ message: error?.message || SOMETHING_WENT_WRONG });
      setShowCancelModal(false);
      dispatch(setAddonType(""));
    }
  };

  return (
    <Modal.Root open={showCancelModal} onOpenChange={setShowCancelModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel subscription</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            Are you sure you want to cancel this subscription? We will stop all
            accounting activity starting next month. You will also lose CPA &
            bookkeeper access via chat.
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              setShowCancelModal(false);
              dispatch(setAddonType(""));
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={handleCancelSubscription}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Cancel subscription
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
