import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { BankLogo } from "components/icons/BankLogo";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useToast } from "hooks/useToast";
import { useEffect, useId, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  useReactTable,
} from "react-table-8.10.7";
import {
  useCategorizeGroupTransactionsMutation,
  useLazyGetAllTransactionsDataQuery,
} from "store/apis/transactions";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { MerchantComponent } from "./MerchantSelector";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { closeSimilarTxnModal } from "store/slices/similarTransactions";
import Loader from "components/design/loader";
import { setRuleInfo } from "store/slices/ruleEngine";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";

export const AutoAssignModal = ({
  openConfirmation,
}: {
  openConfirmation: () => void;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useDispatch();
  const { successToast, alertToast } = useToast();
  const infiniteScrollId = useId();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { updateTxnResponse, isOpen } = useSelector(
    (state: RootState) => state.similarTransactions
  );

  const {
    transaction: { amount },
    group_categorization: {
      merchant_data_name,
      merchant_data_uuid,
      previous_assigned_category_id,
      previous_assigned_category_name,
      similar_merchant_txns_count,
      similar_merchant_txns_ids,
      transaction_category_name,
      transaction_category_uuid,
    },
  } = updateTxnResponse as Transactions;

  const [
    groupCategorize,
    { isLoading: isGroupCategorising, isSuccess: isBulkCategorised },
  ] = useCategorizeGroupTransactionsMutation();

  const transactionIds = similar_merchant_txns_ids;

  const {
    data: transactionData,
    loadNext,
    isLoading,
  } = usePaginatedQuery<{
    transactions?: Transactions[];
  }>(useLazyGetAllTransactionsDataQuery, "transactions", {
    groupId,
    entityId,
    transactionIds: transactionIds.join(","),
  });

  const {
    transactions = [],
    current_page = 1,
    total_pages = 1,
  } = transactionData || {};

  const createColumn = createColumnHelper<Transactions>();

  const columns = useMemo(
    () => [
      createColumn.display({
        id: "select",
        size: 1,
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),

        cell: ({ row }) => (
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
              key: row.id,
            }}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      }),

      createColumn.accessor("transaction.date", {
        size: 20,
        header: "DATE",
        cell: (info) => (
          <div className="t-py-3">
            {dayjs(info.getValue()).format(DD_MMM_YYYY)}
          </div>
        ),
      }),

      createColumn.accessor("transaction.from", {
        size: 10,
        header: () => (
          <div className="t-flex t-justify-center t-w-full">Source</div>
        ),
        cell: (info) => {
          const from = info.getValue();
          const { bank_account } = from || {};
          const { bank_brand } = bank_account || {};
          const { logo_url } = bank_brand || {};

          const mask = from?.bank_account?.mask
            ? `•••• ${from?.bank_account?.mask}`
            : "";

          return (
            <ConditionalToolTip
              condition={
                from?.bank_account && `${from?.bank_account?.nickname} ${mask}`
              }
            >
              <span className="t-flex t-justify-center">
                {logo_url ? (
                  <Avatar
                    src={logo_url}
                    alt={from?.bank_account?.mask || "Bank"}
                  />
                ) : (
                  <BankLogo />
                )}
              </span>
            </ConditionalToolTip>
          );
        },
      }),

      createColumn.accessor("transaction.merchant", {
        size: 25,
        header: "VENDOR",
        cell: (info) => {
          const {
            row: {
              original: {
                transaction: { logo, merchant },
              },
            },
          } = info;

          if (!merchant) {
            return "-";
          }

          return <MerchantComponent name={merchant} logo={logo} />;
        },
      }),

      createColumn.accessor("transaction.category", {
        size: 25,
        header: () => "CATEGORY",
        cell: (info) => {
          const {
            row: {
              original: {
                transaction: { category },
              },
            },
          } = info;

          if (!category) {
            return "-";
          }

          return <>{category?.name}</>;
        },
      }),

      createColumn.accessor("transaction.amount", {
        size: 20,
        header: () => <div className="t-flex t-justify-end">AMOUNT</div>,
        cell: (info) => {
          const amount = info.getValue();
          const {
            transaction: { is_credit_card },
          } = info.row.original || {};

          const isCredit = amount > 0;

          if (!amount) {
            return "-";
          }

          return (
            <div
              className={classNames("t-flex t-justify-end", {
                "t-text-dark_green-50": isCredit && !is_credit_card,
              })}
            >
              <AmountSuperScript amount={amount} />
            </div>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection ?? (() => {}),
    state: {
      rowSelection,
    },
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const selectedTransactionIds = useMemo(() => {
    return table.getSelectedRowModel().flatRows.map(
      ({
        original: {
          transaction: { uuid },
        },
      }) => uuid
    );
  }, [table.getSelectedRowModel()]);

  useEffect(() => {
    const selectedTxns = transactions
      .map(({ transaction: { uuid } }) => uuid)
      .reduce((accumulator, currentId, i) => {
        return { ...accumulator, [i]: true };
      }, {});
    setRowSelection(selectedTxns);
  }, [transactions]);

  const categorizeAll = async () => {
    const payload = {
      transaction_ids: selectedTransactionIds.join(", "),
      merchant_data_uuid: merchant_data_uuid,
      transaction_category_uuid: transaction_category_uuid,
      previous_assigned_category_id: previous_assigned_category_id,
      cash_flow: amount > 0 ? "CREDIT" : "DEBIT",
    };

    try {
      await groupCategorize({ groupId, entityId, payload }).unwrap();
      successToast({
        message: `Category ${
          payload.transaction_category_uuid ? "assigned" : "unassigned"
        }`,
      });

      if (transaction_category_uuid) {
        dispatch(
          setRuleInfo({
            categoryId: transaction_category_uuid || "",
            merchantNames: merchant_data_name || "",
          })
        );
        openConfirmation();
      }
      dispatch(closeSimilarTxnModal());
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <>
      <Modal.Root
        open={isOpen}
        onOpenChange={() => dispatch(closeSimilarTxnModal())}
      >
        <Modal.Content size="xl">
          <Modal.Header>
            <Modal.Title>Update similar transactions</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-flex t-gap-6 t-flex-col t-text-body">
            <span>
              {transaction_category_uuid ? (
                <>
                  We found {similar_merchant_txns_count} similar uncategorised
                  transactions for <b>{merchant_data_name}.</b>
                  <br />
                  Would you like to categorize all of them as
                  <b> “{transaction_category_name}” </b>?
                </>
              ) : (
                <>
                  We found {similar_merchant_txns_count} transactions for{" "}
                  <b>{merchant_data_name}</b> categorized as{" "}
                  <b> “{previous_assigned_category_name}”</b>.
                  <br />
                  Would you like to uncategorize all of them?
                </>
              )}
            </span>
            {isLoading ? (
              <Loader />
            ) : (
              <div id={infiniteScrollId} className="t-h-full t-overflow-y-auto">
                <InfiniteScroll
                  dataLength={transactions?.length || 0}
                  next={loadNext}
                  hasMore={total_pages > current_page}
                  scrollableTarget={infiniteScrollId}
                  className="t-space-y-5"
                  loader={
                    <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
                      <span className="t-flex t-origin-center t-animate-spin">
                        <LoadingIcon />
                      </span>
                      Loading...
                    </div>
                  }
                >
                  <Table.Content>
                    <Table.Head>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <Table.Row key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <Table.HeadCell
                              key={header.id}
                              style={{ width: `${header.getSize()}%` }}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </Table.HeadCell>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Head>
                    <Table.Body>
                      {table.getRowModel().rows.map((row) => (
                        <Table.Row key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Table.Cell
                                key={cell.id}
                                style={{ width: `${cell.column.getSize()}%` }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table.Content>
                </InfiniteScroll>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="t-flex t-gap-3 t-justify-end">
            <Button onClick={() => dispatch(closeSimilarTxnModal())}>
              Don't update
            </Button>
            <Button
              customType="primary"
              onClick={categorizeAll}
              isLoading={isGroupCategorising}
              disabled={isGroupCategorising}
            >
              {transaction_category_uuid ? "Categorise" : "Uncategorise"} all
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
