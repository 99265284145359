import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { Form, Formik } from "formik";
import { esignDocSchema } from "formValidations/EsignDocSchema";
import { useToast } from "hooks/useToast";
import { useGetAllChannelMemberQuery } from "store/apis/chat";
import {
  useCreateEnvelopeMutation,
  useLazyGetSignatureUrlQuery,
} from "store/apis/eSign";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import { useChannelStateContext } from "stream-chat-react";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const RequestSignModal = ({ close, isOpen }: ModalProps) => {
  const { alertToast } = useToast();
  const { channel } = useChannelStateContext();
  const [createEnvelope] = useCreateEnvelopeMutation();
  const [getSignatureUrl] = useLazyGetSignatureUrlQuery();

  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel?.id!,
    },
    {
      skip: !channel?.id || !isOpen,
    }
  );

  const groupId = currentChannelData?.company_group?.uuid;

  const { data: members, isLoading } = useGetAllChannelMemberQuery(
    {
      groupId: groupId!,
      channelId: channel?.id!,
    },
    {
      skip: !channel?.id || !groupId,
    }
  );

  const onSubmit = async ({
    channelUrl,
    file,
    groupId,
    signerProfileIds,
  }: {
    groupId: string;
    channelUrl: string;
    signerProfileIds: string[];
    file: FileType | null;
  }) => {
    try {
      const fileToSign =
        "uuid" in file! ? { file_id: file.uuid } : { file: file };

      const { uuid } = await createEnvelope({
        groupId: groupId,
        signerProfileIds: signerProfileIds.join(","),
        channelUrl,
        ...fileToSign,
      }).unwrap();

      const { signature_url } = await getSignatureUrl({
        groupId: groupId!,
        envelopeId: uuid,
        callbackUrl: window.location.href,
        getSenderView: true,
      }).unwrap();
      close();
      window.open(signature_url, "_self");
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        initialValues={{
          groupId: groupId!,
          channelUrl: channel?.id!,
          signerProfileIds: [],
          file: null,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validationSchema={esignDocSchema}
      >
        {({ submitForm, isSubmitting, values: { file }, isValid }) => (
          <Modal.Content useCustomOverlay>
            <Modal.Header>
              <Modal.Title>Request sign</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-gap-4 t-flex-col">
                <FileInput
                  withForm
                  name="file"
                  label="Esign document"
                  file={file}
                  accept={{
                    "application/pdf": [".pdf"],
                  }}
                  withInkleDocs
                  groupId={groupId}
                />
                <Combobox
                  required
                  name="signerProfileIds"
                  placeholder="Select signers"
                  withForm
                  isMulti
                  label="Signers"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  options={members?.map((member) => ({
                    label: member.name,
                    value: member.profile_id,
                  }))}
                  menuPortalTarget={document.body}
                />
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button disabled={isSubmitting} onClick={close}>
                Cancel
              </Button>
              <Button
                customType="primary"
                onClick={submitForm}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid || !Boolean(file)}
              >
                Send
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};
