import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { SliderNavigation } from "components/DesignSystem/SliderNavigation/SliderNavigation";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { Cross } from "components/icons/Cross";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetRuleByIdQuery } from "store/apis/ruleEngine";
import { closeSlider, openSlider } from "store/slices/ruleEngine";
import { RootState } from "store/store";
import { Actions, ValueType } from "./RuleEngine";

const InfoItem = ({
  label,
  children,
}: {
  label: string | ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <Label className="!t-pb-0">{label}</Label>
      <span className="t-text-subtext">{children}</span>
    </div>
  );
};

const useSilderRule = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { previewRuleId } = useSelector((store: RootState) => store.ruleEngine);

  return useGetRuleByIdQuery(
    { entityId, groupId, ruleId: previewRuleId },
    { skip: !groupId || !entityId || !previewRuleId }
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const { data } = useSilderRule();
  const { name } = data || {};

  const { previewRuleId, allRuleIds } = useSelector(
    (store: RootState) => store.ruleEngine
  );

  const close = () => {
    dispatch(closeSlider());
  };

  const currentRuleIndex = allRuleIds.findIndex(
    (ruleId) => ruleId === previewRuleId
  );

  const dataLength = allRuleIds.length;

  const goToNext = () => {
    if (currentRuleIndex + 1 < allRuleIds.length) {
      dispatch(openSlider(allRuleIds[currentRuleIndex + 1]));
    }
  };

  const goToPrev = () => {
    if (currentRuleIndex > 0) {
      dispatch(openSlider(allRuleIds[currentRuleIndex - 1]));
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          goToPrev();
          return;

        case "ArrowRight":
          goToNext();
          return;

        default:
          return;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentRuleIndex]);

  return (
    <div className="t-w-full t-px-5 t-py-3 t-border t-border-solid t-border-b t-border-l-0 t-border-t-0 t-border-r-0 t-border-neutral-0 t-bg-surface t-flex t-justify-between t-sticky t-top-0 t-z-header">
      <div className="t-flex t-gap-2 t-items-center t-text-subtitle-sm">
        {name}
      </div>
      <div className="t-flex t-gap-2 t-items-center">
        <SliderNavigation
          dataLength={dataLength}
          currentIndex={currentRuleIndex}
          onPrev={goToPrev}
          onNext={goToNext}
        />
        <Actions ruleId={previewRuleId} />
        <Button customType="ghost_icon" size="small" onClick={close}>
          <Cross color="currentColor" />
        </Button>
      </div>
    </div>
  );
};

const SliderBody = () => {
  const { data, isLoading, isSuccess } = useSilderRule();
  const {
    name,
    end_date,
    start_date,
    conditions,
    category,
    apply_to_future_txns,
    apply_to_txns_with_no_category,
  } = data || {};

  return (
    <Async.Root {...{ isEmpty: false, isLoading, isSuccess }}>
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-flex-col">
          <Header />

          <div className="t-p-5 t-flex t-gap-4 t-flex-col t-pb-24">
            <SliderAccordion.Root
              type="multiple"
              defaultValue={["DETAILS", "CONDITIONS", "CATEGORY"]}
              className="t-flex t-gap-4 t-flex-col"
            >
              <SliderAccordion.Item value="DETAILS">
                <SliderAccordion.Trigger>Details</SliderAccordion.Trigger>
                <SliderAccordion.Content>
                  <span className="t-flex t-gap-16 t-justify-between">
                    <InfoItem label="Rule name">{name}</InfoItem>
                    <InfoItem label="Duration">
                      {dayjs(start_date).format(DD_MMM_YYYY)} to{" "}
                      {dayjs(end_date).format(DD_MMM_YYYY)}
                    </InfoItem>
                  </span>
                </SliderAccordion.Content>
              </SliderAccordion.Item>

              <SliderAccordion.Item value="CONDITIONS">
                <SliderAccordion.Trigger>Conditions</SliderAccordion.Trigger>
                <SliderAccordion.Content>
                  <span className="t-flex t-gap-4 t-flex-col">
                    {conditions?.map(
                      (
                        {
                          condition_operand,
                          condition_operator,
                          value,
                          value_type,
                          uuid,
                        },
                        i
                      ) => (
                        <InfoItem label={`Condition ${i + 1}`} key={uuid}>
                          {condition_operand.name}
                          <span className="t-lowercase">
                            {" "}
                            {condition_operator.name}
                          </span>
                          <ValueType value={value} value_type={value_type} />
                        </InfoItem>
                      )
                    )}
                  </span>
                </SliderAccordion.Content>
              </SliderAccordion.Item>

              <SliderAccordion.Item value="CATEGORY">
                <SliderAccordion.Trigger>Category</SliderAccordion.Trigger>
                <SliderAccordion.Content>
                  <span className="t-flex t-gap-6 t-flex-col">
                    <InfoItem label="Category">{category?.name}</InfoItem>

                    {apply_to_future_txns && (
                      <Checkbox
                        label="Apply to future transactions."
                        checked={apply_to_future_txns}
                        disabled
                      />
                    )}

                    {apply_to_txns_with_no_category && (
                      <Checkbox
                        label="Apply to transactions with no category."
                        checked={apply_to_txns_with_no_category}
                        disabled
                      />
                    )}
                  </span>
                </SliderAccordion.Content>
              </SliderAccordion.Item>
            </SliderAccordion.Root>
          </div>
        </div>
      </Async.Success>
    </Async.Root>
  );
};

export const RuleSlider = () => {
  const dispatch = useDispatch();

  const { isSliderOpen, previewRuleId } = useSelector(
    (store: RootState) => store.ruleEngine
  );

  const close = () => {
    dispatch(closeSlider());
  };

  return (
    <Slider open={isSliderOpen} onClose={close} withOverlay type="fixed">
      <SliderBody key={previewRuleId} />
    </Slider>
  );
};
