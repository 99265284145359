import { BYOA, PAYG, PRO, STANDARD } from "constants/subscriptionType";
import QueryString from "qs";
import { BOOKKEEPING_MONTHLY_SUMMARY_STATUS } from "types/contants/bookkeepingMonthlySummaryStatus";
import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";
import { StringOrTemplateHeader } from "react-table-8.10.7";
import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";

export type TransactionMonthlySummaryCard = {
  current_plan_name: typeof PRO | typeof PAYG | typeof BYOA | typeof STANDARD;
  season: {
    year: number;
    uuid: string;
  };
  monthly_summary: {
    uuid: string;
    date: string;
    end_date: null | string;
  };
  assigned_agent: null | {
    name: string;
    profile_id: string;
    agent_id: string;
    team: string;
  };
  status: BOOKKEEPING_MONTHLY_SUMMARY_STATUS;
  is_due: boolean;
  is_historical: boolean;
};
export type TransactionMonthlySummaryCardsForEntity = {
  transaction_monthly_summaries: TransactionMonthlySummaryCard[];
};
export type TransactionMonthlySummaryCards = {
  group: {
    name: string;
    uuid: string;
  };
  entity: {
    name: string;
    uuid: string;
    country_code: string;
  };
  current_plan_name: typeof PRO | typeof PAYG | typeof BYOA | typeof STANDARD;
  current_assigned_agent: null | {
    name: string;
    profile_id: string;
    agent_id: string;
    team: string;
  };
  due_amount: number;
  ongoing_since: string;
  completed_till: string;
};

type GroupMonthlyClosing = {
  transaction_monthly_summary_cards: TransactionMonthlySummaryCards[];
} & Pagination;

type subscriptionValidType = "STANDARD" | "BYOA" | "PAYG" | "PRO";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    groupMonthlyClosing: build.query<
      GroupMonthlyClosing,
      {
        searchTerm: string | null;
        companyGroupIds?: string;
        pageNum?: number;
        subscriptionPlanIds?: string;
        seasons?: string;
        statuses?: string;
        assignees?: string;
        showInProgress: boolean;
        teams?: string;
        subscriptionPlans?: string;
      }
    >({
      query: ({
        searchTerm,
        companyGroupIds,
        pageNum,
        subscriptionPlanIds,
        seasons,
        statuses,
        assignees,
        showInProgress,
        teams,
        subscriptionPlans,
      }) => {
        let queryUrl = QueryString.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
            company_group_ids: companyGroupIds || null,
            seasons: seasons || null,
            statuses: statuses || null,
            assignees: assignees || null,
            subscription_plan_ids: subscriptionPlanIds || null,
            show_inprogress: showInProgress,
            service_team: teams || null,
            subscription_plans: subscriptionPlans || null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/all_monthly_closing/${queryUrl}`,
        };
      },
      providesTags: (result) => {
        return result ? [{ type: "BOOKS_CRM" }] : [];
      },
    }),
    bookkeepingCRMFilterOptions: build.query<
      {
        subscription_plans: subscriptionValidType[];
        teams: (typeof INKLE_TEAM_AND_CPA_TEAM)[];
        assignees: {
          name: string;
          profile_id: string;
        }[];
      },
      void
    >({
      query: () => {
        return {
          url: `/api/inkle/bookkeeping/crm_filter_options/`,
        };
      },
    }),
    groupMonthlyClosingSingle: build.query<
      TransactionMonthlySummaryCards,
      {
        entityId: string;
        showInProgress: boolean;
      }
    >({
      query: ({ entityId, showInProgress }) => {
        return {
          url: `/api/inkle/bookkeeping/grouped_monthly_closing_details/${entityId}?show_inprogress=${showInProgress}`,
        };
      },
    }),
    getMonthlyClosingCardsForEntity: build.query<
      TransactionMonthlySummaryCardsForEntity,
      {
        entityId: string;
        showInProgress: boolean;
      }
    >({
      query: ({ entityId, showInProgress }) => {
        let queryUrl = QueryString.stringify(
          {
            show_inprogress: showInProgress,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/monthly_closing_cards/${entityId}/${queryUrl}`,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGroupMonthlyClosingQuery,
  useBookkeepingCRMFilterOptionsQuery,
  useGroupMonthlyClosingSingleQuery,
  useGetMonthlyClosingCardsForEntityQuery,
} = extendedApi;
