export const PaperPlaneRight = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="PaperPlaneRight">
      <path
        id="Vector"
        d="M21.714 11.4569L5.91712 2.61626C5.65391 2.46277 5.35082 2.39147 5.04679 2.41151C4.74275 2.43156 4.45165 2.54204 4.21087 2.72876C3.96497 2.92277 3.78643 3.18932 3.70061 3.49055C3.61478 3.79179 3.62603 4.1124 3.73275 4.40689L6.36712 11.7663C6.39377 11.8396 6.44213 11.9032 6.50577 11.9484C6.56941 11.9936 6.64531 12.0184 6.72337 12.0194H13.4921C13.6867 12.0163 13.8753 12.0872 14.0197 12.2177C14.1641 12.3482 14.2537 12.5286 14.2702 12.7225C14.2767 12.8249 14.262 12.9275 14.2272 13.0239C14.1924 13.1204 14.1382 13.2087 14.0679 13.2834C13.9976 13.3581 13.9127 13.4176 13.8185 13.4582C13.7243 13.4987 13.6228 13.5196 13.5202 13.5194H6.72337C6.64531 13.5204 6.56941 13.5452 6.50577 13.5904C6.44213 13.6356 6.39377 13.6991 6.36712 13.7725L3.73275 21.1319C3.6537 21.3586 3.62988 21.6009 3.66327 21.8387C3.69666 22.0764 3.78629 22.3028 3.92471 22.499C4.06314 22.6952 4.24637 22.8555 4.45919 22.9667C4.67201 23.0778 4.90827 23.1366 5.14837 23.1381C5.40387 23.137 5.6551 23.0726 5.87962 22.9506L21.714 14.0819C21.946 13.9501 22.1389 13.7593 22.2731 13.5287C22.4073 13.2982 22.478 13.0362 22.478 12.7694C22.478 12.5026 22.4073 12.2406 22.2731 12.01C22.1389 11.7795 21.946 11.5886 21.714 11.4569Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
