import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import QueryString from "qs";

const initialState = {
  filters: {
    cashFlow: {
      name: "Cash flow",
      value: null,
      type: "amount",
    },
    minAmount: { name: "Min Amount", value: null, type: "amount" },
    maxAmount: { name: "Max Amount", value: null, type: "amount" },
    startDate: {
      name: "Since",
      value: null,
      type: "transactionDate",
    },
    endDate: {
      name: "Till",
      value: null,
      type: "transactionDate",
    },
    accountingMethod: {
      name: "",
      value: null,
      type: "accounting",
    },
    hideZero: {
      name: "Hide zero balance accounts",
      value: null,
      type: "others",
    },
    categoryIds: {
      name: "Categories",
      value: null,
      type: "category",
      fixed: false,
    },
  },
};

export type FilterName =
  | "cashFlow"
  | "minAmount"
  | "maxAmount"
  | "startDate"
  | "endDate"
  | "accountingMethod"
  | "hideZero"
  | "categoryIds";

export const ledgerFilter = createSlice({
  name: "ledgerFilter",
  initialState,
  reducers: {
    setLedgerFilter: (state, action: PayloadAction<QueryString.ParsedQs>) => {
      const payload = action.payload;

      const updatedValues = Object.keys(payload).reduce((acc, el) => {
        const value = payload[el] === "true" ? true : payload[el];
        const newState = {
          ...initialState.filters?.[el as FilterName],
          value: value,
        };

        return { ...acc, [el]: { ...newState } };
      }, {});

      state.filters = {
        ...state.filters,
        ...updatedValues,
      };
    },
  },
});

export const { setLedgerFilter } = ledgerFilter.actions;

export default ledgerFilter.reducer;
