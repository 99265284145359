export const InklePracticeSymbol = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5723_7835)">
      <mask
        id="mask0_5723_7835"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="24"
        height="24"
      >
        <path d="M40 16H16V40H40V16Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5723_7835)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28 20.0374C27.1329 20.0374 26.4299 20.7403 26.4299 21.6075H22.3925C22.3925 18.5106 24.9031 16 28 16C31.097 16 33.6075 18.5106 33.6075 21.6075C33.6075 24.7044 31.097 27.2149 28 27.2149V23.1776C28.8671 23.1776 29.5701 22.4746 29.5701 21.6075C29.5701 20.7403 28.8671 20.0374 28 20.0374ZM35.9626 28C35.9626 27.1329 35.2596 26.4299 34.3925 26.4299V22.3925C37.4895 22.3925 40 24.9031 40 28C40 31.097 37.4895 33.6075 34.3925 33.6075C31.2956 33.6075 28.785 31.097 28.785 28H32.8224C32.8224 28.8671 33.5254 29.5701 34.3925 29.5701C35.2596 29.5701 35.9626 28.8671 35.9626 28ZM21.6075 29.5701C20.7403 29.5701 20.0374 28.8671 20.0374 28C20.0374 27.1329 20.7403 26.4299 21.6075 26.4299C22.4746 26.4299 23.1776 27.1329 23.1776 28H27.2149C27.2149 24.9031 24.7044 22.3925 21.6075 22.3925C18.5106 22.3925 16 24.9031 16 28C16 31.097 18.5106 33.6075 21.6075 33.6075V29.5701ZM28 35.9626C28.8671 35.9626 29.5701 35.2596 29.5701 34.3925H33.6075C33.6075 37.4895 31.097 40 28 40C24.9031 40 22.3925 37.4895 22.3925 34.3925C22.3925 31.2956 24.9031 28.785 28 28.785V32.8224C27.1329 32.8224 26.4299 33.5254 26.4299 34.3925C26.4299 35.2596 27.1329 35.9626 28 35.9626Z"
          fill="url(#paint0_linear_5723_7835)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5723_7835"
        x1="16"
        y1="28"
        x2="40"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <clipPath id="clip0_5723_7835">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
);
