import classNames from "classnames";
import { NEW_FOLDER_MESSAGE } from "constants/documents";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import Prevfolder from "static/images/Prevfolder.svg";
import {
  useCreateFolderMutation,
  useGetFolderContentQuery,
} from "store/apis/documents";
import { BackendError } from "types/utils/error";
import { FileIcon } from "utils/fileTypeIcon";
import Async from "./DesignSystem/AsyncComponents/Async";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";
import { AddNewFolder } from "./documents/documentsModal/AddNewFolder";

type SelectFolderForUploadProp = {
  showModal: boolean;
  closeModal: () => void;
  onUpload: (v: string) => void;
};

export const SelectFolderForUpload = ({
  showModal,
  closeModal,
  onUpload,
}: SelectFolderForUploadProp) => {
  const { uuid: groupId, documents_group_id } = useCurrentGroupContext();
  const [clickedFolder, setClickedFolder] = useState({
    uuid: "",
    name: "",
  });
  const [folder, setFolder] = useState("");

  const { alertToast, successToast } = useToast();
  const [createFolder] = useCreateFolderMutation();

  const folderId = folder || documents_group_id;

  const {
    data: folderData,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useGetFolderContentQuery(
    {
      groupId,
      folderId: folderId,
    },
    { skip: !folderId }
  );
  const { subfolders: folderContent = [], parents_list = [] } =
    folderData || {};
  const isEmptyFolder = folderContent.length === 0 && isSuccess;

  const {
    [parents_list.length - 1]: currentFolder,
    [parents_list.length - 2]: parentFolder,
  } = parents_list;

  const openSelectedFolder = (
    refetchData: boolean,
    folder: { uuid: string; name: string }
  ) => {
    if (refetchData) {
      setFolder(folder.uuid);
    }
    setClickedFolder(folder);
  };

  const onFileUpload = () => {
    onUpload(clickedFolder.uuid);
    closeModal();
  };

  const newFolderModal = useModal();

  const onCreateFolder = async ({ folder_name }: { folder_name: string }) => {
    try {
      const { uuid, name } = await createFolder({
        folder_name,
        groupId,
        parentFolderId: folderId,
      }).unwrap();
      successToast({ message: NEW_FOLDER_MESSAGE });
      newFolderModal.close();
      refetch();
      setClickedFolder({ uuid, name });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const isBaseFolder = currentFolder?.uuid === documents_group_id;

  return (
    <>
      <Modal.Root open={showModal} onOpenChange={closeModal}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <div className="t-flex t-items-center">
                {!isBaseFolder && (
                  <Button
                    onClick={() =>
                      openSelectedFolder(true, {
                        uuid: parentFolder?.uuid,
                        name: parentFolder?.name,
                      })
                    }
                    customType="ghost_icon"
                    size="small"
                  >
                    <img
                      src={Prevfolder}
                      className="t-cursor-pointer"
                      alt="Back"
                    />
                  </Button>
                )}
                Upload to: &nbsp;
                <span className="t-max-w-80 t-truncate">
                  {clickedFolder.name || currentFolder?.name}
                </span>
              </div>
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Async.Root
              isEmpty={isEmptyFolder}
              isLoading={isLoading}
              isSuccess={isSuccess}
            >
              <Async.Empty>
                <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center t-w-full t-text-neutral-40 t-h-80">
                  This folder is empty
                  <Button
                    customType="secondary"
                    size="small"
                    onClick={newFolderModal.open}
                  >
                    Create folder
                  </Button>
                </div>
              </Async.Empty>
              <Async.Success>
                <div className="t-flex t-h-80 t-flex-col t-items-start t-gap-1 t-select-none">
                  {folderContent.map(({ uuid, name, file_type }) => {
                    return (
                      <button
                        disabled={isFetching}
                        onClick={() =>
                          openSelectedFolder(false, { uuid, name })
                        }
                        onDoubleClick={() =>
                          openSelectedFolder(true, { uuid, name })
                        }
                        key={uuid}
                        className={classNames(
                          "all:unset t-flex t-w-full t-cursor-pointer t-items-center t-gap-2 t-border-solid t-border-surface-transparent t-px-2 t-py-1 hover:t-rounded hover:t-bg-purple-10 focus-visible:t-rounded focus-visible:t-bg-purple-10 focus-visible:t-outline focus-visible:t-outline-purple-30",
                          {
                            "t-rounded t-border-solid t-border-purple-30 t-bg-purple-10":
                              clickedFolder.uuid === uuid,
                          }
                        )}
                      >
                        <FileIcon
                          fileType={file_type}
                          width="32px"
                          height="32px"
                        />
                        <div className="t-max-w-[25rem] t-truncate t-text-body-sm">
                          {name}
                        </div>
                      </button>
                    );
                  })}
                </div>
              </Async.Success>
            </Async.Root>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-between t-items-center">
            <Button
              customType="secondary"
              size="small"
              onClick={newFolderModal.open}
            >
              Create folder
            </Button>
            <Button
              customType="primary"
              size="small"
              disabled={!clickedFolder.name}
              onClick={onFileUpload}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      {newFolderModal.isOpen && (
        <AddNewFolder
          isOpen={newFolderModal.isOpen}
          close={newFolderModal.close}
          createFolder={onCreateFolder}
        />
      )}
    </>
  );
};
