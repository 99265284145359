import {
  OPEN_ITEM_CUSTOM_TYPE,
  REQUESTED_INFO_CUSTOM_TYPE,
} from "constants/chatType";
import { UploadCSV } from "types/Models/openItem";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    addOpenItems: build.mutation<
      UploadCSV,
      { groupId: string; file?: File; transactionIds?: string }
    >({
      query: ({ groupId, file, transactionIds }) => {
        const formData = new FormData();
        if (transactionIds) {
          formData.append("transaction_ids", transactionIds);
        }
        if (file) {
          formData.append("files", file);
        }
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item/`,
          method: "POST",
          body: formData,
        };
      },
    }),

    getOpenItems: build.query<UploadCSV, { groupId: string; csvId: string }>({
      query: ({ groupId, csvId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item/?csv_id=${csvId}`,
        };
      },
      providesTags: () => [{ type: "OPEN_ITEMS" }],
    }),

    sendClarificationRequest: build.mutation<
      UploadCSV,
      {
        groupId: string;
        channelUrl: string;
        csvId: string;
        customType:
          | typeof REQUESTED_INFO_CUSTOM_TYPE
          | typeof OPEN_ITEM_CUSTOM_TYPE;
      }
    >({
      query: ({ groupId, channelUrl, csvId, customType }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item-request/`,
          method: "POST",
          body: {
            channel_url: channelUrl,
            csv_id: csvId,
            custom_type: customType,
          },
        };
      },
    }),

    deleteOpenItemTxn: build.mutation<
      UploadCSV,
      {
        groupId: string;
        openItemId: string;
      }
    >({
      query: ({ groupId, openItemId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item-request/?open_item_id=${openItemId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: "OPEN_ITEMS" }],
    }),

    uploadOpenItemInvoice: build.mutation<
      UploadCSV,
      { groupId: string; openItemId: string; file: File }
    >({
      query: ({ groupId, openItemId, file }) => {
        const formData = new FormData();
        formData.append("open_item_id", openItemId);
        formData.append("file", file);
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item/`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: () => [{ type: "OPEN_ITEMS" }],
    }),

    deleteOpenItemInvoice: build.mutation<
      UploadCSV,
      { groupId: string; openItemId: string }
    >({
      query: ({ groupId, openItemId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item/?open_item_id=${openItemId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: "OPEN_ITEMS" }],
    }),

    addClarification: build.mutation<
      UploadCSV,
      {
        groupId: string;
        openItemId: string;
        clarification?: string;
        informationRequest?: string;
      }
    >({
      query: ({ groupId, openItemId, clarification, informationRequest }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item-request/`,
          method: "PATCH",
          body: {
            open_item_id: openItemId,
            clarification,
            information_request: informationRequest,
          },
        };
      },
      invalidatesTags: () => [{ type: "OPEN_ITEMS" }],
    }),

    submitClarification: build.mutation<
      UploadCSV,
      { groupId: string; messageId: string; csvId: string }
    >({
      query: ({ groupId, messageId, csvId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/open-item-request/`,
          method: "PUT",
          body: {
            csv_id: csvId,
            message_id: messageId,
          },
        };
      },
      invalidatesTags: () => [{ type: "OPEN_ITEMS" }],
    }),
  }),
});

export const {
  useAddOpenItemsMutation,
  useGetOpenItemsQuery,
  useDeleteOpenItemTxnMutation,
  useSendClarificationRequestMutation,
  useUploadOpenItemInvoiceMutation,
  useAddClarificationMutation,
  useSubmitClarificationMutation,
  useDeleteOpenItemInvoiceMutation,
} = extendedApi;
