import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import React from "react";
import {
  useApproveUserProfileMutation,
  useRejectUserProfileMutation,
} from "store/apis/salesCrmProfile";

export const ApproveModal = ({
  showModal,
  setShowModal,
  approveUserProfileId,
}: any) => {
  const { alertToast } = useToast();
  const [approveuserProfile, { isLoading: isApproveBtnLoading }] =
    useApproveUserProfileMutation();

  const handleApprove = async () => {
    try {
      await approveuserProfile(approveUserProfileId).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowModal(false);
  };
  return (
    <Modal.Root open={showModal} onOpenChange={setShowModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <span className="t-text-subtitle">Approve the user?</span>
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-align-middle t-text-button t-leading-4">
            Are you sure that you want to approve the user?
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button
            customType="primary"
            isLoading={isApproveBtnLoading}
            onClick={handleApprove}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export const RejectModal = ({
  showRejectModal,
  setShowRejectModal,
  approveUserProfileId,
}: any) => {
  const { alertToast } = useToast();
  const [rejectUserProfile, { isLoading: isRejectBtnLoading }] =
    useRejectUserProfileMutation();

  const rejectUser = async () => {
    try {
      await rejectUserProfile(approveUserProfileId).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowRejectModal(false);
  };

  return (
    <Modal.Root
      open={showRejectModal}
      onOpenChange={() => setShowRejectModal(false)}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <span className="t-text-subtitle">Reject the user?</span>
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-align-middle t-text-button t-leading-4">
            Are you sure that you want to reject the user?
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={() => setShowRejectModal(false)}>Cancel</Button>
          <Button
            customType="primary"
            isLoading={isRejectBtnLoading}
            onClick={rejectUser}
          >
            Reject
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
