import { boolean, object, string } from "yup";

export const addAdhocTaskSchema = object({
  title: string().required("Please enter filing name"),
  adhoc_scope_of_work: string().required("Please enter scope of work"),
  payment_amount: string().required("Please enter price"),
  base_task_template_id: string().required("Please select entity"),
  entity: string().required("Please select Entity"),
});

export const addTaskSchema = object({
  entity: string().required("Please select Entity"),
  default_task_template: boolean(),
  season: string().when("default_task_template", {
    is: false,
    then: string().required("Please select Season"),
    otherwise: string().notRequired(),
  }),
  state_id: string().when("has_states", {
    is: true,
    then: string().required("Please select state"),
    otherwise: string().notRequired(),
  }),
});
