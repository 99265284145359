export const Compliance = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6240_1603)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.995 3.00501C13.2684 3.27838 13.2684 3.7216 12.995 3.99496L3.99502 12.995C3.72166 13.2683 3.27844 13.2683 3.00507 12.995C2.73171 12.7216 2.73171 12.2784 3.00507 12.005L12.0051 3.00501C12.2784 2.73165 12.7217 2.73165 12.995 3.00501Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.75005 3.69999C4.17015 3.69999 3.70005 4.17009 3.70005 4.74999C3.70005 5.32989 4.17015 5.79999 4.75005 5.79999C5.32995 5.79999 5.80005 5.32989 5.80005 4.74999C5.80005 4.17009 5.32995 3.69999 4.75005 3.69999ZM2.30005 4.74999C2.30005 3.39689 3.39695 2.29999 4.75005 2.29999C6.10315 2.29999 7.20005 3.39689 7.20005 4.74999C7.20005 6.10309 6.10315 7.19999 4.75005 7.19999C3.39695 7.19999 2.30005 6.10309 2.30005 4.74999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.25 10.2C10.6701 10.2 10.2 10.6701 10.2 11.25C10.2 11.8299 10.6701 12.3 11.25 12.3C11.8299 12.3 12.3 11.8299 12.3 11.25C12.3 10.6701 11.8299 10.2 11.25 10.2ZM8.80005 11.25C8.80005 9.89689 9.89695 8.79999 11.25 8.79999C12.6031 8.79999 13.7 9.89689 13.7 11.25C13.7 12.6031 12.6031 13.7 11.25 13.7C9.89695 13.7 8.80005 12.6031 8.80005 11.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6240_1603">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
