export const InkleTaxCreditSymbolSmall = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path
      d="M6.36575 3.7767C6.36575 5.16724 5.23849 6.29449 3.84796 6.29449C2.45742 6.29449 1.33017 5.16724 1.33017 3.7767C1.33017 2.38616 2.45742 1.25891 3.84796 1.25891C5.23849 1.25891 6.36575 2.38616 6.36575 3.7767Z"
      fill="url(#paint0_linear_2_2143)"
    />
    <path
      d="M11.4013 3.7767C11.4013 5.16724 10.2741 6.29449 8.88354 6.29449C7.49301 6.29449 6.36575 5.16724 6.36575 3.7767C6.36575 2.38616 7.49301 1.25891 8.88354 1.25891C10.2741 1.25891 11.4013 2.38616 11.4013 3.7767Z"
      fill="url(#paint1_linear_2_2143)"
    />
    <path
      d="M11.4013 8.81228C11.4013 10.2028 10.2741 11.3301 8.88354 11.3301C7.49301 11.3301 6.36575 10.2028 6.36575 8.81228C6.36575 7.42175 7.49301 6.29449 8.88354 6.29449C10.2741 6.29449 11.4013 7.42175 11.4013 8.81228Z"
      fill="url(#paint2_linear_2_2143)"
    />
    <path
      d="M6.36575 8.81228C6.36575 10.2028 5.23849 11.3301 3.84796 11.3301C2.45742 11.3301 1.33017 10.2028 1.33017 8.81228C1.33017 7.42175 2.45742 6.29449 3.84796 6.29449C5.23849 6.29449 6.36575 7.42175 6.36575 8.81228Z"
      fill="url(#paint3_linear_2_2143)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_2143"
        x1="1.33017"
        y1="3.7767"
        x2="6.36575"
        y2="3.7767"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_2143"
        x1="6.36575"
        y1="3.7767"
        x2="11.4013"
        y2="3.7767"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_2143"
        x1="6.36575"
        y1="8.81228"
        x2="11.4013"
        y2="8.81228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2_2143"
        x1="1.33017"
        y1="8.81228"
        x2="6.36575"
        y2="8.81228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
