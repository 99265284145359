import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useRevokeTeamMemberInviteMutation } from "store/apis/teamSetting";
import { useEditVendorsMutation } from "store/apis/vendors";

type RevokeInviteProps = {
  show: boolean;
  closeModal: () => void;
  groupId: string;
  email: string;
};

export const RevokeInvite = ({
  show,
  closeModal,
  groupId,
  email,
}: RevokeInviteProps) => {
  const { alertToast, successToast } = useToast();
  const [revokeInvite, { isLoading }] = useRevokeTeamMemberInviteMutation();

  const revokeMemberInvite = async () => {
    try {
      await revokeInvite({ groupId, email: email }).unwrap();
      successToast({ message: "User invite has been revoked" });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Revoke invite</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-100">
              Are you sure you want to revoke the invite?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={isLoading}
                customType="danger"
                isLoading={isLoading}
                onClick={revokeMemberInvite}
              >
                Revoke
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
