export const Raise = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6503_1843)">
      <path
        d="M6.5 14C8.433 14 10 11.3137 10 8C10 4.68629 8.433 2 6.5 2C4.567 2 3 4.68629 3 8C3 11.3137 4.567 14 6.5 14Z"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5 2H9.5C11.4331 2 13 4.6875 13 8C13 11.3125 11.4331 14 9.5 14H6.5"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.10875 4H12.1087"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 8H13"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.10875 12H12.1087"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6503_1843">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
