import {
  ASSET,
  EQUITY,
  EXPENSE,
  LIABILITY,
  REVENUE,
} from "constants/bookkeeping";

export const associatedReport = (value: number | string) => {
  const isBalanceSheetAssociated = [ASSET, LIABILITY, EQUITY].includes(
    Number(value)
  );

  const isIncomeStatementAssociated = [REVENUE, EXPENSE].includes(
    Number(value)
  );
  return { isIncomeStatementAssociated, isBalanceSheetAssociated };
};
