import { Cross } from "components/icons/Cross";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { deleteCustomAttachment } from "store/slices/reviewAndBalancePayment";
import { FileIcon } from "utils/fileTypeIcon";

type ICustomFilePreviewCardProps = {
  name: string;
  extension: string;
  path: string;
};

export const CustomFilePreviewCard: FC<ICustomFilePreviewCardProps> = ({
  name,
  extension,
  path,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="t-group t-mt-2 t-py-2 t-px-3 t-flex t-gap-2 t-border t-border-solid t-border-purple-20 t-rounded t-relative">
      <FileIcon fileType={extension} width="40px" height="40px" />
      <div className="t-flex t-flex-col t-justify-between t-w-20">
        <div className="t-text-subtext-sm t-truncate t-w-full">{name}</div>
        <div className="t-text-caption t-text-neutral">{extension}</div>
      </div>
      <button
        className="all:unset t-h-5 t-w-5 t-rounded-full t-flex t-items-center t-justify-center t-bg-purple-20 t-absolute -t-top-[8px] -t-right-[8px]"
        onClick={() => dispatch(deleteCustomAttachment(path))}
      >
        <Cross />
      </button>
    </div>
  );
};
