import { FORBIDDEN_PREFIXES } from "components/teamMembers/forbiddenPrefix";
import { RESTRICTED_EMAILS } from "constants/magicLink";
import { object, string } from "yup";

export const signUp = object({
  first_name: string()
    .required("First name is required")
    .min(1, "Please enter valid first name"),
  last_name: string()
    .required("Last name is required")
    .min(1, "Please enter valid last name"),
  email: string()
    .email("Please enter a valid email id")
    .test({
      name: "prefix-check",
      message: "Please do not use a team email",
      test: (email) => {
        const name = email?.match(/[^@]+/g) || "";

        return !FORBIDDEN_PREFIXES.some((prefix) => prefix === name[0]);
      },
    })
    .test({
      name: "domain-check",
      message: "Please use a work email",
      test: (email) => {
        const name = email?.match(/[^@]+/g) || "";

        return !RESTRICTED_EMAILS.some((prefix) => prefix === name[1]);
      },
    })
    .required("Email is required"),
});
