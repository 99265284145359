import React from "react";

//utils
import checkEmptyString from "../../utils/checkEmptyString";

export default function RegAgentFilter({ filter, setFilter }) {
  function searchQuery(e) {
    setFilter(checkEmptyString(e));
  }

  return (
    <div className="headerDiv">
      <span className="searchTable m-4">
        <input
          value={filter || ""}
          onChange={searchQuery}
          placeholder="  Search"
        />
      </span>
    </div>
  );
}
