export const Cross = ({ stroke = 1.5, color = "#585170", size = "16" }) => (
  <>
    <span className="t-sr-only">Cross Icon</span>
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7866 3.28027L3.78662 12.2803"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7866 12.2803L3.78662 3.28027"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
