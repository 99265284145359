export const Warning = ({
  color = "#FFCC00",
  size = "24",
}: {
  color?: string;
  size?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22.1906 17.625L13.95 3.375C13.7527 3.03228 13.4686 2.74761 13.1262 2.54966C12.7839 2.35172 12.3954 2.2475 12 2.2475C11.6045 2.2475 11.2161 2.35172 10.8737 2.54966C10.5314 2.74761 10.2473 3.03228 10.05 3.375L1.80936 17.625C1.60917 17.966 1.50279 18.3539 1.501 18.7493C1.49921 19.1447 1.60209 19.5335 1.79919 19.8763C1.99629 20.2191 2.28058 20.5036 2.62321 20.7009C2.96583 20.8983 3.35458 21.0015 3.74998 21H20.25C20.6454 21.0015 21.0341 20.8983 21.3768 20.7009C21.7194 20.5036 22.0037 20.2191 22.2008 19.8763C22.3979 19.5335 22.5008 19.1447 22.499 18.7493C22.4972 18.3539 22.3908 17.966 22.1906 17.625ZM11.25 9.75C11.25 9.55109 11.329 9.36032 11.4697 9.21967C11.6103 9.07902 11.8011 9 12 9C12.1989 9 12.3897 9.07902 12.5303 9.21967C12.671 9.36032 12.75 9.55109 12.75 9.75V13.5C12.75 13.6989 12.671 13.8897 12.5303 14.0303C12.3897 14.171 12.1989 14.25 12 14.25C11.8011 14.25 11.6103 14.171 11.4697 14.0303C11.329 13.8897 11.25 13.6989 11.25 13.5V9.75ZM12 18C11.7775 18 11.56 17.934 11.375 17.8104C11.19 17.6868 11.0458 17.5111 10.9606 17.3055C10.8755 17.1 10.8532 16.8738 10.8966 16.6555C10.94 16.4373 11.0472 16.2368 11.2045 16.0795C11.3618 15.9222 11.5623 15.815 11.7805 15.7716C11.9987 15.7282 12.2249 15.7505 12.4305 15.8356C12.6361 15.9208 12.8118 16.065 12.9354 16.25C13.059 16.435 13.125 16.6525 13.125 16.875C13.125 17.1734 13.0065 17.4595 12.7955 17.6705C12.5845 17.8815 12.2984 18 12 18Z"
        fill={color}
      />
    </svg>
  );
};
