export const InprogressPreparerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <g clip-path="url(#clip0_1718_11918)">
      <path
        d="M14.4331 14.5832C14.3892 14.6593 14.3261 14.7224 14.25 14.7663C14.174 14.8102 14.0878 14.8333 14 14.8332H1.99998C1.91225 14.8332 1.82609 14.81 1.75016 14.7661C1.67422 14.7221 1.61117 14.659 1.56735 14.583C1.52352 14.507 1.50047 14.4208 1.50049 14.3331C1.50051 14.2454 1.52361 14.1592 1.56748 14.0832C2.51935 12.4376 3.98623 11.2576 5.6981 10.6982C4.85133 10.1942 4.19344 9.42604 3.82547 8.51187C3.45749 7.59769 3.39978 6.58799 3.66119 5.63784C3.9226 4.68769 4.48868 3.84961 5.27249 3.25232C6.05631 2.65503 7.01452 2.33154 7.99998 2.33154C8.98543 2.33154 9.94365 2.65503 10.7275 3.25232C11.5113 3.84961 12.0774 4.68769 12.3388 5.63784C12.6002 6.58799 12.5425 7.59769 12.1745 8.51187C11.8065 9.42604 11.1486 10.1942 10.3019 10.6982C12.0137 11.2576 13.4806 12.4376 14.4325 14.0832C14.4765 14.1592 14.4997 14.2454 14.4998 14.3332C14.4999 14.4209 14.4769 14.5072 14.4331 14.5832Z"
        fill="#CFCDD6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1718_11918">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.833252)"
        />
      </clipPath>
    </defs>
  </svg>
);
