import { ReactNode } from "react";

type TaskDetailLabelValueProps = {
  label: string;
  value: ReactNode;
};

export const TaskDetailLabelValue = ({
  label,
  value,
}: TaskDetailLabelValueProps) => {
  return (
    <div className="t-flex t-justify-between t-items-center">
      <div className="t-text-body t-text-text-30">{label}</div>
      {value}
    </div>
  );
};
