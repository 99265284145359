export const Reports = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_1535)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.93713 0.469681C6.09259 0.602673 6.18209 0.797018 6.18209 1.0016L6.18207 5.62411C6.18207 6.01071 5.86867 6.32411 5.48207 6.32411L1.14728 6.3241C0.961493 6.3241 0.783329 6.25025 0.652032 6.1188C0.520734 5.98736 0.447075 5.80912 0.447279 5.62333C0.44812 4.85996 0.530391 4.21989 0.740577 3.63056C0.952153 3.03732 1.2777 2.53929 1.70501 2.04033L1.70502 2.04033C2.23789 1.41811 2.78662 1.03069 3.41565 0.775806C4.01966 0.531053 4.67566 0.419453 5.3737 0.310043C5.57581 0.278363 5.78167 0.336689 5.93713 0.469681ZM3.94142 2.07333C3.53312 2.23878 3.1651 2.48773 2.76836 2.95099C2.4152 3.36336 2.19684 3.71498 2.05922 4.10085C1.97485 4.33742 1.91509 4.60301 1.88098 4.9241L4.78208 4.92411L4.78209 1.83368C4.46123 1.89913 4.19004 1.97258 3.94142 2.07333Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.50503 1.50498C7.63633 1.3737 7.81441 1.29996 8.00008 1.29999C9.17394 1.30016 10.3272 1.60874 11.3442 2.19483C12.3613 2.78091 13.2066 3.62393 13.7953 4.63945C14.3841 5.65498 14.6958 6.80737 14.699 7.98123C14.7023 9.15509 14.3972 10.3092 13.8141 11.328C13.231 12.3468 12.3905 13.1946 11.3767 13.7863C10.3629 14.3781 9.21143 14.6931 8.03759 14.6999C6.86375 14.7066 5.70874 14.4049 4.68821 13.8248C3.61295 13.2136 1.70448 11.557 1.44872 8.07755C1.43446 7.88351 1.5016 7.69228 1.63404 7.54975C1.76648 7.40722 1.95227 7.32624 2.14684 7.32624H7.29997V1.99999C7.29997 1.81432 7.37374 1.63625 7.50503 1.50498ZM8.69997 2.74654V8.02624C8.69997 8.41284 8.38657 8.72624 7.99997 8.72624H2.93444C3.33586 11.1242 4.6953 12.2184 5.38004 12.6077C6.18731 13.0665 7.10098 13.3052 8.02954 13.2999C8.95811 13.2946 9.86897 13.0454 10.6709 12.5772C11.4729 12.1091 12.1378 11.4385 12.599 10.6326C13.0602 9.82668 13.3017 8.91372 13.2991 7.98515C13.2965 7.05658 13.0499 6.14498 12.5842 5.34165C12.1184 4.53833 11.4498 3.87146 10.6452 3.40785C10.0442 3.06151 9.3831 2.83768 8.69997 2.74654Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6445_1535">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
