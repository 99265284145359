import { BILLING } from "constants/billing";
import { AUTOFILL_ADDRESS_TYPE_TAG } from "constants/tagCategory";
import { useGetTagsForCategoryQuery } from "store/apis/billing";

export const useBillingtag = (): {
  uuid: string;
  tag_category: number;
  title: string;
  color_hex: string;
  bg_color_hex: string;
} => {
  const { data: tagsData = [] } = useGetTagsForCategoryQuery({
    tagType: AUTOFILL_ADDRESS_TYPE_TAG,
  });

  return (
    tagsData.find(({ title }) => title === BILLING) || {
      uuid: "",
      tag_category: 0,
      title: "",
      color_hex: "",
      bg_color_hex: "",
    }
  );
};
