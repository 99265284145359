import React from "react";

//components
import { Button } from "components/DesignSystem/Button/Button";

//icons
import NoMail from "../../static/images/NoMail.svg";

export const NoMails = ({ onClick, checkAnyFilterApplied }) => {
  return (
    <span className="empty-mailbox-conatiner">
      <img src={NoMail} alt="Empty mail Box" />
      {!checkAnyFilterApplied && (
        <Button
          size="regular"
          customType="primary"
          onClick={onClick}
          color="purple"
        >
          &nbsp;
          <i className="fas fa-plus" />
          &nbsp; Get started
        </Button>
      )}
    </span>
  );
};
