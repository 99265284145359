import React from "react";

export const Folder = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3344 19.5H3.69375C3.60265 19.5 3.51243 19.4821 3.42826 19.4472C3.34409 19.4123 3.26762 19.3612 3.20319 19.2968C3.13877 19.2324 3.08767 19.1559 3.05281 19.0717C3.01794 18.9876 3 18.8974 3 18.8063V7.5H20.25C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25V18.8344C21 19.0109 20.9299 19.1802 20.805 19.305C20.6802 19.4299 20.5109 19.5 20.3344 19.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7.5V5.25C3 5.05109 3.07902 4.86033 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H8.69062C8.78801 4.49966 8.88451 4.51853 8.9746 4.55553C9.06469 4.59253 9.14659 4.64694 9.21563 4.71563L12 7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
