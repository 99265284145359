import classNames from "classnames";
import { InputHTMLAttributes } from "react";

export type SearchProps = {
  customSize?: "small" | "regular" | "large";
  block?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const INPUT_TYPE_CLASSES = {
  /* @tw */
  SMALL: "t-h-8",
  /* @tw */
  REGULAR: "t-h-10",
  /* @tw */
  LARGE: "t-h-12",
};

export const Search = ({
  block = false,
  customSize = "small",
  ...props
}: SearchProps) => {
  return (
    <input
      {...props}
      placeholder={props.placeholder || "Search"}
      type="search"
      className={classNames(
        "t-rounded t-border t-border-solid t-border-neutral-0 t-bg-surface-lighter-grey t-bg-[url('static/images/SearchIcon.svg')] t-bg-[10px] t-bg-no-repeat t-px-3 t-pl-8 t-text-body t-font-normal t-text-text-30 hover:t-border-purple-30 hover:t-bg-surface-grey focus:t-border-purple focus:t-bg-surface t-outline-none",
        props.className,
        {
          "t-w-full": block,
          [INPUT_TYPE_CLASSES.SMALL]: customSize === "small",
          [INPUT_TYPE_CLASSES.REGULAR]: customSize === "regular",
          [INPUT_TYPE_CLASSES.LARGE]: customSize === "large",
        }
      )}
    />
  );
};
