import { Button } from "components/DesignSystem/Button/Button";
import { LongArrowRight } from "components/icons/LongArrowRight";
import React from "react";
import PreincorpPackage from "static/images/PreincorpPackage.svg";

export const PreIncorporationDiscount = ({
  onClick,
}: {
  onClick: React.DOMAttributes<HTMLDivElement>["onClick"];
}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="t-border t-border-solid t-rounded-lg t-p-5 t-border-blue-20 t-h-full t-w-full t-relative t-bg-blue-10"
    >
      <div className="t-flex t-gap-5">
        <div className="t-w-9/12 t-gap-4">
          <p className="t-text-h5 t-m-0 t-w-2/3 t-text-purple">20% discount</p>

          <div className="t-text-body t-mt-1 t-mb-3 t-w-9/12">
            on US incorporation by Inventus Law
          </div>

          <Button customType="secondary" size="small">
            Continue
            <LongArrowRight />
          </Button>
        </div>

        <img
          src={PreincorpPackage}
          alt="OnboardingIcon"
          className="t-absolute t-top-1/2 t-transform -t-translate-y-1/2 t-right-10"
        />
      </div>
    </div>
  );
};
