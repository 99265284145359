import { AddressAutofill } from "types/Models/addressAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { emptyApi } from "./emptyApi";

type AutoFillType = "addresses" | "authorized_persons";

export const practiceAutofillApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getPracticeAutofill: build.query<
      (PersonaAutofill & AddressAutofill)[],
      {
        serviceTeamId: string;
        autofillKey: AutoFillType;
        tagId?: string;
      }
    >({
      query: ({ serviceTeamId, autofillKey, tagId }) => {
        return {
          url: `/api/inkle/autofill/service-team/${serviceTeamId}/autofill_data/`,
          params: {
            autofill_type: autofillKey,
            tag_id: tagId,
          },
        };
      },
      providesTags: ["PRACTICE_AUTOFILL"],
    }),

    getPracticeAutofillById: build.query<
      (PersonaAutofill & AddressAutofill)[],
      {
        serviceTeamId: string;
        autofillKey: AutoFillType;
        autoFillId?: string;
      }
    >({
      query: ({ serviceTeamId, autoFillId, autofillKey }) => {
        return {
          url: `/api/inkle/autofill/service-team/${serviceTeamId}/autofill/${autoFillId}/`,
          params: {
            autofill_type: autofillKey,
          },
        };
      },
      providesTags: ["PRACTICE_AUTOFILL"],
    }),

    addPracticeAutofill: build.mutation<
      {
        practiceAutofill: string;
      },
      {
        serviceTeamId: string;
        autofillKey: AutoFillType;
        autoFill: {
          street_address: string;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          tag_id: string;
        };
      }
    >({
      query: ({ serviceTeamId, autofillKey, autoFill }) => {
        return {
          url: `/api/inkle/autofill/service-team/${serviceTeamId}/autofill_data/`,
          params: {
            autofill_type: autofillKey,
          },
          method: "POST",
          body: autoFill,
        };
      },
      invalidatesTags: (result) => (result ? ["PRACTICE_AUTOFILL"] : []),
    }),

    updatePracticeAutofill: build.mutation<
      PersonaAutofill & AddressAutofill,
      {
        serviceTeamId: string;
        autoFillId: string;
        autofillKey: AutoFillType;
        autoFill: {
          street_address: string;
          city: string;
          state: string;
          zipcode: string;
          country: string;
          tag_id: string;
        };
      }
    >({
      query: ({ serviceTeamId, autoFillId, autofillKey, autoFill }) => {
        return {
          url: `api/inkle/autofill/service-team/${serviceTeamId}/autofill/${autoFillId}/`,
          params: {
            autofill_type: autofillKey,
          },
          method: "PUT",
          body: autoFill,
        };
      },
      invalidatesTags: (result) => (result ? ["PRACTICE_AUTOFILL"] : []),
    }),

    deletePracticeAutofill: build.mutation<
      void,
      {
        serviceTeamId: string;
        autoFillId: string;
        autofillKey: AutoFillType;
      }
    >({
      query: ({ serviceTeamId, autoFillId, autofillKey }) => {
        return {
          url: `api/inkle/autofill/service-team/${serviceTeamId}/autofill/${autoFillId}/`,
          params: {
            autofill_type: autofillKey,
          },
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["PRACTICE_AUTOFILL"] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPracticeAutofillQuery,
  useGetPracticeAutofillByIdQuery,
  useAddPracticeAutofillMutation,
  useUpdatePracticeAutofillMutation,
  useDeletePracticeAutofillMutation,
} = practiceAutofillApi;
