import { useCallback, useEffect, useState } from "react";
import { Channel, Event } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ChannelPreview } from "./ChannelPreview/ChannelPreview";
import { useChatContext } from "stream-chat-react";

export const ChannelSearchPreview = ({
  channel,
  unreplied,
  onSelect: onSelectFromProps,
  searchValue,
}: {
  channel: Channel<DefaultStreamChatGenerics>;
  unreplied: boolean;
  onSelect?: (channelId: string) => void;
  searchValue: string;
}) => {
  const [unread, setUnread] = useState(0);
  const { setActiveChannel, channel: activeChannel } = useChatContext();

  const refreshUnreadCount = useCallback(() => {
    setUnread(channel.countUnread());
  }, [channel]);

  useEffect(() => {
    refreshUnreadCount();

    const handleEvent = (event: Event<DefaultStreamChatGenerics>) => {
      refreshUnreadCount();
    };

    channel.on("message.new", handleEvent);
    channel.on("message.updated", handleEvent);
    channel.on("message.deleted", handleEvent);

    return () => {
      channel.off("message.new", handleEvent);
      channel.off("message.updated", handleEvent);
      channel.off("message.deleted", handleEvent);
    };
  }, [refreshUnreadCount]);

  const onSelect = () => {
    if (onSelectFromProps && channel.id) {
      onSelectFromProps(channel.id);
    }
  };

  return (
    <ChannelPreview
      searchValue={searchValue}
      unreplied={unreplied}
      active={activeChannel?.cid === channel.cid}
      channel={channel}
      unread={unread}
      setActiveChannel={setActiveChannel}
      onSelect={onSelectFromProps && onSelect}
    />
  );
};
