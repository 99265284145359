import cx from "classnames";
export const Loader = ({ size = "regular" }: { size?: string }) => {
  return (
    <div
      id="loader-v1"
      className={cx(
        "t-animate-spin t-rounded-full t-border-4 t-border-solid t-border-purple t-border-t-surface-transparent",
        { "t-w-6 t-h-6": size === "small", "t-h-12 t-w-12": size !== "small" }
      )}
    >
      <span className="t-sr-only">loader</span>
    </div>
  );
};
