import { axiosInstance } from "./apiInstance";

export const getTaskFromChannel = async (channelUrl) => {
  const response = await axiosInstance.get(
    `/api/inkle/tasks/channel/${channelUrl}/task/`
  );
  return response.data;
};

export const assignUserToTask = async ({ taskId, userId }) => {
  const response = await axiosInstance.put(
    `/api/inkle/tasks/${taskId}/assign/`,
    {
      profile_id: userId,
    }
  );

  return response.data;
};

export const getInprogressTasksCount = async ({ group_id }) => {
  const { data } = await axiosInstance.get(
    `/api/inkle/tasks/in_progress_task_count/group/${group_id}/`
  );
  return data;
};

export const fileUpload = ({ formId, groupId, tag, file }) => {
  const controller = new AbortController();
  const formData = new FormData();
  formData.append("file", file);
  const axiosPromise = axiosInstance.post(
    `/api/inkle/tasks/form_instance/${formId}/group/${groupId}/file_upload/?tag=${tag}`,
    formData,
    {
      signal: controller.signal,
    }
  );

  return { axiosPromise, controller };
};

export const deleteUploadedFile = async ({ formId, groupId, tag, fileId }) => {
  const { data } = await axiosInstance.put(
    `/api/inkle/tasks/form_instance/${formId}/group/${groupId}/file_delete/?tag=${tag}`,
    {
      file_id: fileId,
    }
  );

  return data;
};

export const toggleFieldHiddenState = async ({ formId, fieldKey }) => {
  const { data } = await axiosInstance.put(
    `/api/inkle/tasks/form_instance/${formId}/hide_question_toggle/?question_type=${fieldKey}`
  );

  return data;
};
