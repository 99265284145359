import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/V2/Table";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { Category, Vendor } from "components/JournalEntry/AddJournalEntryTable";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { Transactions } from "types/Models/books";
import { Transaction } from "../AddTransaction/AddTransactionManuallyModal";
import {
  Amount,
  Description,
  Invoice,
} from "../AddTransaction/TransactionTable";

const Delete = (info: CellContext<Transaction, number>) => {
  const { id } = info.row.original;

  const { values, setValues } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const onDelete = () => {
    const newValues = values.transactions.filter(
      (transaction) => transaction.id !== id
    );
    setValues({ transactions: newValues });
  };
  const disableDelete = values.transactions.length <= 2;

  return (
    <Button customType="ghost_icon" onClick={onDelete} disabled={disableDelete}>
      <DeleteIcon />
    </Button>
  );
};

const TransactionInfo = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <div className="t-text-body-sm t-text-text-30">{label}</div>
      <div className="t-text-body t-text-text-100">{value}</div>
    </div>
  );
};

export const SplitTransactionTable = ({
  transaction,
  isDeleteSplitTxnsFlow,
  totalAmount,
  amountDifference,
}: {
  transaction: Transactions | {};
  isDeleteSplitTxnsFlow: boolean;
  totalAmount: number;
  amountDifference: number;
}) => {
  const { setFieldValue, values } = useFormikContext<{
    transactions: Transaction[];
  }>();
  const showDifference = amountDifference !== 0;

  const addAnotherTransaction = () => {
    const newValues = [
      ...values.transactions,
      {
        id: values.transactions.length + 1,
        merchant: "",
        category: "",
        description: "",
        amount: 0,
        invoice: null,
      },
    ];
    setFieldValue("transactions", newValues);
  };

  const createColumn = createColumnHelper<Transaction>();

  const columns = useMemo(
    () => [
      createColumn.accessor("merchant", {
        size: 20,
        header: () => <Label>VENDOR</Label>,
        cell: (info) => {
          const id = info.row.id;
          const merchant = info.getValue();

          return <Vendor id={id} merchant={merchant} />;
        },
      }),

      createColumn.accessor("description", {
        size: 20,
        header: () => <Label required>DESCRIPTION</Label>,
        cell: Description,
      }),

      createColumn.accessor("category", {
        size: 20,
        header: () => <Label required>Category</Label>,
        cell: (info) => {
          const id = info.row.id;
          const { category = "" } = info.row.original || {};

          const selectedCategories: string[] = values.transactions.reduce(
            (categories: string[], transaction) =>
              transaction.category && transaction.id - 1 !== Number(id)
                ? [...categories, transaction.category]
                : categories,
            []
          );

          return (
            <Category
              id={id}
              category={category}
              hiddenCategories={selectedCategories}
            />
          );
        },
      }),

      createColumn.accessor("amount", {
        size: 20,
        header: () => (
          <div className="t-flex t-justify-end">
            <Label required>AMOUNT</Label>
          </div>
        ),
        cell: Amount,
      }),

      createColumn.accessor("invoice", {
        size: 5,
        header: "",
        cell: (info) => <Invoice info={info} size="sm" />,
      }),

      createColumn.accessor("id", {
        size: 5,
        header: "",
        cell: Delete,
      }),
    ],
    [values.transactions]
  );

  const table = useReactTable({
    data: values.transactions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  if (!("transaction" in transaction)) {
    return null;
  }

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <div className="t-text-subtitle t-text-text-100">
        Transaction Amount:
        <AmountSuperScript amount={transaction.transaction?.amount} />
      </div>
      <div className="t-flex t-gap-12">
        <TransactionInfo
          label="Date"
          value={dayjs(transaction.transaction.date).format(DD_MMM_YYYY) || "-"}
        />
        <TransactionInfo
          label="Source"
          value={transaction.transaction.from?.source || "-"}
        />
        <TransactionInfo
          label="Vendor"
          value={transaction.transaction.merchant || "-"}
        />
        <TransactionInfo
          label="Description"
          value={transaction.transaction.description || "-"}
        />
      </div>
      <Divider />
      <Table.Container>
        <Table.Content>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: `${header.getSize()}%` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body>
            <AnimatePresence>
              {table.getRowModel().rows.map((row) => (
                <motion.tr
                  layout
                  key={row.original.id}
                  transition={{ duration: 0.1, ease: "easeOut" }}
                  animate={{ x: 0 }}
                  exit={{ x: -1000 }}
                  className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}%` }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </motion.tr>
              ))}
              <motion.tr
                layout
                key="total amount"
                transition={{ duration: 0.1, ease: "easeOut" }}
                animate={{ x: 0 }}
                exit={{ x: -1000 }}
                className="t-px-4"
              >
                <Table.Cell key="amount" style={{ width: "20%" }}></Table.Cell>
                <Table.Cell key="amount" style={{ width: "20%" }}></Table.Cell>
                <Table.Cell key="amount" style={{ width: "20%" }}></Table.Cell>
                <Table.Cell key="total" style={{ width: "20%" }}>
                  <div className="t-w-full t-pr-3.5 t-text-subtitle-sm t-text-text-30 t-pt-2 t-flex t-gap-6 t-justify-end">
                    <div className="t-flex t-flex-col t-gap-3">
                      <div>Total</div>
                      {showDifference && (
                        <div className="t-text-red">Difference</div>
                      )}
                    </div>
                    <div className="t-flex t-flex-col t-gap-3 t-items-end">
                      <AmountSuperScript amount={totalAmount} />
                      {showDifference && (
                        <div className="t-text-red">
                          <AmountSuperScript amount={amountDifference} />
                        </div>
                      )}
                    </div>
                  </div>
                </Table.Cell>
              </motion.tr>
            </AnimatePresence>
          </Table.Body>
        </Table.Content>
      </Table.Container>

      {!isDeleteSplitTxnsFlow && (
        <div className="t-mt-2 t-bg-white t-py-4 t-sticky t-bottom-0">
          <Button size="small" type="button" onClick={addAnotherTransaction}>
            Add more
          </Button>
        </div>
      )}
    </div>
  );
};
