export const FORM_FILING_TYPE = "FORM_FILING";
export const UPLOAD_DOCUMENTS_TYPE = "UPLOAD_DOCUMENTS";
export const PAYMENT_TYPE = "PAYMENT";
export const REVIEW_TYPE = "REVIEW";
export const FILING_IN_PROGRESS_TYPE = "FILING_IN_PROGRESS";
export const SUBMITTED_TYPE = "SUBMITTED";
export const TASK_DEFINING_TYPE = "TASK_DEFINING";
export const TASK_AMENDMENT_DOCUMENTS = "TASK_AMENDMENT_DOCUMENTS";
export const TASK_AMENDMENT = "TASK_AMENDMENT";
export const TASK_AMENDMENT_COMPLETED = "TASK_AMENDMENT_COMPLETED";
