import { put7004Form } from "apis/taskForm";
import cx from "classnames";
import { Button as NewButton } from "components/DesignSystem/Button/Button";
import { FormControl } from "components/fileTax/formControl";
import {
  FORM_7004_SAVE_FAILED,
  OPENED_SIGNUP_FROM_7004,
} from "constants/analyticsEvents";
import { TASK_SESSION_ID } from "constants/session";
import { Form as FormikForm, Formik } from "formik";
import { useAnalytics } from "hooks/useAnalytics";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { Accordion, Card, Form } from "react-bootstrap";
import { setOrGetRandomSessionId } from "utils/sessionId";
import Task7004Complete from "./Task7004Complete";

const Fields = ({
  fields,
  hide,
  hidden,
  current,
  data,
  uuid,
  repeatableId,
  groupId,
  formId,
  sectionKey,
  isArchived,
  formData,
  section,
}) => {
  const { isAdmin } = useRoleBasedView();

  const onChange = (collapsable) => (event) => {
    if (collapsable) {
      hide?.(event.target.checked);
    }
  };

  if (!fields) {
    return null;
  }

  return fields?.map(
    (field) =>
      (!hidden || field.collapse_section) && (
        <Form.Group
          className={cx({
            "opacity-50": field.is_hidden_for_customer,
            "p-2": !field.is_hidden_for_customer || isAdmin,
          })}
          key={field.field_key}
        >
          <FormControl
            sectionKey={sectionKey}
            formId={formId}
            type={field.type}
            id={field.field_key}
            name={field.field_key}
            label={field.field_label}
            placeholder={field.placeholder}
            options={field.options}
            tooltip={field.tooltip}
            defaultValue={field.value}
            onChange={onChange(field.collapse_section)}
            descript={field?.description}
            mandatory={field?.mandatory}
            current={current}
            data={data}
            uuid={uuid}
            groupId={groupId}
            autofillKey={field.autofill_key}
            autofillPrefix={field.autofill_prefix}
            autofillSuffix={repeatableId}
            tag={field.tag}
            document={field.document}
            field={field}
            isArchived={isArchived}
            formData={{ data: formData }}
            section={section}
          />
        </Form.Group>
      )
  );
};

const RepeatableFields = ({
  repeatableFields,
  duplicationAllowed,
  title,
  hidden,
  updateSectionKeyIdPair,
  sectionKey,
  updateRemovedSectionIds,
  current,
  data,
  uuid,
  groupId,
  formId,
  isArchived,
  formData,
  section,
}) => {
  const [repeatables, setFields] = useState(repeatableFields);
  const addField = () => {
    const ids = repeatables.map(({ id }) => id);
    const maxId = Math.max(...ids);
    const nextId = maxId + 1;
    const templateFields = repeatables[0].fields.map((field) => {
      let keyOnly = field.field_key.split("_");
      keyOnly = keyOnly.slice(0, keyOnly.length - 1);
      keyOnly = keyOnly.join("_");
      return {
        ...field,
        value: "",
        field_key: `${keyOnly}_${nextId}`,
      };
    });
    setFields((r) => [...r, { id: nextId, fields: templateFields }]);
    updateSectionKeyIdPair({ key: sectionKey, id: nextId });
  };

  if (hidden) {
    return null;
  }

  const onRemove = (removeId) => () => {
    setFields((r) => r.filter(({ id }) => id !== removeId));
    updateRemovedSectionIds({ key: sectionKey, id: removeId });
  };

  return (
    <>
      {repeatables?.map(({ fields, id }) => (
        <Card key={id} className="mb-4">
          <Card.Body>
            <Fields
              formId={formId}
              fields={fields}
              repeatableId={id.toString()}
              current={current}
              data={data}
              uuid={uuid}
              groupId={groupId}
              sectionKey={sectionKey}
              isArchived={isArchived}
              formData={formData}
              section={section}
            />
            <NewButton
              customType="danger"
              size="regular"
              onClick={onRemove(id)}
              disabled={repeatables.length === 1}
            >
              Remove
            </NewButton>
          </Card.Body>
        </Card>
      ))}
      {duplicationAllowed && (
        <NewButton customType="primary" size="regular" onClick={addField}>
          Add {title}
        </NewButton>
      )}
    </>
  );
};

const AllFields = ({
  section,
  updateSectionKeyIdPair,
  updateRemovedSectionIds,
  current,
  data,
  uuid,
  groupId,
  formId,
  isArchived,
  formData,
}) => {
  const field = section?.section_fields?.[0];
  const [hidden, hide] = useState(
    field?.type === "checkbox" && field?.value === "on"
  );

  return (
    <>
      <p
        className="descT"
        dangerouslySetInnerHTML={{ __html: section.section_description }}
      ></p>
      <Fields
        sectionKey={section.section_key}
        formId={formId}
        fields={section?.section_fields}
        removable={section?.is_removable}
        hide={hide}
        hidden={hidden}
        current={current}
        data={data}
        uuid={uuid}
        sectionId={section.uuid}
        groupId={groupId}
        isArchived={isArchived}
        formData={formData}
        section={section}
      />
      <RepeatableFields
        formId={formId}
        updateSectionKeyIdPair={updateSectionKeyIdPair}
        duplicationAllowed={section.section_duplication_allowed}
        sectionKey={section.section_key}
        repeatableFields={section.repeatable_fields}
        title={section?.section_title}
        hidden={hidden}
        updateRemovedSectionIds={updateRemovedSectionIds}
        current={current}
        data={data}
        uuid={uuid}
        groupId={groupId}
        isArchived={isArchived}
        formData={formData}
        section={section}
      />
    </>
  );
};
export const PublicFormEngine = ({ form_data, email, mobile }) => {
  const { alertToast } = useToast();
  const [sectionKeyIdPair, setSectionKeyIdPair] = useState({});
  const [removedSectionIds, setRemovedSectionIds] = useState({});
  const { trackEvent } = useAnalytics();
  const [showTaskComplete, setShowTaskComplete] = useState(false);
  const [submitting, setToSubmit] = useState();

  const updateSectionKeyIdPair = ({ key, id }) =>
    setSectionKeyIdPair((keyIdPairs) => ({ ...keyIdPairs, [key]: id }));

  const updateRemovedSectionIds = ({ key, id }) =>
    setRemovedSectionIds((keyIdPairs) => ({
      ...keyIdPairs,
      [key]: [...(keyIdPairs[key] || []), id],
    }));

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    let formNameValue = {};
    for (var pair of formData.entries()) {
      formNameValue[pair[0]] = pair[1];
    }
    const updatedValue = (key) => formNameValue[key];

    const publicUserEmail = updatedValue("email");
    const publicUserMobile = updatedValue("mobile");

    if (!publicUserEmail) {
      alertToast({ message: "Please enter the email" });
      return;
    }

    if (!updatedValue("company_name")) {
      alertToast({ message: "Please enter the Company name" });
      return;
    }

    const updatedSections = form_data?.data?.section_group?.[0].sections.map(
      (section) => {
        const updatedSectionFields = section?.section_fields?.map((field) => {
          if (field.field_key === "email" || field.field_key === "mobile") {
            return null;
          }

          if (field.type === "checkbox") {
            const currentCheckboxValue = updatedValue(field.field_key);
            return {
              ...field,
              value:
                currentCheckboxValue === "" || currentCheckboxValue === "true"
                  ? "true"
                  : null,
            };
          }
          return {
            ...field,
            value: updatedValue(field.field_key),
          };
        });

        let updatedSection = {
          ...section,
          section_fields: updatedSectionFields.filter(Boolean),
        };

        if (section.repeatable_fields?.length > 0) {
          updatedSection.repeatable_fields = updatedSection?.repeatable_fields
            ?.filter(
              ({ id }) =>
                !removedSectionIds[updatedSection.section_key]?.includes(id)
            )
            .map(({ id, fields }) => ({
              id: id,
              fields: fields?.map((field) => ({
                ...field,
                value: updatedValue(field?.field_key),
              })),
            }));

          const finalId = sectionKeyIdPair[section.section_key];
          const allId = updatedSection?.repeatable_fields?.map((f) => f.id);
          const existingMaxId = Math.max(...allId);

          if (finalId && finalId - existingMaxId > 0) {
            const noOfFieldsToCreate = finalId - existingMaxId;

            const separateFieldKey = (fieldWithId) => {
              let nameOnly = fieldWithId.split("_");
              nameOnly = nameOnly.slice(0, nameOnly.length - 1);
              return nameOnly.join("_");
            };

            for (let i = 1; i <= noOfFieldsToCreate; i++) {
              const fields = updatedSection.repeatable_fields[0].fields.map(
                (field) => {
                  let keyOnly = separateFieldKey(field.field_key);
                  let autofillSection = field.autofill_section
                    ? separateFieldKey(field.autofill_section)
                    : null;
                  const fieldKey = `${keyOnly}_${existingMaxId + i}`;
                  const returnObj = {
                    ...field,
                    value: updatedValue(fieldKey),
                    field_key: fieldKey,
                  };

                  if (autofillSection) {
                    returnObj.autofill_section = `${autofillSection}_${
                      existingMaxId + i
                    }`;
                  }

                  return returnObj;
                }
              );

              updatedSection.repeatable_fields.push({
                id: existingMaxId + i,
                fields,
              });
            }
          }
        }
        return updatedSection;
      }
    );

    const params = {
      form_data: {
        ...form_data,
        data: {
          ...form_data.data.data,
          section_group: [{ sections: updatedSections }],
        },
      },
      public_user_email: publicUserEmail,
      public_user_mobile: publicUserMobile,
    };

    localStorage.setItem("PUBLIC_USER_EMAIL", publicUserEmail);

    setToSubmit(true);
    put7004Form({
      params,
      session_id: setOrGetRandomSessionId(TASK_SESSION_ID),
    })
      .then((response) => {
        trackEvent(OPENED_SIGNUP_FROM_7004, {
          company_name: updatedValue("company_name"),
        });
        setShowTaskComplete(true);
        setToSubmit(false);
      })
      .catch(() => {
        trackEvent(FORM_7004_SAVE_FAILED, {
          company_name: updatedValue("company_name"),
        });
        setToSubmit(false);
      });
  };

  if (!form_data) {
    return null;
  }

  const emailField = {
    type: "email",
    regex: "",
    value: email || "",
    options: [],
    tooltip: "",
    disabled: false,
    field_key: "email",
    mandatory: true,
    field_label: "Your work email",
    placeholder: "john@acme.com",
    collapse_section: false,
  };

  const mobileField = {
    type: "text",
    regex: "",
    value: mobile || "",
    options: [],
    tooltip: "",
    disabled: false,
    field_key: "mobile",
    mandatory: false,
    field_label: "Mobile number",
    placeholder: "",
    collapse_section: false,
  };

  return (
    <>
      <Formik initialValues={{}}>
        <FormikForm onSubmit={onSubmit} className="taxing -t-mt-6 t-h-[600px]">
          <Accordion defaultActiveKey="0" className="w-100">
            {form_data?.data?.section_group?.[0].sections.map(
              (section, index) => {
                return (
                  <Accordion.Item
                    eventKey={String(index)}
                    key={section?.section_title}
                  >
                    <Accordion.Header>
                      {section?.section_title}
                    </Accordion.Header>
                    <Accordion.Body>
                      <AllFields
                        formId={form_data.uuid}
                        section={{
                          ...section,
                          section_fields: [
                            ...(index === 0 ? [emailField, mobileField] : []),
                            ...section.section_fields,
                          ],
                        }}
                        updateSectionKeyIdPair={updateSectionKeyIdPair}
                        updateRemovedSectionIds={updateRemovedSectionIds}
                        formData={form_data}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }
            )}
          </Accordion>
          <div className="d-flex mt-2 mb-4 w-100 t-justify-end">
            <NewButton
              isLoading={submitting}
              disabled={submitting}
              type="submit"
              customType="primary"
              size="regular"
            >
              Submit
            </NewButton>
          </div>
          <div className="spaceFrm" />
        </FormikForm>
      </Formik>
      <Task7004Complete
        showTaskComplete={showTaskComplete}
        setShowTaskComplete={setShowTaskComplete}
      />
    </>
  );
};
