import classNames from "classnames";
import { ImgHTMLAttributes } from "react";

export const Avatar = ({
  alt,
  src,
  size = "small",
  lettersCount = 1,
  ...props
}: {
  alt: string;
  src?: string | undefined;
  size?: "small" | "regular" | "large";
  lettersCount?: number;
} & ImgHTMLAttributes<HTMLImageElement>) => {
  if (!src) {
    return (
      <span
        className={classNames(
          "t-flex t-items-center t-justify-center t-rounded-full t-text-subtitle-sm t-font-medium t-uppercase t-text-text-black t-bg-neutral-10 t-overflow-hidden",
          props.className || "",
          {
            "t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6": size === "small",
            "t-min-h-8 t-min-w-8 t-max-h-8 t-max-w-8": size === "regular",
            "t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10": size === "large",
          }
        )}
      >
        {alt?.slice(0, lettersCount)?.toUpperCase()}
      </span>
    );
  }

  return (
    <img
      {...props}
      className={classNames(
        "t-rounded-full t-overflow-hidden t-text-overline",
        props.className || "",
        {
          "t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6": size === "small",
          "t-min-h-8 t-min-w-8 t-max-h-8 t-max-w-8": size === "regular",
          "t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10": size === "large",
        }
      )}
      src={src}
      alt={alt}
    />
  );
};
