import { number, object, string } from "yup";

export const merchantAddSchema = () =>
  object({
    name: string().required("Name is required"),
    email: string().email("Should be a valid email"),
    total_amount: number()
      .required("Please enter Amount")
      .moreThan(0, "Amount must be greater than 0"),
    amount_paid_in_season: string().required("Year of payment is required"),
  });

export const merchantEditSchema = () =>
  object({
    name: string().required("Name is required"),
    email: string().email("Should be a valid email"),
  });

export const merchantEmailAddSchema = () =>
  object({
    email: string()
      .required("Email is required")
      .email("Should be a valid email"),
  });
