import AddTaskEntityModal from "components/tasks/modal/AddTaskEntityModal";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { Route, Switch, useRouteMatch } from "react-router-dom";

export const AddTaskModal = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${url}/addtask`}
        render={() => <TemplateMenu to={`${url}`} />}
      />
      <Route
        exact
        path={`${url}/addtask/:templateId`}
        render={() => <AddTaskEntityModal to={`${url}`} />}
      />
    </Switch>
  );
};
