import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { ArrowRight } from "components/icons/ArrowRight";
import { HTMLAttributes, ReactNode, useContext, useState } from "react";
import { Account } from "types/Models/report";
import {
  ReportAccordionContext,
  ReportAccordionContextType,
} from "./ReportAccordionContext";
import { CASH_FLOW_STATEMENT } from "constants/bookkeeping";
import { AnimatePresence } from "framer-motion";
import { AccordionAnimation } from "components/AccordionAnimation";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { GeneralLedgerTable } from "components/GeneralLedgerAccordion/GeneralLedgerAccordion";
import { useGetTransactionsForReportQuery } from "store/apis/reports";
import { Loader } from "components/DesignSystem/Loader/Loader";

type RootTypes = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement> &
  ReportAccordionContextType;

const Root = ({
  maxIndent = 4,
  reportType,
  children,
  accountingMethod,
  showNonZeroAccounts,
  startDate,
  endDate,
  isLoading,
  ...props
}: RootTypes) => {
  return (
    <ReportAccordionContext.Provider
      value={{
        maxIndent,
        reportType,
        showNonZeroAccounts,
        startDate,
        endDate,
        accountingMethod,
        isLoading,
      }}
    >
      <div {...props}>{children}</div>
    </ReportAccordionContext.Provider>
  );
};

const Header = ({
  children,
  ...props
}: { children: ReactNode } & React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>) => {
  return (
    <header
      {...props}
      className="t-py-3 t-text-text-30 t-text-subtext-sm t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-10 t-rounded-t-lg t-flex t-justify-between t-w-full t-gap-24 t-items-center"
    >
      {children}
    </header>
  );
};

const TransactionsTable = ({
  categoryId,
  nextLevel,
}: {
  categoryId: string;
  nextLevel: number;
}) => {
  const entityId = useCurrentEntityId();
  const { reportType, accountingMethod, startDate, endDate } = useContext(
    ReportAccordionContext
  );

  const { data: categoryLedgerInfo, isLoading } =
    useGetTransactionsForReportQuery(
      {
        entityId: entityId,
        query: {
          accounting_method: accountingMethod,
          start_date: startDate,
          end_date: endDate,
          category_id: categoryId!,
          report_type: reportType,
        },
      },
      {
        skip: !categoryId,
      }
    );

  if (isLoading) {
    return (
      <AccordionAnimation>
        <div className="t-flex t-justify-center t-h-full t-w-full t-items-center t-py-3">
          <Loader size="small" />
        </div>
      </AccordionAnimation>
    );
  }

  if (
    categoryLedgerInfo?.transactions &&
    categoryLedgerInfo?.transactions?.length === 0
  ) {
    return (
      <AccordionAnimation>
        <div className="t-flex t-justify-center t-h-full t-w-full t-items-center t-py-3 t-border-solid t-border-y t-border-0 t-border-neutral-10 t-text-body">
          No transactions found
        </div>
      </AccordionAnimation>
    );
  }

  return (
    categoryLedgerInfo?.transactions &&
    categoryLedgerInfo?.transactions?.length > 0 && (
      <AccordionAnimation>
        <div style={{ paddingLeft: 9 * nextLevel + 26 }}>
          <div className="t-flex t-rounded t-flex-col">
            <div className="t-text-body-sm t-text-text-60 t-w-full t-flex t-justify-end t-gap-4 t-items-center hover:t-bg-surface-lighter-grey t-border t-border-t t-border-solid t-border-b-0 t-border-x-0 t-border-neutral-0 t-p-2">
              <div className="t-bg-surface-lighter-grey t-px-1.5 t-py-0.5 t-border t-border-solid t-border-neutral-0 t-rounded">
                {categoryLedgerInfo?.transactions_count} entries
              </div>
              <div>
                Starting Balance: {"  "}
                <AmountSuperScript
                  amount={categoryLedgerInfo?.opening_balance!}
                />
              </div>
            </div>
          </div>

          <GeneralLedgerTable
            tableData={categoryLedgerInfo?.transactions!}
            startDate={startDate!}
            endDate={endDate!}
            totalDebits={categoryLedgerInfo?.total_debits!}
            totalCredits={categoryLedgerInfo?.total_credits!}
          />
        </div>
      </AccordionAnimation>
    )
  );
};

export const Category = ({
  account,
  nextLevel,
  action,
}: {
  account: Account;
  nextLevel: number;
  action?: (args: Account) => ReactNode;
}) => {
  const [accordionActive, setAccordionActive] = useState(nextLevel === 1);
  const [showTransactions, setShowTransactions] = useState(false);
  const { reportType } = useContext(ReportAccordionContext);

  const { uuid, types, name, summary_amount, is_cumulative } = account;

  return (
    <div
      className={classNames("t-w-full", {
        "t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-0":
          nextLevel === 1,
      })}
      key={uuid}
    >
      <button
        className="all:unset t-flex t-justify-between t-w-full hover:t-bg-surface-lighter-grey t-py-2 t-px-3 t-gap-24 t-items-center t-group"
        onClick={(e) => {
          e.stopPropagation();
          setAccordionActive((v) => !v);
        }}
      >
        <div
          className="t-flex t-items-center t-justify-between t-w-full t-gap-2"
          style={{ paddingLeft: types ? 8 * nextLevel : 9 * nextLevel }}
        >
          <div className="t-flex t-items-center">
            <div
              className={classNames({
                "t-block": Boolean(types),
                "t-invisible": !Boolean(types),
              })}
            >
              <div
                className={classNames(
                  "t-transform t-transition t-duration-300 t-ease-in-out",
                  { "t-rotate-90": accordionActive }
                )}
              >
                {types.length > 0 ? (
                  <ArrowRight stroke="1.5" color="currentColor" />
                ) : (
                  <div className="t-w-4"></div>
                )}
              </div>
            </div>
            <div className="t-flex t-w-full t-justify-end t-h-full t-items-center t-gap-3">
              <div
                className={classNames(
                  "t-truncate t-w-max t-text-body t-mt-0.5",
                  {
                    "t-text-text-60": showTransactions,
                  }
                )}
              >
                {name}
              </div>
              <div
                className={classNames("t-invisible t-pb-1", {
                  "t-invisible": nextLevel === 1,
                  "group-hover:!t-visible": nextLevel > 1,
                })}
              >
                {action?.(account)}
              </div>
            </div>
          </div>

          {(types.length === 0 || nextLevel > 0) && (
            <button
              className={classNames("all:unset t-text-body", {
                "hover:!t-underline group-hover:t-text-purple":
                  !showTransactions,
                "!t-underline t-text-purple": showTransactions,
              })}
              onClick={(e) => {
                e.stopPropagation();
                setShowTransactions((v) => !v);
              }}
            >
              <AmountSuperScript amount={summary_amount} />
            </button>
          )}
        </div>
      </button>

      {showTransactions && (
        <AnimatePresence>
          <TransactionsTable categoryId={uuid} nextLevel={nextLevel} />
        </AnimatePresence>
      )}

      <AnimatePresence>
        {accordionActive && types && (
          <AccordionAnimation>
            <div
              className={classNames({
                "t-text-text-100": nextLevel === 0,
                "t-text-text-60": nextLevel === 1,
                "t-text-text-30": nextLevel >= 2,
              })}
            >
              <Trigger data={types} level={nextLevel} action={action} />
            </div>
          </AccordionAnimation>
        )}
      </AnimatePresence>

      {is_cumulative && types.length > 0 && accordionActive && (
        <div
          className="t-w-full t-flex t-justify-between t-border t-border-b-0 t-border-solid t-border-t t-border-x-0 t-border-neutral-0 t-text-text-100 hover:t-bg-surface-lighter-grey t-py-2 t-px-3"
          style={{
            paddingLeft: types ? 8 * nextLevel : 9 * nextLevel,
          }}
        >
          {" "}
          <div className="t-min-w-32 t-truncate t-w-max t-text-body t-mt-0.5 t-pl-6">
            {nextLevel === 1 && reportType === CASH_FLOW_STATEMENT
              ? "Net cash provided by"
              : "Total"}{" "}
            {name}
          </div>
          <div className="t-text-body">
            <AmountSuperScript amount={summary_amount} />
          </div>
        </div>
      )}
    </div>
  );
};

export const Trigger = ({
  data,
  level = 0,
  action,
  children,
}: {
  data: Account[];
  level?: number;
  action?: (props: Account) => ReactNode;
  children?: ReactNode;
}) => {
  const { maxIndent, isLoading } = useContext(ReportAccordionContext);

  let nextLevel = level;
  if (maxIndent && nextLevel <= maxIndent) {
    nextLevel = nextLevel + 1;
  }

  if (!data) {
    return null;
  }

  if (isLoading) {
    return (
      <>
        {data.map(({ uuid }) => (
          <div
            className="t-w-full t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-0 t-flex t-justify-between t-py-2 t-px-3 t-h-11 t-items-center"
            key={uuid}
          >
            <div className="t-w-2/4 t-bg-neutral-10 t-animate-pulse t-h-5 t-rounded"></div>
            <div className="t-w-1/6  t-bg-neutral-10 t-animate-pulse t-h-5 t-rounded"></div>
          </div>
        ))}
      </>
    );
  }

  return data?.map((account) => (
    <Category
      key={account.uuid}
      account={account}
      nextLevel={nextLevel}
      action={action}
    />
  ));
};

const ReportsAccordion = {
  Root,
  Trigger,
  Header,
};

export default ReportsAccordion;
