import cx from "classnames";
import Loader from "components/design/loader";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import {
  Invoice,
  useGetInvoiceItemsQuery,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import { currency } from "utils/Currency";

export const InvoicePreivew = ({
  invoice,
  size = "small",
}: {
  invoice?: Invoice;
  size?: "small" | "large";
}) => {
  const group = useCurrentGroup();
  const { trackEvent } = useAnalytics();
  const entityId = useCurrentEntityId();

  const { data: invoiceSettings } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    {
      skip: !group.uuid || !entityId,
    }
  );

  const { data: items, isLoading } = useGetInvoiceItemsQuery(
    {
      groupId: group?.uuid!,
      entityId: invoiceSettings?.entity_id!,
      invoiceId: invoice?.uuid!,
    },
    { skip: !group?.uuid || !invoiceSettings?.entity_id }
  );

  if (!invoice) {
    return null;
  }

  const entityFromSettings = group.entities?.find(
    (e) => e.uuid === invoiceSettings?.entity_id
  );

  if (isLoading) {
    <div className="t-flex t-w-full t-h-full t-justify-center t-items-center">
      <Loader />
    </div>;
  }

  return (
    <div
      className={cx("t-p-5 t-flex t-flex-col t-min-h-full", {
        "t-text-[7px]": size === "small",
        "t-text-[11px]": size === "large",
      })}
    >
      <div className="t-flex t-justify-between t-mb-2">
        <div>
          <div>
            <img
              className="t-max-h-[54px] t-max-w-[168px] t-mb-2"
              src={invoiceSettings?.logo_url}
            />
          </div>
          <p
            className={cx("t-font-bold t-m-0", {
              "t-text-[9px]": size === "small",
              "t-text-[14px]": size === "large",
            })}
          >
            {entityFromSettings?.name}
          </p>
          {/* {invoiceSettings?.address && ( */}
          <>
            <span>
              {" "}
              {invoiceSettings?.address?.street_address &&
                `${invoiceSettings?.address?.street_address},`}
            </span>
            <br />
            <span>
              {" "}
              {invoiceSettings?.address?.city &&
                `${invoiceSettings?.address?.city},`}
            </span>
            <span> {invoiceSettings?.address?.state}</span>
            <br />
            <span>
              {" "}
              {invoiceSettings?.address?.country &&
                `${invoiceSettings?.address?.country},`}
            </span>
            <span>
              {" "}
              {invoiceSettings?.address?.zipcode &&
                invoiceSettings?.address?.zipcode}
            </span>
          </>
        </div>

        <div className="t-flex t-flex-col t-gap-1 t-text-right">
          <span
            className={cx("t-font-bold", {
              "t-text-body-sm": size === "small",
              "t-text-[22px]": size === "large",
            })}
          >
            {invoice.title}
          </span>
          <span className="t-text-text-30">#{invoice.invoice_number}</span>
        </div>
      </div>

      <table className="t-table-fixed [&_td]:t-py-0.5 [&_td]:t-w-1/4">
        <tbody>
          <tr>
            <td>Bill to:</td>
            <td></td>
            <td className="t-text-right">Date:</td>
            <td className="t-text-right t-pr-1">
              {invoice.invoice_date &&
                dayjs(invoice.invoice_date).format(DD_MMM_YYYY)}
            </td>
          </tr>

          <tr>
            <td rowSpan={4} className="t-align-top">
              {invoice.customer && (
                <>
                  <span className="t-font-bold">
                    {invoice.customer?.company_name &&
                      `${invoice.customer?.company_name},`}
                  </span>
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.street_address &&
                      `${invoice.customer?.billing_address?.street_address},`}
                  </span>
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.city &&
                      `${invoice.customer?.billing_address?.city},`}
                  </span>
                  <span> {invoice.customer?.billing_address?.state}</span>
                  <br />
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.country &&
                      `${invoice.customer?.billing_address?.country},`}
                  </span>
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.zipcode &&
                      invoice.customer?.billing_address?.zipcode}
                  </span>
                </>
              )}
            </td>
            <td rowSpan={4} className="t-align-top"></td>
            {invoice.due_date && (
              <>
                <td className="t-text-right">Due Date:</td>
                <td className="t-text-right t-pr-1">
                  {dayjs(invoice.due_date).format(DD_MMM_YYYY)}
                </td>
              </>
            )}
          </tr>

          {invoice.po_number && (
            <tr>
              <td className="t-text-right">PO number:</td>
              <td className="t-text-right t-pr-1">{invoice.po_number}</td>
            </tr>
          )}

          <tr className="t-font-bold">
            <td className="t-text-right t-bg-neutral-0 t-rounded-l t-pr-1">
              Balance Due:
            </td>
            <td className="t-text-right t-bg-neutral-0 t-rounded-r t-pr-1">
              {currency({ amount: invoice.due_balance || "0" })}
            </td>
          </tr>

          <tr>
            <td colSpan={4} height={20}></td>
          </tr>
        </tbody>
      </table>

      <table className="t-table-fixed [&_td]:t-py-0.5 t-w-full t-my-5">
        <tbody>
          <tr>
            <td className="t-bg-i-primary-100 t-rounded-l t-text-surface t-w-6/12 t-pl-1">
              Item
            </td>
            <td className="t-bg-i-primary-100 t-text-surface t-w-3/12">
              Quantity
            </td>
            <td className="t-bg-i-primary-100 t-text-surface t-w-1/12 t-text-right">
              Rate
            </td>
            <td className="t-bg-i-primary-100 t-text-surface t-rounded-r t-pr-1 t-w-2/12 t-text-right">
              Amount
            </td>
          </tr>

          <tr>
            <td colSpan={4} height={8} />
          </tr>

          {items?.map((item) => (
            <tr key={item.uuid}>
              <td className="t-pl-1">{item.description}</td>
              <td>{item.quantity}</td>
              <td className="t-text-right">
                {currency({ amount: item.rate || "0" })}
              </td>
              <td className="t-text-right t-pr-1">
                {currency({ amount: item.amount || "0" })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className="t-table-fixed [&_td]:t-py-0.5 [&_td]:t-w-1/4 t-w-full">
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td className="t-text-right">Subtotal:</td>
            <td className="t-text-right">
              {currency({ amount: invoice.sub_total || "0" })}
            </td>
          </tr>

          {Boolean(invoice.discount) && (
            <tr>
              <td></td>
              <td></td>
              <td className="t-text-right">
                Discount{" "}
                {invoice.discount_type === "PERCENT" &&
                  `(${invoice.discount}%)`}{" "}
                :
              </td>
              <td className="t-text-right">
                {currency({ amount: invoice.discount_amount || "0" })}
              </td>
            </tr>
          )}

          {Boolean(invoice.tax) && (
            <tr>
              <td></td>
              <td></td>
              <td className="t-text-right">
                Tax
                {invoice.tax_type === "PERCENT" && `(${invoice.tax}%)`}:
              </td>
              <td className="t-text-right">
                {currency({ amount: invoice.tax_amount || "0" })}
              </td>
            </tr>
          )}

          <tr>
            <td></td>
            <td></td>
            <td className="t-text-right">Total</td>
            <td className="t-text-right">
              {currency({ amount: invoice.total || "0" })}
            </td>
          </tr>

          {Boolean(invoice.paid_amount) && (
            <tr>
              <td></td>
              <td></td>
              <td className="t-text-right">Amount Paid:</td>
              <td className="t-text-right">
                {currency({ amount: invoice.paid_amount || "0" })}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {invoice.notes && (
        <>
          <p className="t-m-0 t-mt-auto">Notes:</p>
          <p className="t-whitespace-pre-wrap">{invoice.notes}</p>
        </>
      )}

      {invoice.terms && (
        <>
          <p className="t-m-0">Terms:</p>
          <p className="t-m-0 t-whitespace-pre-wrap">{invoice.terms}</p>
        </>
      )}
    </div>
  );
};
