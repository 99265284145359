import { Button } from "components/DesignSystem/Button/Button";
import { BareInput, Label } from "components/DesignSystem/TextInput/TextInput";
import { NumericInput } from "components/NumericInput/NumericInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DeleteIcon } from "components/icons/delete";
import { useFormikContext } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useParams } from "react-router-dom";
import {
  Invoice,
  InvoiceItem,
  invoiceApis,
  useCreateInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
  useGetEntityInvoiceQuery,
  useGetInvoiceItemsQuery,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceItemMutation,
} from "store/apis/invoices";

export const InvoiceItems = () => {
  const { values } = useFormikContext<Partial<Invoice>>();
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const entityId = useCurrentEntityId();

  const { data: invoiceSettings, isLoading: invoiceSettingLoading } =
    useGetInvoiceSettingsQuery(
      {
        groupId: groupId!,
        entityId,
      },
      { skip: !groupId || !entityId }
    );

  const { data: items } = useGetInvoiceItemsQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !invoiceSettings?.entity_id }
  );

  const { data: invoice, isLoading: invoiceLoading } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const [createInvoiceItem, { isLoading: creatingInvoiceItem }] =
    useCreateInvoiceItemMutation();
  const [updateInvoiceItem] = useUpdateInvoiceItemMutation();
  const [deleteInvoiceItem, { isLoading: deleting, originalArgs }] =
    useDeleteInvoiceItemMutation();

  const dispatch = useAppDispatch();

  const onCreateInvoice = () => {
    if (groupId && invoiceId && entityId) {
      createInvoiceItem({
        groupId,
        entityId,
        invoiceId,
      });
    }
    if (invoice?.customer) {
      dispatch(
        invoiceApis.util.invalidateTags([
          { type: "INVOICE_CUSTOMERS", id: invoice?.customer?.uuid },
        ])
      );
    }
  };

  const onUpdateInvoice = (
    invoiceItemId: string,
    values: InvoiceItem,
    update: Partial<InvoiceItem>
  ) => {
    if (groupId && invoiceId && entityId) {
      updateInvoiceItem({
        groupId,
        entityId,
        invoiceId,
        invoiceItemId,
        payload: { ...values, ...update },
      });

      if (invoice?.customer) {
        dispatch(
          invoiceApis.util.invalidateTags([
            { type: "INVOICE_CUSTOMERS", id: invoice?.customer?.uuid },
          ])
        );
      }
    }
  };

  const onDeleteInvoice = (invoiceItemId: string) => {
    if (groupId && invoiceId && entityId) {
      deleteInvoiceItem({
        groupId,
        entityId,
        invoiceId,
        invoiceItemId,
      });

      if (invoice?.customer) {
        dispatch(
          invoiceApis.util.invalidateTags([
            { type: "INVOICE_CUSTOMERS", id: invoice?.customer?.uuid },
          ])
        );
      }
    }
  };

  if (!values?.invoice_items) {
    return null;
  }

  return (
    <div className="t-flex t-flex-col t-gap-6">
      <>
        <div className="t-flex t-flex-col t-gap-5">
          {items &&
            items.map((invoiceItem, index) => (
              <div
                className="t-grid t-grid-cols-[2fr_1fr_1fr_0.5fr] t-gap-3 t-items-end"
                key={invoiceItem.uuid}
              >
                <div>
                  <Label htmlFor={`item_description.${index}`}>Item</Label>
                  <BareInput
                    name={`item_description.${index}`}
                    onChange={(e) =>
                      onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                        description: e.target.value,
                      })
                    }
                    defaultValue={invoiceItem.description}
                  />
                </div>
                <div>
                  <NumericInput
                    fieldProps={{ name: `item_quantity.${index}` }}
                    numericProps={{
                      onValueChange: ({ floatValue }) =>
                        onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                          quantity: floatValue || 0,
                        }),
                      defaultValue: invoiceItem.quantity,
                    }}
                    label="Quantity"
                  />
                </div>
                <div>
                  <PriceInput
                    name={`item_price.${index}`}
                    onValueChange={({ floatValue }: { floatValue: number }) =>
                      onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                        rate: floatValue || 0,
                      })
                    }
                    defaultValue={invoiceItem.rate}
                    label="Rate"
                  />
                </div>
                <div>
                  <Button
                    onClick={() => onDeleteInvoice(invoiceItem.uuid)}
                    type="button"
                    customType="icon"
                    disabled={items.length === 1}
                    isLoading={
                      deleting &&
                      originalArgs?.invoiceItemId === invoiceItem.uuid
                    }
                  >
                    <DeleteIcon color="currentColor" />
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div>
          <Button
            type="button"
            customType="secondary"
            onClick={onCreateInvoice}
            isLoading={creatingInvoiceItem}
            disabled={creatingInvoiceItem}
          >
            Add line item
          </Button>
        </div>
      </>
    </div>
  );
};
