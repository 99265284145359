import classNames from "classnames";
import React from "react";

const data = [
  "Fill form",
  "Review",
  "Filing in progress",
  "Successfully filed",
];

const Task7004Bar = () => {
  return (
    <div className="t-z-50 t-flex t-w-full ">
      <ul className="StepProgress t-flex t-overflow-auto t-border t-border-solid t-border-[transparent] t-border-b-neutral-10 t-py-[6px] t-gap-4 t-w-full">
        {data?.map((item, index) => {
          return (
            <>
              <div
                className={classNames("StepProgress-item t-z-10", {
                  "t-bg-purple t-text-surface t-py-1 t-px-2 t-rounded-lg":
                    index === 0,
                })}
                key={index}
                id={index === 0 ? "" : "nonClickable"}
              >
                <strong className="t-whitespace-nowrap">{item}</strong>
              </div>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default Task7004Bar;
