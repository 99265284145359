export const MapPointer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="5"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_337_19723)">
        <path
          d="M10.75 7.5C10.75 8.0439 10.5887 8.57558 10.2865 9.02782C9.98437 9.48005 9.55488 9.83253 9.05238 10.0407C8.54989 10.2488 7.99695 10.3033 7.4635 10.1972C6.93006 10.0911 6.44005 9.82914 6.05546 9.44454C5.67086 9.05995 5.40895 8.56995 5.30284 8.0365C5.19673 7.50305 5.25119 6.95012 5.45933 6.44762C5.66748 5.94512 6.01995 5.51563 6.47218 5.21346C6.92442 4.91128 7.4561 4.75 8 4.75C8.72909 4.75083 9.42809 5.04082 9.94363 5.55637C10.4592 6.07192 10.7492 6.77091 10.75 7.5ZM14.5 8C14.5 9.28558 14.1188 10.5423 13.4046 11.6112C12.6903 12.6801 11.6752 13.5132 10.4874 14.0052C9.29973 14.4972 7.99279 14.6259 6.73192 14.3751C5.47104 14.1243 4.31285 13.5052 3.40381 12.5962C2.49477 11.6872 1.8757 10.529 1.6249 9.26809C1.37409 8.00721 1.50282 6.70028 1.99479 5.51256C2.48676 4.32484 3.31988 3.30968 4.3888 2.59545C5.45772 1.88122 6.71442 1.5 8 1.5C9.72335 1.50182 11.3756 2.18722 12.5942 3.40582C13.8128 4.62441 14.4982 6.27665 14.5 8ZM13.5 8C13.4992 7.25971 13.3491 6.52718 13.0587 5.84622C12.7683 5.16527 12.3436 4.54987 11.8099 4.03683C11.2762 3.5238 10.6445 3.12366 9.95264 2.86035C9.26075 2.59704 8.52287 2.47597 7.78313 2.50437C4.83938 2.61812 2.49188 5.07 2.5 8.01562C2.50283 9.35658 2.99739 10.6499 3.89 11.6506C4.25352 11.1234 4.71528 10.6712 5.25 10.3188C5.29559 10.2886 5.34979 10.2743 5.40431 10.2779C5.45883 10.2815 5.51066 10.3029 5.55188 10.3387C6.23136 10.9265 7.09973 11.2499 7.99813 11.2499C8.89652 11.2499 9.76489 10.9265 10.4444 10.3387C10.4856 10.3029 10.5374 10.2815 10.5919 10.2779C10.6465 10.2743 10.7007 10.2886 10.7463 10.3188C11.2817 10.671 11.7441 11.1232 12.1081 11.6506C13.0052 10.6463 13.5007 9.34662 13.5 8Z"
          fill="#2D1285"
        />
      </g>
      <defs>
        <clipPath id="clip0_337_19723">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
