import { CreditsCard } from "components/CreditsCard";
import { CreditsTable } from "components/CreditsTable/CreditsTable";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { DocumentPreviewModal } from "components/PreviewModal";
import { PromoCard, PromoCardItem } from "components/PromoCard/PromoCard";
import { ReferralTable } from "components/Refer/ReferralTable";
import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import ReferCoins from "static/images/ReferCoins.svg";
import { useGetCreditsQuery } from "store/apis/refrral";

const CreditHistory = () => {
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const { uuid: groupId } = useCurrentGroupContext();

  const { isLoading, isSuccess, refetch } = useGetCreditsQuery(
    { groupId, pageNum: page },
    { skip: !groupId }
  );

  const {
    authtoken: { referral_link },
  } = useContext(authContext);

  const { alertToast, successToast } = useToast();

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(referral_link);
      successToast({ message: COPIED_TEXT });
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
    return false;
  };

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <div className="t-pb-16">
      <div className="t-mb-8 t-flex t-gap-6">
        <CreditsCard refetch={refetch} />
        <PromoCard
          title="How to earn credits:"
          image={<img src={ReferCoins} alt="ReferCoinsIllustration" />}
          CTA={
            <Button
              customType="primary-outlined"
              block
              onClick={copyToClipBoard}
            >
              Copy referral link
            </Button>
          }
        >
          <PromoCardItem>Invite your friends </PromoCardItem>
          <PromoCardItem>
            Receive <span className="t-text-purple">$100 Inkle credits.</span>
          </PromoCardItem>
        </PromoCard>
      </div>

      <Tab.Root defaultValue="CREDITS">
        <Header
          v2
          title="History"
          bottom={
            <Tab.List>
              <Tab.Trigger value="CREDITS">Credits</Tab.Trigger>
              <Tab.Trigger value="REFERRAL">Referral</Tab.Trigger>
            </Tab.List>
          }
        />
        <div>
          <Tab.Content value="CREDITS">
            <CreditsTable />
          </Tab.Content>
          <Tab.Content value="REFERRAL">
            <ReferralTable />
          </Tab.Content>
        </div>
      </Tab.Root>

      <DocumentPreviewModal />
    </div>
  );
};

export default CreditHistory;
