export const Billing = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6385_3091)">
        <mask
          id="mask0_6385_3091"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="12"
          width="11"
          height="3"
        >
          <path
            d="M2.68579 12.2379H12.2692V13.5157C12.2692 13.8686 11.9831 14.1546 11.6302 14.1546H3.96357C3.25787 14.1546 2.68579 13.5825 2.68579 12.8769V12.2379Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_6385_3091)">
          <path
            d="M10.804 14.4129H4.66365V13.8963H10.7179L10.804 14.4129ZM12.1906 14.2166L12.1625 14.0479L12.3312 14.076C12.2896 14.1279 12.2425 14.175 12.1906 14.2166ZM3.22255 13.5769C3.23586 13.591 3.24957 13.6047 3.26365 13.618V14.2445C2.97717 14.0976 2.74288 13.8633 2.59595 13.5769H3.22255Z"
            fill="#706A85"
            stroke="#706A85"
            stroke-width="1.4"
          />
        </g>
        <path
          d="M8 5.33333L8 6"
          stroke="#706A85"
          stroke-width="0.933333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 8.66667V9.33333"
          stroke="#706A85"
          stroke-width="0.933333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.00008 8.66667H8.50008C8.7211 8.66667 8.93306 8.59643 9.08934 8.4714C9.24562 8.34638 9.33341 8.17681 9.33341 8C9.33341 7.82319 9.24562 7.65362 9.08934 7.5286C8.93306 7.40357 8.7211 7.33333 8.50008 7.33333H7.50008C7.27907 7.33333 7.06711 7.2631 6.91083 7.13807C6.75455 7.01305 6.66675 6.84348 6.66675 6.66667C6.66675 6.48986 6.75455 6.32029 6.91083 6.19526C7.06711 6.07024 7.27907 6 7.50008 6H9.00008"
          stroke="#706A85"
          stroke-width="0.933333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3 3.33333H4H11.3333C11.7015 3.33333 12 3.63181 12 4V11.3333M4 5V12C4 12.7364 4.59695 13.3333 5.33333 13.3333H8.66667"
          stroke="#706A85"
          stroke-width="1.4"
          stroke-linejoin="round"
        />
        <path
          d="M2 5.33333V4.33333C2 3.78105 2.44772 3.33333 3 3.33333V3.33333C3.55228 3.33333 4 3.78105 4 4.33333V5.33333"
          stroke="#706A85"
          stroke-width="1.4"
          stroke-linejoin="round"
        />
        <path
          d="M5.33325 13.3333H5.84141C6.2096 13.3333 6.50808 13.0349 6.50808 12.6667V11.3333L13.9999 11.3333V12C13.9999 12.7364 13.403 13.3333 12.6666 13.3333H5.33325Z"
          stroke="#706A85"
          stroke-width="1.4"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6385_3091">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
