export const InkleBooks = () => (
  <svg
    width="181"
    height="56"
    viewBox="0 0 181 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.1038 29.4121H26.5685C26.512 29.4121 26.5245 29.5266 26.7031 29.6182C26.8459 29.6915 26.8663 29.9469 26.8586 30.0654V38.0009H30.1263V30.154C30.0065 29.6237 29.0677 29.4385 28.6133 29.4121H28.1038Z"
      fill="url(#paint0_linear_5723_7753)"
    />
    <path
      d="M22.7893 26.5625H21.254C21.1975 26.5625 21.2101 26.715 21.3886 26.837C21.5315 26.9345 21.5518 27.2746 21.5441 27.4325V37.9996H24.8119V27.5504C24.692 26.8443 23.7532 26.5976 23.2988 26.5625H22.7893Z"
      fill="url(#paint1_linear_5723_7753)"
    />
    <path
      d="M17.5652 23.334H16.0299C15.9734 23.334 15.9859 23.529 16.1645 23.685C16.3074 23.8097 16.3277 24.2446 16.32 24.4465V37.9596H19.5877V24.5973C19.4679 23.6943 18.5291 23.3788 18.0747 23.334H17.5652Z"
      fill="url(#paint2_linear_5723_7753)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.4285 20.8513H16V18H27.4285C28.8476 18 30.9444 18.4131 32.7275 19.6943C34.5835 21.0279 35.9999 23.2379 35.9999 26.5539V37.959H33.1428V26.5539C33.1428 24.1672 32.1783 22.8132 31.0581 22.0083C29.865 21.151 28.3904 20.8513 27.4285 20.8513Z"
      fill="url(#paint3_linear_5723_7753)"
    />
    <path
      d="M46 20.378C46 19.1746 46.78 18.3945 48.0058 18.3945C49.2092 18.3945 49.9893 19.1746 49.9893 20.378C49.9893 21.5815 49.2092 22.3838 48.0058 22.3838C46.78 22.3838 46 21.5815 46 20.378ZM49.7887 37.4939H46.2229V24.1221H49.7887V37.4939Z"
      fill="url(#paint4_linear_5723_7753)"
    />
    <path
      d="M60.7289 30.7857C60.7289 28.8245 59.7037 27.5319 58.1214 27.5319C56.6282 27.5319 55.5362 28.9137 55.5362 30.808V37.4939H51.9703V24.1221H55.5362V25.6822C56.0042 24.6793 57.3859 23.9661 58.7677 23.9661C62.1775 23.9661 64.2947 26.685 64.2947 30.7857V37.4939H60.7289V30.7857Z"
      fill="url(#paint5_linear_5723_7753)"
    />
    <path
      d="M70.0118 37.4939H66.446V18.5505H70.0118V30.1171L74.5359 24.1221H78.4361L73.8896 30.808L78.9041 37.4939H74.3799L70.0118 31.4989V37.4939Z"
      fill="url(#paint6_linear_5723_7753)"
    />
    <path
      d="M83.8047 37.4939H80.2389V18.5505H83.8047V37.4939Z"
      fill="url(#paint7_linear_5723_7753)"
    />
    <path
      d="M94.646 33.3932H98.4346C97.5209 35.8893 95.1363 37.6053 92.4173 37.6053C88.5172 37.6053 85.776 34.775 85.776 30.7634C85.776 26.8856 88.6064 23.9438 92.3505 23.9438C96.0946 23.9438 98.9249 26.8856 98.9249 30.7634C98.9249 31.1869 98.9026 31.6326 98.8358 32.0338H89.5201C89.9213 33.3932 91.0133 34.2624 92.395 34.2624C93.4425 34.2624 94.2671 33.9058 94.646 33.3932ZM89.5201 29.4708H95.1808C94.7797 28.1559 93.7322 27.3091 92.3505 27.3091C90.9687 27.3091 89.9213 28.1559 89.5201 29.4708Z"
      fill="url(#paint8_linear_5723_7753)"
    />
    <path
      d="M103.925 37.4835V20.1523H110.449C113.447 20.1523 115.384 21.9059 115.384 24.8216C115.384 26.5343 114.67 27.8392 113.406 28.5529C115.139 29.2665 116.159 30.7142 116.159 32.5696C116.159 35.4242 114.099 37.4835 111.224 37.4835H103.925ZM110.449 22.334H106.127V27.6965H110.633C112.183 27.6965 113.182 26.6159 113.182 24.9643C113.182 23.3739 112.121 22.334 110.449 22.334ZM111.224 35.2814C112.876 35.2814 113.956 34.1192 113.956 32.4881C113.956 30.9181 112.692 29.7559 110.633 29.7559H106.127V35.2814H111.224Z"
      fill="#41395C"
    />
    <path
      d="M117.759 31.3462C117.759 27.8188 120.369 25.107 123.753 25.107C127.118 25.107 129.707 27.8188 129.707 31.3462C129.707 34.894 127.118 37.6059 123.753 37.6059C120.369 37.6059 117.759 34.894 117.759 31.3462ZM123.753 27.2887C121.612 27.2887 119.961 29.0422 119.961 31.3462C119.961 33.6707 121.612 35.4038 123.753 35.4038C125.894 35.4038 127.525 33.6707 127.525 31.3462C127.525 29.0422 125.894 27.2887 123.753 27.2887Z"
      fill="#41395C"
    />
    <path
      d="M131.224 31.3462C131.224 27.8188 133.834 25.107 137.219 25.107C140.583 25.107 143.172 27.8188 143.172 31.3462C143.172 34.894 140.583 37.6059 137.219 37.6059C133.834 37.6059 131.224 34.894 131.224 31.3462ZM137.219 27.2887C135.078 27.2887 133.426 29.0422 133.426 31.3462C133.426 33.6707 135.078 35.4038 137.219 35.4038C139.359 35.4038 140.991 33.6707 140.991 31.3462C140.991 29.0422 139.359 27.2887 137.219 27.2887Z"
      fill="#41395C"
    />
    <path
      d="M147.197 37.4835H144.995V20.1523H147.197V31.02L152.356 25.2497H154.803L149.624 31.3666L155.251 37.4835H152.417L147.197 31.7133V37.4835Z"
      fill="#41395C"
    />
    <path
      d="M155.537 33.5891H157.616C157.678 34.7717 158.86 35.7096 160.267 35.73C161.572 35.7504 162.51 35.1387 162.51 34.16C162.51 32.9978 161.572 32.5085 159.696 31.9987C157.8 31.4686 155.944 30.7549 155.944 28.5121C155.944 26.412 157.82 25.107 160.226 25.107C162.592 25.107 164.263 26.5547 164.467 28.7568H162.49C162.347 27.7373 161.409 26.9829 160.206 26.9829C159.044 26.9625 158.147 27.5946 158.126 28.4713C158.126 29.6743 159.615 30.0209 160.451 30.2452C162.306 30.7142 164.692 31.2647 164.692 34.1396C164.692 36.1582 162.775 37.6059 160.206 37.6059C157.902 37.6059 155.598 36.0766 155.537 33.5891Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7753"
        x1="26.5386"
        y1="33.7065"
        x2="30.1263"
        y2="33.7065"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7753"
        x1="21.2241"
        y1="32.2811"
        x2="24.8119"
        y2="32.2811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7753"
        x1="16"
        y1="30.6468"
        x2="19.5878"
        y2="30.6468"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7753"
        x1="16"
        y1="27.9795"
        x2="35.9999"
        y2="27.9795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5723_7753"
        x1="46"
        y1="27.9999"
        x2="98.9249"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5723_7753"
        x1="46"
        y1="27.9999"
        x2="98.9249"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5723_7753"
        x1="46"
        y1="27.9999"
        x2="98.9249"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_5723_7753"
        x1="46"
        y1="27.9999"
        x2="98.9249"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_5723_7753"
        x1="46"
        y1="27.9999"
        x2="98.9249"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
