import { axiosInstance } from "./apiInstance";

export const putDocData = async ({ docId, body }) => {
  const response = await axiosInstance.put(
    `/api/inkle/tasks/doc_instance/${docId}/`,
    body
  );

  return response.data;
};
