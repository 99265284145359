export const ChatText = ({ stroke = "1.5" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20312 18.2731L4.24726 20.7528C4.14255 20.8394 4.01542 20.8946 3.88061 20.9118C3.7458 20.9292 3.60885 20.9079 3.48563 20.8506C3.36242 20.7932 3.258 20.7021 3.18449 20.5877C3.11098 20.4734 3.07139 20.3406 3.07031 20.2047V5.9375C3.07031 5.74688 3.14604 5.56406 3.28083 5.42927C3.41562 5.29448 3.59844 5.21875 3.78906 5.21875H19.6016C19.7922 5.21875 19.975 5.29448 20.1098 5.42927C20.2446 5.56406 20.3203 5.74688 20.3203 5.9375V17.4375C20.3203 17.6281 20.2446 17.811 20.1098 17.9457C19.975 18.0805 19.7922 18.1562 19.6016 18.1562H7.53554L7.20312 18.2731Z"
      stroke="currentColor"
      strokeOpacity="0.8"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.82031 10.25H14.5703"
      stroke="currentColor"
      strokeOpacity="0.8"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.82031 13.125H14.5703"
      stroke="currentColor"
      strokeOpacity="0.8"
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
