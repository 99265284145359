import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { usePageTitle } from "hooks/usePageTitle";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { AllNotifications } from "./AllNotifications";

export const AdminNotificationDashboard = () => {
  usePageTitle("Notifications");
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Notifications"
          bottom={
            <Tab.Root defaultValue={pathname} value={pathname}>
              <Tab.List>
                <Tab.NavTrigger exact value={url}>
                  All notifications
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      }
    >
      <Switch>
        <Route path={path}>
          <AllNotifications />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};
