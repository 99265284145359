import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import {
  ControlProps,
  MultiValue,
  SingleValue,
  components,
} from "react-select";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import {
  useAddStakeholderMutation,
  useGetStakeholderUpdateQuery,
  useGetStakeholdersQuery,
  useLazyGetAuthorizationURLGmailQuery,
  useSendStakeholderUpdateMutation,
  useUpdateStakeholderUpdateMutation,
} from "store/apis/stakeholderUpdate";
import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { ReactNode, useContext, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  INVESTOR_UPDATE_STARTED_GOOGLE_SSO,
  SENT_INVESTOR_UPDATE,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useToast } from "hooks/useToast";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowRight } from "components/icons/ArrowRight";
import { Divider } from "components/design/Divider";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { InvestorUpdateEditor } from "components/InvestorUpdateEditor";
import { openLink } from "utils/openLink";
import Gmail from "static/images/Gmail.png";
import Inkle from "static/images/inkle.png";
import authContext from "jwt_context&axios/authContext";

const Control = ({ children, ...props }: ControlProps<OptionData>) => (
  <components.Control
    {...props}
    className={classNames("t-p-0 t-border-none", {
      "group-[&]/small:t-py-1 group-[&]/large:t-py-2.5 t-py-2": props.isMulti,
      "group-[&]/small:t-py-1.5 group-[&]/large:t-py-3.5 t-py-2.5":
        !props.isMulti,
    })}
  >
    {children}
  </components.Control>
);

export const InvestorUpdateNewPreivewModal = ({
  show,
  onChangeShow,
}: {
  show: boolean;
  onChangeShow: () => void;
}) => {
  const group = useCurrentGroup();
  const { investorUpdateId } = useParams<{ investorUpdateId?: string }>();
  const query = useQuery();
  const selectedSh = query.get("stakeHolders")
    ? query.get("stakeHolders")?.split(",")
    : [];

  const selectedCCEmails = query.get("cc") ? query.get("cc")?.split(",") : [];
  const showCCByDefault = (selectedCCEmails?.length || 0) > 0;

  const [stakeholderIds, setStatkeholderIds] = useState<string[]>(
    selectedSh || []
  );

  const [ccEmails, setCCEmails] = useState<string[]>(selectedCCEmails || []);

  const [showCC, setShowCC] = useState<boolean>(showCCByDefault);

  const history = useHistory();

  const [subject, setSubject] = useState<string>();
  const { trackEvent } = useAnalytics();
  const { successToast, alertToast } = useToast();
  const { update } = useUpdateQuery();

  const [getGmailUrl, { isLoading: isGmailUrlLoading }] =
    useLazyGetAuthorizationURLGmailQuery();

  const { search } = useLocation();
  const { url } = useRouteMatch();

  const { authtoken } = useContext(authContext);

  const { is_gmail_access_token_available } = authtoken || {};

  const {
    data: stakeholderUpdate,
    isSuccess: stakeholderUpdateLoaded,
    isFetching: isRefreshing,
  } = useGetStakeholderUpdateQuery(
    {
      groupId: group?.uuid!,
      updateId: investorUpdateId!,
    },
    {
      skip: !group?.uuid || !investorUpdateId,
    }
  );

  const { data: stakeholders, isSuccess: stakeholdersLoaded } =
    useGetStakeholdersQuery(
      {
        groupId: group?.uuid!,
      },
      {
        skip: !group?.uuid,
      }
    );

  const preSelectedStakeholders = stakeholders?.holders
    .filter((s) => stakeholderIds.includes(s.uuid))
    .map((s) => ({ label: s.email, value: s.uuid }));

  const preSelectedCCEmails = stakeholders?.holders
    .filter((s) => ccEmails.includes(s.uuid))
    .map((s) => ({ label: s.email, value: s.uuid }));

  const onFormChange: React.FormEventHandler<HTMLFormElement> = (e) => {
    // @ts-ignore
    setSubject(e.target.value);
  };

  const onStakeholderChange = (
    values?: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    if (values && values instanceof Array) {
      setStatkeholderIds(values.map((v) => v.value));
      updateSHQuery(values.map((v) => v.value).join(","));
    }
  };

  const [sendStakeholderUpdate, { isLoading: sendingStakeholderUpdate }] =
    useSendStakeholderUpdateMutation();
  const [addStakeholder, { isLoading: addingStakeholder }] =
    useAddStakeholderMutation();

  const onCreateOption = (inputValue: string) => {
    if (group?.uuid) {
      addStakeholder({
        groupId: group.uuid,
        payload: { name: inputValue, email: inputValue },
      });
    }
  };

  const updateSHQuery = (value: string | null) => {
    update({
      query: "stakeHolders",
      value,
    });
  };

  const updateCCQuery = (value: string | null) => {
    update({
      query: "cc",
      value,
    });
  };

  const onCCChange = (
    values?: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    if (values && values instanceof Array) {
      setCCEmails(values.map((v) => v.value));
      updateCCQuery(values.map((v) => v.value).join(","));
    }
  };

  const sendUpdate = async (
    { useInkle }: { useInkle: boolean } = { useInkle: false }
  ) => {
    try {
      if (stakeholderUpdate && group?.uuid) {
        await sendStakeholderUpdate({
          groupId: group.uuid,
          updateId: stakeholderUpdate?.uuid,
          stakeholders: stakeholderIds,
          ccEmails: preSelectedCCEmails?.map((p) => p.label) || [],
          emailSenderInkle: useInkle,
          subject,
        }).unwrap();

        trackEvent(SENT_INVESTOR_UPDATE, {
          ccSelected: Array.from(stakeholderIds),
          recipientsSelected: [],
          emailSenderInkle: useInkle,
        });

        successToast({
          message: "Your mail was successfully sent.",
          title: "Mail sent",
        });
        history.push("/community/investor-updates");
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const sendViaInkle = () => sendUpdate({ useInkle: true });

  const sendViaGmail = async () => {
    try {
      if (!is_gmail_access_token_available) {
        const { authorization_url } = await getGmailUrl({
          callbackUrl: `${url}/send-updated/${search}`,
        }).unwrap();
        trackEvent(INVESTOR_UPDATE_STARTED_GOOGLE_SSO);
        openLink(authorization_url);
      } else {
        sendUpdate({ useInkle: false });
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  if (!stakeholderUpdateLoaded || !stakeholdersLoaded || isRefreshing) {
    return (
      <div className="t-flex t-h-full t-w-full t-items-center t-justify-center">
        <div className="t-h-12 t-w-12 t-animate-spin t-rounded-full t-border-4 t-border-solid t-border-purple t-border-t-surface-transparent" />
      </div>
    );
  }

  const initialBlocks =
    stakeholderUpdateLoaded &&
    stakeholderUpdate?.block_json &&
    typeof stakeholderUpdate?.block_json === "string"
      ? JSON.parse(stakeholderUpdate?.block_json)
      : [];

  const noStakeholderSelected = preSelectedStakeholders?.length === 0;

  return (
    <Modal.Root open={show} onOpenChange={onChangeShow}>
      <Modal.Content size="xxl">
        <Modal.Header>
          <div className="t-w-full">
            <Modal.Title titleIcon={<Modal.Close />}>
              Preview and Send
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="!t-p-0">
          <Formik onSubmit={() => {}} initialValues={{}}>
            <Form
              onChange={onFormChange}
              className="t-m-0 t-w-full t-flex t-flex-col t-gap-2 t-px-6 t-py-3"
            >
              <div className="t-flex t-gap-2 t-w-full t-items-center t-justify-center">
                <span className="t-text-body t-text-text-30">To</span>
                <div className="t-flex-1">
                  <Combobox
                    components={{
                      Control,
                      DropdownIndicator: () => null,
                      ClearIndicator: () => null,
                    }}
                    defaultValue={preSelectedStakeholders}
                    autoFocus
                    creatable
                    menuPortalTarget={document.body}
                    onChange={onStakeholderChange}
                    onCreateOption={onCreateOption}
                    isMulti
                    withForm
                    name="stakeholders"
                    placeholder={
                      <span className="t-text-neutral-30">Enter here</span>
                    }
                    options={stakeholders?.holders.map((s) => ({
                      label: s.email,
                      value: s.uuid,
                    }))}
                  />
                </div>
                <Button onClick={() => setShowCC(true)} customType="ghost_icon">
                  CC
                </Button>
              </div>

              {showCC && (
                <div className="t-flex t-gap-2 t-w-full t-items-center t-justify-center">
                  <span className="t-text-body t-text-text-30">CC</span>
                  <div className="t-flex-1">
                    <Combobox
                      components={{
                        Control,
                        DropdownIndicator: () => null,
                        ClearIndicator: () => null,
                      }}
                      defaultValue={preSelectedCCEmails}
                      autoFocus
                      creatable
                      onChange={onCCChange}
                      onCreateOption={onCreateOption}
                      isMulti
                      withForm
                      name="stakeholders"
                      placeholder={
                        <span className="t-text-neutral-30">Enter here</span>
                      }
                      options={stakeholders?.holders.map((s) => ({
                        label: s.email,
                        value: s.uuid,
                      }))}
                    />
                  </div>
                </div>
              )}

              <div className="t-flex t-gap-2 t-w-full t-items-center t-justify-center">
                <span className="t-text-body t-text-text-30">Subject</span>
                <div className="t-flex-1">
                  <input
                    className="t-border-0 t-border-none t-outline-none t-w-full placeholder:t-text-neutral-30 placeholder:t-text-body"
                    placeholder="Enter here"
                  />
                </div>
              </div>
            </Form>
          </Formik>

          <div className="t-flex t-justify-center t-bg-surface-lighter-grey">
            <div className="t-w-[700px] t-pt-10 t-mb-20">
              <div className="t-pt-6 t-rounded-lg t-bg-surface">
                <InvestorUpdateEditor
                  config={{
                    initialContent: initialBlocks,
                    editable: false,
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-absolute t-bottom-0 t-left-0 t-flex t-w-full t-justify-end t-gap-3 t-bg-white t-px-6 t-py-4">
            <Button onClick={onChangeShow}>Cancel</Button>
            <Button
              onClick={sendViaGmail}
              customType="secondary"
              isLoading={isGmailUrlLoading || sendingStakeholderUpdate}
              disabled={
                isGmailUrlLoading ||
                noStakeholderSelected ||
                sendingStakeholderUpdate
              }
            >
              <div className="t-flex t-items-center">
                <span>Send via</span>
                <img className="t-ml-2 t-h-6" src={Gmail} alt="gmail" />
                <div className="t-ml-3 t-text-neutral-20 t-w-4">
                  <Divider />
                </div>
                <Dropdown.Root>
                  <Dropdown.Trigger asChild className="t-group t-ml-1">
                    <div
                      className="all:unset"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                        <ArrowRight stroke="2.5" color="currentColor" />
                      </div>
                    </div>
                  </Dropdown.Trigger>
                  <Dropdown.Portal>
                    <Dropdown.Content
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="t-z-dropdown"
                      sideOffset={10}
                    >
                      <Dropdown.Item
                        onSelect={sendViaInkle}
                        className="t-text-center"
                      >
                        <div className="t-flex t-items-center t-gap-2">
                          <span>Send via</span>
                          <img src={Inkle} alt="gmail" className="t-h-4" />
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={sendViaGmail}
                        className="t-text-center"
                      >
                        <div className="t-flex t-items-center t-gap-2">
                          <span>Send via</span>
                          <img src={Gmail} alt="gmail" className="t-h-6" />
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown.Portal>
                </Dropdown.Root>
              </div>
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
