import classNames from "classnames";
import Table from "components/DesignSystem/Table/Table";
import { Badge } from "components/Task/Badge";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable } from "react-table";
import Search from "static/images/Search.svg";
import { useGetRegisteredAgentQuery } from "store/apis/registerAgent";
import { closeSlider, openSlider } from "store/slices/registerAgent";
import { RootState } from "store/store";
import { RASilder } from "./RASilder";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

const Item = ({ children }: { children: ReactNode }) => {
  return <span className="t-text-body t-text-text-100">{children || "-"}</span>;
};

export const RATable = () => {
  const { show, raId } = useSelector(
    (state: RootState) => state.registerAgent.silder
  );
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: registeredAgents = [], isSuccess } = useGetRegisteredAgentQuery(
    { groupId, entityId },
    { skip: !groupId || !entityId }
  );

  const noRAFound = registeredAgents.length === 0 && isSuccess;
  const data = useMemo(() => registeredAgents, [registeredAgents]);

  const dates = !show
    ? [
        {
          Header: "Starting date",
          accessor: "start_date",
          width: "15%",
          Cell: ({ value }: { value: string }) => {
            return <Item>{value}</Item>;
          },
        },
        {
          Header: "Expiry date",
          accessor: "expiration_date",
          width: "15%",
          Cell: ({ value }: { value: string }) => {
            return <Item>{value}</Item>;
          },
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        width: show ? "20%" : "15%",
        Cell: ({ value }: any) => {
          return (
            <Item>
              <Badge type={value}>{value}</Badge>
            </Item>
          );
        },
      },
      {
        Header: "State",
        accessor: "state",
        width: show ? "60%" : "40%",
        Cell: ({ value }: { value: string }) => {
          return <Item>{value}</Item>;
        },
      },
      {
        Header: "Agent name",
        accessor: "agent_name",
        width: show ? "20%" : "15%",
        Cell: ({ value }: { value: string }) => {
          return <Item>{value}</Item>;
        },
      },
      ...dates,
    ],
    [show]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      data,
    });

  return (
    <div className="t-flex t-gap-2 sm:t-flex-col">
      <div className="t-h-max t-w-full">
        <Table.Container>
          <Table.Content {...getTableProps()} className="all:unset">
            <Table.Head>
              {headerGroups.map((headerGroup: any) => (
                <Table.Row
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-2"
                >
                  {headerGroup.headers.map((column: any) => (
                    <th
                      className="t-text-start"
                      key={column.id}
                      {...column.getHeaderProps({})}
                      style={{ width: column?.width }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>

            {noRAFound ? (
              <div className="t-flex t-h-[calc(100vh-260px)] t-flex-col t-items-center t-justify-center t-gap-4 t-text-subtitle-sm t-font-medium t-text-text-30">
                <img src={Search} alt="No Filing found" />
                Look's like you don't have any register agent
              </div>
            ) : (
              <Table.Body {...getTableBodyProps()}>
                {rows.map((row: any) => {
                  prepareRow(row);
                  const {
                    original: { agent_group_mapping_uuid },
                  } = row;

                  return (
                    <Table.Row
                      className={classNames(
                        "t-flex t-cursor-pointer t-select-none t-items-center t-justify-center t-gap-6 t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent t-px-4 t-py-2 hover:t-bg-i-surface-light-purple",
                        {
                          "t-bg-i-surface-light-purple":
                            raId === agent_group_mapping_uuid,
                        }
                      )}
                      key={row.id}
                      {...row.getRowProps()}
                      onClick={() => {
                        if (raId !== agent_group_mapping_uuid) {
                          dispatch(openSlider(agent_group_mapping_uuid));
                        } else {
                          dispatch(closeSlider());
                        }
                      }}
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            key={cell.value}
                            {...cell.getCellProps({})}
                            style={{ width: cell.column?.width }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            )}
          </Table.Content>
        </Table.Container>
      </div>
      <RASilder />
    </div>
  );
};
