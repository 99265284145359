import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { OptionDropdown } from "components/icons/optionDropdown";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import {
  useDeleteEntityInvoiceMutation,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceMutation,
  useLazyDownloadInvoiceQuery,
  invoiceApis,
  Invoice,
  useLazyGetEntityInvoiceQuery,
  useLazyGetInvoiceItemsQuery,
  useCreateEntityInvoiceMutation,
} from "store/apis/invoices";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { openLink } from "utils/openLink";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import { BackendError } from "types/utils/error";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useAnalytics } from "hooks/useAnalytics";
import {
  DUPLICATED_INVOICE_FROM_THREE_DOTS,
  MARKED_INVOICE_AS_PAID_FROM_THREE_DOTS,
  MARKED_INVOICE_AS_SENT_FROM_THREE_DOTS,
} from "constants/analyticsEvents";
import { useModal } from "hooks/useModal";
import { SendInvoiceModal } from "components/SendInvoiceModal/SendInvoiceModal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const InvoiceActions = ({
  invoice,
  isFromInvoiceDetails,
}: {
  invoice: Invoice;
  isFromInvoiceDetails?: boolean;
}) => {
  const group = useCurrentGroupContext();
  const history = useHistory();
  const [duplicating, setDuplicating] = useState(false);
  const { search } = useLocation();
  const sendInvoice = useModal();
  const entityId = useCurrentEntityId();

  const { customerId: customerIdFromUrl } = useParams<{
    customerId?: string;
  }>();

  const [
    deleteInvoice,
    { isLoading: deletingInvoice, originalArgs: deletingArgs },
  ] = useDeleteEntityInvoiceMutation();

  const { data: invoiceSetting } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const [
    updateInvoice,
    { isLoading: updatingInvoice, originalArgs: updateInvoiceArgs },
  ] = useUpdateInvoiceMutation();

  const [
    getInvoiceDownloadUrl,
    { isLoading: downloadingInvoice, originalArgs: invoiceDownloadArgs },
  ] = useLazyDownloadInvoiceQuery();

  const [getInvoice] = useLazyGetEntityInvoiceQuery();
  const [getInvoiceItems] = useLazyGetInvoiceItemsQuery();
  const [createInvoice] = useCreateEntityInvoiceMutation();

  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const { alertToast } = useToast();
  const dispatch = useAppDispatch();
  const { trackEvent } = useAnalytics();

  const invoiceId = invoice.uuid;
  const customerId = invoice.customer_id;
  const status = invoice.status;

  const markInvoiceAs = async (status: Invoice["status"]) => {
    if (group?.uuid && customerId) {
      try {
        const invoice = await updateInvoice({
          entityId: entityId!,
          groupId: group?.uuid!,
          invoiceId,
          payload: {
            status,
          },
        }).unwrap();

        if (invoice.customer) {
          dispatch(
            invoiceApis.util.invalidateTags([
              { type: "INVOICE_CUSTOMERS", id: invoice.customer.uuid },
            ])
          );
        }
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const onDelete = async () => {
    try {
      await deleteInvoice({
        groupId: group.uuid,
        entityId: entityId!,
        invoiceId,
      }).unwrap();
      dispatch(
        invoiceApis.util.invalidateTags([
          { type: "INVOICE_CUSTOMERS", id: customerId },
        ])
      );
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const downloadInvoice = async () => {
    try {
      if (group.uuid && entityId) {
        const { doc_id } = await getInvoiceDownloadUrl({
          groupId: group.uuid,
          entityId,
          invoiceId: invoiceId,
        }).unwrap();

        const { download_url } = await getPreviewUrl({
          groupId: group.uuid!,
          fileId: doc_id,
        }).unwrap();
        openLink(download_url, "_blank");
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const duplicate = async () => {
    setDuplicating(true);
    try {
      if (group.uuid && entityId) {
        const {
          uuid,
          customer_id,
          due_date,
          invoice_date,
          updated_at,
          document,
          customer,
          status,
          sent_to,
          sent_on,
          title,
          ...currentInvoice
        } = await getInvoice({
          groupId: group.uuid,
          entityId,
          invoiceId,
        }).unwrap();

        const currentInvoiceItems = await getInvoiceItems({
          groupId: group.uuid,
          entityId,
          invoiceId,
        }).unwrap();

        const newInvoice = await createInvoice({
          groupId: group.uuid,
          entityId,
          payload: {
            ...currentInvoice,
            customer_id: customerIdFromUrl || invoice.customer_id,
            invoice_items: currentInvoiceItems,
          },
        }).unwrap();
        if (isFromInvoiceDetails) {
          openLink(`/books/invoicing/${newInvoice.uuid}/${search}`);
        } else {
          history.push(`/books/invoicing/${newInvoice.uuid}/${search}`);
        }
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
    setDuplicating(false);
  };

  const handleMarkAsSent = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    trackEvent(MARKED_INVOICE_AS_SENT_FROM_THREE_DOTS);
    markInvoiceAs(INVOICE_STATUSES.SENT);
  };

  const handleMarkAsPaid = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    trackEvent(MARKED_INVOICE_AS_PAID_FROM_THREE_DOTS);
    markInvoiceAs(INVOICE_STATUSES.PAID);
  };

  const handleInvoiceDuplicate = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    trackEvent(DUPLICATED_INVOICE_FROM_THREE_DOTS);
    duplicate();
  };

  const openSentInvoiceEditFlow = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    let currentLink = `/books/invoicing/${invoiceId}${search}`;
    if (search) {
      currentLink += "&editflow=true";
    } else {
      currentLink += "?editflow=true";
    }
    history.push(currentLink);
  };

  const openSendInvoice = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    sendInvoice.open();
  };

  const isLoading =
    (updatingInvoice && updateInvoiceArgs?.invoiceId === invoiceId) ||
    (downloadingInvoice && invoiceDownloadArgs?.invoiceId === invoiceId) ||
    (deletingInvoice && deletingArgs?.invoiceId === invoiceId) ||
    duplicating;

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <span>
          <Button
            customType={isFromInvoiceDetails ? "icon" : "ghost_icon"}
            size="small"
            isLoading={isLoading}
            disabled={isLoading}
          >
            <OptionDropdown color="currentColor" />
          </Button>
        </span>
      </Dropdown.Trigger>
      <Dropdown.Content align="end">
        {status !== INVOICE_STATUSES.SENT && (
          <ConditionalToolTip
            side="left"
            condition={!customerId && "No customer assigned"}
          >
            <span>
              <Dropdown.Item disabled={!customerId} onClick={handleMarkAsSent}>
                Mark as sent
              </Dropdown.Item>
            </span>
          </ConditionalToolTip>
        )}
        {status !== INVOICE_STATUSES.PAID && (
          <ConditionalToolTip
            side="left"
            condition={!customerId && "No customer assigned"}
          >
            <span>
              <Dropdown.Item disabled={!customerId} onClick={handleMarkAsPaid}>
                Mark as paid
              </Dropdown.Item>
            </span>
          </ConditionalToolTip>
        )}
        <Dropdown.Item
          onClick={(e) => {
            e.stopPropagation();
            downloadInvoice();
          }}
        >
          Download
        </Dropdown.Item>
        {status !== INVOICE_STATUSES.DRAFT && (
          <Dropdown.Item onClick={handleInvoiceDuplicate}>
            Duplicate
          </Dropdown.Item>
        )}
        {invoice.status === INVOICE_STATUSES.DRAFT && (
          <Dropdown.Item
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <span className="t-text-red-50">Delete</span>
          </Dropdown.Item>
        )}
        {status === INVOICE_STATUSES.SENT && (
          <Dropdown.Item onClick={openSentInvoiceEditFlow}>Edit</Dropdown.Item>
        )}
        {!isFromInvoiceDetails && status === INVOICE_STATUSES.SENT && (
          <Dropdown.Item onClick={openSendInvoice}>Remind</Dropdown.Item>
        )}
      </Dropdown.Content>
      {sendInvoice.isOpen && (
        <div onClick={(e) => e.stopPropagation()}>
          <SendInvoiceModal
            isOpen={sendInvoice.isOpen}
            close={sendInvoice.close}
            invoiceFromProp={invoiceId}
            isReminder
          />
        </div>
      )}
    </Dropdown.Root>
  );
};
