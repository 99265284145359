import { profileData } from "apis/profileData";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { CHANGE_ADMIN } from "constants/autofill";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import React, { useContext, useEffect } from "react";
import { TeamMember, useChangeAdminMutation } from "store/apis/teamSettingCPA";

export const MakeAdminModal = ({
  data,
  showMakeAdmin,
  setShowMakeAdmin,
}: {
  data: TeamMember;
  showMakeAdmin: boolean;
  setShowMakeAdmin: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const { updateUser } = useContext(authContext);
  const { name, uuid, service_team } = data;
  const { successToast, alertToast } = useToast();

  const [changeAdmin, { isLoading }] = useChangeAdminMutation();

  const makeAdminHandler = async () => {
    const payload = { action: CHANGE_ADMIN };
    try {
      await changeAdmin({ memberId: uuid, payload }).unwrap();
      successToast({ message: "Admin changed" });
      profileData().then((res: any) => updateUser(res));
      window.location.reload();
    } catch (error) {
      alertToast({ message: "Changing Admin failed" });
    }
    setShowMakeAdmin(false);
  };

  return (
    <Modal.Root open={showMakeAdmin} onOpenChange={setShowMakeAdmin}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Make Admin</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-text-body-lg">
          Are you sure you want make <b>{name}</b> the admin of&nbsp;
          <b>{service_team}</b>
          ?
          <br />
          <br />
          You will lose admin rights & become a member.
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={() => setShowMakeAdmin(false)}>Cancel</Button>
          <Button
            customType="danger"
            onClick={makeAdminHandler}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
