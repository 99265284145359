import { Button, ButtonProps } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Cross } from "components/icons/Cross";
import { motion } from "framer-motion";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-table-8.10.7";
import { setPopulateTransactionIDs } from "store/slices/chat";
import { setSelectedTransactionIds } from "store/slices/transactions";
import { RootState } from "store/store";
import { Transactions, TxnAccountType } from "types/Models/books";
import { BulkAssignVendor } from "./BulkAssignVendor";
import { BulkCategorise } from "./BulkCategorise";
import { TxnFilter } from "./Filter";
import { StripeTxnFilter } from "./StripeTxnFilter";
import { UncategoriseModal } from "./UncategoriseModal";

type TableSelectionControlsProps = {
  txnAccountType?: TxnAccountType;
  table: Table<Transactions>;
  sendToChat: ({
    transactionIds,
  }: {
    transactionIds: string[];
  }) => Promise<void>;
  onRequestInfo: ({
    transactionIds,
  }: {
    transactionIds: string;
  }) => Promise<void>;
  deselectRows: () => void;
};

export const TableSelectionControls = ({
  table,
  sendToChat,
  onRequestInfo,
  deselectRows,
  txnAccountType,
}: TableSelectionControlsProps) => {
  const dispatch = useDispatch();
  const { isAdmin, isCpa } = useRoleBasedView();
  const { populateTransactionIDsList } = useSelector(
    (state: RootState) => state.chat
  );

  const buttonProps = {
    size: "small",
    customType: "primary-outlined",
  } as ButtonProps;

  const transactionsSelected =
    table?.getSelectedRowModel?.()?.flatRows.length > 0;

  const transactionIds = useMemo(() => {
    return table.getSelectedRowModel().flatRows.map(
      ({
        original: {
          transaction: { uuid },
        },
      }) => uuid
    );
  }, [table.getSelectedRowModel()]);

  const bulkCategoriseModal = useModal();
  const bulkUncategoriseModal = useModal();
  const bulkAssignVendorModal = useModal();

  const onBulkTransactionsCategorise = () => {
    bulkCategoriseModal.open();
    dispatch(setSelectedTransactionIds(transactionIds));
  };

  const onBulkUncategorise = () => {
    bulkUncategoriseModal.open();
    dispatch(setSelectedTransactionIds(transactionIds));
  };

  const onBulkAssignVendor = () => {
    bulkAssignVendorModal.open();
    dispatch(setSelectedTransactionIds(transactionIds));
  };

  const resetViewTransactions = () => {
    dispatch(setPopulateTransactionIDs(""));
  };

  if (!transactionsSelected) {
    return (
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        key={transactionIds[0]}
        className="t-flex t-gap-2 t-items-center"
      >
        {populateTransactionIDsList && (
          <Button
            customType="secondary"
            onClick={resetViewTransactions}
            size="small"
          >
            <div className="t-flex t-text-body-sm t-text-text-30 t-items-center t-gap-1">
              <Cross />
              {table.getRowModel().rows.length} selected
            </div>
          </Button>
        )}
        {txnAccountType === "STRIPE" ? <StripeTxnFilter /> : <TxnFilter />}
      </motion.span>
    );
  }

  return (
    <>
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.2 }}
        key={transactionIds[0]}
        className="t-flex t-gap-2 t-items-center t-pt-2 t-flex-wrap"
      >
        <Checkbox
          indeterminate
          onChange={deselectRows}
          name="selected"
          label={
            <div className="t-flex t-text-body-sm t-text-text-30 t-items-center">
              {table.getSelectedRowModel().flatRows.length} selected
            </div>
          }
        />

        <div className="t-flex t-gap-2 t-flex-wrap">
          <Button {...buttonProps} onClick={onBulkTransactionsCategorise}>
            Categorise
          </Button>
          <Button {...buttonProps} onClick={onBulkUncategorise}>
            Uncategorise
          </Button>
          <Button
            {...buttonProps}
            onClick={() =>
              sendToChat({
                transactionIds,
              })
            }
          >
            Send to chat
          </Button>
          {(isAdmin || isCpa) && (
            <Button
              {...buttonProps}
              onClick={() =>
                onRequestInfo({
                  transactionIds: transactionIds.join(","),
                })
              }
            >
              Request info
            </Button>
          )}
          <Button {...buttonProps} onClick={onBulkAssignVendor}>
            Assign vendor
          </Button>
        </div>
      </motion.span>

      <BulkCategorise
        deselectRows={deselectRows}
        close={bulkCategoriseModal.close}
        isOpen={bulkCategoriseModal.isOpen}
      />

      {bulkUncategoriseModal.isOpen && (
        <UncategoriseModal
          deselectRows={deselectRows}
          close={bulkUncategoriseModal.close}
          isOpen={bulkUncategoriseModal.isOpen}
        />
      )}
      <BulkAssignVendor
        deselectRows={deselectRows}
        close={bulkAssignVendorModal.close}
        isOpen={bulkAssignVendorModal.isOpen}
      />
    </>
  );
};
