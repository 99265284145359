import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Tag, TagTypeProps } from "components/DesignSystem/Tag/Tag";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import Pencil from "components/icons/pencil";
import { Preview } from "components/PreviewModal";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { REGISTRATION_TYPE, REGISTRATION_TYPE_BADGE } from "dictionaries";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useMultipleFileDetails } from "hooks/useMultipleFileDetails";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useParams } from "react-router-dom";
import CopyIcon from "static/images/CopyPurple.svg";
import { useGetEntityAllStateDataQuery } from "store/apis/entity";
import { EntityDataAttribute, EntityStateData } from "types/Models/entity";
import { FileObject } from "types/Models/fileObject";
import { FileIcon } from "utils/fileTypeIcon";
import AddJurisdiction from "./AddJurisdiction";
import DeleteJurisdictions from "./DeleteJurisdictions";
import EditJurisdiction from "./EditJurisdiction";
import ConditionalToolTip from "components/design/conditionalToolTip";

const FilePrview = ({ file }: { file: FileObject }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { file_type, name } = file;

  const {
    close: closePreviewModal,
    isOpen: isPreviewModalOpen,
    open: openPreviewModal,
  } = useModal();

  return (
    <>
      <ConditionalToolTip condition={name}>
        <button
          className="all:unset t-flex t-items-start t-gap-1 t-w-10/12"
          onClick={openPreviewModal}
        >
          <FileIcon fileType={file_type} width={24} height={24} />
          <div className="t-line-clamp-1 t-ml-1 t-text-body t-text-text-black">
            {name}
          </div>
        </button>
      </ConditionalToolTip>
      <Preview
        showModal={isPreviewModalOpen}
        closeModal={closePreviewModal}
        groupId={groupId}
        previewId={file.uuid}
      />
    </>
  );
};

const String = ({ field }: { field: EntityDataAttribute }) => {
  const { can_copy, value } = field;
  const { copyToClipboard } = useCopyToClipboard();

  if (!value) return null;

  return (
    <div className="t-flex t-flex-col t-gap-0">
      <Label>{field.label}</Label>
      <div className="t-flex t-items-center t-text-body t-text-text-black t-gap-1">
        <span className="t-line-clamp-1"> {value}</span>
        {can_copy && (
          <button
            onClick={() => copyToClipboard(value as string)}
            className="all:unset"
          >
            <img src={CopyIcon} alt="copyIcon" />
          </button>
        )}
      </div>
    </div>
  );
};

const DateField = ({ field }: { field: EntityDataAttribute }) => {
  const { value } = field;

  if (!value) return null;

  return (
    <div className="t-flex t-flex-col t-gap-0">
      <Label>{field.label}</Label>
      <div className="t-flex t-items-center t-text-body t-text-text-black">
        {dayjs(value as string).format(DD_MMM_YYYY)}
      </div>
    </div>
  );
};

const FileField = ({ field }: { field: EntityDataAttribute }) => {
  const { value = [] } = field;
  const files = useMultipleFileDetails(value as string[]);

  if ((value as string[])?.length === 0) return null;

  return (
    <div className="t-flex t-flex-col t-gap-0">
      <Label>{field.label}</Label>
      <div className="t-flex t-flex-col t-items-start t-text-body t-text-text-black">
        {files.map((file) => (
          <FilePrview file={file} key={file.uuid} />
        ))}
      </div>
    </div>
  );
};

const FieldRenderer = ({ field }: { field: EntityDataAttribute }) => {
  const { type } = field;
  switch (type) {
    case "STRING":
      return <String field={field} />;
    case "NUMBER":
      return <String field={field} />;
    case "INPUT":
      return <String field={field} />;
    case "TEXTAREA":
      return <String field={field} />;
    case "DATE":
      return <DateField field={field} />;
    case "FILE":
      return <FileField field={field} />;
    default:
      return null;
  }
};

const StateCard = ({ stateData }: { stateData: EntityStateData }) => {
  const { isAdmin } = useRoleBasedView();

  const {
    state,
    registration_type,
    foreign_qualified_data_attributes = [],
    state_id,
    uuid,
  } = stateData;

  const isNotHomeState = registration_type !== "HOME_STATE";

  const editModal = useModal();
  const deleteModal = useModal();

  return (
    <div
      className="t-p-5 t-border t-border-solid t-rounded-lg t-border-neutral-10  t-gap-5 t-flex t-flex-col"
      aria-label={`state-card-${uuid}`}
    >
      <div className="t-flex t-items-center t-justify-between">
        <span className="t-text-text-100 t-text-subtitle t-flex t-gap-2 t-items-center">
          {state}
          <Tag
            tagType={
              REGISTRATION_TYPE_BADGE[
                registration_type
              ] as TagTypeProps["tagType"]
            }
            icon={false}
          >
            {REGISTRATION_TYPE[registration_type]}
          </Tag>
        </span>
        <div className="t-flex t-items-center t-gap-2">
          <Button size="small" customType="ghost_icon" onClick={editModal.open}>
            <span className="t-text-text-30">
              <Pencil color="currentColor" size="16" />
            </span>
          </Button>
          {isNotHomeState && isAdmin && (
            <Button
              size="small"
              customType="ghost_icon"
              onClick={deleteModal.open}
            >
              <span className="t-text-text-30">
                <DeleteIcon color="currentColor" size="16" />
              </span>
            </Button>
          )}
        </div>
      </div>

      <div className="t-grid t-grid-cols-2 t-gap-5">
        {foreign_qualified_data_attributes?.map((field) => (
          <FieldRenderer field={field} key={field.uuid} />
        ))}
      </div>
      <EditJurisdiction
        isOpen={editModal.isOpen}
        close={editModal.close}
        stateId={state_id}
      />
      <DeleteJurisdictions
        isOpen={deleteModal.isOpen}
        close={deleteModal.close}
        stateId={state_id}
      />
    </div>
  );
};

const Jurisdictions = () => {
  const { entityId } = useParams<{ entityId: string }>();
  const { uuid: groupId, entities } = useCurrentGroupContext();

  const entity = entities.find((entity) => entity.uuid === entityId);
  const { country_code } = entity || {};
  const isUSEntity = country_code === "US";

  const {
    data = [],
    isLoading,
    isSuccess,
  } = useGetEntityAllStateDataQuery(
    { entityId, groupId },
    { skip: !groupId || !entityId }
  );

  const { close, isOpen, open } = useModal();

  const isEmpty = data?.length === 0;

  if (isLoading) {
    return null;
  }

  return (
    <Async.Root isEmpty={isEmpty} isLoading={isLoading} isSuccess={isSuccess}>
      <Async.Empty>
        <div className="t-flex t-gap-3 t-flex-col">
          <div className="t-flex t-items-center t-justify-between t-text-subtitle t-text-text-100">
            Jurisdictions
            {isUSEntity && (
              <Button size="small" onClick={open}>
                Add
              </Button>
            )}
          </div>
        </div>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-gap-3 t-flex-col">
          <div className="t-flex t-items-center t-justify-between t-text-subtitle t-text-text-100">
            Jurisdictions
            {isUSEntity && (
              <Button size="small" onClick={open}>
                Add
              </Button>
            )}
          </div>
          {data?.map((stateData) => (
            <StateCard stateData={stateData} key={stateData.uuid} />
          ))}
        </div>
      </Async.Success>
      {isOpen && <AddJurisdiction isOpen={isOpen} close={close} />}
    </Async.Root>
  );
};

export default Jurisdictions;
