import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

// constants
import { ENTITY_PATH } from "../../constants/entity";

//style
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { ENTITY_CARD_CLICKED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import authContext from "jwt_context&axios/authContext";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import FileSearch from "static/images/FileSearch.svg";
import UsersThree from "static/images/UsersThree.svg";
import { setRestrictedChild } from "store/slices/entity";

const EntityTree = ({ tree, entities, refetch }) => {
  const location = useLocation();
  const path = location.pathname;
  const { url } = useRouteMatch();
  const {
    authtoken: { email, uuid },
  } = useContext(authContext);
  const { trackEvent } = useAnalytics();

  const routeTo = url === "/entities" ? "" : url;

  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();

  const flagSize = {
    width: "2.3em",
    height: "2.3em",
  };

  const checkPath = () => {
    if (path === ENTITY_PATH || path.includes("/crm")) return true;
    return false;
  };

  function countChild(parent) {
    const lastChildren =
      parent?.entity?.children?.children_data.slice(-1)[0]?.entity.entity_uuid;
    const indexOfLastChildren =
      parent?.entity?.children?.child_uuids.indexOf(lastChildren);
    let pseudoHeight = 1;

    if (indexOfLastChildren > 0) pseudoHeight = indexOfLastChildren + 1;
    else if (!parent.entity.children.children_data.length) pseudoHeight = 0;

    return pseudoHeight;
  }

  const handleEntityClick = (parent) => {
    trackEvent(ENTITY_CARD_CLICKED, {
      email,
      userId: uuid,
    });
    if (checkPath) {
      dispatch(setRestrictedChild(parent?.entity?.children?.child_uuids));
    }
  };

  return (
    <>
      <div className="t-col-sm-12 t-col-md-12 t-col-lg-4 t-mb-2 t-ml-[74px] t-hidden t-w-[700px] md:t-block">
        {tree?.map((parent) => {
          const level = countChild(parent);
          const arrowHeight = `${level * 224}px`;

          return (
            <div key={parent.entity.entity_uuid} className="t-relative">
              <ConditionalLink
                to={`${routeTo}/entity/${parent?.entity?.entity_uuid}`}
                role="button"
                onClick={() => handleEntityClick(parent)}
              >
                <div className="t-relative t-z-10 t-mb-4 t-flex t-h-52 t-items-center t-justify-items-start t-gap-8 t-rounded-md t-border t-border-solid t-border-neutral-0 t-bg-surface hover:t-cursor-pointer t-px-8 t-py-4 hover:t-border-purple-20 t-shadow-entity-card hover:t-shadow">
                  <div className="t-flex t-w-full t-flex-col">
                    <div className="t-flex t-items-center t-gap-3">
                      <h6 className="cardAbout countryFlag">
                        <ReactCountryFlag
                          countryCode={
                            entities.get(parent.entity.entity_uuid)
                              ?.country_code
                          }
                          svg
                          style={{
                            ...flagSize,
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          title={
                            entities.get(parent.entity.entity_uuid)?.country
                          }
                          className="t-rounded"
                        />
                      </h6>
                      <h6 className="t-text-subtitle t-m-0 t-text-text-100">
                        {parent.entity.entity_name}
                      </h6>
                    </div>
                    <div className="t-flex t-w-4/5 t-my-4">
                      <span className="t-overflow-hidden t-overflow-ellipsis t-pr-6 t-whitespace-nowrap t-text-body t-font-normal t-text-text-60 t-border-neutral-0">
                        <span className="t-text-body-sm t-font-light">
                          Country
                        </span>
                        <br />
                        {entities.get(parent.entity.entity_uuid)?.country}
                      </span>
                      <span className="t-h-7 t-m-2 t-border-neutral-0 t-border t-border-solid t-border-r t-border-l-0 t-border-t-0 t-border-b-0 " />
                      <span className="t-overflow-hidden t-overflow-ellipsis t-whitespace-nowrap t-text-body t-px-6 t-font-normal t-text-text-60 t-border-neutral-0">
                        <span className="t-text-body-sm t-font-light">
                          State
                        </span>
                        <br />
                        {entities.get(parent.entity.entity_uuid)?.state}
                      </span>
                      <span className="t-h-7 t-m-2 t-border-neutral-0 t-border t-border-solid t-border-r t-border-l-0 t-border-t-0 t-border-b-0" />
                      <span className=" t-overflow-hidden t-overflow-ellipsis t-pl-6 t-whitespace-nowrap t-text-body t-font-normal t-text-text-60">
                        <span className="t-text-body-sm t-font-light">
                          Formation
                        </span>
                        <br />
                        {
                          entities.get(parent.entity.entity_uuid)
                            ?.formation_type
                        }
                      </span>
                    </div>
                    <Divider />
                    <div className="t-flex t-gap-3 t-text-subtext-sm t-mt-4">
                      <div
                        role="button"
                        className="t-py-2 t-px-4 t-flex t-gap-2 t-text-text-60 t-rounded hover:t-bg-surface-lighter-grey"
                      >
                        <img src={FileSearch} alt="FileSearch" />
                        <div>Overview</div>
                      </div>
                      <ConditionalLink
                        role="button"
                        className="t-py-2 t-px-4 t-flex t-gap-2 t-text-text-60 t-rounded hover:t-bg-surface-lighter-grey t-whitespace-nowrap"
                        to={`${routeTo}/entity/${parent?.entity?.entity_uuid}/directors-officers`}
                      >
                        <img src={UsersThree} alt="UsersThree" />
                        <div className="t-text-text-60">
                          Directors & Officers
                        </div>
                      </ConditionalLink>
                    </div>
                  </div>
                </div>
              </ConditionalLink>
              {entities?.get(parent.entity.entity_uuid)?.parent_entity_data && (
                <div
                  className={`t-absolute t-left-[-40px] t-top-[64px] t-h-[1px] t-w-[40px] t-bg-text-black`}
                ></div>
              )}
              <div
                className={`t-absolute t-top-[65px] t-ml-[33px] t-w-[1px] t-bg-text-black`}
                style={{ height: arrowHeight }}
              ></div>

              {parent.entity.children && (
                <EntityTree
                  tree={parent.entity.children.children_data}
                  entities={entities}
                  groupId={groupId}
                  refetch={refetch}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="t-col-sm-12 t-col-md-12 t-col-lg-4 t-mb-2 t-ml-10 t-block t-w-[calc(100vw-110px)] md:t-hidden">
        {tree?.map((parent) => {
          const level = countChild(parent);
          const arrowHeight = `${level * 76}px`;

          return (
            <div key={parent.entity.entity_uuid} className="t-relative">
              <ConditionalLink
                to={`${routeTo}/entity/${parent?.entity?.entity_uuid}`}
                role="button"
                className="t-relative t-z-50 t-mb-4 t-flex t-h-[60px] t-w-[220px] t-items-center  t-gap-4 t-rounded-md t-border-solid t-border-neutral-0 t-bg-surface t-p-4"
                onClick={() => {
                  if (checkPath) {
                    dispatch(
                      setRestrictedChild(parent.entity.children.child_uuids)
                    );
                  }
                }}
              >
                <h6 className="cardAbout countryFlag">
                  <ReactCountryFlag
                    countryCode={
                      entities.get(parent.entity.entity_uuid)?.country_code
                    }
                    svg
                    style={flagSize}
                    title={entities.get(parent.entity.entity_uuid)?.country}
                    className="t-rounded"
                  />
                </h6>
                <div className="t-text-left t-text-subtitle t-text-text-100">
                  {parent.entity.entity_name}
                </div>
              </ConditionalLink>
              {entities?.get(parent.entity.entity_uuid)?.parent_entity_data && (
                <div className="t-absolute -t-left-5 t-top-[34px] t-h-[1px] t-w-[20px] t-bg-text-black"></div>
              )}
              <div
                className={`t-absolute t-top-[35px] t-ml-[20px] t-w-[1px] t-bg-text-black`}
                style={{ height: arrowHeight }}
              ></div>

              {parent.entity.children && (
                <EntityTree
                  tree={parent.entity.children.children_data}
                  entities={entities}
                  groupId={groupId}
                  refetch={refetch}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default EntityTree;
