import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Formik, FormikValues, Form } from "formik";
import { FC } from "react";
import { BalancingPaymentSchema } from "formValidations/editPriceAndDescriptionSchema";
import { Button } from "components/DesignSystem/Button/Button";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { UploadFile } from "components/UploadFile/UploadFile";
import {
  setCustomAttachments,
  setDocumentReviewPayload,
  setTaskReviewBalancePaymentStep,
} from "store/slices/reviewAndBalancePayment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { TaskReviewBalancePaymentStepper } from "./TaskReviewBalancePaymentStepper";
import { TASK_REVIEW_AND_PAY } from "constants/chatType";
import { BalancingPaymentInvoiceHistory } from "./BalancingPaymentInvoiceHistory";
import { DropFile } from "components/DesignSystem/BaseFileInput/BaseFileInput";

type IReviewAndBalancePaymentProps = {
  show: boolean;
  closeModal: () => void;
};

export const ReviewAndBalancePayment: FC<IReviewAndBalancePaymentProps> = ({
  show,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { taskReviewBalancePaymentStep, customAttachment, docReviewPayload } =
    useSelector((state: RootState) => state.reviewAndBalancePayment);

  const handleSubmit = (values: FormikValues) => {
    const { amount, scopeOfWork } = values;
    dispatch(
      setDocumentReviewPayload({
        amount: amount,
        description: scopeOfWork,
        document_operation: TASK_REVIEW_AND_PAY,
      })
    );
    closeModal();
  };

  const onFileSelect = (files: any[]) => {
    dispatch(setCustomAttachments(files));
    dispatch(setTaskReviewBalancePaymentStep(2));
  };

  return (
    <>
      {taskReviewBalancePaymentStep === 1 && (
        <UploadFile
          showModal
          closeModal={closeModal}
          onFileSelect={onFileSelect}
          prevSelectedFiles={customAttachment as DropFile[]}
          showStepper
          title="Upload Documents"
        />
      )}
      {taskReviewBalancePaymentStep === 2 && (
        <Modal.Root open={show} onOpenChange={closeModal}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Add Payment Details</Modal.Title>
              <Modal.Close />
            </Modal.Header>

            <Formik
              initialValues={{
                amount: docReviewPayload.amount,
                scopeOfWork: docReviewPayload.description,
              }}
              onSubmit={handleSubmit}
              validationSchema={BalancingPaymentSchema}
            >
              <Form className="t-m-0">
                <div className="t-pl-8 t-pt-4 t-pb-4">
                  <TaskReviewBalancePaymentStepper />
                </div>
                <Modal.Body className="t-overflow-auto t-max-h-[500px]">
                  <BalancingPaymentInvoiceHistory />
                  <div>
                    <TextInput
                      block
                      name="amount"
                      placeholder="Enter amount"
                      label="Amount"
                      onKeyDown={(e) => e.stopPropagation()}
                      type="number"
                    />
                  </div>
                  <div className="t-mt-4">
                    <TextArea
                      placeholder="Enter reason for balancing payment"
                      label="Reason for balancing payment"
                      name="scopeOfWork"
                      block
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button
                      type="button"
                      onClick={() =>
                        dispatch(setTaskReviewBalancePaymentStep(1))
                      }
                    >
                      Back
                    </Button>
                    <Button type="submit" customType="primary">
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            </Formik>
          </Modal.Content>
        </Modal.Root>
      )}
    </>
  );
};
