import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { DropdownIndicator } from "components/Transaction/TransactionColumn";
import { SERVICE_TEAM_LABEL } from "constants/bookkeeping";
import { CALENDAR, FISCAL } from "constants/calendarTypes";
import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";
import { DD_MMM_YYYY } from "constants/date";
import { values } from "cypress/types/lodash";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikValues,
  useFormikContext,
} from "formik";
import { closingMonthSchema } from "formValidations/closingMonthSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useBookkeepingCRMFilterOptionsQuery } from "store/apis/bookkeepingTracker";
import {
  useCreateTxnMonthlySummaryMutation,
  useGetSessonMonthListQuery,
  useGetTransactionMonthlySummariesQuery,
} from "store/apis/financialClosing";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { closeStartFinancialClosingModal } from "store/slices/financialClosing";
import { RootState } from "store/store";

const MonthForm = () => {
  const {
    values: {
      seasonSummaryId,
      multipleMonths,
      isHistorical,
      seasonYear,
      seasonType,
      team,
      assignee,
    },
    setFieldValue,
  } = useFormikContext<{
    isHistorical: boolean;
    multipleMonths: boolean;
    startDate: string;
    endDate: string;
    seasonSummaryId: string;
    seasonType: typeof CALENDAR | typeof FISCAL;
    seasonYear: string;
    team: typeof INKLE_TEAM_AND_CPA_TEAM;
    assignee: string;
  }>();
  const { isCustomer } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: monthlySummaries = [] } =
    useGetTransactionMonthlySummariesQuery(
      {
        groupId,
        entityId,
      },
      { skip: !groupId || !entityId }
    );

  const { data: monthsList, isLoading } = useGetSessonMonthListQuery(
    {
      groupId,
      seasonSummaryId,
      isHistorical,
      seasonYear,
      seasonType,
    },
    { skip: !Boolean(seasonSummaryId || seasonYear) }
  );

  const { months = [], season_year } = monthsList || {};

  const selectedSeason = monthlySummaries.find(
    ({ uuid }) => uuid === seasonSummaryId
  );

  const selectedYear = isHistorical
    ? season_year
    : dayjs(selectedSeason?.start_date).get("year").toString();

  const excludeDates = months
    .filter(({ is_created }) => is_created)
    .map(({ name }) => new Date(`${selectedYear}-${name}-01`));

  const { isCpa, isAdmin } = useRoleBasedView();

  const { data: serviceTeamData, isLoading: isTeamsLoading } =
    useBookkeepingCRMFilterOptionsQuery();
  const { teams = [] } = serviceTeamData || {};

  const { data: assignees = [], isLoading: isAssigneesLoading } =
    useGetServiceTeamQuery(
      {
        accessible_teams: team,
      },
      {
        skip: isCustomer,
      }
    );

  const selectedAssignee = assignees.find(({ uuid }) => uuid === assignee);

  const resetValues = () => {
    setFieldValue("seasonYear", "");
    setFieldValue("seasonSummaryId", "");
    setFieldValue("startDate", "");
    setFieldValue("endDate", "");
  };

  return (
    <>
      <SwitchField
        label="Historical cleanup"
        name="isHistorical"
        onChange={resetValues}
      />

      <SwitchField
        label="Close bookkeeping for multiple months"
        name="multipleMonths"
      />

      {isHistorical && (
        <Radio.Root
          defaultValue={CALENDAR}
          onValueChange={(value) => {
            setFieldValue("seasonType", value);
          }}
          className="t-flex t-flex-col t-gap-2"
        >
          <Label required>Select tax year</Label>
          <Radio.Content className="t-gap-2.5">
            <Radio.Item asChild value={CALENDAR}>
              Calendar year
            </Radio.Item>
            <Radio.Item asChild value={FISCAL}>
              Fiscal year
            </Radio.Item>
          </Radio.Content>
        </Radio.Root>
      )}
      {isHistorical ? (
        <SelectDropDown label="Select season" name="seasonYear" required>
          <option value="">Select season</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
        </SelectDropDown>
      ) : (
        <SelectDropDown label="Select season" name="seasonSummaryId" required>
          <option value="">Select season</option>
          {monthlySummaries.map(({ uuid, name }) => (
            <option value={uuid} key={uuid}>
              {name}
            </option>
          ))}
        </SelectDropDown>
      )}

      {Boolean(seasonSummaryId || seasonYear) && (
        <>
          <Field name="startDate">
            {({ field }: FieldProps) => {
              return (
                <DateInput
                  disabled={isLoading}
                  {...field}
                  label={multipleMonths ? "From" : "Month"}
                  placeholder="Select month"
                  dateFormat="MMMM-yyyy"
                  excludeDates={excludeDates}
                  showMonthYearPicker
                  required
                  openToDate={new Date(selectedYear || "")}
                  portalId="startDate"
                  maxDate={dayjs(selectedYear).endOf("year").toDate()}
                  minDate={dayjs(selectedYear).startOf("year").toDate()}
                />
              );
            }}
          </Field>
          {multipleMonths && (
            <Field name="endDate">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    disabled={isLoading}
                    {...field}
                    label="To"
                    placeholder="Select month"
                    dateFormat="MMMM-yyyy"
                    excludeDates={excludeDates}
                    showMonthYearPicker
                    required
                    openToDate={new Date(selectedYear || "")}
                    maxDate={dayjs(selectedYear).endOf("year").toDate()}
                    minDate={dayjs(selectedYear).startOf("year").toDate()}
                    portalId="endDate"
                    onDateChange={(date) =>
                      setFieldValue(
                        "endDate",
                        dayjs(date).endOf("month").format(DD_MMM_YYYY)
                      )
                    }
                  />
                );
              }}
            </Field>
          )}
        </>
      )}
      {(isCpa || isAdmin) && (
        <div className="t-flex t-w-full t-items-center t-gap-4">
          <Combobox
            required
            withForm
            block
            name="team"
            label="Team"
            options={teams.map((team) => ({
              label: SERVICE_TEAM_LABEL[team],
              value: team,
            }))}
            onInputChange={() => {
              setFieldValue("assignee", null);
            }}
            className="t-w-full"
            isSearchable={true}
            menuPortalTarget={document.body}
            backspaceRemovesValue={false}
            escapeClearsValue={false}
            isClearable={false}
          />
          <Combobox
            required
            withForm
            block
            name="assignee"
            label="Assignee"
            options={assignees.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))}
            isSearchable={true}
            value={
              selectedAssignee
                ? {
                    label: selectedAssignee?.name,
                    value: selectedAssignee?.uuid,
                  }
                : null
            }
            menuPortalTarget={document.body}
            backspaceRemovesValue={false}
            escapeClearsValue={false}
            isClearable={false}
            isDisabled={!team}
          />
        </div>
      )}
    </>
  );
};

export const StartFinancialClosingModal = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isHistorical = query.get("isHistorical");
  const { isStartBookkeepingOpen, financialClosingInitialState } = useSelector(
    (state: RootState) => state.financialClosing
  );
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const [createTxnMonthlySummary, { isLoading }] =
    useCreateTxnMonthlySummaryMutation();
  const { search } = useLocation();
  const history = useHistory();
  const onClose = () => {
    dispatch(closeStartFinancialClosingModal());
  };
  const onStart = async ({
    startDate,
    endDate,
    multipleMonths,
    seasonSummaryId,
    seasonYear,
    seasonType,
    isHistorical,
    assignee,
  }: FormikValues) => {
    try {
      await createTxnMonthlySummary({
        groupId,
        entityId,
        startDate: startDate && dayjs(startDate).format("MMMM"),
        endDate: endDate && dayjs(endDate).format("MMMM"),
        multipleMonths,
        seasonSummaryId: isHistorical ? "" : seasonSummaryId,
        seasonYear: isHistorical ? seasonYear : "",
        seasonType,
        isHistorical,
        assignee,
      }).unwrap();
      onClose();
      successToast({ title: "Month created!" });
      history.push(`/books/monthly-closing${search}`);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root
      open={isStartBookkeepingOpen}
      onOpenChange={onClose}
      modal={false}
    >
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>Start Bookkeeping</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          onSubmit={onStart}
          initialValues={
            financialClosingInitialState
              ? financialClosingInitialState
              : {
                  isHistorical: isHistorical || false,
                  startDate: "",
                  endDate: "",
                  multipleMonths: false,
                  seasonSummaryId: "",
                  seasonType: CALENDAR,
                  seasonYear: "",
                }
          }
          validationSchema={closingMonthSchema}
        >
          {({ values, setFieldValue }) => {
            const date1 = dayjs(values.startDate, "DD-MMM-YYYY");
            const date2 = dayjs(values.endDate, "DD-MMM-YYYY");

            const isDate1Bigger = date1.isAfter(date2);
            if (isDate1Bigger) {
              setFieldValue("endDate", "");
            }

            return (
              <Form className="all:unset">
                <Modal.Body className="t-flex t-gap-4 t-flex-col t-w-full">
                  <MonthForm />
                </Modal.Body>
                <Modal.Footer className="t-justify-end t-flex">
                  <Button
                    customType="primary"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Start
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
