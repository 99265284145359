import Table from "components/DesignSystem/Table/V2/Table";
import { ReactNode } from "react";
import {
  flexRender,
  HeaderGroup,
  Row,
  Table as TableProps,
} from "react-table-8.10.7";

export const TableUI = <T,>({
  table,
  children,
  header,
  size,
  onRowClick,
}: {
  table: TableProps<T>;
  children?: ({ row }: { row: Row<T> }) => ReactNode;
  header?: ({ headerGroup }: { headerGroup: HeaderGroup<T> }) => ReactNode;
  size?: "small" | "regular" | "large";
  onRowClick?: (row: Row<T>) => void;
}) => {
  return (
    <Table.Container size={size || "small"}>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) =>
            Boolean(header) ? (
              header?.({ headerGroup })
            ) : (
              <Table.HeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: `${header.getSize()}%` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.HeadRow>
            )
          )}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) =>
            Boolean(children) ? (
              children?.({ row })
            ) : (
              <Table.Row
                key={row.id}
                onRowClick={onRowClick ? () => onRowClick(row) : undefined}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Cell
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}%` }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};
