import { SubscriptionSummary } from "components/SubscriptionSummary";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetTeamMembersQuery } from "store/apis/teamSetting";
import SeatPlanManage from "./SeatPlanManage";
import { Loader } from "components/DesignSystem/Loader/Loader";

const BillingSubscription = () => {
  const history = useHistory();
  const query = useQuery();
  const isOpenSeatManage = Boolean(query.get("manageseat"));
  const { uuid: groupId } = useCurrentGroupContext();
  const [showSeatManage, setShowSeatManage] = useState(isOpenSeatManage);
  const {
    data = {
      active_group_users_count: 0,
      available_seats: 0,
      extra_seat_amount: 0,
      free_seats: 0,
    },
    isLoading: teamMemberLoading,
    isSuccess,
  } = useGetTeamMembersQuery({ groupId }, { skip: !groupId });

  const closeModal = () => {
    setShowSeatManage(false);
    if (isOpenSeatManage) {
      history.push("/billing");
    }
  };

  const extraSeats =
    data.active_group_users_count + data.available_seats - data.free_seats;

  if (teamMemberLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <div className="">
      <SubscriptionSummary
        freeSeats={data.free_seats}
        extraSeats={extraSeats}
        setShowSeatManage={setShowSeatManage}
        activeSeats={data.active_group_users_count}
      />
      <SeatPlanManage
        show={showSeatManage}
        closeModal={closeModal}
        seatData={data}
      />
    </div>
  );
};

export default BillingSubscription;
