export const NOT_STARTED = "Not Started";
export const IN_PROGRESS = "In Progress";
export const IN_CPA_REVIEW = "Pending Review";
export const IN_CLIENT_REVIEW = "In Client Review";
export const COMPLETED = "Completed";
export const NOT_APPLICABLE = "Not Applicable";
export const PENDING_REVIEW = "Pending Review";

// Onboarding steps
export const BANK_CONNECT = "Bank connect";
export const SEASON = "Season";
export const PLATFORM = "Platform";
