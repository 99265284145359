import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import {
  BOOKS_PRO,
  DISCOUNTED_PRO_PLAN,
  FREE_PLAN,
  PRO_PLAN,
  STANDARD_PLAN,
} from "../constants/subscriptionType";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useCurrentEntityId } from "./useCurrentEntityId";
import { SubscriptionInProfile } from "types/Models/subscription";

/**  Need group context and entity id in the URL to get the current group subscription
 */
export const useCurrentGroupSubscription = () => {
  const group = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: subscriptions, isLoading } = useGetSubscriptionsQuery(
    {
      groupId: group.uuid,
      entityId,
    },
    { skip: !entityId || !group.uuid }
  );

  const platform_subscription = subscriptions
    ?.filter((subscription) => subscription.subscription_type === "PLATFORM")
    ?.find(({ group_subscription }) => !group_subscription);

  const addon_subscriptions = subscriptions?.filter(
    (subscription) => subscription.subscription_type !== "PLATFORM"
  );

  const { isCpa, isAdmin } = useRoleBasedView();
  const { authtoken } = useContext(authContext);

  if (!authtoken) {
    return {};
  }

  const addons =
    isCpa || isAdmin
      ? addon_subscriptions
      : (authtoken?.addon_subscriptions as SubscriptionInProfile[] | undefined);

  const isBooksProUser: boolean = Boolean(
    addons?.some(
      ({ subscription_name }: { subscription_name: string }) =>
        subscription_name === BOOKS_PRO
    )
  );

  const currentPlan =
    isCpa || isAdmin
      ? platform_subscription?.subscription_name
      : authtoken?.platform_subscription?.subscription_name;

  return {
    isStandardUser: currentPlan === STANDARD_PLAN,
    isProUser: currentPlan === PRO_PLAN || currentPlan === DISCOUNTED_PRO_PLAN,
    isFreeUser: !currentPlan || currentPlan === FREE_PLAN,
    isBooksProUser,
    isLoading,
  };
};
