import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { JSX } from "react/jsx-runtime";
import {
  ReactionsListProps,
  StreamEmoji,
  useMessageContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const ReactionsList = (
  props: JSX.IntrinsicAttributes &
    ReactionsListProps<DefaultStreamChatGenerics> & { sentByTheUser: boolean }
) => {
  const { message } = useMessageContext();
  const emojiType = ["haha", "like", "love", "sad", "wow"];
  const uniqueEmoji = [
    ...new Set(
      message?.latest_reactions
        ?.filter(({ type }) => emojiType.includes(type))
        ?.map(({ type }) => type)
    ),
  ];
  const totalReactions = message?.latest_reactions?.length;

  if (!Boolean(totalReactions)) {
    return null;
  }

  return (
    <DropDown.Root modal={false}>
      <DropDown.Trigger className="all:unset t-z-table-head t-group t-ml-1">
        <div className="t-flex t-gap-1 -t-mt-3 t-bg-surface-lighter-grey t-border t-border-solid t-border-neutral-10 t-rounded-2xl t-px-2 t-py-1 t-items-center group-data-state-open:t-bg-neutral-10">
          {uniqueEmoji?.map((type) => (
            <StreamEmoji
              key={type}
              type={type as "haha" | "like" | "love" | "sad" | "wow"}
            />
          ))}
          <div className="t-text-body t-font-light t-text-neutral-50">
            {totalReactions}
          </div>
        </div>
      </DropDown.Trigger>
      <DropDown.Portal>
        <DropDown.Content
          sideOffset={10}
          className="t-rounded-lg t-bg-surface t-p-3 t-drop-shadow-sidebar t-min-w-36 t-flex t-flex-col t-gap-2"
          align={props.sentByTheUser ? "end" : "start"}
        >
          {message?.latest_reactions?.map(({ type, user, user_id }) => (
            <div className="t-flex t-justify-between t-w-full" key={user?.id}>
              <div className="t-text-neutral-80 t-text-body-sm">
                {user?.name}
              </div>

              <StreamEmoji
                type={
                  type as "angry" | "haha" | "like" | "love" | "sad" | "wow"
                }
              />
            </div>
          ))}
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
};
