export const SolidCaretDown = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_357_15159)">
        <path
          d="M16.8273 7.26094C16.7801 7.14673 16.7 7.0491 16.5972 6.9804C16.4944 6.91171 16.3736 6.87503 16.25 6.875L3.75 6.875C3.62632 6.8749 3.50539 6.91151 3.40252 6.98017C3.29964 7.04884 3.21946 7.14649 3.17211 7.26076C3.12477 7.37502 3.11239 7.50076 3.13655 7.62207C3.1607 7.74337 3.22031 7.85478 3.30782 7.94219L9.55782 14.1922C9.61586 14.2503 9.68479 14.2964 9.76067 14.3279C9.83654 14.3593 9.91787 14.3755 10 14.3755C10.0821 14.3755 10.1635 14.3593 10.2393 14.3279C10.3152 14.2964 10.3841 14.2503 10.4422 14.1922L16.6922 7.94219C16.7796 7.85473 16.8391 7.74333 16.8631 7.62206C16.8872 7.5008 16.8747 7.37513 16.8273 7.26094Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_357_15159">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
