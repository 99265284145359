export const getAutofillFields = (sections) => {
  // Transforms
  // Input -> [{autofill_key: "A", autofill_section: "AS"}, {autofill_section: "AS", id: 1}, {au..section: "AS", id: 2}]
  // Input -> {"A": "AS", "B": "BS"}
  // Output -> { "AS": [ {autofill_section: "AS", id: 1}, {autofill_section: "AS", id: 2} ]}
  const autofillFields = sections.reduce(
    (acc, { section_fields, repeatable_fields }) => {
      let addFields = [];

      if (repeatable_fields?.length > 0) {
        addFields = [
          ...repeatable_fields
            .map((f) => f.fields.map((fi) => ({ ...fi, id: f.id })))
            .flat()
            .filter((f) => f.autofill_section),
        ];
      }
      if (section_fields?.length > 0) {
        addFields = [
          ...addFields,
          ...section_fields.filter((f) => f.autofill_section?.length > 0),
        ];
      }

      return [...acc, ...addFields];
    },
    []
  );

  const keyAndSectionMap = autofillFields
    .filter((a) => a.autofill_key?.length > 0)
    .reduce((acc, a) => ({ ...acc, [a.autofill_section]: a.autofill_key }), {});

  const autofillFieldsWithKeys = Object.entries(keyAndSectionMap).reduce(
    (acc, [section]) => ({
      ...acc,
      [section]: autofillFields.filter((f) => f.autofill_section === section),
    }),
    {}
  );

  return [keyAndSectionMap, autofillFieldsWithKeys];
};

export const getKeysAndValuesFromFields = (fields) => {
  const metaField = fields.find((f) => f.autofill_key);
  const withoutMetaField = fields.filter((f) => !f.autofill_key);

  return Object.fromEntries(
    withoutMetaField.map((field) => {
      const key = field.field_key
        .split(`${metaField.autofill_prefix}_`)[1]
        .split(`_${field.id}`)[0];

      return [key, field.value];
    })
  );
};
