import React from "react";

export const CloudUploadSmall = ({
  color = "#706A85",
  size = "20",
}: {
  color?: string;
  size?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_2509_421)">
      <path
        d="M6.99995 13H4.49995C4.00368 12.9994 3.51321 12.8932 3.06109 12.6886C2.60896 12.484 2.20553 12.1855 1.87755 11.8131C1.54957 11.4406 1.30455 11.0027 1.15874 10.5283C1.01293 10.0539 0.969676 9.55398 1.03184 9.06162C1.094 8.56925 1.26015 8.09573 1.51928 7.67248C1.77841 7.24923 2.12459 6.88592 2.53484 6.60667C2.9451 6.32742 3.41005 6.13861 3.89884 6.05276C4.38764 5.96692 4.8891 5.986 5.36995 6.10875"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10L9.5 8L11.5 10"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 13V8"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 8.00005C5.00021 7.09865 5.2441 6.21407 5.70585 5.43992C6.1676 4.66577 6.83004 4.03085 7.62307 3.60233C8.4161 3.17382 9.31023 2.96766 10.2108 3.00567C11.1114 3.04368 11.985 3.32444 12.7391 3.81824C13.4932 4.31205 14.0998 5.00053 14.4947 5.81083C14.8896 6.62113 15.0581 7.52311 14.9823 8.42133C14.9066 9.31954 14.5894 10.1806 14.0644 10.9133C13.5395 11.646 12.8261 12.2232 12 12.5838"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2509_421">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
