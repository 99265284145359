import { createContext } from "react";
import { CheckoutDataProps } from "./CheckoutModal";

type UseCreditsProps = {
  useCredits: boolean;
  setUseCredits: (value: boolean) => void;
};

type EntityChangeProps = {
  setEntityId: (value: string) => void;
  isEntityProvided: boolean;
};

type SelectPaymentMethod = {
  paymentMethodId: string | null;
  setPaymentMethodId: (id: string) => void;
};

type Steps = {
  step: "DETAILS" | "PAYMENT";
  setStep: (value: "DETAILS" | "PAYMENT") => void;
};

type CheckoutModalCreditPropsContext = {
  type: "credits";
  creditsToBeAdded: number;
  setCreditsToBeAdded: (credits: number) => void;
} & EntityChangeProps &
  SelectPaymentMethod;

type CheckoutModalInvoicesPropsContext = {
  type: "invoices";
  paymentMethodId: string | null;
  setPaymentMethodId: (id: string) => void;
} & UseCreditsProps &
  SelectPaymentMethod &
  EntityChangeProps;

type CheckoutModalSubscriptionPropsContext = {
  type: "subscription";
} & EntityChangeProps;

type CheckoutModalCartPropsContext = {
  type: "cart";
} & UseCreditsProps &
  SelectPaymentMethod;

type CheckoutModalTaskPropsContext = {
  type: "task";
} & UseCreditsProps &
  SelectPaymentMethod;

type ContextUnion =
  | CheckoutModalCreditPropsContext
  | CheckoutModalInvoicesPropsContext
  | CheckoutModalSubscriptionPropsContext
  | CheckoutModalCartPropsContext
  | CheckoutModalTaskPropsContext;

export type CheckoutModalPropsContextType = {
  entityId: string;
  payerUserType: "FCA" | "CUSTOMER";
} & CheckoutDataProps &
  ContextUnion &
  Steps & {
    groupId: string;
  };

export const CheckoutModalPropsContext = createContext<
  CheckoutModalPropsContextType | undefined
>(undefined);
