import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

type ConfirmationModalProps = {
  show: boolean;
  close: () => void;
  action: "Remove" | "Churn" | "Dissolve" | "Delete" | "Restore";
  actionOn: "group" | "profile";
  handleClick: () => void;
  isLoading: boolean;
};

export const ConfirmationModal = ({
  show,
  close,
  action,
  actionOn,
  handleClick,
  isLoading,
}: ConfirmationModalProps) => {
  const onClick = () => {
    handleClick();
    close();
  };

  return (
    <Modal.Root open={show} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            {action} this {actionOn}?
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Are you sure you want to {action.toLowerCase()} this {actionOn}?
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={close}>Cancel</Button>
          <Button
            customType={action === "Restore" ? "primary" : "danger"}
            onClick={onClick}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
