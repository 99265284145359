import classNames from "classnames";
import { useFormikContext } from "formik";
import { Switch, SwitchProps } from "./Switch";

export const SwitchField = ({
  label,
  name,
  description,
  onChange,
  disabled = false,
  ...props
}: {
  label: string;
  name: string;
  description?: string;
  onChange?: (value: boolean) => any;
  disabled?: boolean;
} & SwitchProps) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();

  const onCheckedChange = (value: boolean) => {
    setFieldValue(name, value);
    onChange?.(value);
  };
  if (!label) {
    return (
      <Switch
        disabled={disabled}
        checked={getFieldMeta<boolean>(name).value}
        name={name}
        onCheckedChange={onCheckedChange}
        {...props}
      />
    );
  }
  return (
    <div className="t-flex t-items-center t-justify-between">
      <label htmlFor={name} className="t-cursor-pointer t-w-full">
        <span
          className={classNames("t-mr-2 t-inline-block t-text-body", {
            "t-opacity-50 t-cursor-not-allowed": disabled === true,
          })}
        >
          {label}
        </span>
      </label>
      <div className="t-shrink-0 t-flex t-justify-center t-items-center">
        <Switch
          disabled={disabled}
          checked={getFieldMeta<boolean>(name).value}
          name={name}
          onCheckedChange={onCheckedChange}
          {...props}
        />
      </div>
    </div>
  );
};
