import classNames from "classnames";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { Badge } from "components/design/badge";
import ToolTip from "components/design/toolTip";
import { CrossIcon } from "components/icons/CrossIcon";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { IN_PROGRESS, SERVICE_TEAM_LABEL } from "constants/bookkeeping";
import {
  BOOKS_SUBSCRIPTION,
  CRM_SUBSCRIPTION_BADGE_MAP,
  CRM_SUBSCRIPTION_TYPE,
} from "dictionaries";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ReactNode } from "react";
import ReactCountryFlag from "react-country-flag";
import {
  TransactionMonthlySummaryCard,
  TransactionMonthlySummaryCards,
  TransactionMonthlySummaryCardsForEntity,
  useGetMonthlyClosingCardsForEntityQuery,
  useGroupMonthlyClosingSingleQuery,
} from "store/apis/bookkeepingTracker";
import { formatTimeToMonthYear } from "utils/formatDate";

type subscriptionValidType = "STANDARD" | "BYOA" | "PAYG" | "PRO";

const InfoItem = ({
  label,
  children,
}: {
  label: string | ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <Label className="!t-pb-0 t-text-body-sm">{label}</Label>
      <span className="t-text-subtext">{children}</span>
    </div>
  );
};

const SliderHeader = ({
  current_plan_name,
  group_name,
}: {
  current_plan_name: subscriptionValidType;
  group_name: string;
}) => {
  const { update } = useUpdateQuery();
  const onClose = () => {
    update({ query: "entityId", value: null });
  };
  return (
    <>
      <div className="t-flex t-justify-between t-px-5 t-py-3 t-items-center">
        <div className="t-flex t-gap-2 t-max-h-10 t-items-center">
          <Avatar alt={group_name} size="large" />
          <span className=" t-text-subtitle">{group_name}</span>
          <Badge size="medium" color={CRM_SUBSCRIPTION_TYPE[current_plan_name]}>
            {CRM_SUBSCRIPTION_BADGE_MAP[current_plan_name]}
          </Badge>
        </div>
        <div onClick={onClose} data-testid="bookkeeping-tracker-slider-cross">
          <CrossIcon />
        </div>
      </div>
      <Divider color="grey" />
    </>
  );
};
const BooksTabItem = ({
  start_date,
  end_date,
  status,
  books_status,
  is_due,
  group_uuid,
  entity_uuid,
  month_uuid,
}: {
  start_date: string | null;
  end_date: string | null;
  status: string;
  books_status: string;
  is_due: boolean;
  group_uuid: string;
  entity_uuid: string;
  month_uuid: string;
}) => {
  const formattedStartDate = formatTimeToMonthYear(start_date);
  const formattedEndDate = end_date ? formatTimeToMonthYear(end_date) : null;
  return (
    <div className="t-flex t-items-center t-justify-between hover:t-bg-surface-grey t-ease-in-out">
      <span className="t-flex t-gap-x-4 t-items-center">
        <span className="t-text-body-sm t-text  t-underline hover: t-cursor-pointer">
          <ConditionalLink
            to={`/books/monthly-closing?company=${group_uuid}&entity=${entity_uuid}&openMonthId=${month_uuid}`}
            className="t-text-text-black"
          >
            {formattedEndDate == null || formattedStartDate === formattedEndDate
              ? `${formattedStartDate}`
              : `${formattedStartDate} - ${formattedEndDate}`}
          </ConditionalLink>
        </span>
        {is_due && (
          <span className="t-text-subtext-sm">
            <Badge color="dark-red" fitContent size="small">
              Due
            </Badge>
          </span>
        )}
      </span>

      <span>
        <Badge
          color={status === IN_PROGRESS ? "yellow" : "light-green"}
          size="large"
        >
          {books_status}
        </Badge>
      </span>
    </div>
  );
};
const SliderBody = ({
  entityId,
  status,
}: {
  entityId: string;
  status: string;
}) => {
  const emptyTransactionMonthlySummaryCardDetails: TransactionMonthlySummaryCards =
    {
      group: {
        name: "",
        uuid: "",
      },
      entity: {
        name: "",
        uuid: "",
        country_code: "",
      },
      current_plan_name: "STANDARD",
      current_assigned_agent: {
        name: "",
        profile_id: "",
        agent_id: "",
        team: "INKLE_TEAM",
      },
      due_amount: 0,
      ongoing_since: "",
      completed_till: "",
    };
  const emptyTransactionMonthlySummaryCard: TransactionMonthlySummaryCardsForEntity =
    {
      transaction_monthly_summaries: [],
    };

  const {
    data: transactionGroupData = emptyTransactionMonthlySummaryCardDetails,
    isLoading,
    isSuccess,
  } = useGroupMonthlyClosingSingleQuery({
    entityId,
    showInProgress: status == IN_PROGRESS,
  });
  const {
    data: transaction_monthly_summaries_data = emptyTransactionMonthlySummaryCard,
    isLoading: isCardsLoading,
    isSuccess: isCardsSuccess,
  } = useGetMonthlyClosingCardsForEntityQuery({
    entityId,
    showInProgress: status === IN_PROGRESS,
  });
  const { transaction_monthly_summaries } = transaction_monthly_summaries_data;

  const isCardsEmpty = transaction_monthly_summaries.length == 0;

  const isEmpty = transactionGroupData?.entity.uuid ? false : true;
  const {
    group,
    entity,
    due_amount,
    completed_till,
    ongoing_since,
    current_plan_name,
    current_assigned_agent,
  } = transactionGroupData;
  return (
    <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
      <Async.Empty>
        <Loader />
      </Async.Empty>
      <Async.Success>
        <SliderHeader
          current_plan_name={current_plan_name}
          group_name={group.name}
        />
        <SliderAccordion.Root
          type="multiple"
          defaultValue={["DETAILS", "BOOKS_TAB"]}
          className="t-flex t-flex-col t-w-full t-p-5 t-gap-y-4"
        >
          <SliderAccordion.Item value="DETAILS">
            <SliderAccordion.Trigger>Details</SliderAccordion.Trigger>
            <SliderAccordion.Content>
              <div className="t-flex t-flex-col t-gap-y-6">
                <div className="t-grid t-grid-cols-2">
                  <div className="t-col-span-1">
                    <InfoItem label="Group Name">
                      <span className=" t-text-subtext">{group.name}</span>
                    </InfoItem>
                  </div>
                  <InfoItem label="Entity Name">
                    <div className="t-col-span-1">
                      <span className="t-flex t-gap-x-2 t-items-center">
                        <ReactCountryFlag
                          style={{
                            width: "14px",
                            height: "10px",
                            marginBottom: "3px",
                          }}
                          countryCode={entity.country_code}
                          svg
                        />
                        <span>{entity.name}</span>
                      </span>
                    </div>
                  </InfoItem>
                </div>
                <div className="t-grid t-grid-cols-2">
                  <div className="t-grid t-grid-cols-2">
                    <InfoItem label="Team">
                      <span className=" t-text-subtext">
                        {current_assigned_agent
                          ? SERVICE_TEAM_LABEL[current_assigned_agent.team]
                          : "-"}
                      </span>
                    </InfoItem>
                  </div>
                  <div className="t-grid t-grid-cols-2">
                    <InfoItem label="Agent">
                      <span className="t-flex t-gap-x-2 t-items-center">
                        <span>
                          {current_assigned_agent
                            ? current_assigned_agent.name
                            : "-"}
                        </span>
                      </span>
                    </InfoItem>
                  </div>
                </div>
                <InfoItem label="Plan">
                  <span className=" t-text-subtext">
                    {BOOKS_SUBSCRIPTION[current_plan_name]}
                  </span>
                </InfoItem>
                <InfoItem
                  label={
                    <div className="t-flex">
                      <span>Amount Due</span>
                      <ToolTip text="Amount due for bookkeeping only">
                        <div>
                          <InfoFilledSmall size="13" color="#706A85" />
                        </div>
                      </ToolTip>
                    </div>
                  }
                >
                  <span
                    className={classNames(
                      " t-text-subtext t-text-i-neutral-50",
                      {
                        "t-text-red-70": due_amount > 0,
                      }
                    )}
                  >
                    {due_amount}
                  </span>
                </InfoItem>
              </div>
            </SliderAccordion.Content>
          </SliderAccordion.Item>
          <SliderAccordion.Item value="BOOKS_TAB">
            <SliderAccordion.Trigger>
              {status == "IN_PROGRESS" ? "Ongoing Months" : "Completed Months"}
            </SliderAccordion.Trigger>
            <SliderAccordion.Content className="t-flex t-flex-col t-gap-y-4 t-overflow-auto t-max-h-96">
              <Async.Root
                {...{
                  isLoading: isCardsLoading,
                  isSuccess: isCardsSuccess,
                  isEmpty: isCardsEmpty,
                }}
              >
                <Async.Empty>
                  <span className=" t-flex t-justify-items-center t-justify-center">
                    {status === IN_PROGRESS
                      ? "There are no ongoing books "
                      : "There are no completed books"}
                  </span>
                </Async.Empty>
                <Async.Success>
                  {transaction_monthly_summaries.map(
                    ({
                      status: bookStatus,
                      is_due,
                      monthly_summary: { uuid: monthId, date, end_date },
                    }) => (
                      <BooksTabItem
                        key={monthId}
                        start_date={date}
                        end_date={end_date}
                        books_status={bookStatus}
                        status={status}
                        is_due={is_due}
                        group_uuid={group.uuid}
                        entity_uuid={entity.uuid}
                        month_uuid={monthId}
                      />
                    )
                  )}
                </Async.Success>
              </Async.Root>
            </SliderAccordion.Content>
          </SliderAccordion.Item>
        </SliderAccordion.Root>
        <div className="t-fixed t-w-[480px] t-bottom-0 t-px-5 t-py-4 t-bg-surface t-border t-border-solid t-border-b-0 t-border-l-0 t-border-t t-border-r-0 t-border-neutral-0">
          <ConditionalLink
            to={`/books/monthly-closing?company=${group.uuid}&entity=${entity.uuid}`}
          >
            <Button customType="primary" block>
              Go to Financial Closing
            </Button>
          </ConditionalLink>
        </div>
      </Async.Success>
    </Async.Root>
  );
};
export const BookkeepingTrackerSlider = ({ status }: { status: string }) => {
  const query = useQuery();
  const { update } = useUpdateQuery();
  const entityId = query.get("entityId");
  const onClose = () => {
    update({ query: "entityId", value: null });
  };

  return (
    <Slider open={Boolean(entityId)} type="fixed" onClose={onClose} withOverlay>
      <SliderBody entityId={entityId!} status={status} />
    </Slider>
  );
};
