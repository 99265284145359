import classNames from "classnames";
import { Badge } from "components/Task/Badge";
import {
  SUBMITTED_TYPE,
  TASK_AMENDMENT_COMPLETED,
  FORM_FILING_TYPE,
  TASK_AMENDMENT,
} from "constants/taskStates";
import { useRoleBasedView } from "hooks/useRoleBasedView";

import { Task } from "types/Models/task";

const TASK_STATUS = {
  Processing: "PROCESSING",
  Completed: "COMPLETED",
  Archived: "ARCHIVED",
};

export const TaskStatus = ({
  status,
  statusType: statusTypeFromProps,
  state,
}: {
  status?: string;
  statusType?: string;
  state?: Task["state"];
}) => {
  const { isCpa, isAdmin } = useRoleBasedView();

  if (isCpa || isAdmin) {
    const statusType = statusTypeFromProps;
    const isStatusFinal =
      statusType === SUBMITTED_TYPE || statusType === TASK_AMENDMENT_COMPLETED;
    const isStatusInitial =
      (statusType === FORM_FILING_TYPE ||
        state === null ||
        statusType === TASK_AMENDMENT) &&
      statusType !== SUBMITTED_TYPE &&
      statusType !== TASK_AMENDMENT_COMPLETED;
    const isStatusInprogress =
      statusType !== FORM_FILING_TYPE &&
      state !== null &&
      statusType !== SUBMITTED_TYPE &&
      statusType !== TASK_AMENDMENT_COMPLETED &&
      statusType !== TASK_AMENDMENT;

    return (
      <Badge
        // @ts-ignore
        type={classNames({
          COMPLETED: isStatusFinal && !isStatusInitial && !isStatusInprogress,
          PROCESSING: (isStatusInitial || isStatusInprogress) && !isStatusFinal,
        })}
      >
        {(typeof state === "string" ? state : state?.name) || "Not started"}
      </Badge>
    );
  }

  return (
    <Badge
      type={
        status === "Action required"
          ? "ACTION_REQUIRED"
          : // @ts-ignore
            TASK_STATUS[status]?.toString()
      }
    >
      {status || "-"}
    </Badge>
  );
};
