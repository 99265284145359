import { Button } from "components/DesignSystem/Button/Button";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Modal from "components/DesignSystem/Modal/Modal";
import OtpBox from "components/design/otpBox";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { MOBILE, MOBILE_UPDATE } from "constants/profileUpdate";
import { Form, Formik, FormikValues, useFormikContext } from "formik";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import React, { useContext, useState } from "react";
import {
  useSendOtpForMobileMutation,
  useUpdateProfileEmailAndMobileMutation,
} from "store/apis/settings";

const SendOTPComponent = ({
  setShowMobileModal,
  setStep,
}: {
  setShowMobileModal: (newState: React.SetStateAction<boolean>) => void;
  setStep: (newState: React.SetStateAction<number>) => void;
}) => {
  const [sendOtpForMobile, { isLoading }] = useSendOtpForMobileMutation({});
  const { alertToast, successToast } = useToast();
  const { authtoken } = useContext(authContext);

  const { values } = useFormikContext<FormikValues>();

  const sendOtp = async () => {
    const { mobile } = values;

    try {
      await sendOtpForMobile({
        mobile: mobile,
        email: authtoken.email,
      }).unwrap();
      successToast({ message: "OTP sent" });
      setStep(2);
    } catch (error: any) {
      alertToast({
        message: error?.data?.error?.message || SOMETHING_WENT_WRONG,
      });
    }
  };

  return (
    <Form
      className="all:unset"
      onSubmit={(e) => {
        e.preventDefault();
        sendOtp();
      }}
    >
      <Modal.Body>
        <MobileInput label="Primary mobile number" name="mobile" />
      </Modal.Body>
      <Modal.Footer className="t-flex t-justify-end t-gap-3">
        <Button onClick={() => setShowMobileModal(false)}>Cancel</Button>
        <Button customType="primary" isLoading={isLoading} disabled={isLoading}>
          Send OTP
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const VerifyOTPComponent = ({
  setShowMobileModal,
  setStep,
}: {
  setShowMobileModal: (newState: React.SetStateAction<boolean>) => void;
  setStep: (newState: React.SetStateAction<number>) => void;
}) => {
  const [otp, setOtp] = useState("");
  const { successToast, alertToast } = useToast();
  const [updateEmailOrPhone, { isLoading }] =
    useUpdateProfileEmailAndMobileMutation();
  const { values, isSubmitting } = useFormikContext<FormikValues>();
  const { updateUser } = useContext(authContext);

  const verifyOtp = async () => {
    try {
      const updatedProfile = await updateEmailOrPhone({
        operation: MOBILE_UPDATE,
        otp: otp,
        mobile: values?.mobile,
      }).unwrap();
      updateUser({ data: { data: updatedProfile } });
      successToast({ message: "Mobile number updated" });
      setShowMobileModal(false);
    } catch (error: any) {
      alertToast({
        message: error?.data?.error?.message || SOMETHING_WENT_WRONG,
      });
    }
  };

  const onCancel = () => {
    setStep(1);
    setShowMobileModal(false);
  };

  return (
    <>
      <Modal.Body>
        <div className="t-text-body t-text-text-60 t-mb-5">
          Please enter the 6-character code sent to {values?.mobile}
        </div>
        {/* @ts-ignore */}
        <OtpBox
          className="all:unset"
          otp={otp}
          setOtp={setOtp}
          getOtp={() => {}}
        />
      </Modal.Body>
      <Modal.Footer className="t-flex t-justify-end t-gap-3">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          customType="primary"
          disabled={otp?.length !== 6 || isSubmitting || isLoading}
          isLoading={isLoading}
          onClick={verifyOtp}
        >
          Update
        </Button>
      </Modal.Footer>
    </>
  );
};

export const MobileUpdate = ({
  mobile,
  showMobileModal,
  setShowMobileModal,
}: {
  mobile: string;
  showMobileModal: boolean;
  setShowMobileModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const [step, setStep] = useState(1);
  return (
    <Modal.Root open={showMobileModal} onOpenChange={setShowMobileModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Enter new mobile number</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{ mobile: `${mobile}`, step: 1 }}
          onSubmit={() => {}}
        >
          {({ values }) => {
            return step === 1 ? (
              <SendOTPComponent
                setShowMobileModal={setShowMobileModal}
                setStep={setStep}
              />
            ) : (
              <VerifyOTPComponent
                setShowMobileModal={setShowMobileModal}
                setStep={setStep}
              />
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
