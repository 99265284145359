import React from "react";

//utils
import Optional from "../../utils/optionalRender";

const PerksTag = ({ tags }) => {
  return (
    <div className="task-assign-toggle button-reset">
      <Optional optionalRender="">
        {tags?.length > 0 && (
          <div className="badgeGroup">
            {tags?.map(({ color_hex, bg_color_hex, uuid, title }) => {
              return (
                <span
                  className="badgeTags"
                  style={{
                    color: color_hex,
                    backgroundColor: bg_color_hex,
                  }}
                  key={uuid}
                >
                  {title}
                </span>
              );
            })}
          </div>
        )}
      </Optional>
    </div>
  );
};

export default PerksTag;
