import { axiosInstance } from "./apiInstance";
import qs from "qs";
import { makeFormData } from "../utils/makeFormData";

export const getAllDeals = async ({
  groupId,
  perk_tag_uuids,
  search_term,
  page_num,
}) => {
  let url = `/api/inkle/reference/group/${groupId}/get/`;

  let queryUrl = qs.stringify(
    {
      search_term,
      page_num,
    },
    { skipNulls: true }
  );

  if (queryUrl) url = url.replace("get/", "get/?");
  url += queryUrl;

  const tagsUrl = encodeURI(perk_tag_uuids);
  if (tagsUrl) {
    url += "&perk_tag_uuids=" + tagsUrl;
  }

  return await axiosInstance.get(url);
};

export const getPerkTags = async () => {
  return await axiosInstance.get("/api/inkle/tags/PERK_TAG/");
};

export const addPerk = async ({ data }) => {
  let payload = {
    name: data.name,
    description: data.description,
    offer_description: data.offer_description,
    icon: data.file,
    linkedin_url: data.linkedin,
    perk_type: data.perkType,
    promo_code: data.couponCode,
    promo_code_redeem_url: data.redemptionUrl,
    special_email: data.referralEmail,
    special_url: data.utmLink,
    intro_email: data.introEmail,
    heading: data.shortDescription,
    website: data.website,
    contact_email: data.contactEmail,
  };
  let formData = makeFormData(payload);
  return await axiosInstance.post(
    `/api/inkle/reference/create-perk/`,
    formData
  );
};
