import { captureException } from "@sentry/react";
import { ToastSizeType } from "components/DesignSystem/Toast/Toast";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "store/slices/toast";

type ToastProps = {
  message?: string;
  title?: string;
  size?: ToastSizeType;
};

export const useToast = () => {
  const dispatch = useDispatch();

  const alertToast = useCallback(
    (
      {
        message = "Please try again",
        title = "Error",
        size = "regular",
      }: ToastProps,
      error?: Error
    ) => {
      if (error) {
        captureException(error);
      }

      return dispatch(
        showToast({
          message,
          title,
          size,
          type: "error",
        })
      );
    },
    []
  );

  const successToast = useCallback(
    ({ message, title = "Successful", size = "regular" }: ToastProps) => {
      return dispatch(
        showToast({
          message,
          title,
          size,
          type: "success",
        })
      );
    },
    []
  );

  const infoToast = useCallback(
    ({ message, title, size = "regular" }: ToastProps) => {
      return dispatch(
        showToast({
          message,
          title,
          size,
          type: "default",
        })
      );
    },
    []
  );

  return { alertToast, successToast, infoToast };
};
