export const Seperator = () => (
  <svg
    width="2"
    height="11"
    viewBox="0 0 2 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 0.5L1 10.5" stroke="#E7E6EB" strokeLinecap="round" />
  </svg>
);
