import { array, boolean, number, object, string } from "yup";

export const updateNoteSchema = object({
  description: string().min(1).required(""),
});

export const createNonChatTicketSchema = object({
  ticket_title: string().min(1).required("Title required"),
  company_group_id: string().required("Company group required"),

  group_entity_id: string().when("isNonChatTicket", {
    is: true,
    then: (schema) => schema.required("Group entity required"),
    otherwise: (schema) => schema.optional(),
  }),

  is_priced_ticket: string().required("Ticket type required"),

  price: number().when("is_priced_ticket", {
    is: "PRICED_TICKETS",
    then: (schema) =>
      schema.required().min(1, "Ticket price cannot be set to zero"),
    otherwise: (schema) => schema.optional(),
  }),

  assignees: array().min(1).required("Assignees required"),
  createChatChannel: boolean().notRequired(),

  channelName: string().when("createChatChannel", {
    is: true,
    then: string().required("Channel name required"),
    otherwise: string().notRequired(),
  }),
});

export const ticketPriceSchema = object({
  ticket_price: number()
    .moreThan(0, "Ticket price cannot be set to zero")
    .required("Invalid price"),
  ticket_title: string().min(1).required("Title required"),
});
