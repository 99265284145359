import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MessageSearchState = {
  isActive: boolean;
  channelId?: string | null;
  value?: string;
  expandSidebarOnClose?: boolean;
};

const initialState: MessageSearchState = {
  isActive: false,
  channelId: null,
  value: "",
  expandSidebarOnClose: false,
};

export const messageSearch = createSlice({
  name: "chatSearch",
  initialState,
  reducers: {
    setMessageSearchActive: (
      state,
      {
        payload,
      }: PayloadAction<{ setSideBarToExpandOnClose?: boolean } | undefined>
    ) => {
      state.isActive = true;
      state.expandSidebarOnClose = payload?.setSideBarToExpandOnClose;
    },
    setMessageSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.value = payload;
    },
    closeMessageSearch: (state) => {
      state.isActive = false;
      state.channelId = null;
      state.value = "";
    },
  },
});

export const {
  setMessageSearchActive,
  setMessageSearchValue,
  closeMessageSearch,
} = messageSearch.actions;

export default messageSearch.reducer;
