import { stateUpdate } from "../../apis/stateUpdate";

export const updateTaskState = async (state) => {
  try {
    let path = window.location.href;
    var url = path.split("/");
    const taskId = url[url.length - 1];

    await stateUpdate({ taskId, id: state });
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};
