import { axiosInstance } from "./apiInstance";

export const stateUpdate = async ({ taskId, id }) => {
  const response = await axiosInstance.put(`/api/inkle/tasks/${taskId}/`, {
    new_state_id: id,
  });

  return response.data;
};

export const updateTaskPrice = async ({ taskId, payload, groupId }) => {
  const { amount, notes } = payload;
  return await axiosInstance.put(
    `/api/inkle/billing/group/${groupId}/tasks/${taskId}/invoices/`,
    {
      amount: +amount,
      notes,
    }
  );
};
