import qs from "qs";
import { Task } from "types/Models/task";
import {
  AdHocTask,
  NonAdHocTask,
  TaskTemplate,
  TaskTemplateResponse,
} from "types/Models/taskTemplate";
import { emptyApi } from "./emptyApi";

export type UpdatedDeadlineResponse = {
  updated_deadline: string;
  deadline: string;
  is_fiscal: boolean;
  filing7004_filed: boolean;
  is_deadline_updated: boolean;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskTemplates: build.query<{ templates: TaskTemplateResponse[] }, void>({
      query: () => `/api/inkle/tasks/templates/`,
    }),

    getBaseTaskTemplates: build.query<
      TaskTemplate,
      {
        searchTerm?: string;
        task_category_type?:
          | "TAX"
          | "BOOKS"
          | "RND"
          | "INTRA_GROUP"
          | "SALES_TAX";
      }
    >({
      query: ({ searchTerm, task_category_type }) => {
        const queryParam = qs.stringify(
          { search_string: searchTerm, task_category_type },
          { skipNulls: true, addQueryPrefix: true }
        );
        return `api/inkle/tasks/base_task_templates/${queryParam}`;
      },
    }),

    getSingleTaskTemplates: build.query<TaskTemplate, { templateId: string }>({
      query: ({ templateId }) => {
        return `api/inkle/tasks/base_task_template/${templateId}`;
      },
    }),

    createTask: build.mutation<
      Task,
      { entity: string; metaData: AdHocTask | NonAdHocTask }
    >({
      query: ({ entity, metaData }) => {
        return {
          url: `/api/inkle/tasks/v1/company/entity/${entity}/task/`,
          method: "post",
          body: metaData,
        };
      },
      invalidatesTags: () => [{ type: "TaskTemplate", id: "LIST" }, "TaskList"],
    }),

    getUpdatedDeadlines: build.query<
      UpdatedDeadlineResponse,
      { season: string; baseTaskTemplateId: string; entity: string }
    >({
      query: ({ baseTaskTemplateId, season, entity }) => {
        const queryParam = qs.stringify(
          { base_task_template_id: baseTaskTemplateId, season },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/tasks/company/entity/${entity}/get_updated_deadline/${queryParam}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTaskTemplatesQuery,
  useGetTaskTemplatesQuery,
  useGetBaseTaskTemplatesQuery,
  useCreateTaskMutation,
  useGetSingleTaskTemplatesQuery,
  useLazyGetUpdatedDeadlinesQuery,
} = extendedApi;
