export const Blog = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6529_1877)">
      <path
        d="M7.25 10C7.94036 10 8.5 9.44036 8.5 8.75C8.5 8.05964 7.94036 7.5 7.25 7.5C6.55964 7.5 6 8.05964 6 8.75C6 9.44036 6.55964 10 7.25 10Z"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.00063 14L6.36625 9.63373"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 14L10.7156 12.5475C10.8021 12.533 10.8832 12.4961 10.9509 12.4404C11.0186 12.3846 11.0705 12.3121 11.1012 12.23L12.5 8.5L7.5 3.5L3.77 4.89875C3.68791 4.92955 3.61537 4.98139 3.55964 5.04907C3.50392 5.11676 3.46697 5.1979 3.4525 5.28438L2 14Z"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 8.50002L14.3538 6.64627C14.4474 6.55251 14.5001 6.42538 14.5001 6.29283C14.5001 6.16028 14.4474 6.03315 14.3538 5.93939L10.0625 1.64627C9.96874 1.55257 9.84161 1.49994 9.70906 1.49994C9.57651 1.49994 9.44938 1.55257 9.35563 1.64627L7.5 3.50002"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6529_1877">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
