import classNames from "classnames";
import { useToast } from "hooks/useToast";
import { FC } from "react";
import { useDropzone } from "react-dropzone";
import UploadFile from "static/images/UploadFile.svg";

type UploadCSVProps = {
  onDrop: (files: File[]) => void;
};

export const UploadCSV: FC<UploadCSVProps> = ({ onDrop }) => {
  const { alertToast } = useToast();

  const onDropRejected = () => {
    alertToast({ message: "Please upload a CSV file exclusively." });
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop,
      maxSize: 25000000,
      accept: { "text/xlsx": [".xlsx"] },
      onDropRejected,
    });

  return (
    <div
      className={classNames(
        "t-border-2 t-rounded-lg t-border-dashed t-border-neutral-20 t-px-6 t-py-8 t-text-body t-text-text-100 t-flex t-items-center t-justify-center t-flex-col t-gap-3 t-bg-surface-lighter-grey",
        {
          "!t-border-green": isDragAccept,
          "!t-border-red-50": isDragReject,
        }
      )}
      {...getRootProps()}
      onClick={open}
    >
      <input {...getInputProps()} />
      <img src={UploadFile} alt="Upload File" />
      Click or drag and drop to upload.
    </div>
  );
};
