import { forwardRef, Ref, RefAttributes } from "react";
import { JSX } from "react/jsx-runtime";
import {
  ReactionSelector as StreamReactionSelector,
  ReactionSelectorProps,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const ReactionSelector = forwardRef(
  (
    props: JSX.IntrinsicAttributes &
      ReactionSelectorProps<DefaultStreamChatGenerics> &
      RefAttributes<HTMLDivElement | null>,
    ref: Ref<HTMLDivElement | null> | undefined
  ) => {
    return <StreamReactionSelector {...props} ref={ref} />;
  }
);
