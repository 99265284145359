import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { TickMarkBlue } from "components/icons/TickMarkBlue";
import { InvoicePreivew } from "components/InvoicePreivew/InvoicePreivew";
import { Formik } from "formik";
import { invoiceValidation } from "formValidations/invoiceValidation";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { ComponentProps, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Invoice,
  useGetEntityInvoiceQuery,
  useGetInvoiceSettingsQuery,
  useLazyDownloadInvoiceQuery,
  useUpdateInvoiceMutation,
  useUpdateInvoiceRecurringMutation,
} from "store/apis/invoices";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { debounce } from "utils/debouncing";
import { openLink } from "utils/openLink";
import { useModal } from "hooks/useModal";
import { SendInvoiceModal } from "components/SendInvoiceModal/SendInvoiceModal";
import { DashboardLayout } from "components/DashboardLayout";
import { DownloadIcon } from "components/icons/Download";
import {
  InvoiceForm,
  invoiceInitialValues,
} from "components/InvoiceForm/InvoiceForm";
import { InvoiceInfo } from "components/InvoiceInfo/InvoiceInfo";
import { useAnalytics } from "hooks/useAnalytics";
import {
  CLICKED_DOWNLOAD_IN_DRAFT_INVOICE_VIEW,
  CLICKED_PREVIEW_AND_SEND_IN_DRAFT_INVOICE_VIEW,
} from "constants/analyticsEvents";
import { Divider } from "components/design/Divider";
import Toggle from "components/design/toggle";
import { RecurringInvoiceSetting } from "./Recurring/RecurringInvoiceSetting";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ConfirmChanges } from "./ConfirmChanges";
import { ColorInfo } from "components/icons/ColorInfo";
import { InvoiceActions } from "components/InvoiceAction/InvoiceAction";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { formatDate } from "utils/formatDate";
import { InvoiceConfiguration } from "components/InvoiceConfiguration/InvoiceConfiguration";
import { Badge } from "components/design/badge";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const FormikListener = ({
  values,
  callback,
}: {
  values: Record<string, any>;
  callback: (v: Record<string, any>) => void;
}) => {
  const stringValues = JSON.stringify(values);

  useEffect(() => {
    callback(values);
  }, [stringValues]);

  return null;
};

export const AddInvoice = ({
  breadcrumbs,
}: {
  breadcrumbs?: ComponentProps<typeof Header>["breadcrumbs"];
}) => {
  const { invoiceId } = useParams<{
    invoiceId: string;
  }>();
  const group = useCurrentGroupContext();
  const { alertToast } = useToast();
  const sendInvoiceModal = useModal();
  const { trackEvent } = useAnalytics();
  const query = useQuery();
  const editFlow = query.get("editflow");
  const confirmChangesModal = useModal();
  const entityId = useCurrentEntityId();

  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();
  const [getInvoiceDownloadUrl, { isLoading: downloadingInvoice }] =
    useLazyDownloadInvoiceQuery();

  const { data: invoice, isLoading: invoiceLoading } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const isInvoiceSent = invoice && invoice.status === "SENT";
  const isSentInvoiceEditFlow = isInvoiceSent && Boolean(editFlow);
  const [updateInvoice] = useUpdateInvoiceMutation();

  if (invoiceLoading) {
    return (
      <div className="t-flex t-h-full t-w-full t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  if (!invoice) {
    return null;
  }

  const onChange = debounce((values: Partial<Invoice>) => {
    if (isSentInvoiceEditFlow) return;
    if (group?.uuid && entityId) {
      updateInvoice({
        payload: values,
        groupId: group.uuid,
        entityId: entityId,
        invoiceId,
      });
    }
  });

  const downloadInvoice = async () => {
    try {
      if (group.uuid && entityId) {
        const { doc_id } = await getInvoiceDownloadUrl({
          groupId: group.uuid,
          entityId,
          invoiceId: invoice.uuid,
        }).unwrap();

        const { download_url } = await getPreviewUrl({
          groupId: group.uuid!,
          fileId: doc_id,
        }).unwrap();
        openLink(download_url, "_blank");
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const breadcrumbsWithCurrentInvoice = [
    ...(breadcrumbs || []),
    { name: invoice.invoice_number },
  ];

  return (
    <Formik
      onSubmit={() => {}}
      validateOnBlur
      validateOnChange={true}
      validationSchema={invoiceValidation}
      initialValues={
        invoice || (invoiceInitialValues as Omit<Invoice, "uuid" | "document">)
      }
    >
      {({ validateForm, values }) => {
        return (
          <DashboardLayout
            header={
              <Header
                v2
                title={
                  <div className="t-flex t-items-center t-justify-center t-gap-3">
                    <h2 className="t-text-h5 t-font-bold t-m-0">
                      {invoice.invoice_number}
                    </h2>
                    <Tag
                      rounded
                      icon={false}
                      tagType={
                        {
                          [INVOICE_STATUSES.DRAFT]: "gray",
                          [INVOICE_STATUSES.SENT]: "purple",
                          [INVOICE_STATUSES.PAID]: "green",
                        }[invoice.status] as "gray" | "purple" | "green"
                      }
                    >
                      <span className="t-text-body-sm">{invoice.status}</span>
                    </Tag>
                  </div>
                }
                right={
                  <div className="t-hidden t-items-center t-gap-3 md:t-flex">
                    <span className="t-text-body">
                      <div className="t-flex t-w-full t-gap-3">
                        {invoice &&
                          (invoice.status === "DRAFT" ||
                            isSentInvoiceEditFlow) && (
                            <Button
                              size="small"
                              type="button"
                              onClick={async () => {
                                trackEvent(
                                  CLICKED_DOWNLOAD_IN_DRAFT_INVOICE_VIEW
                                );
                                try {
                                  const errors = await validateForm();

                                  if (Object.entries(errors).length > 0) {
                                    return alertToast({
                                      message:
                                        "Please fill all the required fields.",
                                    });
                                  }

                                  downloadInvoice();
                                } catch (error) {}
                              }}
                              isLoading={downloadingInvoice}
                              disabled={downloadingInvoice}
                            >
                              <span className="t-flex t-items-center t-gap-1">
                                <DownloadIcon
                                  strokeWidth="1.5"
                                  color="currentColor"
                                />
                                <span>Download</span>
                              </span>
                            </Button>
                          )}

                        <Button
                          size="small"
                          type="button"
                          customType="primary"
                          onClick={async () => {
                            trackEvent(
                              CLICKED_PREVIEW_AND_SEND_IN_DRAFT_INVOICE_VIEW
                            );
                            try {
                              const errors = await validateForm();

                              if (Object.entries(errors).length > 0) {
                                return alertToast({
                                  message:
                                    "Please fill all the required fields.",
                                });
                              }
                              if (isSentInvoiceEditFlow) {
                                confirmChangesModal.open();
                                return;
                              }
                              sendInvoiceModal.open();
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          {isSentInvoiceEditFlow
                            ? "Save & Preview"
                            : isInvoiceSent
                            ? "Remind"
                            : "Preview & Send"}
                        </Button>
                        {invoice &&
                          invoice.status === "SENT" &&
                          !isSentInvoiceEditFlow && (
                            <InvoiceActions
                              invoice={invoice}
                              isFromInvoiceDetails
                            />
                          )}
                      </div>
                    </span>
                  </div>
                }
                breadcrumbs={breadcrumbsWithCurrentInvoice}
              />
            }
          >
            {isSentInvoiceEditFlow && (
              <div className="t-rounded-lg t-bg-orange-0 t-border t-border-solid t-border-orange-10 t-p-3 t-flex t-items-center t-gap-2 t-text-orange t-mb-4">
                <ColorInfo />
                <div className="t-text-subtitle-sm t-text-text-100">
                  You are editing a sent invoice
                </div>
              </div>
            )}
            <div className="t-h-full md:t-flex-row t-flex-col t-flex-1 t-gap-12 t-overflow-y-auto md:t-flex">
              {invoice.status === "DRAFT" || isSentInvoiceEditFlow ? (
                <InvoiceForm onChange={onChange} />
              ) : (
                <InvoiceInfo />
              )}

              <div className="md:t-sticky t-top-0 md:t-w-5/12">
                <div className="t-h-2/3 t-overflow-auto t-mb-4 t-min-h-[400px] t-flex t-justify-center t-bg-surface-lighter-grey t-p-2.5 t-rounded t-flex-col t-gap-5">
                  <div className="t-flex-col t-flex t-gap-3">
                    <p className="t-text-subtitle t-m-0">Preview</p>
                    <Divider />
                  </div>
                  <div className="t-max-w-[570px] t-bg-surface t-rounded t-overflow-auto t-flex-1 t-shadow-light-30">
                    <InvoicePreivew invoice={invoice} />
                  </div>
                </div>

                <InvoiceConfiguration />
              </div>
            </div>

            {sendInvoiceModal.isOpen && (
              <SendInvoiceModal
                {...sendInvoiceModal}
                isReminder={!isSentInvoiceEditFlow && invoice.status === "SENT"}
              />
            )}
            {confirmChangesModal.isOpen && (
              <ConfirmChanges
                open={confirmChangesModal.isOpen}
                close={confirmChangesModal.close}
                openReviewModal={sendInvoiceModal.open}
                entityId={entityId}
              />
            )}
          </DashboardLayout>
        );
      }}
    </Formik>
  );
};
