import PublicWFormUploadSuccessImg from "static/images/PublicWFormUploadSuccess.svg";

export const PublicWFormUploadSuccess = () => {
  return (
    <div className="t-bg-surface t-px-14 t-py-20 t-w-[500] t-rounded-2xl t-flex t-flex-col t-items-center t-justify-center">
      <img
        src={PublicWFormUploadSuccessImg}
        alt="PublicWFormUploadSuccessImg"
      />
      <div className="t-text-h4 t-text-text-100 t-mt-6">
        Successfully Uploaded
      </div>
      <div className="t-text-text-30 t-text-body-lg t-mt-1">
        Your work is done here, you can now close the tab.
      </div>
    </div>
  );
};
