import { axiosInstance } from "./apiInstance";

export const putDeadline = async ({ task_id, body }) => {
  const response = await axiosInstance.put(
    `/api/inkle/tasks/${task_id}/update/`,
    body
  );

  return response.data;
};
