import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";

export const ConfirmEdit = ({
  open,
  onClose,
  onConfirmSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onConfirmSubmit: () => void;
}) => {
  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik initialValues={{}} onSubmit={onConfirmSubmit}>
          {({ isSubmitting }) => (
            <Form className="all:unset">
              <Modal.Body>
                These changes will be reflected everywhere.
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-end t-gap-3">
                <Button type="reset" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  customType="primary"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
