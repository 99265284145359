import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import qs from "qs";
import {
  DepreciationSchedule,
  DepreciationSchedulePreview,
  FixedAssetResult,
} from "types/Models/fixedAsset";
import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getFixedAsset: build.query<
      { fixed_assets: FixedAssetResult[] } & Pagination,
      { groupId: string; entityId: string; pageNum: number; searchTerm: string }
    >({
      query: ({ groupId, entityId, pageNum, searchTerm }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
          },
          {
            filter: (prefix, value) =>
              value === false || value === "" ? undefined : value,
            skipNulls: true,
            addQueryPrefix: true,
          }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/fixed_asset/${queryUrl}`,
        };
      },
      providesTags: ["FIXED_ASSET"],
    }),

    getFixedAssetById: build.query<
      FixedAssetResult,
      {
        groupId: string;
        entityId: string;
        fixedAssetId: string;
      }
    >({
      query: ({ groupId, entityId, fixedAssetId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/fixed_asset/${fixedAssetId}/`,
        };
      },
    }),

    schedulePreview: build.mutation<
      DepreciationSchedulePreview,
      {
        groupId: string;
        entityId: string;
        asset_name: string;
        purchase_price: number;
        frequency: string;
        depreciation_start_date: string;
        salvage_amount: number;
        useful_life: number;
        description: string;
        method: "STRAIGHT_LINE";
        asset_account_id: string;
        depreciation_account_id: string;
        accumulated_depreciation_account_id: string;
        accumulated_depreciation_amount: number;
      }
    >({
      query: ({ groupId, entityId, depreciation_start_date, ...payload }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/depreciation_schedule_preview/`,
          method: "POST",
          body: {
            depreciation_start_date: dayjs(depreciation_start_date).format(
              YYYY_MM_DD
            ),
            ...payload,
          },
        };
      },
    }),

    createFixedAsset: build.mutation<
      {
        fixed_asset: FixedAssetResult;
        depreciation_schedule: DepreciationSchedule[];
      },
      {
        groupId: string;
        entityId: string;
        asset_name: string;
        purchase_price: number;
        frequency: string;
        depreciation_start_date: string;
        salvage_amount: number;
        useful_life: number;
        description: string;
        method: "STRAIGHT_LINE";
        asset_account_id: string;
        depreciation_account_id: string;
        accumulated_depreciation_account_id: string;
        accumulated_depreciation_amount: number;
      }
    >({
      query: ({ groupId, entityId, depreciation_start_date, ...payload }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/fixed_asset/`,
          method: "POST",
          body: {
            depreciation_start_date: dayjs(depreciation_start_date).format(
              YYYY_MM_DD
            ),
            ...payload,
          },
        };
      },
      invalidatesTags: ["FIXED_ASSET"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetFixedAssetQuery,
  useGetFixedAssetByIdQuery,
  useSchedulePreviewMutation,
  useCreateFixedAssetMutation,
} = extendedApi;
