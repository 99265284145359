import { axiosInstance } from "./apiInstance";

export const deleteTeamSettingTags = async ({ uuid, tagId }) => {
  const response = await axiosInstance.delete(
    `/api/inkle/tags/group_user_map/${uuid}/tag/${tagId}/`
  );
  return response.data;
};

export const postTeamSettingTags = async ({ uuid, tagId }) => {
  const response = await axiosInstance.post(
    `/api/inkle/tags/group_user_map/${uuid}/tag/${tagId}/`
  );

  return response.data;
};

export const invite = async (profileId, groupId) => {
  const response = await axiosInstance.post(
    `/api/inkle/company/v2/group/${groupId}/invite_user/`,
    { profileId }
  );

  return response.data;
};
