export const MapPin = ({ color = "#DDD3FF" }: { color?: string }) => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_159_18685)">
        <rect
          x="1"
          y="0.5"
          width="24"
          height="24"
          rx="4"
          fill={color}
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_159_18685)">
          <path
            d="M13 5.5C11.5418 5.50165 10.1438 6.08165 9.11274 7.11274C8.08165 8.14383 7.50165 9.54182 7.5 11C7.5 15.7063 12.5 19.2606 12.7131 19.4094C12.7972 19.4683 12.8974 19.4999 13 19.4999C13.1026 19.4999 13.2028 19.4683 13.2869 19.4094C13.5 19.2606 18.5 15.7063 18.5 11C18.4983 9.54182 17.9184 8.14383 16.8873 7.11274C15.8562 6.08165 14.4582 5.50165 13 5.5ZM13 9C13.3956 9 13.7822 9.1173 14.1111 9.33706C14.44 9.55682 14.6964 9.86918 14.8478 10.2346C14.9991 10.6001 15.0387 11.0022 14.9616 11.3902C14.8844 11.7781 14.6939 12.1345 14.4142 12.4142C14.1345 12.6939 13.7781 12.8844 13.3902 12.9616C13.0022 13.0387 12.6001 12.9991 12.2346 12.8478C11.8692 12.6964 11.5568 12.44 11.3371 12.1111C11.1173 11.7822 11 11.3956 11 11C11 10.4696 11.2107 9.96086 11.5858 9.58579C11.9609 9.21071 12.4696 9 13 9Z"
            fill="white"
          />
        </g>
        <rect
          x="1.5"
          y="1"
          width="23"
          height="23"
          rx="3.5"
          stroke={color}
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_159_18685"
          x="0"
          y="0.5"
          width="26"
          height="26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_18685"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_18685"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_159_18685">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(5 4.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
