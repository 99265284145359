import { Calendar } from "components/calendar/Calendar";
import { Header } from "components/DesignSystem/Header/Header";
import { usePageTitle } from "hooks/usePageTitle";
import { CalendarYearSelector } from "./Calender/CalendarYearSelector";
import { EntitySelector } from "../components/EntitySelector/EntitySelector";

export const PrivateCalendar = () => {
  usePageTitle("Deadline Alerts");

  return (
    <Calendar
      header={
        <Header
          v2
          title="Deadline Alerts"
          right={
            <div className="t-flex gap-4">
              <EntitySelector showOnlyUSEntities />
              <CalendarYearSelector />
            </div>
          }
        />
      }
    />
  );
};
