import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useAcceptTOSMutation } from "store/apis/transferPricing";
import { TP_TOS } from "types/contants/tpPayments";

export const AcceptTOS = () => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
  const { isCpa } = useRoleBasedView();

  const [acceptTOSAPI, { isLoading }] = useAcceptTOSMutation();

  const handleConfirm = async () => {
    try {
      await acceptTOSAPI({ groupId }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <div className="t-mt-5 t-flex t-w-full t-justify-between t-rounded t-border t-border-solid t-border-yellow-40 t-bg-yellow-10 t-px-6 t-py-4 t-shadow-card">
      <Checkbox
        label={
          <div>
            I agree to the updated
            <ConditionalLink to={TP_TOS}>
              &nbsp;Terms of Service
            </ConditionalLink>
            &nbsp;for Inkle TP Payments.
          </div>
        }
        disabled={isCpa}
        name="termsandcondition"
        onChange={() => setIsCheckBoxSelected((prev) => !prev)}
        checked={isCheckBoxSelected}
      />
      <Button
        disabled={isLoading || !isCheckBoxSelected || isCpa}
        isLoading={isLoading}
        customType="primary"
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </div>
  );
};
