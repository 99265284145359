export const InkleAdminTax = () => (
  <svg
    width="189"
    height="56"
    viewBox="0 0 189 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7191 24.0048L19.5993 32.9999L17.0308 30.4665C15.6564 29.1109 15.6564 26.9129 17.0308 25.5572L23.6619 19.0168C25.0364 17.6611 27.2648 17.6611 28.6393 19.0168L28.7191 19.0954C30.0935 20.4511 30.0935 22.6491 28.7191 24.0048Z"
      fill="url(#paint0_linear_5723_7938)"
    />
    <path
      d="M23.2808 31.9951L32.4006 23L34.9691 25.5334C36.3435 26.889 36.3435 29.087 34.9691 30.4427L28.338 36.9831C26.9635 38.3388 24.7351 38.3388 23.3606 36.9831L23.2808 36.9045C21.9064 35.5488 21.9064 33.3508 23.2808 31.9951Z"
      fill="url(#paint1_linear_5723_7938)"
    />
    <path
      d="M45.9995 20.065C45.9995 18.8121 46.7795 18 48.0053 18C49.2087 18 49.9888 18.8121 49.9888 20.065C49.9888 21.3178 49.2087 22.1531 48.0053 22.1531C46.7795 22.1531 45.9995 21.3178 45.9995 20.065ZM49.7882 37.8839H46.2224V23.9628H49.7882V37.8839Z"
      fill="url(#paint2_linear_5723_7938)"
    />
    <path
      d="M60.7283 30.9001C60.7283 28.8584 59.7032 27.5127 58.1208 27.5127C56.6277 27.5127 55.5356 28.9512 55.5356 30.9234V37.8839H51.9698V23.9628H55.5356V25.587C56.0037 24.5429 57.3854 23.8004 58.7671 23.8004C62.1769 23.8004 64.2941 26.631 64.2941 30.9001V37.8839H60.7283V30.9001Z"
      fill="url(#paint3_linear_5723_7938)"
    />
    <path
      d="M70.0113 37.8839H66.4455V18.1624H70.0113V30.2041L74.5354 23.9628H78.4355L73.8891 30.9234L78.9035 37.8839H74.3794L70.0113 31.6426V37.8839Z"
      fill="url(#paint4_linear_5723_7938)"
    />
    <path
      d="M83.8042 37.8839H80.2384V18.1624H83.8042V37.8839Z"
      fill="url(#paint5_linear_5723_7938)"
    />
    <path
      d="M94.6453 33.6147H98.434C97.5203 36.2133 95.1356 37.9999 92.4167 37.9999C88.5166 37.9999 85.7754 35.0533 85.7754 30.8769C85.7754 26.8399 88.6058 23.7772 92.3499 23.7772C96.0939 23.7772 98.9243 26.8399 98.9243 30.8769C98.9243 31.3178 98.902 31.7818 98.8352 32.1994H89.5195C89.9207 33.6147 91.0127 34.5196 92.3944 34.5196C93.4419 34.5196 94.2665 34.1484 94.6453 33.6147ZM89.5195 29.5312H95.1802C94.7791 28.1623 93.7316 27.2807 92.3499 27.2807C90.9681 27.2807 89.9207 28.1623 89.5195 29.5312Z"
      fill="url(#paint6_linear_5723_7938)"
    />
    <path
      d="M169.853 31.7402C169.853 29.273 168.589 27.6827 166.631 27.6827C164.817 27.6827 163.512 29.3954 163.512 31.7606V37.8774H161.31V25.6437H163.512V27.9273C163.981 26.5205 165.367 25.501 166.998 25.501C170.097 25.501 172.055 27.9681 172.055 31.7402V37.8774H169.853V31.7402Z"
      fill="#41395C"
    />
    <path
      d="M156.732 21.9739C156.732 21.1991 157.242 20.6689 158.057 20.6689C158.832 20.6689 159.342 21.1991 159.342 21.9739C159.342 22.7691 158.832 23.2992 158.057 23.2992C157.242 23.2992 156.732 22.7691 156.732 21.9739ZM159.158 37.8777H156.956V25.644H159.158V37.8777Z"
      fill="#41395C"
    />
    <path
      d="M144.074 31.7402C144.074 29.273 142.81 27.6827 140.852 27.6827C139.038 27.6827 137.733 29.3954 137.733 31.7606V37.8774H135.531V25.6437H137.733V27.9273C138.202 26.5205 139.588 25.501 141.219 25.501C143.177 25.501 144.686 26.5001 145.522 28.172C146.317 26.4797 147.703 25.501 149.457 25.501C152.74 25.501 154.819 27.9681 154.819 31.7402V37.8774H152.617V31.7402C152.617 29.273 151.353 27.6827 149.375 27.6827C147.581 27.6827 146.276 29.3954 146.276 31.7402V37.8774H144.074V31.7402Z"
      fill="#41395C"
    />
    <path
      d="M131.167 37.878V35.5944C130.616 37.0216 128.883 38.0003 127.048 38.0003C123.521 38.0003 121.196 35.5128 121.196 31.7407C121.196 27.9891 123.521 25.5015 127.048 25.5015C128.842 25.5015 130.616 26.4802 131.167 27.9279V20.5469H133.369V37.878H131.167ZM123.398 31.7407C123.398 34.1875 125.009 35.7983 127.293 35.7983C129.597 35.7983 131.187 34.1875 131.167 31.7611C131.167 29.3552 129.536 27.6832 127.293 27.6832C124.989 27.6832 123.398 29.294 123.398 31.7407Z"
      fill="#41395C"
    />
    <path
      d="M103.924 37.878L110.938 20.5469H113.283L120.297 37.878H117.952L116.097 33.3107H108.125L106.269 37.878H103.924ZM108.981 31.1698H115.241L112.121 23.4422L108.981 31.1698Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7938"
        x1="16"
        y1="25.5"
        x2="29.7499"
        y2="25.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7938"
        x1="22.25"
        y1="30.5"
        x2="35.9999"
        y2="30.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7938"
        x1="45.9995"
        y1="27.9999"
        x2="98.9243"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7938"
        x1="45.9995"
        y1="27.9999"
        x2="98.9243"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5723_7938"
        x1="45.9995"
        y1="27.9999"
        x2="98.9243"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5723_7938"
        x1="45.9995"
        y1="27.9999"
        x2="98.9243"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5723_7938"
        x1="45.9995"
        y1="27.9999"
        x2="98.9243"
        y2="27.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
