import { FiltersValues } from "store/slices/reportFilter";

export const redirectToCategories = ({
  uuid,
  groupId,
  entityId,
  capsuleFilters,
  getFilterName,
  isAdmin,
}: {
  uuid: string;
  groupId: string;
  entityId: string;
  capsuleFilters: FiltersValues[];
  getFilterName: (e: string) => void;
  isAdmin: boolean;
}) => {
  let to = `/books/categories?entity=${entityId}&category=${uuid}`;

  to = capsuleFilters
    ?.filter((ele: FiltersValues) => ele && ele?.value)
    ?.reduce((acc: string, filter: FiltersValues) => {
      acc = acc + `&${getFilterName(filter?.name)}=${filter?.value}`;
      return acc;
    }, to);

  if (isAdmin) {
    to = to + `&company=${groupId}`;
  }

  return to;
};
