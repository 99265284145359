import { ValidationError, array, object, string } from "yup";

export const raiseGroupValidation = object({
  description: string().max(150, "Max characters allowed is 150."),
  company_pitch: string().transform((value) => {
    const words = value.trim().split(/\s+/);
    if (words.length > 150) {
      throw new ValidationError("Max words allowed is 150.");
    }
    return value;
  }),
  year_founded: string().min(4, "Enter year").max(4, "Enter year"),
  current_annual_revenue: string().required("Please enter the current ARR."),
  round_type: string().required("Please select the a type of round."),
  cofounder_data: array().of(
    object({
      cofounder_name: string().required("Founder name is required"),
      cofounder_email: string()
        .email("Enter a valid email")
        .required("Email is required"),
    })
  ),
});
