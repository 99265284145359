import { ConditionalLink } from "components/conditionalLink";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { DashboardLayout } from "components/DashboardLayout";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { Header } from "components/DesignSystem/Header/Header";
import { Link } from "components/DesignSystem/Link/Link";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { DateFilter } from "components/Filters/DateFilter";
import { MultiGroupFilter } from "components/Filters/MultiGroupFilter";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import qs from "qs";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Column,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  AuditLog,
  useGetAuditLogsQuery,
  useGetCompanyGroupAuditLogsQuery,
} from "store/apis/auditlogs";
import { formatDate, formatTime } from "utils/formatDate";

const ProductType = ({ log }: { log: AuditLog }) => {
  const { isAdmin } = useRoleBasedView();

  if (log.obj?.content_type === "companygroupinvoice") {
    const linkWithInvoiceId = isAdmin
      ? `/crm/${log.company_group?.uuid}/billing/invoices/${log.obj.uuid}?entity=${log.company_entity?.uuid}`
      : `/billing/invoices/${log.obj.uuid}?entity=${log.company_entity?.uuid}`;

    const linkWithoutInvoiceId = isAdmin
      ? `/crm/${log.company_group?.uuid}/billing/invoices/`
      : `/billing/invoices/`;

    return (
      <Link to={log.company_entity ? linkWithInvoiceId : linkWithoutInvoiceId}>
        Invoice
      </Link>
    );
  }

  if (log.obj?.content_type === "companygroupsubscriptiondata") {
    const linkWithEntityId = isAdmin
      ? `/crm/${log.company_group?.uuid}/billing?entity=${log.company_entity?.uuid}`
      : `/billing?entity=${log.company_entity?.uuid}`;

    const linkWithoutEntityId = isAdmin
      ? `/crm/${log.company_group?.uuid}/billing`
      : `/billing`;

    return (
      <Link to={log.company_entity ? linkWithEntityId : linkWithoutEntityId}>
        Subscription
      </Link>
    );
  }

  if (log.obj?.content_type === "entityitem") {
    const query = qs.stringify(
      {
        entity: log.company_entity?.uuid,
        company: log.company_group?.uuid,
      },
      { addQueryPrefix: true }
    );

    return (
      <Link to={`/books/data-sources/${log.obj.uuid}/${query}`}>
        Data source
      </Link>
    );
  }

  if (log.obj?.content_type === "entitytransaction") {
    const query = qs.stringify(
      {
        entity: log.company_entity?.uuid,
        company: log.company_group?.uuid,
        transaction_id: log.obj.uuid,
      },
      { addQueryPrefix: true }
    );

    return <Link to={`/books/transactions/${query}`}>Transaction</Link>;
  }

  if (log.obj?.content_type === "task") {
    return <Link to={`/tax/filings/${log.obj.uuid}`}>Task</Link>;
  }

  return (
    <span className="t-capitalize">{log.product_type.toLocaleLowerCase()}</span>
  );
};

const createAuditLogColumns = createColumnHelper<AuditLog>();

const defaultData: never[] = [];

const AuditLogsTable = () => {
  const page = useQuery().get("page") || "1";
  const { update } = useUpdateQuery();
  const { isAdmin, isCustomer } = useRoleBasedView();
  const group = useCurrentGroupContext();

  const paginationControls = usePagination({
    pageNumber: Number(page),
    onPageNumChange: (pageNum) => update({ query: "page", value: pageNum }),
  });

  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      START_DATE: "" as string,
      END_DATE: "" as string,
      SELECT_PERIOD: "" as string,
      ...(group.uuid
        ? {}
        : {
            GROUPS: [] as string[],
            ENDITY_ID: "" as string,
          }),
    },
  });

  const { data } = useGetAuditLogsQuery(
    {
      pageNum: Number(page),
      groupIds: filterValues.GROUPS?.join(","),
      startDate: filterValues.START_DATE,
      endDate: filterValues.END_DATE,
      entityId: filterValues.ENDITY_ID,
    },
    {
      skip: Boolean(group.uuid),
    }
  );

  const { data: customerAuditLog } = useGetCompanyGroupAuditLogsQuery(
    {
      pageNum: Number(page),
      groupId: group.uuid,
      startDate: filterValues.START_DATE,
      endDate: filterValues.END_DATE,
      entityId: filterValues.ENDITY_ID,
    },
    {
      skip: !group.uuid,
    }
  );

  const auditlogs = group.uuid ? customerAuditLog : data;

  const auditLogColumns = useMemo(
    () => [
      createAuditLogColumns.accessor("profile.short_name", {
        header: "User",
        size: 15,
        cell: (info) => info.getValue() || "-",
      }),
      createAuditLogColumns.accessor("event.name", {
        header: "Description",
        size: 30,
      }),
      createAuditLogColumns.accessor("product_type", {
        header: "Feature",
        size: 10,
        cell: (info) => <ProductType log={info.row.original} />,
      }),

      ...(!group.uuid
        ? [
            createAuditLogColumns.accessor("company_group.name", {
              header: "Company group",
              size: 15,
              cell: (info) => (
                <Link to={`/crm/${info.row.original.company_group?.uuid}`}>
                  {info.getValue()}
                </Link>
              ),
            }),
          ]
        : []),
      createAuditLogColumns.accessor("company_entity.name", {
        header: "Entity",
        size: 10,
        cell: (info) => {
          const entityId = info.row.original.company_entity?.uuid;
          const link = isAdmin
            ? `/crm/${info.row.original.company_group?.uuid}/entity/${entityId}`
            : `/tax/entities/entity/${entityId}`;

          return (
            <Link to={entityId ? link : ""}>{info.getValue() || "-"}</Link>
          );
        },
      }),

      createAuditLogColumns.accessor("event.time", {
        header: "Date & Time",
        size: 20,
        cell: (info) =>
          `${formatDate(info.getValue())} ${formatTime(info.getValue())}`,
      }),
    ],
    [isAdmin]
  );

  const tableData = useMemo(
    () => (group.uuid ? customerAuditLog?.logs : data?.logs),
    [customerAuditLog, data, isCustomer]
  );

  const table = useReactTable({
    data: tableData || defaultData,
    columns: auditLogColumns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  useEffect(() => {
    if (auditlogs?.total_pages) {
      paginationControls.setTotalPage(auditlogs?.total_pages);
    }
  }, [auditlogs?.total_pages, paginationControls.setTotalPage]);

  return (
    <DashboardContainer className="t-gap-4 t-h-full">
      <DashboardContainer.Header>
        <div className="t-flex t-justify-between">
          <Filter.Root
            defaultValue={group.uuid ? "ENTITY_ID" : "GROUP"}
            capsule={
              <>
                {Boolean(filterValues?.START_DATE) && (
                  <Chip
                    onClose={() => {
                      updateFilter("START_DATE", "");
                      updateFilter("SELECT_PERIOD", "");
                    }}
                    isActive
                    filterType="DATE"
                  >
                    From {dayjs(filterValues.START_DATE).format(DD_MMM_YYYY)}
                  </Chip>
                )}
                {Boolean(filterValues?.END_DATE) && (
                  <Chip
                    onClose={() => {
                      updateFilter("END_DATE", "");
                      updateFilter("SELECT_PERIOD", "");
                    }}
                    isActive
                    filterType="DATE"
                  >
                    To {dayjs(filterValues.END_DATE).format(DD_MMM_YYYY)}
                  </Chip>
                )}
                {Boolean(filterValues?.ENDITY_ID) && (
                  <Chip
                    onClose={() => {
                      updateFilter("ENDITY_ID", "");
                    }}
                    isActive
                    filterType="ENTITY_ID"
                  >
                    Entity:{" "}
                    {
                      group?.entities?.find(
                        (entity) => entity.uuid === filterValues.ENDITY_ID
                      )?.name
                    }
                  </Chip>
                )}
              </>
            }
          >
            <Filter.Portal>
              <Filter.List>
                {!group.uuid && (
                  <Filter.ListItem value="GROUP">Company group</Filter.ListItem>
                )}
                {group.uuid && (
                  <Filter.ListItem value="ENTITY_ID">Entity</Filter.ListItem>
                )}
                <Filter.ListItem value="DATE">Date</Filter.ListItem>
              </Filter.List>
              <Filter.Body value="GROUP" block>
                <MultiGroupFilter
                  values={filterValues}
                  updateFilter={updateFilter}
                />
              </Filter.Body>
              <Filter.Body value="ENTITY_ID" block>
                <RadioGroup.Root
                  value={filterValues.ENDITY_ID}
                  className="t-flex t-flex-col t-gap-3"
                  onValueChange={(value) => updateFilter("ENDITY_ID", value)}
                >
                  {group?.entities?.map((entity) => (
                    <RadioGroup.Item key={entity.uuid} value={entity.uuid}>
                      {entity.name}
                    </RadioGroup.Item>
                  ))}
                </RadioGroup.Root>
              </Filter.Body>
              <Filter.Body value="DATE">
                <DateFilter values={filterValues} updateFilter={updateFilter} />
              </Filter.Body>
            </Filter.Portal>
          </Filter.Root>

          <div className="t-flex t-gap-2">
            <Pagination
              totalItemCount={auditlogs?.total_count || 0}
              totalPage={auditlogs?.total_pages || 1}
              currentPage={auditlogs?.current_page || 1}
              itemsPerPage={auditlogs?.per_page || 1}
              goToFirstPage={paginationControls.goToFirstPage}
              goToPrevPage={paginationControls.goToPrevPage}
              goToNextPage={paginationControls.goToNextPage}
              goToLastPage={paginationControls.goToLastPage}
            />
          </div>
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        <TableUI size="regular" table={table} />
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};

export const AuditLogs = () => {
  return (
    <DashboardLayout header={<Header v2 title="Audit Logs" />}>
      <AuditLogsTable />
    </DashboardLayout>
  );
};
