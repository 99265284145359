import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  deleteTeamSettingTags,
  postTeamSettingTags,
} from "../../apis/teamSettingTags";
import "../../static/styles/components/taskAssignee.css";
import { TagDropdown } from "./TagDropdown";

export default function Tags({ uuid, tags, currentTags, refetch }) {
  const [isLoading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState();
  const { alertToast } = useToast();

  const addTag = (value) => {
    setCurrentId((prevId) => value || prevId);

    setLoading(true);
    let tagId = value;
    postTeamSettingTags({ uuid, tagId })
      .then(() => {
        refetch();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alertToast({ message: error?.response?.data?.error?.message });
      });
  };

  const deleteTag = (value) => {
    setCurrentId((prevId) => value || prevId);

    setLoading(true);
    let tagId = value;
    deleteTeamSettingTags({ uuid, tagId })
      .then(() => {
        refetch();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alertToast({ message: error?.response?.data?.error?.message });
      });
  };

  return (
    <TagDropdown
      currentTags={currentTags}
      tags={tags}
      onAdd={addTag}
      isLoading={isLoading}
      currentId={currentId}
      onDelete={deleteTag}
    />
  );
}
