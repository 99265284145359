import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { LocalFileOrInkleDocs } from "types/Models/reviewDocumentAndBalancingPayment";
import { CustomFilePreviewCard } from "./CustomFilePreviewCard";

export const CustomAttachmentPreviewList = () => {
  const attachments = useSelector(
    (state: RootState) => state.reviewAndBalancePayment.customAttachment
  );

  return (
    <div className="custom-attachment-preview-list t-flex t-gap-3 t-overflow-scroll">
      {attachments.map((attachment: LocalFileOrInkleDocs) => {
        const extension = attachment?.name
          ?.split?.(".")
          ?.pop?.()
          ?.toUpperCase?.();
        let path = "";
        if ("uuid" in attachment) {
          path = attachment.uuid;
        }
        if ("path" in attachment) {
          path = attachment.path;
        }
        return (
          <CustomFilePreviewCard
            key={path}
            name={attachment.name}
            extension={extension!}
            path={path}
          />
        );
      })}
    </div>
  );
};
