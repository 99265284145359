import dayjs from "dayjs";
import { formatDate } from "utils/formatDate";

export const MessageDateFormat = ({
  createdAt,
}: {
  createdAt?: string | Date;
}) => {
  const messageSentToday = dayjs(createdAt).isToday();
  const messageSentYesterday = dayjs(createdAt).isYesterday();
  const messageSentPastWeek = dayjs().diff(dayjs(createdAt), "days") < 7;

  let date = createdAt && formatDate(createdAt);

  if (messageSentPastWeek) {
    date = dayjs(createdAt).format("dddd");
  }

  if (messageSentToday) {
    date = dayjs(createdAt).format("hh:mm a");
  }

  if (messageSentYesterday) {
    date = "Yesterday";
  }

  return <>{date || null}</>;
};
