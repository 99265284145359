import { emptyApi } from "./emptyApi";
import qs from "qs";
import { FSP_GROUP } from "types/Models/group";

export type TeamMember = {
  name: string;
  email: string;
  role: string;
  status: string;
  uuid: string;
  service_team: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTeamManagementMembers: build.query<
      TeamMember[],
      { searchTerm?: string | null; serviceTeamId?: string | null }
    >({
      query: ({ searchTerm, serviceTeamId }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
            service_team_id: serviceTeamId,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/serviceteams/settings/${queryUrl}`,
        };
      },
      providesTags: ["CPATeamManagement"],
    }),

    inviteManageTeamMembers: build.mutation({
      query: ({
        payload,
      }: {
        payload: {
          first_name: string;
          last_name: string;
          email: string;
        };
      }) => {
        return {
          url: `/api/inkle/serviceteams/settings/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["CPATeamManagement"],
    }),

    changeAdmin: build.mutation<
      TeamMember,
      {
        memberId: string;
        payload: {
          action: string;
        };
      }
    >({
      query: ({ memberId, payload }) => {
        return {
          url: `/api/inkle/serviceteams/team_member/${memberId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["CPATeamManagement"],
    }),

    getPracticeDetail: build.query<FSP_GROUP, { serviceTeamId: string }>({
      query: ({ serviceTeamId }) => {
        return {
          url: `/api/inkle/serviceteams/${serviceTeamId}/`,
        };
      },
      providesTags: ["PracticeTeamManagement"],
    }),
  }),
});

export const {
  useGetTeamManagementMembersQuery,
  useInviteManageTeamMembersMutation,
  useChangeAdminMutation,
  useGetPracticeDetailQuery,
} = extendedApi;
