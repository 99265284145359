import Tab from "components/DesignSystem/Tab/Tab";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { ArchivedCrm } from "./ArchivedCrm";
import SalesCrm from "./salesCrm";
import SalesCrmProfiles from "./SalesCrmProfiles";

export const SalesCrmRoute = ({ selectedTabIs }: { selectedTabIs: string }) => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <div className="t-w-full ">
      <Tab.Root defaultValue={pathname} value={pathname}>
        <div className="t-pl-6 t-box-border t-w-full t-border t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-neutral-20">
          <Tab.List>
            <Tab.NavTrigger exact value={url}>
              Groups
            </Tab.NavTrigger>
            <Tab.NavTrigger exact value={`${url}/profiles`}>
              Profiles
            </Tab.NavTrigger>
            <Tab.NavTrigger exact value={`${url}/archived`}>
              Archived
            </Tab.NavTrigger>
          </Tab.List>
        </div>
      </Tab.Root>
      <Switch>
        <Route exact path={path}>
          <SalesCrm />
        </Route>
        <Route exact path={`${path}/profiles`}>
          <SalesCrmProfiles />
        </Route>
        <Route exact path={`${path}/archived`}>
          <ArchivedCrm />
        </Route>
      </Switch>
    </div>
  );
};
