import { useStripe } from "@stripe/react-stripe-js";
import { profileData } from "apis/profileData";
import { Button } from "components/DesignSystem/Button/Button";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import WarningCircle from "static/images/WarningCircle.svg";
import {
  useReviveSubscriptionMutation,
  useSavePaymentMethodMutation,
} from "store/apis/subscriptions";
import { setSubscriptionInactiveStatus } from "store/slices/subscriptionModal";
import ReviveSubscriptionPaymentFailed from "./ReviveSubscriptionPaymentFailed";
import { useAppSelector } from "hooks/useAppSelector";
import Modal from "components/DesignSystem/Modal/Modal";
import GrayFilledInfo from "static/images/GrayFilledInfo.svg";
import { BankLogos, BILLING_CYCLE } from "dictionaries";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import ReactCountryFlag from "react-country-flag";
import { useGetAllSavedCardsQuery } from "store/apis/billing";
import { ConditionalLink } from "components/conditionalLink";
import * as Accordion from "@radix-ui/react-accordion";
import { CARD_ACCORDION } from "constants/billing";
import classNames from "classnames";
import { PlusIcon } from "components/icons/PlusIcon";
import { CaretRight } from "components/icons/CaretRight";
import SavedCards from "components/billing/SavedCards";
import { AddCardModal } from "components/billing/AddCardModal";
import { US } from "constants/countryCodes";
import * as Sentry from "@sentry/react";

const ReviveSubscription = () => {
  const { successToast } = useToast();
  const history = useHistory();
  const group = useCurrentGroupContext();
  const { uuid: groupId } = group;
  const [showCardModal, setShowCardModal] = useState(false);
  const [showPaymentFail, setShowPaymentFail] = useState(false);
  const [reviveSubscription] = useReviveSubscriptionMutation();
  const [reviving, setReviving] = useState(false);
  const INCOMPLETE = "INCOMPLETE";
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [showAddCard, setShowAddCard] = useState<boolean>();
  const [savePaymentMethod] = useSavePaymentMethodMutation();
  const { subscriptionsInactive } = useAppSelector(
    (state) => state.subscriptionModal
  );

  const [entityId, setEntityId] = useState<string | null>();

  const { data: savedCards = [], isLoading: isCardsLoading } =
    useGetAllSavedCardsQuery(
      { groupId: groupId, entityId: entityId! },
      { skip: !groupId || !entityId }
    );

  const defaultCard = savedCards?.find(
    ({ is_default_card }) => is_default_card
  );

  const [paymentMethodId, setPaymentMethodId] = useState<string | undefined>(
    defaultCard?.payment_method_id
  );

  useEffect(() => {
    if (savedCards.length > 0) {
      const defaultCard = savedCards?.find(
        ({ is_default_card }) => is_default_card
      );

      setPaymentMethodId(
        defaultCard?.payment_method_id || savedCards.at(0)?.payment_method_id
      );
    }
  }, [isCardsLoading, savedCards.length]);

  const startCheckingIfProfileSubscriptionStatusUpdated = async (
    reject: any,
    resolve: any,
    totalSeconds: number = 0
  ) => {
    const res = await profileData();
    const noInactiveSubscriptions =
      res.data.data?.platform_subscription?.subscription_status !==
        INCOMPLETE &&
      res.data.data?.addon_subscriptions?.every(
        ({ subscription_status }: { subscription_status: string }) =>
          subscription_status !== INCOMPLETE
      );

    if (totalSeconds === 1 * 60 * 1000) {
      reject();
    }

    if (noInactiveSubscriptions) {
      dispatch(setSubscriptionInactiveStatus([]));

      resolve();
      history.push("/billing");
    } else {
      setTimeout(
        () =>
          startCheckingIfProfileSubscriptionStatusUpdated(
            reject,
            resolve,
            totalSeconds + 2000
          ),
        2000
      );
    }
  };

  const reviveSubscriptionPayment = async () => {
    if (paymentMethodId) {
      try {
        setReviving(true);
        const entityId = subscriptionsInactive[0].entity_id;
        const { payment_intent_client_secret, stripe_user_profile_id } =
          await reviveSubscription({
            groupId,
            entityId,
          }).unwrap();

        const paymentResponse = await stripe?.confirmCardPayment(
          payment_intent_client_secret,
          {
            payment_method: paymentMethodId,
            setup_future_usage: "off_session",
          }
        );

        if (paymentResponse?.error) {
          setReviving(false);
          throw new Error("Failed to confirm, please contact support.");
        }

        await savePaymentMethod({
          groupId: groupId,
          stripeUserProfileId: stripe_user_profile_id,
          paymentMethodId,
          isDefaultCard: true,
          entityId,
        });

        await new Promise((resolve, reject) =>
          startCheckingIfProfileSubscriptionStatusUpdated(reject, resolve, 0)
        );
        successToast({ message: "Payment Successful" });
        setReviving(false);
      } catch (e: any) {
        Sentry.captureException(e, {
          data: {
            error: "Failed to revive subscription payment",
          },
        });
        setShowPaymentFail(true);
      }
    }
  };

  const openPaymentMethodModal = () => {
    setShowPaymentFail(false);
    setShowCardModal(true);
  };

  const entityOptions = group.entities
    .filter((e) => e.country_code === US)
    .map(({ name, uuid, country, country_code }) => ({
      label: (
        <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
          <ReactCountryFlag countryCode={country_code} svg title={country} />
          <span className="t-truncate t-max-w-36">{name}</span>
        </div>
      ),
      value: uuid,
    }));

  const currentEntity = entityOptions.find(
    (entity) => entity.value === entityId
  );

  if (showAddCard && entityId) {
    return (
      <AddCardModal
        entityId={entityId}
        show
        closeModal={() => setShowAddCard(false)}
        cardsAdded={savedCards.length > 0}
        ispaymentFlow
      />
    );
  }

  return (
    <div className="t-w-full t-flex-none">
      <div className="t-flex t-w-full t-items-center t-justify-between t-bg-red-10 t-px-5 t-py-2 t-rounded">
        <div className="t-flex t-items-center t-gap-1">
          <img src={WarningCircle} alt="WarningCircle" />
          <span className="t-text-subtext">
            Your subscription payment has failed.
          </span>
        </div>
        <div className="t-flex t-items-center t-gap-2">
          <Button
            size="small"
            customType="danger"
            onClick={() => setShowCardModal(true)}
            isLoading={reviving}
          >
            Try Again
          </Button>
        </div>
      </div>
      {showCardModal && (
        <Modal.Root open={showCardModal} onOpenChange={setShowCardModal}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Revive Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="t-flex t-flex-col t-gap-4">
                <Combobox
                  label="Billing entity"
                  menuPortalTarget={document.body}
                  options={entityOptions}
                  // @ts-ignore
                  onChange={(value) => {
                    if (value) {
                      if (value instanceof Array) {
                        return null;
                      }

                      setEntityId(value.value);
                    }
                  }}
                  value={currentEntity || null}
                />
                <div className="t-flex t-gap-3 t-flex-col">
                  <div className="t-flex t-w-full t-justify-between t-items-center">
                    <div className="t-text-subtitle t-text-text-100">
                      Payment Methods
                    </div>
                    <Button
                      size="small"
                      onClick={() => setShowAddCard(true)}
                      disabled={!entityId}
                    >
                      <div className="t-text-neutral t-mr-1">
                        <PlusIcon color="currentColor" />
                      </div>
                      Add card
                    </Button>
                  </div>
                  <div
                    className={classNames(
                      "t-border t-p-3 t-border-solid t-rounded t-border-neutral-10"
                    )}
                  >
                    <Accordion.Root
                      type="single"
                      collapsible
                      defaultValue={CARD_ACCORDION}
                    >
                      <Accordion.Item value={CARD_ACCORDION}>
                        <Accordion.Trigger
                          className={classNames(
                            "all:unset t-group t-w-full t-flex t-items-center t-justify-between"
                          )}
                        >
                          <div className={classNames("t-text-subtext")}>
                            Saved cards
                          </div>
                          <div
                            className={classNames(
                              "group-data-state-open:-t-rotate-90 group-data-state-closed:t-rotate-90"
                            )}
                          >
                            <CaretRight />
                          </div>
                        </Accordion.Trigger>
                        <Accordion.Content>
                          {savedCards?.length > 0 && (
                            <SavedCards
                              savedCards={savedCards}
                              selectedCard={paymentMethodId}
                              setSelectedCard={setPaymentMethodId}
                            />
                          )}
                        </Accordion.Content>
                      </Accordion.Item>
                    </Accordion.Root>
                  </div>
                </div>

                <Button
                  customType="primary"
                  disabled={!paymentMethodId || !entityId || reviving}
                  isLoading={reviving}
                  onClick={reviveSubscriptionPayment}
                >
                  Proceed
                </Button>
              </div>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}

      <ReviveSubscriptionPaymentFailed
        show={showPaymentFail}
        setShow={setShowPaymentFail}
        openPaymentMethodModal={openPaymentMethodModal}
      />
    </div>
  );
};

export default ReviveSubscription;
