import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

type InviteUserManagePlanProps = {
  show: boolean;
  closeModal: () => void;
  openManageSeatModal: () => void;
  activeGroupUsersCount: number;
  availableSeats: number;
};

export const InviteUserManagePlan = ({
  show,
  closeModal,
  openManageSeatModal,
  activeGroupUsersCount,
  availableSeats,
}: InviteUserManagePlanProps) => {
  const totalSeats = availableSeats + activeGroupUsersCount;
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Invite User</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body t-text-text-100">
              You have exhausted all your paid seats ({activeGroupUsersCount}/
              {totalSeats}). To acquire additional invites, please buy more
              seats.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end">
              <Button customType="primary" onClick={openManageSeatModal}>
                Add more seats
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
