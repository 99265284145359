import React from "react";

//components
import { Button } from "components/DesignSystem/Button/Button";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { BillingInvoice } from "types/Models/billing";
import Modal from "components/DesignSystem/Modal/Modal";

export const InvoiceTopayModal = ({
  show,
  closeModal,
  unPaidInvoice,
  PayUnpaid,
  totalAmount,
}: {
  show: boolean;
  closeModal: () => void;
  unPaidInvoice: BillingInvoice[];
  PayUnpaid: () => void;
  totalAmount: number;
}) => {
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Pay For All Dues</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="t-flex t-flex-col t-gap-3  t-h-[330px] t-overflow-auto">
            {unPaidInvoice.map(({ name, discount, amount, uuid }) => (
              <div
                className="t-flex t-justify-between t-text-text-60"
                key={uuid}
              >
                <p className="t-m-0">{name}</p>
                <p className="t-m-0">
                  <AmountSuperScript
                    amount={
                      discount
                        ? Number(amount) - Number(discount)
                        : Number(amount)
                    }
                  />
                </p>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button block customType="primary" onClick={PayUnpaid}>
            <div className="t-flex t-gap-1">
              <span>Pay</span> <AmountSuperScript amount={totalAmount} />
            </div>
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
