import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDeleteInteractiveReportMutation } from "store/apis/reports";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const DeleteReport = ({
  close,
  isOpen,
  reportId,
}: ModalProps & { reportId: string }) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [deleteReport, { isLoading }] = useDeleteInteractiveReportMutation();

  const onDelete = async () => {
    try {
      await deleteReport({
        groupId,
        entityId,
        reportId,
      }).unwrap();
      close();
      successToast({ message: `Report deleted` });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Delete Report?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-text-body t-text-text-60">
          Are you sure you want to delete this report?
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="danger"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
