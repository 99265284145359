import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ConnectionsInitialState = {
  isAddBankModalOpen: boolean;
  isEditBankModalOpen: boolean;
  editBankId: string;
};

const initialState: ConnectionsInitialState = {
  isAddBankModalOpen: false,
  isEditBankModalOpen: false,
  editBankId: "",
};

export const connections = createSlice({
  name: "connections",
  initialState,
  reducers: {
    openAddMaualBankModal: (state) => {
      state.isAddBankModalOpen = true;
    },

    closeAddMaualBankModal: (state) => {
      state.isAddBankModalOpen = false;
    },

    openEditBankModal: (
      state,
      action: PayloadAction<{
        editBankId: string;
      }>
    ) => {
      state.isEditBankModalOpen = true;
      state.editBankId = action.payload.editBankId;
    },

    closeEditBankModal: (state) => {
      state.isEditBankModalOpen = false;
    },

    resetEditBankId: (state) => {
      state.editBankId = "";
    },
  },
});

export const {
  openAddMaualBankModal,
  closeAddMaualBankModal,
  openEditBankModal,
  closeEditBankModal,
  resetEditBankId,
} = connections.actions;

export default connections.reducer;
