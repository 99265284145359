import axios from "axios";

export const useFilePreviewUrl = () => {
  const preview = async ({ url }: { url: string }) => {
    if (!Boolean(url)) {
      return;
    }

    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      });

      if (response.status !== 200) {
        throw new Error("Network response was not ok.");
      }

      const blob = response.data;
      const fileURL = URL.createObjectURL(blob);

      return fileURL;
    } catch (error) {
      throw error;
    }
  };

  return { preview };
};
