import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/V2/Table";
import { InfoSolid } from "components/InfoSolid";
import { DD_MMM_YYYY } from "constants/date";
import * as RECONCILLIATION_STATUS from "constants/reconcilliationStatus";
import dayjs from "dayjs";
import { CONNECTION_TYPE } from "dictionaries";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useDispatch } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import Bank from "static/images/Bank.svg";
import NoTransaction from "static/images/NoTransaction.svg";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { setReconcileBankAccountId } from "store/slices/reconciliation";
import { Banks } from "types/Models/banks";
import { Reconcile } from "./Reconcile";
import {
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConditionalLink } from "components/conditionalLink";

export const Reconciliation = () => {
  usePageTitle("Reconciliation");
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const {
    data: ledger,
    isLoading,
    isSuccess,
  } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const history = useHistory();
  const { search } = useLocation();
  const { url, path } = useRouteMatch();

  const { accounts = [] } = ledger || {};
  const columnHelper = createColumnHelper<Banks>();

  const {
    isOpen,
    open: openReconcilieModal,
    close: closeReconcilieModal,
  } = useModal();

  const onReconcile = (accountId: string) => {
    dispatch(setReconcileBankAccountId(accountId));
    openReconcilieModal();
  };

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "Account",
      cell: (info) => {
        const {
          account: { nickname, mask, connection_type },
          bank_brand: { logo, name },
        } = info.getValue();

        return (
          <div className="t-flex t-items-center t-gap-2">
            <img
              src={logo || Bank}
              alt={name}
              className="t-w-8 t-rounded-full"
            />
            <div className="t-text-text-100">
              <div className="t-w-[11.93rem] t-truncate t-text-subtext">
                {nickname}
              </div>
              {mask && (
                <div className="t-text-body-sm">
                  {`\u2022\u2022 ${mask}`}
                  <span>{CONNECTION_TYPE[connection_type]}</span>
                </div>
              )}
            </div>
          </div>
        );
      },
      header: () => "Account",
    }),
    columnHelper.accessor((row) => row, {
      id: "Last reconciled",
      cell: (info) => {
        const {
          account: { last_reconciled_date },
        } = info.getValue();
        return (
          <div className="t-text-text-60 t-text-subtext">
            {last_reconciled_date
              ? dayjs(last_reconciled_date).format(DD_MMM_YYYY)
              : "-"}
          </div>
        );
      },
      header: () => "Last Reconciled till",
    }),
    columnHelper.accessor((row) => row, {
      id: "Status",
      cell: (info) => {
        const {
          last_successful_transaction_update,
          account: {
            current_balance,
            running_balance,
            available_balance,
            last_reconcilation_status,
            balance_matches,
            connection_type,
          },
        } = info.getValue();

        const plaidAmountDifference = running_balance
          ? current_balance - running_balance
          : current_balance;

        const directConnectionAmountDifference = running_balance
          ? running_balance - (available_balance || 0)
          : current_balance - (available_balance || 0);

        const diff =
          connection_type === "DIRECT"
            ? directConnectionAmountDifference
            : plaidAmountDifference;

        return (
          <div className="t-flex t-flex-col t-gap-0.5">
            {last_reconcilation_status === RECONCILLIATION_STATUS.COMPLETED ||
            balance_matches ? (
              <span className="t-text-dark_green t-text-subtext">
                No difference
              </span>
            ) : (
              <div className="t-flex t-items-center t-gap-1">
                <span className="t-text-subtext t-text-red">
                  Difference <AmountSuperScript amount={diff} />
                </span>
              </div>
            )}
            <div>
              {last_successful_transaction_update
                ? dayjs(last_successful_transaction_update).format(DD_MMM_YYYY)
                : "-"}
            </div>
          </div>
        );
      },
      header: () => (
        <p className="t-flex t-gap-1 t-m-0 t-items-center">
          <span>Status</span>
          <ConditionalToolTip condition="This is the difference between statement ending balance and Inkle balance as of given date.">
            <span className="t-text-text-30 t-flex">
              <InfoSolid size="14" />
            </span>
          </ConditionalToolTip>
        </p>
      ),
    }),
    columnHelper.accessor("account.last_reconcilation_status", {
      id: "Action",
      cell: (info) => {
        const {
          row: {
            original: {
              account: { uuid, balance_matches },
            },
          },
        } = info;
        const disabled =
          info.getValue() === RECONCILLIATION_STATUS.COMPLETED ||
          balance_matches;

        return (
          <ConditionalLink to={disabled ? "" : `${url}/start/${uuid}${search}`}>
            <Button size="small" disabled={disabled}>
              Reconcile
            </Button>
          </ConditionalLink>
        );
      },
      header: () => "Action",
    }),
  ];

  const table = useReactTable({
    data: accounts,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const isEmpty = accounts.length === 0;

  return (
    <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
      <Async.Empty>
        <EmptyScreen
          text="Please connect your US bank account, so that we can sync transcations"
          className="!t-h-[75dvh]"
        >
          <img src={NoTransaction} alt="No Transaction" />
        </EmptyScreen>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-flex-col t-gap-6">
          <div className="t-text-body t-text-text-100">
            Match transactions in Inkle Books with bank statement, fix missing
            or duplicate transactions in Inkle Books.
          </div>
          <Table.Container>
            <Table.Content>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{ width: cell.column.getSize() }}
                        className="t-p-2"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </div>
        <Switch>
          <Route exact path={`${path}/start/:bankAccountId`}>
            {({ match }) => {
              if (!match) {
                return null;
              }

              return (
                <Reconcile
                  isOpen
                  onClose={() => history.push(`${url}${search}`)}
                  bankAccountId={match.params.bankAccountId}
                />
              );
            }}
          </Route>
        </Switch>
      </Async.Success>
    </Async.Root>
  );
};
