export const DividerLine = () => (
  <svg
    width="2"
    height="11"
    viewBox="0 0 2 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.30176 0.5L1.30176 10.5"
      stroke="currentColor"
      stroke-linecap="round"
    />
  </svg>
);
