import React from "react";
import { Card } from "react-bootstrap";
import classNames from "classnames";

//css
import "../../static/styles/components/commonComponent.css";

const CommonCard = ({ children, hover = true, newClassName = "", ...rest }) => {
  return (
    <Card
      className={classNames("commonCard", {
        hoverCard: hover,
        [newClassName]: true,
      })}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default CommonCard;
