import { BIG } from "constants/chatBubbleWidths";
import { ComponentProps } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LandscapeImagePlaceholder from "static/images/LandscapeImagePlaceholder.png";
import PortraitImagePlaceholder from "static/images/PortraitImagePlaceholder.png";
import SquareImagePlaceholder from "static/images/SquareImagePlaceholder.png";
import "react-lazy-load-image-component/src/effects/blur.css";

export const Image = (
  props: ComponentProps<typeof LazyLoadImage> & {
    width?: number;
    height?: number;
  }
) => {
  const height = Math.min(props.height!, 266);
  const width = Math.min(props.width!, 230);

  let placeholderSrc = SquareImagePlaceholder;
  if (props.width && props.height) {
    if (height / width > 1) {
      placeholderSrc = PortraitImagePlaceholder;
    }

    if (height / width < 1) {
      placeholderSrc = LandscapeImagePlaceholder;
    }
  }

  return (
    <LazyLoadImage
      wrapperClassName="t-w-full t-block"
      placeholderSrc={placeholderSrc}
      {...props}
      height={isNaN(height) ? undefined : height}
      width={"100%"}
      effect="blur"
    />
  );
};
