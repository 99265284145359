import { object, string } from "yup";

export const officerSchema = () =>
  object({
    ap_status: string().required("Status is required"),
    persona_autofill_id: string().required("Please select person"),
    role_id: string().required("Please Select role"),
  });

export const directorSchema = () =>
  object({
    ap_status: string().required("Status is required"),
    persona_autofill_id: string().required("Please select person"),
  });
