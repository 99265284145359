import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { InfoSolid } from "components/InfoSolid";
import { ConditionalLink } from "components/conditionalLink";
import { INKLE_PRICING_URL } from "constants/inklePricing";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useQuery } from "hooks/useQuery";
import { useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import CalendarBlank from "static/images/CalendarBlank.svg";
import CoinVertical from "static/images/CoinVertical.svg";
import InfoGray from "static/images/InfoGray.svg";
import {
  PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
  PRO_MONTHLY_BOOKKEEPING,
} from "types/Models/services";
import { BaseTaskTemplate } from "types/Models/taskTemplate";
import Redirect from "static/images/Redirect.svg";
import classnames from "classnames";
import { useAnalytics } from "hooks/useAnalytics";
import { CLICKED_ON_SERVICE_FROM_INTRAGROUP_SERVICES_LIST_MODAL } from "constants/analyticsEvents";

dayjs.extend(advancedFormat);

export const TaskDetails = ({
  data,
  currentCategory,
  handleServiceTaskTrigger,
  currentBookkeepingService,
}: {
  data: BaseTaskTemplate & { is_first: boolean };
  currentCategory: string;
  handleServiceTaskTrigger: (taskTemplateId: string) => void;
  currentBookkeepingService?: string;
}) => {
  const query = useQuery();
  const searchTerm = query.get("filing_search") || "";
  const history = useHistory();
  const { url } = useRouteMatch();
  const categoryRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useAnalytics();
  const isBooksTask =
    data.category === "Bookkeeping" ||
    data.category === "R&D" ||
    data.category === "Intragroup" ||
    data.category === "Sales Tax";

  useEffect(() => {
    if (currentCategory === data.category) {
      if (data.is_first) {
        categoryRef?.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [currentCategory]);

  const disableTask =
    (data.base_task_key == PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING &&
      currentBookkeepingService === PRO_MONTHLY_BOOKKEEPING) ||
    (data.base_task_key == PRO_MONTHLY_BOOKKEEPING &&
      currentBookkeepingService === PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING);

  const handleTaskCardClick = () => {
    trackEvent(CLICKED_ON_SERVICE_FROM_INTRAGROUP_SERVICES_LIST_MODAL, {
      service_category: data.category,
    });
    if (isBooksTask) {
      if (!disableTask) handleServiceTaskTrigger(data.uuid);
      return;
    }
    history.push(`${url}/${data.uuid}`);
  };
  return (
    <div
      aria-details={data.category}
      data-testid={data.base_task_key}
      ref={categoryRef}
      className={classnames(
        "t-mb-4 t-cursor-pointer t-rounded-md t-border t-border-solid t-border-neutral-10 t-p-4 hover:t-border-purple-30 hover:t-shadow-task-templates",
        {
          "t-pointer-events-none t-bg-surface-grey  t-text-neutral-30 t-border-neutral-0":
            disableTask,
        }
      )}
      onClick={handleTaskCardClick}
      aria-hidden={true}
    >
      <h6 className="t-font-bol1 t-mt-1">
        <HighlightSearchTerm text={data.title} searchTerm={searchTerm} />
      </h6>
      <h6
        className={classnames(
          "t-mt-6 t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral",
          {
            "t-text-neutral-40": disableTask,
          }
        )}
      >
        <img
          height="15"
          className={classnames("t-mr-2", {
            "t-text-neutral-40": disableTask,
          })}
          src={InfoGray}
          alt="coin"
        />
        ABOUT
      </h6>
      <span
        className="t-text-subtitle-sm t-font-thin"
        dangerouslySetInnerHTML={{
          __html:
            data.description?.length > 200
              ? data.description.substring(0, 160) + "....."
              : data.description,
        }}
      ></span>
      <div className="t-flex t-gap-4">
        {data.approx_deadline ? (
          <div className="t-flex-1">
            <h6 className="t-mt-2 t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
              <img
                height="15"
                className="t-mr-2"
                src={CalendarBlank}
                alt="coin"
              />
              TYPICAL DEADLINE
            </h6>
            <h6 className="t-font-bold">
              {dayjs(data.approx_deadline).format("Do MMMM")} each year
            </h6>
          </div>
        ) : (
          ""
        )}
        <div className="t-flex-1">
          <h6
            className={classnames(
              "t-mt-2 t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral",
              {
                "t-text-neutral-40": disableTask,
              }
            )}
          >
            <img height="15" className="t-mr-2" src={CoinVertical} alt="coin" />
            PRICE
          </h6>
          <h6 className="t-font-bold">
            {data.approx_price === "0" ? "Free" : "$" + data.approx_price}
          </h6>
        </div>
        {Boolean(data?.government_fee) && (
          <div className="t-mt-1 t-flex-1">
            <h6 className="t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
              <img
                height="15"
                className="t-mr-2"
                src={CoinVertical}
                alt="coin"
              />
              GOVERNMENT FEE
            </h6>
            <h6 className="t-mt-1 t-font-bold">
              {data.government_fee === "0" ? "Free" : "$" + data.government_fee}
            </h6>
          </div>
        )}
      </div>
      {data?.base_task_key === PRO_MONTHLY_BOOKKEEPING && (
        <div className="t-flex t-items-center t-gap-2">
          <div className=" t-text-red">
            <InfoSolid size="16" />
          </div>
          <span className="t-text-body-sm">May have extra charges.</span>
          <ConditionalLink to={INKLE_PRICING_URL} className="t-group">
            <div className="t-flex t-gap-1">
              <span
                className={classnames(
                  "t-text-purple t-text-subtext-sm hover:t-underline",
                  {
                    "t-text-purple-30": disableTask,
                  }
                )}
              >
                Check pricing
              </span>
              <img
                src={Redirect}
                alt="redirect"
                className="group-hover:!t-visible t-invisible t-h-4 t-w-4"
              />
            </div>
          </ConditionalLink>
        </div>
      )}
    </div>
  );
};
