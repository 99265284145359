import { axiosInstance } from "./apiInstance";

export const signupResendOtp = ({ values, isMailOtp }) => {
  let url = "api/inkle/users/resend-token-or-otp/?";
  if (isMailOtp) {
    url = url + `email=${values.email_id}`;
  } else {
    url =
      url +
      `mobile=${encodeURIComponent(values.phone_no)}&email=${values.email}`;
  }
  const { data } = axiosInstance.post(url);
  return data;
};
