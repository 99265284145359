import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import OtpBox from "components/design/otpBox";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { EMAIL_UPDATE } from "constants/profileUpdate";
import { Form, Formik, FormikValues, useFormikContext } from "formik";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import React, { useContext, useState } from "react";
import {
  useSendOtpForEmailMutation,
  useUpdateProfileEmailAndMobileMutation,
} from "store/apis/settings";
import { checkValidEmail } from "utils/checkValidEmail";

const SendOTPComponent = ({
  setShowEmailModal,
  setStep,
}: {
  setShowEmailModal: (newState: React.SetStateAction<boolean>) => void;
  setStep: (newState: React.SetStateAction<number>) => void;
}) => {
  const [sendOtpForEmail, { isLoading }] = useSendOtpForEmailMutation();
  const { alertToast, successToast } = useToast();

  const { values } = useFormikContext<FormikValues>();

  const sendOtp = async () => {
    const { email } = values;
    if (checkValidEmail(email)) {
      try {
        await sendOtpForEmail({ email: email }).unwrap();
        successToast({ message: "OTP sent" });
        setStep(2);
      } catch (error: any) {
        alertToast({
          message: error?.data?.error?.message,
        });
      }
    }
  };

  return (
    <Form
      className="all:unset"
      onSubmit={(e) => {
        e.preventDefault();
        sendOtp();
      }}
    >
      <Modal.Body>
        <TextInput label="Email" name="email" />
      </Modal.Body>
      <Modal.Footer className="t-flex t-justify-end t-gap-3">
        <Button onClick={() => setShowEmailModal(false)}>Cancel</Button>
        <Button customType="primary" isLoading={isLoading} disabled={isLoading}>
          Send OTP
        </Button>
      </Modal.Footer>
    </Form>
  );
};

const VerifyOTPComponent = ({
  setShowEmailModal,
  setStep,
}: {
  setShowEmailModal: (newState: React.SetStateAction<boolean>) => void;
  setStep: (newState: React.SetStateAction<number>) => void;
}) => {
  const [otp, setOtp] = useState("");
  const { successToast, alertToast } = useToast();
  const [updateEmail, { isLoading }] = useUpdateProfileEmailAndMobileMutation();
  const { values, isSubmitting } = useFormikContext<FormikValues>();
  const { updateUser } = useContext(authContext);

  const verifyOtp = async () => {
    try {
      const updatedProfile = await updateEmail({
        operation: EMAIL_UPDATE,
        token: otp,
        email: values?.email,
      }).unwrap();
      updateUser({ data: { data: updatedProfile } });
      successToast({ message: "Email Updated" });
      setShowEmailModal(false);
    } catch (error: any) {
      alertToast({
        message: error?.data?.error?.message || SOMETHING_WENT_WRONG,
      });
    }
  };

  const onCancel = () => {
    setStep(1);
    setShowEmailModal(false);
  };

  return (
    <>
      <Modal.Body>
        <div className="t-text-body t-text-text-60 t-mb-5">
          Please enter the 6-character code sent to {values?.email}
        </div>
        {/* @ts-ignore */}
        <OtpBox
          className="all:unset"
          otp={otp}
          setOtp={setOtp}
          getOtp={() => {}}
        />
      </Modal.Body>
      <Modal.Footer className="t-flex t-justify-end t-gap-3">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          customType="primary"
          onClick={verifyOtp}
          disabled={otp?.length !== 6 || isSubmitting || isLoading}
          isLoading={isLoading}
        >
          Update
        </Button>
      </Modal.Footer>
    </>
  );
};

export const EmailUpdate = ({
  email,
  showEmailModal,
  setShowEmailModal,
}: {
  email: string;
  showEmailModal: boolean;
  setShowEmailModal: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const [step, setStep] = useState<number>(1);
  return (
    <Modal.Root open={showEmailModal} onOpenChange={setShowEmailModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Enter new email</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik initialValues={{ email: email }} onSubmit={() => {}}>
          {({ values }) => {
            return step === 1 ? (
              <SendOTPComponent
                setShowEmailModal={setShowEmailModal}
                setStep={setStep}
              />
            ) : (
              <VerifyOTPComponent
                setShowEmailModal={setShowEmailModal}
                setStep={setStep}
              />
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
