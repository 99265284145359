export const EmptyMediaSlider = () => (
  <svg
    width="118"
    height="78"
    viewBox="0 0 118 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2673_25082)">
      <rect
        x="59.6396"
        y="1.43074"
        width="59.1723"
        height="65.401"
        rx="3.11433"
        transform="rotate(10 59.6396 1.43074)"
        fill="#E7E6EB"
      />
      <rect
        x="64.692"
        y="8.64638"
        width="46.715"
        height="46.715"
        rx="1.55717"
        transform="rotate(10 64.692 8.64638)"
        fill="white"
      />
      <path
        d="M95.6578 46.7438C95.7712 47.002 95.8124 47.2861 95.7769 47.5659C95.7414 47.8456 95.6307 48.1105 95.4564 48.3322C95.2822 48.5539 95.051 48.7241 94.7876 48.8247C94.5242 48.9253 94.2384 48.9525 93.9607 48.9033L81.6926 46.7401L69.4246 44.577C69.149 44.5285 68.8916 44.4067 68.6794 44.2243C68.4673 44.042 68.3081 43.8058 68.2188 43.5407C68.1294 43.2756 68.1131 42.9913 68.1716 42.7177C68.2301 42.4441 68.3613 42.1913 68.5513 41.986L77.3448 32.4682C77.5218 32.2766 77.744 32.1326 77.9911 32.0492C78.2382 31.9659 78.5023 31.946 78.7591 31.9912C79.0159 32.0365 79.2573 32.1456 79.461 32.3084C79.6647 32.4712 79.8242 32.6826 79.925 32.9231L81.2828 36.1629L86.3371 30.8697C86.5141 30.684 86.7344 30.545 86.9781 30.4649C87.2219 30.3849 87.4816 30.3663 87.7343 30.4109C87.987 30.4554 88.2247 30.5617 88.4264 30.7203C88.6281 30.8789 88.7875 31.0848 88.8904 31.3199L95.6578 46.7438Z"
        fill="#E7E6EB"
      />
      <circle
        cx="79.7782"
        cy="28.5787"
        r="1.13445"
        transform="rotate(10 79.7782 28.5787)"
        fill="#E7E6EB"
      />
    </g>
    <g clip-path="url(#clip1_2673_25082)">
      <rect
        x="0.0870972"
        y="13.1654"
        width="59.1723"
        height="65.401"
        rx="3.11433"
        transform="rotate(-12 0.0870972 13.1654)"
        fill="#F3F3F5"
      />
      <rect
        x="7.47467"
        y="17.9629"
        width="46.715"
        height="46.715"
        rx="1.55717"
        transform="rotate(-12 7.47467 17.9629)"
        fill="white"
      />
      <path
        d="M50.4573 41.6863C50.6591 41.8832 50.8037 42.1312 50.8756 42.4039C50.9475 42.6765 50.944 42.9636 50.8655 43.2344C50.787 43.5053 50.6365 43.7497 50.4299 43.9416C50.2234 44.1336 49.9685 44.2658 49.6927 44.3243L37.5076 46.9143L25.3225 49.5043C25.0488 49.5626 24.7646 49.5461 24.4995 49.4565C24.2345 49.3669 23.9985 49.2076 23.8163 48.9952C23.6341 48.7829 23.5125 48.5254 23.4643 48.2498C23.4161 47.9742 23.4429 47.6908 23.5422 47.4292L28.13 35.3103C28.2223 35.0664 28.3744 34.8496 28.5723 34.6798C28.7702 34.5099 29.0075 34.3925 29.2626 34.3383C29.5177 34.2841 29.7823 34.2948 30.0322 34.3694C30.2821 34.4441 30.5092 34.5803 30.6927 34.7656L33.1652 37.2608L35.8686 30.4596C35.9633 30.2211 36.1154 30.0097 36.3114 29.8442C36.5074 29.6787 36.7413 29.5642 36.9923 29.5108C37.2432 29.4575 37.5035 29.467 37.7499 29.5384C37.9963 29.6099 38.2212 29.7412 38.4047 29.9206L50.4573 41.6863Z"
        fill="#E7E6EB"
      />
      <circle
        cx="28.9292"
        cy="30.7925"
        r="1.13445"
        transform="rotate(-12 28.9292 30.7925)"
        fill="#E7E6EB"
      />
    </g>
    <defs>
      <clipPath id="clip0_2673_25082">
        <rect
          x="59.6396"
          y="1.43074"
          width="59.1723"
          height="65.401"
          rx="3.11433"
          transform="rotate(10 59.6396 1.43074)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_2673_25082">
        <rect
          x="0.0870972"
          y="13.1654"
          width="59.1723"
          height="65.401"
          rx="3.11433"
          transform="rotate(-12 0.0870972 13.1654)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
