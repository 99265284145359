export const LockSecure = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#F3F3F5" />
    <g clipPath="url(#clip0_1388_22139)">
      <path
        d="M12 13.875C12.6472 13.875 13.1719 13.3503 13.1719 12.7031C13.1719 12.0559 12.6472 11.5312 12 11.5312C11.3528 11.5312 10.8281 12.0559 10.8281 12.7031C10.8281 13.3503 11.3528 13.875 12 13.875Z"
        stroke="#706A85"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.875V15.2812"
        stroke="#706A85"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6875 9.65625H7.3125C7.05362 9.65625 6.84375 9.86612 6.84375 10.125V16.6875C6.84375 16.9464 7.05362 17.1562 7.3125 17.1562H16.6875C16.9464 17.1562 17.1562 16.9464 17.1562 16.6875V10.125C17.1562 9.86612 16.9464 9.65625 16.6875 9.65625Z"
        stroke="#706A85"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.65625 9.65625V7.78125C9.65625 7.15965 9.90318 6.56351 10.3427 6.12397C10.7823 5.68443 11.3784 5.4375 12 5.4375C12.6216 5.4375 13.2177 5.68443 13.6573 6.12397C14.0968 6.56351 14.3438 7.15965 14.3438 7.78125V9.65625"
        stroke="#706A85"
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1388_22139">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(4.5 4.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
