import React, { forwardRef, useState, useMemo, useEffect, useRef } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import classNames from "classnames";

// css
import "../../static/styles/components/design/commonTable.css";

//components
import PaginatedItems from "../pagination/paginate";

//constant
import { NEXT_CONTACT_DATE } from "../../constants/taskStatus";

function CommonTable({
  tableData,
  tableDataDependencies = [],
  tableColumns,
  tableColumnsDependencies = [],
  sortFeature = true,
  headerBg = "#f2f5f9",
  serviceTeam = [],
  //pagination props
  pagination = false,
  path = "",
  setPageNumber,
  pageCount,
  initialPage,
  checkBoxNeed = false,
  setSelectedRow = () => {},
  needRowData = false,
  selectedRowAction = (row) => {},
  isRowActionNeeded = false,
  ExtraComponent = <></>,
  singleSort = false,
  updateSort = () => {},
  singleClickedId = false,
  clickIdKey = "uuid",
  checkBoxClick = () => {},
  checkboxDisableType = "uuid",
  checkboxDisableNeeded = false,
}) {
  const [selectedRowData, setSelectedRowData] = useState({});
  const [multipleSelectedRowData, setMultipleSelectedRowData] = useState([]);
  const data = useMemo(() => tableData, [...tableDataDependencies, tableData]);

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, rowData, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            onClick={checkBoxClick}
            className={classNames("table-checkbox", {
              "no-Checkbox-needed": checkboxDisableNeeded
                ? rowData?.[checkboxDisableType]
                : false,
            })}
          />
        </>
      );
    }
  );

  const columns = useMemo(
    () => tableColumns,
    [...tableColumnsDependencies, tableColumns]
  );
  const tableInstance = useTable(
    { columns, data },
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (checkBoxNeed) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div
                className={classNames("checkBoxHeader", {
                  "d-block": checkBoxNeed,
                  "d-none": !checkBoxNeed,
                })}
              >
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div
                className={classNames("checkBoxBody", {
                  "d-block": checkBoxNeed,
                  "d-none": !checkBoxNeed,
                })}
              >
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  rowData={row.original}
                />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setPageSize,
    state,
    state: { selectedRowIds },
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const multipleSelectedHandler = () => {
    const data = selectedFlatRows?.map((row) => row);
    setMultipleSelectedRowData(data);
  };

  useEffect(() => {
    isRowActionNeeded && selectedRowAction(multipleSelectedRowData);
  }, [multipleSelectedRowData]);

  useEffect(() => {
    setSelectedRowData({ id: selectedRowIds, data: selectedFlatRows });
    multipleSelectedHandler();
  }, [selectedFlatRows, selectedRowIds]);

  return (
    <div className="w-100 commonTable">
      <table {...getTableProps()} className="w-100 px-2">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="commonTableHeading"
              style={{
                backgroundColor: headerBg,
              }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {singleSort || sortFeature
                ? headerGroup.headers.map((column) => (
                    <th
                      className="commonTableHead"
                      key={column.id}
                      {...column.getHeaderProps(column.getSortByToggleProps(), {
                        style: {
                          width: column.width,
                          textAlign: column.textAlign,
                        },
                      })}
                      onClick={column.sortNeed && updateSort}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSort &&
                          (column.isDesc === NEXT_CONTACT_DATE ? (
                            <i className="fas fa-angle-down sortIcon"></i>
                          ) : (
                            <i className="fas fa-angle-up sortIcon"></i>
                          ))}
                      </span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-angle-down sortIcon"></i>
                          ) : (
                            <i className="fas fa-angle-up sortIcon"></i>
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))
                : headerGroup.headers.map((column) => (
                    <th
                      className="columnTableHead !t-px-2.5 !t-pl-3"
                      key={column.id}
                      {...column.getHeaderProps({
                        style: {
                          width: column.width,
                          textAlign: column.textAlign,
                        },
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                onClick={(e) => needRowData && setSelectedRow(row.original, e)}
                className={classNames("tableRowData", {
                  hoverRow: needRowData,
                  selectedRowStyle: selectedRowIds[row.id] ? true : false,
                  unSelectedRowStyle: !selectedRowIds[row.id] ? true : false,
                  rowClicked:
                    singleClickedId &&
                    singleClickedId === row.original[clickIdKey],
                })}
                key={row.id}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={`commonTableColumnData ${cell.column.className}`}
                      key={cell.value}
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width,
                          textAlign: cell.column.textAlign,
                        },
                      })}
                    >
                      {" "}
                      {cell.column.Header === "#" && cell.render(index + 1)}
                      {serviceTeam.length !== 0
                        ? cell.render("Cell", { serviceTeam })
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {ExtraComponent}
      {pagination && pageCount > 1 && (
        <PaginatedItems
          setPageNumber={setPageNumber}
          pageCount={pageCount}
          initialPage={initialPage}
        />
      )}
    </div>
  );
}

export default CommonTable;
