import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { MONTHLY, YEARLY } from "constants/billingCycles";
import { SubscriptionsContext } from "contexts/SubscriptionsContext";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "static/styles/components/subscriptionModal.css";
import { useAddProductToCartMutation } from "store/apis/products";
import {
  setPaymentTitle,
  setScheduledPayment,
  setUsableCredit,
} from "store/slices/credit";
import { Subscription } from "types/Models/subscription";
import { ConditionalLink } from "../conditionalLink";
import { Discount } from "../Discount";
import { Price } from "../Price";
import { CheckoutModal } from "../CheckoutModal/CheckoutModal";
import { subscriptionApis } from "store/apis/subscriptions";
import GrayFilledInfo from "static/images/GrayFilledInfo.svg";
import ToolTip from "../design/toolTip";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import Radio from "../DesignSystem/RadioGroup/RadioGroup";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { RootState } from "store/store";
import classNames from "classnames";

type SubscriptionModalProps = {
  show: boolean;
  onClose: () => void;
  onPaymentDone?: () => void;
};

const PLAN_SELECTION = "PLAN_SECLECTION";
const PAYMENT = "PAYMENT";

export const SubscriptionModal = ({
  show = true,
  onClose,
  onPaymentDone,
}: SubscriptionModalProps) => {
  const { authtoken } = useContext(authContext);
  let { subscriptions } = useContext(SubscriptionsContext);
  const { groupId: groupIdInParams } = useParams<{ groupId?: string }>();
  const entityId = useCurrentEntityId();
  const currentGroup = useCurrentGroupContext();
  const [step, setStep] = useState(PLAN_SELECTION);
  const [selectedSubscription, setSelectedSubscription] =
    useState<null | Subscription>(null);
  const dispatch = useDispatch();
  const [selectedBillingCycle, setSelectedBillingCycle] = useState(YEARLY);
  const { isScheduledPayment } = useSelector(
    (state: RootState) => state.credits
  );

  const [addToCart] = useAddProductToCartMutation();

  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const { platform_subscription: groupPlatformSubscription } = currentGroup;
  let { platform_subscription: currentSubscription } = authtoken || {};

  if (groupIdInParams) {
    currentSubscription = groupPlatformSubscription;
  }

  subscriptions = subscriptions?.filter(
    ({ subscription_type }: any) => subscription_type === "PLATFORM"
  );

  const onPlanSelect = async (uuid: string) => {
    const selected = subscriptions.find((s) => s.uuid === uuid) || null;
    const groupId = groupIdInParams || currentGroup.uuid;

    if (isCartSubscriptionActive && selected) {
      await addToCart({
        groupId: groupId,
        entityId,
        payload: {
          product_id: selected.uuid,
          product_content_type_id: selected.product_content_type_id,
        },
      });
      dispatch(toggleCartSubscription(false));
      onClose();
      return;
    }

    dispatch(setUsableCredit(false));
    dispatch(setPaymentTitle(selected?.subscription_name || ""));
    setSelectedSubscription(selected);
    setStep(PAYMENT);
  };

  const onIncorporatedBeforeChange = (value: string) => {
    dispatch(setScheduledPayment(value === "YES" ? false : true));
  };

  const onSubscribed = () => {
    setStep(PLAN_SELECTION);
    dispatch(subscriptionApis.util.invalidateTags([{ type: "Subscriptions" }]));
    onPaymentDone?.();
  };

  const standardSubscriptions = subscriptions.filter(
    (s) => s.upgrade_level === 1
  );

  const annualSubscription = standardSubscriptions.find(
    (s) => s.billing_cycle === YEARLY
  );

  const otherSubscriptions = subscriptions.filter((s) => s.upgrade_level !== 1);

  let subscriptionsToShow: Subscription[] | Subscription = [
    ...otherSubscriptions,
  ];

  if (annualSubscription) {
    subscriptionsToShow = [...subscriptionsToShow, annualSubscription];
  }

  subscriptionsToShow =
    subscriptionsToShow.find(
      ({ billing_cycle, amount }) =>
        billing_cycle === selectedBillingCycle && amount > 0
    ) || subscriptionsToShow[0];

  const {
    subscription_name,
    amount,
    description,
    uuid,
    upgrade_level,
    is_redeemable,
    billing_cycle,
  } = subscriptionsToShow || {};

  useEffect(() => {
    document.body.style.pointerEvents = "auto";
  }, [show]);

  const closeModal = () => {
    dispatch(setScheduledPayment(false));
    onClose();
    setStep(PLAN_SELECTION);
  };

  return (
    <>
      <Modal.Root
        open={show && step === PLAN_SELECTION}
        onOpenChange={closeModal}
      >
        <Modal.Content size="regular">
          {step === PLAN_SELECTION && (
            <Modal.Header>
              <div className="t-w-full">
                <Modal.Title titleIcon={<Modal.Close />}>
                  <div>
                    Upgrade Your Plan{" "}
                    <ConditionalLink
                      target="_blank"
                      to="https://www.inkle.io/pricing"
                    >
                      <ToolTip text="Inkle Pricing">
                        <img src={GrayFilledInfo} alt="GrayFilledInfo" />
                      </ToolTip>
                    </ConditionalLink>
                  </div>
                </Modal.Title>
              </div>
            </Modal.Header>
          )}

          <Modal.Body className="t-max-h-[90vh] t-overflow-auto">
            <div className="subscription-plan-modal">
              <div className="t-mb-6">
                <Radio.Root
                  onValueChange={onIncorporatedBeforeChange}
                  defaultValue={isScheduledPayment ? "NO" : "YES"}
                >
                  <Radio.Content>
                    <div className="t-text-subtext t-text-text-60">
                      Do you have one or more entity incorporated before{" "}
                      {dayjs().startOf("year").format(DD_MMM_YYYY)}?
                    </div>
                    <div className="t-flex t-w-[30%] t-items-center">
                      <Radio.Item value="YES">Yes</Radio.Item>
                      <Radio.Item value="NO">No</Radio.Item>
                    </div>
                  </Radio.Content>
                </Radio.Root>
              </div>

              {step === PLAN_SELECTION && (
                <div className="row gap-5">
                  <div className="t-flex t-flex-col t-gap-3 col">
                    <div className="t-px-5 t-py-4 t-border t-border-solid t-border-neutral-10 t-flex t-flex-col t-gap-2 t-shadow-light-30 t-rounded-lg t-mb-4">
                      <div>
                        <span className="t-text-subtext t-text-text-100 t-flex t-justify-between">
                          {subscription_name}
                          {isScheduledPayment && (
                            <div className="t-flex t-text-body-sm t-text-neutral-70 t-bg-neutral-0 t-rounded-3xl t-px-3 t-items-center">
                              Next payment:{" "}
                              {dayjs()
                                .add(1, "year")
                                .startOf("year")
                                .format(DD_MMM_YYYY)}
                            </div>
                          )}
                        </span>
                        <div className="t-mb-1 t-flex t-gap-2 t-items-center">
                          <Price
                            cycle={billing_cycle}
                            size="small"
                            amount={
                              subscriptionsToShow?.stripe_coupon
                                ?.discounted_amount || amount
                            }
                            type={
                              isScheduledPayment ? "STRIKE_THROUGH" : "NORMAL"
                            }
                          />
                          {isScheduledPayment && (
                            <div className="t-text-subtext">Free</div>
                          )}
                        </div>

                        <div className="t-text-body-sm t-text-text-30">
                          {isScheduledPayment
                            ? "Current year is free for entities incorporated this year."
                            : "Billed annually."}
                        </div>

                        {subscriptionsToShow?.stripe_coupon && (
                          <Discount
                            coupon={subscriptionsToShow.stripe_coupon}
                            amount={amount}
                          />
                        )}
                      </div>
                    </div>
                    <div className="t-text-subtext t-pl-0.5">
                      Unlock the Inkle platform:
                    </div>
                    <div
                      className="t-text-body-sm t-gap-3 t-text-text-60"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={
                currentSubscription?.upgrade_level >= upgrade_level ||
                !is_redeemable
              }
              size="regular"
              customType="primary"
              onClick={() => onPlanSelect(uuid)}
              block
            >
              {currentSubscription?.subscription_uuid === uuid
                ? "Current plan"
                : "Upgrade"}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      {step === PAYMENT && selectedSubscription && (
        <>
          <CheckoutModal
            entityId={entityId}
            type="subscription"
            title="Subscription"
            onClose={() => setStep("PLAN_SELECTION")}
            open={step === PAYMENT}
            subscription={selectedSubscription}
            onSubscribed={onSubscribed}
          />
        </>
      )}
    </>
  );
};
