import { Button } from "components/DesignSystem/Button/Button";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import {
  LANDED_PERSONA_SELECTION,
  OPEN_PERSONA_PARTNER,
  OPEN_PERSONA_USER,
} from "constants/analyticsEvents";
import { ACCOUNTANT, FOUNDER, PARTNER } from "constants/onBoarding";
import { PERK_PROVIDER_LINK } from "constants/perks";
import { FOREIGN_CA } from "constants/userTypes";
import { useAnalytics } from "hooks/useAnalytics";
import { useLocalStorage } from "hooks/useLocalStorage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountantIcon from "static/images/accountantIcon.svg";
import FounderPersonaIcon from "static/images/founderPersonaIcon.svg";
import PartnerIcon from "static/images/partnerIcon.svg";
import { openLink } from "utils/openLink";
import OnboardingOptionListCard from "./onboardingOptionListCard";
import { OnboardingRadioItem } from "./OnboardingRadioItem";

const OnboardingOptionLists = () => {
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const {
    localData: userType,
    setItem: setUserType,
    removeItem: removeUserType,
  } = useLocalStorage("user_type", null);
  const [selectedPersona, setSelectedPersona] = useState("");

  useEffect(() => {
    trackEvent(LANDED_PERSONA_SELECTION, {});
    removeUserType();
  }, []);

  const redirectToPartnerForm = () => {
    openLink(PERK_PROVIDER_LINK);
    trackEvent(OPEN_PERSONA_PARTNER, {});
  };

  const showSignUpForm = ({ isFSP = false }) => {
    if (isFSP) {
      localStorage.setItem("user_type", FOREIGN_CA);
    }
    history.push("/signup?signupform=true");
    trackEvent(OPEN_PERSONA_USER, {});
  };

  const handlePersonaSignup = () => {
    switch (selectedPersona) {
      case ACCOUNTANT: {
        showSignUpForm({ isFSP: true });
        return;
      }
      case FOUNDER: {
        showSignUpForm({ isFSP: false });
        return;
      }
      case PARTNER: {
        redirectToPartnerForm();
        return;
      }
      default:
        return;
    }
  };

  return (
    <div className="onboardingOptionLists !t-p-0 sm:!t-mt-16 sm:t-h-[calc(100vh-100px)]">
      <div className="middleContent !t-mt-0 !t-h-[calc(100vh-102px)] sm:!t-h-[calc(100vh-42px)]">
        <div className="t-mb-8 t-text-center t-text-h3 sm:t-text-h4 t-text-text-100">
          What best describes you?
        </div>
        <Radio.Root
          defaultValue={selectedPersona}
          onValueChange={(value) => setSelectedPersona(value)}
        >
          <Radio.Content>
            <div className="t-flex t-justify-center t-gap-6 t-flex-wrap sm:t-mb-12">
              <OnboardingRadioItem
                asChild
                value={ACCOUNTANT}
                className="!t-w-fit"
              >
                <OnboardingOptionListCard
                  title="Accountant"
                  details="Grow your practice & diversify offerings"
                  icon={AccountantIcon}
                  selected={selectedPersona === ACCOUNTANT}
                />
              </OnboardingRadioItem>
              <OnboardingRadioItem asChild value={FOUNDER} className="!t-w-fit">
                <OnboardingOptionListCard
                  title="Founder"
                  details="Automate tax & accounting for your Delaware C-Corp"
                  icon={FounderPersonaIcon}
                  selected={selectedPersona === FOUNDER}
                />
              </OnboardingRadioItem>
              <OnboardingRadioItem asChild value={PARTNER} className="!t-w-fit">
                <OnboardingOptionListCard
                  title="Partner"
                  details="Promote your product to Inkle customers"
                  icon={PartnerIcon}
                  selected={selectedPersona === PARTNER}
                />
              </OnboardingRadioItem>
            </div>
          </Radio.Content>
        </Radio.Root>
        <div className="t-w-[400px] sm:t-hidden t-visible t-mx-auto t-py-8">
          <Button
            customType="primary"
            block
            disabled={selectedPersona === ""}
            onClick={handlePersonaSignup}
          >
            Sign up
          </Button>
        </div>
        <div className="t-w-full sm:t-visible md:t-hidden t-fixed t-left-0 t-bg-surface t-bottom-0 t-py-4 t-border t-border-solid t-border-neutral-0 t-shadow-top-shadow t-px-3">
          <div className="t-w-[292px] t-mx-auto">
            <Button
              customType="primary"
              block
              disabled={selectedPersona === ""}
              onClick={handlePersonaSignup}
            >
              Sign up
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingOptionLists;
