import DashboardContainer from "components/dashboard/DashboardContainer";
import { Button } from "components/DesignSystem/Button/Button";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { Cross } from "components/icons/Cross";
import { useDispatch, useSelector } from "react-redux";
import { closeBillingInvoiceSlider } from "store/slices/billingInvoiceSlider";
import { RootState } from "store/store";
import { RefundDetails } from "./RefundDetails";
import { RefundForm } from "./RefundForm";

export const BillingInvoiceSlider = () => {
  const dispatch = useDispatch();
  const { showBillingSlider, invoice } = useSelector(
    (store: RootState) => store.billingInvoiceSlider
  );

  const closeSlider = () => {
    dispatch(closeBillingInvoiceSlider());
  };

  return (
    <Slider
      open={showBillingSlider}
      type="fixed"
      width={480}
      withOverlay
      onClose={closeSlider}
    >
      <DashboardContainer className="t-h-full">
        <DashboardContainer.Header>
          <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center t-border-b t-border-0 t-border-solid t-border-i-neutral-10">
            <span className="t-text-subtitle">Refund</span>
            <Button customType="ghost_icon" size="small" onClick={closeSlider}>
              <Cross />
            </Button>
          </div>
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          {invoice?.refund_data ? <RefundDetails /> : <RefundForm />}
        </DashboardContainer.Content>
      </DashboardContainer>
    </Slider>
  );
};
