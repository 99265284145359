import qs from "qs";
import { CapTable } from "types/Models/capTable";
import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";

export const capTable = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllCapTables: build.query<
      { cap_tables: CapTable[] } & Pagination,
      {
        pageNum: number;
        searchTerm?: string | null;
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId, pageNum, searchTerm }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/cap_table/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["CAPTBALE"] : []),
    }),

    createCapTable: build.mutation<
      CapTable,
      { groupId: string; entityId: string; payload: FormData }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/cap_table/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["CAPTBALE"] : []),
    }),

    updateCapTable: build.mutation<
      CapTable,
      {
        groupId: string;
        entityId: string;
        capTableId: string;
        payload: FormData;
      }
    >({
      query: ({ groupId, entityId, capTableId, payload }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/cap_table/${capTableId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? ["CAPTBALE"] : []),
    }),

    deleteCapTable: build.mutation<
      CapTable,
      {
        groupId: string;
        entityId: string;
        capTableId: string;
      }
    >({
      query: ({ groupId, entityId, capTableId }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/cap_table/${capTableId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["CAPTBALE"] : []),
    }),
  }),
});

export const {
  useGetAllCapTablesQuery,
  useCreateCapTableMutation,
  useUpdateCapTableMutation,
  useDeleteCapTableMutation,
} = capTable;
