import { Seperator } from "components/icons/Chat/Seperator";
import TPGlobe from "static/images/TPGlobe.svg";

export const TPHomeCard = () => {
  return (
    <div className="t-border t-border-solid t-rounded-lg t-p-5 t-overflow-hidden t-border-neutral-10 t-h-full t-w-full t-relative">
      <div className="t-flex t-gap-5">
        <div className="t-w-9/12">
          <p className="t-text-h5 t-m-0 t-mb-2.5">
            Transfer funds from US to India with ease
          </p>

          <p className="t-m-0 t-text-body-sm t-text-text-30 t-flex t-gap-2 t-items-center t-mb-3">
            <span>Compliant</span>
            <Seperator />
            <span>Transparent</span>
            <Seperator />
            <span>T+1 Settlement</span>
          </p>

          <p className="t-text-body t-m-0 t-font-semibold t-text-dark_green t-mb-5">
            0.25% Markup (mid-market rate)
          </p>
        </div>

        <img
          src={TPGlobe}
          alt="OnboardingIcon"
          className="t-absolute t-bottom-4 t-right-4"
        />
      </div>
    </div>
  );
};
