import {
  BareInput,
  ErrorMessage,
  TextInput,
} from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldAttributes, FieldProps, getIn } from "formik";
import { PatternFormat } from "react-number-format";
import { ComponentProps, MouseEvent, ReactNode } from "react";

export const PatternInput = ({
  fieldProps = {},
  patternProps = { format: "" },
  label,
}: {
  fieldProps?: FieldAttributes<any>;
  patternProps?: ComponentProps<typeof PatternFormat>;
  label?: ReactNode;
}) => (
  <Field {...fieldProps}>
    {({
      field: { value, onChange, ...field },
      form: { touched, errors, validateOnChange, setFieldValue },
    }: FieldProps) => {
      let hasError = getIn(errors, field.name) && getIn(touched, field.name);
      return (
        <div className="t-flex t-flex-col t-gap-1.5">
          <PatternFormat
            {...field}
            // HACK:
            name="RANDOM_NAME"
            label={label}
            value={value}
            {...patternProps}
            customInput={TextInput}
            onValueChange={(values, ...rest) => {
              patternProps.onValueChange?.(values, ...rest);
              return setFieldValue(field.name, values.formattedValue);
            }}
            onChange={() => {}}
          />
          {hasError && (
            <ErrorMessage>
              {/* @ts-ignore */}
              {getIn(errors, field.name)}
            </ErrorMessage>
          )}
        </div>
      );
    }}
  </Field>
);
