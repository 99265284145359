import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import ReviveSubscription from "components/reviveSubscription/ReviveSubscription";
import { useDispatch } from "react-redux";

import { useGetEntitiesQuery } from "store/apis/group";
import { setIsSnackbar } from "store/slices/dashboard";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useAppSelector } from "hooks/useAppSelector";
import { loadStripe } from "@stripe/stripe-js";
import { UnpaidDues } from "components/UnpaidDues/UnpaidDues";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

export const DashboardBanner = () => {
  const dispatch = useDispatch();

  const { subscriptionsInactive } = useAppSelector(
    (state) => state.subscriptionModal
  );

  const { isCustomer } = useRoleBasedView();

  const { data: { groups: [group] = [null] } = {} } = useGetEntitiesQuery();

  useEffect(() => {
    dispatch(
      setIsSnackbar(
        Boolean(group?.due_amount) || subscriptionsInactive.length > 0
      )
    );
  }, [group?.due_amount, subscriptionsInactive.length]);

  if (!isCustomer) {
    return null;
  }

  // if (isPreIncorporated) {
  //   return <PreIncorporatebanner />;
  // }

  // if (startOnboarding) {
  //   return <OnboardingBanner />;
  // }

  if (subscriptionsInactive.length > 0) {
    return (
      <div className="t-px-6 t-py-2">
        <Elements stripe={stripePromise}>
          <ReviveSubscription />
        </Elements>
      </div>
    );
  }

  if (group && group.due_amount > 0) {
    return (
      <div className="t-px-6 t-py-2">
        <UnpaidDues amount={group?.due_amount} />
      </div>
    );
  }
  return null;
};
