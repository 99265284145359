import {
  BaseFileInput,
  BaseFileInputProps,
} from "components/DesignSystem/BaseFileInput/BaseFileInput";
import { UploadFromSelectorModal } from "components/UploadFromSelectorModal/UploadFromSelectorModal";
import { UploadFromInkleDocsFileSelectorModalProps } from "components/UploadFromInkleDocsFileSelectorModal/UploadFromInkleDocsFileSelectorModal";
import { Field, FieldProps } from "formik";
import { useModal } from "hooks/useModal";
import { SelecteFile } from "types/Models/documents";

export type FileType = File | SelecteFile;

type Props = BaseFileInputProps & {
  onDrop?: (files: FileType[]) => void;
} & Pick<UploadFromInkleDocsFileSelectorModalProps, "fileTag" | "groupId">;

export type FileInputProps =
  | (Props & {
      withInkleDocs?: false;
    })
  | (Props & {
      withInkleDocs: true;
    });

/**
 * Component for handling file drag and drop with InkleDocs integration.
 *
 * @component
 * @param {boolean} withInkleDocs - Flag indicating whether to enable InkleDocs integration.
 *
 */

const BareFileInput = ({
  withInkleDocs = false,
  groupId,
  ...props
}: FileInputProps) => {
  const uploadFromModal = useModal();

  const onDropAreaClick = () => {
    uploadFromModal.open();
  };

  return (
    <>
      <BaseFileInput
        {...props}
        onDropAreaClick={
          withInkleDocs ? onDropAreaClick : props.onDropAreaClick
        }
      />

      {withInkleDocs && (
        <UploadFromSelectorModal
          onDrop={props.onDrop || function () {}}
          groupId={groupId}
          close={uploadFromModal.close}
          isOpen={uploadFromModal.isOpen}
          accept={props.accept}
          fileTag={props.fileTag}
        />
      )}
    </>
  );
};

const FileInputWithForm = ({
  onDrop: onDropFromProps,
  ...props
}: FileInputProps) => {
  // TODO: Handle for multiple files from inkle docs
  return (
    <Field {...props}>
      {({ form: { setFieldValue } }: FieldProps) => {
        const onDrop = (acceptedFiles: FileType[]) => {
          if (props.name) {
            if (props.multiple) {
              setFieldValue(props.name, [...props?.files!, ...acceptedFiles]);
            } else {
              setFieldValue(props.name, acceptedFiles[0]);
            }
          }
          onDropFromProps?.(acceptedFiles);
        };
        return (
          <>
            <BareFileInput {...props} onDrop={onDrop} />
          </>
        );
      }}
    </Field>
  );
};

export const FileInput = ({
  withInkleDocs = false,
  ...props
}: FileInputProps) => {
  if (props.withForm) {
    return <FileInputWithForm withInkleDocs={withInkleDocs} {...props} />;
  }

  return <BareFileInput withInkleDocs={withInkleDocs} {...props} />;
};
