export const User = ({ size = "25" }: { size?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3082_49258)">
      <path
        d="M12.0493 15.8481C15.363 15.8481 18.0493 13.1619 18.0493 9.84814C18.0493 6.53444 15.363 3.84814 12.0493 3.84814C8.73561 3.84814 6.04932 6.53444 6.04932 9.84814C6.04932 13.1619 8.73561 15.8481 12.0493 15.8481Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.04932 21.0981C4.86525 17.9603 8.16338 15.8481 12.0493 15.8481C15.9353 15.8481 19.2334 17.9603 21.0493 21.0981"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3082_49258">
        <rect
          width={size}
          height={size}
          fill="white"
          transform="translate(0.0493164 0.848145)"
        />
      </clipPath>
    </defs>
  </svg>
);
