import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/Modal/Modal";
import ReviveSubscriptionPaymentFailedImg from "static/images/ReviveSubscriptionPaymentFailed.svg";

const ReviveSubscriptionPaymentFailed = ({
  show,
  setShow,
  openPaymentMethodModal,
}: {
  show: boolean;
  setShow: (v: boolean) => void;
  openPaymentMethodModal: () => void;
}) => {
  return (
    <Modal.Root open={show} onOpenChange={() => setShow(false)}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-min-w-[360px] t-max-w-[360px] t-max-w-[420px] md:!t-min-w-[420px]">
          <Modal.Close />
          <div className="t-mb-4 t-mt-12  t-flex t-flex-col t-items-center">
            <img
              src={ReviveSubscriptionPaymentFailedImg}
              alt="ReviveSubscriptionPaymentFailedImg"
            />
            <div className="t-mt-4 t-text-h4 t-text-text-100">
              Payment failed
            </div>
            <div className="t-mb-6 t-mt-1 t-text-center t-text-body-lg t-text-neutral">
              Oops! Your payment was not successful. Please try again.
            </div>
            <Button onClick={openPaymentMethodModal}>
              Update card details
            </Button>
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};

export default ReviveSubscriptionPaymentFailed;
