import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TransactionState {
  isCartSubscriptionActive: boolean;
}

const initialState: TransactionState = {
  isCartSubscriptionActive: false,
};

export const cartSubscription = createSlice({
  name: "books",
  initialState,
  reducers: {
    toggleCartSubscription: (state, action: PayloadAction<boolean>) => {
      state.isCartSubscriptionActive = action.payload;
    },
  },
});

export const { toggleCartSubscription } = cartSubscription.actions;

export default cartSubscription.reducer;
