import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OnboardingStep {
  currentStep: number;
}

const initialState: OnboardingStep = {
  currentStep: 1,
};

const onboarding = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnboardingStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
  },
});

export const { setOnboardingStep } = onboarding.actions;

export default onboarding.reducer;
