import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Pencil from "components/icons/pencil";
import { InfoSolid } from "components/InfoSolid";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetReconciliationStatusQuery } from "store/apis/reconciliation";
import { RootState } from "store/store";
import { UpdateStatementBalance } from "./UpdateStatementBalance";

export const ReconciliationConsole = () => {
  const { reconcilationId } = useParams<{ reconcilationId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { includedCredits, includedDebits } = useSelector(
    (state: RootState) => state.reconciliation
  );

  const { data: csvTxn } = useGetReconciliationStatusQuery(
    {
      reconcilationId,
      entityId,
      groupId,
    },
    { skip: !groupId || !entityId }
  );
  const {
    statement_ending_balance = 0,
    inkle_total_balance = 0,
    reconcilation_end_date,
  } = csvTxn || {};

  const {
    open: openUpdateStatementBalanceModal,
    close: closeUpdateStatementBalanceModal,
    isOpen: isUpdateStatementBalanceModalOpen,
  } = useModal();

  const newBalance = parseFloat(
    (inkle_total_balance + includedCredits + includedDebits).toFixed(2)
  );
  const difference = parseFloat(
    (statement_ending_balance - newBalance).toFixed(2)
  );

  return (
    <div className="t-sticky t-z-header t-top-0 t-z-10 t-flex t-flex-col t-gap-5 t-bg-surface">
      {Boolean(reconcilation_end_date) && (
        <div className="t-text-body t-text-text-100">
          As of {dayjs(reconcilation_end_date).format(DD_MMM_YYYY)}, the
          difference between statement balance and Inkle balance should be 0 to
          complete reconciliation.
        </div>
      )}
      <div className="t-shadow-reconciliation-console t-px-5 t-justify-between t-flex">
        <div className="t-border-r t-border-0 t-border-solid t-border-neutral-0 t-pr-4 t-pt-3 t-pb-5 t-flex t-gap-6 t-flex-col t-rounded">
          <button
            className="all:unset t-text-subtext t-text-text-100 t-flex t-gap-1 t-items-center"
            onClick={openUpdateStatementBalanceModal}
          >
            Statement balance
            <Button customType="ghost_icon" size="small">
              <Pencil color="#706A85" />
            </Button>
          </button>
          <div className="t-text-subtitle t-text-purple-70">
            <AmountSuperScript amount={statement_ending_balance || 0} />
          </div>
        </div>

        <div className="t-pr-4 t-pt-3 t-pb-5 t-flex t-gap-2 t-pl-8 t-justify-between t-flex-auto">
          <div className="t-flex t-flex-col t-gap-2 t-justify-between">
            <div className="t-text-subtext t-text-text-100 t-flex t-gap-1 t-items-center">
              Inkle balance
            </div>
            <div className="t-flex t-gap-1 t-flex-col">
              <div className="t-text-subtext-sm t-text-text-30 t-flex t-gap-1 t-items-center">
                Beginning Balance
                <ConditionalToolTip condition="Ending Inkle balance before this reconciliation.">
                  <span className="t-text-text-30">
                    <InfoSolid size="14" />
                  </span>
                </ConditionalToolTip>
              </div>
              <div className="t-text-subtitle-sm t-text-text-60">
                <AmountSuperScript amount={inkle_total_balance || 0} />
              </div>
            </div>
          </div>
          <div className="t-flex t-gap-1 t-flex-col t-self-end">
            <div className="t-text-subtext-sm t-text-text-30 t-flex t-gap-1 t-items-center">
              Included Credits
            </div>
            <div className="t-text-subtitle-sm t-text-text-60">
              +<AmountSuperScript amount={includedCredits} />
            </div>
          </div>
          <div className="t-flex t-gap-1 t-flex-col t-self-end">
            <div className="t-text-subtext-sm t-text-text-30 t-flex t-gap-1 t-items-center">
              Included Debits
            </div>
            <div className="t-text-subtitle-sm t-text-text-60">
              <AmountSuperScript amount={includedDebits} />
            </div>
          </div>
          <div className="t-flex t-gap-1 t-flex-col t-self-end">
            <div className="t-text-subtext-sm t-text-text-30 t-flex t-gap-1 t-items-center">
              New Balance
              <ConditionalToolTip condition="New Inkle balance after this reconciliation">
                <span className="t-text-text-30">
                  <InfoSolid size="14" />
                </span>
              </ConditionalToolTip>
            </div>
            <div className="t-text-subtitle-sm t-text-text-60">
              <AmountSuperScript amount={newBalance} />
            </div>
          </div>
        </div>

        <div className="t-pl-8 t-pt-3 t-pb-5 t-flex t-gap-6 t-flex-col t-justify-between t-border-l t-border-0 t-border-solid t-border-neutral-0">
          <div className="t-text-subtext t-text-text-100 t-flex t-gap-1 t-items-center t-h-8">
            Difference
            <ConditionalToolTip condition="This is the difference between statement ending balance and Inkle balance after this reconciliation. This should be 0 to complete reconciliation.">
              <span className="t-text-text-30">
                <InfoSolid size="14" />
              </span>
            </ConditionalToolTip>
          </div>
          <div
            className={classNames("t-text-subtitle", {
              "t-text-dark_green": difference === 0,
              "t-text-red": difference !== 0,
            })}
          >
            <AmountSuperScript amount={difference || 0} />
          </div>
        </div>
        <UpdateStatementBalance
          isOpen={isUpdateStatementBalanceModalOpen}
          onClose={closeUpdateStatementBalanceModal}
        />
      </div>
    </div>
  );
};
