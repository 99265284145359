import { updatePublicUser } from "apis/profileData";
import classNames from "classnames";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import Modal from "components/DesignSystem/Modal/Modal";
import Loader from "components/design/loader";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { closeCardAddModal } from "store/slices/subscriptionModal";

const TRIAL_SUBSCRIPTION_UUID = "86bd78d4-35bf-4b0b-b696-ab667a3c7168";

interface CardAddProps {
  openAddCard: boolean;
  closeAddCard?: () => void;
  closeModal?: () => void;
  reviveSubscription?: boolean;
  startReviveSubscription?: (v: string) => void;
}

const CardAdd = ({
  openAddCard,
  closeAddCard = () => {},
  closeModal = () => {},
}: CardAddProps) => {
  const { alertToast } = useToast();
  const history = useHistory();
  const { authtoken } = useContext(authContext);
  const dispatch = useDispatch();

  const { data: subscriptions } = useGetSubscriptionsQuery({});

  const trailSubscription = subscriptions?.find(
    (s) => s.uuid === TRIAL_SUBSCRIPTION_UUID
  );

  const closeCardModal = () => {
    closeAddCard();
    closeModal();
  };

  const onSubscribed = async () => {
    try {
      const res = await updatePublicUser({
        isPublicUser: authtoken.is_public_user,
      });
      dispatch(closeCardAddModal());
      history.push(`/filings/${res.data.data.public_task_id}`);
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  if (!trailSubscription) {
    return <Loader />;
  }

  return (
    <CheckoutModal
      type="subscription"
      open={openAddCard}
      onClose={closeCardModal}
      onSubscribed={onSubscribed}
      subscription={trailSubscription}
    />
  );
};

export default CardAdd;
