export const tagColors = [
  {
    textColor: "#015364",
    bgColor: "#C8F9E8",
  },
  {
    textColor: "#475467",
    bgColor: "#E4E7EC",
  },
  {
    textColor: "#DC6803",
    bgColor: "#FFECDB",
  },
  {
    textColor: "#016D6E",
    bgColor: "#E3FFFF",
  },
  {
    textColor: "#141365",
    bgColor: "#E7E7FF",
  },
];

export const NO_TAG = "**No Tag**";
export const NO_TAG_KEY = "no_tag";
