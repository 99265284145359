import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import SmallOptionIcon from "components/icons/SmallOptionIcon";
import { CREDIT_CARD_OPTION_OPENED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import authContext from "jwt_context&axios/authContext";
import { useContext, useState } from "react";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import DefaultCardModal from "./DefaultCardModal";
import DeleteCardModal from "./DeleteCardModal";

const AddedCardOptions = ({
  isDefaultCard,
  paymentMethodId,
  entityId,
}: {
  isDefaultCard: boolean;
  paymentMethodId: string;
  entityId: string;
}) => {
  const { uuid: groupId } = useCurrentGroup();
  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const {
    authtoken: { email },
  } = useContext(authContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDefaultCardModal, setShowDefaultCardModal] = useState(false);
  const { trackEvent } = useAnalytics();

  const updateDropdownShow = (show: boolean) => {
    setDropdownOpen(show);
    if (show) {
      trackEvent(CREDIT_CARD_OPTION_OPENED, {
        group_id: groupId!,
        email: email,
      });
    }
  };

  const groupHasNoSubscriptions = subscriptions?.every(
    (sub) => !sub.group_subscription
  );

  const deletable = groupHasNoSubscriptions || !isDefaultCard;

  return (
    <>
      <DropDown.Root
        onOpenChange={updateDropdownShow}
        open={dropdownOpen}
        modal={false}
      >
        <DropDown.Trigger
          className={classNames("all:unset t-rounded", {
            "hover:t-bg-purple-50": isDefaultCard,
            "hover:t-bg-neutral-0": !isDefaultCard,
          })}
        >
          <div
            role="button"
            className={classNames({
              "t-text-[black]": !isDefaultCard,
              "t-text-surface": isDefaultCard,
            })}
          >
            <SmallOptionIcon color="currentColor" />
          </div>
        </DropDown.Trigger>
        <DropDown.Portal>
          <DropDown.Content
            sideOffset={8}
            className="t-rounded-lg t-bg-surface t-p-2 t-drop-shadow-dropdown sm:t-w-[54vw]"
            align="end"
          >
            <DropDown.Item
              className="t-flex t-items-center t-gap-2 t-rounded hover:t-bg-i-surface-light-purple data-[disabled]:t-text-neutral-30"
              onSelect={() => setShowDefaultCardModal(true)}
              disabled={isDefaultCard}
            >
              Mark as default
            </DropDown.Item>
            <ConditionalToolTip
              condition={
                !deletable &&
                "This is your default card for active subscriptions. It cannot be deleted unless another card is labeled as default."
              }
            >
              <span>
                <DropDown.Item
                  className="t-flex t-items-center t-gap-2 t-rounded hover:t-bg-i-surface-light-purple data-[disabled]:t-text-neutral-30"
                  onSelect={() => setShowDeleteModal(true)}
                  disabled={!deletable}
                >
                  Delete
                </DropDown.Item>
              </span>
            </ConditionalToolTip>
          </DropDown.Content>
        </DropDown.Portal>
      </DropDown.Root>
      <DeleteCardModal
        entityId={entityId}
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        paymentMethodId={paymentMethodId}
      />
      <DefaultCardModal
        entityId={entityId}
        show={showDefaultCardModal}
        closeModal={() => setShowDefaultCardModal(false)}
        paymentMethodId={paymentMethodId}
      />
    </>
  );
};

export default AddedCardOptions;
