import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import cx from "classnames";
import { MERCHANT_TASK_STATUS_TYPES } from "types/Models/merchant";
import {
  COMPLETED,
  IN_PROGRESS,
  TBD,
  NOT_REQUIRED,
  REQUIRED,
  VERIFYING,
} from "constants/taskContractorFilingStates";
import GrayPencil from "static/images/GrayPencil.svg";
import AssignedUser from "static/images/AssignedUser.svg";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Button } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import { useRoleBasedView } from "hooks/useRoleBasedView";

type Task1099ContractorFillingProps = {
  filingStatus: MERCHANT_TASK_STATUS_TYPES;
  onUpdate: (status: MERCHANT_TASK_STATUS_TYPES) => any;
  isLoading: boolean;
  isSuccessPage: boolean;
};

const TEXT_MAP = {
  [COMPLETED]: "Completed",
  [IN_PROGRESS]: "In Progress",
  [TBD]: "TBD",
  [REQUIRED]: "Required",
  [NOT_REQUIRED]: "Not Required",
  [VERIFYING]: "Verifying",
};

const TAG_COLORS = {
  [COMPLETED]: "green",
  [IN_PROGRESS]: "orange",
  [TBD]: "gray",
  [REQUIRED]: "green",
  [NOT_REQUIRED]: "transparent",
  [VERIFYING]: "gray",
};

const FilingItem = ({
  onSelect,
  selectedStatus,
  status,
}: {
  onSelect: Task1099ContractorFillingProps["onUpdate"];
  status: MERCHANT_TASK_STATUS_TYPES;
  selectedStatus: MERCHANT_TASK_STATUS_TYPES;
}) => {
  return (
    <Dropdown.Item
      as="button"
      eventKey={status}
      className="button-reset task-assign-item"
    >
      <span
        className={cx("sendDataTag", {
          inactive: selectedStatus === status,
        })}
        onClick={() => onSelect(status)}
      >
        {TEXT_MAP[status]}
      </span>
      {selectedStatus === status && (
        <div className="w-25">
          <img src={AssignedUser} className="assignCheck w-100" alt="check" />
        </div>
      )}
    </Dropdown.Item>
  );
};

export const Task1099FilingStatus = ({
  filingStatus,
  onUpdate,
  isLoading,
  isSuccessPage,
}: Task1099ContractorFillingProps) => {
  const { isCustomer } = useRoleBasedView();
  const finalStatus = filingStatus;
  const [isEditable, setIsEditable] = useState(false);

  return (
    <Dropdown autoClose="outside">
      <div className="t-flex t-items-center t-gap-2">
        <Dropdown.Toggle
          as="button"
          className="task-assign-toggle button-reset t-group"
          disabled={isCustomer || isSuccessPage}
        >
          <div
            className="t-flex t-gap-2 t-items-center"
            onClick={() => setIsEditable((isEditable) => !isEditable)}
          >
            {/* @ts-ignore */}
            <Tag tagType={TAG_COLORS[finalStatus]}>
              <span className="t-whitespace-nowrap t-inline-block t-w-full t-overflow-hidden t-text-ellipsis t-text-body-sm t-line-clamp-1">
                {TEXT_MAP[finalStatus]}
              </span>
            </Tag>
            {!isEditable && !isCustomer && !isSuccessPage && (
              <img
                src={GrayPencil}
                alt="GrayPencil"
                className="t-h-4 group-hover:t-visible t-invisible"
              />
            )}
            {isEditable && !isCustomer && !isSuccessPage && <Cross />}
          </div>
        </Dropdown.Toggle>
      </div>

      <Dropdown.Menu key={finalStatus}>
        <FilingItem
          selectedStatus={filingStatus}
          status={COMPLETED}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={IN_PROGRESS}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={TBD}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={REQUIRED}
          onSelect={onUpdate}
        />
        <FilingItem
          selectedStatus={filingStatus}
          status={NOT_REQUIRED}
          onSelect={onUpdate}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
