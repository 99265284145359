import Loader from "components/design/loader";
import NoDataAvailable from "components/design/noDataAvailable";
import { DocumentPreviewModal } from "components/PreviewModal";
import {
  ACTION_REQUIRED,
  ADD_MAIL_TITLE,
  EDIT_MAIL_TITLE,
  FORWARDED,
  FORWARD_REQUESTED,
  NEWEST,
  SHREDDED,
  SHRED_REQUESTED,
} from "constants/mailRoom";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { DefaultForwardMessage } from "pages/MailroomForward/DefaultForwardMessage";
import { useContext, useEffect, useState } from "react";
import "static/styles/components/mailbox.css";
import {
  useGetAllMailroomQuery,
  useGetMailroomAddressQuery,
} from "store/apis/mailroom";
import { AddNewmailModal } from "./addNewmailModal";
import { MailboxAddress } from "./mailboxAddress";
import { MailRoomData } from "./mailboxData";
import { SORT_TYPE } from "./mailboxDictonaries";
import { MailboxHeader } from "./mailboxHeader";
import { MailRoomNotSubscribe } from "./mailRoomNotSubscribe";
import { NoMails } from "./noMails";

export const Mailbox = ({ userGroupId, hasMailroomSubscription }) => {
  const { groupEntities } = useContext(authContext);
  const mailroomSubscription =
    groupEntities?.data?.groups[0]?.has_mailroom_subscription ||
    hasMailroomSubscription;
  usePageTitle("Mailroom");
  const { isAdmin } = useRoleBasedView();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const pageNumber = Number(query.get("page") || 1);
  const oldSearchTerm = query.get("search_term");
  const tab = query.get("tab");
  const type = query.get("sort_by");
  const [addMailModalShow, setAddMailModalShow] = useState(false);
  const [mailModalTitle, setMailModalTitle] = useState("");
  const [clickedData, setClickedData] = useState({});
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [sortType, setSortType] = useState(
    SORT_TYPE[type] || SORT_TYPE[NEWEST]
  );
  const [tabName, setTabName] = useState(tab || null);
  const [checkedBoxes, setCheckedBoxes] = useState({
    ACTION_REQUIRED: query.get(ACTION_REQUIRED) || false,
    FORWARD_REQUESTED: query.get(FORWARD_REQUESTED) || false,
    SHREDDED: query.get(SHREDDED) || false,
    FORWARDED: query.get(FORWARDED) || false,
    SHRED_REQUESTED: query.get(SHRED_REQUESTED) || false,
  });
  const {
    isOpen: showAddModal,
    close: closeAddModal,
    open: openAddModal,
  } = useModal();
  const [filters, setFilters] = useState([]);
  const { uuid: groupId } = useCurrentGroupContext();
  const { data, isLoading, isSuccess } = useGetAllMailroomQuery(
    {
      groupId,
      page_num: pageNumber || null,
      search_term: oldSearchTerm || null,
      sort_type: sortType.type || null,
      filter_statuses: filters?.join(",") || null,
      filter_type: tabName || null,
    },
    {
      skip: !groupId,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    mails: mailboxData = [],
    current_page,
    per_page,
    total_count,
    total_pages,
  } = data || {};
  const { data: mailroomAddress } = useGetMailroomAddressQuery(
    { groupId },
    { skip: !groupId, refetchOnMountOrArgChange: true }
  );
  const isForwardAddressRequired = !Boolean(mailroomAddress?.uuid);
  const openAddressModal = query.get("open_address");

  useEffect(() => {
    if (openAddressModal) {
      openAddModal();
      update({ query: "open_address", value: null });
    }
  }, [openAddressModal]);

  useEffect(() => {
    const checkFilterApplied = () => {
      let appliedFilters = [];
      appliedFilters = Object.entries(checkedBoxes).reduce(
        (acc, [key, value]) => (value ? [...acc, key] : acc),
        []
      );

      setFilters(appliedFilters);
    };
    checkFilterApplied();
  }, [checkedBoxes]);

  const openAddMailModal = () => {
    setAddMailModalShow(true);
    setMailModalTitle(ADD_MAIL_TITLE);
  };

  const openEditMail = (data) => {
    setClickedData(data);
    setMailModalTitle(EDIT_MAIL_TITLE);
    setAddMailModalShow(true);
  };

  const closeAddAndEditMailModal = () => {
    setClickedData({});
    setAddMailModalShow(false);
  };

  const openAddress = () => setShowAddressModal(true);

  const checkAnyFilterApplied = oldSearchTerm !== null || filters.length > 0;

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  if (isLoading || !groupId) {
    return (
      <div className="w-100 mailbox-container">
        <Loader />
      </div>
    );
  }

  if (!mailroomSubscription && !isLoading && isSuccess) {
    if (isAdmin) {
      return <NoDataAvailable noDataText="Mailroom not subscribed" />;
    } else {
      return (
        <div className="w-100 mailbox-container">
          <MailRoomNotSubscribe />;
        </div>
      );
    }
  }

  return (
    <div className="w-100 t-flex t-flex-col t-gap-5 mailbox-container">
      <MailboxHeader
        searchTerm={oldSearchTerm}
        openAddMailModal={openAddMailModal}
        setCheckedBoxes={setCheckedBoxes}
        checkedBoxes={checkedBoxes}
        setTabName={setTabName}
        tabName={tabName}
        setSortType={setSortType}
        sortType={sortType}
        openAddress={openAddress}
        filters={filters}
        paginationData={paginationData}
        totalPages={total_pages}
      />
      {mailboxData.length === 0 ? (
        <NoMails
          onClick={openAddMailModal}
          checkAnyFilterApplied={checkAnyFilterApplied}
        />
      ) : (
        <MailRoomData mailData={mailboxData} openEditMail={openEditMail} />
      )}
      {addMailModalShow && (
        <AddNewmailModal
          clickedData={clickedData}
          addMailModalShow={addMailModalShow}
          closeModal={closeAddAndEditMailModal}
          groupId={groupId}
          title={mailModalTitle}
          mailboxData={mailboxData}
        />
      )}
      {showAddressModal && (
        <MailboxAddress
          show={showAddressModal}
          closeModal={() => setShowAddressModal(false)}
          groupId={groupEntities?.data?.groups[0]?.uuid || userGroupId}
          openAddModal={openAddModal}
          isForwardAddressRequired={isForwardAddressRequired}
          defaultAddressAutofillString={mailroomAddress?.autofill_string}
        />
      )}
      <DefaultForwardMessage
        isOpen={showAddModal}
        close={closeAddModal}
        openForwardModal={openAddModal}
        defaultForwardAddress={mailroomAddress?.uuid}
      />
      <DocumentPreviewModal />
    </div>
  );
};
