import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Cross } from "components/icons/Cross";
import { NoVendors } from "components/icons/NoVendors";
import { DocumentPreviewModal } from "components/PreviewModal";
import { FileRequestOrUpload } from "components/WformFileRequestOrUpload";
import { WSeriesFormUpload } from "components/WSeriesFormUpload";
import { AWAITING } from "constants/merchantFormStatuses";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import CloseIcon from "static/images/Close.svg";
import GreenTick from "static/images/GreenTick.svg";
import HourGlassTimer from "static/images/Hourglass.svg";
import {
  useEditVendorsMutation,
  useUploadForm1099FileMutation,
  vendorsApi,
} from "store/apis/vendors";
import {
  closeAddOrEditModal,
  openAddOrEditModal,
  setMerchantToMerge,
} from "store/slices/vendors";
import {
  CPA_REVIEWED,
  NOT_REQUIRED,
  SYSTEM_REVIEWED,
} from "types/Models/vendors";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import { AddOrEditMerchant } from "./addOrEditMerchant";
import { MarkMerchantAsVendor } from "./MarkMerchantAsVendorModal";
import { MerchantTableDropdown } from "./MerchantTableDropdown";
import { MergeMerchants } from "./MergeMerchants";
import VendorColumn from "./VendorColumn";
import { VendorsFilter } from "./VendorsFilter";
import { FileInput } from "components/FileInput/FileInput";

export const MerchantTable = ({
  merchants,
  groupId,
  type,
  totalPages,
  paginationData,
  noMerchant,
}) => {
  const { alertToast, successToast } = useToast();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const selectedSeason = query.get("season") || "2023";

  let entityId = useCurrentEntityId();

  const [editVendors] = useEditVendorsMutation();

  const showAddOrEditModal = useSelector(
    (state) => state.vendors.showAddOrEditModal
  );

  const {
    open: openFormUploadModal,
    close: closeFormUploadModal,
    isOpen: showFormUploadModal,
  } = useModal();

  const {
    open: openMarkAsVendorModal,
    close: closeMarkAsVendorModal,
    isOpen: showMarkAsVendorModal,
  } = useModal();
  const {
    open: openMergeMerchantsModal,
    close: closeMergeMerchantsModal,
    isOpen: showMergeMerchantsModal,
  } = useModal();
  const openPreview = useDocPreview();
  const { isCustomer } = useRoleBasedView();
  const [upload1099File, { isLoading: isUploading, originalArgs }] =
    useUploadForm1099FileMutation();
  const dispatch = useDispatch();
  const [editMerchantId, setEditMerchantId] = useState("");
  const showWform = type === "wForm";

  const updateMerchantType = async (merchant_type, uuid) => {
    try {
      await editVendors({ groupId, uuid, payload: { merchant_type } }).unwrap();
      successToast({ message: "Vendor has been updated" });
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const onDrop = async ({ newFiles, form1099DoucmentType, editMerchantId }) => {
    try {
      const file = newFiles[0];
      const fileToSign = file?.uuid ? { fileId: file.uuid } : { file: file };
      await upload1099File({
        groupId: groupId,
        merchantId: editMerchantId,
        entityId,
        form1099Document: form1099DoucmentType,
        ...fileToSign,
      }).unwrap();
      successToast({ message: "File uploaded successfully" });
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const openMarkAsVendor = (merchant) => {
    setEditMerchantId(merchant.uuid);
    openMarkAsVendorModal();
  };

  const editMerchant = (merchant) => {
    setEditMerchantId(merchant.uuid);
    dispatch(openAddOrEditModal());
  };

  const openWFormUpload = (merchantId) => {
    openFormUploadModal();
    setEditMerchantId(merchantId);
  };

  const openFilePreview = (fileId) => {
    if (fileId) {
      openPreview(fileId);
    }
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = page < totalPages ? page : totalPages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (page < totalPages) {
      update({ query: "page", value: page + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: totalPages });
  };

  const getAllMerchants = () => {
    dispatch(vendorsApi.util.invalidateTags(["VENDORS"]));
  };

  const closeAddOrEditMerchantModal = () => {
    setEditMerchantId();
    dispatch(closeAddOrEditModal());
  };

  const closeWseriesFormUpload = () => {
    closeFormUploadModal();
    setEditMerchantId();
  };

  const mergeMerchants = () => {
    const merchantsToMerge = table
      .getSelectedRowModel()
      .flatRows.map((merchant) => {
        return merchant.original.uuid;
      });
    dispatch(setMerchantToMerge(merchantsToMerge));
    openMergeMerchantsModal();
  };

  const columnHelper = createColumnHelper();

  const optionColumn = columnHelper.display({
    header: "",
    id: "actions",
    cell: (info) => {
      const isManualMerchant = info.row.original.source === "MANUAL";
      const isVendor = info.row.original.is_w_form_required !== "NOT_REQUIRED";
      const showOption = showWform
        ? info.row.original?.is_w_form_required === CPA_REVIEWED
        : true;

      if (!showOption) {
        return;
      }

      return (
        <div className="t-flex t-justify-end t-w-full t-bg-surface">
          <MerchantTableDropdown
            editMerchant={() => editMerchant(info.row.original)}
            openMarkAsVendor={() => openMarkAsVendor(info.row.original)}
            merchantId={info.row.original.uuid}
            groupId={groupId}
            isManualMerchant={isManualMerchant}
            isVendor={isVendor}
          />
        </div>
      );
    },
    size: showWform ? 10 : 25,
  });

  const commonColumns = [
    columnHelper.display({
      id: "select",
      size: 3,
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          key={row.id}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    }),
    columnHelper.accessor("logo", {
      cell: (info) => {
        const merchantName = info.row.original.name;
        const merchantInitialLetter = merchantName[0].toUpperCase();
        const canRedirectToTransaction = info.row.original.source !== "MANUAL";
        let transactionRedirectUrl = `/books/transactions?entity=${entityId}&vendors%5B0%5D=${merchantName}`;
        if (!isCustomer) {
          transactionRedirectUrl += `&company=${groupId}`;
        }

        return (
          <div
            className={classNames("t-flex t-gap-2 t-items-center", {
              "t-group t-cursor-pointer": canRedirectToTransaction,
            })}
            onClick={() =>
              canRedirectToTransaction && openLink(transactionRedirectUrl)
            }
          >
            <div className="t-min-h-8 t-min-w-8 t-flex t-items-center t-justify-center t-rounded-full t-bg-purple-40">
              {info.getValue() ? (
                <img
                  src={info.getValue()}
                  className="t-h-8 t-w-8 t-rounded-full"
                  alt="logo"
                />
              ) : (
                <div className="t-text-subtitle-sm t-text-surface">
                  {merchantInitialLetter}
                </div>
              )}
            </div>
            <div className="t-text-subtext t-text-text-60 group-hover:t-underline group-hover:t-text-purple-50">
              {merchantName}
            </div>
          </div>
        );
      },
      header: "VENDOR",
      size: showWform ? 18 : 30,
    }),
    columnHelper.accessor("email", {
      cell: (info) => (
        <VendorColumn.Email
          info={info}
          editMerchantId={editMerchantId}
          setEditMerchantId={setEditMerchantId}
        />
      ),
      header: "EMAIL",
      size: showWform ? 18 : 30,
    }),
    columnHelper.accessor("transaction_amount", {
      cell: (info) => (
        <div className="t-w-full t-flex t-justify-end t-text-subtext t-text-text-60">
          <AmountSuperScript amount={info.getValue() * -1} />
        </div>
      ),
      header: <div className="t-flex t-justify-end">AMOUNT PAID</div>,
      size: showWform ? 12 : 15,
    }),
  ];

  const vendorsColumns = [
    columnHelper.accessor("w_form_document", {
      cell: (info) => {
        const rowData = info.row.original;

        if (!rowData.w_form_document) {
          return (
            <div className="t-w-4/5 t-ml-12">
              <FileRequestOrUpload
                merchant={rowData}
                groupId={groupId}
                updateMerchant={getAllMerchants}
                editable
                wFormStatus={rowData.form_status}
                season={selectedSeason}
                isSuccessPage={false}
              />
            </div>
          );
        }

        if (rowData.form_status === AWAITING) {
          return (
            <div className="t-w-fit t-ml-12">
              <ToolTip text="Awaiting response">
                <span>
                  <Button disabled size="small">
                    <img
                      height="16px"
                      src={HourGlassTimer}
                      alt="HourGlassTimer"
                      className="t-mr-1"
                    />
                    W Form
                  </Button>
                </span>
              </ToolTip>
            </div>
          );
        }

        if (rowData.w_form_document) {
          return (
            <div className="t-flex t-items-center t-gap-2 t-ml-8">
              <ToolTip text="Click to preview">
                <span>
                  <Button
                    onClick={() => openWFormUpload(rowData.uuid)}
                    size="small"
                  >
                    <div className="t-flex t-items-center t-gap-1 t-text-text-30">
                      <FileIcon
                        fileType={rowData?.w_form_document?.file_type}
                        width="16px"
                        height="16px"
                      />
                      <div className="t-text-subtext">
                        {rowData?.w_form_choice}
                      </div>
                    </div>
                  </Button>
                </span>
              </ToolTip>
            </div>
          );
        }
      },
      header: <div className="t-ml-12">FORMS</div>,
      id: "wFormDocument",
      size: 22,
    }),
    columnHelper.display({
      id: "form_1099_document",
      header: "1099 FILING",
      size: 10,
      cell: (info) => {
        const rowData = info.row.original;
        const { form_1099_document } = info.row.original;
        return (
          <div
            className="t-flex t-gap-2 t-items-center"
            onClick={(e) => e.stopPropagation()}
          >
            <ToolTip text="Payer 1099 Form">
              <span>
                {!form_1099_document?.payers_document && !isCustomer ? (
                  <FileInput
                    onDrop={(files) =>
                      onDrop({
                        newFiles: files,
                        editMerchantId: rowData.uuid,
                        form1099DoucmentType: "PAYERS_DOCUMENT",
                      })
                    }
                    isUploading={
                      isUploading &&
                      originalArgs?.merchantId === rowData.uuid &&
                      originalArgs?.form1099Document === "PAYERS_DOCUMENT"
                    }
                    variant="icon"
                    withInkleDocs
                  />
                ) : (
                  <>
                    {form_1099_document?.payers_document && (
                      <FileInput
                        file={form_1099_document?.payers_document}
                        variant="icon"
                        onFileClick={({ uuid: fileId }) =>
                          openFilePreview(fileId)
                        }
                      />
                    )}
                  </>
                )}
              </span>
            </ToolTip>
            <ToolTip text="Recipient 1099 Form">
              <span>
                {!form_1099_document?.recipients_document && !isCustomer ? (
                  <FileInput
                    onDrop={(files) =>
                      onDrop({
                        newFiles: files,
                        editMerchantId: rowData.uuid,
                        form1099DoucmentType: "RECIPIENTS_DOCUMENT",
                      })
                    }
                    isUploading={
                      isUploading &&
                      originalArgs?.merchantId === rowData.uuid &&
                      originalArgs?.form1099Document === "RECIPIENTS_DOCUMENT"
                    }
                    variant="icon"
                    withInkleDocs
                  />
                ) : (
                  <>
                    {form_1099_document?.recipients_document && (
                      <FileInput
                        file={form_1099_document?.recipients_document}
                        variant="icon"
                        onFileClick={({ uuid: fileId }) =>
                          openFilePreview(fileId)
                        }
                      />
                    )}
                  </>
                )}
              </span>
            </ToolTip>
          </div>
        );
      },
    }),
    columnHelper.accessor("is_w_form_required", {
      header: "Form required",
      id: "wFormRequired",
      cell: (info) => {
        const rowData = info.row.original;
        const showWFormAction =
          rowData.is_w_form_required === SYSTEM_REVIEWED && !isCustomer;

        return (
          <div className="t-flex t-items-center t-gap-6">
            {showWFormAction ? (
              <>
                <ToolTip text="W Form is required">
                  <span>
                    <Button
                      customType="ghost_icon"
                      size="small"
                      onClick={() =>
                        updateMerchantType(CPA_REVIEWED, rowData.uuid)
                      }
                    >
                      <img
                        className="t-h-4 t-w-4"
                        src={GreenTick}
                        alt="Green Tick"
                      />
                    </Button>
                  </span>
                </ToolTip>
                <ToolTip text="W Form not required">
                  <span>
                    <Button
                      customType="ghost_icon"
                      size="small"
                      onClick={() =>
                        updateMerchantType(NOT_REQUIRED, rowData.uuid)
                      }
                    >
                      <img
                        className="t-h-4 t-w-4"
                        src={CloseIcon}
                        alt="Close Icon"
                      />
                    </Button>
                  </span>
                </ToolTip>
              </>
            ) : (
              <Tag tagType="gray" icon={false}>
                Form required
              </Tag>
            )}
          </div>
        );
      },
      size: 10,
    }),
  ];

  const table = useReactTable({
    data: merchants,
    columns: showWform
      ? [...commonColumns, ...vendorsColumns, optionColumn]
      : [...commonColumns, optionColumn],
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  return (
    <>
      <div className="t-flex">
        {table?.getSelectedRowModel?.()?.flatRows.length === 0 ? (
          <VendorsFilter />
        ) : (
          <>
            <div className="t-flex t-gap-3 t-items-center t-pt-2">
              <div className="t-flex t-text-body-sm t-text-text-30 t-items-center t-gap-2">
                <Button
                  customType="ghost_icon"
                  size="small"
                  onClick={() => {
                    table.resetRowSelection();
                  }}
                >
                  <Cross color="currentColor" />
                </Button>
                {table.getSelectedRowModel().flatRows.length} &nbsp; selected
              </div>
              {table?.getSelectedRowModel?.()?.flatRows.length > 1 && (
                <div className="t-flex t-gap-2">
                  <Button size="small" onClick={mergeMerchants}>
                    Merge
                  </Button>
                </div>
              )}
            </div>
          </>
        )}

        <div className="t-ml-auto">
          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </div>
      {noMerchant ? (
        <>
          <div className="t-pt-6">
            <EmptyScreen text="No vendors found">
              <NoVendors />
            </EmptyScreen>
          </div>
          <AddOrEditMerchant
            show={showAddOrEditModal}
            groupId={groupId}
            closeModal={closeAddOrEditMerchantModal}
          />
        </>
      ) : (
        <>
          <Table.Container className={classNames("t-overflow-auto")}>
            <Table.Content className="t-w-full">
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.HeadRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        className="t-text-subtext-sm t-uppercase t-py-2"
                        style={{ width: `${header.getSize()}%` }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.HeadRow>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                        className="t-py-2"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
          <div className="t-mt-7 t-flex t-justify-end">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </>
      )}
      <DocumentPreviewModal />
      <WSeriesFormUpload
        show={showFormUploadModal}
        closeModal={closeWseriesFormUpload}
        groupId={groupId}
        merchant={merchants.find(({ uuid }) => uuid === editMerchantId)}
        updateMerchant={getAllMerchants}
        editable
        openModal={openFormUploadModal}
        season={selectedSeason}
      />

      <MarkMerchantAsVendor
        groupId={groupId}
        show={showMarkAsVendorModal}
        closeModal={closeMarkAsVendorModal}
        merchantId={editMerchantId}
      />
      <AddOrEditMerchant
        show={showAddOrEditModal}
        groupId={groupId}
        editMerchantData={merchants.find(({ uuid }) => uuid === editMerchantId)}
        closeModal={closeAddOrEditMerchantModal}
      />
      <MergeMerchants
        show={showMergeMerchantsModal}
        closeModal={closeMergeMerchantsModal}
        groupId={groupId}
        resetSelectedMerchant={() => table.resetRowSelection()}
        merchants={merchants}
      />
    </>
  );
};
