import classNames from "classnames";
import { DashboardLayout } from "components/DashboardLayout";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Modal from "components/DesignSystem/Modal/Modal";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { SliderNavigation } from "components/DesignSystem/SliderNavigation/SliderNavigation";
import Table from "components/DesignSystem/Table/V2/Table";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { InvestorEdit } from "components/RaiseCRM/InvestorEdit";
import { InvestorView } from "components/RaiseCRM/InvestorView";
import { RaiseProfileInfo } from "components/RaiseProfileInfo/RaiseProfileInfo";
import { ConditionalLink } from "components/conditionalLink";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import { Cross } from "components/icons/Cross";
import { SingleArrowIcon } from "components/icons/SingleArrowIcon";
import { DD_MMM_YYYY, TIME_FORMAT } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useSliderNavigation } from "hooks/useSliderNavigation";
import { useToast } from "hooks/useToast";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import Globe from "static/images/Globe.svg";
import LinkedInLogo from "static/images/LinkedInLogo.svg";
import {
  VCRaiseProfile,
  useGetRaiseProfilesQuery,
  useUpdateRaiseProfileMutation,
} from "store/apis/raiseProfiles";
import { VentureCapitalist } from "store/apis/vcCrm";
import {
  setInvestorEditable,
  setOpenInvestorSlider,
} from "store/slices/investor";
import { RootState } from "store/store";

const StartupHeader = ({
  currentIndex,
  setOpenProfile,
  raiseProfile,
  onNext,
  onPrev,
}: {
  currentIndex: number;
  setOpenProfile: (newState: React.SetStateAction<string | null>) => void;
  raiseProfile: VCRaiseProfile;
  onNext: () => void;
  onPrev: () => void;
}) => {
  const { data: intros = [] } = useGetRaiseProfilesQuery();

  return (
    <div className="t-flex t-justify-between t-border-b t-border-neutral-10 t-border-solid t-border-0 t-p-4 t-sticky t-top-0 t-bg-surface">
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-bg-surface-grey t-w-8 t-h-8 t-flex t-justify-center t-items-center t-rounded-full">
          {raiseProfile?.company_group.name?.at(0)?.toUpperCase()}
        </div>

        <div className="t-flex t-flex-col">
          <span>{raiseProfile?.company_group.name}</span>
          <span className="t-text-body-sm t-text-text-30">
            Last updated on{" "}
            {dayjs(raiseProfile?.details_for_investors?.updated_at).format(
              DD_MMM_YYYY
            )}
          </span>
        </div>
      </div>
      <div className="t-flex t-justify-between t-items-center">
        <SliderNavigation
          dataLength={intros.length}
          currentIndex={currentIndex}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Button customType="ghost_icon" onClick={() => setOpenProfile(null)}>
          <Cross color="currentColor" />
        </Button>
      </div>
    </div>
  );
};

const SliderHeader = ({ rowData }: { rowData?: VentureCapitalist }) => {
  const dispatch = useDispatch();

  return (
    <div className="t-flex t-justify-between t-border-b t-border-neutral-10 t-border-solid t-border-0 t-p-4 t-sticky t-top-0 t-bg-surface t-z-10">
      <div className="t-flex t-gap-2 t-items-center">
        {rowData?.name && (
          <div className="t-bg-surface-grey t-w-8 t-h-8 t-flex t-justify-center t-items-center t-rounded-full">
            {rowData?.name?.at(0)?.toUpperCase()}
          </div>
        )}

        <span>{rowData?.name}</span>
      </div>
      <Button
        customType="ghost_icon"
        size="small"
        onClick={() => {
          dispatch(setInvestorEditable(false));
          dispatch(setOpenInvestorSlider(false));
        }}
      >
        <Cross color="currentColor" />
      </Button>
    </div>
  );
};

const UnInterested = ({
  setComment,
  raiseProfile,
  onUpdate,
  accepting,
}: {
  setComment: (newState: React.SetStateAction<string>) => void;
  raiseProfile?: VCRaiseProfile;
  onUpdate: (profile_id: string, type: "Accepted" | "Rejected") => void;
  accepting: boolean;
}) => {
  return (
    <Formik
      initialValues={{ comment: "" }}
      onSubmit={({ comment }) => setComment(comment)}
    >
      {({ submitForm }) => (
        <Modal.Content size="small">
          <Modal.Header>
            <Modal.Title>Add Comment</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <p>
              Could you briefly share why this startup may not align with your
              current investment preferences?
            </p>

            <Form className="t-m-0">
              <TextInput label="Comment (Optional)" name="comment" />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Root>
              <div className="t-flex t-gap-2 t-justify-end">
                <Modal.Trigger asChild></Modal.Trigger>
                <Modal.Trigger asChild>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    type="submit"
                  >
                    Next
                  </Button>
                </Modal.Trigger>
              </div>

              {raiseProfile && (
                <ConfirmNotInterested
                  onConfirm={() => onUpdate(raiseProfile?.uuid, "Rejected")}
                  isLoading={accepting}
                />
              )}
            </Modal.Root>
          </Modal.Footer>
        </Modal.Content>
      )}
    </Formik>
  );
};

const ConfirmInterested = ({
  onConfirm,
  isLoading,
}: {
  onConfirm: () => void;
  isLoading: boolean;
}) => {
  return (
    <Modal.Content size="small">
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body>This will send an email to the startup.</Modal.Body>
      <Modal.Footer>
        <div className="t-flex t-gap-2 t-justify-end">
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            customType="success"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Content>
  );
};

const ConfirmNotInterested = ({
  onConfirm,
  isLoading,
}: {
  onConfirm: () => void;
  isLoading: boolean;
}) => {
  return (
    <Modal.Content size="small">
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body>This will send an email to the startup.</Modal.Body>
      <Modal.Footer>
        <div className="t-flex t-gap-2 t-justify-end">
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="danger"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Content>
  );
};

export const RaiseInvestor = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const [openProfile, setOpenProfile] = useState<string | null>(null);
  const [comment, setComment] = useState("");
  const [rowData, setRowData] = useState<VCRaiseProfile | null>();
  const { openInvestorSlider, investorEdit } = useSelector(
    (state: RootState) => state.investor
  );

  const {
    data: intros = [],
    isLoading,
    isSuccess,
  } = useGetRaiseProfilesQuery();

  const {
    currentItem: raiseProfile,
    currentItemIndex,
    setCurrentItemIndex,
    goToPreviousItem,
    goToNextItem,
  } = useSliderNavigation({ data: intros! });

  const [updateRaiseProfile, { isLoading: accepting, originalArgs }] =
    useUpdateRaiseProfileMutation();

  useEffect(() => {
    if (openProfile) {
      setOpenProfile(raiseProfile?.uuid);
    }
  }, [raiseProfile]);

  const createColumn = createColumnHelper<VCRaiseProfile>();

  const onUpdate = async (
    profile_id: string,
    type: "Accepted" | "Rejected"
  ) => {
    try {
      await updateRaiseProfile({
        profileId: profile_id,
        status: type,
        comment,
      }).unwrap();
      setComment("");
      setOpenProfile(null);
      successToast({ message: "Updated successfully" });
    } catch (error) {
      alertToast({
        message: "Error in updating.",
      });
    }
  };

  const onRowClick = (
    uuid: string,
    e: Event | React.MouseEvent<HTMLTableRowElement>,
    rowId: string
  ) => {
    if (openProfile && openProfile === uuid) {
      setOpenProfile(null);
      return;
    }
    e.stopPropagation();
    setOpenProfile(uuid);
    setCurrentItemIndex(Number(rowId));
  };

  const onOutSideClick = () => {
    setOpenProfile(null);
    dispatch(setInvestorEditable(false));
    dispatch(setOpenInvestorSlider(false));
  };

  const columns = [
    createColumn.accessor("company_group.name", {
      header: "Startup name",
      size: 25,
      cell: ({
        row: {
          original: {
            company_group: { name, group_website, group_linkedIn_url },
          },
        },
      }) => {
        return (
          <div className="t-flex t-gap-2 t-items-center">
            <div
              className="t-flex t-gap-2"
              onClick={(e) => e.stopPropagation()}
            >
              <ConditionalLink to={group_linkedIn_url}>
                <img src={LinkedInLogo} alt="In" />
              </ConditionalLink>
              <ConditionalLink to={group_website}>
                <img src={Globe} alt="website" />
              </ConditionalLink>
            </div>
            <div className="t-text-body">{name}</div>
          </div>
        );
      },
    }),

    createColumn.accessor("details_for_investors.cofounder_data", {
      header: "Contact name",
      size: 15,
      cell(info) {
        const cofounderData = info.getValue();

        const { cofounder_name, cofounder_linkedIn_url } =
          cofounderData?.[0] || {};

        if (!cofounder_name && !cofounder_linkedIn_url) {
          return "-";
        }

        return (
          <>
            <ConditionalLink
              to={cofounder_linkedIn_url}
              className="t-items-center t-gap-2 hover:!t-underline t-inline-flex"
            >
              {cofounder_linkedIn_url && (
                <img src={LinkedInLogo} alt="In" className="t-mb-0.5" />
              )}
              <span className="t-text-text-100">{cofounder_name}</span>
            </ConditionalLink>
          </>
        );
      },
    }),

    createColumn.accessor("company_group.description", {
      header: "STARTUP DESCRIPTION",
      size: 35,
      cell(info) {
        const value = info.getValue();

        return (
          <ToolTip text={value}>
            <span className="t-line-clamp-1">{value}</span>
          </ToolTip>
        );
      },
    }),

    createColumn.accessor("vc_interest", {
      header: "Interested",
      size: 15,
      cell(info) {
        const status = info.getValue();

        if (status === "Accepted") {
          return <Tag tagType="green">Interested</Tag>;
        }

        if (status === "Rejected") {
          return <Tag tagType="red">Not interested</Tag>;
        }

        return <Tag tagType="yellow">Pending</Tag>;
      },
    }),

    createColumn.accessor("vc_interest", {
      id: "action",
      header: "",
      size: 10,
      cell(info) {
        const vcInterest = info.getValue();

        if (vcInterest === "Accepted" || vcInterest === "Rejected") {
          return null;
        }

        return (
          <Button customType="primary-outlined" size="small">
            Respond
          </Button>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: intros || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Raise"
          right={
            <Button
              customType="primary"
              size="small"
              onClick={() => dispatch(setOpenInvestorSlider(true))}
            >
              View My Profile
            </Button>
          }
        />
      }
    >
      <div className="t-flex t-flex-col t-gap-8 t-p-5">
        <Async.Root
          isLoading={isLoading}
          isEmpty={Boolean(intros) && intros?.length === 0}
          isSuccess={isSuccess}
        >
          <Async.Empty>No intros</Async.Empty>
          <Async.Success>
            <>
              <div className="t-text-body t-text-text-60">
                <p>
                  Access vetted dealfow of India {">"} US early stage startups
                  focusing on US+global markets.
                </p>

                <p>
                  Below are the startups who have recently applied to Inkle
                  Raise.
                </p>
                <p>
                  Select a profile, read and decide using the action buttons. If
                  you click on "Interested" you'll receive a 3-way email
                  introducing you (reply and BCC us to drop us from the thread).
                  If you click "Not interested", it will fire a decision (with
                  optional comment) to the founder, but will not CC you. You'll
                  receive a weekly digest email with more applicants. Happy
                  hunting!
                </p>
              </div>
              <Table.Container>
                <Table.Content>
                  <Table.Head>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Table.HeadCell
                            key={header.id}
                            style={{ width: `${header.getSize()}%` }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </Table.HeadCell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {table.getRowModel().rows.map((row) => (
                      <Table.Row
                        key={row.id}
                        className={classNames(
                          "t-cursor-pointer hover:t-bg-surface-lighter-grey",
                          {
                            "t-bg-surface-lighter-grey":
                              openProfile === row?.original?.uuid,
                          }
                        )}
                        onClick={(e) =>
                          onRowClick(row.original.uuid, e, row.id)
                        }
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell
                            key={cell.id}
                            style={{ width: `${cell.column.getSize()}%` }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table.Content>
              </Table.Container>

              <div
                className={classNames({
                  "t-fixed t-top-0 t-left-0 t-bg-text-100 t-opacity-20 t-w-full t-h-full t-z-overlay":
                    Boolean(openProfile || openInvestorSlider),
                  "t-w-0 t-h-0 t-invisible": !Boolean(
                    openProfile || openInvestorSlider
                  ),
                })}
                onClick={onOutSideClick}
              />
              <Slider
                open={Boolean(openProfile)}
                position="right"
                width={500}
                className="t-overflow-auto t-rounded t-border t-border-solid t-border-neutral-0 t-shadow !t-z-overlay !t-fixed t-w-[500px] !t-p-0"
              >
                <StartupHeader
                  currentIndex={currentItemIndex}
                  setOpenProfile={setOpenProfile}
                  raiseProfile={raiseProfile}
                  onNext={goToNextItem}
                  onPrev={goToPreviousItem}
                />
                <RaiseProfileInfo currentIndex={currentItemIndex}>
                  {raiseProfile?.vc_interest !== "Accepted" &&
                    raiseProfile?.vc_interest !== "Rejected" && (
                      <div className="t-flex t-gap-2 t-sticky t-bottom-0 t-py-4 t-bg-white">
                        <Modal.Root>
                          <Modal.Trigger asChild>
                            {raiseProfile && (
                              <Button block customType="primary">
                                Interested
                              </Button>
                            )}
                          </Modal.Trigger>

                          {raiseProfile && (
                            <ConfirmInterested
                              onConfirm={() =>
                                onUpdate(raiseProfile?.uuid, "Accepted")
                              }
                              isLoading={accepting}
                            />
                          )}
                        </Modal.Root>
                        <Modal.Root>
                          <Modal.Trigger asChild>
                            {raiseProfile && (
                              <Button block>Not interested</Button>
                            )}
                          </Modal.Trigger>
                          <UnInterested
                            setComment={setComment}
                            accepting={accepting}
                            raiseProfile={raiseProfile}
                            onUpdate={onUpdate}
                          />
                        </Modal.Root>
                      </div>
                    )}
                </RaiseProfileInfo>
              </Slider>
            </>
          </Async.Success>
        </Async.Root>
      </div>
      <Slider
        open={openInvestorSlider}
        position="right"
        width={500}
        className="t-overflow-auto t-rounded t-border t-border-solid t-border-neutral-0 t-shadow !t-z-overlay !t-fixed t-w-[500px] !t-p-0"
      >
        {investorEdit ? (
          <InvestorEdit investorId={rowData?.uuid!}>
            {(rowData) => <SliderHeader rowData={rowData} />}
          </InvestorEdit>
        ) : (
          <InvestorView investorId={rowData?.uuid!}>
            {(rowData) => <SliderHeader rowData={rowData} />}
          </InvestorView>
        )}
      </Slider>
    </DashboardLayout>
  );
};
