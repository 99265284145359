import SeatPlanManage from "components/billing/SeatPlanManage";
import { Button } from "components/DesignSystem/Button/Button";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useDispatch } from "react-redux";
import { teamSettingsApi } from "store/apis/teamSetting";

type TeamMemberSeatBannerProps = {
  availableSeats: number;
  seatData: {
    active_group_users_count: number;
    available_seats: number;
    extra_seat_amount: number;
    free_seats: number;
  };
};

export const TeamMemberSeatBanner = ({
  availableSeats,
  seatData,
}: TeamMemberSeatBannerProps) => {
  const { isGroupAdmin } = useRoleBasedView();
  const {
    open: openSeatManageModal,
    close: closeSeatManageModal,
    isOpen: showSeatManageModal,
  } = useModal();
  const dispatch = useDispatch();

  const updateTeamMembers = () => {
    dispatch(teamSettingsApi.util.invalidateTags(["TeamMember"]));
  };

  return (
    <div className="t-shadow-light-30 t-flex t-justify-between t-items-center t-p-4 t-mb-5 t-rounded">
      <div className="t-text-subtext t-text-text-60">
        {availableSeats} paid seat remaining
      </div>
      {isGroupAdmin && (
        <Button
          size="small"
          customType="primary-outlined"
          onClick={openSeatManageModal}
        >
          Manage Seats
        </Button>
      )}
      {showSeatManageModal && (
        <SeatPlanManage
          show={showSeatManageModal}
          closeModal={closeSeatManageModal}
          seatData={seatData}
          handlePlanManageSuccess={updateTeamMembers}
        />
      )}
    </div>
  );
};
