import { useGetAllPerkTagsQuery } from "store/apis/perksCrm";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Loader } from "components/DesignSystem/Loader/Loader";

export const PerkCategoryFilter = ({
  onSelect,
  selected,
}: {
  onSelect: (id: string) => void;
  selected: string[];
}) => {
  const { data } = useGetAllPerkTagsQuery();

  if (!data) {
    return (
      <div className="t-flex t-items-center t-justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {data.map((perkTag) => (
        <div className="t-px-3 t-py-1.5" key={perkTag.uuid}>
          <Checkbox
            key={perkTag.uuid}
            label={
              <span className="t-text-text-30 t-text-body">
                {perkTag.title}
              </span>
            }
            name={perkTag.title}
            checked={selected.includes(perkTag.uuid)}
            onChange={(e) => onSelect(perkTag.uuid)}
          />
        </div>
      ))}
    </>
  );
};
