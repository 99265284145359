import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { FC } from "react";

interface IConfirmReviewProps {
  show: boolean;
  closeModal: () => void;
  isLoading: boolean;
  markDocumentAsReview: () => void;
}

export const ConfirmReview: FC<IConfirmReviewProps> = ({
  show,
  closeModal,
  isLoading,
  markDocumentAsReview,
}) => {
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Review documents</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-30">
            Are you sure you have reviewed the draft and want to proceed with
            the filing?
          </div>
          <div className="t-text-body t-text-text-100 t-mt-8">
            You can’t undo this action.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end">
            <Button
              customType="primary"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={markDocumentAsReview}
            >
              Confirm review
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
