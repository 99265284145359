export const PrivateChat = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_322_48343)">
      <path
        d="M10.0001 13C10.8631 13 11.5626 12.3004 11.5626 11.4375C11.5626 10.5746 10.8631 9.875 10.0001 9.875C9.13718 9.875 8.43762 10.5746 8.43762 11.4375C8.43762 12.3004 9.13718 13 10.0001 13Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0001 13V14.875"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2501 7.375H3.75012C3.40494 7.375 3.12512 7.65482 3.12512 8V16.75C3.12512 17.0952 3.40494 17.375 3.75012 17.375H16.2501C16.5953 17.375 16.8751 17.0952 16.8751 16.75V8C16.8751 7.65482 16.5953 7.375 16.2501 7.375Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.87512 7.375V4.875C6.87512 4.0462 7.20436 3.25134 7.79041 2.66529C8.37646 2.07924 9.17132 1.75 10.0001 1.75C10.8289 1.75 11.6238 2.07924 12.2098 2.66529C12.7959 3.25134 13.1251 4.0462 13.1251 4.875V7.375"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_322_48343">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.00012207 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
