import { emptyApi } from "./emptyApi";

export const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    showNotificationsNudge: build.query<
      { show_nudge: boolean; browser_blocked: boolean },
      void
    >({
      query: () => {
        return {
          url: `/api/inkle/pigeon/nudge/`,
        };
      },
      providesTags: () => [{ type: "NOTIFICATION" }],
    }),

    addNotificationNudgeEvent: build.mutation<
      {
        accepted: boolean;
        device_type: string;
        profile_id: string;
      },
      {
        accepted: boolean;
        deviceType?: string;
        deviceToken?: string;
        browserBlocked?: boolean;
      }
    >({
      query: ({ deviceType, accepted, deviceToken, browserBlocked }) => {
        return {
          url: `/api/inkle/pigeon/nudge/`,
          body: {
            accepted,
            device_type: deviceType,
            device_token: deviceToken,
            browser_blocked: browserBlocked,
          },
          method: "POST",
        };
      },
      invalidatesTags: () => [{ type: "NOTIFICATION" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useShowNotificationsNudgeQuery,
  useAddNotificationNudgeEventMutation,
} = extendedApi;
