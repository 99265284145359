import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { CreateRule } from "components/RuleEngine/CreateRule";
import { useModal } from "hooks/useModal";
import { useDispatch } from "react-redux";
import { setRuleInfo } from "store/slices/ruleEngine";
import { ModalProps } from "types/utils/modal";

export const RuleConfirmation = ({
  isOpen,
  close,
  deselectRows,
}: ModalProps & { deselectRows?: () => void }) => {
  const dispatch = useDispatch();

  const {
    isOpen: isOpenCreateRule,
    open: openCreateRule,
    close: closeCreateRule,
  } = useModal();

  const resetState = () => {
    deselectRows?.();
    dispatch(setRuleInfo({ categoryId: "", merchantNames: "" }));
  };

  const onSkip = () => {
    close();
    resetState();
  };

  const onCloseCreateRule = () => {
    closeCreateRule();
    resetState();
  };

  return (
    <div>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Create a rule</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-text-text-60">
            Would you like to create a rule to categorise such transactions in
            the future?
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button onClick={onSkip}>Skip for now</Button>
            <Button customType="primary" onClick={openCreateRule}>
              Create rule
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      <CreateRule isOpen={isOpenCreateRule} close={onCloseCreateRule} />
    </div>
  );
};
