import * as FILE_TYPE from "constants/fileTypes";
import FileCsvType from "../static/images/FileCsvType.svg";
import FileDocType from "../static/images/FileDocType.svg";
import FileJpgType from "../static/images/FileJpgType.svg";
import FilePdfType from "../static/images/FilePdfType.svg";
import FileXlsType from "../static/images/FileXlsType.svg";
import FolderType from "../static/images/FolderType.svg";
import LockedFolder from "../static/images/LockedFolder.svg";
import OtherFilesType from "../static/images/OtherFilesType.svg";
import MultipFileType from "static/images/MultiFiles.svg";

export const FileIcon = ({ fileType, width, height }) => {
  const checkFileExtension = (fileType) => {
    if ([FILE_TYPE.PDF].includes(fileType)) return "PDF";
    else if (
      [
        FILE_TYPE.IMAGE,
        FILE_TYPE.SVG,
        FILE_TYPE.JPG,
        FILE_TYPE.JPEG,
        FILE_TYPE.PNG,
        FILE_TYPE.GIF,
      ].includes(fileType)
    )
      return "IMAGE";
    else if ([FILE_TYPE.CSV].includes(fileType)) return "CSV";
    else if ([FILE_TYPE.DOC].includes(fileType)) return "DOC";
    else if (
      [
        FILE_TYPE.EXCEL,
        FILE_TYPE.XLSX,
        FILE_TYPE.XLSM,
        FILE_TYPE.XLSB,
        FILE_TYPE.XML,
        FILE_TYPE.XLR,
        FILE_TYPE.XLA,
      ].includes(fileType)
    )
      return "EXCEL";
    else if ([FILE_TYPE.FOLDER].includes(fileType)) return "FOLDER";
    else if (FILE_TYPE.LOCKED_FOLDER === fileType) return "LOCKED_FOLDER";
    else if ([FILE_TYPE.MULTI_FILES].includes(fileType)) return "MULTI_FILES";
    else return;
  };

  switch (checkFileExtension(fileType)) {
    case "PDF":
      return (
        <img
          src={FilePdfType}
          alt="Pdf"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    case "IMAGE":
      return (
        <img
          src={FileJpgType}
          alt="img"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    case "CSV":
      return (
        <img
          src={FileCsvType}
          alt="csv"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    case "DOC" || "DOCX" || "TXT" || "PPT" || "PPTX":
      return (
        <img
          src={FileDocType}
          alt="doc"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    case "EXCEL":
      return (
        <img
          src={FileXlsType}
          alt="excel"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    case "FOLDER":
      return (
        <img
          src={FolderType}
          alt="folder"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    case "LOCKED_FOLDER":
      return (
        <img
          src={LockedFolder}
          alt="folder"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    case "MULTI_FILES":
      return (
        <img
          src={MultipFileType}
          alt="multiple files"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );

    default:
      return (
        <img
          src={OtherFilesType}
          alt="file"
          className="t-select-none"
          width={width}
          height={height}
          draggable="false"
        />
      );
  }
};
