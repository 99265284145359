import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { FileObject } from "types/Models/fileObject";
import { REFERRAL_STATUS } from "dictionaries";

type ValidateReferralResult = {
  name: string;
  referral_code: string;
};

export type MyReferrals = {
  referee_email: string;
  referral_date: string;
  referral_status: keyof typeof REFERRAL_STATUS;
  reward_status: string | null;
  uuid: string;
};

type GetReferralResult = Pagination & {
  my_referrals: MyReferrals[];
};

export type CreditLedger = Pagination & {
  total_credits: number;
} & {
  company_group_credits_ledger: {
    created_date: string;
    expiry_date: string;
    credits: number;
    operation: "CREDIT" | "DEBIT";
    event_type: "PURCHASED" | "TASK";
    description: string;
    receipt?: {
      receipt_document: FileObject;
      receipt_filename: string;
    };
    tasks?: {
      task_title: string;
      task_id: string;
    }[];
  }[];
};

export const refferalApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    validateReferral: build.query<
      ValidateReferralResult,
      { referralCode: string }
    >({
      query: ({ referralCode }) => {
        let queryUrl = qs.stringify(
          {
            ref: referralCode,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/users/signup/${queryUrl}`,
        };
      },
    }),
    sendReferralEmail: build.mutation({
      query: ({ email }) => ({
        url: `/api/inkle/referral/send_referral_email/`,
        method: "post",
        body: { email },
      }),
      invalidatesTags: (_result, _error) => {
        return [{ type: "Referral", id: "LIST" }];
      },
    }),
    getReferral: build.query<GetReferralResult, { groupId: string }>({
      query: ({ groupId }) =>
        `/api/inkle/referral/group/${groupId}/my_referrals/`,
      providesTags: (result) => {
        const { my_referrals = [] } = result || {};
        return result
          ? [
              ...my_referrals?.map(({ uuid }) => ({
                type: "Referral" as "Referral",
                id: uuid,
              })),
              { type: "Referral" as "Referral", id: "LIST" },
            ]
          : [{ type: "Referral" as "Referral", id: "LIST" }];
      },
    }),
    getCredits: build.query<
      CreditLedger,
      { groupId: string; pageNum?: number | null }
    >({
      query: ({ groupId, pageNum }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/billing/group/${groupId}/credits/ledger/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["CREDITS"] : []),
    }),
  }),

  overrideExisting: false,
});

export const {
  useValidateReferralQuery,
  useSendReferralEmailMutation,
  useGetReferralQuery,
  useGetCreditsQuery,
  useLazyGetCreditsQuery,
} = refferalApis;
