export const roundToFixedDecimal = ({
  numberToRound,
  decimalDigit = 2,
}: {
  numberToRound: number;
  decimalDigit?: number;
}) => {
  const multiplier = Math.pow(10, decimalDigit);
  return Math.round(numberToRound * multiplier) / multiplier;
};
