import * as React from "react";
export const FixedAsset = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width={16} height={16} fill="#1E1E1E" />
    <g id="Fixed Asset">
      <path
        d="M-1844 -3394C-1844 -3395.1 -1843.1 -3396 -1842 -3396H12959C12960.1 -3396 12961 -3395.1 12961 -3394V8193C12961 8194.1 12960.1 8195 12959 8195H-1842C-1843.1 8195 -1844 8194.1 -1844 8193V-3394Z"
        fill="#404040"
      />
      <path
        d="M-1842 -3395H12959V-3397H-1842V-3395ZM12960 -3394V8193H12962V-3394H12960ZM12959 8194H-1842V8196H12959V8194ZM-1843 8193V-3394H-1845V8193H-1843ZM-1842 8194C-1842.55 8194 -1843 8193.55 -1843 8193H-1845C-1845 8194.66 -1843.66 8196 -1842 8196V8194ZM12960 8193C12960 8193.55 12959.6 8194 12959 8194V8196C12960.7 8196 12962 8194.66 12962 8193H12960ZM12959 -3395C12959.6 -3395 12960 -3394.55 12960 -3394H12962C12962 -3395.66 12960.7 -3397 12959 -3397V-3395ZM-1842 -3397C-1843.66 -3397 -1845 -3395.66 -1845 -3394H-1843C-1843 -3394.55 -1842.55 -3395 -1842 -3395V-3397Z"
        fill="white"
        fillOpacity={0.1}
      />
      <g id="Responsive Frame">
        <rect
          width={1280}
          height={832}
          transform="translate(-16 -430)"
          fill="white"
        />
        <g id="Navigation Sidebar">
          <rect
            width={240}
            height={832}
            transform="translate(-16 -430)"
            fill="#FAFAFB"
          />
          <g id="Frame 812307">
            <g id="Frame 812303">
              <g id="Nav item">
                <mask id="path-3-inside-1_111_239717" fill="white">
                  <path d="M-16 -8H224V24H-16V-8Z" />
                </mask>
                <path d="M-16 -8H224V24H-16V-8Z" fill="#F3F3F5" />
                <path
                  d="M-13 24V-8H-19V24H-13Z"
                  fill="#784EFF"
                  mask="url(#path-3-inside-1_111_239717)"
                />
                <g id="Frame 812299">
                  <g id="Laptop" clipPath="url(#clip0_111_239717)">
                    <path
                      id="Vector"
                      d="M2.5 11V4.5C2.5 4.23478 2.60536 3.98043 2.79289 3.79289C2.98043 3.60536 3.23478 3.5 3.5 3.5H12.5C12.7652 3.5 13.0196 3.60536 13.2071 3.79289C13.3946 3.98043 13.5 4.23478 13.5 4.5V11"
                      stroke="currentColor"
                      strokeWidth={1.4}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M1.5 11H14.5V12C14.5 12.2652 14.3946 12.5196 14.2071 12.7071C14.0196 12.8946 13.7652 13 13.5 13H2.5C2.23478 13 1.98043 12.8946 1.79289 12.7071C1.60536 12.5196 1.5 12.2652 1.5 12V11Z"
                      stroke="currentColor"
                      strokeWidth={1.4}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_3"
                      d="M9 5.5H7"
                      stroke="currentColor"
                      strokeWidth={1.4}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_111_239717">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
