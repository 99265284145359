import React from "react";
import PreIncorporateIcon from "static/images/PreIncorporateIcon.svg";

const PreIncorporateCard = () => {
  return (
    <div className="t-flex t-h-full t-w-full t-flex-wrap t-justify-between t-overflow-hidden t-relative  t-border t-border-solid t-border-neutral-10 t-rounded-lg t-p-5 t-text-text-100">
      <div>
        <div className="t-text-h5">What to do before incorporation?</div>
        <div className="t-mb-5 t-text-body t-w-9/12">
          Access resources & tools curated specifically for early founders.
        </div>
      </div>
      <img
        src={PreIncorporateIcon}
        alt="PreIncorporateIcon"
        className="t-absolute t-w-[201px] t-h-[123px] t-bottom-0 t-right-0"
      />
    </div>
  );
};

export default PreIncorporateCard;
