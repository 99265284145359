import { object, string } from "yup";

export const groupDetails = object({
  leadfounder_first_name: string()
    .min(1, "Min. 1 character")
    .required("Required"),
  leadfounder_last_name: string()
    .min(1, "Min. 1 character")
    .required("Required"),
  leadfounder_email: string()
    .email("Should be a valid email")
    .required("Email is required"),
  group_name: string().min(3, "Min. 3 characters").required("Required"),
});

export const entityDetails = object({
  company_name: string().min(3, "Min. 3 characters").required("Required"),
  country: string().required("Required"),
  state_id: string().required("Required"),
  formation_type_id: string().required("Required"),
});

export const emailDetail = object({
  referrer_email: string().required("Please select a referrer"),
});
