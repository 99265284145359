export const House = ({
  fill = "none",
  stroke = "currentColor",
  size = "24",
}: {
  fill?: string;
  stroke?: string;
  size?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9726 19.7264V15.461C13.9726 15.2724 13.8979 15.0916 13.7648 14.9583C13.6317 14.8249 13.4513 14.7501 13.2632 14.7501H10.4254C10.2373 14.7501 10.0568 14.8249 9.92379 14.9583C9.79075 15.0916 9.716 15.2724 9.716 15.461V19.7264C9.716 19.9149 9.64126 20.0958 9.50821 20.2291C9.37517 20.3624 9.19472 20.4373 9.00656 20.4373H4.74996C4.56181 20.4373 4.38136 20.3624 4.24831 20.2291C4.11527 20.0958 4.04053 19.9149 4.04053 19.7264V11.5065C4.04212 11.4081 4.06333 11.3111 4.10293 11.221C4.14253 11.131 4.19971 11.0498 4.27109 10.9822L11.3654 4.52183C11.4962 4.40194 11.667 4.33545 11.8443 4.33545C12.0215 4.33545 12.1924 4.40194 12.3231 4.52183L19.4175 10.9822C19.4889 11.0498 19.5461 11.131 19.5857 11.221C19.6252 11.3111 19.6464 11.4081 19.6481 11.5065V19.7264C19.6481 19.9149 19.5734 20.0958 19.4403 20.2291C19.3072 20.3624 19.1267 20.4373 18.9387 20.4373H14.682C14.4939 20.4373 14.3135 20.3624 14.1804 20.2291C14.0473 20.0958 13.9726 19.9149 13.9726 19.7264Z"
      stroke={stroke}
      strokeWidth="1.67273"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
