import Loader from "components/design/loader";
import { update } from "cypress/types/lodash";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  useCreateStakeholderUpdateMutation,
  useGetAllStakeholderUpdatesQuery,
  useLazyGetAllStakeholderUpdatesQuery,
} from "store/apis/stakeholderUpdate";

export const CreateNewInvestorUpdate = () => {
  const [getAllStakeholderUpdates] = useLazyGetAllStakeholderUpdatesQuery();
  const [createStakeholderUpdate] = useCreateStakeholderUpdateMutation();
  const group = useCurrentGroup();
  const history = useHistory();

  useEffect(() => {
    const createNewStakeholderUpdate = async (groupId: string) => {
      const updates = await getAllStakeholderUpdates({
        groupId: groupId,
      }).unwrap();
      const newTitle = `Update ${updates.total_count + 1}`;

      const newUpdate = await createStakeholderUpdate({
        groupId,
        title: newTitle,
      }).unwrap();

      history.replace(`/community/investor-updates/${newUpdate.uuid}`);
    };

    if (group.uuid) {
      createNewStakeholderUpdate(group.uuid);
    }
  }, [group.uuid]);

  return (
    <div className="t-w-full t-h-full t-flex t-items-center t-justify-center">
      <Loader />
    </div>
  );
};
