import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ProductType = {
  filings?: boolean;
  bookkeeping?: boolean;
  subscriptions?: boolean;
};

export type SelectedSeason = {
  2023?: boolean;
  2022?: boolean;
  2021?: boolean;
  2020?: boolean;
};

export type PaymentStatus = { paid?: boolean; unpaid?: boolean };

export type BillingActionPayload = {
  name: string;
  type: keyof InitialState["filters"];
  checked: boolean;
};

type InitialState = {
  filters: {
    selectedSeason?: SelectedSeason | null;
    paymentStatus?: PaymentStatus | null;
    searchTerm?: string | null;
    productType?: ProductType | null;
  };
};

const initialState: InitialState = {
  filters: {
    selectedSeason: null,
    paymentStatus: null,
    searchTerm: null,
    productType: null,
  },
};

export const billingFilters = createSlice({
  name: "billingFilters",
  initialState,
  reducers: {
    setBillingFilters: (state, action: PayloadAction<BillingActionPayload>) => {
      const { name, type, checked } = action.payload;

      let filtersObject = state.filters[type] as
        | SelectedSeason
        | PaymentStatus
        | ProductType;

      filtersObject = { ...filtersObject, ...{ [name]: checked } };

      state.filters = { ...state.filters, [type]: filtersObject };
    },
    resetBillingFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export const { setBillingFilters } = billingFilters.actions;

export default billingFilters.reducer;
