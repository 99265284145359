import { useEffect } from "react";
import { useChannelActionContext } from "stream-chat-react";

export const JumpToMessage = ({ messageId }: { messageId: string }) => {
  const { jumpToMessage } = useChannelActionContext();

  useEffect(() => {
    if (messageId) {
      try {
        jumpToMessage(messageId);
      } catch (error) {}
    }
  }, [messageId]);

  return <></>;
};
