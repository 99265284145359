export const ChatCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 4.50006C1 3.94778 1.44772 3.50006 2 3.50006H12C12.5523 3.50006 13 3.94778 13 4.50006V10.5001C13 11.0523 12.5523 11.5001 12 11.5001H2C1.44772 11.5001 1 11.0523 1 10.5001V4.50006ZM3.47055 6.40929C3.47055 6.21437 3.62856 6.05635 3.82349 6.05635H9.47054C9.66547 6.05635 9.82349 6.21437 9.82349 6.40929C9.82349 6.60421 9.66547 6.76223 9.47054 6.76223H3.82349C3.62856 6.76223 3.47055 6.60421 3.47055 6.40929ZM3.82349 8.23812C3.62856 8.23812 3.47055 8.39614 3.47055 8.59106C3.47055 8.78599 3.62856 8.94401 3.82349 8.94401H7.3529C7.54782 8.94401 7.70584 8.78599 7.70584 8.59106C7.70584 8.39614 7.54782 8.23812 7.3529 8.23812H3.82349Z"
        fill="currentColor"
      />
    </svg>
  );
};
