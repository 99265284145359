import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ReconciliationState = {
  bankAccountId: string;
  includedCredits: number;
  includedDebits: number;
  duplicateTransactionsList: any;
  csvTxnsToBeAddedList: any;
  manualTxnsToBeAddedList: any;
};

const initialState: ReconciliationState = {
  bankAccountId: "",
  includedCredits: 0,
  includedDebits: 0,
  duplicateTransactionsList: [],
  csvTxnsToBeAddedList: [],
  manualTxnsToBeAddedList: [],
};

export const reconciliation = createSlice({
  name: "reconciliation",
  initialState,
  reducers: {
    setReconcileBankAccountId: (state, action: PayloadAction<string>) => {
      state.bankAccountId = action.payload;
    },
    setIncludedCredits: (state, action: PayloadAction<number>) => {
      state.includedCredits = action.payload;
    },
    setIncludedDebits: (state, action: PayloadAction<number>) => {
      state.includedDebits = action.payload;
    },
    setDuplicateTransaction: (state, action: PayloadAction<any>) => {
      state.duplicateTransactionsList = action.payload;
    },
    setCSVTxnsToBeAdded: (state, action: PayloadAction<any>) => {
      state.csvTxnsToBeAddedList = action.payload;
    },
    setManualTxnsToBeAdded: (state, action: PayloadAction<any>) => {
      state.manualTxnsToBeAddedList = action.payload;
    },
  },
});

export const {
  setReconcileBankAccountId,
  setIncludedCredits,
  setIncludedDebits,
  setDuplicateTransaction,
  setCSVTxnsToBeAdded,
  setManualTxnsToBeAdded,
} = reconciliation.actions;

export default reconciliation.reducer;
