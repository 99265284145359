import React from "react";

export const BalanceSheetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_3699_1875)">
        <path
          d="M12.0833 1.66675H4.99998C4.55795 1.66675 4.13403 1.84234 3.82147 2.1549C3.50891 2.46746 3.33331 2.89139 3.33331 3.33341V16.6667C3.33331 17.1088 3.50891 17.5327 3.82147 17.8453C4.13403 18.1578 4.55795 18.3334 4.99998 18.3334H15C15.442 18.3334 15.8659 18.1578 16.1785 17.8453C16.4911 17.5327 16.6666 17.1088 16.6666 16.6667V6.25008L12.0833 1.66675Z"
          stroke="#5DADE2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 1.66675V6.66675H16.6667"
          stroke="#5DADE2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66669 10.8334H8.33335"
          stroke="#5DADE2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66669 14.1667H8.33335"
          stroke="#5DADE2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 10.8334H13.3334"
          stroke="#5DADE2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 14.1667H13.3334"
          stroke="#5DADE2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3699_1875">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
