import { PersonaModal } from "components/AutoFill/PersonaModal";
import { FormikValues } from "formik";
import { useToast } from "hooks/useToast";
import {
  useCreateAutofillMutation,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import { AutofillComponentProps } from "types/Models/bankAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { BackendError } from "types/utils/error";

export const AddEditPersona = ({
  isOpenAdd,
  isOpenEdit,
  autofillKey,
  initialValue: detailsForEdit,
  groupId,
  onSuccess,
  onClose,
  isEditFlow,
  entityId,
}: AutofillComponentProps & {
  initialValue?: PersonaAutofill;
  onSuccess: (value: PersonaAutofill) => void;
  entityId: string;
}) => {
  const [createAutofill, { isLoading: isAdding }] = useCreateAutofillMutation();
  const [editAutofill, { isLoading: isUpdating }] = useUpdateAutofillMutation();
  const { alertToast, successToast } = useToast();

  const addPersona = async (values: FormikValues) => {
    try {
      const persona = (await createAutofill({
        groupId,
        autofillKey,
        fields: values,
        entityId,
      }).unwrap()) as PersonaAutofill;
      successToast({ message: "Persona added successfully!" });
      onSuccess(persona);
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      onClose();
    }
  };

  const editPersona = async (values: FormikValues) => {
    try {
      const persona = (await editAutofill({
        groupId,
        autofillKey,
        fields: values,
        uuid: values.id,
        entityId,
      }).unwrap()) as PersonaAutofill;
      successToast({ message: "Persona updated successfully!" });
      onSuccess(persona);
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      onClose();
    }
  };

  return (
    <PersonaModal
      entityId={entityId}
      isLoading={isUpdating || isAdding}
      initialValues={
        Boolean(detailsForEdit?.uuid) && isEditFlow
          ? { ...detailsForEdit, id: detailsForEdit?.uuid }
          : {}
      }
      onSubmit={Boolean(detailsForEdit?.uuid) ? editPersona : addPersona}
      onClose={onClose}
      open={isOpenAdd || Boolean(isOpenEdit)}
    />
  );
};
