import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { Badge } from "components/Task/Badge";
import { OPEN_ITEMS_CARD_OPENED } from "constants/analyticsEvents";
import { OPEN_ITEM_STATUS } from "dictionaries";
import { useAnalytics } from "hooks/useAnalytics";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { openCalrifyModal } from "store/slices/openItem";
import { OpenItemStatus } from "types/Models/openItem";

type IReviewMessageChatCardProps = {
  customData: { status: OpenItemStatus; header: string; csv_id: string };
  messageId: string;
};

export const OpenItemCard: FC<IReviewMessageChatCardProps> = ({
  customData,
  messageId,
}) => {
  const dispatch = useDispatch();
  const { status, header, csv_id } = customData;
  const { trackEvent } = useAnalytics();

  const openClarifyModal = () => {
    trackEvent(OPEN_ITEMS_CARD_OPENED);
    dispatch(openCalrifyModal({ csvId: csv_id, messageId: messageId, status }));
  };
  return (
    <ChatCard.Root>
      <ChatCard.Header
        status={
          //@ts-ignore
          <Badge type={OPEN_ITEM_STATUS[status]}>
            <div className="first-letter:!t-uppercase t-lowercase">
              {status}
            </div>
          </Badge>
        }
      >
        {header}
      </ChatCard.Header>
      <ChatCard.Footer>
        <Button customType="text" onClick={openClarifyModal}>
          {status === "SUBMITTED" ? "Open" : "Clarify"}
        </Button>
      </ChatCard.Footer>
    </ChatCard.Root>
  );
};
