import { array, object, string } from "yup";

const notRequired = {
  field: string().notRequired(),
  conditionFunction: string().notRequired(),
  conditionValue: string().notRequired(),
};

const required = {
  field: string().required(" "),
  conditionFunction: string().required(" "),
  conditionValue: string().required(" "),
};

export const categorisationRuleSchema = () =>
  object({
    ruleConditionData: array().of(
      object().when({
        is: (value: Record<string, string>) => {
          return Object.entries(value).every(([key, value]) => !Boolean(value));
        },
        then: object(notRequired),
        otherwise: object(required),
      })
    ),
    assigned_category_id: string().required("Please select category"),
    startDate: string().required("Please select date").nullable(),
    endDate: string().required("Please select date").nullable(),
    transactionsFor: string().required("Please select date"),
    name: string().required("Please enter rule name"),
  });

export const editCategorisationRuleSchema = () =>
  object({
    ruleConditionData: array().of(
      object().when({
        is: (value: Record<string, string>) => {
          return Object.entries(value).every(([key, value]) =>
            key === "uuid" ? true : !Boolean(value)
          );
        },
        then: object(notRequired),
        otherwise: object(required),
      })
    ),
    assigned_category_id: string().required("Please select category"),
    startDate: string().required("Please select date").nullable(),
    endDate: string().required("Please select date").nullable(),
    name: string().required("Please enter rule name"),
  });
