import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useSelector } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import { useMergeMerchantsMutation } from "store/apis/vendors";
import { RootState } from "store/store";
import { Vendor } from "types/Models/vendors";

type MergeMerchantProps = {
  show: boolean;
  closeModal: () => void;
  groupId: string;
  resetSelectedMerchant: () => void;
  merchants: Vendor[];
};

export const MergeMerchants = ({
  show,
  closeModal,
  groupId,
  resetSelectedMerchant,
  merchants,
}: MergeMerchantProps) => {
  const { alertToast, successToast } = useToast();
  const { merchantsToMerge } = useSelector((state: RootState) => state.vendors);
  const [mergeMerchants, { isLoading: isMerging }] =
    useMergeMerchantsMutation();
  const query = useQuery();
  let entityId = query.get("entity");
  const defaultSelectedMerchant = merchantsToMerge.map((merchantId) => {
    const matchedMerchant = merchants.find(
      ({ uuid, name }) => merchantId === uuid
    );
    return {
      label: matchedMerchant?.name || "",
      value: matchedMerchant?.uuid || "",
    };
  });

  const mergeMultipleMerchants = async (val: any) => {
    try {
      const { merchants, primaryMerchant } = val;
      const child_merchant_ids = merchants.filter(
        (merchantId: string) => merchantId !== primaryMerchant
      );

      await mergeMerchants({
        group_id: groupId,
        entity_id: entityId!,
        parent_merchant_id: primaryMerchant,
        child_merchant_ids,
      }).unwrap();
      successToast({ message: "Vendors merged successfully" });
      resetSelectedMerchant();
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Formik
          validateOnChange={false}
          enableReinitialize
          initialValues={{
            merchants: merchantsToMerge,
            primaryMerchant: "",
          }}
          onSubmit={mergeMultipleMerchants}
        >
          {({ values, submitForm, setFieldValue }) => {
            const primaryMerchantOptions = merchants
              .filter(({ uuid }) =>
                values.merchants.find((merchant) => merchant === uuid)
              )
              .map(({ uuid, name }) => ({ label: name, value: uuid }));

            const isValidPrimaryMerchant = values.merchants.find(
              (merchant) => merchant === values.primaryMerchant
            );

            return (
              <Form>
                <Modal.Content>
                  <Modal.Header>
                    <div>
                      <Modal.Title>Merge Vendors</Modal.Title>
                    </div>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body>
                    <Combobox
                      menuPortalTarget={document.body}
                      components={{
                        DropdownIndicator: () => null,
                        ClearIndicator: () => null,
                      }}
                      onChange={(
                        merchants:
                          | SingleValue<OptionData>
                          | MultiValue<OptionData>
                      ) => {
                        if (merchants instanceof Array) {
                          const isPrimaryMerchantExists = merchants.find(
                            ({ value }: { value: string }) =>
                              value === values.primaryMerchant
                          );

                          if (!isPrimaryMerchantExists) {
                            setFieldValue("primaryMerchant", "");
                          }
                        }
                      }}
                      defaultValue={defaultSelectedMerchant}
                      isMulti
                      withForm
                      name="merchants"
                      label="Selected vendors"
                      options={merchants?.map((merchant) => ({
                        label: merchant.name,
                        value: merchant.uuid,
                      }))}
                    />
                    <div className="t-mt-4">
                      <Combobox
                        menuPortalTarget={document.body}
                        components={{
                          DropdownIndicator: () => null,
                          ClearIndicator: () => null,
                        }}
                        withForm
                        label="Primary vendors"
                        name="primaryMerchant"
                        options={primaryMerchantOptions?.map((merchant) => ({
                          label: merchant.label,
                          value: merchant.value,
                        }))}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="t-flex t-justify-end t-gap-3">
                      <Button onClick={closeModal}>Cancel</Button>
                      <Button
                        disabled={
                          isMerging ||
                          !Boolean(isValidPrimaryMerchant) ||
                          values.merchants.length <= 1
                        }
                        customType="primary"
                        isLoading={isMerging}
                        type="submit"
                        onClick={submitForm}
                      >
                        Merge Vendors
                      </Button>
                    </div>
                  </Modal.Footer>
                </Modal.Content>
              </Form>
            );
          }}
        </Formik>
      </Modal.Portal>
    </Modal.Root>
  );
};
