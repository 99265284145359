import BookkeepingAccordionContent from "components/BookkeepingAccordion/BookkeepingAccordionContent";
import Modal from "components/DesignSystem/Modal/Modal";
import { DocumentPreviewModal } from "components/PreviewModal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useGetMonthlyInvoicesQuery } from "store/apis/financialClosing";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { closeInvoiceModal } from "store/slices/financialClosing";
import { RootState } from "store/store";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";

export const InvoiceModal = () => {
  const dispatch = useDispatch();
  const openPreview = useDocPreview();
  const { alertToast } = useToast();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const monthId = useSelector(
    (state: RootState) => state.financialClosing.invoiceModal
  );

  const { data: invoices } = useGetMonthlyInvoicesQuery(
    {
      groupId,
      entityId,
      monthId,
    },
    { skip: !groupId || !entityId || !monthId, refetchOnMountOrArgChange: true }
  );

  const onDownload = async ({ fileId }: { fileId: string }) => {
    try {
      const { data } = await getPreviewUrl({ groupId, fileId });
      openLink(data?.download_url);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onClose = () => {
    dispatch(closeInvoiceModal());
  };

  return (
    <>
      <Modal.Root open={Boolean(monthId)} onOpenChange={onClose}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Invoices</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-flex t-flex-col t-gap-4">
            {Object.values(invoices || {})?.map((invoice) => {
              return (
                invoice?.uuid && (
                  <BookkeepingAccordionContent.SummaryReport
                    onPreview={() => openPreview(invoice?.document?.uuid!)}
                    icon={
                      <FileIcon
                        fileType={invoice?.document?.file_type}
                        width="30px"
                        height="30px"
                      />
                    }
                    label={invoice?.short_name!}
                    onDownload={() =>
                      onDownload({ fileId: invoice?.document?.uuid! })
                    }
                  />
                )
              );
            })}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <DocumentPreviewModal />
    </>
  );
};
