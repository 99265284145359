import { axiosInstance } from "./apiInstance";
import qs from "qs";

export type CRMViewFilter = { viewFilter: string };

export const groupStage = async ({ viewFilter }: CRMViewFilter) => {
  let url = `/api/inkle/company/stages/`;

  let queryUrl = qs.stringify(
    {
      crm_view_filter: viewFilter,
    },
    {
      addQueryPrefix: true,
    }
  );
  url += queryUrl;
  const { data } = await axiosInstance.get<{ data: [] }>(url);
  return data;
};
