import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/store";

const getVendorsFilter = (state: RootState) => state.vendorsFilter;

export const getVendorsFilterStatus = createSelector(
  [getVendorsFilter],
  ({ filters }) => {
    const season = Object.values(filters).filter(
      ({ type }) => type === "season"
    );
    const vendorsType = Object.values(filters).filter(
      ({ type }) => type === "vendorsType"
    );

    const appliedFiltersCount =
      [...season, ...vendorsType].filter(({ value }) => {
        if (Array.isArray(value) && value?.length === 0) {
          return null;
        }
        return value;
      })?.length - 1;

    const capsuleFilters = Object.values(filters).filter(({ value }) => {
      if (Array.isArray(value) && value?.length === 0) {
        return null;
      }
      return value;
    });

    return {
      appliedFiltersCount,
      capsuleFilters,
      filters: {
        season,
        vendorsType,
      },
    };
  }
);
