import NoDataAvailable from "components/design/noDataAvailable";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { PlusIcon } from "components/icons/PlusIcon";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import React from "react";
import { useParams } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetAllShareholdersQuery } from "store/apis/Shareholders";
import { Shareholder } from "types/Models/Shareholders";
import { AddShareholder } from "./AddShareholder";
import ShareholderAction from "./ShareholderAction";

export const ShareHolders = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { entityId } = useParams<{ entityId: string }>();
  const addShareholderModal = useModal();
  const {
    data: shareholders = [],
    isLoading,
    isSuccess,
  } = useGetAllShareholdersQuery(
    { entityId, groupId },
    { skip: !groupId || !entityId }
  );

  const createColumn = createColumnHelper<Shareholder>();

  const columns = [
    createColumn.accessor("name", {
      cell: (info) => (
        <div className="t-text-text-60">{info.getValue() || "-"}</div>
      ),
      header: () => "NAME",
      size: 20,
    }),

    createColumn.accessor("ownership_stake", {
      cell: (info) => (
        <div className="t-text-text-60">{`${info.getValue()}%` || "-"}</div>
      ),
      header: () => "OWNERSHIP",
      size: 10,
    }),

    createColumn.accessor("tin", {
      header: () => "TIN",
      cell: (info) => (
        <div className="t-text-text-60">{info.getValue() || "-"}</div>
      ),
      size: 20,
    }),

    createColumn.accessor("country.name", {
      cell: (info) => (
        <div className="t-text-text-60">{info.getValue() || "-"}</div>
      ),
      header: () => "COUNTRY",
      size: 20,
    }),

    createColumn.accessor("address.autofill_string", {
      header: () => "ADDRESS",
      cell: (info) => (
        <div className="t-w-full md:t-max-w-80 t-max-w-40 t-truncate t-text-ellipsis t-text-text-60">
          {info.getValue() || "-"}
        </div>
      ),
      size: 25,
    }),

    createColumn.display({
      size: 5,
      header: " ",
      cell: (info) => <ShareholderAction shareholder={info.row.original} />,
    }),
  ];

  const table = useReactTable({
    data: shareholders,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const isEmpty = !shareholders || shareholders.length === 0;

  return (
    <div className="t-flex t-flex-col">
      <div className="t-mb-5 t-flex t-items-center t-justify-between">
        <div className="t-text-subtitle">Shareholders</div>
        <Button
          size="small"
          customType="primary"
          onClick={addShareholderModal.open}
        >
          <div className="t-flex t-items-center t-gap-2">
            <PlusIcon color="currentColor" />
            <div>Add</div>
          </div>
        </Button>
      </div>
      <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
        <Async.Empty>
          <NoDataAvailable />
        </Async.Empty>
        <Async.Success>
          <TableUI size="regular" table={table} />
        </Async.Success>
      </Async.Root>
      {addShareholderModal.isOpen && (
        <AddShareholder {...addShareholderModal} />
      )}
    </div>
  );
};
