import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

export default function Timer({
  setShowTimer,
  duration,
  key,
  originFromSignup,
}) {
  function updateShowTimer() {
    setShowTimer(false);
    return {
      shouldRepeat: false,
      delay: 1,
    };
  }
  return (
    <div className="timerWrapper">
      <CountdownCircleTimer
        key={key}
        isPlaying
        duration={duration}
        colors="blue"
        size="30"
        strokeWidth="0"
        onComplete={updateShowTimer}
      >
        {({ remainingTime }) => (
          <span className="remainingTimer t-text-body-sm t-font-normal t-text-text-30">
            Didn't receive the OTP? Retry in 00:{remainingTime}
          </span>
        )}
      </CountdownCircleTimer>
    </div>
  );
}
