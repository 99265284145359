import DashboardContainer from "components/dashboard/DashboardContainer";
import { Button } from "components/DesignSystem/Button/Button";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { debounce } from "utils/debouncing";
import { FilterDropdown } from "./filterDropdown";
import { SortDropDown } from "./sortDropDown";

export const MailboxHeader = ({
  openAddMailModal,
  searchTerm,
  checkedBoxes,
  setCheckedBoxes,
  setSortType,
  sortType,
  openAddress,
  filters,
  paginationData,
  totalPages,
}) => {
  const query = useQuery();
  const { updateMultiple, update } = useUpdateQuery();
  const pageNumber = Number(query.get("page") || 1);

  const handleSearchChange = debounce((e) => {
    const { value } = e.target;
    if (value) {
      updateMultiple([
        { query: "search_term", value },
        { query: "page", value: 1 },
      ]);
    } else {
      updateMultiple([
        { query: "search_term", value: null },
        { query: "page", value: 1 },
      ]);
    }
  }, 200);

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setCheckedBoxes({ ...checkedBoxes, [name]: checked });
    if (checked) {
      updateMultiple([
        { query: name, value: checked },
        { query: "page", value: 1 },
      ]);
    } else {
      updateMultiple([
        { query: name, value: null },
        { query: "page", value: 1 },
      ]);
    }
  };

  const onPageNumChange = (currentPage) => {
    update({ query: "page", value: currentPage });
  };

  const {
    setTotalPage,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
  } = usePagination({
    pageNumber,
    onPageNumChange,
  });

  useEffect(() => {
    setTotalPage(totalPages);
  }, [totalPages]);

  return (
    <DashboardContainer.Header className="t-sticky t-top-0 t-z-header">
      <div className="t-bg-surface t-flex t-flex-col t-gap-5">
        <div className="t-flex t-gap-4 t-w-full t-items-center t-justify-between">
          <div className="t-w-1/2">
            <Search
              placeholder="Search..."
              block
              autoFocus
              onChange={handleSearchChange}
              defaultValue={searchTerm}
            />
          </div>

          <div className="t-flex t-gap-2">
            <Button onClick={openAddress} size="small">
              My Addresses
            </Button>
            <Button
              size="small"
              customType="primary"
              onClick={openAddMailModal}
            >
              &nbsp;
              <i className="fas fa-plus" />
              &nbsp; Add
            </Button>
          </div>
        </div>
        <div className="t-flex t-mb-4">
          <div className="t-flex t-gap-2">
            <FilterDropdown
              checkedBoxes={checkedBoxes}
              handleCheckBox={handleCheckBox}
              setCheckedBoxes={setCheckedBoxes}
              filters={filters}
            />
            <SortDropDown setSortType={setSortType} sortType={sortType} />
          </div>

          <div className="t-ml-auto">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </div>
      </div>
    </DashboardContainer.Header>
  );
};
