import { CALENDAR, FISCAL } from "constants/calendarTypes";
import { stringify } from "qs";
import {
  BooksQuote,
  MonthlyClosingBillingInfo,
  MonthlySummariesResponse,
  MonthlySummary,
  MontlyInvoice,
  SeasonMonthList,
  SeasonResponse,
} from "types/Models/overview";
import { Reports } from "components/FinancialClosing/AddSummaryModal";
import { emptyApi } from "./emptyApi";

export enum SEASON_TYPE {
  CALENDAR = "CALENDAR",
  FISCAL = "FISCAL",
}

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getBooksOnboardingStatus: build.query<
      {
        season_summary_created: boolean;
        bank_accounts_linked: boolean;
        is_books_product_onboarding_in_completed_state: boolean;
      },
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) =>
        `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/get_books_onboarding_status`,
      providesTags: () => [
        { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
        { type: "BooksOnboarding" as "BooksOnboarding", id: "STATUS_LIST" },
      ],
    }),

    getSeasonSummary: build.query<
      SeasonResponse,
      {
        groupId: string;
        entityId: string;
        uuid?: string;
        getUnpaidInvoices?: boolean;
      }
    >({
      query: ({ groupId, entityId, uuid, getUnpaidInvoices }) => {
        let queryUrl = stringify(
          {
            uuid,
            get_unpaid_invoices: getUnpaidInvoices,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/season_summary${queryUrl}`,
        };
      },
      providesTags: (result) => {
        const { uuid } = result || {};
        return result
          ? [
              { type: "BooksOnboarding", id: uuid },
              { type: "BooksOnboarding", id: "STATUS_LIST" },
            ]
          : [];
      },
    }),

    createSeasonSummary: build.mutation<
      SeasonResponse,
      {
        groupId: string;
        entityId: string;
        season_type?: SEASON_TYPE;
      }
    >({
      query: ({ groupId, entityId, season_type }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/season_summary`,
          body: { season_type },
          method: "POST",
        };
      },
      invalidatesTags: (_result, _error) => [
        { type: "BooksOnboarding", id: "STATUS_LIST" },
      ],
    }),

    updateSeasonSummary: build.mutation<
      SeasonResponse,
      {
        groupId: string;
        entityId: string;
        payload: any;
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/season_summary`,
          body: payload,
          method: "PATCH",
        };
      },
      invalidatesTags: (result) => {
        const { uuid } = result || {};
        return result
          ? [
              { type: "BooksOnboarding", id: uuid },
              { type: "BooksOverview", id: uuid },
              {
                type: "BooksOnboarding",
                id: "STATUS_LIST",
              },
            ]
          : [];
      },
    }),

    getTransactionMonthlySummaries: build.query<
      MonthlySummariesResponse[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => ({
        url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/list_transactions_monthly_summary`,
      }),
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ uuid }: { uuid: string }) => ({
                type: "BooksOverview" as const,
                id: uuid,
              })),
            ]
          : [];
      },
    }),

    getMonthSummary: build.query<
      MonthlySummary,
      { groupId: string; entityId: string; uuid: string }
    >({
      query: ({ groupId, entityId, uuid }) =>
        `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transactions_monthly_summary?uuid=${uuid}`,
      providesTags: (result) => {
        return result
          ? [{ type: "BooksOverview", id: result.season_summary }]
          : [];
      },
    }),

    updateMonthSummary: build.mutation<
      MonthlySummary,
      { groupId: string; entityId: string; payload: any }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/update_transactions_monthly_summary/`,
          body: payload,
          method: "PATCH",
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [{ type: "BooksOverview", id: result.season_summary }, "REPORTS"]
          : [];
      },
    }),

    markMonthCompleted: build.mutation<
      MonthlySummary,
      { groupId: string; entityId: string; uuid: string }
    >({
      query: ({ groupId, entityId, uuid }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transactions_monthly_summary?uuid=${uuid}&status=COMPLETED`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [{ type: "BooksOverview", id: result.season_summary }]
          : [];
      },
    }),

    deleteReport: build.mutation<
      null,
      {
        groupId: string;
        entityId: string;
        uuid: string;
        field: Reports;
        reportFrom: "update_transactions_monthly_summary" | "season_summary";
      }
    >({
      query: ({ groupId, entityId, uuid, field, reportFrom }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/${reportFrom}`,
          method: "DELETE",
          body: { uuid, field },
        };
      },
      invalidatesTags: () => [
        { type: "BooksOverview" },
        { type: "BooksOnboarding" },
      ],
    }),

    getMonthlyBillingDetails: build.query<
      MonthlyClosingBillingInfo,
      {
        groupId: string;
        entityId: string;
        monthId: string;
      }
    >({
      query: ({ groupId, entityId, monthId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/monthly_txn_summary_id/${monthId}/billing/`,
        };
      },
    }),
    getMonthlyInvoices: build.query<
      MontlyInvoice,
      {
        groupId: string;
        entityId: string;
        monthId: string;
      }
    >({
      query: ({ groupId, entityId, monthId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/monthly_txn_summary_id/${monthId}/invoices/`,
        };
      },
    }),

    getSessonMonthList: build.query<
      SeasonMonthList,
      {
        groupId: string;
        isHistorical?: boolean;
        seasonSummaryId?: string;
        seasonYear?: string;
        seasonType?: typeof CALENDAR | typeof FISCAL;
      }
    >({
      query: ({
        groupId,
        isHistorical,
        seasonSummaryId,
        seasonYear,
        seasonType,
      }) => {
        let queryUrl = stringify(
          {
            season_summary_id: seasonSummaryId,
            is_historical: isHistorical,
            season_year: seasonYear,
            season_type: seasonType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/months/${queryUrl}`,
        };
      },
      providesTags: (result) => {
        return [{ type: "BooksOverview" }];
      },
    }),
    createTxnMonthlySummary: build.mutation<
      null,
      {
        groupId: string;
        entityId: string;
        startDate: string;
        endDate: string;
        multipleMonths: boolean;
        seasonSummaryId: string;
        seasonYear?: string;
        seasonType?: typeof CALENDAR | typeof FISCAL;
        isHistorical?: boolean;
        assignee?: string;
      }
    >({
      query: ({
        groupId,
        entityId,
        startDate,
        endDate,
        multipleMonths,
        seasonSummaryId,
        seasonYear,
        seasonType,
        isHistorical,
        assignee,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/transactions_monthly_summary/`,
          method: "POST",
          body: {
            start_date: startDate,
            end_date: endDate,
            multiple_months: multipleMonths,
            season_summary_id: seasonSummaryId,
            season_year: seasonYear,
            season_type: seasonType,
            is_historical: isHistorical,
            assignee: assignee,
          },
        };
      },
      invalidatesTags: () => [{ type: "BooksOverview" }],
    }),
    deleteMonthCard: build.mutation<
      null,
      {
        groupId: string;
        entityId: string;
        uuid: string;
      }
    >({
      query: ({ groupId, entityId, uuid }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/monthly-summary/${uuid}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: "BooksOverview" }],
    }),

    getBooksQuotes: build.query<
      BooksQuote[], // needs to be checked
      {
        groupId: string;
        entityId: string;
        year: string;
      }
    >({
      query: ({ groupId, entityId, year }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/quote/?year=${year}`,
        };
      },
      providesTags: ["BOOKS_QUOTES"],
    }),
    exportBooksQuotes: build.mutation<
      { excel_document_id: string },
      {
        groupId: string;
        entityId: string;
        year: string;
      }
    >({
      query: ({ groupId, entityId, year }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/quote/csv/?year=${year}`,
          method: "POST",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBooksOnboardingStatusQuery,
  useCreateSeasonSummaryMutation,
  useUpdateSeasonSummaryMutation,
  useGetSeasonSummaryQuery,
  useGetTransactionMonthlySummariesQuery,
  useGetMonthSummaryQuery,
  useUpdateMonthSummaryMutation,
  useMarkMonthCompletedMutation,
  useDeleteReportMutation,
  useGetMonthlyBillingDetailsQuery,
  useGetMonthlyInvoicesQuery,
  useGetSessonMonthListQuery,
  useCreateTxnMonthlySummaryMutation,
  useDeleteMonthCardMutation,
  useGetBooksQuotesQuery,
  useExportBooksQuotesMutation,
} = extendedApi;
