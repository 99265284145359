import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { DeleteIcon } from "components/icons/delete";
import { Preview } from "components/PreviewModal";
import { PriceInput } from "components/PriceInput/PriceInput";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, useFormikContext } from "formik";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useMultipleFileDetails } from "hooks/useMultipleFileDetails";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import React, { useContext, useState } from "react";
import { NumberFormatValues } from "react-number-format";
import { MultiValue, SingleValue } from "react-select";
import CopyIcon from "static/images/CopyPurple.svg";
import { useUploadFileMutation } from "store/apis/documents";
import { useDeleteFieldMutation } from "store/apis/entity";
import { useGetFolderOrFileQuery } from "store/apis/previewUrl";
import { EntityDataAttribute } from "types/Models/entity";
import { SubscriptionInProfile } from "types/Models/subscription";

type FieldBuilderProps = {
  field: EntityDataAttribute;
  onChange?: ({
    name,
    value,
  }: {
    name: string;
    value: EntityDataAttribute["value"];
  }) => void;
};

const useHideField = (show: EntityDataAttribute["show"]) => {
  const { values: formikValues } = useFormikContext();
  const { value: showValue, when } = show || {};
  //   @ts-ignore
  const formikFieldValue = formikValues[when];

  return showValue !== formikFieldValue && show;
};

const DeleteField = ({
  dataAttributeId,
  is_delete_allowed,
}: {
  dataAttributeId: string;
  is_delete_allowed: boolean;
}) => {
  const [deleteField, { isLoading }] = useDeleteFieldMutation();
  const { isAdmin } = useRoleBasedView();

  const onDelete = async () => {
    try {
      await deleteField({ dataAttributeId }).unwrap();
    } catch (error) {}
  };

  if (is_delete_allowed && isAdmin) {
    return (
      <div className="t-self-end">
        <Button
          customType="ghost_icon"
          size="small"
          onClick={onDelete}
          isLoading={isLoading}
          disabled={isLoading}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  }

  return null;
};

const CheckBoxField = ({ field, onChange }: FieldBuilderProps) => {
  const { values: formikValues, setFieldValue } = useFormikContext();
  const { disabled, label, name, is_required: required, show } = field;
  //   @ts-ignore
  const value = formikValues[name];
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.checked);
    onChange?.({ name, value: e.target.checked });
  };

  return (
    <Checkbox
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      defaultChecked={value as boolean}
      checked={value as boolean}
      onChange={handleChange}
    />
  );
};

const DateField = ({ field, onChange }: FieldBuilderProps) => {
  const { authtoken } = useContext(authContext);
  const {
    platform_subscription: currentSubscription,
  }: {
    platform_subscription: SubscriptionInProfile;
  } = authtoken || {};

  const minDate = dayjs(currentSubscription.scheduled_date)
    .subtract(1, "year")
    .startOf("year")
    .toDate();

  const { setFieldValue } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
  } = field;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (date: Date) => {
    setFieldValue(name, date ? dayjs(date).format(YYYY_MM_DD) : null);
    onChange?.({ name, value: date ? dayjs(date).format(YYYY_MM_DD) : null });
  };

  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        return (
          <DateInput
            customSize="small"
            {...field}
            name={name}
            label={label}
            required={required}
            disabled={disabled}
            placeholder={placeholder || "DD-MMM-YYYY"}
            onDateChange={handleChange}
            {...(currentSubscription.scheduled_date
              ? { minDate: minDate }
              : {})}
          />
        );
      }}
    </Field>
  );
};

const InputField = ({ field, onChange }: FieldBuilderProps) => {
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
    is_delete_allowed,
    uuid,
  } = field;

  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({ name, value: e.target.value });
  };

  return (
    <div className="t-flex t-items-center t-gap-2">
      <TextInput
        customSize="small"
        name={name}
        disabled={disabled}
        placeholder={placeholder || ""}
        label={label}
        required={required}
        onChange={handleChange}
        block
      />
      <DeleteField
        dataAttributeId={uuid}
        is_delete_allowed={is_delete_allowed}
      />
    </div>
  );
};

const SelectField = ({ field, onChange }: FieldBuilderProps) => {
  const { values: formikValues } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    options,
    placeholder,
  } = field;
  //   @ts-ignore
  const value = formikValues[name];
  const isHidden = useHideField(show);

  const optionValue = value
    ? options?.find((item) => item.value === value)
    : null;

  if (isHidden) return null;

  const handleChange = (
    option: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    if (option instanceof Array) {
      return null;
    }
    onChange?.({ name, value: option?.value || "" });
  };

  return (
    <Combobox
      size="small"
      options={options || []}
      name={name}
      label={label}
      value={optionValue}
      required={required}
      isDisabled={disabled}
      placeholder={placeholder}
      onChange={handleChange}
      withForm
      menuPortalTarget={document.body}
    />
  );
};

const TextAreaField = ({ field, onChange }: FieldBuilderProps) => {
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
  } = field;
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.({ name, value: e.target.value });
  };

  return (
    <TextArea
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      placeholder={placeholder || ""}
      onChange={handleChange}
    />
  );
};

const RadioField = ({ field, onChange }: FieldBuilderProps) => {
  const { values: formikValues, setFieldValue } = useFormikContext();
  const { disabled, label, name, is_required: required, show, options } = field;
  //   @ts-ignore
  const value = formikValues[name];
  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (value: string) => {
    onChange?.({ name, value });
    setFieldValue(name, value);
  };

  return (
    <Radio.Root
      disabled={disabled}
      defaultValue={value as string}
      onValueChange={handleChange}
      className="t-flex t-flex-col t-gap-2"
    >
      <Label required={required} htmlFor={name}>
        {label}
      </Label>
      <Radio.Content className="t-gap-2.5">
        {options?.map((item) => (
          <Radio.Item key={item.value} value={item.value}>
            {item.label}
          </Radio.Item>
        ))}
      </Radio.Content>
    </Radio.Root>
  );
};

const MultiFileInputField = ({ field, onChange }: FieldBuilderProps) => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const [previewId, setPreviewId] = useState<string>("");
  const { uuid: groupId, unsorted_folder_id } = useCurrentGroupContext();
  const { values: formikValues, setFieldValue } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
  } = field;
  //   @ts-ignore
  const value = (formikValues[name] as string[]) || [];
  const isHidden = useHideField(show);
  const fileresponse = useMultipleFileDetails(value);
  const files = fileresponse.map(
    ({ name, file_type, is_previewable, uuid }) => ({
      name,
      file_type,
      is_previewable,
      uuid,
    })
  );

  const onDelete = (e: React.MouseEvent<HTMLButtonElement>, index?: number) => {
    const newFiles = value.filter((_, i) => i !== index);
    setFieldValue(name, newFiles);
    onChange?.({ name, value: newFiles });
  };

  const {
    close: closePreviewModal,
    isOpen: isPreviewModalOpen,
    open: openPreviewModal,
  } = useModal();

  const onDrop = async (files: FileType[]) => {
    try {
      const uploadedFiles = await uploadFile({
        files: files as File[],
        groupId,
        parentFolderId: unsorted_folder_id,
      }).unwrap();
      const fileIds = uploadedFiles.map(({ uuid }) => uuid);
      setFieldValue(name, [...value, ...fileIds]);
      onChange?.({ name, value: [...value, ...fileIds] });
    } catch (error) {}
  };

  if (isHidden) return null;

  return (
    <div>
      <FileInput
        required={required}
        isUploading={isLoading}
        disabled={disabled || isLoading}
        onDrop={onDrop}
        onDelete={onDelete}
        onFileClick={({ uuid }) => {
          setPreviewId(uuid);
          openPreviewModal();
        }}
        label={label}
        files={files}
        multiple
      />
      <Preview
        showModal={isPreviewModalOpen}
        closeModal={closePreviewModal}
        groupId={groupId}
        previewId={previewId}
      />
    </div>
  );
};

const SingleFileInputField = ({ field, onChange }: FieldBuilderProps) => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const { uuid: groupId, unsorted_folder_id } = useCurrentGroupContext();
  const { values: formikValues, setFieldValue } = useFormikContext();
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
  } = field;
  //   @ts-ignore
  const value = (formikValues[name] as string[]) || [];
  const isHidden = useHideField(show);
  const file = value?.[0];
  const isUploaded = Boolean(value.length > 0);

  const { data, isFetching } = useGetFolderOrFileQuery(
    {
      fileId: file,
      groupId,
    },
    { skip: !file }
  );

  const onDelete = () => {
    setFieldValue(name, []);
    onChange?.({ name, value: [] });
  };

  const {
    close: closePreviewModal,
    isOpen: isPreviewModalOpen,
    open: openPreviewModal,
  } = useModal();

  const onDrop = async (files: FileType[]) => {
    try {
      const uploadedFiles = await uploadFile({
        files: [files[0]] as File[],
        groupId,
        parentFolderId: unsorted_folder_id,
      }).unwrap();

      const fileIds = uploadedFiles.map(({ uuid }) => uuid);
      setFieldValue(name, [fileIds[0]]);
      onChange?.({ name, value: [fileIds[0]] });
    } catch (error) {}
  };

  if (isHidden) return null;

  return (
    <div>
      <Label required={required} htmlFor={name}>
        {label}
      </Label>
      <FileInput
        isUploading={isLoading}
        disabled={disabled || isFetching || isLoading}
        onDrop={onDrop}
        onDelete={onDelete}
        label={placeholder}
        onFileClick={openPreviewModal}
        file={
          isUploaded
            ? {
                name: data?.name || "",
                file_type: data?.file_type || "IMAGE",
                is_previewable: data?.is_previewable || false,
                uuid: file,
              }
            : null
        }
      />
      <Preview
        showModal={isPreviewModalOpen}
        closeModal={closePreviewModal}
        groupId={groupId}
        previewId={file}
      />
    </div>
  );
};

const String = ({ field }: FieldBuilderProps) => {
  const { values: formikValues } = useFormikContext();
  const { copyToClipboard } = useCopyToClipboard();
  const { label, name, is_required: required, show, can_copy } = field;
  //   @ts-ignore
  const value = formikValues[name];
  const isHidden = useHideField(show);

  if (isHidden) return null;

  return (
    <div>
      <Label required={required} htmlFor={name}>
        {label}
      </Label>
      <div className="t-text-body t-text-text-100 t-flex t-gap-1 t-items-center">
        {value}
        {can_copy && (
          <Button
            customType="ghost_icon"
            size="small"
            onClick={() => copyToClipboard(value)}
          >
            <img src={CopyIcon} alt="copyIcon" />
          </Button>
        )}
      </div>
    </div>
  );
};

const AmountInputField = ({ field, onChange }: FieldBuilderProps) => {
  const {
    disabled,
    label,
    name,
    is_required: required,
    show,
    placeholder,
  } = field;

  const isHidden = useHideField(show);

  if (isHidden) return null;

  const handleChange = (values: NumberFormatValues) => {
    const { floatValue } = values;
    onChange?.({ name, value: floatValue || null });
  };

  return (
    <PriceInput
      customSize="small"
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      onValueChange={handleChange}
    />
  );
};

const AmountField = ({ field }: FieldBuilderProps) => {
  const { values: formikValues } = useFormikContext();
  const { label, name, is_required: required, show } = field;
  //   @ts-ignore
  const value = formikValues[name];
  const isHidden = useHideField(show);

  if (isHidden) return null;

  return (
    <div>
      <Label required={required} htmlFor={name}>
        {label}
      </Label>
      <div>
        <AmountSuperScript amount={Number(value)} />
      </div>
    </div>
  );
};

export const FieldBuilder = ({ field, onChange }: FieldBuilderProps) => {
  switch (field.type) {
    case "CHECKBOX":
      return <CheckBoxField field={field} onChange={onChange} />;
    case "DATE":
      return <DateField field={field} onChange={onChange} />;
    case "INPUT":
      return <InputField field={field} onChange={onChange} />;
    case "SELECT":
      return <SelectField field={field} onChange={onChange} />;
    case "TEXTAREA":
      return <TextAreaField field={field} onChange={onChange} />;
    case "RADIO":
      return <RadioField field={field} onChange={onChange} />;
    case "MULTI_FILE_INPUT":
      return <MultiFileInputField field={field} onChange={onChange} />;
    case "FILE":
      return <SingleFileInputField field={field} onChange={onChange} />;
    case "AMOUNT_INPUT":
      return <AmountInputField field={field} onChange={onChange} />;
    case "STRING":
      return <String field={field} />;
    case "NUMBER":
      return <String field={field} />;
    case "AMOUNT":
      return <AmountField field={field} />;
    default:
      return null;
  }
};

const CustomField = {
  CheckBoxField,
  DateField,
  InputField,
  SelectField,
  TextAreaField,
  RadioField,
  MultiFileInputField,
  SingleFileInputField,
  AmountInputField,
  AmountField,
  String,
};

export default CustomField;
