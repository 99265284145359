import { ChannelFilters, Event, StreamChat } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const shouldChannelListUpdate = async <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>({
  client,
  event,
  filters = {},
  isCustomer = false,
}: {
  client: StreamChat<StreamChatGenerics>;
  event?: Event<StreamChatGenerics>;
  filters?: ChannelFilters<StreamChatGenerics>;
  isCustomer?: boolean;
}) => {
  let update = true;

  if (event?.message?.is_announcement && !isCustomer) {
    return false;
  }

  try {
    const [queriedChannel] = await client.queryChannels(
      {
        cid: event?.cid,
        ...filters,
      },
      [],
      { limit: 1 }
    );

    if (queriedChannel?.cid !== event?.cid) {
      update = false;
    }
  } catch (error) {
    update = false;
  }

  return update;
};
