import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// styles
import "../../../static/styles/components/selfServePerk.css";

// components
import CompanyInformation from "./companyInformation";
import RedemptionInformation from "./redemptionInformation";
import PerkInformation from "./perkInformation";
import PerkStepBar from "./stepBar";
import InterActivePerksCard from "./interactivePerkCard";
import { Button } from "../../design/button";
import { usePageTitle } from "hooks/usePageTitle";
import PerksCard from "../perksCard";

const PerkStepController = () => {
  usePageTitle("Perks");
  const history = useHistory();
  const [currentState, setCurrentState] = useState(0);
  const [perkData, setPerkData] = useState({});
  const [previewImage, setPreviewImage] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPerkData({ ...perkData, [name]: value });
  };

  const goBack = () => history.push("/community/perks");
  const recreate = () => window.location.reload();

  const showForm = () => {
    switch (currentState) {
      case 0: {
        return (
          <CompanyInformation
            setPerkData={setPerkData}
            goBack={goBack}
            setPreviewImage={setPreviewImage}
            handleChange={handleChange}
            setCurrentState={setCurrentState}
            perkData={perkData}
          />
        );
      }
      case 1: {
        return (
          <PerkInformation
            setPerkData={setPerkData}
            goBack={goBack}
            handleChange={handleChange}
            setCurrentState={setCurrentState}
            perkData={perkData}
          />
        );
      }
      case 2: {
        return (
          <RedemptionInformation
            setPerkData={setPerkData}
            goBack={goBack}
            handleChange={handleChange}
            setCurrentState={setCurrentState}
            perkData={perkData}
          />
        );
      }
      default:
        return <></>;
    }
  };
  return (
    <div className="selfServePerkContainer t-w-4/5 t-mx-auto">
      <PerkStepBar currentState={currentState} />
      {currentState !== 3 ? (
        <div className="perkStateContainer">
          <div className="leftContainer perksBodyContainer t-p-5">
            <PerksCard
              perk={{ ...perkData, icon: previewImage }}
              isPreview={true}
            />
          </div>
          <div className="rightContainer">{showForm()}</div>
        </div>
      ) : (
        <div className="perkStateContainer perksBodyContainer t-p-5">
          <div className="successFulContainer">
            <PerksCard
              perk={{ ...perkData, icon: previewImage }}
              isPreview={true}
            />
            <span className="heading">Thank you for submitting your perk!</span>
            <span className="content">
              Your perk is being reviewed by our team. We will notify you once
              we’re done.{" "}
            </span>
            <div className="d-flex justify-content-between">
              <Button size="medium" color="purple" onClick={goBack}>
                Go Back
              </Button>
              <Button size="medium" color="purple" onClick={recreate}>
                Add another perk
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PerkStepController;
