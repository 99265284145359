import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  restrictedChild: "",
};

export const entitySlice = createSlice({
  name: "restrictedChild",
  initialState,
  reducers: {
    setRestrictedChild: (state, action: PayloadAction<string>) => {
      state.restrictedChild = action.payload;
    },
  },
});

export const { setRestrictedChild } = entitySlice.actions;

export default entitySlice.reducer;
