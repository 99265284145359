import React from "react";

export const ClockWiseWait = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 5V8"
      stroke="#F39C12"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6 9.5L8 8"
      stroke="#F39C12"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5117 6.23145H14.0117V3.73145"
      stroke="#F39C12"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8868 11.8875C11.1178 12.6571 10.1378 13.1814 9.07074 13.394C8.00372 13.6066 6.89763 13.4979 5.89237 13.0818C4.88712 12.6656 4.02787 11.9607 3.42332 11.0561C2.81878 10.1515 2.49609 9.08799 2.49609 8C2.49609 6.91201 2.81878 5.84847 3.42332 4.9439C4.02787 4.03933 4.88712 3.33439 5.89237 2.91824C6.89763 2.50209 8.00372 2.39343 9.07074 2.60601C10.1378 2.81859 11.1178 3.34286 11.8868 4.1125L14.0118 6.23125"
      stroke="#F39C12"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
