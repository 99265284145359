import React from "react";

export const ClockCounterClockwise = ({
  color = "#C59400",
  size = 20,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.75V6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.95 7.125L6 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.36523 4.67285H1.49023V2.79785"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.08398 8.91563C3.66074 9.49286 4.39574 9.88606 5.196 10.0455C5.99627 10.2049 6.82584 10.1234 7.57978 9.81133C8.33372 9.49921 8.97816 8.9705 9.43157 8.29208C9.88498 7.61365 10.127 6.81599 10.127 6C10.127 5.18401 9.88498 4.38635 9.43157 3.70792C8.97816 3.0295 8.33372 2.50079 7.57978 2.18868C6.82584 1.87656 5.99627 1.79507 5.196 1.95451C4.39574 2.11395 3.66074 2.50715 3.08398 3.08438L1.49023 4.67344"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
