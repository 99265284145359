import { ConditionalLink } from "components/conditionalLink";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Button } from "components/DesignSystem/Button/Button";
import { HIT_404 } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useEffect } from "react";

export const PageNotFound = () => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(HIT_404, {
      url: window.location.href,
    });
  }, [trackEvent]);

  return (
    <DashboardContainer className="t-h-full">
      <DashboardContainer.Content className="t-w-full t-flex t-justify-center t-items-center t-flex-col t-gap-7 t-h-screen">
        <span className="t-text-[30px] t-text-text-30 t-font-normal t-tracking-[0.25px]">
          You are lost!
        </span>
        <span className="t-flex t-items-center">
          <span className="t-text-[200px] t-font-semibold t-text-text-100 t-leading-[148px]">
            4
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="235"
            height="148"
            viewBox="0 0 235 148"
            fill="none"
          >
            <circle cx="113.971" cy="74" r="74" fill="#E7E6EB" />
            <circle opacity="0.5" cx="131.971" cy="105" r="14" fill="#CFCDD6" />
            <circle
              opacity="0.5"
              cx="100.471"
              cy="70.5"
              r="7.5"
              fill="#CFCDD6"
            />
            <circle opacity="0.4" cx="159.971" cy="60" r="6" fill="#CFCDD6" />
            <circle opacity="0.4" cx="111.971" cy="32" r="6" fill="#CFCDD6" />
            <ellipse
              opacity="0.5"
              cx="59.4707"
              cy="67"
              rx="7.5"
              ry="7"
              fill="#CFCDD6"
            />
            <ellipse
              opacity="0.4"
              cx="85.4707"
              cy="119"
              rx="7.5"
              ry="7"
              fill="#CFCDD6"
            />
            <path
              d="M35.4705 83C11.9707 90 2.62814 97 5.04163 105.828C9.87318 123.5 64.2064 118.75 125.471 102C186.735 85.2504 231.637 62.5 226.899 45.1723C224.255 35.5 208.379 33.3624 182.471 37.5"
              stroke="#CFCDD6"
              strokeWidth="6"
              strokeLinecap="round"
            />
          </svg>
          <span className="t-text-[200px] t-font-semibold t-text-text-100 t-leading-[148px]">
            4
          </span>
        </span>
        <span className="t-text-h3 t-font-semibold t-text-text-100">
          Sorry, page not found
        </span>
        <ConditionalLink to="/">
          <Button>Go back home</Button>
        </ConditionalLink>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
