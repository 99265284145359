import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Price } from "components/Price";
import { ConditionalLink } from "components/conditionalLink";
import { GROUP_BOOKS_CONSOLIDATION_MANAGE } from "constants/addons";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import { resetAllAddonDataPayload, setAddonType } from "store/slices/addons";
import { RootState } from "store/store";

export const GroupConsolidationManage = () => {
  const dispatch = useDispatch();

  const { addonType, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );

  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonType === GROUP_BOOKS_CONSOLIDATION_MANAGE
  );

  const {
    isOpen: showCancelModal,
    close: closeCancelModal,
    open: openCancelModal,
  } = useModal();

  const { subscription_name, stripe_coupon, group_subscription } =
    addonData || {};

  return (
    <>
      <Modal.Root
        open={showAddonModal}
        onOpenChange={() => {
          setShowAddonModal(false);
          dispatch(setAddonType(""));
          dispatch(resetAllAddonDataPayload());
        }}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Manage Plan</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-p-5 t-border-solid t-border-neutral-0 t-rounded-lg">
              <div className="t-mb-5">
                <div className="t-text-subtitle">{subscription_name}</div>
              </div>
              <Price
                amount={
                  stripe_coupon?.discounted_amount ||
                  group_subscription?.[0]?.amount
                }
                cycle={group_subscription?.[0]?.billingCycle}
                size="medium"
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-between">
            <Button
              customType="ghost"
              onClick={() => {
                setShowAddonModal(false);
                openCancelModal();
              }}
            >
              Cancel subscription
            </Button>
            <ConditionalLink to={"/chat"}>
              <Button customType="primary">Contact us</Button>
            </ConditionalLink>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <GroupConsolidationCancel
        showCancelModal={showCancelModal}
        openCancelModal={openCancelModal}
        closeCancelModal={closeCancelModal}
      />
    </>
  );
};

const GroupConsolidationCancel = ({
  showCancelModal,
  openCancelModal,
  closeCancelModal,
}: {
  showCancelModal: boolean;
  openCancelModal: () => void;
  closeCancelModal: () => void;
}) => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const entityId = useCurrentEntityId();

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription({
        groupId,
        entityId,
        subscriptionId: addonData?.group_subscription?.[0]?.groupSubscriptionId,
      }).unwrap();
      successToast({ message: "Subscription cancelled successfully" });
      dispatch(setAddonType(""));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      alertToast({ message: error?.message });
      dispatch(setAddonType(""));
    }
  };

  return (
    <Modal.Root open={showCancelModal} onOpenChange={closeCancelModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Cancel subscription</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            Are you sure you want to cancel this subscription? We will stop all
            accounting activity starting next month.
          </div>
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3">
          <Button
            onClick={() => {
              closeCancelModal();
              dispatch(setAddonType(""));
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            customType="danger"
            onClick={handleCancelSubscription}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Cancel subscription
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
