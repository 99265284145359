import { Header } from "components/DesignSystem/Header/Header";
import { usePageTitle } from "hooks/usePageTitle";
import { useContext } from "react";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/profileSetting.css";
import { ProfilePicUpdate } from "./profilePicUpdate";
import { ProfileUpdate } from "./profileUpdate";
import Tab from "components/DesignSystem/Tab/Tab";
import { Route, useLocation, useRouteMatch, Switch } from "react-router-dom";
import { DashboardLayout } from "components/DashboardLayout";

export const Settings = () => {
  usePageTitle("Profile Settings");
  const { pathname } = useLocation();
  const { url, path } = useRouteMatch();
  const { authtoken, updateUser } = useContext(authContext);

  return (
    <DashboardLayout header={<Header v2 title="Profile Settings" />}>
      <div className="t-w-[440px] t-flex t-flex-col t-gap-6">
        <ProfilePicUpdate />
        <ProfileUpdate user={authtoken} updateUser={updateUser} />
      </div>
    </DashboardLayout>
  );
};
