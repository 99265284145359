export const RegAgent = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_1391)">
      <path
        d="M8 10C9.38071 10 10.5 8.88071 10.5 7.5C10.5 6.11929 9.38071 5 8 5C6.61929 5 5.5 6.11929 5.5 7.5C5.5 8.88071 6.61929 10 8 10Z"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.98749 12.4606C4.36368 11.7196 4.93771 11.0973 5.64595 10.6626C6.35419 10.2279 7.16897 9.99774 7.99999 9.99774C8.831 9.99774 9.64578 10.2279 10.354 10.6626C11.0623 11.0973 11.6363 11.7196 12.0125 12.4606"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9169 6.99999C14.1278 8.25334 13.935 9.54129 13.3662 10.6779C12.7974 11.8145 11.8821 12.7409 10.7525 13.3234C9.62282 13.9058 8.33729 14.1142 7.08149 13.9184C5.8257 13.7226 4.66462 13.1328 3.76591 12.2341C2.8672 11.3354 2.27737 10.1743 2.08158 8.9185C1.88579 7.6627 2.09417 6.37717 2.67664 5.24753C3.25911 4.11789 4.18552 3.2026 5.32212 2.63381C6.45871 2.06503 7.74666 1.87218 9 2.08312"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip1_6445_1391)">
        <path
          d="M10.9375 3.375L12.25 4.6875L15.25 1.6875"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6445_1391">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_6445_1391">
        <rect width="6" height="6" fill="white" transform="translate(10)" />
      </clipPath>
    </defs>
  </svg>
);
