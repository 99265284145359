import classNames from "classnames";
import { ReactNode } from "react";

export const EmptyScreen = ({
  children,
  text,
  cta,
  ...props
}: {
  children?: ReactNode;
  text: ReactNode;
  cta?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={classNames(
        "t-flex t-items-center t-w-full t-justify-center t-h-[50dvh] t-flex-col t-gap-4",
        props.className
      )}
    >
      <div className="t-flex-shrink-0">{children}</div>
      <div className="t-text-subtitle t-text-i-text-dark t-text-center t-max-w-80">
        {text}
      </div>
      {cta}
    </div>
  );
};
