export const InkleCommunity = () => (
  <svg
    width="246"
    height="56"
    viewBox="0 0 246 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1782 18H26.1782C31.7011 18 36.1782 22.4772 36.1782 28H26.1782C20.6554 28 16.1782 23.5228 16.1782 18Z"
      fill="url(#paint0_linear_5738_8487)"
    />
    <path
      d="M16.1782 28H26.1782C31.7011 28 36.1782 32.4772 36.1782 38H26.1782C20.6554 38 16.1782 33.5228 16.1782 28Z"
      fill="url(#paint1_linear_5738_8487)"
    />
    <path
      d="M46.1782 20.065C46.1782 18.8121 46.9582 18 48.184 18C49.3875 18 50.1675 18.8121 50.1675 20.065C50.1675 21.3179 49.3875 22.1531 48.184 22.1531C46.9582 22.1531 46.1782 21.3179 46.1782 20.065ZM49.9669 37.884H46.4011V23.9629H49.9669V37.884Z"
      fill="url(#paint2_linear_5738_8487)"
    />
    <path
      d="M60.9071 30.9002C60.9071 28.8585 59.882 27.5128 58.2996 27.5128C56.8064 27.5128 55.7144 28.9513 55.7144 30.9234V37.884H52.1486V23.9629H55.7144V25.587C56.1824 24.5429 57.5642 23.8005 58.9459 23.8005C62.3558 23.8005 64.473 26.6311 64.473 30.9002V37.884H60.9071V30.9002Z"
      fill="url(#paint3_linear_5738_8487)"
    />
    <path
      d="M70.1901 37.884H66.6243V18.1624H70.1901V30.2042L74.7143 23.9629H78.6144L74.068 30.9234L79.0824 37.884H74.5583L70.1901 31.6427V37.884Z"
      fill="url(#paint4_linear_5738_8487)"
    />
    <path
      d="M83.9831 37.884H80.4173V18.1624H83.9831V37.884Z"
      fill="url(#paint5_linear_5738_8487)"
    />
    <path
      d="M94.8244 33.6148H98.6131C97.6993 36.2135 95.3147 38 92.5957 38C88.6956 38 85.9544 35.0534 85.9544 30.877C85.9544 26.8399 88.7847 23.7773 92.5289 23.7773C96.273 23.7773 99.1034 26.8399 99.1034 30.877C99.1034 31.3179 99.0811 31.7819 99.0142 32.1995H89.6985C90.0996 33.6148 91.1917 34.5197 92.5734 34.5197C93.6209 34.5197 94.4455 34.1485 94.8244 33.6148ZM89.6985 29.5313H95.3592C94.9581 28.1624 93.9106 27.2807 92.5289 27.2807C91.1471 27.2807 90.0996 28.1624 89.6985 29.5313Z"
      fill="url(#paint6_linear_5738_8487)"
    />
    <path
      d="M229.493 25.3662L224.065 39.0815C223.133 41.4433 222.553 42.5828 220.067 42.5828H219.3V40.3867H219.86C220.75 40.3867 221.331 40.0138 221.807 38.8121L222.18 37.797L217.249 25.3662H219.632L223.382 34.8136L227.132 25.3662H229.493Z"
      fill="#41395C"
    />
    <path
      d="M211.491 22.7344H213.169V25.3656H216.09V27.4788H213.169V33.487C213.169 34.9579 213.77 35.6002 215.178 35.6002H216.09V37.7963H214.971C212.133 37.7963 210.931 36.5947 210.931 33.6113V27.4788H209.004V25.3656H210.931L211.491 22.7344Z"
      fill="#41395C"
    />
    <path
      d="M204.705 21.6365C204.705 20.8492 205.223 20.3105 206.052 20.3105C206.839 20.3105 207.357 20.8492 207.357 21.6365C207.357 22.4445 206.839 22.9832 206.052 22.9832C205.223 22.9832 204.705 22.4445 204.705 21.6365ZM207.171 37.7965H204.933V25.3657H207.171V37.7965Z"
      fill="#41395C"
    />
    <path
      d="M200.511 31.5614C200.511 29.0545 199.226 27.4385 197.238 27.4385C195.394 27.4385 194.068 29.1788 194.068 31.5821V37.7975H191.83V25.3667H194.068V27.6871C194.544 26.2576 195.953 25.2217 197.61 25.2217C200.76 25.2217 202.749 27.7285 202.749 31.5614V37.7975H200.511V31.5614Z"
      fill="#41395C"
    />
    <path
      d="M181.156 31.6023C181.156 34.1092 182.44 35.7045 184.429 35.7045C186.273 35.7045 187.599 33.9849 187.599 31.5816V25.3662H189.836V37.797H187.599V35.4766C187.122 36.9061 185.714 37.942 184.056 37.942C180.907 37.942 178.918 35.4351 178.918 31.6023V25.3662H181.156V31.6023Z"
      fill="#41395C"
    />
    <path
      d="M166.097 31.5614C166.097 29.0545 164.813 27.4385 162.824 27.4385C160.98 27.4385 159.654 29.1788 159.654 31.5821V37.7975H157.417V25.3667H159.654V27.6871C160.131 26.2576 161.539 25.2217 163.197 25.2217C165.186 25.2217 166.719 26.2369 167.568 27.9357C168.376 26.2161 169.785 25.2217 171.567 25.2217C174.903 25.2217 177.016 27.7285 177.016 31.5614V37.7975H174.778V31.5614C174.778 29.0545 173.494 27.4385 171.484 27.4385C169.661 27.4385 168.335 29.1788 168.335 31.5614V37.7975H166.097V31.5614Z"
      fill="#41395C"
    />
    <path
      d="M144.348 31.5614C144.348 29.0545 143.063 27.4385 141.074 27.4385C139.231 27.4385 137.905 29.1788 137.905 31.5821V37.7975H135.667V25.3667H137.905V27.6871C138.381 26.2576 139.79 25.2217 141.447 25.2217C143.436 25.2217 144.969 26.2369 145.819 27.9357C146.627 26.2161 148.036 25.2217 149.817 25.2217C153.153 25.2217 155.266 27.7285 155.266 31.5614V37.7975H153.029V31.5614C153.029 29.0545 151.744 27.4385 149.735 27.4385C147.911 27.4385 146.585 29.1788 146.585 31.5614V37.7975H144.348V31.5614Z"
      fill="#41395C"
    />
    <path
      d="M121.591 31.5614C121.591 27.9772 124.243 25.2217 127.682 25.2217C131.1 25.2217 133.732 27.9772 133.732 31.5614C133.732 35.1663 131.1 37.9218 127.682 37.9218C124.243 37.9218 121.591 35.1663 121.591 31.5614ZM127.682 27.4385C125.507 27.4385 123.828 29.2202 123.828 31.5614C123.828 33.9232 125.507 35.6842 127.682 35.6842C129.857 35.6842 131.515 33.9232 131.515 31.5614C131.515 29.2202 129.857 27.4385 127.682 27.4385Z"
      fill="#41395C"
    />
    <path
      d="M117.664 26.2777C116.773 23.8537 114.847 22.4034 112.319 22.4034C108.88 22.4034 106.601 25.0346 106.601 28.9917C106.601 32.9281 108.901 35.5593 112.319 35.5593C114.888 35.5593 116.877 34.0883 117.664 31.7058H120.068C119.177 35.5179 116.235 37.9004 112.34 37.9004C107.554 37.9004 104.363 34.3162 104.363 28.9917C104.363 23.6465 107.554 20.083 112.34 20.083C116.256 20.083 119.094 22.4656 120.068 26.2777H117.664Z"
      fill="#41395C"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5738_8487"
        x1="16.1782"
        y1="23"
        x2="36.1782"
        y2="23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5738_8487"
        x1="16.1782"
        y1="33"
        x2="36.1782"
        y2="33"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5738_8487"
        x1="46.1782"
        y1="28"
        x2="99.1034"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5738_8487"
        x1="46.1782"
        y1="28"
        x2="99.1034"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5738_8487"
        x1="46.1782"
        y1="28"
        x2="99.1034"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5738_8487"
        x1="46.1782"
        y1="28"
        x2="99.1034"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5738_8487"
        x1="46.1782"
        y1="28"
        x2="99.1034"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
