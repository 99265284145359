import { Button } from "components/DesignSystem/Button/Button";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import EmptyCalender from "static/images/EmptyCalendar.svg";
import { setBookingStartDateModal } from "store/slices/chartOfAccounts";

export const StartBookkeepingDateEmptyScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();

  return (
    <div className="t-w-full t-h-full t-flex t-flex-col t-justify-center t-items-center t-gap-3 ">
      <img src={EmptyCalender} alt="EmptyCalender" />
      <div className="t-flex t-flex-col t-items-center t-w-[280px] t-gap-1">
        <div className="t-text-subtitle-sm t-text-text-60">No data found!</div>
        <div className="t-text-body-sm t-text-text-30 t-break-words t-text-center">
          Please either change the bookkeeping start date or the selected
          period.
        </div>
      </div>
      <Button
        customType="primary-outlined"
        size="small"
        onClick={() => {
          history.push(`categories/chart-of-accounts${search}`);
          dispatch(setBookingStartDateModal(true));
        }}
      >
        Change bookkeeping start date
      </Button>
    </div>
  );
};
