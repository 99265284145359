import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode } from "react";
import { RepeatableFields, SectionField } from "types/Models/Filing";
import { FilingReviewSectionDisplay } from "../FilingReviewSectionDisplay";

type SectionProp = {
  section_title: string;
  section_fields: any;
  repeatable_fields: any;
  is_hidden_for_customer: boolean;
};

export const ReviewForm = ({
  sections,
  children,
}: {
  sections: SectionProp[];
  children?: ReactNode;
}) => {
  const { isCustomer } = useRoleBasedView();

  return (
    <div className="t-w-full">
      <div className="t-w-full t-space-y-4 t-mb-4">
        {sections
          ?.filter(
            (section: SectionProp) =>
              !(section.is_hidden_for_customer && isCustomer)
          )
          ?.map(
            (
              section: {
                section_title: string;
                section_fields: SectionField;
                repeatable_fields: RepeatableFields;
              },
              index: React.Key | null | undefined
            ) => {
              return (
                <FilingReviewSectionDisplay
                  key={index}
                  sectionTitle={section?.section_title}
                  sectionFields={section?.section_fields}
                  repeatable_fields={section?.repeatable_fields}
                />
              );
            }
          )}
      </div>
      {children}
    </div>
  );
};
