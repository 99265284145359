import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BillingInvoice } from "types/Models/billing";

type BillingSliderState = {
  showBillingSlider: boolean;
  invoice: BillingInvoice | null;
};

const initialState: BillingSliderState = {
  showBillingSlider: false,
  invoice: null,
};

export const billingInvoiceSlider = createSlice({
  name: "billingInvoiceSlider",
  initialState,
  reducers: {
    openBillingInvoiceSlider: (
      state,
      action: PayloadAction<BillingInvoice>
    ) => {
      state.invoice = action.payload;
      state.showBillingSlider = true;
    },
    closeBillingInvoiceSlider: (state) => {
      state.invoice = null;
      state.showBillingSlider = false;
    },
  },
});

export const { openBillingInvoiceSlider, closeBillingInvoiceSlider } =
  billingInvoiceSlider.actions;

export default billingInvoiceSlider.reducer;
