export const ArrowBendDownLeft = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ArrowBendDownLeft">
      <path
        id="Vector"
        d="M5 6.5L2 9.5L5 12.5"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector_2"
        d="M14 3.5C14 5.0913 13.3679 6.61742 12.2426 7.74264C11.1174 8.86786 9.5913 9.5 8 9.5H2"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);
