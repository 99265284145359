import { DashboardLayout } from "components/DashboardLayout";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Modal from "components/DesignSystem/Modal/Modal";
import { Slider } from "components/DesignSystem/Slider/Slider";
import Table from "components/DesignSystem/Table/V2/Table";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { ContactColumn } from "components/RaiseCRM/InvestorTable";
import { InvestorView } from "components/RaiseCRM/InvestorView";
import { RaiseProfileEdit } from "components/RaiseProfileEdit/RaiseProfileEdit";
import { RaiseProfileInfo } from "components/RaiseProfileInfo/RaiseProfileInfo";
import { NoGroupRaiseProfile } from "components/TPPayments/Illustration/NoGroupRaiseProfile";
import { ConditionalLink } from "components/conditionalLink";
import ToolTip from "components/design/toolTip";
import { CheckOutline } from "components/icons/CheckOutline";
import { Cross } from "components/icons/Cross";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { raiseGroupValidation } from "formValidations/raiseGroupValidation";
import { Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import randomBytes from "randombytes";
import { MouseEvent, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import Globe from "static/images/Globe.svg";
import {
  RaiseProfileUpdatePayload,
  useGetGroupsRaiseProfileQuery,
  useUpdateGroupRaiseProfileMutation,
} from "store/apis/raiseProfiles";
import { Contact } from "store/apis/vcCrm";
import {
  Intro,
  useAcceptVcIntroMutation,
  useGetVcIntrosQuery,
} from "store/apis/vcIntro";
import { BackendError } from "types/utils/error";

export const ContactsTable = ({ contacts }: { contacts: Contact[] }) => {
  const createColumn = createColumnHelper<{
    name: string;
    linkedIn_url?: string;
  }>();

  const columns = [
    createColumn.accessor("name", {
      header: "Investor",
    }),

    createColumn.accessor("linkedIn_url", {
      header: "Linkedin profile",
      cell(info) {
        return (
          <ConditionalLink to={info?.getValue()} className="t-text-purple">
            {info.getValue()}
          </ConditionalLink>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: contacts,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table.Container>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: header.getSize() }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const Raise = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();
  const [openSlider, setOpenSlider] = useState<boolean>(false);
  const [openInvestorSlider, setOpenInvestorSlider] = useState<boolean>(false);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [rowData, setRowData] = useState<Intro | null>();

  const {
    data: intros,
    isLoading,
    isSuccess,
  } = useGetVcIntrosQuery(
    {
      groupId: groupId,
    },
    { skip: !groupId }
  );

  const {
    data: raiseGroupProfile,
    isLoading: loadingGroupRaiseProfile,
    isSuccess: loadedGroupRaiseProfile,
  } = useGetGroupsRaiseProfileQuery(
    {
      groupId,
    },
    { skip: !groupId }
  );

  const [updateGroupProfile, { isLoading: updatingGroupRaiseProfile }] =
    useUpdateGroupRaiseProfileMutation();

  const [acceptVcInterest, { isLoading: accepting, originalArgs }] =
    useAcceptVcIntroMutation();

  const createColumn = createColumnHelper<Intro>();

  const onAccept = async (introId: string) => {
    if (groupId) {
      try {
        await acceptVcInterest({
          groupId: groupId,
          introId,
        }).unwrap();
        successToast({ message: "Email is being sent." });
      } catch (error) {
        alertToast({
          message: "Error in sending the introduction, please reach out.",
        });
      }
    }
  };

  const columns = [
    createColumn.accessor("vc.name", {
      header: "Investor name",
      cell: ({
        row: {
          original: {
            vc: { name, website },
          },
        },
      }) => {
        return (
          <div className="t-flex t-gap-2 t-items-center">
            <ConditionalLink to={website} onClick={(e) => e.stopPropagation()}>
              <img src={Globe} alt="website" />
            </ConditionalLink>
            <div className="t-text-body">{name}</div>
          </div>
        );
      },
    }),

    createColumn.accessor("vc.contacts", {
      header: "Contact name",
      cell: ContactColumn,
    }),

    createColumn.accessor("vc_interest", {
      header: "Status",
      cell(info) {
        const status = info.getValue();

        if (status === "Accepted") {
          return <Tag tagType="green">Interested</Tag>;
        }

        if (status === "Rejected") {
          return <Tag tagType="red">Not interested</Tag>;
        }

        return <Tag tagType="yellow">Pending</Tag>;
      },
    }),

    createColumn.accessor("comment", {
      header: "Comment",
      cell(info) {
        if (!info.getValue()) {
          return "-";
        }

        return (
          <ToolTip text={info.getValue()}>
            <span className="t-line-clamp-1">{info.getValue()}</span>
          </ToolTip>
        );
      },
    }),

    createColumn.accessor("vc_interest", {
      header: "Action",
      cell(info) {
        const vcInterest = info.getValue();
        const status = info.row.original.status;

        const uuid = info.row.original.uuid;

        if (status === "VC_APPROVED") {
          return (
            <div className="t-flex t-gap-1 t-items-center">
              <span className="t-text-dark_green-50">
                {" "}
                <CheckOutline />
              </span>{" "}
              <span>Introduced</span>
            </div>
          );
        }

        if (vcInterest === "Accepted" && status === "INITIATED") {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <Modal.Root>
                <Modal.Trigger asChild>
                  <Button
                    customType="primary"
                    isLoading={accepting && originalArgs?.introId === uuid}
                    disabled={accepting && originalArgs?.introId === uuid}
                    size="small"
                  >
                    View
                  </Button>
                </Modal.Trigger>
                <Modal.Content size="small">
                  <Modal.Header>
                    <Modal.Title>Get an introduction</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      You will be introduced to the investor via email. Click
                      below to fire the email.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Modal.RawClose asChild>
                      <Button
                        block
                        customType="primary"
                        onClick={() => onAccept(uuid)}
                      >
                        Send email
                      </Button>
                    </Modal.RawClose>
                  </Modal.Footer>
                </Modal.Content>
              </Modal.Root>
            </div>
          );
        }

        return "-";
      },
    }),
  ];

  const table = useReactTable({
    data: intros?.vc_intros || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onSubmit = async (values: RaiseProfileUpdatePayload) => {
    try {
      const payload = {
        ...values,
        // @ts-ignore
        raised_two_mil: values.raised_two_mil === "yes" ? true : false,
        indian_resident_ten_percent_founder_exists:
          // @ts-ignore
          values.indian_resident_ten_percent_founder_exists === "yes"
            ? true
            : false,
      };
      await updateGroupProfile({ groupId, payload }).unwrap();
      successToast({ message: "Updated successfully!" });

      setEnableEdit(false);
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onCreateProfile = () => {
    setOpenSlider(true);
    setEnableEdit(true);
  };

  const viewProfile = () => {
    setEnableEdit(false);
    setOpenSlider(true);
  };

  const onRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: { original: Intro }
  ) => {
    if (openInvestorSlider && row.original.uuid === rowData?.uuid) {
      setOpenInvestorSlider(false);
      setRowData(null);
      return;
    }
    e.stopPropagation();
    setRowData(row.original);
    setOpenSlider(false);
    setOpenInvestorSlider(true);
  };

  const coFounderData =
    raiseGroupProfile?.details_for_investors?.cofounder_data.map(
      (coFounder) => ({ ...coFounder, id: randomBytes(10).toString("hex") })
    );

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Raise"
          right={
            raiseGroupProfile?.company_group ? (
              <Button customType="primary" onClick={viewProfile}>
                View Raise Profile
              </Button>
            ) : (
              <Button customType="primary" onClick={onCreateProfile}>
                Create Raise Profile
              </Button>
            )
          }
        />
      }
    >
      <div className="t-flex t-flex-col t-h-full">
        <Async.Root isLoading={isLoading} isEmpty={false} isSuccess={isSuccess}>
          <Async.Success>
            {loadedGroupRaiseProfile && !raiseGroupProfile.company_group ? (
              <div className="t-w-full t-flex t-justify-center t-flex-col t-items-center t-gap-4 t-h-full">
                <NoGroupRaiseProfile />
                <Button customType="primary" onClick={onCreateProfile}>
                  Create Profile
                </Button>
              </div>
            ) : (
              <Table.Container>
                <Table.Content>
                  <Table.Head>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Table.HeadCell
                            key={header.id}
                            style={{ width: header.getSize() }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </Table.HeadCell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {table.getRowModel().rows.map((row) => (
                      <Table.Row
                        key={row.id}
                        onClick={(e) => onRowClick(e, row)}
                        className="t-cursor-pointer hover:t-bg-surface-lighter-grey"
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Table.Cell
                            key={cell.id}
                            style={{ width: cell.column.getSize() }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table.Content>
              </Table.Container>
            )}

            <Slider
              open={openSlider}
              position="right"
              width={500}
              className="t-overflow-auto t-rounded t-border t-border-solid t-border-neutral-0 t-shadow !t-z-overlay !t-fixed t-w-[500px] !t-p-0"
            >
              <div className="t-flex t-justify-between t-border-b t-border-neutral-10 t-border-solid t-border-0 t-p-4 t-sticky t-top-0 t-bg-surface t-z-10">
                <div className="t-flex t-gap-2 t-items-center">
                  {raiseGroupProfile?.company_group && (
                    <div className="t-bg-surface-grey t-w-8 t-h-8 t-flex t-justify-center t-items-center t-rounded-full">
                      {raiseGroupProfile?.company_group.name
                        ?.at(0)
                        ?.toUpperCase()}
                    </div>
                  )}

                  {raiseGroupProfile?.company_group ? (
                    <div className="t-flex t-flex-col">
                      <span>{raiseGroupProfile?.company_group.name}</span>
                      <span className="t-text-body-sm t-text-text-30">
                        Last updated on{" "}
                        {dayjs(
                          raiseGroupProfile?.details_for_investors?.updated_at
                        ).format(DD_MMM_YYYY)}
                      </span>
                    </div>
                  ) : (
                    <span>Create Raise profile</span>
                  )}
                </div>
                <Button
                  customType="ghost_icon"
                  size="small"
                  onClick={() => setOpenSlider(false)}
                >
                  <Cross color="currentColor" />
                </Button>
              </div>

              {enableEdit ? (
                <Formik
                  onSubmit={onSubmit}
                  validationSchema={raiseGroupValidation}
                  initialValues={{
                    group_website:
                      raiseGroupProfile?.company_group?.group_website || "",
                    group_linkedIn_url:
                      raiseGroupProfile?.company_group?.group_linkedIn_url ||
                      "",
                    raised_till_date:
                      raiseGroupProfile?.details_for_investors
                        ?.raised_till_date || "",
                    round_type:
                      raiseGroupProfile?.details_for_investors?.round_type ||
                      "",
                    company_pitch:
                      raiseGroupProfile?.company_group?.company_pitch || "",
                    year_founded:
                      raiseGroupProfile?.details_for_investors?.year_founded ||
                      "",
                    investor_types:
                      raiseGroupProfile?.details_for_investors
                        ?.investor_types || [],
                    indian_resident_ten_percent_founder_exists:
                      raiseGroupProfile?.details_for_investors
                        ?.indian_resident_ten_percent_founder_exists || false,
                    markets:
                      raiseGroupProfile?.details_for_investors?.markets || [],
                    industry_types:
                      raiseGroupProfile?.details_for_investors
                        ?.industry_types || [],
                    business_models:
                      raiseGroupProfile?.details_for_investors
                        ?.business_models || [],
                    description:
                      raiseGroupProfile?.company_group?.description || "",
                    current_annual_revenue:
                      raiseGroupProfile?.details_for_investors
                        ?.annual_revenue || "",
                    cofounder_data: coFounderData || [
                      {
                        id: randomBytes(10).toString("hex"),
                        cofounder_name: "",
                        cofounder_email: "",
                        cofounder_linkedIn_url: "",
                      },
                      {
                        id: randomBytes(10).toString("hex"),
                        cofounder_name: "",
                        cofounder_email: "",
                        cofounder_linkedIn_url: "",
                      },
                    ],
                  }}
                  validateOnBlur={true}
                  validateOnMount={false}
                  validateOnChange={false}
                >
                  <RaiseProfileEdit>
                    <div className="t-flex t-gap-2 t-sticky t-bottom-0 t-py-4 t-bg-white">
                      <Button
                        block
                        onClick={() => setEnableEdit(false)}
                        type="button"
                      >
                        Cancel
                      </Button>
                      <Button
                        block
                        customType="primary"
                        type="submit"
                        isLoading={updatingGroupRaiseProfile}
                        disabled={updatingGroupRaiseProfile}
                      >
                        Save
                      </Button>
                    </div>
                  </RaiseProfileEdit>
                </Formik>
              ) : (
                <RaiseProfileInfo currentIndex={null}>
                  <div className="t-flex t-gap-2 t-sticky t-bottom-0 t-py-4 t-bg-white">
                    <Button
                      block
                      type="submit"
                      customType="primary"
                      onClick={() => setEnableEdit(true)}
                    >
                      Edit
                    </Button>
                  </div>
                </RaiseProfileInfo>
              )}
            </Slider>
            <Slider
              open={openInvestorSlider}
              position="right"
              width={500}
              className="t-overflow-auto t-rounded t-border t-border-solid t-border-neutral-0 t-shadow !t-z-overlay !t-fixed t-w-[500px] !t-p-0"
            >
              <div className="t-flex t-justify-between t-border-b t-border-neutral-10 t-border-solid t-border-0 t-p-4 t-sticky t-top-0 t-bg-surface t-z-10">
                <div className="t-flex t-gap-2 t-items-center">
                  {rowData?.vc?.name && (
                    <div className="t-bg-surface-grey t-w-8 t-h-8 t-flex t-justify-center t-items-center t-rounded-full">
                      {rowData?.vc?.name?.at(0)?.toUpperCase()}
                    </div>
                  )}

                  {rowData?.vc?.name && <span>{rowData?.vc?.name}</span>}
                </div>
                <Button
                  customType="ghost_icon"
                  size="small"
                  onClick={() => setOpenInvestorSlider(false)}
                >
                  <Cross color="currentColor" />
                </Button>
              </div>
              <InvestorView investorId={rowData?.uuid!} />
            </Slider>
          </Async.Success>
        </Async.Root>
      </div>
    </DashboardLayout>
  );
};
