import Logo from "components/inkleLogo";
// import { ContractorFormHelp } from 'ContractorFormHelp';
import { ReactNode, useEffect } from "react";
import "static/styles/components/contractorPageLayout.css";

const options = {
  enabled: true,
  chatButtonSetting: {
    backgroundColor: "#4dc247",
    ctaText: "Message Us",
    borderRadius: "25",
    marginLeft: "0",
    marginBottom: "50",
    marginRight: "50",
    position: "right",
  },
  brandSetting: {
    brandName: "Inkle",
    brandSubTitle: "Typically replies within a day",
    brandImg:
      "https://inkle-django-files.s3.ap-south-1.amazonaws.com/display_picture/76a46618b0d04b50be13d8301927518c.png",
    welcomeText: "Hi there!\nHow can I help you?",
    messageText: "Hello, I have a question about {{page_link}}",
    backgroundColor: "#0a5f54",
    ctaText: "Start Chat",
    borderRadius: "25",
    autoShow: false,
    phoneNumber: "16282774045",
  },
};

const Header = () => {
  return (
    <div className="contractor-page-header">
      <Logo />
      <div className="vr" />
      W-Series Form
    </div>
  );
};

export const ContractorPageLayout = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    try {
      const waScript = document.getElementById("wa-script");
      if (!waScript) {
        const url =
          "https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?10413";
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = url;
        s.id = "wa-script";
        s.onload = function () {
          // @ts-ignore
          CreateWhatsappChatWidget(options);
        };
        const x = document.getElementsByTagName("script")[0];
        x.parentNode?.insertBefore(s, x);
      }

      if (waScript) {
        try {
          // @ts-ignore
          CreateWhatsappChatWidget(options);
        } catch (error) {}
      }

      return () => {
        const wAppWidget = document.querySelectorAll("#whatsapp-chat-widget");
        wAppWidget.forEach((e) => e.remove());
      };
    } catch (error) {}
  }, []);

  return (
    <div className="">
      <Header />
      <div className="contractor-page-body">{children}</div>
    </div>
  );
};
