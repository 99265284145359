import dayjs from "dayjs";
import React, { forwardRef, useState } from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const CustomInput = forwardRef(({ value, ...props }, ref) => (
  <Form.Control
    value={value}
    ref={ref}
    {...props}
    type="text"
    autoComplete="off"
  />
));

CustomInput.displayName = "CustomInput";

export const DatePicker = ({
  defaultValue,
  name,
  placeholder,
  min,
  max,
  ...props
}) => {
  const date = defaultValue
    ? dayjs(defaultValue, ["DD/MM/YYYY", "YYYY-MM-DD"]).toDate()
    : "";

  const [value, setValue] = useState(date);

  return (
    <ReactDatePicker
      showPopperArrow={false}
      selected={value}
      onChange={setValue}
      name={name}
      dateFormat="dd-MM-yyyy"
      placeholderText={placeholder}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      minDate={min ? dayjs(min).toDate() : null}
      maxDate={max ? dayjs(max).toDate() : null}
      customInput={<CustomInput {...props} defaultValue={defaultValue} />}
    />
  );
};
