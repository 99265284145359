import { CapTableHome } from "components/CapTable/CapTableHome";
import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect } from "react";
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useGetEntityDataAttributesQuery } from "store/apis/entity";
import { useGetGroupDataQuery } from "store/apis/group";
import { DirectorsTable } from "./DirectorsTable";
import EntityFormEngine from "./EntityFormEngine/EntityFormEngine";
import { OfficersTable } from "./OfficersTable";
import { ShareHolders } from "./Shareholders/Shareholders";

const EntityDetail = ({ parentUrl }: { parentUrl?: string }) => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { groupId } = useParams<{ groupId?: string }>();
  const { entityId } = useParams<{ entityId: string }>();
  const { isAdmin } = useRoleBasedView();
  const { uuid: currentGroupId } = useCurrentGroupContext();
  const { data: currentEntityDetails, isLoading } =
    useGetEntityDataAttributesQuery(
      {
        groupId: currentGroupId!,
        entityId,
      },
      {
        skip: !currentGroupId || !entityId,
      }
    );

  const { data: adminGroup } = useGetGroupDataQuery(
    {
      groupId: groupId!,
    },
    {
      skip: !groupId,
    }
  );

  const { dispatch } = useCurrentGroupContext();

  useEffect(() => {
    if (adminGroup) {
      dispatch({ type: "SET_GROUP", payload: adminGroup });
    }
  }, [adminGroup]);

  const { value: entityName = "" } =
    currentEntityDetails?.find(({ name }) => name === "entity_name") || {};

  const name = entityName as string;

  usePageTitle(name || `${"Entity"}`);

  if (isLoading) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  const entityBreadcrumbs = [
    {
      name: "My Entities",
      link: isAdmin
        ? `${parentUrl || ""}/crm/${groupId}`
        : `${parentUrl || ""}/entities`,
    },
    { name: name || " " },
  ];

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title={
            <div className="t-flex t-items-center t-gap-2">
              <div className="t-flex t-text-h5 t-text-text-100">{name}</div>
            </div>
          }
          bottom={
            <Tab.Root defaultValue={pathname} value={pathname}>
              <Tab.List>
                <Tab.NavTrigger replace exact value={url}>
                  Overview
                </Tab.NavTrigger>
                <Tab.NavTrigger
                  replace
                  exact
                  value={`${url}/directors-officers`}
                >
                  Directors & Officers
                </Tab.NavTrigger>
                <Tab.NavTrigger exact value={`${url}/cap-table`}>
                  Cap Table
                </Tab.NavTrigger>
                <Tab.NavTrigger exact value={`${url}/shareholders`}>
                  Shareholders
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
          breadcrumbs={entityBreadcrumbs}
        />
      }
    >
      <Switch>
        <Route exact path={path}>
          <EntityFormEngine />
        </Route>
        <Route exact path={`${path}/directors-officers`}>
          <div className="t-flex t-flex-col t-gap-8">
            <DirectorsTable />
            <OfficersTable />
          </div>
        </Route>
        <Route exact path={`${path}/cap-table`}>
          <CapTableHome />
        </Route>
        <Route exact path={`${path}/shareholders`}>
          <ShareHolders />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

export default EntityDetail;
