import { date, number, object, string } from "yup";

export const addProductToCartValidation = object({
  entity_id: string().required("Please select Entity"),
  season: string().when("has_season", {
    is: true,
    then: string().required("Please select Season"),
    otherwise: string().notRequired(),
  }),
  state_id: string().when("has_states", {
    is: true,
    then: string().required("Please select state"),
    otherwise: string().notRequired(),
  }),
  payment_amount: number().when("has_states", {
    is: true,
    then: number()
      .positive()
      .min(1, "Please enter a valid price.")
      .required("Please select state"),
    otherwise: number().notRequired(),
  }),
  to_date: date()
    .when("from_date", (from_date, schema) => {
      return from_date
        ? schema.min(from_date, "End date must be after start date")
        : schema;
    })
    .nullable(),
});
