export const PAYMENT_METHOD = [
  {
    name: "FedWire",
    key: "12",
    required: "Routing Number",
  },
  {
    name: "ACH Credit",
    key: "13",
    required: "Routing Number",
  },
  {
    name: "RTP",
    key: "14",
    required: "Routing Number",
  },
  { name: "SWIFT", key: 15, required: "BIC" },
];

export const ROUTING_NUMBER = "Routing Number";
export const ABA_CODE = "ABA Code/Routing Number";
export const BIC = "BIC";

export const ARJAV_CALENDLY_LINK = "https://calendly.com/arjav-inkle/30min";
export const TP_COMPLIANCE_CALENDLY_LINK =
  "https://calendly.com/arjav-inkle/transfer-pricing-compliances";
export const SWIFT_BLOG_LINK =
  "https://www.inkle.io/core-products/tp-payments#comparison";
export const SQUAREFEET_BLOG_LINK =
  "https://www.inkle.io/case-studies/91squarefeet";
