import { SubscriptionModal } from "./SubscriptionModal";
import {
  closeSubscriptionModal,
  closeUpgradePlanModal,
  openSubscriptionModal,
  setRedirectPath,
  setRequiredSubscriptionId,
} from "store/slices/subscriptionModal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useHistory } from "react-router-dom";
import {
  useGetSubscriptionByIdQuery,
  useGetSubscriptionsQuery,
} from "store/apis/subscriptions";
import { useGetSubscriptionsToShow } from "hooks/useGetSubscriptionsToShow";
import Modal from "components/DesignSystem/Modal/Modal";
import LockOnboarding from "static/images/LockOnboarding.svg";
import { Button } from "components/DesignSystem/Button/Button";
import { validate as uuidValidate } from "uuid";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { useAnalytics } from "hooks/useAnalytics";
import {
  UPGRADE_PLAN_CLICKED,
  UPGRADE_PLAN_MODAL_OPENED,
} from "constants/analyticsEvents";
import { PLATFORM } from "constants/addons";
import { useEffect } from "react";

export const PlanBaseSubscriptionModal = () => {
  const dispatch = useAppDispatch();
  const { isUpgradePlanModalOpen } = useAppSelector(
    (state) => state.subscriptionModal
  );

  const history = useHistory();
  const { link } = useConstructInternalLink();
  const { upgrade } = useUpgradePlan();
  const { trackEvent } = useAnalytics();

  const {
    isOpen: isSubscriptionModalOpen,
    redirectPath,
    requiredSubscriptionId,
  } = useAppSelector((state) => state.subscriptionModal);

  const isValidUuid = uuidValidate(requiredSubscriptionId);

  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery({});

  const { data: requiredSubscriptionData, isSuccess } =
    useGetSubscriptionByIdQuery(
      {
        subscriptionId: requiredSubscriptionId,
      },
      { skip: !requiredSubscriptionId || !isValidUuid }
    );

  const { allAddOnSubscriptions, booksPlusPlanToshow } =
    useGetSubscriptionsToShow({ allSubscriptions });

  const taxSubscriptions = allSubscriptions
    .filter(({ subscription_type }) => subscription_type === PLATFORM)
    .map(({ uuid }) => uuid);

  const closeSubscription = () => {
    dispatch(closeSubscriptionModal());
    if (redirectPath) {
      history.push(
        link(redirectPath, {
          keys: ["entity"],
        })
      );
    }
  };

  const isTaxSubscriptionRequired =
    taxSubscriptions.includes(requiredSubscriptionId) || !isValidUuid;

  const isBooksPlusPlanRequired =
    booksPlusPlanToshow?.subscription_type ===
    requiredSubscriptionData?.subscription_type;

  const close = () => {
    dispatch(closeUpgradePlanModal());
  };

  const billingPath = link("/settings/billing", {
    keys: ["entity"],
  });

  const onSubscribeHandler = () => {
    close();

    const addon = allAddOnSubscriptions.find(
      ({ subscription_type }) =>
        subscription_type === requiredSubscriptionData?.subscription_type
    );

    if (isTaxSubscriptionRequired) {
      dispatch(openSubscriptionModal());
      dispatch(setRedirectPath(billingPath));
      history.push(billingPath);
      trackEvent(UPGRADE_PLAN_CLICKED, {
        required_subscription:
          requiredSubscriptionData?.subscription_type || PLATFORM,
      });
      dispatch(setRequiredSubscriptionId(""));
      return;
    }

    if (isBooksPlusPlanRequired && booksPlusPlanToshow) {
      upgrade({ addon: booksPlusPlanToshow });
    }

    if (addon) {
      upgrade({ addon: addon });
    }
    if (requiredSubscriptionData?.subscription_name) {
      trackEvent(UPGRADE_PLAN_CLICKED, {
        required_subscription: requiredSubscriptionData.subscription_name,
      });
    }
    dispatch(setRequiredSubscriptionId(""));
    history.push(billingPath);
  };

  useEffect(() => {
    if (isUpgradePlanModalOpen) {
      if (isTaxSubscriptionRequired) {
        trackEvent(UPGRADE_PLAN_MODAL_OPENED, {
          required_subscription:
            requiredSubscriptionData?.subscription_name || PLATFORM,
        });
        return;
      }

      if (isSuccess && requiredSubscriptionData?.subscription_name) {
        trackEvent(UPGRADE_PLAN_MODAL_OPENED, {
          required_subscription: requiredSubscriptionData.subscription_name,
        });
        return;
      }
    }
  }, [isUpgradePlanModalOpen, isSuccess]);

  return (
    <>
      <SubscriptionModal
        onPaymentDone={closeSubscription}
        show={isSubscriptionModalOpen}
        onClose={closeSubscription}
      />
      <Modal.Root open={isUpgradePlanModalOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Upgrade Plan</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-flex t-justify-center t-flex-col t-items-center t-gap-4">
            <img src={LockOnboarding} alt="Lock" />
            <p className="t-text-body-lg t-text-text-100 t-text-center t-m-0">
              You can't access this feature.
              <br /> Upgrade your plan to unlock it.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button customType="primary" block onClick={onSubscribeHandler}>
              Upgrade
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
