import React from "react";
import TaxAndComplianceStart from "components/Illustrations/TaxAndComplianceStart.svg";
import { Button } from "components/DesignSystem/Button/Button";

export const TaxAndComplianceGetStarted = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  return (
    <div className="t-flex t-flex-col t-h-full t-w-full t-justify-center t-items-center">
      <img
        src={TaxAndComplianceStart}
        alt="Get started with compliance"
        className="t-mb-6"
      />
      <p className="t-text-subtitle t-mb-2">Begin Monitoring Compliance</p>
      <p className="t-text-text-30 t-text-body t-mb-5">
        Click the button below to add compliances and monitor them all using our
        dashboard
      </p>
      <Button onClick={onClick} customType="primary">
        Add Compliance
      </Button>
    </div>
  );
};
