import React from "react";

const NoDataAvailable = ({ noDataText = "No Data Available" }) => {
  return (
    <div className="userPage">
      <div className="noTaskFound">{noDataText}</div>
    </div>
  );
};

export default NoDataAvailable;
