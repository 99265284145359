import React from "react";

export const SmallPriceLedger = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.125 5H16.875"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 10H8.125"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 15H9.375"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_2707_4454)">
      <path
        d="M14.375 7.8125V8.75"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 15V15.9375"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5625 10.3125C16.5625 10.1073 16.5221 9.90413 16.4436 9.71456C16.365 9.52499 16.2499 9.35274 16.1049 9.20765C15.9598 9.06255 15.7875 8.94746 15.5979 8.86894C15.4084 8.79042 15.2052 8.75 15 8.75H13.75C13.3356 8.75 12.9382 8.91462 12.6451 9.20765C12.3521 9.50067 12.1875 9.8981 12.1875 10.3125C12.1875 10.7269 12.3521 11.1243 12.6451 11.4174C12.9382 11.7104 13.3356 11.875 13.75 11.875H15.3125C15.7269 11.875 16.1243 12.0396 16.4174 12.3326C16.7104 12.6257 16.875 13.0231 16.875 13.4375C16.875 13.8519 16.7104 14.2493 16.4174 14.5424C16.1243 14.8354 15.7269 15 15.3125 15H13.4375C13.0231 15 12.6257 14.8354 12.3326 14.5424C12.0396 14.2493 11.875 13.8519 11.875 13.4375"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2707_4454">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(9.375 6.875)"
        />
      </clipPath>
    </defs>
  </svg>
);
