import dayjs from "dayjs";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
} from "react-table-8.10.7";
import Table from "components/DesignSystem/Table/V2/Table";
import { useState } from "react";
import ThreeDots from "static/images/ThreeDots.svg";
import { Button } from "components/DesignSystem/Button/Button";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Check } from "components/icons/Check";
import { OptionProps, Props, SingleValue, components } from "react-select";
import { Cross } from "components/icons/Cross";
import ReactDatePicker from "react-datepicker";
import CirclePlus from "components/icons/circlePlus";
import {
  EntityCompliance,
  useCreateEntityComplianceMutation,
  useDeleteEntityComplianceMutation,
  useEditEntityComplianceMutation,
  useGetAllComplianceTypeQuery,
  useGetAllEntityComplianceQuery,
} from "store/apis/taxAndCompliance";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import * as ALL_COMPLIANCE_FREQUENCIES from "constants/complianceFrequencies";
import * as ALL_COMPLIANCE_MONTH_STATUS from "constants/complianceMonthStatuses";
import { DD_MMM_YYYY } from "constants/date";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";

import WarningCircle from "static/images/WarningCircle.svg";
import CheckCircleFilled from "static/images/CheckCircleFilled.svg";
import Hourglass from "static/images/Hourglass.svg";
import Dash from "static/images/dash.svg";
import ToolTip from "components/design/toolTip";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { useQuery } from "hooks/useQuery";
import { TaxAndComplianceGetStarted } from "components/TaxAndComplianceGetStarted/TaxAndComplianceGetStarted";
import { complianceValidation } from "formValidations/complianceValidation";
import { COMPLIANCE_MONTH_STATUSES } from "types/contants/complianceMonthStatuses";
import { useAnalytics } from "hooks/useAnalytics";
import { CLICKED_ADD_COMPLIANCE } from "constants/analyticsEvents";

const ICON_STATUS_MAP = {
  [ALL_COMPLIANCE_MONTH_STATUS.UPCOMING]: Hourglass,
  [ALL_COMPLIANCE_MONTH_STATUS.COMPLETED]: CheckCircleFilled,
  [ALL_COMPLIANCE_MONTH_STATUS.OVERDUE]: WarningCircle,
  [ALL_COMPLIANCE_MONTH_STATUS.NOT_APPLICABLE]: Dash,
};

const StatusOptionLabel = ({
  status,
  title,
}: {
  status: COMPLIANCE_MONTH_STATUSES;
  title: string;
}) => (
  <div className="t-flex t-gap-1 t-items-center">
    <img
      alt={status}
      className="t-w-5 t-max-h-4"
      src={ICON_STATUS_MAP[status]}
    />
    <span>{title}</span>
  </div>
);

const montlyStatusOptions = [
  {
    label: (
      <StatusOptionLabel
        title="Completed"
        status={ALL_COMPLIANCE_MONTH_STATUS.COMPLETED}
      />
    ),
    value: ALL_COMPLIANCE_MONTH_STATUS.COMPLETED,
  },
  {
    label: (
      <StatusOptionLabel
        title="Upcoming"
        status={ALL_COMPLIANCE_MONTH_STATUS.UPCOMING}
      />
    ),
    value: ALL_COMPLIANCE_MONTH_STATUS.UPCOMING,
  },
  {
    label: (
      <StatusOptionLabel
        title="Overdue"
        status={ALL_COMPLIANCE_MONTH_STATUS.OVERDUE}
      />
    ),
    value: ALL_COMPLIANCE_MONTH_STATUS.OVERDUE,
  },
  {
    label: (
      <StatusOptionLabel
        title="N/A"
        status={ALL_COMPLIANCE_MONTH_STATUS.NOT_APPLICABLE}
      />
    ),
    value: ALL_COMPLIANCE_MONTH_STATUS.NOT_APPLICABLE,
  },
];

const FormRow = ({
  cancel,
  loading,
}: {
  cancel: () => void;
  loading?: boolean;
}) => {
  const { values, setFieldValue, errors, resetForm } =
    useFormikContext<Omit<EntityCompliance, "entity">>();

  const group = useCurrentGroup();

  const { data: complianceTypes } = useGetAllComplianceTypeQuery({
    groupId: group.uuid!,
  });

  const onDateSelect = (date: Date) => {
    const nextIndex = values.due_dates.length;
    const indexOfMonth = dayjs(date).month();

    setFieldValue(`due_dates[${nextIndex}]`, dayjs(date).format("YYYY-MM-DD"));
    setFieldValue(
      `monthly_compliance_list.${indexOfMonth}.status`,
      ALL_COMPLIANCE_MONTH_STATUS.UPCOMING
    );
  };

  const editing = Boolean(values.uuid);

  const onDeleteDueDate = (index: number) => {
    const deletingMonth = dayjs(values.due_dates[index]).month();

    const newValues = values.due_dates.slice();
    newValues.splice(index, 1);
    setFieldValue("due_dates", newValues);

    const otherDueDateForTheMonthExists = newValues.some(
      (v) => dayjs(v).month() === deletingMonth
    );

    if (!editing && !otherDueDateForTheMonthExists) {
      setFieldValue(
        `monthly_compliance_list.${deletingMonth}.status`,
        ALL_COMPLIANCE_MONTH_STATUS.NOT_APPLICABLE
      );
    }
  };

  const frequencyOptions = [
    {
      label: "Monthly",
      value: ALL_COMPLIANCE_FREQUENCIES.MONTHLY,
    },
    {
      label: "Quaterly",
      value: ALL_COMPLIANCE_FREQUENCIES.QUARTERLY,
    },
    {
      label: "Annual",
      value: ALL_COMPLIANCE_FREQUENCIES.ANNUAL,
    },
  ];

  return (
    <>
      <td className="compliance-form-cell">
        <TextInput
          hideError
          placeholder="Name"
          customSize="small"
          name="name"
        />
      </td>
      <td className="compliance-form-cell">
        <TextInput
          placeholder="Type"
          hideError
          customSize="small"
          name="compliance_type"
        />
        <datalist id="compliance_type">
          {complianceTypes?.map((c) => (
            <option value={c.compliance_type} key={c.compliance_type}></option>
          ))}
        </datalist>
      </td>
      <td className="compliance-form-cell">
        <Combobox
          type={errors.frequency ? "error" : undefined}
          hideError
          size="small"
          withForm
          name="frequency"
          defaultValue={{
            label: (
              <span className="t-capitalize">
                {values.frequency?.toLocaleLowerCase()}
              </span>
            ),
            value: values.frequency,
          }}
          options={frequencyOptions}
          menuPortalTarget={document.body}
        />
      </td>
      <td className="compliance-form-cell">
        <div className="t-flex t-items-center">
          {values.due_dates.length > 0 && (
            <div className="t-mr-1 t-w-9/12">
              <Combobox
                // @ts-ignore
                onDeletDueDate={onDeleteDueDate}
                value={{
                  label: (
                    <span className="t-line-clamp-1">
                      {values.due_dates
                        .map((v) => dayjs(v).format(DD_MMM_YYYY))
                        .join(" ")}
                    </span>
                  ),
                  value: "",
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    width: "200px",
                  }),
                }}
                size="small"
                components={{
                  Option: DeleteDueDate,
                  ClearIndicator: () => null,
                }}
                options={values.due_dates.map((d) => ({
                  label: dayjs(d).format(DD_MMM_YYYY),
                  value: d,
                }))}
                isOptionDisabled={() => true}
              />
            </div>
          )}

          <ReactDatePicker
            wrapperClassName="t-w-3/12"
            // {...props}
            maxDate={new Date(`${dayjs().year()}-12-31`)}
            showPopperArrow={false}
            onChange={onDateSelect}
            dateFormat="dateFormat"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            // portalId="root-portal-for-calendar"
            customInput={
              <div>
                <Button type="button" customType="ghost_icon" size="small">
                  <CirclePlus color="currentColor" />
                </Button>
              </div>
            }
            autoComplete="off"
            preventOpenOnFocus={true}
          />
        </div>
      </td>
      {dayjs
        .months()
        .map((m) => m.toLocaleUpperCase())
        .map((month, index) => (
          <td key={month} className="compliance-form-cell t-w-[90px]">
            <div className="t-px-1">
              <Combobox
                size="small"
                name={`monthly_compliance_list.${index}.status`}
                withForm
                menuPortalTarget={document.body}
                value={{
                  label: (
                    <img
                      alt={values.monthly_compliance_list[index].status}
                      className="t-w-5 t-max-h-4"
                      src={
                        ICON_STATUS_MAP[
                          values.monthly_compliance_list[index].status
                        ]
                      }
                    />
                  ),
                  value: values.monthly_compliance_list[index].status,
                }}
                placeholder={
                  <img
                    alt={ALL_COMPLIANCE_MONTH_STATUS.NOT_APPLICABLE}
                    src={
                      ICON_STATUS_MAP[
                        ALL_COMPLIANCE_MONTH_STATUS.NOT_APPLICABLE
                      ]
                    }
                  />
                }
                components={{
                  ClearIndicator: () => null,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    width: "160px",
                  }),
                }}
                options={montlyStatusOptions}
              />
            </div>
          </td>
        ))}
      <td className="compliance-form-cell t-sticky t-right-0">
        <div className="t-flex t-gap-1 t-h-14 t-items-center t-justify-end t-p-2">
          <Button
            customType="icon"
            size="small"
            isLoading={loading}
            disabled={loading}
          >
            <span className="t-text-dark_green-50">
              <Check color="currentColor" />
            </span>
          </Button>
          <ToolTip text="Cancel">
            <div>
              <Button
                disabled={loading}
                type="button"
                customType="icon"
                size="small"
                onClick={() => {
                  resetForm();
                  cancel();
                }}
              >
                <Cross />
              </Button>
            </div>
          </ToolTip>
        </div>
      </td>
    </>
  );
};

const DeleteDueDate = ({ children, ...props }: OptionProps<OptionData>) => {
  return (
    <components.Option
      {...props}
      className="[&.select\_\_option--is-focused]:t-bg-surface-lighter-grey [&.select\_\_option--is-selected]:t-bg-i-surface-light-purple"
    >
      <div className="t-flex t-gap-2.5 t-font-medium t-items-center t-justify-between t-px-3">
        <div className="t-cursor-pointer t-truncate !t-border-none t-py-2.5">
          {children}
        </div>
        <Button
          customType="ghost_icon"
          size="small"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            // @ts-ignore
            props.selectProps?.onDeletDueDate?.(props.data.index);
          }}
        >
          <Cross color="currentColor" />
        </Button>
      </div>
    </components.Option>
  );
};

export const ComplianceTable = () => {
  const group = useCurrentGroup();
  const query = useQuery();
  let entityId = query.get("entity");
  const [editComplianceId, setEditComplianceId] = useState<string | null>();
  const [addNewCompliance, setAddNewCompliance] = useState(false);
  const { trackEvent } = useAnalytics();

  const { data: compliances } = useGetAllEntityComplianceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
    },
    { skip: !group?.uuid || !entityId }
  );

  const [createCompliance, { isLoading: creating }] =
    useCreateEntityComplianceMutation();
  const [editCompliance, { isLoading: updating, originalArgs: updatingArgs }] =
    useEditEntityComplianceMutation();
  const [deleteCompliance, { isLoading: deleting, originalArgs: deleteArgs }] =
    useDeleteEntityComplianceMutation();

  const startEdit = (id: string) => {
    setAddNewCompliance(false);
    setEditComplianceId(id);
  };

  const onDelete = (id: string) => {
    deleteCompliance({
      groupId: group.uuid!,
      entityId: entityId!,
      complianceId: id,
    });
  };

  const columnHelper = createColumnHelper<EntityCompliance>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => {
        return (
          <ToolTip text={info.getValue()}>
            <span className="t-line-clamp-1 t-max-w-full t-inline-block">
              {info.getValue()}
            </span>
          </ToolTip>
        );
      },
      header: "Compliance Name",
      size: 200,
    }),
    columnHelper.accessor("compliance_type", {
      id: "lastName",
      cell: (info) => {
        const value = info.getValue();

        return value ? (
          <ToolTip text={value}>
            <div className="t-inline-block t-max-w-full">
              <Tag>
                <span className="t-whitespace-nowrap t-inline-block t-w-full t-overflow-hidden t-text-ellipsis t-text-body-sm t-line-clamp-1">
                  {value}
                </span>
              </Tag>
            </div>
          </ToolTip>
        ) : null;
      },
      header: "Compliance type",
      size: 180,
    }),
    columnHelper.accessor("frequency", {
      header: () => "Frequency",
      cell: (info) => (
        <span className="t-capitalize">
          {info.getValue()?.toLocaleLowerCase()}
        </span>
      ),
      size: 120,
    }),
    columnHelper.accessor("due_dates", {
      header: () => <span>Due Date</span>,
      size: 140,
      cell: (info) => {
        const value = info.getValue();

        if (value && value.length > 0) {
          const firstValue = value[0];

          return (
            <Dropdown.Root modal={false}>
              <Dropdown.Trigger asChild>
                <div>
                  <div>{dayjs(firstValue).format(DD_MMM_YYYY)}</div>
                  {value.length > 1 && (
                    <Button type="button" customType="link">
                      {value.length - 1} more
                    </Button>
                  )}
                </div>
              </Dropdown.Trigger>
              <Dropdown.Portal>
                <Dropdown.Content>
                  {value.map((v) => (
                    <Dropdown.Item key={v}>
                      {dayjs(v).format(DD_MMM_YYYY)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Content>
              </Dropdown.Portal>
            </Dropdown.Root>
          );
        }
        return <div>&nbsp;</div>;
      },
    }),
    ...dayjs
      .months()
      .map((m) => m.toLocaleUpperCase())
      .map((m) => {
        return m;
      })
      .map((monthName) =>
        columnHelper.display({
          header: () => (
            <span className="t-text-center t-block">
              {monthName.slice(0, 3)}
            </span>
          ),
          id: monthName,
          size: 90,
          cell: (info) => {
            const monthData = info.row.original.monthly_compliance_list?.find(
              ({ month }) => month === monthName
            );

            const status = monthData?.status;

            if (status) {
              return (
                <ToolTip text={status}>
                  <div className="t-flex t-justify-center t-min-h-5">
                    <img src={ICON_STATUS_MAP[status]} />
                  </div>
                </ToolTip>
              );
            }

            return null;
          },
        })
      ),
    columnHelper.display({
      id: "Actions",
      size: 90,
      cell: (info) => (
        <div className="t-flex t-justify-center t-w-full t-bg-surface">
          <Dropdown.Root>
            <Dropdown.Trigger asChild>
              <div>
                <Button
                  type="button"
                  customType="ghost_icon"
                  isLoading={
                    deleting &&
                    deleteArgs?.complianceId === info.row.original.uuid
                  }
                  disabled={
                    deleting &&
                    deleteArgs?.complianceId === info.row.original.uuid
                  }
                >
                  <img src={ThreeDots} />
                </Button>
              </div>
            </Dropdown.Trigger>
            <Dropdown.Portal>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  onClick={() => startEdit(info.row.original.uuid)}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onDelete(info.row.original.uuid)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Portal>
          </Dropdown.Root>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data: compliances || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { successToast, alertToast } = useToast();

  const onCreate = async (
    values: Omit<EntityCompliance, "entity" | "uuid">,
    helpers: FormikHelpers<Omit<EntityCompliance, "entity" | "uuid">>
  ) => {
    try {
      await createCompliance({
        groupId: group.uuid!,
        entityId: entityId!,
        payload: values,
      }).unwrap();
      successToast({ message: "Compliance check has been created!" });
      setAddNewCompliance(false);
      helpers.resetForm();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onEditSave = async (
    values: Omit<EntityCompliance, "entity" | "uuid">,
    helpers: FormikHelpers<Omit<EntityCompliance, "entity" | "uuid">>
  ) => {
    try {
      await editCompliance({
        groupId: group.uuid!,
        entityId: entityId!,
        complianceId: editComplianceId!,
        payload: values,
      }).unwrap();
      successToast({ message: "Compliance check has been edited!" });
      setEditComplianceId(null);
      helpers.resetForm();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const initialMonthlyComplianceList: EntityCompliance["monthly_compliance_list"] =
    dayjs
      .months()
      .map((s) => s.toUpperCase())
      .map((m) => ({
        month: m,
        status: ALL_COMPLIANCE_MONTH_STATUS.NOT_APPLICABLE,
      }));

  const complianceInEdit = compliances?.find(
    ({ uuid }) => uuid === editComplianceId
  );

  const cancelForm = () => {
    setEditComplianceId(null);
    setAddNewCompliance(false);
  };

  const handleAddCompliance = () => {
    setAddNewCompliance(true);
    trackEvent(CLICKED_ADD_COMPLIANCE);
  };

  if (!addNewCompliance && compliances?.length === 0) {
    return <TaxAndComplianceGetStarted onClick={handleAddCompliance} />;
  }

  return (
    <Formik
      onSubmit={complianceInEdit ? onEditSave : onCreate}
      enableReinitialize
      validationSchema={complianceValidation}
      initialValues={
        complianceInEdit || {
          uuid: "",
          name: "",
          compliance_type: "",
          frequency: ALL_COMPLIANCE_FREQUENCIES.MONTHLY,
          due_dates: [],
          monthly_compliance_list: initialMonthlyComplianceList,
        }
      }
    >
      {({ values: {} }) => (
        <Form className="t-m-0 t-h-full">
          <Table.Container className="t-h-full" layout="flex">
            <Table.Content className="t-w-full">
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        className="t-text-subtext-sm t-uppercase t-py-2 compliance-form-cell"
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) =>
                  // @ts-ignore
                  values.uuid === row.original.uuid ? (
                    <Table.Row key={row.original.uuid}>
                      <FormRow
                        loading={
                          updating &&
                          updatingArgs?.complianceId === row.original.uuid
                        }
                        cancel={cancelForm}
                      />
                    </Table.Row>
                  ) : (
                    <Table.Row key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{ width: cell.column.getSize() }}
                          className="compliance-form-cell"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </Table.Row>
                  )
                )}
                {addNewCompliance ? (
                  <Table.Row className="t-px-3 t-flex t-items-center t-border t-border-solid t-border-neutral-0 t-border-b t-border-l-0 t-border-r-0 t-border-t-0 t-text-body">
                    <FormRow cancel={cancelForm} loading={creating} />
                  </Table.Row>
                ) : (
                  !complianceInEdit && (
                    <Table.Row className="t-px-3 t-flex t-items-center t-border t-border-solid t-border-neutral-0 t-border-b t-border-l-0 t-border-r-0 t-border-t-0 t-text-body t-sticky t-bottom-0">
                      <td className="t-px-2 t-py-4 t-sticky t-left-0">
                        <Button type="button" onClick={handleAddCompliance}>
                          Add Compliance
                        </Button>
                      </td>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </Form>
      )}
    </Formik>
  );
};
