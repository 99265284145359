import { emptyApi } from "./emptyApi";
import { Profile } from "types/Models/user";

export const profileApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<Profile, void>({
      query: () => {
        return {
          url: `/api/inkle/users/profile/`,
        };
      },
      providesTags: (result) => [{ type: "PROFILE" }],
    }),
    getUserEtdCount: build.query<{ task_etd_pending_count: number }, void>({
      query: () => {
        return {
          url: `/api/inkle/users/pending_etd_count/`,
        };
      },
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useGetUserEtdCountQuery,
} = profileApi;
