import React from "react";

export const CaretRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_1049_8108)">
      <path
        d="M6 3L11 8L6 13"
        stroke="currentColor"
        stroke-width="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1049_8108">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(-1 0 0 -1 16 16)"
        />
      </clipPath>
    </defs>
  </svg>
);
