export const Vendors = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_681)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 5.53183C13.3866 5.53183 13.7 5.84523 13.7 6.23183V13C13.7 13.3182 13.5736 13.6235 13.3485 13.8485C13.1235 14.0736 12.8182 14.2 12.5 14.2H3.49999C3.18173 14.2 2.8765 14.0736 2.65146 13.8485C2.42642 13.6235 2.29999 13.3182 2.29999 13V6.48183C2.29999 6.09523 2.61339 5.78183 2.99999 5.78183C3.38659 5.78183 3.69999 6.09523 3.69999 6.48183V12.8H12.3V6.23183C12.3 5.84523 12.6134 5.53183 13 5.53183Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.37499 1.79999H12.625C12.8857 1.80004 13.1394 1.88497 13.3475 2.04193C13.5556 2.1989 13.7069 2.41936 13.7786 2.66999L14.6732 5.80809C14.7334 6.01926 14.691 6.24643 14.5587 6.42169C14.4264 6.59695 14.2196 6.69999 14 6.69999H1.99999C1.78035 6.69999 1.57344 6.59689 1.44116 6.42154C1.30889 6.24619 1.26659 6.01892 1.32692 5.80772L2.22317 2.67022C2.29464 2.42033 2.44537 2.20018 2.65258 2.04329C2.85979 1.8864 3.11233 1.80102 3.37223 1.79999L3.37499 1.79999ZM12.4739 3.19999H3.52783L2.92795 5.29999H13.0726L12.4739 3.19999Z"
        fill="currentColor"
      />
      <g clip-path="url(#clip1_6445_681)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.12499 8.69999C7.54509 8.69999 7.07499 9.17009 7.07499 9.74999C7.07499 10.3299 7.54509 10.8 8.12499 10.8C8.70489 10.8 9.17499 10.3299 9.17499 9.74999C9.17499 9.17009 8.70489 8.69999 8.12499 8.69999ZM5.67499 9.74999C5.67499 8.39689 6.77189 7.29999 8.12499 7.29999C9.47809 7.29999 10.575 8.39689 10.575 9.74999C10.575 11.1031 9.47809 12.2 8.12499 12.2C6.77189 12.2 5.67499 11.1031 5.67499 9.74999Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.12498 12.2C7.25876 12.2 6.51929 12.6674 6.10584 13.3819C5.91219 13.7165 5.48396 13.8307 5.14936 13.6371C4.81475 13.4434 4.70048 13.0152 4.89413 12.6806C5.53997 11.5646 6.7244 10.8 8.12498 10.8C9.52556 10.8 10.71 11.5646 11.3558 12.6806C11.5495 13.0152 11.4352 13.4434 11.1006 13.6371C10.766 13.8307 10.3378 13.7165 10.1441 13.3819C9.73067 12.6674 8.9912 12.2 8.12498 12.2Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6445_681">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_6445_681">
        <rect
          width="7"
          height="7"
          fill="white"
          transform="translate(4.55005 6.73184)"
        />
      </clipPath>
    </defs>
  </svg>
);
