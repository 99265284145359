import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { ComponentProps } from "react";

export const Link = (props: ComponentProps<typeof ConditionalLink>) => (
  <ConditionalLink
    {...props}
    className={classNames(
      "t-text-purple hover:t-text-purple-80",
      props.className
    )}
  />
);
