import CopyCode from "components/design/copyCode";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowRight } from "components/icons/ArrowRight";
import {
  ABA_CODE,
  BIC,
  PAYMENT_METHOD,
  ROUTING_NUMBER,
} from "constants/tppayments";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CopyPurple from "static/images/CopyPurple.svg";
import StepCheckMark from "static/images/StepCheckMark.svg";
import { closeFloatingChat } from "store/slices/chat";
import { resetAllTPStates } from "store/slices/transferpricing";
import { RootState } from "store/store";
import { TransferPricingTransfer } from "types/Models/TransferPricingTransfer";
import { currency as currencyFormatter } from "utils/Currency";
import { PaymentInitiateModal } from "../TransferTable/PaymentInitiateModal";

export const TransferMoneyStep = ({
  setCurrentStep,
}: {
  setCurrentStep: (v: number) => void;
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { isAdmin } = useRoleBasedView();
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    name: string;
    key: string;
    required: string;
  }>({ name: "FedWire", key: "12", required: "ABA Code" });

  const [currentTransferDetails, setCurrentTransferDetails] =
    useState<TransferPricingTransfer>();

  const { accountInfo, amount, purpose_code, tpPaymentId, currency } =
    useSelector((state: RootState) => state.tpPaymentsSlice);
  const dispatch = useDispatch();

  const { receiver, sender_bank_account, receiver_bank_account } =
    accountInfo || {};

  const [openInitiatPaymentModal, setOpenInitiatePaymentModal] =
    useState(false);

  const getSelectedPaymentMode = () => {
    if (selectedPaymentMethod?.required === ABA_CODE) {
      return sender_bank_account?.domestic_wire || "";
    }
    if (selectedPaymentMethod?.required === ROUTING_NUMBER) {
      if (selectedPaymentMethod.name === "FedWire") {
        return sender_bank_account?.domestic_wire || "";
      }
      return sender_bank_account?.domestic_credit || "";
    }
    if (selectedPaymentMethod?.required === BIC) {
      return sender_bank_account?.global_wire || "";
    }
    return sender_bank_account?.domestic_wire || "";
  };

  const handleCheckBoxChange = () => {
    setOpenInitiatePaymentModal(true);
    setIsCheckBoxSelected((prev) => !prev);
  };

  const handleGoToDashboard = () => {
    dispatch(resetAllTPStates());
    if (isAdmin) {
      history.push(`/books/transfers/${search}`);
    } else {
      history.push(`/intragroup/transfers/${search}`);
    }
    setCurrentStep(1);
    dispatch(closeFloatingChat());
  };

  return (
    <div className="t-flex t-flex-1 t-w-full t-justify-start t-mx-14 t-gap-6">
      <div className="t-flex t-flex-col t-items-start t-w-1/2">
        <div className="t-flex t-items-center t-justify-center t-pb-6">
          <img src={StepCheckMark} width="78px" height="78px" alt="check" />
        </div>
        <div className="t-flex t-flex-col t-gap-4">
          <div className="t-flex t-text-h4">It’s almost done!</div>
          <div className="t-flex t-flex-col t-gap-2">
            <div className="t-text-subtitle-sm">Here are you next steps</div>
            <ol className="t-text-body-lg">
              <li>
                Please initiate a{" "}
                <span className="t-font-semibold">{`${currencyFormatter({
                  amount,
                  currencyCode: "USD",
                })}`}</span>{" "}
                payment to your Virtual Account (VAC) to receive funds in{" "}
                <span className="t-font-semibold">
                  {receiver_bank_account?.domestic_credit?.slice(0, 4)} - &nbsp;
                  {receiver_bank_account?.account_number.slice(-4)}
                </span>{" "}
                from online banking.
              </li>
              <li>
                Once done let us know below if you have initiated the payment.
              </li>
            </ol>
            {!currentTransferDetails?.initiated_date && (
              <div className="t-w-[280px] t-rounded t-border t-border-solid t-border-purple-40 t-p-2 t-bg-surface-purple">
                <Checkbox
                  label="Yes, I have initiated the payment"
                  name="ispaymentinitiated"
                  onChange={handleCheckBoxChange}
                  checked={isCheckBoxSelected}
                />
              </div>
            )}
          </div>
          <div className="t-flex t-justify-center t-gap-3">
            <Button customType="primary" onClick={handleGoToDashboard} block>
              Go to dashboard
            </Button>
          </div>
        </div>
      </div>
      <div className="t-p-6 t-rounded-lg t-border t-border-solid t-border-neutral-0 t-bg-surface-lighter-grey t-w-1/2 t-flex t-flex-col t-gap-4 t-mt-32">
        <div className="t-text-subtitle-sm">Transfer Instructions</div>
        <div className="t-flex t-flex-col t-gap-4">
          <div className="t-flex t-gap-2">
            <div className="t-text-body t-text-text-30">Recipient Name:</div>
            <div className="t-flex t-gap-1 t-text-body">
              {receiver?.name && (
                <CopyCode
                  getCopyText={() => receiver.name}
                  defaultText={receiver.name}
                  copyIcon={CopyPurple}
                  /* @tw */
                  addClassName="all:unset"
                  alert={false}
                  codeCopiedText="Copied"
                />
              )}
            </div>
          </div>
          <div className="t-flex t-gap-2">
            <div className="t-text-body t-text-text-30">Bank:</div>
            <div className="t-text-body">
              {sender_bank_account?.bank_name && (
                <div className="t-flex t-gap-1 t-text-body">
                  <CopyCode
                    getCopyText={() => sender_bank_account?.bank_name}
                    defaultText={sender_bank_account?.bank_name}
                    copyIcon={CopyPurple}
                    /* @tw */
                    addClassName="all:unset"
                    alert={false}
                    codeCopiedText="Copied"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="t-flex t-gap-2">
            <div className="t-text-body t-text-text-30">Address:</div>
            <div className="t-text-body">
              {sender_bank_account?.bank_address && (
                <CopyCode
                  getCopyText={() => sender_bank_account?.bank_address}
                  defaultText={sender_bank_account?.bank_address}
                  copyIcon={CopyPurple}
                  /* @tw */
                  addClassName="all:unset"
                  alert={false}
                  codeCopiedText="Copied"
                />
              )}
            </div>
          </div>
          <div className="t-flex">
            <div className="t-flex t-w-[200px] t-flex-col t-pr-6 t-gap-2">
              <div className="t-text-body t-text-text-30">Payment Method</div>
              <div>
                <DropDown.Root>
                  <DropDown.Trigger asChild>
                    <div className="secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-2 t-text-body t-font-medium t-drop-shadow-i-dropdown">
                      <div className="t-text-body">
                        {selectedPaymentMethod.name}
                      </div>
                      <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                        <ArrowRight stroke="2.5" color="currentColor" />
                      </div>
                    </div>
                  </DropDown.Trigger>
                  <DropDown.Portal>
                    <DropDown.Content className="t-w-[180px] t-py-2">
                      {PAYMENT_METHOD.map((ele: any) => (
                        <DropDown.Item
                          key={ele.key}
                          onSelect={() => {
                            setSelectedPaymentMethod(ele);
                          }}
                        >
                          <div className="t-text-body">{ele.name}</div>
                        </DropDown.Item>
                      ))}
                    </DropDown.Content>
                  </DropDown.Portal>
                </DropDown.Root>
              </div>
            </div>
          </div>
          <div className="t-flex t-flex-col t-pr-4">
            <div className="t-text-body t-text-text-30">A/C Number</div>
            <div className="t-flex t-gap-1 t-pt-2 t-text-body">
              <CopyCode
                getCopyText={() => sender_bank_account?.account_number}
                defaultText={sender_bank_account?.account_number}
                copyIcon={CopyPurple}
                /* @tw */
                addClassName="all:unset"
                alert={false}
                codeCopiedText="Copied"
              />
            </div>
          </div>
          <div className="t-flex t-flex-col">
            <div className="t-text-body t-text-text-30">
              {selectedPaymentMethod?.required}
            </div>
            <div className="t-flex t-gap-1 t-pt-2 t-text-body ">
              <CopyCode
                getCopyText={getSelectedPaymentMode}
                defaultText={getSelectedPaymentMode()}
                copyIcon={CopyPurple}
                /* @tw */
                addClassName="all:unset"
                alert={false}
                codeCopiedText="Copied"
              />
            </div>
          </div>
        </div>
      </div>
      <PaymentInitiateModal
        isOpen={openInitiatPaymentModal}
        close={() => setOpenInitiatePaymentModal(false)}
        tpPaymentId={tpPaymentId}
        amount={amount}
        currency={currency}
        purposeCodeUuid={purpose_code.uuid}
        isCheckBoxSelected={isCheckBoxSelected}
        setIsCheckBoxSelected={setIsCheckBoxSelected}
        setCurrentTransferDetails={setCurrentTransferDetails}
      />
    </div>
  );
};
