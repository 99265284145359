import { TASK_SESSION_ID } from "constants/session";
import { setOrGetRandomSessionId } from "utils/sessionId";
import { emptyApi } from "./emptyApi";

export const task7004Apis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTask7004: build.query<any, null>({
      query: () => {
        const sessionId = setOrGetRandomSessionId(TASK_SESSION_ID);
        return {
          url: `/api/inkle/tasks/form_template/${process.env.REACT_APP_EXTENSION_TASK_TEMPLATE_UUID}/?session_id=${sessionId}`,
        };
      },
      providesTags: (_result, _error) => [{ type: "Task7004", id: "Task" }],
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { useGetTask7004Query } = task7004Apis;
