import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Task {
  showPricingModal: boolean;
  isPricingStep: boolean;
}

const initialState: Task = {
  showPricingModal: false,
  isPricingStep: false,
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setShowPricingModal: (state, action: PayloadAction<boolean>) => {
      state.showPricingModal = action.payload;
    },
    setIsPricingStep: (state, action: PayloadAction<boolean>) => {
      state.isPricingStep = action.payload;
    },
  },
});

export const { setShowPricingModal, setIsPricingStep } = taskSlice.actions;

export default taskSlice.reducer;
