export const TemplateButtonIcon = ({ color = "#888399" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.3125 16.875H3.72655C3.64726 16.8761 3.56857 16.8612 3.49511 16.8313C3.42165 16.8015 3.35492 16.7572 3.29884 16.7011C3.24277 16.6451 3.1985 16.5783 3.16864 16.5049C3.13878 16.4314 3.12394 16.3527 3.12499 16.2734V9.6875C3.12499 7.78126 3.88224 5.95309 5.23016 4.60517C6.57808 3.25725 8.40625 2.5 10.3125 2.5V2.5C11.2564 2.5 12.191 2.68591 13.063 3.04712C13.9351 3.40832 14.7274 3.93775 15.3948 4.60517C16.0622 5.27259 16.5917 6.06494 16.9529 6.93696C17.3141 7.80899 17.5 8.74362 17.5 9.6875V9.6875C17.5 10.6314 17.3141 11.566 16.9529 12.438C16.5917 13.3101 16.0622 14.1024 15.3948 14.7698C14.7274 15.4373 13.9351 15.9667 13.063 16.3279C12.191 16.6891 11.2564 16.875 10.3125 16.875V16.875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.8125 8.75H12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.8125 11.25H12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
