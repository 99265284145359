import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import React from "react";
import { useHistory } from "react-router-dom";
import WarningCircle from "static/images/WarningCircle.svg";

export const UnpaidDues = ({ amount }: { amount: number }) => {
  const history = useHistory();
  const entityId = useCurrentEntityId();

  const invoicePage = entityId
    ? `/settings/billing/invoices?entity=${entityId}`
    : "/settings/billing/invoices";

  return (
    <div className="t-p-2 t-border t-border-solid t-rounded-lg t-border-red-10 t-bg-red-0 t-flex t-justify-between t-items-center">
      <div className="t-text-text-60 t-text-subtext t-flex t-gap-2 t-items-center">
        <img src={WarningCircle} alt="Invoices due" className="t-w-5 t-h-5" />
        <span>
          Invoices due: <AmountSuperScript amount={amount} />
        </span>
      </div>
      <Button
        customType="danger-outlined"
        size="small"
        onClick={() => history.push(invoicePage)}
      >
        View all invoices
      </Button>
    </div>
  );
};
