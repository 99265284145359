import * as Sentry from "@sentry/browser";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { AddCardStyle } from "components/billing/AddCardModal";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useAuth } from "hooks/useAuth";
import { useServiceTeamId } from "hooks/useServiceTeamId";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import PoweredByStripe from "static/images/PoweredByStripe.svg";
import { useSavePracticePaymentMethodMutation } from "store/apis/practiceBilling";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const AddPaymentMethod = ({ close, isOpen }: ModalProps) => {
  const { alertToast, successToast } = useToast();
  const serviceTeamId = useServiceTeamId();
  const [isComplete, setIsComplete] = useState(false);
  const { name, email, mobile } = useAuth();
  const elements = useElements();
  const stripe = useStripe();

  const [savePracticePaymentMethod] = useSavePracticePaymentMethodMutation();

  const onCardSubmit = async ({
    isDefaultCard,
  }: {
    isDefaultCard: boolean;
  }) => {
    try {
      if (elements && stripe) {
        const paymentElement = elements.getElement(CardElement);

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: paymentElement!,
          billing_details: {
            name,
            email,
            phone: mobile,
          },
        });

        if (error) {
          throw error;
        }

        await savePracticePaymentMethod({
          serviceTeamId,
          paymentMethodId: paymentMethod?.id!,
          isDefaultCard,
        }).unwrap();
      }
      successToast({ message: "Payment method added successfully" });
      close();
    } catch (error) {
      if ((error as StripeError).message) {
        Sentry.captureException(error);
        alertToast({ message: (error as StripeError).message });
      }

      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik initialValues={{ isDefaultCard: false }} onSubmit={onCardSubmit}>
        {({
          submitForm,
          isSubmitting,
          setFieldValue,
          values: { isDefaultCard },
        }) => (
          <Modal.Content useCustomOverlay>
            <Modal.Header>
              <Modal.Title>Add card</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-flex-col t-gap-6 t-m-0">
                <div className="t-rounded-lg t-border t-border-solid t-border-neutral-0 t-p-3">
                  <CardElement
                    options={AddCardStyle}
                    onChange={({ complete }) => setIsComplete(complete)}
                  />
                </div>
                <Checkbox
                  label="Mark as default"
                  name="isDefaultCard"
                  checked={isDefaultCard}
                  onChange={(e) =>
                    setFieldValue("isDefaultCard", e.target.checked)
                  }
                />
                <img
                  src={PoweredByStripe}
                  className="t-mx-auto  t-flex"
                  alt="Powered by stripe"
                />
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button onClick={close} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                customType="primary"
                onClick={submitForm}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isComplete}
              >
                Confirm billing method
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};
