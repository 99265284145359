import dayjs from "dayjs";
import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const groupMessageFileByMonth = (
  messages: {
    message: MessageResponse<DefaultStreamChatGenerics>;
  }[]
): [string, MessageResponse<DefaultStreamChatGenerics>[]][] =>
  messages.reduce((acc, message) => {
    const messageCreatedAt = message.message.created_at;
    const monthSent = dayjs(messageCreatedAt).format("MMMM");
    const messageSentPastWeek =
      dayjs().diff(dayjs(messageCreatedAt), "days") < 7;
    const messageSentPastMonth =
      dayjs().diff(dayjs(messageCreatedAt).format("YYYY-MM"), "month") === 1;

    const lastWeekMessages = acc.find(([name]) => name === "Last week");
    const lasMonthMessages = acc.find(([name]) => name === "Last month");
    const currentMonthMessages = acc.find(([name]) => name === monthSent);

    if (messageSentPastWeek) {
      if (!lastWeekMessages) {
        acc = [...acc, ["Last week", []]];
      }

      return acc.map(([name, messages]) => {
        if (name === "Last week") {
          return [name, [...messages, message.message]];
        }

        return [name, messages];
      });
    }

    if (messageSentPastMonth) {
      if (!lasMonthMessages) {
        acc = [...acc, ["Last month", []]];
      }

      return acc.map(([name, messages]) => {
        if (name === "Last month") {
          return [name, [...messages, message.message]];
        }

        return [name, messages];
      });
    }

    if (!currentMonthMessages) {
      acc = [...acc, [monthSent, []]];
    }

    return acc.map(([name, messages]) => {
      if (!currentMonthMessages) {
        acc = [...acc, [monthSent, []]];
      }
      if (name === monthSent) {
        return [name, [...messages, message.message]];
      }

      return [name, messages];
    });
  }, [] as [string, MessageResponse<DefaultStreamChatGenerics>[]][]);
