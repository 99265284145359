import { AppLayout } from "components/AppLayout/AppLayout";
import {
  LeftBar,
  LeftBarApps,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { Home } from "components/icons/LeftNav/Home";
import { TopBar } from "components/TopBar/TopBar";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import "static/styles/containers/dashboard.css";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import { NavAcrossApps } from "components/NavAcrossApps/NavAcrossApps";
import { ComingSoon } from "components/ComingSoon/ComingSoon";
import { InkleSalesTax } from "components/icons/Logos/InkleSalesTax";
import { InkleSalesTaxSymbol } from "components/icons/Logos/InkleSalesTaxSymbol";

import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { useAuth } from "hooks/useAuth";
import { FOREIGN_CA } from "constants/onBoarding";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { SalesTaxServices } from "components/SalesTaxService/SalesTaxService";
import { Filings } from "components/icons/LeftNav/Filings";
import { parse, stringify } from "qs";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { UpgradeTaxPlan } from "./Tax/UpgradeTaxPlan";

const Navbar = () => {
  const { url } = useRouteMatch();
  const { type } = useAuth();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <>
      {type === FOREIGN_CA && (
        <div className="t-m-2">
          <GroupSelect />
        </div>
      )}
      <LeftBarItem exact to="/" icon={Home}>
        Home
      </LeftBarItem>

      <LeftBarItem to="/state-filings" icon={MyEntities}>
        State Filings
      </LeftBarItem>

      <LeftBarItem to={`/services${search}`} icon={Filings}>
        Services
      </LeftBarItem>

      <div className="t-py-3">
        <LeftBarSeparator />
      </div>

      <NavAcrossApps />

      <div className="t-mt-auto">
        <div className="t-py-3">
          <LeftBarSeparator name="Other Apps" />
        </div>
        <LeftBarApps current={url} />
      </div>
    </>
  );
};

export const SalesTaxDashboard = () => {
  const { path, url } = useRouteMatch();

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo logo={InkleSalesTax} symbol={InkleSalesTaxSymbol} />
          }
        >
          <Navbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={
            <LeftBarLogo logo={InkleSalesTax} symbol={InkleSalesTaxSymbol} />
          }
          nav={
            <LeftBar>
              <Navbar />
            </LeftBar>
          }
        />
      }
      // banner={
      //   <DashboardBanner
      //     isCustomer={isCustomer!}
      //     subscriptionInactive={subscriptionInactive}
      //     stripePromise={stripePromise}
      //   />
      // }
    >
      <Switch>
        <RestrictedRoute exact path={`${path}/services/addtask`}>
          <TemplateMenu
            to={`/sales-tax/services`}
            taskCategoryType="SALES_TAX"
          />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/services/:taskId`}>
          <FilingDetails isFromServices parentRoute="/sales-tax/services" />
        </RestrictedRoute>
        <RestrictedRoute
          path={`${path}/state-filings`}
          component={ComingSoon}
        />
        <RestrictedRoute path={`${path}/services/`}>
          <SalesTaxServices />
        </RestrictedRoute>
        <RestrictedRoute exact path={path} component={ComingSoon} />

        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
