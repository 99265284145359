import { currency, CURRENCY_CODE_COUNTRY_CODE_MAP } from "utils/Currency";

export const AmountSuperScript = ({
  amount,
  currencyCode = "USD",
  noDecimal = false,
}: {
  amount: number;
  currencyCode?: keyof typeof CURRENCY_CODE_COUNTRY_CODE_MAP;
  noDecimal?: boolean;
}) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });
  const currencyAmount = numberFormat.formatToParts(amount);
  const fraction = currencyAmount.filter(({ type }) => type === "fraction");
  const { value: decimal } = fraction?.[0] || {};
  const localAmount = amount > 0 ? Math.floor(amount) : Math.ceil(amount);

  return (
    <span>
      <span className="t-sr-only">
        {`${currency({
          amount: localAmount,
          currencyCode: currencyCode,
          maximumFractionDigits: 0,
        })}.${decimal}`}
      </span>
      <span aria-hidden>
        {currency({
          amount: localAmount,
          currencyCode: currencyCode,
          maximumFractionDigits: 0,
        })}
      </span>
      {!noDecimal && (
        <sup aria-hidden className="-t-top-[0.2em] t-no-underline">
          .{decimal}
        </sup>
      )}
    </span>
  );
};
