import React, { ReactNode } from "react";
import classNames from "classnames";

// styles
import "../../static/styles/components/design/rightSlider.css";

const RightSlider = ({
  show,
  width,
  closeModal,
  children,
}: {
  show: boolean;
  width?: string;
  closeModal: () => void;
  children: ReactNode;
}) => {
  return (
    <>
      <div
        className={classNames("parentDisable", {
          "d-none": !show,
        })}
        onClick={closeModal}
      />
      <div
        style={{ width }}
        className={classNames({
          popup: show,
          closePopup: !show,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default RightSlider;
