import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import React from "react";
import { useDispatch } from "react-redux";
import { openEditSplitTransactionModal } from "store/slices/splitTransaction";
import { closeSlider } from "store/slices/transactions";
import { Transactions } from "types/Models/books";
import { CategorySelector } from "./TransactionColumn";

export const SplitCategoryOnPopover = ({
  transaction,
  truncate,
  showSplitActions,
  tooltipText,
  outline,
}: {
  transaction: Transactions;
  truncate?: boolean;
  showSplitActions: boolean;
  tooltipText: string;
  outline?: boolean;
}) => {
  const dispatch = useDispatch();
  const transactionId = transaction?.transaction.uuid;

  const openSplitTransaction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(closeSlider(transactionId));
    transaction && dispatch(openEditSplitTransactionModal(transaction));
  };

  return (
    <ToolTip
      propClass="!t-z-modal"
      text={
        <div
          className="t-min-w-64 t-text-left"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="t-text-subtitle-sm">Category</div>
          <div className="t-my-2 t-flex t-flex-col t-gap-0.5">
            {transaction.split_transactions?.map((transaction) => (
              <div
                className="t-flex t-justify-between t-items-center"
                key={transaction.uuid}
              >
                <div>{transaction.category?.name}</div>
                <div>
                  <AmountSuperScript amount={transaction.amount} />
                </div>
              </div>
            ))}
          </div>
          <button
            className="all:unset t-underline t-subtext-sm"
            onClick={openSplitTransaction}
          >
            Edit split transaction
          </button>
        </div>
      }
    >
      <span>
        <CategorySelector
          transaction={transaction}
          truncate={truncate}
          showSplitActions={showSplitActions}
          txnsToShowInLabel={tooltipText}
          outline={outline}
        />
      </span>
    </ToolTip>
  );
};
