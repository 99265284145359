import { FC, useMemo } from "react";
import Table from "components/DesignSystem/Table/Table";
import { useTable } from "react-table";
import { formatDate } from "utils/formatDate";

interface ITaskPriceLedgerTableProps {
  tableData: {
    created_at: string;
    description: string;
    price: string;
    uuid: string;
  }[];
}

export const TaskPriceLedgerTable: FC<ITaskPriceLedgerTableProps> = ({
  tableData,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "DATE",
        accessor: "created_at",
        width: "20%",
        textAlign: "left",
        Cell: ({ row }: { row: { original: { created_at: string } } }) => (
          <div className="t-text-subtext t-text-text-100">
            {formatDate(row.original.created_at)}
          </div>
        ),
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        width: "60%",
        textAlign: "left",
        marginRight: "10%",
        Cell: ({ row }: { row: { original: { description: string } } }) => {
          return (
            <div className="t-text-body t-text-text-100">
              {row.original.description}
            </div>
          );
        },
      },
      {
        Header: "PRICE",
        accessor: "price",
        textAlign: "left",
        width: "10%",
        Cell: ({ row }: { row: { original: { price: string | number } } }) => {
          const finalPrice = row.original.price;
          return (
            <div className="t-text-body t-text-text-100">
              {isNaN(finalPrice as number) ? (
                <div className="t-flex t-gap-4 t-items-center">
                  {finalPrice}
                </div>
              ) : (
                <div className="t-flex t-gap-4 t-items-center">
                  <>{`$${finalPrice}`}</>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => tableData, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      data,
    });

  return (
    <Table.Container className="!t-h-fit t-max-h-full">
      <Table.Content {...getTableProps()} className="all:unset">
        <Table.Head>
          {headerGroups.map((headerGroup) => (
            <Table.Row
              // @ts-ignore
              key={headerGroup.id}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="t-text-start t-text-subtext-sm"
                  // @ts-ignore
                  key={column.id}
                  {...column.getHeaderProps({})}
                  style={{
                    width: column?.width,
                    // @ts-ignore
                    textAlign: column?.textAlign,
                    // @ts-ignore
                    marginRight: column?.marginRight,
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </Table.Row>
          ))}
        </Table.Head>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Table.Row
                className="t-flex t-cursor-pointer t-items-center t-justify-center t-gap-6 t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent t-px-4 t-py-2"
                // @ts-ignore
                key={row.id}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      // @ts-ignore
                      key={cell.value}
                      {...cell.getCellProps({})}
                      style={{
                        width: cell.column?.width,
                        // @ts-ignore
                        textAlign: cell.column?.textAlign,
                        // @ts-ignore
                        marginRight: cell.column?.marginRight,
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};
