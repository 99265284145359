export const EmptyInvoiceSetting = () => (
  <svg
    width="91"
    height="86"
    viewBox="0 0 91 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.85">
      <g clipPath="url(#clip0_3241_30007)">
        <path
          d="M86.6072 27.8101H4.39453V81.2798H86.6072V27.8101Z"
          fill="#E7E6EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.6035 8.13623H72.7588C73.0718 11.6579 74.2708 15.1795 76.4839 18.7043C78.694 22.2229 79.896 25.7476 80.1997 29.2662H90.1699C89.766 25.7445 88.2322 22.2229 85.3867 18.7043C82.5412 15.1827 81.0073 11.661 80.6035 8.13623Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.9053 8.13623H72.7593C73.0724 11.6579 74.2713 15.1795 76.4845 18.7043C78.6945 22.2229 79.8966 25.7476 80.2002 29.2662H70.23C70.014 25.7445 69.1563 22.2229 67.5723 18.7043C65.9884 15.1827 65.1307 11.661 64.9084 8.13623H64.9053Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.9052 8.13623H57.0605C57.1858 11.6579 57.7054 15.1827 58.657 18.7043C59.6087 22.226 60.1283 25.7476 60.2535 29.2662H70.2237C70.0077 25.7445 69.15 22.2229 67.566 18.7043C65.9821 15.1827 65.1244 11.661 64.9021 8.13623H64.9052Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.209 8.13623H57.0631C57.1883 11.6579 57.7079 15.1827 58.6595 18.7043C59.6112 22.226 60.1308 25.7476 60.256 29.2662H50.2858C50.2514 25.7445 50.0698 22.2229 49.7474 18.7043C49.425 15.1858 49.2528 11.661 49.209 8.13623Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.2098 8.13623H41.3651C41.3119 11.6579 41.146 15.1827 40.836 18.7043C40.5261 22.2229 40.3696 25.7476 40.3164 29.2662H50.2866C50.2522 25.7445 50.0706 22.2229 49.7482 18.7043C49.4258 15.1858 49.2536 11.661 49.2098 8.13623Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5144 8.13623H41.3685C41.3153 11.6579 41.1494 15.1827 40.8395 18.7043C40.5295 22.2229 40.373 25.7476 40.3198 29.2662H30.3496C30.4936 25.7445 30.9913 22.2229 31.9304 18.7043C32.8696 15.1827 33.3767 11.661 33.5175 8.13623H33.5144Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5133 8.13623H25.6593C25.4339 11.6579 24.5793 15.1827 23.011 18.7043C21.4458 22.2229 20.6068 25.7476 20.3721 29.2662H30.3423C30.4863 25.7445 30.984 22.2229 31.9231 18.7043C32.8622 15.1827 33.3693 11.661 33.5102 8.13623H33.5133Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.815 8.13623H25.6597C25.4343 11.6579 24.5798 15.1827 23.0114 18.7043C21.4463 22.2229 20.6073 25.7476 20.3725 29.2662H10.4023C10.7248 25.7445 11.9049 22.2197 14.0993 18.7043C16.2999 15.1795 17.4926 11.661 17.815 8.13623Z"
          fill="#FFFFFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8151 8.13623H9.9548C9.54159 11.6579 8.01398 15.1795 5.181 18.7043C2.35115 22.2197 0.836059 25.7445 0.422852 29.2662H10.4024C10.7249 25.7445 11.905 22.2197 14.0994 18.7043C16.3 15.1795 17.4927 11.661 17.8151 8.13623Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.3664 81.1424H58.2135V49.2154C58.2135 48.9758 57.9515 48.7793 57.627 48.7793H33.9498C33.6285 48.7793 33.3633 48.9758 33.3633 49.2154V81.1424H33.3664Z"
          fill="#CFCDD6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.1419 28.9526H80.1748V33.0534C80.1748 35.9678 82.4161 38.3531 85.1583 38.3531C87.9005 38.3531 90.145 35.9678 90.145 33.0534V28.9526H90.1419Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.2043 28.9526H80.1714V33.0534C80.1714 35.9678 77.93 38.3531 75.1878 38.3531C72.4456 38.3531 70.2012 35.9678 70.2012 33.0534V28.9526H70.2043Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.2678 28.9526H60.2348V33.0534C60.2348 35.9678 57.9935 38.3531 55.2513 38.3531C52.5091 38.3531 50.2646 35.9678 50.2646 33.0534V28.9526H50.2678Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.2678 28.9526H40.3008V33.0534C40.3008 35.9678 42.5421 38.3531 45.2843 38.3531C48.0265 38.3531 50.271 35.9678 50.271 33.0534V28.9526H50.2678Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.3342 28.9526H40.3013V33.0534C40.3013 35.9678 38.0599 38.3531 35.3177 38.3531C32.5755 38.3531 30.3311 35.9678 30.3311 33.0534V28.9526H30.3342Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.3333 28.9526H20.3662V33.0534C20.3662 35.9678 22.6075 38.3531 25.3497 38.3531C28.0919 38.3531 30.3364 35.9678 30.3364 33.0534V28.9526H30.3333Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3957 28.9526H20.3628V33.0534C20.3628 35.9678 18.1214 38.3531 15.3792 38.3531C12.637 38.3531 10.3926 35.9678 10.3926 33.0534V28.9526H10.3957Z"
          fill="#E8E8E7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.396 28.9526H0.425781V33.0534C0.425781 35.9678 2.66712 38.3531 5.40932 38.3531C8.15151 38.3531 10.396 35.9678 10.396 33.0534V28.9526Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.7532 9.24684H9.67018C9.64824 6.84152 11.0302 4.62354 15.3172 4.62354H75.0842C79.3587 4.62354 80.7156 6.86041 80.7532 9.24684Z"
          fill="#B8B5C2"
        />
        <path
          d="M90.5741 80.8853H0.422852V86.0034H90.5741V80.8853Z"
          fill="#B8B5C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.2044 28.9526H60.2373V33.0534C60.2373 35.9678 62.4786 38.3531 65.2208 38.3531C67.963 38.3531 70.2075 35.9678 70.2075 33.0534V28.9526H70.2044Z"
          fill="#B8B5C2"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3241_30007">
        <rect
          width="90.1544"
          height="86.0004"
          fill="white"
          transform="translate(0.422852)"
        />
      </clipPath>
    </defs>
  </svg>
);
