import React from "react";
import classNames from "classnames";

// images
import CheckedPerkCircle from "../../../static/images/CheckedPerkCircle.svg";
import ActivePerkCircle from "../../../static/images/ActivePerkCircle.svg";
import InactivePerkCircle from "../../../static/images/InactivePerkCircle.svg";

const PerkStepBar = ({ currentState }) => {
  const getImage = (state) => {
    if (state === currentState) return ActivePerkCircle;
    else if (state < currentState) return CheckedPerkCircle;
    else return InactivePerkCircle;
  };

  return (
    <div className="stepBar w-100">
      <span>
        <img src={getImage(0)} className="circle" alt="circleImage" />
        <span
          className={classNames("stepName", {
            activeStep: currentState === 0,
          })}
        >
          Company Information
        </span>
      </span>
      {currentState >= 1 ? (
        <span className="ActiveBar w-100"></span>
      ) : (
        <span className="bar w-100"></span>
      )}
      <span>
        <img src={getImage(1)} className="circle" alt="circleImage" />
        <span
          className={classNames("stepName", {
            activeStep: currentState === 1,
          })}
        >
          Perk Information
        </span>
      </span>
      {currentState >= 2 ? (
        <span className="ActiveBar w-100"></span>
      ) : (
        <span className="bar w-100"></span>
      )}
      <span>
        <img src={getImage(2)} className="circle" alt="circleImage" />
        <span
          className={classNames("stepName", {
            activeStep: currentState === 2,
          })}
        >
          Redemption Information
        </span>
      </span>
    </div>
  );
};

export default PerkStepBar;
