import QueryString from "qs";
import { AddressAutofill } from "types/Models/addressAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import { DirectorAutofill } from "types/Models/directorAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { emptyApi } from "./emptyApi";

export const autofillApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query({
      query: () => {
        return {
          url: "/api/inkle/company/countries",
        };
      },
    }),

    getAutofill: build.query<
      (PersonaAutofill & BankAutofill & AddressAutofill & DirectorAutofill)[],
      {
        groupId: string;
        entityId: string;
        autofillKey: string;
        tagId?: string;
      }
    >({
      query: ({ groupId, autofillKey, tagId, entityId }) => {
        let queryUrl = QueryString.stringify(
          {
            autofill_type: autofillKey,
            tag_id: tagId,
          },
          { skipNulls: true }
        );
        return {
          url: `api/inkle/autofill/group/${groupId}/entity/${entityId}/autofill_data/?${queryUrl}`,
        };
      },
      providesTags: (result, _, req) => (result ? [{ type: "AUTOFILL" }] : []),
    }),

    createAutofill: build.mutation<
      AddressAutofill | PersonaAutofill | BankAutofill | DirectorAutofill,
      {
        groupId: string;
        entityId: string;
        autofillKey: string;
        fields: any;
      }
    >({
      query: ({ groupId, fields, autofillKey, entityId }) => {
        let queryUrl = QueryString.stringify(
          {
            autofill_type: autofillKey,
          },
          { skipNulls: true }
        );
        return {
          url: `api/inkle/autofill/group/${groupId}/entity/${entityId}/autofill_data/?${queryUrl}`,
          body: fields,
          method: "post",
        };
      },
      invalidatesTags: (result, _, req) =>
        result ? [{ type: "AUTOFILL" }] : [],
    }),

    updateAutofill: build.mutation<
      AddressAutofill | PersonaAutofill | BankAutofill | DirectorAutofill,
      {
        groupId: string;
        entityId: string;
        autofillKey: string;
        uuid: string;
        fields: any;
      }
    >({
      query: ({ groupId, uuid, fields, autofillKey, entityId }) => {
        let queryUrl = QueryString.stringify(
          {
            autofill_type: autofillKey,
          },
          { skipNulls: true }
        );
        return {
          url: `/api/inkle/autofill/group/${groupId}/entity/${entityId}/autofill/${uuid}/?${queryUrl}`,
          body: fields,
          method: "put",
        };
      },
      invalidatesTags: (result, _, req) =>
        result ? [{ type: "AUTOFILL" }, { type: "TP_ACCOUNTINFO" }] : [],
    }),

    deleteAutofill: build.mutation<
      void,
      { groupId: string; uuid: string; autofillKey: string; entityId: string }
    >({
      query: ({ groupId, uuid, autofillKey, entityId }) => {
        return {
          url: `/api/inkle/autofill/group/${groupId}/entity/${entityId}/autofill/${uuid}/`,
          method: "delete",
          params: {
            autofill_type: autofillKey,
          },
        };
      },
      invalidatesTags: (result, _, req) => [{ type: "AUTOFILL" }],
    }),

    getParticularAutofill: build.query<
      (PersonaAutofill | BankAutofill | AddressAutofill | DirectorAutofill)[],
      { groupId: string; uuid: string; autofillKey: string; entityId: string }
    >({
      query: ({ groupId, uuid, autofillKey, entityId }) => {
        return {
          url: `/api/inkle/autofill/group/${groupId}/entity/${entityId}/autofill/${uuid}/`,
          params: {
            autofill_type: autofillKey,
          },
        };
      },
    }),

    getAutofillsCount: build.query({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/autofill/group/${groupId}/count`,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAutofillQuery,
  useCreateAutofillMutation,
  useGetCountriesQuery,
  useUpdateAutofillMutation,
  useDeleteAutofillMutation,
  useGetParticularAutofillQuery,
  useGetAutofillsCountQuery,
} = autofillApi;
