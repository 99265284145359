import * as SelectPrimitive from "@radix-ui/react-select";
import cx from "classnames";
import { ArrowRight } from "components/icons/ArrowRight";
import { CaretDown } from "components/icons/CaretDown";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import check from "static/images/check.svg";

type ContentProps = {
  className?: string;
};

const Trigger = ({
  className = "",
  children,
  ...props
}: SelectPrimitive.SelectTriggerProps &
  React.RefAttributes<HTMLButtonElement> &
  ContentProps) => (
  <SelectPrimitive.Trigger
    {...props}
    className={cx(
      "all:unset t-cursor-pointer t-bg-white t-px-2 t-py-1 t-text-subtext-sm t-flex t-gap-1 t-group t-select-none focus:t-outline-1 focus:t-outline-purple-30 focus:t-outline",
      {
        [className]: className,
      }
    )}
  >
    {children}
    <Select.Icon asChild>
      <span className="group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out t-text-neutral">
        <CaretDown size="14" />
      </span>
    </Select.Icon>
  </SelectPrimitive.Trigger>
);

const Item = ({
  className = "",
  ...props
}: SelectPrimitive.SelectItemProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => (
  <SelectPrimitive.Item
    {...props}
    className={cx(
      "t-group t-w-full t-cursor-pointer t-truncate !t-border-none t-px-6 t-py-2 t-font-medium hover:t-bg-i-surface-light-purple focus-visible:t-outline-none data-[disabled]:t-cursor-not-allowed data-[disabled]:t-text-neutral-30 data-[disabled]:hover:t-bg-surface-transparent data-[highlighted]:t-bg-surface-lighter-grey data-[state='checked']:t-bg-purple-10 data-[state='checked']:hover:t-bg-purple-20 t-relative t-flex t-items-center t-select-none",
      {
        [className]: className,
      }
    )}
  >
    <Select.ItemIndicator asChild className="t-absolute t-left-1.5">
      <img src={check} alt="selcted" className="t-h-4 t-w-4" />
    </Select.ItemIndicator>
    <Select.ItemText>{props.children}</Select.ItemText>
  </SelectPrimitive.Item>
);

const ScrollDownButton = ({
  className = "",
  ...props
}: SelectPrimitive.SelectScrollDownButtonProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <SelectPrimitive.ScrollDownButton
      {...props}
      className={cx(
        "t-w-full t-cursor-pointer !t-border-none hover:t-bg-i-surface-light-purple focus-visible:t-outline-none t-bg-neutral-0 t-flex t-items-center t-justify-center t-h-7",
        {
          [className]: className,
        }
      )}
    >
      <div className="t-rotate-90 t-text-text-black">
        <ArrowRight stroke="2" color="currentColor" />
      </div>
    </SelectPrimitive.ScrollDownButton>
  );
};

const ScrollUpButton = ({
  className = "",
  ...props
}: SelectPrimitive.SelectScrollUpButtonProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <SelectPrimitive.ScrollUpButton
      {...props}
      className={cx(
        "t-w-full t-cursor-pointer !t-border-none hover:t-bg-i-surface-light-purple focus-visible:t-outline-none t-bg-neutral-10 t-flex t-items-center t-justify-center t-h-7",
        {
          [className]: className,
        }
      )}
    >
      <div className="-t-rotate-90 t-text-text-black">
        <ArrowRight stroke="2" color="currentColor" />
      </div>
    </SelectPrimitive.ScrollUpButton>
  );
};

const Portal = (props: SelectPrimitive.SelectPortalProps) => (
  <AnimatePresence>
    <SelectPrimitive.Portal {...props} />
  </AnimatePresence>
);

const Content = ({
  position = "popper",
  sideOffset = 2,
  className = "",
  container,
  children,
  ...props
}: SelectPrimitive.SelectContentProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps & {
    container?: SelectPrimitive.SelectPortalProps["container"];
  }) => {
  return (
    <Portal container={container}>
      <SelectPrimitive.Content
        {...props}
        position={position}
        sideOffset={sideOffset}
        asChild
        className={cx(
          "t-border primary-border t-z-dropdown t-rounded-md t-bg-surface t-text-subtext-sm t-drop-shadow-i-dropdown t-overflow-hidden",
          {
            [className]: className,
          }
        )}
      >
        <motion.div initial={{ y: -10 }} animate={{ y: 0 }} exit={{ y: -10 }}>
          <ScrollUpButton />
          <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
          <ScrollDownButton />
        </motion.div>
      </SelectPrimitive.Content>
    </Portal>
  );
};

const Select = {
  ...SelectPrimitive,
  Item,
  Content,
  Portal,
  ScrollDownButton,
  ScrollUpButton,
  Trigger,
};

export default Select;
