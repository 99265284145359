import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { AvatarGroup } from "components/DesignSystem/AvatarGroup/AvatarGroup";
import { Button } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  setTransactionsInChat,
  TransactionsInChat,
} from "store/slices/transactionsInChat";
import { pluralize } from "utils/pluralize";

import { motion } from "framer-motion";
import ChatCard from "components/DesignSystem/ChatCard/v2/ChatCard";
import { formatDate } from "utils/formatDate";
import { VendorDefault } from "components/icons/VendorDefault";
import TransactionColumn from "components/Transaction/TransactionColumn";

export const TransactionsInChatCard = ({
  transactionsInChat,
}: {
  transactionsInChat: TransactionsInChat[];
}) => {
  const dispatch = useAppDispatch();

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className="t-rounded t-bg-surface-lighter-grey"
    >
      <div className="t-flex t-flex-col t-gap-4 t-w-full t-pb-1.5">
        <ChatCard.Content>
          <ChatCard.Header>
            <div className="t-flex t-justify-between t-items-center">
              <span>Transaction Details</span>
              <Button
                customType="ghost_icon"
                onClick={() =>
                  dispatch(
                    setTransactionsInChat({
                      transactionsInChat: [],
                      entityId: "",
                    })
                  )
                }
              >
                <Cross />
              </Button>
            </div>
          </ChatCard.Header>
          <ChatCard.Body>
            {transactionsInChat && transactionsInChat.length > 1 ? (
              <div className="t-w-full t-flex t-flex-col t-gap-1.5">
                <div className="t-flex t-justify-between t-w-full t-items-center">
                  <div className="t-flex t-gap-2 t-items-center">
                    <AvatarGroup max={4}>
                      {transactionsInChat.map((txn) => (
                        <div
                          className="t-border-solid t-border-2 t-border-surface t-rounded-full"
                          key={txn.id}
                        >
                          {txn.vendor.name ? (
                            <Avatar
                              src={txn.vendor.logo_url || ""}
                              alt={txn.vendor.name || ""}
                            />
                          ) : (
                            <div className="t-flex t-w-6 t-h-6">
                              <VendorDefault />
                            </div>
                          )}
                        </div>
                      ))}
                    </AvatarGroup>
                    <span className="t-text-subtext">
                      {pluralize(
                        transactionsInChat.length,
                        "transaction",
                        "transactions"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="t-w-full t-flex t-flex-col t-gap-1.5">
                <div className="t-flex t-justify-between t-w-full t-items-center">
                  <div className="t-flex t-gap-2 t-items-center">
                    {transactionsInChat?.[0].vendor.name ? (
                      <Avatar
                        src={
                          transactionsInChat?.[0].vendor.logo_url || undefined
                        }
                        alt={transactionsInChat?.[0].vendor.name || ""}
                      />
                    ) : (
                      <VendorDefault />
                    )}
                    <span className="t-text-subtext t-max-w-[100px] t-truncate">
                      {transactionsInChat?.[0].vendor.name || (
                        <span className="t-text-text-30">--</span>
                      )}
                    </span>
                  </div>
                  <span className="t-text-subtext">
                    <TransactionColumn.Amount
                      amount={transactionsInChat?.[0].amount || 0}
                      isCreditCard={transactionsInChat?.[0].isCreditCard}
                    />
                  </span>
                </div>
                <div className="t-flex t-text-body-sm">
                  {transactionsInChat?.[0].source && (
                    <p className="t-max-w-[100px] t-truncate t-m-0 t-ml-8">
                      {transactionsInChat?.[0].source}
                    </p>
                  )}
                  {transactionsInChat?.[0].date && (
                    <span className="t-ml-auto">
                      {formatDate(transactionsInChat?.[0].date)}
                    </span>
                  )}
                </div>
              </div>
            )}
          </ChatCard.Body>
        </ChatCard.Content>
      </div>
    </motion.div>
  );
};
