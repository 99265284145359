import classNames from "classnames";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import SmallPencil from "components/icons/SmallPencil";
import { useToast } from "hooks/useToast";
import { useContext, useState } from "react";
import { useAddTagsMutation, useRemoveTagsMutation } from "store/apis/taskTags";
import authContext from "../../../jwt_context&axios/authContext";
import "../../../static/styles/components/taskAssignee.css";
import Tag from "../../design/Tag";
import CirclePlus from "../../icons/circlePlus";
import Pencil from "../../icons/pencil";

const CustomerTags = ({ size, tags, justifyEnd }) => {
  if (!tags || tags?.length === 0) {
    return "-";
  }

  return (
    <div
      className={classNames("badgeGroup", {
        "t-justify-end": justifyEnd,
      })}
    >
      {tags?.map((tag) => (
        <Tag size={size} key={tag.uuid} {...tag} justifyEnd={justifyEnd} />
      ))}
    </div>
  );
};

export default function Tags({
  taskId,
  currentTags,
  tags,
  size,
  justifyEnd = false,
}) {
  const { authtoken } = useContext(authContext);
  const { alertToast } = useToast();
  const [selectedTag, setSelectedTag] = useState();
  const [addTags, { isLoading: isAdding, isFetching: isFetchingAdd }] =
    useAddTagsMutation();
  const [
    removeTags,
    { isLoading: isRemoving, isFetching: isFetchingRemoving },
  ] = useRemoveTagsMutation();

  const sendData = async (value) => {
    setSelectedTag((prevId) => value || prevId);

    try {
      await addTags({ taskId, tagId: value }).unwrap();
    } catch (error) {
      alertToast({ message: error.data?.error?.message });
    }
  };

  const deleteData = async (e, value) => {
    setSelectedTag((prevId) => value || prevId);

    try {
      await removeTags({ taskId, tagId: value }).unwrap();
    } catch (error) {
      alertToast({ message: error.data?.error?.message });
    }
  };

  const isLoading = Boolean(
    isAdding || isFetchingAdd || isRemoving || isFetchingRemoving
  );

  const selectedIds = currentTags?.map(({ uuid }) => uuid);

  // To reposition the tag dropdown
  const keyToRerender = selectedIds?.reduce((acc, id) => id + acc, "");

  if (!authtoken.is_service_user) {
    return (
      <CustomerTags size={size} tags={currentTags} justifyEnd={justifyEnd} />
    );
  }

  return (
    <DropDown.Root>
      <DropDown.Trigger className="all:unset" asChild>
        {currentTags?.length > 0 ? (
          <div
            className={classNames("t-flex t-flex-col", {
              "t-justify-end": justifyEnd,
            })}
          >
            {currentTags?.map((tag) => (
              <Tag size={size} key={tag.id} {...tag} />
            ))}
            {size === "small" ? <SmallPencil /> : <Pencil />}
          </div>
        ) : (
          <span role="button">
            <CirclePlus />
          </span>
        )}
      </DropDown.Trigger>
      <DropDown.Portal>
        <DropDown.Content
          sideOffset={8}
          align="end"
          className="t-relative -t-mr-4 t-mt-2  t-max-h-72  t-overflow-auto t-w-64 t-rounded-lg t-bg-surface t-drop-shadow-dropdown sm:t-max-h-52"
        >
          {tags?.map((data) => {
            const tagUsed = currentTags?.some(({ uuid }) => uuid === data.uuid);

            return (
              <DropDown.Item
                key={data.uuid}
                as="button"
                eventKey={data.uuid}
                className="button-reset task-assign-item"
              >
                <span
                  className="sendDataTag !t-text-subtitle"
                  onClick={!tagUsed && sendData.bind(this, data.uuid)}
                  aria-hidden={true}
                >
                  #{data.title}
                </span>
                {isLoading && data.uuid === selectedTag && (
                  <i className="fas fa-spinner fa-spin task-assign-icon" />
                )}
                {tagUsed && !isLoading && (
                  <button
                    className="button-reset"
                    onClick={(e) => deleteData(e, data.uuid)}
                  >
                    <i className="far fa-times-circle task-assign-icon" />
                  </button>
                )}
              </DropDown.Item>
            );
          })}
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
}
