import { FC } from "react";

export const WaringCircle: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 14 15"
      fill="none"
    >
      <g clip-path="url(#clip0_2801_17429)">
        <path
          d="M7 1.8125C5.87512 1.8125 4.7755 2.14607 3.8402 2.77102C2.90489 3.39597 2.17591 4.28423 1.74544 5.32349C1.31496 6.36274 1.20233 7.50631 1.42179 8.60958C1.64124 9.71284 2.18292 10.7263 2.97833 11.5217C3.77374 12.3171 4.78716 12.8588 5.89043 13.0782C6.99369 13.2977 8.13726 13.185 9.17651 12.7546C10.2158 12.3241 11.104 11.5951 11.729 10.6598C12.3539 9.7245 12.6875 8.62488 12.6875 7.5C12.6859 5.99207 12.0862 4.54636 11.0199 3.48009C9.95365 2.41382 8.50793 1.81409 7 1.8125ZM6.5625 4.875C6.5625 4.75897 6.6086 4.64769 6.69064 4.56564C6.77269 4.48359 6.88397 4.4375 7 4.4375C7.11603 4.4375 7.22731 4.48359 7.30936 4.56564C7.39141 4.64769 7.4375 4.75897 7.4375 4.875V7.9375C7.4375 8.05353 7.39141 8.16481 7.30936 8.24686C7.22731 8.32891 7.11603 8.375 7 8.375C6.88397 8.375 6.77269 8.32891 6.69064 8.24686C6.6086 8.16481 6.5625 8.05353 6.5625 7.9375V4.875ZM7 10.5625C6.87021 10.5625 6.74333 10.524 6.63541 10.4519C6.52749 10.3798 6.44338 10.2773 6.39371 10.1574C6.34404 10.0375 6.33104 9.90552 6.35636 9.77822C6.38168 9.65092 6.44419 9.53399 6.53596 9.44221C6.62774 9.35043 6.74467 9.28793 6.87197 9.26261C6.99927 9.23729 7.13122 9.25028 7.25114 9.29995C7.37105 9.34962 7.47354 9.43374 7.54565 9.54166C7.61776 9.64958 7.65625 9.77646 7.65625 9.90625C7.65625 10.0803 7.58711 10.2472 7.46404 10.3703C7.34097 10.4934 7.17405 10.5625 7 10.5625Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2801_17429">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
