import { useToast } from "hooks/useToast";
import { useContext, useState } from "react";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/taskAssignee.css";
import Tag from "../design/Tag";
import CirclePlus from "../icons/circlePlus";
import Pencil from "../icons/pencil";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";

const CustomerTags = ({ tags }) => {
  if (!tags || tags?.length === 0) {
    return "-";
  }

  return (
    <div className="badgeGroup">
      {tags?.map((tag) => (
        <Tag key={tag.uuid} {...tag} />
      ))}
    </div>
  );
};

export default function Tags({ taskId, currentTags, tags, updateTags }) {
  const { alertToast } = useToast();
  const { authtoken } = useContext(authContext);

  const [isLoading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState();

  const checktagUsed = (value) =>
    currentTags?.some(({ uuid }) => uuid === value);

  const sendData = async (value) => {
    if (!checktagUsed(value)) {
      setSelectedTag((prevId) => value || prevId);

      try {
        setLoading(true);
        await updateTags({
          groupId: taskId,
          payLoad: { tag_id: value },
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          alertToast({ message: error?.response?.data?.error?.message });
        }
      }
    }
  };

  const deleteData = async (e, value) => {
    if (checktagUsed(value)) {
      setSelectedTag((prevId) => value || prevId);

      try {
        setLoading(true);
        await updateTags({
          groupId: taskId,
          payLoad: { remove_tag_id: value },
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          alertToast({ message: error?.response?.data?.error?.message });
        }
      }
    }
  };

  const selectedIds = currentTags?.map(({ uuid }) => {
    return uuid;
  });

  // To reposition the tag dropdown
  const keyToRerender = selectedIds?.reduce((acc, id) => id + acc, "");

  if (!authtoken.is_service_user) {
    return <CustomerTags tags={currentTags} />;
  }

  return (
    <div className="t-w-min" onClick={(e) => e.stopPropagation()}>
      <Dropdown.Root modal={false}>
        <Dropdown.Trigger
          as="button"
          className="task-assign-toggle button-reset"
        >
          {currentTags?.length > 0 ? (
            <div className="badgeGroup">
              {currentTags?.map((tag) => (
                <Tag key={tag.id} {...tag} />
              ))}
              <Pencil />
            </div>
          ) : (
            <CirclePlus />
          )}
        </Dropdown.Trigger>

        <Dropdown.Content
          key={keyToRerender}
          className="t-max-h-96 t-overflow-auto"
        >
          {tags?.map((data) => {
            return (
              <Dropdown.Item
                key={data.uuid}
                as="button"
                eventKey={data.uuid}
                className="button-reset task-assign-item"
              >
                <span
                  className="sendDataTag"
                  onClick={sendData.bind(this, data.uuid)}
                  aria-hidden={true}
                >
                  #{data.title}
                </span>
                {isLoading && data.uuid === selectedTag && (
                  <i className="fas fa-spinner fa-spin task-assign-icon" />
                )}
                {checktagUsed(data.uuid) && !isLoading && (
                  <button
                    className="button-reset"
                    onClick={(e) => deleteData(e, data.uuid)}
                  >
                    <i className="far fa-times-circle task-assign-icon" />
                  </button>
                )}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Content>
      </Dropdown.Root>
    </div>
  );
}
