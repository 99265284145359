import React, { ReactNode } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import ToolTip from "./toolTip";

const ConditionalToolTip = ({
  condition,
  children,
  propClass = "",
  ...props
}: RadixTooltip.TooltipContentProps & {
  children: ReactNode;
  condition: string | boolean | ReactNode;
  propClass?: string;
}) => {
  const tooltipProps = { text: condition as string, propClass, ...props };

  if (condition) {
    return <ToolTip {...tooltipProps}>{children}</ToolTip>;
  }

  return <>{children}</>;
};

export default ConditionalToolTip;
