import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";

type CreateRandDServiceFormProps = {
  amount: number | string;
  templateData: any;
};

export const CreateRandDServiceForm = ({
  amount,
  templateData,
}: CreateRandDServiceFormProps) => {
  const { entities } = useCurrentGroupContext();

  return (
    <>
      <SelectDropDown name="entityId" label="Entity">
        <option value="">Select entity</option>
        {entities.map(({ uuid, name }) => (
          <option value={uuid} key={uuid}>
            {name}
          </option>
        ))}
      </SelectDropDown>
      <SelectDropDown label="Season" name="season">
        <option value="">Year</option>
        {templateData?.[0]?.seasons?.map((year: string) => {
          return (
            <option value={year} key={year}>
              {year}
            </option>
          );
        })}
      </SelectDropDown>

      <div>
        <div className="t-text-body-sm t-text-text-30 t-pb-1.5">Price</div>
        <div className="t-text-subtitle-sm t-text-text-100">${amount}</div>
      </div>
    </>
  );
};
