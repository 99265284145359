import React from "react";
import { Button } from "../Button/Button";
import { SingleArrowIcon } from "components/icons/SingleArrowIcon";

export const SliderNavigation = ({
  dataLength,
  currentIndex,
  onPrev,
  onNext,
}: {
  dataLength: number;
  currentIndex: number;
  onPrev: () => void;
  onNext: () => void;
}) => {
  return (
    <div className="t-flex t-gap-1.5 t-items-center">
      <span className="t-text-body-sm t-text-text-100">
        {currentIndex + 1} of {dataLength}
      </span>

      <div className="t-flex t-items-center">
        <Button
          customType="ghost_icon"
          size="small"
          onClick={onPrev}
          disabled={currentIndex === 0}
        >
          <span>
            <SingleArrowIcon color="currentColor" />
          </span>
        </Button>
        <Button
          customType="ghost_icon"
          size="small"
          onClick={onNext}
          disabled={currentIndex === dataLength - 1}
        >
          <span className="t-rotate-180">
            <SingleArrowIcon color="currentColor" />
          </span>
        </Button>
      </div>
    </div>
  );
};
