import { createSelector } from "@reduxjs/toolkit";
import { FilterName } from "store/slices/transactionFilter";
import { RootState } from "store/store";

const getReportFilterState = (state: RootState) => state.reportFilter;

export const getFilterStatus = createSelector(
  [getReportFilterState],
  ({ filters }) => {
    const reportDate = Object.values(filters).filter(
      ({ type }) => type === "reportDate"
    );
    const others = Object.values(filters).filter(
      ({ type }) => type === "others"
    );
    const accountingMethod = Object.values(filters).filter(
      ({ type }) => type === "accounting"
    );

    const appliedFilterCount = [
      ...reportDate,
      ...others,
      ...accountingMethod,
    ].filter(({ value }) => value)?.length;

    const capsuleFilters = Object.values(filters).filter(
      ({ value, fixed }) => value && !fixed
    );

    const fixedFilters = Object.values(filters).filter(({ fixed }) => fixed);

    const getFilterName = (name: string) => {
      for (let key in filters) {
        // @ts-ignore
        if (filters.hasOwnProperty(key) && filters[key].name === name) {
          return key as FilterName;
        }
      }
    };

    return {
      appliedFilterCount,
      capsuleFilters,
      getFilterName,
      filters: {
        reportDate,
        others,
        accountingMethod,
      },
      fixedFilters,
    };
  }
);
