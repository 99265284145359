import { array, object, string } from "yup";
import { companyFormationTypes } from "constants/companyFormationTypes";

const entityNameTest = string().test({
  name: "Brand name suffixes",
  exclusive: false,
  message: "Brand name shouldn't include a suffix.",
  test: (value) => {
    const lowerCasedValue = value?.toLowerCase().trim();
    for (const suffix of companyFormationTypes) {
      if (lowerCasedValue?.endsWith(" " + suffix.toLowerCase())) {
        return false;
      }
    }
    return true;
  },
});

const notRequired = {
  country: string().notRequired(),
  suffix: string().notRequired(),
  state: string().notRequired(),
  entityName: entityNameTest.notRequired(),
  formationType: string().notRequired(),
};

const required = {
  country: string().required(),
  suffix: string().required(),
  state: string().required(),
  entityName: entityNameTest.required(),
  formationType: string().required(),
};

export const multiEntityCreation = () =>
  object({
    brandName: string()
      .test({
        name: "Brand name suffixes",
        exclusive: false,
        message: "Brand name shouldn't include a suffix.",
        test: (value) => {
          const lowerCasedValue = value?.toLowerCase().trim();
          for (const suffix of companyFormationTypes) {
            if (lowerCasedValue?.endsWith(" " + suffix.toLowerCase())) {
              return false;
            }
          }
          return true;
        },
      })
      .required("Brand Name is required"),
    entityData: array().of(
      object().when({
        is: (value: Record<string, string>) => {
          return Object.entries(value).every(([key, value]) =>
            key === "suffix" ? true : !value
          );
        },
        then: object(notRequired),
        otherwise: object(required),
      })
    ),
  });
