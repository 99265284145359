import { INR, USD } from "constants/currency";
import { EN_US, EN_IN } from "constants/javaLocales";

export const CURRENCY_CODE_COUNTRY_CODE_MAP = {
  [USD]: [EN_US],
  [INR]: [EN_IN],
};

export const currency = ({
  amount,
  currencyCode = "USD",
  maximumFractionDigits = 2,
}: {
  amount: string | number;
  currencyCode?: keyof typeof CURRENCY_CODE_COUNTRY_CODE_MAP;
  maximumFractionDigits?: number;
}) => {
  return new Intl.NumberFormat(CURRENCY_CODE_COUNTRY_CODE_MAP[currencyCode], {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: maximumFractionDigits,
  }).format(Number(amount));
};
