import React from "react";
import classNames from "classnames";

// styles
import "../../static/styles/components/commonComponent.css";

const StatusTags = ({ statusType, statusName }) => {
  //TODO: check before pushing
  // classnames : notStarted, ongoing, completed, Awaiting
  if (statusName === "Awaiting") {
    return (
      <div
        className={classNames(
          "statusTagStyle t-bg-yellow-10 t-text-yellow-70",
          {
            [statusType]: true,
          }
        )}
      >
        {statusName}
      </div>
    );
  }

  if (
    statusName === "settled" ||
    statusName === "Settled" ||
    statusName === "paid"
  ) {
    return (
      <div
        className={classNames("statusTagStyle t-bg-green-10 t-text-green-70", {
          [statusType]: true,
        })}
      >
        {statusName}
      </div>
    );
  }

  return (
    <div
      className={classNames("statusTagStyle t-bg-surface-lighter-grey", {
        [statusType]: true,
      })}
    >
      {statusName}
    </div>
  );
};

export default StatusTags;
