import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { GeneralLedger } from "components/GeneralLedger/GeneralLedger";
import { useActionItems } from "hooks/useActionItems";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { COATable } from "../../../components/ChartOfAccounts/COATable";
import { JournalEntry } from "components/JournalEntry/JournalEntry";

export const GeneralLedgerApp = () => {
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const { isReconciliationRequired } = useActionItems();

  const isCOAPage = pathname.includes("/chart-of-accounts");
  const isJournalEntryPage = pathname.includes("/journalentry");

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Categories"
          right={<div className="t-flex t-gap-2"></div>}
          bottom={
            <Tab.Root defaultValue={pathname} value="MATCH">
              <Tab.List>
                <Tab.NavTrigger
                  exact
                  to={`${url}${search}`}
                  value={
                    !pathname.includes("/chart-of-accounts") &&
                    !isJournalEntryPage
                      ? "MATCH"
                      : ""
                  }
                  className="t-flex t-items-center"
                >
                  General Ledger
                  {isReconciliationRequired && (
                    <ActionRequiredPopOver
                      type="RECONCILIATION"
                      btnText="Reconcile"
                    />
                  )}
                </Tab.NavTrigger>
                <Tab.NavTrigger
                  exact
                  to={`${url}/journalentry${search}`}
                  value={isJournalEntryPage && !isCOAPage ? "MATCH" : ""}
                >
                  Journal Entry
                </Tab.NavTrigger>
                <Tab.NavTrigger
                  exact
                  to={`${url}/chart-of-accounts${search}`}
                  value={isCOAPage ? "MATCH" : ""}
                >
                  Chart of Accounts
                  {isReconciliationRequired && (
                    <ActionRequiredPopOver
                      type="RECONCILIATION"
                      btnText="Reconcile"
                    />
                  )}
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      }
    >
      <Switch>
        <Route exact path={path}>
          <GeneralLedger />
        </Route>
        <Route exact path={`${path}/chart-of-accounts`}>
          <COATable />
        </Route>
        <Route exact path={`${path}/journalentry`}>
          <JournalEntry />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};
