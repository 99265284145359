import { axiosInstance } from "./apiInstance";

export const getGroupRequests = async ({ id }) => {
  return await axiosInstance.get(`/api/inkle/company/group/${id}/join/`);
};

export const groupJoinAction = async ({ groupId, requestId, type, body }) => {
  return await axiosInstance.put(
    `/api/inkle/company/group/${groupId}/request/${requestId}/${type}/`,
    body
  );
};

export const groupJoinRequest = async ({ body }) => {
  return await axiosInstance.post(`/api/inkle/company/group/join/`, body);
};

export const getTeamMembers = async ({ id }) => {
  return await axiosInstance.get(`/api/inkle/company/group/${id}/team/`);
};
