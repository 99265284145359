import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import React from "react";

export const DeleteModal = ({
  show,
  closeModal,
  onClick,
  text,
  closeButton = true,
  actionText = "Delete",
  body = "You can't undo this action.",
  isLoading = false,
}) => {
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content size="regular" className="t-z-toast">
        <Modal.Header>
          <Modal.Title>{text}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <label className="folderNameLable">{body}</label>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-gap-4 t-justify-end">
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              customType="danger"
              onClick={onClick}
            >
              {actionText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
