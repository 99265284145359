import { CreateTicket } from "components/Ticket/CreateTicket";
import { useAppSelector } from "hooks/useAppSelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useDispatch } from "react-redux";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import { closeCreateChatTicketModal } from "store/slices/ticket";
import { useChannelStateContext } from "stream-chat-react";

export const ChatTicketModal = () => {
  const dispatch = useDispatch();
  const { isAdmin } = useRoleBasedView();
  const { channel } = useChannelStateContext();

  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
      refetchOnMountOrArgChange: true,
    }
  );

  const { entity, company_group } = channelTask || {};
  const { uuid: currentEntityUuid } = entity || {};
  const { uuid: taskGroupId } = company_group || {};
  const groupId = (channel.data?.group_uuid as string) || taskGroupId;

  const { showCreateTicketModal } = useAppSelector(
    (state) => state.ticket.createTicketData
  );

  if (isAdmin && showCreateTicketModal) {
    return (
      <CreateTicket
        isNonChatTicket={false}
        show={showCreateTicketModal}
        currentEntityUuid={currentEntityUuid}
        channelId={channel.id}
        groupId={groupId}
        closeModal={() => dispatch(closeCreateChatTicketModal())}
      />
    );
  }
  return null;
};
