import { useAuth } from "./useAuth";

/**
 * Custom hook to retrieve the service team ID.
 * @returns The UUID of the service team.
 */
export const useServiceTeamId = () => {
  const { service_team } = useAuth();
  return service_team?.uuid;
};
