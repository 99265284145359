import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { BankLogo } from "components/icons/BankLogo";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { TRANSACTION_SOURCE } from "dictionaries";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import qs from "qs";
import { ChangeEvent, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useAddClarificationMutation,
  useDeleteOpenItemInvoiceMutation,
  useGetOpenItemsQuery,
  useSubmitClarificationMutation,
  useUploadOpenItemInvoiceMutation,
} from "store/apis/openItem";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  openFloatingChat,
  setPopulateTransactionIDs,
  setToOpenChatId,
} from "store/slices/chat";
import { closeAddRequestInfoModal } from "store/slices/openItem";
import { resetTxnFilters } from "store/slices/transactionFilter";
import { RootState } from "store/store";
import { FileObject } from "types/Models/fileObject";
import { OpenItem } from "types/Models/openItem";
import { debounce } from "utils/debouncing";
import { openLink } from "utils/openLink";

const AddInformation = (info: CellContext<OpenItem, string>) => {
  const [updatingInfoId, setUpdatingInfoId] = useState("");
  const { clarification, uuid } = info.row.original;

  const { csvId, groupId } = useSelector(
    (state: RootState) => state.openItem.addRequestInfoModal
  );

  const { data: requestedTxn } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { status } = requestedTxn || {};

  const [addRequestInfo, { isLoading: isUpdating }] =
    useAddClarificationMutation();

  const handleCommentChange = debounce(
    async (e: ChangeEvent<HTMLInputElement>, openItemId: string) => {
      const { value } = e.target;
      setUpdatingInfoId(openItemId);
      await addRequestInfo({
        groupId,
        openItemId,
        clarification: value,
      }).unwrap();
    },
    1000
  );

  if (status === "SUBMITTED") {
    return <div className="t-text-subtext t-text-text-60">{clarification}</div>;
  }

  return (
    <Formik
      initialValues={{ clarification: clarification || "" }}
      onSubmit={() => {}}
    >
      <Form
        className="all:unset"
        // @ts-ignore // Ignoring for compatability
        onChange={(e) => handleCommentChange(e, uuid)}
      >
        <div className="t-flex t-items-center t-gap-1.5">
          <TextArea name="clarification" cols={100} rows={1} />
          <span
            className={classNames("t-flex t-animate-spin", {
              "t-invisible": !Boolean(isUpdating && updatingInfoId === uuid),
              "t-block": Boolean(isUpdating && updatingInfoId === uuid),
            })}
          >
            <LoadingIcon />
          </span>
        </div>
      </Form>
    </Formik>
  );
};

const Invoice = (info: CellContext<OpenItem, FileObject>) => {
  const { invoice, uuid } = info.row.original;
  const { successToast, alertToast } = useToast();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const [uploadOpenItemInvoice, { isLoading: isUploading }] =
    useUploadOpenItemInvoiceMutation();

  const [deleteOpenItemInvoice, { isLoading: isDeleting }] =
    useDeleteOpenItemInvoiceMutation();

  const { csvId, groupId } = useSelector(
    (state: RootState) => state.openItem.addRequestInfoModal
  );

  const { data: requestedTxn } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { status } = requestedTxn || {};

  const uploadInvoice = async ({
    openItemId,
    file,
  }: {
    openItemId: string;
    file: FileType;
  }) => {
    try {
      await uploadOpenItemInvoice({
        groupId,
        openItemId,
        file: file as File,
      }).unwrap();
      successToast({ message: "File Uploaded" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onInvoiceDelete = async ({ openItemId }: { openItemId: string }) => {
    try {
      await deleteOpenItemInvoice({ openItemId, groupId }).unwrap();
      successToast({ message: "File Deleted" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onPreview = async ({ fileId }: { fileId: string }) => {
    try {
      const { preview_url } = await getPreviewUrl({ groupId, fileId }).unwrap();
      openLink(preview_url);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  if (status === "SUBMITTED" && !Boolean(invoice)) {
    return "-";
  }

  return (
    <FileInput
      file={invoice}
      onDrop={(files) => uploadInvoice({ openItemId: uuid, file: files[0] })}
      isUploading={isUploading}
      variant="icon"
      onFileClick={({ uuid: fileId }) => onPreview({ fileId })}
      isDeleting={isDeleting}
      onDelete={
        status === "REQUESTED"
          ? (e) => onInvoiceDelete({ openItemId: uuid })
          : undefined
      }
    />
  );
};

export const AddRequestInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { alertToast, successToast } = useToast();

  const [submitClarification, { isLoading: isSubmiting }] =
    useSubmitClarificationMutation();

  const { csvId, groupId, messageId, channelId } = useSelector(
    (state: RootState) => state.openItem.addRequestInfoModal
  );

  const { data: requestedTxn, isLoading } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { open_items = [], status, entity_id } = requestedTxn || {};

  const columnHelper = createColumnHelper<OpenItem>();

  const onClose = () => {
    dispatch(closeAddRequestInfoModal());
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "Date",
        cell: (info) => {
          const { date } = info.getValue();
          return (
            <div className="t-text-subtext t-text-text-30">
              {dayjs(date).format(DD_MMM_YYYY)}
            </div>
          );
        },
        header: "Date",
        size: 12,
      }),
      columnHelper.accessor("from", {
        id: "source",
        cell: (info) => {
          const from = info.getValue();

          const { bank_account } = from || {};
          const { bank_brand } = bank_account || {};
          const { logo_url } = bank_brand || {};

          const mask = bank_account.mask ? `•••• ${bank_account.mask}` : "";

          return (
            <ConditionalToolTip
              condition={
                from &&
                `${from?.bank_account?.nickname} ${mask} (${
                  TRANSACTION_SOURCE[from?.source!]
                })`
              }
            >
              <span className="t-flex t-justify-center">
                {logo_url ? (
                  <Avatar
                    src={logo_url}
                    alt={from?.bank_account?.mask || "Bank"}
                  />
                ) : (
                  <BankLogo />
                )}
              </span>
            </ConditionalToolTip>
          );
        },
        header: "Source",
        size: 7,
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => {
          const description = info.getValue();
          if (!Boolean(description)) {
            return "-";
          }

          return (
            <div className="t-flex t-gap-2 t-items-center">
              <div className="t-text-subtext t-text-text-60">{description}</div>
            </div>
          );
        },
        header: "Description",
        size: 20,
      }),
      columnHelper.accessor((row) => row, {
        id: "Amount",
        cell: (info) => {
          const { amount } = info.getValue();
          return (
            <div className="t-flex t-justify-end t-mr-10 t-text-subtext t-text-text-60">
              <AmountSuperScript amount={Number(amount)!} />
            </div>
          );
        },
        header: () => (
          <div className="t-flex t-justify-end t-mr-10">Amount</div>
        ),
        size: 10,
      }),
      columnHelper.accessor("invoice", {
        id: "Invoice",
        cell: Invoice,
        header: "Invoice",
        size: 7,
      }),
      columnHelper.accessor((row) => row, {
        id: "Information Requested",
        cell: (info) => {
          const { information_request } = info.getValue();
          return (
            <div className="t-text-subtext t-text-text-60">
              {information_request}
            </div>
          );
        },
        header: "Information Requested",
        size: 22,
      }),
      columnHelper.accessor("clarification", {
        id: "Add Information",
        cell: AddInformation,
        header: () => (
          <div
            className={classNames({
              "after:t-font-bold after:t-text-red after:t-content-['_*']":
                status === "REQUESTED",
            })}
          >
            Add Information
          </div>
        ),
        size: 22,
      }),
    ],
    [open_items, status]
  );

  const table = useReactTable({
    data: open_items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 5,
      maxSize: 100,
    },
  });

  const isAllTxnClarificationAdded = open_items?.every(
    ({ clarification }) => clarification
  );

  const openItemsTxnIds = open_items
    .map(({ transaction_id }) => transaction_id)
    .join(",");

  const onSubmit = async () => {
    try {
      await submitClarification({ groupId, messageId, csvId }).unwrap();
      dispatch(setToOpenChatId(channelId));
      dispatch(openFloatingChat());
      successToast({ message: "Submitted" });
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const viewTransactions = () => {
    dispatch(resetTxnFilters());
    let queryParams = qs.stringify(
      {
        company: groupId,
        entity: entity_id,
      },
      { skipNulls: true, addQueryPrefix: true }
    );

    history.push(`/books/transactions${queryParams}`);
    dispatch(setPopulateTransactionIDs(openItemsTxnIds));
    onClose();
  };

  return (
    <Modal.Root open={Boolean(csvId)} onOpenChange={onClose}>
      <Modal.Content size="xxl">
        <Modal.Header>
          <Modal.Title>
            {status === "REQUESTED" ? "Add" : "View"} Info
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          {isLoading && open_items.length === 0 && <Loader />}
          {!isLoading && open_items.length > 0 && (
            <Table.Container>
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          key={header.id}
                          style={{
                            width: `${header.getSize()}%`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  {table.getRowModel().rows.map((row) => (
                    <Table.Row key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Table.Cell
                          key={cell.id}
                          style={{
                            width: `${cell.column.getSize()}%`,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Content>
            </Table.Container>
          )}
        </Modal.Body>
        <Modal.Footer className="t-flex t-justify-end t-gap-3 t-items-center">
          <Button customType="text" onClick={viewTransactions}>
            View in Transactions
          </Button>
          {status === "REQUESTED" && (
            <Button
              customType="primary"
              disabled={!isAllTxnClarificationAdded || isSubmiting}
              onClick={onSubmit}
              isLoading={isSubmiting}
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
