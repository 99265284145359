export const BookkeepingCardHeader = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="t-flex t-flex-col t-items-start t-gap-2">
      <span className="t-text-h6 t-text-text-100">{title}</span>
      <span className="t-text-subtitle-sm t-font-medium t-text-i-text-light">
        {description}
      </span>
    </div>
  );
};
