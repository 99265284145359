import { axiosInstance } from "./apiInstance";
import qs from "qs";
import { makeFormData } from "utils/makeFormData";

export const getMerchants = async ({
  groupId,
  merchants_search,
  merchantType,
  pageNumber,
}) => {
  const queries = {
    search_term: merchants_search,
    merchant_type: merchantType,
    page_num: pageNumber,
  };
  let queryUrl = qs.stringify(queries, { skipNulls: true });
  return await axiosInstance.get(
    `/api/inkle/bookkeeping/group/${groupId}/merchant/?${queryUrl}`
  );
};

export const deleteMerchant = async ({ groupId, uuid }) => {
  return await axiosInstance.delete(
    `/api/inkle/bookkeeping/group/${groupId}/merchant/${uuid}/delete/`
  );
};

export const editMerchant = async ({ groupId, uuid, payload }) => {
  return await axiosInstance.patch(
    `/api/inkle/bookkeeping/group/${groupId}/merchant/${uuid}/update/`,
    payload
  );
};

export const addMerchant = async ({ groupId, payload }) => {
  return await axiosInstance.post(
    `/api/inkle/bookkeeping/group/${groupId}/create-merchant/`,
    payload
  );
};

export const assignMerchantToTxn = async ({ txnId, groupId, merchantId }) => {
  return await axiosInstance.put(
    `/api/inkle/bookkeeping/group/${groupId}/txn-merchant/${txnId}/`,
    { merchant_id: merchantId }
  );
};

export const assignAndCreateMerchant = async ({ groupId, payload }) => {
  let formData = makeFormData(payload);
  return await axiosInstance.post(
    `/api/inkle/bookkeeping/group/${groupId}/merchant/`,
    formData
  );
};
