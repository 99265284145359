import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { RecurringJournalEntryUpdateModal } from "components/RecurringJournalEntryUpdateModal/RecurringJournalEntryUpdateModal";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetRelatedLedgerEntryQuery } from "store/apis/generalLedger";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { useDeleteTransactionMutation } from "store/apis/transactions";
import { closeSlider } from "store/slices/transactions";
import { RootState } from "store/store";
import { RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES_T } from "types/contants/recurringJournalEntryUpdateTypes";
import * as RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES from "constants/recurringJorunalEntryUpdateTypes";
import { InvoiceType, Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import * as RECURRING_JOUNAL_ENTRY_UPDATE_TYPES from "constants/recurringJorunalEntryUpdateTypes";
import { useDeleteJournalEntryMutation } from "store/apis/journalEntry";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { RecurringJournalEntrySettings } from "./AddJournalEntryModal";

const Invoice = ({ invoice }: { invoice: InvoiceType | null }) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [getPreviewUrl, { isLoading }] = useLazyGetPreviewUrlQuery();

  const onPreview = async ({
    fileId,
    e,
  }: {
    fileId: string;
    e: MouseEvent<HTMLButtonElement>;
  }) => {
    e.stopPropagation();
    try {
      const { preview_url } = await getPreviewUrl({ groupId, fileId }).unwrap();
      openLink(preview_url);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  if (invoice) {
    return (
      <div className="t-flex t-justify-center t-items-center">
        <div className="t-relative">
          <Button
            isLoading={isLoading}
            disabled={isLoading}
            customType="ghost_icon"
            onClick={(e) => onPreview({ fileId: invoice.uuid, e })}
          >
            <FileIcon
              fileType={invoice?.file_data?.file_type}
              width={32}
              height={32}
            />
          </Button>
        </div>
      </div>
    );
  }

  return <div className="t-flex t-justify-center t-items-center">-</div>;
};

export const TransactionTable = ({
  transactions,
}: {
  transactions: Transactions[];
}) => {
  const columnHelper = createColumnHelper<Transactions>();

  const columns = [
    columnHelper.accessor("transaction.uuid", {
      id: "uuid",
      size: 10,
      header: () => "S.No",
      cell: (info) => info.row.index + 1,
    }),

    columnHelper.accessor("transaction.description", {
      id: "description",
      size: 20,
      header: () => "DESCRIPTION",
      cell: (info) => info.getValue() || "-",
    }),

    columnHelper.accessor("transaction.merchant", {
      id: "merchant",
      size: 20,
      header: () => "VENDOR",
      cell: (info) => info.getValue() || "-",
    }),

    columnHelper.accessor("transaction.category.name", {
      id: "category",
      size: 20,
      header: () => "CATEGORY",
      cell: (info) => info.getValue() || "-",
    }),

    columnHelper.accessor("transaction.amount", {
      id: "debit",
      size: 15,
      header: () => <div className="t-flex t-justify-end">Debit</div>,
      cell: (info) => {
        const amount = info.getValue();

        if (amount > 0) {
          return null;
        }

        if (!Boolean(amount)) {
          return <div className="t-flex t-justify-end">-</div>;
        }

        return (
          <div className="t-flex t-justify-end t-py-2">
            <AmountSuperScript amount={Number(amount)} />
          </div>
        );
      },
    }),

    columnHelper.accessor("transaction.amount", {
      id: "credit",
      size: 15,
      header: () => <div className="t-flex t-justify-end">Credit</div>,
      cell: (info) => {
        const amount = info.getValue();

        if (amount < 0) {
          return null;
        }

        if (!Boolean(amount)) {
          return <div className="t-flex t-justify-end">-</div>;
        }

        return (
          <div className="t-flex t-justify-end t-py-2">
            <AmountSuperScript amount={Number(amount)} />
          </div>
        );
      },
    }),

    columnHelper.accessor("invoices", {
      id: "invoices",
      size: 5,
      header: "invoice",
      cell: (info) => {
        const invoice = info.getValue();

        return <Invoice invoice={invoice[0]} />;
      },
    }),
  ];

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <Table.Container>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}%` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const DeleteJournalEntryTable = ({
  close,
  isOpen,
  transactionId,
}: ModalProps & {
  transactionId: string;
}) => {
  const dispatch = useDispatch();

  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [deleteJournalEntry, { isLoading: isDeleting }] =
    useDeleteJournalEntryMutation();

  const recurringCheckModal = useModal();

  const { data: relatedLedgerEntry, isLoading } = useGetRelatedLedgerEntryQuery(
    {
      entityId,
      groupId,
      transactionId,
    },
    { skip: !entityId || !groupId || !transactionId || !isOpen }
  );

  const { ledger_entry, transactions = [] } = relatedLedgerEntry || {};
  const { entry_name = "", entry_number = 0 } = ledger_entry || {};

  const transaction_date = dayjs(transactions?.[0]?.transaction?.date).format(
    DD_MMM_YYYY
  );

  const onTransactionDelete = async () => {
    if (relatedLedgerEntry?.ledger_entry.settings?.is_recurring_enabled) {
      return recurringCheckModal.open();
    }

    if (relatedLedgerEntry) {
      try {
        await deleteJournalEntry({
          entityId,
          groupId,
          ledgerId: relatedLedgerEntry?.ledger_entry.uuid,
          event_update_type: RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT,
        }).unwrap();
        close();
        dispatch(closeSlider(transactionId));
        successToast({ message: "Journal entry deleted!" });
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    }
  };

  const onRecurringConfirmation = async ({
    event,
  }: {
    event: RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES_T;
  }) => {
    if (relatedLedgerEntry) {
      try {
        await deleteJournalEntry({
          entityId,
          groupId,
          ledgerId: relatedLedgerEntry?.ledger_entry.uuid,
          event_update_type: event,
        }).unwrap();
        close();
        dispatch(closeSlider(transactionId));
        successToast({ message: "Journal entry deleted!" });
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    }
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="xxxl">
          <Modal.Header>
            <Modal.Title>Delete Journal Entry</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="!t-overflow-auto t-flex t-gap-4 t-flex-col">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Formik
                  initialValues={{
                    transaction_date,
                    ledger_entry_name: "#"
                      .concat(entry_number?.toString())
                      .concat(" ")
                      .concat(entry_name),
                    is_recurring_enabled:
                      ledger_entry?.settings?.is_recurring_enabled,
                    frequency: ledger_entry?.settings?.frequency,
                    end_date: ledger_entry?.settings?.end_date,
                    max_recurring_count:
                      ledger_entry?.settings?.max_recurring_count,
                  }}
                  onSubmit={() => {}}
                >
                  <Form>
                    <div className="t-flex t-gap-6 t-items-center">
                      <div className="t-flex t-gap-6 t-w-2/4">
                        <Field name="transaction_date">
                          {({ field }: FieldProps) => {
                            return (
                              <DateInput
                                label="Date"
                                {...field}
                                portalId="transaction_date"
                                required
                                block
                                disabled
                                maxDate={new Date()}
                                placeholder="DD-MMM-YYYY"
                              />
                            );
                          }}
                        </Field>
                        <TextInput
                          disabled
                          name="ledger_entry_name"
                          label="Journal entry title"
                          required
                        />
                      </div>

                      {ledger_entry?.settings?.is_recurring_enabled && (
                        <div className="t-ml-auto">
                          <SwitchField
                            disabled
                            name="is_recurring_enabled"
                            label="Recurring entry"
                          />
                        </div>
                      )}
                    </div>
                    {ledger_entry?.settings?.is_recurring_enabled && (
                      <div className="t-flex t-gap-6 t-pt-4">
                        <RecurringJournalEntrySettings disabled />
                      </div>
                    )}
                  </Form>
                </Formik>
                <TransactionTable transactions={transactions} />
              </>
            )}
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button type="reset" onClick={close}>
              Cancel
            </Button>
            <Button
              customType="danger"
              type="submit"
              onClick={onTransactionDelete}
              disabled={isDeleting}
              isLoading={isDeleting}
            >
              Delete
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      <Modal.Root
        open={recurringCheckModal.isOpen}
        onOpenChange={recurringCheckModal.close}
      >
        <Formik
          onSubmit={onRecurringConfirmation}
          initialValues={{
            event: RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT,
          }}
        >
          {({ submitForm, setFieldValue, values }) => (
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Are you sure?</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <p className="t-text-body t-text-text-100 t-mb-4">
                    This is a recurring journal entry, this action will delete:
                  </p>
                  <RadioGroup.Root
                    value={values.event}
                    onValueChange={(v) => setFieldValue("event", v)}
                    className="t-flex t-flex-col t-gap-4"
                  >
                    <RadioGroup.Item
                      value={RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT}
                    >
                      <span className="t-text-body t-flex">This entry</span>
                    </RadioGroup.Item>
                    <RadioGroup.Item
                      value={
                        RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT_AND_FOLLOWING
                      }
                    >
                      <span className="t-text-body t-flex">
                        This and following entries
                      </span>
                    </RadioGroup.Item>
                  </RadioGroup.Root>
                  <p className="t-text-text-30 t-text-body t-m-0 t-mt-3">
                    Note: This won't affect previous entries.
                  </p>
                </Form>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button>Cancel</Button>
                </Modal.RawClose>
                <Button customType="danger" onClick={submitForm}>
                  Confirm
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          )}
        </Formik>
      </Modal.Root>
    </>
  );
};
