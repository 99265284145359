import { emptyApi } from "./emptyApi";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import { Pagination } from "types/Models/pagination";
import { stringify } from "qs";

export type RecurringSettingTypes =
  | "WEEKLY"
  | "QUARTERLY"
  | "MONTHLY"
  | "YEARLY";

export type InvoiceCustomer = {
  company_name: string;
  billing_address: {
    street_address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    uuid: string;
  };
  customer_id: string;
  email: string;
  phone: string;
  uuid?: string;
  is_reminders_enabled: boolean;
};

export type ReminderSetting = {
  subject: string;
  cc_emails: string[];
  greeting: string;
  body: string;
  signature: string;
  reminder_setting_types: string;
  uuid: string;
};

export type InvoiceSettings = {
  invoice_prefix: string;
  logo_url?: string;
  entity_id: string;
  address?: {
    address_string: string;
    uuid: string;
    street_address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
  };
  email: string;
  payment_info?: string;
  reminder_settings?: ReminderSetting;
};

export type InvoiceItem = {
  description: string;
  quantity: number;
  rate: number;
  amount?: string;
  uuid: string;
};

export type GroupInvoiceCustomer = {
  uuid: string;
  name: string;
  email: string;
  due_amount: number;
  latest_invoice_on: string | null;
  total_amount?: number;
  paid_amount?: number;
};

export type Invoice = {
  created_on: string;
  title: string;
  customer?: InvoiceCustomer;
  customer_id?: string;
  invoice_number: string;
  po_number: string;
  sent_to: string;
  sent_on: string;
  discount: string;
  document?: {
    file_name: string;
    file_id: string;
  };
  discount_amount?: string;
  invoice_date: string;
  status: (typeof INVOICE_STATUSES)[keyof typeof INVOICE_STATUSES];
  invoice_items: InvoiceItem[];
  due_date: string;
  sub_total: string;
  tax: string;
  tax_type: "PERCENT" | "ABSOLUTE";
  discount_type: "PERCENT" | "ABSOLUTE";
  tax_amount?: string;
  total: string;
  paid_amount: string;
  due_balance: string;
  invoice_amount: string;
  due_amount: string;
  notes: string;
  terms: string;
  updated_at?: string;
  uuid: string;
  invoice_settings?: {
    is_recurring_enabled: boolean;
    recurring_setting_types: RecurringSettingTypes;
    next_recurring_date: string | null;
    recurring_end_date: string | null;
    is_recurring_send_automated: boolean;
    uuid: string;
  };
  associated_txns?: {
    uuid: string;
    amount: string;
    date: string;
  }[];
};

export const invoiceApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllInvoices: build.query<
      {
        invoices: Invoice[];
        current_page: number;
        total_pages: number;
        total_count: number;
        per_page: number;
        paid_amount: number;
        due_amount: number;
        total_amount: number;
      },
      {
        groupId: string;
        currentPage: number;
        entityId: string;
        searchTerm: string | null;
        sortCol?: "CREATED_AT" | "SENT_TO" | "AMOUNT";
        sortOrder?: "ASC" | "DSC";
      }
    >({
      query: ({
        groupId,
        entityId,
        currentPage,
        searchTerm,
        sortCol,
        sortOrder,
      }) => {
        const query = stringify(
          {
            search_term: searchTerm,
            page_num: currentPage,
            sort_col: sortCol,
            sort_order: sortOrder,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoices/${query}`,
        };
      },
      providesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    createEntityInvoice: build.mutation<
      Invoice,
      {
        groupId: string;
        entityId: string;
        payload?: Omit<
          Invoice,
          | "uuid"
          | "due_date"
          | "invoice_date"
          | "updated_at"
          | "document"
          | "status"
          | "sent_to"
          | "sent_on"
          | "title"
        > & { invoice_items?: InvoiceItem[] };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    deleteEntityInvoice: build.mutation<
      Invoice & { invoice_id: string },
      { groupId: string; entityId: string; invoiceId: string }
    >({
      query: ({ groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    getEntityInvoice: build.query<
      Invoice,
      { groupId: string; entityId: string; invoiceId: string }
    >({
      query: ({ groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
        };
      },
      providesTags: (result) => {
        return result ? [{ type: "INVOICES" }] : [];
      },
    }),

    updateInvoice: build.mutation<
      Invoice,
      {
        groupId: string;
        entityId: string;
        invoiceId: string;
        payload: Partial<Invoice>;
      }
    >({
      query: ({ groupId, entityId, invoiceId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: "INVOICES" },
              { type: "INVOICE_CUSTOMERS", id: result.customer?.customer_id },
              "AUDIT_LOG_BY_OBJECT",
            ]
          : [],
    }),

    sendInvoice: build.mutation<
      Invoice,
      {
        groupId: string;
        entityId: string;
        invoiceId: string;
        payload: {
          attachments?: File[];
          body: string;
          subject: string;
        };
      }
    >({
      query: ({ groupId, entityId, invoiceId, payload }) => {
        const formData = new FormData();
        formData.append("subject", payload.subject);
        formData.append("body", payload.body);
        payload.attachments?.forEach((file) => {
          formData.append("attachments", file);
        });

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/send/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICES" }] : []),
    }),

    downloadInvoice: build.query<
      { doc_id: string },
      { groupId: string; entityId: string; invoiceId: string }
    >({
      query: ({ groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/download/`,
        };
      },
    }),

    getAllInvoiceCustomers: build.query<
      InvoiceCustomer[],
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) =>
        `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/recipients/`,
      providesTags: (result) =>
        result
          ? [
              { type: "INVOICE_CUSTOMERS" as const, id: "LIST" },
              ...result.map((c) => ({
                type: "INVOICE_CUSTOMERS" as const,
                id: c.uuid,
              })),
            ]
          : [],
    }),

    getAllInvoiceCustomersList: build.query<
      Pagination & {
        group_customers: GroupInvoiceCustomer[];
      },
      {
        groupId: string;
        entityId: string;
        pageNum: string | number;
        searchTerm?: string | null;
      }
    >({
      query: ({ groupId, entityId, pageNum, searchTerm }) => {
        const query = stringify(
          { page_num: pageNum, search_term: searchTerm },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/customers/${query}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              { type: "INVOICE_CUSTOMERS" as const, id: "LIST" },
              ...result.group_customers.map((c) => ({
                type: "INVOICE_CUSTOMERS" as const,
                id: c.uuid,
              })),
            ]
          : [],
    }),

    getInvoiceCustomer: build.query<
      {
        uuid: string;
        name: string;
        email: string;
        phone: string;
        billing_address: string;
        due_amount: number;
        total_amount: number;
        paid_amount: number;
        percent_paid: number;
        invoices: Invoice[];
        is_reminders_enabled?: boolean;
      },
      {
        groupId: string;
        entityId: string;
        customerId: string;
      }
    >({
      query: ({ groupId, entityId, customerId }) =>
        `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/customers/${customerId}`,
      providesTags: (result) =>
        result ? [{ type: "INVOICE_CUSTOMERS", id: result.uuid }] : [],
    }),

    createInvoiceCustomer: build.mutation<
      InvoiceCustomer,
      {
        groupId: string;
        entityId: string;
        payload: Omit<InvoiceCustomer, "customer_id" | "is_reminders_enabled">;
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/recipients/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "INVOICE_CUSTOMERS", id: "LIST" }] : [],
    }),

    updateInvoiceCustomer: build.mutation<
      InvoiceCustomer,
      {
        groupId: string;
        entityId: string;
        customerId: string;
        payload: Partial<InvoiceCustomer>;
      }
    >({
      query: ({ groupId, entityId, customerId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/recipients/${customerId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: "INVOICE_CUSTOMERS", id: result.uuid },
              { type: "INVOICES" },
              { type: "INVOICE_SETTING" },
            ]
          : [],
    }),

    createOrUpdateInvoiceSettings: build.mutation<
      InvoiceSettings & { logo: File },
      {
        groupId: string;
        entityId: string;
        payload: Partial<Omit<InvoiceSettings, "address">> & {
          autofill_address_id?: string;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        const formData = new FormData();

        Object.entries(payload).forEach(
          // @ts-ignore
          ([key, value]) => value && formData.append(key, value)
        );

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/entity_info/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result) => {
        return result ? [{ type: "INVOICE_SETTING" }] : [];
      },
    }),

    getInvoiceSettings: build.query<
      InvoiceSettings | null,
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/entity_info/`,
        };
      },
      providesTags: (result) =>
        result || result === null ? [{ type: "INVOICE_SETTING" }] : [],
    }),

    getInvoiceItems: build.query<
      InvoiceItem[],
      {
        groupId: string;
        entityId: string;
        invoiceId: string;
      }
    >({
      query: ({ groupId, invoiceId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/invoice_item/`,
        };
      },
      providesTags: (result) =>
        result || result === null ? [{ type: "INVOICE_ITEMS" }] : [],
    }),

    createInvoiceItem: build.mutation<
      Omit<InvoiceItem, "uuid">,
      {
        groupId: string;
        invoiceId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, invoiceId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/invoice_item/`,
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICE_ITEMS" }] : []),
    }),

    updateInvoiceItem: build.mutation<
      Omit<Partial<InvoiceItem>, "uuid">,
      {
        groupId: string;
        entityId: string;
        invoiceId: string;
        invoiceItemId: string;
        payload: InvoiceItem;
      }
    >({
      query: ({ groupId, invoiceId, entityId, payload, invoiceItemId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/invoice_item/${invoiceItemId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) => (result ? [{ type: "INVOICE_ITEMS" }] : []),
    }),

    deleteInvoiceItem: build.mutation<
      null,
      {
        groupId: string;
        invoiceId: string;
        entityId: string;
        invoiceItemId: string;
      }
    >({
      query: ({ groupId, invoiceId, invoiceItemId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/invoice_item/${invoiceItemId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => [{ type: "INVOICE_ITEMS" }],
    }),

    getPendingDues: build.query<void, { groupId: string }>({
      query: ({ groupId }) => ({
        url: `/api/inkle/company/group/${groupId}/pending_dues/`,
      }),
    }),
    createInvoicingEmailConfiguration: build.mutation<
      null,
      {
        payload: {
          subject: string;
          cc_emails: string;
          greeting: string;
          body: string;
          signature: string;
          payment_info: string;
          reminder_setting_types: string;
        };
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ payload, groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/reminders/group/${groupId}/entity/${entityId}`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICE_SETTING"],
    }),
    updateInvoicingEmailConfiguration: build.mutation<
      null,
      {
        payload: {
          subject?: string;
          cc_emails?: string;
          greeting?: string;
          body?: string;
          signature?: string;
          payment_info?: string;
          reminder_setting_types?: string;
          is_reminders_enabled?: boolean;
        };
        reminderId: string;
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ payload, reminderId, groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/reminders/group/${groupId}/entity/${entityId}/reminder_setting/${reminderId}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICE_SETTING"],
    }),
    createInvoiceRecurring: build.mutation<
      null,
      {
        payload: {
          is_recurring_enabled: boolean;
          recurring_setting_type?: RecurringSettingTypes;
          recurring_end_date?: string | null;
          is_recurring_send_automated?: boolean;
        };
        invoiceId: string;
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ payload, invoiceId, groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/invoice_settings/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICES"],
    }),

    updateInvoiceRecurring: build.mutation<
      null,
      {
        payload: {
          is_recurring_enabled: boolean;
          recurring_setting_type?: RecurringSettingTypes;
          recurring_end_date?: string | null;
          is_recurring_send_automated?: boolean;
        };
        invoiceSettingId: string;
        groupId: string;
        entityId: string;
        invoiceId: string;
      }
    >({
      query: ({ payload, invoiceSettingId, groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/invoicing/invoice_settings/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/invoice_setting/${invoiceSettingId}/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["INVOICES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetPendingDuesQuery,
  useGetAllInvoicesQuery,
  useGetEntityInvoiceQuery,
  useCreateEntityInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetAllInvoiceCustomersQuery,
  useCreateInvoiceCustomerMutation,
  useUpdateInvoiceCustomerMutation,
  useGetInvoiceSettingsQuery,
  useCreateOrUpdateInvoiceSettingsMutation,
  useSendInvoiceMutation,
  useCreateInvoiceItemMutation,
  useUpdateInvoiceItemMutation,
  useGetInvoiceItemsQuery,
  useDeleteInvoiceItemMutation,
  useLazyDownloadInvoiceQuery,
  useGetAllInvoiceCustomersListQuery,
  useGetInvoiceCustomerQuery,
  useDeleteEntityInvoiceMutation,
  useLazyGetInvoiceItemsQuery,
  useLazyGetEntityInvoiceQuery,
  useCreateInvoicingEmailConfigurationMutation,
  useUpdateInvoicingEmailConfigurationMutation,
  useCreateInvoiceRecurringMutation,
  useUpdateInvoiceRecurringMutation,
} = invoiceApis;
