import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/Table";
import React, { useContext, useMemo, useState } from "react";
import { useTable } from "react-table";
import AddUserIcon from "../../static/images/UserPlus.svg";
import { Badge } from "components/design/badge";
import { InviteTeammate } from "./Modals/InviteTeammate";
import { debounce } from "utils/debouncing";
import { useGetTeamManagementMembersQuery } from "store/apis/teamSettingCPA";
import Loader from "components/design/loader";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { MakeAdmin } from "./MakeAdmin";
import { Admin, Member } from "constants/foreignCATask";
import authContext from "jwt_context&axios/authContext";
import { useParams } from "react-router-dom";

const getRole = {
  Admin: Member,
  Super_Admin: Admin,
};

export const TeamManagement = ({ groupDomain }: { groupDomain?: string }) => {
  const { isAnyServiceSuperAdmin } = useRoleBasedView();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);
  const { authtoken } = useContext(authContext);
  const { practicecrmId } = useParams<{ practicecrmId: string }>();

  const { data: members = [], isLoading } = useGetTeamManagementMembersQuery({
    searchTerm: search,
    serviceTeamId: practicecrmId || authtoken.service_team_id,
  });

  const makeAdminOption = isAnyServiceSuperAdmin
    ? [
        {
          Header: "",
          accessor: "options",
          width: "10%",
          Cell: ({ row: { original } }: any) => {
            return <MakeAdmin data={original} />;
          },
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "30%",
        Cell: ({ value }: any) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        width: "40%",
        Cell: ({ value }: any) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Role",
        accessor: "role",
        width: "20%",
        Cell: ({ value }: { value: "Admin" | "Super_Admin" }) => {
          return <div>{getRole[value]}</div>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: "10%",
        Cell: ({ value }: any) => {
          return (
            <span>
              {value === "JOINED" ? (
                <Badge
                  color="green"
                  trim={false}
                  children="Joined"
                  fitContent
                />
              ) : (
                <Badge
                  color="yellow"
                  trim={false}
                  children="Invited"
                  fitContent
                />
              )}
            </span>
          );
        },
      },
      ...makeAdminOption,
    ],
    []
  );

  const data = useMemo(() => members, [members]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //@ts-ignore
    useTable({ columns, data });

  const handleSearchChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value) {
        setSearch(value);
      } else {
        setSearch(null);
      }
    }
  );
  return (
    <div className="t-w-full t-h-full">
      <Header
        title="Team Management"
        right={
          <div className="t-flex t-gap-3">
            <Search placeholder="Search..." onChange={handleSearchChange} />
            <Button
              customType="primary"
              size="small"
              onClick={() => setShowInviteModal(true)}
            >
              <img src={AddUserIcon} alt="AddUser" />
              &nbsp; Invite member
            </Button>
          </div>
        }
      />
      <div className="t-p-5 t-overflow-auto t-relative t-pb-3">
        <Table.Container>
          <Table.Content {...getTableProps()} className="all:unset">
            <Table.Head className="t-sticky t-top-0">
              {headerGroups.map((headerGroup: any) => (
                <Table.Row
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-2"
                >
                  {headerGroup.headers.map((column: any) => (
                    <th
                      className="t-text-start"
                      key={column.id}
                      {...column.getHeaderProps({})}
                      style={{ width: column?.width }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            {isLoading ? (
              <Loader />
            ) : (
              <Table.Body {...getTableBodyProps()}>
                {rows.map((row: any) => {
                  prepareRow(row);

                  return (
                    <Table.Row
                      key={row.id}
                      {...row.getRowProps()}
                      className="t-cursor-pointer t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent hover:t-bg-i-surface-light-purple t-flex t-items-center t-justify-center t-gap-6 t-px-4 t-py-2"
                    >
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            key={cell.value}
                            {...cell.getCellProps({})}
                            style={{
                              width: cell.column?.width,
                              textAlign: cell.column?.textAlign,
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            )}
          </Table.Content>
        </Table.Container>
      </div>
      <InviteTeammate
        showInviteModal={showInviteModal}
        setShowInviteModal={setShowInviteModal}
        groupDomain={groupDomain}
      />
    </div>
  );
};
