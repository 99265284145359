import React from "react";

export const NoPermission = ({
  noDataText = "You don't have permission to access this page",
}) => {
  return (
    <div className="userPage">
      <div className="noTaskFound">{noDataText}</div>
    </div>
  );
};
