import { object } from "yup";
import * as Yup from "yup";

export const calendarReminderSchema = object({
  email: Yup.string()
    .email("Please enter valid email address")
    .test({
      name: "Required if send_email_reminders enabled",
      exclusive: false,
      params: {},
      message: "Please enter valid email address",
      test: (value: any, context: any) =>
        context.parent.send_email_reminders ? Boolean(value) : true,
    }),
  mobile: Yup.string().test({
    name: "Required if send_wa_reminders enabled",
    exclusive: false,
    params: {},
    message: "Please enter valid mobile number",
    test: (value: any, context: any) =>
      context.parent.send_wa_reminders ? value.length > 10 : true,
  }),
});
