import classNames from "classnames";
import dayjs from "dayjs";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { YYYY_MM_DD } from "../../constants/date";
import CalendarIcon from "../../static/images/CalendarBlank.svg";

const DateInputBox = ({
  value,
  onChange,
  name,
  placeholder,
  calendarIcon = false,
  disable,
}) => {
  const [active, setActive] = useState(false);
  const setDate = (date) => {
    let updatedDate = dayjs(new Date(date)).format(YYYY_MM_DD);

    const e = {
      target: {
        name: name,
        value: updatedDate,
      },
    };
    onChange(e);
  };

  const getIST = (givenDate) => {
    return new Date(givenDate);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className={classNames("dateCustomInput", {
        activeDateInput: active,
      })}
      onClick={() => {
        setActive(true);
        onClick();
      }}
      ref={ref}
    >
      <span className="iconDate">
        {value} {calendarIcon && <img src={CalendarIcon} alt="CalendarIcon" />}
      </span>
    </div>
  ));

  return (
    <DatePicker
      readOnly={disable}
      dateFormat="dd-MMM-yyyy"
      selected={value ? getIST(value) : ""}
      onChange={(date) => setDate(date)}
      placeholderText={placeholder}
      customInput={<CustomInput />}
    />
  );
};

export default DateInputBox;
