export const EMAIL_UPDATE = "EMAIL_UPDATE";
export const MOBILE_UPDATE = "MOBILE_UPDATE";
export const MOBILE = "mobile";

export const ENTITY_DATA = {
  company_name: "",
  country: "--select--",
  state_id: "--select--",
  stateUUID: "",
  formation_type_id: "--select--",
};
