import { Button } from "components/DesignSystem/Button/Button";
import Help from "components/help/help";
import {
  MOBILE_NUMBER_SUBMITTED,
  MOBILE_NUMBER_SUBMIT_FAILURE,
  MOBILE_VERIFICATION_FAILURE,
  MOBILE_VERIFICATION_SUCCESS,
} from "constants/analyticsEvents";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { AFTER_AUTH_REDIRECT_TO } from "constants/storageKeys";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useAnalytics } from "hooks/useAnalytics";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BlackArrowLeft from "static/images/BlackArrowLeft.svg";
import { setOnboardingStep } from "store/slices/onboarding";
import {
  addPhoneNumberToGoogleUser,
  getGoogleUser,
  verifyPhoneNumber,
} from "../../apis/googleAuthApis";
import { signupResendOtp } from "../../apis/signupResendOtp";
import { PHONE } from "../../constants/googleAuth";
import {
  INCORRECT_OTP,
  OTP_SENT_SUCCESSFULLY,
} from "../../constants/magicLink";
import { DURATION } from "../../constants/resendOtp";
import { timezones } from "../../data/timezones";
import authContext from "../../jwt_context&axios/authContext";
import WhatsappIcon from "../../static/images/whatsappIcon.png";
import { debounce } from "../../utils/debouncing";
import { handleEnter } from "../../utils/handleEnter";
import AuthFooter from "./authFooter";
import OtpBox from "./otpBox";
import RightSlider from "./RightSlider";
import Timer from "./Timer";
import Toggle from "./toggle";
import { FOREIGN_CA } from "constants/userTypes";
import {
  useSendOtpForMobileMutation,
  useVerifyMobileOtpMutation,
} from "store/apis/settings";
import { Profile } from "types/Models/user";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import { Form, Formik, FormikValues } from "formik";
import { BackendError } from "types/utils/error";

dayjs.extend(timezone);
dayjs.extend(utc);

const GoogleAuth = ({
  googleAccessToken,
  setLoading,
  onGoogleAuthFail,
  invitation = false,
}: {
  googleAccessToken?: string;
  setLoading?: (loading: boolean) => void;
  onGoogleAuthFail?: () => void;
  invitation?: boolean;
}) => {
  let query = useQuery();
  const { alertToast, successToast } = useToast();
  const refCode = query.get("ref");
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const [askPhoneNumber, setAskPhoneNumber] = useState(true);
  const [mobile, setMobile] = useState("");
  const [profileId, setProfileId] = useState("");
  const [userDetails, setUserDetails] = useState<Profile>();
  const [inCorrectOtp, setInCorrectOtp] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [isChecked, setChecked] = useState(true);
  const [showTimer, setShowTimer] = useState(false);
  const [key, setKey] = useState(0);
  const { setUser, setAuthtoken } = useContext(authContext);
  const [otp, setOtp] = useState("");
  const [couponCode, setCouponCode] = useState(
    localStorage.getItem("couponCode") !== "null"
      ? localStorage.getItem("couponCode")
      : ""
  );
  const [sendOtpForMobile] = useSendOtpForMobileMutation();
  const [verifyMobileOtp] = useVerifyMobileOtpMutation();

  let userData = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const { localData: refdata, removeItem: removeReferralCode } =
    useLocalStorage("referralCode", null);
  const referralCode = refCode || refdata;
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const setUserData = (user: Profile) => {
    setAuthtoken(user);
    setUser(user.access);
    localStorage.setItem("authTokens", JSON.stringify(user));

    const profile = user;
    const isPartOfServiceTeam = profile.is_any_service_user;
    const isForeignCA = profile.user_type === FOREIGN_CA;

    if (isForeignCA && !isPartOfServiceTeam) {
      dispatch(setOnboardingStep(3));
      history.push("/profile");
      return;
    }

    if (user.company_group && user.is_public_user && !user.is_restricted) {
      history.push(`/filings/${user.company_group}`);
    } else if (
      (user.company_group && user.is_public_user && user.is_restricted) ||
      user.is_restricted
    ) {
      history.push("/verify");
    } else if (user.is_archived && user.is_group_created) {
      history.push("/archived");
    } else if (user.is_group_created) {
      // move to dashboard
      history.replace(localStorage.getItem(AFTER_AUTH_REDIRECT_TO) || "/");
    } else {
      // Move to create group/entities
      dispatch(setOnboardingStep(3));
      history.push("/profile");
    }
  };

  const getGoogleUserData = async () => {
    const session_id = localStorage.getItem("TASK_SESSION_ID");
    try {
      const response = await getGoogleUser({
        access_token: googleAccessToken,
        session_id,
        accept_tos: true,
      });
      setProfileId(response.data.data?.uuid);
      setUserDetails(response.data.data);
      if (!response.data.data?.mobile) setAskPhoneNumber(true);
      else setUserData(response.data.data);
      setLoading?.(false);
    } catch (error: any) {
      onGoogleAuthFail?.();
      setLoading?.(false);
      if (googleAccessToken === "access_denied") {
        alertToast({ message: "Access denied, please try again!" });
        return;
      }
      if (typeof error?.response?.data?.error?.message === "string") {
        alertToast({ message: error?.response?.data?.error?.message });
        return;
      }
      alertToast({ message: SOMETHING_WENT_WRONG });
    }
  };

  useEffect(() => {
    dispatch(setOnboardingStep(2));
    if (googleAccessToken) {
      getGoogleUserData();
    } else {
      setProfileId(userData?.uuid);
    }
  }, []);

  const addMobileNumber = async (values: FormikValues) => {
    setIsLoading(true);
    const phone = values.phone.replace(/ /g, "");
    setMobile(phone);
    const magicLinkData = localStorage.getItem("magicLinkData");
    const partialUser = magicLinkData ? JSON.parse(magicLinkData) : {};
    try {
      await sendOtpForMobile({
        mobile: phone,
        email: partialUser?.email || userDetails?.email || userData?.email,
        whatsapp_consent: isChecked,
        accept_tos: true,
      }).unwrap();

      trackEvent(MOBILE_NUMBER_SUBMITTED, {
        screen: "Add Mobile number",
        is_whatsapp_checked: isChecked,
        email: partialUser?.email || userDetails?.email || userData?.email,
        mobile: phone,
      });
      setAskPhoneNumber(false);
    } catch (error: any) {
      trackEvent(MOBILE_NUMBER_SUBMIT_FAILURE, {
        screen: "Add Mobile number",
        email: partialUser?.email || userDetails?.email || userData?.email,
        mobile: phone,
        error_message: error?.response?.data?.error?.message,
      });
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
    setIsLoading(false);
  };

  const verifyOtp = async () => {
    setIsLoading(true);
    const magicLinkData = localStorage.getItem("magicLinkData");

    const partialUser = magicLinkData ? JSON.parse(magicLinkData) : {};

    try {
      const currentTimezone = dayjs.tz.guess();
      const timezoneTitle = timezones.find((t) =>
        t.utc.includes(currentTimezone)
      );

      //@ts-ignore
      const { data: user } = await verifyMobileOtp({
        payload: {
          mobile: mobile.toString(),
          profile_id: profileId || partialUser.uuid,
          otp,
          coupon_code: couponCode || undefined,
          wa_consent: isChecked,
          referral_code: referralCode,
          current_timezone: timezoneTitle?.value || "",
        },
      });

      if (user) {
        setUserData(user);
        removeReferralCode();
        localStorage.removeItem("couponCode");
        trackEvent(MOBILE_VERIFICATION_SUCCESS, {
          screen: "OTP Verification - Mobile",
          is_whatsapp_checked: isChecked,
          email: partialUser?.email || userDetails?.email || userData?.email,
          mobile: mobile,
        });
      }
    } catch (error: any) {
      console.log(error);
      trackEvent(MOBILE_VERIFICATION_FAILURE, {
        screen: "OTP Verification - Mobile",
        email: partialUser?.email || userDetails?.email || userData?.email,
        mobile: mobile,
        error_message: error?.response?.data?.error?.message,
      });
      alertToast({ message: error?.response?.data?.error?.message });
      updateData(error?.response?.data?.error?.message);
    }
    setIsLoading(false);
  };

  const handleVerifyOtp = debounce(() => verifyOtp());

  const updateData = (message: string) => {
    if (message === INCORRECT_OTP) setInCorrectOtp(true);
  };

  const goBack = () => {
    setAskPhoneNumber(true);
  };

  const handleCouponCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
  };

  const resendOtp = async () => {
    const magicLinkData = localStorage.getItem("magicLinkData");
    const partialUser = magicLinkData ? JSON.parse(magicLinkData) : {};
    try {
      const values = {
        phone_no: mobile.toString(),
        email: partialUser?.email || userDetails?.email || userData?.email,
      };
      let res = await signupResendOtp({ values, isMailOtp: false });
      setOtp("");
      setShowTimer(true);
      setKey((prev) => prev + 1);
      if (res?.status === 200) {
        successToast({ message: OTP_SENT_SUCCESSFULLY });
      }
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const closeModal = () => setShowHelp(false);

  return (
    <>
      <div className="rightBox googleForm otpBoxVerify t-ml-8 !t-h-screen t-w-3/5 t-border t-border-solid t-border-surface-transparent t-border-l-neutral-10  t-pt-0 sm:t-w-full">
        <div className="navigation !t-mb-6 t-w-full t-items-center">
          <div className="accountHead !t-text-left t-text-h4">
            {!askPhoneNumber && !invitation && (
              <div
                className="backBtn t-absolute -t-ml-16 -t-mt-2 sm:-t-ml-8"
                onClick={goBack}
                aria-hidden="true"
              >
                <img src={BlackArrowLeft} alt="BlackArrowLeft" />
              </div>
            )}
            Verify your mobile number
          </div>
        </div>
        {askPhoneNumber ? (
          <>
            <Formik
              initialValues={{ phone: "" }}
              onSubmit={addMobileNumber}
              validateOnChange
            >
              <Form className="t-m-0">
                <div>
                  <MobileInput
                    label="Enter mobile number"
                    name="phone"
                    placeholder="Phone Number"
                    updateIsValidPhoneNumber={(valid) => setDisable(!valid)}
                  />
                </div>
                <div className="whatsappDiv !t-my-6 !t-mb-10 !t-w-full">
                  <Toggle setToggleValue={setChecked} checked={isChecked} />
                  <img
                    src={WhatsappIcon}
                    alt="WhatsappIcon"
                    className="whatsappIcon"
                  />
                  <span className="whatsappConsent  t-w-[84%] !t-text-body-sm">
                    By sharing your WhatsApp number, you give Inkle consent to
                    send you WhatsApp messages.
                  </span>
                </div>
                <Button
                  customType="primary"
                  size="large"
                  block
                  disabled={isDisable || isLoading}
                  isLoading={isLoading}
                  type="submit"
                >
                  Continue
                </Button>
                <div className="t-mt-6 t-text-center">
                  <AuthFooter />
                </div>
              </Form>
            </Formik>
          </>
        ) : (
          <>
            <div className="t-flex t-w-full t-items-start t-justify-start">
              <label className="t-mb-4  t-font-sans t-text-caption t-text-neutral-80">
                Please enter the 6-character OTP sent to {mobile}
              </label>
            </div>
            <div className="homeForm otpPage t-m-0 sm:!t-w-full">
              <OtpBox
                otp={otp}
                setOtp={setOtp}
                inCorrectOtp={inCorrectOtp}
                getOtp={setOtp}
                width="100%"
                needOtpDetail
                setInvalidOtp={setInvalidOtp}
                className="t-m-0 t-justify-start"
              />
              <br />
              <label className="t-font-sans t-text-caption t-text-neutral-80">
                Have a coupon code? Enter it below.
              </label>
              <input
                type="text"
                className="emailBox t-flex !t-w-full t-items-center t-justify-center t-rounded t-border t-border-solid t-bg-surface-lighter-grey t-px-3 t-text-body t-font-medium t-text-text-100 t-transition-all  focus:t-bg-surface-transparent  focus:t-outline-none"
                placeholder="Enter coupon code"
                onChange={handleCouponCodeChange}
                value={couponCode || ""}
              />
              <br />
              <Button
                customType="primary"
                size="large"
                block
                onClick={handleVerifyOtp}
                disabled={isLoading}
                isLoading={isLoading}
              >
                Verify OTP
              </Button>
            </div>
          </>
        )}
        {/* <AuthFooter /> */}
        {!askPhoneNumber && (
          <div className="t-mt-4 t-w-full">
            {showTimer ? (
              <Timer
                setShowTimer={setShowTimer}
                duration={DURATION}
                key={key}
                originFromSignup
              />
            ) : (
              <span className="resendLink t-cursor-pointer" onClick={resendOtp}>
                Resend OTP
              </span>
            )}
          </div>
        )}
      </div>
      {showHelp && (
        <RightSlider width="auto" show={showHelp} closeModal={closeModal}>
          <Help show={false} loggedOut closeModal={closeModal} />
        </RightSlider>
      )}
    </>
  );
};

export default GoogleAuth;
