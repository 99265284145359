import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CreateTicketState {
  isCustomerSliderOpen: boolean;
  sliderAccordionValue: string;
  sliderTab: string;
  createTicketData: {
    messageId: string;
    channelId: string;
    showCreateTicketModal: boolean;
  };
}

const initialState: CreateTicketState = {
  createTicketData: {
    messageId: "",
    showCreateTicketModal: false,
    channelId: "",
  },
  isCustomerSliderOpen: false,
  sliderAccordionValue: "",
  sliderTab: "",
};

export const ticket = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    openCreateChatTicketModal: (
      state,
      action: PayloadAction<CreateTicketState["createTicketData"]>
    ) => {
      state.createTicketData = action.payload;
    },

    closeCreateChatTicketModal: (state) => {
      state.createTicketData = {
        channelId: "",
        messageId: "",
        showCreateTicketModal: false,
      };
    },

    setSliderAccordionValue: (state, action: PayloadAction<string>) => {
      state.sliderAccordionValue = action.payload;
    },

    setTicketSliderTab: (state, action: PayloadAction<string>) => {
      state.sliderTab = action.payload;
    },
  },
});

export const {
  openCreateChatTicketModal,
  closeCreateChatTicketModal,
  setSliderAccordionValue,
  setTicketSliderTab,
} = ticket.actions;

export default ticket.reducer;
