import { House } from "components/icons/Navbar/House";
import { useMemo, useState } from "react";
import { StateFilings } from "types/Models/StateFilings";
import WarningCircle from "static/images/WarningCircle.svg";
import CheckCircleFilled from "static/images/CheckCircleFilled.svg";
import Hourglass from "static/images/Hourglass.svg";
import Pencil from "components/icons/pencil";
import { Button } from "components/DesignSystem/Button/Button";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import {
  useAddStateMutation,
  useEditStateMutation,
  useGetChoicesQuery,
} from "store/apis/taxAndCompliance";
import { AddStateModal, EditStateModal } from "./Modals";
import { useToast } from "hooks/useToast";
import { FILING_STATES } from "dictionaries";
import { ACTION_REQUIRED, ACTIVE, COMPLETED, WITH_INKLE } from "constants/tax";
import classNames from "classnames";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import Table from "components/DesignSystem/Table/V2/Table";

const TableHeader = ({ children }: { children: React.ReactNode }) => (
  <span className="t-w-[180px] t-text-subtitle-sm t-text-text-100">
    {children}
  </span>
);

export const FilingsTable = ({
  stateFilings,
  groupId,
  entityId,
  season,
}: {
  stateFilings: StateFilings[];
  groupId: string;
  entityId: string;
  season: string;
}) => {
  const { isAdmin } = useRoleBasedView();
  const { data: countries = [] } = useGetCountriesDataQuery();
  const { data: complianceStatuses } = useGetChoicesQuery({
    choice: "COMPLIANCE_STATUS",
  });
  const { data: raStatuses } = useGetChoicesQuery({
    choice: "REGISTERED_AGENT",
  });

  const { alertToast, successToast } = useToast();

  const [addNewState] = useAddStateMutation();
  const [editExistingState] = useEditStateMutation();

  const states =
    countries.find((country) => country.name === "United States")?.states || [];

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedFiling, setSelectedFiling] = useState("");
  const [hoverRow, setHoverRow] = useState(null);

  type FILINGS_STATES_TYPE = keyof typeof FILING_STATES;

  const setCellData = (value: FILINGS_STATES_TYPE) => {
    switch (value) {
      case ACTION_REQUIRED:
        return (
          <>
            <img
              className="t-w-4 t-h-4"
              src={WarningCircle}
              alt={FILING_STATES[value]}
            />{" "}
            {FILING_STATES[value]}
          </>
        );

      case WITH_INKLE:
        return (
          <>
            <img
              className="t-w-4 t-h-4"
              src={Hourglass}
              alt={FILING_STATES[value]}
            />{" "}
            {FILING_STATES[value]}
          </>
        );

      case COMPLETED:
      case ACTIVE:
        return (
          <>
            <img
              className="t-w-4 t-h-4"
              src={CheckCircleFilled}
              alt={FILING_STATES[value]}
            />{" "}
            {FILING_STATES[value]}
          </>
        );

      default:
        return <span className="t-ml-1">-</span>;
    }
  };

  const addState = async (values: { state: string }) => {
    try {
      await addNewState({
        groupId,
        entityId,
        data: {
          season,
          state_id: values.state,
        },
      }).unwrap();

      setIsAddModalOpen(false);
      successToast({
        title: "State added successfully.",
      });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const editState = async (values: {
    state_filing_id?: string;
    foreign_qualification?: string;
    franchise_tax?: string;
    statement_of_information?: string;
    income_tax_filing?: string;
    registered_agent?: string;
  }) => {
    const data = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );

    try {
      await editExistingState({ groupId, entityId, data }).unwrap();

      setIsEditModalOpen(false);
      successToast({
        title: "State has been changed successfully.",
        size: "large",
      });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const columnHelper = createColumnHelper<StateFilings>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("state_name", {
        header: "State",
        size: 16,
        cell: (info) => {
          return <span className="t-text-body">{info.getValue()}</span>;
        },
      }),
      columnHelper.accessor("foreign_qualification", {
        header: "Foreign Qualification",
        size: 16,
        cell: (info) => {
          return (
            <span className="t-text-body t-text-text-60 t-flex t-items-center t-gap-1">
              {info.row?.original?.is_home_state ? (
                <span className="t-text-blue-50 t-flex t-items-center t-gap-1">
                  <House size="16" fill="#5DADE2" /> <span>Home</span>
                </span>
              ) : (
                setCellData(info.getValue())
              )}
            </span>
          );
        },
      }),
      columnHelper.accessor("franchise_tax", {
        header: "Franchise Tax",
        size: 16,
        cell: (info) => {
          return (
            <span className="t-text-body t-text-text-60 t-flex t-items-center t-gap-1">
              {setCellData(info.getValue())}
            </span>
          );
        },
      }),
      columnHelper.accessor("statement_of_information", {
        header: "Statement of Information",
        size: 16,
        cell: (info) => {
          return (
            <span className="t-text-body t-text-text-60 t-flex t-items-center t-gap-1">
              {setCellData(info.getValue())}
            </span>
          );
        },
      }),
      columnHelper.accessor("income_tax", {
        header: "Income tax filings",
        size: 16,
        cell: (info) => {
          return (
            <span className="t-text-body t-text-text-60 t-flex t-items-center t-gap-1">
              {setCellData(info.getValue())}
            </span>
          );
        },
      }),
      columnHelper.accessor("registered_agent", {
        header: "Registered Agent",
        size: 16,
        cell: (info) => {
          return (
            <span className="t-text-body t-text-text-60 t-flex t-items-center t-gap-1">
              {setCellData(info.getValue())}
            </span>
          );
        },
      }),

      ...(isAdmin
        ? [
            columnHelper.display({
              header: "Edit",
              size: 4,
              cell: ({ row }) => {
                return (
                  isAdmin && (
                    <span
                      className={classNames("t-opacity-0", {
                        "t-opacity-100": hoverRow === row.id,
                      })}
                      onClick={() => {
                        setSelectedFiling(row.original.uuid);
                        setIsEditModalOpen(true);
                      }}
                    >
                      <Button customType="transparent" size="small">
                        <Pencil size="16" color="#706A85" />
                      </Button>
                    </span>
                  )
                );
              },
            }),
          ]
        : []),
    ],
    [hoverRow, isAdmin]
  );

  const table = useReactTable({
    // @ts-ignore
    columns,
    data: stateFilings,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="t-mb-4">
      <div className="t-flex t-justify-between t-mt-12 t-mb-6">
        <p className="t-m-0 t-text-subtitle t-text-text-100">State Filings</p>
        {isAdmin && (
          <Button
            customType="secondary"
            size="small"
            onClick={() => setIsAddModalOpen(true)}
          >
            Add State
          </Button>
        )}
      </div>

      <Table.Container>
        <Table.Content>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: `${header.getSize()}%` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body>
            {table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}%` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Content>
      </Table.Container>

      <AddStateModal
        open={isAddModalOpen}
        onOpenChange={setIsAddModalOpen}
        onSubmit={addState}
        states={states}
      />

      <EditStateModal
        open={isEditModalOpen}
        onOpenChange={setIsEditModalOpen}
        onSubmit={editState}
        complianceStatuses={complianceStatuses || []}
        raStatuses={raStatuses || []}
        selectedFiling={selectedFiling}
      />
    </div>
  );
};
