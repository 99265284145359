import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface InitialState {
  isModalOpen: boolean;
  channelId?: string | null;
  groupId?: string | null;
  editing?: boolean;
  requestId?: string;
  documentsRequest?: {
    uuid: string;
    type: string;
    is_uploaded: string;
  }[];
}

const initialState: InitialState = {
  isModalOpen: false,
};

export const requestDocomentInChat = createSlice({
  name: "requestDocumentsInChat",
  initialState,
  reducers: {
    openRequestDocumentsModal: (state, action: PayloadAction<InitialState>) => {
      state.isModalOpen = action.payload.isModalOpen;
      state.channelId = action.payload.channelId;
      state.groupId = action.payload.groupId;
      state.documentsRequest = action.payload.documentsRequest;
      state.requestId = action.payload.requestId;
    },

    toggleDocumentRequestEdit: (state, action: PayloadAction<boolean>) => {
      state.editing = action.payload;
    },
  },
});

export const { openRequestDocumentsModal, toggleDocumentRequestEdit } =
  requestDocomentInChat.actions;

export default requestDocomentInChat.reducer;
