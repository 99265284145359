import React, {
  ChangeEvent,
  Dispatch,
  isValidElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import cx from "classnames";

import "static/styles/components/questionAnswers.css";
import { Button } from "./design/button";
import { ArrowRight } from "./icons/ArrowRight";
import { ConditionalLink } from "./conditionalLink";
import { Button as NewButton } from "components/DesignSystem/Button/Button";

const UPDATE_QUESTION = "UPDATE_QUESTION";
const MAKE_ACTIVE = "MAKE_ACTIVE";
const MOVE_TO_NEXT = "MOVE_TO_NEXT";
const MOVE_TO_PREVIOUS = "MOVE_TO_PREVIOUS";
const NEXT = "NEXT";
const PREVIOUS = "PREVIOUS";

type QuestionProps = {
  question: string;
  children: ReactNode;
  questionKey: string;
};

type QuestionAnswerListProps = {
  children: ReactNode;
  note: string;
  onChange: (a: Record<string, string>) => void;
  active?: string;
  disabled?: boolean;
  openShowUploadFileScreen: () => void;
};

type InternalQuestion = {
  active: boolean;
  question: string;
  questionKey: string;
  answer: string;
};

type QuestionAnswerContextAction = {
  type: "UPDATE_QUESTION" | "MAKE_ACTIVE" | "MOVE_TO_NEXT" | "MOVE_TO_PREVIOUS";
  payload?: Partial<InternalQuestion>;
};

type QuestionAnswerContextT = {
  state: InternalQuestion[];
  dispatch: Dispatch<QuestionAnswerContextAction>;
};

const QuestionAnswerContext = React.createContext<QuestionAnswerContextT>({
  state: [],
  dispatch: () => {},
});

const moveToQuestion = (
  questions: InternalQuestion[],
  step: "NEXT" | "PREVIOUS"
) => {
  const activeQuestionIndex = questions.findIndex((s) => s.active);

  const indexToUpdate =
    step === NEXT ? activeQuestionIndex + 1 : activeQuestionIndex - 1;

  let questionToUpdate = questions[indexToUpdate];
  let updatedList = [...questions];

  if (questionToUpdate) {
    updatedList = updatedList.map((u) =>
      u.questionKey === questionToUpdate.questionKey
        ? {
            ...u,
            active: true,
          }
        : u
    );

    return updatedList.map((u) =>
      u.questionKey === questions[activeQuestionIndex].questionKey
        ? {
            ...u,
            active: false,
          }
        : u
    );
  }

  return updatedList;
};

const reducer = (
  state: InternalQuestion[],
  action: QuestionAnswerContextAction
): InternalQuestion[] => {
  switch (action.type) {
    case UPDATE_QUESTION:
      let updatedList = state.map((s) =>
        s.questionKey === action.payload?.questionKey
          ? { ...s, ...action.payload }
          : s
      );

      return updatedList;
    // return moveToQuestion(updatedList, "NEXT");

    case MAKE_ACTIVE:
      return state.map((s) =>
        s.questionKey === action.payload?.questionKey
          ? { ...s, active: true }
          : { ...s, active: false }
      );

    case MOVE_TO_NEXT:
      return moveToQuestion(state, NEXT);

    case MOVE_TO_PREVIOUS:
      return moveToQuestion(state, PREVIOUS);

    default:
      return state;
  }
};

type QuestionAnswersWrapperProps = {
  isActive: boolean;
} & QuestionProps;

const QuestionAnswersWrapper = ({
  isActive,
  ...props
}: QuestionAnswersWrapperProps) => {
  if (isActive) {
    return <QuestionAnswers {...props} />;
  }

  return null;
};

export const QuestionAnswerList = ({
  children,
  note,
  onChange,
  active,
  disabled = false,
  openShowUploadFileScreen,
}: QuestionAnswerListProps) => {
  const questionList: InternalQuestion[] = useMemo(() => {
    let list = React.Children.map(children, (child) => {
      if (isValidElement(child) && child.type === QuestionAnswers) {
        return {
          question: child.props.question as string,
          questionKey: child.props.questionKey as string,
          active: false,
          answer: "",
        };
      }
      return undefined;
    });
    list = (list || []).filter(Boolean);

    const noActiveQuestionsPresent = list.every((q) => !q.active);

    if (noActiveQuestionsPresent) {
      list[0] = { ...list[0], active: true };
    }

    return list;
  }, [children]);

  const [state, dispatch] = useReducer(reducer, questionList || []);

  useEffect(() => {
    dispatch({ type: MAKE_ACTIVE, payload: { questionKey: active } });
  }, [active]);

  const activeList: Record<string, boolean> = state.reduce(
    (acc, c) => (c ? { ...acc, [c.questionKey]: c.active } : acc),
    {}
  );

  const answersList: Record<string, string> = state.reduce(
    (acc, c) => (c ? { ...acc, [c.questionKey]: c.answer } : acc),
    {}
  );

  const answers = Object.values(answersList);

  useEffect(() => {
    if (answers.filter(Boolean).length > 0) {
      onChange(answersList);
    }
  }, answers);

  const currentQuestion = state.find((s) => s.active);
  const currentQuestionIndex = state.findIndex((s) => s.active);

  const isFirstQuestionActive = currentQuestionIndex === 0;
  const isLastQuestionActive = currentQuestionIndex === state.length - 1;

  const isCurrentQuestionAnswered =
    currentQuestion?.answer !== undefined &&
    currentQuestion?.answer !== null &&
    currentQuestion?.answer !== "";

  return (
    <QuestionAnswerContext.Provider value={{ state, dispatch }}>
      <div className="question-answers-list">
        <div className="question-answers-list-actions d-flex justify-content-between">
          {!isFirstQuestionActive && (
            <Button
              color="cancel"
              size="small"
              onClick={() => dispatch({ type: MOVE_TO_PREVIOUS })}
            >
              <span className="question-answers-list-btn-icon">
                <ArrowRight />
              </span>
            </Button>
          )}
          {!isLastQuestionActive && isCurrentQuestionAnswered && (
            <div className="ms-auto">
              <Button
                color="cancel"
                size="small"
                onClick={() => dispatch({ type: MOVE_TO_NEXT })}
              >
                <ArrowRight />
              </Button>
            </div>
          )}
        </div>
        <div className="question-answers-list-note">{note}</div>
        <div
          className={cx("question-answers-list-list", {
            "question-answers-list-list-disabled": disabled,
          })}
        >
          {React.Children.map(children, (child) => {
            if (isValidElement(child)) {
              return (
                <QuestionAnswersWrapper
                  isActive={activeList[child.props.questionKey]}
                  {...child.props}
                />
              );
            }
          })}
        </div>
        <div className="t-flex t-items-center t-gap-1">
          <div>Already have the form?</div>
          <NewButton
            customType="transparent"
            onClick={openShowUploadFileScreen}
          >
            <div className="t-text-purple">Upload here</div>
          </NewButton>
        </div>
      </div>
    </QuestionAnswerContext.Provider>
  );
};

const QuestionContenxt = React.createContext("");
export const QuestionAnswers = ({
  question,
  children,
  questionKey,
}: QuestionProps) => {
  return (
    <QuestionContenxt.Provider value={questionKey}>
      <div className="question d-flex flex-column gap-4">
        <p className="question-answer-item-title m-0">{question}</p>
        <div className="d-flex flex-column gap-3">{children}</div>
      </div>
    </QuestionContenxt.Provider>
  );
};

type AnswerProps = {
  answer: string;
  answerKey: string;
};

export const Answer = ({ answer, answerKey }: AnswerProps) => {
  const { state, dispatch } = useContext(QuestionAnswerContext);
  const questionKey = useContext(QuestionContenxt);

  const selectedAnswer = state.find(
    (s) => s.questionKey === questionKey
  )?.answer;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UPDATE_QUESTION,
      payload: { answer: e.target.value, questionKey },
    });
  };

  return (
    <label
      htmlFor={answerKey}
      className="question-answer-item-input-label d-flex gap-3"
    >
      <input
        checked={selectedAnswer === answerKey}
        onChange={onChange}
        className="question-answer-item-input"
        type="radio"
        value={answerKey}
        id={answerKey}
        name={questionKey}
      />
      <span>{answer}</span>
    </label>
  );
};
