import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TPXInvoiceState {
  openTransferMoney: { isOpen: boolean };
  invoiceFilters: { search_term: null | string; page_num: number };
  transferTableFilters: { search_term: null | string; page_num: number };
}

const initialState: TPXInvoiceState = {
  invoiceFilters: { search_term: null, page_num: 1 },
  transferTableFilters: { search_term: null, page_num: 1 },
  openTransferMoney: { isOpen: false },
};

export const invoice = createSlice({
  name: "Invoices",
  initialState,
  reducers: {
    setInvoiceTableFilters: (
      state,
      action: PayloadAction<TPXInvoiceState["invoiceFilters"]>
    ) => {
      state.invoiceFilters = action.payload;
    },
    setTransferTableFilters: (
      state,
      action: PayloadAction<TPXInvoiceState["invoiceFilters"]>
    ) => {
      state.invoiceFilters = action.payload;
    },
    openTransferMoney: (state) => {
      state.openTransferMoney.isOpen = true;
    },
    closeTransferMoney: (state) => {
      state.openTransferMoney.isOpen = false;
    },
  },
});

export const {
  openTransferMoney,
  closeTransferMoney,
  setInvoiceTableFilters,
  setTransferTableFilters,
} = invoice.actions;

export default invoice.reducer;
