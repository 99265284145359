export const companyFormationTypes = [
  "Limited",
  "Corp.",
  "Corp",
  "Corporation",
  "Inc.",
  "Inc",
  "Incorporated",
  "Incorporation",
  "Pvt. Ltd.",
  "Ltd.",
  "Ltd",
  "LLP",
  "LLC",
  "Co.",
  "Co",
  "Association",
  "Trust",
  "Foundation",
  "Fund",
  "Syndicate",
  "Company",
  "PBC",
];
