import React, { useEffect, useState } from "react";
import FilledCaretRight from "../../../static/images/FilledRightCaret.svg";
import FilledCaretLeft from "../../../static/images/FilledLeftCaret.svg";

// components
import { Button } from "components/design/button";

// constants
import {
  INTRO_EMAIL,
  PROMO_CODE,
  SPECIAL_EMAIL,
  SPECIAL_URL,
} from "constants/perks";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";

const PerkInformation = ({
  goBack,
  setPerkData,
  handleChange,
  setCurrentState,
  perkData,
}) => {
  const storeData = (values) => {
    setPerkData((perkData) => ({ ...perkData, ...values }));
    setCurrentState(2);
  };

  return (
    <>
      <span className="perkRightContainerHeader">Perk Information</span>
      <Formik initialValues={perkData} onSubmit={storeData}>
        <Form className="newPerkForm t-w-full" onChange={handleChange}>
          <div className="t-flex t-flex-col t-gap-4">
            <TextInput
              placeholder="Your discount/offer in one sentence"
              name="offer_description"
              value={perkData?.offerDescription}
              label="Offer"
              required
            />
            <TextArea
              placeholder="Detail description of the company/product or details about the perk"
              name="description"
              onChange={handleChange}
              label="Description"
              required
            />
            <SelectDropDown name="perkType" label="Perk Type" required>
              <option value="">Choose your perk type</option>
              <option value={PROMO_CODE}>Coupon Code</option>
              <option value={SPECIAL_URL}>UTM Link</option>
              <option value={SPECIAL_EMAIL}>Referral Email</option>
              <option value={INTRO_EMAIL}>Inkle Introduction</option>
            </SelectDropDown>

            <TextInput
              placeholder="Contact email"
              name="contactEmail"
              label="Contact Email"
              type="email"
              value={perkData.contactEmail}
              onChange={handleChange}
              required
            />
          </div>
          <div className="d-flex justify-content-between w-100 perkBtns">
            <Button size="medium" color="cancel" onClick={goBack} type="button">
              Cancel
            </Button>
            <span>
              <Button size="medium" color="transparent">
                <img
                  onClick={() => setCurrentState(0)}
                  src={FilledCaretLeft}
                  alt="FilledCaretLeft"
                />
              </Button>
              &nbsp;
              <Button size="medium" color="transparent">
                <img src={FilledCaretRight} alt="FilledCaretRight" />
              </Button>
            </span>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default PerkInformation;
