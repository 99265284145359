export const ChannelFrozenBlocker = () => (
  <div className="t-flex t-flex-col t-mb-8 t-gap-0.5">
    <div className="t-flex t-gap-6 t-px-3 t-items-center">
      <hr className="t-bg-[var(--str-chat\_\_date-separator-line-color)] t-flex-1 t-border-none t-h-px t-m-0" />
      <span className="t-text-subtext t-text-text-60">
        Chat has been archived
      </span>
      <hr className="t-bg-[var(--str-chat\_\_date-separator-line-color)] t-flex-1 t-border-none t-h-px t-m-0" />
    </div>
    <p className="t-m-0 t-text-text-30 t-text-body-sm t-text-center">
      You can no longer send messages in this channel
    </p>
  </div>
);
