import { axiosInstance } from "./apiInstance";
import qs from "qs";

export const getServiceTeamGroupData = ({
  page_num,
  search_term,
  tag_filter,
  sort_filter,
  subscription_filter,
  bank_filter,
  stage_filter,
  agent_filter,
}) => {
  let url = "/api/inkle/company/v2/provider/groups/";

  let queryUrl = qs.stringify(
    {
      page_num,
      search_term,
      tag_filter,
      sort_filter,
      subscription_filter,
      bank_filter,
      stage_filter,
      agent_filter,
    },
    { skipNulls: true }
  );

  if (queryUrl) {
    url = url.replace("/groups/", "/groups?");
    url += queryUrl;
  }

  return axiosInstance.get(url);
};

export const getGroupNotes = async ({ groupId, currentPage }) => {
  return await axiosInstance.get(
    `/api/inkle/notes/company/group/${groupId}/?page_num=${currentPage}`
  );
};

export const addGroupNotes = async ({ groupId, payload }) => {
  return await axiosInstance.post(
    `/api/inkle/notes/company/group/${groupId}/`,
    payload
  );
};

export const updateContactDates = async ({ groupId, payload }) => {
  return await axiosInstance.put(
    `/api/inkle/company/v1/group/${groupId}/update/`,
    payload
  );
};

export const addGroupNotesWithDates = async ({ groupId, payload }) => {
  return await axiosInstance.post(
    `/api/inkle/company/v1/group/${groupId}/meeting/`,
    payload
  );
};
