import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TASK_AMENDMENT } from "constants/taskStates";
import { useToast } from "hooks/useToast";
import React, { FC } from "react";
import { useAmendFilingMutation } from "store/apis/task";

type AmendFilingConfirmationProps = {
  show: boolean;
  closeModal: () => void;
  taskId: string;
  groupId: string;
};

export const AmendFilingConfirmation: FC<AmendFilingConfirmationProps> = ({
  show,
  closeModal,
  taskId,
  groupId,
}) => {
  const [amendTask, { isLoading }] = useAmendFilingMutation();
  const { alertToast } = useToast();

  const amendFiling = async () => {
    try {
      await amendTask({
        taskId,
        groupId,
        payload: { next_state_type: TASK_AMENDMENT },
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Amend filing</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body t-text-text-black">
            Are you sure you want to amend this filing?
            <div className="t-font-semibold">You can't undo this action.</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-3">
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              disabled={isLoading}
              customType="danger"
              isLoading={isLoading}
              onClick={amendFiling}
            >
              Amend
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
