import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { Connection } from "store/apis/booksConnections";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { object, string } from "yup";
import SolidRedCross from "static/images/SolidRedCross.svg";
import { useDeleteBankMutation } from "store/apis/bankConnections";

export const DeleteBankModal = ({
  close,
  isOpen,
  connection,
}: ModalProps & { connection: Connection }) => {
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [deleteBank, { isLoading }] = useDeleteBankMutation();

  const removeDirectAccount = async () => {
    try {
      await deleteBank({
        groupId: groupId,
        entityId: entityId,
        bankId: connection.uuid,
      }).unwrap();

      successToast({
        message: `${connection.name} deleted successfully!`,
      });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        initialValues={{ delete: "" }}
        onSubmit={removeDirectAccount}
        validateOnMount
        validateOnChange
        validationSchema={object({
          delete: string()
            .matches(/^DELETE$/, {
              message: 'Text must match the following: "DELETE"',
            })
            .required('Text must match the following: "DELETE"'),
        })}
      >
        {({ submitForm, isValid }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Delete bank</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-flex t-gap-4 t-flex-col">
                <div className="t-text-text-black t-text-body">
                  Are you sure you want to delete
                  <b> {connection.name}?</b>
                </div>
                <div className="t-flex t-flex-col t-gap-3 t-py-6">
                  <div className="t-text-subtitle t-text-text-100">
                    Upon deleting your account:
                  </div>
                  <div className="t-text-subtitle t-font-medium t-text-text-60">
                    <img src={SolidRedCross} alt="lose" className="t-mr-2" />
                    You lose access to your transactions
                  </div>
                  <div className="t-text-subtitle t-font-medium t-text-text-60">
                    <img src={SolidRedCross} alt="lose" className="t-mr-2" />
                    You lose access to transaction invoices
                  </div>
                  <div className="t-text-subtitle t-font-medium t-text-text-60">
                    <img src={SolidRedCross} alt="lose" className="t-mr-2" />
                    You lose progress on transaction categories
                  </div>
                  <div className="t-text-subtitle t-font-medium t-text-text-60">
                    <img src={SolidRedCross} alt="lose" className="t-mr-2" />
                    You lose all relevant bank account information
                  </div>
                </div>
                <TextInput
                  hideError
                  required
                  name="delete"
                  label={
                    <>
                      Type<span className="t-text-red"> DELETE </span>
                      to confirm.
                    </>
                  }
                  placeholder="DELETE"
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="t-flex t-gap-3 t-justify-end t-w-full">
                  <Button
                    customType="secondary"
                    onClick={close}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="danger"
                    onClick={submitForm}
                    disabled={isLoading || !isValid}
                    isLoading={isLoading}
                  >
                    Delete
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
