import { CSVParsedResponse } from "types/Models/reconciliation";
import { emptyApi } from "./emptyApi";

export type ReconciliationTxn = {
  amount: number;
  description: string;
  date: string;
  source: "CSV" | "INKLE_BOOKS" | "MANUAL";
  txn_uuid?: string;
  duplicate_with_id?: string;
  merchant?: string;
};

type ReconcileStateResult = {
  statement_ending_balance: number;
  entity_bank_account_id: string;
  reconcilation_end_date: string;
  reconcilation_start_date: string;
  inkle_total_balance: number;
  missing_txns: ReconciliationTxn[];
  duplicate_txns: ReconciliationTxn[];
  unaccounted_txns: [];
};
type UpdateStatementBalanceResult = {
  bank_csv_id: string;
  entity_id: string;
  redis_store_key: string;
  reconcilation_start_date: string;
  reconcilation_end_date: string;
  last_reconcilation_status: "ONGOING" | "COMPLETED" | "PENDING";
  customer_ending_balance: number;
  entity_bank_account_id: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    uploadReconcileCSV: build.mutation<
      CSVParsedResponse,
      {
        groupId: string;
        entityId: string;
        payload: {
          entity_bank_account_id: string;
          reconcilation_ending_date: string;
          customer_statement_ending_balance: string;
          customer_statement_starting_balance: string;
          csv: File;
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        const body = new FormData();
        Object.entries(payload).forEach(([name, value]) => {
          body.append(name, value);
        });

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/v2/upload_csv/`,
          method: "POST",
          body,
        };
      },
    }),
    storeCSVtransactions: build.mutation<
      {
        message: string;
        reconcilation_state_id: string;
        redis_csv_store_key: string;
      },
      {
        groupId: string;
        entityId: string;
        bank_csv_id: string;
        date_field: string;
        description_field: string;
        merchant_field: string;
        amount_field: string;
        credit_field: string;
        debit_field: string;
        reconcilation_state_id: string;
      }
    >({
      query: ({
        groupId,
        entityId,
        date_field,
        description_field,
        merchant_field,
        amount_field,
        debit_field,
        credit_field,
        bank_csv_id,
        reconcilation_state_id,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/reconcile_transactions/`,
          method: "POST",
          body: {
            date_field,
            description_field,
            merchant_field,
            amount_field,
            debit_field,
            credit_field,
            bank_csv_id,
            reconcilation_state_id,
          },
        };
      },
    }),
    getReconciliationStatus: build.query<
      ReconcileStateResult,
      { reconcilationId: string; groupId: string; entityId: string }
    >({
      query: ({ reconcilationId, entityId, groupId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/perform_reconcilation/?reconcilation_state_id=${reconcilationId}`,
        };
      },
      providesTags: ["RECONCILIATION"],
    }),
    updateStatementBalance: build.mutation<
      UpdateStatementBalanceResult,
      {
        groupId: string;
        entityId: string;
        statementEndingBalance: string;
        reconcilationId: string;
      }
    >({
      query: ({
        groupId,
        entityId,
        statementEndingBalance,
        reconcilationId,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/reconcilation_state/`,
          method: "PUT",
          body: {
            statement_ending_balance: statementEndingBalance,
            reconcilation_state_id: reconcilationId,
          },
        };
      },
      invalidatesTags: ["RECONCILIATION"],
    }),
    completeReconciliation: build.mutation<
      any,
      {
        groupId: string;
        entityId: string;
        reconcilationId: string;
        duplicateTransactionsList: string[];
        manualTxnsToBeAddedList: string[];
        csvTxnsToBeAddedList: string[];
      }
    >({
      query: ({
        groupId,
        entityId,
        reconcilationId,
        duplicateTransactionsList,
        manualTxnsToBeAddedList,
        csvTxnsToBeAddedList,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/perform_reconcilation/`,
          method: "POST",
          body: {
            reconcilation_state_id: reconcilationId,
            duplicate_transactions_list: duplicateTransactionsList,
            manual_txns_to_be_added_list: manualTxnsToBeAddedList,
            csv_txns_to_be_added_list: csvTxnsToBeAddedList,
          },
        };
      },
      invalidatesTags: ["RECONCILIATION", { type: "PlaidBanks", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useUploadReconcileCSVMutation,
  useStoreCSVtransactionsMutation,
  useGetReconciliationStatusQuery,
  useUpdateStatementBalanceMutation,
  useCompleteReconciliationMutation,
} = extendedApi;
