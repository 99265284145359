import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { CHAT_CARD_TYPE, TASK_ADDITIONAL_PAYMENT } from "constants/chatType";
import { Form, Formik, FormikValues } from "formik";
import { BalancingPaymentSchema } from "formValidations/editPriceAndDescriptionSchema";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { FC } from "react";
import { useParams } from "react-router-dom";
import {
  useGetTaskFromChannelIdQuery,
  useSendDocumentToReviewMutation,
} from "store/apis/task";
import { useChannelStateContext, useChatContext } from "stream-chat-react";

type IBalancePaymentProps = {
  show: boolean;
  closeModal: () => void;
};

export const BalancePayment: FC<IBalancePaymentProps> = ({
  show,
  closeModal,
}) => {
  const { channel: currentChannel } = useChatContext();
  const [addBalancePayment, { isLoading }] = useSendDocumentToReviewMutation();
  const { channel } = useChannelStateContext();
  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: currentChannel?.id!,
    },
    {
      skip: !currentChannel?.id,
    }
  );
  const { tasks, company_group: companyGroup, entity } = channelTask || {};
  const task =
    tasks?.find((task) => task.is_parent_task) || tasks?.[1] || tasks?.[0];
  const { uuid: groupUuid } = useCurrentGroupContext();
  const { taskId: taskUuid } = useParams<{ taskId: string }>();
  const channelId = channel.id || "";
  const groupId = companyGroup?.uuid || groupUuid;
  const taskId = task?.uuid || taskUuid;
  const { alertToast } = useToast();

  const handleSubmit = async (values: FormikValues) => {
    const { amount, scopeOfWork } = values;
    try {
      const payload = {
        task_id: taskId,
        group_id: groupId,
        entity_id: entity?.uuid!,
        custom_type: CHAT_CARD_TYPE,
        document_operation: TASK_ADDITIONAL_PAYMENT,
        price: amount,
        description: scopeOfWork,
        payment_required: true,
      };
      await addBalancePayment({
        channel_id: channelId,
        payload,
      }).unwrap();
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Add Payment Details</Modal.Title>
          <Modal.Close />
        </Modal.Header>

        <Formik
          initialValues={{
            amount: "",
            scopeOfWork: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={BalancingPaymentSchema}
          validateOnMount={false}
          validateOnBlur={false}
          validateOnChange
        >
          {({ isValid, isSubmitting }) => {
            return (
              <Form className="t-m-0">
                <Modal.Body className="t-overflow-auto t-max-h-[500px]">
                  <TextInput
                    block
                    name="amount"
                    placeholder="Enter amount"
                    label="Amount"
                    type="number"
                  />
                  <div className="t-mt-4">
                    <TextArea
                      placeholder="Enter reason for balancing payment"
                      label="Reason for Balancing Payment"
                      name="scopeOfWork"
                      block
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button type="button" onClick={closeModal}>
                      Back
                    </Button>
                    <Button
                      type="submit"
                      customType="primary"
                      disabled={!isValid || isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Add
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
