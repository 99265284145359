import { FileObject } from "types/Models/fileObject";
import { emptyApi } from "./emptyApi";

export type DocumentRequest = {
  uuid: string;
  group_id: string;
  message_id: string;
  status: "SUBMITTED" | "REQUESTED";
  requested_documents: [
    {
      uuid: string;
      name: string;
      document_type_id: string;
      description: string;
      document: FileObject;
      is_uploaded: boolean;
    }
  ];
};

export type DocumentRequestPayload = {
  channel_id: string;
  request_data: {
    document_type_id: string;
    description: string;
  }[];
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllDocumentTypes: build.query<
      { uuid: string; name: string }[],
      { groupId: string }
    >({
      query: ({ groupId }) =>
        `/api/inkle/messaging/group/${groupId}/document-type/`,
    }),
    createDocumentRequest: build.mutation<
      DocumentRequest,
      {
        groupId: string;
        payload: DocumentRequestPayload;
      }
    >({
      query: ({ groupId, payload }) => ({
        url: `/api/inkle/messaging/group/${groupId}/document-request/`,
        method: "POST",
        body: payload,
      }),
    }),
    updateDocumentRequest: build.mutation<
      DocumentRequest,
      {
        groupId: string;
        requestId: string;
        payload: Omit<DocumentRequestPayload, "channel_id">;
      }
    >({
      query: ({ groupId, payload, requestId }) => {
        return {
          url: `/api/inkle/messaging/group/${groupId}/document-request/`,
          method: "PUT",
          body: { document_request_id: requestId, ...payload },
        };
      },
      invalidatesTags: (res) =>
        res ? [{ type: "DOCUMENT_REQUEST", id: res.uuid }] : [],
    }),
    uploadRequestedDocument: build.mutation<
      DocumentRequest,
      { requestedDocumentId: string; groupId: string; file: File }
    >({
      query: ({ groupId, requestedDocumentId, file }) => {
        const body = new FormData();
        body.append("file", file);

        return {
          url: `/api/inkle/messaging/group/${groupId}/document-request/${requestedDocumentId}/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (res) =>
        res ? [{ type: "DOCUMENT_REQUEST", id: res.uuid }] : [],
    }),
    deleteRequestedDocument: build.mutation<
      DocumentRequest,
      { requestedDocumentId: string; groupId: string }
    >({
      query: ({ groupId, requestedDocumentId }) => ({
        url: `/api/inkle/messaging/group/${groupId}/document-request/${requestedDocumentId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (res) =>
        res ? [{ type: "DOCUMENT_REQUEST", id: res.uuid }] : [],
    }),
    getAllDocumentRequests: build.query<
      { pending_requests: DocumentRequest[] },
      { groupId: string }
    >({
      query: ({ groupId }) =>
        `/api/inkle/messaging/group/${groupId}/document-request/`,
      providesTags: (res) =>
        res && res.pending_requests.length > 0
          ? res?.pending_requests.map((p) => ({
              type: "DOCUMENT_REQUEST",
              id: p.uuid,
            }))
          : [],
    }),
    getDocumentRequest: build.query<
      DocumentRequest,
      { groupId: string; requestId: string }
    >({
      query: ({ groupId, requestId }) =>
        `/api/inkle/messaging/group/${groupId}/document-request/?document_request_id=${requestId}`,
      providesTags: (res) =>
        res ? [{ type: "DOCUMENT_REQUEST", id: res.uuid }] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateDocumentRequestMutation,
  useGetAllDocumentRequestsQuery,
  useGetDocumentRequestQuery,
  useGetAllDocumentTypesQuery,
  useUploadRequestedDocumentMutation,
  useDeleteRequestedDocumentMutation,
  useUpdateDocumentRequestMutation,
} = extendedApi;
