import React from "react";

//constants
import {
  INTRO_EMAIL,
  PROMO_CODE,
  SPECIAL_URL,
  GENERIC,
  INTRO_EMAIL_DETAILS,
  SPECIAL_URL_DETAILS,
  SPECIAL_EMAIL,
} from "../../constants/perks";

// utils
import { openLink } from "../../utils/openLink";

const PerksAccessDetails = ({ perk }) => {
  const { perk_type, website, redeem_details } = perk;
  const genericInstruction = redeem_details?.[0]?.redemption_instruction;
  const specialEmailInstructions = redeem_details?.[0]?.redeem_instruction;

  const handleClick = (link) => openLink(link);

  const PROMO_CODE_DETAILS = [
    "Copy the Coupon Code",
    <span key="perk_type">
      Visit{" "}
      <span
        className="promoCodeWebsite"
        onClick={() => handleClick(redeem_details[0]?.promo_code_redeem_url)}
      >
        {perk_type === PROMO_CODE &&
          perk?.redeem_details &&
          perk.redeem_details[0]?.promo_code_redeem_url}
      </span>
    </span>,
    "Paste the code and submit to redeem",
  ];

  switch (perk_type) {
    case PROMO_CODE: {
      return (
        <ol className="perksAccessDetails">
          {PROMO_CODE_DETAILS.map((details) => (
            <li key={details}>{details}</li>
          ))}
        </ol>
      );
    }
    case INTRO_EMAIL: {
      return (
        <ol className="perksAccessDetails">
          {INTRO_EMAIL_DETAILS.map((details) => (
            <li key={details}>{details}</li>
          ))}
        </ol>
      );
    }
    case SPECIAL_URL: {
      return (
        <ol className="perksAccessDetails">
          {SPECIAL_URL_DETAILS.map((details) => (
            <li key={details}>{details}</li>
          ))}
        </ol>
      );
    }
    case SPECIAL_EMAIL: {
      return (
        <span
          className="perksAccessDetails"
          dangerouslySetInnerHTML={{
            __html: specialEmailInstructions,
          }}
        ></span>
      );
    }
    case GENERIC: {
      return (
        <span
          className="perksAccessDetails"
          dangerouslySetInnerHTML={{
            __html: genericInstruction,
          }}
        ></span>
      );
    }
    default:
      return <></>;
  }
};

export default PerksAccessDetails;
