/* eslint-disable no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "react-table";

//style
import "../../../static/styles/components/addTeamMember.css";
import "../../../static/styles/components/consultantDashBoard.css";

//images
import Clock from "../../../static/images/ClockCounterClockwise.svg";
import FolderIcon from "../../../static/images/Folder.svg";
import { ChatCenteredText } from "../../icons/ChatCenteredText";

//components
import CommonTable from "../../design/commonTable";
import Loader from "../../design/loader";
import NoDataAvailable from "../../design/noDataAvailable";
import RemainingDays from "../../design/remainingDays";
import SubcategoryTab from "../../design/subcategoryTab";
import RightSideBar from "../renderChatItem";
import TaskTabBar from "../taskTabBar";
import { ConsultantDashBoardGlobalFilter } from "./ConsultantDashboardFilter";
import Tags from "./tags";

//constants
import {
  FORM_FILING_TYPE,
  SUBMITTED_TYPE,
} from "../../../constants/taskStates";

//utils
import { openLink } from "../../../utils/openLink";

//context
import { usePageTitle } from "hooks/usePageTitle";
import { useGetTaskTemplatesQuery } from "store/apis/getTemplates";
import authContext from "../../../jwt_context&axios/authContext";
import { useGetAllConsultantDashboardDataQuery } from "store/apis/task";
import { useUpdateQuery } from "hooks/useQuery";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { NOT_STARTED } from "constants/taskStatus";
import Table from "components/DesignSystem/Table/V2/Table";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Header } from "components/DesignSystem/Header/Header";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { ALL, ARCHIVED, COMPLETED, ONGOING } from "constants/task";
import {
  useGetAllTaskStatesQuery,
  useGetTaskTagQuery,
  useLazyGetTaskDataQuery,
} from "store/apis/taskTags";
import { taskColumns } from "../taskForForeignCA/ForeignCATask";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useChatContext } from "stream-chat-react";
import { useUnreadFromChannels } from "hooks/useUnreadFromChannels";
import { usePagination } from "hooks/usePagination";
import { useModal } from "hooks/useModal";
import { TaskSlider } from "components/TaskSlider/TaskSlider";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const defaultSortOption = {
  id: 1,
  title: "Default",
  value: "Default",
};

export default function ConsultantDashboard() {
  const { data } = useGetTaskTemplatesQuery();
  const { templates } = data || { templates: [] };
  usePageTitle("My Tasks");
  const query = useQuery();
  const { update } = useUpdateQuery();
  const { authtoken } = useContext(authContext);
  const [groups, setGroups] = useState([]);
  const searchTerm = query.get("search_term") || null;
  const pageNum = Number(query.get("page") || 1);
  const selectedTag = query.get("task_tag_filter_uuids") || null;
  const selectedETD = query.get("etd_filter") || null;
  const [loading, setLoading] = useState(false);
  const status = query.get("status");
  const stateFilter = query.get("task_state_filter_uuids") || null;
  const selectedTemplate = query.get("task_template_filter_uuids") || null;
  const [modalTitle, setModalTitle] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedSortOption, setSelectedSortOption] =
    useState(defaultSortOption);
  const [channelUrl, setChannelUrl] = useState("");
  const [privateChannelUrl, setPrivateChannelUrl] = useState("");
  const [showSortFeature, setShowSortFeature] = useState(true);
  const [groupId, setGroupId] = useState(null);
  const [isTabSwitched, setIsTabSwitched] = useState(false);
  const [getTaskData] = useLazyGetTaskDataQuery();
  const { data: tagsData = [] } = useGetTaskTagQuery({});
  const { data: taskStatesData = [] } = useGetAllTaskStatesQuery({});
  const taskChatSlider = useModal();
  const taskSlider = useModal();
  const [taskId, setTaskId] = useState(null);

  const {
    data: consultantDashBoardData,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllConsultantDashboardDataQuery(
    {
      page_num: pageNum,
      search_term: searchTerm,
      task_list_type: status,
      task_tag_filter_uuids: selectedTag,
      task_state_filter_uuids: stateFilter,
      task_template_filter_uuids: selectedTemplate,
      task_sort_by: selectedSortOption.sortKey,
      etd_type: selectedETD,
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    tasks = [],
    total_pages = 1,
    total_count = 1,
    per_page = 10,
  } = consultantDashBoardData || {};

  const channelUrls = tasks.map((t) => t.channel);

  const channelUnreadMap = useUnreadFromChannels({
    channelUrls,
    queryOptions: { limit: per_page },
  });

  useEffect(() => {
    refetch();
  }, [groups]);

  const setTagsToTask = ({ taskId, tags }) => {
    setGroups((tasks) => {
      let updatedTask = tasks.find((t) => t.uuid === taskId);
      updatedTask = { ...updatedTask, tags };
      return tasks.map((t) => (t.uuid === taskId ? updatedTask : t));
    });
  };

  const tableData = useMemo(() => tasks, [tasks]);
  const { isAdmin, isServiceSuperAdmin, isCpa, isForeignCA } =
    useRoleBasedView();

  const onTaskChatClick = useCallback((taskId) => {
    taskChatSlider.open();
    setTaskId(taskId);
  }, []);

  const columns = useMemo(() => {
    return taskColumns({
      isAdmin,
      isServiceSuperAdmin,
      isCpa,
      tags: tagsData,
      channelUnreadMap,
      openTaskChat: onTaskChatClick,
      isForeignCA,
    });
  }, [
    JSON.stringify(channelUnreadMap),
    isAdmin,
    isServiceSuperAdmin,
    isCpa,
    tagsData,
    onTaskChatClick,
    isForeignCA,
  ]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const clearFilters = () => {
    update({ query: "page", value: null });
    update({ query: "search_term", value: null });
    update({ query: "task_template_filter_uuids", value: null });
    update({ query: "task_tag_filter_uuids", value: null });
    update({ query: "task_state_filter_uuids", value: null });
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = pageNum < total_pages ? pageNum : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNum < total_pages) {
      update({ query: "page", value: pageNum + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  useEffect(() => {
    if (!isFetching) {
      setIsTabSwitched(false);
    }
  }, [isTabSwitched, isFetching]);

  const pagination = usePagination();

  const paginationData = {
    totalPage: total_pages,
    currentPage: pageNum,
    itemsPerPage: per_page,
    totalItemCount: total_count,
    ...pagination,
  };

  const onTaskRowClick = (taskId) => {
    taskSlider.open();
    setTaskId(taskId);
  };

  const onTaskSliderClose = () => {
    taskSlider.close();
    taskChatSlider.close();
    setTaskId(null);
  };

  return (
    <div className="consultantDashBoardContainer t-w-full">
      <TaskTabBar defaultValue="admin/myfilings" />

      <div className="t-flex t-justify-between t-items-center t-mr-4">
        <div className="t-p-4">
          <ToggleGroup
            onValueChange={(value) => {
              if (value) {
                update({
                  query: "status",
                  value: value !== ALL ? value : null,
                });
                setIsTabSwitched(true);
              }
            }}
            value={status || ALL}
          >
            <ToggleGroupItem value={ALL}>All</ToggleGroupItem>
            <ToggleGroupItem value={ONGOING}>Ongoing</ToggleGroupItem>
            <ToggleGroupItem value={COMPLETED}>Completed</ToggleGroupItem>
            <ToggleGroupItem value={ARCHIVED}>Archived</ToggleGroupItem>
          </ToggleGroup>
        </div>
        <Pagination {...paginationData} />
      </div>

      <div className="consultantDashBoardFilter">
        <ConsultantDashBoardGlobalFilter
          searchedTerm={searchTerm}
          selectedStateFilter={stateFilter}
          tags={tagsData}
          selectedTag={selectedTag}
          selectedETD={selectedETD}
          taskStates={taskStatesData}
          templates={templates}
          selectedTemplate={selectedTemplate}
          selectedSortOption={selectedSortOption}
          setSelectedSortOption={setSelectedSortOption}
          showSortFeature={showSortFeature}
          update={update}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="t-mx-5">
          <Table.Container size="regular">
            <Table.Content>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        className="t-text-subtext-sm t-uppercase t-py-2"
                        style={{ width: `${header.getSize()}%` }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row
                    key={row.id}
                    onRowClick={() => onTaskRowClick(row.original.uuid)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                        className="t-py-2"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </div>
      )}
      <TaskSlider
        taskId={taskId}
        isOpen={taskSlider.isOpen || taskChatSlider.isOpen}
        onClose={onTaskSliderClose}
        defaultTab={taskSlider.isOpen ? "DETAILS" : "CHAT"}
      />
      {tasks.length === 0 && <NoDataAvailable />}
    </div>
  );
}
