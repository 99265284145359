import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { MessageSearchPreview } from "components/MessageSearchPreivew/MessageSearchPreivew";
import InfiniteScroll from "react-infinite-scroll-component";

export const MessageSearchList = ({
  messages,
  hasNextPage,
  loadNextPage,
  windowElementId,
  searchTerm,
  onSelect,
}: {
  messages: {
    message: MessageResponse<DefaultStreamChatGenerics>;
  }[];
  hasNextPage: boolean;
  loadNextPage: () => void;
  windowElementId?: string;
  searchTerm: string;
  onSelect?: (channelId: string, messageId: string) => void;
}) => {
  if (!messages) {
    return null;
  }

  if (messages.length === 0) {
    return null;
  }

  return (
    <InfiniteScroll
      dataLength={messages.length}
      next={loadNextPage}
      hasMore={hasNextPage}
      scrollThreshold={0.6}
      scrollableTarget={windowElementId}
      loader={
        <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
          Loading...
        </div>
      }
    >
      {messages.map((message) => (
        <MessageSearchPreview
          key={message.message.id}
          onSelect={onSelect}
          message={message.message}
          searchTerm={searchTerm}
        />
      ))}
    </InfiniteScroll>
  );
};
